import React, { useEffect, useState } from 'react';
import { getAllMails, MailDocument, SearchResult, selectSearchResult, useAppDispatch } from '@focus-front/core';
import { Virtuoso } from 'react-virtuoso';
import { MailSearchResult } from '../../Mail/MailSearchResult';
import MailSearchResultFake from '../../Mail/MailSearchResultFake';
import AllListContainer, { AllListProps } from './AllListContainer';
import { useTranslation } from 'react-i18next';
import NoResults from './NoResults';
import { mailOutline } from 'ionicons/icons';

export default function AllMailsList({ onBack }: Pick<AllListProps, 'onBack'>) {
  const [mails, setMails] = useState<SearchResult<MailDocument>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      await loadMore();
      setLoading(false);
    })();
  }, []);

  const loadMore = async () => {
    const res = await dispatch(getAllMails(50, mails.length));
    setMails([
      ...mails,
      ...res.map((mail) => ({
        item: {
          id: mail.id,
          date: mail.date,
          from_attendee: mail.from_attendee,
          body_plain: mail.body_plain,
          subject: mail.subject,
          draft: false,
          has_attachments: mail.has_attachments,
          tags: mail.tags,
        },
        matches: [],
        refIndex: 0,
      })),
    ]);
  };

  return (
    <AllListContainer title={t('search.mail_plural')} onBack={onBack}>
      {loading && (
        <>
          <MailSearchResultFake />
          <MailSearchResultFake />
          <MailSearchResultFake />
          <MailSearchResultFake />
          <MailSearchResultFake />
          <MailSearchResultFake />
        </>
      )}
      {!loading && mails.length > 0 && (
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={mails.length}
          endReached={loadMore}
          itemContent={(index) => (
            <MailSearchResult
              index={index}
              key={index}
              mail={mails[index]}
              onClick={() => dispatch(selectSearchResult({ id: mails[index].item.id, type: 'mail' }))}
            />
          )}
        />
      )}
      {!loading && !mails.length && <NoResults icon={mailOutline} label={t('search.mail_plural')} />}
    </AllListContainer>
  );
}
