import { createProjector, toUTCDateTimeMs } from '../../../Common';
import { createViewMessageModel, ViewMessageModel } from '../projections/ViewMessageModel';
import { ViewMessageModelRepo } from '../projections/ViewMessageModelRepo';

export interface viewMessageModelsProjectorRepos {
  viewMessageModel: ViewMessageModelRepo;
}

/**
 * Handler/Projector.
 */
export const viewMessageModelsProjector = createProjector('viewMessageModelsProjector', {
  MESSAGE_MODEL_CREATED: async ({ aggregateId, messageModel, createdAt }, viewMessageModelRepo: ViewMessageModelRepo) => {
    return viewMessageModelRepo.add(
      createViewMessageModel(aggregateId, { ...messageModel, create_date: toUTCDateTimeMs(createdAt), use_count: 0 })
    );
  },
  MESSAGE_MODEL_DELETED: async ({ aggregateId }, viewMessageModelRepo: ViewMessageModelRepo) => {
    viewMessageModelRepo.remove(aggregateId);
  },
  MESSAGE_MODEL_UPDATED: async ({ aggregateId, update, createdAt }, viewMessageModelRepo: ViewMessageModelRepo) => {
    return viewMessageModelRepo.update(aggregateId, { ...update, update_date: toUTCDateTimeMs(createdAt) });
  },
  MESSAGE_MODEL_USED: async ({ aggregateId, count, createdAt }, viewMessageModelRepo: ViewMessageModelRepo) => {
    return viewMessageModelRepo.update(aggregateId, {
      last_use_date: toUTCDateTimeMs(createdAt),
      use_count: (count) + 1,
    });
  },
});
