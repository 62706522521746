import { AggregateRoot, createProjectionConfig, createUuid, DomainEvent, Uuid } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { ContactCreated, ContactDeleted, ContactEdited } from './events/Contact.events';
import { NewContactDTO, UpdateContactDTO } from './projections/ViewContact';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const CONTACT_NAMESPACE = '7ee05a13-50e7-44c2-8d1b-3fe46d9412b3' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  deleted: false,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  CONTACT_CREATED: (event) => ({ id: event.aggregateId }),
  CONTACT_DELETED: () => ({
    deleted: true,
  }),
});

/**
 * Aggregate.
 */
export class Contact extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   *
   * @todo Check invariants here !
   */
  static create(content: NewContactDTO) {
    /** Contacts are too versatile to determine if a contact already exists here, so we don't create a deterministic UUID */
    const aggregateId = createUuid();
    return Contact.accept(new ContactCreated(aggregateId, content));
  }

  /**
   * Command.
   */
  delete() {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new ContactDeleted(this.id, this.version)).accept();
  }

  /**
   * Command.
   *
   * @todo Check invariants here !
   */
  edit(values: UpdateContactDTO) {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new ContactEdited(this.id, this.version, values)).accept();
  }
}
