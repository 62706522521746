import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Account } from '../../domain/Account';

/**
 * Repository.
 */
export class AccountRepo extends AggregateRepo<Account> {
  constructor(eventStore: DomainEventStore) {
    super(Account, eventStore);
  }
}
