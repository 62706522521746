import { Box, ClickAwayListener, createStyles, makeStyles, Paper, useTheme } from '@material-ui/core';
import { Check, FormatColorText } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import { Editor } from '@tiptap/react';
import React, { FC, useState } from 'react';

const COLORS: string[] = [
  '#000000',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
];

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      border: 'none',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      transition: 'color .2s ease-out',
    },
    colors: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: 128,
      bottom: 0,
      zIndex: 9999,
      transform: 'translateY(-25%)',
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
    },
    color: {
      border: 'none',
      boxShadow: theme.shadows[1],
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.25),
      '& > *': {
        opacity: 0,
        transition: 'opacity 0.2s ease-out',
      },
      '&.ql-active': {
        '& > *': {
          opacity: 1,
        },
      },
    },
  })
);

interface TipTapToolbarColorsProps {
  editor: Editor;
}

const TipTapToolbarColors: FC<TipTapToolbarColorsProps> = ({ editor }) => {
  const classes = useStyles();
  const theme = useTheme();
  const currentColor = editor.getAttributes('textStyle').color;

  const [showColors, setShowColors] = useState(false);

  const setColor = (color: string) => {
    editor.commands.setColor(color);
    setShowColors(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowColors(false)}>
      <Box position="relative">
        <ToggleButton
          size="small"
          value="1"
          className={classes.button}
          onClick={() => setShowColors(!showColors)}
          style={{ color: currentColor }}
        >
          <FormatColorText color="inherit" />
        </ToggleButton>
        <Paper variant="elevation" elevation={2} className={classes.colors} style={{ display: showColors ? 'flex' : 'none' }}>
          {COLORS.map(
            (color) =>
              color && (
                <ToggleButton
                  className={classes.color}
                  key={color}
                  value={color}
                  style={{ color: theme.palette.getContrastText(color), backgroundColor: color }}
                  onClick={() => setColor(color)}
                >
                  <Check fontSize="small" color="inherit" />
                </ToggleButton>
              )
          )}
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

export default TipTapToolbarColors;
