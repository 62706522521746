import dayjs from 'dayjs';
import { toUTCDateTimeMs, UTCDateTimeMs, Uuid } from '../../../Common';
import { Select } from '../../../Common/utils';

export type ViewMessageModel = Readonly<{
  // Aggregate ID
  id: Uuid;
  // MessageModel name
  name: string;
  // MessageModel body
  body?: string | undefined;
  create_date: UTCDateTimeMs;
  update_date?: UTCDateTimeMs;
  last_use_date?: UTCDateTimeMs;
  use_count: number;
}>;

type CreateDateDTO = Readonly<{
  create_date: UTCDateTimeMs;
}>;

export type NewMessageModelDTO = Select<Omit<ViewMessageModel, 'id'>, 'name'>;

export type UpdateMessageModelDTO = Partial<Omit<ViewMessageModel, 'id'>>;

export function createViewMessageModel(aggregateId: Uuid, values: NewMessageModelDTO & CreateDateDTO): ViewMessageModel {
  if (!values.name) throw new Error('Missing values to instantiate a MessageModel');

  return {
    body: values.body || '',
    use_count: values.use_count || 0,
    ...values,
    id: aggregateId,
  };
}
