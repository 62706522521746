import React from 'react';
import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Uuid } from '@focus-front/core';
import { useHistory } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { fileTrayOutline } from 'ionicons/icons';

const useClasses = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    fontWeight: 500,
  },
}));

export function NotificationChip({ id }: { id: Uuid }) {
  const { t } = useTranslation();
  const c = useClasses();
  const history = useHistory();
  return (
    <Tooltip title={t('search.open_left')}>
      <Chip
        size="small"
        label={t('header.inboxMob')}
        classes={{
          root: c.root,
        }}
        clickable
        onClick={(e) => {
          e.stopPropagation();
          history.push('/inbox/' + id);
        }}
        icon={<IonIcon icon={fileTrayOutline} />}
      />
    </Tooltip>
  );
}
