import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { rrulestr } from '@keep_focus/rrule-tz';
import { toUTCDateTimeMs, Tzid, UTCDateTimeMs } from './Date';

dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * @note Opaque type.
 */
declare const validRRuleString: unique symbol;
export type RRuleString = string & { [validRRuleString]: true };

/**
 * @note As per Google Calendar.
 */
function defaultLimitIterator(date: Date, i: number): boolean {
  return i < 730 && date.getTime() < 4102444800000; // new Date('2100-01-01T00:00:00Z').getTime()
}

const dummyDate = toUTCDateTimeMs('1980-03-01T00:00:00.000Z');

/**
 * @note RRuleString name used to differentiate it from a RRule object
 *       and leave the name available for later when using a library,
 *       e.g. : import { RRule, RRuleSet, rrulestr } from '@keep_focus/rrule-tz'.
 *
 * @todo Replace placeholder parser/validator.
 *
 * @todo Disallow DTSTART, DTEND in this parser/validator.
 *       As per Google :
 *
 *       "List of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event,
 *        as specified in RFC5545. Note that DTSTART and DTEND lines are not
 *        allowed in this field; event start and end times are specified in the
 *        start and end fields. This field is omitted for single events or
 *        instances of recurring events."
 *
 *       See https://developers.google.com/calendar/api/v3/reference/events#recurrence
 */
export function toRRuleString(rrule: string): RRuleString {
  console.warn('@todo toRRuleString parsing/validation.');
  /** @todo Validate !  */
  return rrule as RRuleString;
}

/**
 * Check if both given recurrence rules are semantically equivalent.
 *
 * @note Google calendar seems to use different criterias to decide if two
 *       sets of RRules are equivalent.
 */
export function areEquivalentRRules(
  rulesA: RRuleString[],
  rulesB: RRuleString[],
  start = dummyDate,
  start_tzidA?: Tzid | null,
  start_tzidB?: Tzid | null,
  limitIterator = defaultLimitIterator
): boolean {
  const a = expandRRuleStrings(rulesA, start, limitIterator, start_tzidA);
  const b = expandRRuleStrings(rulesB, start, limitIterator, start_tzidB);

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0, length = a.length; i < length; i++) {
    if (a[i].getTime() !== b[i].getTime()) {
      return false;
    }
  }
  return true;
}

/**
 * @see https://datatracker.ietf.org/doc/html/rfc5545
 *
 * @note rrulstr is used because RRule.parseString(rule) doesn't work with RDATE.
 *       RDATE can be added via a method on RRuleSet, but this would require
 *       to parse the rules to extract RDATEs which would defeat the purpose.
 *
 * @note DTSTART is injected at the beginning as a workaround to the dtstart
 *       option seemingly not working as expected on rrulestr.
 *
 * @note Google calendar doesn't play nice if UNTIL is used in a RRULE and some
 *       RDATE are set after the UNTIL value. The instance are not displayed in
 *       the interface but they seem to exist. They can be interacted with
 *       via the API.
 *
 * @note If tzid parameter is omitted, start will be kept in UTC and
 *       DTSTART won't have any TZID property applied to reflect that,
 *       as per RFC5545 :
 *
 *        3.2.19.  Time Zone Identifier
 *          "The "TZID" property parameter MUST NOT be applied to DATE
 *          properties and DATE-TIME or TIME properties whose time values are
 *          specified in UTC."
 *
 * @note From RFC5545 :
 *
 *         DTSTART:19970714T133000                       ; Local time / Floating time.
 *         DTSTART:19970714T173000Z                      ; UTC time   / Absolute time.
 *         DTSTART;TZID=America/New_York:19970714T133000 ; Local time and time zone reference
 *
 *       Floating time :
 *         "They are used to represent the same hour, minute, and second value
 *         regardless of which time zone is currently being observed.  For example, an
 *         event can be defined that indicates that an individual will be
 *         busy from 11:00 AM to 1:00 PM every day, no matter which time zone
 *         the person is in."
 *
 * @note Google will rewrite all rules using Floating or Absolute time with the
 *       Calendar default TZID.
 *       It doesn't seem to support true Floating time. It accepts them, translate
 *       them and then result is then set in stone.
 *       Until we have a use case for Floating time, expandRRuleStrings will NOT
 *       support it.
 *
 *       See  https://developers.google.com/calendar/api/concepts/events-calendars?hl=fr#recurring_event_time_zone
 *
 * @todo Check that Floating time values don't cause problems.
 *
 * @todo Figure out what RFC5545 says about mixed TZIDs. Right now, we rewrite
 *       every rule that uses a TZID as UTC and apply given tzid on DTSTART if
 *       any, because it seems rrulestr latches on the first TZID it finds and
 *       uses it for everything.
 *
 *
 * @todo Figure out if the way you're combining the rules into a RRuleSet is
 *       actually how it works and how Google calendar uses it :
 *
 *       "List of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event,
 *        as specified in RFC5545. Note that DTSTART and DTEND lines are not
 *        allowed in this field; event start and end times are specified in the
 *        start and end fields. This field is omitted for single events or
 *        instances of recurring events."
 */
export function expandRRuleStrings(
  rules: RRuleString[],
  start: UTCDateTimeMs,
  limitIterator = defaultLimitIterator,
  tzid?: Tzid | null
): Date[] {
  tzid ??= 'Etc/UTC' as Tzid;
  /**  Rewrite all rules with given TZID. */
  rules = rules.map((rule) => rule.replace(TZID_REGEX, rewriteTzidAs(tzid as Tzid)) as RRuleString);
  const rruleString = `DTSTART;TZID=${tzid}:${dayjs(start).tz(tzid).format('YYYYMMDDTHHmmss')}\n` + rules.join('\n');

  // console.log(rruleString);

  /**  Return all dates converted to UTC. */
  return rrulestr(rruleString, {
    forceset: true,
  }).all(limitIterator);
}

// export function expandRRuleStrings(
//   rules: RRuleString[],
//   start: UTCDateTimeMs,
//   limitIterator = defaultLimitIterator,
//   tzid?: Tzid
// ): Date[] {
//   let rruleString: string;
//   if (tzid === undefined) {
//     /** UTC time. */
//     /** Rewrite rules ( that use TZID ) in UTC. */
//     rules = rules.map((rule) => rule.replace(TZID_REGEX, rewriteTzidAsUtc) as RRuleString);
//     rruleString = `DTSTART:${dayjs.utc(start).format('YYYYMMDDTHHmmss[Z]')}\n` + rules.join('\n');
//   } else {
//     /**  Rewrite all rules ( that use TZID ) with given TZID. */
//     rules = rules.map((rule) => rule.replace(TZID_REGEX, rewriteTzidAs(tzid)) as RRuleString);
//     rruleString = `DTSTART;TZID=${tzid}:${dayjs(start).tz(tzid).format('YYYYMMDDTHHmmss')}\n` + rules.join('\n');
//   }
//   /**  Return all dates in actual UTC. */
//   return rrulestr(rruleString, {
//     forceset: true,
//   }).all(limitIterator);

//   // Unsupported RFC prop RDATE
//   //   const rruleSet = new RRuleSet();
//   //   rules.forEach((rule) => {
//   //     const parsed = RRule.parseString(rule);
//   //     // parsed.dtstart = dayjs(start).toDate();
//   //     parsed.dtstart = new Date(start);
//   //     rruleSet.rrule(new RRule(parsed));
//   //   });
//   //   return rruleSet.all(limitIterator);
// }

/**
 *
 */
function rewriteTzidAsUtc(match: string, tzid: string, dates: string) {
  return (
    ':' +
    dates
      .split(',')
      .map((date) =>
        date.length === 8
          ? /**
             *  @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.4
             *       for Date format. No notion of local/UTC time.
             *
             *       https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.5
             *       for Datetime format.
             */
            //     dayjs.tz(date, tzid).tz('UTC').format('YYYYMMDD')
            //   : dayjs.tz(date, tzid).tz('UTC').format('YYYYMMDDTHHmmss[Z]')
            dayjs.tz(date, tzid).utc().format('YYYYMMDD')
          : dayjs.tz(date, tzid).utc().format('YYYYMMDDTHHmmss[Z]')
      )
      .join(',')
  );
}

/**
 *
 */
export function rewriteTzidAs(asTzid: Tzid) {
  return function rewriteTzid(match: string, tzid: string, dates: string) {
    return (
      `;TZID=${asTzid}:` +
      dates
        .split(',')
        .map((date) =>
          date.length === 8
            ? dayjs.tz(date, tzid).tz(asTzid).format('YYYYMMDD')
            : dayjs.tz(date, tzid).tz(asTzid).format('YYYYMMDDTHHmmss')
        )
        .join(',')
    );
  };
}

export const TZID_REGEX = /(?:;TZID=([^:=]+?))(?::|=)([^;\s]+)/i;
