import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import {
  addGoogleCalendarAccount,
  envSelector,
  getCalendars,
  initialGoogleCalendarFetch,
  requestAuthGoogleCalendar,
  useAppDispatch,
} from '@focus-front/core';
import { Box, Typography, Link } from '@material-ui/core';

import { BackButton, NextButton } from './NavButton';
import { WizardContext } from './WizardContext';
import { useSelector } from 'react-redux';
import { GoogleCalendarScopes } from '@focus-front/core';
import { Alert } from '@material-ui/lab';

export const calendarScopes = GoogleCalendarScopes.join(' ');

/**
 *
 */
function WebLogin() {
  const { goBack, forms, setLoading, setForms, goNext, setAlias, setAccountId } = useContext(WizardContext);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSuccess = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (response.code) {
      const { error, result } = await dispatch(requestAuthGoogleCalendar(response.code));

      if (error) {
        if (error !== 'cancelled') {
          // alert(error);
          setError(error);
        }
        setLoading(false);
      } else if (result) {
        setAlias(result.identifier);
        setForms({ ...forms, GOOGLE_CALENDAR_LOGIN: result });

        const { at, rt, identifier } = result;
        const res = await dispatch(addGoogleCalendarAccount(identifier, { at, rt }, { calendar: identifier }));
        const account_id = res.account_id;

        if (res.error) {
          setLoading(false);
          setError(res.error);
          return;
        }

        const error = await dispatch(initialGoogleCalendarFetch(account_id));
        if (error) {
          /**
           * @todo Consider what should be done here ?
           *       Account has already been added but something went wrong during first pull.
           *       Should we delete the account ?
           *       Retry the first pull ?
           */
          setLoading(false);
          setError(res.error);
          goNext();
          return;
        }
        setAccountId(account_id);
        dispatch(getCalendars());

        goNext();
      }
    } else {
      throw new Error('GoogleCalendarLogin handleSuccess, unhandled response type.');
    }
  };

  const handleFailure = async (error: unknown) => {
    setLoading(false);
  };

  const { signIn, loaded } = useGoogleLogin({
    onSuccess: handleSuccess,
    onFailure: handleFailure,
    clientId: '615134359151-ofgfjeas1g8uumar503fh1vop1cr5eak.apps.googleusercontent.com',
    scope: calendarScopes,
    accessType: 'offline',
    responseType: 'code',
    prompt: 'consent',
  });

  const handleLogin = () => {
    setError(null);
    setLoading(true);
    signIn();
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.googleCalendarLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.googleCalendarLogin.subtitle')}</Typography>
      <Box mt={3} mb={5}>
        <Typography variant="body1">
          <Trans i18nKey="wizard.googleCalendarLogin.tuto">
            <ul>
              <li>
                Notre application est actuellement en phase de bêta-test, merci de ne pas prendre en compte le message
                d'avertissement de Google.
                <strong>Cliquez sur 'Paramètres avancés' (en gris clair en bas) puis 'Accéder à Unipile (non sécurisé)'</strong>
              </li>
              <li>
                Lorsque Google affichera les services auxquels nous devons avoir accès pour intégrer votre agenda,
                <strong>cochez obligatoirement les 3 cases pour que cela fonctionne</strong>. Nous n'accédons volontairement qu'au
                strict minimum des informations nécessaires.
              </li>
            </ul>
          </Trans>
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
      <NextButton onClick={handleLogin}>{t('wizard.googleCalendarLogin.loginBtn')}</NextButton>
    </div>
  );
}

/**
 *
 */
function DesktopLogin() {
  const { goBack, forms, setLoading, setForms, goNext, setAlias, setAccountId } = useContext(WizardContext);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    // setLoading(true);
    setError(null);
    const { error, result } = await dispatch(requestAuthGoogleCalendar());
    if (error) {
      if (error !== 'cancelled') {
        // alert(error);
        setError(error);
      }
      //   setLoading(false);
    } else if (result) {
      setAlias(result.identifier);
      setForms({ ...forms, GOOGLE_CALENDAR_LOGIN: result });

      const { at, rt, identifier } = result;
      const res = await dispatch(addGoogleCalendarAccount(identifier, { at, rt }, { calendar: identifier }));
      const account_id = res.account_id;

      if (res.error) {
        setLoading(false);
        setError(res.error);
        return;
      }

      const error = await dispatch(initialGoogleCalendarFetch(account_id));
      if (error) {
        /**
         * @todo Consider what should be done here ?
         *       Account has already been added but something went wrong during first pull.
         *       Should we delete the account ?
         *       Retry the first pull ?
         */
        setLoading(false);
        setError(res.error);
        goNext();
        return;
      }
      setAccountId(account_id);
      dispatch(getCalendars());

      goNext();
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.googleCalendarLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.googleCalendarLogin.subtitle')}</Typography>
      <Box mt={3} mb={5}>
        <Typography variant="body1">
          <Trans i18nKey="wizard.googleCalendarLogin.tuto">
            <ul>
              <li>
                Notre application est actuellement en phase de bêta-test, merci de ne pas prendre en compte le message
                d'avertissement de Google.
                <strong>Cliquez sur 'Paramètres avancés' (en gris clair en bas) puis 'Accéder à Unipile (non sécurisé)'</strong>
              </li>
              <li>
                Lorsque Google affichera les services auxquels nous devons avoir accès pour intégrer votre agenda,
                <strong>cochez obligatoirement les 3 cases pour que cela fonctionne</strong>. Nous n'accédons volontairement qu'au
                strict minimum des informations nécessaires.
              </li>
            </ul>
          </Trans>
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
      <NextButton onClick={handleLogin}>{t('wizard.googleCalendarLogin.loginBtn')}</NextButton>
    </div>
  );
}

/**
 *
 */
export default function GoogleCalendarLogin() {
  const { device_type } = useSelector(envSelector);
  return device_type === 'desktop' ? <DesktopLogin /> : <WebLogin />;
}
