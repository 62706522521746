import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getMailDraftAttachmentData,
  getMailDraftView,
  MailAttachment,
  sessionSelector,
  Uuid,
  ViewMailDraft,
  useAppDispatch,
  LocalFile,
} from '@focus-front/core';
import { Attachment } from '@focus-front/ui';
import { makeStyles, Typography } from '@material-ui/core';

import AccountLabel from '../Common/AccountLabel';
import ErrorMessage from '../Common/ErrorMessage';
import AttendeeAvatar from '../Contact/AttendeeAvatar';

const useClasses = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  contactContainer2: {
    marginLeft: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(2),
  },
  attachments: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default function MailDraftView({ mail_id, reload }: { mail_id: Uuid; reload: number }) {
  const [mail, setMail] = useState<ViewMailDraft>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<MailAttachment[]>([]);
  const [files, setFiles] = useState<LocalFile[]>([]);
  const session = useSelector(sessionSelector);
  const [attLoading, setAttLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const bodyRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      // Load the mail draft
      const mail = await dispatch(getMailDraftView(mail_id));
      setMail(mail);

      if (!mail) return setNotFound(true);

      if (mail.attachment_ids) {
        // Get attachmetnts data from local storage from attachment_ids
        const files = await Promise.all(
          mail.attachment_ids.map((attachment_id) => dispatch(getMailDraftAttachmentData(attachment_id)))
        );

        /**
         * Assign data to the list of attachments ids. If data was not found (cleared local storage or file uploaded from another device)
         * Set "not found" data
         **/
        setAttachments(
          mail.attachment_ids.map((id) => {
            const found = files.find((att) => att?.id === id);
            if (found) return found;
            return { id, extension: '', mime: '', name: t('attachment.notFound'), size: 0 };
          })
        );
      }

      setAttLoading(false);
    })();
  }, [mail_id, reload]);

  useEffect(() => {
    if (bodyRef && bodyRef.current) {
      if (!bodyRef.current.shadowRoot) bodyRef.current.attachShadow({ mode: 'open' });

      bodyRef.current.shadowRoot.innerHTML = `<div id="mail-body" style="display: table; width: 100%">${mail.body || ''}</div>`;
    }
  }, [mail]);

  if (notFound) return <ErrorMessage title={t('mail_draft.notFoundTitle')} subtitle={t('mail_draft.notFoundSubtitle')} />;
  if (!mail) return <div></div>;

  return (
    <div>
      <div className={classes.header}>
        <div>
          <Typography variant="h1">{mail.subject || t('mailForm.noSubject')}</Typography>
          <div className={classes.contactContainer}>
            <AttendeeAvatar display_name={session.firstname} avatar_url={session.profile_picture} />
            <div className={classes.contactContainer2}>
              <Typography variant="h4">{session.firstname + ' ' + session.lastname}</Typography>
              <Typography variant="body2">
                <strong>{t('mailForm.to')} :</strong> {mail.to_attendees?.map((att) => att.display_name).join(', ')}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.details}>
          <Typography variant="body2" className={classes.detailText}>
            {dayjs(mail.update_date).format('DD MMM YYYY [à] HH:mm')}
          </Typography>
          <AccountLabel account_id={mail.account_id} className={classes.detailText} />
        </div>
      </div>
      <div ref={bodyRef} className={classes.body}></div>
      {!!attachments?.length && (
        <div className={classes.attachments}>
          {attachments.map((attachment) => (
            <Attachment key={attachment.id} attachment={attachment} loaded={!attLoading} />
          ))}
        </div>
      )}
    </div>
  );
}
