import jwtDecode from 'jwt-decode';
import { Crypto } from '../../../Common/infra/services/crypto/Crypto';
import { prettyPrint } from '../../../Common/utils';
import { CoreApiConfig } from '../../../_container/interfaces/Environment';
import { UserSession, createUser } from '../../domain';
import { SessionRepo } from '../repository/session/SessionRepo';
import { AuthenticatorService, InvalidCredentialsLoginError, InvalidOrExpiredRefreshTokenError } from './AuthenticatorService';
import { CoreServerError, DecodedJwt } from './CoreApiTypes';

// let refreshCount = 0;
/**
 * Authentification service
 */
export class CoreAuthenticatorService implements AuthenticatorService {
  /**
   * The Session's JWT
   * It's stored here in memory for quick access since it's called on every fetch
   */
  private jwt: string | null = null;

  constructor(private config: CoreApiConfig, private sessionRepo: SessionRepo, private crypto: Crypto) {}

  async login(username: string, password: string): Promise<void> {
    /**
     * @note Hash password before authenticating with API core for phase 1.
     */
    const encrypted_password = await this.crypto.getStopGapPasswordHash(password, username);
    const response = await fetch(`${this.config.host}/api/login_check`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ username, password: encrypted_password }),
    });
    let jsonResponse;

    // Try to parse the response as JSON
    try {
      jsonResponse = await response.json();
    } catch (e) {
      throw new CoreServerError(response.statusText);
    }
    if (!response.ok || !jsonResponse.refresh_token || !jsonResponse.token) {
      if (jsonResponse.message === 'Invalid credentials.') throw new InvalidCredentialsLoginError();
      throw new Error(jsonResponse.message ? jsonResponse.message : jsonResponse);
    }
    const { refresh_token, token } = jsonResponse;
    this.jwt = token;

    await this._saveSession(refresh_token, token, password);
  }

  /**
   * Get the session's JWT
   */
  async getJwt(): Promise<string | null> {
    if (this._isJwtExpired()) {
        await this._refreshToken();
    }
    // await this._refreshToken();
    return this.jwt || null;
  }

  /**
   * Logout the user
   */
  async logout(): Promise<void> {
    await this.sessionRepo.delete();
    this.jwt = null;
  }

  /**
   * Refresh JWT if expired
   */
  private async _refreshToken(): Promise<void> {
    /** If the JWT is not expired, we do not need to refresh it */
    // if (!this._isJwtExpired()) return;

    /** Get the session, where the refresh_token is stored */
    const session = await this.sessionRepo.get();

    if (!session) throw new Error('You must be logged in to refresh the session');

    // const rtoken = session.refresh_token + (refreshCount++ < 6 ? '' : 'gooped');
    // prettyPrint({ session, refreshCount, rtoken }, '_refreshToken');

    /** Call the refresh route */
    const response = await fetch(`${this.config.host}/api/token/refresh`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ refresh_token: session.refresh_token }),
    });

    let jsonResponse;
    try {
      jsonResponse = await response.json();
    } catch (e) {
      throw new Error(response.statusText);
    }
    if (!response.ok || !jsonResponse.refresh_token || !jsonResponse.token) {
      if (jsonResponse.message === 'An authentication exception occurred.') {
        throw new InvalidOrExpiredRefreshTokenError();
      }
      throw new Error(jsonResponse.message ? jsonResponse.message : jsonResponse);
    }

    /**
     * @todo We want to throw a custom error if the Refresh token is expired
     */

    const { refresh_token, token } = jsonResponse;

    // Set new JWT in memory
    this.jwt = token;

    await this.sessionRepo.set({ ...session, refresh_token });
  }

  /**
   * Verify if the JWT is valid
   */
  private _isJwtExpired(): boolean {
    /**
     * @todo Change Date.now() to something better
     */
    return !this.jwt || jwtDecode<DecodedJwt>(this.jwt).exp < Math.floor(Date.now() / 1000);
  }

  /**
   * Decode the JWT and build the session out of it
   * @param rt Refresh token
   * @param jwt JWT
   */
  private _buildSession(rt: string, jwt: string): UserSession {
    const decoded = jwtDecode<DecodedJwt>(jwt);

    const user = createUser(decoded);

    const session: UserSession = {
      refresh_token: rt,
      ...user,
    };

    return session;
  }

  private async _saveSession(rt: string, jwt: string, password?: string): Promise<void> {
    const session = this._buildSession(rt, jwt);

    await this.sessionRepo.set(session, password);
  }
}
