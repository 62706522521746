import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { accountSelector, accountSourcesSelector, AccountSourceType, ActionId, Uuid } from '@focus-front/core';
import { AttachedEntityType } from '../../useActionBar';

/**
 * Map the source handling data of each entity type
 * (ex: "IM_THREAD" entities are handled by the "IMS" source)
 */
const EntitySourcesMapping: { [key in AttachedEntityType]: AccountSourceType } = {
  CALL: 'CALLS',
  EVENT: 'CALENDAR',
  IM: 'IMS',
  IM_THREAD: 'IMS',
  MAIL: 'MAILS',
  MAIL_DRAFT: 'MAILS',
};

/**
 * Return a specific message to display if an action is disabled based on the type of action and the source's status
 * @param account_id Account of the entity
 * @param entity_type Source responsible of the action
 * @param action_id Type of action (to personalize the message)
 * @returns A message to warn the user
 */
export function useDisabledAction(account_id: Uuid, entity_type: AttachedEntityType, action_id: ActionId): string | null {
  const { t } = useTranslation();
  const sources = useSelector(accountSourcesSelector(account_id));
  const account = useSelector(accountSelector(account_id));

  const disabledMessage = useMemo(() => {
    const source = sources.find((s) => s.type === EntitySourcesMapping[entity_type]);

    if (!source || !account) return null;

    switch (source.status) {
      case 'UNSUPPORTED': {
        return t(`action.notPossibleAlert.UNSUPPORTED_${account.type}_${entity_type.toLowerCase()}_${action_id}`);
      }
      case 'CREDENTIALS': {
        return t(`action.notPossibleAlert.CREDENTIALS_${entity_type.toLowerCase()}_${action_id}`, { account: account.name });
      }
      case 'ERROR': {
        return t(`action.notPossibleAlert.ERROR`, {
          account: account.name,
        });
      }
      case 'PERMISSIONS': {
        return t(`action.notPossibleAlert.PERMISSIONS_${account.type}_${entity_type.toLowerCase()}_${action_id}`, {
          account: account.name,
        });
      }
    }

    return null;
  }, [sources, account]);

  return disabledMessage;
}
