import { SingleOrArray } from '../utils';
import { AggregateRoot, AggregateRootProjectionType } from './AggregateRoot';
import { DomainEvent, DomainEventId, DomainEventShape } from './DomainEvent';
import { DomainEventStore } from './DomainEventStore';
import { Uuid } from './Uuid';

/**
 * Repository.
 *
 * @note The basic shape for an aggregate repo is getDomainEvents(), getAggregate(),
 *       getAggregateAt().
 *
 *       Extend it when more complex queries are needed.
 *       See SubscriptionsRepository.cs in ref implementation for things that
 *       look ok to use and consider what may be a problem with eventual consistency
 *       and isolated replays !!!
 *
 * @todo Figure out what was the reason AggregateRepoShape aren't returning MaybePromise<T>.
 */
export interface AggregateRepoShape<
  TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent>,
  TDomainEvent extends DomainEventShape = DomainEvent
> {
  /**
   * Query.
   */
  getDomainEvents: (aggregateId: Uuid) => Promise<TDomainEvent[]>;

  /**
   * Query.
   */
  getDomainEventsAt: (aggregateId: Uuid, refEvent: TDomainEvent) => Promise<TDomainEvent[]>;

  /**
   * Query.
   */
  getAggregate: (aggregateId: Uuid) => Promise<TAggregate>;

  /**
   * Query.
   */
  getAggregateAt: (aggregateId: Uuid, refEvent: TDomainEvent) => Promise<TAggregate>;
}

export abstract class AggregateRepo<
  TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent>,
  TDomainEvent extends DomainEventShape = DomainEvent
> implements AggregateRepoShape<TAggregate, TDomainEvent> {
  /**
   *
   */
  constructor(
    private readonly aggregateType: new (events: SingleOrArray<TDomainEvent>) => TAggregate,
    protected readonly eventStore: DomainEventStore<TDomainEvent>
  ) {}

  /**
   * Query.
   *
   * @todo Consider checking if requested aggregateId is actually relevant to the repo type.
   */
  async getDomainEvents(aggregateId: Uuid): Promise<TDomainEvent[]> {
    return this.eventStore.getByAggregate(aggregateId) as Promise<TDomainEvent[]>;
  }

  /**
   * Query.
   */
  async getDomainEventsAt(aggregateId: Uuid, refEvent: TDomainEvent): Promise<TDomainEvent[]> {
    return this.eventStore.getByAggregateUpTo(aggregateId, refEvent) as Promise<TDomainEvent[]>;
  }

  /**
   * Query.
   *
   * @note What happens when given aggregateId is valid for a non-CalendarEvent
   *       aggregate and getAggregate returns unrelated events.
   *
   *       events.length ? checks out, a new CalendarEvent is instantiated and
   *       the latter ignores all the event types it doesn't handle.
   *
   *       You end up with a malformed aggregate without an id.
   *
   */
  async getAggregate(aggregateId: Uuid): Promise<TAggregate> {
    const events = await this.eventStore.getByAggregate(aggregateId);
    if (!events.length) {
      throw new UnknownAggregateIdError(aggregateId);
    }
    return new this.aggregateType(events);
  }

  /**
   * Query.
   *
   * Retrieve an Aggregate in the state it would be at a certain point in time
   * from the point of view of given refEventId.
   */
  async getAggregateAt(aggregateId: Uuid, refEvent: TDomainEvent): Promise<TAggregate> {
    const events = await this.eventStore.getByAggregateUpTo(aggregateId, refEvent);
    if (!events.length) {
      throw new UnknownAggregateIdError(aggregateId, { aggregateId: refEvent.aggregateId, version: refEvent.version });
    }
    return new this.aggregateType(events);
  }
}

/**
 *
 */
export class UnknownAggregateIdError extends Error {
  constructor(readonly aggregateId: Uuid, readonly refEventId?: DomainEventId) {
    super(
      `Unknown aggregate id : ${aggregateId}${refEventId ? ` from [${refEventId.aggregateId}, ${refEventId.version}] POV` : ''} !`
    );
    this.name = 'UnknownAggregateIdError';
  }
}
