import { sourceIsFetchingSelector, refreshSync, useAppDispatch } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import dayjs from 'dayjs';
import { refreshOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useClasses = makeStyles((theme) => ({
  root: ({ fetching }: { fetching: boolean }) => ({
    animation: fetching && 'rotate 1s infinite linear',
  }),
}));

export default function RefreshButton() {
  const fetching = useSelector(sourceIsFetchingSelector);
  const dispatch = useAppDispatch();
  const c = useClasses({ fetching });
  const { t } = useTranslation();

  const [time, setTime] = useState<{ mail: string; im: string }>({
    mail: sessionStorage.getItem('LAST_MAIL_FETCH'),
    im: sessionStorage.getItem('LAST_IM_FETCH'),
  });

  if (fetching)
    return (
      <IconButton className={c.root} disabled={fetching} onClick={() => dispatch(refreshSync())} size="small">
        <Refresh />
      </IconButton>
    );

  return (
    <Tooltip
      arrow
      title={
        <div>
          <div>{`${t('inbox.refresh.last_mail_check')} : ${dayjs(time.mail).fromNow()}`}</div>
          <div>{`${t('inbox.refresh.last_im_check')} : ${dayjs(time.im).fromNow()}`}</div>
        </div>
      }
      onOpen={() =>
        setTime({
          mail: sessionStorage.getItem('LAST_MAIL_FETCH'),
          im: sessionStorage.getItem('LAST_IM_FETCH'),
        })
      }
    >
      <IconButton className={c.root} onClick={() => dispatch(refreshSync())} size="small">
        <Refresh />
      </IconButton>
    </Tooltip>
  );
}
