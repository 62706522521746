import { deleteDB, IDBPDatabase, IDBPTransaction, openDB, StoreNames } from 'idb';
import { createUuid } from '../../../domain/Uuid';

import { DBSchema } from './schemas';
import { DBSchemaV1 } from './schemas/DBSchemaV1';
import { DBSchemaV2 } from './schemas/DBSchemaV2';
import { DBSchemaV3 } from './schemas/DBSchemaV3';
import { DBSchemaV4 } from './schemas/DBSchemaV4';

/**
 * IMPORTANT FOR MIGRATIONS :
 * Please visit https://www.npmjs.com/package/idb#opendb to learn more about upgrade() and Schema versions
 */

export const DB_NAME = '__UNIPILE_DB';
export const DB_VERSION = 5;

export let Idb: IDBPDatabase<DBSchema>; // Database instance
export let userDB: number | undefined = undefined; // ID of the database instance's user

/**
 *
 */
export class IdbInitializationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'IdbInitializationError';
  }
}

/**
 *
 */
export async function closeIdb(user_id: number) {
  if (!Idb) {
    throw new Error(`Cannot close Idb connection ${DB_NAME + user_id} : no Idb connection opened.`);
  }

  if (userDB !== user_id) {
    throw new Error(`Cannot close Idb connection ${DB_NAME + user_id}: another Idb connection currently opened.`);
  }

  console.warn('closing IDB connection');

  await Idb.close();
  userDB = undefined;
}

/**
 *
 */
export async function deleteIdb(user_id: number) {
  await deleteDB(DB_NAME + user_id);
  userDB = undefined;
  return;
}

/**
 * Return the IndexedDB instance of a user
 * @param user_id ID of the user
 */
export async function initializeIdb(user_id?: number) {
  //   if (!Idb || userDB !== user_id) {
  if (userDB !== user_id) {
    userDB = user_id;
    try {
      Idb = await openDB<DBSchema>(DB_NAME + (user_id ?? ''), DB_VERSION, {
        // This function is called if the actual user DB version is older than the version passed above in openDB()
        upgrade(db, oldVersion, newVersion, tx) {
          // Cast references of the database to old schemas.
          // const v1Db = (db as unknown) as IDBPDatabase<DBSchemaV1>;
          // const v1Tx = (tx as unknown) as IDBPTransaction<DBSchemaV1, StoreNames<DBSchemaV1>[], 'versionchange'>;

          if (oldVersion < 1) {
            const v1Db = db as unknown as IDBPDatabase<DBSchemaV1>;
            const v1Tx = tx as unknown as IDBPTransaction<DBSchemaV1, StoreNames<DBSchemaV1>[], 'versionchange'>;
            /**
             * @note Let autoIncrement do its job for domain events generated in the app,
             *      provide known id when inserting domain events retrieved from API Core / user cloud.
             */
            v1Db.createObjectStore('domainEvents', { keyPath: 'id', autoIncrement: true });
            v1Tx.objectStore('domainEvents').createIndex('by-aggregate', 'aggregateId', {
              unique: false,
            });
            v1Tx.objectStore('domainEvents').createIndex('by-aggregate-and-version', ['aggregateId', 'version'], {
              unique: true,
            });
            /** For debug purposes. */
            v1Tx.objectStore('domainEvents').createIndex('by-type', 'type', { unique: false });
            v1Tx.objectStore('domainEvents').createIndex('by-branch', 'branch', { unique: false });

            v1Db.createObjectStore('ledger', { keyPath: 'name' });

            /** @todo Research idiomatic way to add initial data to Idb. */
            const backupStore = v1Db.createObjectStore('backup', { keyPath: 'branch' });
            backupStore.put({ branch: createUuid(), cursors: undefined });

            // CalendarEvent
            v1Db.createObjectStore('viewCalendarEvents', { keyPath: 'id' });
            v1Tx.objectStore('viewCalendarEvents').createIndex('by-calendar', 'calendar_id', {
              unique: false,
            });
            v1Tx.objectStore('viewCalendarEvents').createIndex('by-date', 'start', {
              unique: false,
            });
            v1Tx.objectStore('viewCalendarEvents').createIndex('by-recurring-event', 'recurring_event_id', {
              unique: false,
            });

            v1Tx.objectStore('viewCalendarEvents').createIndex('by-status-and-date', ['status', 'start'], {
              unique: false,
            });

            // RecurringCalendarEvent
            v1Db.createObjectStore('recurringCalendarEvent', { keyPath: 'id' });
            v1Tx.objectStore('recurringCalendarEvent').createIndex('by-calendar', 'calendar_id', {
              unique: false,
            });
            v1Tx.objectStore('recurringCalendarEvent').createIndex('by-date', 'start', {
              unique: false,
            });

            // Calendar
            v1Db.createObjectStore('viewCalendars', { keyPath: 'id' });
            v1Tx.objectStore('viewCalendars').createIndex('by-account', 'account_id', {
              unique: false,
            });
            v1Tx.objectStore('viewCalendars').createIndex('by-account-and-external-id', ['account_id', 'external_id'], {
              /** @note It's a good idea only if you deal with the possible constraint errors. */
              unique: true,
            });

            // Notification
            v1Db.createObjectStore('viewNotifications', { keyPath: 'id' });
            v1Tx.objectStore('viewNotifications').createIndex('by-type', 'type', { unique: false });
            v1Tx.objectStore('viewNotifications').createIndex('by-message', 'message_id', {
              unique: false,
            });
            v1Tx.objectStore('viewNotifications').createIndex('by-entity-id', 'metadata.attached_entity.id', { unique: false });
            v1Tx.objectStore('viewNotifications').createIndex('by-account', 'account_id', {
              unique: false,
            });

            // Contact
            v1Db.createObjectStore('viewContacts', { keyPath: 'id' });
            v1Tx.objectStore('viewContacts').createIndex('by-identifier', 'identifiers', {
              unique: false,
              multiEntry: true,
            });
            v1Tx.objectStore('viewContacts').createIndex('by-full_name', 'full_name', {
              unique: false,
            });
            v1Tx.objectStore('viewContacts').createIndex('by-account', 'managing_accounts', { unique: false, multiEntry: true });

            v1Db.createObjectStore('viewAccounts', { keyPath: 'id' });
            v1Tx.objectStore('viewAccounts').createIndex('by-type', 'type', { unique: false });

            // Im
            v1Db.createObjectStore('viewIms', { keyPath: 'id' });
            v1Tx.objectStore('viewIms').createIndex('by-account', 'account_id', { unique: false });
            v1Tx.objectStore('viewIms').createIndex('by-thread', 'thread_id', { unique: false });
            v1Tx.objectStore('viewIms').createIndex('by-thread-and-date', ['thread_id', 'date'], {
              unique: false,
            });
            v1Tx.objectStore('viewIms').createIndex('by-account-and-date', ['account_id', 'date'], {
              unique: false,
            });
            v1Tx.objectStore('viewIms').createIndex('by-account-and-status-and-date', ['account_id', 'sync_status', 'date'], {
              unique: false,
            });
            v1Tx.objectStore('viewIms').createIndex('by-thread-and-status-and-date', ['thread_id', 'sync_status', 'date'], {
              unique: false,
            });
            v1Tx
              .objectStore('viewIms')
              .createIndex('by-account-and-provider-id-and-status', ['account_id', 'provider_id', 'sync_status'], {
                unique: false,
              });

            // ImThread
            v1Db.createObjectStore('viewImThreads', { keyPath: 'id' });
            v1Tx.objectStore('viewImThreads').createIndex('by-account', 'account_id', {
              unique: false,
            });
            v1Tx.objectStore('viewImThreads').createIndex('by-date', 'last_update', {
              unique: false,
            });
            v1Tx.objectStore('viewImThreads').createIndex('by-account-and-provider-id', ['account_id', 'provider_id'], {
              unique: true,
            });
            v1Tx.objectStore('viewImThreads').createIndex('by-attendee-identifier', 'attendees_identifiers', {
              unique: false,
              multiEntry: true,
            });

            // Call
            v1Db.createObjectStore('viewCalls', { keyPath: 'id' });
            v1Tx.objectStore('viewCalls').createIndex('by-account', 'account_id', { unique: false });
            v1Tx.objectStore('viewCalls').createIndex('by-date', 'date', { unique: false });
            v1Tx.objectStore('viewCalls').createIndex('by-account-and-date-and-type', ['account_id', 'date', 'type'], {
              unique: false,
            });

            // Mail
            v1Db.createObjectStore('viewMails', { keyPath: 'id' });
            v1Tx.objectStore('viewMails').createIndex('by-type', 'type', { unique: false });
            v1Tx.objectStore('viewMails').createIndex('by-account', 'account_id', { unique: false });
            v1Tx.objectStore('viewMails').createIndex('by-date', 'date', { unique: false });
            v1Tx.objectStore('viewMails').createIndex('by-account-and-date', ['account_id', 'date'], {
              unique: false,
            });

            // MailDraft
            v1Db.createObjectStore('viewMailDrafts', { keyPath: 'id' });
            v1Tx.objectStore('viewMailDrafts').createIndex('by-type', 'type', { unique: false });
            v1Tx.objectStore('viewMailDrafts').createIndex('by-account', 'account_id', {
              unique: false,
            });
            v1Tx.objectStore('viewMailDrafts').createIndex('by-date', 'update_date', {
              unique: false,
            });
            v1Tx.objectStore('viewMailDrafts').createIndex('by-parent-and-type', ['parent_mail_id', 'type'], { unique: true });

            v1Db.createObjectStore('localFiles', { keyPath: 'id' });

            v1Db.createObjectStore('keys', { keyPath: 'topic' });
            v1Db.createObjectStore('credentials', { keyPath: 'id' });

            // Signature
            v1Db.createObjectStore('viewSignatures', { keyPath: 'id' });

            // MailCache
            v1Db.createObjectStore('mailCache', { keyPath: 'id' });
            v1Tx.objectStore('mailCache').createIndex('by-account', 'account_id', { unique: false });

            // MailMeta
            v1Db.createObjectStore('mailMeta', { keyPath: 'id' });
            v1Tx.objectStore('mailMeta').createIndex('by-date', 'date', { unique: false });
            v1Tx.objectStore('mailMeta').createIndex('by-account', 'account_id', { unique: false });
          }
          if (oldVersion < 2) {
            const v2Db = db as unknown as IDBPDatabase<DBSchemaV2>;
            const v2Tx = tx as unknown as IDBPTransaction<DBSchemaV2, StoreNames<DBSchemaV2>[], 'versionchange'>;
            // Tag
            v2Db.createObjectStore('viewTags', { keyPath: 'id' });

            // TagRelation
            v2Db.createObjectStore('viewTagRelations', { keyPath: 'id' });
            v2Tx.objectStore('viewTagRelations').createIndex('by-element', ['element', 'element_id'], { unique: false });
            v2Tx.objectStore('viewTagRelations').createIndex('by-tag-id', 'tag_id', { unique: false });
          }
          if (oldVersion < 3) {
            const v3Db = db as unknown as IDBPDatabase<DBSchemaV3>;
            const v3Tx = tx as unknown as IDBPTransaction<DBSchemaV3, StoreNames<DBSchemaV3>[], 'versionchange'>;
            /** Remove debug indexes. */
            v3Tx.objectStore('domainEvents').deleteIndex('by-type');
            v3Tx.objectStore('domainEvents').deleteIndex('by-branch');
          }
          if (oldVersion < 4) {
            const v4Db = db as unknown as IDBPDatabase<DBSchemaV4>;
            v4Db.createObjectStore('keyCanary', { keyPath: 'branch' });
          }
          if (oldVersion < 5) {
            db.createObjectStore('viewMessageModels', { keyPath: 'id' });
          }
        },
      });
      Idb.addEventListener('close', (e) => console.warn('Idb connection closing'));
      Idb.addEventListener('error', (e) => console.warn('Idb error', e));
    } catch (e) {
      /**
       * @note Error type will most likely be a DOMException ?
       *       If that's the case e.name can help pinpoint the kind of error,
       *       see https://developer.mozilla.org/en-US/docs/Web/API/IDBRequest/error
       *
       *       The main purpose of the IdbInitializationError wrapper is to send
       *       an easier to identify error upstream for ErrorRecommendations.
       *       The main use case is for VersionError where a localReset should
       *       be suggested.
       */
      if (e instanceof Error) {
        throw new IdbInitializationError(`${e.name} : ${e.message}`);
      } else if (typeof e === 'string') {
        throw new IdbInitializationError(e);
      } else {
        throw new IdbInitializationError('Unclear or no message in original error.');
      }
    }
  }
  return Idb;
}

/**
 *
 */
export async function clearIdbDomain(): Promise<void> {
  if (Idb) {
    const tx = Idb.transaction(
      [
        'domainEvents',
        'ledger',
        'backup',
        'viewCalendarEvents',
        'recurringCalendarEvent',
        'viewCalendars',
        'viewNotifications',
        'viewContacts',
        'viewAccounts',
        'viewImThreads',
        'viewIms',
        'viewCalls',
        'viewMails',
        'viewMailDrafts',
        'viewSignatures',
        'viewTags',
        'viewTagRelations',
        // "localFiles" NOT cleared on purpose !
        'keys',
        'keyCanary',
        'credentials',
      ],
      'readwrite'
    );

    /** Clear all domain related stores. */
    await Promise.all(
      [...tx.objectStoreNames].map((storeName) => {
        tx.objectStore(storeName).clear();
      })
    );
    /** Create a new branch. */
    tx.objectStore('backup').put({ branch: createUuid(), cursors: undefined });
    return tx.done;
  }
}

export * from './schemas';
export * from './utils';
