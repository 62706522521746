import { ViewCalendarEvent } from '../../Calendar';
import { ViewNotification } from '../../Notification';

export type ActionId = 'reply' | 'transfer' | 'chat' | 'callback' | 'done' | 'plan' | 'replan' | 'write';

export type ActionContext = ViewNotification | ViewCalendarEvent;

export function isNotification(context: ActionContext): context is ViewNotification {
  return !!(context as ViewNotification).title;
}

export function isEvent(context: ActionContext): context is ViewCalendarEvent {
  return !!(context as ViewCalendarEvent).summary;
}
