import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ViewEventContainer from '../Event/ViewEventContainer';
import WorkContainer from '../Event/WorkContainer';
import WorkEventContainer from '../Event/WorkEventContainer';
import HeaderLeft from '../Header/HeaderLeft';
import Inbox from '../Notification/Inbox';
import NotificationPage from '../Notification/NotificationPage';

export default function MainArea() {
  return (
    <>
      <HeaderLeft />
      <div className="UniMainArea-container" id="tour-2">
        <Switch>
          <Route path="/work/:id" render={() => <WorkEventContainer />} />
          <Route path="/event/:id" render={() => <ViewEventContainer />} />
          <Route path="/work" render={() => <WorkContainer />} />
          <Route path="/inbox/:id" render={() => <NotificationPage />} />
          <Route path="/inbox" render={() => <Inbox />} />
          <Route path="/" render={() => <Redirect to="/inbox" />} />
        </Switch>
      </div>
    </>
  );
}
