import { Capacitor } from '@capacitor/core';
import { MailAttachment } from '../../domain';
import { convertBase64 } from '../../../Common/utils';
import { LocalFile } from '../../../Common';

interface Return {
  body: string;
  attachments: MailAttachment[];
}
const INLINE_IMG_EXT = ['JPG', 'JPEG', 'PNG', 'SVG', 'GIF', 'BMP'];

/**
 * Replace CID Images in an email by the base64 data found in the attachments and remove those images from it
 *
 * @param body The body of an email with images pointing to a cid type of url
 * @param attachments Attachmenrs of this email where the data of the images should be
 */
export function cidReplacement(body: string, attachments: MailAttachment[], files: LocalFile[]): Return {
  return outlook({ body, attachments }, files);
}

function outlook({ body, attachments }: Return, files: LocalFile[]): Return {
  // Regex to find the src attribute of images with a cid url in a mail from Outlook
  const regex = /"cid:([^']*?)@[^']*?"/gm;
  let m: RegExpExecArray | null = null;

  let b = body;
  const a = [...attachments];

  // Loop over matches
  while ((m = regex.exec(body)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // Get the image name from the url match
    const img_name = m[1];

    // Find the image in the attachments
    const index = a.findIndex((att) => att.name === img_name);

    if (index >= 0) {
      const att = a[index];
      const file = files.find((file) => file.id === att.id);

      if (file) {
        // Replace the cid url by the base64 data of the image
        b = b.replace(m[0], getSrc(file));

        // Remove the image from the attachments
        a.splice(index, 1);
      }
    }
  }

  // Return updated body and attachemnts
  return {
    attachments: a,
    body: b,
  };
}

// function macOsMail({ body, attachments }: Return): Return {
//   /**
//    * Look if their is any other attachment than images
//    * In that case, just remove all inline images et let them as attachments
//    */
//   const not_only_images = attachments.find((att) => !INLINE_IMG_EXT.includes(att.extension)) !== undefined;

//   let b = body;
//   const a = attachments;

//   if (not_only_images) {
//     // Regex to find all img tag with cid url as src value
//     const regex = /<img[^']*?src="cid:([^']*?)"[^']*?>/gm;
//     let m: RegExpExecArray;

//     // @TODO Body empty in thise case, to fix on backend

//     return {
//       attachments,
//       body,
//     };
//   } else {
//     const regex = /"cid:([^']*?)"/gm;
//     let m: RegExpExecArray | null;

//     let index = 0;
//     // Loop over matches
//     while ((m = regex.exec(body)) !== null) {
//       // This is necessary to avoid infinite loops with zero-width matches
//       if (m.index === regex.lastIndex) {
//         regex.lastIndex++;
//       }

//       const att = attachments[index];

//       // Replace the cid url by the base64 data of the image
//       if (att) {
//         b = b.replace(m[0], getSrc(att)); // Remove the image from the attachments
//         a.splice(index, 1);
//       }

//       index++;
//     }

//     // Return updated body and attachemnts
//     return {
//       attachments: a,
//       body: b,
//     };
//   }
// }

function getSrc(file: LocalFile): string {
  // On Mobile, convert the url to something understandable by the webview
  if (file.data?.startsWith('file://')) return Capacitor.convertFileSrc(file.data);
  // On Web, convert the base64 data to a format readable by the img tag src fileribute
  else return `"data:${file.mime}; base64,${convertBase64(file.data || '')}"`;
}
