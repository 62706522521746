import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EventDocument,
  SearchResult,
  getAllPastEvents,
  selectSearchResult,
  useAppDispatch,
  Uuid,
  getAllFutureEvents,
} from '@focus-front/core';
import AllListContainer, { AllListProps } from './AllListContainer';
import { Virtuoso } from 'react-virtuoso';
import { EventSearchResult } from '../../Event/EventSearchResult';
import EventSearchResultFake from '../../Event/EventSearchResultFake';
import { calendarOutline } from 'ionicons/icons';
import NoResults from './NoResults';

export default function AllEventsList({ onBack }: Pick<AllListProps, 'onBack'>) {
  const START_INDEX = 10000;

  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX);
  const [events, setEvents] = useState<(SearchResult<EventDocument> & { index: number })[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const pastOffset = useRef(0);
  const futureOffset = useRef(0);

  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      // We do not call loadMorePast and loadMoreFuture because setEvents on load must be trigger the render once
      const past = await dispatch(getAllPastEvents(50, pastOffset.current));
      const future = await dispatch(getAllFutureEvents(50, futureOffset.current));
      setEvents([
        ...past
          .map((event, i) => ({
            item: event,
            matches: [],
            refIndex: 0,
            index: START_INDEX - pastOffset.current - i,
          }))
          .reverse(),
        ...future.map((event, i) => ({
          item: event,
          matches: [],
          refIndex: 0,
          index: START_INDEX + futureOffset.current + i,
        })),
      ]);
      setFirstItemIndex(firstItemIndex - past.length);
      pastOffset.current += past.length;
      futureOffset.current += future.length;
      setLoading(false);
    })();
  }, []);

  const loadMorePast = async () => {
    const res = await dispatch(getAllPastEvents(50, pastOffset.current));
    setEvents([
      ...res
        .map((event, i) => ({
          item: event,
          matches: [],
          refIndex: 0,
          index: START_INDEX - pastOffset.current - i,
        }))
        .reverse(),
      ...events,
    ]);
    setFirstItemIndex(firstItemIndex - res.length);
    pastOffset.current += res.length;
  };

  const loadMoreFuture = async () => {
    const res = await dispatch(getAllFutureEvents(50, futureOffset.current));
    setEvents([
      ...events,
      ...res.map((event, i) => ({
        item: event,
        matches: [],
        refIndex: 0,
        index: START_INDEX + futureOffset.current + i + 1,
      })),
    ]);
    futureOffset.current += res.length;
  };

  return (
    <AllListContainer title={t('search.event_plural')} onBack={onBack}>
      {loading && (
        <>
          <EventSearchResultFake />
          <EventSearchResultFake />
          <EventSearchResultFake />
          <EventSearchResultFake />
          <EventSearchResultFake />
          <EventSearchResultFake />
        </>
      )}
      {!loading && events.length > 0 && (
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={events.length}
          firstItemIndex={firstItemIndex}
          initialTopMostItemIndex={45}
          data={events}
          overscan={500}
          endReached={loadMoreFuture}
          startReached={loadMorePast}
          itemContent={(index, event) => (
            <EventSearchResult
              index={index}
              key={index}
              event={event}
              onClick={() => dispatch(selectSearchResult({ id: event.item.id as Uuid, type: 'event' }))}
            />
          )}
        />
      )}
      {!loading && !events.length && <NoResults icon={calendarOutline} label={t('search.event_plural')} />}
    </AllListContainer>
  );
}
