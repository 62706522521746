import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { refreshSync, setCalendarSync, useAppDispatch, ViewCalendar } from '@focus-front/core';
import { Box, Button, Typography } from '@material-ui/core';

import AccountSyncCalendarList from '../Settings/AccountSyncCalendarList';
import { WizardContext } from './WizardContext';
import { NextButton } from './NavButton';

export default function GoogleCalendarSync() {
  const { goBack, goNext, accountId } = useContext(WizardContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  //   useEffect(() => {
  //     (async () => {})();
  //   }, []);

  const handleEnd = () => {
    dispatch(refreshSync());
    goNext();
  };

  const handleCalendarSwitch = async (calendar: ViewCalendar) => {
    if (calendar.type === 'GOOGLE') {
      /** Toggle. */
      dispatch(setCalendarSync(calendar.id, !calendar.sync_activated));
    }
  };

  return (
    <>
      <Typography variant="h2">{t('wizard.sync.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.sync.subtitle')}</Typography>
      <Box mt={3} mb={5}>
        <Typography variant="body1">{t('wizard.sync.label')}</Typography>
        <AccountSyncCalendarList handleCalendarSwitch={handleCalendarSwitch} accountId={accountId} />
      </Box>
      <NextButton onClick={goNext} />
    </>
  );
}
