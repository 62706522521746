import { loginWhatsApp, useAppDispatch } from '@focus-front/core';
import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { WhatsAppLoginForm, WizardContext } from './WizardContext';

export default function WhatsAppLogin() {
  const { t } = useTranslation();

  const { goBack, forms, setLoading, setForms, goNext } = useContext(WizardContext);
  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    setLoading(true);

    const { error, result } = await dispatch(loginWhatsApp());

    if (error) {
      alert(error);
      setLoading(false);
    } else {
      setForms({ ...forms, WHATSAPP_LOGIN: { whatsapp_phone_number: result.identifier, session: result.session } });
      goNext();
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.whatsAppLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.whatsAppLogin.subtitle')}</Typography>
      <NextButton onClick={handleLogin}>{t('wizard.whatsAppLogin.loginBtn')}</NextButton>
    </div>
  );
}
