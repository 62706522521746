import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/web/WebContainer';
import {
  initCalendarCron,
  initCallCron,
  initImCron,
  initMailCron,
  initMailMetaSync,
  initRetryCron,
  loadAccounts,
  loadSources,
} from '../../Account';
import { getCalendars, initNotifyMissedEvents, loadWorkEvents } from '../../Calendar';
import { Image, Result } from '../../Common';
import i18n from '../../Common/infra/services/i18nextService';
import { initSearch } from '../../Search';
import { getAllTags } from '../../Tag';
import { UserSession } from '../domain';
import { initSubscriptionCheckCron } from './subscription.slice';
import { SingleOrArray, sleep } from '../../Common/utils';

interface SessionState {
  session: UserSession | null;
  /**
   * This loading status represent the initialization of the app for a session, after the actual login
   */
  loading: boolean;
  initProgress: string[];
  loadingLogout: boolean;
  error: string | null;
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: { session: null, loading: true, initProgress: [], error: null, loadingLogout: false } as SessionState,
  reducers: {
    updateSession: (state, { payload }: PayloadAction<UserSession>) => {
      state.session = payload;
    },
    loginDone: (state, { payload }: PayloadAction<UserSession>) => {
      state.error = null;
      state.session = payload;
      state.loading = true;
    },
    initDone: (state) => {
      state.loading = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
      state.session = null;
    },
    initProgress: (state, { payload }: PayloadAction<SessionState['initProgress']>) => {
      state.initProgress = payload;
    },
    logoutDone: (state) => {
      state.session = null;
      state.loadingLogout = false;
    },
    logout: (state) => {
      state.loadingLogout = true;
    },
    logoutError: (state) => {
      state.loadingLogout = false;
    },
  },
});

export const { loginDone, updateSession, initDone, initError, initProgress, logout, logoutDone, logoutError } =
  sessionSlice.actions;

/**
 * Initialize the app state for a session during the login screen
 */
export const setupSession =
  (login_password?: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    const onProgress = (messages: SingleOrArray<string>) => {
      dispatch(initProgress(Array.isArray(messages) ? messages : [messages]));
    };
    const { error } = await user.initializeAppForSession(login_password, onProgress);

    if (error) {
      dispatch(initError(error));
      return;
    }

    onProgress?.('login.steps.loadAccounts');
    await dispatch(loadAccounts());
    // await sleep(1000);

    onProgress?.('login.steps.loadSources');
    await dispatch(loadSources());
    // await sleep(1000);

    onProgress?.('login.steps.getCalendars');
    await dispatch(getCalendars());
    // await sleep(1000);

    onProgress?.('login.steps.getSession');
    await dispatch(loadWorkEvents());
    // await sleep(1000);

    onProgress?.([
        'login.steps.getTags', 
        'login.steps.notifyMissedEvents', 
        'login.steps.syncMailMeta',
        'login.steps.initMailCron',
        'login.steps.initCallCron',
        'login.steps.initImCron',
        'login.steps.initCalendarCron',
        'login.steps.initSearch',
        'login.steps.initSubscriptionCron',
        'login.steps.initRetryCron',
    ]);
    await Promise.all([
      dispatch(getAllTags()),
      dispatch(initNotifyMissedEvents()),
      dispatch(initMailMetaSync()),
      dispatch(initMailCron()),
      dispatch(initCallCron()),
      dispatch(initImCron()),
      dispatch(initCalendarCron()),
      dispatch(initSearch()),
      dispatch(initSubscriptionCheckCron()),
      dispatch(initRetryCron()),
    ]);
    // await sleep(1000);

    dispatch(initDone());
  };

export const loginUser =
  (username: string, password: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<string | void> => {
    const { result, error } = await user.login(username, password);
    if (error) return error;
    if (result) {
      dispatch(loginDone(result));
      dispatch(setupSession(password));
    }
  };

export const recoverSession =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<boolean> => {
    const { result, error } = await user.recoverSession();
    if (error) dispatch(initError(error));
    if (result) {
      dispatch(loginDone(result));
      await dispatch(setupSession());
      return true;
    }
    dispatch(initDone());
    return false;
  };

export const setupAnonymous =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    await user.initAnonymous();
  };

export const updateUser =
  (first_name: string, last_name: string, profile_picture?: Image | null) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    const updated_session = await user.updateProfile(first_name, last_name, profile_picture);
    dispatch(updateSession(updated_session));
  };

export const completeOnboarding =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    const updated_session = await user.completeOnboarding();
    dispatch(updateSession(updated_session));
  };

export const completeTour =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    const updated_session = await user.completeTour();
    dispatch(updateSession(updated_session));
  };

export const logoutUser =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    dispatch(logout());
    const { error } = await user.logout();
    if (error) {
      dispatch(logoutError());
      alert(error);
    } else {
      dispatch(logoutDone());
      dispatch(resetSession());
    }
  };

export const requireLogin =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    dispatch(logout());
    const { error } = await user.logout();
    if (error) {
      dispatch(logoutError());
      alert(error);
    } else {
      dispatch(logoutDone());
      dispatch(resetSession());
      dispatch(initError(i18n.t('error.session_end')));
    }
  };

export const registerUser =
  (firstname: string, lastname: string, email: string, password: string, referral_code?: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<string | undefined> => {
    const { error } = await user.register(firstname, lastname, email, password, referral_code);
    return error;
  };

export const getAdminLink =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<Result<string>> => {
    return user.getAdminLink();
  };

export const sendFeedback =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { logger }: Container): Promise<void> => {
    logger.sendFeedback();
  };

export const sessionSelector = (state: { session: SessionState }) => {
  return state.session.session;
};

export const sessionStateSelector = (state: { session: SessionState }) => {
  return state.session;
};

export const resetSession = createAction('SESSION_RESET');

export default sessionSlice.reducer;
