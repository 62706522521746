import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import MailSearchResult from '../Mail/MailSearchResult';
import MailSearchResultFake from '../Mail/MailSearchResultFake';
import { SearchTabProps, useClasses } from './SearchTabProps';
import { mailResultsSelector, scrollResults, selectSearchResult, Uuid, useAppDispatch } from '@focus-front/core';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, debounce, makeStyles } from '@material-ui/core';
import NoResults from './NoResults';
import { Virtuoso } from 'react-virtuoso';

export function MailSearchTab() {
  const c = useClasses();
  const { results, scroll, status } = useSelector(mailResultsSelector);
  const dispatch = useAppDispatch();

  const handleScroll = () => {
    const firstRendered = document.querySelector('#search-results > div > div > div');
    if (firstRendered) dispatch(scrollResults({ tab: 'mail', scroll: Number(firstRendered.getAttribute('data-index')) }));
  };

  if (status === 'LOADING')
    return (
      <div className={c.wrapper}>
        <MailSearchResultFake />
        <MailSearchResultFake />
        <MailSearchResultFake />
        <MailSearchResultFake />
      </div>
    );
  if (!results.length) return <NoResults />;

  return (
    <div className={c.wrapper}>
      <Virtuoso
        style={{ height: '100%' }}
        totalCount={results.length}
        initialTopMostItemIndex={scroll}
        onScroll={debounce(handleScroll, 100)}
        id="search-results"
        itemContent={(index) => (
          <MailSearchResult
            index={index}
            mail={results[index]}
            onClick={() =>
              dispatch(
                selectSearchResult({ id: results[index].item.id, type: results[index].item.draft ? 'mail_draft' : 'mail' })
              )
            }
          />
        )}
      />
    </div>
  );
}

export default memo(MailSearchTab, (prev, next) => true);
