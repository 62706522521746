import {
  inboxSortSelector,
  NotificationType,
  setTagFilter,
  setTypeFilter,
  toggleSort,
  useAppDispatch,
  ViewTag,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Badge, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { arrowDown, arrowUp, caretDown, caretDownSharp, chevronDown, filter, pricetagsOutline } from 'ionicons/icons';
import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FilterByAccount from '../Common/Filters/FilterByAccount';
import FilterByTag from '../Common/Filters/FilterByTag';
import FilterByType from '../Common/Filters/FilterByType';
import TagPicker from '../Tag/TagPicker';
import { NavButton } from '../Wizard/NavButton';
import NotificationTypePicker from './NotificationTypePicker';

const useClasses = makeStyles((theme) => ({
  label: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    paddingLeft: 0,
    color: theme.palette.grey[700],
  },
}));

function InboxFilters() {
  const dispatch = useAppDispatch();
  const sort = useSelector(inboxSortSelector);
  const { t } = useTranslation();
  return (
    <div>
      <FilterByAccount />
      <FilterByType />
      <FilterByTag />
      <div style={{ marginLeft: 10 }}>
        <NavButton onClick={() => dispatch(toggleSort())} endIcon={<IonIcon icon={sort === 'DSC' ? arrowDown : arrowUp} />}>
          {t('inbox.sort' + sort)}
        </NavButton>
      </div>
    </div>
  );
}

export default React.memo(InboxFilters);
