import dayjs from 'dayjs';
import {
  Publisher,
  UTCDate,
  LoggerService,
  toUTCDateTimeMs,
  UTCDateTimeMs,
  Uuid,
  toUTCDate,
  toUTCDateTime,
  InvalidAccountError,
  DomainEvent,
  DomainEventStore,
} from '../../../Common';
import { CalendarRepo } from '../repository/CalendarRepo';
import { CalendarEventRepo } from '../repository/CalendarEventRepo';
import { ViewCalendarRepo } from '../../domain/projections/ViewCalendarRepo';
import { ViewCalendarEventRepo } from '../../domain/projections/ViewCalendarEventRepo';
import { AccountRepo, ViewAccountRepo, isCalendarSupportedAccount, AccountSourceStatus } from '../../../Account';
import { CalendarServicesMap } from '../../../_container/interfaces/Container';
import { CalendarEventListResult, CalendarListResult } from './CalendarService';
import { InvalidCredentialsError, MissingCredentialsError } from '@focus-front/proxy';
import { Calendar, CalendarEvent, ExternalCalendar, ViewCalendar, GoogleCalendarEvent } from '../../domain';
import {
  DeleteGoogleCalendarEventExceptionDTO,
  GoogleCalendarEventExceptionDTO,
} from '../../domain/types/CalendarEventExceptionDTO';
import { GoogleSyncTokenInvalidatedError } from './GcalApiClient';

/**
 * @todo Be careful about making this awaitable here and in a reactor if this
 *       is going to be debounced. How would that even work ? A promise on the
 *       debounced call ? How about keeping it "fire and forget" ?
 *
 * @todo Bench against a MemoryViewCalendarEventRepo to help see if stutters on
 *       initialPulls are Idb related.
 *
 * @todo Make this handle other calendar type, right now there are lots of
 *       Google specific stuff. We need to at least be able to switch on type.
 *
 * @todo Consider storing collection etag on Calendar along with sync_token.
 */
export class CalendarSyncService {
  constructor(
    private readonly publisher: Publisher,
    private readonly eventStore: DomainEventStore,
    private readonly calendar: CalendarRepo,
    private readonly calendarEvent: CalendarEventRepo,
    private readonly account: AccountRepo,
    private readonly viewCalendar: ViewCalendarRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly calendarServices: CalendarServicesMap,
    private readonly viewAccount: ViewAccountRepo,
    private readonly logger: LoggerService
  ) {}

  /**
   * @todo Look for a nicer looking way to work in chunks than extracting the repetition.
   *       At least return DomainEvents without emitting if you're going the
   *       transaction + cherry-picking route.
   */
  private async _ackCalendars(account_id: Uuid, apiResult: CalendarListResult) {
    const ids: Uuid[] = [];

    /** Ignore deleted calendars for initial pull. */
    await Promise.all(
      apiResult.calendars.map(async (c) => {
        const resultAck = Calendar.acknowledge({
          account_id,
          ...c,
          sync_token: null,
          sync_activated: c.type === 'GOOGLE' && c.is_primary,
        });
        try {
          await this.publisher.emit(resultAck.changes);
          /** @note Quieting compiler not being as strict as core with resultAck.ok === true. */
          if (resultAck.ok === true) {
            ids.push(resultAck.aggregate.id);
          }
        } catch (e) {
          if (e instanceof DOMException && e.name === 'ConstraintError') {
            console.log(`Calendar ${Calendar.getAggregateIdFromGoogleId(c.external_id, account_id)} already exists : `, e, c);
            return;
          }
          throw e;
        }
      })
    );
    return ids;
  }

  /**
   * @todo Consider if it's worth it to try to make the whole thing a transaction
   *       and cherry-pick already existing calendars/events on ConstraintError.
   *
   * @todo Extract all external sync related methods to CalendarSyncService class ?
   */
  async initialPullExternalCalendars(account_id: Uuid): Promise<{ calendars: ViewCalendar[]; status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (!account || !isCalendarSupportedAccount(account)) {
        throw new InvalidAccountError();
      }

      let apiResult: CalendarListResult;
      apiResult = await this.calendarServices[account.type].listCalendars(account_id);
      const ids = await this._ackCalendars(account_id, apiResult);

      while (apiResult.sync_token === null && apiResult.page_token) {
        apiResult = await this.calendarServices[account.type].listCalendars(account_id, { page_token: apiResult.page_token });
        ids.push(...(await this._ackCalendars(account_id, apiResult)));
      }

      /** Mark account fetched with the sync_token. */
      if (apiResult.sync_token) {
        const accountAgg = await this.account.getAggregate(account_id);
        const resultMark = accountAgg.markFetched({ type: 'GOOGLE_CALENDAR', sync_token: apiResult.sync_token });
        if (resultMark.ok) {
          await this.publisher.emit(resultMark.changes);
        }
        console.log('Mark account fetched with the sync_token.', account_id, resultMark);
      } else {
        throw new Error('todo ! Unhandled path in CalendarSyncService.initialExternalCalendarFetch : sync_token is null.');
      }
      return { calendars: await this.viewCalendar.getMany(ids), status: 'IDLE' };
    } catch (e) {
      if (e instanceof MissingCredentialsError || e instanceof InvalidCredentialsError) {
        return { calendars: [], status: 'CREDENTIALS' };
      }

      /** @todo Retry Account.markFetched if the aggregate was updated concurrently. */
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService.initialPullExternalCalendars :\n' +
            'Aggregate was updated concurrently before Account.markFetched result was comitted.\n' +
            'You should implement retry.',
          e
        );
      }

      this.logger.captureException(e);
      return { calendars: [], status: 'ERROR' };
    }
  }

  /**
   * @todo Look for a nicer looking way to work in chunks than extracting the repetition.
   *       At least return DomainEvents without emitting if you're going the
   *       transaction + cherry-picking route.
   */
  private async _processCalendars(account_id: Uuid, apiResult: CalendarListResult) {
    const changes: DomainEvent[] = [];

    console.log('>>> _processCalendars', account_id, apiResult);

    await Promise.all(
      apiResult.calendars.map(async (c) => {
        /**
         * Fetch aggregates of known calendars appearing in API results.
         *
         * @note Using this.eventStore directly rather than this.calendarEvent.getAggregate to
         *       avoid having to deal with exceptions.
         */
        const timeline = await this.eventStore.getByAggregate(Calendar.getAggregateIdFromGoogleId(c.external_id, account_id));

        /** Known calendar. */
        if (timeline.length) {
          const knownCalendar = new Calendar(timeline);
          /** @todo Consider diffing patch here or maybe in acknowledgePatch command ? */
          const { external_id, ...patch } = c;
          const resultPatch = knownCalendar.acknowledgePatch(patch);
          /** @note Quieting compiler not being as strict as core with resultPatch.ok === true. */
          if (resultPatch.ok === true) {
            changes.push(...resultPatch.changes);
            return;
          }

          /** Restored calendar. */
          if (resultPatch.reason === 'DELETED') {
            switch (c.type) {
              case 'GOOGLE': {
                /** @todo Consider retrieving previous sync_token ? Does it even make sense ?  */
                const resultRestore = knownCalendar.acknowledgeRestore({
                  account_id,
                  ...c,
                  sync_token: null,
                  sync_activated: true,
                });
                changes.push(...resultRestore.changes);
                return;
              }
              case 'OUTLOOK':
                /** Not yet supported. */
                return;
            }
          }
          return;
        }

        /** Unknown calendar. */
        const resultAck = Calendar.acknowledge({ account_id, ...c, sync_token: null, sync_activated: true });
        changes.push(...resultAck.changes);
      })
    );

    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._processCalendars : ' +
            'One or more Calendar or patch event already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
  }

  /**
   * @todo Look for a nicer looking way to work in chunks than extracting the repetition.
   *       At least return DomainEvents without emitting if you're going the
   *       transaction + cherry-picking route.
   */
  private async _processCalendarsDeleted(account_id: Uuid, apiResult: CalendarListResult) {
    const changes: DomainEvent[] = [];

    console.log('>>> _processCalendarsDeleted', account_id, apiResult);

    await Promise.all(
      apiResult.deleted.map(async (c) => {
        const timeline = await this.eventStore.getByAggregate(Calendar.getAggregateIdFromGoogleId(c.external_id, account_id));

        /** Known calendar. */
        if (timeline.length) {
          const knownCalendar = new Calendar(timeline);
          const resultDelete = knownCalendar.acknowledgeDelete(c.etag);
          changes.push(...resultDelete.changes);
        }
        /** Unknown calendar ignored. */
      })
    );

    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._processCalendarsDeleted : ' +
            'One or more delete or etag ack events already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
  }

  /**
   * @todo Consider that you may want to warn the user before deleting an external calendar as a
   *       result of a pull. There are extras unipile-only data grafted on these calendars and
   *       associated events. This is something we can discuss and rethink later, e.g. : delete
   *       automatically and offer a 'restore in unipile' option. Altough event sourcing makes this
   *       possible, this offer may expire depending on how aggressive we get with snapshots and
   *       pruning.
   */
  async pullExternalCalendars(account_id: Uuid): Promise<{ status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (!account || !isCalendarSupportedAccount(account)) {
        throw new InvalidAccountError();
      }

      if (account.sync_token === undefined) {
        /** Something bad happened before AccountSynced was committed. */
        return this._pullExternalCalendarsMissingOrInvalidatedToken(account_id);
      }

      let apiResult: CalendarListResult;
      apiResult = await this.calendarServices[account.type].listCalendarChanges(account_id, { sync_token: account.sync_token });

      /** Abort if there is no change to process. */
      if (!(apiResult.calendars.length || apiResult.deleted.length)) {
        // console.warn('>>> No changes, aborting pullExternalCalendars.', account_id, apiResult);
        return { status: 'IDLE' };
      }

      /** Process calendars. */
      await this._processCalendars(account_id, apiResult);
      /** Process deleted. */
      await this._processCalendarsDeleted(account_id, apiResult);

      /** Next chunks. */
      while (apiResult.sync_token === null && apiResult.page_token) {
        apiResult = await this.calendarServices[account.type].listCalendarChanges(account_id, {
          page_token: apiResult.page_token,
          sync_token: account.sync_token,
        });
        await this._processCalendars(account_id, apiResult);
        await this._processCalendarsDeleted(account_id, apiResult);
      }

      /** Mark account fetched with the sync_token. */
      if (apiResult.sync_token) {
        const accountAgg = await this.account.getAggregate(account_id);
        const resultMark = accountAgg.markFetched({ type: 'GOOGLE_CALENDAR', sync_token: apiResult.sync_token });
        if (resultMark.ok) {
          await this.publisher.emit(resultMark.changes);
        }
        console.log('Mark account fetched with the sync_token.', account_id, resultMark);
      } else {
        throw new Error('todo ! Unhandled path in CalendarSyncService.pullExternalCalendars : sync_token is null.');
      }
      return { status: 'IDLE' };
    } catch (e) {
      /**
       * @todo Consider that GoogleSyncTokenInvalidatedError may happen during any chunk.
       *       Does it make any difference ? It shouldn't.
       *       If a 'full resync' is needed, it doesn't really matter where we stopped.
       */
      if (e instanceof GoogleSyncTokenInvalidatedError) {
        return this._pullExternalCalendarsMissingOrInvalidatedToken(account_id);
      }
      if (e instanceof MissingCredentialsError || e instanceof InvalidCredentialsError) {
        return { status: 'CREDENTIALS' };
      }

      /** @todo Retry Account.markFetched if the aggregate was updated concurrently. */
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService.pullExternalCalendars :\n' +
            'Aggregate was updated concurrently before Account.markFetched result was committed.\n' +
            'You should implement retry.',
          e
        );
      }

      this.logger.captureException(e);
      return { status: 'IDLE' };
    }
  }

  /**
   * @todo Look for a nicer looking way to work in chunks than extracting the repetition.
   *       At least return DomainEvents without emitting if you're going the
   *       transaction + cherry-picking route.
   *
   * @todo Find a nicer way to connect an external_id to agg id, start with
   *       with adding indexes on viewCalendarEvent, recurringCalendarEvent stores.
   *
   * @note To determine if we should apply StatusOptions to recurring events
   *       the heuristic looks different than what you would do on a single event.
   *       It's on purpose. As long as the original recurring event as starts
   *       before the cutoff date we apply StatusOptions and let the finer grained
   *       calculation be done later.
   */
  private async _initialAckEvents(
    account_id: Uuid,
    calendar_id: Uuid,
    apiResult: CalendarEventListResult,
    completeEventsBefore: UTCDateTimeMs
  ) {
    /** Emit all at once. */
    const recurringEvents = new Map<string, CalendarEvent>();
    const changes: DomainEvent[] = [];
    apiResult.events.forEach((e) => {
      const resultAck = CalendarEvent.acknowledge(
        e.kind === 'RECURRING'
          ? {
              account_id,
              calendar_id,
              ...e,
              ...(e.start < completeEventsBefore && { statusOptions: [{ status: 'DONE', to: completeEventsBefore }] }),
            }
          : {
              account_id,
              calendar_id,
              ...e,
              ...(e.end < completeEventsBefore && { status: 'DONE' }),
            }
      );

      changes.push(...resultAck.changes);
      /** @note Quieting compiler not being as strict as core with resultAck.ok === true. */
      if (resultAck.ok === true && e.kind === 'RECURRING' && e.external_id) {
        recurringEvents.set(e.external_id, resultAck.aggregate);
      }
    });
    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._initialAckEvents : ' +
            'One or more CalendarEvent already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
    return recurringEvents;
  }

  /**
   * Process exceptions.
   *
   * @todo Find a way to return and emit all events in one go.
   */
  private async _initialProcessExceptions(
    exceptions: GoogleCalendarEventExceptionDTO[],
    recurringEvents: Map<string, CalendarEvent>
  ) {
    /** Emit all at once. */
    const changes: DomainEvent[] = [];
    exceptions.forEach((e) => {
      if (e.external_recurring_event_id && e.external_id) {
        const agg = recurringEvents.get(e.external_recurring_event_id);
        if (agg) {
          const result = agg.acknowledgeException(e);
          if (result.ok) {
            changes.push(...result.changes);
          }
          // return;
        } else {
          console.warn(
            'todo ! Unhandled path in CalendarSyncService.initialExternalEventFetch : Process exceptions.' +
              'agg is null, no known original recurring event for that exception. ' +
              'This probably related to a allDay recurring event split situation.',
            recurringEvents,
            e
          );
        }
      }
    });

    return this.publisher.emit(changes);
  }

  /**
   * Process exceptions_deleted.
   *
   * @todo Find a way to return and emit all events in one go.
   */
  private async _initialProcessExceptionsDeleted(
    exceptions_deleted: DeleteGoogleCalendarEventExceptionDTO[],
    recurringEvents: Map<string, CalendarEvent>
  ) {
    /** Emit all at once. */
    const changes: DomainEvent[] = [];
    exceptions_deleted.forEach((e) => {
      if (e.external_recurring_event_id && e.external_id) {
        const agg = recurringEvents.get(e.external_recurring_event_id);
        if (agg) {
          const result = agg.acknowledgeInstanceDelete(e.original_start, e.etag, e.type);
          changes.push(...result.changes);
        } else {
          console.warn(
            'todo ! Unhandled path in CalendarSyncService.initialExternalEventFetch : Process exceptions_deleted.' +
              'agg is null, no known original recurring event for that exception. ' +
              'This probably related to a allDay recurring event split situation.',
            recurringEvents,
            e
          );
        }
      }
    });

    return this.publisher.emit(changes);
  }

  /**
   * @note For Google, the order of the events is an unspecified, stable order.
   *       This means we should process the exceptions after being done with all
   *       the events.
   *
   * @note Marking all instances projected before min_date as 'DONE' causes
   *       concurrency issues.
   *
   *       It relies on emitting a bunch of event and working
   *       with the result of their projection.
   *
   *       The issue arises when BackupService pushes these events and they are
   *       picked up by another device which marks the instance as 'MISSED'
   *       before we get to the step where we mark  the instance as 'DONE'.
   *
   *      What can we do ?
   *
   *      1. One big transaction
   *        a. Hold on to all events before emitting anything.
   *        b. Either have an ad hoc DecisionProjection to retrieve instances dates
   *           taking exceptions into account.
   *           Or extend Aggregate's DecisionProjection to be usable with
   *           CalendarEvent.getInstanceDates.
   *        c. Use CalendarEvent.getInstanceDates to decide which instances
   *           should be marked as 'DONE'.
   *
   *        -- Not working in chunks. It may be possible to break it down into
   *           multiple transactions but it would probably require to go to the
   *           last page of API results, store, sort, etc ...
   *
   *
   *      2. CalendarEventInstanceRangeDone
   *        Add a an event to mark a range of instances 'DONE', let the projectors
   *        sort it out.
   *
   *        ++ Obviate the problem of calculating right now which instances, taking
   *           exceptions into account, should be marked as 'DONE'.
   *        -- Doesn't help with the chunking problem.
   *        -- Having a single event vs possibly hundreds, sounds like a nice
   *           drive-by optimization. But it complicates the Aggregate's DecisionProjection
   *           and makes the command rejection based on the individual instance
   *           status really fuzzy or requiring new rules. Moreover, snapshots
   *           would make those kind of optimization irrelevant, globally.
   *
   *      3. Don't care about exceptions ( for now )
   *        a. Mark instances before min_date as 'DONE' as recurring event are ACKed
   *           using CalendarEvent.expandInstanceDates.
   *        b. Don't care about the rare-ish case where an exception moves an
   *           instance either side of the cut-off date, i.e. :
   *           possibly end up with 'DONE' instances after the cut-off,
   *           or 'PLANNED' instance before the cut-off.
   *
   *        ++ Much less work that covers 95%-ish of the cases.
   *        -- Trying to rectify and cover the few bad cases after the events
   *           are emitted reintroduces the concurrency issue.
   *
   *      4. Ask BackupService to wait
   *        a. Introduce some form of requestable lock on BackupService.
   *        b. Request lock before emitting anything.
   *        c. Release lock when done.
   *
   *        ++ Makes it look like one big commit from the POV of the whole distributed
   *           system.
   *        -- Introduce locks, having to deal with possible deadlocks, etc ...
   *        ++ Raises MANY questions about all the other places these concurrency
   *           issues can already/also arise :
   *
   *           Let's say we have one big perfect transaction for the whole thing.
   *           Pretend that BackupService can push it to the BagHolder in one go.
   *           Another device pull the whole thing, merges it.
   *           Projectionist.playMerged starts.
   *           Some cronJob fire during Projectionist.playMerged.
   *           Uh-oh ...
   *
   *           On one hand it's expected that the cronJob result can be different
   *           whether it happens before of after Projectionist.playMerged.
   *
   *           On the other hand it's weird and unexpected if the cronJob result
   *           is different depending on how late it fired during a Projectionist.playMerged
   *           of a sequence of events that was 'thought of as a transaction'.
   *
   *           The truth is probably that thinking of any sequence of events as
   *           a transaction for the whole distributed system is wrong.
   *           The natural grain of atomicity is a single event and trying to work
   *           around it is going to take some work.
   *
   *           Could we apply the idea of optimistic concurrency control to
   *           anything that works with a projection, i.e. : If the thing you're
   *           trying to do depends on a projection that is at step N but the store
   *           is at step N+3, it fails ?
   *           That's probably too coarse.
   *           How much of this is already covered by agg.version OCC ? This case
   *           is a bit different :
   *             - All relevant events are already in the store.
   *             - The command is issued based on a lagging projection.
   *             - Either the command is rejected because it violates an aggregate
   *               invariant or it's fair game !
   *
   *      5. Status option for creating/acking recurring events
   *
   *        Something like :
   *
   *          {
   *            // kind : 'RECURRING';
   *            [s in CalendarEventStatus]? : { start : UTCDateTimeMs; end : UTCDateTimeMs; };
   *          })
   *
   *        ++ Atomic.
   *        ++ Doesn't mess with aggregate invariants like CalendarEventInstanceRangeDone.
   *        -- More complex DecisionProjection.
   *        -- Covers 95%-ish but doesn't solve the exceptions problem.
   *
   *      6. Tweak BackupService debounce timeout
   *        A longer timeout and/or exponential backoff.
   *
   *        ++ Easy.
   *        ++ Mitigates the issue.
   *        -- Doesn't fix the underlying issue.
   *        -- May hide similar problems that will come back to bite us later.
   *
   *      7. Upgrade the crude refresh method.
   *        Current refresh method is pretty crude : we reload the whole page
   *        after a merge.
   *        This has the side effect of triggering a lot cron jobs and thus making
   *        this issue more prominent.
   *
   *        By using a finer-grained refresh method that doesn't trigger all
   *        cron jobs we can reduce the occurrence of the problem.
   *
   *        ++ Mitigates the issue.
   *        -- Doesn't fix the underlying issue.
   *        -- May hide similar problems that will come back to bite us later.
   *
   *
   * @todo Process exception and exceptions_deleted in chunks, accumulating them
   *       may be a problem for large accounts.
   *
   * @todo Consider that the recurringEvents agg refs you're holding may get outdated
   *       by the time you're reusing them. Another device may pick up on the first
   *       few chunks and do stuff and the current device can retrieve that in the
   *       interval. This means that you at least need to retry on OCC violation,
   *       i.e. retrieve agg from store and reissue the command. Otherwise some
   *       exception might get dropped.
   *
   * @note Let single events that start after min_date and end before 'now' through a
   *       and generate missed notifications.
   */
  async initialPullExternalEvents(
    { id: calendar_id, account_id, external_id, type }: ExternalCalendar,
    chunkSize = 1000
  ): Promise<{ status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (!account || !isCalendarSupportedAccount(account)) {
        throw new InvalidAccountError();
      }
      const min = dayjs().startOf('hour');
      const min_date = toUTCDateTime(min);
      const completeEventsBefore = toUTCDateTimeMs(min);

      let apiResult: CalendarEventListResult;
      apiResult = await this.calendarServices[account.type].listEvents(account_id, {
        calendar_external_id: external_id,
        min_date,
        max_results: chunkSize,
      });
      const { exceptions, exceptions_deleted } = apiResult;

      /** Process events. */
      const recurringEvents = await this._initialAckEvents(account_id, calendar_id, apiResult, completeEventsBefore);

      /** Next chunks while holding on to exceptions and exceptions_deleted. */
      while (apiResult.sync_token === null && apiResult.page_token) {
        apiResult = await this.calendarServices[account.type].listEvents(account_id, {
          calendar_external_id: external_id,
          min_date,
          page_token: apiResult.page_token,
          max_results: chunkSize,
        });
        exceptions.push(...apiResult.exceptions);
        exceptions_deleted.push(...apiResult.exceptions_deleted);
        for (const entry of await this._initialAckEvents(account_id, calendar_id, apiResult, completeEventsBefore)) {
          recurringEvents.set(...entry);
        }
      }

      await this._initialProcessExceptions(exceptions, recurringEvents);
      await this._initialProcessExceptionsDeleted(exceptions_deleted, recurringEvents);
      /** Ignore events_deleted. */

      /** Mark calendar synced with the sync_token. */
      if (apiResult.sync_token) {
        console.log('---- Mark calendar synced.', calendar_id, apiResult.sync_token);
        const calendarAgg = await this.calendar.getAggregate(calendar_id);
        const resultMark = calendarAgg.markSynced({ type, sync_token: apiResult.sync_token });
        if (resultMark.ok) {
          await this.publisher.emit(resultMark.changes);
        }
        console.log('Mark calendar synced with the sync_token.', calendar_id, resultMark, calendarAgg);
      } else {
        throw new Error('todo ! Unhandled path in CalendarSyncService.initialExternalCalendarFetch : sync_token is null.');
      }

      return { status: 'IDLE' };
    } catch (e) {
      if (e instanceof MissingCredentialsError || e instanceof InvalidCredentialsError) {
        return { status: 'CREDENTIALS' };
      }

      /** @todo Retry Calendar.markSynced if the aggregate was updated concurrently. */
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService.initialPullExternalEvents :\n' +
            'Aggregate was updated concurrently before Calendar.markSynced result was comitted.\n' +
            'You should implement retry.',
          e
        );
      }

      this.logger.captureException(e);
      return { status: 'ERROR' };
    }
  }

  /**
   * @todo Consider holding on to and returning recurringEvents aggs.
   *
   * @todo Consider retrieving all aggregates of known events appearing in API results ahead of time.
   *
   * @todo Consider adding a way to retrieve a 'disjoint' set of aggregates.
   */
  private async _processEvents(
    account_id: Uuid,
    calendar_id: Uuid,
    apiResult: CalendarEventListResult,
    completeEventsBefore: UTCDateTimeMs
  ) {
    const recurringEvents = new Map<string, CalendarEvent>();
    const changes: DomainEvent[] = [];

    await Promise.all(
      apiResult.events.map(async (e) => {
        /**
         * Fetch aggregates of known events appearing in API results.
         *
         * @note Using this.eventStore directly rather than this.calendarEvent.getAggregate to
         *       avoid having to deal with exceptions.
         */
        const timeline = await this.eventStore.getByAggregate(
          CalendarEvent.getAggregateIdFromGoogleId(e.external_id, calendar_id)
        );

        /** Known event. */
        if (timeline.length) {
          const knownEvent = new CalendarEvent(timeline);
          /** @todo Consider diffing patch here or maybe in acknowledgePatch command ? */
          const { external_id, ...patch } = e;
          const resultPatch = knownEvent.acknowledgePatch(patch);
          /** @note Quieting compiler not being as strict as core with resultPatch.ok === true. */
          if (resultPatch.ok === true) {
            changes.push(...resultPatch.changes);
            return;
          }

          /**
           * Restored event.
           *
           * @note Google seems to restore events to confirmed by default, even when
           *       they were set to tentative.
           */
          if (resultPatch.reason === 'DELETED') {
            switch (e.type) {
              case 'GOOGLE':
                if (e.google_status === 'confirmed' || e.google_status === 'tentative') {
                  const resultRestore = knownEvent.acknowledgeRestore({ account_id, calendar_id, ...e });
                  changes.push(...resultRestore.changes);
                  return;
                }
            }
          }
          return;
          // if (e.kind === 'RECURRING' && e.external_id) {
          //   recurringEvents.set(e.external_id, knownEvent);
          // }
        }

        /** Unknown event. */
        // const resultAck = CalendarEvent.acknowledge({ account_id, calendar_id, ...e });
        const resultAck = CalendarEvent.acknowledge(
          e.kind === 'RECURRING'
            ? {
                account_id,
                calendar_id,
                ...e,
                ...(e.start < completeEventsBefore && { statusOptions: [{ status: 'DONE', to: completeEventsBefore }] }),
              }
            : {
                account_id,
                calendar_id,
                ...e,
                ...(e.end < completeEventsBefore && { status: 'DONE' }),
              }
        );
        changes.push(...resultAck.changes);

        // /** @note Quieting compiler not being as strict as core with resultAck.ok === true. */
        // if (resultAck.ok === true && e.kind === 'SINGLE' && e.start < completeEventsBefore) {
        //   const resultComplete = resultAck.aggregate.complete(e.start, e.end);
        //   changes.push(...resultComplete.changes);
        // }

        //   /** @note Quieting compiler not being as strict as core with resultAck.ok === true. */
        //   if (resultAck.ok === true && e.kind === 'RECURRING' && e.external_id) {
        //     recurringEvents.set(e.external_id, resultAck.aggregate);
        //   }
      })
    );

    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._processEvents : ' +
            'One or more CalendarEvent or patch event already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
    return recurringEvents; // recurringEvents is always empty for now.
    // return [changes, recurringEvents];
  }

  /**
   * @todo Consider holding on to and returning recurringEvents aggs.
   *
   * @todo Consider retrieving all aggregates of known events appearing in API results ahead of time.
   *
   * @todo Consider adding a way to retrieve a 'disjoint' set of aggregates.
   */
  private async _processEventsDeleted(calendar_id: Uuid, apiResult: CalendarEventListResult) {
    const recurringEvents = new Map<string, CalendarEvent>();
    const changes: DomainEvent[] = [];

    await Promise.all(
      apiResult.events_deleted.map(async (e) => {
        /** @todo Double check this is enough to tell an event deleted or exception cancelled. */
        const timeline = await this.eventStore.getByAggregate(
          CalendarEvent.getAggregateIdFromGoogleId(e.external_id, calendar_id)
        );

        /** Known event. */
        if (timeline.length) {
          const knownEvent = new CalendarEvent(timeline);
          const resultDelete = knownEvent.acknowledgeDelete(e.etag);
          changes.push(...resultDelete.changes);
        }
        /** Unknown event ignored. */
      })
    );

    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._processEventsDeleted : ' +
            'One or more delete or etag ack events already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
    return recurringEvents; // recurringEvents is always empty for now.
    // return [changes, recurringEvents];
  }

  /**
   * @note There may be multiple exceptions for the same original recurring events !!!
   *       You cannot process all exceptions in parallel and emit generated events all
   *       at once if you don't hold refs to up to date aggregates.
   *
   *       This means you can either :
   *         - Start simple, emit as you go SEQUENTIALLY.
   *           Getting an aggregate, issueing a command, emitting changes is definitely NOT ATOMIC.
   *           If exceptions are processed in parallel, one process can emit changes rendering another
   *           aggregate 'obsolete' before that process changes are emitted and yielding random
   *           ConstraintError.
   *
   *         - Hold on to 'up to date' aggregates
   *           ( AND add retry logic for ConstraintError ! ) <-- this should be ok if the aggregate ref is shared.
   *           AND sort events before emitting because projector don't allow out of order events ( for now ).
   *
   *         - Group exceptions from the set you intend to parallelize and process by aggregate !

   * @todo Consider holding on to and returning recurringEvents aggs.
   *
   * @todo Consider retrieving all aggregates of known events appearing in API results ahead of time.
   *
   * @todo Consider adding a way to retrieve a 'disjoint' set of aggregates.
   */
  private async _processExceptionsParallel(calendar_id: Uuid, apiResult: CalendarEventListResult) {
    const recurringEvents = new Map<string, CalendarEvent>();
    const changes: DomainEvent[] = [];

    const exceptionsByAgg: Map<
      NonNullable<GoogleCalendarEvent['external_recurring_event_id']>,
      GoogleCalendarEventExceptionDTO[]
    > = new Map();

    /** Group exceptions by aggregate. */
    apiResult.exceptions.forEach((e) => {
      const value = exceptionsByAgg.get(e.external_recurring_event_id);
      if (!value) {
        exceptionsByAgg.set(e.external_recurring_event_id, [e]);
      } else {
        value.push(e);
      }
    });

    await Promise.all(
      [...exceptionsByAgg].map(async ([external_recurring_event_id, exceptions]) => {
        const timeline = await this.eventStore.getByAggregate(
          CalendarEvent.getAggregateIdFromGoogleId(external_recurring_event_id, calendar_id)
        );

        /** Known original recurring event. */
        if (timeline.length) {
          const knownEvent = new CalendarEvent(timeline);
          exceptions.forEach((e) => {
            /** New or patched exception. */
            const resultAckException = knownEvent.acknowledgeException(e);
            /** @note Quieting compiler not being as strict as core with resultPatch.ok === true. */
            if (resultAckException.ok === true) {
              changes.push(...resultAckException.changes);
              return;
            }

            /**
             * Restored exception.
             *
             * @note Google seems to restore events to confirmed by default, even when
             *       they were set to tentative.
             */
            if (resultAckException.reason === 'INSTANCE DELETED') {
              switch (e.type) {
                case 'GOOGLE':
                  if (e.google_status === 'confirmed' || e.google_status === 'tentative') {
                    const resultRestore = knownEvent.acknowledgeExceptionRestore(e);
                    changes.push(...resultRestore.changes);
                    return;
                  }
              }
            }
          });
        }
        /** Unknown original recurring event -> ignored. */
      })
    );

    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._processExceptionsParallel : ' +
            'One or more exceptions event already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
    return recurringEvents; // recurringEvents is always empty for now.
    // return [changes, recurringEvents];
  }

  /**
   * @note There may be multiple exceptions_deleted for the same original recurring events !!!
   *       You cannot process all exceptions in parallel and emit generated events all
   *       at once if you don't hold refs to up to date aggregates.
   *
   *       This means you can either :
   *         - Start simple, emit as you go SEQUENTIALLY.
   *           Getting an aggregate, issueing a command, emitting changes is definitely NOT ATOMIC.
   *           If exceptions_deleted are processed in parallel, one process can emit changes rendering another
   *           aggregate 'obsolete' before that process changes are emitted and yielding random
   *           ConstraintError.
   *
   *         - Hold on to 'up to date' aggregates
   *           ( AND add retry logic for ConstraintError ! ) <-- this should be ok if the aggregate ref is shared.
   *           AND sort events before emitting because projector don't allow out of order events ( for now ).
   *
   *         - Group exceptions_deleted from the set you intend to parallelize and process by aggregate !

   * @todo Consider holding on to and returning recurringEvents aggs.
   *
   * @todo Consider retrieving all aggregates of known events appearing in API results ahead of time.
   *
   * @todo Consider adding a way to retrieve a 'disjoint' set of aggregates.
   */
  private async _processExceptionsDeletedParallel(calendar_id: Uuid, apiResult: CalendarEventListResult) {
    const recurringEvents = new Map<string, CalendarEvent>();
    const changes: DomainEvent[] = [];

    const exceptionsByAgg: Map<
      NonNullable<GoogleCalendarEvent['external_recurring_event_id']>,
      DeleteGoogleCalendarEventExceptionDTO[]
    > = new Map();

    /** Group exceptions_deleted by aggregate. */
    apiResult.exceptions_deleted.forEach((e) => {
      const value = exceptionsByAgg.get(e.external_recurring_event_id);
      if (!value) {
        exceptionsByAgg.set(e.external_recurring_event_id, [e]);
      } else {
        value.push(e);
      }
    });

    await Promise.all(
      [...exceptionsByAgg].map(async ([external_recurring_event_id, exceptions_deleted]) => {
        const timeline = await this.eventStore.getByAggregate(
          CalendarEvent.getAggregateIdFromGoogleId(external_recurring_event_id, calendar_id)
        );

        /** Known original recurring event. */
        if (timeline.length) {
          const knownEvent = new CalendarEvent(timeline);
          exceptions_deleted.forEach((e) => {
            const resultAckInstanceDelete = knownEvent.acknowledgeInstanceDelete(e.original_start, e.etag, e.type);
            /** No changes if acknowledgeInstanceDelete is rejected. */
            changes.push(...resultAckInstanceDelete.changes);
          });
        }
        /** Unknown original recurring event -> ignored. */
      })
    );

    try {
      await this.publisher.emit(changes);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService._processExceptionsDeletedParallel : ' +
            'One or more exceptions_deleted event already exists, ' +
            'the whole transaction was aborted.' +
            'You should implement cherry-picking/retry.',
          e
        );
      }
      throw e;
    }
    return recurringEvents; // recurringEvents is always empty for now.
    // return [changes, recurringEvents];
  }

  /**
   *
   */
  private async _pullExternalCalendarsMissingOrInvalidatedToken(account_id: Uuid): Promise<{ status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (!account || !isCalendarSupportedAccount(account)) {
        throw new InvalidAccountError();
      }

      let apiResult: CalendarListResult;
      apiResult = await this.calendarServices[account.type].listCalendars(account_id);

      /**
       * @todo Handle deleted calendars possibly not being returned at all by Google Calendar
       *       or other services.
       */

      /** Process calendars. */
      await this._processCalendars(account_id, apiResult);
      /** Process deleted. */
      await this._processCalendarsDeleted(account_id, apiResult);

      /** Next chunks. */
      while (apiResult.sync_token === null && apiResult.page_token) {
        apiResult = await this.calendarServices[account.type].listCalendars(account_id, { page_token: apiResult.page_token });
        await this._processCalendars(account_id, apiResult);
        await this._processCalendarsDeleted(account_id, apiResult);
      }

      /** Mark account fetched with the sync_token. */
      if (apiResult.sync_token) {
        const accountAgg = await this.account.getAggregate(account_id);
        const resultMark = accountAgg.markFetched({ type: 'GOOGLE_CALENDAR', sync_token: apiResult.sync_token });
        if (resultMark.ok) {
          await this.publisher.emit(resultMark.changes);
        }
        console.log('Mark account fetched with the sync_token.', account_id, resultMark);
      } else {
        throw new Error('todo ! Unhandled path in CalendarSyncService.pullExternalCalendars : sync_token is null.');
      }
      return { status: 'IDLE' };
    } catch (e) {
      if (e instanceof MissingCredentialsError || e instanceof InvalidCredentialsError) {
        return { status: 'CREDENTIALS' };
      }

      /** @todo Retry Account.markFetched if the aggregate was updated concurrently. */
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService.pullExternalCalendars :\n' +
            'Aggregate was updated concurrently before Account.markFetched result was committed.\n' +
            'You should implement retry.',
          e
        );
      }

      this.logger.captureException(e);
      return { status: 'IDLE' };
    }
  }
  /**
   *
   */
  private async _pullExternalEventsMissingOrInvalidatedToken(
    calendar: ExternalCalendar,
    chunkSize = 1000
  ): Promise<{ status: AccountSourceStatus }> {
    try {
      const { id: calendar_id, account_id, external_id: calendar_external_id, type } = calendar;

      const account = await this.viewAccount.get(account_id);
      if (!account || !isCalendarSupportedAccount(account)) {
        throw new InvalidAccountError();
      }

      const min_date = toUTCDateTime(account.created_at);
      const now = toUTCDateTimeMs(dayjs().startOf('hour'));

      let apiResult: CalendarEventListResult;
      apiResult = await this.calendarServices[account.type].listEvents(account_id, {
        calendar_external_id,
        min_date,
        showDeleted: true,
        max_results: chunkSize,
      });

      /**
       * @todo Handle deleted events possibly not being returned at all by Google Calendar
       *       or other services.
       */

      const { exceptions, exceptions_deleted } = apiResult;

      /** Process events. */
      const recurringEvents = await this._processEvents(account_id, calendar_id, apiResult, now);

      /**  Process events_deleted. */
      for (const entry of await this._processEventsDeleted(calendar_id, apiResult)) {
        recurringEvents.set(...entry);
      }

      /** Next chunks while holding on to exceptions and exceptions_deleted. */
      while (apiResult.sync_token === null && apiResult.page_token) {
        apiResult = await this.calendarServices[account.type].listEvents(account_id, {
          calendar_external_id,
          page_token: apiResult.page_token,
          min_date,
          showDeleted: true,
          max_results: chunkSize,
        });
        exceptions.push(...apiResult.exceptions);
        exceptions_deleted.push(...apiResult.exceptions_deleted);
        for (const entry of await this._processEvents(account_id, calendar_id, apiResult, now)) {
          recurringEvents.set(...entry);
        }
        for (const entry of await this._processEventsDeleted(calendar_id, apiResult)) {
          recurringEvents.set(...entry);
        }
      }

      /** Process exceptions. */
      for (const entry of await this._processExceptionsParallel(calendar_id, apiResult)) {
        recurringEvents.set(...entry);
      }

      /** Process exceptions_deleted. */
      await this._processExceptionsDeletedParallel(calendar_id, apiResult);

      /**
       * @todo Emit DomainEvents all at once.
       *       >>> Not so fast ! There are data dependencies between steps.
       *           If you do it, you need to carefully evaluate them.
       *           The recurringEvents map is not the solution anyway : it can
       *           be used to 'emit all at once' but not to parallelize everything.
       */

      /** @todo Mark all instances projected before min_date as 'DONE'. */

      /**
       * Mark calendar synced with the sync_token.
       */
      if (apiResult.sync_token) {
        const calendarAgg = await this.calendar.getAggregate(calendar_id);
        const resultMark = calendarAgg.markSynced({ type, sync_token: apiResult.sync_token });
        if (resultMark.ok) {
          await this.publisher.emit(resultMark.changes);
        }
        // console.warn('Mark calendar synced with the sync_token.', calendar_id, resultMark, calendarAgg);
      } else {
        throw new Error('todo ! Unhandled path in CalendarSyncService.pullExternalEvents : sync_token is null.');
      }

      return { status: 'IDLE' };
    } catch (e) {
      if (e instanceof MissingCredentialsError || e instanceof InvalidCredentialsError) {
        return { status: 'CREDENTIALS' };
      }

      /** @todo Retry Calendar.markSynced if the aggregate was updated concurrently. */
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService.pullExternalEvents :\n' +
            'Aggregate was updated concurrently before Calendar.markSynced result was comitted.\n' +
            'You should implement retry.',
          e
        );
      }

      this.logger.captureException(e);
      return { status: 'ERROR' };
    }
  }

  /**
   * @note For Google, the order of the events is an unspecified, stable order.
   *       Even though it seems they always list an original recurring event
   *       before its exceptions, there are no guarantees !!!
   *       This means :
   *         - We should process the exceptions and exceptions_deleted after being
   *           done with all the events.
   *         - We should stash exceptions and exceptions_deleted as they are received,
   *           then retrieve and process them in chunks, lest we risk running out of
   *           memory handling large accounts.
   *         - We could have a fast path and keep everything in memory until we
   *           reach some specific number of exceptions.
   *
   * @note For Google, when changing event on the main calendar, some built-in calendars
   *       like e_2_en#weeknum@group.v.calendar.google.com, will get a new etag for their
   *       events list collection and yield a new sync token if said collection is queried.
   *       The collection etag seem to change for other yet unidentified reasons while
   *       queries with a sync_token yield no new changes.
   *
   *       We may want to avoid saving every new sync_token if there wasn't any changes ?
   *       For now, we abort if there is no change to process.
   *
   * @todo Process exception and exceptions_deleted in chunks, accumulating them
   *       may be a problem for large accounts.
   */
  async pullExternalEvents(calendar: ExternalCalendar, chunkSize = 1000): Promise<{ status: AccountSourceStatus }> {
    try {
      if (calendar.sync_token === null) {
        /** Either it's a newly acked calendar or something bad happened before CalendarSynced was committed. */
        return this._pullExternalEventsMissingOrInvalidatedToken(calendar, chunkSize);
      }

      const { id: calendar_id, account_id, external_id: calendar_external_id, type, sync_token } = calendar;

      const account = await this.viewAccount.get(account_id);
      if (!account || !isCalendarSupportedAccount(account)) {
        throw new InvalidAccountError();
      }

      const now = toUTCDateTimeMs(dayjs().startOf('hour'));

      let apiResult: CalendarEventListResult;
      apiResult = await this.calendarServices[account.type].listEventChanges(account_id, {
        calendar_external_id,
        max_results: chunkSize,
        sync_token,
      });

      /** Abort if there is no change to process. */
      if (
        !(
          apiResult.events.length ||
          apiResult.events_deleted.length ||
          apiResult.exceptions.length ||
          apiResult.exceptions_deleted.length
        )
      ) {
        // console.warn('>>> No changes, aborting pullExternalEvents.', calendar_id, apiResult);
        return { status: 'IDLE' };
      }

      // console.info('>>> CHANGES, pullExternalEvents first chunk.', calendar_id, apiResult);

      const { exceptions, exceptions_deleted } = apiResult;

      /** Process events. */
      const recurringEvents = await this._processEvents(account_id, calendar_id, apiResult, now);

      /**  Process events_deleted. */
      for (const entry of await this._processEventsDeleted(calendar_id, apiResult)) {
        recurringEvents.set(...entry);
      }

      /** Next chunks while holding on to exceptions and exceptions_deleted. */
      while (apiResult.sync_token === null && apiResult.page_token) {
        apiResult = await this.calendarServices[account.type].listEventChanges(account_id, {
          calendar_external_id,
          page_token: apiResult.page_token,
          max_results: chunkSize,
          sync_token,
        });
        exceptions.push(...apiResult.exceptions);
        exceptions_deleted.push(...apiResult.exceptions_deleted);
        for (const entry of await this._processEvents(account_id, calendar_id, apiResult, now)) {
          recurringEvents.set(...entry);
        }
        for (const entry of await this._processEventsDeleted(calendar_id, apiResult)) {
          recurringEvents.set(...entry);
        }
      }

      /** Process exceptions. */
      for (const entry of await this._processExceptionsParallel(calendar_id, apiResult)) {
        recurringEvents.set(...entry);
      }

      /** Process exceptions_deleted. */
      await this._processExceptionsDeletedParallel(calendar_id, apiResult);

      /**
       * @todo Emit DomainEvents all at once.
       *       >>> Not so fast ! There are data dependencies between steps.
       *           If you do it, you need to carefully evaluate them.
       *           The recurringEvents map is not the solution anyway : it can
       *           be used to 'emit all at once' but not to parallelize everything.
       */

      /** @todo Mark all instances projected before min_date as 'DONE'. */

      /**
       * Mark calendar synced with the sync_token.
       */
      if (apiResult.sync_token) {
        const calendarAgg = await this.calendar.getAggregate(calendar_id);
        const resultMark = calendarAgg.markSynced({ type, sync_token: apiResult.sync_token });
        if (resultMark.ok) {
          await this.publisher.emit(resultMark.changes);
        }
        // console.warn('Mark calendar synced with the sync_token.', calendar_id, resultMark, calendarAgg);
      } else {
        throw new Error('todo ! Unhandled path in CalendarSyncService.pullExternalEvents : sync_token is null.');
      }

      return { status: 'IDLE' };
    } catch (e) {
      if (e instanceof GoogleSyncTokenInvalidatedError) {
        return this._pullExternalEventsMissingOrInvalidatedToken(calendar, chunkSize);
      }

      if (e instanceof MissingCredentialsError || e instanceof InvalidCredentialsError) {
        return { status: 'CREDENTIALS' };
      }

      /** @todo Retry Calendar.markSynced if the aggregate was updated concurrently. */
      if (e instanceof DOMException && e.name === 'ConstraintError') {
        console.log(
          'todo ! Unhandled path in CalendarSyncService.pullExternalEvents :\n' +
            'Aggregate was updated concurrently before Calendar.markSynced result was comitted.\n' +
            'You should implement retry.',
          e
        );
      }

      this.logger.captureException(e);
      return { status: 'ERROR' };
    }
  }
}
