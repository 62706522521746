import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import {
  getAllImThreads,
  selectSearchResult,
  ViewIm,
  ViewImThread,
  useAppDispatch,
  ViewAccount,
  Taggable,
} from '@focus-front/core';

import CallSearchResultFake from '../../Call/CallSearchResultFake';
import ImThreadListView from '../../Im/ImThreadListView';
import AllListContainer, { AllListProps } from './AllListContainer';
import NoResults from './NoResults';
import { chatboxOutline } from 'ionicons/icons';

export default function AllImThreadsList({ onBack }: Pick<AllListProps, 'onBack'>) {
  const [threads, setThreads] = useState<
    { thread: ViewImThread & Taggable; last_im: ViewIm | null; account: ViewAccount | null }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      await loadMore();
      setLoading(false);
    })();
  }, []);

  const loadMore = async () => {
    const res = await dispatch(getAllImThreads(50, threads.length));
    setThreads([...threads, ...res]);
  };
  return (
    <AllListContainer title={t('search.im_thread_plural')} onBack={onBack}>
      {loading && (
        <>
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
        </>
      )}
      {!loading && threads.length > 0 && (
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={threads.length}
          endReached={loadMore}
          itemContent={(index) => (
            <ImThreadListView
              thread={threads[index].thread}
              last_im={threads[index].last_im}
              account={threads[index].account}
              onClick={() => dispatch(selectSearchResult({ id: threads[index].thread.id, type: 'im_thread' }))}
            />
          )}
        />
      )}
      {!loading && !threads.length && <NoResults icon={chatboxOutline} label={t('search.im_thread_plural')} />}
    </AllListContainer>
  );
}
