import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getContactView,
  showAttendeesList,
  showAttendeesListSelector,
  useAppDispatch,
  useAppStore,
  ViewContact,
  ViewImThread,
  ViewAccount,
  getOneToOneThreadByAttendee,
  AccountType,
  writeNewIm,
  IdentifierType,
  notificationByAttachedSelector,
  eventByAttachedSelector,
  Attendee,
  Uuid,
  isEnvSupportedAccount,
  envSelector,
  mockUuid,
} from '@focus-front/core';
import { useSelector } from 'react-redux';
import { useContactsList } from '../Contact/useContactsList';
import { GroupedVirtuoso } from 'react-virtuoso';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { NotificationChip } from '../Common/NotificationChip';
import { EventChip } from '../Common/EventChip';
import SearchTextField from '../Common/SearchTextField';
import { AccountTypeIcon } from '@focus-front/ui';

const useClasses = makeStyles((theme) => ({
  group: {
    paddingLeft: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[200],
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  processing: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Context = React.createContext<{ setProcessing: (b: boolean) => void }>({
  setProcessing: null,
});

export default function NewImModal() {
  const c = useClasses();
  const { t } = useTranslation();
  const [expand, setExpand] = useState<{ [key: number]: boolean }>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const open = useSelector(showAttendeesListSelector);

  useEffect(() => {
    if (open) {
      setExpand({});
      setQuery('');
      setProcessing(false);
    }
  }, [open]);
  const { contacts, groupCounts, groups, loading, setQuery, query } = useContactsList(open);
  const handleClose = () => dispatch(showAttendeesList(false));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <Context.Provider value={{ setProcessing }}>
        {processing && (
          <div className={c.processing}>
            <CircularProgress />
          </div>
        )}
        <DialogTitle id="scroll-dialog-title">{t('header.newMessage')}</DialogTitle>
        <DialogTitle>
          <SearchTextField
            name="contact-search"
            id="contact-search-bar"
            autoComplete="off"
            autoCorrect="off"
            placeholder={t('search.placeholder.contact')}
            clickClear={() => setQuery('')}
            showClear={query.length > 0}
            value={query}
            onChange={(e) => {
              const value = e.target.value;
              setQuery(value);
            }}
          />
        </DialogTitle>
        <DialogContent dividers style={{ height: '50vh', padding: 0 }}>
          <List style={{ height: '100%' }} disablePadding>
            {loading ? (
              <ListItem>
                <ListItemText primary="Loading" />
              </ListItem>
            ) : (
              <GroupedVirtuoso
                style={{ height: '100%' }}
                groupCounts={groupCounts}
                groupContent={(index) => {
                  return (
                    <div className={c.group}>
                      <Typography variant="subtitle2">{groups[index]}</Typography>
                    </div>
                  );
                }}
                itemContent={(index) => (
                  <ContactItem
                    contact={contacts[index]}
                    expanded={expand[contacts[index].id]}
                    onClick={() => setExpand((expand) => ({ ...expand, [contacts[index].id]: !expand[contacts[index].id] }))}
                  />
                )}
              />
            )}
          </List>
        </DialogContent>
      </Context.Provider>
    </Dialog>
  );
}

const ContactItem = memo(
  function ContactItem({ contact, expanded, onClick }: { contact: ViewContact; expanded: boolean; onClick: () => void }) {
    return (
      <>
        <ListItem button onClick={onClick}>
          <ListItemAvatar>
            <AttendeeAvatar display_name={contact.full_name} src={contact.profile_picture} size="small"></AttendeeAvatar>
          </ListItemAvatar>
          <ListItemText primary={contact.full_name} />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {contact.social.map((social, index) => (
              <ThreadItem
                key={index}
                attendee={{
                  identifier: social.identifier,
                  identifier_type: social.identifier_type,
                  display_name: contact.full_name,
                }}
                account_id={social.managing_account}
                provider={
                  social.identifier_type.slice(0, -3) === 'MESSENGER'
                    ? 'MESSENGER'
                    : social.identifier_type.slice(0, -3) === 'LINKEDIN'
                    ? 'LINKEDIN'
                    : social.identifier_type.slice(0, -3) === 'TIKTOK'
                    ? 'TIKTOK'
                    : social.identifier_type.slice(0, -3) === 'TWITTER'
                    ? 'TWITTER'
                    : social.identifier_type.slice(0, -3) === 'INSTAGRAM'
                    ? 'INSTAGRAM'
                    : 'LINKEDIN'
                }
              />
            ))}
            {contact.phone.map((phone, index) => (
              <ThreadItem
                key={index}
                attendee={{
                  identifier: phone.number,
                  identifier_type: 'PHONE_NUMBER',
                  display_name: contact.full_name,
                }}
                account_id={phone.managing_account}
                provider={phone.label === 'WHATSAPP' ? 'WHATSAPP' : 'MOBILE'}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  },
  (prev, next) => prev.expanded === next.expanded && prev.contact.id === next.contact.id
);

function ThreadItem({ attendee, provider, account_id }: { attendee: Attendee; provider: AccountType; account_id: Uuid }) {
  const c = useClasses();
  const [thread, setThread] = useState<ViewImThread>(undefined);
  const dispatch = useAppDispatch();
  const notification = useSelector(notificationByAttachedSelector(thread?.id));
  const event = useSelector(eventByAttachedSelector(thread?.id));
  const history = useHistory();
  const { setProcessing } = useContext(Context);
  useEffect(() => {
    (async () => {
      const thread = await dispatch(getOneToOneThreadByAttendee(attendee.identifier, attendee.identifier_type));
      setThread(thread);
    })();
  }, []);

  const onClick = useCallback(async () => {
    setProcessing(true);
    if (event) history.push(`/work/${event.id}?action=chat`);
    else if (notification) history.push(`/inbox/${notification.id}?action=chat`);
    else {
      const event_id = await dispatch(writeNewIm(attendee, account_id));
      history.push(`/work/${event_id}?action=chat`);
    }
    dispatch(showAttendeesList(false));
  }, [notification, event, thread]);

  const getTitle = useMemo<string>(() => {
    if (provider === 'LINKEDIN') return `LinkedIn`;
    if (provider === 'MESSENGER') return `Facebook Messenger`;
    if (provider === 'INSTAGRAM') return `Instagram`;
    if (provider === 'TIKTOK') return `TikTok`;
    if (provider === 'TWITTER') return 'Twitter';
    if (provider === 'WHATSAPP') return `${attendee.identifier} (WhatsApp)`;
    return attendee.identifier;
  }, [provider]);

  return (
    <ListItem button disabled={thread === undefined} className={c.nested} onClick={onClick}>
      <ListItemAvatar style={{ minWidth: 40 }}>
        <AccountTypeIcon account_type={provider} size={30} />
      </ListItemAvatar>
      <ListItemText primary={getTitle} />
      <ListItemSecondaryAction>
        {!!notification && <NotificationChip id={notification.id} />}
        {!!event && <EventChip id={event.id} />}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
