import * as React from 'react';
import { IonIcon } from '@ionic/react';
import { makeStyles, Typography } from '@material-ui/core';
import { peopleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import { UnipileEventAttendee } from '@focus-front/core';

interface EventAttendeesProps {
  className: string;
  attendees: UnipileEventAttendee[];
}

const useClasses = makeStyles((theme) => ({
  attendeesList: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  attendeesItem: {
    marginLeft: theme.spacing(2),
  },
}));

const EventAttendees: React.FC<EventAttendeesProps> = ({ className, attendees }) => {
  const { t } = useTranslation();
  const classes = useClasses();

  return (
    <div className={className}>
      <IonIcon icon={peopleOutline} />
      <div>
        <Typography variant="body2">{`${attendees.length} ${t('event.header.attendees')}`} </Typography>
        {attendees.map((attendee: UnipileEventAttendee) => (
          <div key={attendee.email} className={classes.attendeesList}>
            <AttendeeAvatar display_name={attendee.display_name || attendee.email} size="tiny" />
            <div className={classes.attendeesItem}>
              <Typography variant="body2">{attendee.display_name || attendee.email}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventAttendees;
