import { Box, Chip, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React, { memo, useContext, useEffect, useRef } from 'react';
import {
  MailDocument,
  SearchResult,
  notificationByAttachedSelector,
  eventByAttachedSelector,
  displayAttendeeName,
} from '@focus-front/core';
import Highlighter from '../Search/Highlighter';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IonIcon } from '@ionic/react';
import { attachOutline, chevronForward, notificationsOutline, playForwardOutline, pricetag, pricetags } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import { NotificationChip } from '../Common/NotificationChip';
import { EventChip } from '../Common/EventChip';
import Tags from '../Tag/Tags';
dayjs.extend(localizedFormat);

const useClasses = makeStyles((theme) => ({
  root: (props: { isOpen: boolean }) => ({
    padding: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: props.isOpen && theme.palette.background.default,
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '& .Avatar': {
      flexShrink: 0,
    },
    borderLeft: props.isOpen && `solid 4px ${theme.palette.secondary.main}`,
  }),
  right: {
    paddingLeft: 10,
    flex: 1,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  body: {
    flex: 1,
  },
  date: {
    fontSize: '0.75rem',
    opacity: 0.5,
  },
  attach: { fontSize: 20, verticalAlign: 'bottom' },
  arrow: {
    fontSize: 24,
    color: theme.palette.grey[700],
  },
  draft: {
    color: theme.palette.warning.main,
  },
}));

interface Props {
  onClick: () => void;
  mail: SearchResult<MailDocument>;
  index: number;
}

export function MailSearchResult({ onClick, mail, index }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const notification = useSelector(notificationByAttachedSelector(mail.item.id));
  const event = useSelector(eventByAttachedSelector(mail.item.id));
  const c = useClasses({
    isOpen: (notification && location.pathname.includes(notification.id)) || (event && location.pathname.includes(event.id)),
  });

  return (
    <div className={c.root} onClick={onClick}>
      <AttendeeAvatar display_name={displayAttendeeName(mail.item.from_attendee)} size="small" />
      <div className={c.right}>
        <div className={c.top}>
          <Box display="flex" alignItems="center">
            <Typography variant="h4" style={{ marginRight: 10 }}>
              {mail.item.draft && <span className={c.draft}>[ {t('search.draft_upper')} ] </span>}
              {mail.matches.find((match) => match.key === 'from_attendee.display_name') ? (
                <Highlighter
                  text={displayAttendeeName(mail.item.from_attendee)}
                  ranges={mail.matches.find((match) => match.key === 'from_attendee.display_name').indices}
                />
              ) : (
                displayAttendeeName(mail.item.from_attendee)
              )}
            </Typography>
            {!!notification && <NotificationChip id={notification.id} />}
            {!!event && <EventChip id={event.id} />}
          </Box>

          <div className={c.date}>
            {mail.item.has_attachments && <IonIcon icon={attachOutline} className={c.attach}></IonIcon>}
            {dayjs(mail.item.date).format('L')}
          </div>
        </div>
        <Typography variant="subtitle1">
          {mail.matches.find((match) => match.key === 'subject') ? (
            <Highlighter text={mail.item.subject} ranges={mail.matches.find((match) => match.key === 'subject').indices} />
          ) : (
            mail.item.subject
          )}
        </Typography>
        <Box display="flex" alignItems="flex-end" minHeight="24px">
          <div className={c.body}>
            <Typography variant="subtitle2">
              {mail.matches.find((match) => match.key === 'body_plain') ? (
                <Highlighter
                  text={mail.item.body_plain}
                  ranges={mail.matches.find((match) => match.key === 'body_plain').indices}
                  trimSize={12}
                />
              ) : mail.item.body_plain?.length > 150 ? (
                mail.item.body_plain.slice(0, 150) + '...'
              ) : (
                mail.item.body_plain
              )}
            </Typography>
            {mail.item.tags && mail.item.tags.length > 0 && <Tags tags={mail.item.tags} listMode />}
          </div>

          <IonIcon className={c.arrow} icon={chevronForward} />
        </Box>
      </div>
    </div>
  );
}

export default memo(MailSearchResult, (prev, next) => prev.mail.item.id === next.mail.item.id);
