import { Uuid, Tzid } from '../../../Common';
import { GcalCalendarAccessRole } from '../../infra/services/GcalApiTypes';
import { NewCalendarDTO } from '../types/CalendarDTO';

/**
 * Projection.
 */
export interface ViewCalendarBase {
  id: Uuid /** Aggregate id. */;
  name: string;
  background_color: string /** @todo Add HexColor opaque type. */;
  default_tzid: Tzid;
}

/**
 *
 */
export interface UnipileCalendar extends ViewCalendarBase {
  type: 'UNIPILE';
  user_id?: number;
  team_ids?: Uuid[];
  group_ids?: Uuid[];
  user_ids?: Uuid[];
  is_shared: boolean;
}

/**
 *
 */
export interface GoogleCalendar extends ViewCalendarBase {
  type: 'GOOGLE';
  external_id: string;
  account_id: Uuid;
  //   summary: string /** @todo Streamline to name ? */;
  description?: string;
  etag: string;
  foreground_color?: string /** @todo Add HexColor opaque type. */;
  is_primary: boolean;
  access_role: GcalCalendarAccessRole; // 'owner' | 'writer' | 'reader' | 'freeBusyReader';
  sync_token: string | null;
  sync_activated: boolean;
}

/**
 *
 */
export interface OutlookCalendar extends ViewCalendarBase {
  type: 'OUTLOOK';
  external_id: string;
  account_id: Uuid;
  etag: string /** @note Streamlined name from changeKey. */;
  sync_token: string | null;
  sync_activated: boolean;
}

export type ViewCalendar = Readonly<UnipileCalendar | GoogleCalendar | OutlookCalendar>;

export type CalendarType = ViewCalendar['type'];
export type ExternalCalendarType = Exclude<CalendarType, 'UNIPILE'>;
export type ExternalCalendar = Exclude<ViewCalendar, UnipileCalendar>;

/**
 * @note Warning : TS will let you return the wrong type inside the switch,
 *       see https://gist.github.com/pozorfluo/a042f3c5cedc3562982fc554b5e73e44
 *       I asked how to work around this on TS's discord on 20/07/2021 and didn't
 *       get an answer.
 */
export function createViewCalendar<T extends NewCalendarDTO>(aggregateId: Uuid, values: T): ViewCalendar & { type: T['type'] } {
  switch (values.type) {
    case 'GOOGLE':
      return { ...values, id: aggregateId, sync_activated: values.sync_activated ?? false };
    case 'OUTLOOK':
      return { ...values, id: aggregateId, sync_activated: values.sync_activated ?? false };
    case 'UNIPILE':
      return {
        ...values,
        id: aggregateId,
        is_shared: values.is_shared || false,
        background_color: '#00BFA2',
      };
  }
}

//------------------------------------------------------------------------------
/**
 * Aliases.
 */
export type ExternalCalendarEtag = NonNullable<GoogleCalendar['etag']>;
