/**
 * @note This doesn't quite "catch error" :
 *
 *       - It's too late, the error has been allowed to bubble up to global
 *         scope and "current execution has been stopped".
 *
 *       - An "Uncaught Error : ..." error message will be printed in the console.
 *         if preventDefault is set to false.
 *
 *       Errors should be properly handled earlier !
 *
 *       This is a safety net to register some recovery mechanism or
 *       last ditch attempt at logging.
 *
 * @note Despite using error boundaries, errors thrown in a click handler rather
 *       than render() in React will "hit twice". This is supposed to be limited
 *       to dev build. To alleviate the problem we track handled errors as suggested
 *       here : https://github.com/facebook/react/issues/10474#issuecomment-322909303
 *
 *       Because pretty much anything can be thrown we use a regular Set instead
 *       of the suggested WeakSet which can only hold object refs.
 *
 *       To properly handle further fungible primitives that get thrown and
 *       avoid the 'chain throwing' issue, unregister the errorSink after dealing
 *       with an error and register a new one.
 */
export function registerErrorSink(errorSink: (error: Error) => void, preventDefault = true) {
  let handledErrors: Set<unknown> | null = new Set();

  const errorHandler = (event: ErrorEvent) => {
    const { error } = event;
    if (!handledErrors?.has(error)) {
      errorSink(error);
      handledErrors?.add(error);
    }

    if (preventDefault) {
      event.preventDefault();
    }
    // event.stopImmediatePropagation();
    // event.stopPropagation();
  };

  const rejectionHandler = (event: PromiseRejectionEvent) => {
    const { reason: error } = event;
    if (!handledErrors?.has(error)) {
      errorSink(error);
      handledErrors?.add(error);
    }

    // errorSink(event.reason);
    if (preventDefault) {
      event.preventDefault();
    }
    // event.stopImmediatePropagation();
    // event.stopPropagation();
  };

  window.addEventListener('error', errorHandler, true);
  window.addEventListener('unhandledrejection', rejectionHandler, true);

  return function unregisterErrorSink() {
    console.warn('unregisterErrorSink');
    handledErrors = null;
    window.removeEventListener('error', errorHandler, true);
    window.removeEventListener('unhandledrejection', rejectionHandler, true);
  };
}

/**
 * Stop some ResizeObserver errors from propagating,
 * see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
 *
 * Use before registering error sinks.
 */
export function stopResizeObserverErrorPropagation() {
  window.addEventListener(
    'error',
    (event) => {
      //   console.warn('stopResizeObserverErrorPropagation');
      if (
        event.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        event.message === 'ResizeObserver loop limit exceeded'
      ) {
        event.stopImmediatePropagation();
        // event.preventDefault();
      }
    },
    true
  );
}

// export class ErrorSink {
//   static register();
// }
// export interface ErrorHandler {
//   (error: Error): void;
// }
