import { MaybePromise } from '../utils';
import { DomainEvent, DomainEventId, DomainEventShape } from './DomainEvent';
import { Uuid } from './Uuid';

export type Committed<TDomainEvent extends DomainEventShape> = { id: number; branch?: Uuid } & TDomainEvent;

/**
 * @note Bigger but more readable than previous digest shape.
 * digest: {
 *           [aggregateId]: {
 *             [version]: [branchId, id, TDomainEvent['type'], schema, createdAt];
 *           };
 *         };
 */
export interface DebugDigestMap<TDomainEvent extends DomainEventShape> {
  schema: number;
  digest: Record<string, Record<number, [string, number, TDomainEvent['type'], number, number]>>;
}

/**
 *
 * @note If you need a smaller digest, consider this shape which repeats branchId
 *       a lot less :
 *
 *         digest: {
 *                   [branchId]: {
 *                     [aggregateId]: {
 *                       [version]: [id, TDomainEvent['type'], schema, createdAt];
 *                     };
 *                   };
 *                 };
 */
// export interface DebugDigestMap<TDomainEvent extends DomainEventShape> {
//   schema: number;
//   digest: Record<string, Record<string, Record<number, [number, TDomainEvent['type'], number, number]>>>;
// }

export function getDigest<TDomainEvent extends DomainEventShape = DomainEvent>(
  events: Committed<TDomainEvent>[]
): DebugDigestMap<TDomainEvent> {
  return events.reduce(
    (map, event) => {
      (map.digest[event.aggregateId] || (map.digest[event.aggregateId] = {}))[event.version] = [
        /** @note Dashes are prepended to keep things neatly aligned with Uuids for readability. */
        event?.branch ?? '-------------------------------local',
        event.id,
        event.type,
        event.schema,
        event.createdAt,
      ];
      return map;
    },
    {
      schema: 1,
      digest: {},
    } as DebugDigestMap<TDomainEvent>
  );
}

/**
 * @todo Consider Streams of events for the get*().
 *
 * @todo Consider what may happen with deterministic UUID, there may be cases where
 *       aggregate timelines get broken because a creational events ends up with an
 *       existing id for otherwise legit reasons, e.g. wrongly checking mail twice ?
 *       Crashing after acknowledging a mail and before moving mail cursor,
 *       what happens next app launch when it is ackownledged again on the same device ?
 */
export interface DomainEventStore<TDomainEvent extends DomainEventShape = DomainEvent> {
  //   getBranchId(): MaybePromise<Uuid>;
  getByAggregate: (aggregateId: Uuid) => MaybePromise<Committed<TDomainEvent>[]>;
  getByAggregateUpTo: (aggregateId: Uuid, refEvent: TDomainEvent) => MaybePromise<Committed<TDomainEvent>[]>;
  //   getByAggregate<TDomainEvent extends DomainEvent = DomainEvent>(aggregateId: Uuid): NarrowBy<DomainEvent, TDomainEvent['type']>[];
  //   getByType<TDomainEventType extends TDomainEvent['type']>(
  //     types: SingleOrArray<TDomainEventType>
  //   ): MaybePromise<NarrowBy<TDomainEvent, TDomainEventType>[]>;
  getLatest: () => MaybePromise<Committed<TDomainEvent> | null>;
  getLatestPosition: () => MaybePromise<number | null>;
  getLatestId: () => MaybePromise<DomainEventId | null>;
  getAll: (fromPosition?: number, count?: number) => MaybePromise<Committed<TDomainEvent>[]>;
  getAllAfter: (eventId: DomainEventId, count?: number) => MaybePromise<Committed<TDomainEvent>[]>;
  getDebugDigest: () => MaybePromise<DebugDigestMap<TDomainEvent>>;
  add: (event: TDomainEvent) => MaybePromise<void>;
  addMany: (events: TDomainEvent[]) => MaybePromise<void>;
  clobberMany: (events: (TDomainEvent & { id?: never; local?: never })[]) => MaybePromise<number[]>;
}

/**
 *
 */
export class UnknownDomainEventIdError extends Error {
  constructor(readonly unknownId: DomainEventId) {
    super(`Unknown DomainEventId : [${unknownId.aggregateId}, ${unknownId.version}] !`);
    this.name = 'UnknownDomainEventIdError';
  }
}
