import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    sectionTitle: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
    },
    dangerBtn: {
      color: theme.palette.error.main,
    },
    boost: {
      background: 'linear-gradient(12deg, rgba(0,191,162,1) 0%, rgba(0,255,216,1) 100%);',
    },
  })
);
