import { GoogleCalendarConnectionParams } from '../../../Account';
import { Uuid } from '../../../Common';
import { EncryptionCryptoKey } from '../../../Common/infra/services/crypto/Crypto';
import {
  CalendarEventListChangesQuery,
  CalendarEventListQuery,
  CalendarEventListResult,
  CalendarListChangesQuery,
  CalendarListQuery,
  CalendarListResult,
  CalendarService,
} from './CalendarService';
import { GcalApiClient } from './GcalApiClient';

/**
 * @todo Define scopes above and pass down.
 */
 export const GoogleCalendarScopes = [
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/calendar.events',
    'https://www.googleapis.com/auth/calendar.settings.readonly',
  ];

/**
 *
 */
export abstract class GcalService implements CalendarService<GoogleCalendarConnectionParams> {
  protected connections = new Map<Uuid, GcalApiClient>();

  /**
   *
   */
  private _getConnection(account_id: Uuid): GcalApiClient {
    const connection = this.connections.get(account_id);
    if (!connection) {
      throw new Error('Gcal connection missing');
    }
    return connection;
  }

  /**
   *
   */
  abstract registerAccount(
    accountId: Uuid,
    connectionParams: GoogleCalendarConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): void;

  /**
   *
   */
  unregisterAccount(account_id: Uuid): void {
    this.connections.delete(account_id);
  }

  /**
   *
   */
  listCalendars(account_id: Uuid, query?: CalendarListQuery): Promise<CalendarListResult> {
    return this._getConnection(account_id).listCalendars(query);
  }

  /**
   *
   */
  listCalendarChanges(account_id: Uuid, query: CalendarListChangesQuery): Promise<CalendarListResult> {
    return this._getConnection(account_id).listCalendarChanges(query);
  }

  /**
   *
   */
  listEvents(account_id: Uuid, query: CalendarEventListQuery): Promise<CalendarEventListResult> {
    return this._getConnection(account_id).listEvents(query);
  }

  /**
   *
   */
  listEventChanges(account_id: Uuid, query: CalendarEventListChangesQuery): Promise<CalendarEventListResult> {
    return this._getConnection(account_id).listEventChanges(query);
  }
}
