import { createProjector } from '../../../Common';
import { createViewCalendar } from '../projections/ViewCalendar';
import { ViewCalendarRepo } from '../projections/ViewCalendarRepo';

/**
 * Handler/Projector.
 */
export const viewCalendarsProjector = createProjector('viewCalendarsProjector', {
  CALENDAR_CREATED: async ({ aggregateId, calendar }, repo: ViewCalendarRepo) => {
    /**
     * @todo Have a separate NewCalendarDTO and CalendarDTO, as done with
     *       ViewCalendarEvent. Use the types to help enforce that the starting
     *       value must be false.
     */
    return repo.add(createViewCalendar(aggregateId, calendar));
  },

  CALENDAR_ACKNOWLEDGED: async ({ aggregateId, calendar }, repo: ViewCalendarRepo) => {
    return repo.add(createViewCalendar(aggregateId, calendar));
  },

  CALENDAR_DELETED: async ({ aggregateId }, repo) => {
    return repo.remove(aggregateId);
  },

  CALENDAR_DELETE_ACKNOWLEDGED: async ({ aggregateId }, repo) => {
    return repo.remove(aggregateId);
  },

  CALENDAR_EDITED: async ({ aggregateId, updatedValues }, repo) => {
    return repo.patch(aggregateId, updatedValues);
  },

  CALENDAR_PATCH_ACKNOWLEDGED: async ({ aggregateId, patch }, repo) => {
    return repo.patch(aggregateId, patch);
  },

  CALENDAR_ETAG_ACKNOWLEDGED: async ({ aggregateId, etag }, repo) => {
    try {
      await repo.patch(aggregateId, { etag });
      return;
    } catch {
      /**
       * Let it silently fail, this is not an error, most of the time
       * ViewCalendar is not going to exist when this event is played.
       */
      return;
    }
  },

  CALENDAR_SYNC_ACTIVATED: async ({ aggregateId }, repo) => {
    return repo.patch(aggregateId, { sync_activated: true });
  },

  CALENDAR_SYNC_DEACTIVATED: async ({ aggregateId }, repo) => {
    return repo.patch(aggregateId, { sync_activated: false });
  },
  CALENDAR_SYNCED: async ({ aggregateId, sync_token }, repo) => {
    // console.log('CALENDAR_SYNCED', aggregateId, sync_token);
    return repo.patch(aggregateId, { sync_token });
  },
});
