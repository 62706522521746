import { DomainEventShape, UTCDateTimeMs, Uuid } from '../../../Common';
import { Merge, Never, Optional, Strip } from '../../../Common/utils';
import { CalendarEventException } from '../types/CalendarEventException';
import {
  CalendarEventExceptionDTO,
  ExternalCalendarEventExceptionDTO,
  ExternalPatchCalendarEventExceptionDTO,
} from '../types/CalendarEventExceptionDTO';
import {
  CalendarEventInstanceId,
  CalendarEventKind,
  CalendarEventRecurringKind,
  CalendarEventSingleKind,
  CalendarEventStatus,
  ExternalCalendarEventEtag,
  ExternalCalendarEventType,
} from '../projections/ViewCalendarEvent';
import {
  CreateCalendarEventDTO,
  AcknowledgeCalendarEventDTO,
  ExternalPatchCalendarEventDTO,
  PatchCalendarEventDTO,
  StatusOption,
} from '../types/CalendarEventDTO';

/**
 * DomainEvent.
 *
 * @note Hard setting version at 0 means it can only happens when creating the
 *       aggregate.
 */
export class CalendarEventCreated implements DomainEventShape {
  readonly type = 'CALENDAREVENT_CREATED';
  readonly schema = 1;
  readonly version = 0;
  readonly calendarEvent: Strip<CreateCalendarEventDTO, 'statusOptions'>;
  readonly statusOptions?: StatusOption[];
  constructor(readonly aggregateId: Uuid, calendarEvent: CreateCalendarEventDTO, readonly createdAt = Date.now()) {
    const { statusOptions, ...stripped } = calendarEvent;
    if (statusOptions) {
      this.statusOptions = statusOptions;
    }
    this.calendarEvent = stripped;
  }
}
// export class CalendarEventCreated implements DomainEventShape {
//   readonly type = 'CALENDAREVENT_CREATED';
//   readonly schema = 1;
//   readonly version = 0;
//   constructor(
//     readonly aggregateId: Uuid,
//     readonly calendarEvent: CreateCalendarEventDTO,
//     readonly createdAt = Date.now()
//   ) {}
// }

/**
 * DomainEvent.
 */
export class CalendarEventAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_ACKNOWLEDGED';
  readonly schema = 1;
  readonly version = 0;
  //   readonly calendarEvent:AcknowledgeCalendarEventDTO & {statusOptions? : never};
  readonly calendarEvent: Strip<AcknowledgeCalendarEventDTO, 'statusOptions'>;
  readonly statusOptions?: StatusOption[];
  constructor(readonly aggregateId: Uuid, calendarEvent: AcknowledgeCalendarEventDTO, readonly createdAt = Date.now()) {
    const { statusOptions, ...stripped } = calendarEvent;
    if (statusOptions) {
      this.statusOptions = statusOptions;
    }
    this.calendarEvent = stripped;
  }
}
// export class CalendarEventAcknowledged implements DomainEventShape {
//   readonly type = 'CALENDAREVENT_ACKNOWLEDGED';
//   readonly schema = 1;
//   readonly version = 0;
//   constructor(readonly aggregateId: Uuid, readonly calendarEvent: AcknowledgeCalendarEventDTO, readonly createdAt = Date.now()) {}
// }

/**
 * DomainEvent.
 *
 * @todo Consider pros/cons of alternate way to write events ?
 *         eg : extending base vs implementing interface.
 */
export class CalendarEventDeleted implements DomainEventShape {
  readonly type = 'CALENDAREVENT_DELETED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly kind: (CalendarEventSingleKind | CalendarEventRecurringKind)['kind'],
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventDeleteAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_DELETE_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly kind: (CalendarEventSingleKind | CalendarEventRecurringKind)['kind'],
    readonly etag: ExternalCalendarEventEtag,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 *
 * @note While it seems the same result could be achieved using CalendarEventPatchAcknowledged
 *       with only an etag as payload, this opens up the possibility of having dedicated
 *       handling to keep track of etag when some command rejected.
 */
export class CalendarEventEtagAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_ETAG_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    /**
     * @todo Rethink payload in order to avoid having to send kind and especially
     *       recurrence.
     */
    readonly patch: Strip<CalendarEventSingleKind | CalendarEventRecurringKind, 'id'> & { etag: ExternalCalendarEventEtag },
    // readonly etag: ExternalCalendarEventEtag,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventRestoreAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_RESTORE_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly calendarEvent: AcknowledgeCalendarEventDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventInstanceDeleted implements DomainEventShape {
  readonly type = 'CALENDAREVENT_INSTANCE_DELETED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly createdAt = Date.now()
  ) {}
}
/**
 * DomainEvent.
 */
export class CalendarEventInstanceDeleteAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_INSTANCE_DELETE_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly etag: ExternalCalendarEventEtag,
    /**
     * @todo Weight stamping the type in the event vs querying in proj handler/repo.
     */
    readonly calendarType: ExternalCalendarEventType,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventEdited implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EDITED';
  readonly schema = 1;
  //   readonly patch: Strip<PatchCalendarEventDTO, 'type'>;
  readonly patch: PatchCalendarEventDTO;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    patch: PatchCalendarEventDTO,
    /**
     * @todo Consider if this is a good way to encode the result of this decision ?
     *       Maybe emit a different event that explicitely says that instances
     *       need to be re-expanded ?
     */
    readonly mustExpand: boolean,
    readonly createdAt = Date.now()
  ) {
    // const { id, type, external_id, ...stripped } = patch as PatchCalendarEventDTO & { id: unknown };
    // this.patch = stripped;
    this.patch = patch;
  }
}

/**
 * DomainEvent.
 *
 * @todo Consider stripping type tag in ctor !
 */
export class CalendarEventPatchAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_PATCH_ACKNOWLEDGED';
  readonly schema = 1;
  //   readonly values: Strip<ExternalPatchCalendarEventDTO, 'type' | 'calendar_id'>;
  readonly patch: Strip<ExternalPatchCalendarEventDTO, 'calendar_id'>;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    /**
     * @todo Rethink this DTO, right now it's not possible to change a recurring event
     *       without having to send its recurrence even when it doesn't change.
     */
    // This didn't make sense and only seemed to worked because of the type hole
    // introduced by the assertion when stripping just below.
    // patch: Optional<ExternalPatchCalendarEventDTO, 'kind'>,
    patch: ExternalPatchCalendarEventDTO,
    /**
     * @todo Consider if this is a good way to encode the result of this decision ?
     *       Maybe emit a different event that explicitely says that instances
     *       need to be re-expanded ?
     */
    readonly mustExpand: boolean,
    readonly createdAt = Date.now()
  ) {
    /**
     * @note Be very careful with asserts for stripping, it needs to be kept in
     *       sync with the original definition or it will create a type hole.
     */
    const { id, calendar_id, external_id, ...stripped } = patch as ExternalPatchCalendarEventDTO & {
      id: unknown;
    };

    this.patch = stripped;
  }
}

/**
 * DomainEvent.
 *
 * @todo Consider shortening instanceId to the UTCDateTimeBasic suffix only.
 */
export class CalendarEventExceptionAdded implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_ADDED';
  readonly schema = 1;
  //   readonly exception: Strip<CalendarEventExceptionDTO, 'type' | 'calendar_id'>;
  readonly exception: CalendarEventException;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    exception: CalendarEventExceptionDTO,
    readonly createdAt = Date.now()
  ) {
    // const { type, calendar_id, recurrence, ...stripped } = exception as CalendarEventExceptionDTO & { recurrence: unknown };
    const { id, type, calendar_id, original_start, original_tzid, recurrence, recurring_event_id, kind, ...stripped } =
      exception as CalendarEventExceptionDTO & Merge<CalendarEventKind>;
    this.exception = stripped;
  }
}

/**
 * DomainEvent.
 */
export class CalendarEventExceptionRestoreAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_RESTORE_ACKNOWLEDGED';
  readonly schema = 1;
  readonly exception: Strip<ExternalCalendarEventExceptionDTO, 'type' | 'calendar_id' | 'original_start'>;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    // exception: ExternalCalendarEventExceptionDTO,
    exception: Optional<ExternalCalendarEventExceptionDTO, 'original_start'>,
    readonly createdAt = Date.now()
  ) {
    const { id, type, calendar_id, original_start, ...stripped } = exception as ExternalCalendarEventExceptionDTO & {
      id: unknown;
    };
    this.exception = stripped;
  }
}

/**
 * DomainEvent.
 */
export class CalendarEventExceptionAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_ACKNOWLEDGED';
  readonly schema = 1;
  readonly exception: Strip<ExternalCalendarEventExceptionDTO, 'type' | 'calendar_id' | 'original_start'>;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    // exception: ExternalCalendarEventExceptionDTO,
    exception: Optional<ExternalCalendarEventExceptionDTO, 'original_start'>,
    readonly createdAt = Date.now()
  ) {
    const { id, type, calendar_id, original_start, ...stripped } = exception as ExternalCalendarEventExceptionDTO & {
      id: unknown;
    };
    this.exception = stripped;
  }
}

// function testDistributive(
//   o: Optional<ExternalCalendarEventExceptionDTO, 'original_start'>,
//   e: ExternalPatchCalendarEventExceptionDTO
// ) {
//   if (e.start) {
//     e.end;
//   }
// }

/**
 * DomainEvent.
 */
export class CalendarEventExceptionPatchAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_PATCH_ACKNOWLEDGED';
  readonly schema = 1;
  readonly exceptionPatch: Strip<ExternalPatchCalendarEventExceptionDTO, 'type' | 'original_start'>;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    // { type: _, ...exceptionPatch }: Strip<ExternalPatchCalendarEventExceptionDTO, 'original_start'>,
    { type: _, original_start: __, ...exceptionPatch }: Optional<ExternalPatchCalendarEventExceptionDTO, 'original_start'>,
    readonly createdAt = Date.now()
  ) {
    this.exceptionPatch = exceptionPatch;
  }
}

/**
 * DomainEvent.
 *
 * @note While it seems the same result could be achieved using CalendarEventExceptionAcknowledged
 *       with only an etag as payload, this opens up the possibility of having dedicated
 *       handling to keep track of etag when some command rejected.
 */
export class CalendarEventExceptionEtagAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_ETAG_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly etag: ExternalCalendarEventEtag,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventExceptionEdited implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_EDITED';
  readonly schema = 1;
  /**
   * @todo Reconsider stripping patches to bare essentials and loosening requirement
   *       in consumers later, once everything is somewhat stabilized.
   */
  //   readonly exceptionPatch: CalendarEventExceptionDTO;
  readonly exceptionPatch: Strip<CalendarEventExceptionDTO, 'type' | 'calendar_id'>;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    exceptionPatch: CalendarEventExceptionDTO,
    // { type: _, ...exceptionPatch }: CalendarEventExceptionDTO,
    readonly createdAt = Date.now()
  ) {
    const { id, type, calendar_id, original_start, original_tzid, recurrence, recurring_event_id, kind, ...stripped } =
      exceptionPatch as CalendarEventExceptionDTO & Merge<CalendarEventKind>;
    // & {
    //   id: unknown;
    //   original_start: UnixTimeMs;
    //   kind : CalendarEventKind['kind']
    // };
    this.exceptionPatch = stripped;
  }
}

/**
 * DomainEvent.
 */
export class CalendarEventExceptionCancelled implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_CANCELLED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventExceptionCancelAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAREVENT_EXCEPTION_CANCEL_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly etag: ExternalCalendarEventEtag,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 *
 * @todo Consider that CalendarEventSnoozed doesn't care about the notification
 *       created, just that it has been snoozed.
 */
export class CalendarEventSnoozed implements DomainEventShape {
  readonly type = 'CALENDAREVENT_SNOOZED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly notificationId: Uuid,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventMissed implements DomainEventShape {
  readonly type = 'CALENDAREVENT_MISSED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly notificationId: Uuid,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventInstanceMissed implements DomainEventShape {
  readonly type = 'CALENDAREVENT_INSTANCE_MISSED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly notificationId: Uuid,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 *
 * @todo Ask about realStart, realEnd for allDay events !
 */
export class CalendarEventDone implements DomainEventShape {
  readonly type = 'CALENDAREVENT_DONE';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly realStart: UTCDateTimeMs,
    readonly realEnd: UTCDateTimeMs,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventInstanceDone implements DomainEventShape {
  readonly type = 'CALENDAREVENT_INSTANCE_DONE';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly instanceId: CalendarEventInstanceId,
    readonly realStart: UTCDateTimeMs,
    readonly realEnd: UTCDateTimeMs,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEventRescheduled implements DomainEventShape {
  readonly type = 'CALENDAREVENT_RESCHEDULED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly start: UTCDateTimeMs,
    // readonly schedule: {
    //   start_date: UTCDate;
    //   end_date: UTCDate;
    //   start_datetime: UTCDateTimeMs;
    //   end_datetime: UTCDateTimeMs;
    // },
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type CalendarEventDomainEvent =
  | CalendarEventCreated
  | CalendarEventAcknowledged
  | CalendarEventDeleted
  | CalendarEventDeleteAcknowledged
  | CalendarEventRestoreAcknowledged
  | CalendarEventInstanceDeleted
  | CalendarEventInstanceDeleteAcknowledged
  | CalendarEventEdited
  | CalendarEventPatchAcknowledged
  | CalendarEventEtagAcknowledged
  | CalendarEventExceptionAdded
  | CalendarEventExceptionAcknowledged
  | CalendarEventExceptionRestoreAcknowledged
  | CalendarEventExceptionEdited
  | CalendarEventExceptionPatchAcknowledged
  | CalendarEventExceptionEtagAcknowledged
  | CalendarEventExceptionCancelled
  | CalendarEventExceptionCancelAcknowledged
  | CalendarEventSnoozed
  | CalendarEventMissed
  | CalendarEventInstanceMissed
  | CalendarEventDone
  | CalendarEventInstanceDone
  | CalendarEventRescheduled;
