import dayjs from 'dayjs';
import { Attendee } from '../../../Contact';
import { UnipileCalendarEventInstanceId } from '../../../Calendar/domain/CalendarEventInstance';
import { AccountType } from '../../../Account';

import { UTCDateTimeMs, Uuid } from '../../../Common';
import { Select } from '../../../Common/utils';

export enum NotificationTypeEnum {
  MAIL_NEW,
  MESSAGE_NEW,
  CALL_MISSED,
  CALL_INCOMING,
  CALL_OUTGOING,
  CALL_NO_ANSWER,
  EVENT_SNOOZED,
  EVENT_MISSED,
}
/**
 * @todo Upgrade ViewNotification to use discriminated unions for types !
 *       See ViewAccount.
 */
export type NotificationType = keyof typeof NotificationTypeEnum;

export type NotificationMetadata = {
  /** Event responsible of the notification's creation, if missed notification */
  origin_event?: {
    id: Uuid | UnipileCalendarEventInstanceId;
    /** Notification responsible of the event's creation, if any */
    origin_notification?: {
      id: Uuid;
      type: NotificationType;
    };
  };
  /** Entity to show in the notification content */
  attached_entity?:
    | {
        type: 'MAIL' | 'EVENT' | 'IM' | 'CALL' | 'IM_THREAD';
        id: Uuid;
        account_type: AccountType;
        account_id: Uuid;
      }
    | {
        type: 'MAIL_DRAFT';
        id: Uuid;
        account_type?: AccountType;
        account_id?: Uuid;
      };
};

/**
 * Projection.
 */
export type ViewNotification = Readonly<{
  id: Uuid /** Aggregate id. */;
  account_id?: Uuid;
  account_type?: AccountType;
  title: string;
  type: NotificationType;
  /**
   * @description Content of the notification
   */
  content: string;
  read_date?: UTCDateTimeMs;
  snooze_date?: UTCDateTimeMs;
  received_date: UTCDateTimeMs;
  attendees?: Attendee[];
  metadata?: NotificationMetadata;
}>;

export type NewNotificationDTO = Omit<Select<ViewNotification, 'type' | 'title' | 'content' | 'received_date'>, 'id'>;

export type UpdateNotificationDTO = Partial<NewNotificationDTO>;

/**
 *
 */
export function createViewNotification(aggregateId: Uuid, values: NewNotificationDTO): ViewNotification {
    /** 
     * @note If you need to enforce invariants not covered by the type system, e.g. : "no empty strings" :
     *          - It must be done upstream, e.g. during the Notification.create command.
     *          - We can add an opaque type, e.g. NonEmptyString.
     */
  //   if (!values.type || !values.title || !values.content || !values.received_date)
  //     throw new Error('Missing values to instantiate a Notification');
  return {
    ...values,
    id: aggregateId,
  };
}

/**
 * Test if a notification has a LIVE status (should be shown in Dashboard).
 */
export function isLive({ snooze_date }: ViewNotification): boolean {
  return !snooze_date || dayjs().diff(dayjs(snooze_date), 'm') > 10;
}

/**
 * Test if a notification has a SNOOZE status (should be hidden from Dashboard).
 */
export function isSnoozed({ snooze_date }: ViewNotification): boolean {
  return !!snooze_date && dayjs().diff(dayjs(snooze_date), 'm') <= 10;
}
