import {
  calendar,
  calendarOutline,
  call,
  callOutline,
  chatbox,
  chatboxOutline,
  chevronForwardOutline,
  close,
  createOutline,
  mail,
  mailOutline,
  pencilOutline,
  personCircle,
  personCircleOutline,
  searchOutline,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import {
  searchLayoutSelector,
  runSearch,
  SearchableEntity,
  selectSearchResult,
  indexStatusSelector,
  Uuid,
  useAppDispatch,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import {
  CircularProgress,
  IconButton,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import SearchMailView from '../Mail/SearchMailView';
import ContactSearchTab from './ContactSearchTab';
import EventSearchTab from './EventSearchTab';
import ImSearchTab from './ImSearchTab';
import MailSearchTab from './MailSearchTab';
import SearchHeaderTab from './SearchHeaderTab';
import SearchCatCard from './SearchCatCard';
import AllMailsList from './AllList/AllMailsList';
import SelectedResultView from './SelectedResultView';
import AllCallsList from './AllList/AllCallsList';
import AllImThreadsList from './AllList/AllImThreadsList';
import AllContactsList from './AllList/AllContactsList';
import AllEventsList from './AllList/AllEventsList';
import AllMailDraftsList from './AllList/AllMailDraftsList';
import { useTranslation } from 'react-i18next';
import CallSearchTab from './CallSearchTab';
import { Skeleton } from '@material-ui/lab';
import LightTabs from '../Common/LightTabs';
import SearchTextField from '../Common/SearchTextField';
import SearchTagsCloud from './SearchTagsCloud';

const useClasses = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  field: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .searchIcon': {
      marginRight: theme.spacing(1),
      fontSize: 24,
    },
  },
  categories: {},
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  content: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function SearchTab() {
  const status = useSelector(indexStatusSelector);
  const c = useClasses();
  if (status === 'INDEXING')
    return (
      <>
        <div className={c.wrapper}>
          <Skeleton variant="rect" style={{ height: 48, borderRadius: 10 }} />
        </div>
      </>
    );

  return <SearchContainer />;
}

export function SearchContainer() {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { query, tab, selected } = useSelector(searchLayoutSelector);
  const [input, setInput] = useState<string>(query);
  const [allList, setAllList] = useState<SearchableEntity | 'mail_draft'>(null);

  useDebounce(() => dispatch(runSearch(input)), 200, [input]);

  const closeAllList = () => {
    setAllList(null);
  };
  return (
    <>
      <div className={c.wrapper}>
        <SearchTextField
          name="search"
          id="search-bar"
          autoComplete="off"
          autoCorrect="off"
          autoFocus={!selected}
          placeholder={'Email, Contact, Evènement, Message...'}
          clickClear={() => setInput('')}
          showClear={input.length > 0}
          value={input}
          onChange={(e) => {
            const value = e.target.value;
            setInput(value);
          }}
          onFocus={() => {
            selected && dispatch(selectSearchResult(null));
            setAllList(null);
          }}
        />
      </div>
      <div className={c.content}>
        {input.length <= 2 ? (
          <>
            {allList === 'mail' && <AllMailsList onBack={closeAllList} />}
            {allList === 'mail_draft' && <AllMailDraftsList onBack={closeAllList} />}
            {allList === 'call' && <AllCallsList onBack={closeAllList} />}
            {allList === 'im' && <AllImThreadsList onBack={closeAllList} />}
            {allList === 'contact' && <AllContactsList onBack={closeAllList} />}
            {allList === 'event' && <AllEventsList onBack={closeAllList} />}
            {!allList && (
              <div className={c.wrapper}>
                {/* <Typography variant="h1" className={c.sectionTitle}>
        Recherches récentes
      </Typography> */}
                <Typography variant="h2" className={c.sectionTitle}>
                  {t('search.titleBrowseAll')}
                </Typography>
                <div className={c.categories}>
                  <SearchCatCard
                    title={t('search.contact_plural')}
                    icon={personCircleOutline}
                    onClick={() => setAllList('contact')}
                  />
                  <SearchCatCard title={t('search.event_plural')} icon={calendarOutline} onClick={() => setAllList('event')} />
                  <SearchCatCard title={t('search.mail_plural')} icon={mailOutline} onClick={() => setAllList('mail')} />
                  <SearchCatCard title={t('search.draft_plural')} icon={createOutline} onClick={() => setAllList('mail_draft')} />
                  <SearchCatCard title={t('search.im_thread_plural')} icon={chatboxOutline} onClick={() => setAllList('im')} />
                  <SearchCatCard title={t('search.call_plural')} icon={callOutline} onClick={() => setAllList('call')} />
                </div>
                <Typography variant="h2" className={c.sectionTitle}>
                  {t('search.titleSearchByTag')}
                </Typography>
                <SearchTagsCloud onTagClick={(tag) => setInput(tag.label)} />
              </div>
            )}
          </>
        ) : (
          <>
            <LightTabs value={tab}>
              <SearchHeaderTab value="mail" selected={'mail' === tab} />
              <SearchHeaderTab value="im" selected={'im' === tab} />
              <SearchHeaderTab value="call" selected={'call' === tab} />
              <SearchHeaderTab value="event" selected={'event' === tab} />
              <SearchHeaderTab value="contact" selected={'contact' === tab} />
            </LightTabs>

            {tab === 'mail' && <MailSearchTab />}
            {tab === 'im' && <ImSearchTab />}
            {tab === 'contact' && <ContactSearchTab />}
            {tab === 'event' && <EventSearchTab />}
            {tab === 'call' && <CallSearchTab />}
          </>
        )}
        <SelectedResultView />
      </div>
    </>
  );
}
