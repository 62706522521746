import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { InvalidOrExpiredRefreshTokenError, sessionSelector, setSettingsDialogTab, Tab, updateUser, useAppDispatch } from '@focus-front/core';
import { Button, createStyles, Grid, makeStyles, Paper, Snackbar, TextField, Theme, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import LoadingButton from '../Common/LoadingButton';
import ProfilePictureUpload from './ProfilePictureUpload';
import SubscriptionCard from './Subscription/SubscriptionCard';

interface ProfileForm {
  first_name: string;
  last_name: string;
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(4),
        width: 400,
      },
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(4),
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    sectionTitle: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
    },
  })
);
export default function UserProfilePage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const session = useSelector(sessionSelector);
  const dispatch = useAppDispatch();

  const [updating, setUpdating] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');

  const [imageChanged, setImageChanged] = useState<boolean>(false);
  const [image, setImage] = useState<string>(session.profile_picture);
  const [imageName, setImageName] = useState<string>(null);

  const profile_picture = useRef<HTMLInputElement>();

  const setTab = (tab: Tab) => {
    dispatch(setSettingsDialogTab(tab));
  }

  const { register, errors, handleSubmit } = useForm<ProfileForm>({
    defaultValues: {
      first_name: session.firstname,
      last_name: session.lastname,
    },
  });

  /**
   * Trigger after choosing a picture to upload
   */
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    // Load the file as dataURL in the state
    const reader = new FileReader();
    const file = e.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase();

    reader.onloadend = () => {
      setImage(reader.result as string);
      setImageName(`profile-pic-${Date.now()}.${extension}`);
      setImageChanged(true);
    };

    reader.readAsDataURL(file);
  };

  /**
   * On click "Delete picture", remove picture data from state
   */
  const handleDeletePicture = () => {
    setImage(null);
  };

  /**
   * On click "Upload new picture...", programmaticly click on hidden file input
   */
  const handleUploadPicture = () => {
    if (profile_picture.current) {
      profile_picture.current.click();
    }
  };

  const onSubmit = async (data: ProfileForm) => {
    // throw new InvalidOrExpiredRefreshTokenError();
    setUpdating('loading');
    try {
      let profile_picture;

      // Case where user updated his profile picture
      if (imageChanged && image)
        profile_picture = {
          filename: imageName,
          data: image.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        };
      // Case where user delete his profile picture
      if (imageChanged && !image) profile_picture = null;

      await dispatch(updateUser(data.first_name, data.last_name, profile_picture));

      setUpdating('success');
    } catch (e) {
      if (e instanceof InvalidOrExpiredRefreshTokenError) {
        throw e;
      }
      setUpdating('error');
    }
  };

  const successClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setUpdating('idle');
  };

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography variant="h2">{t('userSettings.profile.title')}</Typography>
        <Typography variant="subtitle2">{t('userSettings.profile.subtitle')}</Typography>
      </div>

      <Grid container spacing={4}>
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
            <input type="file" ref={profile_picture} onChange={handleImageChange} style={{ display: 'none' }} />
            <TextField
              label={t('userSettings.profile.firstname')}
              name="first_name"
              inputRef={register({
                required: t<string>('profile.form.firstname.required'),
              })}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              error={!!errors.first_name}
              helperText={errors.first_name && errors.first_name.message}
            />
            <TextField
              label={t('userSettings.profile.lastname')}
              name="last_name"
              inputRef={register({
                required: t<string>('profile.form.lastname.required'),
              })}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              error={!!errors.last_name}
              helperText={errors.last_name && errors.last_name.message}
            />
            <LoadingButton loading={updating === 'loading'} variant="outlined" disableElevation type="submit">
              {t('userSettings.profile.save')}
            </LoadingButton>
            {updating === 'error' && <Typography component="p">{t('profile.form.error')}</Typography>}
          </form>
        </Grid>
        <Grid item>
          <ProfilePictureUpload onDelete={handleDeletePicture} onUpload={handleUploadPicture} imgSrc={image} />
        </Grid>
      </Grid>

      {/* <div className={classes.sectionTitle}>
        <Typography variant="h2">{t('subscription.current.title')}</Typography>
        <Typography variant="subtitle2">{t('subscription.current.subtitle')}</Typography>
      </div>

      <SubscriptionCard offer={session.offer} />
      <Button onClick={() => setTab('subscription')} variant="outlined" disableElevation>
        {t('subscription.manage')}
      </Button> */}

      <Snackbar open={updating === 'success'} autoHideDuration={6000} onClose={successClose}>
        <Alert variant="filled" elevation={6} severity="success">
          {t('userSettings.profile.success')}
        </Alert>
      </Snackbar>
    </>
  );
}
