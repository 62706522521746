import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { EventDocument, SearchResult, notificationByAttachedSelector, workEventSelector } from '@focus-front/core';
import Highlighter from '../Search/Highlighter';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IonIcon } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CalendarEventIcon } from '@focus-front/ui';
import { NotificationChip } from '../Common/NotificationChip';
import { EventChip } from '../Common/EventChip';
import Tags from '../Tag/Tags';
dayjs.extend(localizedFormat);

const useClasses = makeStyles((theme) => ({
  root: (props: { isOpen: boolean }) => ({
    padding: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: props.isOpen && theme.palette.background.default,
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    borderLeft: props.isOpen && `solid 4px ${theme.palette.secondary.main}`,
  }),
  left: {
    marginRight: theme.spacing(2),
  },
  center: {
    flex: 1,
  },
  body: {
    flex: 1,
  },
  arrow: {
    fontSize: 24,
    alignSelf: 'flex-end',
    color: theme.palette.grey[700],
  },
  DD: {
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: 30,
    height: 30,
    textAlign: 'center',
    lineHeight: '30px',
    borderRadius: 10,
    margin: '0 auto',
  },
  MMMYY: {
    fontSize: 14,
    textAlign: 'center',
  },
  icon: {
    marginRight: 8,
  },
}));

interface Props {
  onClick: () => void;
  event: SearchResult<EventDocument>;
  index: number;
}

export function EventSearchResult({ onClick, event, index }: Props) {
  const history = useHistory();
  const location = useLocation();
  const notification = useSelector(notificationByAttachedSelector(event.item.id));
  const work_event = useSelector(workEventSelector(event.item.id));
  const { t } = useTranslation();

  const c = useClasses({
    isOpen: location.pathname.includes(event.item.id),
  });

  return (
    <div className={c.root} onClick={onClick} style={{ opacity: event.item.status !== 'PLANNED' ? 0.7 : 1 }}>
      <div className={c.left}>
        <div className={c.DD}>{dayjs(event.item.start).format('DD')}</div>
        <div className={c.MMMYY}>{dayjs(event.item.start).format('MMM YY')}</div>
      </div>
      <div className={c.center}>
        <Box display="flex" alignItems="center">
          <CalendarEventIcon size={24} event={event.item} className={c.icon} />
          <Typography variant="h4" style={{ marginRight: 10 }}>
            {event.matches.find((match) => match.key === 'summary') ? (
              <Highlighter text={event.item.summary || t('calendar.form.defaultTitle')} ranges={event.matches.find((match) => match.key === 'summary').indices} />
            ) : (
              event.item.summary || t('calendar.form.defaultTitle')
            )}
          </Typography>
          {!!notification && <NotificationChip id={notification.id} />}
          {!!work_event && <EventChip id={work_event.id} />}
        </Box>
        <Typography variant="subtitle1">
          {dayjs(event.item.start).format('HH:mm')} -{dayjs(event.item.end).format('HH:mm')}
        </Typography>
        <Typography variant="subtitle2" className={c.body}>
          {event.matches.find((match) => match.key === 'description') ? (
            <Highlighter
              text={event.item.description}
              ranges={event.matches.find((match) => match.key === 'description').indices}
            />
          ) : (
            event.item.description
          )}
        </Typography>
        {event.item.tags.length > 0 && <Tags tags={event.item.tags} listMode />}
      </div>
      <IonIcon className={c.arrow} icon={chevronForward} />
    </div>
  );
}

export default memo(EventSearchResult, (prev, next) => prev.event.item.id === next.event.item.id);
