import { AppBar, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from './Logo';

import { IonIcon } from '@ionic/react';
import HeaderTabs from './HeaderTabs';
import HeaderTab from './HeaderTab';
import NewButton from './NewButton';
import { fileTrayOutline, notificationsOutline, playForwardOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
export default function HeaderLeft() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar variant="dense">
        <Logo onClick={() => history.push('/')} style={{ cursor: 'pointer' }} />
        <NewButton />
        <HeaderTabs value={location.pathname.split('/')[1] || ''}>
          <HeaderTab
            label={t('header.inbox')}
            value="inbox"
            // @ts-expect-error material-ui withStyles bug
            component={Link}
            to="/inbox"
            style={{ width: 200 }}
            icon={<IonIcon icon={fileTrayOutline} />}
          />
          <HeaderTab
            label={t('header.work')}
            value="work"
            id="tour-4"
            // @ts-expect-error material-ui withStyles bug
            component={Link}
            to="/work"
            style={{ width: 200 }}
            icon={<IonIcon icon={playForwardOutline} />}
          />
          {/** This is a workaround tab, not displayed, that does nothing, to prevent the "no connected tab" error.*/}
          <HeaderTab value="" style={{ display: 'none' }} />
        </HeaderTabs>
      </Toolbar>
    </AppBar>
  );
}
