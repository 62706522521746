import { Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { PropsWithChildren } from 'react';

export function ActionAlert({ show, children }: PropsWithChildren<{ show: boolean }>) {
  return (
    <Fade in={show}>
      <Alert style={{ position: 'absolute', bottom: 100, left: '50%', transform: 'translateX(-50%)' }} severity="error">
        {children}
      </Alert>
    </Fade>
  );
}
