import React from 'react';
import logo from './fleches.svg';

interface LogoProps {
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Logo: React.FC<LogoProps> = ({ onClick, style }) => {
  return <img src={logo} onClick={onClick} style={{ ...style, width: 49 }} alt="Unipile"></img>;
};

export default Logo;
