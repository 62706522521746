import { UTCDateTimeMs, Uuid } from '../../../Common';
import { Attendee } from '../../../Contact';

export type CallType = 'INCOMING' | 'OUTGOING' | 'MISSED' | 'NO_ANSWER';

/**
 * Projection
 */
export type ViewCall = Readonly<{
  id: Uuid /** Aggregate id. */;
  account_id: Uuid;
  from_attendee: Attendee;
  to_attendee: Attendee;
  date: UTCDateTimeMs;
  duration?: number;
  provider_id?: string;
  voice_mail_id?: Uuid;
  type: CallType;
}>;

export type ViewMissedCall = ViewCall & { type: 'MISSED' }; // Manqué
export type ViewIncomingCall = ViewCall & { type: 'INCOMING' }; // Recu avec réponse
export type ViewOutgoingCall = ViewCall & { type: 'OUTGOING' }; // Emis avec réponse
export type ViewNoAnswerCall = ViewCall & { type: 'NO_ANSWER' }; // Emis sans réponse

/**
 * Typeguards.
 */

export function isMissedCall(call: ViewCall): call is ViewMissedCall {
  return call.type === 'MISSED';
}

export function isIncomingCall(call: ViewCall): call is ViewIncomingCall {
  return call.type === 'INCOMING';
}

export function isOutgoingCall(call: ViewCall): call is ViewOutgoingCall {
  return call.type === 'OUTGOING';
}

export function isNoAnswerCall(call: ViewCall): call is ViewNoAnswerCall {
  return call.type === 'NO_ANSWER';
}

export type NewCallDTO = Omit<ViewCall, 'id'>;

export type UpdateCallDTO = Partial<Omit<NewCallDTO, 'account_id'>>;

/**
 *
 */
export function createViewCall(aggregateId: Uuid, values: NewCallDTO): ViewCall {
  /** @note Keep this pattern to check at runtime values received from the external world. */
  if (!values.from_attendee || !values.to_attendee || !values.account_id || !values.date || !values.type) {
    throw new Error('Missing values to instantiate a Call');
  }
  return {
    ...values,
    id: aggregateId,
    /**
     * Add default values here if needed.
     */
  };
}
