import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 *  Number of seconds since January 1, 1970, 00:00:00 UTC.
 *
 * @note Opaque type.
 */
declare const validUnixTime: unique symbol;
export type UnixTime = number & { [validUnixTime]: true };

/**
 *  Number of milliseconds since January 1, 1970, 00:00:00 UTC.
 *
 * @note Opaque type.
 */
declare const validUnixTimeMs: unique symbol;
export type UnixTimeMs = number & { [validUnixTimeMs]: true };

/**
 *  ISO 8601 UTC Calendar Date in extended format :
 *
 *  YYYY-MM-DD
 *
 * @note Opaque type.
 */
declare const validUTCDate: unique symbol;
export type UTCDate = string & { [validUTCDate]: true };

/**
 * ISO 8601 UTC Datetime in extended format :
 *
 * YYYY-MM-DDTHH:mm:ssZ
 *
 * @note Opaque type.
 */
declare const validUTCDateTime: unique symbol;
export type UTCDateTime = string & { [validUTCDateTime]: true };

/**
 * ISO 8601 UTC Datetime with milliseconds in extended format :
 *
 * YYYY-MM-DDTHH:mm:ss.SSSZ
 *
 * @note Opaque type.
 */
declare const validUTCDateTimeMs: unique symbol;
export type UTCDateTimeMs = string & { [validUTCDateTimeMs]: true };

/**
 * ISO 8601 UTC Calendar Date in basic format :
 *
 * YYYYMMDD
 *
 * @note Opaque type.
 */
declare const validUTCDateBasic: unique symbol;
export type UTCDateBasic = string & { [validUTCDateBasic]: true };

/**
 * ISO 8601 UTC Datetime in basic format :
 *
 * YYYYMMDDTHHmmssZ
 *
 * @note Opaque type.
 */
declare const validUTCDateTimeBasic: unique symbol;
export type UTCDateTimeBasic = string & { [validUTCDateTimeBasic]: true };

/**
 *  IANA timezone identifier.
 *
 * @see https://www.iana.org/time-zones
 * @see https://unicode-org.github.io/cldr-staging/charts/37/supplemental/zone_tzid.html
 *
 * @note Opaque type.
 */
declare const validTzid: unique symbol;
export type Tzid = string & { [validTzid]: true };

/**
 * @note Based on dayjs timezone plugin internal function getDateTimeFormat.
 *       See https://github.com/iamkun/dayjs/blob/dev/src/plugin/timezone/index.js
 *
 * @throws RangeError: Invalid time zone specified if Intl.DateTimeFormat fails.
 */
const dtfCache: Record<string, true> = {};
export function toTzid(tzid: string): Tzid {
  if (!dtfCache[tzid]) {
    Intl.DateTimeFormat(undefined, { timeZone: tzid });
    dtfCache[tzid] = true;
  }
  return tzid as Tzid;
}

/**
 * @todo Consider moment-tz as an alternative that is already a transitive
 *       dependency of the project.
 *       Intl.DateTimeFormat is pretty slow, but, for our use case, once the
 *       cache is warmed up with the few values we're going to be checking over
 *       and over, it's probably good enough.
 *       See https://www.measurethat.net/Benchmarks/Show/17261/0/moment-tests-tz
 */
export function isTzid(tzid: string): tzid is Tzid {
  if (!dtfCache[tzid]) {
    try {
      Intl.DateTimeFormat(undefined, { timeZone: tzid });
      dtfCache[tzid] = true;
      return true;
    } catch {
      return false;
    }
  }
  return true;
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUnixTime(date: number | string | dayjs.Dayjs, local = false): UnixTime {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  return parsed.unix() as UnixTime; // UTC.
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUnixTimeMs(date: number | string | dayjs.Dayjs, local = false): UnixTimeMs {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  return parsed.valueOf() as UnixTimeMs; // UTC.
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUTCDate(date: number | string | dayjs.Dayjs, local = false): UTCDate {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  //   return parsed.format('YYYY-MM-DD') as UTCDate; // WRONG ! This outputs in local time.
  return parsed.toISOString().slice(0, 10) as UTCDate; // UTC.
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUTCDateTime(date: number | string | dayjs.Dayjs, local = false): UTCDateTime {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  return (parsed.toISOString().slice(0, 19) + 'Z') as UTCDateTime;
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUTCDateTimeMs(date: number | string | dayjs.Dayjs | undefined | Date, local = false): UTCDateTimeMs {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  return parsed.toISOString() as UTCDateTimeMs;
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUTCDateBasic(date: number | string | dayjs.Dayjs, local = false): UTCDateBasic {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  return parsed.utc().format('YYYYMMDD') as UTCDateBasic;
}

/**
 * @note Pass local = true to handle string date without timezone designator
 *       as local, otherwise it will be parsed as if UTC.
 */
export function toUTCDateTimeBasic(date: number | string | dayjs.Dayjs, local = false): UTCDateTimeBasic {
  const parsed = local ? dayjs(date) : dayjs.utc(date);

  if (!parsed.isValid()) {
    throw new Error(`${date} is not a valid date.`);
  }
  return parsed.utc().format('YYYYMMDDTHHmmss[Z]') as UTCDateTimeBasic;
}
