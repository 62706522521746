import React from 'react';
import { Box, Button, ButtonProps, withStyles } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { ArrowRightAlt } from '@material-ui/icons';

export const NavButton = withStyles((theme) => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
    },
  },
}))((props: ButtonProps) => <Button variant="text" {...props}></Button>);

export const BackButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <NavButton {...props} startIcon={<IonIcon icon={arrowBackOutline} />}>
      {t('wizard.back')}
    </NavButton>
  );
};

export const NextButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        color="secondary"
        style={{ minWidth: 120, textTransform: 'capitalize' }}
        endIcon={<IonIcon icon={arrowForwardOutline} />}
        {...props}
      >
        {props.children || t('wizard.next')}
      </Button>
    </Box>
  );
};
