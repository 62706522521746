import dayjs from 'dayjs';
import { alertCircle, checkmark, ellipsisHorizontal } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Attendee, displayAttendeeName, ImSendStatus, useAppDispatch, ViewIm } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import AttendeeAvatar from '../Contact/AttendeeAvatar';
import ImImageAttachment from './ImImageAttachment';
import ImVideoAttachment from './ImVideoAttachment';
import ImAudioAttachment from './ImAudioAttachment';
import ImDocumentAttachment from './ImDocumentAttachment';

interface Props {
  /**
   * Is the user talking or someone else, define the side et appearance of the bubble
   */
  self?: boolean;
  im: ViewIm;
  attendee: Attendee;
  focused?: boolean;
  show_status?: boolean;
  show_date?: boolean;
  show_avatar?: boolean;
  index: number;
}

const useClasses = makeStyles<Theme, Partial<Props>>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  container: ({ self, show_avatar }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: self ? 'flex-end' : 'flex-start',
    width: '100%',
    paddingBottom: 2,
  }),
  bubble: ({ self, focused, show_avatar }) => ({
    borderRadius: 16,
    backgroundColor: self ? theme.palette.secondary.main : theme.palette.background.paper,
    border: focused && 'solid 2px ' + theme.palette.warning.main,
    maxWidth: '70%',
    marginLeft: show_avatar ? theme.spacing(1) : 30 + theme.spacing(1),
    marginRight: self ? 0 : theme.spacing(1),
    borderBottomLeftRadius: show_avatar && !self ? 0 : 16,
    position: 'relative',
    marginBottom: 2,
  }),
  text: ({ self, focused, show_avatar }) => ({
    color: self ? theme.palette.secondary.contrastText : theme.palette.text.primary,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  }),
  date: ({ self }) => ({
    textAlign: 'center',
    textTransform: 'uppercase',
    opacity: 0.7,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
  image: () => ({
    maxWidth: 200,
    height: 'auto',
  }),
  attachment: ({ self }) => ({
    display: 'grid',
    gridGap: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    color: self ? theme.palette.secondary.contrastText : theme.palette.text.primary,
  }),
  icon: {
    color: theme.palette.background.default,
    position: 'absolute',
    bottom: 5,
    right: 5,
    '& ion-icon': {
      fontSize: '1.2rem',
      display: 'block',
    },
  },
  name: {
    marginLeft: theme.spacing(1),
    opacity: 0.5,
  },
}));

function getStatus(status: ImSendStatus) {
  switch (status) {
    case 'SENDING':
      return <IonIcon icon={ellipsisHorizontal} />;
    case 'SENDING_ERROR':
      return <IonIcon icon={alertCircle} />;
    case 'SENT':
      return <IonIcon icon={checkmark} />;
    default:
      return null;
  }
}

function urlify(text: string): string {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => '<a target="_blank" href="' + url + '">' + url + '</a>');
}

function ImBubble({
  self = false,
  im,
  attendee,
  focused = false,
  show_status,
  show_date = false,
  show_avatar = false,
  index,
}: Props) {
  const c = useClasses({ self, focused, show_avatar });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div data-item>
      {show_date && (
        <div className={c.date}>
          {dayjs(im.date).isToday()
            ? dayjs(im.date).format(`[${t('humanizedDates.today')} ${t('humanizedDates.at')}] HH:mm`)
            : dayjs(im.date).format(`DD MMM YYYY [${t('humanizedDates.at')}] HH:mm`)}
        </div>
      )}
      <div className={c.wrapper}>
        {!self && show_avatar && (
          <AttendeeAvatar
            size="tiny"
            display_name={displayAttendeeName(attendee)}
            avatar_url={attendee.profile_picture}
            style={{ marginBottom: 24 }}
          />
        )}
        <div className={c.container}>
          {im.attachments &&
            im.attachments.map((att) => (
              <div className={c.bubble} key={att.id}>
                {att.type === 'IMAGE' && <ImImageAttachment account_id={im.account_id} attachment={att} />}
                {att.type === 'VIDEO' && <ImVideoAttachment account_id={im.account_id} attachment={att} />}
                {att.type === 'AUDIO' && <ImAudioAttachment account_id={im.account_id} attachment={att} self={self} />}
                {att.type === 'DOCUMENT' && <ImDocumentAttachment account_id={im.account_id} attachment={att} />}
                {!['AUDIO', 'DOCUMENT', 'IMAGE', 'VIDEO'].includes(att.type) && (
                  <Typography variant="body2">{t('attachment.messageNotSupported')}</Typography>
                )}
              </div>
            ))}

          <div
            className={c.bubble}
            // style={{ maxWidth: im.attachments?.find((att) => att.type === 'IMAGE' || att.type === 'VIDEO') ? '250px' : '70%' }}
          >
            {im.body && <div className={c.text} dangerouslySetInnerHTML={{ __html: urlify(im.body) }}></div>}
            {self && show_status && <div className={c.icon}>{getStatus(im.send_status)}</div>}
          </div>
          {!self && show_avatar && (
            <Typography variant="body2" className={c.name}>
              {displayAttendeeName(attendee)}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ImBubble, (prev, next) => prev.im.id === next.im.id);
