import { IonIcon } from "@ionic/react";
import { IconButton } from "@material-ui/core";
import { documentsOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import MessageModelPicker from "./MessageModelPicker";
import { createNewMessageModelFromContent, PreviewMessageModel, settingsDialogSelector, useAppDispatch, ViewMessageModel } from "@focus-front/core";
import { useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

type Props = {
  messageBody: string;
  applyMessageModel: (messageModel: ViewMessageModel) => void;
  previewMessageModel: (messageModel: ViewMessageModel) => Promise<PreviewMessageModel>;
};

function MessageModelButton({ messageBody, applyMessageModel, previewMessageModel }: Props & React.HTMLAttributes<HTMLDivElement>) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingMessageModel, setPendingMessageModel] = useState(null);
  const settings = useSelector(settingsDialogSelector);

  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const prevAnchor = useRef();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (messageModel?: ViewMessageModel) => {
    if (!messageModel) return setAnchorEl(null);
    if (messageBody === '') handleConfirm(messageModel);
    else {
      try {
        await confirm({
          title: t('message_model.picker.confirmation.title'),
          description: t('message_model.picker.confirmation.description'),
          confirmationText: t('message_model.picker.confirmation.confirm'),
          cancellationText: t('message_model.picker.confirmation.cancel'),
          confirmationButtonProps: {
            color: 'secondary',
          },
        });
        handleConfirm(messageModel)
      } catch (error) {
        handleCancel();
      }
    }

    setAnchorEl(null);
  };

  const handleManageModels = async () => {
    setAnchorEl(null);
  }

  const handleCreateNewModel = async () => {
    dispatch(createNewMessageModelFromContent(messageBody))
    setAnchorEl(null);
  }

  const handleConfirm = (messageModel?: ViewMessageModel) => {
    applyMessageModel(messageModel || pendingMessageModel);
  }

  const handleCancel = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (anchorEl !== null) prevAnchor.current = anchorEl;
  }, [anchorEl]);

  useEffect(() => {
    if (!settings.open && settings.tab.startsWith('message_models')) {
      setAnchorEl(prevAnchor.current);
    }
  }, [settings.open]);

  return (
    <>
      <IconButton
        style={{ marginRight: '5px', padding: '7px' }}
        onClick={handleClick}
        >
        {/* <LibraryBooks /> */}
        <IonIcon icon={documentsOutline} />
      </IconButton>
      <MessageModelPicker anchorEl={anchorEl} handleClose={handleClose} handleManageModels={handleManageModels} handleCreateNewModel={handleCreateNewModel} previewMessageModel={previewMessageModel} />
    </>
  );
}

export default React.memo(MessageModelButton);
