import { Extension } from '@tiptap/core';

export const StyleAttr = Extension.create({
  addGlobalAttributes() {
    return [
      {
        types: [
          'heading',
          'paragraph',
          'link',
          'image',
          'tableCell',
          'table',
          'tableRow',
          'tableHeader',
          'textStyle',
          'div',
          'blockquote',
          'code',
        ],
        attributes: {
          style: {
            default: '',
            renderHTML: (attributes) =>
              attributes.style && {
                style: attributes.style,
              },
            parseHTML: (element) => element.style.cssText || '',
          },
          width: {
            default: null,
            renderHTML: (attributes) => ({
              width: attributes.width,
            }),
            parseHTML: (element) => element.getAttribute('width') || null,
          },
          height: {
            default: null,
            renderHTML: (attributes) => {
              // console.log(attributes.height);
              return {
                height: attributes.height,
              };
            },
            parseHTML: (element) => {
              //console.log(element.getAttribute('height'), element);
              return element.getAttribute('height') || null;
            },
          },
          bgcolor: {
            default: null,
            renderHTML: (attributes) => ({
              bgcolor: attributes.bgcolor,
            }),
            parseHTML: (element) => element.getAttribute('bgcolor') || null,
          },
          background: {
            default: null,
            renderHTML: (attributes) => ({
              background: attributes.background,
            }),
            parseHTML: (element) => element.getAttribute('background') || null,
          },
          align: {
            default: null,
            renderHTML: (attributes) => ({
              align: attributes.align,
            }),
            parseHTML: (element) => element.getAttribute('align') || null,
          },
          valign: {
            default: null,
            renderHTML: (attributes) => ({
              valign: attributes.valign,
            }),
            parseHTML: (element) => element.getAttribute('valign') || null,
          },
        },
      },
    ];
  },
});
