import React from 'react';

interface Props {
  text: string;
  ranges: [number, number][];
  trimSize?: number;
}
export default function Highlighter({ text, ranges, trimSize }: Props) {
  function getEndSlice() {
    const last_range = ranges[ranges.length - 1];
    const from = last_range[1] + 1;
    const to = trimSize ? Math.min(from + trimSize, text.length) : text.length;

    let slice = text.slice(from, to);
    if (trimSize && from + trimSize < text.length) slice += '...';

    return slice;
  }

  function getHighlightedRange(range: [number, number]) {
    return text.slice(range[0], range[1] + 1);
  }

  function getBetweenSlice(range: [number, number], index: number) {
    // If not the first range, the "not highlighted" part start at the end of the previous range
    const from = index > 0 ? ranges[index - 1][1] + 1 : 0;
    const to = range[0];
    const slice = text.slice(from, to);

    if (!trimSize || slice.length < trimSize * 2 + 3) return slice;

    const trimmed = slice.slice(0, trimSize) + '...' + slice.slice(slice.length - trimSize, slice.length);
    return trimmed;
  }

  const content = ranges.map((range, index) => (
    <>
      {getBetweenSlice(range, index)}
      <em>{getHighlightedRange(range)}</em>
    </>
  ));

  return (
    <span className="hl">
      {content}
      {getEndSlice()}
    </span>
  );
}
