export const recoveryOptions = [
  'warnUnsupported',
  'checkConnectivity',
  'restart',
  'localReset',
  'accountUndo',
  'accountReset',
] as const;

export type RecoveryOption = typeof recoveryOptions[number];

/**
 * @note We are currently using ErrorRecommendations as a 'crash-list' for the
 *       the recovery page. Only errors listed here will trigger a crash/recovery
 *       attempt.
 */
export const ErrorRecommendations: Record<string, RecoveryOption[]> = {
  /** Generic errors */
  //   TypeError: ['restart'],
  /** Debug errors */
  DebugRecoveryPageRequestedError: ['restart', 'localReset'],
  /** Unipile errors */
  IdbInitializationError: ['localReset', 'warnUnsupported'],
  UnexpectedError: ['restart', 'localReset'],
  InvalidAccountError: ['restart', 'localReset'],
  //   UnsupportedDevice: ['restart'],
  //   NoPermissionsError: ['restart'] /** todo */,
  //   NetworkError: ['restart'],
  UnknownAggregateIdError: ['localReset', 'accountUndo', 'accountReset'],
  DomainEventMergeError: ['localReset', 'accountUndo', 'accountReset'],
  MissingAggregateIdError: ['restart', 'localReset'] /** Not much the user can do beside asking for a patch. */,
  UnexpectedAggregateIdError: ['restart', 'localReset', 'accountUndo'],
  UnexpectedDomainEventVersionError: ['restart', 'localReset', 'accountUndo', 'accountReset'],
  UnknownDomainEventIdError: ['restart', 'localReset', 'accountReset'],
  BrokenProjectorError: ['localReset', 'accountUndo', 'accountReset'],
  InvalidPasswordError: ['restart', 'localReset'],
  UnexpectedKeyConflictError: ['restart', 'localReset', 'accountReset'],
  KeyCannotBeCreatedError: ['checkConnectivity', 'restart'],
  UnavailableCryptoApiError: ['warnUnsupported', 'restart'],
  //   UnauthorizedError: ['restart'],
  //   SessionEndedError: ['restart'],
  UninitializedKeychainError: ['restart', 'localReset'],
  //   InvalidCredentialsLoginError: ['restart'],
  CoreServerError: ['restart'] /** todo */,
  //   InvalidCredentialsError: ['restart', 'localReset'] /** todo */,
  //   MissingCredentialsError: ['restart', 'localReset'] /** todo */,
  ProxyServerError: ['restart'] /** todo */,
  /** Unipile errors : ScraperService, Queue */
  //   IncorrectCredentialsError: ['restart'] /** todo */,
  //   IncorrectUrlError: ['restart'] /** todo */,
  //   MissingDomElementError: ['restart'] /** todo */,
  //   NavigationError: ['restart'] /** todo */,
  //   DownloadError: ['restart'] /** todo */,
  //   CanceledError: ['restart'] /** todo */,
  /** Unipile errors : Im */
  //   CantSendMessageError: ['checkConnectivity', 'warnUnsupported', 'restart'] /** todo */,
  MissingThreadError: ['localReset', 'accountUndo', 'accountReset'] /** todo */,
  /** Unipile errors : Mail */
  //   CantSendMailError: ['checkConnectivity', 'warnUnsupported', 'restart'] /** todo */,
};

/**
 *
 */
export class DebugRecoveryPageRequestedError extends Error {
  constructor() {
    super(`Recovery page requested !`);
    this.name = 'DebugRecoveryPageRequestedError';
  }
}
