import { Idb } from '../../../Common/infra/services/idb';
import { Uuid } from '../../../Common';
import { MailMeta, MailReference, PartialViewMail } from '../../domain';
import { notEmpty } from '../../../Common/utils';
import { Taggable, ViewTagRelationRepo, ViewTagRepo, ViewTagRelation } from '../../../Tag';
/**
 * Repository handling Mails' Metadata persistance in Indexed DB
 *
 *
 */
export class IdbMailMetaRepo {
  constructor(private readonly viewTagRelationRepo: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  /**
   * Remove all mails metadata
   */
  async clear(): Promise<void> {
    return Idb.clear('mailMeta');
  }

  /**
   * Remove a mail's metadata
   */
  async remove(id: Uuid): Promise<void> {
    return Idb.delete('mailMeta', id);
  }

  /**
   * Remove metadata of multiple mails
   */
  async removeMany(ids: string[]): Promise<void> {
    const tx = Idb.transaction('mailMeta', 'readwrite', { durability: 'relaxed' });
    for (let i = 0, length = ids.length; i < length; ++i) {
      tx.store.delete(ids[i]);
    }
    return tx.done;
  }

  /**
   * Remove metadata of all mails of a specific account
   */
  async removeByAccount(account_id: Uuid): Promise<void> {
    let cursor = await Idb.transaction('mailMeta', 'readwrite', { durability: 'relaxed' })
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  /**
   * Add metadata for a mail
   */
  async add(data: MailMeta): Promise<void> {
    await Idb.put('mailMeta', data);
  }

  /**
   * Add metadata for multiple mails
   */
  async addMany(data: MailMeta[]): Promise<void> {
    const tx = Idb.transaction('mailMeta', 'readwrite', { durability: 'relaxed' });
    for (let i = 0, length = data.length; i < length; ++i) {
      tx.store.put(data[i]);
    }
    return tx.done;
  }

  /**
   * Get metadata of a mail by its id
   */
  async get(id: Uuid): Promise<(PartialViewMail & Taggable) | null> {
    const meta = await Idb.get('mailMeta', id);
    if (meta) {
      const ref = await Idb.get('viewMails', id);
      if (ref) {
        const tags = await this.viewTagRelationRepo.getTagsByElement('MAIL', id);
        return { ...meta, ...ref, tags };
      }
    }
    return null;
  }

  private async _getMatchingRefsAndTags(metas: MailMeta[]): Promise<(PartialViewMail & Taggable)[]> {
    const refReq: Promise<MailReference | undefined>[] = [];
    const refTx = Idb.transaction('viewMails', 'readonly', { durability: 'relaxed' }).store;

    for (let i = 0, length = metas.length; i < length; ++i) {
      refReq[i] = refTx.get(metas[i].id);
    }

    const mails = (
      await Promise.all(
        refReq.map(async (req, i) => {
          const ref = await req;
          if (!ref) return undefined;
          return { ...metas[i], ...ref };
        })
      )
    ).filter(notEmpty);

    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    for (let i = 0, length = mails.length; i < length; ++i) {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['MAIL', metas[i].id]));
    }

    const allTags = await this.viewTagRepo.getAll();

    const mailsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...mails[i], tags };
      })
    );

    return mailsWithTags;
  }

  /**
   * Get metadata of all mails by date
   */
  async getAllByDate(size: number, offset: number): Promise<(PartialViewMail & Taggable)[]> {
    let cursor = await Idb.transaction('mailMeta', 'readonly').store.index('by-date').openCursor(undefined, 'prev');
    const results: MailMeta[] = [];
    let hasSkipped = false;

    while (results.length < size && cursor) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        cursor = (await cursor?.advance(offset)) ?? null;
      }
      if (!cursor) break;
      results.push(cursor.value);
      cursor = await cursor.continue();
    }
    return this._getMatchingRefsAndTags(results);
  }

  async getByAccount(account_id: Uuid): Promise<(PartialViewMail & Taggable)[]> {
    const metas = await Idb.getAllFromIndex('mailMeta', 'by-account', IDBKeyRange.only(account_id));
    return this._getMatchingRefsAndTags(metas);
  }

  async getAll(): Promise<(PartialViewMail & Taggable)[]> {
    const metas = await Idb.getAll('mailMeta');
    return this._getMatchingRefsAndTags(metas);
  }

  async getAllIds(): Promise<string[]> {
    return Idb.getAllKeys('mailMeta');
  }
}
