import { arrowBack, listOutline, locationOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IonIcon } from '@ionic/react';
import { Button, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { CreateOutlined } from '@material-ui/icons';
import {
  workEventSelector,
  editCalendarEvent,
  mockUuid,
  openViewEvent,
  Uuid,
  ViewCalendarEvent,
  useAppDispatch,
} from '@focus-front/core';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import EventStatusBar from './EventStatusBar';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
  },
  titles: {
    marginTop: 13,
    flex: 1,
  },
  datetime: {
    color: theme.palette.grey[600],
  },
  content: {
    overflowY: 'scroll',
    flex: 1,
    padding: theme.spacing(2),
  },
  navigationBtn: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
      marginLeft: 10,
    },
  },
  details: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& ion-icon': {
      fontSize: 24,
      marginRight: 10,
      color: theme.palette.grey[700],
    },
    '& .MuiTypography-root': {
      flex: 1,
    },
  },
  preview: {
    height: 300,
    overflowY: 'scroll',
    marginTop: theme.spacing(2),
    borderRadius: 10,
    border: `solid 1px ${theme.palette.divider}`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

interface Props {
  event_id: Uuid;
  onBack: () => void;
}

export default function SearchEventView({ event_id, onBack }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  const [event, setEvent] = useState<ViewCalendarEvent>(null);
  const work_event = useSelector(workEventSelector(event_id));
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const e = await dispatch(openViewEvent(mockUuid(event_id)));
      setEvent(e);
    })();
  }, [event_id]);

  const edit = () => {
    dispatch(editCalendarEvent('CALENDAR', event_id));
  };

  if (!event) return null;
  return (
    <div className={c.root}>
      <div className={c.header}>
        <IconButton onClick={onBack}>
          <IonIcon icon={arrowBack} />
        </IconButton>
        <div className={c.titles}>
          <Typography variant="h1">{event.summary|| t('calendar.form.defaultTitle')}</Typography>
          <Typography variant="subtitle1" className={c.datetime}>
            {event.all_day
              ? dayjs(event.start).format('dddd D MMMM')
              : `${dayjs(event.start).format('dddd D MMMM, HH:mm')} - ${dayjs(event.end).format('HH:mm')}`}
          </Typography>
        </div>
        <div>
          <Tooltip title={t('event.action.editTooltip')} enterDelay={800}>
            <Button variant="text" className={c.navigationBtn} onClick={edit}>
              {t('event.action.edit')}
              <CreateOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      {work_event ? <div></div> : <EventStatusBar event={event} />}
      <div className={c.content}>
        {!!event.description && (
          <div className={c.details}>
            <IonIcon icon={listOutline} />
            <Typography variant="body1">{event.description}</Typography>
          </div>
        )}

        {!!event.location && (
          <div className={c.details}>
            <IonIcon icon={locationOutline} />
            <Typography variant="body1">
              <a href={`https://www.google.fr/maps/place/${event.location}`} target="_blank">
                {event.location}
              </a>
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
