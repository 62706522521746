import { settingsDialogSelector } from '@focus-front/core';
import { makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LightTab from '../Common/LightTab';
import LightTabs from '../Common/LightTabs';
import SignaturesPage from './SignaturesPage';

export default function MailSettings() {
  const { t } = useTranslation();
  const { tab } = useSelector(settingsDialogSelector);

  const selected_tab = tab.split('/')[1];
  return (
    <div>
      <LightTabs value={selected_tab}>
        <LightTab value="signatures" label={t('settings.menu.signatures')}></LightTab>
      </LightTabs>
      {selected_tab === 'signatures' && <SignaturesPage />}
    </div>
  );
}
