import { getAllMessageModels, getModelSearchable, getSortedMessageModels, MessageModelSortOptions, mockUuid, openMessageModelSettings, openSettingsDialog, PreviewMessageModel, selectableMessageModelsSelector, setMessageModels, sortMessageModels, Taggable, toUTCDateTimeMs, useAppDispatch, ViewMessageModel } from "@focus-front/core";
import { InputBase, makeStyles, Popper } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { SyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { usePickerClasses } from "../Common/usePickerClasses";
import Tags from "../Tag/Tags";
import MessageModelPreview from "./MessageModelPreview";
import SortOptions from "./SortOptions";

const useClasses = makeStyles((theme) => ({
  input: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    alignItems: 'flex-end',
  },
  inputBase: {
    borderBottom: 'none',
  },
  inputWrapper: {
    position: 'relative',
    borderBottom: '1px solid #dfe2e5',
  },
}));

type Props = {
  anchorEl: Element;
  handleClose: (messageModel?: ViewMessageModel) => void;
  handleManageModels: () => void;
  handleCreateNewModel: () => void;
  previewMessageModel: (messageModel: ViewMessageModel) => Promise<PreviewMessageModel>;
};

export type MessageModelOption = ViewMessageModel & Taggable & { inputValue?: string, highlighted?: boolean };

const filter = createFilterOptions<MessageModelOption>();

export default function MessageModelPicker({anchorEl, handleClose, handleManageModels, handleCreateNewModel, previewMessageModel}: Props) {
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const classes = usePickerClasses();
  const { t } = useTranslation();
  const c = useClasses();

  const [anchorModelEl, setAnchorModelEl] = useState(null);
  const [pendingValue, setPendingValue] = useState<MessageModelOption>();
  const [previewContent, setPreviewContent] = useState<PreviewMessageModel>(null);
  const [previewModel, setPreviewModel] = useState(null);

  const messageModels = useSelector(selectableMessageModelsSelector);

  const [height, setHeight] = useState(0);

  const setModels = (messageModels: (ViewMessageModel & Taggable)[]) => {
    dispatch(setMessageModels(messageModels));
  }

  const autocompleteRef = useRef(null);

  const handleHover = async (event, value: MessageModelOption, showPreview) => {
    const target = event.currentTarget;

    if (showPreview) {
      const preview = await previewMessageModel(value);
      setPreviewModel(value);
      setPreviewContent(preview);
      setAnchorModelEl(target);
    }
    else setAnchorModelEl(null);
  };

  const handleLeave = () => {
    setAnchorModelEl(null);
  }

  const handleEdit = () => {
    setAnchorModelEl(null);
    dispatch(openMessageModelSettings(previewContent.id));
    handleManageModels();
  }

  const handleSort = (sortOptions: MessageModelSortOptions) => {
    dispatch(sortMessageModels(sortOptions));
  }

  const handleUse = (messageModel: MessageModelOption) => {
    setPendingValue(messageModel);
    handleClose(messageModel);
  }

  const handleUseFromPreview = () => {
    handleUse(previewModel);
  }

  const onAutocompleteClose = (event, reason) => {
    if (reason === 'toggleInput') return;

    setPendingValue(undefined);

    // @todo This condition, preventing the picker to be closed when clicking on the sort options,
    // needs to be reworked to not depend on the MUI classes.
    if (event.relatedTarget?.classList.contains('MuiSelect-selectMenu') || event.relatedTarget?.classList.contains('MuiPopover-paper')) return;

    setAnchorModelEl(null);
    handleClose();
  };

  useEffect(() => {
    (async () => {
      await dispatch(getSortedMessageModels());
    })();
  }, [open]);

  useLayoutEffect(() => {
    /**
     * @todo: fix having to use a timeout here to get the height of the autocomplete. Without the timeout, the height is 0.
     * height is set to clientHeight + 2px to get the correct height for the popper. If we use the ref on the popper, the height is always 0.
     */
    setTimeout(() => {
      setHeight(autocompleteRef.current?.clientHeight + 2);
    }, 300);
  }, [open]);

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="top" className={classes.popper + ' ' + classes.lowerPopper} style={{ height: height }} >
        <div ref={autocompleteRef}>
          <Autocomplete
            open={true}
            onClose={onAutocompleteClose}
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            freeSolo={true}
            value={pendingValue}
            onChange={(event: SyntheticEvent, newValue: MessageModelOption, reason) => {
              if (reason === 'remove-option' && event.type === 'keydown') return;
              setAnchorModelEl(null);

              if (newValue.id === 'CREATE') {
                handleCreateNewModel();
                return;
              }
              else if (newValue.id === 'MANAGE') {
                dispatch(openSettingsDialog('message_models'));
                handleManageModels();
              }
              else {
                handleUse(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              filtered.push(
                {
                  id: mockUuid('CREATE'),
                  name: t('message_model.picker.create'),
                  inputValue: params.inputValue,
                  highlighted: false,
                  create_date: toUTCDateTimeMs(0),
                  use_count: 0,
                  tags: [],
                },
                {
                  id: mockUuid('MANAGE'),
                  name: t('message_model.picker.manage'),
                  inputValue: params.inputValue,
                  highlighted: false,
                  create_date: toUTCDateTimeMs(0),
                  use_count: 0,
                  tags: [],
                }
              );

              return filtered;
            }}
            disablePortal
            renderTags={() => null}
            noOptionsText={t('message_model.picker.noResult')}
            renderOption={(option,) => (
              <React.Fragment>
                <div className={classes.text} style={{ fontWeight: option.id === 'CREATE' || option.id === 'MANAGE' ? 700 : 'inherit' }}>
                  {option.name}
                </div>
                <Tags tags={option.tags} maxVisible={1} />
              </React.Fragment>
            )}
            onHighlightChange={(event, newHighlighted, reason) => {
              if (reason === 'mouse' || reason === 'keyboard') {
                handleHover(event,newHighlighted, newHighlighted.id !== 'CREATE' && newHighlighted.id !== 'MANAGE');
                setModels(messageModels.map(messageModel => messageModel.id === newHighlighted.id && newHighlighted.id !== 'CREATE' && newHighlighted.id !== 'MANAGE' ? {...messageModel, highlighted: true} : {...messageModel, highlighted: false}));
              }
            }}
            onMouseLeave={() => handleLeave()}
            options={messageModels}
            getOptionLabel={(option) => getModelSearchable(option, false)}
            renderInput={(params) => (
              <div className={c.inputWrapper}>
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  disabled={!messageModels.length}
                  className={classes.inputBase + ' ' + c.inputBase}
                />
                { messageModels.length > 0 && <SortOptions handleSort={handleSort} /> }
                <MessageModelPreview anchorEl={anchorModelEl} preview={previewContent} handleEdit={handleEdit} handleUse={handleUseFromPreview} />
              </div>
            )}
          />
          </div>
      </Popper>
    </>
  );
}