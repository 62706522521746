import React, { useEffect, useState } from 'react';

import { getMailDraftView, isEvent, mailDraftWrite, ViewMailDraft, useAppDispatch, updateCalendarEvent } from '@focus-front/core';

import MailForm, { MailFormAttendee } from '../Mail/MailForm';
import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';
import { useTranslation } from 'react-i18next';
import { ActionAlert } from './ActionAlert';

export default function MailDraftWriteAction({ onClose, onDone, context }: ActionModalProps) {
  const dispatch = useAppDispatch();
  const [draft, setDraft] = useState<ViewMailDraft>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const { t } = useTranslation();

  const mail_draft_id = context.metadata.attached_entity.id;

  useEffect(() => {
    (async () => {
      const draft = await dispatch(getMailDraftView(mail_draft_id));
      setDraft(draft);
    })();
    return onClose;
  }, [mail_draft_id]);

  const onSend = async (draft: ViewMailDraft) => {
    setLoading(true);
    const { error } = await dispatch(mailDraftWrite(context, draft));
    if (error) {
      setLoading(false);
      setShowError(true);
      setTimeout(() => setShowError(false), 4000);
    } else onDone('write');
  };

  const onToUpdate = (to: MailFormAttendee[]) => {
    if (isEvent(context)) {
      dispatch(
        updateCalendarEvent(context.id, {
          /** @todo Support other type of calendar and other kind of events, this is hard coded as a cope-out. */
          type: 'UNIPILE',
          kind: 'SINGLE',
          summary: t('event.defaultTitle.mail_draftEdited', { attendees: to.map((att) => att.display_name).join(', ') }),
        })
      );
    }
  };

  return (
    <ActionModal loading={loading} onClose={onClose} expanded={true} expandable={false}>
      {draft && <MailForm defaultValues={draft} onSend={onSend} onToUpdate={onToUpdate} action_id="write" />}
      <ActionAlert show={!!showError}>{t('error.mail_cantsend')}</ActionAlert>
    </ActionModal>
  );
}
