// import { ApiDomainEvent } from '@focus-front/api';
import { DomainEvent, DomainEventShape, DomainEventId } from '../../domain/DomainEvent';
import { Publisher } from '../../domain/Publisher';
import { ApiDomainEventDraft, BackupCursors, BackupRepo } from '../../domain/BackupRepo';
import { DomainEventStore, UnknownDomainEventIdError, Committed, getDigest } from '../../domain/DomainEventStore';
import { Projectionist, ProjectionistConfig } from '../../domain/Projectionist';
import { Uuid } from '../../domain/Uuid';
import { UTCDateTime } from '../../domain/Date';
import { Heap, MaybePromise, prettyPrint } from '../../utils';
import { dumpToFile } from '../../utils/debug';
import { asyncPipe, identity } from '../../utils/function';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { NetworkError } from '../../../Common/app/Errors';
import { CoreServerError } from '../../../User/infra/services/CoreApiTypes';
import { InvalidOrExpiredRefreshTokenError } from '../../../User';
import { ProjectorError } from '../../domain/Projector';

dayjs.extend(utc);

/**
 * @todo Consider moving branch Uuid to ApiDomainEvent.
 */
export interface ApiDomainEvent {
  id: number;
  content: string;
  user_id: number;
  // branch: Uuid; // node ?
  group_id?: number;
  team_id?: number;
  is_sync: boolean;
  creation_date: UTCDateTime;
}

/**
 * A DomainEvent that has been tagged with its branch and position in its EventStore of origin.
 *
 * At the moment we have :
 *  - DomainEvent             -> a naked DomainEvent.
 *
 *  - Committed<DomainEvent>  -> a DomainEvent retrieved from the local EventStore.
 *                               It has either been locally emitted and committed to the
 *                               EventStore or has been pulled from the remote bag holder and merged.
 *
 *  - Pushed<DomainEvent>     -> a Committed<DomainEvent> that has been pushed to the remote bag holder.
 *
 *  - MaybeLocal<DomainEvent> -> a way to distinguish between what is pulled from the remote bag holder and
 *                               and what is retrieved from the local EventStore in a mix of Pushed<DomainEvent>
 *                               and Committed<DomainEvent> when merging.
 *
 * @todo Consider fusing Committed and Pushed. This would require tagging each DomainEvent
 *       with the local branchId as they are committed to the EventStore.
 */
export type Pushed<TDomainEvent extends DomainEventShape> = {
  id: number;
  branch: Uuid;
} & TDomainEvent;

/**
 * A Pushed<DomainEvent> that may have been tagged as 'retrieved from local EventStore'.
 *
 * Used to distinguished between :
 *  - Pushed<DomainEvent> freshly retrieved directly from the remote bag holder,e.g. : API Core.
 *  - possibly old already merged Pushed<DomainEvent>, retrieved from local EventStore to rebuild a timeline.
 *
 * .local type is null, and specifically not a boolean, to make it explicit that it is the mere presence
 * of the key that is checked for this tag and that it's NOT OK to set it to false and clobber it
 * to the EventStore.
 */
export type MaybeLocal<TDomainEvent extends DomainEventShape> = {
  local?: null;
} & Pushed<TDomainEvent>;

/**
 * A DomainEvent stripped of any possible id and local tag.
 */
export type Stripped<TDomainEvent extends DomainEventShape> = TDomainEvent & {
  id?: never;
  local?: never;
};

/**
 * {
 *   [aggregateId: string]: {
 *     [branch: string]: {
 *       [version: number]: Pushed<TDomainEvent>;
 *     };
 *   };
 * };
 *
 * @todo Find a usable way to encode that a BranchMap must only contain events
 *       with a corresponding branchId. See attempt below.
 */
type DedupedDomainEventMap<TDomainEvent extends DomainEventShape> = Record<
  string,
  Record<string, Record<number, Pushed<TDomainEvent>>>
>;

// /**
//  * @see https://stackoverflow.com/questions/50639496/is-there-a-way-to-prevent-union-types-in-typescript
//  * @see https://github.com/type-challenges/type-challenges
//  *
//  */
// type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
// type NotAUnion<Key> = [Key] extends [boolean] ? boolean : [Key] extends [UnionToIntersection<Key>] ? Key : never;

// /**
//  * @note Opaque type.
//  */
// declare const validBranchMap: unique symbol;
// type BranchMap<Id extends string, TDomainEvent extends DomainEventShape> = NotAUnion<Id> extends never
//   ? never
//   : Record<string, Record<number, Pushed<TDomainEvent> & { branch: Id }>> & { [validBranchMap]: true };

// /**
//  * @note As a workaround TS's lack of support for existential types,
//  *       we uses opaques type to require going through
//  *       helper functions to guarantee DedupedDomainEventMap's internal consistency.
//  */
// function createBranchMap<TDomainEvent extends DomainEventShape>(event : Pushed<TDomainEvent>) {

// }

// type DedupedDomainEventMap<TDomainEvent extends DomainEventShape, Ids extends string = any> = Record<
//   string,
//   BranchMap<Ids, TDomainEvent>
// >;

/**
 *
 */
function getEventMapDigest<TDomainEvent extends DomainEventShape>(eventMap: DedupedDomainEventMap<TDomainEvent>[string]) {
  const digest: Record<string, Record<number, Pushed<DomainEventShape>>> = {};
  Object.entries(eventMap).forEach(([branchId, value]) => {
    const branch: Record<string, Pushed<DomainEventShape>> = {};
    Object.entries(value).forEach(([version, event]) => {
      branch[version] = {
        id: event.id,
        aggregateId: event.aggregateId,
        type: event.type,
        schema: event.schema,
        createdAt: event.createdAt,
        branch: event.branch,
        version: event.version,
      };
    });
    digest[branchId] = branch;
  });

  return digest;
}

/**
 *
 */
export class DomainEventMergeError extends Error {
  constructor(readonly userId: number, readonly branchId: Uuid, originalErrorType: string, originalErrorMessage: string) {
    super(
      `Merge failed for user ${userId} on branch ${branchId} !\n` +
        `Original error : ${originalErrorType}\n` +
        'message :\n' +
        originalErrorMessage
    );
    this.name = 'DomainEventMergeError';
  }
}
/**
 * @todo Look at more thorough checks per event types ? There are validation
 *       libraries for parsed json, but it looks like quite a big investment
 *       and extra boilerplate on top of every DomainEvent type definition.
 *
 * @note typeof event === 'function' skipped because it makes no sense here.
 *
 * @todo Figure out if there is a way to satisfy etc/no-misused-generics for
 *       this purpose.
 */
// eslint-disable-next-line etc/no-misused-generics
export function isPushedDomainEvent<TDomainEvent extends DomainEventShape>(event: unknown): event is Pushed<TDomainEvent> {
  return (
    typeof event === 'object' &&
    !!event &&
    'type' in event &&
    'id' in event &&
    'branch' in event &&
    'aggregateId' in event &&
    'version' in event &&
    'schema' in event &&
    'createdAt' in event
  );
}

export interface PushMiddleware {
  (apiEventDraft: ApiDomainEventDraft): MaybePromise<ApiDomainEventDraft>;
}
export interface PullMiddleware {
  (apiEvent: ApiDomainEvent): MaybePromise<ApiDomainEvent>;
}

/**
 *
 */
export interface BackupServiceCtor<TDomainEvent extends DomainEventShape = DomainEvent> {
  //   new (...args: ConstructorParameters<typeof BackupService>): BackupService<TDomainEvent>;
  new (
    user_id: number,
    repo: BackupRepo,
    eventStore: DomainEventStore<TDomainEvent>,
    pushMiddlewares: PushMiddleware[],
    pullMiddlewares: PullMiddleware[],
    projectionist?: Projectionist<ProjectionistConfig<unknown, TDomainEvent>, TDomainEvent>,
    debounceTimeoutInMilliseconds?: number,
    chunkSize?: number,
    maxRetries?: number
  ): BackupService<TDomainEvent>;
}

/**
 * Each user has 1 repo.
 *
 * Each device ( more accurately each EventStore ) is a clone of the user repo
 * and has its own branch.
 *
 * Each clone has a working copy. There is no intermediary bare repo !
 *
 * Each DomainEvent in an EventStore is a commit.
 *
 * Each Aggregate(id) is a file.
 *
 * API Core is only a bag holder for device to exchange commits.
 *
 * @todo Consider :
 *         - Do we know who we merge from ? Does it matter ?
 *
 *         - Adding a Device aggregate, than can take a 'merge' command ?
 *
 *         - The result of a merge probably needs to be persisted as a DomainEvent,
 *           for the same reason DomainEvents are persisted rather than Commands.
 *
 *         - What if all backup and sync related events were not DomainEvents but
 *           something like AppEvents ? BranchMerged, an AppEvent, would contain infos
 *           about what to do with APICore events and 'project' it into the device
 *           EventStore ?
 *
 *         - How do you handle resolved conflicts when BranchMerged is a DomainEvent ?
 *           Knowing that it can only be appended after a DomainEvent it might want to
 *           'retcon'.
 *
 * @todo Use [branch, EventStore issued id, (createdAt)] as some kind of Lamport timestamp ?
 *
 * @todo Consider replacing middleware defaulting to identity function with a branch skipping
 *       middleware use altogether. However, it may not be worth bothering since a middleware
 *       will always be used for this app particular use case.
 *
 * @todo Research ways to help make sure Push/Pull middlewares are 'symmetrical' ? Something
 *       like expected version like in DecisionProjection, validation ? Does it always have
 *       to be symmetrical ?
 */
export class BackupService<TDomainEvent extends DomainEventShape = DomainEvent> {
  cursors: BackupCursors | null = null;
  private readonly pushTransformer: PushMiddleware;
  private readonly pullTransformer: PullMiddleware;
  private pendingPush: ReturnType<typeof setTimeout> | null = null;
  private status = 'IDLE' as 'IDLE' | 'PULLING' | 'PUSHING';

  constructor(
    private readonly user_id: number,
    private readonly repo: BackupRepo,
    private readonly eventStore: DomainEventStore<TDomainEvent>,
    pushMiddlewares: PushMiddleware[] = [],
    pullMiddlewares: PullMiddleware[] = [],
    private readonly projectionist?: Projectionist<ProjectionistConfig<unknown, TDomainEvent>, TDomainEvent>,
    private readonly debounceTimeoutInMilliseconds = 1000,
    private readonly chunkSize = 250,
    private readonly maxRetries = 3
  ) {
    this.pushTransformer = pushMiddlewares.length ? asyncPipe(...pushMiddlewares) : identity;
    this.pullTransformer = pullMiddlewares.length ? asyncPipe(...pullMiddlewares) : identity;
    this._push = this._push.bind(this);
    this.push = this.push.bind(this);
    this.pull = this.pull.bind(this);
  }

  /**
   * @todo Look for a way to wrap push in try/catch and add logging. Right now,
   *       setTimeout is used for debouncing push and it makes catching error from here
   *       tricky.
   */
  register(publisher: Publisher<TDomainEvent>): void {
    publisher.afterAny(this.push);
  }

  /**
   * Retrieve outstanding ApiDomainEvents according to pullCursor.
   *
   * @note There is no guarantee about the order of events on API core or wherever
   *       they are retrieved from. Depending on where a chunk cut-off point would
   *       land, some retrieved event sets may appear faulty, with holes, while
   *       the missing pieces are waiting in the next chunk and just need to be sorted.
   *
   *       The outcome of a merge might also change depending on a chunk cut-off point,
   *       e.g. : an event in the next chunk might tip a merge strategy depending on
   *       'most recently updated branch' toward a different decision.
   *
   * @todo Figure out if always pulling before pushing is enough to avoid transposing the
   *       'chunk cut-off point' problem to the sync of a device that has operated
   *       some time without being connected ? Probably not because another device
   *       might be in the middle of a large dump and sending chunks !!!
   *
   * @todo Consider persisting each retrieved chunk with some extra method on BackupRepo
   *       and sorting/merging from this ? This is probably going to become necessary
   *       for huge event history on memory constrained devices ?
   *       Beside the possible technical constraint, it is probably necessary to store
   *       shelved/discarded timelines that may actually be resolved next pull !!!
   */
  private async _retrieveRemoteDomainEvents() {
    if (!this.cursors) {
      this.cursors = await this.repo.getCursors();
    }

    // console.log('_retrieveRemoteDomainEvents this.cursors', this.cursors);

    /** Retrieve events starting from cursor position. */
    let apiEvents: ApiDomainEvent[];
    const remoteEvents: Pushed<TDomainEvent>[] = [];

    let pullLastEventId = this.cursors.pull.lastEventId;

    //   apiEvents = await this.repo.pull(this.cursors.pull.lastEventId, this.chunkSize);
    try {
      apiEvents = await this.repo.pull(pullLastEventId, this.chunkSize);
    } catch (e) {
      return [];
    }

    while (apiEvents.length) {
      // prettyPrint({ chunkSize: this.chunkSize, received: apiEvents.length }, '_retrieveRemoteDomainEvents', 'warn');
      console.log('transforming chunk...');
      const t0 = performance.now();
      apiEvents.forEach(async (apiEvent) => {
        /**
         * @todo Handle transformer errors !!!
         */
        // const transformed = await this.pullTransformer(apiEvent);
        let transformed: ApiDomainEvent | null = null;

        try {
          // console.log('transforming : ', apiEvent);
          transformed = await this.pullTransformer(apiEvent);
          const parsed = JSON.parse(transformed.content);
          /**
           * @todo Consider if we should discard malformed but parseable events here or later ?
           * @todo Research better/more thorough validation techniques !
           */
          if (isPushedDomainEvent<TDomainEvent>(parsed)) {
            /** Any stowaway .local  MUST BE STRIPPED. */
            const { local: _, ...stripped } = parsed as MaybeLocal<TDomainEvent>;
            remoteEvents.push(stripped as Pushed<TDomainEvent>);
          }
        } catch (error) {
          //   if (error instanceof SyntaxError) {
          console.log('Malformed ApiDomainEvent ignored : ', apiEvent, transformed);
          //   } else {
          //     throw error;
          //   }
        }
      });
      const t1 = performance.now();
      console.log(`transforming chunk done in ${t1 - t0} milliseconds.`);
      /**
       * @note Don't update pull cursors that way ! Consider what would happen if
       *       this aborts/throws midway, returns nothing and then persist the
       *       now wrong cursors from the instance state ??
       */
      //   this.cursors.pull.lastEventId = apiEvents[apiEvents.length - 1].id + 1;
      pullLastEventId = apiEvents[apiEvents.length - 1].id + 1;

      /** Next chunk. */
      console.log('retrieving next chunk...');
      const t0c = performance.now();
      apiEvents = await this.repo.pull(pullLastEventId, this.chunkSize);
      const t1c = performance.now();
      console.log(`chunk retrieved in ${t1c - t0c} milliseconds.`);
    }

    /**
     * @todo Consider that updating pull cursor here is a bit better, but probably
     *       not good enough.
     */
    this.cursors.pull.lastEventId = pullLastEventId;
    return remoteEvents;
  }

  /**
   * @todo Convert the callback thing to an awaitable promise result.
   */
  async pull(onDone?: (mergeCount: number, errors : ProjectorError<TDomainEvent>[]) => void): Promise<void> {
    /** Try again later if anything is going on. */
    if (this.status !== 'IDLE') {
      this.pendingPush = setTimeout(() => {
        this.pull(onDone);
      }, this.debounceTimeoutInMilliseconds);
      return;
    }

    this.status = 'PULLING';
    // console.log('BackupService.pull : ', this.cursors, this.status);

    let pulledEvents: Pushed<TDomainEvent>[] | undefined;

    try {
      pulledEvents = await this._retrieveRemoteDomainEvents();

      // console.log('BackupService.pull pulledEvents :', pulledEvents);

      if (pulledEvents.length) {
        const mergeResult = await this.merge(pulledEvents);
        // console.warn('BackupService.pull :', 'pulledEvents', getDigest(pulledEvents), 'mergeResult', getDigest(mergeResult));

        if (this.cursors) {
          // console.log('>>> pull about to this.repo.putCursors(this.cursors)', this.cursors);
          await this.repo.putCursors(this.cursors);
        } else {
          throw new Error('@todo : Express that cursors must have been initialized during _retrieveRemoteDomainEvents.');
        }

        let errors: ProjectorError<TDomainEvent>[] = [];

        if (this.projectionist && mergeResult.length) {
          errors = await this.projectionist.playMerged(mergeResult);
        }

        onDone?.(mergeResult.length, errors);
      } else {
        onDone?.(0, []);
      }
    } catch (error) {
      console.error(error);
      // throw error;
      if (error instanceof NetworkError || error instanceof CoreServerError) {
        /** Ignore. */
        return;
      }
      if (error instanceof DOMException && error.name === 'InvalidStateError') {
        /** Idb is most likely closing during a logout. Ignore ? */
        return;
      }
      // console.log('@todo : Retry BackupService.pull ! maybe API core error. Reset pullCursor ?');

      const digest = pulledEvents ? getDigest(pulledEvents) : 'pulledEvents is undefined';
      console.log('@todo : Retry BackupService.pull ! maybe merge error.', this.cursors, JSON.stringify(digest));

      const branchId = this.cursors?.branch ?? (this.cursors = await this.repo.getCursors()).branch;
      const shouldDumpToFile = window.confirm('BackupService.pull : error during merge.\nDo you want to save diagnostic files ?');
      if (shouldDumpToFile) {
        dumpToFile(
          { pulledEvents: digest, store: this.eventStore.getDebugDigest() },
          `MergeErrorDigest_${this.user_id}_${branchId}_${dayjs().utc().format('YYYYMMDDTHHmmss[Z]')}.json`
        );
        // dumpToFile(
        //   this.eventStore.getDebugDigest(),
        //   `EventStoreDigest_${this.user_id}_${branchId}_${dayjs().utc().format('YYYYMMDDTHHmmss[Z]')}.json`
        // );
      }

      this.status = 'IDLE';
      throw new DomainEventMergeError(
        this.user_id,
        branchId,
        error instanceof Error ? error.name : typeof error,
        error instanceof Error ? error.message : '' + error
      );
    } finally {
      /**
       * @todo Fix misunderstanding about how finally works !!! It seems it doesn't happen if catch
       *       rethrows !
       */
      this.status = 'IDLE';
    }
  }

  /**
   * @todo Detect early if all events pulled are from 'own branch' !!!
   */
  async merge(remoteEvents: Pushed<TDomainEvent>[]) {
    // console.log('BackupService.merge : ', this.cursors, this.status, remoteEvents);

    const dedupedRemoteMap = this._dedupe(remoteEvents);
    // console.log('merge dedupedRemoteMap', JSON.stringify(dedupedRemoteMap, null, 4));
    /** @todo Consider that not all conflict can be detected and solved until EventStore is checked. */
    // const localMap = await this._getLocalMap(dedupedRemoteMap);
    const merged = await this._solveAndDiscardBrokenTimelines(dedupedRemoteMap); //, dedupedRemoteMap);

    const sorted = this._causalSortWithBestEffortTotalOrder(merged);

    // try {

    //   } catch (error) {
    //     console.log('@todo : Retry Backup.merge !', this.mergeCursor);
    //   } finally {
    //     await this.repo.putCursors([this.pushCursor, this.pullCursor, mergeCursor]);
    //     this.status = 'IDLE';
    // }
    /** @todo Look for a way to make an async getter. */
    const branch = this.cursors?.branch ?? (this.cursors = await this.repo.getCursors()).branch;

    /** No need to clobber 'own branch' events or events pulled from local EventStore to rebuild timelines. */
    // const toClobber = sorted.filter((event) => !('local' in event || !event?.branch || event.branch === this.branch));
    const toClobber: Stripped<TDomainEvent>[] = [];
    for (let i = 0, length = sorted.length; i < length; ++i) {
      if (!('local' in sorted[i] || !sorted[i]?.branch || sorted[i].branch === branch)) {
        const { id: _, local: __, ...event } = sorted[i];
        toClobber.push(event as Stripped<TDomainEvent>);
      }
    }

    if (toClobber.length === 0) {
      /** Nothing to do. */
      console.log('BackupService.merge : nothing to do.');
      return [];
    }
    console.log('BackupService.merge : clobbering.', toClobber.length);

    /** Clobber and return complete updated timelines. */
    await this.eventStore.clobberMany(toClobber);
    return sorted;
  }

  /**
   * Interpret given DedupedDomainEventMap as a disjoint union of directed acyclic graphs.
   * Perform a topological sort breaking ties according to timestamp and/or
   * branch issued id.
   *
   * The first element in each Aggregate partial timeline has no incoming edge, i.e. indegree  0.
   * The last  element in each Aggregate partial timeline has no outgoing edge, i.e. outdegree 0.
   * Each Aggregate partial timeline has a degree sequence of the shape :
   * (0,1)[(1,1)...](1,0) or (0,0).
   *
   * @todo Make the tie-breaking policy injectable ?
   */
  //   private _causalSortWithBestEffortTotalOrder(eventGraphs: Pushed<TDomainEvent>[][]): Pushed<TDomainEvent>[] {
  private _causalSortWithBestEffortTotalOrder(eventGraphs: MaybeLocal<TDomainEvent>[][]): MaybeLocal<TDomainEvent>[] {
    let expectedEventCount = 0;
    eventGraphs.forEach((timeline) => (expectedEventCount += timeline.length));

    const timelineHeap = Heap.make(
      ([a], [b]) =>
        a.createdAt < b.createdAt
          ? -1
          : a.createdAt > b.createdAt
          ? 1
          : a.id < b.id /** @todo Try id first for tentative Lamport timestamp setup ? */
          ? -1
          : a.id > b.id
          ? 1
          : a.branch < b.branch
          ? -1
          : a.branch > b.branch
          ? 1
          : 0,
      eventGraphs
    );

    // console.log('startNodes', startNodes);
    const sorted: MaybeLocal<TDomainEvent>[] = [];
    let peek: MaybeLocal<TDomainEvent>[] | undefined;
    // const sorted: Pushed<TDomainEvent>[] = [];
    // let peek: Pushed<TDomainEvent>[];

    while ((peek = timelineHeap.peek()) !== undefined) {
      let next: Pushed<TDomainEvent>;

      /** peek is never undefined nor empty here : the whole branch is popped on its last element. */
      if (peek.length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        next = peek.shift()!;
        /** Heap is broken between these two statements. No operation must be done on the heap before replace().*/
        timelineHeap.replace(peek);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        next = timelineHeap.pop()![0];
      }
      sorted.push(next);
    }

    // console.log('sorted', sorted, expectedEventCount, sorted.length);
    return sorted;
  }

  /**
   * @todo Figure out if the order in which branch are merged into local matters ?
   *       Can merging be done commutatively ?
   *       If a remote has merged another remote and extended upon it, does it mean
   *       this is to be re-merged locally ? Or that both branch were fully pushed ?
   *       How about starting with the latter for the POC ?
   *
   * @todo Replace this shot-in-the-dark tie-breaker.
   *       At the very least it should be a set tie-break between branches,
   *       not between individual events.
   *       It most likely needs something more elaborate, like recursively
   *       trying to merge branch pairs ?
   *
   *       There's a lot left to study about merging, especially because of
   *       the complexity and variety of scenarios. See octopus merge.
   *
   *       Lossy or not, the only objective right now is :
   *       'should converge to a valid and semantically identical state on every node'.
   *
   * @todo Make the tie-breaking policy injectable ?
   *
   * @todo Short-circuit if remote branch can fast-forward local !!!
   *
   * @todo Consider if picking the longest timeline for diverging branches is enough
   *       to guarantee that you don't get an illegal according to the aggregate internal
   *       rules but otherwise valid timeline, e.g. : deleted then edited ?
   */
  private _solveForAggregate(eventMap: DedupedDomainEventMap<TDomainEvent>[string]): Pushed<TDomainEvent>[] {
    // console.log('_solveForAggregate', JSON.stringify(getEventMapDigest(eventMap), null, 4));
    // console.log('_solveForAggregate eventMap', JSON.stringify(eventMap, null, 4));

    /** Make min-heap according to first event's version of each branch. */
    const branchHeap = Heap.make(
      (a, b) => a[0].version - b[0].version,
      /** Sort each branch by version in ascending order. */
      Object.keys(eventMap).map((branchName) => Object.values(eventMap[branchName]).sort((a, b) => a.version - b.version))
    );

    // console.log('branchHeap', branchHeap._array);

    /** K-way merge. */
    let peek: Pushed<TDomainEvent>[] | undefined;
    const merged = new Map<number, Pushed<TDomainEvent>>();
    while ((peek = branchHeap.peek()) !== undefined) {
      let next: Pushed<TDomainEvent> | undefined;

      /** peek is never undefined nor empty here : the whole branch is popped on its last element. */
      if (peek.length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        next = peek.shift();
        /** Heap is broken between these two statements. No operation must be done on the heap before replace().*/
        branchHeap.replace(peek);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        next = branchHeap.pop()?.[0];
      }

      /** Pick the longest timeline for diverging branches. */
      if (next) {
        // console.log('_solveForAggregate next', JSON.stringify(next, null, 4));
        const conflict = merged.get(next.version);
        if (conflict) {
          /** @todo Build a stats map (length, continuous, lastUpdate, ... ) of all branches. */
          const nextBranchLatest = Object.values(eventMap[next.branch]).sort((a, b) => b.version - a.version)[0];
          const conflictBranchLatest = Object.values(eventMap[conflict.branch]).sort((a, b) => b.version - a.version)[0];

          // console.log('conflict', nextBranchLatest, conflictBranchLatest);

          /** Break tie. */
          next =
            nextBranchLatest.version < conflictBranchLatest.version
              ? conflict
              : nextBranchLatest.version > conflictBranchLatest.version
              ? next
              : nextBranchLatest.createdAt < conflictBranchLatest.createdAt
              ? conflict
              : nextBranchLatest.createdAt > conflictBranchLatest.createdAt
              ? next
              : nextBranchLatest.id < conflictBranchLatest.id
              ? conflict
              : nextBranchLatest.id > conflictBranchLatest.id
              ? next
              : next.branch < conflict.branch
              ? conflict
              : next;
          // console.log(nextBranchLatest, conflictBranchLatest, next.branch);
        }

        if (next !== conflict) {
          merged.set(next.version, next);
        }
      }
    }
    // console.log('_solveForAggregate', merged);
    // console.log('_solveForAggregate filtered', this._filterValidToAppend(merged));

    return this._filterValid(merged);
  }

  /**
   * Filter given event map :
   *   - Stop before any unexpected event.version.
   *
   * @todo Review _filterValid : while it's handy to avoid broken timelines, it may
   *       mask other problem withs current conflict tie-breaking, especially when
   *       the longest timeline for diverging branches. This must be model checked.
   */
  private _filterValid(mergedMap: Map<number, Pushed<TDomainEvent>>): Pushed<TDomainEvent>[] {
    const result: Pushed<TDomainEvent>[] = [];

    /** Since local timeline was pulled, this starts from 0 or is broken. */
    let expectedVersion = 0;

    for (const event of mergedMap.values()) {
      if (event.version !== expectedVersion) {
        break;
      }

      ++expectedVersion;

      result.push(event);
      // if (event.branch !== this.branch) {
      //   result.push(event);
      // }
    }

    return result;
  }

  /**
   * @note Not pulling local map separately to clobber incoming remote dupe ?
   *
   * @todo Consider what would happen if it pulled mangled events of its own local
   *       branch ? Just clobber ?
   */
  private async _solveAndDiscardBrokenTimelines(remoteMap: DedupedDomainEventMap<TDomainEvent>) {
    // console.log('todo : BackupService._solveAndDiscardBrokenTimelines');
    const merged: MaybeLocal<TDomainEvent>[][] = [];

    /** @todo Look for a way to make an async getter ? */
    const localBranch = this.cursors?.branch ?? (this.cursors = await this.repo.getCursors()).branch;

    await Promise.all(
      (Object.keys(remoteMap) as Array<Uuid>).map(async (aggregateId) => {
        /**
         * Retrieve local Aggregate timeline, clobber any dupe or mangled existing events in remoteMap.
         *
         * @todo Consider that this clobbers any duplicate [aggregateId, branch, version], last one wins,
         *       with whatever is in store locally.
         *       There isn't supposed to be multiple [aggregateId, branch, version] with different content
         *       ( this is something you definitely want to model check at the scale of the whole system).
         *
         * @note Current version with :
         *         const branch = localBranch;
         *         // const branch = event.branch ?? localBranch;
         *       Overwrites the temporary timeline copy with the localBranch id.
         *       Disregard todo above for that experiment.
         */
        const timeline = await this.eventStore.getByAggregate(aggregateId);
        timeline.forEach((event) => {
          const branch = localBranch;
          //   const branch = event.branch ?? localBranch;
          (remoteMap[aggregateId][branch] || (remoteMap[aggregateId][branch] = {}))[event.version] = {
            ...event,
            /** @todo Consider tagging events with local branch name when writing to the eventStore if necessary. */
            branch,
            // branch: localBranch, // As if local ?
            // branch: event.branch ?? branch,
            local: null,
          };
        });

        const solved = this._solveForAggregate(remoteMap[aggregateId]);
        if (solved.length) {
          merged.push(solved);
        }
      })
    );

    // console.log(merged);
    return merged;
  }

  /**
   * Build a deduped and sorted map of the following shape :
   *
   * {
   *   [aggregateId: string]: {
   *     [branch: string]: {
   *       [version: number]: Pushed<TDomainEvent>;
   *     };
   *   };
   * };
   * @todo Consider using Map/Set.
   */
  private _dedupe(events: Pushed<TDomainEvent>[]): DedupedDomainEventMap<TDomainEvent> {
    const deduped: DedupedDomainEventMap<TDomainEvent> = {};

    events.forEach((event) => {
      /**
       * @note This clobbers any duplicate [aggregateId, branch, version], last one wins.
       *
       * @todo Investigate if [aggregateId, branch, version]is enough to qualify
       *       for uniqueness index.
       */
      ((deduped[event.aggregateId] || (deduped[event.aggregateId] = {}))[event.branch] ||
        (deduped[event.aggregateId][event.branch] = {}))[event.version] = event;
    });

    return deduped;
  }

  /**
   * Debounced ( trailing edge ) push function.
   * Fire after event bursts.
   */
  push() {
    // console.warn('BackupService.push : ', this.pendingPush);
    if (this.pendingPush) {
      clearTimeout(this.pendingPush);
      //   console.log('BackupService.push : pendingPush cleared', this.pendingPush);
    }

    this.pendingPush = setTimeout(this._push, this.debounceTimeoutInMilliseconds);
    // if (this.status === 'IDLE') {
    //   this.pendingPush = setTimeout(this._push, this.debounceTimeoutInMilliseconds);
    // }
  }
  /**
   * @todo Consider merge both try/catch blocks if UnknownDomainEventIdError
   *       handling doesn't require a separate one. This made sense in the Projectionist
   *       method where the pattern was lifted from, but not necessarily here.
   */
  private async _push() {
    // console.warn('BackupService._push : ', this.pendingPush);
    /** Try again later if anything is going on. */
    if (this.status !== 'IDLE') {
      this.pendingPush = setTimeout(this.push, this.debounceTimeoutInMilliseconds);
      return;
    }

    if (!this.cursors) {
      this.cursors = await this.repo.getCursors();
    }

    /**
     * Pull first ?!
     *
     * @todo Consider that this doesn't prevent another device from pushing
     *       between this pull and the following push !!
     */
    await this.pull();

    this.status = 'PUSHING';
    // console.log('BackupService._push : ', this.cursors, this.status);

    /** Retrieve events after cursor position. */
    let events: Committed<TDomainEvent>[];

    try {
      const lastEventId = this.cursors.push.lastEventId;
      //   const lastEventId = this.cursors.push.lastEventId ? { ...this.cursors.push.lastEventId } : null;
      events = lastEventId
        ? await this.eventStore.getAllAfter(lastEventId, this.chunkSize)
        : await this.eventStore.getAll(0, this.chunkSize);
    } catch (error) {
      if (error instanceof UnknownDomainEventIdError) {
        /**
         * @todo See https://stackoverflow.com/questions/41431605/handle-error-from-settimeout/41432073
         *       about where/how to handle errors from setTimeout callbacks.
         */
        this.cursors.push.status = 'BROKEN';
        console.log('@todo : Find out what can be done if cursor is invalid ?', this.cursors);
        /** @todo Do something with / reset BROKEN cursors ? */
        await this.repo.putCursors(this.cursors);
        throw error;
      } else {
        throw error;
      }
    }

    /**
     * @todo Study how wrong can things go if other devices push between chunks.
     *       Events retrieved from API Core will most likely need to be sorted
     *       anyway. A branch Uuid on each DomainEvent seems crucial.
     *
     * @todo Consider alternatives to filtering by branch, like getting only
     *       this.branch events from the EventStore.
     */
    let lastEventId: DomainEventId | null = null;
    try {
      const branch = this.cursors.branch;
      while (events.length) {
        const transformedEvents = events
          /** @todo Consider that only events where .branch isn't set should be pushed ? */
          .filter((event) => !event?.branch || event.branch === branch)
          .map((event) => {
            // console.log('this.pushTransformer : ', event);
            return this.pushTransformer({
              /** Do not overwrite event.branch if events are not filtered by this.branch first !!! */
              content: JSON.stringify({ ...event, branch }),
              user_id: this.user_id,
            });
          });

        if (transformedEvents.length) {
          /**
           * @todo Consider doing this for all push/pull transformation since they
           *       can be run in parallel.
           */
          await this.repo.push(await Promise.all(transformedEvents));
        }

        const { aggregateId, version } = events[events.length - 1];
        // this.cursors.push.lastEventId = { aggregateId, version };
        lastEventId = { aggregateId, version };

        /** Next chunk. */
        events = await this.eventStore.getAll((events[events.length - 1] as unknown as { id: number }).id + 1, this.chunkSize);
      }

      if (lastEventId) {
        this.cursors.push.lastEventId = { ...lastEventId };
        // console.log('>>> _push about to this.repo.putCursors(this.cursors)', this.cursors);
        await this.repo.putCursors(this.cursors);
      }
    } catch (error) {
      if (error instanceof InvalidOrExpiredRefreshTokenError) {
        throw error;
      }
      /**
       * Either you need to rewind the cached this.cursors on failure,
       * or you don't cache intermediate result during chunks !
       */
      console.log('@todo : Retry Backup.push !', this.cursors, error);
    } finally {
      // No ! This advance and lose events on trivial failure, e.g. NetworkError.
      //   await this.repo.putCursors(this.cursors);
      this.status = 'IDLE';
    }
  }
}
