import { useConfirm } from 'material-ui-confirm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { dumpRecoveryCode, useAppDispatch } from '@focus-front/core';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { useStyles } from './useStyles';

const useClasses = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
  },
}));

export default function SecurityPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const style = useStyles();
  const history = useHistory();
  const classes = useClasses();
  const confirm = useConfirm();

  return (
    <>
      <div className={style.sectionTitle}>
        <Typography variant="h2">{t('security.recoveryCode.title')}</Typography>
        <Typography variant="subtitle2">{t('security.recoveryCode.subtitle')}</Typography>
      </div>

      <Button
        onClick={async () => {
          await dispatch(dumpRecoveryCode());
        }}
        variant="outlined"
        startIcon={<GetApp />}
        disableElevation
      >
        {t('security.recoveryCode.downloadCode')}
      </Button>
    </>
  );
}
