import { createProjector } from '../../../Common';
import { createViewSignature } from '../projections/ViewSignature';
import { ViewSignatureRepo } from '../projections/ViewSignatureRepo';

/**
 * Handler/Projector.
 */
export const viewSignaturesProjector = createProjector('viewSignaturesProjector', {
  SIGNATURE_CREATED: ({ aggregateId, signature }, repo: ViewSignatureRepo) => {
    repo.add(createViewSignature(aggregateId, signature));
  },
  SIGNATURE_DELETED: ({ aggregateId }, repo) => {
    repo.remove(aggregateId);
  },
  SIGNATURE_EDITED: ({ aggregateId, updatedValues }, repo) => {
    repo.update(aggregateId, updatedValues);
  },
});
