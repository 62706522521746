import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import {
  selectSignature,
  isGoogleAccount,
  isMailAccount,
  isMobileAccount,
  ViewSignature,
  Uuid,
  signaturesSelector,
  accountIdentifier,
  useAppDispatch,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import {
  Avatar,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AccountTypeIcon } from '@focus-front/ui';

const Control = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      border: 'none!important',
    },
  },
})(FormControl);

interface Props {
  signatures: ViewSignature[];
}

const useClasses = makeStyles((theme) => ({
  itemCont: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  accIcon: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],
    color: theme.palette.grey[800],
    height: 50,
    width: 50,
    fontSize: 30,
    marginRight: theme.spacing(2),
  },
}));

export default function SignaturesAccountList({ signatures }: Props) {
  const accounts = useSelector(signaturesSelector);
  const dispatch = useAppDispatch();
  const c = useClasses();
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);

  return (
    <>
      <List>
        {accounts.map((account) => (
          <Paper key={account.id} variant="outlined" className={c.itemCont}>
            <ListItem key={account.id}>
              <ListItemAvatar>
                <AccountTypeIcon account_type={account.type} size={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h3">{account.name || accountIdentifier(account.connection_params)}</Typography>}
                secondary={accountIdentifier(account.connection_params)}
              />
              <ListItemSecondaryAction>
                <Control>
                  <Select
                    value={account.current_signature || null}
                    onChange={async (event) => {
                      const error = await dispatch(selectSignature(account.id, event.target.value as Uuid));
                      if (error) alert(error);
                      else setSuccess(true);
                    }}
                    displayEmpty
                  >
                    <MenuItem value={null}>
                      <em>{t('signatureSettings.assign.noSign')}</em>
                    </MenuItem>
                    {signatures.map((sign, index) => (
                      <MenuItem key={index} value={sign.id}>
                        {sign.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Control>
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        ))}
      </List>
      <Snackbar open={success} autoHideDuration={2000} onClose={() => setSuccess(false)}>
        <Alert severity="success">{t('signatureSettings.assign.success')}</Alert>
      </Snackbar>
    </>
  );
}
