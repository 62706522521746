import { makeStyles, Theme } from '@material-ui/core';
import mailSvg from './svg/MAIL.svg';
import gmailSvg from './svg/GMAIL.svg';
import icloudmailSvg from './svg/ICLOUDMAIL.svg';
import outlookSvg from './svg/OUTLOOK.svg';
import whatsappSvg from './svg/WHATSAPP.svg';
import linkedinSvg from './svg/LINKEDIN.svg';
import messengerSvg from './svg/MESSENGER.svg';
import tiktokSvg from './svg/TIKTOK.svg';
import instagramSvg from './svg/INSTAGRAM.svg';
import twitterSvg from './svg/TWITTER.svg';

import microsoftSvg from './svg/MICROSOFT.svg';
import googleCalendarSvg from './svg/GOOGLE_CALENDAR.svg';
import googleSvg from './svg/GOOGLE.svg';
import Phone from '@material-ui/icons/Phone';
import Message from '@material-ui/icons/Chat';
import Event from '@material-ui/icons/Event';
import Drafts from '@material-ui/icons/Drafts';
import PhoneMissedRoundedIcon from '@material-ui/icons/PhoneMissedRounded';
import SettingsPhoneRoundedIcon from '@material-ui/icons/SettingsPhoneRounded';
import PhoneCallbackRoundedIcon from '@material-ui/icons/PhoneCallbackRounded';
import PhoneForwardedRoundedIcon from '@material-ui/icons/PhoneForwardedRounded';
import React from 'react';
import { IonIcon } from '@ionic/react';
import { peopleOutline } from 'ionicons/icons';
import { AlternateEmailOutlined, PhonelinkLockOutlined, VpnKeyOutlined } from '@material-ui/icons';

export type UnipileIconsProps = {
  size: number;
  backgroundColor: string;
  icon: JSX.Element;
};

const useClasses = makeStyles<Theme, UnipileIconsProps>(() => ({
  root: ({ size, backgroundColor }) => ({
    backgroundColor: backgroundColor,
    borderRadius: '100%',
    width: size,
    height: size,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }),
}));

function UnipileIconContainer(props: UnipileIconsProps) {
  const c = useClasses(props);

  return <div className={c.root}>{props.icon}</div>;
}

export function MailAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#264C5F" icon={<img src={mailSvg} style={{ width: '55%' }} />} />;
}

export function GmailAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={gmailSvg} style={{ width: '55%' }} />} />;
}

export function ICloudAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={icloudmailSvg} style={{ width: '100%' }} />} />;
}

export function OutlookAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={outlookSvg} style={{ width: '55%' }} />} />;
}

export function LinkedInAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={linkedinSvg} style={{ width: '65%' }} />} />;
}

export function MicrosoftAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={microsoftSvg} style={{ width: '55%' }} />} />;
}

export function WhatsAppAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#42AF71" icon={<img src={whatsappSvg} style={{ width: '60%' }} />} />;
}

export function GoogleAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={googleSvg} style={{ width: '55%' }} />} />;
}

export function GoogleCalendarAccountIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={googleCalendarSvg} style={{ width: '55%' }} />} />
  );
}

export function AndroidMessagesAccountIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#325ECC"
      icon={<Message style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function AndroidCallAccountIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#325ECC"
      icon={<Phone style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function AndroidIncomingCallIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#325ECC"
      icon={<PhoneCallbackRoundedIcon style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function AndroidMissedCallIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#325ECC"
      icon={<PhoneMissedRoundedIcon style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function AndroidNoAnswserCallIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#325ECC"
      icon={<SettingsPhoneRoundedIcon style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function AndroidOutgoingCallIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#325ECC"
      icon={<PhoneForwardedRoundedIcon style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function MessengerAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={messengerSvg} style={{ width: '80%' }} />} />;
}

export function TikTokAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={tiktokSvg} style={{ width: '100%' }} />} />;
}

export function TwitterAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={twitterSvg} style={{ width: '100%' }} />} />;
}

export function InstagramAccountIcon({ size }: { size: number }) {
  return <UnipileIconContainer size={size} backgroundColor="#fff" icon={<img src={instagramSvg} style={{ width: '100%' }} />} />;
}

export function CalendarEventDefaultIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#0E2936"
      icon={<Event style={{ fontSize: size * 0.7, color: '#fff' }} />}
    />
  );
}

export function NotificationDefaultIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#0E2936"
      icon={<span style={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: size * 0.6, color: '#fff' }}>?</span>}
    />
  );
}

export function MissedEventIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#FF7F00"
      icon={<span style={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: size * 0.6, color: '#fff' }}>!</span>}
    />
  );
}

export function MailDraftIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#0E2936"
      icon={<Drafts style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function GroupeThreadIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#0E2936"
      icon={<IonIcon icon={peopleOutline} style={{ fontSize: size * 0.6, color: '#fff' }} />}
    />
  );
}

export function TwoFAIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#f5f5f5"
      icon={<PhonelinkLockOutlined style={{ fontSize: size * 0.6 }} />}
    />
  );
}

export function ImapActivationIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer
      size={size}
      backgroundColor="#f5f5f5"
      icon={<AlternateEmailOutlined style={{ fontSize: size * 0.6 }} />}
    />
  );
}

export function AppPasswordIcon({ size }: { size: number }) {
  return (
    <UnipileIconContainer size={size} backgroundColor="#f5f5f5" icon={<VpnKeyOutlined style={{ fontSize: size * 0.6 }} />} />
  );
}
