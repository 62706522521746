import { IDBPDatabase } from 'idb';
import { BackupCursors } from '../../domain/BackupRepo';
import { DBSchema, Idb } from '../services/idb';

import { Uuid } from '../../domain/Uuid';
import { BackupCursorRepo } from '../../domain/BackupCursorRepo';

/**
 *
 */
export class IdbBackupCursorRepo implements BackupCursorRepo {
  private branch: Uuid | undefined;

  /**
   *
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   *
   */
  async get(): Promise<BackupCursors> {
    const backupCursors = (await Idb.getAll('backup'))?.[0];
    const branch = backupCursors?.branch;

    /** Empty string, null, undefined are a no-go.*/
    if (!branch) {
      throw new Error(`No branch id : backup store might not have been initialized !`);
    }

    let { cursors } = backupCursors;

    if (!Array.isArray(cursors) || !cursors.length) {
      cursors = [
        { status: 'PLAYING', lastEventId: null },
        { status: 'PLAYING', lastEventId: 0 },
      ];
      await this.put({ branch, push: cursors[0], pull: cursors[1] });
    }

    return { branch, push: cursors[0], pull: cursors[1] };
  }

  /**
   *
   */
  async put(newCursors: BackupCursors): Promise<void> {
    const tx = Idb.transaction('backup', 'readwrite');

    if (!(this.branch ?? (this.branch = (await tx.store.getAll())?.[0]?.branch))) {
      throw new Error(`No branch id : backup store might not have been initialized !`);
    }

    if (newCursors?.branch !== this.branch) {
      throw new Error(`Unexpected or invalid branch id : some services might be out-of-sync with backup store !`);
    }

    tx.store.put({ branch: this.branch, cursors: [newCursors.push, newCursors.pull] });
    return tx.done;
  }

  /**
   *
   */
  async clear(): Promise<void> {
    const tx = Idb.transaction('backup', 'readwrite');

    if (!(this.branch ?? (this.branch = (await tx.store.getAll())?.[0]?.branch))) {
      throw new Error(`No branch id : backup store might not have been initialized !`);
    }

    tx.store.put({ branch: this.branch, cursors: undefined });
    return tx.done;
  }

  /**
   *
   */
  async getBranchId() : Promise<Uuid>{
    return this.branch ?? (this.branch = (await Idb.getAll('backup'))?.[0]?.branch);
  }
}
