import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';

export default function LoadingButton({ children, loading, ...props }) {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Button disabled={loading} {...props}>
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </div>
  );
}
