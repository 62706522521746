import React from 'react';
import {
  Button,
  ButtonGroup,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  PopoverProps,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Attendee, displayAttendeeName, useAppDispatch } from '@focus-front/core';
import AttendeeAvatar from './AttendeeAvatar';
import { IonIcon } from '@ionic/react';
import { addOutline, personAddOutline } from 'ionicons/icons';
import AtendeeIdentifier from './AtendeeIdentifier';

const useClasses = makeStyles((theme) => ({
  link: {
    '& ion-icon': {
      verticalAlign: 'middle',
      marginRight: 5,
      fontSize: 20,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  infos: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  infos2: {
    marginLeft: theme.spacing(2),
  },
  itemCont: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  accIcon: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    color: theme.palette.grey[800],
    height: 50,
    width: 50,
    fontSize: 30,
    marginRight: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  attendee: Attendee;
} & PopoverProps;

export default function AttendeePopover({ attendee, ...popoverProps }: Props) {
  const c = useClasses();
  const { t } = useTranslation();

  const onCreateClick = () => {
    alert('todo');
  };

  const onAssignClick = () => {
    alert('todo');
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 2,
      }}
      {...popoverProps}
    >
      <div className={c.root}>
        <div className={c.header}>
          <div className={c.infos}>
            <AttendeeAvatar size="default" display_name={displayAttendeeName(attendee)} avatar_url={attendee.profile_picture} />
            <div className={c.infos2}>
              <Typography variant="h4">{displayAttendeeName(attendee)}</Typography>
              <AtendeeIdentifier attendee={attendee} />
            </div>
          </div>
        </div>

        <List dense>
          <Paper variant="outlined">
            <ListItem>
              <ListItemText primary={t('contact.popover.unknown')} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Link onClick={onCreateClick} className={c.link}>
                    <IonIcon icon={addOutline} />
                    {t('contact.popover.create')}
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Link onClick={onAssignClick} className={c.link}>
                    <IonIcon icon={personAddOutline} />
                    {t('contact.popover.assign')}
                  </Link>
                }
              />
            </ListItem>
          </Paper>
        </List>
      </div>
    </Popover>
  );
}
