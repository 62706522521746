import React, { useEffect } from 'react';
import TipTapUnipile from '../Common/TipTapUnipile/TipTapUnipile';
import { Button, Chip, makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { deleteMessageModel, MESSAGE_MODEL_VARIABLES, Taggable, updateMessageModel, UpdateMessageModelDTO, useAppDispatch, Uuid, ViewMessageModel } from '@focus-front/core';
import { useTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import EditableTags from '../Tag/EditableTags';

const useClasses = makeStyles((theme) => ({
  bottomBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(2),
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      marginLeft: theme.spacing(1),
    },
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
  variables: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  tagWrapper: {
    display: 'flex',
  },
}));

interface MessageModelEditorProps {
  defaultValues: UpdateMessageModelDTO & Taggable;
  id: Uuid;
  onUpdated: (sign: ViewMessageModel) => void;
  onDeleted: (id: Uuid) => void;
  showDelete: boolean;
}

const MessageModelEditor: React.FC<MessageModelEditorProps> = ({ defaultValues, id, onUpdated, onDeleted, showDelete }) => {
  const c = useClasses();

  const { register, handleSubmit, control, formState, reset, setValue, watch } = useForm<UpdateMessageModelDTO>({
    mode: 'onChange',
    defaultValues,
  });

  const confirm = useConfirm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    reset(defaultValues);
  }, [id]);

  const onSubmit = async (values) => {
    const { error, result } = await dispatch(updateMessageModel(id, values));
    if (error) return alert(error);
    reset(values);
    onUpdated(result);
  };

  const onDelete = async () => {
    await confirm({
      title: t('messageModelsSettings.deleteConfirm.title'),
      description: t('messageModelsSettings.deleteConfirm.description'),
      cancellationText: t('messageModelsSettings.deleteConfirm.cancellationText'),
      confirmationText: t('messageModelsSettings.deleteConfirm.confirmationText'),
      confirmationButtonProps: {
        variant: 'outlined',
        className: c.deleteBtn,
        startIcon: <Delete />,
      },
      cancellationButtonProps: {
        variant: 'text',
      },
    });
    const error = await dispatch(deleteMessageModel(id));
    if (error) return alert(error);
    onDeleted(id);
  };

  const showSave = formState.isValid && formState.isDirty;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <div className={c.tagWrapper}>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <TextField variant="outlined" label="Nom" fullWidth value={value} onChange={onChange} />
            )}
          />
          <EditableTags tags={defaultValues?.tags} element='MESSAGE_MODEL' element_id={id} />
        </div>

        <Controller
          control={control}
          name="body"
          render={({ onChange, value }) => <TipTapUnipile onChange={onChange} initialContent={defaultValues?.body} />}
        />
      </div>
      <div className={c.bottomBar}>
        <div className={c.actions}>
          {showDelete && (
            <Button variant="outlined" className={c.deleteBtn} startIcon={<Delete />} onClick={onDelete}>
              {t('messageModelsSettings.form.delete')}
            </Button>
          )}
          <Button onClick={handleSubmit(onSubmit)} disabled={!showSave} color="secondary">
            {t('messageModelsSettings.form.save')}
          </Button>
        </div>
        <div>
          <div className={c.variables}>
            Variables : { MESSAGE_MODEL_VARIABLES.map((variable) => <Chip key={variable} variant="outlined" size="small" label={`{{ ${variable} }}`} />) }
          </div>
        </div>
      </div>

    </div>
  );
};

export default MessageModelEditor;
