import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { callResultsSelector, scrollResults, selectSearchResult, useAppDispatch } from '@focus-front/core';
import { useClasses } from './SearchTabProps';
import NoResults from './NoResults';
import { Virtuoso } from 'react-virtuoso';
import { debounce } from '@material-ui/core';
import CallSearchResult from '../Call/CallSearchResult';
import CallSearchResultFake from '../Call/CallSearchResultFake';

export default function CallSearchTab() {
  const c = useClasses();
  const { results, scroll, status } = useSelector(callResultsSelector);
  const dispatch = useAppDispatch();

  const handleScroll = () => {
    const firstRendered = document.querySelector('#search-results > div > div > div');
    if (firstRendered) dispatch(scrollResults({ tab: 'call', scroll: Number(firstRendered.getAttribute('data-index')) }));
  };

  if (status === 'LOADING')
    return (
      <div className={c.wrapper}>
        <CallSearchResultFake />
        <CallSearchResultFake />
        <CallSearchResultFake />
        <CallSearchResultFake />
      </div>
    );
  if (!results.length) return <NoResults />;

  return (
    <div className={c.wrapper}>
      <Virtuoso
        style={{ height: '100%' }}
        totalCount={results.length}
        initialTopMostItemIndex={scroll}
        onScroll={debounce(handleScroll, 100)}
        id="search-results"
        itemContent={(index) => (
          <CallSearchResult
            key={index}
            index={index}
            call={results[index]}
            onClick={() => dispatch(selectSearchResult({ type: 'call', id: results[index].item.id }))}
          />
        )}
      />
    </div>
  );
}
