import { ElectronError } from '../app/Errors';
import { MaybePromise } from './utilityTypes';

/**
 * Identity function.
 */
export const identity = <TExpr>(expression: TExpr): TExpr => expression;

/**
 * Return a pipe that threads an expression through given functions.
 */
export const pipe =
  <TExpr>(...funcs: ((expr: TExpr) => TExpr)[]) =>
  (expression: TExpr): TExpr =>
    funcs.reduce((current, func) => func(current), expression);

/**
 * Return a pipe that threads an expression through given maybe async functions.
 *
 * @note Switched to a regular loop as reduce was too opaque / flaky with async.
 */
export const asyncPipe =
  <TExpr>(...funcs: ((expr: TExpr) => MaybePromise<TExpr>)[]) =>
  async (expression: TExpr): Promise<TExpr> => {
    for (let i = 0, length = funcs.length; i < length; ++i) {
      expression = await funcs[i](expression);
    }
    return expression;
  };
// export const asyncPipe = <TExpr>(...funcs: ((expr: TExpr) => MaybePromise<TExpr>)[]) => (expression: TExpr) =>
//   funcs.reduce(async (current, func) => func(await current), Promise.resolve(expression));

interface ElectronResult<T> {
  result: T;
  error: {
    name: string;
    message: string;
    extra: { [key: string]: any };
  };
}

/**
 * Invoke an Electron event function and throw errors returned as object
 * Errors are returned as object because they become opaque in the rendered process when thrown in the main process
 * @param func Function returning the event to invoke
 * @returns the result of the event
 */
export async function invokeWithErrors<T>(func: () => Promise<ElectronResult<T>>): Promise<T> {
  const { result, error } = await Promise.resolve(func());
  if (error) {
    throw new ElectronError(error.message, error.name, error.extra);
  }
  return result;
}
