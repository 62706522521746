import React, { useEffect } from 'react';
import { mailDone, useAppDispatch } from '@focus-front/core';
import { ActionModalProps } from './ActionModalProps';

export default function MailDone({ onDone, context }: ActionModalProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const error = await dispatch(mailDone(context));
      if (!error) onDone('done');
      else alert(error);
    })();
  }, []);

  return <div></div>;
}
