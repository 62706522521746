import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import TipTapToolbar from './TipTapToolbar';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import FontFamily from '@tiptap/extension-font-family';
import Color from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import {
  CustomTable,
  CustomTableCell,
  CustonTableHeader,
  CustonTableRow,
  FontSize,
  DivNodeBlock,
  StyleAttr,
} from '@focus-front/ui';
import { Chip, makeStyles } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import './Prosemirror.scss';
import { useState } from 'react';

const useClasses = makeStyles((theme) => ({
  container: {
    minHeight: 0,
    height: 300,
    borderRadius: 4,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  editorContainer: {
    height: '100%',
    overflow: 'auto',
  },
  editorMail: {
    padding: `${theme.spacing(3.5)}px ${theme.spacing(2)}px`,
    flex: 1,
    minHeight: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& blockquote': {
      borderTop: '1px solid black',
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.grey[300],
    },
  },
  editorContainerSignature: {
    padding: 1,
    height: '100%',
    overflow: 'auto',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    border: `solid 1px ${theme.palette.divider}`,
    '&:hover': {
      border: `solid 1px ${theme.palette.text.primary}`,
    },
  },
  editorSignature: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    flex: 1,
    minHeight: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  draftMsg: {
    position: 'absolute',
    right: theme.spacing(4.5),
  },
  hasFocus: {
    padding: 0,
    border: `solid 2px ${theme.palette.secondary.main}`,
    '&:hover': {
      border: `solid 2px ${theme.palette.secondary.main}`,
    },
  },
}));

interface TipTapUnipileProps {
  onChange: (value: string) => void;
  initialContent: string;
  upperToolbar?: React.ReactNode;
  lowerToolbar?: React.ReactNode;
  isEditorMail?: boolean;
  minifiedToolbar?: boolean;
  isSaved?: boolean;
}

const TipTapUnipile: React.FC<TipTapUnipileProps> = ({
  onChange,
  initialContent = '',
  upperToolbar,
  lowerToolbar,
  isEditorMail = false,
  minifiedToolbar = false,
  isSaved,
}) => {
  const c = useClasses();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (editor && editor.getHTML() !== initialContent) {
      editor.commands.setContent(initialContent);
    }
  }, [initialContent]);

  const editor = useEditor({
    content: initialContent,
    editorProps: {
      attributes: {
        class: isEditorMail ? c.editorMail : c.editorSignature,
      },
    },
    extensions: [
      StarterKit.configure({
        paragraph: {
          HTMLAttributes: {
            style: 'margin: 0',
          },
        },
      }),
      DivNodeBlock,
      StyleAttr,
      Underline,
      Image.configure({
        inline: true,
      }),
      CustomTable,
      CustomTableCell,
      CustonTableHeader,
      CustonTableRow,
      TextStyle,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: false,
      }),
      Color.configure({
        types: ['textStyle', 'link', 'paragraph', 'heading', 'image'],
      }),
      FontFamily.configure({
        types: ['textStyle', 'link'],
      }),
      FontSize,
    ],
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  return (
    <div className={c.container}>
      <EditorContent editor={editor} className={(isEditorMail ? c.editorContainer : c.editorContainerSignature + ' ' + (editor?.isFocused && c.hasFocus))} />
      {isSaved && <Chip className={c.draftMsg} label={t('mailForm.draftSaved')} icon={<Done />} color="secondary" size="small" />}
      {upperToolbar}
      <TipTapToolbar editor={editor} isEditorMail={isEditorMail} lowerToolbar={lowerToolbar} minified={minifiedToolbar} />
    </div>
  );
};

export default TipTapUnipile;
