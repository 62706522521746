import { Box, Button, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import imTutorial1 from '../../assets/wizard_im_tutorial_1.png';
import imTutorial2 from '../../assets/wizard_im_tutorial_2.png';
import imTutorial3 from '../../assets/wizard_im_tutorial_3.png';
import { BackButton } from './NavButton';
import { WizardContext } from './WizardContext';
import { useTranslation } from 'react-i18next';

export function ImTutorial1() {
  const { goNext } = useContext(WizardContext);
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" mt={6} pb={2}>
        <img
          src={imTutorial1}
          alt="tutorial"
          height="337"
          style={{ alignItems: 'flex-end', marginLeft: 'auto', marginRight: -16 }}
        />
        <Box pl={10} pr={10} mb={3} mt={2}>
          <Typography variant="h2" align="center" style={{ lineHeight: 1.3 }}>
            {t('accountSettings.wizard.step1')}
          </Typography>
        </Box>
        <Button variant="contained" color="secondary" onClick={goNext}>
          {t('wizard.next')}
        </Button>
      </Box>
    </>
  );
}

export function ImTutorial2() {
  const { goBack, goNext } = useContext(WizardContext);
  const { t } = useTranslation();

  return (
    <>
      <BackButton onClick={goBack} />
      <Box display="flex" flexDirection="column" alignItems="center" mt={3} pb={2}>
        <img
          src={imTutorial2}
          alt="tutorial"
          height="421"
          style={{ alignItems: 'flex-end', marginLeft: 'auto', marginRight: -16 }}
        />
        <Box pl={5} pr={5} mb={4} mt={8}>
          <Typography variant="h2" align="center" style={{ lineHeight: 1.3 }}>
            {t('accountSettings.wizard.step2')}
          </Typography>
        </Box>
        <Button variant="contained" color="secondary" onClick={goNext}>
          {t('wizard.next')}
        </Button>
      </Box>
    </>
  );
}

export function ImTutorial3({ onEnd }: { onEnd: () => void }) {
  const { goBack } = useContext(WizardContext);
  const { t } = useTranslation();

  return (
    <>
      <BackButton onClick={goBack} />
      <Box display="flex" flexDirection="column" alignItems="center" mt={3} pb={2}>
        <img
          src={imTutorial3}
          alt="tutorial"
          height="390"
          style={{ alignItems: 'flex-end', marginLeft: -16, marginRight: 'auto' }}
        />
        <Box pl={10} pr={10} mb={3} mt={2}>
          <Typography variant="h2" align="center" style={{ lineHeight: 1.3 }}>
            {t('accountSettings.wizard.step3')}
          </Typography>
        </Box>
        <Button variant="contained" color="secondary" onClick={onEnd}>
          {t('wizard.next')}
        </Button>
      </Box>
    </>
  );
}
