import { IDBPDatabase } from 'idb';
import { LedgerStore } from '../../domain/LedgerStore';
import { ProjectorCursor } from '../../domain/Projectionist';
import { SingleOrArray } from '../../utils';
import { DBSchema, Idb } from '../services/idb';

/**
 *
 */
export class IdbLedgerStore implements LedgerStore {
  /**
   *
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {
    this.put = this.put.bind(this);
  }

  /**
   *
   */
  async get(projectorName: string) {
    return (await Idb.get('ledger', projectorName)) ?? null;
  }

  /**
   *
   */
  async getAll() {
    return Idb.getAll('ledger');
  }

  /**
   *
   */
  async put(cursors: SingleOrArray<ProjectorCursor>) {
    /** Wrap single cursor in an array. */
    if (!Array.isArray(cursors)) {
      cursors = [cursors];
    }

    const tx = Idb.transaction('ledger', 'readwrite');

    await Promise.all([
      ...cursors.map((cursor) => {
        tx.store.put(cursor);
      }),
      tx.done,
    ]);
  }
  /**
   *
   */
  async clear() {
    Idb.clear('ledger');
  }
  //   /**
  //    *
  //    */
  //   remove(projectorName: string) {
  //     this.byProjectorName.delete(projectorName);
  //   }
}
