import dayjs from 'dayjs';

import { ImServicesMap } from '../../_container/interfaces/Container';
import { ViewAccountRepo } from '../../Account/domain/projections/ViewAccountRepo';
import {
  ActionContext,
  createUuidFrom,
  FileManagerService,
  InvalidAccountError,
  isEvent,
  isNotification,
  LocalFile,
  LoggerService,
  NoPermissionsError,
  Publisher,
  Result,
  toUTCDateTimeMs,
  UnexpectedError,
  UnsupportedDevice,
  FileNotFoundError,
  Uuid,
} from '../../Common';
import { ViewCalendar } from '../../Calendar';
import { Im, ImAttachment, ImThread, IM_THREAD_NAMESPACE, ViewIm, ViewImRepo } from '../domain';
import { ImUseCase } from './ImUseCase';
import { SearchService } from '../../Search';
import { ViewImThreadRepo } from '../domain/projections/ViewImThreadRepo';
import { NewImThreadDTO, ViewImThread } from '../domain/projections/ViewImThread';
import i18n from '../../Common/infra/services/i18nextService';
import { NotificationUseCase } from '../../Notification';
import { CalendarEvent, CalendarUseCase, DEFAULT_EVENT_INTERVAL, ViewCalendarEvent } from '../../Calendar';
import { Environment } from '../../_container/interfaces/Environment';
import { notEmpty, JobCancelledError } from '../../Common/utils';
import { Attendee, ContactUseCase, displayAttendeeName, IdentifierType, ViewContactRepo } from '../../Contact';
import { ImRepo } from '../infra/repository/ImRepo';
import {
  AccountRepo,
  AccountSourceStatus,
  isEnvSupportedAccount,
  isImSupportedAccount,
  ViewAccount,
  AccountType,
} from '../../Account';
import { ViewCalendarEventRepo } from '../../Calendar/domain/projections/ViewCalendarEventRepo';
import { ImThreadRepo } from '../infra/repository/ImThreadRepo';
import { InvalidCredentials } from '../../Account/infra/services/ScrapFormAuthService';
import { Taggable, TagRelation } from '../../Tag';

export class CantSendMessageError extends Error {
  constructor() {
    super(i18n.t('error.im.cantSendMessage'));
    this.name = 'CantSendMessageError';
  }
}
export class MissingThreadError extends Error {
  constructor() {
    super('Missing thread');
    this.name = 'MissingThreadError';
  }
}

export class AppImUseCase implements ImUseCase {
  ERROR_UNEXPECTED = i18n.t('error.unexpected');
  ERROR_UNSUPPORTED_DEVICE = 'UNSUPPORTED_DEVICE';

  constructor(
    private readonly publisher: Publisher,
    private readonly notification: NotificationUseCase,
    private readonly contact: ContactUseCase,
    private readonly calendar: CalendarUseCase,
    private readonly im: ImRepo,
    private readonly imThread: ImThreadRepo,
    private readonly account: AccountRepo,
    private readonly viewIm: ViewImRepo,
    private readonly viewImThread: ViewImThreadRepo,
    private readonly viewAccount: ViewAccountRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly viewContact: ViewContactRepo,
    private readonly imServices: ImServicesMap,
    private readonly logger: LoggerService,
    private readonly search: SearchService,
    private readonly fileManager: FileManagerService,
    private readonly env: Environment
  ) {}

  async get(im_id: Uuid): Promise<ViewIm | null> {
    return this.viewIm.get(im_id);
  }

  /**
   * Retrieve the contact of each attendee and assign it's display_name / picture in the ViewImThread object
   * @param thread
   */
  async _retrieveContactForThread(thread: ViewImThread): Promise<void> {
    await Promise.all(
      thread.attendees.map(async (att, index) => {
        const contacts = await this.viewContact.getByIdentifier(att.identifier_type, att.identifier);
        if (contacts.length) {
          thread.attendees[index].display_name = contacts[0].full_name;
          thread.attendees[index].profile_picture = contacts[0].profile_picture || thread.attendees[index].profile_picture;
        }
      })
    );
  }

  async getThread(thread_id: Uuid): Promise<Result<(ViewImThread & Taggable) | null>> {
    try {
      const thread = await this.viewImThread.get(thread_id);

      if (!thread) return {};

      await this._retrieveContactForThread(thread);

      return {
        result: thread,
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  async getThreadIms(thread_id: Uuid): Promise<ViewIm[]> {
    return this.viewIm.getByThread(thread_id);
  }

  async getAllThreads(
    size: number,
    offset: number
  ): Promise<{ thread: ViewImThread & Taggable; last_im: ViewIm | null; account: ViewAccount | null }[]> {
    const threads = await this.viewImThread.getAllByDate(size, offset);

    const accounts = await this.viewAccount.getAll();

    return Promise.all(
      threads.map(async (thread) => {
        const last_im = await this.viewIm.getLatestByThread(thread.id);
        await this._retrieveContactForThread(thread);
        return {
          thread,
          last_im,
          account: accounts.find((acc) => acc.id === thread.account_id) || null,
        };
      })
    );
  }

  async getOneToOneThreadByAttendee(identifier: string, identifier_type: IdentifierType): Promise<ViewImThread | null> {
    const threads = await this.viewImThread.getByAttendeeIdentifier(identifier_type, identifier);
    return threads.find((thread) => thread.attendees.length === 2) || null;
  }

  /**
   *
   */
  async getAttachmentFile(account_id: Uuid, attachment: ImAttachment, should_cache = true): Promise<Result<LocalFile>> {
    try {
      const account = await this.viewAccount.get(account_id);

      // /**
      //  * On Mobile : we only store attachments path and informations in the fileStore. The file itself is saved in the app data folder on the device.
      //  * > We can directly start a download or open the file by passing its path.
      //  *
      //  * On Web : we store attachments informations AND data as base64 in the fileStore.
      //  * > We can force the file download by creating a temporary link element with a download attribute and emulate a click on it.
      //  *
      //  * On Desktop : we only store attachments path and informations in the fileStore. The file itself is saved in the app data folder on the device.
      //  * > We first need to load the file data as base64 if we want to allow the user to download it. This is why we need to pass withData = true in the case of a desktop env.
      //  * @see FsService
      //  */

      const local_attachment = await this.fileManager.get(attachment.id);

      let file: LocalFile | null = local_attachment;

      if (!local_attachment || !local_attachment.data) {
        if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();
        if (!isEnvSupportedAccount(account, this.env, 'IMS')) throw new UnsupportedDevice();

        const data = await this.imServices[account.type].downloadAttachment(account_id, attachment);

        file = {
          id: attachment.id,
          extension: data.extension || attachment.extension,
          mime: data.mime || attachment.mime,
          name: attachment.name,
          size: data.size,
          data: data.data,
        };

        if (should_cache) await this.fileManager.add(file);
      }

      if (!file) throw new FileNotFoundError();

      return { result: file };
    } catch (e) {
      if (e instanceof InvalidAccountError || e instanceof UnsupportedDevice || e instanceof FileNotFoundError)
        return { error: e.name };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async imDone(calendar: ViewCalendar, context: ActionContext): Promise<Result<ViewCalendarEvent | null>> {
    try {
      if (isNotification(context)) {
        await this.notification.archiveNotification(context.id);

        const end = dayjs();
        const start = end.subtract(DEFAULT_EVENT_INTERVAL, 'minute');

        if (!context.metadata?.attached_entity?.id) {
          throw new Error(`Could not terminate without the attached entity`);
        }

        /**
         * An event is created only if messages has been sent in the thread
         */
        const new_messages = await this.viewIm.getFromDateByThread(context.metadata.attached_entity.id, context.received_date);
        const has_sent_messages = new_messages.filter((m) => m.direction === 'OUTGOING').length > 0;

        const thread = await this.viewImThread.get(context.metadata.attached_entity.id);

        if (!thread) {
          throw new Error(`Thread doesn't exist`);
        }

        const account = await this.viewAccount.get(thread.account_id);

        if (!account) {
          throw new Error(`Thread's account doesn't exist`);
        }

        if (has_sent_messages) {
          const result = CalendarEvent.create({
            kind: 'SINGLE',
            type: 'UNIPILE',
            all_day: false,
            calendar_id: calendar.id,
            summary: i18n.t('event.defaultTitle.im_threadDone', {
              provider: account.type === 'MOBILE' ? 'SMS' : i18n.t('accounts.' + account.type.toLowerCase()),
              attendee:
                thread.attendees.length > 2 && thread.provider_name
                  ? thread.provider_name
                  : thread.attendees
                      .map(displayAttendeeName)
                      .filter((name) => name !== 'YOU')
                      .join(', '),
            }),
            start: toUTCDateTimeMs(start),
            end: toUTCDateTimeMs(end),
            start_tzid: calendar.default_tzid,
            end_tzid: calendar.default_tzid,
            metadata: {
              attached_entity: {
                id: context.metadata.attached_entity.id,
                type: 'IM_THREAD',
                account_id: account.id,
                account_type: account.type,
              },
              origin_notification: context,
            },
          });

          await this.publisher.emit(result.changes);

          if (result.ok === true) {
            /** @todo Track real_start ! */
            const now = toUTCDateTimeMs(dayjs());
            const eventCompleted = result.aggregate.complete(now, now);
            if (eventCompleted.ok === true) {
              await this.publisher.emit(eventCompleted.changes);
              const event = await this.viewCalendarEvent.get(result.aggregate.id);
              return event ? { result: event } : { error: `Could not find calendar event ( ${result.aggregate.id} ).` };
            } else {
              throw new Error(`Could not complete calendar event ( ${result.aggregate.id} ).`);
            }
          } else {
            throw new Error(`Could not add calendar event : ${result.reason}.`);
          }
        }

        return { result: null };
      }

      if (isEvent(context)) {
        if (context.metadata?.attached_entity?.id) {
          /**
           * An event is created only if messages has been sent in the thread
           */
          const new_messages = await this.viewIm.getFromDateByThread(context.metadata?.attached_entity?.id, context.start);
          const has_sent_messages = new_messages.filter((m) => m.direction === 'OUTGOING').length > 0;

          if (has_sent_messages) await this.calendar.completeEvent(context);
          else await this.calendar.deleteEvent(context.id);
        }

        return { result: null };
      }

      throw new Error(`Context is not Event or Notification`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Create a new ViewIm as soon as the users sends it
   */
  async imChatSubmit(message: string, thread: ViewImThread): Promise<Result<ViewIm>> {
    try {
      if (!thread) throw new MissingThreadError();

      const account = await this.viewAccount.get(thread.account_id);

      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();

      const { aggregate, changes } = Im.create(
        {
          account_id: account.id,
          /**
           * @todo create an Attendee per account representing the user
           */
          from_attendee_identifier: 'YOU',
          date: toUTCDateTimeMs(dayjs()),
          provider_thread_id: thread.provider_id,
          direction: 'OUTGOING',
          body: message,
        },
        thread.id,
        'SUBMIT'
      );

      const send = aggregate.send();

      await this.publisher.emit([...changes, ...send.changes]);

      const result = await this.viewIm.get(aggregate.id);

      if (!result) throw new UnexpectedError();

      return { result };
    } catch (e) {
      if (e instanceof UnsupportedDevice) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async imChatSend(
    im: ViewIm,
    thread: ViewImThread
  ): Promise<Result<{ im: ViewIm; thread: Pick<ViewImThread, 'sync_status' | 'provider_id' | 'provider_name'> }>> {
    try {
      if (!thread) throw new MissingThreadError();

      const account = await this.viewAccount.get(thread.account_id);

      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();

      const { send_status, error, ...threadUpdateValues } = await this.imServices[account.type].sendMessage(
        account.id,
        im,
        thread
      );
      if (error) throw error;
      if (send_status) {
        const imAggregate = await this.im.getAggregate(im.id);
        await this.publisher.emit(send_status === 'SENT' ? imAggregate.successSend().changes : imAggregate.errorSend().changes);
      }

      /**
       * Acknowledge remote thread after message is sent
       */
      if (threadUpdateValues.provider_id && thread.sync_status === 'NOT_SYNC') {
        const imThreadAggregate = await this.imThread.getAggregate(thread.id);
        const acknowledged = imThreadAggregate.acknowledge(threadUpdateValues);
        if (acknowledged.ok) {
          await this.publisher.emit(acknowledged.changes);
        }
      }

      const result_im = await this.viewIm.get(im.id);
      const result_thread = await this.viewImThread.get(im.thread_id);
      if (!result_im || !result_thread) throw new UnexpectedError();

      return {
        result: {
          im: result_im,
          thread: {
            sync_status: result_thread.sync_status,
            provider_id: result_thread.provider_id,
            provider_name: result_thread.provider_name,
          },
        },
      };
    } catch (e) {
      if (e instanceof CantSendMessageError) return { error: e.message };
      if (e instanceof UnsupportedDevice) return { error: e.message };
      // if (e instanceof CanceledError) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async writeNewIm(calendar: ViewCalendar, attendee: Attendee, account_id: Uuid): Promise<Result<ViewCalendarEvent>> {
    try {
      let id: Uuid;

      // Find the 1 to 1 thread (exclude groups)
      const oneToOne = await this.getOneToOneThreadByAttendee(attendee.identifier, attendee.identifier_type);

      // If we don't have conversation history with the contact, we must create the thread locally
      if (!oneToOne) {
        const account = await this.viewAccount.get(account_id);

        if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();

        // Create locally
        const { changes, aggregate } = ImThread.create(
          {
            account_id,
            attendees: [attendee, { identifier: 'YOU', identifier_type: attendee.identifier_type, display_name: 'YOU' }],
            provider_id: createUuidFrom(attendee.identifier + attendee.identifier_type + account_id, IM_THREAD_NAMESPACE),
          },
          'SUBMIT'
        );

        let all_changes = changes;

        // Assign account's tags to the thread
        account.tags.forEach((tag) => {
          const { changes } = TagRelation.create({ element: 'IM_THREAD', element_id: aggregate.id, tag_id: tag.id });
          all_changes = all_changes.concat(changes);
        });

        await this.publisher.emit(all_changes);

        id = aggregate.id;
      } else {
        id = oneToOne.id;
      }

      return this.imThreadWork(calendar, id);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async imThreadWork(calendar: ViewCalendar, thread_id: Uuid): Promise<Result<ViewCalendarEvent>> {
    try {
      const thread = await this.viewImThread.get(thread_id);

      if (!thread) throw new Error('Thread not found : AppImUseCase | imThreadWork');

      const account = await this.viewAccount.get(thread.account_id);

      if (!account) throw new Error("Thread's account not found : AppImUseCase | imThreadWork");

      /**
       * @note Maybe we should check if the thread is not attached to anything before ?
       */
      const start = dayjs();
      const end = start.add(DEFAULT_EVENT_INTERVAL, 'minute');
      const result = CalendarEvent.create({
        kind: 'SINGLE',
        type: 'UNIPILE',
        all_day: false,
        calendar_id: calendar.id,
        summary: i18n.t('event.defaultTitle.im_threadWork', {
          provider: account.type === 'MOBILE' ? 'SMS' : i18n.t('accounts.' + account.type.toLowerCase()),
          attendee:
            thread.attendees.length > 2 && thread.provider_name
              ? thread.provider_name
              : thread.attendees
                  .map(displayAttendeeName)
                  .filter((name) => name !== 'YOU')
                  .join(', '),
        }),
        start: toUTCDateTimeMs(start),
        end: toUTCDateTimeMs(end),
        start_tzid: calendar.default_tzid,
        end_tzid: calendar.default_tzid,
        metadata: {
          attached_entity: {
            id: thread_id,
            type: 'IM_THREAD',
            account_id: account.id,
            account_type: account.type,
          },
        },
      });

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const event = await this.viewCalendarEvent.get(result.aggregate.id);
        return event ? { result: event } : { error: `Could not find calendar event ( ${result.aggregate.id} ).` };
      }
      throw new Error(`Could not add calendar event : ${result.reason}.`);
    } catch (error) {
      this.logger.captureException(error);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Fetch new messages remotely for a specific threads
   * Create the thread if it doesn't exists
   */
  async fetchNewInThread(thread_id: Uuid): Promise<Result<ViewIm[]>> {
    try {
      const thread = await this.viewImThread.get(thread_id);

      if (!thread) return {};
      if (thread.sync_status !== 'SYNC') return {};

      const account = await this.viewAccount.get(thread.account_id);

      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();
      if (!isEnvSupportedAccount(account, this.env, 'IMS')) throw new UnsupportedDevice();

      let extra;

      const results = await this.imServices[account.type].searchMessages(account.id, {
        after_date: thread.last_update || toUTCDateTimeMs(dayjs()),
        provider_thread_id: thread.provider_id,
        extra,
      });

      /**
       * Delete ims that were sent from unipile (that are NOT_SYNC and not SENDING) because they are fetched here
       */
      const all_ims = await this.viewIm.getByThread(thread.id);
      await Promise.all(
        all_ims
          .filter((im) => im.sync_status === 'NOT_SYNC' && im.send_status !== 'SENDING')
          .map(async (im) => {
            const aggregate = await this.im.getAggregate(im.id);
            return this.publisher.emit(aggregate.replace().changes);
          })
      );

      // Create all new ims and get them
      const returnResults: ViewIm[] = [];

      await Promise.all(
        results.map(async ({ im }) => {
          const { aggregate, ...result } = Im.create(im, thread_id, 'SYNC');
          try {
            await this.publisher.emit(result.changes);
            const im = await this.viewIm.get(aggregate.id);
            if (im) returnResults.push(im);
          } catch (e) {
            // If an IM is already sync (by the openned threadView), ignore the error and return null.
            if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
              return null;
            }
            throw e;
          }
        })
      );

      returnResults.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

      return {
        result: returnResults,
      };
    } catch (e) {
      if (e instanceof UnsupportedDevice || e instanceof NoPermissionsError) return { error: e.name };
      /**
       * CanceledError can occur when :
       * - Scraper Queue is emptied due to Account suppression
       * - By manually ignoring calls when a thread_id is specified but it is not from the currently fetched account_id
       */
      // if (e instanceof CanceledError) return {};
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Fetch new messages remotely for an Im supported account and create them locally before returning them
   * Create the thread if it doesn't exists
   */
  async fetchNew(account_id: Uuid): Promise<{ result: { im: ViewIm; thread: ViewImThread }[]; status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();
      if (!isEnvSupportedAccount(account, this.env, 'IMS')) throw new UnsupportedDevice();

      /**
       * after_date search query defined in the following order
       * - last fetch date of the account
       * - account creation date
       */
      const last_used_date = account.last_fetched_at || account.created_at;

      const results = await this.imServices[account.type].searchMessages(account.id, {
        after_date: last_used_date,
        extra:
          account.type === 'LINKEDIN' ||
          account.type === 'MESSENGER' ||
          account.type === 'TIKTOK' ||
          account.type === 'INSTAGRAM' ||
          account.type === 'TWITTER'
            ? 'attendees'
            : undefined,
      });

      // Deduplicate thread for the next step
      const map: { [key: string]: boolean } = {};
      const threads: NewImThreadDTO[] = [];
      results.forEach(({ thread }) => {
        if (!thread) return;
        if (!map[thread.account_id + thread.provider_id]) {
          map[thread.account_id + thread.provider_id] = true;
          threads.push(thread);
        }
      });

      // Create all new threads fetched
      await Promise.all(
        threads.map(async (thread) => {
          /**
           * Try to find a local, not sync thread based on the identifier to acknowledge the thread
           * This is to handle the case where the user want to send a new message to a contact without a thread remotely created :
           * A thread will be created locally but won't be remotely until he actually send a message.
           * If the user leave this not synced thread on the side without sending anything and receive a message from that contact in the meanwhile,
           * it should make the relation with the not sync thread, not create a new one
           */
          let found_not_sync_thread = null;
          const attendees = thread.attendees.filter((att) => att.display_name !== 'YOU');
          if (attendees.length === 1) {
            found_not_sync_thread = await this.viewImThread.getByProviderId(
              thread.account_id,
              createUuidFrom(attendees[0].identifier + attendees[0].identifier_type + account_id, IM_THREAD_NAMESPACE)
            );
          }

          // If a not sync thread is found, acknowledge
          if (found_not_sync_thread) {
            const agg = await this.imThread.getAggregate(found_not_sync_thread.id);
            return this.publisher.emit(agg.acknowledge(thread).changes);
          }

          // If not, try to find the sync thread if it already exists
          const found_thread = await this.viewImThread.getByProviderId(thread.account_id, thread.provider_id);

          // If not, create it
          if (!found_thread) {
            /**
             * If the thread we are in is a 1to1 LINKEDIN thread, create the contact from the attendee
             * (because we know that a 1to1 thread is necessarily with a contact in LinkedIn, contrary to other providers)
             */
            if (thread.attendees.length === 2 && (account.type === 'LINKEDIN' || account.type === 'INSTAGRAM')) {
              const attendee = thread.attendees.find((att) => att.display_name !== 'YOU');
              if (attendee) {
                await this.contact.createContactFromAttendee(attendee, account_id);
              }
            }

            const { changes, aggregate } = ImThread.create(thread, 'SYNC');

            let all_changes = changes;

            // Assign account's tags to the thread
            account.tags.forEach((tag) => {
              const { changes } = TagRelation.create({ element: 'IM_THREAD', element_id: aggregate.id, tag_id: tag.id });
              all_changes = all_changes.concat(changes);
            });

            return this.publisher.emit(all_changes);
          }

          /**
           * If the thread is found and has a different amount of attendees, update the list of attendees.
           * This is to handle threads without all the informations about attendees after the initialFetch (for exemple, Linkedin)
           * AND groups that add / remove people
           */
          if (thread.attendees.length !== found_thread.attendees.length) {
            const agg = await this.imThread.getAggregate(found_thread.id);

            /**
             * If the thread we are in is a 1to1 LINKEDIN thread, create the contact from the attendee
             * (because we know that a 1to1 thread is necessarily with a contact in LinkedIn, contrary to other providers)
             */
            if (thread.attendees.length === 2 && (account.type === 'LINKEDIN' || account.type === 'INSTAGRAM')) {
              const attendee = thread.attendees.find((att) => att.display_name !== 'YOU');
              if (attendee) {
                await this.contact.createContactFromAttendee(attendee, account_id);
              }
            }

            return this.publisher.emit(agg.acknowledgeAttendees({ attendees: thread.attendees }).changes);
          }
        })
      );

      // Create all new ims with parent thread
      const returnResults = (
        await Promise.all(
          results.map(async ({ im }) => {
            const found_thread = await this.viewImThread.getByProviderId(account_id, im.provider_thread_id);
            if (found_thread) {
              const { aggregate, ...result } = Im.create(im, found_thread.id, 'SYNC');
              try {
                await this.publisher.emit(result.changes);
              } catch (e) {
                // If an IM is already sync (by the openned threadView), ignore the error and return null.
                if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
                  return null;
                }
                throw e;
              }

              return {
                im: await this.viewIm.get(aggregate.id),
                thread: await this.viewImThread.get(found_thread.id),
              };
            }
          })
        )
      ).filter((res): res is { im: ViewIm; thread: ViewImThread & Taggable } => res !== null);

      /**
       * Update the last fetch date of the account
       *
       * Update only account.last_fetched_at if the last message date is after the last used date
       * Otherwise, after the initial fetch, some threads might be loaded with older messages
       * This makes the sync take the most recent value between either the last message date or the account creation date
       **/
      const last_message = await this.viewIm.getLatestByAccountAndSyncStatus(account.id, 'SYNC');
      if (last_message) {
        const last_message_date_is_after_last_used_date = dayjs(last_message.date).isAfter(last_used_date);
        if (last_message_date_is_after_last_used_date) {
          const acc = await this.account.getAggregate(account_id);
          const update_acc = acc.markFetched({ last_fetched_at: last_message.date, type: account.type });
          await this.publisher.emit(update_acc.changes);
        }
      }

      return {
        result: returnResults,
        status: 'IDLE',
      };
    } catch (e) {
      if (e instanceof UnsupportedDevice) return { result: [], status: 'UNSUPPORTED' };
      if (e instanceof NoPermissionsError) return { result: [], status: 'PERMISSIONS' };
      if (e instanceof InvalidCredentials) return { result: [], status: 'CREDENTIALS' };
      if (e instanceof JobCancelledError) return { result: [], status: 'IDLE' };

      /**
       * CanceledError can occur when :
       * - Scraper Queue is emptied due to Account suppression
       * - By manually ignoring calls when a thread_id is specified but it is not from the currently fetched account_id
       */
      // if (e instanceof CanceledError) return { result: [], status: 'IDLE' };
      this.logger.captureException(e);

      return { result: [], status: 'ERROR' };
    }
  }

  /**
   * Initial thread fetch when an IM supported account is added
   */
  async initialFetch(account_id: Uuid): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();
      if (!isEnvSupportedAccount(account, this.env, 'IMS')) throw new UnsupportedDevice();

      const threads = await this.imServices[account.type].getThreadsList(account_id);

      await Promise.all(
        threads.map(async (thread) => {
          const found_thread = await this.viewImThread.getByProviderId(thread.account_id, thread.provider_id);
          if (!found_thread) {
            const { changes, aggregate } = ImThread.create(thread, 'SYNC');

            let all_changes = changes;

            // Assign account's tags to the thread
            account.tags.forEach((tag) => {
              const { changes } = TagRelation.create({ element: 'IM_THREAD', element_id: aggregate.id, tag_id: tag.id });
              all_changes = all_changes.concat(changes);
            });

            try {
              await this.publisher.emit(all_changes);
            } catch (e) {
              // If a thread is already created, ignore the error and return null.
              // This can happen due to a bug on Linkedin mobile website, some threads are shown twice...
              if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
                return null;
              }
              throw e;
            }
          }
        })
      );

      return {};
    } catch (e) {
      if (e instanceof UnsupportedDevice || e instanceof NoPermissionsError) return { error: e.name };
      /**
       * CanceledError can occur when :
       * - Scraper Queue is emptied due to Account suppression
       * - By manually ignoring calls when a thread_id is specified but it is not from the currently fetched account_id
       */
      // if (e instanceof CanceledError) return {};
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Get old messages (when scrolling top) in a thread sorted by date
   * Get them locally or remotely of needed
   * @param thread_id Id of the thread
   * @param size Number of messages wanted
   * @param offset Number of messages to offset
   *
   * @returns ims : List of messages
   * @returns reached_end_local : Boolean indicating whether that was the oldest locally stored messages returned
   * @returns reached_end_remote : Boolean indicating whether that was the oldest messages returned from all time
   * @returns must_reload_thread : Boolean indicating whether new informations have been updated in the thread
   * => Use case ex : Attendees in a LinkedIn Thread are unknown when the user add an account because it take to much time
   * to scrap, so we get them at the same time we get the first messages, and the thread must be reloaded in the view.
   */
  async loadIms(
    thread_id: Uuid,
    size: number,
    offset: number
  ): Promise<Result<{ ims: ViewIm[]; reached_end_local: boolean; reached_end_remote: boolean; must_reload_thread: boolean }>> {
    try {
      const thread = await this.viewImThread.get(thread_id);

      if (!thread) throw new MissingThreadError();

      // If the thread is not synced yet (when sending a message to someone without thread), there is nothing to load.
      if (thread.sync_status !== 'SYNC')
        return {
          result: {
            ims: [],
            must_reload_thread: false,
            reached_end_local: true,
            reached_end_remote: true,
          },
        };

      // Get Local ims
      const ims = await this.viewIm.getAllByThreadAndDate(thread_id, size, offset);

      // If Local ims found, return them
      if (ims && ims.length)
        return {
          result: {
            ims,
            reached_end_local: ims.length < size,
            reached_end_remote: thread.all_past_ims_synced,
            must_reload_thread: false,
          },
        };

      // If no Local ims found, try to fetch remotely

      // If we know we already fetch everything remotely, stop here to not busy the scrap queue
      if (thread.all_past_ims_synced)
        return {
          result: {
            ims: [],
            reached_end_local: true,
            reached_end_remote: true,
            must_reload_thread: false,
          },
        };

      // Check account status to fetch remotely
      const account = await this.viewAccount.get(thread.account_id);
      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();
      if (!isEnvSupportedAccount(account, this.env, 'IMS')) throw new UnsupportedDevice();

      let extra;

      // [Linkedin only] We pass "attendees" as an extra to tell the linkedin service to retrieve attendees of the thread with the messages
      if (account.type === 'LINKEDIN') {
        if (thread.attendees.find((att) => att.identifier_type === 'LINKEDIN_THREAD_ID')) extra = 'attendees';
      }
      // [Messenger copy linkedin] We pass "attendees" as an extra to tell the messenger service to retrieve attendees of the thread with the messages
      if (account.type === 'MESSENGER') {
        if (thread.attendees.find((att) => att.identifier_type === 'MESSENGER_THREAD_ID')) extra = 'attendees';
      }

      // [TikTok copy Messenger] We pass "attendees" as an extra to tell the messenger service to retrieve attendees of the thread with the messages
      if (account.type === 'TIKTOK') {
        if (thread.attendees.find((att) => att.identifier_type === 'TIKTOK_THREAD_ID')) extra = 'attendees';
      }

      // [Twitter copy Messenger] We pass "attendees" as an extra to tell the messenger service to retrieve attendees of the thread with the messages
      if (account.type === 'TWITTER') {
        if (thread.attendees.find((att) => att.identifier_type === 'TWITTER_THREAD_ID')) extra = 'attendees';
      }

      // [Instagram copy Messenger] We pass "attendees" as an extra to tell the messenger service to retrieve attendees of the thread with the messages
      if (account.type === 'INSTAGRAM') {
        if (thread.attendees.find((att) => att.identifier_type === 'INSTAGRAM_THREAD_ID')) extra = 'attendees';
      }

      /**
       * Determine the datetime to search from.
       * It's either the date of the oldest local message or now if no messages has been fetched yet.
       **/
      const oldest_im = await this.viewIm.getOldestByThread(thread_id);
      const datetime_from = oldest_im ? oldest_im.date : toUTCDateTimeMs(dayjs());

      const results = await this.imServices[account.type].searchMessages(account.id, {
        /**
         * @note For now, it will fetch the maximum of ims it can fin within 3 sec maximum (2 iterations of 1.5sec)
         */
        max: 2,
        before_date: datetime_from,
        provider_thread_id: thread.provider_id,
        extra,
      });

      const returnResults = (
        await Promise.all(
          results.map(async ({ im }) => {
            const { aggregate, ...result } = Im.create(im, thread.id, 'SYNC');
            try {
              await this.publisher.emit(result.changes);
            } catch (e) {
              // Ignore already fetched im creation errors
              if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
                console.log(`Im ${aggregate.id} already exists : `, e, im);
                return this.viewIm.get(aggregate.id);
              }
              throw e;
            }

            return this.viewIm.get(aggregate.id);
          })
        )
      ).filter(notEmpty);

      /**
       * If the number of messages fetched are inferior to the number asked, that mean we reached the top of the thread
       * We can update the thread status
       * @note For now, we consider all past ims are loaded after 1 pass
       */
      // if (results.length < STEP) {
      const hasAllPastFetched = (await this.imThread.getAggregate(thread_id)).hasAllPastFetched();
      try {
        await this.publisher.emit(hasAllPastFetched.changes);
      } catch (e) {
        if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
          console.debug('Thread already marked as hasAllPastFetched');
        } else throw e;
      }
      // }

      let must_reload_thread = false;
      /**
       * If we retrieved the attendees on this pass, update them in the thread
       */
      const update = results[0]?.update_thread;
      if (
        (account.type === 'LINKEDIN' ||
          account.type === 'MESSENGER' ||
          account.type === 'TIKTOK' ||
          account.type === 'INSTAGRAM' ||
          account.type === 'TWITTER') &&
        update &&
        update.attendees
      ) {
        const acknowledgeAttendees = (await this.imThread.getAggregate(thread_id)).acknowledgeAttendees(update);
        await this.publisher.emit(acknowledgeAttendees.changes);
        must_reload_thread = true;

        /**
         * If the thread we are in is a 1to1 thread, create the contact from the attendee
         * (because we know that a 1to1 thread is necessarily with a contact in LinkedIn, contrary to other providers)
         */
        if (update.attendees.length === 2 && (account.type === 'LINKEDIN' || account.type === 'INSTAGRAM')) {
          const attendee = update.attendees.find((att) => att.display_name !== 'YOU');
          if (attendee) {
            await this.contact.createContactFromAttendee(attendee, account.id);
          }
        }
      }

      return {
        result: {
          ims: returnResults,
          must_reload_thread,
          reached_end_local: true,
          /**
           * @note For now, we consider all past remote ims are loaded rafter 1 pass
           */
          reached_end_remote: true,
        },
      };
    } catch (e) {
      if (e instanceof UnsupportedDevice || e instanceof NoPermissionsError) return { error: e.name };
      /**
       * CanceledError can occur when :
       * - Scraper Queue is emptied due to Account suppression
       * - By manually ignoring calls when a thread_id is specified but it is not from the currently fetched account_id
       */
      // if (e instanceof CanceledError) return {};
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }
}
