import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/*
 * State shape
 */
export interface WorkState {
  notificationsFetched: boolean;
  calendarEventsFetched: boolean;
}

/*
 * Initial state
 */
const initialState: WorkState = {
  notificationsFetched: false,
  calendarEventsFetched: false,
};

/*
 * Slice
 */
const workSlice = createSlice({
  name: 'work',
  initialState,
  reducers: {
    setNotificationsFetched: (state, action: PayloadAction<boolean>) => {
      state.notificationsFetched = action.payload;
    },
    setCalendarEventsFetched: (state, action: PayloadAction<boolean>) => {
      state.calendarEventsFetched = action.payload;
    },
  },
  extraReducers: {
    'notifications/getNotifications': (state) => {
      state.notificationsFetched = true;
    },
    'calendars/eventsFetchDone': (state) => {
      state.calendarEventsFetched = true;
    },
  },
});

/*
 * Actions
 */
export const {
  setNotificationsFetched: setWorkNotificationsFetched,
  setCalendarEventsFetched: setWorkCalendarEventsFetched,
} = workSlice.actions;


/*
 * Selectors
 */

export const workNotificationsFetchedSelector = (state: { work: WorkState }) => {
  return state.work.notificationsFetched;
};

export const workCalendarEventsFetchedSelector = (state: { work: WorkState }) => {
  return state.work.calendarEventsFetched;
};
/*
export const workCurrentIndexSelector = (state: { work: WorkState }) => {
  return state.work.currentIndex;
};

export const workEntitiesSelector = (state: { work: WorkState }) => {
  return state.work.entities;
};
*/

/*
 * Reducer
 */
export default workSlice.reducer;
