import React, { FC, useState } from 'react';
import { Editor } from '@tiptap/react';
import { FormatSize, CheckCircle, Check } from '@material-ui/icons';
import {
  Paper,
  createStyles,
  makeStyles,
  ClickAwayListener,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import TipTapToolbarButton from './TipTapToolbarButton';
import { FontSizesArray } from '@focus-front/ui';

interface TipTapToolbarFontSizeProps {
  editor: Editor;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sizesPaper: {
      position: 'absolute',
      backgroundColor: 'white',
      bottom: 0,
      zIndex: 9999,
      height: '380px',
      overflow: 'auto',
      width: '160px',
    },
    sizesList: {
      padding: 0,
    },
    sizesListItem: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.5),
    },
    sizesListItemIcon: {
      color: theme.palette.grey[500],
      minWidth: '28px',
      paddingBottom: '2px',
    },
  })
);

const TipTapToolbarFontSize: FC<TipTapToolbarFontSizeProps> = ({ editor }) => {
  const c = useStyles();

  const [showSizes, setShowSizes] = useState(false);

  const sizes = FontSizesArray;
  const currentSize = editor.getAttributes('textStyle').fontSize;

  const setSize = (size: number) => {
    editor.chain().focus().setFontSize(size).run();
    setShowSizes(false);
  };

  const handleChange = (size: number) => {
    setSize(size);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowSizes(false)}>
      <Box position="relative">
        <TipTapToolbarButton
          onClick={() => setShowSizes(true)} // On Click apri Paper
          className={editor.isActive('fontSize') ? 'is-active' : ''}
          icon={<FormatSize />}
        />
        <Paper variant="elevation" elevation={5} className={c.sizesPaper} style={{ display: showSizes ? 'inherit' : 'none' }}>
          <List className={c.sizesList}>
            {sizes.map((size) => (
              <ListItem className={c.sizesListItem} key={size} button onClick={() => handleChange(size)}>
                <ListItemIcon className={c.sizesListItemIcon}>
                  {currentSize === size + 'px' && <Check style={{ fontSize: '18px' }} />}
                </ListItemIcon>
                <ListItemText primary={size} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

export default TipTapToolbarFontSize;
