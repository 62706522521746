import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  AggregateRoot,
  CommandAccepted,
  CommandResult,
  createUuid,
  createUuidFrom,
  DomainEvent,
  toUTCDateTimeMs,
  UnixTimeMs,
  UTCDateTimeMs,
  Uuid,
} from '../../Common';
import { areEquivalentRRules, expandRRuleStrings } from '../../Common/domain/RRule';
import { Demand, Optional, Select, SingleOrArray, Strip } from '../../Common/utils';
import { createGoogleCalendarEventIdFrom } from '../infra/services/GoogleCalendarEventId';
import { CalendarEventAggStatus, initialState, projectionConfig } from './CalendarEvent.DecisionProjection';
import { UnipileCalendarEventInstanceId } from './CalendarEventInstance';
import {
  CalendarEventAcknowledged,
  CalendarEventCreated,
  CalendarEventDeleteAcknowledged,
  CalendarEventDeleted,
  CalendarEventDone,
  CalendarEventEdited,
  CalendarEventEtagAcknowledged,
  CalendarEventExceptionAcknowledged,
  CalendarEventExceptionAdded,
  CalendarEventExceptionCancelAcknowledged,
  CalendarEventExceptionCancelled,
  CalendarEventExceptionEdited,
  CalendarEventExceptionEtagAcknowledged,
  CalendarEventExceptionPatchAcknowledged,
  CalendarEventExceptionRestoreAcknowledged,
  CalendarEventInstanceDeleteAcknowledged,
  CalendarEventInstanceDeleted,
  CalendarEventInstanceDone,
  CalendarEventInstanceMissed,
  CalendarEventMissed,
  CalendarEventPatchAcknowledged,
  CalendarEventRescheduled,
  CalendarEventRestoreAcknowledged,
  CalendarEventSnoozed,
} from './events/CalendarEvent.events';
import { RecurringCalendarEventRoot } from './projections/RecurringCalendarEventRoot';
import {
  CalendarEventInstanceId,
  ExternalCalendarEventEtag,
  ExternalCalendarEventType,
  GoogleCalendarEvent,
  InstanceCalendarEvent,
  RecurringCalendarEvent,
  ViewCalendarEvent,
} from './projections/ViewCalendarEvent';
import {
  AcknowledgeCalendarEventDTO,
  CreateCalendarEventDTO,
  ExternalPatchCalendarEventDTO,
  NewCalendarEventDTO,
  PatchCalendarEventDTO,
  StatusOption,
} from './types/CalendarEventDTO';
import {
  CalendarEventExceptionDTO,
  ExternalCalendarEventExceptionDTO,
  ExternalPatchCalendarEventExceptionDTO,
} from './types/CalendarEventExceptionDTO';

dayjs.extend(utc);

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const CALENDAREVENT_NAMESPACE = '58065cbd-b0d9-4f35-9fd9-0842abca25c4' as Uuid;

/**
 * Aggregate.
 */
export class CalendarEvent extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   *
   */
  static getAggregateIdFromGoogleId(external_id: NonNullable<GoogleCalendarEvent['external_id']>, calendar_id: Uuid): Uuid {
    return createUuidFrom(external_id + calendar_id, CALENDAREVENT_NAMESPACE);
  }

  /**
   *
   */
  static getInstanceId(aggregateId: Uuid, original_start: UnixTimeMs): UnipileCalendarEventInstanceId {
    return `${aggregateId}_${dayjs(original_start).utc().format('YYYYMMDDTHHmmss[Z]')}` as UnipileCalendarEventInstanceId;
  }

  /**
   *
   */
  static getInstanceIds(
    { id, start, start_tzid, recurrence }: Pick<RecurringCalendarEvent, 'id' | 'start' | 'recurrence' | 'start_tzid'>,
    limitIterator?: (date: Date, i: number) => boolean
  ): UnipileCalendarEventInstanceId[] {
    const instanceDates = expandRRuleStrings(recurrence, start, limitIterator, start_tzid);
    return instanceDates.map((date) => {
      return this.getInstanceId(id, date.valueOf() as UnixTimeMs);
    });
  }

  /**
   * Expand instance dates for given id and rules. This does not deal with exceptions.
   *
   * @note As per Google calendar, mixed start/end Tzids are not allowed on recurring events.
   *       We only look at start_tzid to expand instances and use it for end_tzid as well.
   */
  static expandInstanceDates(
    {
      id,
      start,
      end,
      start_tzid,
      recurrence,
    }: Pick<RecurringCalendarEvent, 'id' | 'start' | 'end' | 'start_tzid' | 'recurrence'>,
    limitIterator?: (date: Date, i: number) => boolean
  ): Pick<InstanceCalendarEvent, 'id' | 'start' | 'end'>[] {
    const instanceDates = expandRRuleStrings(recurrence, start, limitIterator, start_tzid);
    const eventDuration = dayjs(end).diff(start, 'millisecond');

    return instanceDates.map((date) => {
      const instanceStart = dayjs(date);
      const instanceEnd = instanceStart.add(eventDuration, 'millisecond');
      return {
        id: this.getInstanceId(id, date.valueOf() as UnixTimeMs),
        start: toUTCDateTimeMs(instanceStart),
        end: toUTCDateTimeMs(instanceEnd),
        start_tzid,
        end_tzid: start_tzid,
      };
    });
  }

  /**
   * Get all instance dates, taking exceptions into account, for given RecurringCalendarEventRoot.
   *
   * @note Be very careful if you add a limitIterator. Do you expect to limit
   *       before or after applying exceptions ?
   */
  static getInstanceDates(
    {
      id,
      start,
      end,
      start_tzid,
      recurrence,
      exceptions,
    }: Pick<
      RecurringCalendarEventRoot & { kind: 'RECURRING' },
      'id' | 'start' | 'end' | 'start_tzid' | 'recurrence' | 'exceptions'
    >,
    limitIterator?: (date: Date, i: number) => boolean
  ): Pick<InstanceCalendarEvent, 'id' | 'start' | 'end' | 'original_start'>[] {
    const instanceDates = expandRRuleStrings(recurrence, start, limitIterator, start_tzid);
    const eventDuration = dayjs(end).diff(start, 'millisecond');
    const result: Pick<InstanceCalendarEvent, 'id' | 'start' | 'end' | 'original_start'>[] = [];

    for (const date of instanceDates) {
      const originalStartUnixTimeMs = date.valueOf() as UnixTimeMs;
      const instanceId = CalendarEvent.getInstanceId(id, originalStartUnixTimeMs);
      const exception = exceptions.get(instanceId);
      if (exception?.status === 'DELETED') {
        continue;
      }
      const originalStart = dayjs(date);
      const originalEnd = originalStart.add(eventDuration, 'millisecond');
      const instanceStart = exception?.start ?? toUTCDateTimeMs(originalStart);
      const instanceEnd = exception?.end ?? toUTCDateTimeMs(originalEnd);
      result.push({
        id: instanceId,
        start: instanceStart,
        end: instanceEnd,
        original_start: originalStartUnixTimeMs,
      });
    }
    return result;
  }

  /**
   *
   */
  static hasValidDates(event: Demand<ViewCalendarEvent, 'start' | 'end'>): boolean {
    return event.start <= event.end;
  }

  /**
   * Check if given changed properties warrant re-expanding instances considering
   * given previous state.
   *
   * @note This is different from updating known instances.
   *
   * @todo Check what Google does when tzid changes, especially when tzid changes but the resolved
   *       UTC datetime is equivalent.
   *
   * @todo Handle tzid.
   *       See https://github.com/pozorfluo/gcal-seq/blob/main/GoogleCalendarRecurringEvent/ReExpandOnSomeTzidChange.json
   */
  static mustExpand(
    state: Select<typeof initialState, 'kind' | 'start' | 'all_day' | 'start_tzid'>,
    // state: Select<typeof initialState, 'kind' | 'start' | 'all_day' | 'start_tzid' | 'recurrence'>,
    changes: Optional<PatchCalendarEventDTO, 'recurrence'> | ExternalPatchCalendarEventDTO
    // changes: PatchCalendarEventDTO | ExternalPatchCalendarEventDTO //unknown & { kind: 'SINGLE' | 'RECURRING' }
  ): boolean {
    if (state.kind !== changes.kind) {
      return true;
    }

    if (changes.kind !== 'RECURRING') {
      return false;
    }

    if (changes.start && changes.start !== state.start) {
      return true;
    }

    if (changes.all_day && changes.all_day !== state.all_day) {
      return true;
    }

    if (
      (changes.recurrence || 'start_tzid' in changes) &&
      state.recurrence &&
      !areEquivalentRRules(
        changes.recurrence ?? state.recurrence,
        state.recurrence,
        changes.start ?? state.start,
        changes.start_tzid ?? state.start_tzid,
        state.start_tzid
      )
    ) {
      return true;
    }

    // if (
    //   changes.kind === 'RECURRING' &&
    //   ((changes.start && changes.start !== state.start) ||
    //     (changes.all_day && changes.all_day !== state.all_day) ||
    //     (!!changes.recurrence && !!state.recurrence && !areEquivalentRRules(changes.recurrence, state.recurrence, changes.start)))
    // ) {
    //   return true;
    // }

    return false;
  }

  /**
   * @todo Consider ways to flatten StatusOption and break early once no exceptions in any ranges.
   *       Consider also that breaking early can be tricky since an event duration can be
   *       greater than the recurrence interval.
   */
  static expandStatusOptions(
    id: Uuid,
    calendarEvent: Strip<CreateCalendarEventDTO, 'statusOptions'> & { kind: 'RECURRING' },
    statusOptions: StatusOption[]
  ): Record<UnipileCalendarEventInstanceId, Pick<StatusOption, 'status'>> {
    // /** @note Checking kind to quiet compiler not being as strict as core. */
    // if (!calendarEvent.statusOptions || calendarEvent.kind !== 'RECURRING') {
    // if (calendarEvent.kind !== 'RECURRING') {
    //   return {};
    // }
    const instances = CalendarEvent.expandInstanceDates({
      id,
      ...calendarEvent,
    });
    // return calendarEvent.statusOptions.reduce((acc, option) => {
    return statusOptions.reduce((acc, option) => {
      /** Upper bound. */
      if (!('from' in option)) {
        for (const instance of instances) {
          if (instance.end <= option.to) {
            acc[instance.id] = { status: option.status };
            // acc[instance.id] = { status: option.status, etag: null };
          }
        }

        return acc;
      }

      /** Range. */
      if ('to' in option) {
        for (const instance of instances) {
          if (instance.start >= option.from && instance.end <= option.to) {
            acc[instance.id] = { status: option.status };
            // acc[instance.id] = { status: option.status, etag: null };
          }
        }
        return acc;
      }

      /** Lower bound. */
      for (const instance of instances) {
        if (instance.start >= option.from) {
          acc[instance.id] = { status: option.status };
          // acc[instance.id] = { status: option.status, etag: null };
        }
      }
      return acc;
    }, {} as Record<CalendarEventInstanceId, Pick<StatusOption, 'status'>>);
  }
  /**
   *
   */
  static expandStatusOptionsAsMap(
    id: Uuid,
    calendarEvent: Strip<CreateCalendarEventDTO, 'statusOptions'> & { kind: 'RECURRING' },
    statusOptions: StatusOption[]
  ): Map<CalendarEventInstanceId, Pick<StatusOption, 'status'>> {
    const instances = CalendarEvent.expandInstanceDates({
      id,
      ...calendarEvent,
    });
    return statusOptions.reduce((map, option) => {
      /** Upper bound. */
      if (!('from' in option)) {
        for (const instance of instances) {
          if (instance.end <= option.to) {
            map.set(instance.id, { status: option.status });
          }
        }

        return map;
      }

      /** Range. */
      if ('to' in option) {
        for (const instance of instances) {
          if (instance.start >= option.from && instance.end <= option.to) {
            map.set(instance.id, { status: option.status });
          }
        }
        return map;
      }

      /** Lower bound. */
      for (const instance of instances) {
        if (instance.start >= option.from) {
            map.set(instance.id, { status: option.status });
        }
      }
      return map;
    }, new Map<CalendarEventInstanceId, Pick<StatusOption, 'status'>>());
  }

  /**
   * Command.
   *
   * @note Stamping google calendar events with external_id derived from aggregateId
   *       so that we can tell 'oh ! we know that calendar event' when we sync
   *       with the external system.
   *
   *       Consider the other way around is probably not a good idea :
   *       Not stamping with an external_id and having to decode external_ids
   *       acked on sync to try to find known aggregateId. That's a lot of
   *       decoding every pull and we don't control the creation of all external_ids.
   *       There may be false positive when decoding external_ids we didn't generate
   *       or worse, some byte sequence that yield a query breaking string ?
   *       Not sure how likely the latter is but Google is just using a custom
   *       base32 alphabet for ids, not encoding 'nice strings'.
   */
  static create(
    content: NewCalendarEventDTO
  ): CommandResult<
    'INVALID DATES' | 'STATUS OPTION UNSUPPORTED ON SINGLE EVENT' | 'MULTIPLE TZID ON RECURRING EVENT',
    DomainEvent,
    CalendarEvent
  > {
    if (!CalendarEvent.hasValidDates(content)) {
      return CalendarEvent.reject('INVALID DATES');
    }

    if (content.kind === 'SINGLE' && content.statusOptions) {
      return CalendarEvent.reject('STATUS OPTION UNSUPPORTED ON SINGLE EVENT');
    }

    if (content.kind === 'RECURRING' && content.start_tzid !== content.end_tzid) {
      return CalendarEvent.reject('MULTIPLE TZID ON RECURRING EVENT');
    }

    switch (content.type) {
      case 'UNIPILE':
        return CalendarEvent.accept(
          new CalendarEventCreated(createUuid(), {
            ...content,
          })
        );
      case 'GOOGLE': {
        /**
         * We need to be able to derive aggregateId from the generated external_id and given calendar_id.
         *
         * A 'seed' Uuid is used to generate the external_id from which the actual aggregateId
         * is derived.
         *
         * @todo Profile if this ever causes performance issues.
         */
        const seed = createUuid();
        const external_id = createGoogleCalendarEventIdFrom(seed);
        return CalendarEvent.accept(
          new CalendarEventCreated(CalendarEvent.getAggregateIdFromGoogleId(external_id, content.calendar_id), {
            ...content,
            external_id,
          })
        );
      }
    }
  }

  /**
   * Command.
   */
  static acknowledge(content: AcknowledgeCalendarEventDTO): CommandAccepted<DomainEvent, CalendarEvent> {
    //   : CommandResult<undefined, DomainEvent, CalendarEvent>
    switch (content.type) {
      case 'GOOGLE': {
        const aggregateId = CalendarEvent.getAggregateIdFromGoogleId(content.external_id, content.calendar_id);
        return CalendarEvent.accept(new CalendarEventAcknowledged(aggregateId, content));
      }
    }
  }

  /**
   * Command.
   */
  delete(): CommandResult<'DELETED'> {
    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    return this.apply(new CalendarEventDeleted(this.id, this.version, this.projection.state.kind)).accept();
  }

  /**
   * Command.
   */
  acknowledgeDelete(etag: ExternalCalendarEventEtag): CommandResult<'NOT A NEW ETAG' | 'DELETED'> {
    if (this.projection.state.etag === etag) {
      return this.reject('NOT A NEW ETAG');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.apply(
        new CalendarEventEtagAcknowledged(this.id, this.version, {
          /**
           * @todo Rethink event's payload in order to avoid having to send kind and especially
           *       recurrence.
           */
          ...(this.projection.state.kind === 'RECURRING'
            ? { kind: 'RECURRING', recurrence: this.projection.state.recurrence }
            : { kind: 'SINGLE' }),
          etag,
        })
      ).reject('DELETED');
    }

    return this.apply(new CalendarEventDeleteAcknowledged(this.id, this.version, this.projection.state.kind, etag)).accept();
  }

  /**
   * Command.
   */
  acknowledgeRestore(
    values: AcknowledgeCalendarEventDTO
  ): CommandResult<'TYPE MISMATCH' | 'NOT A NEW ETAG' | Exclude<CalendarEventAggStatus, 'DELETED'>> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.etag === values.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    if (this.projection.state.current !== 'DELETED') {
      return this.reject(this.projection.state.current);
    }

    return this.apply(new CalendarEventRestoreAcknowledged(this.id, this.version, values)).accept();
  }

  /**
   * Command.
   */
  deleteInstance(
    instanceId: CalendarEventInstanceId
  ): CommandResult<'NOT A RECURRING EVENT' | 'DELETED' | 'INSTANCE ALREADY DELETED'> {
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    switch (this.projection.state.exceptions[instanceId]?.status) {
      case 'DELETED':
        return this.reject('INSTANCE ALREADY DELETED');
      case undefined:
      default:
        return this.apply(new CalendarEventInstanceDeleted(this.id, this.version, instanceId)).accept();
    }
  }
  /**
   * Command.
   */
  acknowledgeInstanceDelete(
    original_start: UnixTimeMs,
    instanceEtag: ExternalCalendarEventEtag,
    type: ExternalCalendarEventType
  ): CommandResult<
    'NOT A RECURRING EVENT' | 'TYPE MISMATCH' | 'DELETED' | 'NOT A NEW ETAG' | 'INSTANCE ALREADY DELETED - ETAG ACCEPTED'
  > {
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.type !== type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceId = CalendarEvent.getInstanceId(this.id, original_start);

    if (this.projection.state.exceptions[instanceId]?.etag === instanceEtag) {
      return this.reject('NOT A NEW ETAG');
    }

    switch (this.projection.state.exceptions[instanceId]?.status) {
      case 'DELETED':
        return this.apply(new CalendarEventExceptionEtagAcknowledged(this.id, this.version, instanceId, instanceEtag)).reject(
          'INSTANCE ALREADY DELETED - ETAG ACCEPTED'
        );
      case undefined:
      default:
        return this.apply(
          new CalendarEventInstanceDeleteAcknowledged(this.id, this.version, instanceId, instanceEtag, type)
        ).accept();
    }
  }

  /**
   * Command.
   */
  edit(
    values: Optional<PatchCalendarEventDTO, 'recurrence'>
  ): CommandResult<'TYPE MISMATCH' | 'INVALID DATES' | 'DELETED' | 'MULTIPLE TZID ON RECURRING EVENT'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    /**
     * @todo Enforce start/end format and invariants for allDay events at
     *       the AggregateRoot level !!!
     */
    if (
      (values.start || values.end) &&
      !CalendarEvent.hasValidDates({
        start: values.start ?? this.projection.state.start,
        end: values.end ?? this.projection.state.end,
      })
    ) {
      return this.reject('INVALID DATES');
    }

    if (values.kind === 'RECURRING' && values.start_tzid !== values.end_tzid) {
      return CalendarEvent.reject('MULTIPLE TZID ON RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    return this.apply(
      new CalendarEventEdited(
        this.id,
        this.version,
        values.kind === 'RECURRING' ? { ...values, recurrence: values.recurrence ?? this.projection.state.recurrence } : values,
        CalendarEvent.mustExpand(this.projection.state, values)
      )
    ).accept();
  }

  /**
   * Command.
   */
  acknowledgePatch(
    values: ExternalPatchCalendarEventDTO
  ): CommandResult<'TYPE MISMATCH' | 'DELETED' | 'NOT A NEW ETAG' | 'INVALID DATES - ETAG ACCEPTED AS PATCH'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.current === 'DELETED') {
      /**
       * @note Not acking the etag here allows to issue a restore command on 'DELETED' rejection.
       *
       * @todo Consider acking the etag in a CalendarEtagAcknowledged and not rejecting on same etag
       *       in restore command ? Wouldn't that introduce repeated acks problems ? Probably not if
       *       it also rejects if the effect of a restore is already applied, i.e. 'NOT DELETED'.
       */
      return this.reject('DELETED');
      //   switch (values.type) {
      //     case 'GOOGLE':
      //       if (values.google_status === 'confirmed') {
      //         return this.apply(
      //           new CalendarEventRestoreAcknowledged(this.id, this.version, { ...values, status: 'PLANNED' }, true)
      //         ).accept();
      //       }
      //       return this.reject('DELETED');
      //   }
    }

    /**
     * @todo Consider that you may want to accept anyway when this.projection.state.etag === null.
     *       values.etag should never be null but it's not encoded well in that DTO's type right now
     *       and it looks weird because it reads like it could reject in acceptable scenarios.
     *       See how it's done on exception with the stronger non-nullable requirement on etag.
     */
    if (this.projection.state.etag === values.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    if (
      (values.start || values.end) &&
      !CalendarEvent.hasValidDates({
        start: values.start ?? this.projection.state.start,
        end: values.end ?? this.projection.state.end,
      })
    ) {
      return this.apply(
        new CalendarEventEtagAcknowledged(this.id, this.version, {
          /**
           * Use the projection.state values, except the etag, since we're rejecting possibly
           * modified values from the patch.
           *
           * @todo Rethink event's payload in order to avoid having to send kind and especially
           *       recurrence.
           */
          ...(this.projection.state.kind === 'RECURRING'
            ? { kind: 'RECURRING', recurrence: this.projection.state.recurrence }
            : { kind: 'SINGLE' }),
          etag: values.etag,
        })
      ).reject('INVALID DATES - ETAG ACCEPTED AS PATCH');
    }

    return this.apply(
      new CalendarEventPatchAcknowledged(this.id, this.version, values, CalendarEvent.mustExpand(this.projection.state, values))
    ).accept();
  }

  /**
   * Command.
   */
  addException(
    instanceId: CalendarEventInstanceId,
    values: CalendarEventExceptionDTO
  ): CommandResult<'TYPE MISMATCH' | 'NOT A RECURRING EVENT' | 'INVALID DATES' | 'DELETED'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    /** Either both start and end are set and valid or neither are set. */
    if (
      !values.start !== !values.end ||
      (values.start &&
        values.end &&
        !CalendarEvent.hasValidDates({
          start: values.start,
          end: values.end,
        }))
    ) {
      return this.reject('INVALID DATES');
    }

    /**
     * @note For Google Calendar event, when no reminders value is provided,
     *       Exception should store the recurring root's current reminder value
     *       when they are created. Later when the recurring root's reminder value
     *       changes, exceptions should not be affected.
     */
    if (values.type === 'GOOGLE' && this.projection.state.reminders && !values.reminders) {
      //   values.reminders ??= this.projection.state.reminders;
      return this.apply(
        new CalendarEventExceptionAdded(this.id, this.version, instanceId, {
          ...values,
          reminders: this.projection.state.reminders,
        })
      ).accept();
    }

    return this.apply(new CalendarEventExceptionAdded(this.id, this.version, instanceId, values)).accept();
  }

  /**
   * Command.
   *
   * @note Original recurring event etag is not updated when an exception is
   *       added or modified.
   */
  acknowledgeException(
    exception: ExternalCalendarEventExceptionDTO
  ): CommandResult<'TYPE MISMATCH' | 'NOT A RECURRING EVENT' | 'DELETED' | 'NOT A NEW ETAG' | 'INSTANCE DELETED'> {
    if (this.projection.state.type !== exception.type) {
      return this.reject('TYPE MISMATCH');
    }
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceId = CalendarEvent.getInstanceId(this.id, exception.original_start);

    if (this.projection.state.exceptions[instanceId]?.etag === exception.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    switch (this.projection.state.exceptions[instanceId]?.status) {
      case 'DELETED':
        /**
         * @note Not acking the etag here allows to issue a restore command on 'DELETED' rejection.
         *
         * @todo Consider acking the etag in a CalendarExceptionEtagAcknowledged and not rejecting on same etag
         *       in restore command ? Wouldn't that introduce repeated acks problems ? Probably not if
         *       it also rejects if the effect of a restore is already applied, i.e. 'NOT DELETED'.
         */
        return this.reject('INSTANCE DELETED');
      case undefined:
      default:
        return this.apply(new CalendarEventExceptionAcknowledged(this.id, this.version, instanceId, exception)).accept();
    }
  }

  /**
   * Command.
   */
  acknowledgeExceptionRestore(
    exception: ExternalCalendarEventExceptionDTO
  ): CommandResult<'TYPE MISMATCH' | 'NOT A RECURRING EVENT' | 'DELETED' | 'NOT A NEW ETAG' | 'INSTANCE NOT DELETED'> {
    if (this.projection.state.type !== exception.type) {
      return this.reject('TYPE MISMATCH');
    }
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceId = CalendarEvent.getInstanceId(this.id, exception.original_start);

    if (this.projection.state.exceptions[instanceId]?.etag === exception.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    switch (this.projection.state.exceptions[instanceId]?.status) {
      case 'DELETED':
        return this.apply(new CalendarEventExceptionRestoreAcknowledged(this.id, this.version, instanceId, exception)).accept();
      case undefined:
      default:
        return this.reject('INSTANCE NOT DELETED');
    }
  }

  /**
   * Command.
   */
  editException(
    instanceId: CalendarEventInstanceId,
    values: CalendarEventExceptionDTO
  ): CommandResult<'TYPE MISMATCH' | 'NOT A RECURRING EVENT' | 'INVALID DATES' | 'DELETED'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    /** Either both start and end are set and valid or neither are set. */
    if (
      !values.start !== !values.end ||
      (values.start &&
        values.end &&
        !CalendarEvent.hasValidDates({
          start: values.start,
          end: values.end,
        }))
    ) {
      return this.reject('INVALID DATES');
    }

    return this.apply(new CalendarEventExceptionEdited(this.id, this.version, instanceId, values)).accept();
  }

  /**
   * Command.
   */
  acknowledgeExceptionPatch(
    values: ExternalPatchCalendarEventExceptionDTO
  ): CommandResult<'TYPE MISMATCH' | 'NOT A RECURRING EVENT' | 'DELETED' | 'NOT A NEW ETAG'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceId = CalendarEvent.getInstanceId(this.id, values.original_start);

    if (this.projection.state.exceptions[instanceId]?.etag === values.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    return this.apply(new CalendarEventExceptionPatchAcknowledged(this.id, this.version, instanceId, values)).accept();
  }

  /**
   * Command.
   *
   * @note Cancelling an exception means the regular instance needs to be
   *       expanded without modification. It is not the same thing as deleting
   *       an instance ! Deleting an instance IS an exception.
   */
  cancelException(
    instanceId: CalendarEventInstanceId
  ): CommandResult<'NOT A RECURRING EVENT' | 'DELETED' | 'UNKNOWN RECURRING EVENT EXCEPTION'> {
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    return !(instanceId in this.projection.state.exceptions)
      ? this.reject('UNKNOWN RECURRING EVENT EXCEPTION')
      : this.apply(new CalendarEventExceptionCancelled(this.id, this.version, instanceId)).accept();
  }

  /**
   * Command.
   *
   * @note Cancelling an exception means the regular instance needs to be
   *       expanded without modification. It is not the same thing as deleting
   *       an instance ! Deleting an instance IS an exception.
   */
  acknowledgeExceptionCancel(
    original_start: UnixTimeMs,
    exceptionEtag: ExternalCalendarEventEtag,
    type: ExternalCalendarEventType
  ): CommandResult<'TYPE MISMATCH' | 'NOT A RECURRING EVENT' | 'DELETED' | 'NOT A NEW ETAG' | 'UNKNOWN EXCEPTION'> {
    if (this.projection.state.type !== type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceId = CalendarEvent.getInstanceId(this.id, original_start);

    if (!this.projection.state.exceptions[instanceId]) {
      return this.reject('UNKNOWN EXCEPTION');
    }

    if (this.projection.state.exceptions[instanceId]?.etag === exceptionEtag) {
      return this.reject('NOT A NEW ETAG');
    }

    return this.apply(
      new CalendarEventExceptionCancelAcknowledged(
        this.id,
        this.version,
        CalendarEvent.getInstanceId(this.id, original_start),
        exceptionEtag
      )
    ).accept();
  }

  /**
   * Command.
   *
   * @todo Ask team what would it mean to snooze a recurring event ?
   *       Does it make sense ? Does it cancel all instances ?
   *       Should snooze be restricted to non-recurring events ?
   *
   * @todo Ask team if this even used at all anymore.
   */
  snooze(notificationId: Uuid): CommandResult<Exclude<CalendarEventAggStatus, 'PLANNED'>> {
    return this.projection.state.current === 'PLANNED'
      ? this.apply(new CalendarEventSnoozed(this.id, this.version, notificationId)).accept()
      : this.reject(this.projection.state.current);
  }

  /**
   * Command.
   */
  miss(notificationId: Uuid): CommandResult<'NOT A SINGLE EVENT' | Exclude<CalendarEventAggStatus, 'PLANNED'>> {
    if (this.projection.state.kind !== 'SINGLE') {
      return this.reject('NOT A SINGLE EVENT');
    }

    return this.projection.state.current === 'PLANNED'
      ? this.apply(new CalendarEventMissed(this.id, this.version, notificationId)).accept()
      : this.reject(this.projection.state.current);
  }

  /**
   * Command.
   *
   * @note missInstance means marking an instance, exception or not, of a
   *       recurring event as 'MISSED'.
   *       If the marked instance wasn't an exception, it is now an exception.
   */
  missInstance(
    instanceId: CalendarEventInstanceId,
    notificationId: Uuid
  ): CommandResult<'NOT A RECURRING EVENT' | 'DELETED' | `INSTANCE ${Exclude<CalendarEventAggStatus, 'PLANNED'>}`> {
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceStatus = this.projection.state.exceptions[instanceId]?.status;

    switch (instanceStatus) {
      case undefined:
      case 'PLANNED':
        return this.apply(new CalendarEventInstanceMissed(this.id, this.version, instanceId, notificationId)).accept();
      default:
        return this.reject(`INSTANCE ${instanceStatus}`);
    }
  }

  /**
   * Command.
   * @note Can't be done without which notificationId to associate with which
   *       instance found, or not, in given range.
   */
  // missInstanceRange(minDate : UTCDateTimeMs, maxDate: UTCDateTimeMs){
  // }

  /**
   * Command.
   *
   * @todo Add CalendarEventManyInstancesMissed event.
   */
  //   missInstances(
  //     instanceIdNotificationIdTuples: [CalendarEventInstanceId, Uuid][]
  //   ): CommandResult<'NOT A RECURRING EVENT' | 'DELETED' | `INSTANCE ${Exclude<CalendarEventAggStatus, 'PLANNED'>}`> {}

  /**
   * Command.
   *
   * @todo Add CalendarEventManyInstancesDone event.
   */
  //   completeInstances(
  //     instanceIdrealStartrealEndTuples: [CalendarEventInstanceId, UTCDateTimeMs, UTCDateTimeMs][]
  //   ): CommandResult<'NOT A RECURRING EVENT' | 'DELETED' | `INSTANCE ${Exclude<CalendarEventAggStatus, 'PLANNED'>}`> {}

  /**
   * Command.
   *
   * @note completeInstance means marking an instance, exception or not, of a
   *       recurring event as 'DONE'.
   *       If the marked instance wasn't an exception, it is now an exception.
   */
  completeInstance(
    instanceId: CalendarEventInstanceId,
    realStart: UTCDateTimeMs,
    realEnd: UTCDateTimeMs
  ): CommandResult<'NOT A RECURRING EVENT' | 'DELETED' | `INSTANCE ${Exclude<CalendarEventAggStatus, 'PLANNED' | 'MISSED'>}`> {
    if (this.projection.state.kind !== 'RECURRING') {
      return this.reject('NOT A RECURRING EVENT');
    }

    if (this.projection.state.current === 'DELETED') {
      return this.reject('DELETED');
    }

    const instanceStatus = this.projection.state.exceptions[instanceId]?.status;

    switch (instanceStatus) {
      case undefined:
      case 'PLANNED':
      case 'MISSED':
        return this.apply(new CalendarEventInstanceDone(this.id, this.version, instanceId, realStart, realEnd)).accept();
      default:
        return this.reject(`INSTANCE ${instanceStatus}`);
    }
  }

  /**
   * Command.
   */
  complete(
    realStart: UTCDateTimeMs,
    realEnd: UTCDateTimeMs
  ): CommandResult<'NOT A SINGLE EVENT' | Exclude<CalendarEventAggStatus, 'PLANNED' | 'MISSED'>> {
    if (this.projection.state.kind !== 'SINGLE') {
      return this.reject('NOT A SINGLE EVENT');
    }

    switch (this.projection.state.current) {
      case 'PLANNED':
      case 'MISSED':
        return this.apply(new CalendarEventDone(this.id, this.version, realStart, realEnd)).accept();
      default:
        return this.reject(this.projection.state.current);
    }
  }

  /**
   * Command.
   *
   * @todo Ask team what should happen when rescheduling a recurring event ?
   *       Does it make sense ? Does it reinstate all instances and maybe exceptions
   *       in some scenarios like google calendar ?
   *       Should reschedule be restricted to non-recurring events ?
   */
  reschedule(
    start: UTCDateTimeMs
    // schedule: { start_date: UTCDate; end_date: UTCDate; start_datetime: UTCDateTimeMs; end_datetime: UTCDateTimeMs }
  ): CommandResult<'NOT A SINGLE EVENT' | Exclude<CalendarEventAggStatus, 'SNOOZED' | 'MISSED'>> {
    switch (this.projection.state.current) {
      case 'SNOOZED':
      case 'MISSED':
        return this.apply(new CalendarEventRescheduled(this.id, this.version, start)).accept();
      default:
        return this.reject(this.projection.state.current);
    }
  }
}

/**
 * @note On external calendars, deleted events can be restored. But it's not as simple as
 *       as setting the event status to PLANNED since other changes to the event may have
 *       accrued and retrieved when syncing. For now we require the 'full' event. Later
 *       we may either require a patch after diffing before the command, or require the 'full'
 *       event and diff inside the command to save a patch in CalendarEventRestoreAcknowledged.
 *
 * @note In Google calendar, deleting an instance does not discard the associated
 *       exception if any. It just sets it to cancelled.
 *
 * @note When editing a recurring event and applying to all event, Google
 *       calendar will return cancelled exceptions on the next sync, e.g. :
 *
 *       // Deleted instance -> exception.
 *       {
 *         "kind": "calendar#event",
 *         "etag": "\"3255145973882000\"",
 *         "id": "2mbu5m27j9e0p2vp0jlig48jdv_20210708",
 *         "status": "cancelled",
 *         "recurringEventId": "2mbu5m27j9e0p2vp0jlig48jdv",
 *         "originalStartTime": {
 *           "date": "2021-07-08"
 *         }
 *       }
 *
 *       // Cancelled exception -> regular instance reinstated.
 *       {
 *        "kind": "calendar#event",
 *        "etag": "\"3255146171927000\"",
 *        "id": "2mbu5m27j9e0p2vp0jlig48jdv_20210708",
 *        "status": "cancelled"
 *       }
 *
 * @note When getting aggregated changes using a sync token on Google calendar API,
 *       you may get cancelled exceptions that you never heard about !
 *       See ExceptionBeforeDeleteOg sequence.
 *
 * @note Exception ids are determined by their original instance id.
 *       An exception turning a non all-day recurring event instance into an
 *       all-day exception will still have the original non all-day type id :
 *       GoogleCalendarEventInstanceId.
 *
 * @note When syncing with Google Calendar API and getting an data for an
 *       instance, it means it's an exception. If no attendees property
 *       is present, and some exist on the root, it means all attendees have
 *       been deleted on the exception.
 *
 * @note calendar_id mustn't be changed on an exception ! As per Google calendar
 *       it is only allowed on single/original recurring event. Here, the
 *       calendar_id key is not checked in the command but altogether stripped
 *       in the CalendarEventExceptionAdded to prevent stowaways.
 *
 *
 * @note Google Calendar API is weird about using default calendar reminders
 *       on exceptions. It seems to default to the type, allDay vs non-allDay,
 *       of the recurring root no matter what the exception type is.
 *
 *       If you set an exception to use the default calendar reminders,
 *       the API will ok it, return the exception as if use_default : true,
 *       but will default to the recurring root's value !!!
 *
 *       As long as as use_default : false is on an exception, even if
 *       overrides is an empty [], changing reminders on the root won't
 *       affect the exception.
 *
 * @note As per Google calendar, when editing a recurring event :
 *
 *         - keep exceptions, if changed, overwrite edited property on exceptions for
 *             google_status ( e.g. confirmed -> confirmed doesn't overwrite ! )
 *                           When cancelling a recurring event, Google will
 *                           notify that all exceptions are cancelled and
 *                           recreate them if the event is brought back.
 *             summary,
 *             description,
 *             location,
 *             attendees.response_status
 *
 *         - keep exceptions for
 *             reminders
 *
 *         - keep exceptions, append/remove from list for
 *             attendees ( @todo See how organiser is added by default. )
 *
 *         - keep relevant exceptions, discard other for
 *             recurrence
 *             ( an exception is relevant if it's instance id is still in the
 *               expanded instances list yielded by the new rrule ).
 *
 *         - discard exceptions if changed for << NO, exceptions are NOT DISCARDED, they
 *           are marked as cancelled.
 *             start,
 *             end,
 *             all_day
 *
 * @note Google calendar doesn't issue a new etag or list a change in the next
 *       list using sync token for patch operation that :
 *         - patch a value that is identical to the previous known value.
 *         - patch a value that is semantically equivalent to the previous
 *           known value for some fields, e.g. for recurrence :
 *
 *             "RRULE:FREQ=DAILY;COUNT=11;INTERVAL=1"
 *             "RRULE:FREQ=DAILY;COUNT=11;"
 *
 *             is not a change.
 *
 *           Strangely :
 *
 *             "recurrence": [
 *               "RRULE:FREQ=DAILY;COUNT=10;",
 *             ]
 *
 *             "recurrence": [
 *               "RRULE:FREQ=DAILY;COUNT=10",
 *               "RRULE:FREQ=DAILY;COUNT=9"
 *             ]
 *
 *            are considered different by Google calendar.
 *
 *           While :
 *
 *             "recurrence": [
 *               "RRULE:FREQ=DAILY;COUNT=10",
 *             ]
 *
 *             "recurrence": [
 *               "RRULE:FREQ=DAILY;COUNT=10;INTERVAL=1",
 *               "RRULE:FREQ=DAILY;COUNT=10"
 *             ]
 *
 *             are considered equivalent.
 *
 * @note Google calendar will return the whole event when listing changes,
 *       not just the fields that have changed !
 *       When acknowledging an update, it's definitely simpler to just take the
 *       whole thing.
 *
 *       You do not need the diff to solve what to do with a recurring event
 *       exceptions because the list will include the exceptions that have
 *       changed as a result of editing the recurring event.
 *
 *       Later, if this is ever a perf/size problem, maybe, consider
 *       doing some diff.
 *
 *       The exception override/discard behaviour only needs to be encoded on
 *       our end as part of the projection(s), including the aggregate root's
 *       decision projection. After pushing the edit event to Google calendar,
 *       we will acknowledge the update to the exceptions as solved by Google.
 *
 *       It looks like even if our understanding and implementation of the
 *       rules of recurring events update isn't 100% to spec, this will work
 *       as some sort of optimistic update.
 */

/**
 * @todo Consider where/when do you need to use the etag/changeKey ? How/where
 *       to retrieve it ? Which version ? Can we read it from a projection ?
 *       Should we do dedicated rollbackProjection ? Can this be done in the
 *       'update reducer' of CalendarSync ? We may need to require the etag in
 *       some DomainEvent for calendar type that are are going to use it.
 *
 *       Consider a dedicated projector that handle the 'update reducer' part
 *       by projecting a task list with all you need to push, including etag
 *       for the conditional requests. Sync events to 'move the cursor' and
 *       prune the task list ?
 *
 * @todo Think about how to model 'tracking instances' : Aren't they exceptions
 *       as soon as you need to add any deviation from what can be expanded
 *       from RRULE ? You can decide later if this need to translate into
 *       something to send to the external calendar API in the 'update reducer'
 *       of CalendarSync.
 *
 * @todo WAIT : Consider that CANCELLING an exception by removing its entry
 *              in state.exceptions would mean that it reinstates the 'regular'
 *              expanded instance !
 *
 *       Figure out how google calendar deals with the distinction between :
 *         - Removing the exceptional quality of an instance.
 *         - Cancelling an instance, thus giving it an exceptional google_status
 *           'cancelled'.
 *
 *       See CancelledExceptionVsDeletedInstance sequence.
 *
 *       // Deleted instance -> exception.
 *       {
 *         "kind": "calendar#event",
 *         "etag": "\"3255145973882000\"",
 *         "id": "2mbu5m27j9e0p2vp0jlig48jdv_20210708",
 *         "status": "cancelled",
 *         "recurringEventId": "2mbu5m27j9e0p2vp0jlig48jdv",
 *         "originalStartTime": {
 *           "date": "2021-07-08"
 *         }
 *       }
 *
 *       // Cancelled exception -> regular instance reinstated.
 *       {
 *        "kind": "calendar#event",
 *        "etag": "\"3255146171927000\"",
 *        "id": "2mbu5m27j9e0p2vp0jlig48jdv_20210708",
 *        "status": "cancelled"
 *       }
 *
 * @todo WARNING : Google calendar doesn't really ever discard exceptions ?!
 *       They seem to resurface event after a 'short form' cancelled, albeit
 *       completely overwritten with the value from the recurring root.
 *
 *       See SingleToRecurringToSingle, RecurrenceChangeDiscardExceptions sequences.
 *
 * @todo Consider that you most likely need to acknowledge an exception etag
 *       even if you reject an update ? That or accept without question ?
 *       But wouldn't acknowledging an etag exception as an exception update
 *       actually create an exception entry ??
 *
 * @todo Consider that it may not make sense to cancel each exception individually;
 *       editing and applying to all event just cancel all exceptions thus
 *       realigning instances with the original recurring event.
 *
 *       >> NO, editing some properties of the original recurring event
 *          recurrence rule will maintain its exceptions while other will
 *          discard them. Worse, some scenarios will update part of the exception
 *          while keeping the unedited differences.
 *
 *          See EditOgExceptionMaintained sequence.
 *
 *       >> NO, unfortunately the original recurring event may be edited via
 *          a split while keeping its exceptions !
 *
 *          See ExceptionBeforeSplit sequence.
 *
 *       A split will act like it 'cancels all exceptions' past the split point.
 *       In effect, the exceptions do not carry over the newly generated
 *       recurring event. The original recurring event does keep whatever
 *       exceptions are still relevant for its updated recurrence rule.
 *
 *       Changing the reccurence rule again on the original reccuring event so
 *       that some of the 'discarded' exceptions past the split point would be
 *       relevant again does not reinstate these exceptions. It seems they
 *       are gone for good. Phew.
 *
 * @todo Check if exception already exist ? Clobber ?
 *
 * @todo Consider to what extent invariants should be checked when acknowledging.
 *
 *       Start with instanceId ! How do we know that it's a valid instanceId
 *       for this recurring event ?
 *
 *         - Project all current possible instanceIds in DecisionProjection ?
 *         - Compute instanceId inside the command ?
 *         - Accept garbage Ids ?
 *
 * @todo Consider that you most likely need to acknowledge an etag even if
 *       you reject an update ?
 *       That or accept without question ?
 *
 * @todo Consider merging acknowledgeException/acknowledgeExceptionPatch ?
 *       If they are not merged, acknowledgeExceptionPatch should probably
 *       reject on unknown exception !
 *       Would 'not merging' require the user to know if an exception exists ?
 *
 * @todo Figure out how to find original event per retrieved/acked exception ?
 *       split external_id on '_' ? recurring_id ? This should always be available
 *       to rebuild the deterministic UUID of the original event.
 *       Explore if keeping exceptions attached to the recurring event is a
 *       good idea.
 *
 *       >> Careful when considering splitting external_id ! Use recurring_id
 *          if available. Google Calendar may create an id with an _R${Date}
 *          suffix when editing an instance and saving with 'this and the
 *          following event' for the new recurring event it is in all-day mode.
 *
 * @todo Consider merging addException/editException ? If they are not merged,
 *       editException should probably reject on unknown exception ! And add
 *       should reject on an existing exception.
 *       Would 'not merging' require the user to know if an exception exists ?
 *       The add/edit semantic is not clear here, especially since CALENDAREVENT_EXCEPTION_ADDED
 *       is currently doing a 'put' ( add or replace ) like operation in viewCalendarEventsProjector.
 *
 * @todo Consider checking for garbage Ids even when computing instanceId
 *       inside command !
 *
 * @todo Check that the exception refers to a valid instance according to
 *       rrule.
 *
 * @todo Consider that you most likely need to acknowledge an etag even if
 *       you reject a patch ?
 *       That or accept without question ?
 *       Otherwise you won't be able to push further updates with conditional
 *       requests and an obsolete etag !
 *       This also means you probably need some way to 'forcePush' and
 *       disregard the whatever etag the external calendar holds ?
 *       Alternatively instead of disregarding the etag, you can get and
 *       acknowledge the latest etag, diff ? compare update time ? Push
 *       with retrieved etag if necessary.
 *
 * @todo Consider that this is too strict and outdated and probably relies on the disused reschedule
 *       command.
 *       Either relax to accept any status but DELETED, or overhaul reschedule command.
 *
 *         if (this.projection.state.current !== 'PLANNED') {
 *           return this.reject(this.projection.state.current);
 *         }
 *
 * @todo Consider that long lived recurring events that accrue many exceptions
 *       may prove to be legit candidates for aggregate snapshots.
 *
 * @todo Decide where to handle exceptions on edit for non Google
 *       calendar event. In the edit event handler ? By re-expanding ?
 *
 *       >> Or by generating exception cancelled events in the edit command ?
 *
 *       It shouldn't matter for Google calendar because an edit on a recurring
 *       event will return the appropriate thing to do on next sync.
 *
 *       >> Is this necessary ?
 *
 * @todo Deal with excess properties/stowaways when a kind is not specifically
 *       mentionned ?
 *
 * @todo Find a way to avoid having to stamp every CalendarEventEdited with
 *       the recurrence value for recurring events.
 *
 * @todo Consider having a separate editRecurringRoot command ?
 *
 * @todo Consider AcknowledgeInstanceDelete vs using acknowledgeException for
 *       acknowledging external instance deletion.
 *
 * @todo Add support for splitting recurring event sequence :
 *      'this and all following events'.
 *
 * @todo Consider if we need to be able to delete event that are in any other
 *       state than 'PLANNED'.
 *
 * @todo Consider that you most likely need to acknowledge a new etag even if
 *       you reject a delete ?
 */

/**
 * @note Create a CalendarEvent aggregate with its own unique id by using the static method :
 *
 *         CalendarEvent.create(content)
 *
 *       Get an instance or resurrect an 'existing' CalendarEvent replaying all relevant events
 *       with the constructor :
 *
 *         new CalendarEvent(events)
 *
 *         --> you get an instance of the aggregate which can take further commands and make
 *             decision based on the projection built from all the events it replayed.
 *
 * @note A DecisionProjection does not look like a good fit for constructor
 *       dependency injection, this is private and part of the definition of the aggregate.
 *
 * @todo Consider what should happen / Throw when getting an event with an aggregateId that
 *       does not match the aggregate ?
 *       Consider that the aggregate may be reacting to other events and this is why
 *       the ref implementation was more specific about all the ids ( e.g. : messageId, authorId, etc ... ).
 *       -> No, if something not registered has something to say, its will be through something
 *          that triggers a command here.
 */
