import { GetMailCallbacks } from '../app/MailUseCase';
import { eventCreateDone, calendarsDefaultCalendarSelector } from '../../Calendar';
import { Taggable } from '../../Tag';
import { UnipileCalendarEventInstanceId } from '../../Calendar/domain/CalendarEventInstance';
import { Result, Uuid, LocalFile, showAutomaticHelp } from '../../Common';
import { ActionContext } from '../../Common/domain/Action';
import Container from '../../_container/interfaces/Container';
import { AppGetState } from '../../_container/mobile/MobileContainer';
import { AppDispatch } from '../../_container/store';

import {
  MailAttachment,
  MailDraftType,
  NewMailDraftDTO,
  UpdateMailDraftDTO,
  ViewMailDraft as MailDraft,
  ViewMailDraft,
  PartialViewMail,
  ViewMail,
} from '../domain';
import { startMailFullFetch } from '../../Account/state/accounts.slice';

export const getAllMails =
  (size: number, offset: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<(PartialViewMail & Taggable)[]> => {
    return mail.getAll(size, offset);
  };

export const getAllDraftMails =
  (size: number, offset: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<ViewMailDraft[]> => {
    return mail.getAllDrafts(size, offset);
  };

export const getMailView =
  (mail_id: Uuid, callbacks: GetMailCallbacks) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void> => {
    return mail.get(mail_id, callbacks);
  };

export const getMailDraftView =
  (mail_draft_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<ViewMailDraft | null> => {
    return mail.getDraft(mail_draft_id);
  };

export const getMailAttachmentsData =
  (view_mail: ViewMail) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<LocalFile[]> => {
    return mail.getAttachmentsData(view_mail);
  };

export const getMailAttachmentFile =
  (attachment_id: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { fileManager }: Container): Promise<LocalFile | null> => {
    return fileManager.get(attachment_id);
  };

export const saveMailDraftAttachmentData =
  (file: File) =>
  async (dispatch: AppDispatch, getState: AppGetState, { fileManager }: Container): Promise<MailAttachment> => {
    const attachment = await fileManager.upload(file);
    return attachment;
  };

export const getMailDraftAttachmentData =
  (attachment_id: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { fileManager }: Container): Promise<LocalFile | null> => {
    const attachment = await fileManager.get(attachment_id);
    return attachment;
  };

export const getMailChildDraft =
  (mail_id: Uuid, type: MailDraftType, update_attendees?: boolean) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<MailDraft | null> => {
    const { result, error } = await mail.getChildDraft(mail_id, type, update_attendees);
    if (error) {
      console.log(error);
      return null;
    }
    return result ?? null;
  };

export const writeNewMail =
  (values?: NewMailDraftDTO) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { mail }: Container
  ): Promise<Result<Uuid | UnipileCalendarEventInstanceId | void>> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());
    if (defaultCalendar) {
      const { error, result } = await mail.writeNewMail(defaultCalendar, values ?? {});
      if (result) {
        dispatch(eventCreateDone(result));
      }
      return { error: error || '', result: result?.id };
    } else throw new Error('todo ! Unhandled path in writeNewMail thunk : either calendar_id is null or result is undefined.');
  };

export const saveMailDraft =
  (id: Uuid, mail_draft: UpdateMailDraftDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void> => {
    const { error } = await mail.editDraft(id, mail_draft);
    if (error) alert(error);
  };

export const mailDone =
  (context: ActionContext) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void | string> => {
    const { error } = await mail.mailDone(context);
    if (error) return error;
  };

export const mailDraftDone =
  (context: ActionContext) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void | Error> => {
    return mail.mailDraftDone(context);
  };

export const mailReply =
  (context: ActionContext, mail_draft: ViewMailDraft) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void | string> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());
    if (defaultCalendar) {
      const { error, result } = await mail.mailReply(defaultCalendar, context, mail_draft);
      if (error) return error;
      if (result) dispatch(eventCreateDone(result));
    }
  };

export const mailTransfer =
  (context: ActionContext, mail_draft: ViewMailDraft) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void | string> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());
    if (defaultCalendar) {
      const { error, result } = await mail.mailTransfer(defaultCalendar, context, mail_draft);
      if (error) return error;
      if (result) dispatch(eventCreateDone(result));
    }
  };

export const mailDraftWrite =
  (context: ActionContext, mail_draft: ViewMailDraft) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<Result<void>> => {
    return mail.mailDraftWrite(context, mail_draft);
  };

export const initFullFetch =
  (account_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void> => {
    dispatch(startMailFullFetch(account_id));
    mail.fullFetch(account_id);
  };
