import { MaybePromise } from '../utils';
import { DomainEvent, DomainEventShape } from './DomainEvent';
import { Publisher } from './Publisher';

/**
 *
 */
export interface ReactorHandler<TRepos, TDomainEvent extends DomainEventShape> {
  (event: TDomainEvent, publisher: Publisher<TDomainEvent>, repos: TRepos): MaybePromise<unknown>;
}

/**
 * Collection of ReactorHandlers keyed by DomainEventType.
 */
export type ReactorConfig<TRepos, TDomainEvent extends DomainEventShape = DomainEvent> = {
  [TDomainEventType in TDomainEvent['type']]?: ReactorHandler<TRepos, TDomainEvent & { type: TDomainEventType }>;
};

/**
 * @todo Add a function to disconnect from given publisher, for all/some event types.
 */
export function createReactor<TRepos, TDomainEvent extends DomainEventShape = DomainEvent>(
  name: string,
  config: ReactorConfig<TRepos, TDomainEvent>
): Reactor<TRepos, TDomainEvent> {
  return {
    type: 'reactor',
    name,
    /**
     *
     */
    register(publisher: Publisher<TDomainEvent>, repos) {
      (Object.keys(config) as Array<keyof typeof config>).forEach((eventType) => {
        publisher.on(eventType as TDomainEvent['type'], async (event) => {
          await config[eventType]?.(event, publisher, repos);
        });
      });
    },
  };
}
/**
 * Reactors are domain event handlers meant to be called on original event
 * emission ONLY.
 *
 * They MAY perform side-effects.
 */
export interface Reactor<TRepos = Record<string, Record<string, unknown>>, TDomainEvent extends DomainEventShape = DomainEvent> {
  type: 'reactor';
  name: string;
  register: (publisher: Publisher<TDomainEvent>, repos: TRepos) => void;
}
