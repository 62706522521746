import { inboxTypeFilterSelector, NotificationType, toggleTypeFilter, useAppDispatch } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { caretDownSharp } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavButton } from '../../Wizard/NavButton';
import { FilterCheckbox } from './FilterCheckbox';
import FilterPopper from './FilterPopper';

export default function FilterByType() {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const types = useSelector(inboxTypeFilterSelector);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePick = (type: NotificationType) => {
    dispatch(toggleTypeFilter(type));
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <NavButton onClick={handleClick} endIcon={<IonIcon icon={caretDownSharp} style={{ fontSize: 12 }} />}>
        {t('inbox.filterByType.btn')}
      </NavButton>
      <FilterPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        title={t('inbox.filterByType.title')}
        onClose={() => setAnchorEl(null)}
      >
        <Box>
          <List>
            <ListItem dense button onClick={() => handlePick('MAIL_NEW')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'MAIL_NEW')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.mail_new')} />
            </ListItem>
            <ListItem dense button onClick={() => handlePick('MESSAGE_NEW')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'MESSAGE_NEW')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.message_new')} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem dense button onClick={() => handlePick('CALL_INCOMING')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'CALL_INCOMING')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.call_incoming')} />
            </ListItem>
            <ListItem dense button onClick={() => handlePick('CALL_MISSED')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'CALL_MISSED')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.call_missed')} />
            </ListItem>
            <ListItem dense button onClick={() => handlePick('CALL_OUTGOING')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'CALL_OUTGOING')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.call_outgoing')} />
            </ListItem>
            <ListItem dense button onClick={() => handlePick('CALL_NO_ANSWER')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'CALL_NO_ANSWER')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.call_no_answer')} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem dense button onClick={() => handlePick('EVENT_MISSED')}>
              <ListItemIcon>
                <FilterCheckbox checked={!!types.find((t) => t === 'EVENT_MISSED')} />
              </ListItemIcon>
              <ListItemText primary={t('notification.type.event_missed')} />
            </ListItem>
          </List>
        </Box>
      </FilterPopper>
    </div>
  );
}
