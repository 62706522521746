import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { reconnectAccountSuccess, reconnectInstagramAccount, Uuid, useAppDispatch } from '@focus-front/core';
import { Button, Dialog, DialogContent, InputAdornment, LinearProgress, TextField } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import DialogTitle from '../Common/DialogTitle';

export type ReconnectForm = {
  password?: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  account_id: Uuid;
}

export default function ReconnectInstagram({ isOpen, onClose, account_id }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSubmit = async (values: ReconnectForm) => {
    setError(null);
    setLoading(true);

    const error = await dispatch(reconnectInstagramAccount(values.password, account_id));
    if (error) {
      setError(error);
      setLoading(false);
    } else {
      setError(null);
      setLoading(false);
      onClose();
      dispatch(reconnectAccountSuccess(account_id));
    }
  };
  const { register, formState, handleSubmit } = useForm<ReconnectForm>({
    mode: 'onChange',
    defaultValues: {
      password: '',
    },
  });

  return (
    <Dialog open={isOpen}>
      <DialogTitle onClose={onClose}>{t('accountSettings.reconnect.title')}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          label={t('accountSettings.reconnect.password')}
          name="password"
          type="password"
          inputRef={register({ required: t<string>('accountSettings.reconnect.required') })}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock color="disabled" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          style={{ marginBottom: 8 }}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={!formState.isValid || loading}
        >
          {t('accountSettings.reconnect.button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
