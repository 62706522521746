import React, { useEffect, useState } from 'react';

import { isEmail, searchAttendees, UnipileEventAttendee, useAppDispatch } from '@focus-front/core';
import { Avatar, Chip, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { MailFormAttendee } from './MailForm';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  value: MailFormAttendee[];
  onChange: (values: MailFormAttendee[]) => void;
  isEventForm?: boolean;
}

const useClasses = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& ion-icon': {
      marginRight: theme.spacing(1),
      fontSize: 20,
    },
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
}));

type AttendeeField = UnipileEventAttendee | MailFormAttendee;

const AttendeesField: React.FC<Props> = ({ value, onChange, isEventForm = false }) => {
  const { t } = useTranslation();
  const c = useClasses();
  const [input, setInput] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AttendeeField[]>([]);
  const dispatch = useAppDispatch();

  const getDisplayedString = (option: AttendeeField) => {
    return !!option.display_name && option.display_name !== option.email
      ? `${option.display_name} - ${option.email}`
      : option.email;
  };

  useEffect(() => {
    let active = true;

    if (input === '') {
      setOptions([]);
      return undefined;
    }

    (async () => {
      const attendees = await dispatch(searchAttendees(input));

      if (active) {
        setOptions([
          ...attendees.slice(0, 5).map((att) => ({
            display_name: att.item.display_name,
            email: att.item.identifier,
            email_label: null,
          })),
        ]);
      }
    })();

    return () => {
      active = false;
    };
  }, [input]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * Convert manually typed emails to Attendee and remove non valid email then callback onChange
   * @param values Values of the input (selected from autocomplete - Attendee or manually typed - string)
   */
  const handleChanges = (event, values: (string | MailFormAttendee)[]) => {
    onChange(
      values
        .map((value) => (typeof value === 'string' ? { display_name: value, email: value } : value))
        .filter((val) => isEmail(val.email))
    );
  };

  const handleBlur = (event) => {
    const changes: (string | MailFormAttendee)[] = [...value];
    if (input !== '') changes.push(input);
    handleChanges(event, changes);
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleChanges}
      // Save what the user is writing in a tmp state to append to the actual value onBlur
      onInputChange={(e, v) => {
        setInput(v);
      }}
      // Trigger onChange on blur with initial values to clean the input of non valid emails
      onBlur={handleBlur}
      multiple
      disableClearable
      handleHomeEndKeys
      openOnFocus={false}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoComplete
      autoHighlight
      options={options}
      filterOptions={(x) => x}
      getOptionLabel={(option: AttendeeField) => getDisplayedString(option)}
      getOptionSelected={(option: AttendeeField, value: AttendeeField) => option.email === value.email}
      freeSolo
      fullWidth
      renderTags={(values: MailFormAttendee[] | UnipileEventAttendee[], getTagProps) =>
        values.map((value, index) => (
          <Chip
            key={index + value.display_name}
            avatar={<Avatar src={value.avatar_url}>{value?.display_name?.charAt[0]}</Avatar>}
            size="small"
            color="primary"
            label={value.display_name || value.email}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) =>
        isEventForm ? (
          <TextField
            {...params}
            fullWidth
            placeholder={t('calendar.form.attendeesPlaceholder')}
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              className: cn(c.field, params.InputProps.className),
              startAdornment: (
                <>
                  {/*<IonIcon icon={peopleOutline} />*/}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        ) : (
          <TextField {...params} variant="standard" fullWidth />
        )
      }
      renderOption={(option: AttendeeField) => {
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <AttendeeAvatar size="tiny" display_name={getDisplayedString(option)} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                {getDisplayedString(option)}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(AttendeesField);
