import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Attendee, displayAttendeeName } from '@focus-front/core';
import AttendeeAvatarPopover from './AttendeeAvatarPopover';
import { AvatarGroup } from '@material-ui/lab';
import AttendeeAvatar from './AttendeeAvatar';
import AtendeeIdentifier from './AtendeeIdentifier';

const useClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  containerRight: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  attendees: Attendee[];
  titleOverride?: string;
};

export default function AttendeeTitle({ attendees, titleOverride }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  if (!attendees.length) return null;
  return (
    <div className={c.container}>
      {attendees.length > 1 ? (
        <AvatarGroup>
          {attendees.map((attendee, index) => (
            <AttendeeAvatar key={index} src={attendee.profile_picture} display_name={displayAttendeeName(attendee)} />
          ))}
        </AvatarGroup>
      ) : (
        <AttendeeAvatarPopover attendee={attendees[0]} />
      )}

      <div className={c.containerRight}>
        <Typography variant="h4">
          {titleOverride ||
            (attendees.length > 1
              ? displayAttendeeName(attendees[0]) + t('thread.groupTitleDefault', { number: attendees.length })
              : displayAttendeeName(attendees[0]))}
        </Typography>
        {attendees.length > 1 && (
          <Typography variant="body2">{attendees.map((attendee) => displayAttendeeName(attendee)).join(' • ')}</Typography>
        )}
        {attendees.length === 1 && <AtendeeIdentifier attendee={attendees[0]} />}
      </div>
    </div>
  );
}
