import { inboxTagFilterSelector, tagsSelector, toggleTagFilter, useAppDispatch, ViewTag } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab';
import { caretDownSharp } from 'ionicons/icons';
import React, { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tag from '../../Tag/Tag';
import { NavButton } from '../../Wizard/NavButton';
import { FilterCheckbox } from './FilterCheckbox';
import FilterPopper from './FilterPopper';

const useClasses = makeStyles((theme) => ({
  itemIcon: { minWidth: 40 },
  container: { overflowY: 'scroll', flex: 1 },
  searchField: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
}));

export default function FilterByTag() {
  const c = useClasses();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [input, setInput] = useState('');
  const allTags = useSelector(tagsSelector);
  const tags = useSelector(inboxTagFilterSelector);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePick = (tag: ViewTag) => {
    dispatch(toggleTagFilter(tag.id));
  };

  const onSearch = (e) => {
    setInput(e.target.value);
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <NavButton onClick={handleClick} endIcon={<IonIcon icon={caretDownSharp} style={{ fontSize: 12 }} />}>
        {t('inbox.filterByTag.btn')}
      </NavButton>
      <FilterPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        title={t('inbox.filterByTag.title')}
        onClose={() => setAnchorEl(null)}
      >
        <Box p={1}>
          <TextField
            onChange={onSearch}
            autoFocus
            placeholder={t('inbox.filterByTag.search')}
            fullWidth
            className={c.searchField}
            size="small"
          />
        </Box>
        <Divider />
        <div className={c.container}>
          <List>
            {allTags
              .slice() // Clone the array because sort must be done on non-readonly arrays
              .sort((a, b) => a.label.localeCompare(b.label))
              .filter((c) => c.label.toLowerCase().indexOf(input.toLowerCase()) === 0)
              .map((tag) => (
                <ListItem dense button key={tag.id} onClick={() => handlePick(tag)}>
                  <ListItemIcon classes={{ root: c.itemIcon }}>
                    <FilterCheckbox checked={!!tags.find((t) => t === tag.id)} />
                  </ListItemIcon>
                  <ListItemText primary={<Tag tag={tag} />} />
                </ListItem>
              ))}
          </List>
        </div>
      </FilterPopper>
    </div>
  );
}
