import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, withStyles, LinearProgress, CircularProgress, Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  getAccount,
  Uuid,
  ViewAccount,
  MailSupportedAccounts,
  isMailSupportedAccount,
  initFullFetch,
  accountSelector,
} from '@focus-front/core';
import { useInterval } from 'react-use';
import { PlayArrowOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    animation: 'MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}))(LinearProgress);

const useClasses = makeStyles((theme) => ({}));

type Props = {
  account_id: Uuid;
};

export function FullFetchProgress({ account_id }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const store_account = useSelector(accountSelector(account_id));
  const [account, setAccount] = useState<(ViewAccount & { type: MailSupportedAccounts }) | null>(null);

  useInterval(() => {
    dispatch(getAccount(account_id)).then((acc) => {
      if (isMailSupportedAccount(acc)) setAccount(acc);
    });
  }, 5000);

  useEffect(() => {
    dispatch(getAccount(account_id)).then((acc) => {
      if (isMailSupportedAccount(acc)) setAccount(acc);
    });
  }, []);

  const { percent, total, fetched } = useMemo(() => {
    if (!account || account.full_fetch_status === 'IDLE') return { percent: 0, total: 0, fetched: 0 };

    if (account.full_fetch_status === 'DONE') return { percent: 100, total: 0, fetched: 0 };

    const total = account.full_fetch_progress.map((a) => a.total).reduce((a, b) => a + b);
    let fetched = 0;
    account.full_fetch_progress.forEach((mb) => (fetched += Math.min(mb.offset, mb.total)));

    return { percent: (fetched / total) * 100, total, fetched };
  }, [account]);

  const onClickPlay = () => {
    dispatch(initFullFetch(account_id));
  };

  if (!account || account.full_fetch_status === 'DONE') return null;

  return (
    <div style={{ marginTop: 12 }}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={1}>
        {store_account.mail_ff_start ? (
          <Typography variant="body2">
            <CircularProgress style={{ width: 14, height: 14 }} color="secondary" />
            {t('accountSettings.sources.fullFetch.fetchInProgress')}
          </Typography>
        ) : (
          <Button size="small" onClick={onClickPlay} startIcon={<PlayArrowOutlined />}>
            {t('accountSettings.sources.fullFetch.startButton')}
          </Button>
        )}
        <Typography variant="body2">
          {fetched} / {total}
        </Typography>
      </Box>

      <BorderLinearProgress variant="determinate" value={percent} />
    </div>
  );
}
