import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

/**
 * @note Google calendar#event.id as per Google Calendar API :
 *
 * - characters allowed in the ID are those used in base32hex encoding,
 *   i.e. lowercase letters a-v and digits 0-9, see section 3.1.2 in RFC2938.
 *
 * - the length of the ID must be between 5 and 1024 characters.
 *
 * - the ID must be unique per calendar.
 *
 * @see https://developers.google.com/calendar/api/v3/reference/events
 *
 * @note Although Google Calendar API documentation mentions base32hex and RFC2938,
 *       it actually requires a custom alphabet ( lowercase a-v instead of uppercase )
 *       and rejects padding characters.
 */
export const GOOGLE_CALENDAR_EVENT_ID_REGEX = /^[a-v0-9]{5,1024}$/;
const GOOGLE_BASE32 = '0123456789abcdefghijklmnopqrstuv';

/**
 * @note Opaque type.
 */
declare const validGoogleCalendarEventId: unique symbol;
export type GoogleCalendarEventId = string & { [validGoogleCalendarEventId]: true };

/**
 * @note This is for user issued GoogleCalendarEventId. Google Calendar will
 *       issue id for events that contains characters not belonging to the alphabet
 *       they request you use when specifying your own id on event creation.
 */
export function isGoogleCalendarEventId(s: string): s is GoogleCalendarEventId {
  //   const length = s.length;
  //   return length >= 5 && length <= 1024 && GOOGLE_CALENDAR_EVENT_ID_REGEX.test(s);
  return GOOGLE_CALENDAR_EVENT_ID_REGEX.test(s);
}

/**
 * Namespace UUID.
 * Used for deterministic UUID generation.
 */
const GOOGLE_CALENDAR_EVENT_NAMESPACE = 'fa0fb588-8d6e-43c5-955f-d871de71cf07'; // as Uuid;

// const encoder = new TextEncoder();
const UUID_BYTE_SIZE = 16;

/**
 * Generate a random calendar#event.id.
 */
export function createGoogleCalendarEventId(): GoogleCalendarEventId {
  const buffer = uuidv4(undefined, new Uint8Array(UUID_BYTE_SIZE));

  return encodeToGoogleBase32(buffer);
}

/**
 * Generate a deterministic calendar#event.id from given name.
 */
export function createGoogleCalendarEventIdFrom(name: string): GoogleCalendarEventId {
  const buffer = uuidv5(name, GOOGLE_CALENDAR_EVENT_NAMESPACE, new Uint8Array(UUID_BYTE_SIZE));

  return encodeToGoogleBase32(buffer);
}

/**
 * @note Adapted from https://github.com/scttcper/ts-base32
 */
export function encodeToGoogleBase32(buffer: Uint8Array): GoogleCalendarEventId {
  const length = buffer.byteLength;
  let bits = 0;
  let value = 0;
  let output = '';

  for (let i = 0; i < length; ++i) {
    value = (value << 8) | buffer[i];
    bits += 8;

    while (bits >= 5) {
      output += GOOGLE_BASE32[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }

  if (bits > 0) {
    output += GOOGLE_BASE32[(value << (5 - bits)) & 31];
  }

  return output as GoogleCalendarEventId;
}
