import { Taggable, ViewTagRelationRepo, ViewTagRepo, ViewTagRelation } from '../../../Tag';
import { Uuid } from '../../../Common';
import { Idb } from '../../../Common/infra/services/idb';
import { CallType, createViewCall, UpdateCallDTO, ViewCall } from '../../domain/projections/ViewCall';
import { ViewCallRepo } from '../../domain/projections/ViewCallRepo';
import { notEmpty } from '../../../Common/utils';

/**
 * Repository.
 */
export class IdbViewCallRepo implements ViewCallRepo {
  constructor(private readonly viewTagRelation: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewCall) {
    return Idb.put('viewCalls', projection);
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateCallDTO) {
    const tx = Idb.transaction('viewCalls', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewCall ${id} does not exist.`);
    }

    /** @note await should be unnecessary here. */
    tx.store.put(createViewCall(id, { ...old_projection, ...values }));
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid) {
    return Idb.delete('viewCalls', id);
  }

  async removeByAccount(account_id: Uuid) {
    let cursor = await Idb.transaction('viewCalls', 'readwrite')
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  /**
   *
   */
  async clear() {
    return Idb.clear('viewCalls');
  }

  /**
   * Query.
   */
  async get(id: Uuid): Promise<(ViewCall & Taggable) | null> {
    const call = await Idb.get('viewCalls', id);
    if (!call) return null;
    const tags = await this.viewTagRelation.getTagsByElement('CALL', id);
    return { ...call, tags };
  }

  private async _getTags(results: ViewCall[]): Promise<(ViewCall & Taggable)[]> {
    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    for (let i = 0, length = results.length; i < length; ++i) {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['CALL', results[i].id]));
    }

    const allTags = await this.viewTagRepo.getAll();

    const callsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...results[i], tags };
      })
    );

    return callsWithTags;
  }

  /**
   * Query.
   */
  async getAll(): Promise<(ViewCall & Taggable)[]> {
    const calls = await Idb.getAll('viewCalls');
    return this._getTags(calls);
  }

  /**
   * Query.
   */
  async getAllByDate(size: number, offset: number): Promise<(ViewCall & Taggable)[]> {
    let cursor = await Idb.transaction('viewCalls', 'readonly').store.index('by-date').openCursor(null, 'prev');
    const results: ViewCall[] = [];
    let hasSkipped = false;

    while (results.length < size && cursor) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        cursor = (await cursor?.advance(offset)) ?? null;
      }
      if (!cursor) break;
      results.push(cursor.value);
      cursor = await cursor.continue();
    }

    return this._getTags(results);
  }

  /**
   * Query.
   */
  async getByAccount(account_id: Uuid) {
    const calls = await Idb.getAllFromIndex('viewCalls', 'by-account', IDBKeyRange.only(account_id));
    return calls;
  }

  /**
   * Query.
   */
  async getLatestByAccountAndType(account_id: Uuid, type: CallType) {
    const cursor = await Idb.transaction('viewCalls', 'readonly')
      .store.index('by-account-and-date-and-type')
      /**
       * @todo Look at IdbViewCalendarEventRepo.getByCalendarAndDate and ask
       *       Thomas why this is likely to fail because of compound indexes.
       */
      .openCursor(IDBKeyRange.bound([account_id, '0', type], [account_id, 'Z', type]), 'prev');
    //                                           ^^^                      ^^^
    //                                            |                        |
    // safe values that you know are lexicographically before/after any possible dates.

    const call = cursor?.value;

    return call || null;
  }
}
