import { MaybePromise, SingleOrArray } from '../utils';

import { DomainEvent, DomainEventShape } from './DomainEvent';
import { DomainEventStore } from './DomainEventStore';

export interface Subscriber<TEvent extends DomainEventShape> {
  (event: TEvent): MaybePromise<void>;
}
// export interface ErrorSubscriber {
//   (error: Error): MaybePromise<void>;
// }

/**
 * @note Publisher only cares about the very generic EventShape but, as a DX convenience,
 *       will default to the much more specific DomainEvent if no generic parameter
 *       is given.
 *
 * @note By default EventEmitter will print a warning if more than 10 listeners are added to it.
 *       See https://github.com/browserify/events/blob/main/events.js
 *
 * @todo Consider moving Publisher to infra.
 *
 * @todo Research ways to prevent Subscribers from being able to modify the
 *       Subscribers list, and if its worth doing.
 */
export class Publisher<TDomainEvent extends DomainEventShape = DomainEvent> {
  public debugSubCount = 0;
  private onAnySubs: Subscriber<TDomainEvent>[] = [];
  private afterAnySubs: Subscriber<TDomainEvent>[] = [];
  private subs: {
    [TdomainEventType in TDomainEvent['type']]?: Subscriber<TDomainEvent & { type: TdomainEventType }>[];
  } = {};

  /**
   *
   */
  constructor(private store?: DomainEventStore<TDomainEvent>) {
    this.emit = this.emit.bind(this);
  }

  /**
   *
   */
  setStore(store?: DomainEventStore<TDomainEvent>): void {
    this.store = store;
  }

  /**
   *
   */
  on<TdomainEventType extends TDomainEvent['type']>(
    eventType: TdomainEventType,
    subscriber: Subscriber<TDomainEvent & { type: TdomainEventType }>
  ): void {
    /**
     * @todo Figure out why TS thinks this might be undefined and if it's right.
     *       It does seem to work in TS playground though. It starts getting confused
     *       when eventType is a parameter.
     *       See https://gist.github.com/pozorfluo/aa94a3cbd0b6f14231ed312fbb2a2243
     */
    // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (this.subs[eventType] || (this.subs[eventType] = []))?.push(subscriber);
    this.debugSubCount++;
    // return this;
  }

  /**
   *
   */
  onAny(subscriber: Subscriber<TDomainEvent>): void {
    this.onAnySubs.push(subscriber);
    this.debugSubCount++;
    // return this;
  }

  /**
   *
   */
  afterAny(subscriber: Subscriber<TDomainEvent>): void {
    this.afterAnySubs.push(subscriber);
    this.debugSubCount++;
    // return this;
  }

  /**
   *
   */
  clearSubscribers(): void {
    this.onAnySubs = [];
    this.afterAnySubs = [];
    this.subs = {};
    this.debugSubCount = 0;
  }
  //   /**
  //    *
  //    */
  //   onError(subscriber: ErrorSubscriber) {
  //     this.emitter.on('error', subscriber);
  //     return this;
  //   }

  /**
   * @todo Consider sorting ( causal order + best effort ) events here !
   * 
   * @todo Consider that subscriber can be added during an emit.
   *       Figure out how bad it can be and if it needs some kind of lock.
   *       It's probably "ok" with the way we currently use Publisher, that is
   *       with a single place doing the registering, i.e. the Projectionist.
   *       But still, in its current form it allows to do things that will most
   *       likely lead to unexpected/bad results.
   * 
   * @todo Consider branching before the loop to obviate possibly useless
   *       onAnySubs, ( subs ? ), afterAnySubs steps ?
   */
  async emit(events: SingleOrArray<TDomainEvent>): Promise<void> {
    if (!Array.isArray(events)) {
      /** Wrap single event in an array. */
      events = [events];
    }

    const length = events.length;

    if (length) {
      //   await this.store?.addMany(events);
      if (this.store) {
        await this.store.addMany(events);
      }

      for (let i = 0; i < length; ++i) {
        const event = events[i];
        await Promise.all(this.onAnySubs.map((subscriber) => subscriber(event)) ?? []);

        await Promise.all(this.subs?.[event.type as TDomainEvent['type']]?.map((subscriber) => subscriber(event)) ?? []);

        await Promise.all(this.afterAnySubs.map((subscriber) => subscriber(event)) ?? []);
      }
    }
  }
}
// await Promise.all(
//     this.subs?.[events[i].type as TDomainEvent['type']]?.map(async (subscriber) => {
//       const t = await subscriber(event);
//       console.log(event, t, typeof t);
//       return t;
//     }) ?? []
//   );
