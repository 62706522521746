import {
  CalendarEvent,
  NewCalendarEventDTO,
  CalendarEventRepo,
  ViewCalendarEvent,
  PatchCalendarEventDTO,
  UnipileCalendarEventInstanceId,
} from '../../Calendar';
import { ViewCalendarEventRepo } from '../../Calendar/domain/projections/ViewCalendarEventRepo';
import { ViewIncomingCall, ViewMissedCall, ViewNoAnswerCall, ViewOutgoingCall } from '../../Call';
import { InvalidAccountError, LoggerService, Publisher, Result, toUTCDateTimeMs, UnexpectedError, Uuid } from '../../Common';
import { ViewIm, ViewImThreadRepo } from '../../Im';
import { PartialViewMail } from '../../Mail';
import { Notification, ViewNotification, ViewNotificationRepo } from '../domain';
import { NotificationRepo } from '../infra/repository/NotificationRepo';
import { NotificationUseCase } from './NotificationUseCase';
import i18n from '../../Common/infra/services/i18nextService';
import { ViewAccountRepo, ImAccountIdentifierType, isImSupportedAccount } from '../../Account';
import { displayAttendeeName, ViewContactRepo, Attendee } from '../../Contact';
import { Taggable, ViewTagRelationRepo, ViewTag } from '../../Tag';

export class AppNotificationUseCase implements NotificationUseCase {
  constructor(
    private readonly publisher: Publisher,
    private readonly notification: NotificationRepo,
    private readonly calendarEvent: CalendarEventRepo,
    private readonly viewNotification: ViewNotificationRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly viewAccount: ViewAccountRepo,
    private readonly viewContact: ViewContactRepo,
    private readonly viewImThread: ViewImThreadRepo,
    private readonly viewTagRelation: ViewTagRelationRepo,
    private readonly logger: LoggerService
  ) {}

  private async _getNotificationTags(notification: ViewNotification): Promise<ViewTag[]> {
    const element_id = notification.metadata?.attached_entity?.id;
    const element_type = notification.metadata?.attached_entity?.type;

    if (!element_id || !element_type || element_type === 'IM' || element_type === 'MAIL_DRAFT') return [];

    const tags = await this.viewTagRelation.getTagsByElement(element_type, element_id);

    return tags;
  }

  async getNotifications(): Promise<(ViewNotification & Taggable)[]> {
    try {
      const notifications = await this.viewNotification.getAll();
      return Promise.all(
        notifications.map(async (notification) => {
          if (notification.attendees)
            await Promise.all(
              notification.attendees.map(async (att, index) => {
                const contact = await this.viewContact.getByIdentifier(att.identifier_type, att.identifier);
                if (contact.length && Array.isArray(notification.attendees)) {
                  notification.attendees[index].display_name = contact[0].full_name;
                  notification.attendees[index].profile_picture = contact[0].profile_picture;
                }
              })
            );
          const tags = await this._getNotificationTags(notification);
          return { ...notification, tags };
        })
      );
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async notifyNewMail(mail: PartialViewMail): Promise<ViewNotification> {
    try {
      const account = await this.viewAccount.get(mail.account_id);

      if (!account) throw new Error("Can't find mail's account");

      const att = mail.has_attachments ? ' 📎' : '';

      const { aggregate, ...result } = Notification.create({
        type: 'MAIL_NEW',
        content: (mail.subject || i18n.t('mailForm.noSubject')) + att,
        title: i18n.t('notification.type.mail_new'),
        account_id: mail.account_id,
        account_type: account.type,
        metadata: {
          attached_entity: {
            type: 'MAIL',
            id: mail.id,
            account_id: mail.account_id,
            account_type: account.type,
          },
        },
        attendees: [mail.from_attendee],
        received_date: toUTCDateTimeMs(mail.date),
      });

      await this.publisher.emit(result.changes);

      const notification = await this.viewNotification.get(aggregate.id);
      if (!notification) {
        throw new Error(`Could not find notification ( ${aggregate.id} ).`);
      }

      return notification;
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async notifyNewIm(message: ViewIm): Promise<ViewNotification | null> {
    try {
      const account = await this.viewAccount.get(message.account_id);

      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();

      const thread = await this.viewImThread.get(message.thread_id);

      if (!thread) throw new Error('Thread not found');

      const found = await this.viewNotification.getByEntityId(thread.id);
      const already_exist = found.length > 0;

      /**
       * If the message is OUTGOING and there is no notification (eg : the user send a message in the thread from the workmode of unipile), do not notify anything
       */
      if (!already_exist && message.direction === 'OUTGOING') return null;

      const sender = thread.attendees.find((att) => att.identifier === message.from_attendee_identifier) || {
        identifier: message.from_attendee_identifier,
        identifier_type: ImAccountIdentifierType[account.type],
      };

      const content =
        displayAttendeeName(sender) +
        ': ' +
        (message.body ? (message.body.length > 40 ? message.body.slice(0, 37) + '...' : message.body) : '');

      /**
       * @note A "fake" attendee of type group_thread is used to store group meta data
       */
      const attendee: Attendee =
        thread.attendees.length > 2
          ? {
              identifier: '',
              identifier_type: 'GROUP_THREAD',
              display_name: thread.provider_name || displayAttendeeName(sender),
            }
          : thread.attendees[0];

      /**
       * If the message is INCOMING and there is no notification, create a new notification
       */
      if (!already_exist && message.direction === 'INCOMING') {
        const { aggregate, ...result } = Notification.create({
          type: 'MESSAGE_NEW',
          content,
          title: i18n.t('notification.type.message_new'),
          account_id: message.account_id,
          account_type: account.type,
          metadata: {
            attached_entity: {
              type: 'IM_THREAD',
              id: message.thread_id,
              account_id: message.account_id,
              account_type: account.type,
            },
          },
          attendees: [attendee],
          received_date: toUTCDateTimeMs(message.date),
        });

        await this.publisher.emit(result.changes);

        return this.viewNotification.get(aggregate.id);
      }

      /**
       * If there is already a notification, update it with the body of the INCOMING or OUTGOING message
       */
      const notificationAgg = await this.notification.getAggregate(found[0].id);
      /**
       * @note Update Im Notification with last message data
       */
      const result = notificationAgg.update({
        content,
        title: i18n.t('notification.type.messages_new'),
        attendees: [attendee],
        received_date: toUTCDateTimeMs(message.date),
      });

      await this.publisher.emit(result.changes);
      return this.viewNotification.get(found[0].id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async notifyNewMissedCall(call: ViewMissedCall): Promise<ViewNotification | null> {
    try {
      const account = await this.viewAccount.get(call.account_id);

      if (!account) throw new Error("Can't find call's account");

      const { aggregate, ...result } = Notification.create({
        type: 'CALL_MISSED',
        content: i18n.t('notification.call.missedTitle', {
          attendee: call.from_attendee.display_name,
        }),
        title: i18n.t('notification.type.call_missed'),
        account_id: call.account_id,
        account_type: account.type,
        metadata: {
          attached_entity: {
            type: 'CALL',
            id: call.id,
            account_id: call.account_id,
            account_type: account.type,
          },
        },
        attendees: [call.from_attendee],
        received_date: toUTCDateTimeMs(call.date),
      });

      await this.publisher.emit(result.changes);

      return this.viewNotification.get(aggregate.id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async notifyNewIncomingCall(call: ViewIncomingCall): Promise<ViewNotification | null> {
    try {
      const account = await this.viewAccount.get(call.account_id);

      if (!account) throw new Error("Can't find call's account");

      const { aggregate, ...result } = Notification.create({
        type: 'CALL_INCOMING',
        content: i18n.t('notification.call.incomingTitle', {
          attendee: call.from_attendee.display_name,
        }),
        title: i18n.t('notification.type.call_incoming'),
        account_id: call.account_id,
        account_type: account.type,
        metadata: {
          attached_entity: {
            type: 'CALL',
            id: call.id,
            account_id: call.account_id,
            account_type: account.type,
          },
        },
        attendees: [call.from_attendee],
        received_date: toUTCDateTimeMs(call.date),
      });

      await this.publisher.emit(result.changes);
      return this.viewNotification.get(aggregate.id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async notifyNewOutgoingCall(call: ViewOutgoingCall): Promise<ViewNotification | null> {
    try {
      const account = await this.viewAccount.get(call.account_id);

      if (!account) throw new Error("Can't find call's account");

      const { aggregate, ...result } = Notification.create({
        type: 'CALL_OUTGOING',
        content: i18n.t('notification.call.outgoingTitle', {
          attendee: call.from_attendee.display_name,
        }),
        title: i18n.t('notification.type.call_outgoing'),
        account_id: call.account_id,
        account_type: account.type,
        metadata: {
          attached_entity: {
            type: 'CALL',
            id: call.id,
            account_id: call.account_id,
            account_type: account.type,
          },
        },
        attendees: [call.from_attendee],
        received_date: toUTCDateTimeMs(call.date),
      });

      await this.publisher.emit(result.changes);
      return this.viewNotification.get(aggregate.id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async notifyNewNoAnswerCall(call: ViewNoAnswerCall): Promise<ViewNotification | null> {
    try {
      const account = await this.viewAccount.get(call.account_id);

      if (!account) throw new Error("Can't find call's account");

      const { aggregate, ...result } = Notification.create({
        type: 'CALL_NO_ANSWER',
        content: i18n.t('notification.call.no_answerTitle', {
          attendee: call.from_attendee.display_name,
        }),
        title: i18n.t('notification.type.call_no_answer'),
        account_id: call.account_id,
        account_type: account.type,
        metadata: {
          attached_entity: {
            type: 'CALL',
            id: call.id,
            account_id: call.account_id,
            account_type: account.type,
          },
        },
        attendees: [call.from_attendee],
        received_date: toUTCDateTimeMs(call.date),
      });

      await this.publisher.emit(result.changes);
      return this.viewNotification.get(aggregate.id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async snoozeNotification(notification_id: Uuid): Promise<ViewNotification | null> {
    try {
      const agg = await this.notification.getAggregate(notification_id);
      const result = agg.snooze();
      await this.publisher.emit(result.changes);
      return this.viewNotification.get(notification_id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async archiveNotification(notification_id: Uuid): Promise<void> {
    try {
      const agg = await this.notification.getAggregate(notification_id);
      const result = agg.delete();
      await this.publisher.emit(result.changes);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   * Replan a missed event from its notification and delete the notification
   * @param notification_id Missed event type notification with the missed event to replan as origin_event
   * @param event_draft Updated values of the event
   */
  async replanMissedEvent(notification_id: Uuid, values: PatchCalendarEventDTO): Promise<Result<ViewCalendarEvent & Taggable>> {
    // prettyPrint({ notification_id, values }, 'replanMissedEvent');
    try {
      const notification = await this.viewNotification.get(notification_id);

      if (!notification) {
        throw new Error('Notification not found');
      }

      if (!notification.metadata?.origin_event || notification.type !== 'EVENT_MISSED') {
        throw new Error('Not a EVENT_MISSED notification');
      }

      /**
       * @todo Talk about the use cases for notification.metadata.origin_event.id.
       *
       *       At the moment it can hold an event id, but that may be a single
       *       event or an instance of an expanded recurring event.
       *
       *       Instances' id are different from the aggregate id.
       *
       *       Always storing the aggregate id in notification.metadata.origin_event.id
       *       does not tell us which instance is involved.
       *
       *       Recommend :
       *           - store both aggregate id ( i.e. : recurring_event_id on instances )
       *             and instance id.
       *           - use calendarEventAgg.reschedule ( or replan, whatever fits )
       *             instead of calendarEventAgg.edit.
       *             calendarEventAgg.edit "works" but doesn't represent accurately
       *             what happened.
       *
       *
       *       In the meantime, here's a dirty hack that we should not rely on,
       *       to determine if we have a single event or a recurring event instance
       *       and extract the aggregate id from an instance id.
       */
      const hack = notification.metadata.origin_event.id.split('_');
      const aggId = hack[0] as Uuid;
      const isSingleEvent = hack.length === 1;

      const eventAgg = await this.calendarEvent.getAggregate(aggId);

      /** @todo Consider implementing and using CalendarEvent.reschedule / rescheduleInstance. */
      const eventEdit = isSingleEvent
        ? eventAgg.edit({ ...values, status: 'PLANNED' })
        : eventAgg.editException(notification.metadata.origin_event.id as UnipileCalendarEventInstanceId, {
            ...values,
            status: 'PLANNED',
          });

      const notifAgg = await this.notification.getAggregate(notification_id);
      const notifDel = notifAgg.delete();

      if (eventEdit.ok === true && notifDel.ok === true) {
        await this.publisher.emit([...eventEdit.changes, ...notifDel.changes]);

        const event = await this.viewCalendarEvent.get(isSingleEvent ? eventAgg.id : notification.metadata.origin_event.id);

        return event ? { result: event } : { error: `Could not find updated calendar event ( ${eventAgg.id} ).` };
      }

      throw new Error(
        `Could not replan missed ${isSingleEvent ? 'event' : 'instance'}  from its notification ( ${notification_id} ) : ${
          (eventEdit.ok === false ? eventEdit.reason : '') + (notifDel.ok === false ? notifDel.reason : '')
        }.`
      );
    } catch (error) {
      this.logger.captureException(error);
      return typeof error === 'string' ? { error: error } : {};
    }
  }

  async planNotification(notification_id: Uuid, event_draft: NewCalendarEventDTO): Promise<Result<ViewCalendarEvent>> {
    try {
      const notification = await this.viewNotification.get(notification_id);
      if (!notification) throw new Error('Notification not found');
      const resultCreate = CalendarEvent.create({
        ...event_draft,
        metadata: {
          origin_notification: notification,
          ...(notification.metadata && { attached_entity: notification.metadata.attached_entity }),
        },
      });

      const notificationAgg = await this.notification.getAggregate(notification_id);
      const resultPlan = notificationAgg.plan();

      if (resultCreate.ok === true && resultPlan.ok === true) {
        await this.publisher.emit([...resultCreate.changes, ...resultPlan.changes]);
        const event = await this.viewCalendarEvent.get(resultCreate.aggregate.id);
        return event ? { result: event } : { error: `Could not find calendar event ( ${resultCreate.aggregate.id} ).` };
      }
      throw new Error(
        `Could not create calendar event to plan notification ( ${notification_id} ) : ${
          (resultCreate.ok === false ? resultCreate.reason : '') + (resultPlan.ok === false ? resultPlan.reason : '')
        }.`
      );
    } catch (error) {
      this.logger.captureException(error);
      return typeof error === 'string' ? { error: error } : {};
    }
  }
}
