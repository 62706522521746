import { IonIcon } from '@ionic/react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { ArrowDropDownCircleSharp } from '@material-ui/icons';
import GetApp from '@material-ui/icons/GetApp';
import { Alert, AlertTitle } from '@material-ui/lab';
import { globeOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

export function AlertWeb() {
  const { t } = useTranslation();
  return (
    <Alert severity="info" variant="outlined">
      <AlertTitle>{t('onboarding.webWarning.alertTitle')}</AlertTitle>
      {t('onboarding.webWarning.alertContent')}
    </Alert>
  );
}

export default function DownloadDesktopModal() {
  const [show, setShow] = useLocalStorage<boolean>('__UNIPILE_DL_DESKTOP', true);
  const { t } = useTranslation();
  return (
    <Dialog open={show}>
      <DialogContent>
        <IonIcon style={{ fontSize: 30 }} icon={globeOutline} />
        <Typography variant="h2">{t('onboarding.webWarning.title')}</Typography>
        <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
          {t('onboarding.webWarning.subtitle')}
        </Typography>
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <AlertWeb />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShow(false)} variant="contained">
          {t('onboarding.webWarning.btnContinue')}
        </Button>
        <Button
          href="https://www.unipile.com/telechargement-beta/"
          target="_blank"
          variant="contained"
          color="primary"
          startIcon={<GetApp />}
        >
          {t('onboarding.webWarning.btnDownload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
