import {
  dumpEventStoreDigest,
  envSelector,
  ErrorRecommendations,
  recoveryErrorSelector,
  RecoveryOption,
  recoveryStatusSelector,
  recoveryReloadRequestSelector,
  resetAccount,
  resetLocalBranch,
  restart,
  sessionSelector,
  useAppDispatch,
  logoutUser,
} from '@focus-front/core';
import {
  Button,
  CircularProgress,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { Error, SettingsBackupRestore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import Logo from '../Header/Logo';

/**
 *
 */
export default function RecoveryPage() {
  const session = useSelector(sessionSelector);
  const error = useSelector(recoveryErrorSelector);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const env = useSelector(envSelector);
  const history = useHistory();
//   const { reloadRequested, recoveryRequested, networkFailed } = useSelector(recoveryStatusSelector);
  const  reloadRequested= useSelector(recoveryReloadRequestSelector);

  //   if (!session) return <Redirect to="/login" />;

  //   // Redirect to home if onboarding already done
  //   if (session.params.onboarding) return <Redirect to="/" />;

  const handleRestart = async () => {
    await dispatch(restart());
  };

  const handleLocalReset = async () => {
    await dispatch(resetLocalBranch());
  };

  const handleAccountUndo = async () => {
    console.log('todo');
  };

  const handleAccountReset = async () => {
    const confirmed = window.confirm(`${t('recovery.accountReset.confirmTitle')} :\n${t('recovery.accountReset.confirm')}`);
    if (confirmed) {
      await dispatch(resetAccount());
    } else {
      console.log('accountReset canceled.');
    }
  };

  const pickOption = (option: RecoveryOption): JSX.Element => {
    switch (option) {
      case 'warnUnsupported':
        return <RecoveryOptionWarnUnsupported />;
      case 'checkConnectivity':
        return <RecoveryOptionCheckConnectivity />;
      case 'restart':
        return <RecoveryOptionRestart onClick={handleRestart} />;
      case 'localReset':
        return <RecoveryOptionLocalReset onClick={handleLocalReset} />;
      case 'accountUndo':
        return <RecoveryOptionAccountUndo onClick={handleAccountUndo} />;
      case 'accountReset':
        return <RecoveryOptionAccountReset onClick={handleAccountReset} />;
    }
  };

  if (reloadRequested)
    return (
      <Fade in={true}>
        <div className="splashscreen">
          <Logo />
          <CircularProgress color="inherit" style={{ marginTop: 40 }} />
          <Typography variant="subtitle1">{t('recovery.cleaning')}</Typography>
        </div>
      </Fade>
    );

  const recommendations = error ? ErrorRecommendations[error.name] ?? [] : [];
  const [recommend1st, ...recommend2nd] = recommendations;
  const otherOptions = (['restart', 'localReset', 'accountUndo', 'accountReset'] as const).filter(
    (o) => !recommendations.includes(o)
  );

  return (
    <List subheader={<ListSubheader component="div">{t('recovery.label')}</ListSubheader>}>
      {error && (
        <>
          <ListItem>
            <Typography variant="h3">{t('recovery.description')}</Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Error />
            </ListItemIcon>
            <Alert severity="error">
              {t('recovery.errorLabel')}
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </Alert>
          </ListItem>
        </>
      )}

      <ListItem>
        <Typography variant="h4">{t('recovery.message')}</Typography>
      </ListItem>

      {recommend1st && (
        <>
          <ListItem>
            <ListItemIcon>
              <Error />
            </ListItemIcon>
            <ListItemText primary={t('recovery.recommend1st')} />
          </ListItem>
          <ListItem>{pickOption(recommend1st)}</ListItem>
          <Divider />
        </>
      )}

      {!!recommend2nd.length && (
        <>
          <ListItem>
            <ListItemIcon>
              <Error />
            </ListItemIcon>
            <ListItemText primary={t('recovery.recommend2nd')} />
          </ListItem>
          {recommend2nd.map((option, index) => (
            <ListItem key={option + index}>{pickOption(option)}</ListItem>
          ))}
          <Divider />
        </>
      )}
      {otherOptions.length && (
        <>
          {recommend1st && (
            <ListItem>
              <ListItemIcon>
                <Error />
              </ListItemIcon>
              <ListItemText primary={t('recovery.otherOptions')} />
            </ListItem>
          )}
          {otherOptions.map((option, index) => (
            <ListItem key={option + index}>{pickOption(option)}</ListItem>
          ))}
          <Divider />
        </>
      )}
      {session && (
        <>
          <ListItem>
            <ListItemIcon>
              <SettingsBackupRestore />
            </ListItemIcon>
            <ListItemText primary={t('recovery.dumpDigest.title')} />
            <ListItemSecondaryAction>
              <Button
                style={{
                  width: 300,
                  textTransform: 'none',
                }}
                onClick={async () => {
                  await dispatch(dumpEventStoreDigest());
                }}
                variant="contained"
                color="secondary"
                disableElevation
              >
                {t('recovery.dumpDigest.description')}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          {/* <ListItem>
          <ListItemIcon>
            <SettingsBackupRestore />
          </ListItemIcon>
          <ListItemText primary={t('recovery.logout.title')} />
          <ListItemSecondaryAction>
            <Button
              style={{
                width: 300,
                textTransform: 'none',
              }}
              onClick={async () => {
                await dispatch(logoutUser());
              }}
              variant="contained"
              color="secondary"
              disableElevation
            >
              {t('recovery.logout.description')}
            </Button>
          </ListItemSecondaryAction>
        </ListItem> */}
        </>
      )}
    </List>
  );
}

/**
 *
 */
export function RecoveryOptionRestart({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <>
      <ListItemIcon>
        <SettingsBackupRestore />
      </ListItemIcon>
      <ListItemText primary={t('recovery.restart.title')} />
      <ListItemSecondaryAction>
        <Button
          style={{
            width: 300,
            textTransform: 'none',
          }}
          onClick={onClick}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {t('recovery.restart.description')}
        </Button>
      </ListItemSecondaryAction>
    </>
  );
}

/**
 *
 */
export function RecoveryOptionLocalReset({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <>
      <ListItemIcon>
        <SettingsBackupRestore />
      </ListItemIcon>
      <ListItemText primary={t('recovery.localReset.title')} />
      <ListItemSecondaryAction>
        <Button
          style={{
            width: 300,
            textTransform: 'none',
          }}
          onClick={onClick}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {t('recovery.localReset.description')}
        </Button>
      </ListItemSecondaryAction>
    </>
  );
}

/**
 *
 */
export function RecoveryOptionAccountUndo({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <>
      <ListItemIcon>
        <SettingsBackupRestore />
      </ListItemIcon>
      <ListItemText primary={t('recovery.accountUndo.title')} />
      <ListItemSecondaryAction>
        <Button
          style={{
            width: 300,
            textTransform: 'none',
          }}
          onClick={onClick}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {t('recovery.accountUndo.description')}
        </Button>
      </ListItemSecondaryAction>
    </>
  );
}

/**
 *
 */
export function RecoveryOptionAccountReset({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <>
      <ListItemIcon>
        <SettingsBackupRestore />
      </ListItemIcon>
      <ListItemText primary={t('recovery.accountReset.title')} />
      <ListItemSecondaryAction>
        <Button
          style={{
            width: 300,
            textTransform: 'none',
          }}
          onClick={onClick}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {t('recovery.accountReset.description')}
        </Button>
      </ListItemSecondaryAction>
    </>
  );
}

/**
 *
 */
export function RecoveryOptionWarnUnsupported() {
  const { t } = useTranslation();

  return (
    <>
      <ListItemIcon>
        <SettingsBackupRestore />
      </ListItemIcon>
      <ListItemText primary={t('recovery.warnUnsupported.title')} />
    </>
  );
}
/**
 *
 */
export function RecoveryOptionCheckConnectivity() {
  const { t } = useTranslation();

  return (
    <>
      <ListItemIcon>
        <SettingsBackupRestore />
      </ListItemIcon>
      <ListItemText primary={t('recovery.checkConnectivity.title')} />
    </>
  );
}

/**
 *
 */
export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  //   return (
  //     <div role="alert">
  //       <p>Oops, something went wrong.</p>
  //       <pre>{error.message}</pre>
  //       <button onClick={resetErrorBoundary}>Try again</button>
  //     </div>
  //   );
  return <Redirect to="/recover" />;
}
