import { DomainEventShape, Uuid } from '../../../Common';
import { NewMailReferenceDTO } from '../projections/MailReference';

/**
 * DomainEvent.
 */
export class MailReceived implements DomainEventShape {
  readonly type = 'MAIL_RECEIVED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly newMail: NewMailReferenceDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class MailArchived implements DomainEventShape {
  readonly type = 'MAIL_ARCHIVED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class MailReplied implements DomainEventShape {
  readonly type = 'MAIL_REPLIED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent
 */
export class MailTag implements DomainEventShape {
  readonly type = 'MAIL_TAG';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly tag_id: Uuid, readonly createdAt = Date.now()) {}
}

/**
 * Collect and export all event definitions.
 */
export type MailDomainEvent = MailReceived | MailArchived | MailReplied | MailTag;
