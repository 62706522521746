import { AppDispatch, AppGetState } from '../../_container/store';

import { UnknownAggregateIdError } from '../domain/AggregateRepo';
import { UnexpectedAggregateIdError, UnexpectedDomainEventVersionError } from '../domain/DecisionProjection';
import { DebugDigestMap, UnknownDomainEventIdError } from '../domain/DomainEventStore';
import { UnexpectedKeyConflictError, KeyCannotBeCreatedError } from '../infra/services/KeychainService';

import { createAction, createSlice } from '@reduxjs/toolkit';
import Container from '../../_container/interfaces/Container';
import { BrokenProjectorError } from '../domain/Projectionist';
import i18n from '../infra/services/i18nextService';
import { logoutUser, initDone, requireLogin } from '../../User/state/session.slice';
import { NetworkError } from '../app/Errors';
import { ErrorRecommendations } from '../app/ErrorRecommendations';
import { ExportedRawKey } from '../infra/services/crypto/Crypto';
import { BackupKeyRequestResponse } from '../../User/infra/services/CoreApiTypes';
import { InvalidOrExpiredRefreshTokenError } from '../../User/infra/services/AuthenticatorService';
import { DomainEvent } from '..';

export interface RecoveryState {
  user_id: number | null;
  error: {
    name: string;
    message: string;
    /** @note Non-standard, ignored, see MDN.  */
    // stack?: string;
    extra: ExtraErrorData;
  } | null;
  reloadRequested: boolean;
  recoveryRequested: boolean;
  networkFailed: boolean;
}

const initialState: RecoveryState = {
  user_id: null,
  error: null,
  reloadRequested: false,
  recoveryRequested: false,
  networkFailed: false,
};

/**
 *
 */
function serializeError(e: Error) {
  if (e instanceof BrokenProjectorError) {
    return [e.name, e.message, { report: e.report }] as const;
  } else if (e instanceof UnknownAggregateIdError) {
    return [e.name, e.message, { aggregateId: e.aggregateId, refEventId: e.refEventId }] as const;
  } else if (e instanceof UnexpectedAggregateIdError) {
    return [e.name, e.message, { receivedId: e.receivedId, expectedId: e.expectedId }] as const;
  } else if (e instanceof UnexpectedDomainEventVersionError) {
    return [e.name, e.message, { event: e.event, expectedVersion: e.expectedVersion }] as const;
  } else if (e instanceof UnknownDomainEventIdError) {
    return [e.name, e.message, { unknownId: e.unknownId }] as const;
  } else if (e instanceof UnexpectedKeyConflictError) {
    return [e.name, e.message, { topic: e.topic }] as const;
  } else if (e instanceof KeyCannotBeCreatedError) {
    return [e.name, e.message, { topic: e.topic }] as const;
  } else {
    return [e.name, e.message, null] as const;
  }
  // return [e.name, e.message, null] as const;
}

type ExtraErrorData = ReturnType<typeof serializeError>[2];

/**
 * @note Anything can be thrown as an Error, we have to tread carefully.
 *       Error are not serializable and don't play well with redux.
 */
export const recoveryRequested = createAction('APP_RECOVERY_REQUESTED', (user_id: number | null, e: unknown) => {
  let name = 'Unspecified error type';
  let message = 'Unspecified error message';
  let extra: ExtraErrorData = null;

  const type = typeof e;
  const unhelpfulType = i18n.t('recovery.unhelpfulErrorType');

  switch (typeof e) {
    case 'string':
    case 'number':
    case 'boolean':
      name = `${type} ${unhelpfulType}`;
      message = `${e}`;
      break;
    case 'function':
      name = `${type} ${unhelpfulType}`;
      message = `${e.name || 'anonymous'} ${e.toString()}`;
      break;
    case 'bigint':
    case 'symbol':
      name = `${type} ${unhelpfulType}`;
      message = e.toString();
      break;
    case 'undefined':
      name = `${type} ${unhelpfulType}`;
      break;
    case 'object':
      if (e instanceof Error) {
        [name, message, extra] = serializeError(e);
      } else {
        name = `${type} ${unhelpfulType}`;
        /** Anything not supported by stringify will be discarded. */
        message = JSON.stringify(e);
      }
      break;
  }

  return {
    payload: {
      user_id,
      error: {
        name,
        message,
        extra,
      },
    },
  };
});

/**
 *
 */
export const recoverySlice = createSlice({
  name: 'recovery',
  initialState,
  reducers: {
    reloadRequested: (state) => {
      state.reloadRequested = true;
    },
    recoveryRequested: (state) => {
      state.recoveryRequested = true;
    },
    networkFailed: (state) => {
      state.networkFailed = true;
    },
    networkOk: (state) => {
      state.networkFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(recoveryRequested, (state, { payload: { error, user_id } }) => {
      state.error = error;
      state.user_id = user_id;
      state.recoveryRequested = true;
    });
  },
});

export const recoveryErrorSelector = (state: { recovery: RecoveryState }): RecoveryState['error'] => {
  return state.recovery.error;
};

export const recoveryUserIdSelector = (state: { recovery: RecoveryState }): number | null => {
  return state.recovery.user_id;
};

export const recoveryStatusSelector = (state: { recovery: RecoveryState }): RecoveryState => {
  //   const { reloadRequested, recoveryRequested, networkFailed } = state.recovery;
  //   return { reloadRequested, recoveryRequested, networkFailed };
  return state.recovery;
};

export const recoveryReloadRequestSelector = (state: { recovery: RecoveryState }): boolean => {
  //   const { reloadRequested, recoveryRequested, networkFailed } = state.recovery;
  //   return { reloadRequested, recoveryRequested, networkFailed };
  return state.recovery.reloadRequested;
};

export const recoveryRequestSelector = (state: { recovery: RecoveryState }): boolean => {
  return state.recovery.recoveryRequested;
};

export const { reloadRequested, networkFailed, networkOk } = recoverySlice.actions;

export default recoverySlice.reducer;

/**
 *
 */
export const restart =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    await dispatch(logoutUser());
    dispatch(reloadRequested());
  };

/**
 *
 */
export const crash =
  (e: unknown) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user, logger }: Container): Promise<void> => {
    if (!(e instanceof NetworkError)) {
      logger.captureException(e);
    }
    dispatch(initDone());

    if (e instanceof InvalidOrExpiredRefreshTokenError) {
      console.log('session has expired');
      dispatch(requireLogin());
    }

    /** @note crash list / permissive approach */
    if (e instanceof Error && e.name in ErrorRecommendations) {
      const {
        session: { session },
        recovery,
      } = getState();

      user.clearCrons();
      /**
       * If multiple errors are chain thrown, session.user_id will be cleared,
       * if recovery.user_id has been set from a previous crash, use it,
       * otherwise consider no user is logged in.
       */
      dispatch(recoveryRequested(session?.id ?? recovery.user_id ?? null, e));
    }

    /** @note safe list / strict approach */
    //   if (e instanceof NetworkError) {
    //     /** We don't need to crash and recover for NetworkError or just being offline. */
    //     console.warn('App is most likely offline.');
    //     dispatch(networkFailed);
    //     /**
    //      * @todo Add a hook that pings an url to check connectivity.
    //      *       Trigger it when recovery.networkFailed.
    //      *       If ping succeeds, dispatch(networkOk) from that hook.
    //      *       It should be more reliable than navigator.online/offline events.
    //      *       See : https://developer.mozilla.org/en-US/docs/Web/API/Navigator/Online_and_offline_events
    //      */
    //   } else {
    //     const {
    //       session: { session },
    //       recovery,
    //     } = getState();

    //     user.clearCrons();
    //     /**
    //      * If multiple errors are chain thrown, session.user_id will be cleared,
    //      * if recovery.user_id has been set from a previous crash, use it,
    //      * otherwise consider no user is logged in.
    //      */
    //     dispatch(recoveryRequested(session?.user_id ?? recovery.user_id ?? null, e));
    //   }
  };

/**
 *
 */
export const resetLocalBranch =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    try {
      await user.deleteBranch();
      await dispatch(logoutUser());
      dispatch(reloadRequested());
    } catch (e) {
      /** Handle the other possible cases, e.g. :
       *  There can be a leftover session with a failed Idb init.
       */
      const {
        recovery: { user_id },
      } = getState();
      if (user_id) {
        await user.deleteDb(user_id);
        await dispatch(logoutUser());
        dispatch(reloadRequested());
      } else {
        console.log(e);
        dispatch(recoveryRequested(null, new Error('You must be logged in to reset local data : Please hit restart and login.')));
      }
    }
  };

/**
 *
 */
export const dumpEventStoreDigest =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    return user.debugDumpEventStore();
  };
/**
 *
 */
export const debugGetEventStoreDigest =
  () =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { user }: Container
  ): Promise<{
    name: string;
    dump: DebugDigestMap<DomainEvent>;
  }> => {
    return user.debugGetEventStoreDigest();
  };

/**
 *
 */
export const dumpRecoveryCode =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    return user.dumpRecoveryCode();
  };

/**
 *
 */
export const getRecoveryCode =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<ExportedRawKey> => {
    return user.getRecoveryCode();
  };

/**
 *
 */
export const resetPassword =
  (email: string, recoveryCode: string, newPassword: string, requestId: string, token: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container) => {
    /** @todo Handle errors. */
    return user.resetPassword(email, recoveryCode, newPassword, requestId, token);
  };

/**
 *
 */
export const retryResetPassword =
  (email: string, recoveryCode: string, newPassword: string, retryWith: BackupKeyRequestResponse) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container) => {
    /** @todo Handle errors. */
    return user.retryResetPassword(email, recoveryCode, newPassword, retryWith);
  };

/**
 *
 */
export const resetPasswordWithoutRecoveryCode =
  (email: string, newPassword: string, requestId: string, token: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container) => {
    /** @todo Handle errors. */
    return user.resetPasswordWithoutRecoveryCode(email, newPassword, requestId, token);
  };

/**
 * @note WARNING : radical recovery mecanism. Tread lightly.
 */
export const resetAccount =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    const {
      recovery: { user_id },
    } = getState();
    console.log('resetAccount', user_id);

    if (user_id) {
      /** @todo Suggest login if user_id is set but session isn't ? */
      try {
        await user.clearAccountDataStopGap(user_id);
      } catch (e) {
        console.log('resetAccount failed : ', e);
        if (e instanceof NetworkError) {
          dispatch(
            recoveryRequested(user_id, new Error('You must be online to reset your account : Please connect to the internet.'))
          );
          return;
        } else {
          const msg = e instanceof Error ? `( ${e.name} : ${e.message} ) ` : e;
          dispatch(
            recoveryRequested(user_id, new Error(`${msg} Error while trying to reset your account : Please contact support.`))
          );
          return;
        }
      }
      await dispatch(resetLocalBranch());
      await dispatch(logoutUser());
      dispatch(reloadRequested());
    } else {
      console.log('no session');
      dispatch(recoveryRequested(null, new Error('You must be logged in to reset your account : Please hit restart and login.')));
    }
    // window.location.reload();
  };

/**
 *
 */
export const debugThrowError =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, container: Container): Promise<void> => {
    try {
      console.log('Throwing generic error from thunk');
      throw new Error('generic error from thunk');
    } catch (e) {
      console.log('error caught in thunk, rethrowing something else');
      throw new Error('rethrowing');
    }
  };

/**
 *
 */
export const dumpPublisher =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, container: Container): Promise<void> => {
    container.user.debugDumpPublisher();
  };
