import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/store';
import { ViewAccount } from '../../Account';
import { calendarsDefaultCalendarSelector, eventCreateDone, UnipileCalendarEventInstanceId } from '../../Calendar';
import { ActionContext, LocalFile, Result, Uuid } from '../../Common';
import { Attendee, IdentifierType } from '../../Contact';
import { notifyNewIm } from '../../Notification';
import { ImAttachment, ViewIm, ViewImThread, UpdateImThreadDTO } from '../domain';
import { Taggable } from '../../Tag';

interface ImState {
  thread_view: {
    thread: (ViewImThread & Taggable) | null;
    ims: ViewIm[];
  };
  show_attendees_list: boolean;
}

export const imsSlice = createSlice({
  name: 'ims',
  initialState: {
    thread_view: {
      thread: null,
      ims: [],
    },
    show_attendees_list: false,
  } as ImState,
  reducers: {
    loadThreadViewThread: (state, { payload }: PayloadAction<ViewImThread & Taggable>) => {
      state.thread_view.thread = payload;
    },
    loadThreadViewIms: (state, { payload }: PayloadAction<ViewIm[]>) => {
      state.thread_view.ims = [...payload.reverse(), ...state.thread_view.ims];
    },
    unloadThreadView: (state) => {
      state.thread_view = { thread: null, ims: [] };
    },
    pushNewIm: (state, { payload }: PayloadAction<ViewIm>) => {
      if (!state.thread_view.thread) return;
      if (payload.thread_id === state.thread_view.thread.id) state.thread_view.ims.push(payload);
    },
    syncThreadView: (state, { payload }: PayloadAction<ViewIm[]>) => {
      if (state.thread_view.thread === null) return;
      const synced = state.thread_view.ims.filter((im) => im.sync_status === 'SYNC' || im.send_status === 'SENDING');
      state.thread_view.ims = synced;
      payload.forEach((im) => {
        state.thread_view.ims.push(im);
      });
    },
    updateIm: (state, { payload }: PayloadAction<ViewIm>) => {
      if (!state.thread_view.thread) return;
      if (payload.thread_id === state.thread_view.thread.id) {
        const index = state.thread_view.ims.findIndex((im) => im.id === payload.id);
        state.thread_view.ims[index] = payload;
        state.thread_view.thread.provider_id = payload.provider_thread_id;
      }
    },
    updateThreadView: (state, { payload }: PayloadAction<{ im: ViewIm; thread: UpdateImThreadDTO }>) => {
      if (!state.thread_view.thread) return;
      if (payload.im.thread_id === state.thread_view.thread.id) {
        const index = state.thread_view.ims.findIndex((im) => im.id === payload.im.id);
        state.thread_view.ims[index] = payload.im;
        state.thread_view.thread = { ...state.thread_view.thread, ...payload.thread };
      }
    },
    showAttendeesList: (state, { payload }: PayloadAction<boolean>) => {
      state.show_attendees_list = payload;
    },
  },
});

export const {
  loadThreadViewThread,
  loadThreadViewIms,
  unloadThreadView,
  pushNewIm,
  syncThreadView,
  updateIm,
  updateThreadView,
  showAttendeesList,
} = imsSlice.actions;

/**
 * Bridge Thunk
 */
export const getAllImThreads =
  (size: number, offset: number) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { im }: Container
  ): Promise<{ thread: ViewImThread & Taggable; last_im: ViewIm | null; account: ViewAccount | null }[]> => {
    return im.getAllThreads(size, offset);
  };

/**
 * Bridge Thunk
 */
export const getOneToOneThreadByAttendee =
  (identifier: string, identifier_type: IdentifierType) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<ViewImThread | null> => {
    return im.getOneToOneThreadByAttendee(identifier, identifier_type);
  };

export const imDone =
  (context: ActionContext) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<void | string> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());

    if (defaultCalendar) {
      const { result, error } = await im.imDone(defaultCalendar, context);
      if (error) return error;
      if (result) dispatch(eventCreateDone(result));
    } else throw new Error('todo ! Unhandled path in imDone thunk : calendar_id is null.');
  };

export const imChatSubmit =
  (message: string, thread: ViewImThread) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<string | undefined> => {
    const { error, result } = await im.imChatSubmit(message, thread);
    if (error) return error;
    if (result) {
      dispatch(pushNewIm(result));
      return dispatch(imChatSend(result, thread));
    }
  };

export const imChatSend =
  (view_im: ViewIm, thread: ViewImThread) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<string | undefined> => {
    const { error, result } = await im.imChatSend(view_im, thread);
    if (error) return error;
    if (result) dispatch(updateThreadView(result));
  };

export const writeNewIm =
  (attendee: Attendee, account_id: Uuid) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { im }: Container
  ): Promise<Uuid | UnipileCalendarEventInstanceId | void> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());
    if (defaultCalendar) {
      const { error, result } = await im.writeNewIm(defaultCalendar, attendee, account_id);
      if (error) alert(error);
      if (result) {
        dispatch(eventCreateDone(result));
        return result.id;
      }
    } else throw new Error('todo ! Unhandled path in workOnEntity thunk : calendar_id is null.');
  };

/**
 * Get a chunk of past messages in a thread, from an offset.
 * @param thread_id ID of the thread
 * @param size Size of the chunk
 * @param offset Offset to get from (sorted by date)
 * @param live Is it a live thread (openned on the left pannel) ? In that case, messages are saved in the redux store,
 * if not, they are returned and stored in a component's local state
 *
 * @see AppImUseCase.loadIms() for more informations
 */
export const loadIms =
  (thread_id: Uuid, size: number, offset: number, live = false) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { im }: Container
  ): Promise<Result<{ ims: ViewIm[]; reached_end_local: boolean; reached_end_remote: boolean; must_reload_thread: boolean }>> => {
    if (live) offset = getState().ims.thread_view.ims.length;

    const { result, error } = await im.loadIms(thread_id, size, offset);

    if (live && result) {
      /**
       * We must check the loaded thread in the store AFTER the response of loadIms() because
       * it could be different from the original request
       * if the user navigate to another thread while it was loading.
       * Update the ims in the store only if the thread is the good one.
       */
      const current_id = getState().ims.thread_view.thread?.id;
      if (current_id === thread_id) dispatch(loadThreadViewIms(result.ims));
    }
    return { result, error };
  };

export const fetchNewInThread =
  (thread_id: Uuid, live = false) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<Result<ViewIm[]>> => {
    const { result, error } = await im.fetchNewInThread(thread_id);
    if (live && result && result.length) {
      dispatch(syncThreadView(result));
    }
    if (result && result.length) dispatch(notifyNewIm(result[result?.length - 1]));
    return { result, error };
  };

export const getThread =
  (thread_id: Uuid, live = false) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<Result<(ViewImThread & Taggable) | null>> => {
    const { result, error } = await im.getThread(thread_id);

    if (live && result) dispatch(loadThreadViewThread(result));

    return { result, error };
  };

/**
 * Bridge Thunk
 */
export const getAttachmentFile =
  (account_id: Uuid, attachment: ImAttachment, should_cache = true) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<Result<LocalFile | null>> => {
    return im.getAttachmentFile(account_id, attachment, should_cache);
  };

export const threadViewSelector = (state: { ims: ImState }) => {
  return state.ims.thread_view;
};

export const showAttendeesListSelector = (state: { ims: ImState }) => {
  return state.ims.show_attendees_list;
};

export default imsSlice.reducer;
