import { AggregateRoot, createProjectionConfig, createUuidFrom, DomainEvent, Uuid } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { MailArchived, MailReceived, MailReplied } from './events/Mail.events';
import { NewMailReferenceDTO } from './projections/MailReference';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const MAIL_NAMESPACE = '8599d81f-f7c3-4eac-86bc-e9514d8293cf' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  account_id: null as Uuid | null,
  archived: false,
  replied: 'NO' as 'NO' | 'YES' | 'REQUESTED',
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  MAIL_RECEIVED: (event) => ({
    id: event.aggregateId,
    account_id: event.newMail.account_id,
  }),
  MAIL_ARCHIVED: () => ({
    archived: true,
  }),
  MAIL_REPLIED: () => ({
    replied: 'YES',
  }),
});

/**
 * Aggregate.
 */
export class Mail extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static acknowledge(newMail: NewMailReferenceDTO) {
    /** @todo Use something to uniquely identify a mail accross providers. */
    const aggregateId = createUuidFrom(newMail.provider_id + newMail.account_id, MAIL_NAMESPACE);
    return Mail.accept(new MailReceived(aggregateId, newMail));
  }

  /**
   * Command.
   */
  archive() {
    return this.projection.state.archived
      ? this.reject('ARCHIVED')
      : this.apply(new MailArchived(this.id, this.version)).accept();
  }

  /**
   * Command.
   */
  markReplied() {
    return this.apply(new MailReplied(this.id, this.version)).accept();
  }
}
