export * from './utilityTypes';
export * from './Heap';
export * from './Scheduler';

import { useEffect } from 'react';
import platform from 'platform';

import {
  GoogleConnectionParams,
  LinkedInConnectionParams,
  MessengerConnectionParams,
  TikTokConnectionParams,
  InstagramConnectionParams,
  TwitterConnectionParams,
  MailConnectionParams,
  MobileConnectionParams,
  ViewAccount,
  OutlookConnectionParams,
  WhatsAppConnectionParams,
  GoogleCalendarConnectionParams,
  ICloudConnectionParams,
} from '../../Account';

import { Callable, Primitive } from './utilityTypes';
import { createUuidFrom, Uuid } from '../domain/Uuid';
import { CommandAccepted } from '../domain/AggregateRoot';
import { DomainEvent } from '../domain/DomainEvent';
import { Publisher } from '../domain/Publisher';

/**
 *
 */
export function sleep(milliseconds: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 *
 */
export function prettyPrint(
  record: Record<string, unknown>,
  from?: string,
  level = 'log' as 'log' | 'info' | 'warn' | 'error'
): void {
  const prettyArgs = Object.entries(record).reduce(
    (acc, [k, v]) => {
      acc.push('\n' + k, JSON.stringify(v, null, 2));
      return acc;
    },
    from ? ['\n>>>> ' + from] : ([] as string[])
  );
  console[level](...prettyArgs);
}

export function randomEnum<T extends Record<string, T[keyof T]>>(enumeration: T): T[keyof T] {
  const enumValues = Object.values<T[keyof T]>(enumeration);
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  const randomEnumValue = enumValues[randomIndex];
  return randomEnumValue;
}

export function intersection<T>(a: T[], b: T[]): T[] {
  const bSet = new Set(b);
  return [...new Set(a)].filter((e) => bSet.has(e));
}

/**
 * Return an object whose keys are the set of values resulting from applying
 * given iteratee function on every element of given array. The value for each
 * key are buckets, i.e. an array of each element of given array that yielded
 * that key upon applying iteratee.
 *
 * Essentially this groups element of given array by the result of given iteratee,
 * e.g. :
 *
 *   groupBy([
 *    {type : "a", v : 3 },
 *    {type : "b", v : 2 },
 *    {type : "a", v : 4 },
 *    {type : "c", v : 1 },
 *   ],
 *   (e) => e.type
 *   )
 *
 *   returns
 *     {
 *       a: [{ type: 'a', v: 3 },{ type: 'a', v: 4 }],
 *       b: [{ type: 'b', v: 2 }],
 *       c: [{ type: 'c', v: 1 }],
 *     }
 *
 */
export function groupBy<TElem, TKey extends PropertyKey>(
  arr: TElem[],
  iteratee: (element: TElem) => TKey
): Record<TKey, TElem[]> {
  return arr.reduce((acc, v) => {
    const k = iteratee(v);
    if (acc[k]) {
      acc[k].push(v);
    } else {
      acc[k] = [v];
    }
    return acc;
  }, {} as Record<TKey, TElem[]>);
}

/**
 * Same thing as groupBy, but groupByTransform applies given transform function
 * to each element of given array before grouping.
 * e.g. :
 *
 *   groupByTransform([
 *      {type : "a", v : 3 },
 *      {type : "b", v : 2 },
 *      {type : "a", v : 4 },
 *      {type : "c", v : 1 },
 *     ],
 *     (e) => e.type,
 *     (e) => e.v * 3
 *     )
 *
 *   returns
 *     {
 *       a: [9, 12],
 *       b: [6],
 *       c: [3],
 *     }
 */
export function groupByTransform<TElem, TKey extends PropertyKey, TValue>(
  arr: TElem[],
  iteratee: (element: TElem) => TKey,
  transform: (element: TElem) => TValue
): Record<TKey, TValue[]> {
  return arr.reduce((acc, v) => {
    const k = iteratee(v);
    if (acc[k]) {
      acc[k].push(transform(v));
    } else {
      acc[k] = [transform(v)];
    }
    return acc;
  }, {} as Record<TKey, TValue[]>);
}
/**
 * Same thing as groupByTransform, but each bucket is a Set instead of an array.
 */
export function groupAsSetBy<TElem, TKey extends PropertyKey, TValue>(
  arr: TElem[],
  iteratee: (element: TElem) => TKey,
  transform: (element: TElem) => TValue
): Record<TKey, Set<TValue>> {
  return arr.reduce((acc, v) => {
    const k = iteratee(v);
    if (acc[k]) {
      acc[k].add(transform(v));
    } else {
      (acc[k] = new Set<TValue>()).add(transform(v));
    }
    return acc;
  }, {} as Record<TKey, Set<TValue>>);
}

/**
 * @note Using Record<PropertyKey, unknown> instead of object allows to get
 *       rid of the required assertions to test properties in further predicates.
 *
 *              e.g. :
 *
 *              isObject(x) && u.type === 'MAIL
 *
 *              instead of :
 *
 *              typeof x === 'object' && !!x && (x as any)?.type === 'MAIL'
 *
 *
 */
export function isObject(x: unknown): x is Record<PropertyKey, unknown> {
  return typeof x === 'object' && x !== null;
}

/**
 *
 */
interface TypeOf {
  undefined: undefined;
  object: Record<PropertyKey, unknown>;
  boolean: boolean;
  number: number;
  bigint: bigint;
  string: string;
  symbol: symbol;
  function: Callable;
  array: unknown[];
}

const undef = Symbol('undefined');
const object = Symbol('object');
const boolean = Symbol('boolean');
const number = Symbol('number');
const bigint = Symbol('bigint');
const string = Symbol('string');
const symbol = Symbol('symbol');
const func = Symbol('function');
const array = Symbol('array');

export const T = {
  undefined: undef,
  object,
  boolean,
  number,
  bigint,
  string,
  symbol,
  function: func,
  array,
} as const;

const TSymOf = {
  [undef]: 'undefined',
  [object]: 'object',
  [boolean]: 'boolean',
  [number]: 'number',
  [bigint]: 'bigint',
  [string]: 'string',
  [symbol]: 'symbol',
  [func]: 'function',
  [array]: 'object',
} as const;

interface TOf {
  [T.undefined]: undefined;
  [T.object]: Record<PropertyKey, unknown>;
  [T.boolean]: boolean;
  [T.number]: number;
  [T.bigint]: bigint;
  [T.string]: string;
  [T.symbol]: symbol;
  [T.function]: Callable;
  [T.array]: unknown[];
}

export function hasProp<O extends Record<PropertyKey, unknown>, P extends PropertyKey>(
  obj: O,
  prop: P
): obj is O & Record<P, unknown> {
  return prop in obj;
}

export function hasPropOfType<O extends Record<string, unknown>, P extends string, T extends keyof TypeOf>(
  obj: O,
  prop: P,
  type: T
): obj is O & Record<P, TypeOf[T]> {
  return (
    // Object.prototype.hasOwnProperty.call(obj, prop) && (type === 'array' ? Array.isArray(obj[prop]) : typeof obj[prop] === type)
    prop in obj && (type === 'array' ? Array.isArray(obj[prop]) : typeof obj[prop] === type)
  );
}

export function hasOptionalPropOfType<O extends Record<string, unknown>, P extends string, T extends keyof TypeOf>(
  obj: O,
  prop: P,
  type: T
): obj is O & { P?: TypeOf[T] } {
  return !(prop in obj) || (type === 'array' ? Array.isArray(obj[prop]) : typeof obj[prop] === type);
}

export function hasTypeSchema<O extends Record<string, unknown>, S extends Record<string, keyof TypeOf>>(
  obj: O,
  schema: S
): obj is O & { [P in keyof S]: TypeOf[S[P]] } {
  for (const [prop, type] of Object.entries(schema)) {
    if (!(prop in obj && (type === 'array' ? Array.isArray(obj[prop]) : typeof obj[prop] === type))) {
      return false;
    }
  }
  return true;
}
export function hasSchema<O extends Record<string, unknown>, S extends Record<string, keyof TOf | Exclude<Primitive, symbol>>>(
  obj: O,
  schema: S
): obj is O & { [P in keyof S]: S[P] extends keyof TOf ? TOf[S[P]] : S[P] } {
  for (const [prop, def] of Object.entries(schema)) {
    if (
      !(
        prop in obj &&
        (typeof def === 'symbol' && def in TSymOf
          ? def === array
            ? Array.isArray(obj[prop])
            : typeof obj[prop] === TSymOf[def]
          : obj[prop] === def)
      )
    ) {
      return false;
    }
  }
  return true;
}

export function hasPropEqualTo<O extends Record<string, unknown>, P extends string, V extends Primitive>(
  obj: O,
  prop: P,
  value: V
): obj is O & Record<P, V> {
  return prop in obj && obj[prop] === value;
}

// function testHasX(u: Record<string, unknown>) {
//   //   if (hasPropEqualTo(u, 'a', 'sandwich')) {
//   //     const r = u.a;
//   //   }
//   //   if (hasSchema2(u, { a: t.boolean, b: 3 as const, c: undefined, d: 'yass' } as const)) {
//   if (hasSchema(u, { a: T.boolean, b: 3, c: undefined, d: 'yass', e: T.array } as const)) {
//     const r = u.a;
//     const r2 = u.b;
//     const r3 = u.c;
//     const r4 = u.d;
//     const r5 = u.e;
//   }
// }

/**
 *
 */
export function deepEqual<T>(object1: T, object2: any): boolean {
  if (typeof object1 !== 'object' && typeof object2 !== 'object') return object1 === object2;
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = (object1 as Record<string, unknown>)[key];
    const val2 = (object2 as Record<string, unknown>)[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

/**
 *
 */
export default function shallowEqual(a: any, b: any) {
  if (Object.is(a, b)) {
    return true;
  }

  if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0, length = keysA.length; i < length; ++i) {
    if (!Object.prototype.hasOwnProperty.call(b, keysA[i]) || !Object.is(a[keysA[i]], b[keysA[i]])) {
      return false;
    }
  }

  return true;
}

// use async operation with automatic abortion on unmount
export function useAsync(asyncFn: any, onSuccess: any) {
  useEffect(() => {
    let isMounted = true;
    asyncFn().then((data: any) => {
      if (isMounted) onSuccess(data);
    });
    return () => {
      isMounted = false;
    };
  }, []);
}

/**
 * Extract the email and name from an Imap Mail Head (ex Name <email@email.com>)
 */
// export function extractImapMailUser(string: string): Attendee {
//   try {
//     const regex_mail = string.match(new RegExp('<(.*?)>'));
//     if (!regex_mail || !regex_mail.length) throw null;
//     const extracted_name = string.replace(' ' + regex_mail[0], '');

//     return {
//       identifier: regex_mail[1].toLowerCase(),
//       display_name: extracted_name.includes(regex_mail[1]) ? regex_mail[1] : extracted_name.replace(/["]/gi, ''),
//       identifier_provider: 'MAIL',
//     };
//   } catch (e) {
//     return {
//       identifier: string,
//       display_name: string,
//       identifier_provider: 'MAIL',
//     };
//   }
// }

// export function apiDate(iso_string: string): string {
//   if (!iso_string) return undefined;
//   return iso_string.slice(0, -5) + 'Z';
// }

// export function parseId(value: string): number {
//   if (value === null || typeof value === 'undefined') return null;
//   if (/^(-|\+)?(\d+|Infinity)$/.test(value)) return Number(value);
//   return NaN;
// }

/**
 * Extract an account identifier from its connection params
 * @param connection_params Account connection params
 */
export function accountIdentifier(connection_params: ViewAccount['connection_params'] | null) {
  if (connection_params) {
    return (
      (connection_params as MailConnectionParams).mail?.imap_user ||
      (connection_params as MobileConnectionParams).call?.phone_number ||
      (connection_params as GoogleConnectionParams).mail?.imap_user ||
      (connection_params as ICloudConnectionParams).mail?.imap_user ||
      (connection_params as LinkedInConnectionParams).im?.username ||
      (connection_params as MessengerConnectionParams).im?.username ||
      (connection_params as TikTokConnectionParams).im?.username ||
      (connection_params as InstagramConnectionParams).im?.username ||
      (connection_params as TwitterConnectionParams).im?.username ||
      (connection_params as WhatsAppConnectionParams).im?.phone_number ||
      (connection_params as OutlookConnectionParams).mail?.username ||
      (connection_params as GoogleCalendarConnectionParams).calendar ||
      'Unknown id'
    );
  }
  return '';
}

// Format Bytes - https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// Convert Base64 to URLStandard : https://github.com/dankogai/js-base64/blob/ad8897c84bf6b2607a718fbf390ddde63cb4ceff/base64.ts#L167
export const convertBase64 = (a: string) => a.replace(/[-_]/g, (m0) => (m0 === '-' ? '+' : '/'));

// Can't use .flat() yet so using reduce for now
// Need to modify settings : https://stackoverflow.com/questions/53556409/typescript-flatmap-flat-flatten-doesnt-exist-on-type-any in order to use .flat()
export const flatten = <T>(arr: T[][]) => arr.reduce((a, c) => a.concat(c), []);

// To filter (string | null)[] to string[]
// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const fakeRandom = (r: any) => (r && Math.random() > 0.5) || false;

/**
 * @note Reads a file and returns its base64 content
 */
export const readFilePromisified = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
  });
};

// https://www.w3resource.com/javascript/form/email-validation.php
export const isEmail = (email: string) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return true;
  }
  return false;
};

// Naive utility function to get device type by sniffing User Agent
export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'mobile'; // This is for tablets
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'web';
};

export const getDeviceName = () => {
  return `${platform.name ?? ''} ${platform.os?.family ?? ''} ${platform.product ?? ''}`.trim();
};

// https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds/3733257#:~:text=27%20Answers&text=To%20get%20the%20number%20of,floor(time%20%2F%2060)%3B
/**
 * Convert duration into fancy formated time
 * @param duration in seconds
 * @returns formated duration hh:mm:ss
 */
export function fancyTimeFormat(duration: number): string {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
}

/**
 * @note Returns a hash based on file content
 */
export const getFileHash = (file: File): Promise<string> => {
  const namespace = '7d271e27-44d6-474d-a91f-d10456afe57c';
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = async () => {
      /**
       * @note On Android, if a file is selected from Google Drive, result is null.
       */
      if (reader.result === null) return reject('File not found');
      try {
        /**
         * SHA-1 is deprecated for cryptographic secure apps, but it is faster than SHA-256 and should be enough here
         */
        const fileHash = await window.crypto.subtle.digest('SHA-1', reader.result as ArrayBuffer);
        resolve(Array.prototype.map.call(new Uint8Array(fileHash), (x) => ('00' + x.toString(16)).slice(-2)).join(''));
      } catch (e) {
        try {
          // Tmp solution in case window.crypto is not defined (will happen when serving on mobile)
          const uuid = createUuidFrom(reader.result as string, namespace as Uuid);

          resolve(uuid);
        } catch (e) {
          reject(e);
        }
      }
    };

    reader.readAsArrayBuffer(file);

    // reader.onerror = (err) => {
    //   reject(err);
    // };
  });
  // This does not seem to work on mobile
  // const fileBuffer = await file.arrayBuffer();
};

/**
 * Extract identifier and account_id from a Contact based on provider
 * @todo Handle the possibility of having a different channel from the provider default
 * Examples:
 *    On WHATSAPP > we can get and assign a phone number value to the contact, in addition to the default whatsapp identifier
 *    On SIM > we can get and assign email addresses values to the contact, in addition to the default phone number
 */
// export function contactIdentifierAndAccountByProvider(contact: Partial<ViewContact>): { identifier?: string; account_id?: Uuid } {
//   if (contact) {
//     switch (contact.provider) {
//       case 'LINKEDIN':
//       case 'WHATSAPP':
//         if (!contact.social || contact.social.length === 0) return {};
//         return {
//           identifier: contact.social[0].identifier,
//           account_id: contact.social[0].account_id,
//         };
//       case 'SIM':
//         if (!contact.phone || contact.phone.length === 0) return {};
//         return {
//           identifier: contact.phone[0].number,
//         };
//       case 'MAIL':
//         if (!contact.email || contact.email.length === 0) return {};
//         return {
//           identifier: contact.email[0].email,
//         };
//       default:
//         return {};
//     }
//   }
//   return {};
// }

export const base64ToUint8Array = (base64: string) => {
  return new Uint8Array(Array.prototype.slice.call(atob(base64), 0).map((c) => c.charCodeAt(0)));
};

export function commandsToChanges(commands: CommandAccepted<DomainEvent, any>[]): DomainEvent[] {
  return commands.map((command) => command.changes).reduce((all_changes, changes) => [...all_changes, ...changes]);
}

export async function emitMultipleCommandsSeries(commands: CommandAccepted<DomainEvent, any>[], publisher: Publisher) {
  await Promise.all(
    commands.map(async (command) => {
      try {
        await publisher.emit(command.changes);
      } catch (e) {
        // Ignore the error thrown if an aggregate already exist
        if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) return null;

        // Rethrow unexpected errors
        throw e;
      }
    })
  );
}
/**
 * Seperate an array into chunks
 * @example const chunks = chunkArray([1,2,3,4,5], 2) // [[1,2],[3,4],[5]]
 */
export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  return array.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}
// /**
//  * Convert an Imap user to a Local Message User
//  * @param user
//  */
// export function ImapUsertoLocalMessageUser(user: ImapMailUser): LocalMessageUser {
//   return {
//     name: user.name,
//     identifier: user.email,
//   };
// }

// /**
//  * Convert an IMAP email head from a provider to a local item
//  * @param source_mail
//  */
// export function ImapHeadtoLocalMessage(source_mail: ImapMailHead, account_id: number): LocalMessage {
//   return {
//     date: dayjs(source_mail.date).toISOString(),
//     from_person: [ImapUsertoLocalMessageUser(extractImapMailUser(source_mail.from))],
//     to_person: [ImapUsertoLocalMessageUser(extractImapMailUser(source_mail.to))],
//     reply_to: [ImapUsertoLocalMessageUser(extractImapMailUser(source_mail.in_reply_to))],
//     cc_persons: [],
//     bcc_persons: [],
//     uid: source_mail.uid + '',
//     message_id: source_mail.message_id,
//     subject: source_mail.subject,
//     seen: !!source_mail.seen,
//     content_fetched: false,
//     account_id,
//   };
// }

// /**
//  * Convert an IMAP email from a provider to a local item
//  * @param source_mail
//  */
// export function ImapMailToLocalMessage(source_mail: ImapMail, account_id: number): LocalMessage {
//   return {
//     body_html: source_mail.text_html,
//     body_plain: source_mail.text_plain,
//     date: dayjs(source_mail.date).toISOString(),
//     from_person: source_mail.from.map(ImapUsertoLocalMessageUser),
//     to_person: source_mail.to.map(ImapUsertoLocalMessageUser),
//     reply_to: source_mail.reply_to.map(ImapUsertoLocalMessageUser),
//     cc_persons: source_mail.cc.map(ImapUsertoLocalMessageUser),
//     bcc_persons: source_mail.bcc.map(ImapUsertoLocalMessageUser),
//     uid: source_mail.uid + '',
//     message_id: source_mail.message_id,
//     subject: source_mail.subject,
//     content_fetched: true,
//     account_id,
//   };
// }

// export function AccountToLocalAccount(account: Account): LocalAccount {
//   return {
//     connection_params: account.connection_params,
//     creation_date: account.creation_date,
//     id: account.id,
//     last_update_date: account.last_update_date ? dayjs(account.last_update_date).toISOString() : null,
//     last_used_date: account.last_used_date ? dayjs(account.last_used_date).toISOString() : null,
//     name: account.name,
//     type: account.type,
//     user_id: account.user_id,
//     signature: account.signature,
//   };
// }
