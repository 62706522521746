import dayjs from 'dayjs';
import React, { memo, useCallback, useLayoutEffect, useRef } from 'react';

import { DURATION_BETWEEN_IMS_SHOW_DATE, ViewIm, ViewImThread } from '@focus-front/core';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

import ImBubble from './ImBubble';
import { Alert } from '@material-ui/lab';
import { ArrowDropUpOutlined, ArrowUpward, Refresh, Sync } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  threadContainer: {
    // padding: theme.spacing(2),
    flex: 1,
    backgroundColor: theme.palette.background.default,
    height: 0,
    padding: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: 6,
      position: 'absolute',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.default,
      borderRadius: 3,
      transition: '.2s ease',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
    },
    flexDirection: 'column',
    display: 'flex',
    overflowY: 'scroll',
  },
  virtuoso: {},
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    padding: theme.spacing(1),
  },
}));

type Props = {
  ims: ViewIm[];
  thread: ViewImThread;
  showMoreLoading: boolean;
  showMoreError: boolean;
  showMoreUnsupported: boolean;
  showMoreButton: boolean;
  startReached: () => void;
  showMoreClick: () => void;
  retryClick: () => void;
};

function ImThreadBubblesContainer({
  ims,
  thread,
  startReached,
  showMoreLoading,
  showMoreButton,
  showMoreError,
  showMoreUnsupported,
  showMoreClick,
  retryClick,
}: Props) {
  const c = useClasses();
  const { t } = useTranslation();

  // Ref to the container with elements
  const containerRef = useRef<HTMLDivElement>(null);
  const isBottomRef = useRef(null);
  const scrollTopPixels = useRef(null);
  const scrollHeightPixels = useRef(null);
  const lastImId = useRef(null);

  // On scroll, save scroll informations for later usage + detect if user is at the top
  const handleScroll = useCallback(
    (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
      isBottomRef.current = bottom;
      scrollTopPixels.current = e.target.scrollTop;
      scrollHeightPixels.current = e.target.scrollHeight;

      if (e.target.scrollTop < 50) startReached();
    },
    [startReached]
  );

  const scrollToBottom = useCallback(() => {
    const nodeElements = containerRef.current?.querySelectorAll('[data-item]');
    if (nodeElements) {
      // Get last element and scroll soomthly to it
      const el = nodeElements[nodeElements.length - 1];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
        isBottomRef.current = true;
      }
    }
  }, []);

  // Define the scroll behavior to adopt when new ims are loaded (old / new received / new sent)
  useLayoutEffect(() => {
    const scrollHeight = containerRef.current?.scrollHeight;

    const isNewSent = !!(
      lastImId.current &&
      ims.length &&
      ims[ims.length - 1].from_attendee_identifier === 'YOU' &&
      ims[ims.length - 1].id !== lastImId.current
    );

    const isNewReceived = !!(
      lastImId.current &&
      ims.length &&
      ims[ims.length - 1].from_attendee_identifier !== 'YOU' &&
      ims[ims.length - 1].id !== lastImId.current
    );

    const isAtBottom = isBottomRef.current === true;

    /**
     * If the user is already at the bottom, it should stick to the bottom whatever type of message is loaded (old / received / sent)
     * If the user is not at the bottom but did send a message, the view should scroll to the bottom
     */
    if (isNewSent || isAtBottom) scrollToBottom();
    /**
     * If the user is not at the bottom and new ims loaded are old (not received or sent), the scroll should keep the same position
     * which involve this trick. When elements are added at the top, the browser automatically scroll to top
     */ else if (!isNewReceived)
      containerRef.current.scrollTop = scrollTopPixels.current + (scrollHeight - scrollHeightPixels.current);

    lastImId.current = ims.length ? ims[ims.length - 1].id : null;
  }, [ims.length]);

  return (
    <div className={c.threadContainer} ref={containerRef} onScroll={handleScroll}>
      <div className={c.loader}>
        {showMoreLoading && <CircularProgress />}
        {showMoreButton && (
          <Button color="secondary" variant="outlined" onClick={showMoreClick} endIcon={<Sync />}>
            {t('thread.syncMore')}
          </Button>
        )}
        {showMoreError && (
          <Alert
            severity="error"
            action={
              <Button onClick={retryClick}>
                <Refresh />
              </Button>
            }
          >
            {t('thread.syncMoreError')}
          </Alert>
        )}
        {showMoreUnsupported && <Alert severity="warning">{t('thread.syncMoreUnsupported')}</Alert>}
      </div>
      {ims
        // .filter((im) => im.attachments && im.attachments.length && im.attachments.find((att) => att.type == 'VIDEO'))
        .map((im, index) => (
          <ImBubble
            key={im.id}
            index={index}
            im={im}
            attendee={
              thread.attendees.find((att) => att.identifier === im.from_attendee_identifier) || {
                identifier: im.from_attendee_identifier,
                identifier_type: thread.attendees.find((att) => att.display_name === 'YOU').identifier_type,
              }
            }
            self={im.from_attendee_identifier === 'YOU'}
            show_status
            show_date={
              index === 0 ||
              (ims[index] && dayjs(ims[index].date).diff(dayjs(ims[index - 1].date), 'minute') > DURATION_BETWEEN_IMS_SHOW_DATE)
            }
            show_avatar={index === ims.length - 1 || ims[index + 1]?.from_attendee_identifier !== im.from_attendee_identifier}
          />
        ))}
    </div>
  );
}

export default memo(
  ImThreadBubblesContainer
  // (prev, next) =>
  //   prev.ims.length === next.ims.length &&
  //   prev.showMoreLoading === next.showMoreLoading &&
  //   prev.showMoreButton === next.showMoreButton &&
  //   prev.showMoreError === next.showMoreError
);
