import { ApiService } from '../../../User/infra/services/ApiService';
import { BASE64_REGEX } from '../../utils/Base64';
import { WrappedKey } from '../services/crypto/Crypto';
import { KeyTopic, keyTopics } from '../stores/KeyStore';
import { RemoteKeyRepo } from './RemoteKeyRepo';

/**
 * @note There's a big concurrency issue around the shared User.params bag of
 *       properties. Any client AND the backend can add or update properties.
 *
 *       Every modification clobbers the WHOLE bag of properties :
 *         - Any property not explicitely copied will disappear.
 *         - Any property tentatively copied will ovewrite what currently exists.
 *           Which means something you copy to avoid losing may overwrite a
 *           concurrent update.
 *
 *       This bag of properties is used for many things : some innocuous like
 *       tour/onboarding status, some maybe problematic to lose like stripe or
 *       mautic related stuff, some disastrous to lose like wrapped encryption
 *       keys !!!
 *
 *      As a temporary workaround wrapped encryption keys will go through a disused
 *      route ( i.e. /api/device ) until the problem is addressed or a specific
 *      route is created.
 *
 * @note In this workaround old wrapped keys are not overwritten when an update is
 *      pushed. New wrapped keys are added and only the latest per topic is returned
 *      when pulling. This can be used to revert a tester to using a previous key
 *      if something goes wrong until the workaround is no longer needed.
 */
export class ApiKeyRepo implements RemoteKeyRepo {
  /**
   *
   */
  constructor(private readonly user_id: number, private api: ApiService) {}

  /**
   *
   */
  async pull(topic: KeyTopic): Promise<WrappedKey | null> {
    return (await this.pullAll())[topic] ?? null;
  }

  /**
   *
   */
  async pullAll(): Promise<Partial<Record<KeyTopic, WrappedKey>>> {
    const devices = await this.api.getUserDevices(this.user_id);
    return devices.reduce((map, { name }) => {
      try {
        const parsed = JSON.parse(name);

        if (isTopicKeyTuple(parsed)) {
          map[parsed[0]] = parsed[1];
        }
      } catch (error) {
        console.log(
          `ApiKeyRepo ignored "${name}" because it is using /api/device to exchange keys as part of a concurrency issue workaround.`
        );
      }
      return map;
    }, {} as Partial<Record<KeyTopic, WrappedKey>>);
  }

  /**
   *
   */
  async push(topic: KeyTopic, key: WrappedKey) : Promise<WrappedKey> {
    const name = JSON.stringify([topic, key]);

    const { new_id } = await this.api.createDevice({
      name,
      type: 'mobile',
      user_id: this.user_id,
    });

    if (!new_id) {
      throw new Error(`/api/device did not accept : ${name} for user_id ${this.user_id} !`);
    }

    return key;
  }
}

/**
 * @note Checking that u[1] is a valid Base64 string doesn't guarantee that it
 *       actually is a WrappedKey, but an attempt can be made to unwrap it !
 */
export function isTopicKeyTuple(u: unknown): u is [KeyTopic, WrappedKey] {
  return Array.isArray(u) && u.length === 2 && keyTopics.includes(u[0]) && BASE64_REGEX.test(u[1]);
}
