import { IDBPDatabase } from 'idb';
import { Uuid } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { ViewTagRepo, ViewTag, UpdateTagDTO, createViewTag } from '../../domain';

/**
 * Repository.
 *
 */
export class IdbViewTagRepo implements ViewTagRepo {
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  async add(tag: ViewTag): Promise<string> {
    return Idb.put('viewTags', tag);
  }

  async get(id: Uuid): Promise<ViewTag | null> {
    const tag = await Idb.get('viewTags', id);

    return tag || null;
  }

  async update(id: Uuid, values: UpdateTagDTO): Promise<void> {
    const tx = Idb.transaction('viewTags', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewTag ${id} does not exist.`);
    }

    tx.store.put(createViewTag(id, { ...old_projection, ...values }));
    return tx.done;
  }

  async getMany(ids: Uuid[]): Promise<ViewTag[]> {
    const index = Idb.transaction('viewTags', 'readonly', {
      durability: 'relaxed',
    }).store;

    const tags: Promise<ViewTag | undefined>[] = [];

    for (const id of ids) {
      tags.push(index.get(id));
    }

    return (await Promise.all(tags)).filter((tag): tag is ViewTag => tag !== undefined);
  }

  async getAll(): Promise<ViewTag[]> {
    return Idb.getAll('viewTags');
  }

  async clear(): Promise<void> {
    return Idb.clear('viewTags');
  }
}
