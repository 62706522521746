import { IDBPDatabase } from 'idb';
import { Uuid } from '../../../Common';
import { DBSchema, Idb } from '../../../Common/infra/services/idb';
import { MailReference } from '../../domain/projections/MailReference';
import { MailReferenceRepo } from '../../domain/projections/MailReferenceRepo';

/**
 * Repository.
 */
export class IdbMailReferenceRepo implements MailReferenceRepo {
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  async add(projection: MailReference): Promise<string> {
    return Idb.put('viewMails', projection);
  }

  async remove(id: Uuid): Promise<void> {
    return Idb.delete('viewMails', id);
  }

  async removeByAccount(account_id: Uuid): Promise<void> {
    let cursor = await Idb.transaction('viewMails', 'readwrite')
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  async clear(): Promise<void> {
    return Idb.clear('viewMails');
  }

  async get(id: Uuid): Promise<MailReference | null> {
    const mail = await Idb.get('viewMails', id);
    return mail || null;
  }

  async getAll(): Promise<MailReference[]> {
    const mails = await Idb.getAll('viewMails');
    return mails;
  }

  async getAllIds(): Promise<string[]> {
    return Idb.getAllKeys('viewMails');
  }

  async getAllByDate(size: number, offset: number): Promise<MailReference[]> {
    let cursor = await Idb.transaction('viewMails', 'readonly').store.index('by-date').openCursor(undefined, 'prev');
    const results: MailReference[] = [];
    let hasSkipped = false;

    while (results.length < size && cursor) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        cursor = (await cursor?.advance(offset)) ?? null;
      }
      if (!cursor) return results;
      results.push(cursor.value);
      cursor = await cursor.continue();
    }
    return results;
  }

  async getByAccount(account_id: Uuid): Promise<MailReference[]> {
    const mails = await Idb.getAllFromIndex('viewMails', 'by-account', IDBKeyRange.only(account_id));
    return mails;
  }

  async getLatestByAccount(account_id: Uuid): Promise<MailReference | null> {
    const cursor = await Idb.transaction('viewMails', 'readonly')
      .store.index('by-account-and-date')
      .openCursor(IDBKeyRange.bound([account_id, '0'], [account_id, 'Z']), 'prev');
    //                                           ^^^                ^^^
    //                                            |                  |
    // safe values that you know are lexicographically before/after any possible dates.

    return cursor?.value ?? null;
  }
}
