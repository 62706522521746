import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';

import { Environment } from '../../../_container/interfaces/Environment';
import { LoggerService } from './LoggerService';
import pck from '../../../../../../package.json';

import { UserSession } from '../../../User';
import { Severity } from '@sentry/react';

export class SentryService implements LoggerService {
  constructor(environment: Environment) {
    Sentry.init({
      dsn: environment.sentry.dsn,
      release: `unipile@${pck.version}`,
      environment: environment.environment,
      integrations: [
        new Integrations.BrowserTracing(),
        ...(environment.device_type === 'web'
          ? []
          : [
              new RewriteFramesIntegration({
                iteratee: (frame) => {
                  if (!frame.filename) {
                    return frame;
                  }
                  const parts = frame.filename.split('/');
                  frame.filename = 'app:///' + parts[parts.length - 1];
                  return frame;
                },
              }),
            ]),
      ],
      tracesSampleRate: 1.0,
      maxValueLength: 2048,

      autoSessionTracking: true,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  }

  setSession(session: UserSession | null) {
    Sentry.setUser(
      session
        ? {
            email: session.email,
            id: session.id.toString(),
          }
        : null
    );
  }

  async captureException(exception: any): Promise<void> {
    Sentry.captureException(exception);
  }

  captureMessage(message: string): void {
    Sentry.captureMessage(message);
  }

  captureWarning(message: string): void {
    Sentry.captureMessage(message, 'warning');
  }

  sendFeedback(): void {
    // Need to capture either an exception or a message before showing report dialog
    // Otherwise if last event id is not an exception or a message, it will not be registered in Sentry Dashboard
    Sentry.captureMessage('User Feedback');
    Sentry.showReportDialog();
  }

  static createReduxEnhancer(): any {
    return Sentry.createReduxEnhancer();
  }
}
