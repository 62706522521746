import { arrowForwardCircleOutline } from 'ionicons/icons';
import React from 'react';

import { IonIcon } from '@ionic/react';
import { Button, Typography } from '@material-ui/core';

import zen from './zen.svg';

type Props = {
  title: string;
  subtitle: string;
  link?: string;
  link_href?: string;
};

export default function ZenMessage({ title, subtitle, link, link_href }: Props) {
  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <img src={zen} alt="Zen" height="100" id="zen" style={{ marginBottom: 10 }} />
      <Typography variant="h2" align="center">
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        {subtitle}
      </Typography>
      {/* {link && (
        <Button
          color="secondary"
          variant="text"
          style={{ marginTop: 10, textTransform: 'none' }}
          onClick={() => window.open(link_href, '_blank')}
        >
          <IonIcon icon={arrowForwardCircleOutline} style={{ fontSize: 20, marginRight: 5 }}></IonIcon>
          {link}
        </Button>
      )} */}
    </div>
  );
}
