import { loginMessenger, useAppDispatch } from '@focus-front/core';
import { Box, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { MessengerLoginForm, WizardContext } from './WizardContext';

export default function MessengerLogin() {
  const { t } = useTranslation();

  const { goBack, forms, setLoading, setForms, goNext } = useContext(WizardContext);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit } = useForm<MessengerLoginForm>({
    mode: 'onChange',
    defaultValues: forms.MESSENGER_LOGIN,
  });

  const onSubmit = async ({ messenger_user, messenger_password }: MessengerLoginForm) => {
    setError(null);
    setLoading(true);
    setForms({
      ...forms,
      MESSENGER_LOGIN: { messenger_user, messenger_password, messenger_cookies: undefined },
    });

    const { error, result } = await dispatch(loginMessenger(messenger_user, messenger_password));

    if (error) {
      // This error comes from ScrapFormAuthService
      if (error === 'Invalid Credentials') {
        setError(t('wizard.messengerLogin.invalidCredentials'));
      } else {
        setError(error);
      }
      setLoading(false);
    } else {
      if (result) {
        setForms({
          ...forms,
          MESSENGER_LOGIN: { messenger_user, messenger_password, messenger_cookies: result },
        });
      }
      goNext();
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.messengerLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.messengerLogin.subtitle')}</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        <Box mt={3} mb={5} maxWidth={300}>
          <TextField
            inputRef={register({ required: true })}
            name="messenger_user"
            variant="standard"
            label={t('wizard.messengerLogin.userLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            autoComplete="off"
            autoFocus
          />
          <TextField
            inputRef={register({ required: true })}
            name="messenger_password"
            variant="standard"
            label={t('wizard.messengerLogin.passwordLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 8 }}
            autoComplete="new-password"
            type="password"
          />
        </Box>
        <NextButton type="submit" />
      </form>
    </div>
  );
}
