import { Uuid } from '../../../Common';

/**
 * Projection.
 */
export type ViewSignature = Readonly<{
  id: Uuid /** Aggregate id. */;
  name: string;
  body?: string;
}>;

export type NewSignatureDTO = Omit<ViewSignature, 'id'>;
export type UpdateSignatureDTO = Omit<ViewSignature, 'id'>;

/**
 *
 */
export function createViewSignature(aggregateId: Uuid, values: NewSignatureDTO): ViewSignature {
  return { body: '', ...values, id: aggregateId };
}
