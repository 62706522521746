import { Extension } from '@tiptap/core';

interface FontSizeOptions {
  types: string[];
  sizes: number[];
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (size: number) => ReturnType;
    };
  }
}

export const FontSizesArray = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 24, 32, 42];

export const FontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions() {
    return {
      types: ['textStyle'],
      sizes: FontSizesArray,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize: (size: number) => ({ chain }) => {
        return chain()
          .setMark('textStyle', { fontSize: `${size}px` })
          .run();
      },
    };
  },
});
