import { logoGoogle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from 'react-google-login';

import { reconnectAccountSuccess, reconnectGoogleCalendarAccount, Uuid, useAppDispatch, envSelector } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, withStyles, Dialog as D, DialogContent, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import DialogTitle from '../Common/DialogTitle';
import { calendarScopes } from '../Wizard/GoogleCalendarLogin';
import { useSelector } from 'react-redux';
import { NextButton } from '../Wizard/NavButton';
import { useTranslation } from 'react-i18next';

const Dialog = withStyles((theme) => ({
  paper: {
    width: 670,
    padding: 20,
  },
}))(D);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  account_id: Uuid;
}

/**
 *
 */
export default function ReconnectGoogleCalendar(props: Props) {
  const { device_type } = useSelector(envSelector);
  return device_type === 'desktop' ? <DesktopReconnect {...props} /> : <WebReconnect {...props} />;
}

function DesktopReconnect({ isOpen, onClose, account_id }: Props) {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSignIn = async () => {
    const error = await dispatch(reconnectGoogleCalendarAccount(account_id));
    if (error) {
      if (error !== 'cancelled') {
        setError(error);
        // alert(error);
      }
    } else {
      dispatch(reconnectAccountSuccess(account_id));
      onClose();
    }
  };

  return (
    <Dialog maxWidth="lg" open={isOpen}>
      <DialogTitle onClose={onClose}>
        <Typography variant="h2">{t('wizard.googleCalendarLogin.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">{t('wizard.googleCalendarLogin.subtitle')}</Typography>
        <NextButton startIcon={<IonIcon icon={logoGoogle} />} variant="contained" disableElevation onClick={() => onSignIn()}>
          {t('wizard.googleCalendarLogin.loginBtn')}
        </NextButton>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
    </Dialog>
  );
}
/**
 *
 */
function WebReconnect({ isOpen, onClose, account_id }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const responseGoogle = async (response) => {
    if (response.error) {
      setLoading(false);
    }
    if (response.code) {
      const error = await dispatch(reconnectGoogleCalendarAccount(account_id, response.code));
      if (error) {
        setError(error);
        setLoading(false);
      } else {
        dispatch(reconnectAccountSuccess(account_id));
        onClose();
      }
    }
  };

  const { signIn, loaded } = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: responseGoogle,
    clientId: '615134359151-ofgfjeas1g8uumar503fh1vop1cr5eak.apps.googleusercontent.com',
    scope: `${calendarScopes}`,
    accessType: 'offline',
    responseType: 'code',
    prompt: 'consent',
  });

  useEffect(() => {
    if (loaded) setLoading(false);
  }, [loaded]);

  const onSignIn = async () => {
    setLoading(true);
    signIn();
  };

  return (
    <Dialog maxWidth="lg" open={isOpen}>
      <DialogTitle onClose={onClose}>
        <Typography variant="h2">{t('wizard.googleCalendarLogin.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">{t('wizard.googleCalendarLogin.subtitle')}</Typography>
        <NextButton startIcon={<IonIcon icon={logoGoogle} />} variant="contained" disableElevation onClick={() => onSignIn()}>
          {t('wizard.googleCalendarLogin.loginBtn')}
        </NextButton>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
    </Dialog>
  );
}
