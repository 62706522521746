import React, { useState } from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getAttachmentFile, ImAttachment, useAppDispatch, Uuid } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { documentTextOutline, downloadOutline } from 'ionicons/icons';

const useClasses = makeStyles((theme) => ({
  root: {
    width: 400,
    padding: 5,
  },
  desc: {
    height: 70,
    borderRadius: 11,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingLeft: 10,
    paddingRight: 10,
    '& ion-icon': {
      color: theme.palette.secondary.main,
      fontSize: 40,
    },
  },
  text: {
    flex: 1,
    minWidth: 0,
    '& > *': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  download: {
    backgroundColor: 'rgba(36,36,36,0.7)',
    borderRadius: 50,
    width: 50,
    height: 50,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: 10,
    marginRight: 10,
    '& ion-icon': {
      fontSize: 30,
    },
    '& .progress': {
      position: 'absolute',
    },
  },
}));

function ImDocumentAttachment({ account_id, attachment }: { account_id: Uuid; attachment: ImAttachment }) {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    setLoading(true);
    const { error, result } = await dispatch(getAttachmentFile(account_id, attachment));

    if (error) {
      setLoading(false);
      return alert(t('attachment.' + (error === 'UnsupportedDevice' ? 'notSupported' : 'error')));
    }
    if (!result || !result.data) {
      setLoading(false);
      return alert('This file is empty');
    }

    const downloadLink = document.createElement('a');
    downloadLink.href = `data:${attachment.mime}; base64,${result.data}`;
    downloadLink.download = attachment.name;
    downloadLink.click();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className={c.root}>
      <div className={c.desc}>
        <div className={c.icon}>
          <IonIcon icon={documentTextOutline} />
        </div>
        <div className={c.text}>
          <Typography variant="body1">{attachment.name}</Typography>
          <Typography variant="subtitle2">
            {attachment.extension.toUpperCase()} • {Math.ceil(attachment.size / 1000)}kb
          </Typography>
        </div>
        <div className={c.download} onClick={handleDownload}>
          {loading ? <CircularProgress /> : <IonIcon icon={downloadOutline} />}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ImDocumentAttachment);
