export const environment = {
  google: {
    clientId: '615134359151-ofgfjeas1g8uumar503fh1vop1cr5eak.apps.googleusercontent.com',
  },
  proxy: {
    host: 'https://proxy.unipile.com',
    username: 'api_user',
    password: 'kEEP#fOCUS',
  },
  update_server: {
    host: 'http://51.15.211.74:5080',
  },
  api: {
    // host: 'https://localhost:8000',
    host: 'https://core.unipile.com',
  },
  microsoft: {
    clientId: '3eb19f05-8506-4c1f-b3ed-1d0a7449e724',
  },
};
