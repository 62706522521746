export interface DecodedJwt extends UserDTO {
  exp: number;
  iat: number;
  id: number;
}

export interface SuccessResponse {
  success: string;
  code: number;
  new_id?: number;
}

export interface BackupKeyRequestResponse {
  request_id: string;
  token: string;
  user_backup_key: string | null;
}
export interface PasswordResetResponse {
  status: string;
  success: string;
}

export interface ImageUpload {
  filename: string;
  data: string;
}

export interface UserDTO {
  [key: string]: unknown;
}

export interface UserUpdate {
  firstname?: string;
  lastname?: string;
  roles?: [string];
  params?: any;
  profile_picture?: ImageUpload | null;
  language?: string;
}

export interface ApiDomainEvent {
  id: number;
  content: string;
  user_id: number;
  // branch: Uuid; // node ?
  group_id?: number;
  team_id?: number;
  is_sync: boolean;
  //   creation_date: ApiUTCDateTime;
  creation_date: string;
}

export type ApiDomainEventDraft = Omit<ApiDomainEvent, 'id' | 'is_sync' | 'creation_date'>;

export interface Device {
  id: number;
  name: string;
  creation_date: string;
  last_update_date: string;
  last_used_date: string;
  last_sync_date: string;
  user_id: number;
  type: 'web' | 'mobile' | 'laptop' | 'desktop' | 'tablet';
  is_valid: boolean;
}

export interface NewDevice {
  name: string;
  type: 'web' | 'mobile' | 'laptop' | 'desktop' | 'tablet';
  user_id: number;
}

interface NewInvitation {
  user_id: number;

  subject: string;
  message: string;
}

export interface NewMailInvitation extends NewInvitation {
  email: string;
}

export interface NewSmsInvitation extends NewInvitation {
  phone_number: string;
}

export interface Invitation {
  id: number;
  user_id: number;
  email?: string;
  phone_number?: string;
  subject?: string;
  message?: string;
  status?: string;
  creation_date?: string;
  last_update_date?: string;
  is_valid?: true;
}

export class CoreServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CoreServerError';
  }
}
