import { IDBPDatabase } from 'idb';
import { SingleOrArray } from '../../../Common/utils';
import { Uuid } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { createViewCalendar, ExternalCalendar, ViewCalendar } from '../../domain';
import { ViewCalendarRepo } from '../../domain/projections/ViewCalendarRepo';
import { PatchCalendarDTO } from '../../domain/types/CalendarDTO';

/**
 * Repository.
 *
 * @todo Consider using a transaction that takes a collections of items to add,
 *       clear the store, add each item for replays.
 */
export class IdbViewCalendarRepo implements ViewCalendarRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewCalendar): Promise<string> {
    return Idb.put('viewCalendars', projection);
  }

  /**
   * @todo Figure out why typescript doesn't complain about potential type
   *       mismatch when using this signature :
   *
   *         async patch<T extends PatchCalendarDTO>(id: Uuid, values: T)
   */
  async patch(id: Uuid, values: PatchCalendarDTO): Promise<void> {
    const tx = Idb.transaction('viewCalendars', 'readwrite', {
      durability: 'relaxed',
    });
    // const tx = this.idb.transaction('viewCalendars', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewCalendar ${id} does not exist.`);
    }

    /**
     * @todo Figure out how to allow a change of Calendar type and guarantee
     *       that you don't end up with an update type tag and the wrong properties.
     */
    // if (old_projection.type !== values.type) {
    //   throw new Error(`Invalid type : update type ${values.type} doesn't match projection type ${old_projection.type}`);
    // }
    // const u = createViewCalendar(id, { ...old_projection, ...values })

    /** @note await should be unnecessary here. */
    tx.store.put(createViewCalendar(id, { ...old_projection, ...values } as ViewCalendar));
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid): Promise<void> {
    return Idb.delete('viewCalendars', id);
  }

  /**
   *
   */
  async clear(): Promise<void> {
    return Idb.clear('viewCalendars');
  }

  /**
   * Query.
   */
  async get(id: Uuid): Promise<ViewCalendar | null> {
    return (await Idb.get('viewCalendars', id)) ?? null;
  }

  /**
   * Query.
   */
  async getAll(): Promise<ViewCalendar[]> {
    return Idb.getAll('viewCalendars');
  }

  /**
   * Query.
   *
   * @note See https://gist.github.com/pozorfluo/d6fefcb6308bdd8efa4bc9cc8eeb3efe
   *       for alternative ways to do this.
   *
   * @todo Consider adding pagination.
   */
  async getMany(ids: SingleOrArray<Uuid>): Promise<ViewCalendar[]> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    } else {
      ids = [...new Set(ids)];
    }

    const index = Idb.transaction('viewCalendars', 'readwrite', {
      durability: 'relaxed',
    }).store;

    const tasks: Promise<ViewCalendar | undefined>[] = [];

    for (let i = 0, length = ids.length; i < length; ++i) {
      tasks.push(index.get(ids[i]));
    }

    return (await Promise.all(tasks)).filter((calendar): calendar is ViewCalendar => calendar !== undefined);
  }
  /**
   * Query.
   *
   * @note See https://gist.github.com/pozorfluo/d6fefcb6308bdd8efa4bc9cc8eeb3efe
   *       for alternative ways to do this.
   *
   * @todo Consider adding pagination.
   */
  async getByExternalId(account_id: Uuid, external_ids: SingleOrArray<string>): Promise<ViewCalendar[]> {
    if (!Array.isArray(external_ids)) {
      external_ids = [external_ids];
    } else {
      external_ids = [...new Set(external_ids)];
    }

    const index = Idb.transaction('viewCalendars', 'readwrite', {
      durability: 'relaxed',
    }).store.index('by-account-and-external-id');

    const tasks: Promise<ViewCalendar | undefined>[] = [];

    for (let i = 0, length = external_ids.length; i < length; ++i) {
      tasks.push(index.get([account_id, external_ids[i]]));
    }

    return (await Promise.all(tasks)).filter((calendar): calendar is ViewCalendar => calendar !== undefined);
  }

  /**
   * Query.
   */
  async getByAccountId(account_id: Uuid): Promise<ExternalCalendar[]> {
      /** By definition, if a calendar has an account_id it is an ExternalCalendar. */
    return Idb.getAllFromIndex('viewCalendars', 'by-account', IDBKeyRange.only(account_id)) as Promise<ExternalCalendar[]>;
  }
}
