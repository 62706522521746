import { Uuid, useAppDispatch, ViewMessageModel, getAllMessageModels, resetModelFromContent, settingsDialogSelector, setMessageModels, messageModelsSelector, Taggable, setMessageModelId, deleteMessageModel, updateMessageModel, getSortedMessageModels, messageModelIdSelector, MessageModelSortOptions, sortMessageModels } from '@focus-front/core';
import { Button, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SortOptions from '../MessageModel/SortOptions';
import MessageModelAddModal from './MessageModelAddModal';
import MessageModelEditor from './MessageModelEditor';

const useClasses = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  editor: {
    border: `solid 1px ${theme.palette.divider}`,
    height: 300,
    borderRadius: 10,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  listWrapper: {
    minWidth: 180,
    width: 180,
    marginRight: theme.spacing(1),
    flex: 'auto',
    overflowX: 'hidden',
  },
  list: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 10,
    minWidth: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      marginLeft: 10,
    },
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  create?: boolean;
};

export default function MessageModelsPage({ create = false }: Props) {
  const c = useClasses();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const modelId = useSelector(messageModelIdSelector);

  const [showNew, setShowNew] = useState<boolean>(create);

  const messageModels = useSelector(messageModelsSelector);

  const setModels = (models: (ViewMessageModel & Taggable)[]) => {
    dispatch(setMessageModels(models));
  }

  const handleSort = (sortOptions: MessageModelSortOptions) => {
    dispatch(sortMessageModels(sortOptions));
  }

  const handleUpdate = (updated: (ViewMessageModel & Taggable)) => {
    const index = messageModels.findIndex((model) => model.id === updated.id);
    const list = [...messageModels];
    list[index] = updated;
    dispatch(updateMessageModel(updated.id, updated));
    setModels(list);
  };

  const handleDelete = (id: Uuid) => {
    const index = messageModels.findIndex((model) => model.id === id);
    const list = [...messageModels];
    list.splice(index, 1);
    dispatch(deleteMessageModel(id));
    if (list.length) dispatch(setMessageModelId(list[0].id));
    setModels(list);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getSortedMessageModels());
    })();
  }, []);

  useEffect(() => {
    if (messageModels.length && !modelId) dispatch(setMessageModelId( messageModels[0].id));
  }, [messageModels])

  const onCreate = async (model: ViewMessageModel & Taggable) => {
    setModels([...messageModels, model]);
    dispatch(setMessageModelId(model.id));
  };

  const onClose = () => {
    dispatch(resetModelFromContent());
    setShowNew(false);
  }

  if (setModels === null) return null;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={c.sectionTitle}>
          <Typography variant="h2">{t('messageModelsSettings.list.title')}</Typography>
          <Typography variant="subtitle2">{t('messageModelsSettings.list.subtitle')}</Typography>
        </div>
        <Button onClick={() => setShowNew(true)} color="secondary" startIcon={<AddCircle />}>
          {t('messageModelsSettings.list.add')}
        </Button>
      </div>
      {modelId && messageModels.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div className={c.listWrapper}>
            { messageModels.length > 0 && <SortOptions handleSort={handleSort} settings /> }
            <div className={c.list}>
              <List>
                {messageModels.map((model) => (
                  <ListItem key={model.id} divider button selected={model.id === modelId} onClick={() => dispatch(setMessageModelId(model.id))}>
                    <ListItemText primary={model.name} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
          <MessageModelEditor
            defaultValues={messageModels.find((model) => model.id === modelId)}
            id={modelId}
            onUpdated={handleUpdate}
            onDeleted={handleDelete}
            showDelete={messageModels.length > 0}
          />
        </div>
      )}

      <MessageModelAddModal open={showNew} onClose={onClose} onCreate={onCreate} />
    </>
  );
}
