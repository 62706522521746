import { Dialog, Modal } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import ContactView from './ContactView';
import { closeViewContact, contactViewSelector, useAppDispatch } from '@focus-front/core';
import { DialogTitle } from '@focus-front/ui';

export default function ContactViewModal() {
  const contact_id = useSelector(contactViewSelector);
  const dispatch = useAppDispatch();
  return (
    <Dialog open={contact_id !== null} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => dispatch(closeViewContact())} />
      <ContactView contact_id={contact_id} />
    </Dialog>
  );
}
