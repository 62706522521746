import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AccountSourceType,
  AccountType,
  closeWizard,
  envSelector,
  useAppDispatch,
  Uuid,
  wizardSelector,
} from '@focus-front/core';
import { Box, Button, CircularProgress, Dialog as D, DialogProps, IconButton, makeStyles, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import AccountConfiguration from './AccountConfiguration';
import AccountTypeSelection from './AccountTypeSelection';
import ConfigurationSuccess from './ConfigurationSuccess';
import { GoogleMailConfigurationManual1, GoogleMailConfigurationManual2 } from './GoogleMailConfigurationManual';
import { MailHistoryConfig } from './HistoryConfig';
import ImapMailConfiguration from './ImapMailConfiguration';
import { ImapPasswordAol, ImapPasswordGmx, ImapPasswordScreen, ImapPasswordYahoo, ImapPasswordZoho } from './ImapPasswordScreen';
import MicrosoftLogin from './MicrosoftLogin';
import { BackButton } from './NavButton';
import LinkedInLogin from './LinkedInLogin';
import MessengerLogin from './MessengerLogin';
import TwitterLogin from './TwitterLogin';
import TikTokLogin from './TikTokLogin';
import InstagramLogin from './InstagramLogin';

import { ImTutorial1, ImTutorial2, ImTutorial3 } from './Tutorials';
import WhatsAppLogin from './WhatsAppLogin';
import { WizardContext, WizardForms, WizardStep } from './WizardContext';
import GoogleCalendarLogin from './GoogleCalendarLogin';
import TagsConfiguration from './TagsConfiguration';
import GoogleCalendarSync from './GoogleCalendarSync';
import { GoogleMailAutoError, GoogleMailConfigurationAuto } from './GoogleMailConfigurationAuto';
import { ICloudMailAutoError, ICloudMailConfigurationAuto } from './ICloudMailConfigurationAuto';
import { ICloudMailConfigurationManual1, ICloudMailConfigurationManual2 } from './ICloudMailConfigurationManual';

const Dialog = withStyles((theme) => ({
  paper: {
    width: 670,
  },
}))(D);

const useClasses = makeStyles((theme) => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
}));

export function Wizard({ onboarding = false, onEnd, onBack }: { onboarding?: boolean; onEnd: () => void; onBack?: () => void }) {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const { t } = useTranslation();
  const [steps, setSteps] = useState<WizardStep[]>(['ACCOUNT_TYPE_SELECTION', 'ACCOUNT_CONFIG', 'SUCCESS_SCREEN']);
  const [accountType, setAccountType] = useState<AccountType>('GOOGLE');
  const [alias, setAlias] = useState<string>('');
  const [forms, setForms] = useState<WizardForms>({});
  const [accountId, setAccountId] = useState<Uuid | null>(null);
  const [sources, setSources] = useState<{ [key in AccountSourceType]: boolean }>({
    CALENDAR: false,
    CALLS: false,
    CONTACTS: false,
    IMS: false,
    MAILS: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { device_type } = useSelector(envSelector);

  const c = useClasses();

  const goNext = () => {
    setLoading(false);
    if (stepIndex + 1 === steps.length) setStepIndex(0);
    else setStepIndex(stepIndex + 1);
  };

  const goBack = () => {
    setStepIndex(stepIndex - 1);
  };

  const goToStep = (step: WizardStep) => {
    const i = steps.findIndex((s) => s === step);
    if (i >= 0) setStepIndex(i);
  };

  useEffect(() => {
    const steps: WizardStep[] = ['ACCOUNT_TYPE_SELECTION', 'ACCOUNT_CONFIG'];
    if (sources?.MAILS) {
      if (accountType === 'MAIL') steps.push('IMAP_MAIL_1', 'MAIL_HISTORY');
      if (accountType === 'GOOGLE') {
        if (device_type === 'web') steps.push('GOOGLE_MAIL_MANUAL_1', 'GOOGLE_MAIL_MANUAL_2', 'MAIL_HISTORY');
        else steps.push('GOOGLE_MAIL_AUTO', 'MAIL_HISTORY');
      }
      if (accountType === 'ICLOUD') {
        if (device_type === 'web') steps.push('IMAP_MAIL_1', 'MAIL_HISTORY');
        else steps.push('ICLOUD_MAIL_AUTO', 'MAIL_HISTORY');
      }
      if (accountType === 'OUTLOOK') steps.push('MICROSOFT_LOGIN', 'MAIL_HISTORY');
    }
    if (accountType === 'LINKEDIN') steps.push('LINKEDIN_LOGIN');
    if (accountType === 'MESSENGER') steps.push('MESSENGER_LOGIN');
    if (accountType === 'TIKTOK') steps.push('TIKTOK_LOGIN');
    if (accountType === 'INSTAGRAM') steps.push('INSTAGRAM_LOGIN');
    if (accountType === 'TWITTER') steps.push('TWITTER_LOGIN');

    if (accountType === 'WHATSAPP') steps.push('WHATSAPP_LOGIN');
    if (accountType === 'GOOGLE_CALENDAR') steps.push('GOOGLE_CALENDAR_LOGIN', 'GOOGLE_CALENDAR_SYNC');
    steps.push('TAGS_CONFIG');
    // if (accountType === 'GOOGLE_CALENDAR') steps.push('GOOGLE_CALENDAR_SYNC');
    steps.push('SUCCESS_SCREEN');
    if (sources?.IMS) {
      steps.push('IM_TUTORIAL_1', 'IM_TUTORIAL_2', 'IM_TUTORIAL_3');
    }
    setSteps(steps);
  }, [sources, accountType]);

  return (
    <WizardContext.Provider
      value={{
        accountType,
        sources,
        setAccountType,
        setSources,
        goBack,
        goNext,
        goToStep,
        setLoading,
        setAlias,
        alias,
        forms,
        setForms,
        setSteps,
        steps,
        accountId,
        setAccountId,
      }}
    >
      {!onboarding && steps[stepIndex] !== 'SUCCESS_SCREEN' && (
        <IconButton size="small" style={{ position: 'absolute', top: 16, right: 16 }} onClick={onEnd}>
          <Close />
        </IconButton>
      )}

      {loading && (
        <div className={c.loader}>
          <CircularProgress />
        </div>
      )}

      <Box p={2} style={{ backgroundColor: 'white' }}>
        {steps[stepIndex] === 'ACCOUNT_TYPE_SELECTION' && (
          <>
            {onboarding && <BackButton onClick={onBack} />}
            <AccountTypeSelection /> {onboarding && <Button onClick={onEnd}>{t('wizard.accountSelection.laterButton')}</Button>}
          </>
        )}
        {steps[stepIndex] === 'ACCOUNT_CONFIG' && <AccountConfiguration />}
        {steps[stepIndex] === 'IMAP_MAIL_1' && <ImapMailConfiguration />}
        {steps[stepIndex] === 'IMAP_PASSWORD_AOL' && <ImapPasswordAol />}
        {steps[stepIndex] === 'IMAP_PASSWORD_YAHOO' && <ImapPasswordYahoo />}
        {steps[stepIndex] === 'IMAP_PASSWORD_GMX' && <ImapPasswordGmx />}
        {steps[stepIndex] === 'IMAP_PASSWORD_ZOHO' && <ImapPasswordZoho />}
        {steps[stepIndex] === 'IMAP_PASSWORD' && <ImapPasswordScreen />}
        {steps[stepIndex] === 'MICROSOFT_LOGIN' && <MicrosoftLogin />}
        {steps[stepIndex] === 'GOOGLE_CALENDAR_LOGIN' && <GoogleCalendarLogin />}
        {steps[stepIndex] === 'GOOGLE_CALENDAR_SYNC' && <GoogleCalendarSync />}
        {steps[stepIndex] === 'GOOGLE_MAIL_MANUAL_1' && <GoogleMailConfigurationManual1 />}
        {steps[stepIndex] === 'GOOGLE_MAIL_MANUAL_2' && <GoogleMailConfigurationManual2 />}
        {steps[stepIndex] === 'GOOGLE_MAIL_AUTO' && <GoogleMailConfigurationAuto />}
        {steps[stepIndex] === 'GOOGLE_MAIL_AUTO_ERROR' && <GoogleMailAutoError />}
        {steps[stepIndex] === 'ICLOUD_MAIL_MANUAL_1' && <ICloudMailConfigurationManual1 />}
        {steps[stepIndex] === 'ICLOUD_MAIL_MANUAL_2' && <ICloudMailConfigurationManual2 />}
        {steps[stepIndex] === 'ICLOUD_MAIL_AUTO' && <ICloudMailConfigurationAuto />}
        {steps[stepIndex] === 'ICLOUD_MAIL_AUTO_ERROR' && <ICloudMailAutoError />}
        {steps[stepIndex] === 'LINKEDIN_LOGIN' && <LinkedInLogin />}
        {steps[stepIndex] === 'MESSENGER_LOGIN' && <MessengerLogin />}
        {steps[stepIndex] === 'TIKTOK_LOGIN' && <TikTokLogin />}
        {steps[stepIndex] === 'TWITTER_LOGIN' && <TwitterLogin />}
        {steps[stepIndex] === 'INSTAGRAM_LOGIN' && <InstagramLogin />}

        {steps[stepIndex] === 'WHATSAPP_LOGIN' && <WhatsAppLogin />}
        {steps[stepIndex] === 'MAIL_HISTORY' && <MailHistoryConfig />}
        {steps[stepIndex] === 'TAGS_CONFIG' && <TagsConfiguration />}
        {steps[stepIndex] === 'SUCCESS_SCREEN' && <ConfigurationSuccess onEnd={sources?.IMS ? undefined : onEnd} />}
        {steps[stepIndex] === 'IM_TUTORIAL_1' && <ImTutorial1 />}
        {steps[stepIndex] === 'IM_TUTORIAL_2' && <ImTutorial2 />}
        {steps[stepIndex] === 'IM_TUTORIAL_3' && <ImTutorial3 onEnd={onEnd} />}
      </Box>
    </WizardContext.Provider>
  );
}

export function WizardDialog() {
  const open = useSelector(wizardSelector);
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeWizard());
  };
  return (
    <Dialog maxWidth="lg" open={open}>
      <Wizard onEnd={onClose} />
    </Dialog>
  );
}
