import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { inboxNotificationsSelector, notificationsStatusSelector, totalNotificationsSelector } from '@focus-front/core';

import ZenMessage from '../Common/ZenMessage';
import NotificationCard from './NotificationCard';
import NotificationCardFake from './NotificationCardFake';
import InboxSnackbar from './InboxSnackbar';
import InboxFiltersInfos from './InboxFiltersInfos';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    '&::-webkit-scrollbar': {
      width: 6,
      position: 'absolute',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 3,
    },
  },
  fakerContainer: {
    paddingRight: theme.spacing(2),
  },
}));

function InboxList() {
  const notifications = useSelector(inboxNotificationsSelector);
  const totalNotifications = useSelector(totalNotificationsSelector);
  const { t } = useTranslation();
  const classes = useStyles();
  const notitications_status = useSelector(notificationsStatusSelector);

  const selectMode = useMemo(() => {
    return notifications.filter((n) => n.selected).length > 0;
  }, [notifications]);

  const computeItemKey = useCallback(
    (index) => {
      return notifications[index].id;
    },
    [notifications]
  );

  console.log(notifications);

  if (totalNotifications === 0 && notitications_status === 'IDLE')
    return (
      <ZenMessage
        title={t('inbox.empty.title')}
        subtitle={t('inbox.empty.subtitle')}
        link={t('inbox.empty.link')}
        link_href="unipile.com"
      />
    );

  if (notitications_status === 'FETCHING')
    return (
      <div className={classes.fakerContainer}>
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
        <NotificationCardFake />
      </div>
    );

  return (
    <div style={{ height: '100%', borderRight: 'solid 2px #fff', position: 'relative' }}>
      <Virtuoso
        style={{ height: '100%' }}
        totalCount={notifications.length}
        overscan={{ main: 1500, reverse: 1500 }}
        fixedItemHeight={84}
        className={classes.container}
        computeItemKey={computeItemKey}
        itemContent={(index) => (
          <div key={index} style={{ margin: '0 16px 8px 0' }}>
            <NotificationCard selectMode={selectMode} notification={notifications[index]} />
          </div>
        )}
        components={{ Header: () => <InboxFiltersInfos /> }}
      />
      <InboxSnackbar />
    </div>
  );
}

export default React.memo(InboxList);
