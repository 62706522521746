import { AppDispatch, AppGetState } from '../../_container/store';
import Container from '../../_container/interfaces/Container';
import { Uuid, Result } from '../../Common';
import { ViewTag, NewTagDTO, TaggableElement } from '../domain';
import { ViewNotification, getNotifications } from '../../Notification';
import { loadAccounts } from '../../Account';
import { loadWorkEvents } from '../../Calendar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getAllMessageModels } from '../../MessageModel';

interface TagsState {
  entities: ViewTag[];
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    entities: [],
  } as TagsState,
  reducers: {
    loadAllTags: (state, { payload }: PayloadAction<ViewTag[]>) => {
      state.entities = payload;
    },
    addTag: (state, { payload }: PayloadAction<ViewTag>) => {
      state.entities.push(payload);
    },
  },
});

export const { loadAllTags, addTag } = tagsSlice.actions;

// Bridge Thunk
export const getNotificationTags =
  (notification: ViewNotification) =>
  async (dispatch: AppDispatch, getState: AppGetState, { tag }: Container): Promise<Result<ViewTag[]>> => {
    return tag.getNotificationTags(notification);
  };

// Bridge Thunk
export const getAllTags =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { tag }: Container): Promise<Result<ViewTag[]>> => {
    const { result, error } = await tag.getAllTags();
    if (result) dispatch(loadAllTags(result));
    return { result, error };
  };

// Bridge Thunk
export const createTag =
  (values: NewTagDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { tag }: Container): Promise<Result<ViewTag>> => {
    const { result, error } = await tag.createTag(values);
    if (error) return { error };
    if (result) dispatch(addTag(result));
    return { result };
  };

// Bridge Thunk
export const updateElementTags =
  (element: TaggableElement, element_id: Uuid, tags: ViewTag[]) =>
  async (dispatch: AppDispatch, getState: AppGetState, { tag }: Container): Promise<Result<void>> => {
    const { result, error } = await tag.updateElementTags(element, element_id, tags);
    if (error) return { error };
    if (element === 'CALL' || element === 'EVENT' || element === 'IM_THREAD' || element === 'MAIL') dispatch(getNotifications());
    if (element === 'MESSAGE_MODEL') dispatch(getAllMessageModels());
    if (element === 'ACCOUNT') dispatch(loadAccounts());
    if (element === 'EVENT') dispatch(loadWorkEvents());
    return { result };
  };

export const tagsSelector = (state: { tags: TagsState }): ViewTag[] => {
  return state.tags.entities
    .slice()
    .sort((a, b) => dayjs(b.last_assign_date || 0).unix() - dayjs(a.last_assign_date || 0).unix());
};

export default tagsSlice.reducer;
