import { testMailConnection, useAppDispatch } from '@focus-front/core';
import { Box, FormControl, Link, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { UnfoldMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { ICloudMailConfigForm, ICLOUD_IMAP_CONFIG_DEFAULTS, WizardContext } from './WizardContext';

export function ICloudMailConfigurationManual1() {
  const { t } = useTranslation();

  const { goBack, goNext } = useContext(WizardContext);

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.icloudMailConfig.title1')}</Typography>
      <Typography variant="subtitle2">{t('wizard.icloudMailConfig.subtitle1')}</Typography>
      <Box mt={3} mb={5}>
        <Typography variant="body1">
          <Trans i18nKey="wizard.imapPassword.help.icloud.content">
            <ul>
              <li>
                Sign in to your Apple account using your Apple ID:{' '}
                <Link href="https://appleid.apple.com/" target="_blank">
                  Sign in
                </Link>
              </li>
              <li>Connectez-vous à votre compte si vous n’êtes pas connecté.</li>
              <li>
                Go to Account Security section and check two-factor authentication is activated. If not, please turn on to
                continue.
              </li>
              <li>
                Then go to App-specific Passwords section and click on “Generate an app-specific password”. Type Unipile and copy
                the app password generated. Copy or type it in toi the Password field below.
              </li>
            </ul>
          </Trans>
        </Typography>
      </Box>
      <NextButton onClick={goNext} />
    </div>
  );
}

export function ICloudMailConfigurationManual2() {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  const { goBack, forms, setLoading, setForms, setAlias, goNext } = useContext(WizardContext);
  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm<ICloudMailConfigForm>({
    mode: 'onChange',
    defaultValues: forms.ICLOUD_MAIL,
  });

  const onSubmit = async (values: ICloudMailConfigForm) => {
    setForms({ ...forms, ICLOUD_MAIL: values });
    setAlias(values.email);
    setLoading(true);
    const success = await dispatch(
      testMailConnection(
        { type: 'MAIL', imap_pwd: values.password, smtp_pwd: values.password },
        {
          ...ICLOUD_IMAP_CONFIG_DEFAULTS,
          imap_user: values.email,
          smtp_user: values.email,
        }
      )
    );
    if (!success) {
      setError(t('wizard.imapMailConfig.connexionFailError'));
      setLoading(false);
    } else {
      goNext();
      setError('');
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.icloudMailConfig.title2')}</Typography>
      <Typography variant="subtitle2">{t('wizard.icloudMailConfig.subtitle2')}</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        <Box mt={3} mb={5} maxWidth={300}>
          <TextField
            inputRef={register({ required: true })}
            name="email"
            variant="standard"
            label={t('wizard.icloudMailConfig.mailLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="mail"
            autoComplete="off"
            autoFocus
          />
          <TextField
            inputRef={register({ required: true })}
            name="password"
            variant="standard"
            label={t('wizard.icloudMailConfig.passwordLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 8 }}
            autoComplete="new-password"
            type="password"
          />
        </Box>
        <NextButton type="submit" />
      </form>
    </div>
  );
}
