import { IDBPDatabase } from 'idb';

import { Uuid } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { MailDraftType, UpdateMailDraftDTO, ViewMailDraft } from '../../domain';
import { ViewMailDraftRepo } from '../../domain/models/ViewMailDraftRepo';

/**
 * Repository.
 */
export class IdbViewMailDraftRepo implements ViewMailDraftRepo {
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  async add(projection: ViewMailDraft): Promise<string> {
    return Idb.put('viewMailDrafts', projection);
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateMailDraftDTO): Promise<void> {
    const tx = Idb.transaction('viewMailDrafts', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewMailDraft ${id} does not exist.`);
    }

    tx.store.put({ ...old_projection, ...values });
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid): Promise<void> {
    return Idb.delete('viewMailDrafts', id);
  }

  /**
   *
   */
  async clear(): Promise<void> {
    return Idb.clear('viewMailDrafts');
  }

  /**
   * Query.
   */
  async get(id: Uuid): Promise<ViewMailDraft | null> {
    return (await Idb.get('viewMailDrafts', id)) ?? null;
  }

  /**
   * Query.
   */
  async getAll(): Promise<ViewMailDraft[]> {
    return Idb.getAll('viewMailDrafts');
  }

  /**
   * Query.
   */
  async getAllByDate(size: number, offset: number): Promise<ViewMailDraft[]> {
    let cursor = await Idb.transaction('viewMailDrafts', 'readonly').store.index('by-date').openCursor(null, 'prev');
    const results: ViewMailDraft[] = [];
    let hasSkipped = false;

    while (results.length < size) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        if (cursor) cursor = await cursor.advance(offset);
      }
      if (!cursor) return results;
      results.push(cursor.value);
      cursor = await cursor.continue();
    }
    return results;
  }

  /**
   * Query.
   */
  async getByAccount(account_id: Uuid): Promise<ViewMailDraft[]> {
    return Idb.getAllFromIndex('viewMailDrafts', 'by-account', IDBKeyRange.only(account_id));
  }

  /**
   * Query.
   */
  async getByParentAndType(parent_mail_id: Uuid, type: MailDraftType): Promise<ViewMailDraft | null> {
    const tx = Idb.transaction('viewMailDrafts', 'readonly');
    return (await tx.store.index('by-parent-and-type').get(IDBKeyRange.only([parent_mail_id, type]))) ?? null;
  }
}
