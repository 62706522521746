import { ViewCallRepo } from '../../../Call';
import { MailReferenceRepo } from '../../../Mail';
import { createProjector } from '../../../Common';
import { createViewAccount, isMailSupportedAccount } from '../projections/ViewAccount';
import { ViewAccountRepo } from '../projections/ViewAccountRepo';

import { ViewImRepo } from '../../../Im';
import { ViewImThreadRepo } from '../../../Im/domain/projections/ViewImThreadRepo';
import { ViewNotificationRepo } from '../../../Notification';
import { ViewCalendarEventRepo } from '../../../Calendar/domain/projections/ViewCalendarEventRepo';
import { ViewContactRepo } from '../../../Contact';

interface viewAccountsProjectorRepos {
  viewAccount: ViewAccountRepo;
  viewMail: MailReferenceRepo;
  viewCall: ViewCallRepo;
  viewIm: ViewImRepo;
  viewImThread: ViewImThreadRepo;
  viewNotification: ViewNotificationRepo;
  viewCalendarEvent: ViewCalendarEventRepo;
  viewContact: ViewContactRepo;
}
/**
 * Handler/Projector.
 */
export const viewAccountsProjector = createProjector('viewAccountsProjector', {
  ACCOUNT_CREATED: ({ aggregateId, account }, { viewAccount }: viewAccountsProjectorRepos) => {
    return viewAccount.add(createViewAccount(aggregateId, account));
  },
  /**
   * @note The only reason that this "works" right now is because viewAccountsProjector is
   *       listed after all other aggregate type involved in this handler in projectionistConfig.
   *
   *       At the time of writing, when playing/replaying events the Projectionist processes
   *       each projector sequentially, in the order of Object.keys(projectionistConfig.projectors).
   *
   *       Object.keys is supposed to be required to follow this order :
   *
   *         (1) Numeric array keys
   *
   *         (2) non-Symbol keys, in insertion order
   *
   *         (3) Symbol keys, in insertion order
   *
   *       So, it works by sheer luck.
   *
   *       This is SUPER FLIMSY.
   *
   *       It won't work anymore as soon as :
   *         - a new viewSomething projector is added after viewAccounts in projectionistConfig
   *         - you try to viewSomething.remove(aggregateId) in this handler.
   *
   *       There won't be any error, but nothing will get removed. Because there won't be
   *       anything to remove when this event is handled by viewAccountsProjector.
   *       Later in the replay, viewSomethingProjector will handle all events it cares
   *       about. And every 'something' that was supposed to be deleted will show up
   *       in idb stores and the app.
   *
   *       See https://docs.google.com/document/d/1tJwrwMva5rItt0j74vQwqhGm4rozsyNbnF_-xQ5JSlA/edit#heading=h.24sqlqdimms
   *       for further explanation, examples and ways to work around this.
   *
   *       Projectionist play/replay/catchup is going to be reworked to enable this kind ( but not all kinds of )
   *       cross-aggregate references.
   */
  ACCOUNT_DELETED: async (
    { aggregateId },
    {
      viewAccount,
      viewMail,
      viewNotification,
      viewCall,
      viewIm,
      viewImThread,
      viewCalendarEvent,
      viewContact,
    }: viewAccountsProjectorRepos
  ) => {
    return Promise.all([
      viewMail.removeByAccount(aggregateId),
      viewNotification.removeByAccount(aggregateId),
      viewIm.removeByAccount(aggregateId),
      viewImThread.removeByAccount(aggregateId),
      viewCall.removeByAccount(aggregateId),
      /** @todo Fetch Calendars by account, for each calendar delete calendar events. */
      //   viewCalendarEvent.removeByAccount(aggregateId),
      viewContact.removeByAccount(aggregateId),
      viewAccount.remove(aggregateId),
    ]);
  },
  ACCOUNT_EDITED: async ({ aggregateId, values }, { viewAccount }: viewAccountsProjectorRepos) => {
    return viewAccount.update(aggregateId, values);
  },
  ACCOUNT_FETCHED: async ({ aggregateId, last_fetched_at }, { viewAccount }: viewAccountsProjectorRepos) => {
    return viewAccount.update(aggregateId, { last_fetched_at });
  },
  ACCOUNT_SYNCED: async ({ aggregateId, sync_token }, { viewAccount }: viewAccountsProjectorRepos) => {
    return viewAccount.update(aggregateId, { sync_token });
  },
  ACCOUNT_SIGNATURE_SELECTED: async ({ aggregateId, signature_id }, { viewAccount }: viewAccountsProjectorRepos) => {
    return viewAccount.update(aggregateId, { current_signature: signature_id });
  },
  ACCOUNT_FF_START: async ({ aggregateId, mailboxes }, { viewAccount }: viewAccountsProjectorRepos) => {
    return viewAccount.update(aggregateId, {
      full_fetch_progress: mailboxes.map((mb) => ({ done: false, mailbox: mb.mailbox, offset: 0, total: mb.total })),
      full_fetch_status: 'FETCHING',
    });
  },
  ACCOUNT_FF_PROGRESS_SET: async ({ aggregateId, done, mailbox, offset }, { viewAccount }: viewAccountsProjectorRepos) => {
    const acc = await viewAccount.get(aggregateId);
    if (acc && isMailSupportedAccount(acc)) {
      const index = acc.full_fetch_progress.findIndex((mb) => mb.mailbox === mailbox);
      if (index > -1) {
        acc.full_fetch_progress[index].done = done;
        acc.full_fetch_progress[index].offset = offset;

        const all_done = acc.full_fetch_progress.filter((mb) => mb.done).length === acc.full_fetch_progress.length;

        if (all_done)
          return viewAccount.update(aggregateId, {
            full_fetch_progress: [],
            full_fetch_status: 'DONE',
          });
        else
          return viewAccount.update(aggregateId, {
            full_fetch_progress: acc.full_fetch_progress,
          });
      }
    }
  },
  // ACCOUNT_FF_COMPLETED: async ({ aggregateId }, { viewAccount }: viewAccountsProjectorRepos) => {
  //   return viewAccount.update(aggregateId, {
  //     full_fetch_progress: [],
  //     full_fetch_status: 'DONE',
  //   });
  // },
});
