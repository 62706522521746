import { Base64 } from '../../../utils/Base64';
import {
  DerivedCryptoKey,
  EncryptionAndWrappingUsages,
  EncryptionCryptoKey,
  EncryptionUsages,
  ExportedRawKey,
  GeneratedCryptoKey,
  KeyMaterial,
  WrappedKey,
  WrappingCryptoKey,
  WrappingUsages,
} from './Crypto';

// const getRandomValues = window.crypto.getRandomValues;

const IV_LENGTH = 12;
const SALT_LENGTH = 16;
const ITERATIONS = 250000;
const ALGO = 'AES-GCM';

/** @note Part of the stop-gap solution until SRP is implemented. */
const STOPGAP_PREFIX = '__UNIPILE_APP_';

export interface DerivedKeyOptions {
  salt?: Uint8Array;
  iterations?: number;
  extractable?: boolean;
  algo?: 'AES-GCM' | 'AES-KW';
  keyUsages?: KeyUsage[];
}

const encoder = new TextEncoder();
const decoder = new TextDecoder();

const CHUNK_SIZE = 1 << 14;

/**
 * @note Work in chunks to handle possible large inputs.
 */
export function uint8ArrayToString(bytes: Uint8Array): string {
  const chunks = [];
  const length = bytes.byteLength;

  for (let i = 0; i < length; i += CHUNK_SIZE) {
    // @ts-expect-error: Argument of type 'Uint8Array' is not assignable to parameter of type 'number[]'.
    // eslint-disable-next-line prefer-spread
    chunks.push(String.fromCharCode.apply(String, bytes.subarray(i, i + CHUNK_SIZE)));
  }
  return chunks.join('');
}

/**
 * Ad-hoc utility type to narrow down on key type by key usages.
 */
type KeyByUsage<TKeyUsage extends KeyUsage[]> = TKeyUsage extends EncryptionAndWrappingUsages
  ? EncryptionCryptoKey & WrappingCryptoKey
  : TKeyUsage extends WrappingUsages
  ? WrappingCryptoKey
  : TKeyUsage extends EncryptionUsages
  ? EncryptionCryptoKey
  : GeneratedCryptoKey;

/**
 * @note Methods are kept WET on purpose.
 */
export const WebCrypto = {
  /**
   *
   */
  isAvailable(): boolean {
    return (
      //   typeof window !== 'undefined' &&
      //   window.crypto &&
      //   window.crypto.subtle &&
      //   typeof window.crypto.getRandomValues === 'function'
      !!window?.crypto?.subtle && typeof window.crypto.getRandomValues === 'function'
    );
  },

  /**
   * @note Used to hash password client-side to avoid sending it in plaintext to API Core.
   *       API Core still consider whatever it receives as plaintext and hashes it.
   *       Part of the stop-gap solution until SRP is implemented.
   *
   * @todo Consider replacing key derivation with digest/hash until moving to SRP.
   *       From what I gathered, it should be ok to derive two key from the same
   *       password for this stopgap, but it's probably better to not risk compromising
   *       the main user key, which is wrapped with a key derived from the password,
   *       for this stopgap.
   *       See https://crypto.stackexchange.com/questions/25318/is-it-safe-to-derive-two-different-keys-with-the-same-password-and-key-derivatio
   *           https://crypto.stackexchange.com/questions/37252/are-there-key-derivation-functions-that-are-safe-to-use-in-parallel-on-the-same
   *
   */
  async getStopGapPasswordHash(password: string, username: string): Promise<Base64> {
    const [key] = await WebCrypto.deriveKeyFrom(password, {
      salt: encoder.encode(STOPGAP_PREFIX + username),
      extractable: true,
    });

    const exported = new Uint8Array(await window.crypto.subtle.exportKey('raw', key));

    return btoa(uint8ArrayToString(exported)) as Base64;
  },

  /**
   *
   */
  async getKeyMaterial(password: string): Promise<KeyMaterial> {
    // console.log(WebCrypto.isAvailable());
    return window.crypto.subtle.importKey('raw', encoder.encode(password), 'PBKDF2', false, [
      'deriveKey',
    ]) as Promise<KeyMaterial>;
  },

  /**
   *
   */
  async deriveKeyFrom(
    password: string,
    {
      salt = window.crypto.getRandomValues(new Uint8Array(SALT_LENGTH)),
      iterations = ITERATIONS,
      extractable = false,
      algo = ALGO,
      keyUsages = ['encrypt', 'decrypt'],
    } = {} as DerivedKeyOptions
  ): Promise<[DerivedCryptoKey, Uint8Array]> {
    const keyMaterial = await WebCrypto.getKeyMaterial(password);

    const key = await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations,
        hash: 'SHA-256',
      },
      keyMaterial,
      { name: algo, length: 256 },
      extractable,
      keyUsages
    );

    return [key as DerivedCryptoKey, salt];
  },

  /**
   *
   */
  async generateKey<TKeyUsage extends KeyUsage[]>(
    keyUsages: TKeyUsage,
    algo = ALGO,
    extractable = true
  ): Promise<KeyByUsage<TKeyUsage>> {
    return window.crypto.subtle.generateKey(
      {
        name: algo,
        length: 256,
      },
      extractable,
      keyUsages
    ) as Promise<KeyByUsage<TKeyUsage>>;
  },

  /**
   *
   */
  async generateEncryptionAndWrappingKey(algo = ALGO, extractable = true): Promise<EncryptionCryptoKey & WrappingCryptoKey> {
    return WebCrypto.generateKey<EncryptionAndWrappingUsages>(['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'], algo, extractable);
  },

  /**
   *
   */
  async generateEncryptionKey(algo = ALGO, extractable = true): Promise<EncryptionCryptoKey> {
    return WebCrypto.generateKey<EncryptionUsages>(['encrypt', 'decrypt'], algo, extractable);
  },

  /**
   *
   */
  async generateWrappingKey(algo = ALGO, extractable = true): Promise<WrappingCryptoKey> {
    return WebCrypto.generateKey<WrappingUsages>(['wrapKey', 'unwrapKey'], algo, extractable);
  },

  /**
   * Wrap given key using a wrapping key derived from given password.
   *
   * @note As a DX convenience to allow live reload when serving mobile, AES-GCM
   *       is used instead of the apparently preferable AES-KW for key wrapping purposes.
   *       When/if this is reverted back to using AES-KW, a one time 'migration'
   *       function will have to be shipped along to do the conversion client-side.
   */
  async wrap(password: string, key: GeneratedCryptoKey): Promise<WrappedKey> {
    const [wrappingKey, salt] = await WebCrypto.deriveKeyFrom(password, {
      algo: 'AES-GCM',
      keyUsages: ['wrapKey'],
    });

    const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));

    const wrapped = new Uint8Array(await window.crypto.subtle.wrapKey('raw', key, wrappingKey, { name: 'AES-GCM', iv }));

    const prependedWithSaltAndIv = new Uint8Array(SALT_LENGTH + IV_LENGTH + wrapped.byteLength);
    prependedWithSaltAndIv.set(salt, 0);
    prependedWithSaltAndIv.set(iv, SALT_LENGTH);
    prependedWithSaltAndIv.set(wrapped, SALT_LENGTH + IV_LENGTH);

    return btoa(uint8ArrayToString(prependedWithSaltAndIv)) as WrappedKey;
  },
  // /**
  //  * Wrap given key using a wrapping key derived from given password.
  //  *
  //  * @note AES-KW version.
  //  */
  // async wrap(password: string, key: GeneratedCryptoKey): Promise<WrappedKey> {
  //   const [wrappingKey, salt] = await WebCrypto.deriveKeyFrom(password, {
  //     algo: 'AES-KW',
  //     keyUsages: ['wrapKey'],
  //   });

  //   const wrapped = new Uint8Array(
  //     await window.crypto.subtle.wrapKey('raw', key, wrappingKey, 'AES-KW')
  //   );

  //   const prependedWithSalt = new Uint8Array(SALT_LENGTH + wrapped.byteLength);
  //   prependedWithSalt.set(salt, 0);
  //   prependedWithSalt.set(wrapped, SALT_LENGTH);

  //   return btoa(
  //       uint8ArrayToString(prependedWithSalt)
  //   ) as WrappedKey;
  // },

  /**
   * Unwrap given wrapped key using a unwrapping key derived from given password.
   *
   * @note As a DX convenience to allow live reload when serving mobile, AES-GCM
   *       is used instead of the apparently preferable AES-KW for key wrapping purposes.
   *       When/if this is reverted back to using AES-KW, a one time 'migration'
   *       function will have to be shipped along to do the conversion client-side.
   */
  async unwrap<TKeyUsage extends KeyUsage[]>(
    password: string,
    wrappedKey: WrappedKey,
    keyUsages: TKeyUsage
  ): Promise<KeyByUsage<TKeyUsage>> {
    const data = atob(wrappedKey);
    const prependedWithSaltAndIv = new Uint8Array(data.length);

    for (let i = 0, length = data.length; i < length; ++i) {
      prependedWithSaltAndIv[i] = data.charCodeAt(i);
    }

    const salt = prependedWithSaltAndIv.subarray(0, SALT_LENGTH);
    const iv = prependedWithSaltAndIv.subarray(SALT_LENGTH, SALT_LENGTH + IV_LENGTH);
    const wrapped = prependedWithSaltAndIv.subarray(SALT_LENGTH + IV_LENGTH);

    const [key] = await WebCrypto.deriveKeyFrom(password, {
      algo: 'AES-GCM',
      keyUsages: ['unwrapKey'],
      salt,
    });

    return window.crypto.subtle.unwrapKey(
      'raw',
      wrapped,
      key,
      { name: 'AES-GCM', iv },
      {
        name: ALGO,
        length: 256,
      },
      true,
      keyUsages
    ) as Promise<KeyByUsage<TKeyUsage>>;
  },
  // /**
  //  * Unwrap given wrapped key using a unwrapping key derived from given password.
  //  */
  // async unwrap(password: string, wrappedKey: WrappedKey): Promise<GeneratedCryptoKey> {
  //   const data = atob(wrappedKey);
  //   const prependedWithSaltAndIv = new Uint8Array(data.length);

  //   for (let i = 0, length = data.length; i < length; ++i) {
  //     prependedWithSaltAndIv[i] = data.charCodeAt(i);
  //   }

  //   const salt = prependedWithSaltAndIv.subarray(0, SALT_LENGTH);
  //   const wrapped = prependedWithSaltAndIv.subarray(SALT_LENGTH);

  //   const [key] = await WebCrypto.deriveKeyFrom(password, {
  //     algo: 'AES-KW',
  //     keyUsages: ['unwrapKey'],
  //     salt,
  //   });

  //   return window.crypto.subtle.unwrapKey(
  //     'raw',
  //     wrapped,
  //     key,
  //     'AES-KW',
  //     {
  //       name: ALGO,
  //       length: 256,
  //     },
  //     true,
  //     ['encrypt', 'decrypt']
  //   ) as Promise<GeneratedCryptoKey>;
  // },

  /**
   * Wrap given key using a given wrapping key.
   *
   * @note As a DX convenience to allow live reload when serving mobile, AES-GCM
   *       is used instead of the apparently preferable AES-KW for key wrapping purposes.
   *       When/if this is reverted back to using AES-KW, a one time 'migration'
   *       function will have to be shipped along to do the conversion client-side.
   */
  async wrapWithKey(wrappingKey: WrappingCryptoKey, key: GeneratedCryptoKey): Promise<WrappedKey> {
    //   const salt = window.crypto.getRandomValues(new Uint8Array(SALT_LENGTH));
    const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));

    const wrapped = new Uint8Array(await window.crypto.subtle.wrapKey('raw', key, wrappingKey, { name: 'AES-GCM', iv }));

    const prependedWithIv = new Uint8Array(IV_LENGTH + wrapped.byteLength);
    prependedWithIv.set(iv, 0);
    prependedWithIv.set(wrapped, IV_LENGTH);

    return btoa(uint8ArrayToString(prependedWithIv)) as WrappedKey;
  },

  /**
   * Unwrap given wrapped key using given (un)wrapping key.
   *
   * @note As a DX convenience to allow live reload when serving mobile, AES-GCM
   *       is used instead of the apparently preferable AES-KW for key wrapping purposes.
   *       When/if this is reverted back to using AES-KW, a one time 'migration'
   *       function will have to be shipped along to do the conversion client-side.
   */
  async unwrapWithKey<TKeyUsage extends KeyUsage[]>(
    wrappingKey: WrappingCryptoKey,
    wrappedKey: WrappedKey,
    keyUsages: TKeyUsage
  ): Promise<KeyByUsage<TKeyUsage>> {
    const data = atob(wrappedKey);
    const prependedWithIv = new Uint8Array(data.length);

    for (let i = 0, length = data.length; i < length; ++i) {
      prependedWithIv[i] = data.charCodeAt(i);
    }

    const iv = prependedWithIv.subarray(0, IV_LENGTH);
    const wrapped = prependedWithIv.subarray(IV_LENGTH);

    return window.crypto.subtle.unwrapKey(
      'raw',
      wrapped,
      wrappingKey,
      { name: 'AES-GCM', iv },
      {
        name: ALGO,
        length: 256,
      },
      true,
      keyUsages
    ) as Promise<KeyByUsage<TKeyUsage>>;
  },

  /**
   *
   */
  async exportRawUnwrapped(key: GeneratedCryptoKey): Promise<ExportedRawKey> {
    const raw = new Uint8Array(await window.crypto.subtle.exportKey('raw', key));
    const binaryString = uint8ArrayToString(raw);
    return btoa(binaryString) as ExportedRawKey;
  },

  /**
   *
   */
  async importRawUnwrapped<TKeyUsage extends KeyUsage[]>(
    key: ExportedRawKey,
    extractable: boolean,
    keyUsages: TKeyUsage,
    algo = ALGO
  ): Promise<KeyByUsage<TKeyUsage>> {
    const importedBinaryString = atob(key);
    const importedRaw = new Uint8Array(importedBinaryString.length);

    for (let i = 0, length = importedBinaryString.length; i < length; ++i) {
      importedRaw[i] = importedBinaryString.charCodeAt(i);
    }

    return window.crypto.subtle.importKey('raw', importedRaw, algo, extractable, keyUsages) as Promise<KeyByUsage<TKeyUsage>>;
  },

  /**
   * Return given data encrypted with given key as Base64 string.
   */
  async encrypt(key: EncryptionCryptoKey, data: string): Promise<Base64> {
    const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));

    const ciphered = new Uint8Array(await window.crypto.subtle.encrypt({ name: ALGO, iv }, key, encoder.encode(data)));

    const prependedWithIv = new Uint8Array(IV_LENGTH + ciphered.byteLength);
    prependedWithIv.set(iv, 0);
    prependedWithIv.set(ciphered, IV_LENGTH);

    return btoa(uint8ArrayToString(prependedWithIv)) as Base64;
  },

  /**
   * Return given base64 data decrypted with given key and parsed.
   */
  async decrypt(key: EncryptionCryptoKey, base64data: Base64): Promise<string> {
    const data = atob(base64data);
    const prependedWithIv = new Uint8Array(data.length);

    for (let i = 0, length = data.length; i < length; ++i) {
      prependedWithIv[i] = data.charCodeAt(i);
    }

    const iv = prependedWithIv.subarray(0, IV_LENGTH);
    const ciphered = prependedWithIv.subarray(IV_LENGTH);

    const plain = await window.crypto.subtle.decrypt({ name: ALGO, iv }, key, ciphered);

    return decoder.decode(plain);
  },

  /**
   *
   */
  async encryptWithPassword(password: string, data: string): Promise<Base64> {
    const [key, salt] = await WebCrypto.deriveKeyFrom(password);
    const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));

    const ciphered = new Uint8Array(await window.crypto.subtle.encrypt({ name: ALGO, iv }, key, encoder.encode(data)));

    const prependedWithSaltAndIv = new Uint8Array(SALT_LENGTH + IV_LENGTH + ciphered.byteLength);
    prependedWithSaltAndIv.set(salt, 0);
    prependedWithSaltAndIv.set(iv, SALT_LENGTH);
    prependedWithSaltAndIv.set(ciphered, SALT_LENGTH + IV_LENGTH);

    return btoa(uint8ArrayToString(prependedWithSaltAndIv)) as Base64;
  },

  /**
   *
   */
  async decryptWithPassword(password: string, base64data: Base64): Promise<string> {
    const data = atob(base64data);
    const prependedWithSaltAndIv = new Uint8Array(data.length);

    for (let i = 0, length = data.length; i < length; ++i) {
      prependedWithSaltAndIv[i] = data.charCodeAt(i);
    }

    const salt = prependedWithSaltAndIv.subarray(0, SALT_LENGTH);
    const iv = prependedWithSaltAndIv.subarray(SALT_LENGTH, SALT_LENGTH + IV_LENGTH);
    const ciphered = prependedWithSaltAndIv.subarray(SALT_LENGTH + IV_LENGTH);

    const [key] = await WebCrypto.deriveKeyFrom(password, { salt });
    const plain = await window.crypto.subtle.decrypt({ name: ALGO, iv }, key, ciphered);

    return decoder.decode(plain);
  },
};
