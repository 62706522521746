import { Uuid } from '../../domain/Uuid';
import { prettyPrint } from '../../utils';
import { RemoteKeyRepo } from '../repositories/RemoteKeyRepo';
import { KeyCanaryStore, KeyCanaryTriggeredError } from '../stores/KeyCanaryStore';
import { KeyStore, KeyTopic, keyTopics } from '../stores/KeyStore';
import {
  Crypto,
  EncryptionAndWrappingUsages,
  EncryptionCryptoKey,
  EncryptionUsages,
  GeneratedCryptoKey,
  UnavailableCryptoApiError,
  WrappedKey,
  WrappingCryptoKey,
  WrappingUsages,
} from './crypto/Crypto';
import { WebCrypto } from './crypto/WebCrypto';

/**
 * Provide an interface exposing static members to allow for async creation of a
 * complete KeychainService via a static method.
 */
export type KeychainServiceBuilder = typeof KeychainService;

/**
 * @todo See https://github.com/Microsoft/TypeScript/issues/24274 for ways
 *       to make sure KeyTypes handles all KeyTopic.
 */
export interface KeyTypes {
  user: WrappingCryptoKey & EncryptionCryptoKey;
  userBackup: WrappingCryptoKey & EncryptionCryptoKey;
  userRecovery: WrappingCryptoKey;
  credentials: EncryptionCryptoKey;
}

type NullableRecord<T> = {
  [P in keyof T]: T[P] | null;
};

/**
 * Password is used to derive a passKey to unwrap other persisted keys.
 *
 * @note This is not part of the event sourcing system, keeping keys in sync
 *       across device should follow some standard pattern with API Core as the
 *       only source of truth.
 *
 * @note KeyStore and RemoteKeyRepo essentially do the same thing and could have
 *       shared an interface. The idea is to have a local and a remote store,
 *       the remote store being treated as the authoritative source. KeychainService
 *       doesn't care where these store actually operate as long as the data ends up in
 *       2 different places. Making two incompatible interfaces makes it harder
 *       to mix up the stores or reference the same store twice. It is still
 *       possible to have 2 implementations, one for each interface, that would
 *       operate on the same underlying data and this is bad, but a lot less likely
 *       to happen unintentionally.
 *
 * @todo Look for ways to derive key from password once on login and doing the proton
 *       trick to save on refresh along with session ?
 *
 * @todo Derive a non-extractable key on login ? Won't this conflict with the session refresh
 *       proton trick ? Just make it extractable if it's never stored beyond the session refresh
 *       proton trick.
 *
 * @todo Figure out what would be needed to allow some operations on keys while
 *       offline and if this is desirable considering the added complexity.
 *
 * @todo Handle key missing on RemoteKeyRepo !!!
 *       They "shouldn't" ever go missing on RemoteKeyRepo, but if they do :
 *         - Branches who have the keys cached locally will continue chugging along
 *           events encrypted with those.
 *         - Branches who don't will issue new keys and it's going to be a mess.
 *
 *       At the moment, local cache is never invalidated except after a password
 *       change, so there's not a lot of reason for an branch who has the keys
 *       to check up on RemoteKeyRepo.
 *
 *       Even if local cache was invalidated here and there or RemoteKeyRepo checked
 *       for other reasons, we probably shouldn't let a new branch issue new keys
 *       without some safeguards.
 */
export class KeychainService {
  private keys = keyTopics.reduce((map, topic) => {
    map[topic] = null;
    return map;
    //   }, {} as Record<KeyTopic, GeneratedCryptoKey | WrappingCryptoKey | null>);
  }, {} as NullableRecord<KeyTypes>);
  /**
   *
   */
  private constructor(
    userKey: WrappingCryptoKey & EncryptionCryptoKey,
    private readonly password: string,
    private readonly keyStore: KeyStore,
    private readonly keyRepo: RemoteKeyRepo,
    private readonly crypto: Crypto
  ) {
    this.keys.user = userKey;
  }

  /**
   * Build a consistent and ready Cryptoservice using an async factory method
   * and a private constructor to restrict creation methods.
   *
   * @todo Consider what needs to be done to enable next step where renewing the
   *       userKey is possible. Right now, if a local userKey exists API Core
   *       isn't even checked for a possibly updated key.
   *
   * @todo Avoid the unnecessary unwrap after a renewKey.
   *
   * @note Should the triple << user, userBackup, userRecovery >> ever be
   *       allowed to go 'out of sync' ?
   *
   *       At the moment, the system doesn't support changing the user key at all.
   *       So no.
   *
   *       Later there may be a use for old << user, userBackup, userRecovery >>,
   *       like recovering data encrypted with a previous key in Proton ?
   *
   *       ApiKeyRepo uses the devices related routes of CoreApi as a workaround
   *       for concurrency issues on the original route intended for handling keys.
   *       They don't allow inserting multiple keys atomically. Even if they did,
   *       one key out of the triple could be modified independantly.
   *
   *       Stuffing the triple in a single record could work but would require
   *       special handling.
   *
   *       We're going to move forward pretending they are always in sync when
   *       stored for now. KeychainService interface must not offer ways to change
   *       them independently.
   */
  static async create(
    password: string,
    keyStore: KeyStore,
    keyRepo: RemoteKeyRepo,
    keyCanaryStore: KeyCanaryStore,
    crypto: Crypto = WebCrypto
  ): Promise<KeychainService> {
    if (!crypto.isAvailable()) {
      throw new UnavailableCryptoApiError(this.name);
    }

    /** user key : Check locally. */
    let wrappedUserKey = await keyStore.get('user');
    let cacheHit = true;

    if (!wrappedUserKey) {
      /** user key : Check remote. */
      cacheHit = false;
      wrappedUserKey = await keyRepo.pull('user');

      if (wrappedUserKey) {
        /** user key : Cache. */
        await keyStore.put('user', wrappedUserKey);
      } else {
        /** user key : Generate a << user, userBackup, userRecovery >>, push on remote, cache local copy. */
        wrappedUserKey = (await KeychainService.renewUserKey(password, keyStore, keyRepo, crypto))[1];
      }
    }

    try {
      const userKey = await crypto.unwrap<EncryptionAndWrappingUsages>(password, wrappedUserKey, [
        'encrypt',
        'decrypt',
        'wrapKey',
        'unwrapKey',
      ]);

      await KeychainService.checkCanary(userKey, keyCanaryStore, crypto);

      return new KeychainService(userKey, password, keyStore, keyRepo, crypto);
    } catch (error) {
      console.warn('KeychainService.create :', error);

      if (error instanceof KeyCanaryTriggeredError) {
        throw error;
      }

      /** If it failed on local key, retry with remote key, invalidate cache. */
      if (cacheHit) {
        wrappedUserKey = await keyRepo.pull('user');
        cacheHit = false;
        if (wrappedUserKey) {
          try {
            const userKey = await crypto.unwrap<EncryptionAndWrappingUsages>(password, wrappedUserKey, [
              'encrypt',
              'decrypt',
              'wrapKey',
              'unwrapKey',
            ]);
            await KeychainService.checkCanary(userKey, keyCanaryStore, crypto);
            /** user key : Cache. */
            await keyStore.put('user', wrappedUserKey);
            return new KeychainService(userKey, password, keyStore, keyRepo, crypto);
          } catch (error) {
            console.log('KeychainService.create after cache invalidation original error :', error);
            if (error instanceof KeyCanaryTriggeredError) {
              throw error;
            }
            throw new InvalidPasswordError('KeychainService after cache invalidation');
          }
        }
      }
      console.log('KeychainService.create original error :', error);
      throw new InvalidPasswordError('KeychainService');
    }
  }

  /**
   *
   */
  static async checkCanary(
    userKey: EncryptionCryptoKey & WrappingCryptoKey,
    keyCanaryStore: KeyCanaryStore,
    crypto: Crypto
  ): Promise<Uuid> {
    const branch = await keyCanaryStore.getBranchId();
    if (!branch) {
      throw new Error(`No branch id : backup store might not have been initialized !`);
    }

    const canary = await keyCanaryStore.get();
    if (!canary) {
      //   const branch = await keyCanaryStore.getBranchId();
      await keyCanaryStore.put({
        branch,
        canary: await crypto.encrypt(userKey, branch),
      });
      return branch;
    }

    try {
      const decrypted = await crypto.decrypt(userKey, canary.canary);
      if (decrypted !== branch) {
        throw new KeyCanaryTriggeredError(branch);
      }
    } catch (error) {
      throw new KeyCanaryTriggeredError(branch);
    }

    return branch;
  }

  /**
   *
   */
  private static async backupUserKey(
    password: string,
    userKey: EncryptionCryptoKey & WrappingCryptoKey,
    keyStore: KeyStore,
    keyRepo: RemoteKeyRepo,
    crypto: Crypto
  ): Promise<[EncryptionCryptoKey & WrappingCryptoKey, WrappingCryptoKey]> {
    // return 'temp' as unknown as Promise<[GeneratedCryptoKey, WrappingCryptoKey]>;

    // Generate an extractable recovery key.
    const recoveryKey = await crypto.generateWrappingKey();
    const wrappedRecoveryKey = await crypto.wrap(password, recoveryKey);

    // Wrap user key with recovery key as backup.
    const wrappedBackupUserKey = await crypto.wrapWithKey(recoveryKey, userKey);

    try {
      await Promise.all([keyRepo.push('userRecovery', wrappedRecoveryKey), keyRepo.push('userBackup', wrappedBackupUserKey)]);
      await Promise.all([keyStore.put('userRecovery', wrappedRecoveryKey), keyStore.put('userBackup', wrappedBackupUserKey)]);
      return [userKey, recoveryKey];
    } catch (error) {
      console.log('KeychainService.backUserKey original error :', error);
      console.log('@todo : Retry/handle/unwind remote keyRepo.push errors !');
      console.log('@todo : Retry/handle/unwind local keyStore.put errors !');
      throw new KeyCannotBeCreatedError('user', 'KeychainService');
    }
  }

  /**
   * @note  RemoteKeyRepo must acknowledge key reception to be able to move on and use that key.
   */
  private static async renewUserKey(
    password: string,
    keyStore: KeyStore,
    keyRepo: RemoteKeyRepo,
    crypto: Crypto
  ): Promise<[EncryptionCryptoKey & WrappingCryptoKey, WrappedKey]> {
    // Generate a user key.
    const userKey = await crypto.generateEncryptionAndWrappingKey();
    const wrappedUserKey = await crypto.wrap(password, userKey);

    // Generate an extractable recovery key.
    const recoveryKey = await crypto.generateWrappingKey();
    const wrappedRecoveryKey = await crypto.wrap(password, recoveryKey);

    // Wrap user key with recovery key as backup.
    const wrappedBackupUserKey = await crypto.wrapWithKey(recoveryKey, userKey);

    try {
      await Promise.all([
        keyRepo.push('user', wrappedUserKey),
        keyRepo.push('userRecovery', wrappedRecoveryKey),
        keyRepo.push('userBackup', wrappedBackupUserKey),
      ]);
      await Promise.all([
        keyStore.put('user', wrappedUserKey),
        keyStore.put('userRecovery', wrappedRecoveryKey),
        keyStore.put('userBackup', wrappedBackupUserKey),
      ]);
      return [userKey, wrappedUserKey];
    } catch (error) {
      console.log('KeychainService.renewUserKey original error :', error);
      console.log('@todo : Retry/handle/unwind remote keyRepo.push errors !');
      console.log('@todo : Retry/handle/unwind local keyStore.put errors !');
      throw new KeyCannotBeCreatedError('user', 'KeychainService');
    }
  }

  /**
   * @note RemoteKeyRepo must acknowledge key reception to be able to move on and use that key.
   *
   * @note Narrowing with generics is still sketchy. At least it should issue an error when we add
   *       a different type to KeyTypes.
   *
   */
  private static async renewKey<T extends Exclude<KeyTopic, 'user' | 'userBackup' | 'userRecovery'>>(
    // password: string,
    userKey: EncryptionCryptoKey & WrappingCryptoKey,
    topic: T,
    keyStore: KeyStore,
    keyRepo: RemoteKeyRepo,
    crypto: Crypto
  ): Promise<[KeyTypes[T], WrappedKey]> {
    const key = await crypto.generateEncryptionKey();
    // const wrappedKey = await crypto.wrap(password, key);
    /** @todo Express that a userKey can also wrap/unwrap ? */
    const wrappedKey = await crypto.wrapWithKey(userKey, key);

    try {
      await keyRepo.push(topic, wrappedKey);
      await keyStore.put(topic, wrappedKey);
      return [key, wrappedKey];
    } catch (error) {
      console.log('KeychainService.renewKey original error :', error);
      console.log('@todo : Retry/handle local keyStore.put errors !');
      throw new KeyCannotBeCreatedError(topic, 'KeychainService');
    }
  }

  /**
   * @todo Figure out why the null check fails to register with TS on the first
   *       early return.
   */
  //   async get(topic: 'userRecovery'): Promise<WrappingCryptoKey>;
  //   async get(topic: Exclude<KeyTopic, 'userRecovery'>): Promise<GeneratedCryptoKey>;
  //   async get(topic: KeyTopic): Promise<WrappingCryptoKey | GeneratedCryptoKey> {
  //   async get<T extends KeyTopic>(topic: T): Promise<T extends 'userRecovery' ? WrappingCryptoKey : GeneratedCryptoKey> {
  async get<T extends KeyTopic>(topic: T): Promise<KeyTypes[T]> {
    // prettyPrint({ KeychainServiceKeys: this.keys, topic }, 'KeychainService.get', 'warn');
    if (this.keys[topic]) {
      /** @todo Remove assertion once typescript 4.7 is out, it shouldn't be necessary anymore. */
      return this.keys[topic] as KeyTypes[T];
    }

    /** Check locally. */
    let wrappedKey = await this.keyStore.get(topic);
    let cacheHit = true;

    if (!wrappedKey) {
      /** Check remote. */
      cacheHit = false;
      wrappedKey = await this.keyRepo.pull(topic);
      //   console.log('KeychainService.get remote wrappedKey : ', topic, wrappedKey);
      if (wrappedKey) {
        /** Cache. */
        await this.keyStore.put(topic, wrappedKey);
      }
    }

    try {
      switch (topic) {
        case 'user':
          /** Unwrap any existing key found or generate a new one. */
          return (this.keys[topic] = wrappedKey
            ? await this.crypto.unwrap<EncryptionAndWrappingUsages>(this.password, wrappedKey, [
                'encrypt',
                'decrypt',
                'wrapKey',
                'unwrapKey',
              ])
            : (await KeychainService.renewUserKey(this.password, this.keyStore, this.keyRepo, this.crypto))[0]);

        case 'userBackup':
          /** Unwrap any existing key found or make a new backup. */
          return (this.keys[topic] = wrappedKey
            ? await this.crypto.unwrapWithKey<EncryptionAndWrappingUsages>(await this.get('userRecovery'), wrappedKey, [
                'encrypt',
                'decrypt',
                'wrapKey',
                'unwrapKey',
              ])
            : (
                await KeychainService.backupUserKey(
                  this.password,
                  await this.get('user'),
                  this.keyStore,
                  this.keyRepo,
                  this.crypto
                )
              )[0]);

        case 'userRecovery':
          /** Unwrap any existing key found or make a new backup. */
          return (this.keys[topic] = wrappedKey
            ? ((await this.crypto.unwrap<WrappingUsages>(this.password, wrappedKey, ['wrapKey', 'unwrapKey'])) as KeyTypes[T])
            : ((
                await KeychainService.backupUserKey(
                  this.password,
                  await this.get('user'),
                  this.keyStore,
                  this.keyRepo,
                  this.crypto
                )
              )[1] as KeyTypes[T]));

        case 'credentials':
          // prettyPrint({ topic, wrappedKey }, 'KeychainService.get case credentials', 'warn');
          /** Unwrap any existing key found or generate a new one. */
          return (this.keys[topic] = wrappedKey
            ? ((await this.crypto.unwrapWithKey<EncryptionUsages>(await this.get('user'), wrappedKey, [
                'encrypt',
                'decrypt',
              ])) as KeyTypes[T])
            : ((
                await KeychainService.renewKey(await this.get('user'), topic, this.keyStore, this.keyRepo, this.crypto)
              )[0] as KeyTypes[T]));

        default: {
          const exhaustiveCheck: never = topic;
          throw new Error(exhaustiveCheck);
        }
      }
    } catch (error) {
      if (error instanceof KeyCannotBeCreatedError) {
        throw error;
      }

      /** If it failed during unwrapping on local key, invalidate cache and retry. */
      if (cacheHit) {
        this.keys[topic] = null;
        this.keyStore.invalidate(topic);
        return this.get(topic);
      }
      throw new KeyCannotBeUnwrappedError(topic, 'KeychainService');
    }
  }

  //   /**
  //    *
  //    */
  //   async getWrapped(topic: KeyTopic): Promise<WrappedKey> {
  //     /** Check locally. */
  //     let wrappedKey = await this.keyStore.get(topic);
  //     // console.log('KeychainService.get local wrappedKey : ', topic, wrappedKey);

  //     if (!wrappedKey) {
  //       /** Check remote. */
  //       wrappedKey = await this.keyRepo.pull(topic);
  //       //   console.log('KeychainService.get remote wrappedKey : ', topic, wrappedKey);
  //       if (wrappedKey) {
  //         /** Cache. */
  //         await this.keyStore.put(topic, wrappedKey);
  //       }
  //     }
  //     /** Return any existing wrapped key found or generate a new one. */
  //     return wrappedKey ?? (await KeychainService.renewKey(this.password, topic, this.keyStore, this.keyRepo, this.crypto))[1];
  //   }

  /**
   *
   */
  async export(): Promise<Partial<Record<KeyTopic, WrappedKey>>> {
    return this.keyStore.getAll();
  }

  /**
   *
   */
  async import(keys: Partial<Record<KeyTopic, WrappedKey>>): Promise<WrappedKey[]> {
    return Promise.all(
      Object.entries(keys).map(([topic, wrappedKey]) => this.keyStore.put(topic as KeyTopic, wrappedKey as WrappedKey))
    );
  }

  /**
   * @todo Avoid the wrap/unwrap sequence if this ever proves to be a bottleneck ( unlikely ).
   *
   * @todo Handle shredding/renewing user/userBackup/userRecovery combo ?
   */
  async shred(topic: Exclude<KeyTopic, 'user' | 'userBackup' | 'userRecovery'>): Promise<WrappedKey> {
    const [key, wrappedKey] = await KeychainService.renewKey(
      await this.get('user'),
      topic,
      this.keyStore,
      this.keyRepo,
      this.crypto
    );
    // const [key, wrappedKey] = await KeychainService.renewKey(this.password, topic, this.keyStore, this.keyRepo, this.crypto);

    this.keys[topic] = key;

    return wrappedKey;
  }
}

/**
 *
 */
export class InvalidPasswordError extends Error {
  constructor(readonly actor?: string) {
    super(`Invalid password${actor ? ' to unlock ' + actor : ''} !`);
    this.name = 'InvalidPasswordError';
  }
}

/**
 * @todo Figure out if its safe/satifactory to shred local key and always follow
 *       remote.
 *
 * @todo Consider something like creating a new branch and pulling when the
 *       conflict is on the user key ? It may not be necessary : everything that
 *       is still available locally is in plaintext and can be used. New events
 *       pulled will just be discarded if they are encrypted with another key.
 *       When/if the user key is renewed on purpose, BackupService middleware
 *       will need to be updated though.
 */
export class UnexpectedKeyConflictError extends Error {
  constructor(readonly topic: KeyTopic, readonly actor?: string) {
    super(`Unexpected conflict between local and remote ${topic} keys ${actor ? 'in ' + actor : ''} !`);
    this.name = 'UnexpectedKeyConflictError';
  }
}

/**
 *
 */
export class KeyCannotBeCreatedError extends Error {
  constructor(readonly topic: KeyTopic, readonly actor?: string) {
    super(`Could not create ${topic} key ${actor ? 'in ' + actor : ''} because app is offline or server errored !`);
    this.name = 'KeyCannotBeCreatedError';
  }
}

/**
 *
 */
export class KeyCannotBeUnwrappedError extends Error {
  constructor(readonly topic: KeyTopic, readonly actor?: string) {
    super(`Could not unwrap ${topic} key ${actor ? 'in ' + actor : ''} !`);
    this.name = 'KeyCannotBeUnwrappedError';
  }
}
