import { DomainEventShape, Uuid } from '../../../Common';
import { NewNotificationDTO, UpdateNotificationDTO } from '../projections/ViewNotification';

/**
 * DomainEvent.
 */
export class NotificationReceived implements DomainEventShape {
  readonly type = 'NOTIFICATION_RECEIVED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly notification: NewNotificationDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class NotificationDeleted implements DomainEventShape {
  readonly type = 'NOTIFICATION_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}
/**
 * DomainEvent.
 */
export class NotificationPlanned implements DomainEventShape {
  readonly type = 'NOTIFICATION_PLANNED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class NotificationSnoozed implements DomainEventShape {
  readonly type = 'NOTIFICATION_SNOOZED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
// export class NotificationReminded implements DomainEventShape {
//   readonly type = 'NOTIFICATION_REMINDED';
//   readonly schema = 1;
//   constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
// }

/**
 * DomainEvent.
 */
export class NotificationImUpdated implements DomainEventShape {
  readonly type = 'NOTIFICATION_IM_UPDATED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly values: UpdateNotificationDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type NotificationDomainEvent =
  | NotificationReceived
  | NotificationDeleted
  | NotificationPlanned
  | NotificationSnoozed
  // | NotificationReminded
  | NotificationImUpdated;
