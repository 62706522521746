import { Uuid } from '../../../Common';

export type TaggableElement = 'MAIL' | 'EVENT' | 'IM_THREAD' | 'CALL' | 'ACCOUNT' | 'CONTACT' | 'MESSAGE_MODEL';

export type ViewTagRelation = {
  id: Uuid;
  element: TaggableElement;
  element_id: Uuid;
  tag_id: Uuid;
};

export type NewTagRelationDTO = Omit<ViewTagRelation, 'id'>;

export function createViewTagRelation(aggregateId: Uuid, data: NewTagRelationDTO): ViewTagRelation {
  if (!data.element || !data.element_id || !data.tag_id) {
    throw new Error('Missing values to createViewTagRelation');
  }
  return {
    id: aggregateId,
    ...data,
  };
}
