import { DeepReadonly, SingleOrArray } from '../utils';
import { DomainEvent, DomainEventShape } from './DomainEvent';
import { Uuid } from './Uuid';

/**
 *
 */
export interface ProjectionShape {
  id: Uuid | null;
  version: number;
}

/**
 * @todo Consider setting id automatically on first event and then enforcing not
 *       being able to return an id with Omit and strict excess property checks
 *       wizardy or skip .id in process() or { id: never }.
 *
 * @note Partial<TProjection & ProjectionShape> doesn't distinguish between
 *       omitted keys and keys set to undefined even when the underlying type
 *       disallow undefined.
 *
 *       There is a compiler option in the works, but not available as of
 *       Typescript 4.3.5.
 *
 *       See https://github.com/Microsoft/TypeScript/issues/13195
 *           https://github.com/microsoft/TypeScript/issues/44421
 *           https://stackoverflow.com/questions/67822178/weird-optional-class-properties-in-typescript
 *           https://stackoverflow.com/questions/54247101/typescript-interface-optional-but-not-undefined
 *
 *       The compiler option is now available in 4.4.0-beta.
 *
 *       See https://devblogs.microsoft.com/typescript/announcing-typescript-4-4-beta/#exact-optional-property-types
 * 
 *       As of 2021-12-31, exactOptionalPropertyTypes has been added to core tsconfig.json.
 */
export interface DomainEventHandler<TProjection extends ProjectionShape, TDomainEvent extends DomainEventShape> {
  (event: TDomainEvent, state: DeepReadonly<TProjection & ProjectionShape>): Partial<TProjection & ProjectionShape>;
} // & {id? : never; version? : never};
// ) => Readonly<Partial<TProjection & ProjectionShape>>;

/**
 * Collection of DomainEventHandlers keyed by DomainEventType.
 */
export type ProjectionHandlers<TProjection, TDomainEvent extends DomainEventShape = DomainEvent> = {
  [TDomainEventType in TDomainEvent['type']]?: DomainEventHandler<
    TProjection & ProjectionShape,
    TDomainEvent & { type: TDomainEventType }
  >;
};

/**
 *
 */
export type InitialState = Record<string, unknown>;
// export interface InitialState {
//   [key: string]: unknown;
// }

/**
 * Type helper for ProjectionConfig creation.
 */
export function createProjectionConfig<TProjection extends InitialState, TDomainEvent extends DomainEventShape = DomainEvent>(
  initialState: TProjection,
  config: Readonly<ProjectionHandlers<TProjection, TDomainEvent>>
  //   config: ProjectionHandlers<TProjection, TDomainEvent> = {}
): [TProjection, ProjectionHandlers<TProjection, TDomainEvent>] {
  return [initialState, config];
}

/**
 * Build a DecisionProjection to keep track of "transient state" for decision making
 * inside of aggregates.
 *
 * @note DecisionProjection now relies on typescript and mark parameter state
 *       as DeepReadOnly. Attempts to mutate state in a handler will yield
 *       a TS error. If that error is ignored, the state will be mutated and
 *       bad things will happen.
 *
 * @todo See how xstate-fsm fits DecisionProjection use case, because as soon as you
 *       add guards/check flags or values from previous state this looks awfully familiar.
 *       Same thing for command handlers in aggregates actually.
 */
export class DecisionProjection<TProjection, TDomainEvent extends DomainEventShape = DomainEvent> {
  public state: TProjection & ProjectionShape;
  private readonly handlers: ProjectionHandlers<TProjection, TDomainEvent>;

  constructor([initialState, handlers]: [TProjection, ProjectionHandlers<TProjection, TDomainEvent>]) {
    /**
     * If an aggregateId is supplied in initialState, roll with it.
     * Otherwise, just satisfy the constraint.
     */
    // if (initialState) {
    //   this.state = { id: null, version: 0, ...initialState };
    // }
    this.state = { id: null, version: 0, ...initialState };
    this.handlers = handlers;
  }

  /**
   * @todo Consider dealing with DomainEvent schema upgrade with either a switch(schema) in
   *       the handler or this.handlers[eventType][schema] here.
   */
  register<TDomainEventType extends TDomainEvent['type']>(
    eventType: TDomainEventType,
    handler: DomainEventHandler<TProjection & ProjectionShape, TDomainEvent & { type: TDomainEventType }> // NarrowBy<TDomainEvent, TDomainEventType>
  ): this {
    this.handlers[eventType] = handler;
    return this;
  }

  /**
   *
   */
  process(events: SingleOrArray<TDomainEvent>): this {
    /** Wrap single event in an array. */
    if (!Array.isArray(events)) {
      events = [events];
    }

    /**
     * @todo Enforce excess property check on handler return type.
     *
     * @todo Handle possible malformed aggregate ending up without an id.
     *       see comment in CalendarEventRepo.ts
     *
     * @todo Consider if it should throw when getting an out of order or invalid
     *       id event that has NO registered handler. It probably is better to throw
     *       since it's most likely a symptom of an underlying problem.
     * 
     * @todo Consider throwing if first event doesn't attribute an id.
     */
    events.forEach((event) => {
      /**
       * Allow first event to give its id to the projection.
       */
      if (this.state.id && this.state.id !== event.aggregateId) {
        throw new UnexpectedAggregateIdError(event.aggregateId, this.state.id, 'DecisionProjection');
      }

      /**
       * @todo Study what is needed to handle Commutative SET ops : state.version <= event.version ?
       *       But then how to enforce that handler can only use SET op ?
       *       Is this even desirable ? Using the previous projection state is very handy.
       */
      if (this.state.version !== event.version) {
        throw new UnexpectedDomainEventVersionError(event, this.state.version, 'DecisionProjection');
      }

      this.state = {
        ...this.state,
        ...this.handlers[event.type as TDomainEvent['type']]?.(event, this.state as DeepReadonly<TProjection & ProjectionShape>),
        /**
         * @note It is difficult to guarantee that handlers never return object with a version property.
         *       Increment and overwrite any possible stowaway 'version' property as a workaround.
         */
        version: this.state.version + 1,
      };
    });
    return this;
  }
}

/**
 *
 */
export class UnexpectedAggregateIdError extends Error {
  constructor(readonly receivedId: Uuid, readonly expectedId: Uuid, readonly actor?: string) {
    super(
      `Unexpected domain event aggregate id received ${actor ? 'by ' + actor : ''} : ${receivedId}. Expected : ${expectedId} !`
    );
    this.name = 'UnexpectedAggregateIdError';
  }
}

/**
 *
 */
export class UnexpectedDomainEventVersionError<TDomainEvent extends DomainEventShape = DomainEvent> extends Error {
  constructor(readonly event: TDomainEvent, readonly expectedVersion: number, readonly actor?: string) {
    super(
      `Domain event ${event.type} [${event.aggregateId}] targets version : ${event.version} but ${
        actor ?? 'target'
      } is version : ${expectedVersion}.`
    );
    this.name = 'UnexpectedDomainEventVersionError';
  }
}
