import { MessageModelSortByOption, MessageModelSortOptions, messageModelsSortSelector, sortOptions } from "@focus-front/core";
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useClasses = makeStyles((theme) => ({
  sortOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#f6f8fa',
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  settingsSortOptions: {
    backgroundColor: 'transparent',
    marginBottom: 10,
    paddingLeft: 0,
    justifyContent: 'flex-start',
  },
  sortIcon: {
    position: 'relative',
  },
  sortIconOrder: {
    position: 'absolute',
    left: '65%',
  },
  select: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& div': {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
    '& fieldset': {
      border: 'none',
    },
  },
}));

type Props = {
  handleSort: (sortOptions: MessageModelSortOptions) => void;
  settings?: boolean;
}

export default function SortOptions({ handleSort, settings = false }: Props) {
  const c = useClasses();

  const { sortBy, sortOrder } = useSelector(messageModelsSortSelector);

  const sort = (event) => {
    const options = (event.target.value as string).split('|');
    const sortOptions: MessageModelSortOptions = { sortBy: options[0] as MessageModelSortByOption, sortOrder: options[1] as 'asc' | 'desc' };
    handleSort(sortOptions);
  }

  return (
    <div className={c.sortOptions + (settings ? ' ' + c.settingsSortOptions : '') }>
      <FormControl size="small">
        <Select value={sortBy + '|' + sortOrder} variant="outlined" onChange={sort} className={c.select}>
          {sortOptions.map((option) => (
            <MenuItem key={option.name + '|' + option.direction} value={option.name + '|' + option.direction}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}