import React from 'react';
import { ContactDocument, SearchResult } from '@focus-front/core';
import { makeStyles, Typography } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import AttendeeAvatar from './AttendeeAvatar';
import Tags from '../Tag/Tags';

const useClasses = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '& .Avatar': {
      flexShrink: 0,
    },
  },
  arrow: {
    fontSize: 24,
    color: theme.palette.grey[700],
  },
  light: {
    opacity: 0.6,
  },
}));

/**
 * Create a string with the identifiers to show below the contact name
 */
function getIdentifier(contact: ContactDocument): string {
  let identifiers = [];
  if (contact.email?.length) contact.email.forEach((email) => identifiers.push(email.address));

  if (contact.phone?.length) contact.phone.forEach((phone) => identifiers.push(phone.number));
  if (contact.social?.length) {
    if (contact.social.find((social) => social.identifier_type === 'LINKEDIN_ID')) identifiers.push('LinkedIn connection');
    if (contact.social.find((social) => social.identifier_type === 'MESSENGER_ID')) identifiers.push('Messenger connection');
    if (contact.social.find((social) => social.identifier_type === 'TIKTOK_ID')) identifiers.push('TikTok connection');
    if (contact.social.find((social) => social.identifier_type === 'INSTAGRAM_ID')) identifiers.push('Instagram connection');
    if (contact.social.find((social) => social.identifier_type === 'TWITTER_ID')) identifiers.push('Twitter connection');
  }

  return identifiers.join(' • ');
}

interface Props {
  onClick: () => void;
  contact: SearchResult<ContactDocument>;
  index: number;
}

export default function ContactSearchResult({ onClick, contact }: Props) {
  const c = useClasses();

  return (
    <div className={c.root} onClick={onClick}>
      <AttendeeAvatar size="default" avatar_url={contact.item.profile_picture} display_name={contact.item.full_name} />
      <div style={{ paddingLeft: 10, flex: 1 }}>
        <Typography variant="h4">{contact.item.full_name}</Typography>
        {getIdentifier(contact.item) && <Typography variant="subtitle2">{getIdentifier(contact.item)}</Typography>}
        {contact.item.tags && contact.item.tags.length > 0 && <Tags tags={contact.item.tags} listMode />}
      </div>
      <IonIcon className={c.arrow} icon={chevronForward} />
    </div>
  );
}
