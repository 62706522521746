import dayjs from 'dayjs';
import { Attendee } from '../../../Contact';
import { toUTCDateTimeMs, UTCDateTimeMs, Uuid } from '../../../Common';
import { Select } from '../../../Common/utils';

export type ImThreadSyncStatus = 'NOT_SYNC' | 'SYNC';

export const ZERO_INBOX_IM_THREAD_NAME = '__ZERO_INBOX_IM_THREAD_';
export const UNIPILE_IM_THREAD_NAME = '__UNIPILE_IM_THREAD_';

/**
 * Projection.
 */
export type ViewImThread = Readonly<{
  id: Uuid /** Aggregate id. */;
  provider_id: string;
  provider_name?: string;
  account_id: Uuid;
  attendees: Attendee[];
  last_seen: UTCDateTimeMs;
  last_update: UTCDateTimeMs | null;
  sync_status: ImThreadSyncStatus;
  /**
   * Define if all the past messages has been synced
   */
  all_past_ims_synced: boolean;
}>;

/**
 * Stored version of the projection for IDB
 */
export type IdbViewImThread = ViewImThread & { attendees_identifiers: string[] };

/**
 * DTO used when Attendees does not exists yet
 */
export type NewImThreadDTO = Select<Omit<ViewImThread, 'id'>, 'account_id' | 'provider_id' | 'attendees'>;
export type NewImThreadUndefAttendeesDTO = Select<Omit<ViewImThread, 'id' | 'attendees'>, 'account_id' | 'provider_id'> & {
  attendees: Attendee[] | undefined;
};

export type UpdateImThreadDTO = Partial<Omit<NewImThreadDTO, 'id' | 'account_id'>>;

/**
 * This dummy Thread can be created for some Im Sources that don't provide consistent dates for their messages
 * @see prepareZeroInbox in AppImUseCase
 */
export function isZeroInboxImThread(thread: ViewImThread) {
  return thread.provider_id.startsWith(ZERO_INBOX_IM_THREAD_NAME);
}

/**
 *
 */
export function createViewImThread(aggregateId: Uuid, values: NewImThreadDTO): ViewImThread {
  /** @note Keep this pattern to check at runtime values received from the external world. */
  if (!values.account_id || !values.attendees || !values.attendees.length || !values.provider_id) {
    console.log(values);
    throw new Error('Missing values to instantiate an ImThread');
  }
  return {
    last_update: null,
    ...values,
    id: aggregateId,
    last_seen: toUTCDateTimeMs(dayjs()),
    sync_status: values.sync_status || 'NOT_SYNC',
    all_past_ims_synced: values.all_past_ims_synced || false,
  };
}
