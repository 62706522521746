import {
  Avatar,
  Button,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  contactByIdSelectorFactory,
  editContact,
  getContactView,
  Uuid,
  ViewContact,
  writeNewMail,
  useAppDispatch,
  AccountType,
  ViewImThread,
  ViewIm,
  ViewAccount,
  selectSearchResult,
  Taggable,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { CreateOutlined } from '@material-ui/icons';
import LightTabs from '../Common/LightTabs';
import LightTab from '../Common/LightTab';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ImThreadListView } from '../Im/ImThreadListView';
import AttendeeAvatar from './AttendeeAvatar';
import EditableTags from '../Tag/EditableTags';
import { AccountTypeIcon } from '@focus-front/ui';

interface Props {
  contact_id: Uuid;
  onBack?: () => void;
}

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: theme.spacing(2) + 120,
  },
  infos: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  infos2: {
    marginLeft: theme.spacing(2),
  },
  navigationBtn: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
      marginLeft: 10,
    },
  },
  title: {
    paddingLeft: 8,
    fontSize: 30,
    fontWeight: 400,
  },
  content: {
    flex: 1,
    overflowY: 'scroll',
    padding: theme.spacing(2),
    minHeight: 300,
  },
  itemCont: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  accIcon: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    color: theme.palette.grey[800],
    height: 50,
    width: 50,
    fontSize: 30,
    marginRight: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

type Tabs = 'contact' | 'share' | 'exchange';

export default function ContactView({ contact_id, onBack }: Props) {
  const [contact, setContact] = useState<ViewContact & Taggable>(null);
  const [threads, setThreads] = useState<(ViewImThread & { last_im: ViewIm; account: ViewAccount })[]>([]);
  // const contact = useSelector(contactByIdSelectorFactory(contact_id));
  const [tab, setTab] = useState<Tabs>('contact');
  const c = useClasses();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { result, error } = await dispatch(getContactView(contact_id));
      setContact(result.contact);
      setThreads(result.threads);
    })();
  }, []);

  return (
    <div className={c.root}>
      <div className={c.header}>
        {onBack && (
          <IconButton onClick={onBack}>
            <IonIcon icon={arrowBack} />
          </IconButton>
        )}

        {contact && (
          <Fade in>
            <div className={c.infos}>
              <AttendeeAvatar size="large" display_name={contact.full_name} avatar_url={contact.profile_picture} />
              <div className={c.infos2}>
                <Typography variant="h1" className={c.title}>
                  {contact.full_name}
                </Typography>
                <EditableTags tags={contact.tags} element="CONTACT" element_id={contact.id} />
              </div>
            </div>
          </Fade>
        )}
      </div>
      {/* <LightTabs value={tab}>
        <LightTab value="contact" label="Contact" onClick={() => setTab('contact')} />
      </LightTabs> */}
      <div className={c.content}>
        {contact && (
          <>
            {/* <Typography variant="h3" className={c.sectionTitle}>
              Informations de contact
            </Typography> */}
            <List>
              {contact.email?.map((email) => (
                <Paper variant="outlined" className={c.itemCont} key={email.label}>
                  <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                    <ListItemAvatar>
                      <AccountTypeIcon size={40} account_type="MAIL" />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="h3">{email.label}</Typography>} secondary={email.address} />
                  </ListItem>
                </Paper>
              ))}
              {contact.phone?.map((phone) => (
                <Paper variant="outlined" className={c.itemCont} key={phone.number}>
                  {phone.label === 'WHATSAPP' ? (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type="WHATSAPP" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h3">{t('contact.whatsappConnected')}</Typography>}
                          secondary={phone.number}
                        />
                      </ListItem>
                      {threads
                        .filter((thread) => thread.account.type === 'WHATSAPP')
                        .sort((threadA, threadB) => (threadA.last_update > threadB.last_update ? -1 : 1))
                        .map((thread) => (
                          <ImThreadListView
                            key={thread.id}
                            thread={{ ...thread, tags: [] }}
                            last_im={thread.last_im}
                            onClick={() => dispatch(selectSearchResult({ id: thread.id, type: 'im_thread' }))}
                          />
                        ))}
                    </>
                  ) : (
                    <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                      <ListItemAvatar>
                        <AccountTypeIcon size={40} account_type="MOBILE" />
                      </ListItemAvatar>
                      <ListItemText primary={<Typography variant="h3">{phone.label}</Typography>} secondary={phone.number} />
                    </ListItem>
                  )}
                </Paper>
              ))}
              {contact.social?.map((social) => (
                <Paper variant="outlined" className={c.itemCont} key={social.identifier}>
                  {social.identifier_type === 'LINKEDIN_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'LINKEDIN'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h3">{t('contact.linkedinConnected')}</Typography>}
                          secondary={
                            <Link href={`https://linkedin.com/in/${social.identifier}`}>{t('contact.openLinkedInProfile')}</Link>
                          }
                        />
                      </ListItem>
                      {threads
                        .filter((thread) => thread.account.type === 'LINKEDIN')
                        .map((thread) => (
                          <ImThreadListView
                            key={thread.id}
                            thread={{ ...thread, tags: [] }}
                            last_im={thread.last_im}
                            onClick={() => dispatch(selectSearchResult({ id: thread.id, type: 'im_thread' }))}
                          />
                        ))}
                    </>
                  )}
                  {social.identifier_type === 'MESSENGER_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'MESSENGER'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h3">{t('contact.messengerConnected')}</Typography>}
                          secondary={
                            <Link href={`https://www.facebook.com/${social.identifier}`}>
                              {t('contact.openMessengerProfile')}
                            </Link>
                          }
                        />
                      </ListItem>
                      {threads
                        .filter((thread) => thread.account.type === 'MESSENGER')
                        .map((thread) => (
                          <ImThreadListView
                            key={thread.id}
                            thread={{ ...thread, tags: [] }}
                            last_im={thread.last_im}
                            onClick={() => dispatch(selectSearchResult({ id: thread.id, type: 'im_thread' }))}
                          />
                        ))}
                    </>
                  )}
                  {social.identifier_type === 'INSTAGRAM_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'INSTAGRAM'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h3">{t('contact.instagramConnected')}</Typography>}
                          secondary={
                            <Link href={`https://www.instagram.com/${social.identifier}`}>
                              {t('contact.openInstagramProfile')}
                            </Link>
                          }
                        />
                      </ListItem>
                      {threads
                        .filter((thread) => thread.account.type === 'INSTAGRAM')
                        .map((thread) => (
                          <ImThreadListView
                            key={thread.id}
                            thread={{ ...thread, tags: [] }}
                            last_im={thread.last_im}
                            onClick={() => dispatch(selectSearchResult({ id: thread.id, type: 'im_thread' }))}
                          />
                        ))}
                    </>
                  )}
                  {social.identifier_type === 'TIKTOK_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'TIKTOK'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h3">{t('contact.tiktokConnected')}</Typography>}
                          secondary={
                            <Link href={`https://www.tiktok.com/@${social.identifier}`}>{t('contact.openTikTokProfile')}</Link>
                          }
                        />
                      </ListItem>
                      {threads
                        .filter((thread) => thread.account.type === 'TIKTOK')
                        .map((thread) => (
                          <ImThreadListView
                            key={thread.id}
                            thread={{ ...thread, tags: [] }}
                            last_im={thread.last_im}
                            onClick={() => dispatch(selectSearchResult({ id: thread.id, type: 'im_thread' }))}
                          />
                        ))}
                    </>
                  )}
                  {social.identifier_type === 'TWITTER_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'TWITTER'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h3">{t('contact.twitterConnected')}</Typography>}
                          secondary={
                            <Link href={`https://twitter.com/${social.identifier}`}>{t('contact.openTwitterProfile')}</Link>
                          }
                        />
                      </ListItem>
                      {threads
                        .filter((thread) => thread.account.type === 'TWITTER')
                        .map((thread) => (
                          <ImThreadListView
                            key={thread.id}
                            thread={{ ...thread, tags: [] }}
                            last_im={thread.last_im}
                            onClick={() => dispatch(selectSearchResult({ id: thread.id, type: 'im_thread' }))}
                          />
                        ))}
                    </>
                  )}
                </Paper>
              ))}
            </List>
          </>
        )}
      </div>
    </div>
  );
}
