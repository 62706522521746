import { EncryptedCredentialsRepo, MicrosoftAccountInfo, OutlookCredentials } from '../../../Account';
import { toUTCDateTimeMs, Uuid, MicrosoftGraphClient, FileManagerService, LocalFile } from '../../../Common';
import { notEmpty, chunkArray, MaybePromise } from '../../../Common/utils';
import { Crypto, EncryptionCryptoKey } from '../../../Common/infra/services/crypto/Crypto';
import { MailBox, MailSearchQuery, MailService, onFullSyncCallbacks } from './MailService';
import { NewMailDTO, MailReference, ViewMailDraft, ViewMail, MailAttachment, PartialViewMail } from '../../domain/';
import { Attachment, FileAttachment, MailFolder, Message, Recipient, NullableOption } from '@microsoft/microsoft-graph-types';
import { MicrosoftGraphAuthProvider } from '../../../Common/infra/services/microsoft/MicrosoftGraphAuthProvider';
import { stripHtml } from 'string-strip-html';
import { Attendee } from '../../../Contact';

export class OutlookService implements MailService<MicrosoftAccountInfo> {
  protected connections = new Map<Uuid, MicrosoftGraphClient>();

  constructor(
    protected readonly credentials: EncryptedCredentialsRepo,
    protected readonly crypto: Crypto,
    protected clientId: string,
    protected readonly fileManager: FileManagerService
  ) {}

  static extractAttendee(recipient: NullableOption<Recipient> | undefined): Attendee {
    return {
      display_name: recipient?.emailAddress?.name?.toString(),
      identifier: recipient?.emailAddress?.address?.toString() || '?',
      identifier_type: 'EMAIL_ADDRESS',
    };
  }

  static convertFileAttachment(att: FileAttachment): LocalFile {
    if (!att.id) throw new Error('Outlook attachment does not have an ID');
    return {
      data: att.contentBytes?.toString() || '',
      id: att.id,
      name: att.name || 'unknown',
      extension: att.name?.split('.')[1] || '',
      size: att.size || 0,
      mime: att.contentType || 'application/octet-stream', // https://stackoverflow.com/questions/1176022/unknown-file-type-mime
    };
  }

  static convertAttachmentsMetadata(att: Attachment): MailAttachment {
    if (!att.id) throw new Error('Outlook attachment does not have an ID');
    return {
      id: att.id,
      name: att.name || 'unknown',
      extension: att.name?.split('.')[1] || '',
      size: att.size || 0,
      mime: att.contentType || 'application/octet-stream', // https://stackoverflow.com/questions/1176022/unknown-file-type-mime
    };
  }

  static convertMessage(message: Message, account_id: Uuid): NewMailDTO {
    if (!message.receivedDateTime || !message.id) throw new Error('');
    return {
      account_id,
      date: toUTCDateTimeMs(message.receivedDateTime),
      from_attendee: OutlookService.extractAttendee(message.from),
      to_attendees: message.toRecipients?.map(OutlookService.extractAttendee) || [],
      bcc_attendees: message.bccRecipients?.map(OutlookService.extractAttendee) || [],
      cc_attendees: message.ccRecipients?.map(OutlookService.extractAttendee) || [],
      reply_to_attendees: message.replyTo?.map(OutlookService.extractAttendee) || [],
      provider_id: message.id,
      has_attachments: message.hasAttachments || false,
      subject: message.subject?.toString() || '',
      body: message.body?.content || '',
      body_plain: message.body?.content ? stripHtml(message.body?.content).result : '',
      attachments: [],
    };
  }

  registerAccount(account_id: Uuid, connection_params: MicrosoftAccountInfo, credentialsKey: EncryptionCryptoKey): boolean | void {
    this.connections.set(
      account_id,
      new MicrosoftGraphClient(
        new MicrosoftGraphAuthProvider(
          this.clientId,
          async (account_id: string) => {
            const crypto = this.crypto;
            const encryptedCredentials = await this.credentials.get(account_id as Uuid);

            if (!encryptedCredentials) {
              return null;
            }

            const parsed = JSON.parse(await crypto.decrypt(credentialsKey, encryptedCredentials.value));

            return (parsed as OutlookCredentials).devices.web || null;
          },
          account_id
        )
      )
    );
    return true;
  }

  unregisterAccount(account_id: Uuid) {
    this.connections.delete(account_id);
  }

  private getConnection(account_id: Uuid): MicrosoftGraphClient {
    const connection = this.connections.get(account_id);
    if (!connection) throw new Error('Imap connection missing');
    return connection;
  }

  async searchMails(account_id: Uuid, query: MailSearchQuery): Promise<NewMailDTO[]> {
    let mails: Message[] = [];

    const client = this.getConnection(account_id).client;

    let folderName = '';

    /**
     * @note Greg : I fixed the missing break and assigned folderName = 'Inbox'
     *              in the default case as per Marco's instructions.
     *              Right now it looks like it always assigns folderName = 'Inbox'
     *              but that may change if more cases are added ?
     */
    switch (query.mailbox_name) {
      case 'INBOX':
        folderName = 'Inbox';
        break;
      default:
        folderName = 'Inbox';
        break;
    }

    const request = client
      .api(`/me/mailFolders('${folderName}')/messages`) // Select only Inbox messages
      .top(100) // Search 100 by 100
      .select('id,receivedDateTime,from,toRecipients,subject,body,hasAttachments') // Select used fiels only for performances
      .orderby('receivedDateTime') // Order by date
      .filter('isDraft ne true'); // Remove drafts because this method is not supposed to retrieve them

    // Add filters
    if (query.after_date) request.filter('receivedDateTime gt ' + query.after_date);
    if (query.before_date) request.filter('receivedDateTime lt ' + query.before_date);
    if (query.body) request.search('body:' + query.body);
    if (query.subject) request.search('subject:' + query.subject);
    if (query.from) request.search('from:' + query.from);

    /**
     * @TODO Handle other query paramas if needed
     */

    // Execute the request
    const results = await request.get();

    mails = mails.concat(results.value);

    // Fetch recursively next pages with nextLink until there is no more results
    const fetchNextPages = async (link: string) => {
      const results = await client.api(link).get();
      mails = mails.concat(results.value);
      if (results['@odata.nextLink']) await fetchNextPages(results['@odata.nextLink']);
    };

    // If response has a nextLink attribute, that mean there is more than 100 results
    if (results['@odata.nextLink']) await fetchNextPages(results['@odata.nextLink']);

    try {
      const formatted: (NewMailDTO | null)[] = await Promise.all(
        mails.map(async (mail) => {
          let attachments: Attachment[] = [];
          if (mail.hasAttachments) {
            const res = await client.api(`/me/messages/${mail.id}/attachments`).select('id,name,size,contentType').get();
            attachments = res.value;
          }

          return {
            ...OutlookService.convertMessage(mail, account_id),
            attachments: attachments.map(OutlookService.convertAttachmentsMetadata),
          };
        })
      );

      return formatted.filter(notEmpty);
    } catch (e) {
      (e as Error).name = 'Unknown format received by Microsoft API';
      throw e;
      // throw new Error('Unknown format received by Microsoft API' + e.message);
    }
  }

  async getMailsList(account_id: Uuid, mailbox_name: string, offset?: number, limit?: number): Promise<NewMailDTO[]> {
    const request = this.getConnection(account_id).client.api(`/me/mailFolders/${mailbox_name}/messages`);

    if (offset) request.skip(offset);
    if (limit) request.top(limit);

    const res = await request.get();

    return (res.value as Message[]).map((mess) => OutlookService.convertMessage(mess, account_id));
  }

  private async _fetchMail(mail: MailReference): Promise<Message> {
    const client = this.getConnection(mail.account_id).client;

    const res: Message = await client.api(`/me/messages/${mail.provider_id}`).get();

    return res;
  }

  private async _downloadAttachments(mail: MailReference): Promise<FileAttachment[]> {
    const client = this.getConnection(mail.account_id).client;

    const res = await client.api(`/me/messages/${mail.provider_id}/attachments`).get();

    return res.value;
  }

  private async _fetchAttachments(mail: MailReference): Promise<Attachment[]> {
    const client = this.getConnection(mail.account_id).client;

    const res = await client.api(`/me/messages/${mail.id}/attachments`).select('id,name,size,contentType').get();

    return res.value;
  }

  async fetchMail(mail: MailReference, send_attachments = false): Promise<{ mail: ViewMail; files: LocalFile[] | undefined }> {
    const message = await this._fetchMail(mail);

    let files: LocalFile[] | undefined = undefined;
    let attachments: MailAttachment[] = [];

    // If we ask attachment data
    if (send_attachments) {
      if (message.hasAttachments) {
        const result = await this._downloadAttachments(mail);
        // Create files
        files = result.map(OutlookService.convertFileAttachment);
        // Create attachment metadata
        attachments = result.map(OutlookService.convertAttachmentsMetadata);
      } else {
        files = [];
      }
    } // If we don't ask attachment data, we still need to request metadata
    else {
      const result = await this._fetchAttachments(mail);
      attachments = result.map(OutlookService.convertAttachmentsMetadata);
    }

    return {
      files,
      mail: {
        id: mail.id,
        ...OutlookService.convertMessage(message, mail.account_id),
        attachments,
      },
    };
  }

  async fetchMailsMeta(mails: MailReference[], onFetch: (results: ViewMail[]) => MaybePromise<void>): Promise<void> {
    const chunks = chunkArray(mails, 3);

    for (const chunk of chunks) {
      const messages = await Promise.all(chunk.map((mail) => this._fetchMail(mail)));
      await onFetch(
        messages.map((mess, index) => ({
          id: chunk[index].id,
          ...OutlookService.convertMessage(mess, mails[index].account_id),
        }))
      );
    }
  }

  async sendMail(account_id: Uuid, mail_draft: ViewMailDraft): Promise<boolean> {
    const client = this.getConnection(account_id).client;

    const attachments = mail_draft.attachment_ids
      ? await Promise.all(mail_draft.attachment_ids.map((attachment_id) => this.fileManager.get(attachment_id, true)))
      : [];

    if (!mail_draft.from_attendee || !mail_draft.to_attendees) throw new Error('Incomplete ViewMailDraft to send');

    await client.api('/me/sendMail').post({
      message: {
        body: {
          contentType: 'html',
          content: mail_draft.body,
        },
        subject: mail_draft.subject,
        attachments: attachments.filter(notEmpty).map((attachment) => ({
          '@odata.type': '#microsoft.graph.fileAttachment',
          name: attachment.name,
          contentBytes: attachment.data,
        })),
        bccRecipients: mail_draft.bcc_attendees
          ? mail_draft.bcc_attendees.map((att) => ({
              emailAddress: {
                address: att.identifier,
              },
            }))
          : undefined,
        ccRecipients: mail_draft.cc_attendees
          ? mail_draft.cc_attendees.map((att) => ({
              emailAddress: {
                address: att.identifier,
              },
            }))
          : undefined,
        toRecipients: mail_draft.to_attendees
          ? mail_draft.to_attendees.map((att) => ({
              emailAddress: {
                address: att.identifier,
              },
            }))
          : undefined,
      } as Message,
    });

    return true;
  }

  async archiveMail(account_id: Uuid, mail: MailReference): Promise<void> {
    const archiveFolderName = 'ARCHIVE UNIPILE';

    const client = this.getConnection(account_id).client;

    // Retrieve the folders list
    const results = await client.api(`/me/mailFolders`).filter(`displayName eq '${archiveFolderName}'`).get();

    let archiveFolderId = results?.value?.[0]?.id;

    // If the archive folder does not exists, create it
    if (!archiveFolderId) {
      const result: MailFolder = await client.api('/me/mailFolders').post({
        displayName: archiveFolderName,
        isHidden: false,
      });

      archiveFolderId = result.id;
    }

    this.getConnection(account_id).client.api(`/me/messages/${mail.provider_id}/move`).post({
      destinationId: archiveFolderId,
    });
  }

  async getMailboxes(account_id: Uuid): Promise<MailBox[]> {
    const client = this.getConnection(account_id).client;

    const results = await client.api(`/me/mailFolders`).get();

    return (results.value as MailFolder[]).map((folder) => ({
      id: folder.id || '',
      label: folder.displayName || '',
      nb_mails: folder.totalItemCount || 0,
    }));
  }
}
