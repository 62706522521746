import { ApiDomainEvent } from '../services/BackupService';
import { ApiDomainEventDraft, BackupCursors, BackupRepo } from '../../domain/BackupRepo';
import { SingleOrArray } from '../../utils';
import { BackupCursorRepo } from '../../domain/BackupCursorRepo';
import { ApiService } from '../../../User/infra/services/ApiService';
import { Uuid } from '../../domain/Uuid';
import { SuccessResponse } from '../../../User/infra/services/CoreApiTypes';

/**
 *
 */
export class ApiBackupRepo implements BackupRepo {
  /**
   *
   */
  constructor(
    private readonly user_id: number,
    private readonly cursorRepo: BackupCursorRepo,
    private readonly api: ApiService
  ) {}

  /**
   *
   */
  async push(apiEvents: SingleOrArray<ApiDomainEventDraft>): Promise<void> {
    if (!Array.isArray(apiEvents)) {
      apiEvents = [apiEvents];
    }
    // console.log(apiEvents);
    await this.api.pushDomainEvents(apiEvents);
  }

  /**
   * @todo Validate incoming Data ! There are validation
   *       libraries for parsed json.
   *
   *      see https://ajv.js.org/
   */
  async pull(fromId = 0, count = 100): Promise<ApiDomainEvent[]> {
    return this.api.getDomainEvents(this.user_id, fromId, count) as Promise<ApiDomainEvent[]>;
  }

  /**
   *
   */
  async getCursors(): Promise<BackupCursors> {
    return this.cursorRepo.get();
  }

  /**
   *
   */
  async putCursors(cursors: BackupCursors): Promise<void> {
    return this.cursorRepo.put(cursors);
  }

  /**
   *
   */
  async getBranchId(): Promise<Uuid> {
    return this.cursorRepo.getBranchId();
  }

  /**
   * @note We have to use the pull cursor to get a viable to_id : API Core will
   *       error if we issue an id that doesn't exist or if no to_id is supplied.
   *
   *       Also, relying on 1 as the starting event will work until the event with
   *       id : 1 is perma-deleted from API Core DB.
   *
   *       from_id: 0 is an immediate error ( The event doesn't exist on API Core ).
   *
   *       Event are not actually deleted, they're marked as deleted, so a repeated
   *       delete shouldn't error.
   *
   *       Events id are auto-incremented and some events have been manually
   *       perma-deleted from API Core DB, so it has gaps.
   *
   * @todo Ask JM : Spec and request a different API / filters for domain event deletion.
   *
   * @todo Replace with more precise pathological aggregate timeline deletion ?
   */
  async deleteAllRemoteEventsStopGap(): Promise<SuccessResponse> {
    // const {pull : { lastEventId }} = await this.getCursors();
    return this.api.resetDomainEvents(this.user_id);
  }
}
