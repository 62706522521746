import { AggregateRepo, DomainEventStore } from '../../../Common';
import { MessageModel } from '../../domain';

/**
 * Repository.
 */
export class MessageModelRepo extends AggregateRepo<MessageModel> {
  constructor(eventStore: DomainEventStore) {
    super(MessageModel, eventStore);
  }
}
