import dayjs from 'dayjs';
import { IDBPDatabase } from 'idb';
import { expandRRuleStrings } from '../../../Common/domain/RRule';

import {
  toUTCDateTimeBasic,
  toUTCDateTimeMs,
  toUTCDate,
  UTCDate,
  UTCDateTimeMs,
  toUTCDateBasic,
  Uuid,
  UnixTimeMs,
  mockUuid,
} from '../../../Common';
import { DBSchema, Idb } from '../../../Common/infra/services/idb';
import {
  CalendarEventViewDTO,
  CalendarEventInstanceId,
  createViewCalendarEvent,
  NewCalendarEventDTO,
  ViewCalendarEvent,
  GoogleCalendarAttendee,
  CalendarEventStatus,
  CalendarEventDTO,
  CalendarEventType,
  UnipileEventAttendee,
} from '../../domain';
import { toUnipileCalendarEventInstanceId, UnipileCalendarEventInstanceId } from '../../domain/CalendarEventInstance';
import { CalendarEventException } from '../../domain/types/CalendarEventException';
import {
  createRecurringCalendarEventRoot,
  RecurringCalendarEventRoot,
} from '../../domain/projections/RecurringCalendarEventRoot';
import { PatchRecurringCalendarEventRootDTO, RecurringCalendarEventDTO } from '../../domain/types/RecurringCalendarEventDTO';
import { ViewCalendarEventRepo } from '../../domain/projections/ViewCalendarEventRepo';
import { deepEqual } from 'fast-equals';
import { AllKeys, notEmpty } from '../../../Common/utils';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { GoogleCalendarEventId } from '../services/GoogleCalendarEventId';
import {
  GoogleCalendarEventAllDaySplitRecurringId,
  toGoogleCalendarEventInstanceId,
  toGoogleCalendarEventAllDayInstanceId,
} from '../../domain/CalendarEventInstance';
import { Taggable, ViewTagRelationRepo, ViewTagRepo, ViewTagRelation } from '../../../Tag';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

/**
 * Repository.
 *
 * @todo Refactor and extract all the logic that is not layer specific, here meaning specific
 *       to Idb, to a bunch of injected strategies. Re-use in future layers.
 */
export class IdbViewCalendarEventRepo implements ViewCalendarEventRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   *
   * @todo Consider if the idb schema version is something that the repo should
   *       know/care about, e.g. IDBPDatabase<DBSchema>.
   *         -> Sure does if you want types and autocompletion and make sure
   *            the schema is compatible.
   */
  constructor(private readonly viewTagRelation: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  /**
   * @todo Consider specialized diff method per CalendarEvent type.
   *
   * @todo Consider diffing Attendees separately, only if needed.
   */
  private _diffPatch(patch: PatchRecurringCalendarEventRootDTO & { kind: 'RECURRING' }, root: RecurringCalendarEventRoot) {
    /** Diff values and root first and ONCE ! */
    const changes = new Set(
      Object.keys(patch).filter((k) => patch[k as keyof typeof patch] !== root[k as keyof typeof root])
    ) as Set<AllKeys<PatchRecurringCalendarEventRootDTO>>;

    changes.delete('recurrence');
    changes.delete('attendees');

    /** Diff attendees individually. */
    const addedAttendees: Map<string, GoogleCalendarAttendee | UnipileEventAttendee> = new Map();
    const deletedAttendees: Map<string, GoogleCalendarAttendee | UnipileEventAttendee> = new Map();
    const patchedAttendees: Map<string, GoogleCalendarAttendee | UnipileEventAttendee> = new Map();
    if (
      /** @todo Refactor to allow injected strategy per calendar type. */
      // ((root.type === 'GOOGLE' && patch.type === 'GOOGLE') || (root.type === 'UNIPILE' && patch.type === 'UNIPILE')) &&
      patch.attendees
      //   && root.attendees.length
    ) {
      patch.attendees.map((a) => patchedAttendees.set(a.email, a));
      root.attendees.map((a) => deletedAttendees.set(a.email, a));

      for (const [email, attendee] of patchedAttendees) {
        const rootAttendee = deletedAttendees.get(email);
        if (!rootAttendee) {
          /** The added map starts empty, each attendee that doesn't exist on root is added. */
          addedAttendees.set(email, attendee);
          /** The patched map start full, each attendee that did not exist on root is removed. */
          patchedAttendees.delete(email);
        } else {
          if (deepEqual(attendee, rootAttendee)) {
            /** The patched map start full, each attendee that is not a change is removed. */
            patchedAttendees.delete(email);
          }
        }
        /** The deleted map start 'full', each attendee seen in the patch is removed. */
        deletedAttendees.delete(email);
      }
    }

    // console.log('_diffPatch', patch, root);
    // console.log('changes', changes);
    // console.log('addedAttendees', addedAttendees);
    // console.log('deletedAttendees', deletedAttendees);
    // console.log('patchedAttendees', patchedAttendees);

    return [changes, addedAttendees, deletedAttendees, patchedAttendees] as const;
  }

  /**
   * @todo Consider specialized diff method per CalendarEvent type.
   */
  private _patchExceptions(
    patch: PatchRecurringCalendarEventRootDTO & { kind: 'RECURRING' },
    exceptions: Map<UnipileCalendarEventInstanceId, CalendarEventException>,
    changes: ReturnType<IdbViewCalendarEventRepo['_diffPatch']>[0],
    addedAttendees: ReturnType<IdbViewCalendarEventRepo['_diffPatch']>[1] = new Map(),
    deletedAttendees: ReturnType<IdbViewCalendarEventRepo['_diffPatch']>[2] = new Map(),
    patchedAttendees: ReturnType<IdbViewCalendarEventRepo['_diffPatch']>[3] = new Map()
  ) {
    const patchedExceptions: Map<CalendarEventInstanceId, CalendarEventException> = patch.exceptions ?? new Map();

    /** Reminders patches never overwrite exceptions. */
    changes.delete('reminders'); /** @todo Don't mutate given changes map. */

    for (const [id, exception] of exceptions) {
      /** Shallow copy to avoid mutating given exceptions. */
      const patched = { ...exception };
      /** Patch exception, i.e. : revert to root by removing the keys that changed on the exception. */
      for (const k of changes) {
        delete patched[k as keyof CalendarEventException];
      }

      /** Patch exception's attendees. */
      if (
        /** @todo Refactor to allow injected strategy per calendar type. */
        // patch.type === 'GOOGLE' &&
        'attendees' in patched &&
        // && exception.attendees
        (addedAttendees.size || deletedAttendees.size || patchedAttendees.size)
      ) {
        const exceptionAttendees = new Map(
          /** @note Quieting compiler not being as strict as core. */
          (patched.attendees as (GoogleCalendarAttendee | UnipileEventAttendee)[] | undefined)?.map((a) => [a.email, a])
        );

        for (const email of deletedAttendees.keys()) {
          exceptionAttendees.delete(email);
        }

        for (const [email, attendee] of patchedAttendees) {
          if (exceptionAttendees.has(email)) {
            exceptionAttendees.set(email, attendee);
          }
        }

        for (const [email, attendee] of addedAttendees) {
          exceptionAttendees.set(email, attendee);
        }

        patched.attendees = [...exceptionAttendees.values()];
        // console.log('exception.attendees', patched.attendees);
      }

      patchedExceptions.set(id, patched);
    }

    return patchedExceptions;
  }

  /**
   * Expand instances for given recurring calendar event.
   */
  async expand(
    aggregateId: Uuid,
    event: RecurringCalendarEventDTO & { kind: 'RECURRING' },
    //   /** @todo Fix the types that expand accepts as event. */
    //   | (NewCalendarEventDTO & { kind: 'RECURRING' })
    //   | (AcknowledgeCalendarEventDTO & { kind: 'RECURRING' })
    exceptions?: Map<UnipileCalendarEventInstanceId, CalendarEventException>
  ): Promise<void> {
    const instanceDates = expandRRuleStrings(event.recurrence, event.start, undefined, event.start_tzid);
    // console.log(instanceDates);

    const eventDuration = dayjs(event.end).diff(event.start, 'millisecond');
    const { recurrence, kind, ...stripped } = event;

    switch (stripped.type) {
      case 'UNIPILE': {
        const instances: ViewCalendarEvent[] = instanceDates.map((date) => {
          const start = dayjs(date);
          const end = start.add(eventDuration, 'millisecond');
          const instanceId = toUnipileCalendarEventInstanceId(aggregateId, toUTCDateTimeBasic(start));
          const exception = exceptions?.get(instanceId);

          return createViewCalendarEvent(
            aggregateId,
            {
              ...stripped,
              kind: 'INSTANCE',
              start: toUTCDateTimeMs(start),
              end: toUTCDateTimeMs(end),
              original_start: start.valueOf() as UnixTimeMs,
              original_tzid: stripped.start_tzid,
              /** @todo Figure out if there is a way to reject mismatched excess props. */
              // recurrence : [],
              ...exception,
            },
            instanceId
          );
        });
        return this.addMany(instances);
        // return instances;
      }
      case 'GOOGLE': {
        /**
         * @todo Look for a nicer way to handle GoogleCalendarEventAllDaySplitRecurringId :
         *
         *      recurringEventId / external_recurring_event_id : "7pa1js9e0hchpea7ck82cq484u_R20211216"
         *
         *      yields
         *
         *      id / external_id : "7pa1js9e0hchpea7ck82cq484u_20211218".
         */
        const instances: ViewCalendarEvent[] = instanceDates.map((date) => {
          const start = dayjs(date);
          const end = start.add(eventDuration, 'millisecond');
          const instanceId = toUnipileCalendarEventInstanceId(aggregateId, toUTCDateTimeBasic(start));
          const exception = exceptions?.get(instanceId);

          return createViewCalendarEvent(
            aggregateId,
            {
              ...stripped,
              external_recurring_event_id: stripped.external_id as
                | GoogleCalendarEventId
                | GoogleCalendarEventAllDaySplitRecurringId,
              /** @note external_id is now mandatory, conditional expansion no longer needed. */
              external_id: stripped.all_day
                ? toGoogleCalendarEventAllDayInstanceId(
                    stripped.external_id.split('_')[0] as GoogleCalendarEventId,
                    toUTCDateBasic(start)
                  )
                : toGoogleCalendarEventInstanceId(
                    stripped.external_id.split('_')[0] as GoogleCalendarEventId,
                    toUTCDateTimeBasic(start)
                  ),
              kind: 'INSTANCE',
              start: toUTCDateTimeMs(start),
              end: toUTCDateTimeMs(end),
              original_start: start.valueOf() as UnixTimeMs,
              original_tzid: stripped.start_tzid,
              /** @todo Figure out if there is a way to reject mismatched excess props. */
              // recurrence : [],
              ...exception,
              /** @todo Find a way to help TS and get rid of this assertion. */
            } as CalendarEventDTO,
            instanceId
          );
        });
        return this.addMany(instances);
        // return instances;
      }
    }
  }

  /**
   * Re-expand instances for given recurring calendar event.
   *
   * @note 'Relevant' means : the instance id is still valid and can be found
   *       amongst the newly expanded set !
   *
   * @note There are no relevant exceptions if all_day changed.
   *       Google Calendar does something different but definitely buggy where
   *       legit instances end up disappearing altogether, skip.
   *
   * @todo Study not caching exceptions with recurringRoots and just having them tagged as
   *       exceptions amongst the instances in viewCalendarEvent store ?
   *
   * @todo Consider merging reExpand/updateRecurringEvent with a mustExpand : boolean,
   *       if the only difference ends up being the discardedIds/relevantExceptions.
   *
   * @todo Figure out if we can get away with a single format for InstanceIds
   *       or if there is a good reason to have a different format for allDay
   *       instances ?
   *
   * @todo Find a way to help TS and get rid of as RecurringCalendarEventDTO
   *       assertions.
   *
   * @note If there are relevant exceptions
   *       and event duration changed ( reprojected end is # from original end ? ),
   *       default end
   *       If exception start is not valid for default end,
   *       default start.
   *
   * @note Doesn't handle exceptions passed in the patch !!!
   *
   * @todo Consider handling exceptions passed in the patch !!!
   *
   * @todo Handle tzid causing exception to be discarded ? Study Google Calendar
   *       behaviour.
   *
   * @note Google Calendar seems to keep exceptions if og recurring event TZID
   *       changes with a compensating start/end change that yield the same
   *       underlying UTC start/end datetimes. All exceptions were returned
   *       with new etags, yet no other visible changes in the following pull
   *       with a sync_token.
   *       See https://github.com/pozorfluo/gcal-seq/blob/main/GoogleCalendarRecurringEvent/TzidChangeEquivalentUTC.json
   */
  async reExpand(
    aggregateId: Uuid,
    patch: PatchRecurringCalendarEventRootDTO & { kind: 'RECURRING' }
  ): Promise<void | [string, void, void]> {
    const tx = Idb.transaction(['recurringCalendarEvent', 'viewCalendarEvents'], 'readwrite');
    const rootStore = tx.objectStore('recurringCalendarEvent');
    const viewStore = tx.objectStore('viewCalendarEvents');

    const root = await rootStore.get(aggregateId);

    /**
     * @todo Figure out a way to call expand rather than reExpand earlier
     *       upstream. The specific situation addressed here is a SINGLE event
     *       later edited to become a RECURRING event.
     */
    if (!root) {
      const single = await viewStore.get(aggregateId);
      if (!single) {
        throw new Error(`Invalid id : RecurringCalendarEventRoot or ViewCalendarEvent ${aggregateId} does not exist.`);
      }

      return Promise.all([
        rootStore.put(
          createRecurringCalendarEventRoot(
            aggregateId,
            { ...single, ...patch } as RecurringCalendarEventDTO,
            patch.exceptions
            // patch.exceptions ? [...patch.exceptions] : []
          )
        ),
        viewStore.delete(aggregateId),
        this.expand(aggregateId, { ...single, ...patch } as RecurringCalendarEventDTO & { kind: 'RECURRING' }),
      ]);
    }

    /** Expand new instances dates and ids. */
    const { start = root.start, end = root.end, start_tzid = root.start_tzid, end_tzid, ...strippedPatch } = patch;
    const duration = dayjs(end).diff(start, 'millisecond');

    const instanceDates = new Map(
      expandRRuleStrings(patch.recurrence, start, undefined, start_tzid).map((date) => {
        const instanceStart = dayjs(date.getTime());
        return [
          toUnipileCalendarEventInstanceId(aggregateId, toUTCDateTimeBasic(instanceStart)),
          {
            start: toUTCDateTimeMs(instanceStart),
            end: toUTCDateTimeMs(instanceStart.add(duration, 'millisecond')),
            original_start: instanceStart.valueOf() as UnixTimeMs,
          },
        ] as const;
      })
    );

    /** Discard old instances. */
    const discardedIds = (await viewStore.index('by-recurring-event').getAllKeys(IDBKeyRange.only(aggregateId))).filter(
      (id) => !instanceDates.has(id as CalendarEventInstanceId)
    );

    for (let i = 0, length = discardedIds.length; i < length; i++) {
      viewStore.delete(discardedIds[i]);
    }

    /** Keep and update existing exceptions that are still relevant. */
    const relevantExceptions =
      !('all_day' in patch) || patch.all_day === root.all_day
        ? this._patchExceptions(
            patch,
            new Map([...root.exceptions].filter(([id]) => instanceDates.has(id))),
            ...this._diffPatch(strippedPatch, root)
          )
        : new Map<CalendarEventInstanceId, CalendarEventException>();
    // : new Map<UnipileCalendarEventInstanceId, CalendarEventException>();

    /**
     * @todo Weight refactoring to avoid traversing relevantException again
     *       vs not needing to do this at all most of the time.
     */
    if (
      (patch.start || patch.end) &&
      relevantExceptions.size &&
      /** This numbers comparison should be safe : dayjs.diff() returns truncated numbers. */
      duration !== dayjs(root.end).diff(root.start, 'millisecond')
    ) {
      for (const [id, exception] of relevantExceptions) {
        /** By construction the id exists in instanceDates. */
        const instanceDate = instanceDates.get(id);
        const reExpandedEnd = instanceDate?.end as UTCDateTimeMs;
        // const reExpandedEnd = instanceDates.get(id)?.end as UTCDateTimeMs;
        // To get the proper TS Error : if(exception.end) {
        // delete exception.end;
        exception.end = reExpandedEnd;
        if (exception?.start && exception.start > reExpandedEnd) {
          // delete exception.start;
          exception.start = instanceDate?.start as UTCDateTimeMs;
        }
      }
    }

    /** Update the recurring root. */
    const updatedRoot = createRecurringCalendarEventRoot(
      aggregateId,
      {
        ...root,
        ...patch,
      } as RecurringCalendarEventDTO,
      relevantExceptions
      //   [...relevantExceptions]
    );

    rootStore.put(updatedRoot);

    /** Expand new instances set with exceptions applied. */
    const { recurrence, exceptions, ...strippedRoot } = updatedRoot as RecurringCalendarEventRoot & { kind: 'RECURRING' };

    for (const [id, { start, end, original_start }] of instanceDates) {
      const exception = exceptions.get(id);
      if (exception?.status !== 'DELETED') {
        switch (strippedRoot.type) {
          case 'UNIPILE':
            viewStore.put(
              createViewCalendarEvent(
                aggregateId,
                {
                  ...strippedRoot,
                  kind: 'INSTANCE',
                  start,
                  end,
                  original_start,
                  original_tzid: strippedRoot.start_tzid,
                  ...exception,
                  // ...exceptions.get(id),
                },
                id
              )
            );
            break;
          case 'GOOGLE':
            /**
             * @todo Look for nicer way to conditionally expand instances external ids.
             *       Something like 'hoisting' the switch : a function to expand a DTO from a 'create'
             *       event, another from a 'ack' event ?
             *
             * @todo Look for a nicer way to handle GoogleCalendarEventAllDaySplitRecurringId :
             *
             *      recurringEventId / external_recurring_event_id : "7pa1js9e0hchpea7ck82cq484u_R20211216"
             *
             *      yields
             *
             *      id / external_id : "7pa1js9e0hchpea7ck82cq484u_20211218".
             *
             * @todo Double check that Google picks the id format of the instance based on the original
             *       recurring root 'allDay' status.
             */
            viewStore.put(
              createViewCalendarEvent(
                aggregateId,
                {
                  ...strippedRoot,
                  external_recurring_event_id: strippedRoot.external_id as
                    | GoogleCalendarEventId
                    | GoogleCalendarEventAllDaySplitRecurringId,
                  external_id: strippedRoot.all_day
                    ? toGoogleCalendarEventAllDayInstanceId(
                        strippedRoot.external_id.split('_')[0] as GoogleCalendarEventId,
                        toUTCDateBasic(start)
                      )
                    : toGoogleCalendarEventInstanceId(
                        strippedRoot.external_id.split('_')[0] as GoogleCalendarEventId,
                        toUTCDateTimeBasic(start)
                      ),
                  /** @note external_id is now mandatory, conditional expansion no longer needed. */
                  //   ...(strippedRoot.external_id && {
                  //     external_recurring_event_id: strippedRoot.external_id as
                  //       | GoogleCalendarEventId
                  //       | GoogleCalendarEventAllDaySplitRecurringId,
                  //     external_id: strippedRoot.all_day
                  //       ? toGoogleCalendarEventAllDayInstanceId(
                  //           strippedRoot.external_id.split('_')[0] as GoogleCalendarEventId,
                  //           toUTCDateBasic(start)
                  //         )
                  //       : toGoogleCalendarEventInstanceId(
                  //           strippedRoot.external_id.split('_')[0] as GoogleCalendarEventId,
                  //           toUTCDateTimeBasic(start)
                  //         ),
                  //   }),
                  kind: 'INSTANCE',
                  start,
                  end,
                  original_start,
                  original_tzid: strippedRoot.start_tzid,
                  ...exception,
                  // ...exceptions.get(id),
                  /** @todo Find a way to help TS and get rid of this assertion. */
                } as CalendarEventDTO,
                id
              )
            );
            break;
        }
      }
    }

    return tx.done;
  }

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection
   *       with existing id'.
   */
  async add(projection: ViewCalendarEvent): Promise<void> {
    const tx = Idb.transaction('viewCalendarEvents', 'readwrite', { durability: 'relaxed' });
    tx.store.put(projection);
    return tx.done;
  }

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection
   *       with existing id'.
   */
  async addRecurringRoot(projection: RecurringCalendarEventRoot): Promise<void> {
    const tx = Idb.transaction('recurringCalendarEvent', 'readwrite', { durability: 'relaxed' });
    // await Promise.all([tx.store.put(projection), tx.done]);
    tx.store.put(projection);
    return tx.done;
  }

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with
   *       existing id'.
   */
  async addMany(projections: ViewCalendarEvent[]): Promise<void> {
    const tx = Idb.transaction('viewCalendarEvents', 'readwrite', { durability: 'relaxed' });

    for (let i = 0, length = projections.length; i < length; i++) {
      tx.store.put(projections[i]);
    }
    return tx.done;
  }

  /**
   * @todo Figure out why typescript doesn't complain about potential type
   *       mismatch.
   */
  async patch(id: Uuid | UnipileCalendarEventInstanceId, values: CalendarEventViewDTO): Promise<void> {
    const tx = Idb.transaction('viewCalendarEvents', 'readwrite', { durability: 'relaxed' });
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewCalendarEvent ${id} does not exist.`);
    }

    // const o = { ...old_projection, ...values };
    // if (old_projection.kind === 'RECURRING') {
    //     // This should never happen ! Single or Instance only here !
    // }

    /**
     * @todo See if createViewCalendarEvent is really necessary here, aren't we
     *       doing "Check invariant upstream !"  ?
     */
    tx.store.put(
      //   createViewCalendarEvent(
      //     old_projection.kind === 'INSTANCE' ? old_projection.recurring_event_id : old_projection.id,
      //     { ...old_projection, ...values}
      //   )

      {
        ...old_projection,
        ...values,
        id,
        /**
         * @todo Find a way to square this and explain to TS it's ok, or admit
         *       that TS is right and there's a problem :)
         */
      } as ViewCalendarEvent
    );
    return tx.done;
  }

  /**
   * @todo Consider doing the same thing in Agg root and rejecting on 'no change'.
   *
   * @todo Consider you may not need to deepEqual props as reminders never overwrite
   *       exceptions and attendees get special individual treatment.
   *
   * @todo Doublecheck that the standard says it's safe to modify collection
   *       during iteration.
   *       See https://262.ecma-international.org/12.0/#sec-map.prototype.foreach
   *
   * @todo Be more specific about the comparisons ?
   *
   * @todo Figure out a typesafe-ish way to handle this. For starters the exception
   *       collection could use/get linked to the root's type ?
   *
   * @todo Figure out what is supposed to happen if values.exceptions has some
   *       entries. What's the use case ? How should it be dealt with ?
   *
   * @todo Decide between removing an exception property and overwriting with
   *       the default root property ? --> THIS IS DIFFERENT ! Remove it !
   *
   * @todo Consider restricting patch values to never include start/end ?
   *
   * @todo CHECK THAT PUTTING THE DIFF IN AGG ROOT MAKES SENSE WHEN ACKING.
   *
   * @todo Extract methods to calc exceptions patch per provider/ruleset.
   *
   * @note Consider that exception.attendees will completely overwrite
   *       the default root when expanding instances !
   *       [] means NO ATTENDEES ! Not use default attendees.
   *       If you want the instances to default to the root's value,
   *       delete exception.attendees altogether.
   *
   * @note When syncing with Google Calendar API and getting an data for an
   *       instance, it means it's an exception. If no attendees property
   *       is present, and some exist on the root, it means all attendees have
   *       been deleted on the exception.
   *
   * @note Seems like as soon as an exception 'manages' attendees, it is
   *       never going back to defaulting to the root's value !
   *
   * @note Google Calendar will overwrite the optional prop on some attendee
   *       marked as 'needsAction' when the patched attendee is NOT A CHANGE !
   *       Doesn't seem to happen to the organiser. Weird ...
   *
   * @todo Make sure the whole exception patching thing is not triggered
   *       just for 'reminders'. Regular instances must be updated though.
   *
   * When patching root with some values for attendees, we need to distinguish
   * 3 cases :
   *   - added
   *   - deleted
   *   - patched
   *
   *   added
   *     is when the attendee is on the patch and isn't on the root.
   *
   *   deleted
   *     is when the attendee isn't on the patch and is the root.
   *
   *   patched
   *     is when the attendee is both on the patch and the root and
   *     is different on the patch than on the root.
   *
   *   added   -> add to the exception, no matter what.
   *   delete  -> delete from the exception, no matter what.
   *   patched -> patch on the exception only if it exists on the exception.
   *
   * IF
   *   /\ attendee exists on patch
   *   /\ attendee doesn't exist on root
   * THEN
   *   exception(attendee)' = patched(attendee)
   *
   *
   * IF
   *   /\ attendee exists on root
   *   /\ attendee exists on exception
   *   /\ attendee doesn't exist on patch
   * THEN
   *   delete exception(attendee)'
   *
   *
   * IF
   *   /\ attendee exists on patch
   *   /\ attendee exists on root
   *   /\ attendee exists on exception
   *   /\ patch(attendee) # root(attendee)
   * THEN
   *   exception(attendee)' = patched(attendee)
   */
  async patchRecurringEvent(
    recurringEventId: Uuid,
    patch: PatchRecurringCalendarEventRootDTO & { kind: 'RECURRING' }
  ): Promise<void> {
    const tx = Idb.transaction(['recurringCalendarEvent', 'viewCalendarEvents'], 'readwrite', { durability: 'relaxed' });
    const rootStore = tx.objectStore('recurringCalendarEvent');
    const viewStore = tx.objectStore('viewCalendarEvents');

    const root = await rootStore.get(recurringEventId);
    if (!root) {
      throw new Error(`Invalid id : RecurringCalendarEventRoot ${recurringEventId} does not exist.`);
    }

    const [changes, addedAttendees, deletedAttendees, patchedAttendees] = this._diffPatch(patch, root);

    if (changes.size || addedAttendees.size || deletedAttendees.size || patchedAttendees.size) {
      /**
       * Update root's exceptions with given exceptions and changed props.
       */
      const exceptions = this._patchExceptions(
        patch,
        root.exceptions,
        changes,
        addedAttendees,
        deletedAttendees,
        patchedAttendees
      );
      const { recurrence, kind, start, end, start_tzid, end_tzid, exceptions: _, ...strippedPatch } = patch;

      /**
       * Update instances.
       *
       * @note We're not checking for status : 'DELETED' exceptions here.
       *
       *       Either it was removed somewhere else and isn't returned by
       *       index('by-recurring-event').getAll, or, for whatever reason,
       *       it wasn't and needs to be updated.
       */
      (
        (await viewStore.index('by-recurring-event').getAll(IDBKeyRange.only(recurringEventId))) as (ViewCalendarEvent & {
          kind: 'INSTANCE'; // Instances by construction.
        })[]
      ).forEach((instance) => {
        const exception = exceptions.get(instance.id as UnipileCalendarEventInstanceId);

        /**
         * @note Start should never be a change from root.start if we're here !
         *
         * @note This is probably one of the reasons Google Calendar has
         *        originalStartTime on instances.
         *
         * @note Instance start/end may already have exception values applied !
         */
        let reExpandedEnd: UTCDateTimeMs | null = null;
        let defaultedStart: UTCDateTimeMs | null = null;
        if (end) {
          const originalStart = dayjs(instance.original_start);

          const duration = dayjs(end).diff(root.start, 'millisecond');
          reExpandedEnd = toUTCDateTimeMs(originalStart.add(duration, 'millisecond'));
          //   console.log('reExpandedEnd', reExpandedEnd);

          if (exception?.start && exception.start > reExpandedEnd) {
            /**
             * @todo Refactor this so we don't have to further mutate exceptions here...
             *       Leaning on a original_start prop on exceptions/instances
             *       could help with that by making this computable earlier.
             */
            defaultedStart = toUTCDateTimeMs(originalStart);
            /** @todo Find another way to handle defaulting start/end than quieting TS. */
            delete exception['start' as keyof CalendarEventException];
            // delete exception.start;
            // exception.start = defaultedStart;
          }
        }

        viewStore.put({
          ...instance,
          ...strippedPatch,
          /** @todo Consider that applying exceptions after may be less work. */
          //   ...exceptions.get(instance.id as UnipileCalendarEventInstanceId),
          ...exception,
          ...(reExpandedEnd && {
            end: reExpandedEnd,
            ...(defaultedStart && { start: defaultedStart }),
          }),
          id: instance.id,
          ...(instance.type === 'GOOGLE' && { external_id: instance.external_id }),
        } as ViewCalendarEvent);
      });

      const updatedRoot = createRecurringCalendarEventRoot(
        recurringEventId,
        {
          ...root,
          ...patch,
          /** Overwrite any possible stowaway external_id property on patch. */
          ...(root.type === 'GOOGLE' && { external_id: root.external_id }),
        } as RecurringCalendarEventDTO,
        exceptions
        // [...exceptions]
      );
      rootStore.put(updatedRoot);

      //   console.log('patch', patch);
      //   // console.log('root', root);
      //   console.log('changes', changes);
      //   console.log('exceptions', exceptions);
      //   console.log('updatedRoot', updatedRoot, [...updatedRoot.exceptions.values()][0]);
    }

    return tx.done;
  }

  /**
   * @todo Consider doing something like cancelRecurringRoot instead, mark
   *       the recurring root 'SINGLE' and each exception 'cancelled'.
   */
  async removeRecurringRoot(recurringEventId: Uuid): Promise<RecurringCalendarEventRoot> {
    // const tx = Idb.transaction(['recurringCalendarEvent', 'viewCalendarEvents'], 'readwrite', { durability: 'relaxed' });
    // const root = await tx.objectStore('recurringCalendarEvent').get(recurringEventId);

    const tx = Idb.transaction('recurringCalendarEvent', 'readwrite', { durability: 'relaxed' });
    const root = await tx.store.get(recurringEventId);

    if (!root) {
      throw new Error(`Invalid id : RecurringCalendarEventRoot ${recurringEventId} does not exist.`);
    }

    tx.store.delete(recurringEventId);
    await tx.done;
    return root;
    // return Idb.delete('recurringCalendarEvent', recurringEventId);
  }

  /**
   * @note Clobbers existing exceptions.
   *
   * @todo Consider if putExceptions should also update the instance ? Or probably
   *       only deal with exception on RecurringCalendarEventRoots ?
   *
   * @todo Consider a separate store for exceptions.
   *
   * @todo Spec that Tzid are stamped on exceptions ! Dig deeper to make sure we match Google Calendar.
   *       See https://github.com/pozorfluo/gcal-seq/blob/main/GoogleCalendarRecurringEvent/TzidChangeEquivalentUTC.json
   */
  async putException(
    recurringEventId: Uuid,
    instanceId: CalendarEventInstanceId,
    exception: CalendarEventException
  ): Promise<void> {
    const tx = Idb.transaction('recurringCalendarEvent', 'readwrite', { durability: 'relaxed' });

    const projection = await tx.store.get(recurringEventId);

    if (!projection) {
      throw new Error(`Invalid id : RecurringCalendarEventRoot ${recurringEventId} does not exist.`);
    }

    /** @todo See if there is ways to get some typechecking on Object.assign. */
    // Object.assign(projection.exceptions, exceptions);

    // exceptions.forEach((value, key) => projection.exceptions.set(key, value));
    projection.exceptions.set(instanceId, {
      /** Stamp exception with root start_tzid, end_tzid, as per Google Calendar. */
      // all_day: projection.all_day,
      // ...(exception.all_day === false && { start_tzid: projection.start_tzid, end_tzid: projection.end_tzid }),
      /** Let exception overwrite it. */
      ...exception,
      /**
       * @todo Figure out wether we need this or not.
       *       original_tzid is stamped on instances and follows root
       *       start_tzid. This doesn't match Google Calendar behaviour and
       *       may prove to be misleading later but shouldn't cause problem
       *       for now as it not yet used for anything.
       */
      // original_tzid : projection.end_tzid,
    });

    /** @note await should be unnecessary here. */
    tx.store.put(projection);
    // console.log('putExceptions', projection);
    return tx.done;
  }

  /**
   * @todo Consider using getInstancesOf vs parsing rrules to retrieve current
   *       instances list ?
   *
   * @todo Consider working in chunks.
   *
   * @todo RETRIEVE ROOT AND REAPPLY EXCEPTIONS ?!!! Rename to refreshInstances ?
   *       Do not accept values ? Make it private ?
   */
  async patchInstancesOf(recurringEventId: Uuid, values: Partial<NewCalendarEventDTO>): Promise<void> {
    const instances = (await this.getInstancesOf(recurringEventId)).map(
      (instance) => ({ ...instance, ...values, kind: 'INSTANCE', id: instance.id })
      //       {
      //     ...createViewCalendarEvent(recurringEventId, { ...instance, ...values, kind: 'INSTANCE' }),
      //     id: instance.id,
      //   }
      //   createViewCalendarEvent(recurringEventId, Object.assign(instance, values))
      /**
       * @todo Find a way to square this and explain to TS it's ok, or admit
       *       that TS is right and there's a problem :)
       */
    ) as ViewCalendarEvent[];

    // instances.forEach((instance) => Object.assign(instance, values));

    return this.addMany(instances);
  }

  /**
   * @note This makes no calculation about what the changes means for a recurring
   *       event instances and is meant to be used when acknowledging external
   *       changes with the consequences of said changes acknowledged separately.
   *
   * @todo Consider ditching patchRootOf and 'reducing' to a single command with
   *       all associated exception, etc ... included when issuing commands during
   *       sync with external service. The goal would be to craft a command
   *       that could be yield an event used like CalendarEventEdit.
   *       >> Probably not a good idea because it means we must follow 100% the
   *          external calendar calculations and we can't treat it as 'optimistic
   *          update, rectify later when acking'.
   *       >> There is probably some middle ground where we craft less commands
   *          and can still 'rectify later when acking'.
   */
  async patchRootOf(recurringEventId: Uuid, patch: PatchRecurringCalendarEventRootDTO): Promise<void> {
    const tx = Idb.transaction('recurringCalendarEvent', 'readwrite', { durability: 'relaxed' });
    const old_projection = await tx.store.get(recurringEventId);

    if (!old_projection) {
      throw new Error(`Invalid id : RecurringCalendarEventRoot ${recurringEventId} does not exist.`);
    }

    /** @todo Figure out how to get rid of the type assertion ! */
    tx.store.put({
      ...old_projection,
      ...patch,
      ...(patch.kind === 'RECURRING' && patch.recurrence ? { recurrence: patch.recurrence } : { kind: 'SINGLE' }),
    } as RecurringCalendarEventRoot);

    return tx.done;
  }

  /**
   *
   */
  async patchException(
    recurringEventId: Uuid,
    instanceId: CalendarEventInstanceId,
    patch: CalendarEventException
  ): Promise<void> {
    const tx = Idb.transaction('recurringCalendarEvent', 'readwrite', { durability: 'relaxed' });
    const projection = await tx.store.get(recurringEventId);

    if (!projection) {
      throw new Error(`Invalid id : RecurringCalendarEventRoot ${recurringEventId} does not exist.`);
    }

    /** @note See CalendarEventExceptionEdited stricter requirement. */
    // const { type, ...strippedPatch } = patch as CalendarEventException & {type: CalendarEventType};
    delete (patch as CalendarEventException & { type?: CalendarEventType }).type;

    const old_exception = projection.exceptions.get(instanceId);
    projection.exceptions.set(instanceId, { ...old_exception, ...patch });
    tx.store.put(projection);

    // console.warn(
    //   '>>> patchException',
    //   { recurringEventId, instanceId, patch },
    //   '\nold_exception',
    //   old_exception,
    //   '\npatch',
    //   patch,
    //   '\nresult',
    //   projection.exceptions.get(instanceId)
    // );

    return tx.done;
  }

  /**
   * @note Doesn't cancel a deleted instance !!!
   */
  async cancelException(recurringEventId: Uuid, instanceId: CalendarEventInstanceId): Promise<void> {
    const tx = Idb.transaction(['recurringCalendarEvent', 'viewCalendarEvents'], 'readwrite', {
      durability: 'relaxed',
    });
    const rootStore = tx.objectStore('recurringCalendarEvent');
    const viewStore = tx.objectStore('viewCalendarEvents');

    const root = await rootStore.get(recurringEventId);

    if (!root) {
      throw new Error(`Invalid id : RecurringCalendarEventRoot ${recurringEventId} does not exist.`);
    }

    root.exceptions.delete(instanceId);
    rootStore.put(root);

    /**
     * If it exists, it should be an instance by construction.
     */
    const instance = (await viewStore.get(instanceId)) as (ViewCalendarEvent & { kind: 'INSTANCE' }) | undefined;

    if (!instance) {
      // if (!instance || instance.kind !== 'INSTANCE') {
      throw new Error(`Invalid id : ViewCalendarEvent ${instanceId} does not exist.`);
    }

    const { exceptions, ...stripped } = root;

    const instanceStart = dayjs(instance.original_start);
    const duration = dayjs(root.end).diff(root.start, 'millisecond');

    switch (stripped.type) {
      case 'UNIPILE':
        viewStore.put(
          createViewCalendarEvent(
            recurringEventId,
            {
              ...stripped,
              kind: 'INSTANCE',
              start: toUTCDateTimeMs(instanceStart),
              end: toUTCDateTimeMs(instanceStart.add(duration, 'millisecond')),
              original_start: instance.original_start,
              original_tzid: instance.original_tzid, // root.start_tzid
            },
            instanceId
          )
        );
        break;
      case 'GOOGLE':
        viewStore.put(
          createViewCalendarEvent(
            recurringEventId,
            {
              ...stripped,
              external_recurring_event_id: stripped.external_id as
                | GoogleCalendarEventId
                | GoogleCalendarEventAllDaySplitRecurringId,
              external_id: stripped.all_day
                ? toGoogleCalendarEventAllDayInstanceId(
                    stripped.external_id.split('_')[0] as GoogleCalendarEventId,
                    toUTCDateBasic(instanceStart)
                  )
                : toGoogleCalendarEventInstanceId(
                    stripped.external_id.split('_')[0] as GoogleCalendarEventId,
                    toUTCDateTimeBasic(instanceStart)
                  ),
              /** @note external_id is now mandatory, conditional expansion no longer needed. */
              //   ...(stripped.external_id && {
              //     external_recurring_event_id: stripped.external_id as
              //       | GoogleCalendarEventId
              //       | GoogleCalendarEventAllDaySplitRecurringId,
              //     external_id: stripped.all_day
              //       ? toGoogleCalendarEventAllDayInstanceId(
              //           stripped.external_id.split('_')[0] as GoogleCalendarEventId,
              //           toUTCDateBasic(instanceStart)
              //         )
              //       : toGoogleCalendarEventInstanceId(
              //           stripped.external_id.split('_')[0] as GoogleCalendarEventId,
              //           toUTCDateTimeBasic(instanceStart)
              //         ),
              //   }),
              kind: 'INSTANCE',
              start: toUTCDateTimeMs(instanceStart),
              end: toUTCDateTimeMs(instanceStart.add(duration, 'millisecond')),
              original_start: instance.original_start,
              original_tzid: instance.original_tzid, // root.start_tzid
            },
            instanceId
          )
        );
        break;
    }

    return tx.done;
  }

  /**
   *
   */
  async remove(id: ViewCalendarEvent['id']): Promise<void> {
    return Idb.delete('viewCalendarEvents', id);
  }

  /**
   *
   */
  async removeMany(ids: ViewCalendarEvent['id'][]): Promise<void> {
    const tx = Idb.transaction('viewCalendarEvents', 'readwrite', { durability: 'relaxed' });
    for (let i = 0, length = ids.length; i < length; i++) {
      tx.store.delete(ids[i]);
    }
    return tx.done;
  }
  /**
   *
   */
  async removeInstancesOf(recurringEventId: Uuid): Promise<void> {
    const tx = Idb.transaction('viewCalendarEvents', 'readwrite', { durability: 'relaxed' });
    const instanceIds = await tx.store.index('by-recurring-event').getAllKeys(IDBKeyRange.only(recurringEventId));

    for (let i = 0, length = instanceIds.length; i < length; i++) {
      tx.store.delete(instanceIds[i]);
    }
    return tx.done;
  }

  //   async removeByAccount(account_id: Uuid) {
  //     let cursor = await Idb.transaction('viewCalendarEvents', 'readwrite')
  //       .store.index('by-account')
  //       .openCursor(IDBKeyRange.only(account_id), 'next');

  //     while (cursor) {
  //       await cursor.delete();
  //       cursor = await cursor.continue();
  //     }
  //   }

  /**
   *
   */
  async clear(): Promise<void> {
    return Idb.clear('viewCalendarEvents');
  }

  /**
   * Query.
   */
  async get(id: Uuid | UnipileCalendarEventInstanceId): Promise<(ViewCalendarEvent & Taggable) | null> {
    const event = await Idb.get('viewCalendarEvents', id);

    if (!event) return null;
    const tags = await this.viewTagRelation.getTagsByElement(
      'EVENT',
      event.kind === 'SINGLE' ? event.id : event.recurring_event_id
    );
    return { ...event, tags };
  }

  private async _getTags(results: ViewCalendarEvent[]): Promise<(ViewCalendarEvent & Taggable)[]> {
    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    results.forEach((event, i) => {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['EVENT', event.kind === 'SINGLE' ? event.id : event.recurring_event_id]));
    });

    const allTags = await this.viewTagRepo.getAll();

    const eventsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...results[i], tags };
      })
    );

    return eventsWithTags;
  }

  /**
   * Query.
   */
  async getAll(): Promise<(ViewCalendarEvent & Taggable)[]> {
    const events = await Idb.getAll('viewCalendarEvents');
    return this._getTags(events);
  }

  /**
   * Query.
   */
  async getInstancesOf(recurringEventId: Uuid): Promise<(ViewCalendarEvent & Taggable)[]> {
    const events = await Idb.getAllFromIndex('viewCalendarEvents', 'by-recurring-event', IDBKeyRange.only(recurringEventId));

    const tags = await this.viewTagRelation.getTagsByElement('EVENT', recurringEventId);

    return events.map((event) => ({ ...event, tags }));
  }

  /**
   * Query.
   */
  async getRecurringRoot(recurringEventId: Uuid): Promise<RecurringCalendarEventRoot | null> {
    return (await Idb.get('recurringCalendarEvent', recurringEventId)) ?? null;
  }

  /**
   * Query.
   */
  async getAllRecurringRoots(): Promise<RecurringCalendarEventRoot[]> {
    return Idb.getAll('recurringCalendarEvent');
  }

  /**
   * Query.
   */
  async getAllByDate(size: number, offset: number): Promise<(ViewCalendarEvent & Taggable)[]> {
    let cursor = await Idb.transaction('viewCalendarEvents', 'readonly', { durability: 'relaxed' })
      .store.index('by-date')
      .openCursor(null, 'prev');
    const results: ViewCalendarEvent[] = [];
    let hasSkipped = false;

    while (results.length < size) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        if (cursor) {
          cursor = await cursor.advance(offset);
        }
      }
      if (!cursor) {
        break;
      }
      results.push(cursor.value);
      cursor = await cursor.continue();
    }

    return this._getTags(results);
  }

  /**
   * Query.
   */
  async getAllPast(size: number, offset: number): Promise<(ViewCalendarEvent & Taggable)[]> {
    let cursor = await Idb.transaction('viewCalendarEvents', 'readonly', { durability: 'relaxed' })
      .store.index('by-date')
      .openCursor(IDBKeyRange.upperBound(toUTCDate(dayjs())), 'prev');

    const results: ViewCalendarEvent[] = [];
    let hasSkipped = false;

    while (results.length < size) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        if (cursor) {
          cursor = await cursor.advance(offset);
        }
      }
      if (!cursor) {
        break;
      }
      results.push(cursor.value);
      cursor = await cursor.continue();
    }

    return this._getTags(results);
  }

  /**
   * Query.
   */
  async getAllFuture(size: number, offset: number): Promise<(ViewCalendarEvent & Taggable)[]> {
    let cursor = await Idb.transaction('viewCalendarEvents', 'readonly', { durability: 'relaxed' })
      .store.index('by-date')
      .openCursor(IDBKeyRange.lowerBound(toUTCDate(dayjs())), 'next');

    const results: ViewCalendarEvent[] = [];
    let hasSkipped = false;

    while (results.length < size) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        if (cursor) {
          cursor = await cursor.advance(offset);
        }
      }
      if (!cursor) {
        break;
      }
      results.push(cursor.value);
      cursor = await cursor.continue();
    }

    return this._getTags(results);
  }

  /**
   * Query.
   */
  async getByCalendar(calendar_id: Uuid): Promise<ViewCalendarEvent[]> {
    return Idb.getAllFromIndex('viewCalendarEvents', 'by-calendar', IDBKeyRange.only(calendar_id));
    // return this.idb.getAllFromIndex('viewCalendarEvents', 'by-calendar', IDBKeyRange.only(calendar_id));
  }

  /**
   * Query.
   *
   * @note min_date, max_date interval is 'inclusive'.
   */
  async getByDate(min_date: UTCDate, max_date: UTCDate): Promise<(ViewCalendarEvent & Taggable)[]> {
    /** @throws IDBKeyRange.bound throws when min_date > max_date. */
    const results = await Idb.getAllFromIndex(
      'viewCalendarEvents',
      'by-date',
      /** @todo See if something like +'U' would be safer ? */
      IDBKeyRange.bound(min_date, max_date + 'T23:59:59.999Z')
    );

    return this._getTags(results);
  }
  /**
   * Query.
   *
   * @note min_date, max_date interval is 'inclusive'.
   *
   * @todo Research IndexedDB query using multiple indexes or compound indexes.
   */
  async getByCalendarAndDate(calendar_id: Uuid, min_date: UTCDate, max_date: UTCDate): Promise<ViewCalendarEvent[]> {
    /** @throws IDBKeyRange.bound throws when min_date > max_date. */
    const projections = await Idb.getAllFromIndex(
      'viewCalendarEvents',
      'by-date',
      /** @todo See if something like +'U' would be safer ? */
      IDBKeyRange.bound(min_date, max_date + 'T23:59:59.999Z')
    );

    // console.log(`IdbViewCalendarEventRepo.getByCalendarAndDate had to pull and filter ${projections.length} projections.`);
    return projections.filter(({ calendar_id: projection_calendar_id }) => projection_calendar_id === calendar_id);
  }

  /**
   * Query.
   */
  async getOldestByStatus(status: CalendarEventStatus): Promise<ViewCalendarEvent | null> {
    const cursor = await Idb.transaction('viewCalendarEvents', 'readonly')
      .store.index('by-status-and-date')
      .openCursor(IDBKeyRange.bound([status, '0'], [status, 'Z']), 'next');

    return cursor?.value ?? null;
  }
}
