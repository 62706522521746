import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { prettyPrint } from '../../..';
import { AccountSource } from '../../Account';
import { ExternalCalendar, getCalendars, loadWorkEvents, refreshView } from '../../Calendar';
import { isIncomingCall, isMissedCall, isNoAnswerCall, isOutgoingCall } from '../../Call';
import { toUTCDateTimeMs, Uuid } from '../../Common';
import { ViewIm, ViewImThread } from '../../Im';
import { notificationsSlice, notifyNewIm, ViewNotification } from '../../Notification';
import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/store';
import { AccountSourceStatus, AccountSourceType, AccountType } from '../domain';
import { deleteAccount } from './accounts.slice';

export interface SourcesState {
  sources: AccountSource[];
}

/**
 * Slice handling account sources
 */
export const sourcesSlice = createSlice({
  name: 'sources',
  initialState: {
    sources: [],
  } as SourcesState,
  reducers: {
    addSources: (state, { payload }: PayloadAction<AccountSource[]>) => {
      state.sources = [...state.sources, ...payload];
    },
    removeAccountSources: (state, { payload }: PayloadAction<Uuid>) => {
      state.sources = state.sources.filter((source) => source.account_id !== payload);
    },
    setSourceStatus: (state, { payload }: PayloadAction<AccountSource>) => {
      const index = state.sources.findIndex((source) => source.account_id === payload.account_id && source.type === payload.type);
      if (index >= 0) state.sources[index].status = payload.status;
    },
  },
});

export const { addSources, removeAccountSources, setSourceStatus } = sourcesSlice.actions;

export const accountSourcesSelector = (account_id: Uuid) => (state: { sources: SourcesState }) => {
  return state.sources.sources.filter((source) => source.account_id === account_id);
};

export const accountSourceSeletor = (account_id: Uuid, type: AccountSourceType) => (state: { sources: SourcesState }) => {
  const s = state.sources.sources.filter((source) => source.account_id === account_id && source.type === type);
  return s.length ? s[0] : null;
};

export const erroredSourcesSelector = (state: { sources: SourcesState }) => {
  return state.sources.sources.filter(
    (source) => source.status === 'CREDENTIALS' || source.status === 'PERMISSIONS' || source.status === 'ERROR'
  );
};

export const erroredSourceSelector = (type: AccountSourceType, account_id: Uuid) => (state: { sources: SourcesState }) => {
  return (
    state.sources.sources.findIndex(
      (source) =>
        source.type === type &&
        source.account_id === account_id &&
        (source.status === 'CREDENTIALS' || source.status === 'PERMISSIONS' || source.status === 'ERROR')
    ) >= 0
  );
};

export const unsupportedSourceSelector = (type: AccountSourceType, account_id: Uuid) => (state: { sources: SourcesState }) => {
  return (
    state.sources.sources.findIndex(
      (source) => source.type === type && source.account_id === account_id && source.status === 'UNSUPPORTED'
    ) >= 0
  );
};

export const sourceIsFetchingSelector = (state: { sources: SourcesState }) => {
  return !!state.sources.sources.find((source) => source.status === 'FETCHING' && source.type === 'MAILS');
};

export const loadSources =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container) => {
    const credentialsKey = await user.getKey('credentials');
    const { result, error } = await account.initAllAccountsSources(credentialsKey);
    if (error) return;
    if (result) dispatch(addSources(result));
  };

export const initialMailFetchNew =
  (account_id: Uuid, duration: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification, mail, tag }: Container): Promise<string | void> => {
    // eslint-disable-next-line prefer-const
    let { mails, status } = await mail.initialFetchNew(account_id, duration);

    await Promise.all(
      mails.map(async (res) => {
        /**
         * @todo handle error if notification creation fail
         */
        const new_notification = await notification.notifyNewMail(res);
        const { result, error } = await tag.getNotificationTags(new_notification);
        dispatch(notificationsSlice.actions.addNotification({ ...new_notification, tags: result || [] }));
      })
    );

    dispatch(setSourceStatus({ account_id, type: 'MAILS', status }));
  };

/**
 *
 */
export const initialCallFetchNew =
  (account_id: Uuid, duration: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification, call, tag }: Container): Promise<string | void> => {
    const { status, calls } = await call.initialFetchNew(account_id, duration);

    calls.forEach(async (res) => {
      let new_notification: ViewNotification | null = null;
      if (isMissedCall(res)) new_notification = await notification.notifyNewMissedCall(res);
      if (isOutgoingCall(res)) new_notification = await notification.notifyNewOutgoingCall(res);
      if (isIncomingCall(res)) new_notification = await notification.notifyNewIncomingCall(res);
      if (isNoAnswerCall(res)) new_notification = await notification.notifyNewNoAnswerCall(res);

      if (new_notification !== null) {
        const { result, error } = await tag.getNotificationTags(new_notification);
        dispatch(notificationsSlice.actions.addNotification({ ...new_notification, tags: result || [] }));
      }
    });

    dispatch(setSourceStatus({ account_id, type: 'MAILS', status }));
  };

/**
 *
 */
export const initialContactFetch =
  (account_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<string | void> => {
    const { error } = await contact.fetchContacts(account_id);
    if (error) {
      await dispatch(deleteAccount(account_id));
      return error;
    }
  };

/**
 *
 */
export const initialImThreadsFetch =
  (account_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { im }: Container): Promise<string | void> => {
    const { error } = await im.initialFetch(account_id);
    if (error) {
      await dispatch(deleteAccount(account_id));
      return error;
    }
  };

/**
 *
 */
export const initialGoogleCalendarFetch =
  (account_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendarSync }: Container): Promise<string | void> => {
    const { calendars, status } = await calendarSync.initialPullExternalCalendars(account_id);

    if (calendars) {
      //   await Promise.all(
      //     calendars.map(async (c) => {
      //       console.log('-----------------------------------------------------------------------');
      //       console.log('Fetching calendar events', c);
      //       return calendarSync.initialPullExternalEvents(c as ExternalCalendar);
      //     })
      //   );
      dispatch(setSourceStatus({ account_id, type: 'CALENDAR', status }));
      dispatch(getCalendars());
    }
  };

/**
 * Run a cron that fetch new mails from connected imap accounts at interval
 */
export const initMailCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron, notification, mail, tag }: Container): Promise<void> => {
    if (!getState().session) return;

    await cron.runCron(
      'mail',
      async () => {
        const { sources } = getState().sources;

        await Promise.all(
          sources
            .filter(({ type, status }) => status === 'IDLE' && type === 'MAILS')
            .map(async ({ account_id, type }) => {
              dispatch(setSourceStatus({ account_id, type, status: 'FETCHING' }));

              const { mails, status } = await mail.fetchNew(account_id);

              mails.forEach(async (res) => {
                /**
                 * @todo handle error if notification creation fail
                 */
                const new_notification = await notification.notifyNewMail(res);
                const { result, error } = await tag.getNotificationTags(new_notification);
                dispatch(notificationsSlice.actions.addNotification({ ...new_notification, tags: result || [] }));
              });

              dispatch(setSourceStatus({ account_id, type, status }));

              if (status === 'ERROR') dispatch(automaticRetryOnError(account_id, type));
              sessionStorage.setItem('LAST_MAIL_FETCH', dayjs().toISOString());
            })
        );
      },
      1000 * 60 * 10
    );
  };

export const initMailMetaSync =
  (account_id?: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail }: Container): Promise<void> => {
    if (account_id) mail.syncOneMeta(account_id);
    else mail.syncAllMeta();
  };

/**
 * Run a cron that fetch new calls at interval
 */
export const initCallCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron, notification, call, tag }: Container): Promise<void> => {
    if (!getState().session) return;

    await cron.runCron(
      'call',
      async () => {
        const { sources } = getState().sources;

        await Promise.all(
          sources
            .filter(({ type, status }) => status === 'IDLE' && type === 'CALLS')
            .map(async ({ account_id, type }) => {
              dispatch(setSourceStatus({ account_id, type, status: 'FETCHING' }));

              const { status, calls } = await call.fetchNew(account_id);

              calls.forEach(async (res) => {
                /**
                 * @todo handle error if notification creation fail
                 */
                let new_notification;
                if (isMissedCall(res)) new_notification = await notification.notifyNewMissedCall(res);
                if (isOutgoingCall(res)) new_notification = await notification.notifyNewOutgoingCall(res);
                if (isIncomingCall(res)) new_notification = await notification.notifyNewIncomingCall(res);
                if (isNoAnswerCall(res)) new_notification = await notification.notifyNewNoAnswerCall(res);
                if (new_notification) {
                  const { result, error } = await tag.getNotificationTags(new_notification);
                  dispatch(notificationsSlice.actions.addNotification({ ...new_notification, tags: result || [] }));
                }
              });

              dispatch(setSourceStatus({ account_id, type, status }));

              if (status === 'ERROR') dispatch(automaticRetryOnError(account_id, type));
            })
        );
      },
      1000 * 60
    );
  };

/**
 * Run a cron that fetch new ims at interval
 */
export const initImCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron, im }: Container): Promise<void> => {
    if (!getState().session) return;

    await cron.runCron(
      'im',
      async () => {
        // Run the global cron job
        const { sources } = getState().sources;
        await Promise.all(
          sources
            .filter(({ type, status }) => status === 'IDLE' && type === 'IMS')
            .map(async ({ account_id, type }) => {
              dispatch(setSourceStatus({ account_id, type, status: 'FETCHING' }));

              const { status, result } = await im.fetchNew(account_id);

              if (result) {
                /**
                 * Multiple messages in a single thread can be fecthed during one cron execution,
                 * in this case, only notify the latest one.
                 */
                const to_notify: { im: ViewIm; thread: ViewImThread }[] = [];

                for (const { im, thread } of result) {
                  const index = to_notify.findIndex((r) => r.thread.id === thread.id);
                  if (index === -1) to_notify.push({ im, thread });
                  else if (dayjs(im.date).isAfter(dayjs(to_notify[index].im.date))) to_notify[index].im = im;
                }

                await Promise.all(
                  to_notify.map(async ({ im }) => {
                    dispatch(notifyNewIm(im));
                    return;
                  })
                );
              }

              dispatch(setSourceStatus({ account_id, type, status }));

              if (status === 'ERROR') dispatch(automaticRetryOnError(account_id, type));

              sessionStorage.setItem('LAST_IM_FETCH', dayjs().toISOString());
            })
        );
      },
      1000 * 10
    );
  };

/**
 *
 */
export const initCalendarCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron, calendar, calendarSync }: Container): Promise<void> => {
    if (!getState().session) return;

    await cron.runCron(
      'calendar',
      async () => {
        // Run the global cron job
        const { sources } = getState().sources;
        await Promise.all(
          sources
            .filter(({ type, status }) => status === 'IDLE' && type === 'CALENDAR')
            .map(async (source) => {
              dispatch(setSourceStatus({ account_id: source.account_id, type: 'CALENDAR', status: 'FETCHING' }));
              const { status } = await calendarSync.pullExternalCalendars(source.account_id);

              if (status !== 'IDLE') {
                dispatch(setSourceStatus({ account_id: source.account_id, type: 'CALENDAR', status }));
                return;
              }

              const calendars = await calendar.getCalendarsByAccountId(source.account_id);

              /**
               * @todo Ask Paul how one should deal with multiple status that may signal
               *       different problems.
               */
              const tasks: Promise<{
                status: AccountSourceStatus;
              }>[] = [];

              calendars.forEach((c) => {
                if (c.sync_activated) {
                  tasks.push(calendarSync.pullExternalEvents(c));
                }
              });
              const result = await Promise.all(tasks);

              // prettyPrint({ result });
              const finalStatus: AccountSourceStatus = result.filter((r) => r?.status !== 'IDLE').length ? 'CREDENTIALS' : 'IDLE';
              dispatch(setSourceStatus({ account_id: source.account_id, type: 'CALENDAR', status: finalStatus }));
            })
        );
        dispatch(getCalendars());
        dispatch(loadWorkEvents());
        dispatch(refreshView());
      },
      1000 * 60 * 2
    );
  };

/**
 * Set the status of a source back to IDLE if the status is set a first time to ERROR by a cron
 * This is using sessionStorage to save the fact the retry was done or not
 */
const automaticRetryOnError =
  (account_id: Uuid, type: AccountSourceType) =>
  (dispatch: AppDispatch, getState: AppGetState, { cron }: Container): void => {
    const KEY = 'AUTO_RETRY' + account_id + type;
    const tried = sessionStorage.getItem(KEY);
    if (!tried) {
      dispatch(setSourceStatus({ account_id, type, status: 'IDLE' }));
      sessionStorage.setItem(KEY, 'true');
    } else if (tried === 'true') {
      sessionStorage.setItem(KEY, dayjs().toISOString());
    }
  };

/**
 * Every 30 min, set ERROR sources back to IDLE to be retried by the cron
 * This is used when some services (mostly IMAP) lose connection during the night for few minutes
 */
export const initRetryCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron }: Container): Promise<void> => {
    if (!getState().session) return;

    await cron.runCron(
      'retry',
      async () => {
        const sources = getState().sources.sources.filter((s) => s.status === 'ERROR');

        for (const { account_id, type } of sources) {
          const KEY = 'AUTO_RETRY' + account_id + type;
          const already_retried = sessionStorage.getItem(KEY);

          if (already_retried !== 'true') {
            dispatch(setSourceStatus({ account_id, type, status: 'IDLE' }));
          }
        }
      },
      1000 * 60 * 30 // 30 min
    );
  };

export const refreshSync =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron }: Container): Promise<void> => {
    cron.stopCron('mail');
    cron.stopCron('im');
    cron.stopCron('call');
    cron.stopCron('calendar');
    await dispatch(initMailCron());
    await dispatch(initCallCron());
    await dispatch(initImCron());
    await dispatch(initCalendarCron());
  };

export const stopAccountsCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron }: Container): Promise<void> => {
    cron.stopCron('mail');
    cron.stopCron('im');
    cron.stopCron('call');
    cron.stopCron('calendar');
  };

export const restartAccountsCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron }: Container): Promise<void> => {
    await dispatch(initMailCron());
    await dispatch(initCallCron());
    await dispatch(initImCron());
    await dispatch(initCalendarCron());
  };

/**
 * Stops Im Cron (used when an im thread view is loaded and need the scraping queue for itself)
 */
export const stopImCron =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron }: Container): Promise<void> => {
    cron.stopCron('im');
  };

export default sourcesSlice.reducer;
