import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { requestAuthMicrosoft, useAppDispatch } from '@focus-front/core';
import { Typography } from '@material-ui/core';

import { BackButton, NextButton } from './NavButton';
import { WizardContext } from './WizardContext';

function Login() {
  const { goBack, forms, setLoading, setForms, goNext, setAlias } = useContext(WizardContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    // setLoading(true);
    const { error, result } = await dispatch(requestAuthMicrosoft());
    if (error) {
      if (error !== 'cancelled') alert(error);
      // setLoading(false);
    } else if (result) {
      setAlias(result.account.username);
      setForms({ ...forms, MICROSOFT_LOGIN: result });
      goNext();
    }
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.microsoftLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.microsoftLogin.subtitle')}</Typography>

      <NextButton onClick={handleLogin}>{t('wizard.microsoftLogin.loginBtn')}</NextButton>
    </div>
  );
}

export default function MicrosoftLogin() {
  return <Login />;
}
