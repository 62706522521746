import { DomainEventShape, Uuid } from '../../../Common';
import { NewTagDTO, UpdateTagDTO } from '../projections/ViewTag';

/**
 * DomainEvent.
 */
export class TagCreated implements DomainEventShape {
  readonly type = 'TAG_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly tag: NewTagDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class TagUpdated implements DomainEventShape {
  readonly type = 'TAG_UPDATED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly update: UpdateTagDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type TagDomainEvent = TagCreated | TagUpdated;
