import {
  ImapSearchQuery,
  ImapMailBox,
  ImapMailHead,
  ImapNewFolder,
  ImapNewMail,
  ImapMail,
  ImapMailQuery,
  ImapMoveQuery,
} from '../types/imap';
import {
  AutoConfig,
  InvalidCredentialsError,
  MailConnectionParams,
  MissingCredentialsError,
  ProxyConfig,
  ProxyServerError,
} from '../types/common';
import jwtDecode from 'jwt-decode';

type DecodedJwt = {
  exp: number;
};

export class Imap {
  private jwt: string | null = null;

  constructor(
    protected config: ProxyConfig,
    private getCredentials?: (account_id?: string) => Promise<unknown>, //Promise<{ [key: string]: string }>,
    public account_id?: string,
    private connection_params?: MailConnectionParams
  ) {}

  /**
   * Get the IMAP API version
   */
  async getAPIVersion(): Promise<string> {
    return this.fetch(`/api/version`);
  }

  /**
   * Fetch utility handling errors and refreshing jwt if needed
   * @param url Url to call
   * @param options Options (method, headers)
   */
  protected async fetch(url: string, options?: RequestInit): Promise<any | never> {
    // If jwt is invalid, refresh it
    if (this.isJwtExpired()) await this.authenticate();

    const response = await fetch(this.config.host + url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.jwt}`,
      },
      credentials: 'include',
      ...options,
    });
    let jsonResponse;
    try {
      jsonResponse = await response.json();
    } catch (e) {
      // For 500 Errors, response is in HTML, therefore response.json() fail.
      throw new ProxyServerError(response.statusText);
    }
    if (!response.ok) {
      if (
        jsonResponse.type &&
        (jsonResponse.type === 'cant_access_mailbox' || jsonResponse.type === 'error_while_getting_imap_stream')
      )
        throw new InvalidCredentialsError();
      if (jsonResponse.message && jsonResponse.message.includes('authenticate')) throw new InvalidCredentialsError();
      throw new ProxyServerError(jsonResponse.title ? jsonResponse.title : jsonResponse.toString());
    }
    return jsonResponse;
  }

  /**
   * Authenticate the user to a mailbox and save the jwt in the memory
   */
  private async authenticate() {
    let credentials = {};

    // Get account credentials with function passed to creator
    try {
      if (this.getCredentials) credentials = (await this.getCredentials(this.account_id)) as {};
    } catch (e) {
      throw new InvalidCredentialsError();
    }

    if (credentials === {} || !credentials) throw new MissingCredentialsError();

    const response = await fetch(`${this.config.host}/api/login_check`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        username: this.config.username,
        password: this.config.password,
        ...this.connection_params,
        ...credentials,
      }),
    });
    const data = await response.json();
    this.jwt = data.token;
    return true;
  }

  /**
   * Verify if the jwt is valid
   */
  private isJwtExpired(): boolean {
    return !this.jwt || jwtDecode<DecodedJwt>(this.jwt).exp < Math.floor(Date.now() / 1000);
  }

  /**
   * Get the user's mailbox details
   */
  async getMailbox(pattern?: string): Promise<ImapMailBox> {
    return this.fetch(`/api/imap/check${pattern ? '?pattern=' + pattern : ''}`);
  }

  /**
   * Get the list of folders name in the user's mailbox
   */
  async getFolders(): Promise<Array<string>> {
    return this.fetch(`/api/imap/directory/list`);
  }

  /**
   * Get the list of email heads in the user's mailbox
   * @param pattern Name of the mailbox
   * @param sequence Offest and number of items to get (1:10 means 10 items from the first item) ordered by their UID.
   */
  async getMailHeadsList(pattern?: string, sequence?: string): Promise<Array<ImapMailHead>> {
    return this.fetch(`/api/imap/mail/list?sequence=${sequence || '1:200'}${pattern ? '&pattern=' + pattern : ''}`);
  }

  /**
   * Get the list of full emails in the user's mailbox (longer response time)
   * @param pattern Name of the mailbox
   * @param sequence Offest and number of items to get (1:10 means 10 items from the first item) ordered by their UID.
   * @param include_attachments Should include attachment base64 data (heavier)
   */
  async getMailsList(pattern?: string, sequence?: string, include_attachments?: boolean): Promise<Array<ImapMail>> {
    return this.fetch(
      `/api/imap/mail/list?sequence=${sequence || '1:200'}${pattern ? '&pattern=' + pattern : ''}&include_body=true${
        include_attachments ? '&include_attachments=' + include_attachments : ''
      }`
    );
  }

  /**
   * Create a new folder in the user's mailbox
   * @param new_folder Name and pattern of the new folder
   */
  async createFolder(new_folder: ImapNewFolder): Promise<{ status: string; name: string }> {
    return this.fetch(`/api/imap/directory/create`, {
      method: 'POST',
      body: JSON.stringify(new_folder),
    });
  }

  /**
   * Rename a folder in the user's mailbox
   * @param old_name Name of the folder to rename
   * @param new_name New name of the folder
   */
  async renameFolder(old_name: string, new_name: string): Promise<{ status: string; name: string }> {
    return this.fetch(`/api/imap/directory/rename`, {
      method: 'PUT',
      body: JSON.stringify({ old_name, new_name }),
    });
  }

  /**
   * Delete a folder ine the user's mailbox
   * @param name Name of the folder to delete
   */
  async deleteFolder(name: string): Promise<{ status: string }> {
    return this.fetch(`/api/imap/directory/delete`, {
      method: 'DELETE',
      body: JSON.stringify({ name }),
    });
  }

  /**
   * Search mails in the user's mailbox
   * @param query Filters to find mails in the user's mailbox
   */
  async searchMails(query: ImapSearchQuery): Promise<Array<ImapMail>> {
    return this.fetch(`/api/imap/mail/search`, {
      method: 'POST',
      body: JSON.stringify(query),
    });
  }

  /**
   * Retrieve emails content
   * @param query Query ton find a specific set of emails (can be only one)
   */
  async getMails(query: ImapMailQuery): Promise<ImapMail[]> {
    return this.fetch(`/api/imap/mail/content`, {
      method: 'POST',
      body: JSON.stringify(query),
    });
  }

  /**
   * Moves an email from one folder to another
   * @param query Mail to send
   */
  async moveMails(query: ImapMoveQuery): Promise<{ status: string }> {
    // prettyPrint({ query }, 'moveMails');
    return this.fetch(`/api/imap/mail/move`, {
      method: 'POST',
      body: JSON.stringify(query),
    });
  }

  /**
   * Send an email from the user's mailbox smtp
   * @param new_mail Mail to send
   */
  async sendMail(new_mail: ImapNewMail): Promise<{ status: string }> {
    return this.fetch(`/api/smtp/mail/send`, {
      method: 'POST',
      body: JSON.stringify(new_mail),
    });
  }

  /**
   * Get provider configuration based on the email address given
   * @param email Email address
   */
  async getAutoConfig(email: string): Promise<AutoConfig> {
    return this.fetch(`/api/autoconfig/${email}`);
  }
}
