import dayjs from 'dayjs';
import { chevronForward, notificationsOutline, peopleOutline, playForwardOutline, pricetags } from 'ionicons/icons';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  displayAttendeeName,
  eventByAttachedSelector,
  notificationByAttachedSelector,
  Taggable,
  ViewAccount,
  ViewIm,
  ViewImThread,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Avatar, Badge, Box, Chip, makeStyles, Tooltip, Typography } from '@material-ui/core';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import { AvatarGroup } from '@material-ui/lab';
import { EventChip } from '../Common/EventChip';
import { NotificationChip } from '../Common/NotificationChip';
import Tags from '../Tag/Tags';
import { AccountTypeIcon } from '@focus-front/ui';

interface Props {
  last_im?: ViewIm;
  thread: ViewImThread & Taggable;
  account?: ViewAccount;
  onClick: () => void;
}

const useClasses = makeStyles((theme) => ({
  root: (props: { isOpen: boolean }) => ({
    padding: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: props.isOpen && theme.palette.background.default,
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '& .Avatar': {
      flexShrink: 0,
    },
    borderLeft: props.isOpen && `solid 4px ${theme.palette.secondary.main}`,
  }),
  right: {
    paddingLeft: 20,
    flex: 1,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  body: {
    flex: 1,
  },
  date: {
    fontSize: '0.75rem',
    opacity: 0.5,
  },
  attach: { fontSize: 20, verticalAlign: 'bottom' },
  arrow: {
    fontSize: 24,
    color: theme.palette.grey[700],
  },
  draft: {
    color: theme.palette.warning.main,
  },
  groupIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  tags: {
    marginTop: 5,
    minHeight: 26,
  },
  tagsIcon: {
    alignSelf: 'center',
    opacity: 0.3,
    verticalAlign: 'middle',
    marginRight: 5,
    fontSize: 16,
  },
}));

export function ImThreadListView({ last_im, thread, account, onClick }: Props) {
  const notification = useSelector(notificationByAttachedSelector(thread.id));
  const event = useSelector(eventByAttachedSelector(thread.id));
  const history = useHistory();
  const location = useLocation();
  const c = useClasses({
    isOpen: (notification && location.pathname.includes(notification.id)) || (event && location.pathname.includes(event.id)),
  });

  const attendees = thread.attendees.filter((att) => att.display_name !== 'YOU');

  return (
    <div className={c.root} onClick={onClick}>
      {attendees[0] && (
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          style={{ alignSelf: 'flex-start' }}
          badgeContent={!!account && <AccountTypeIcon account_type={account.type} size={30} />}
        >
          {attendees.length > 1 ? (
            <Avatar className={c.groupIcon}>
              <IonIcon icon={peopleOutline} />
            </Avatar>
          ) : (
            <AttendeeAvatar
              display_name={displayAttendeeName(attendees[0])}
              avatar_url={attendees[0].profile_picture}
              size="small"
            />
          )}
        </Badge>
      )}

      <div className={c.right}>
        <div className={c.top}>
          <Box display="flex" alignItems="center">
            <Typography variant="h4" style={{ marginRight: 10 }}>
              {thread.provider_name ? thread.provider_name : displayAttendeeName(attendees[0])}
            </Typography>

            {!!notification && <NotificationChip id={notification.id} />}
            {!!event && <EventChip id={event.id} />}
          </Box>
          {(last_im || thread.last_update) && (
            <div className={c.date}>{!!last_im ? dayjs(last_im.date).format('LL') : dayjs(thread.last_update).format('LL')}</div>
          )}
        </div>
        <Box display="flex" alignItems="flex-end">
          {!!last_im ? (
            thread.attendees.length > 2 && last_im.direction === 'INCOMING' ? (
              <Typography variant="subtitle2" className={c.body}>
                {displayAttendeeName(thread.attendees.find((att) => att.identifier === last_im.from_attendee_identifier)) +
                  ': ' +
                  (last_im.body?.length > 50 ? last_im.body.slice(0, 47) + '...' : last_im.body)}
              </Typography>
            ) : last_im.body ? (
              <Typography variant="subtitle2" className={c.body}>
                {last_im.body?.length > 50 ? last_im.body.slice(0, 47) + '...' : last_im.body}
              </Typography>
            ) : (
              <Typography variant="subtitle2" className={c.body}>
                Attachment
              </Typography>
            )
          ) : (
            <Typography variant="subtitle2" className={c.body} style={{ fontStyle: 'italic' }}>
              (open to load messages...)
            </Typography>
          )}

          <IonIcon className={c.arrow} icon={chevronForward} />
        </Box>
        {thread.tags && thread.tags.length > 0 && <Tags tags={thread.tags} listMode />}
      </div>
    </div>
  );
}

export default memo(ImThreadListView, (prev, next) => prev.thread.id === next.thread.id);
