import { createSignature, useAppDispatch, ViewSignature } from '@focus-front/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SignatureAddModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (sign: ViewSignature) => void;
}

type Props = {};

const SignatureAddModal: React.FC<SignatureAddModalProps> = ({ open, onCreate, onClose }) => {
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleCreate = async (e) => {
    e.preventDefault();
    const { error, result } = await dispatch(createSignature(name));
    if (error) return setError(error);

    onCreate(result);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('signatureSettings.new.title')}</DialogTitle>
        <form onSubmit={handleCreate}>
          <DialogContent>
            <DialogContentText>{t('signatureSettings.new.description')}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label={t('signatureSettings.form.name')}
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {error && <Alert severity="error">{error}</Alert>}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('signatureSettings.new.cancel')}</Button>
            <Button type="submit" color="secondary" disabled={!name}>
              {t('signatureSettings.new.create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={success} autoHideDuration={2000} onClose={() => setSuccess(false)}>
        <Alert severity="success">{t('signatureSettings.new.success')}</Alert>
      </Snackbar>
    </>
  );
};

export default SignatureAddModal;
