import { getRecurringFrequency, RecurringEventSelectOptions } from '@focus-front/ui';
import { makeStyles, Typography } from '@material-ui/core';
import {
  RecurringCalendarEventRoot,
  RecurringCalendarEventRootStrip,
} from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface EventRecurrentStringProps {
  recurringEventRoot: RecurringCalendarEventRoot | RecurringCalendarEventRootStrip;
}

const useClasses = makeStyles((theme) => ({
  subheader: {
    color: theme.palette.grey[600],
  },
}));

const EventRecurrentString: React.FC<EventRecurrentStringProps> = ({ recurringEventRoot }) => {
  const { t } = useTranslation();
  const classes = useClasses();

  const parseRecurrenceValue = (): string => {
    const frequency: RecurringEventSelectOptions = getRecurringFrequency(recurringEventRoot);
    switch (frequency) {
      case 'DAILY':
        return t('calendar.recurrentSelect.everyDay');
      case 'WEEKLY':
        return t('calendar.recurrentSelect.everyWeek');
      case 'MONTHLY':
        return t('calendar.recurrentSelect.everyMonth');
      case 'YEARLY':
        return t('calendar.recurrentSelect.everyYear');
      case 'WEEKLY':
        return t('calendar.recurrentSelect.everyWeekdayWeb');
      default:
        return '';
    }
  };

  return (
    <div className={classes.subheader}>
      <Typography variant="body2">{recurringEventRoot.kind === 'RECURRING' && parseRecurrenceValue()}</Typography>
    </div>
  );
};

export default EventRecurrentString;
