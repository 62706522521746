import { Select, isObject } from '../../Common/utils';
import { UserDTO } from '../infra/services/CoreApiTypes';
import { isSubscriptionOffer as isSubscriptionOffer, SubscriptionOffer } from './Subscription';

export type User = Readonly<{
  id: number;
  email: string;
  lastname: string;
  firstname: string;
  profile_picture: string | null;
  params: UserParams;
  language: string;
  offer: SubscriptionOffer;
  subscription_end_date: string | null;
  subscription_id: number | null;
  referral_code: string;
}>;

export type NewUserDTO = Omit<Select<User, 'email' | 'lastname' | 'firstname'>, 'user_id' | 'profile_picture'> & {
  password: string;
  referral_code?: string;
};

export type UpdateUserDTO = Omit<Partial<User>, 'user_id' | 'profile_picture'>;

/**
 * @note please prefix with ahi_ so it's not confusing with other user params
 */
export type AutomaticHelpId = 'ahi_mail_reply';

export type UserParams = {
  onboarding?: string;
  tour?: string;
  last_login?: string;
  /** @todo Remove userKey from UserParams. */
  userKey?: string;
} & { [key in AutomaticHelpId]?: string };

export type UserSession = User & {
  refresh_token: string;
};

/**
 *
 */
export class InvalidUserDTOError extends Error {
  constructor() {
    super(`Missing or invalid values in UserDTO !`);
    this.name = 'InvalidUserDTOError';
  }
}

/**
 *
 */
export function createUser(data: UserDTO): User {
  if (
    typeof data.email !== 'string' ||
    typeof data.lastname !== 'string' ||
    typeof data.firstname !== 'string' ||
    typeof data.id !== 'number' ||
    (typeof data.subscription_end_date !== 'string' && data.subscription_end_date !== null) ||
    (typeof data.subscription_id !== 'number' && data.subscription_id !== null) ||
    typeof data.language !== 'string' ||
    typeof data.offer !== 'string' ||
    typeof data.referral_code !== 'string' ||
    !isSubscriptionOffer(data.offer) ||
    (typeof data.params === 'object' && data.params !== null && !isUserParams(data.params))
  )
    throw new InvalidUserDTOError();

  return {
    email: data.email,
    lastname: data.lastname,
    firstname: data.firstname,
    id: data.id,
    subscription_end_date: data.subscription_end_date || null,
    subscription_id: data.subscription_id || null,
    language: data.language,
    offer: data.offer,
    params: data.params === [] ? {} : typeof data.params === 'object' && data.params !== null ? data.params : {},
    profile_picture: typeof data.profile_picture === 'string' && data.profile_picture !== '' ? data.profile_picture : null,
    referral_code: data.referral_code,
  };
}

/**
 *
 */
export function isUserParams(u: unknown): u is UserParams {
  return (
    isObject(u) &&
    ('onboarding' in u ? typeof u.onboarding === 'string' : true) &&
    ('tour' in u ? typeof u.tour === 'string' : true) &&
    ('tour_mobile' in u ? typeof u.tour === 'string' : true) &&
    ('userKey' in u ? typeof u.userKey === 'string' : true)
  );
}
