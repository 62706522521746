/**
 * @todo Handle cases that doesn't JSON.stringify and/or make dump param type
 *       only accept JSON.stringifyable values.
 */
export function dumpToFile(dump: unknown, filename: string): void {
  const blob =
    typeof dump === 'string'
      ? new Blob([dump], { type: 'text/plain' })
      : new Blob([JSON.stringify(dump)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  /** @todo Check that encodeURIComponent is enough for a file name. */
  a.download = encodeURIComponent(filename);
  // a.style.display = 'none';
  a.click();
  setTimeout(() => URL.revokeObjectURL(url), 30 * 1000);
}

