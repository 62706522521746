import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Editor } from '@tiptap/react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from '@material-ui/lab';
import { Link } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      border: 'none',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      transition: 'color .2s ease-out',
    },
  })
);

interface TipTapToolbarLinkProps {
  editor: Editor;
}

const TipTapToolbarLink: FC<TipTapToolbarLinkProps> = ({ editor }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');
  const [linkText, setLinkText] = useState('');
  const [disabledLinkText, setDisabledLinkText] = useState(false);
  const [copyFlag, setCopyFlag] = useState(false);

  // Clicking the link button
  const handleOpen = () => {
    setLink(editor.getAttributes('link').href);

    // If we click while on a Link
    if (!!editor.getAttributes('link').href) {
      setDisabledLinkText(true);
    } else if (!editor.state.selection.empty) {
      // If we click while having selected something
      let textContent = '';
      editor.state.selection.content().content.forEach((node) => {
        textContent += node.textContent;
      });
      setLinkText(textContent);
    } else {
      // If we click on a empty point
      setCopyFlag(true);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLink('');
    setLinkText('');
    setDisabledLinkText(false);
    setCopyFlag(false);
  };

  const updateLinkText = (e) => {
    setLinkText(e.target.value);
    if (copyFlag) {
      setCopyFlag(false);
    }
  };

  const updateLink = (e) => {
    setLink(e.target.value);
    if (copyFlag) {
      setLinkText(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!disabledLinkText) {
      editor.commands.insertContent(`<a target="_blank" rel="noopener noreferrer nofollow" href=${link}>${linkText}</a>`);
    } else {
      if (link) {
        editor.chain().focus().extendMarkRange('link').setLink({ href: link }).run();
      } else {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }
    }
    handleClose();
  };

  return (
    <Box>
      <ToggleButton value="1" size="small" className={classes.button} onClick={handleOpen}>
        <Link color="inherit" />
      </ToggleButton>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {disabledLinkText ? t('richTextEditor.toolbar.link.titleEdit') : t('richTextEditor.toolbar.link.titleCreate')}
        </DialogTitle>
        <DialogContent>
          {!disabledLinkText && (
            <TextField
              autoFocus
              margin="dense"
              label={t('richTextEditor.toolbar.link.labelText')}
              fullWidth
              value={linkText}
              onChange={updateLinkText}
            />
          )}
          <TextField
            autoFocus
            margin="dense"
            label={t('richTextEditor.toolbar.link.labelLink')}
            fullWidth
            value={link}
            onChange={updateLink}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            <strong>{t('richTextEditor.toolbar.link.cancel')}</strong>
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            <strong>
              {disabledLinkText ? t('richTextEditor.toolbar.link.confirmEdit') : t('richTextEditor.toolbar.link.confirmCreate')}
            </strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TipTapToolbarLink;
