import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Uuid } from '@focus-front/core';
import { LinearProgress, makeStyles, Typography } from '@material-ui/core';

import AccountLabel from '../Common/AccountLabel';
import ErrorMessage from '../Common/ErrorMessage';
import ImThreadBubblesContainer from './ImThreadBubblesContainer';
import AttendeeTitle from '../Contact/AttendeeTitle';
import { useImThread, useImThreadRedux } from './useImThread';
import EditableTags from '../Tag/EditableTags';

const useClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing(2),

    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
}));

export default function ImThreadView({ thread_id }: { thread_id: Uuid }) {
  const c = useClasses();
  const { t } = useTranslation();
  const { thread, ims, status, loadMore } = useImThreadRedux(thread_id);

  if (status === 'IDLE_AUTO' && !thread)
    return <ErrorMessage title={t('thread.notFoundTitle')} subtitle={t('thread.notFoundSubtitle')} />;

  if (!thread) return <LinearProgress />;

  const attendees = thread.attendees.filter((att) => att.display_name !== 'YOU');
  if (!attendees.length) return <LinearProgress />;

  return (
    <div className={c.container}>
      <div className={c.header}>
        <AttendeeTitle attendees={attendees} titleOverride={thread.provider_name} />
        <div className={c.details}>
          <AccountLabel account_id={thread.account_id} className={c.detailText} />
          <EditableTags tags={thread.tags} element="IM_THREAD" element_id={thread.id} />
        </div>
      </div>
      <ImThreadBubblesContainer
        ims={ims}
        thread={thread}
        startReached={() => {
          status === 'IDLE_AUTO' && loadMore();
        }}
        showMoreClick={loadMore}
        retryClick={loadMore}
        showMoreButton={status === 'IDLE_MANUAL'}
        showMoreError={status === 'ERROR'}
        showMoreLoading={status === 'LOADING'}
        showMoreUnsupported={status === 'UNSUPPORTED'}
      />
    </div>
  );
}
