import { selectSearchResult, useAppDispatch } from '@focus-front/core';
import { makeStyles, Typography } from '@material-ui/core';
import { personCircleOutline, personOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso } from 'react-virtuoso';
import ContactSearchResult from '../../Contact/ContactSearchResult';
import { useContactsList } from '../../Contact/useContactsList';
import AllListContainer, { AllListProps } from './AllListContainer';
import NoResults from './NoResults';

const useClasses = makeStyles((theme) => ({
  group: {
    paddingLeft: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[200],
  },
}));

export default function AllContactsList({ onBack }: Pick<AllListProps, 'onBack'>) {
  const { t } = useTranslation();
  const c = useClasses();
  const dispatch = useAppDispatch();

  const { contacts, groupCounts, groups, loading } = useContactsList(true);

  if (loading) return null;

  return (
    <AllListContainer title={t('search.contact_plural')} onBack={onBack}>
      {groupCounts.length > 0 && (
        <GroupedVirtuoso
          style={{ height: '100%' }}
          groupCounts={groupCounts}
          groupContent={(index) => {
            return (
              <div className={c.group}>
                <Typography variant="subtitle2">{groups[index]}</Typography>
              </div>
            );
          }}
          itemContent={(index) => (
            <ContactSearchResult
              index={index}
              key={index}
              // Contact must be converted into ContactDocument (the search type)
              contact={{ item: contacts[index], matches: [], refIndex: 0 }}
              onClick={() => dispatch(selectSearchResult({ id: contacts[index].id, type: 'contact' }))}
            />
          )}
        />
      )}
      {!groupCounts.length && <NoResults icon={personCircleOutline} label={t('search.contact_plural')} />}
    </AllListContainer>
  );
}
