import { KeyStore, KeyTopic } from './KeyStore';
import { Idb } from '../../../Common/infra/services/idb';
import { WrappedKey } from '../services/crypto/Crypto';

/**
 *
 */
export class IdbKeyStore implements KeyStore {
  /**
   *
   */
  //   constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   *
   */
  async get(topic: KeyTopic): Promise<WrappedKey | null> {
    return (await Idb.get('keys', topic))?.wrappedKey ?? null;
  }

  /**
   *
   */
  async getAll(): Promise<Partial<Record<KeyTopic, WrappedKey>>> {
    return (await Idb.getAll('keys')).reduce((map, entry) => {
      map[entry.topic as KeyTopic] = entry.wrappedKey;
      return map;
    }, {} as Partial<Record<KeyTopic, WrappedKey>>);
  }

  /**
   *
   */
  async put(topic: KeyTopic, wrappedKey: WrappedKey): Promise<WrappedKey> {
    await Idb.put('keys', { topic, wrappedKey });
    return wrappedKey;
  }

  /**
   *
   */
  async invalidate(topic: KeyTopic): Promise<void> {
    return Idb.delete('keys', topic);
  }
}
