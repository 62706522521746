import { UnsupportedDevice } from '../../../Common';
import { NewCallDTO } from '../../domain';
import { CallService } from './CallService';

export class UnsupportedCallService implements CallService<any> {
  registerAccount(): void {
    return;
  }

  unregisterAccount(): void {
    return;
  }

  async searchCalls(): Promise<NewCallDTO[]> {
    throw new UnsupportedDevice();
  }
}
