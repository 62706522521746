import Fuse from 'fuse.js';
import { Taggable } from '../../../Tag';
import { UnipileCalendarEventInstanceId, ViewCalendarEvent } from '../../../Calendar';
import { ViewCall } from '../../../Call';
import { Uuid } from '../../../Common';
import { MaybePromise, notEmpty } from '../../../Common/utils';
import { ViewContact } from '../../../Contact';
import { isZeroInboxImThread, ViewImThread } from '../../../Im';
import { PartialViewMail, ViewMailDraft } from '../../../Mail';

import {
  AttendeeDocument,
  CallDocument,
  ContactDocument,
  EventDocument,
  ExtendedViewIm,
  ImDocument,
  MailDocument,
  SearchResult,
  SearchService,
} from './SearchService';
import i18n from '../../../Common/infra/services/i18nextService';

const COMMON_OPTIONS = {
  useExtendedSearch: true,
  includeMatches: true,
  minMatchCharLength: 4,
  threshold: 0,
};

export default class FuseService implements SearchService {
  private index: {
    mail: Fuse<MailDocument>;
    event: Fuse<EventDocument>;
    call: Fuse<CallDocument>;
    im: Fuse<ImDocument>;
    contact: Fuse<ContactDocument>;
    attendees: Fuse<AttendeeDocument>;
  };
  constructor() {
    this.index = {
      mail: new Fuse([], {
        keys: ['subject', 'body_plain', 'from_attendee.display_name', 'from_attendee.identifier', 'tags.label'],
        ...COMMON_OPTIONS,
      }),
      event: new Fuse([], {
        keys: ['summary', 'description', 'location', 'tags.label'],
        ...COMMON_OPTIONS,
        // sortFn: (a, b) => (a.item.start < b.item.start ? -1 : a.item.start > b.item.start ? 1 : 0),
      }),
      call: new Fuse([], {
        keys: ['from_attendee.display_name', 'from_attendee.identifier', 'tags.label'],
        ...COMMON_OPTIONS,
      }),
      im: new Fuse([], {
        keys: ['from_attendee.display_name', 'from_attendee.identifier', 'body', 'thread.name', 'thread.external_name'],
        ...COMMON_OPTIONS,
      }),
      contact: new Fuse([], {
        keys: ['full_name', 'email.email', 'phone.number', 'social.identifier', 'tags.label'],
        ...COMMON_OPTIONS,
      }),
      attendees: new Fuse([], {
        keys: ['display_name', 'identifier'],
        ...COMMON_OPTIONS,
        includeScore: true,
      }),
    };
  }

  private formatQuery(query: string) {
    return query
      .trim()
      .split(' ')
      .map((word) => "'" + word)
      .join(' ');
  }

  private formatResults<T>(results: Fuse.FuseResult<T>[]): SearchResult<T>[] {
    return results.map((res) => ({
      item: res.item,
      matches:
        res.matches?.map((match) => ({
          indices: match.indices?.map((indice) => indice) || [],
          key: match.key,
        })) || [],
      refIndex: res.refIndex,
      ...(res.score && { score: res.score }),
    }));
  }

  private formatMailDocument(mail: PartialViewMail & Taggable): MailDocument {
    return {
      id: mail.id,
      body_plain: mail.body_plain,
      from_attendee: mail.from_attendee,
      subject: mail.subject || '',
      has_attachments: mail.has_attachments,
      date: mail.date,
      draft: false,
      tags: mail.tags,
    };
  }

  private formatMailDraftDocument(draft: ViewMailDraft): MailDocument {
    return {
      id: draft.id,
      body_plain: draft.body || '',
      from_attendee: draft.from_attendee
        ? {
            display_name: draft.from_attendee.display_name,
            identifier: draft.from_attendee.identifier,
          }
        : undefined,
      subject: draft.subject || '',
      has_attachments: draft.attachment_ids ? draft.attachment_ids?.length > 0 : false,
      date: draft.update_date,
      draft: true,
      parent_mail_id: draft.parent_mail_id,
      tags: [],
    };
  }

  private formatEventDocument(event: ViewCalendarEvent & Taggable): EventDocument {
    return {
      id: event.id,
      summary: event.summary || i18n.t('calendar.form.defaultTitle'),
      end: event.end,
      start: event.start,
      ...(event.description && { description: event.description }),
      ...(event.location && { location: event.location }),
      status: event.status,
      ...(event.kind === 'INSTANCE' && { recurring_event_id: event.recurring_event_id }),
      tags: event.tags,
    };
  }

  private formatCallDocument(call: ViewCall & Taggable): CallDocument {
    return {
      from_attendee: call.from_attendee,
      date: call.date,
      id: call.id,
      type: call.type,
      account_id: call.account_id,
      tags: call.tags,
    };
  }

  private formatImDocument(im: ExtendedViewIm, thread: ViewImThread): ImDocument {
    return {
      from_attendee: {
        identifier: im.from_attendee_identifier,
        display_name: thread.attendees.find((att) => att.identifier === im.from_attendee_identifier)?.display_name,
      },
      date: im.date,
      id: im.id,
      body: im.body,
      thread_id: im.thread_id,
      account_type: im.account_type,
      thread: {
        attendees: thread.attendees,
        provider_name: thread.provider_name,
      },
    };
  }

  private formatContactDocument(contact: ViewContact & Taggable): ContactDocument {
    return contact;
  }

  indexMails(mails: (PartialViewMail & Taggable)[], drafts: ViewMailDraft[]): MaybePromise<void> {
    return this.index.mail.setCollection([...mails.map(this.formatMailDocument), ...drafts.map(this.formatMailDraftDocument)]);
  }

  indexMailDrafts(drafts: ViewMailDraft[]): MaybePromise<void> {
    this.index.mail.setCollection(drafts.map(this.formatMailDraftDocument));
  }

  indexEvents(events: (ViewCalendarEvent & Taggable)[]): MaybePromise<void> {
    this.index.event.setCollection(events.map(this.formatEventDocument));
  }

  indexContacts(contacts: (ViewContact & Taggable)[]): MaybePromise<void> {
    this.index.contact.setCollection(contacts.map(this.formatContactDocument));
  }

  indexCalls(calls: (ViewCall & Taggable)[]): MaybePromise<void> {
    this.index.call.setCollection(calls.map(this.formatCallDocument));
  }

  indexIms(ims: ExtendedViewIm[], threads: ViewImThread[]): MaybePromise<void> {
    this.index.im.setCollection(
      ims
        .map((im) => {
          const thread = threads.find((thread) => thread.id === im.thread_id);
          if (!thread || isZeroInboxImThread(thread)) return null;
          return this.formatImDocument(im, thread);
        })
        .filter(notEmpty)
    );
  }

  indexAttendees(attendees: AttendeeDocument[]): MaybePromise<void> {
    this.index.attendees.setCollection(attendees);
  }

  addMail(mail: PartialViewMail & Taggable): MaybePromise<void> {
    this.index.mail.add(this.formatMailDocument(mail));
  }

  addCall(call: ViewCall & Taggable): MaybePromise<void> {
    this.index.call.add(this.formatCallDocument(call));
  }

  addIm(im: ExtendedViewIm, thread: ViewImThread): MaybePromise<void> {
    this.index.im.add(this.formatImDocument(im, thread));
  }

  addAttendee(attendee: AttendeeDocument): MaybePromise<void> {
    this.index.attendees.add(attendee);
  }

  addEvent(event: ViewCalendarEvent & Taggable): MaybePromise<void> {
    this.index.event.add(this.formatEventDocument(event));
  }

  addEvents(events: (ViewCalendarEvent & Taggable)[]): MaybePromise<void> {
    events.forEach((e) => this.index.event.add(this.formatEventDocument(e)));
  }

  putContact(contact: ViewContact & Taggable): MaybePromise<void> {
    this.index.contact.remove((doc) => doc.id === contact.id);
    this.index.contact.add(this.formatContactDocument(contact));
  }

  /**
   * @todo To work with partial update where you only have access to DomainEvent
   *       with a couple of updated values instead of the complete CalendarEvent,
   *       consider something like :
   *
   *       patchEvent(patch: Select<ViewCalendarEvent,'id'>): MaybePromise<void> {
   *         const [old]  = this.index.event.remove((doc) => doc.id === event.id);
   *         this.index.event.add(this.formatEventDocument({...old, ...patch}));
   *       }
   *
   *
   */
  putEvent(event: ViewCalendarEvent & Taggable): MaybePromise<void> {
    this.index.event.remove((doc) => doc.id === event.id);
    this.index.event.add(event);
  }

  putMail(mail: PartialViewMail & Taggable): MaybePromise<void> {
    this.index.mail.remove((doc) => doc.id === mail.id);
    this.index.mail.add(this.formatMailDocument(mail));
  }

  putCall(call: ViewCall & Taggable): MaybePromise<void> {
    this.index.call.remove((doc) => doc.id === call.id);
    this.index.call.add(this.formatCallDocument(call));
  }

  putMailDraft(draft: ViewMailDraft): MaybePromise<void> {
    this.index.mail.remove((doc) => doc.id === draft.id);
    this.index.mail.add(this.formatMailDraftDocument(draft));
  }

  removeCall(id: Uuid): MaybePromise<void> {
    this.index.call.remove((doc) => doc.id === id);
  }

  removeMail(id: Uuid): MaybePromise<void> {
    this.index.mail.remove((doc) => doc.id === id);
  }

  removeIm(id: Uuid): MaybePromise<void> {
    this.index.im.remove((doc) => doc.id === id);
  }

  removeContact(id: Uuid): MaybePromise<void> {
    this.index.contact.remove((doc) => doc.id === id);
  }

  removeEvent(id: Uuid | UnipileCalendarEventInstanceId): MaybePromise<void> {
    this.index.event.remove((doc) => doc.id === id);
  }

  removeRecurringEvent(recurring_event_id: Uuid): MaybePromise<void> {
    this.index.event.remove((doc) => doc.recurring_event_id === recurring_event_id);
  }

  removeMailDraft(id: Uuid): MaybePromise<void> {
    this.index.mail.remove((doc) => doc.id === id);
  }

  searchMails(query: string): MaybePromise<SearchResult<MailDocument>[]> {
    const results = this.index.mail.search(this.formatQuery(query));
    return this.formatResults<MailDocument>(results);
  }

  searchEvents(query: string): MaybePromise<SearchResult<EventDocument>[]> {
    const results = this.index.event.search(this.formatQuery(query));
    // const now = dayjs();
    const r = this.formatResults<EventDocument>(results).sort(
      // Distance from now, interleaved.
      //   (a, b) => Math.abs(now.diff(a.item.start)) - Math.abs(now.diff(b.item.start))
      //
      // Distance from now, future events first.
      //   (a, b) => {
      //     const diffA = now.diff(a.item.start);
      //     const diffB = now.diff(b.item.start);
      //     if (diffA < 0) {
      //       return diffB < 0 ? diffB - diffA : -1;
      //     } else {
      //       return diffB < 0 ? 1 : diffA - diffB;
      //     }
      //   }
      //
      // Chronological.
      (a, b) => (a.item.start < b.item.start ? -1 : a.item.start > b.item.start ? 1 : 0)
    );
    // prettyPrint({ r: r.map((e) => ({ diff: Math.abs(now.diff(e.item.start)), start: e.item.start })) }, 'searchEvents', 'warn');

    return r;
  }

  searchCalls(query: string): MaybePromise<SearchResult<CallDocument>[]> {
    const results = this.index.call.search(this.formatQuery(query));
    return this.formatResults<CallDocument>(results);
  }

  searchIms(query: string): MaybePromise<SearchResult<ImDocument>[]> {
    const results = this.index.im.search(this.formatQuery(query));
    return this.formatResults<ImDocument>(results);
  }

  searchContacts(query: string): MaybePromise<SearchResult<ContactDocument>[]> {
    const results = this.index.contact.search(this.formatQuery(query));
    return this.formatResults<ContactDocument>(results);
  }

  searchAttendees(query: string): MaybePromise<SearchResult<AttendeeDocument>[]> {
    const results = this.index.attendees.search(this.formatQuery(query));
    return this.formatResults<AttendeeDocument>(results);
  }
}
