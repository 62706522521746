import { PreviewMessageModel } from "@focus-front/core";
import { IconButton, makeStyles, Popper } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import parse from "html-react-parser";
import React, { useState } from "react";
import { usePickerClasses } from "../Common/usePickerClasses";
import Tags from "../Tag/Tags";

const useClasses = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  preview: {
    whiteSpace: 'pre-line',
  },
  box: {
    position: "relative",
    cursor: 'pointer',
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'unset',
    minWidth: 221,
    maxWidth: 500
  },
  tags: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  editButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}));

type Props = {
  anchorEl: Element;
  preview: PreviewMessageModel;
  handleEdit: () => void;
  handleUse: () => void;
}

export default function MessageModelPreview({ anchorEl, preview, handleEdit, handleUse }: Props) {
  const open = Boolean(anchorEl);
  const classes = usePickerClasses();
  const c = useClasses();

  const [showEditButton, setShowEditButton] = useState(false);

  const edit = (e) => {
    e.stopPropagation()
    handleEdit();
 }

  return (
    <div className={c.wrapper} onMouseEnter={() => setShowEditButton(true)} onMouseLeave={() => setShowEditButton(false)}>
      <Popper open={open} anchorEl={anchorEl} placement="right" className={classes.popper + ' ' + c.box} onClick={handleUse} >
        <div className={preview?.destination === 'IM' ? c.preview : ''}>{parse(preview?.content || '')}</div>
        {
          preview?.tags.length > 0 && (
            <div className={c.tags}>
              <Tags tags={preview.tags} maxVisible={4} />
            </div>
          )
        }
        { showEditButton &&
          <div className={c.editButton}>
            <IconButton
              onClick={edit}
              >
              <Edit fontSize="small" />
            </IconButton>
          </div>
        }
      </Popper>
    </div>
  )
}