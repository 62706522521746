import { ViewTag } from '@focus-front/core';
import { Chip, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useRef, useState } from 'react';

type Props = {
  tag: ViewTag;
  onDelete?: () => void;
  size?: 'small' | 'medium';
  onClick?: () => void;
};

const useClasses = makeStyles((theme) => ({
  root: ({ tag }: Props) => ({
    backgroundColor: tag.color,
    marginLeft: 3,
    marginBottom: 1,
    marginTop: 1,
    maxWidth: 200,
    '&:focus': {
      backgroundColor: tag.color,
    },
  }),
  label: {
    color: 'white',
  },
  deleteIcon: {
    color: 'white',
  },
}));

export default function Tag({ tag, onDelete, onClick, size = 'small' }: Props) {
  const c = useClasses({ tag });

  return <Chip classes={c} size={size} onDelete={onDelete} onClick={onClick} label={tag.label} deleteIcon={<CloseIcon />} />;
}
