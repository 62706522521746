import {
  RecurringCalendarEvent,
  SingleCalendarEvent,
  CalendarEventInstanceId,
  CalendarEventRecurringKind,
  CalendarEventSingleKind,
} from './ViewCalendarEvent';
import { CalendarEventException } from '../types/CalendarEventException';
import { Uuid } from '../../../Common';
import { RecurringCalendarEventDTO } from '../types/RecurringCalendarEventDTO';
import { Strip } from '../../../Common/utils';

/**
 * Discriminant properties : type, kind, all_day.
 *
 * @note A recurring event can be turned into a single, non-recurring event.
 *       However, as per Google calendar, exceptions are not discarded ! To keep
 *       track of them and allow this behaviour, a recurring root can be in
 *       single mode.
 *
 *       If this gets too confusing/unwieldy, instead of allowing single mode,
 *       we could delete the cached recurring root projection and use a rollBack
 *       projection ?
 */

export type RecurringCalendarEventRoot = (RecurringCalendarEvent | SingleCalendarEvent) & {
  /**
   * @todo Figure out how to keep CalendarEventException union over the
   *        distributive thing going wrong with Record and opaque type as key.
   *
   */
  //   exceptions: Record<CalendarEventInstanceId, CalendarEventException>;
  exceptions: Map<CalendarEventInstanceId, CalendarEventException>;
};

export type RecurringCalendarEventRootStrip = Strip<RecurringCalendarEventRoot, 'exceptions'>;

/**
 *
 */
export function createRecurringCalendarEventRoot<T extends RecurringCalendarEventDTO>(
  aggregateId: Uuid,
  values: T,
  //   exceptionEntries: [CalendarEventInstanceId, CalendarEventException][] = []
  exceptions: Map<CalendarEventInstanceId, CalendarEventException> = new Map()
  //   exceptions: Map<CalendarEventInstanceId, CalendarEventException & { type: T['type'] }> = new Map()
): RecurringCalendarEventRoot & { type: T['type'] } {
  //   const id = ((): CalendarEventSingleKind | CalendarEventRecurringKind => {
  //     switch (values.kind) {
  //       case 'SINGLE':
  //         return { id: aggregateId, kind: 'SINGLE' };
  //       case 'RECURRING':
  //         return { id: aggregateId, kind: 'RECURRING', recurrence: values.recurrence ?? [] };
  //     }
  //   })();
  const id: CalendarEventSingleKind | CalendarEventRecurringKind = {
    ...(values.kind === 'SINGLE'
      ? { id: aggregateId, kind: 'SINGLE' }
      : { id: aggregateId, kind: 'RECURRING', recurrence: values.recurrence ?? [] }),
  };

  // const exceptions = new Map(exceptionEntries);

  switch (values.type) {
    case 'GOOGLE':
      return {
        ...values,
        exceptions,
        ...id,
        status: values.status ?? 'PLANNED',
        google_status: values.google_status ?? 'confirmed',
        reminders: values.reminders ?? { use_default: true },
        attendees: values.attendees ?? [],
        attachments: values.attachments ?? [],
        /** Non-optional acknowledge-only properties. */
        external_id: values.external_id, //?? null,
        etag: values.etag ?? null,
        ical_uid: values.ical_uid ?? null,
        creator: values.creator ?? null,
        organizer: values.organizer ?? null,
        html_link: values.html_link ?? null,
        // hangout_link: values.hangout_link ?? null,
        ...(values.hangout_link && { hangout_link: values.hangout_link }),
      };
    case 'UNIPILE':
      return {
        ...values,
        exceptions,
        ...id,
        status: values.status ?? 'PLANNED',
        attendees: values.attendees ?? [],
      };
  }
}
