import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  getMailView,
  MailAttachment,
  MailCache,
  MailMeta,
  PartialViewMail,
  Taggable,
  useAppDispatch,
  useCancellablePromise,
  Uuid,
} from '@focus-front/core';

type Status = 'LOADING' | 'NOT_FOUND' | 'ERROR' | 'IDLE';

export function useMailView(id: Uuid): {
  mail: PartialViewMail & Taggable;
  attachments: MailAttachment[];
  status: Status;
  bodyRef: MutableRefObject<HTMLDivElement>;
  errorMessage: string;
  setMail: (mail: PartialViewMail & Taggable) => void;
} {
  const [mail, setMail] = useState<PartialViewMail & Taggable>(null);
  const [attachments, setAttachments] = useState<MailAttachment[]>([]);
  const [status, setStatus] = useState<Status>('LOADING');
  const [attLoading, setAttLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('error');
  const dispatch = useAppDispatch();
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Reset the view when navigating and there is already a mail shown
    if (mail || status === 'ERROR') {
      setMail(null);
      if (mail && bodyRef?.current?.shadowRoot) {
        bodyRef.current.shadowRoot.innerHTML = null;
      }
      if (!attLoading) {
        setAttLoading(true);
      }
      setStatus('LOADING');
      window.scrollTo(0, 0);
    }

    dispatch(
      getMailView(id, {
        onContent: (content) => {
          // If the ref does not exist, there is nothing to display the content in.
          if (!bodyRef || !bodyRef.current) return;

          if (!bodyRef.current.shadowRoot) bodyRef.current.attachShadow({ mode: 'open' });

          if (content === null) return setStatus('NOT_FOUND');

          bodyRef.current.shadowRoot.innerHTML = `<div id="mail-body" style="display: table; width: 100%;">${content.body}</div>`;

          setAttachments(content.attachments);
          setStatus('IDLE');
        },
        onMeta: (meta) => {
          setMail(meta);
        },
        onError: (content) => {
          setStatus('ERROR');
          setErrorMessage(content);
        },
      })
    );
  }, [id]);

  return {
    mail,
    status,
    attachments,
    bodyRef,
    errorMessage,
    setMail,
  };
}
