import { AggregateRoot, createProjectionConfig, createUuidFrom, DomainEvent, Uuid } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { IncomingCallReceived, MissedCallReceived, NoAnswerCallReceived, OutgoingCallReceived } from './events/Call.events';
import { NewCallDTO } from './projections/ViewCall';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const CALL_NAMESPACE = '3b67ec67-0023-463f-8b2b-248cfc7e1476' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  account_id: null as Uuid | null,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  MISSED_CALL_RECEIVED: (event) => ({
    id: event.aggregateId,
    account_id: event.call.account_id,
  }),
  OUTGOING_CALL_RECEIVED: (event) => ({
    id: event.aggregateId,
    account_id: event.call.account_id,
  }),
  INCOMING_CALL_RECEIVED: (event) => ({
    id: event.aggregateId,
    account_id: event.call.account_id,
  }),
  NO_ANSWER_CALL_RECEIVED: (event) => ({
    id: event.aggregateId,
    account_id: event.call.account_id,
  }),
});

const callType = {
  MISSED: MissedCallReceived,
  OUTGOING: OutgoingCallReceived,
  INCOMING: IncomingCallReceived,
  NO_ANSWER: NoAnswerCallReceived,
};

/**
 * Aggregate.
 */
export class Call extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static create(content: NewCallDTO) {
    const aggregateId = createUuidFrom(
      content.from_attendee.identifier + content.to_attendee.identifier + content.date,
      CALL_NAMESPACE
    );

    return Call.accept(new callType[content.type](aggregateId, content));

    // let changes: DomainEvent;

    // switch (content.type) {
    //   case 'MISSED':
    //     changes = new MissedCallReceived(aggregateId, content);
    //     break;
    //   case 'OUTGOING':
    //     changes = new OutgoingCallReceived(aggregateId, content);
    //     break;
    //   case 'INCOMING':
    //     changes = new IncomingCallReceived(aggregateId, content);
    //     break;
    //   case 'NO_ANSWER':
    //     changes = new NoAnswerCallReceived(aggregateId, content);
    //     break;
    // }
  }
}
