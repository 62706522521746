import { calendarOutline, informationCircleOutline, pulseOutline, searchOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IonIcon } from '@ionic/react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';

import CalendarFormModal from '../Calendar/CalendarFormModal';
import HeaderTab from '../Header/HeaderTab';
import HeaderTabs from '../Header/HeaderTabs';
import SettingsButton from '../Settings/SettingsButton';
import SearchTab from '../Search/SearchContainer';
import { useSelector } from 'react-redux';
import { setSideTab, sideTabSelector, useAppDispatch } from '@focus-front/core';
import NewCalendar from '../Calendar/NewCalendar';

const RightBar = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
  },
}))(Toolbar);

const TabContainer = withStyles({
  root: {
    padding: 0,
    overflow: 'hidden',
    height: '100%',
  },
})(TabPanel);

const useClasses = makeStyles((theme) => ({
  searchTab: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
  },
  sideContainer: {
    borderLeft: `solid 1px ${theme.palette.divider}`,
    height: 'calc(100% - 50px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
}));

export type SideTab = 'calendar' | 'search' | 'activity' | 'context';

export default function SideArea() {
  const tab = useSelector(sideTabSelector);
  const dispatch = useAppDispatch();
  const c = useClasses();

  const { t } = useTranslation();

  return (
    <TabContext value={tab}>
      <AppBar position="static" elevation={0}>
        <RightBar variant="dense">
          <HeaderTabs value={tab} style={{ flex: 1 }}>
            <HeaderTab
              label={t('header.calendar')}
              value="calendar"
              icon={<IonIcon icon={calendarOutline} />}
              onClick={() => dispatch(setSideTab('calendar'))}
            />
            <HeaderTab
              label={t('header.search')}
              value="search"
              id="tour-1"
              icon={<IonIcon icon={searchOutline} />}
              onClick={() => dispatch(setSideTab('search'))}
            />
            {/**
             * Following feature/581 we don't currently need these tabs into the menu.
             * <HeaderTab
              label={t('header.context')}
              value="context"
              icon={<IonIcon icon={informationCircleOutline} />}
              onClick={() => setTab('context')}
              disabled
            />
            <HeaderTab
              label={t('header.activity')}
              value="activity"
              icon={<IonIcon icon={pulseOutline} />}
              onClick={() => setTab('activity')}
              disabled
            />*/}
          </HeaderTabs>
          <SettingsButton />
        </RightBar>
      </AppBar>
      <div className={c.sideContainer}>
        {/* @note The Calendar MUST stay mounted and visible behind the tabs to allow drag and drop when another tab is open */}
        <div style={{ height: '100%' }}>
          <NewCalendar />
        </div>
        <div style={{ display: tab === 'search' ? 'flex' : 'none' }} className={c.searchTab}>
          <SearchTab />
        </div>
        {/*<TabContainer value="context">
          <Typography variant="h2">Context</Typography>
          <Typography variant="subtitle1">Soon</Typography>
        </TabContainer>
        <TabContainer value="activity">
          <Typography variant="h2">Activité</Typography>
          <Typography variant="subtitle1">Soon</Typography>
          </TabContainer>*/}

        <CalendarFormModal />
      </div>
    </TabContext>
  );
}
