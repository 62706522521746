import { Button, Fade, makeStyles, Slide, Typography } from '@material-ui/core';
import logo from '../../assets/logo.svg';
// import image from '../assets/test_onboarding.gif';
import image from '../../assets/test_svg_wizard.png';
import image2 from '../../assets/test_svg_wizard2.png';
import image3 from '../../assets/test_svg_wizard3.png';
import image4 from '../../assets/test_svg_wizard4.png';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { completeOnboarding, openWizard, useAppDispatch } from '@focus-front/core';

const useClasses = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  left: {
    flex: 1,
    height: '100%',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '15vh',
    paddingBottom: '15vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    transition: 'all .5s ease',
  },
  right: {
    width: '68%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'all .5s ease',
  },
  rightDone: {
    width: 0,
  },
  leftDone: {
    opacity: 0,
  },
  title: {
    marginBottom: 50,
    fontSize: 28,
  },
  body: {
    fontSize: '1.125rem',
    marginBottom: 16,
  },
  img: {
    boxShadow: '2px 2px 30px rgba(0,0,0,0.1)',
    borderRadius: 10,
    display: 'block',
    width: '100%',
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(244,245,247,1) 100%)',
    position: 'absolute',
    top: '80%',
    left: -30,
    right: -30,
    bottom: 0,
  },
  shadowHide: {
    background: 'rgba(244,245,247,1)',
    position: 'absolute',
    top: '100%',
    left: -30,
    right: -30,
    bottom: -80,
  },
  imgContainer: {
    position: 'relative',
    maxWidth: '80%',
  },
  hiddenImg: {
    opacity: 0,
    transform: 'translateY(50px)',
  },
  transition: {
    transition: 'all .3s ease',
  },
  hidden: {
    opacity: 0,
  },
}));

export default function OnboardingTutorial() {
  const c = useClasses();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(-1);
  const [mounted, setMounted] = useState(false);

  const { t } = useTranslation();

  const text = [
    {
      title: t('onboarding.screen1.title'),
      subtitle: t('onboarding.screen1.subtitle'),
      infos: t('onboarding.screen1.infos'),
    },
    {
      title: t('onboarding.screen2.title'),
      subtitle: t('onboarding.screen2.subtitle'),
    },
    {
      title: t('onboarding.screen3.title'),
      subtitle: t('onboarding.screen3.subtitle'),
    },
    {
      title: t('onboarding.screen4.title'),
      subtitle: t('onboarding.screen4.subtitle'),
    },
    // {
    //   title: t('onboarding.screen5.title'),
    //   subtitle: t('onboarding.screen5.subtitle'),
    // },
  ];

  useEffect(() => {
    setMounted(true);
    setTimeout(() => {
      setStep(0);
      setShow(true);
    }, 500);
  }, []);

  const goNext = () => {
    setShow(false);
    setTimeout(() => {
      setStep(step + 1);
      if (step + 1 === text.length) {
        setMounted(false);
        setTimeout(() => {
          dispatch(openWizard());
          dispatch(completeOnboarding());
          // When completing onboarding, the OnboardingPage component redirect to inbox
        }, 500);
      }
      setShow(true);
    }, 500);
  };

  return (
    <div className={c.container}>
      <div className={cn(c.left, !mounted && c.leftDone)}>
        <Fade in={true}>
          <img src={logo} alt="Logo" width="173px" />
        </Fade>

        <Fade in={show}>
          <div>
            {text[step] && (
              <div className={cn(!show && c.hidden, c.transition)}>
                <Typography variant="h1">
                  {step + 1}/{text.length}
                </Typography>
                <Typography variant="h2" className={c.title}>
                  {text[step].title}
                </Typography>

                <Typography variant="body1" className={c.body}>
                  {text[step].subtitle}
                </Typography>
                {text[step].infos && <Typography variant="subtitle2">{text[step].infos}</Typography>}
              </div>
            )}
          </div>
        </Fade>

        <Button variant="contained" color="secondary" onClick={goNext}>
          {step < 1 ? t('onboarding.start') : t('onboarding.next')}
        </Button>
      </div>
      <div className={cn(c.right, !mounted && c.rightDone)}>
        <Fade in={show}>
          <div className={c.imgContainer}>
            <div className={cn(!show && c.hiddenImg, c.transition)}>
              {step === 0 && <img src={image} className={c.img}></img>}
              {step === 1 && <img src={image2} className={c.img}></img>}
              {step === 2 && <img src={image3} className={c.img}></img>}
              {step === 3 && <img src={image4} className={c.img}></img>}
              {/* {step === 4 && <span>recovery key</span>} */}
            </div>

            <div className={c.gradient}></div>
            <div className={c.shadowHide}></div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
