/**
 *
 */
export interface GoogleAuthenticationProvider {
  getAccessToken: () => Promise<string | null>;
  invalidateCachedTokens: () => void;
}

/**
 *
 */
export class MissingOAuthScopesError extends Error {
  constructor(readonly missingScopes: string[]) {
    super(`The following scopes are missing : ${missingScopes.join(' ')}.`);
    this.name = 'MissingOAuthScopesError';
  }
}
