import {
  mockUuid,
  ViewCalendarEvent,
  openViewEvent,
  workEventSelector,
  useAppDispatch,
  getRecurringRoot,
  Taggable,
} from '@focus-front/core';
import { LinearProgress, Typography } from '@material-ui/core';
import { RecurringCalendarEventRoot } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ErrorMessage from '../Common/ErrorMessage';
import EventPage from './EventPage';

/**
 * This component load the view event and redirect to the work mode if the event is in the work events list
 */
export default function ViewEventContainer() {
  const { id }: { id: string } = useParams();
  const dispatch = useAppDispatch();
  const [event, setEvent] = useState<ViewCalendarEvent & Taggable>(null);
  const [recurringEventRoot, setRecurringEventRoot] = useState<RecurringCalendarEventRoot>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const work_event = useSelector(workEventSelector(mockUuid(id)));

  useEffect(() => {
    (async () => {
      const e = await dispatch(openViewEvent(mockUuid(id)));
      setEvent(e);
      setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    if (event?.kind === 'INSTANCE')
      (async () => {
        const recurringEventRootResult = await dispatch(getRecurringRoot(event.recurring_event_id));
        setRecurringEventRoot(recurringEventRootResult);
      })();
  }, [event]);

  if (work_event) return <Redirect to={`/work/${id}`} />;

  if (loading) return <LinearProgress />;

  if (!loading && !event) return <ErrorMessage title={t('event.notFoundTitle')} subtitle={t('event.notFoundSubtitle')} />;

  return <EventPage status="VIEW" event={event} recurringEventRoot={recurringEventRoot} />;
}
