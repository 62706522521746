import { ViewSignature } from './domain';

export function updateSignatureInHtml(body: string, sign: ViewSignature | undefined): string {
  const parsed = document.createElement('div');
  parsed.innerHTML = body;

  // Remove existing signatures
  const signatures = parsed.getElementsByClassName('UnipileSignature');
  while (signatures.length > 0) signatures[0].remove();

  // If account has a signature
  if (sign) {
    // Create the signature element
    const el = document.createElement('div');
    el.setAttribute('class', 'UnipileSignature');
    el.innerHTML = sign.body + '<br/>';

    const reply = parsed.getElementsByTagName('blockquote');
    // If draft has reply, insert the signature before the reply preview, otherwise at the end
    if (reply.item(0) !== null) {
      //@ts-ignore parentNode is created
      reply.item(0).parentNode.insertBefore(el, reply.item(0));
    } else {
      parsed.appendChild(document.createElement('br'));
      parsed.appendChild(el);
    }
  }
  console.log(parsed.innerHTML);
  return parsed.innerHTML;
}
