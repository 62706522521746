import { createProjector } from '../../../Common';
import { createMailReference } from '../projections/MailReference';
import { MailReferenceRepo } from '../projections/MailReferenceRepo';

/**
 * Handler/Projector.
 */
export const viewMailsProjector = createProjector('viewMailsProjector', {
  MAIL_RECEIVED: async ({ aggregateId, newMail }, repo: MailReferenceRepo) => {
    return repo.add(createMailReference(aggregateId, newMail));
  },
});
/**
 * @note Archiving a mail should not delete it so we can still find it via Search
 *
 * @todo Mark mail as archived !
 * @todo Use mail reply status !
 */
/*
MAIL_ARCHIVED: ({ aggregateId }, repo) => {
  repo.remove(aggregateId);
},
*/
