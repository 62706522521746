import { EncryptedCredentialsRepo, GoogleCalendarConnectionParams, isGoogleCalendarCredentials } from '../../../Account';
import { Uuid } from '../../../Common';
import { Crypto, EncryptionCryptoKey } from '../../../Common/infra/services/crypto/Crypto';
import { GoogleWebAuthenticationProvider } from '../../../Common/infra/services/google/GoogleWebAuthenticationProvider';
import { CalendarService } from './CalendarService';
import { GcalApiClient } from './GcalApiClient';
import { GcalService } from './GcalService';

/**
 *
 */
export class GcalWebService extends GcalService implements CalendarService<GoogleCalendarConnectionParams> {
  /**
   *
   */
  constructor(
    protected readonly config: { host: string; password: string; username: string },
    protected readonly credentials: EncryptedCredentialsRepo,
    protected readonly crypto: Crypto
  ) {
    super();
  }

  /**
   *
   */
  registerAccount(accountId: Uuid, connectionParams: GoogleCalendarConnectionParams, credentialsKey: EncryptionCryptoKey): void {
    /**
     * @note If we don't allow a updated connection to be set, how else can we
     *       communicate that credentials were renewed after a reconnect ?
     *
     *       Invalidating cached tokens on 401 in GcalApiClient ? Sure, but doesn't it
     *       require another request to fail before it tries to retrieve updated
     *       tokens ?
     */
    if (!this.connections.has(accountId)) {
      const crypto = this.crypto;

      
      this.connections.set(
        accountId,
        new GcalApiClient(
          new GoogleWebAuthenticationProvider(
            this.config,
            async (account_id: Uuid) => {
              const encryptedCredentials = await this.credentials.get(account_id);
              if (!encryptedCredentials) {
                return null;
              }

              const parsed = JSON.parse(await crypto.decrypt(credentialsKey, encryptedCredentials.value));

              return isGoogleCalendarCredentials(parsed) ? parsed.devices.web ?? null : null;
            },
            accountId
          )
        )
      );
    }
  }
}
