import { IDBPDatabase } from 'idb';
import { notEmpty } from '../../../Common/utils';
import { ViewTagRelationRepo, ViewTagRepo, Taggable, ViewTagRelation } from '../../../Tag';
import { Uuid } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { ViewMessageModelRepo, ViewMessageModel, UpdateMessageModelDTO, createViewMessageModel } from '../../domain';

/**
 * Repository.
 *
 */
export class IdbViewMessageModelRepo implements ViewMessageModelRepo {
  constructor(private readonly viewTagRelation: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  async add(messageModel: ViewMessageModel): Promise<string> {
    return Idb.put('viewMessageModels', messageModel);
  }

  async remove(id: Uuid) {
    Idb.delete('viewMessageModels', id);
  }

  async get(id: Uuid): Promise<(ViewMessageModel & Taggable) | null> {
    const messageModel = await Idb.get('viewMessageModels', id);
    if (!messageModel) return null;

    const tags = await this.viewTagRelation.getTagsByElement('MESSAGE_MODEL', id);
    return { ...messageModel, tags };
  }

  async update(id: Uuid, values: UpdateMessageModelDTO): Promise<void> {
    const tx = Idb.transaction('viewMessageModels', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewMessageModel ${id} does not exist.`);
    }

    tx.store.put(createViewMessageModel(id, { ...old_projection, ...values }));
    return tx.done;
  }

  private async _getTags(results: ViewMessageModel[]): Promise<(ViewMessageModel & Taggable)[]> {
    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    for (let i = 0, length = results.length; i < length; ++i) {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['MESSAGE_MODEL', results[i].id]));
    }

    const allTags = await this.viewTagRepo.getAll();

    const messageModelsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...results[i], tags };
      })
    );

    return messageModelsWithTags;
  }

  async getAll(): Promise<ViewMessageModel[]> {
    return Idb.getAll('viewMessageModels');
  }

  async getAllWithTags(): Promise<(ViewMessageModel & Taggable)[]> {
    const messageModels = await Idb.getAll('viewMessageModels');
    return this._getTags(messageModels);
  }

  async clear(): Promise<void> {
    return Idb.clear('viewMessageModels');
  }
}
