import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ImapMailConfigForm, WizardContext } from './WizardContext';
import { useTranslation } from 'react-i18next';
import { testMailConnection, useAppDispatch } from '@focus-front/core';
import { BackButton, NextButton } from './NavButton';
import { Alert } from '@material-ui/lab';

export default function ImapMailConfiguration() {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  const dispatch = useAppDispatch();

  const { forms, setLoading, setForms, goNext, goBack } = useContext(WizardContext);

  const onSubmit = async ({ imap_password, smtp_password, ...rest }: ImapMailConfigForm) => {
    setLoading(true);
    setForms({ ...forms, IMAP_MAIL_1: { imap_password, smtp_password, ...rest } });
    const success = await dispatch(testMailConnection({ type: 'MAIL', imap_pwd: imap_password, smtp_pwd: smtp_password }, rest));
    if (!success) {
      setError(t('wizard.imapMailConfig.connexionFailError'));
      setLoading(false);
    } else {
      goNext();
      setError('');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formState.isValid) handleSubmit(onSubmit)();
  }, []);

  const { register, formState, handleSubmit } = useForm<ImapMailConfigForm>({
    mode: 'onChange',
    defaultValues: forms.IMAP_MAIL_1,
  });

  return (
    <div>
      <BackButton onClick={goBack} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Typography variant="h2">{t('wizard.imapMailConfig.title1')}</Typography>
        <Typography variant="subtitle2">{t('wizard.imapMailConfig.subtitle1')}</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Box display="flex" mt={3} mb={5}>
          <Box width="50%" borderRight="solid 1px #ccc" pr={2}>
            <Typography variant="body1" align="center">
              IMAP
            </Typography>
            <Typography variant="subtitle2" align="center">
              Emails entrants
            </Typography>
            <Box mb={1}>
              <TextField
                inputRef={register({ required: true })}
                name="imap_host"
                label={t('wizard.imapMailConfig.serverLabel')}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box mb={1}>
              <TextField
                inputRef={register({ required: true })}
                name="imap_user"
                label={t('wizard.imapMailConfig.mailLabel')}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box mb={1}>
              <TextField
                inputRef={register()}
                name="imap_password"
                label={t('wizard.imapMailConfig.passwordLabel')}
                fullWidth
                type="password"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box mb={1}>
              <TextField
                inputRef={register({ required: true })}
                name="imap_port"
                label={t('wizard.imapMailConfig.portLabel')}
                fullWidth
                variant="standard"
                type="number"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
          <Box width="50%" pl={2}>
            <Typography variant="body1" align="center">
              SMTP
            </Typography>
            <Typography variant="subtitle2" align="center">
              Emails sortants
            </Typography>
            <Box mb={1}>
              <TextField
                inputRef={register({ required: true })}
                name="smtp_host"
                label={t('wizard.imapMailConfig.serverLabel')}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box mb={1}>
              <TextField
                inputRef={register({ required: true })}
                name="smtp_user"
                label={t('wizard.imapMailConfig.mailLabel')}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box mb={1}>
              <TextField
                inputRef={register()}
                name="smtp_password"
                label={t('wizard.imapMailConfig.passwordLabel')}
                fullWidth
                type="password"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box mb={1}>
              <TextField
                inputRef={register({ required: true })}
                name="smtp_port"
                label={t('wizard.imapMailConfig.portLabel')}
                fullWidth
                type="number"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        </Box>
        <NextButton type="submit" />
      </form>
    </div>
  );
}
