import React from 'react';

import { convertBase64, formatBytes, getMailAttachmentFile, LocalFile } from '@focus-front/core';
import { Avatar, Card, CardActionArea, CardHeader } from '@material-ui/core';
import { FileOpener } from '@ionic-native/file-opener';
import { MailAttachment } from '@focus-front/core';

import { IonIcon } from '@ionic/react';
import { documentOutline, documentTextOutline, imageOutline, musicalNotesOutline, videocamOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import { useAppDispatch } from '@focus-front/core';

export interface AttachmentProps {
  attachment: MailAttachment;
  mode?: 'upload' | 'download';
  loaded: boolean;
  onDelete?: (id: string) => void;
}

function extIcon(mime: string) {
  let icon = documentOutline;
  if (mime.startsWith('audio')) icon = musicalNotesOutline;
  if (mime.startsWith('text')) icon = documentTextOutline;
  if (mime.startsWith('video')) icon = videocamOutline;
  if (mime.startsWith('image')) icon = imageOutline;
  return <IonIcon icon={icon} />;
}

export const Attachment = ({ attachment, loaded, mode = 'download', onDelete }: AttachmentProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDownload = async () => {
    const file = await dispatch(getMailAttachmentFile(attachment.id));

    if (!file) return alert("Can't download this attachment");

    // On Mobile, use native file opener
    if (file.data.startsWith('file://') || file.data.startsWith('http://')) {
      FileOpener.showOpenWithDialog(file.data, attachment.mime.split(';')[0]);

      // On Web, create a download link
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:${attachment.mime}; base64,${convertBase64(file.data || '')}`;
      downloadLink.download = attachment.name || t('attachment.defaultTitle');
      downloadLink.click();
    }
  };

  return (
    <Card variant="outlined">
      <CardActionArea disabled={!loaded} href="" onClick={mode === 'download' ? handleDownload : undefined}>
        <CardHeader
          avatar={<Avatar>{extIcon(attachment.mime)}</Avatar>}
          title={attachment.name}
          subheader={formatBytes(attachment.size, 0)}
        />
      </CardActionArea>
    </Card>
  );
};
