import { checkmark } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AccountSourceType,
  addMailAccount,
  useAppDispatch,
  ViewAccount,
  initialMailFetchNew,
  Uuid,
  addGoogleAccount,
  addOutlookAccount,
  addLinkedInAccount,
  addMessengerAccount,
  addTikTokAccount,
  addTwitterAccount,
  addInstagramAccount,
  initialImThreadsFetch,
  addWhatsAppAccount,
  initialContactFetch,
  addGoogleCalendarAccount,
  initialGoogleCalendarFetch,
  setSourceStatus,
  initialCallFetchNew,
  initFullFetch,
  updateElementTags,
  addICloudAccount,
  getCalendars,
  prettyPrint,
  //getAccountCalendars,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';

import { SourceIcon } from './SourceSelection';
import { WizardContext, GMAIL_IMAP_CONFIG_DEFAULTS, ICLOUD_IMAP_CONFIG_DEFAULTS } from './WizardContext';
import { Alert } from '@material-ui/lab';
import { Error } from '@material-ui/icons';
import { AccountTypeIcon } from '@focus-front/ui';

interface Props {
  account: ViewAccount;
}

const useClasses = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: 5,
  },
  icon: {
    position: 'absolute',
    right: -6,
    bottom: -4,
    fontSize: 30,
    borderRadius: 20,
    height: 21,
    width: 21,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ConfigurationSuccess({ onEnd }: { onEnd: () => void }) {
  const { t } = useTranslation();
  const c = useClasses();
  const [loading, setLoading] = useState<'ACCOUNT' | 'CONTACTS' | 'THREADS' | 'MAILS' | false>('ACCOUNT');
  const [error, setError] = useState<string>('');
  const dispatch = useAppDispatch();

  const { accountType, sources, goNext, forms, alias, goBack, accountId, goToStep } = useContext(WizardContext);

  useEffect(() => {
    (async () => {
      let account_id: Uuid;
      if (accountType === 'MAIL') {
        const { imap_password, smtp_password, ...params } = forms.IMAP_MAIL_1;
        const res = await dispatch(
          addMailAccount(
            alias,
            {
              type: 'MAIL',
              imap_pwd: imap_password,
              smtp_pwd: smtp_password,
            },
            { mail: params }
          )
        );
        account_id = res.account_id;
        if (res.error) return setError(error);
      }
      if (accountType === 'GOOGLE') {
        if (sources.MAILS) {
          const { email, password } = forms.GOOGLE_MAIL;
          const res = await dispatch(
            addGoogleAccount(
              alias,
              {
                type: 'GOOGLE',
                mail: {
                  imap_pwd: password,
                  smtp_pwd: password,
                },
              },
              {
                mail: {
                  ...GMAIL_IMAP_CONFIG_DEFAULTS,
                  imap_user: email,
                  smtp_user: email,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'ICLOUD') {
        if (sources.MAILS) {
          const { email, password } = forms.ICLOUD_MAIL;
          const res = await dispatch(
            addICloudAccount(
              alias,
              {
                type: 'ICLOUD',
                mail: {
                  imap_pwd: password,
                  smtp_pwd: password,
                },
              },
              {
                mail: {
                  ...ICLOUD_IMAP_CONFIG_DEFAULTS,
                  imap_user: email,
                  smtp_user: email,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'GOOGLE_CALENDAR') {
        /**
         * @note The following two commented lines are now done in a previous step to allow for
         *       further account configuration before displaying the final success panel.
         */
        // const { at, rt, identifier } = forms.GOOGLE_CALENDAR_LOGIN;
        // const res = await dispatch(addGoogleCalendarAccount(alias, { at, rt }, { calendar: identifier }));
        account_id = accountId;
      }
      if (accountType === 'OUTLOOK') {
        if (sources.MAILS) {
          const { at, rt, account } = forms.MICROSOFT_LOGIN;
          const res = await dispatch(
            addOutlookAccount(
              alias,
              {
                at,
                rt,
              },
              {
                mail: account,
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'LINKEDIN') {
        if (sources.IMS) {
          const { linkedin_user, linkedin_cookie, use_proxy, proxy_host, proxy_password, proxy_port, proxy_username } =
            forms.LINKEDIN_LOGIN;
          const res = await dispatch(
            addLinkedInAccount(
              alias,
              {
                type: 'LINKEDIN',
                cookie: linkedin_cookie,
              },
              {
                im: {
                  username: linkedin_user,
                  proxy: use_proxy
                    ? {
                        host: proxy_host,
                        password: proxy_password,
                        port: proxy_port,
                        username: proxy_username,
                      }
                    : undefined,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'MESSENGER') {
        if (sources.IMS) {
          const { messenger_user, messenger_cookies } = forms.MESSENGER_LOGIN;
          const res = await dispatch(
            addMessengerAccount(
              alias,
              {
                type: 'MESSENGER',
                cookies: messenger_cookies,
              },
              {
                im: {
                  username: messenger_user,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'TIKTOK') {
        if (sources.IMS) {
          const { tiktok_user, tiktok_cookies } = forms.TIKTOK_LOGIN;
          const res = await dispatch(
            addTikTokAccount(
              alias,
              {
                type: 'TIKTOK',
                cookies: tiktok_cookies,
              },
              {
                im: {
                  username: tiktok_user,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'INSTAGRAM') {
        if (sources.IMS) {
          const { instagram_user, instagram_cookies } = forms.INSTAGRAM_LOGIN;
          const res = await dispatch(
            addInstagramAccount(
              alias,
              {
                type: 'INSTAGRAM',
                cookies: instagram_cookies,
              },
              {
                im: {
                  username: instagram_user,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'TWITTER') {
        if (sources.IMS) {
          const { twitter_user, twitter_cookies } = forms.TWITTER_LOGIN;
          const res = await dispatch(
            addTwitterAccount(
              alias,
              {
                type: 'TWITTER',
                cookies: twitter_cookies,
              },
              {
                im: {
                  username: twitter_user,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      if (accountType === 'WHATSAPP') {
        if (sources.IMS) {
          const { whatsapp_phone_number, session } = forms.WHATSAPP_LOGIN;
          const res = await dispatch(
            addWhatsAppAccount(
              alias,
              {
                type: 'WHATSAPP',
                session,
              },
              {
                im: {
                  phone_number: whatsapp_phone_number,
                },
              }
            )
          );
          account_id = res.account_id;
          if (res.error) return setError(res.error);
        }
      }
      // Add tags to account
      if (account_id && forms.TAGS_CONFIG?.tags?.length) {
        const { error } = await dispatch(updateElementTags('ACCOUNT', account_id, forms.TAGS_CONFIG.tags));
        // Do not notify errors
      }
      if (account_id && sources.CALENDAR) {
        // setAccountId(account_id);
        // const error = await dispatch(initialGoogleCalendarFetch(account_id));
        // if (error) {
        //   return setError(error);
        // }
        // dispatch(getCalendars());
        dispatch(setSourceStatus({ account_id, type: 'CALENDAR', status: 'IDLE' }));
      }
      if (account_id && sources.MAILS) {
        if (!isNaN(forms.MAIL_HISTORY_CONFIG)) {
          setLoading('MAILS');
          const error = await dispatch(initialMailFetchNew(account_id, forms.MAIL_HISTORY_CONFIG));
          if (error) return setError(error);
        } else {
          dispatch(setSourceStatus({ account_id, type: 'MAILS', status: 'IDLE' }));
        }
      }
      if (account_id && sources.IMS) {
        setLoading('CONTACTS');
        let error = await dispatch(initialContactFetch(account_id));
        if (error) return setError(error);
        setLoading('THREADS');
        error = await dispatch(initialImThreadsFetch(account_id));
        if (error) return setError(error);
        dispatch(setSourceStatus({ account_id, type: 'IMS', status: 'IDLE' }));
      }
      if (account_id && sources.CALLS) {
        if (!isNaN(forms.CALL_HISTORY_CONFIG)) {
          const error = await dispatch(initialCallFetchNew(account_id, forms.CALL_HISTORY_CONFIG));
          if (error) return setError(error);
        } else {
          dispatch(setSourceStatus({ account_id, type: 'CALLS', status: 'IDLE' }));
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column" height={400}>
        <AccountTypeIcon size={60} account_type={accountType} />
        <Typography variant="h2" style={{ marginTop: 16 }}>
          {error
            ? t('wizard.error')
            : loading
            ? t('wizard.preparingAccount')
            : t('wizard.addedAccount', { type: t('accounts.' + accountType.toLowerCase()) })}
        </Typography>
        {(accountType === 'MESSENGER' ||
          accountType === 'LINKEDIN' ||
          accountType === 'WHATSAPP' ||
          accountType === 'INSTAGRAM' ||
          accountType === 'TIKTOK' ||
          accountType === 'TWITTER') &&
          loading && (
            <Typography variant="subtitle2" align="center">
              {t('wizard.longWait')}
            </Typography>
          )}
        <Typography variant="subtitle2">
          {forms.IMAP_MAIL_1?.imap_user ||
            forms.GOOGLE_MAIL?.email ||
            forms.ICLOUD_MAIL?.email ||
            forms.MICROSOFT_LOGIN?.account.username ||
            forms.GOOGLE_CALENDAR_LOGIN?.identifier ||
            forms.LINKEDIN_LOGIN?.linkedin_user ||
            forms.MESSENGER_LOGIN?.messenger_user ||
            forms.TWITTER_LOGIN?.twitter_user ||
            forms.INSTAGRAM_LOGIN?.instagram_user ||
            forms.TIKTOK_LOGIN?.tiktok_user ||
            forms.WHATSAPP_LOGIN?.whatsapp_phone_number ||
            '...'}
        </Typography>
        <Box display="flex" mt={3}>
          {loading ? (
            <>
              {error ? (
                <Alert variant="filled" color="error" icon={<Error />}>
                  {error}
                </Alert>
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CircularProgress />
                  <Typography variant="subtitle1" align="center">
                    {t('wizard.preparing_' + loading)}...
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            Object.keys(sources).map((source: AccountSourceType) =>
              sources[source] ? (
                <div className={c.container}>
                  <SourceIcon source={source} />
                  <div className={c.icon}>
                    <IonIcon icon={checkmark}></IonIcon>
                  </div>
                </div>
              ) : null
            )
          )}
        </Box>
      </Box>
      {(!loading || error) && (
        <Box display="flex" justifyContent="flex-end">
          {!sources.IMS ||
            (sources.CALENDAR && (
              <Button variant="text" style={{ textTransform: 'capitalize', marginRight: 20 }} onClick={goNext}>
                {t('wizard.startAgain')}
              </Button>
            ))}
          {error && (
            <Button
              variant="text"
              style={{ textTransform: 'capitalize', marginRight: 20 }}
              onClick={() => {
                if (accountType === 'WHATSAPP') {
                  goToStep('WHATSAPP_LOGIN');
                } else goBack();
              }}
            >
              {t('wizard.retry')}
            </Button>
          )}
          <Button color="secondary" style={{ width: 120, textTransform: 'capitalize' }} onClick={sources.IMS ? goNext : onEnd}>
            {t('wizard.end')}
          </Button>
        </Box>
      )}
    </div>
  );
}
