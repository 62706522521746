import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TipTapToolbarButton from './TipTapToolbarButton';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatSize,
  FormatUnderlined,
  Redo,
  Undo,
} from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import TipTapToolbarColors from './TipTapToolbarColors';
import { Editor } from '@tiptap/react';
import TipTapToolbarLink from './TipTapToolbarLink';
import TipTapToolbarImage from './TipTapToolbarImage';
import TipTapToolbarFontSize from './TipTapToolbarFontSize';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
      borderTop: '0',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      flexWrap: 'wrap',
      padding: theme.spacing(0.5),
      background: 'inherit',
    },
    divider: {
      margin: theme.spacing(0.5),
    },
  })
);

interface TipTapToolbarProps {
  editor: Editor;
  isEditorMail?: boolean;
  lowerToolbar?: React.ReactNode;
  minified?: boolean;
}

const TipTapToolbar: FC<TipTapToolbarProps> = ({ editor, isEditorMail, lowerToolbar, minified = false }) => {
  const classes = useStyles();

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.container} style={isEditorMail ? { border: 0, borderTop: '1px solid #D5D5D5' } : null}>
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
        icon={<FormatBold />}
      />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
        icon={<FormatItalic />}
      />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? 'is-active' : ''}
        icon={<FormatUnderlined />}
      />
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TipTapToolbarFontSize editor={editor} />
      <TipTapToolbarColors editor={editor} />
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TipTapToolbarLink editor={editor} />
      <TipTapToolbarImage editor={editor} isEditorMail />
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        icon={<FormatAlignLeft />}
      />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
        icon={<FormatAlignCenter />}
      />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
        icon={<FormatAlignRight />}
      />
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
        icon={<FormatListBulleted />}
      />
      <TipTapToolbarButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
        icon={<FormatListNumbered />}
      />
      {!minified && <Divider flexItem orientation="vertical" className={classes.divider} />}
      {!minified && <TipTapToolbarButton onClick={() => editor.chain().focus().unsetAllMarks().run()} icon={<FormatClear />} />}
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TipTapToolbarButton onClick={() => editor.chain().focus().undo().run()} icon={<Undo />} disabled={!editor.can().undo()} />
      <TipTapToolbarButton onClick={() => editor.chain().focus().redo().run()} icon={<Redo />} disabled={!editor.can().redo()} />
      {!!lowerToolbar && (
        <>
          <Divider flexItem orientation="vertical" className={classes.divider} />
          {lowerToolbar}
        </>
      )}
    </div>
  );
};

export default TipTapToolbar;
