import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { workEventsSelector } from '@focus-front/core';

import ZenMessage from '../Common/ZenMessage';

/**
 * This component manage the redirection to the first upcomming event
 */
export default function WorkContainer() {
  const next_events = useSelector(workEventsSelector);
  const { t } = useTranslation();

  if (next_events.length) return <Redirect to={`/work/${next_events[0].id}`} />;
  return (
    <ZenMessage
      title={t('work.empty.title')}
      subtitle={t('work.empty.subtitle')}
      link={t('work.empty.link')}
      link_href="unipile.com"
    />
  );
}
