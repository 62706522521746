import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Call } from '../../domain/Call';

/**
 * Repository.
 */
export class CallRepo extends AggregateRepo<Call> {
  constructor(eventStore: DomainEventStore) {
    super(Call, eventStore);
  }
}
