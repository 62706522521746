import { SingleOrArray } from '../utils';
import { DecisionProjection, InitialState, ProjectionHandlers } from './DecisionProjection';
import { CreationalDomainEvent, DomainEvent, DomainEventShape } from './DomainEvent';
import { Uuid } from './Uuid';

/**
 * @note Aggregates no longer publish the domain event they may generate.
 *       For convenience, they are still stateful and maintain an internal decision
 *       projection.
 *
 * @todo Consider :
 *
 *       With 'purer' commands that return events instead of publishing them, what is preventing
 *       the recipient from persisting returned events partially and/or out of order ?
 *
 *      This would probably require another layer between an AggregateRoot and
 *      the place that want to send commands :
 *        - https://github.com/jankronquist/rock-paper-scissors-in-java/blob/8565f5ae367e3cdfcf24c2a5edad9082d6b8083b/src/main/java/com/jayway/es/impl/ApplicationService.java
 *        - https://blog.jayway.com/2013/06/20/dont-publish-domain-events-return-them/
 *
 *      Can the AggregateRepo be extended to deal with all the side-effects, and
 *      flush/persist, events guaranteeing order and atomicity ?
 *
 *      Consider moving publisher knowledge in the repo, see :
 *        - https://github.com/gautema/CQRSlite/blob/cae26a5c0e712e64d010e94adef2f30c3d4296c2/Framework/CQRSlite/Domain/Repository.cs
 *
 *      No interaction would happen directly with an AggregateRoot, everything
 *      would go through the corresponding AggregateRepo :
 *        - Exposing the static .create command should be easy.
 *        - How to expose other commands ? A .issue method that takes an aggregate
 *          id, a command name and the command parameters ?
 *          See https://stackoverflow.com/questions/54091828/create-a-union-type-in-typescript-with-methods-of-a-class
 *          to extract a union of available commands ( and exclude .create ), then look
 *          at https://github.com/pozorfluo/mvp-machine/blob/kf-react/src/machine.ts
 *          for typing command parameters.
 *
 * @todo Consider a separate repository for snapshots, see spatie.
 */
export abstract class AggregateRoot<TProjection = InitialState, TDomainEvent extends DomainEventShape = DomainEvent> {
  //   constructor(public readonly projection: DecisionProjection<TProjection, TDomainEvent>) {}
  public readonly projection: DecisionProjection<TProjection, TDomainEvent>;
  private _changes: TDomainEvent[] = [];
  /**
   *
   */
  constructor(
    projectionConfig: [TProjection, ProjectionHandlers<TProjection, TDomainEvent>],
    events: SingleOrArray<TDomainEvent>
  ) {
    this.projection = new DecisionProjection(projectionConfig);
    this.projection.process(events);
  }
  /**
   *
   */
  get id(): Uuid {
    if (this.projection.state.id === null) {
      throw new MissingAggregateIdError(this.constructor.name);
    }
    return this.projection.state.id;
  }

  /**
   *
   */
  get version(): number {
    return this.projection.state.version;
  }

  /**
   *
   */
  protected apply(event: TDomainEvent): this {
    this.projection.process(event);
    this._changes.push(event);
    return this;
  }

  /**
   * Return a accepted CommandResult and an up-to-date aggregate instance.
   *
   * @note CreationalDomainEvent means events with a hard-coded { version: 0 }.
   *
   * @todo Consider changes: SingleOrArray<TDomainEvent>, checking that
   *       changes.length >= 1 and using it for version in CommandAccepted.
   */
  static accept<
    TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent>,
    TDomainEvent extends DomainEventShape = DomainEvent
  >(
    this: new (events: SingleOrArray<TDomainEvent>) => TAggregate,
    changes: AggregateRootEventType<TAggregate> & CreationalDomainEvent
  ): CommandAccepted<TDomainEvent, TAggregate> {
    return new CommandAccepted(1, changes as TDomainEvent, new this(changes));
  }

  /**
   *
   */
  static reject<
    TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent>,
    TDomainEvent extends DomainEventShape = DomainEvent,
    TReason extends string = string
  >(
    this: new (events: SingleOrArray<TDomainEvent>) => TAggregate,
    reason: TReason,
    changes?: AggregateRootEventType<TAggregate>
  ): CommandRejected<AggregateRootEventType<TAggregate>, TReason> {
    return new CommandRejected(reason, changes);
  }

  /**
   * @todo Consider returning Promise<CommandAccepted> and awaiting publisher.emit(event).
   *       Right now a command can be accepted and the emitted event could still fail to
   *       be written to the EventStore. It can be a problem if a process in a UseCase,
   *       reading a CommandAccepted result, launch another command based on that result.
   *
   *       It's probably better to use some form of 'durable process', i.e. : Saga/ProcessManager
   *       to replace the cross-aggregate UseCases.
   */
  protected accept(): CommandAccepted<TDomainEvent, undefined> {
    const result = new CommandAccepted(this.version, this._changes, undefined);
    this._changes = [];
    return result;
  }

  /**
   *
   */
  protected reject<TReason extends string = string>(reason: TReason): CommandRejected<TDomainEvent, TReason> {
    const result = new CommandRejected(reason, this._changes);
    this._changes = [];
    return result;
  }
}

/**
 * Ad-hoc utility type to extract the first generic parameter from a type implementing AggregateRoot<unknown, any>.
 */
export type AggregateRootProjectionType<T> = T extends AggregateRoot<infer G, any> ? G : never;
/**
 * Ad-hoc utility type to extract the second generic parameter from a type implementing AggregateRoot<unknown, any>.
 */
export type AggregateRootEventType<T> = T extends AggregateRoot<any, infer G> ? G : never;

/**
 * Provide a list of changes as DomainEvent and information about the acceptance of a command, i.e. :
 *
 *   - a command is accepted, an event is emitted and an updated version number
 *     for the aggregate is provided.
 *
 *   - a command is rejected, no event is emitted, the reason used for the rejection
 *     is provided. There might be more than one reason for the command to be
 *     rejected, but the command terminates as soon as a rejection condition is
 *     met and will not examine the command further.
 *
 * This is different from the outcome of the command and should NOT be tried/used
 * as a way to return info from the 'read-side'. The command shouldn't know
 * about what happens after emitting an event, when and if anything happens at all.
 *
 * CommandResult.reason may be used with a literal string union to make
 * a NOK result more actionable.
 *
 * e.g. :
 *
 *  function doExampleCommand(publisher: Publisher, values: Partial<AggDraft>): CommandResult<'ALREADY_DONE' | 'IS_ARCHIVED'> {
 *   if (this.projection.state.archived) {
 *     return { ok: false, reason: 'IS_ARCHIVED' };
 *   }
 *
 *   if (this.projection.state.done) {
 *     return { ok: false, reason: 'ALREADY_DONE' };
 *   }
 *
 *   return this.emit(publisher, new MyAggregateSomethingDone(this.id, this.version, values));
 * }
 *
 * function doExampleCommand2(publisher: Publisher): CommandResult<'DELETED' | 'PLANNED'> {
 *   if (this.projection.state.current === 'LIVE') {
 *     return this.emit(publisher, new MyAggregateSomethingDone(this.id, this.version));
 *   }
 *
 *   // Using a method helper :
 *   return this.reject(this.projection.state.current); // Where current : 'LIVE' | 'DELETED' | 'PLANNED';
 *
 *   // Using class constructor :
 *   // return new CommandRejected(this.projection.state.current);
 * }
 */
// export type CommandResult<
//   TDomainEvent extends DomainEventShape,
//   TReason extends string = string,
//   TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent> | undefined = undefined
// > = CommandAccepted<TDomainEvent, TAggregate> | CommandRejected<TDomainEvent, TReason>;
export type CommandResult<
  TReason extends string | undefined = undefined,
  TDomainEvent extends DomainEventShape = DomainEvent,
  TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent> | undefined = undefined
> = TReason extends string
  ? CommandAccepted<TDomainEvent, TAggregate> | CommandRejected<TDomainEvent, TReason>
  : CommandAccepted<TDomainEvent, TAggregate>;

// type Rdef = CommandResult;
// type R = CommandResult<undefined, DomainEvent>;
// type R2 = CommandResult<'no' | 'nope', DomainEvent>;

export class CommandAccepted<
  TDomainEvent extends DomainEventShape,
  TAggregate extends AggregateRoot<AggregateRootProjectionType<TAggregate>, TDomainEvent> | undefined = undefined
> {
  readonly ok = true;
  readonly changes: TDomainEvent[];
  constructor(readonly version: number, changes: SingleOrArray<TDomainEvent> = [], readonly aggregate: TAggregate) {
    this.changes = Array.isArray(changes) ? changes : [changes];
  }
}
export class CommandRejected<TDomainEvent extends DomainEventShape, TReason extends string = string> {
  readonly ok = false;
  readonly changes: TDomainEvent[];
  constructor(readonly reason: TReason, changes: SingleOrArray<TDomainEvent> = []) {
    this.changes = Array.isArray(changes) ? changes : [changes];
  }
}

/**
 *
 */
export class MissingAggregateIdError extends Error {
  constructor(readonly actor?: string) {
    super(`Missing aggregate id : ${actor ?? 'AggregateRoot'} tried to use its id before it was attributed !`);
    this.name = 'MissingAggregateIdError';
  }
}

/**
 * Projection.
 *
 * @note Typescript classes yields type definitions. This pattern cuts a lot of boilerplate.
 *
 *       class MyType {
 *          // properties definition -> type definition
 *          myEssential : string;
 *          myContent : ContentType;
 *          myOptionalField? : string;
 *
 *          constructor(values : NewMyType) {
 *              Object.assign(this, {
 *                  ...values, // copy
 *                  myContent : values.myContent || 'some default values',
 *              })
 *          }
 *       }
 *
 *      // Derive parameter necessary for instantiation from class definition.
 *      type NewMyType = Select<MyType, 'myEssential'>
 *
 * @note Unfortunately this makes it look non-serializable for Redux !
 *
 * @note Use import { ViewCalendarEvent as CalendarEvent } from ... in consumer
 *       if needed. It doesn't make sense to suffix the aggregate root like :
 *       CalendarEventAggregate and then use CalendarEvent for a projection.
 *       Because that projection could be one of many derived from the aggregate.
 */
