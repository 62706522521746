import { toUTCDateTimeBasic, UTCDateTimeMs, Uuid, UnixTimeMs, Tzid } from '../../../Common';
import { ElementType } from '../../../Common/utils';
import { ViewNotification } from '../../../Notification';
import {
  GoogleCalendarEventAllDayInstanceId,
  GoogleCalendarEventAllDaySplitRecurringId,
  GoogleCalendarEventInstanceId,
  toUnipileCalendarEventInstanceId,
  UnipileCalendarEventInstanceId,
  GoogleCalendarEventHolidayId,
  GoogleCalendarEventSportId,
  GoogleCalendarEventMoonId,
  GoogleCalendarEventOtherId,
} from '../CalendarEventInstance';
import { RRuleString } from '../../../Common/domain/RRule';
import { HttpUrl } from '../../../Common/utils/Url';

import { GoogleCalendarEventId } from '../../infra/services/GoogleCalendarEventId';
import { GcalEventAttendeeResponseStatus, GcalEventReminderMethod, GcalEventStatus } from '../../infra/services/GcalApiTypes';
import { CalendarEventDTO } from '../types/CalendarEventDTO';
import { AccountType } from '../../../Account';

export interface CalendarEventAllDayDate {
  start: UTCDateTimeMs;
  end: UTCDateTimeMs;
  start_tzid: null;
  end_tzid: null;
  all_day: true;
}

export interface CalendarEventNonAllDayDate {
  start: UTCDateTimeMs;
  end: UTCDateTimeMs;
  start_tzid: Tzid;
  end_tzid: Tzid;
  all_day: false;
}

/**
 * Discriminant property : all_day.
 */
export type CalendarEventDate = CalendarEventAllDayDate | CalendarEventNonAllDayDate;

/**
 * Projection.
 */
export interface BaseCalendarEvent {
  calendar_id: Uuid;
  summary: string;
  /**
   * @todo Figure out if DELETED makes sense here or if it's better to have a
   *      separate union for exceptions.
   */
  status: 'DONE' | 'MISSED' | 'PLANNED' | 'DELETED';
  description?: string;
  location?: string;
  metadata?: {
    /**
     * @note If I understand correctly, the full notification is made available here,
     *       because it's not available as a projection in viewNotifications store
     *       while it is planned as a calendar event.
     *
     * @todo Consider flattening metadata since we do not need to stuff everything
     *       into a free for all bag on API Core anymore.
     */
    origin_notification?: ViewNotification;
    attached_entity?:
      | {
          type: 'MAIL' | 'EVENT' | 'IM' | 'CALL' | 'IM_THREAD';
          id: Uuid;
          account_type: AccountType;
          account_id: Uuid;
        }
      | {
          type: 'MAIL_DRAFT';
          id: Uuid;
          account_type?: AccountType;
          account_id?: Uuid;
        };
  };
  real_start?: UTCDateTimeMs;
  real_end?: UTCDateTimeMs;
}

/**
 * @note To distinguish optional properties and properties that have no value
 *       until acknowledged from an external sync, the latter are made nullable.
 *       Optional means optional ! A property can be both optional and only
 *       have a value once acknowledged.
 *
 * @todo Consider if an AcknowledgedGoogleCalendarEvent type and a union makes
 *       more sense.
 *
 * @todo Decide between optional vs empty array for attendees, attachments.
 */
export interface GoogleCalendarEvent extends BaseCalendarEvent {
  type: 'GOOGLE';
  account_id: Uuid;
  external_id:
    | GoogleCalendarEventId
    | GoogleCalendarEventInstanceId
    | GoogleCalendarEventAllDayInstanceId
    | GoogleCalendarEventAllDaySplitRecurringId
    | GoogleCalendarEventHolidayId
    | GoogleCalendarEventSportId
    | GoogleCalendarEventMoonId
    | GoogleCalendarEventOtherId /** Other shapes may exists. */;
  // | null;
  external_recurring_event_id?: GoogleCalendarEventId | GoogleCalendarEventAllDaySplitRecurringId;
  etag: string | null;
  /** @see https://developers.google.com/calendar/api/v3/reference/events#status */
  google_status: GcalEventStatus; //'confirmed' | 'tentative' | 'cancelled';
  ical_uid: string | null;
  creator: string | null;
  organizer: string | null;
  /** @todo Expand html_link with btoa(`{e.external_id} {some id derived from calendar id, you better extract it from og rec}`) */
  html_link: HttpUrl | null;
  hangout_link?: HttpUrl; // | null
  reminders: {
    use_default: boolean;
    /** overrides.length must be <= 5. */
    overrides?: {
      method: GcalEventReminderMethod; //'email' | 'popup';
      /** Valid values are between 0 and 40320 (4 weeks in minutes). */
      minutes: number;
    }[];
  };
  attendees: {
    id?: string;
    email: string;
    display_name?: string;
    organizer?: boolean;
    self?: boolean;
    resource?: boolean;
    optional?: boolean;
    response_status: GcalEventAttendeeResponseStatus; //'needsAction' | 'declined' | 'tentative' | 'accepted';
    comment?: string;
    additional_guests?: number;
  }[];
  attachments: /** There can be at most 25 attachments per event.  */
  {
    /** Only Google Drive attachments are supported. */
    file_url: HttpUrl;
    title: string;
    mime_type: string;
    icon_link: HttpUrl;
    file_id: string;
  }[];
  locked?: boolean;
}

export interface UnipileEventAttendee {
  display_name?: string;
  email: string;
}

/**
 *
 */
export interface UnipileCalendarEvent extends BaseCalendarEvent {
  type: 'UNIPILE';
  attendees: UnipileEventAttendee[];
  /** @todo Ask if we want to track these for all calendar types ? */
  //   real_start?: UTCDateTimeMs;
  //   real_end?: UTCDateTimeMs;
}

/**
 * @note See https://github.com/microsoft/TypeScript/issues/1260
 *       for issues about narrowing down based on presence of a property.
 *
 * @note See https://github.com/microsoft/TypeScript/pull/11198
 *           https://github.com/microsoft/TypeScript/issues/10586
 *       for issues about multiple discriminants.
 */
export interface SingleKind {
  kind: 'SINGLE';
  id: Uuid /** Aggregate id. */;
  //   recurring_event_id?: never;
}

export interface RecurringKind {
  kind: 'RECURRING';
  id: Uuid /** Aggregate id. */;
  recurrence: RRuleString[];
}

export interface InstanceKind {
  kind: 'INSTANCE';
  id: UnipileCalendarEventInstanceId /** Internal instance id. */;
  recurring_event_id: Uuid /** Id of the original event for instances of a recurring event. */;
  original_start: UnixTimeMs;
  /**
   * @todo Figure out wether we need this or not.
   *       original_tzid is stamped on instances and follows root
   *       start_tzid. This doesn't match Google Calendar behaviour and
   *       may prove to be misleading later but shouldn't cause problem
   *       for now as it not yet used for anything.
   *       Google Calendar behaviour for original_tzid doesn't seem to make much
   *       sense or be used anything either.
   */
  original_tzid: Tzid | null;
  // recurrence?: never;
}

type ViewCalendarEventBase = UnipileCalendarEvent | GoogleCalendarEvent;

type SingleEvent = ViewCalendarEventBase & SingleKind & CalendarEventDate;

type RecurringEvent = ViewCalendarEventBase & RecurringKind & CalendarEventDate;

type InstanceEvent = ViewCalendarEventBase & InstanceKind & CalendarEventDate;

/**
 * Discriminant property : kind.
 */
export type ViewCalendarEventKind = SingleKind | InstanceKind;

/**
 * Discriminant properties : type, kind, all_day.
 */
export type ViewCalendarEvent = SingleEvent | InstanceEvent;

/**
 * @note Warning : TS will let you return the wrong type inside the switch,
 *       see https://gist.github.com/pozorfluo/a042f3c5cedc3562982fc554b5e73e44
 *       I asked how to work around this on TS's discord on 20/07/2021 and didn't
 *       get an answer.
 *
 * @todo Figure out how to deal with all the read-only/omitted properties on
 *       NewCalendarEventDTO.
 *
 *       Another dedicated function ? Another dedicated DTO ?
 *
 *       Doesn't it actually means these properties are 'optional' on a
 *       GoogleCalendarEvent and are not actually going to be there until we
 *       acknowledge them from an external sync !
 *
 *       For now, in order to distinguish them from truly optional properties,
 *       we can try making them nullable.
 *
 * @todo Figure out if the recurrence values should be available on
 *       an instances ?
 *       Probably if we want to be able to see and edit them without
 *       pulling the recurring root !
 *       Project them parsed a RRule object ?
 *       For now, provide a method in the use case to retrieve the
 *       recurring root if we need to display or edit the recurrence
 *       rule.
 */
export function createViewCalendarEvent<T extends CalendarEventDTO>(
  aggregateId: Uuid,
  values: T,
  instanceId?: UnipileCalendarEventInstanceId
): ViewCalendarEvent & { type: T['type'] } {
  const id: ViewCalendarEventKind = {
    ...(values.kind === 'SINGLE'
      ? { id: aggregateId, kind: 'SINGLE' }
      : {
          kind: 'INSTANCE',
          recurring_event_id: aggregateId,
          original_start: values.original_start,
          original_tzid: values.original_tzid,
          id: instanceId ?? toUnipileCalendarEventInstanceId(aggregateId, toUTCDateTimeBasic(values.start)),
        }),
  };

  switch (values.type) {
    case 'GOOGLE':
      return {
        ...values,
        ...id,
        status: values.status ?? 'PLANNED',
        google_status: values.google_status ?? 'confirmed',
        reminders: values.reminders ?? { use_default: true },
        attendees: values.attendees ?? [],
        attachments: values.attachments ?? [],
        external_id: values.external_id, // ?? null,
        /** @todo Figure out where is the best place to do the external_recurring_event_id calc. */
        // ...(values.kind === 'SINGLE' ? { external_id: values.external_id ?? null } : { external_recurring_event_id: values.external_id ?? null }),
        /** Non-optional acknowledge-only properties. */
        etag: values.etag ?? null,
        ical_uid: values.ical_uid ?? null,
        creator: values.creator ?? null,
        organizer: values.organizer ?? null,
        html_link: values.html_link ?? null,
        // hangout_link: values.hangout_link ?? null,
        ...(values.hangout_link && { hangout_link: values.hangout_link }),
        ...(values.locked && { locked: values.locked }),
      };
    case 'UNIPILE':
      return {
        ...values,
        ...id,
        attendees: values.attendees ?? [],
        status: values.status ?? 'PLANNED',
      };
  }
}

//------------------------------------------------------------------------------
/**
 * Aliases.
 */
export type CalendarEventType = ViewCalendarEvent['type'];

export type ExternalCalendarEvent = ViewCalendarEvent & { type: 'GOOGLE' };
export type ExternalCalendarEventType = ExternalCalendarEvent['type'];

export interface GoogleCalendarAttendee extends ElementType<NonNullable<GoogleCalendarEvent['attendees']>> {}
export interface GoogleCalendarAttachment extends ElementType<NonNullable<GoogleCalendarEvent['attachments']>> {}

export type GoogleCalendarReminders = GoogleCalendarEvent['reminders'];
export interface GoogleCalendarReminder extends ElementType<NonNullable<GoogleCalendarEvent['reminders']['overrides']>> {}

export type GoogleCalendarReminderMethod = GoogleCalendarReminder['method'];
export type GoogleCalendarEventStatus = GoogleCalendarEvent['google_status'];
export type CalendarEventStatus = ViewCalendarEvent['status'];

export type ExternalCalendarEventInstanceId = GoogleCalendarEventInstanceId | GoogleCalendarEventAllDayInstanceId;

// /** No ! Internal instance id must always be in UnipileCalendarEventInstanceId format. */
// export type CalendarEventInstanceId =
//   | UnipileCalendarEventInstanceId
//   | ExternalCalendarEventInstanceId;
export type CalendarEventInstanceId = UnipileCalendarEventInstanceId;

export type SingleCalendarEvent = SingleEvent;
export type RecurringCalendarEvent = RecurringEvent;
export type InstanceCalendarEvent = InstanceEvent;

export type CalendarEventRecurringKind = RecurringKind;
export type CalendarEventSingleKind = SingleKind;
export type CalendarEventInstanceKind = InstanceKind;
export type CalendarEventKind = SingleKind | RecurringKind | InstanceKind;

export type ExternalCalendarEventEtag = NonNullable<GoogleCalendarEvent['etag']>;
