import { initializeIdb, closeIdb, clearIdbDomain, Idb, userDB, deleteIdb } from '../idb';
import { DbService } from '../DbService';

/**
 * @note Right now IDBService manages an IndexedDB connection 'singleton' ref that
 *       is defined and exported somewhere else ( ../idb ). Other classes access
 *       that ref directly with no regards whether the connection is ready or not
 *       or if it is defined at all.
 *
 *       We ought to make that access safer.
 *
 *       The addition of a close method does NOT address that problem. It merely
 *       allows to switch between accounts.
 *
 * @todo Inject Idb in all IdbXXXRepos and other Idb using classes.
 */
export class IDBService implements DbService {
  /**
   *
   */
  async open(user_id: number): Promise<void> {
    if (Idb && userDB && user_id !== userDB) {
      closeIdb(userDB);
    }

    await initializeIdb(user_id);
  }

  /**
   *
   */
  async close(user_id: number): Promise<void> {
    closeIdb(user_id);
  }

  /**
   *
   */
  async clearDomain() {
    return clearIdbDomain();
  }
  /**
   *
   */
  async delete(user_id: number) {
    return deleteIdb(user_id);
  }

  /**
   * @todo Inject Idb in all IdbXXXRepos.
   */
  debugGetIdb() {
    return Idb;
  }

  /**
   *
   */
  debugGetUserDB() {
    return userDB;
  }
}

// let db;

// // Let us open our database
// const DBOpenRequest2 = window.indexedDB.open("toDoList", 5);

// // these event handlers act on the database being opened.
// DBOpenRequest2.onerror = function(event) {
//     console.log('Error loading database.');
// };

// DBOpenRequest2.onsuccess = function(event) {
//     console.log('Database initialised.');

//   // store the result of opening the database in the db
//   // variable. This is used a lot below
//   db = DBOpenRequest2.result;

//   // Run the displayData() function to populate the task
//   // listwith all the to-do list data already in the IDB
//   db.close();
// };

// // This event handles the event whereby a new version of
// // the database needs to be created Either one has not
// // been created before, or a new version number has been
// // submitted via the window.indexedDB.open line above
// // it is only implemented in recent browsers
// DBOpenRequest2.onupgradeneeded = function(event) {
//   const db = this.result;

//   db.onerror = function(event) {
//     console.log('Error loading database');
//   };

//   // Create an objectStore for this database
//   const objectStore = db.createObjectStore("toDoList2", { keyPath: "taskTitle" });

//   // define what data items the objectStore will contain

//   objectStore.createIndex("hours", "hours", { unique: false });
//   objectStore.createIndex("minutes", "minutes", { unique: false });
//   objectStore.createIndex("day", "day", { unique: false });
//   objectStore.createIndex("month", "month", { unique: false });
//   objectStore.createIndex("year", "year", { unique: false });
//   objectStore.createIndex("Saucified", "notified", { unique: false });
// };
