import { Uuid, InvalidAccountError, UTCDateTimeMs, Publisher } from '../../../Common';
import { SearchService } from '../../../Search';
import { MailServicesMap } from '../../../_container/interfaces/Container';
import { IdbMailMetaRepo } from '../repository/IdbMailMetaRepo';
import { IdbMailReferenceRepo } from '../repository/IdbMailReferenceRepo';
import {
  IdbViewAccountRepo,
  isMailSupportedAccount,
  isMailSupportedAccountTaggable,
  ViewAccount,
  MailSupportedAccounts,
  AccountRepo,
} from '../../../Account';
import {
  NewMailDTO,
  Mail,
  MailMeta,
  MailCache,
  MailContent,
  ViewMail,
  PartialViewMail,
  NewMailReferenceDTO,
  MailReference,
} from '../../domain';
import dayjs from 'dayjs';
import { cidReplacement } from './cidReplacement';
import { MailCacheService } from './MailCacheService';
import { Tag, TagColor, TagRelation, Taggable, ViewTagRelationRepo } from '../../../Tag';
import { Label } from '@material-ui/icons';
import { randomEnum } from '../../../Common/utils';
import { MailBox } from '../..';

type MailSplit = { ref: MailReference; meta: MailMeta; content: MailContent };

export class MailSyncService {
  private FULL_FETCH_STEP_SIZE = 50;
  private cancel_ff_ids: Set<Uuid> = new Set();

  constructor(
    private readonly mailServices: MailServicesMap,
    private readonly mailMetaRepo: IdbMailMetaRepo,
    private readonly mailCache: MailCacheService,
    private readonly mailRefRepo: IdbMailReferenceRepo,
    private readonly viewAccount: IdbViewAccountRepo,
    private readonly viewTagRelation: ViewTagRelationRepo,
    private readonly account: AccountRepo,
    private readonly publisher: Publisher,
    private readonly search: SearchService
  ) {}

  private _consolidate(ref: MailReference, meta: MailMeta, cache: MailCache): ViewMail {
    return {
      ...meta,
      ...cache,
      ...ref,
    };
  }

  private _split({ body, attachments, id, provider_id, ...meta }: ViewMail): MailSplit {
    return {
      meta: {
        id,
        ...meta,
      },
      content: {
        account_id: meta.account_id,
        body,
        attachments,
      },
      ref: {
        account_id: meta.account_id,
        date: meta.date,
        id,
        provider_id,
      },
    };
  }

  private _splitPartial({ id, provider_id, ...meta }: PartialViewMail): Omit<MailSplit, 'content'> {
    return {
      meta: {
        id,
        ...meta,
      },
      ref: {
        account_id: meta.account_id,
        date: meta.date,
        id,
        provider_id,
      },
    };
  }

  /**
   * Syncronise references and meta
   * Remove metas of deleted references
   * Fetch metas of new references
   */
  async syncMeta(account: ViewAccount & { type: MailSupportedAccounts }): Promise<void> {
    const metas = await this.mailMetaRepo.getByAccount(account.id);
    const refs = await this.mailRefRepo.getByAccount(account.id);

    const ref_ids = refs.map((r) => r.id);
    const meta_ids = metas.map((m) => m.id);

    const to_remove: string[] = meta_ids.filter((meta_id) => !ref_ids.includes(meta_id));

    // ---- Remove metas
    await this.mailMetaRepo.removeMany(to_remove);
    /**
     * @todo remove cache ?
     */

    const to_fetch = refs.filter((ref) => !meta_ids.includes(ref.id));

    await this.mailServices[account.type].fetchMailsMeta(to_fetch, (results) => {
      return this.mailMetaRepo.addMany(results.map((r) => this._split(r).meta));
    });
  }

  async fetchNew(account: ViewAccount & Taggable, from_date?: UTCDateTimeMs, limit?: number): Promise<PartialViewMail[]> {
    if (!isMailSupportedAccount(account)) throw new InvalidAccountError();

    const last_mail = await this.mailRefRepo.getLatestByAccount(account.id);

    /**
     * after_date search query defined in the following order
     * - from_date param if any
     * - last mail receive date if any mail réceived after account creation
     * - account creation date
     */
    const last_used_date = from_date
      ? from_date
      : last_mail && dayjs(last_mail.date).isAfter(dayjs(account.created_at))
      ? last_mail.date
      : account.created_at;

    let mails: NewMailDTO[] = await this.mailServices[account.type].searchMails(account.id, {
      after_date: last_used_date,
      mailbox_name: 'INBOX',
    });

    if (limit) {
      mails = mails.slice(0, limit);
    }

    const new_mails = await this._saveMails(
      mails,
      'INBOX',
      account.tags.map((tag) => tag.id)
    );

    // Return metas for notification creation
    return new_mails.map(({ meta, ref }) => ({ ...meta, ...ref }));
  }

  /**
   * Get a mail to be displayed
   * Data is returned asap in callbacks because the state of the meta / cache may vary
   * If one or both of them is missing, the data is fetch remotely
   */
  async getViewMail(
    id: Uuid,
    {
      onContent,
      onMeta,
      onError,
    }: {
      onMeta: (meta: PartialViewMail & Taggable) => void;
      onContent: (content: MailContent | null) => void;
      onError: (error: Error) => void;
    }
  ): Promise<void> {
    try {
      // Get the mail's meta and cache
      const mailRef = await this.mailRefRepo.get(id);
      if (!mailRef) throw new Error('This mail does not exist');

      const partial_mail = await this.mailMetaRepo.get(id);
      if (partial_mail) {
        onMeta(partial_mail);
      }

      const cache = await this.mailCache.get(id);

      if (cache && partial_mail) {
        // Check if all files where found locally
        if (cache.attachments.length === cache.files.length) {
          const { attachments, body } = cidReplacement(cache.body, cache.attachments, cache.files);
          onContent({
            account_id: cache.account_id,
            attachments,
            body,
          });
        }
      }

      // Get the mail's account
      const account = await this.viewAccount.get(mailRef.account_id);
      if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();

      if (!partial_mail || !cache || cache.attachments.length !== cache.files.length) {
        // if one of them is missing, fetch the mail remotely
        const { mail, files } = await this.mailServices[account.type].fetchMail(mailRef, true);

        if (!mail) return onContent(null);

        const split = this._split(mail);

        // Get tags
        const tags = await this.viewTagRelation.getTagsByElement('MAIL', mail.id);

        // Return Meta as early as possible if it was not found previously
        if (!partial_mail) onMeta({ ...split.meta, ...mailRef, tags });

        // Return Content as early as possible, try to replace CID images if the mail has attachments
        if (mail.has_attachments && files && files.length) {
          const { attachments, body } = cidReplacement(mail.body, mail.attachments, files);
          onContent({
            account_id: mail.account_id,
            attachments,
            body,
          });
        } else {
          onContent(split.content);
        }

        // Save meta if it was not found previously
        if (!partial_mail) await this.mailMetaRepo.add(split.meta);

        // Set content and files in cache
        await this.mailCache.set(id, split.content, files);
      }
    } catch (e) {
      if (e instanceof Error) onError(e);
    }
  }

  /**
   * Retrieve a mails metas. If not found locally, download the whole mail and save metas.
   * This must be used for performance optimisation, if only met
   */
  async getMailMeta(id: Uuid): Promise<PartialViewMail | null> {
    // Get the projection (MailReference)
    const ref = await this.mailRefRepo.get(id);
    if (!ref) throw new Error('This mail does not exist');

    // Get the mail's account
    const account = await this.viewAccount.get(ref.account_id);
    if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();

    // Get the mail's meta and cache
    const meta = await this.mailMetaRepo.get(id);
    if (meta) return { ...meta, ...ref };

    // if one of them is missing, fetch the mail remotely
    const { mail } = await this.mailServices[account.type].fetchMail(ref, false);

    if (!mail) return null;

    const split = this._split(mail);

    if (!meta) await this.mailMetaRepo.add(split.meta);

    return { ...split.meta, ...ref };
  }

  /**
   * Retrieve a full mail. If not found locally, download the whole mail and save metas and cache.
   * If the mail is not cached, it will download it and cache it
   */
  async getFullMail(id: Uuid): Promise<ViewMail | null> {
    // Get the projection (MailReference)
    const ref = await this.mailRefRepo.get(id);
    if (!ref) throw new Error('This mail does not exist');

    // Get the mail's account
    const account = await this.viewAccount.get(ref.account_id);
    if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();

    // Get the mail's meta and cache
    const meta = await this.mailMetaRepo.get(id);
    const cache = await this.mailCache.get(id);

    if (meta && cache) return this._consolidate(ref, meta, cache);

    // if one of them is missing, fetch the mail remotely
    const { mail, files } = await this.mailServices[account.type].fetchMail(ref, true);

    if (!mail) return null;

    const split = this._split(mail);

    if (!meta) await this.mailMetaRepo.add(split.meta);
    await this.mailCache.set(id, split.content, files);

    return { ...mail, ...ref };
  }

  async getMailsList(size: number, offset: number): Promise<(PartialViewMail & Taggable)[]> {
    return this.mailMetaRepo.getAllByDate(size, offset);
  }

  async getAll(): Promise<(PartialViewMail & Taggable)[]> {
    return this.mailMetaRepo.getAll();
  }

  /**
   * Publish an acknowledge event to the domain store that will create the Mail Reference as a projection
   * and return the id of the aggregate
   * If the mail already have been added, return null
   */
  private async _acknowledgeMail(mail: NewMailReferenceDTO, tag_ids: Uuid[]): Promise<Uuid | null> {
    // prettyPrint({ mail }, '_acknowledgeMail', 'warn');

    /** Strip everything not belonging in a NewMailReferenceDTO. */
    const { aggregate, ...result } = Mail.acknowledge({
      account_id: mail.account_id,
      date: mail.date,
      provider_id: mail.provider_id,
    });

    let all_changes = result.changes;

    for (const tag_id of tag_ids) {
      const { changes } = TagRelation.create({ element: 'MAIL', element_id: aggregate.id, tag_id });
      all_changes = all_changes.concat(changes);
    }
    /**
     * @note The goal is to handle a mail that generates an already
     *       existing aggregate id.
     */
    try {
      await this.publisher.emit(all_changes);
      return aggregate.id;
    } catch (e) {
      if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
        console.log(`Mail ${aggregate.id} already exists : `, e, mail);
        return null;
      }
      throw e;
    }
  }

  private async _createMailboxTag(mailbox: string): Promise<Uuid> {
    const label = mailbox === 'INBOX' ? 'Inbox' : mailbox.split('.').length === 2 ? mailbox.split('.')[1] : mailbox;
    const color = randomEnum(TagColor);

    const { aggregate, ...result } = Tag.create({ color, label });

    try {
      await this.publisher.emit(result.changes);
    } catch (e) {
      if (!(e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError'))) throw e;
    }

    return aggregate.id;
  }

  /**
   * Acknowledge mails, save their metas, cache their content and index them in the search engine
   * then return new ones
   */
  private async _saveMails(mails: NewMailDTO[], mailbox: string, tags: Uuid[] = []): Promise<MailSplit[]> {
    if (!mails.length) return [];

    const tag_ids: Uuid[] = tags;

    // Do not assign mailbox tag if the mailbox is the INBOX
    if (mailbox !== 'INBOX') {
      const mailbox_tag_id = await this._createMailboxTag(mailbox);
      tag_ids.push(mailbox_tag_id);
    }

    const new_mails: (MailSplit & Taggable)[] = (
      await Promise.all(
        mails.map(async (mail) => {
          const id = await this._acknowledgeMail(mail, tag_ids);
          if (id === null) return null;
          const tags = await this.viewTagRelation.getTagsByElement('MAIL', id);
          return { ...this._split({ id, ...mail }), tags };
        })
      )
    ).filter((mail): mail is MailSplit & Taggable => mail !== null);

    Promise.all([
      // Save metas
      this.mailMetaRepo.addMany(new_mails.map((split) => split.meta)),
      // Cache content
      Promise.all(new_mails.map(({ content, meta }) => this.mailCache.set(meta.id, content, []))),
      // Index in search
      Promise.all(new_mails.map(({ meta, ref, tags }) => this.search.addMail({ ...meta, ...ref, tags }))),
    ]);
    return new_mails;
  }

  private async _fullFetchMailbox(
    account: ViewAccount & Taggable & { type: MailSupportedAccounts },
    mailbox: MailBox,
    offset: number
  ): Promise<void> {
    let limit = this.FULL_FETCH_STEP_SIZE;
    if (offset + 1 + limit > mailbox.nb_mails) limit = mailbox.nb_mails - offset;

    if (limit === 0) return;

    const mails: NewMailDTO[] = await this.mailServices[account.type].getMailsList(account.id, mailbox.id, offset + 1, limit);

    // If the fetch was cancelled by a logout or the account deletion, return here.
    if (this.cancel_ff_ids.has(account.id)) return;

    await this._saveMails(
      mails,
      mailbox.label,
      account.tags.map((tag) => tag.id)
    );

    // If there is less than asked results, we are done with this mailbox
    const done = limit < this.FULL_FETCH_STEP_SIZE;
    const next_offset = offset + this.FULL_FETCH_STEP_SIZE;

    const accountAgg = await this.account.getAggregate(account.id);
    const resultMark = accountAgg.setProgress(mailbox.id, next_offset, done);
    if (resultMark.ok) {
      await this.publisher.emit(resultMark.changes);
    }

    if (!done) return this._fullFetchMailbox(account, mailbox, next_offset);
  }

  async fullFetch(account_id?: Uuid): Promise<void> {
    let accounts = await this.viewAccount.getAllWithTags();

    if (account_id) accounts = accounts.filter((acc) => acc.id === account_id);

    const mail_accounts: (ViewAccount & Taggable & { type: MailSupportedAccounts })[] =
      accounts.filter(isMailSupportedAccountTaggable);

    await Promise.all(
      mail_accounts.map(async (account) => {
        let to_fetch: { mailbox: string; offset: number; total: number }[] = [];

        if (account.full_fetch_status === 'DONE') return;

        const mailboxes = await this.mailServices[account.type].getMailboxes(account.id);

        if (account.full_fetch_status === 'IDLE') {
          to_fetch = mailboxes
            .filter((mailbox) => mailbox.nb_mails === null || mailbox.nb_mails > 0 || mailbox.id === '[Gmail]/All Mail')
            .map(({ id, nb_mails }) => ({ mailbox: id, offset: 0, total: nb_mails }));

          const accountAgg = await this.account.getAggregate(account.id);
          const resultMark = accountAgg.startFullFetch(to_fetch);
          if (resultMark.ok) {
            await this.publisher.emit(resultMark.changes);
          }
        } else {
          to_fetch = account.full_fetch_progress.filter((mb) => !mb.done);
        }

        for (const { mailbox, offset } of to_fetch) {
          const mailbox_object = mailboxes.find((mb) => mb.id === mailbox);
          if (mailbox_object) await this._fullFetchMailbox(account, mailbox_object, offset);
        }
      })
    );
  }

  async cancelFullFetch(account_id?: Uuid): Promise<void> {
    if (account_id) this.cancel_ff_ids.add(account_id);
    else {
      const accounts = await this.viewAccount.getAll();
      accounts.forEach((acc) => this.cancel_ff_ids.add(acc.id));
    }
  }
}
