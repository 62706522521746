import React, { FC, useEffect } from 'react';
import { accountCalendarsSelector, syncModalAccountIdSelector, Uuid, ViewCalendar } from '@focus-front/core';
import { List, makeStyles, MenuItem, Switch } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  calendarItem: {
    padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    justifyContent: 'space-between',
    '& span:first-letter': {
      textTransform: 'uppercase',
    },
  },
  color: {
    display: 'inline-flex',
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 14,
    marginRight: 12,
    marginTop: 2,
    marginBottom: '-2px',
  },
}));

interface AccountSyncCalendarListProps {
  handleCalendarSwitch: (calendar: ViewCalendar) => Promise<void>;
  accountId: Uuid;
}

export default function AccountSyncCalendarList({ handleCalendarSwitch, accountId }: AccountSyncCalendarListProps) {
  const c = useClasses();
  const { t } = useTranslation();

  const accountCalendars = useSelector(accountCalendarsSelector(accountId));

  return (
    <List>
      {/* {accountId ?? 'null'} */}
      {accountCalendars.map((calendar) => (
        <MenuItem key={calendar.id} className={c.calendarItem} onClick={() => handleCalendarSwitch(calendar)}>
          <div>
            <div className={c.color} style={{ backgroundColor: calendar.background_color }} />
            <span>
              {calendar.type === 'GOOGLE' && calendar?.is_primary && calendar.name.includes('@')
                ? t('calendar.mainAgenda')
                : calendar.name}
            </span>
          </div>
          <Switch checked={calendar.sync_activated} />
        </MenuItem>
      ))}
    </List>
  );
}
