import React, { useState, useEffect } from 'react';
import { CircularProgress, Dialog, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fancyTimeFormat, getAttachmentFile, ImAttachment, LocalFile, useAppDispatch, Uuid } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { downloadOutline, playOutline, videocam } from 'ionicons/icons';

const useClasses = makeStyles((theme) => ({
  attContainer: {
    width: 250,
    height: 250,
    padding: 5,
    position: 'relative',
    '& > .thumb': {
      borderRadius: 11,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      overflow: 'hidden',
      transform: 'translate3d(0,0,0)',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block',
        filter: 'blur(5px)',
        transform: 'scale(1.1)',
      },
    },
    '& .download': {
      opacity: 0,
      right: 10,
      top: 10,
      width: 34,
      cursor: 'pointer',
      textAlign: 'center',
    },
    '& .meta': {
      bottom: 10,
      left: 10,
      display: 'flex',
      padding: 4,
      '& ion-icon': {
        marginRight: 10,
      },
    },
    '& .meta, & .download': {
      position: 'absolute',
      backgroundColor: 'rgba(36,36,36,0.7)',
      borderRadius: 8,
      color: '#fff',
      fontSize: 22,
    },
    '&:hover .download': {
      opacity: 1,
    },
  },
  playIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    backgroundColor: 'rgba(36,36,36,0.7)',
    borderRadius: 50,
    width: 60,
    height: 60,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& ion-icon': {
      fontSize: 40,
    },
  },
}));

type Props = { account_id: Uuid; attachment: ImAttachment };

function ImVideoAttachment({ account_id, attachment }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  return (
    <>
      <VideoDialog account_id={account_id} attachment={attachment} open={open} onClose={() => setOpen(false)} />
      <div className={c.attContainer}>
        <div className="thumb">{attachment.media_data.preview && <img src={attachment.media_data.preview} />}</div>
        <div className={c.playIcon} onClick={() => setOpen(true)}>
          <IonIcon icon={playOutline} />
        </div>
        <div className="download">
          <IonIcon icon={downloadOutline} />
        </div>
        <div className="meta">
          <IonIcon icon={videocam} />
          <Typography variant="body2">{fancyTimeFormat(attachment.media_data.duration)}</Typography>
        </div>
      </div>
    </>
  );
}

function VideoDialog({ attachment, account_id, open, onClose }: { open: boolean; onClose: () => void } & Props) {
  const [status, setStatus] = useState<'LOADING' | 'ERROR' | 'UNSUPPORTED' | 'IDLE'>('LOADING');
  const [file, setFile] = useState<LocalFile>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (open && !file)
      (async () => {
        const { error, result } = await dispatch(getAttachmentFile(account_id, attachment, false));
        setStatus(error === 'UnsupportedDevice' ? 'UNSUPPORTED' : error ? 'ERROR' : 'IDLE');
        if (result) setFile(result);
      })();
  }, [attachment, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'lg'} scroll="body">
      {status === 'LOADING' && <CircularProgress />}
      {status === 'ERROR' && <Typography variant="subtitle2">{t('attachment.error')}</Typography>}
      {status === 'UNSUPPORTED' && <Typography variant="subtitle2">{t('attachment.notSupported')}</Typography>}
      {file && file.data && (
        <video
          src={`data:${file.mime}; base64,${file.data}`}
          style={{
            width: attachment.media_data.width,
            height: attachment.media_data.height,
            display: 'block',
          }}
          autoPlay
          muted
          controls
        />
      )}
    </Dialog>
  );
}

export default React.memo(ImVideoAttachment);
