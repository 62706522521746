import { DomainEventShape, Uuid } from '../../../Common';
import { NewCallDTO } from '../projections/ViewCall';

/**
 * DomainEvent.
 */
export class MissedCallReceived implements DomainEventShape {
  readonly type = 'MISSED_CALL_RECEIVED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly call: NewCallDTO, readonly createdAt = Date.now()) {}
}

export class OutgoingCallReceived implements DomainEventShape {
  readonly type = 'OUTGOING_CALL_RECEIVED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly call: NewCallDTO, readonly createdAt = Date.now()) {}
}

export class IncomingCallReceived implements DomainEventShape {
  readonly type = 'INCOMING_CALL_RECEIVED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly call: NewCallDTO, readonly createdAt = Date.now()) {}
}

export class NoAnswerCallReceived implements DomainEventShape {
  readonly type = 'NO_ANSWER_CALL_RECEIVED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly call: NewCallDTO, readonly createdAt = Date.now()) {}
}

/**
 * Collect and export all event definitions.
 */
export type CallDomainEvent = MissedCallReceived | OutgoingCallReceived | IncomingCallReceived | NoAnswerCallReceived;
