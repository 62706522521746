import { Plugins } from '@capacitor/core';
import { getAdminLink, useAppDispatch } from '@focus-front/core';

const { Browser } = Plugins;

type Options = { destination?: 'subscription' | 'payment_method' | 'dashboard' };

export function useAdminLink(opts?: Options) {
  const dispatch = useAppDispatch();

  const onClick = async () => {
    const { error, result } = await dispatch(getAdminLink());

    console.log(result);
    if (error) return alert(error);

    /**
     * @note We use capacitor plugin to open the link from Electron app as well
     *
     * @todo Move the logic to the UseCase so the web app does not import capacitor
     */

    await Browser.open({ url: result + (opts?.destination ? '/' + opts.destination : '') });
  };

  return {
    onClick,
  };
}
