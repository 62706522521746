/**
 * @note atob(), btoa() throw InvalidCharacterError when given data is invalid.
 *
 * @note Opaque type.
 */
declare const validBase64: unique symbol;
export type Base64 = string & { [validBase64]: true };

/** @note This is not fit for large string, it will throw RANGE ERROR at around 2^23 chars.*/
export const BASE64_REGEX = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;


const NOT_BASE64_CHAR_REGEX = /[^A-Za-z0-9+/=]/;
/**
 *
 */
export function isBase64(s: string): s is Base64 {
  const length = s.length;
  if (length % 4 !== 0 || NOT_BASE64_CHAR_REGEX.test(s)) {
    return false;
  }
  const firstPaddingChar = s.indexOf('=');
  return (
    firstPaddingChar === -1 ||
    firstPaddingChar === length - 1 ||
    (firstPaddingChar === length - 2 && s[length - 1] === '=')
  );
}
