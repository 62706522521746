import React, { useEffect } from 'react';
import { mailDraftDone, searchLayoutSelector, selectSearchResult, useAppDispatch } from '@focus-front/core';
import { ActionModalProps } from './ActionModalProps';
import { useSelector } from 'react-redux';

export default function MailDraftDone({ onDone, context }: ActionModalProps) {
  const dispatch = useAppDispatch();
  const { selected } = useSelector(searchLayoutSelector);

  useEffect(() => {
    (async () => {
      const error = await dispatch(mailDraftDone(context));
      if (!error) {
        if (selected?.id === context.metadata.attached_entity.id) dispatch(selectSearchResult(null));
        setTimeout(() => onDone('done'), 200);
      } else alert(error.message);
    })();
  }, []);

  return <div></div>;
}
