import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { initError, loginUser, registerUser, useAppDispatch } from '@focus-front/core';
import { createStyles, IconButton, InputAdornment, makeStyles, Snackbar, TextField, Theme, Typography } from '@material-ui/core';
import LoadingButton from '../Common/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface SignUpForm {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  referral_code: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    field: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 10,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& ion-icon': {
        marginRight: theme.spacing(1),
        fontSize: 20,
      },
    },
  })
);
export default function SignUpForm() {
  const params = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const { register, errors, handleSubmit } = useForm<SignUpForm>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      referral_code: params.get('aff'),
    },
  });

  const onSubmit = async (data: SignUpForm) => {
    setLoading(true);
    setError(null);
    const error = await dispatch(registerUser(data.first_name, data.last_name, data.email, data.password, data.referral_code));
    if (error) {
      setLoading(false);
      setError(error);
    } else {
      setIsOpen(true);
      const loginError = await dispatch(loginUser(data.email, data.password));
      // Fake wait time to make the user read the snackbar
      setTimeout(function () {
        if (loginError) {
          dispatch(initError(loginError));
          history.push('/login');
        } else {
          history.push('/');
        }
      }, 2000);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <TextField
        label={t('signup.form.email.label')}
        name="email"
        autoFocus
        placeholder={t('signup.form.email.placeholder')}
        inputRef={register({
          required: t<string>('signup.form.email.required'),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t<string>('signup.form.email.invalid'),
          },
        })}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.field,
        }}
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email && errors.email.message}
      />
      <TextField
        label={t('signup.form.firstname.label')}
        name="first_name"
        inputRef={register({ required: t<string>('signup.form.firstname.required') })}
        fullWidth
        placeholder={t('signup.form.firstname.placeholder')}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.field,
        }}
        variant="outlined"
        error={!!errors.first_name}
        helperText={errors.first_name && errors.first_name.message}
      />
      <TextField
        label={t('signup.form.lastname.label')}
        name="last_name"
        inputRef={register({ required: t<string>('signup.form.lastname.required') })}
        fullWidth
        placeholder={t('signup.form.lastname.placeholder')}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.field,
        }}
        variant="outlined"
        error={!!errors.last_name}
        helperText={errors.last_name && errors.last_name.message}
      />
      <TextField
        label={t('signup.form.password.label')}
        name="password"
        type={showPassword ? 'text' : 'password'}
        inputRef={register({ required: t<string>('signup.form.password.required') })}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.field,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        error={!!errors.password}
        helperText={errors.password && errors.password.message}
      />
      <TextField
        label={t('signup.form.referral_code.label')}
        name="referral_code"
        inputRef={register()}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.field,
        }}
        variant="outlined"
        error={!!errors.referral_code}
        helperText={errors.referral_code && errors.referral_code.message}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        className={classes.button}
        type="submit"
      >
        {t('signup.form.submit')}
      </LoadingButton>
      <Typography component="p" className={classes.error}>
        {error}
      </Typography>
      <Snackbar
        open={isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert variant="filled" elevation={6} severity="success">
          {t('signup.success')}
        </Alert>
      </Snackbar>
    </form>
  );
}
