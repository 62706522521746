import { createViewCalendarEvent, CalendarEventDTO } from '../../../Calendar';
import { createViewCall, ViewCallRepo } from '../../../Call';
import { createViewContact, ViewContactRepo } from '../../../Contact';
import { ViewCalendarEventRepo } from '../../../Calendar/domain/projections/ViewCalendarEventRepo';
import { createReactor } from '../../../Common';
import { ViewMailDraftRepo, createViewMailDraft, MailReferenceRepo } from '../../../Mail';
import { SearchService } from '../../infra/services/SearchService';
import { ViewImThreadRepo } from '../../../Im/domain/projections/ViewImThreadRepo';
import { createViewIm, isZeroInboxImThread } from '../../../Im';

interface Context {
  search: SearchService;
  viewContact: ViewContactRepo;
  viewMailDraft: ViewMailDraftRepo;
  viewCalendarEvent: ViewCalendarEventRepo;
  viewImThread: ViewImThreadRepo;
  viewMail: MailReferenceRepo;
  viewCall: ViewCallRepo;
}

/**
 * Search Index Reactor handle side effects of all create/edit/delete events on searchable entities
 * to sync modifications of those entitie's projections into the search index
 */
export const searchIndexReactor = createReactor('searchIndexReactor', {
  MISSED_CALL_RECEIVED: async (event, publisher, { search }: Context) => {
    return search.addCall({ ...createViewCall(event.aggregateId, event.call), tags: [] });
  },
  INCOMING_CALL_RECEIVED: async (event, publisher, { search }: Context) => {
    return search.addCall({ ...createViewCall(event.aggregateId, event.call), tags: [] });
  },
  OUTGOING_CALL_RECEIVED: async (event, publisher, { search }: Context) => {
    return search.addCall({ ...createViewCall(event.aggregateId, event.call), tags: [] });
  },
  NO_ANSWER_CALL_RECEIVED: async (event, publisher, { search }: Context) => {
    return search.addCall({ ...createViewCall(event.aggregateId, event.call), tags: [] });
  },
  IM_SYNCED: async (event, publisher, { search, viewImThread }: Context) => {
    const thread = await viewImThread.get(event.thread_id);
    if (!thread || isZeroInboxImThread(thread)) return;
    return search.addIm(createViewIm(event.aggregateId, event.thread_id, event.im), thread);
  },
  CALENDAREVENT_CREATED: async ({ aggregateId, calendarEvent }, publisher, { search, viewCalendarEvent }: Context) => {
    switch (calendarEvent.kind) {
      case 'SINGLE':
        return search.putEvent({ ...createViewCalendarEvent(aggregateId, calendarEvent), tags: [] });
      case 'RECURRING': {
        const instances = await viewCalendarEvent.getInstancesOf(aggregateId);
        return search.addEvents(instances);
      }
    }
  },
  CALENDAREVENT_ACKNOWLEDGED: async ({ aggregateId, calendarEvent }, publisher, { search, viewCalendarEvent }: Context) => {
    switch (calendarEvent.kind) {
      case 'SINGLE':
        return search.putEvent({ ...createViewCalendarEvent(aggregateId, calendarEvent), tags: [] });
      case 'RECURRING': {
        const instances = await viewCalendarEvent.getInstancesOf(aggregateId);
        return search.addEvents(instances);
      }
    }
  },
  CALENDAREVENT_RESTORE_ACKNOWLEDGED: async (
    { aggregateId, calendarEvent },
    publisher,
    { search, viewCalendarEvent }: Context
  ) => {
    switch (calendarEvent.kind) {
      case 'SINGLE':
        return search.putEvent({ ...createViewCalendarEvent(aggregateId, calendarEvent), tags: [] });
      case 'RECURRING': {
        const instances = await viewCalendarEvent.getInstancesOf(aggregateId);
        return search.addEvents(instances);
      }
    }
  },
  CALENDAREVENT_EDITED: async ({ aggregateId, patch, mustExpand }, publisher, { search, viewCalendarEvent }: Context) => {
    switch (patch.kind) {
      case 'SINGLE': {
        if (mustExpand) {
          return search.removeRecurringEvent(aggregateId);
        }
        const calEvent = await viewCalendarEvent.get(aggregateId);
        if (calEvent) {
          return search.putEvent({
            ...createViewCalendarEvent(aggregateId, { ...calEvent, ...patch } as CalendarEventDTO),
            tags: calEvent.tags,
          });
        }
        return;
      }
      case 'RECURRING': {
        search.removeRecurringEvent(aggregateId);
        const instances = await viewCalendarEvent.getInstancesOf(aggregateId);
        return search.addEvents(instances);
      }
    }
  },
  CALENDAREVENT_PATCH_ACKNOWLEDGED: async (
    { aggregateId, patch, mustExpand },
    publisher,
    { search, viewCalendarEvent }: Context
  ) => {
    switch (patch.kind) {
      case 'SINGLE': {
        if (mustExpand) {
          return search.removeRecurringEvent(aggregateId);
        }
        const calEvent = await viewCalendarEvent.get(aggregateId);
        if (calEvent) {
          return search.putEvent({
            ...createViewCalendarEvent(aggregateId, { ...calEvent, ...patch } as CalendarEventDTO),
            tags: calEvent.tags,
          });
        }
        return;
      }
      case 'RECURRING': {
        search.removeRecurringEvent(aggregateId);
        const instances = await viewCalendarEvent.getInstancesOf(aggregateId);
        return search.addEvents(instances);
      }
    }
  },
  CALENDAREVENT_DELETED: async (event, publisher, { search }: Context) => {
    switch (event.kind) {
      case 'SINGLE':
        return search.removeEvent(event.aggregateId);
      case 'RECURRING':
        return search.removeRecurringEvent(event.aggregateId);
    }
  },
  CALENDAREVENT_DELETE_ACKNOWLEDGED: async (event, publisher, { search }: Context) => {
    switch (event.kind) {
      case 'SINGLE':
        return search.removeEvent(event.aggregateId);
      case 'RECURRING':
        return search.removeRecurringEvent(event.aggregateId);
    }
  },
  CALENDAREVENT_EXCEPTION_ADDED: async (
    { aggregateId, instanceId, exception },
    publisher,
    { search, viewCalendarEvent }: Context
  ) => {
    const calEvent = await viewCalendarEvent.get(instanceId);
    if (calEvent) {
      return search.putEvent({
        ...createViewCalendarEvent(aggregateId, { ...calEvent, ...exception } as CalendarEventDTO, instanceId),
        tags: calEvent.tags,
      });
    }
  },
  CALENDAREVENT_EXCEPTION_ACKNOWLEDGED: async (
    { aggregateId, instanceId, exception },
    publisher,
    { search, viewCalendarEvent }: Context
  ) => {
    const calEvent = await viewCalendarEvent.get(instanceId);
    if (calEvent) {
      return search.putEvent({
        ...createViewCalendarEvent(aggregateId, { ...calEvent, ...exception } as CalendarEventDTO, instanceId),
        tags: calEvent.tags,
      });
    }
  },
  CALENDAREVENT_EXCEPTION_EDITED: async (
    { aggregateId, instanceId, exceptionPatch },
    publisher,
    { search, viewCalendarEvent }: Context
  ) => {
    const calEvent = await viewCalendarEvent.get(instanceId);
    if (calEvent) {
      return search.putEvent({
        ...createViewCalendarEvent(aggregateId, { ...calEvent, ...exceptionPatch } as CalendarEventDTO, instanceId),
        tags: calEvent.tags,
      });
    }
  },
  CALENDAREVENT_EXCEPTION_PATCH_ACKNOWLEDGED: async (
    { aggregateId, instanceId, exceptionPatch },
    publisher,
    { search, viewCalendarEvent }: Context
  ) => {
    const calEvent = await viewCalendarEvent.get(instanceId);
    if (calEvent) {
      return search.putEvent({
        ...createViewCalendarEvent(aggregateId, { ...calEvent, ...exceptionPatch } as CalendarEventDTO, instanceId),
        tags: calEvent.tags,
      });
    }
  },
  //   CALENDAREVENT_EXCEPTION_CANCELLED: async ({ instanceId }, publisher, { search }: Context) => {
  //     return /** @todo CALENDAREVENT_EXCEPTION_CANCELLED. */
  //   },
  //   CALENDAREVENT_EXCEPTION_CANCEL_ACKNOWLEDGED: async ({ instanceId }, publisher, { search }: Context) => {
  //     return /** @todo CALENDAREVENT_EXCEPTION_CANCEL_ACKNOWLEDGED. */
  //   },
  //   CALENDAREVENT_EXCEPTION_RESTORE_ACKNOWLEDGED: async ({ instanceId }, publisher, { search }: Context) => {
  //     return /** @todo CALENDAREVENT_EXCEPTION_RESTORE_ACKNOWLEDGED. */
  //   },
  CALENDAREVENT_INSTANCE_DELETED: async ({ instanceId }, publisher, { search }: Context) => {
    return search.removeEvent(instanceId);
  },
  CALENDAREVENT_INSTANCE_DELETE_ACKNOWLEDGED: async ({ instanceId }, publisher, { search }: Context) => {
    return search.removeEvent(instanceId);
  },
  CONTACT_CREATED: async (event, publisher, { search }: Context) => {
    return search.putContact({ ...createViewContact(event.aggregateId, event.contact), tags: [] });
  },
  CONTACT_EDITED: async (event, publisher, { search, viewContact }: Context) => {
    const contact = await viewContact.get(event.aggregateId);
    if (contact) {
      return search.putContact({
        ...createViewContact(event.aggregateId, { ...contact, ...event.updatedValues }),
        tags: contact.tags,
      });
    }
  },
  CONTACT_DELETED: async (event, publisher, { search }: Context) => {
    return search.removeContact(event.aggregateId);
  },
});
