import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FilterCheckbox } from './FilterCheckbox';
import FilterPopper from './FilterPopper';
import { Autocomplete } from '@material-ui/lab';
import { NavButton } from '../../Wizard/NavButton';
import { IonIcon } from '@ionic/react';
import { caretDownSharp } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import {
  accountIdentifier,
  accountsListSelector,
  AccountType,
  inboxAccountFilterSelector,
  notificationsSelector,
  setAccountFilter,
  useAppDispatch,
  Uuid,
} from '@focus-front/core';
import { useTranslation } from 'react-i18next';
import { AccountTypeIcon } from '@focus-front/ui';

const useClasses = makeStyles((theme) => ({
  accountType: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  searchField: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  paper: {
    backgroundColor: '#fff',
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

type AccountOption = {
  id: Uuid;
  name: string;
  type: AccountType;
};

type TypeFieldProps = {
  options: AccountOption[];
  onChange: (values: AccountOption[]) => void;
  icon?: JSX.Element;
  values: AccountOption[];
  placeholder: string;
};
function TypeField({ onChange, options, icon, placeholder, values }: TypeFieldProps) {
  const c = useClasses();
  const { t } = useTranslation();

  const onCheckboxClick = useCallback(() => {
    if (values.length > 0) {
      onChange([]);
    } else {
      onChange(options);
    }
  }, [options, values]);

  return (
    <ListItem dense>
      <ListItemIcon>
        <FilterCheckbox
          onClick={onCheckboxClick}
          checked={values.length > 0}
          indeterminate={options.length > values.length && values.length > 0}
        />
        {icon && <div className={c.accountType}>{icon}</div>}
      </ListItemIcon>
      <ListItemText
        primary={
          <Autocomplete
            autoHighlight
            openOnFocus
            options={options}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            disableCloseOnSelect
            disableClearable
            renderTags={() => null}
            multiple
            value={values}
            onChange={(e, value: AccountOption[]) => {
              onChange(value);
            }}
            classes={{
              paper: c.paper,
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <FilterCheckbox checked={selected} />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={values.length ? `${values.length} ${t('inbox.filterByAccount.placeholder')}` : placeholder}
                className={c.searchField}
              />
            )}
          />
        }
      />
    </ListItem>
  );
}

const TYPES: AccountType[] = [
  'WHATSAPP',
  'GOOGLE',
  'LINKEDIN',
  'MAIL',
  'MESSENGER',
  'INSTAGRAM',
  'TIKTOK',
  'TWITTER',
  'MOBILE',
  'OUTLOOK',
];

export default function FilterByAccount() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const allAccounts = useSelector(accountsListSelector);
  const accounts = useSelector(inboxAccountFilterSelector);

  return (
    <div style={{ marginLeft: 10 }}>
      <NavButton onClick={handleClick} endIcon={<IonIcon icon={caretDownSharp} style={{ fontSize: 12 }} />}>
        {t('inbox.filterByAccount.btn')}
      </NavButton>
      <FilterPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        title={t('inbox.filterByAccount.title')}
        onClose={(e, r) => {
          setAnchorEl(null);
        }}
      >
        <Box>
          <List>
            {TYPES.map((type) => {
              const filteredAccounts = allAccounts.filter((acc) => acc.type === type);
              if (filteredAccounts.length)
                return (
                  <TypeField
                    key={type}
                    options={filteredAccounts.map((acc) => ({
                      id: acc.id,
                      name: acc.name || accountIdentifier(acc.connection_params),
                      type,
                    }))}
                    onChange={(values) => {
                      const removed = accounts.filter((acc) => acc.type !== type);
                      dispatch(setAccountFilter([...removed, ...values]));
                    }}
                    values={accounts.filter((acc) => acc.type === type).map((acc) => ({ ...acc, name: '' }))}
                    icon={<AccountTypeIcon account_type={type} size={30} />}
                    placeholder={t('inbox.filterByAccount.placeholderEmpty', { type: t('accounts.' + type.toLowerCase()) })}
                  />
                );
              return null;
            })}
          </List>
        </Box>
      </FilterPopper>
    </div>
  );
}
