import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  PopoverProps,
  Popper,
  PopperProps,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Close } from '@material-ui/icons';
import React, { PropsWithChildren } from 'react';

const useClasses = makeStyles((theme) => ({
  paper: {
    width: 350,
    maxHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    backgroundColor: theme.palette.background.paper,
  },
  section: {
    borderBottom: '1px solid rgba(27,31,35,.15)',
  },

  grey: {
    color: grey[600],
  },
}));

type Props = {
  title: string;
} & PopoverProps;

export default function FilterPopper({ onClose, title, children, ...popperProps }: Props) {
  const c = useClasses();
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transitionDuration={0}
      TransitionComponent={Fade}
      onClose={onClose}
      {...popperProps}
    >
      <Paper className={c.paper}>
        <Box display="flex" justifyContent="space-between" alignItems="center" pl={1} className={c.section}>
          <Typography variant="subtitle1" className={c.grey}>
            {title}
          </Typography>
          <IconButton size="small" onClick={(e) => onClose(e, 'escapeKeyDown')}>
            <Close className={c.grey} />
          </IconButton>
        </Box>
        {children}
      </Paper>
    </Popover>
  );
}
