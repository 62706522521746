import { Image } from '../../../Common';
import { createUser, NewUserDTO, UpdateUserDTO, User } from '../../domain';
import { ApiService } from '../services/ApiService';
import { UserRepo } from './UserRepo';

export class ApiUserRepo implements UserRepo {
  constructor(private api: ApiService) {}

  async create(user: NewUserDTO): Promise<any> {
    return this.api.registerUser(user.email, user.password, user.firstname, user.lastname, user.referral_code);
  }

  async get(user_id: number): Promise<User> {
    const userDto = await this.api.getUser(user_id);
    return createUser(userDto);
  }

  async update(id: number, updates: UpdateUserDTO, picture?: Image | null): Promise<void> {
    await this.api.updateUser(
      {
        firstname: updates.firstname,
        language: updates.language,
        lastname: updates.lastname,
        profile_picture: picture,
        params: updates.params,
      },
      id
    );
  }
}
