import React, { useState } from 'react';
import { Badge, Avatar, Menu, MenuItem, Button } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function ProfilePictureUpload({ imgSrc, onUpload, onDelete }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpload = () => {
    setAnchorEl(null);
    setTimeout(onUpload, 100);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setTimeout(onDelete, 100);
  };
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      badgeContent={
        <>
          <Button onClick={handleClick} color="inherit" startIcon={<EditOutlined />} variant="contained" size="small">
            {t('userSettings.profile.editPicture')}
          </Button>

          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem onClick={handleUpload}>{t('userSettings.profile.uploadNew')}...</MenuItem>
            <MenuItem onClick={handleDelete}>{t('userSettings.profile.deletePicture')}</MenuItem>
          </Menu>
        </>
      }
    >
      <Avatar src={imgSrc} style={{ width: 200, height: 200 }} />
    </Badge>
  );
}
