import { DomainEventShape, UTCDateTimeMs, Uuid } from '../../../Common';
import { NewAccountDTO, UpdateAccountDTO } from '../projections/ViewAccount';
import { NewEncryptedCredentialsDTO } from '../projections/ViewCredentials';

/**
 * DomainEvent.
 */
export class AccountCreated implements DomainEventShape {
  readonly type = 'ACCOUNT_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly account: NewAccountDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class AccountDeleted implements DomainEventShape {
  readonly type = 'ACCOUNT_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class AccountEdited implements DomainEventShape {
  readonly type = 'ACCOUNT_EDITED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly values: UpdateAccountDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class AccountFetched implements DomainEventShape {
  readonly type = 'ACCOUNT_FETCHED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly last_fetched_at: UTCDateTimeMs,
    readonly createdAt = Date.now()
  ) {}
}
/**
 * DomainEvent.
 */
export class AccountSynced implements DomainEventShape {
  readonly type = 'ACCOUNT_SYNCED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly sync_token: string,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class AccountProgressSet implements DomainEventShape {
  readonly type = 'ACCOUNT_FF_PROGRESS_SET';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly mailbox: string,
    readonly offset: number,
    readonly done: boolean,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class AccountFullFetchStart implements DomainEventShape {
  readonly type = 'ACCOUNT_FF_START';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly mailboxes: { mailbox: string; total: number }[],
    readonly createdAt = Date.now()
  ) {}
}

// /**
//  * DomainEvent.
//  */
// export class AccountFullFetchCompleted implements DomainEventShape {
//   readonly type = 'ACCOUNT_FF_COMPLETED';
//   readonly schema = 1;
//   constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
// }

/**
 * DomainEvent.
 */
export class AccountSignatureSelected implements DomainEventShape {
  readonly type = 'ACCOUNT_SIGNATURE_SELECTED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly signature_id: Uuid | undefined,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class AccountCredentialsAdded implements DomainEventShape {
  readonly type = 'ACCOUNT_CREDENTIALS_ADDED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly encryptedCredentials: NewEncryptedCredentialsDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class AccountCredentialsUpdated implements DomainEventShape {
  readonly type = 'ACCOUNT_CREDENTIALS_UPDATED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly encryptedCredentials: NewEncryptedCredentialsDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type AccountDomainEvent =
  | AccountCreated
  | AccountDeleted
  | AccountEdited
  | AccountFetched
  | AccountSynced
  | AccountSignatureSelected
  | AccountCredentialsAdded
  | AccountProgressSet
  | AccountFullFetchStart
  | AccountCredentialsUpdated;
