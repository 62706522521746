import { createProjector } from '../../../Common';
import { createViewContact } from '../projections/ViewContact';
import { ViewContactRepo } from '../projections/ViewContactRepo';

/**
 * Handler/Projector.
 */
export const viewContactsProjector = createProjector('viewContactsProjector', {
  CONTACT_CREATED: async ({ aggregateId, contact }, repo: ViewContactRepo) => {
    return repo.add(createViewContact(aggregateId, contact));
  },
  CONTACT_DELETED: async ({ aggregateId }, repo) => {
    return repo.remove(aggregateId);
  },
  CONTACT_EDITED: async ({ aggregateId, updatedValues }, repo) => {
    return repo.update(aggregateId, updatedValues);
  },
});
