import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

export const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
    };
  },
});

export const CustonTableRow = TableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
    };
  },
});

export const CustonTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
    };
  },
});

export const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      cellSpacing: {
        default: null,
        renderHTML: (attributes) => {
          return {
            cellSpacing: attributes.cellSpacing,
          };
        },
      },
      cellPadding: {
        default: null,
        renderHTML: (attributes) => {
          return {
            cellPadding: attributes.cellPadding,
          };
        },
      },
    };
  },
});
