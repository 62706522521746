import React, { useCallback } from 'react';
import {
  AccountSource,
  AccountSourceStatus,
  setSourceStatus,
  useAppDispatch,
  setShowReconnectAccount,
  AccountType,
} from '@focus-front/core';
import { Alert, Color } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { ArrowForwardIosRounded, RefreshOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';

const SEVERITY: { [key in AccountSourceStatus]: Color } = {
  IDLE: 'info',
  FETCHING: 'info',
  NOT_SETUP: 'info',
  CREDENTIALS: 'warning',
  PERMISSIONS: 'warning',
  ERROR: 'error',
  PAUSE: 'info',
  DISABLED_DEVICE_LIMIT: 'info',
  DISABLED_USER_LIMIT: 'info',
  UNSUPPORTED: 'info',
};

type Props = {
  source: AccountSource;
  account_type: AccountType;
  className?: string;
};

export default function SourceAlert({ source, account_type, className }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const confirm = useConfirm();

  const onAlertClick = () => {
    if (source.status === 'UNSUPPORTED') {
      const el = document.createElement('a');
      el.href = t(`accountSettings.sources.alertButtonLink.info`);
      el.click();
    }

    if (source.status === 'ERROR') {
      const el = document.createElement('a');
      el.href = t(`accountSettings.sources.alertButtonLink.contact`);
      el.click();
    }

    if (source.status === 'NOT_SETUP') {
      alert('Enabling sources after the first account connexion is not available yet.');
    }

    if (source.status === 'CREDENTIALS') {
      dispatch(setShowReconnectAccount({ id: source.account_id, type: account_type }));
    }
  };

  const onRetry = useCallback(async () => {
    await confirm({
      title: t(`accountSettings.sources.alertButton.errorRetryTitle`),
      description: t(`accountSettings.sources.alertButton.errorRetryDescription`),
      confirmationText: t(`accountSettings.sources.alertButton.errorRetryConfirm`),
      cancellationText: t(`accountSettings.sources.alertButton.errorRetryCancel`),
    });
    window.location.reload();
  }, []);

  if (source.status === 'IDLE' || source.status === 'FETCHING' || source.status === 'PAUSE') return null;
  return (
    <Alert
      severity={SEVERITY[source.status]}
      className={className}
      action={
        <>
          {source.status === 'ERROR' && (
            <Button onClick={onRetry} color="inherit" size="small" variant="text" endIcon={<RefreshOutlined />}>
              {t(`accountSettings.sources.alertButton.errorRetry`)}
            </Button>
          )}
          <Button onClick={onAlertClick} color="inherit" size="small" variant="text" endIcon={<ArrowForwardIosRounded />}>
            {t(`accountSettings.sources.alertButton.${source.status}`)}
          </Button>
        </>
      }
    >
      {t(`accountSettings.sources.alert.${source.status}`)}
    </Alert>
  );
}
