import React from 'react';
import { useSelector } from 'react-redux';
import { reconnectAccountSelector, useAppDispatch, setShowReconnectAccount } from '@focus-front/core';
import ReconnectMail from './ReconnectMail';
import ReconnectLinkedIn from './ReconnectLinkedIn';
import ReconnectMessenger from './ReconnectMessenger';
import ReconnectInstagram from './ReconnectInstagram';
import ReconnectTwitter from './ReconnectTwitter';
import ReconnectTikTok from './ReconnectTikTok';
import ReconnectWhatsApp from './ReconnectWhatsApp';
import ReconnectMicrosoft from './ReconnectMicrosoft';
import ReconnectGoogleCalendar from './ReconnectGoogleCalendar';

export default function Reconnect() {
  const account = useSelector(reconnectAccountSelector);
  const dispatch = useAppDispatch();

  const close = () => dispatch(setShowReconnectAccount(null));

  return (
    <>
      <ReconnectMail
        account_id={account?.id}
        isOpen={account && (account.type === 'MAIL' || account.type === 'GOOGLE' || account.type === 'ICLOUD') ? true : false}
        onClose={close}
      />

      <ReconnectLinkedIn
        account_id={account?.id}
        isOpen={account && account.type === 'LINKEDIN' ? true : false}
        onClose={close}
      />

      <ReconnectMessenger
        account_id={account?.id}
        isOpen={account && account.type === 'MESSENGER' ? true : false}
        onClose={close}
      />
      
      <ReconnectTikTok
        account_id={account?.id}
        isOpen={account && account.type === 'TIKTOK' ? true : false}
        onClose={close}
      />

      <ReconnectInstagram
        account_id={account?.id}
        isOpen={account && account.type === 'INSTAGRAM' ? true : false}
        onClose={close}
      />

    <ReconnectTwitter
        account_id={account?.id}
        isOpen={account && account.type === 'TWITTER' ? true : false}
        onClose={close}
      />

      <ReconnectWhatsApp
        account_id={account?.id}
        isOpen={account && account.type === 'WHATSAPP' ? true : false}
        onClose={close}
      />

      <ReconnectMicrosoft
        account_id={account?.id}
        isOpen={account && account.type === 'OUTLOOK' ? true : false}
        onClose={close}
      />

      <ReconnectGoogleCalendar
        account_id={account?.id}
        isOpen={account && account.type === 'GOOGLE_CALENDAR' ? true : false}
        onClose={close}
      />
    </>
  );
}
