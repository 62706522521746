import { NotificationsState } from './notifications.slice';
import { createSelector } from '@reduxjs/toolkit';
import { intersection } from '../../Common/utils';
import { isLive } from '../domain';
import dayjs from 'dayjs';
import { Uuid } from '../../Common';
import { CalendarEventInstanceId } from '../../Calendar';

/**
 * Root selectors
 */

export const inboxSnackbarsSelector = (state: { notifications: NotificationsState }): NotificationsState['snackbars'] => {
  return state.notifications.snackbars;
};

export const notificationsStatusSelector = (state: { notifications: NotificationsState }): NotificationsState['status'] => {
  return state.notifications.status;
};

export const inboxSortSelector = (state: { notifications: NotificationsState }): NotificationsState['sort'] => {
  return state.notifications.sort;
};

export const inboxAccountFilterSelector = (state: {
  notifications: NotificationsState;
}): NotificationsState['filters']['accounts'] => {
  return state.notifications.filters.accounts;
};

export const inboxTagFilterSelector = (state: { notifications: NotificationsState }): NotificationsState['filters']['tags'] => {
  return state.notifications.filters.tags;
};

export const inboxTypeFilterSelector = (state: { notifications: NotificationsState }): NotificationsState['filters']['types'] => {
  return state.notifications.filters.types;
};

export const notificationsSelector = (state: { notifications: NotificationsState }): NotificationsState['entities'] => {
  return state.notifications.entities;
};

/**
 * Memoized selectors
 */

/**
 * Get the previous and next notification id in the list of all notifications
 * -> Used to navigate between notifications in a Notification Page
 * @param notification_id ID of the notification to get next and previous from
 * @returns Object with the asked ids. Ids can be null if it's the first/last notification in the list.
 */
export const nextAndPreviousNotificationSelector = (notification_id: string) => {
  return createSelector(notificationsSelector, (notifications) => ({
    next_id: notifications[notifications.findIndex((n) => n.id === notification_id) + 1]?.id || null,
    previous_id: notifications[notifications.findIndex((n) => n.id === notification_id) - 1]?.id || null,
  }));
};

/**
 * Get the list of notifications with filters applied
 */
export const filteredNotificationsSelector = createSelector(
  notificationsSelector,
  inboxAccountFilterSelector,
  inboxTypeFilterSelector,
  inboxTagFilterSelector,
  (notifications, accounts, types, tags) => {
    return (
      notifications
        // Live notifications filter
        .filter((n) => isLive(n))
        // Tags filter
        .filter((n) =>
          tags.length > 0
            ? intersection(
                n.tags.map((tag) => tag.id),
                tags
              ).length > 0
            : true
        )
        .filter((n) => (types.length > 0 ? intersection([n.type], types).length > 0 : true))
        .filter((n) =>
          accounts.length > 0
            ? intersection(
                [n.account_id],
                accounts.map((acc) => acc.id)
              ).length > 0
            : true
        )
    );
  }
);

/**
 * Get the list of notifications with filters and sort applied
 * -> Used in the Inbox page
 */
export const inboxNotificationsSelector = createSelector(
  filteredNotificationsSelector,
  inboxSortSelector,
  (notifications, sort) => {
    const sorted = notifications.sort((a, b) =>
      sort === 'DSC'
        ? dayjs(a.received_date).unix() - dayjs(b.received_date).unix()
        : dayjs(b.received_date).unix() - dayjs(a.received_date).unix()
    );
    // Return a copy, because if sort change, it does not detect any change in the array since it's a mutation
    return [...sorted];
  }
);

/**
 * Get the total number of notifications WITHOUT filters
 * -> Used to let the user know how many notifications are hidden by the filters
 */
export const totalNotificationsSelector = createSelector(notificationsSelector, (notifications) => {
  return notifications.filter((n) => isLive(n)).length;
});

/**
 * Get the parent notification of any entity
 * -> Used in search to display links to the notification of any result
 * @param entity_id The entity ID
 * @returns The parent notification if any
 */
export const notificationByAttachedSelector = (entity_id: Uuid | CalendarEventInstanceId) => {
  return createSelector(notificationsSelector, (notifications) => {
    if (!entity_id) return undefined;
    return notifications.find((notification) => notification.metadata?.attached_entity?.id === entity_id);
  });
};

/**
 * Get informations about the selection status of notifications in the inbox
 * -> Used in the Inbox to switch to the "Select mode"
 */
export const notificationsSelectedSelector = createSelector(filteredNotificationsSelector, (notifications) => {
  const number = notifications.filter((n) => n.selected).length;
  return {
    number,
    all: number === notifications.length,
    selectMode: number > 0,
  };
});

/**
 * Get a notification by its ID
 * -> Used to display in Notification Page
 * @param id Notification ID
 * @returns the Notification
 */
export const notificationSelector = (id: string | Uuid) => {
  return createSelector(notificationsSelector, (notifications) => {
    return notifications.find((n) => n.id === id);
  });
};
