import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Notification } from '../../domain';

/**
 * Repository.
 */
export class NotificationRepo extends AggregateRepo<Notification> {
  constructor(eventStore: DomainEventStore) {
    super(Notification, eventStore);
  }
}
