export class CronJobService {
  private crons: { [key: string]: { executing: boolean; cron: any } } = {};

  /**
   * Run the cron job at interval
   * @param name name of the cron
   * @param toExecute Function to execute at interval
   * @param interval Interval in ms
   * @param onError Function to execute if an error is throw by toExecute
   */
  async runCron(name: string, toExecute: () => Promise<void>, interval: number, onError?: () => Promise<void>): Promise<void> {
    if (this.crons[name]?.executing) return;
    this.crons[name] = {
      cron: setInterval(async () => {
        if (this.crons[name].executing) return;

        this.crons[name].executing = true;
        try {
          await toExecute();
        } catch (e) {
          onError && onError();
        }

        this.crons[name].executing = false;
      }, interval),
      executing: true,
    };

    // Execute the job without waiting
    await toExecute();

    // Test if cron still exists, it could be clear in the meanwhile by clearCrons()
    if (this.crons[name]) this.crons[name].executing = false;
  }

  /**
   * Stop the cron job
   */
  stopCron(name: string): void {
    clearInterval(this.crons[name].cron);
    this.crons[name].executing = false;
  }

  clearCrons(): void {
    Object.keys(this.crons).forEach((name) => {
      clearInterval(this.crons[name].cron);
    });
    this.crons = {};
  }
}
