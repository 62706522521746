import { ViewCalendarEvent } from '@focus-front/core';
import { CalendarEventIcon } from '@focus-front/ui';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  event: ViewCalendarEvent;
};

const useClasses = makeStyles((theme) => ({
  root: ({ event }: Props) => ({
    background:
      event.status === 'DONE' || event.status === 'MISSED'
        ? `repeating-linear-gradient(45deg, ${theme.palette.grey[200]}, ${theme.palette.grey[200]} 10px, ${theme.palette.grey[300]} 10px, ${theme.palette.grey[300]} 20px)`
        : theme.palette.secondary.main,
    color:
      event.status === 'DONE' || event.status === 'MISSED' ? theme.palette.text.secondary : theme.palette.secondary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  }),
}));

export default function EventStatusBar({ event }: Props) {
  const { t } = useTranslation();
  const c = useClasses({ event });
  return (
    <div className={c.root}>
      <CalendarEventIcon event={event} size={24} />
      <Typography variant="subtitle1">
        {event.status === 'DONE' && t('event.doneMessage')}
        {event.status === 'PLANNED' && t('event.notWorkMessage')}
        {event.status === 'MISSED' && t('event.missedMessage')}
      </Typography>
    </div>
  );
}
