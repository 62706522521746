import { NewContactDTO } from '../../domain';
import { UnsupportedDevice } from '../../../Common';
import { ContactService } from './ContactService';

export class UnsupportedContactService implements ContactService<any> {
  registerAccount(): void {
    return;
  }

  unregisterAccount(): void {
    return;
  }

  async searchContacts(): Promise<NewContactDTO[]> {
    throw new UnsupportedDevice();
  }
}
