import React from 'react';
import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UnipileCalendarEventInstanceId, Uuid } from '@focus-front/core';
import { useHistory } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { playForwardOutline } from 'ionicons/icons';

const useClasses = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    fontWeight: 500,
  },
}));

export function EventChip({ id }: { id: Uuid | UnipileCalendarEventInstanceId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const c = useClasses();
  return (
    <Tooltip title={t('search.open_left')}>
      <Chip
        size="small"
        label={t('header.work')}
        clickable
        classes={c}
        onClick={(e) => {
          e.stopPropagation();
          history.push('/work/' + id);
        }}
        icon={<IonIcon icon={playForwardOutline} />}
      />
    </Tooltip>
  );
}
