import { LoggerService } from './LoggerService';
import i18n from './i18nextService';
import { UserSession } from '../../../User';
import { Capacitor } from '@capacitor/core';

export class BasicLoggerService implements LoggerService {
  captureException(exception: any): void {
    console.error(Capacitor.getPlatform() === 'ios' ? exception.toString() : exception);
  }

  setSession(session: UserSession | null) {
    console.debug('session set');
  }

  captureMessage(message: string): void {
    console.debug(message);
  }

  captureWarning(message: string): void {
    console.warn(message);
  }

  sendFeedback(): void {
    alert(i18n.t('feedback.unavailable'));
  }
}
