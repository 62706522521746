import {
  bugOutline,
  callOutline,
  cardOutline,
  chatboxEllipsesOutline,
  cogOutline,
  lockClosedOutline,
  downloadOutline,
  linkOutline,
  logOutOutline,
  mailOutline,
  openOutline,
  pencilOutline,
  personCircleOutline,
  rocketOutline,
  settingsOutline,
  documentsOutline,
} from 'ionicons/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  crash,
  DebugRecoveryPageRequestedError,
  envSelector,
  erroredSourcesSelector,
  logoutUser,
  sendFeedback,
  sessionSelector,
  UnexpectedError,
  useAppDispatch,
  openSubUpgrade,
  settingsDialogSelector,
  setSettingsDialogTab,
  Tab,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import {
  Avatar,
  Box,
  Button,
  Container as Cont,
  Dialog,
  Divider,
  Grow,
  List,
  ListItem as Li,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';

import { useAdminLink } from '../Common/useAdminLink';
import AccountListPage from '../Settings/AccountListPage';
import AdvancedSettingsPage from '../Settings/AdvancedSettingsPage';
import UserProfilePage from '../Settings/UserProfilePage';
import SettingsHeader from './SettingsHeader';
import { useTranslation } from 'react-i18next';
import SignaturesPage from './SignaturesPage';
import SecurityPage from './SecurityPage';
import MailSettings from './MailSettings';
import AccountDisconnectedIcon from '../Common/AccountDisconnectedIcon';
import pckg from '../../../../../package.json';
import LogoutButton from './LogoutButton';
import { AlertWeb } from '../Onboarding/DownloadDesktopModal';
import SubscriptionLevelTag from './Subscription/SubscriptionLevelTag';
import SubscriptionsPage from './Subscription/SubscriptionsPage';
import { useStyles } from './useStyles';
import MessageModelsPage from './MessageModelsPage';

const ListItem = withStyles((theme) => ({
  selected: {
    borderRight: `solid 4px ${theme.palette.secondary.main}`,
    backgroundColor: `${theme.palette.grey[100]}!important`,
    // color: theme.palette.secondary.contrastText,
    '& .MuiTypography-root': {
      fontWeight: '500',
    },
  },
  root: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    '& .MuiListItemIcon-root': {
      fontSize: 24,
      color: 'inherit',
    },
  },
}))(Li);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

const Container = withStyles({
  root: {
    display: 'flex',
    height: 'calc(100% - 50px)',
  },
})(Cont);

const useClasses = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
  },
  menu: {
    borderRightColor: theme.palette.divider,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    // backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
    width: 300,
  },
  page: {
    flex: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: 80,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 6,
      position: 'absolute',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 3,
      transition: '.2s ease',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dl: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  alertCont: {
    marginTop: theme.spacing(2),
  },
  ellipsis: {
    maxWidth: '220px', // Not the most elegant solution.
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function SettingsDialog({ open, onClose }: Props) {
  const classes = useClasses();
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { tab } = useSelector(settingsDialogSelector);
  const session = useSelector(sessionSelector);
  const errored_sources = useSelector(erroredSourcesSelector);
  const { onClick } = useAdminLink();
  const { t } = useTranslation();
  const env = useSelector(envSelector);

  const setTab = (tab: Tab) => {
    dispatch(setSettingsDialogTab(tab));
  }

  return (
    // <SettingsContext.Provider value={{ tab, setTab }}>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        classes={{ paper: classes.container }}
        // @ts-ignore Trick to fix Sentry dialog not focusable
        TransitionProps={{ tabIndex: '' }}
      >
        <SettingsHeader handleClose={onClose} />
        <Container maxWidth="lg">
          <div className={classes.menu}>
            <List>
              <ListItem button selected={tab === 'user'} onClick={() => setTab('user')}>
                <ListItemAvatar>
                  <Avatar src={session.profile_picture}></Avatar>
                </ListItemAvatar>
                <ListItemText
                  classes={{ primary: classes.ellipsis }}
                  primary={session.firstname + ' ' + session.lastname}
                  // secondary={<SubscriptionLevelTag offer={session.offer} />}
                />
              </ListItem>
              {/* {session.offer === 'Personal' && (
                <Box p={1}>
                  <Button
                    startIcon={<IonIcon icon={rocketOutline} />}
                    color="secondary"
                    fullWidth
                    size="large"
                    onClick={() => (tab === 'subscription' ? dispatch(openSubUpgrade()) : setTab('subscription'))}
                    classes={{ root: styles.boost }}
                  >
                    {t('subscription.upgradeNow')}
                  </Button>
                </Box>
              )} */}
              <Divider className={classes.divider} />
              {/* <ListItem button selected={tab === 'subscription'} onClick={() => setTab('subscription')}>
                <ListItemIcon>
                  <IonIcon icon={cardOutline} />
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.subscription')} />
              </ListItem> */}
              <ListItem button selected={tab === 'connected_accounts'} onClick={() => setTab('connected_accounts')}>
                <ListItemIcon>
                  <IonIcon icon={linkOutline} />
                  {errored_sources.length > 0 && <AccountDisconnectedIcon title={t('accountSettings.disconnected.warning')} />}
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.connectedAccounts')} />
              </ListItem>
              <ListItem button selected={tab.startsWith('mails')} onClick={() => setTab('mails/signatures')}>
                <ListItemIcon>
                  <IonIcon icon={mailOutline} />
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.mails')} />
              </ListItem>

              <ListItem button selected={tab.startsWith('message_models')} onClick={() => setTab('message_models')}>
                <ListItemIcon>
                  <IonIcon icon={documentsOutline} />
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.message_models')} />
              </ListItem>
              {/* <ListItem button selected={tab.startsWith('ims')} onClick={() => setTab('ims')}>
              <ListItemIcon>
                <IonIcon icon={chatboxEllipsesOutline} />
              </ListItemIcon>
              <ListItemText primary={t('settings.menu.ims')} />
            </ListItem>
            <ListItem button selected={tab.startsWith('calls')} onClick={() => setTab('calls')}>
              <ListItemIcon>
                <IonIcon icon={callOutline} />
              </ListItemIcon>
              <ListItemText primary={t('settings.menu.calls')} />
            </ListItem> */}
              <ListItem button selected={tab === 'security'} onClick={() => setTab('security')}>
                <ListItemIcon>
                  <IonIcon icon={lockClosedOutline} />
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.security')} />
              </ListItem>
              <ListItem button selected={tab === 'advanced'} onClick={() => setTab('advanced')}>
                <ListItemIcon>
                  <IonIcon icon={cogOutline} />
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.advanced')} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem button onClick={onClick}>
                <ListItemIcon>
                  <IonIcon icon={settingsOutline} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      {t('settings.menu.admin')} <IonIcon icon={openOutline} style={{ marginLeft: 10 }} />
                    </Box>
                  }
                />
              </ListItem>

              <ListItem button onClick={() => dispatch(sendFeedback())}>
                <ListItemIcon>
                  <IonIcon icon={bugOutline} />
                </ListItemIcon>
                <ListItemText primary={t('settings.menu.feedback')} />
              </ListItem>

              <Divider className={classes.divider} />
              <LogoutButton />
            </List>
            <div>
              <Box p={1}>
                {env.device_type === 'web' && (
                  <Button
                    onClick={() => window.open('https://www.unipile.com/telechargement-beta/', '_blank')}
                    startIcon={<IonIcon icon={downloadOutline} />}
                    color="primary"
                    fullWidth
                  >
                    {t('onboarding.webWarning.btnDownload')}
                  </Button>
                )}
              </Box>

              <Box mt={2}>
                <Typography variant="body2" align="center">
                  {pckg.version}
                </Typography>
              </Box>
            </div>
          </div>
          <div className={classes.page}>
            {env.device_type === 'web' && (
              <div className={classes.alertCont}>
                <AlertWeb />
              </div>
            )}
            {renderTab(tab as Tab)}
          </div>
        </Container>
      </Dialog>
    // </SettingsContext.Provider>
  );
}

function renderTab(tab: Tab) {
  switch (tab) {
    case 'connected_accounts':
      return <AccountListPage />;
    case 'user':
      return <UserProfilePage />;
    case 'advanced':
      return <AdvancedSettingsPage />;
    case 'subscription':
      return <SubscriptionsPage />;
    case 'security':
      return <SecurityPage />;
    case 'mails/signatures':
      return <MailSettings />;
    case 'message_models':
      return <MessageModelsPage />;
    case 'message_models/new':
      return <MessageModelsPage create={true} />;
    case 'ims':
      return null;
  }
}
