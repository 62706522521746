import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  accountIdentifier,
  accountsListSelector,
  erroredSourcesSelector,
  loadAccounts,
  useAppDispatch,
  ViewAccount,
} from '@focus-front/core';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import { ChevronRightOutlined } from '@material-ui/icons';
import AccountDisconnectedIcon from '../Common/AccountDisconnectedIcon';
import Tags from '../Tag/Tags';
import { AccountTypeIcon } from '@focus-front/ui';

interface Props {
  onClick?: (account: ViewAccount) => void;
}

const useClasses = makeStyles((theme) => ({
  itemCont: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  accIcon: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],
    color: theme.palette.grey[800],
    height: 50,
    width: 50,
    fontSize: 30,
    marginRight: theme.spacing(2),
  },
}));

export default function AccountList({ onClick }: Props) {
  const accounts = useSelector(accountsListSelector);
  const erroredSources = useSelector(erroredSourcesSelector);
  const dispatch = useAppDispatch();
  const c = useClasses();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadAccounts());
  }, []);
  return (
    <List>
      {accounts.map((account) => (
        <Paper variant="outlined" className={c.itemCont} key={account.id}>
          {/* @ts-ignore */}
          <ListItem key={account.id} button={!!onClick} onClick={onClick ? () => onClick(account) : undefined}>
            <ListItemAvatar>
              <AccountTypeIcon account_type={account.type} size={40} />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant="h3">{account.name || accountIdentifier(account.connection_params)}</Typography>}
              secondary={accountIdentifier(account.connection_params)}
            />
            <div style={{ marginRight: 10 }}>
              <Tags tags={account.tags} />
            </div>
            {erroredSources.find((source) => source.account_id === account.id) && (
              <AccountDisconnectedIcon title={t('accountSettings.disconnected.warning')} />
            )}
            <ChevronRightOutlined />
          </ListItem>
        </Paper>
      ))}
    </List>
  );
}
