import { Avatar, AvatarProps, makeStyles } from '@material-ui/core';
import React, { HTMLAttributes, memo } from 'react';
import cn from 'classnames';

type Size = 'tiny' | 'small' | 'default' | 'large';

type Props = {
  display_name: string;
  avatar_url?: string;
  size?: Size;
};

function getHexFromLetter(letter: string): string {
  if (!letter) return '00';
  const hex = ((letter.toLowerCase().charCodeAt(0) - 96) * 10 - 4).toString(16);
  return ('0' + hex).slice(-2);
}

const SIZES = {
  tiny: 30,
  small: 40,
  default: 60,
  large: 120,
};

const FONT_SIZES = {
  tiny: 10,
  small: 14,
  default: 20,
  large: 40,
};

const useClasses = makeStyles((theme) => ({
  avatar: ({ size }: { size: Size }) => ({
    height: SIZES[size],
    width: SIZES[size],
  }),
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

function AttendeeAvatar({ display_name, avatar_url, size = 'default', className, style, ...rest }: Props & AvatarProps) {
  const c = useClasses({ size });

  let r = 'F4';
  let g = 'F4';
  let b = 'F4';

  if (display_name?.length >= 3) {
    r = getHexFromLetter(display_name[0]);
    g = getHexFromLetter(display_name[1]);
    b = getHexFromLetter(display_name[2]);
  }

  return (
    <Avatar
      className={cn(c.avatar, rest.onClick && c.clickable, className)}
      style={{ ...style, backgroundColor: `#${r}${g}${b}` }}
      src={avatar_url}
      alt={display_name}
      title={display_name}
      {...rest}
    >
      {display_name ? display_name.slice(0, 1) : '?'}
    </Avatar>
  );
}

export default memo(AttendeeAvatar);
