import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { accountSourcesSelector, CalendarEventInstanceId, Uuid, ViewCalendarEvent, ViewNotification } from '@focus-front/core';
import { ActionId, AttachedEntityType, EntityContext, useActionBar } from '@focus-front/ui';
import { Fade, makeStyles, Snackbar, Tab, Tabs, Typography, withStyles } from '@material-ui/core';

import CallCallbackAction from './CallCallbackAction';
import CallDone from './CallDoneAction';
import ImChatAction from './ImChatAction';
import ImDone from './ImDoneAction';
import MailDone from './MailDoneAction';
import MailDraftDone from './MailDraftDoneAction';
import MailDraftWrite from './MailDraftWriteAction';
import MailReply from './MailReplyAction';
import MailTransfer from './MailTransferAction';
import PlanAction from './PlanAction';
import RePlanAction from './RePlanAction';
import EventDoneAction from './EventDoneAction';
import { useSelector } from 'react-redux';
import SourceAlert from '../Settings/SourceAlert';

const ActionTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 500,
    height: 50,
    // fontFamily: 'Poppins',
    // letterSpacing: -1,
  },
  selected: {
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  disabled: {
    opacity: '0.3!important',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  labelIcon: {
    minHeight: 0,
    paddingTop: 5,
    '& ion-icon:first-child': {
      fontSize: 24,
      marginBottom: '0!important',
      marginRight: 6,
    },
  },
}))(Tab);

const ActionTabs = withStyles((theme) => ({
  indicator: {
    height: 4,
    top: 0,
    bottom: 'auto',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
}))(Tabs);

const useClasses = makeStyles((theme) => ({
  alert: {
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
  actionBar: {
    position: 'relative',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    paddingBottom: 20,
  },
  snackBar: {
    position: 'absolute',
    top: -20,
    bottom: '100%',
    left: 0,
    right: 0,
    transform: 'none',
  },
  snackBarContent: {
    backgroundColor: theme.palette.success.main,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  },
}));

type Props = {
  context: EntityContext;
  entity_type: AttachedEntityType;
  entity_id: Uuid | CalendarEventInstanceId;
  notification?: ViewNotification;
  event?: ViewCalendarEvent;
  onDone: (action_id: ActionId) => void;
  onMustReload?: () => void;
};

export function ActionsTabBar({ context, entity_type, entity_id, notification, event, onDone, onMustReload }: Props) {
  const { t } = useTranslation();
  const classes = useClasses();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const account_id = notification?.metadata?.attached_entity?.account_id || event?.metadata?.attached_entity?.account_id;
  const account_type = notification?.account_type || event?.metadata?.origin_notification?.account_type;

  const { actions, source } = useActionBar(
    context,
    entity_type,
    notification?.type,
    notification?.metadata?.origin_event?.origin_notification?.type,
    account_type,
    account_id
  );
  const [showSuccess, setShowSuccess] = useState(false);

  const tab = useMemo(() => {
    const action = params.get('action') as ActionId;
    const found = actions.find((a) => a.id === action && !a.disabled);
    return found ? action : null;
  }, [params, actions]);

  const handleDone = (action_id: ActionId) => {
    setShowSuccess(true);
    // If action in Reply or Transfer, close the action modal
    if (action_id === 'reply' || action_id === 'transfer') history.push({ search: '' });
    onDone(action_id);
  };

  const actionProps = {
    onDone: handleDone,
    context: event || notification,
  };

  useEffect(() => {
    if (entity_type === 'IM_THREAD' && !!actions.find((action) => action['id'] === 'chat' && !action.disabled)) {
      history.push({ search: '?action=chat' });
    }
  }, [entity_id, entity_type]);

  return (
    <>
      {tab === 'done' && (
        <>
          {entity_type === 'MAIL' && <MailDone {...actionProps} />}
          {entity_type === 'MAIL_DRAFT' && <MailDraftDone {...actionProps} />}
          {entity_type === 'CALL' && <CallDone {...actionProps} />}
          {entity_type === 'IM_THREAD' && <ImDone {...actionProps} />}
          {(!entity_type || entity_type === 'EVENT') && <EventDoneAction {...actionProps} />}
        </>
      )}
      {tab === 'plan' && <PlanAction {...actionProps} notification={notification} />}
      {tab === 'replan' && <RePlanAction {...actionProps} event_id={event ? event.id : notification.metadata.origin_event.id} />}
      {tab === 'reply' && <MailReply {...actionProps} />}
      {tab === 'transfer' && <MailTransfer {...actionProps} />}
      {tab === 'write' && <MailDraftWrite onClose={onMustReload} {...actionProps} />}
      {tab === 'chat' && <ImChatAction {...actionProps} />}
      {tab === 'callback' && <CallCallbackAction {...actionProps} />}

      {source && <SourceAlert className={classes.alert} source={source} account_type={account_type} />}
      <div className={classes.actionBar}>
        <Snackbar
          open={showSuccess}
          autoHideDuration={1500}
          onClose={() => setShowSuccess(false)}
          className={classes.snackBar}
          TransitionComponent={Fade}
        >
          <div className={classes.snackBarContent}>
            <Typography variant="subtitle1">{t(context.toLowerCase() + '.doneMessage')}</Typography>
          </div>
        </Snackbar>

        <ActionTabs value={tab || false} variant="fullWidth" indicatorColor="secondary">
          {actions.map((action) => (
            <ActionTab
              key={action.id}
              label={action.label}
              icon={action.icon}
              value={action.id}
              disabled={action.disabled}
              onClick={() => history.push({ search: '?action=' + action.id })}
            />
          ))}
        </ActionTabs>
      </div>
    </>
  );
}

export default React.memo(ActionsTabBar);
