import { DomainEventBase, DomainEventShape, Uuid } from '../../../Common';
import { NewImDTO } from '../projections/ViewIm';

/**
 * DomainEvent.
 */
export class ImSubmitted implements DomainEventShape {
  readonly type = 'IM_SUBMITTED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly im: NewImDTO, readonly thread_id: Uuid, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ImSentSuccess implements DomainEventShape {
  readonly type = 'IM_SENT_SUCCESS';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ImSentError implements DomainEventShape {
  readonly type = 'IM_SENT_ERROR';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ImSending implements DomainEventShape {
  readonly type = 'IM_SENDING';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ImSynced implements DomainEventShape {
  readonly type = 'IM_SYNCED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly im: NewImDTO, readonly thread_id: Uuid, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent
 */
export class ImReplaced extends DomainEventBase {
  readonly type = 'IM_REPLACED';
  readonly schema = 1;
  constructor(aggregateId: Uuid, version: number) {
    super(aggregateId, version);
  }
}

/**
 * Collect and export all event definitions.
 */
export type ImDomainEvent = ImSubmitted | ImSentError | ImSynced | ImSentSuccess | ImSending | ImReplaced;
