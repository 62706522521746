import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { listOutline, locationOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  NewCalendarEventDTO,
  toUTCDateTimeMs,
  Uuid,
  deleteCalendarEvent,
  useAppDispatch,
  eventDraftUpdate,
  Strip,
  CalendarEventInstanceId,
  Tzid,
  deleteRecurringCalendarEvent,
  PatchCalendarEventDTO,
  prettyPrint,
  toUTCDate,
  DEFAULT_EVENT_INTERVAL,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Button, Checkbox, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useDebounce } from 'react-use';
import { useConfirm } from 'material-ui-confirm';
import EventRecurrentMultiSelect from './EventRecurrentSelect';
import { RecurringCalendarEventRootStrip } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import AttendeesField from '../Mail/AttendeesField';
import {
  areDatesDirty,
  EventDefaultValues,
  EventFormInputs,
  parseAttendees,
  parseSelectValue,
  parseTimeInDates,
} from '@focus-front/ui';
import EventFormPickers from './EventFormPickers';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
interface EventFormProps {
  /**
   * Values to pre-fill the form
   */
  defaultValues: EventDefaultValues;

  /**
   * Callback when form is valid and submitted
   */
  onSubmit: (form: NewCalendarEventDTO | PatchCalendarEventDTO) => void;

  /**
   * Callback when draft is not empty (used to show confirm discard on close)
   */
  isDirty?: (dirty: boolean) => void;

  editedEventId?: Uuid | CalendarEventInstanceId;
  recurringEventRoot: RecurringCalendarEventRootStrip | null;
  replan?: boolean;
}

const useClasses = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    marginBottom: theme.spacing(3),
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  eventTypeContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& ion-icon': {
      marginRight: theme.spacing(1),
      fontSize: 20,
    },
  },
  location: {
    marginBottom: theme.spacing(1),
  },
  del: {
    marginRight: 10,
  },
}));

/**
 * Setup a form with given values data if any.
 * Provide defaults if none or incomplete values is given.
 *
 * @note Greg : areDatesDirty() relies on the difference between defaultValues and
 *              input values.
 *
 *              This doesn't work with the way ReplanAction sets up the eventDraft !!
 *
 *              Because it presets the eventDraft start/end to some new values,
 *              they NEVER appear dirty and are NOT saved in a 'replan' patch !
 *
 *              As a bandaid, pending a refactor, an optional 'replan' prop is added to
 *              signal this mode of operation.
 */
export const EventForm: React.FC<EventFormProps> = ({
  defaultValues,
  onSubmit,
  isDirty,
  editedEventId,
  recurringEventRoot,
  replan = false,
}) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  /**
   * Get the initial title to prefill the form
   * If the title is the default title (Sans Titre) [FR], the field must appear empty to please the user
   */
  //   function getInitialTitle(): string {
  //     if (defaultValues?.summary) {
  //       if (defaultValues.summary.toLowerCase() === t('calendar.form.defaultTitle').toLowerCase()) return '';
  //       return defaultValues.summary;
  //     }
  //     return '';
  //   }

  const { control, register, setValue, handleSubmit, getValues, watch, formState } = useForm<EventFormInputs>({
    mode: 'onChange',
    defaultValues: {
      isDaily: defaultValues.all_day,
      type: defaultValues.type,
      title: defaultValues.summary ?? '',
      description: defaultValues.description || '',
      location: defaultValues.location || '',
      recurrency: defaultValues.recurrency || {
        kind: defaultValues.kind,
        ...(recurringEventRoot?.kind === 'RECURRING' &&
          defaultValues.kind !== 'SINGLE' && { recurrence: recurringEventRoot.recurrence }),
        selectValue: defaultValues.kind === 'SINGLE' ? 'SINGLE' : parseSelectValue(recurringEventRoot),
      },
      attendees: defaultValues.attendees || [],
      fromDate: dayjs(defaultValues.start),
      fromTime: dayjs(defaultValues.start).format('HH:mm'),
      toDate: defaultValues.all_day ? dayjs(defaultValues.end).subtract(1, 'day') : dayjs(defaultValues.end),
      toTime: dayjs(defaultValues.end).format('HH:mm'),
    },
  });

  /**
   * This function is called to both send the from data when creating an event, but also to create the event draft.
   * The event draft is used in the UI to show the event in the calendar.
   */
  function mapInputsToCreateEvent(inputs: EventFormInputs): Strip<NewCalendarEventDTO, 'calendar_id' | 'account_id'> {
    const { isDaily, toTime, fromTime, toDate } = inputs;
    const { parsedFromDate, parsedToDate } = parseTimeInDates(
      {
        ...inputs,
        toTime: isDaily ? '00:00' : toTime,
        fromTime: isDaily ? '00:00' : fromTime,
        toDate: isDaily ? toDate.add(1, 'day') : toDate,
      },
      null
    );

    return {
      type: inputs.type,
      ...(inputs.recurrency.kind === 'RECURRING'
        ? { kind: 'RECURRING', recurrence: inputs.recurrency.recurrence }
        : { kind: 'SINGLE' }),
      summary: inputs.title ?? '',
      description: inputs.description,
      location: inputs.location,
      attendees: inputs.type === 'GOOGLE' ? parseAttendees(inputs.attendees) : inputs.attendees,
      start: toUTCDateTimeMs(parsedFromDate),
      end: toUTCDateTimeMs(parsedToDate),
      ...(inputs.isDaily
        ? {
            all_day: true,
            start_tzid: null,
            end_tzid: null,
          }
        : {
            all_day: false,
            /**  @todo Handle timezones ! At the very least they should default to the calendar default_tzid. */
            start_tzid: 'Europe/Paris' as Tzid,
            end_tzid: 'Europe/Paris' as Tzid,
          }),
    };
  }

  function mapInputsToPatchEvent(inputs: EventFormInputs): PatchCalendarEventDTO {
    const { isDaily, toTime, fromTime, toDate } = inputs;
    const { parsedFromDate, parsedToDate, recurrentParsedFromDate, recurrentParsedToDate } = parseTimeInDates(
      {
        ...inputs,
        toTime: isDaily ? '00:00' : toTime,
        fromTime: isDaily ? '00:00' : fromTime,
        toDate: isDaily ? toDate.add(1, 'day') : toDate,
      },
      recurringEventRoot
    );

    const dirtyInputs = {
      ...(formState.dirtyFields.title && { summary: inputs.title }),
      ...(formState.dirtyFields.description && { description: inputs.description }),
      ...(formState.dirtyFields.location && { location: inputs.location }),
      ...(formState.dirtyFields.attendees && {
        attendees: inputs.type === 'GOOGLE' ? parseAttendees(inputs.attendees) : inputs.attendees,
      }),
    };

    const isRecurringToSingle: boolean = recurringEventRoot?.id && inputs.recurrency.kind === 'SINGLE';
    const isRecurring: boolean = inputs.recurrency.kind === 'RECURRING';

    const isAllDayDirty = defaultValues.all_day !== inputs.isDaily;
    const dirtyDates = replan || areDatesDirty(defaultValues, inputs) || isAllDayDirty;

    return {
      type: inputs.type,
      ...(isRecurring ? { kind: 'RECURRING', recurrence: inputs.recurrency.recurrence } : { kind: 'SINGLE' }),
      ...((dirtyDates || isRecurringToSingle) && {
        start: toUTCDateTimeMs(isRecurring ? recurrentParsedFromDate : parsedFromDate),
        end: toUTCDateTimeMs(isRecurring ? recurrentParsedToDate : parsedToDate),
        ...(inputs.isDaily
          ? {
              all_day: true,
              start_tzid: null,
              end_tzid: null,
            }
          : {
              all_day: false,
              /**  @todo Handle timezones ! At the very least they should default to the calendar default_tzid. */
              start_tzid: 'Europe/Paris' as Tzid,
              end_tzid: 'Europe/Paris' as Tzid,
            }),
      }),
      ...dirtyInputs,
    };
  }

  const confirmSubmit = async (data: EventFormInputs) => {
    const dirtyDates = replan || areDatesDirty(defaultValues, data);

    if (data.recurrency.kind !== 'SINGLE' && data.recurrency.kind !== 'RECURRING' && !dirtyDates) {
      let deleteRadioButtonValue = 'SINGLE';
      await confirm({
        title: t('calendar.form.editAlertRecurrent'),
        description: (
          <FormControl>
            <RadioGroup onChange={(e) => (deleteRadioButtonValue = e.target.value)} defaultValue="SINGLE">
              <FormControlLabel value="SINGLE" control={<Radio />} label={t('calendar.form.confirmAlertSingleOption')} />
              <FormControlLabel value="ALL" control={<Radio />} label={t('calendar.form.confirmAlertAllOption')} />
            </RadioGroup>
          </FormControl>
        ),
        confirmationText: t('calendar.form.saveButton'),
        cancellationText: t('calendar.form.cancelButton'),
      });
      if (deleteRadioButtonValue === 'ALL') {
        data.recurrency.kind = 'RECURRING';
      }
    }
    onSubmit({ ...mapInputsToPatchEvent(data), calendar_id: defaultValues.calendar_id });
  };

  /**
   * This updates the draft (orange) event in the UI.
   */
  const watchAll = watch();

  useDebounce(
    () => {
      const { fromDate } = getValues();
      if (fromDate) dispatch(eventDraftUpdate(mapInputsToCreateEvent(getValues())));
    },
    500,
    [watchAll]
  );

  // ------------------------------------------------

  const handleDeleteSingle = async () => {
    await confirm(t('calendar.form.deleteAlert'));
    const error = await dispatch(deleteCalendarEvent(editedEventId));
    if (error) alert(error);
  };

  const handleDeleteInstance = async () => {
    let deleteRadioButtonValue = 'SINGLE';
    await confirm({
      title: t('calendar.form.deleteAlertRecurrent'),
      description: (
        <FormControl>
          <RadioGroup onChange={(e) => (deleteRadioButtonValue = e.target.value)} defaultValue="SINGLE">
            <FormControlLabel value="SINGLE" control={<Radio />} label={t('calendar.form.confirmAlertSingleOption')} />
            <FormControlLabel value="ALL" control={<Radio />} label={t('calendar.form.confirmAlertAllOption')} />
          </RadioGroup>
        </FormControl>
      ),
      confirmationText: t('calendar.form.deleteButton'),
      cancellationText: t('calendar.form.cancelButton'),
    });
    const error = await dispatch(
      deleteRadioButtonValue === 'SINGLE'
        ? deleteCalendarEvent(editedEventId)
        : deleteRecurringCalendarEvent(defaultValues?.recurring_event_id)
    );
    if (error) alert(error);
  };

  const handleIsDailyChange = (isDaily: boolean, onChange) => {
    onChange(!isDaily);
    const { toTime, fromTime } = getValues();
    // This avoids having equal fromDate/toDate while editing all_day to !all_day.
    if (toTime === '00:00' && fromTime === '00:00' && isDaily) {
      const now = dayjs();
      setValue('fromTime', now.format('HH:mm'));
      setValue('toTime', now.add(DEFAULT_EVENT_INTERVAL, 'minutes').format('HH:mm'));
    }
  };

  return (
    <>
      {/**
       * @note Greg : It seems that typing form data with something like useForm<EventFormInputs> can be very
       *              misleading. Even if a default value is given, if no associated input is registered,
       *              a prop won't be defined at all in getValues() result despite what the return type says.
       *
       *              Until someone figures out another way with react-hook-form  to work around this issue or
       *              we type more appropriately useForm<EventFormInputs>, we're going to use hidden
       *              input fields.
       */}
      <input name="type" type="hidden" ref={register} />
      <TextField
        id="title"
        name="title"
        fullWidth
        variant="standard"
        size="medium"
        InputProps={{
          className: classes.title,
        }}
        onKeyUp={handleSubmit((data, event) => {
          // the key parameter actually exists, but typescript doesn't recognize it.
          /* @ts-ignore */
          if (event.key === 'Enter') {
            editedEventId
              ? confirmSubmit(data)
              : onSubmit({ ...mapInputsToCreateEvent(data), calendar_id: defaultValues.calendar_id });
          }
        })}
        inputRef={register}
        autoFocus
        placeholder={t('calendar.form.titlePlaceholder')}
        autoComplete="off"
      />
      <EventFormPickers defaultValues={defaultValues} formMethods={{ getValues, setValue, register }} />
      <div className={classes.eventTypeContainer}>
        <Controller
          control={control}
          name="isDaily"
          render={({ value, onChange }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={() => handleIsDailyChange(value, onChange)} />}
              label={t('calendar.form.allDay')}
            />
          )}
        />

        <Controller
          control={control}
          name="recurrency"
          render={({ value, onChange }) => (
            <EventRecurrentMultiSelect
              value={value}
              onChange={onChange}
              recurringEventRoot={recurringEventRoot}
              defaultValues={defaultValues}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="attendees"
        render={({ onChange, value }) => <AttendeesField value={value} onChange={onChange} isEventForm={true} />}
      />
      <TextField
        id="location"
        name="location"
        inputRef={register}
        fullWidth
        placeholder={t('calendar.form.locationPlaceholder')}
        InputProps={{
          className: cn(classes.field, classes.location),
          startAdornment: <IonIcon icon={locationOutline} />,
        }}
        autoComplete="off"
      />
      <TextField
        id="description"
        name="description"
        inputRef={register}
        multiline
        minRows={1}
        maxRows={10}
        fullWidth
        placeholder={t('calendar.form.descriptionPlaceholder')}
        InputProps={{
          className: classes.field,
          startAdornment: <IonIcon icon={listOutline} />,
        }}
        autoComplete="off"
      />
      <div className={classes.btnContainer}>
        {editedEventId && (
          <Button className={classes.del} onClick={defaultValues.kind === 'SINGLE' ? handleDeleteSingle : handleDeleteInstance}>
            {t('calendar.form.deleteButton')}
          </Button>
        )}
        <Button
          color="secondary"
          disabled={!formState.isDirty && !!editedEventId && !(replan || areDatesDirty(defaultValues, getValues()))}
          onClick={
            /**
             * @todo Fix the names ! handleSubmit, confirmSubmit, onSubmit.
             */
            handleSubmit((data) => {
              editedEventId
                ? confirmSubmit(data)
                : onSubmit({ ...mapInputsToCreateEvent(data), calendar_id: defaultValues.calendar_id });
            })
          }
        >
          {t('calendar.form.saveButton')}
        </Button>
      </div>
    </>
  );
};
