import {
  CalendarServicesMap,
  CallServicesMap,
  ContactServicesMap,
  ImServicesMap,
  MailServicesMap,
  OAuthServicesMap,
  ScrapAppPasswordServicesMap,
  ScrapFormAuthServicesMap,
  ScrapQRCodeAuthServicesMap,
} from '../../_container/interfaces/Container';
import { DomainEvent, LoggerService, mockUuid, Publisher, Result, toUTCDateTimeMs, Uuid } from '../../Common';
import { deepEqual, prettyPrint } from '../../Common/utils';
import i18n from '../../Common/infra/services/i18nextService';
import {
  Account,
  GoogleConnectionParams,
  GoogleCalendarConnectionParams,
  GoogleCredentials,
  ImapConnectionParams,
  isCallSupportedAccount,
  // isContactSupportedAccount,
  isImSupportedAccount,
  isCalendarSupportedAccount,
  isMailSupportedAccount,
  isWhatsAppAccount,
  LinkedInConnectionParams,
  LinkedInCredentials,
  MessengerConnectionParams,
  MessengerCredentials,
  TikTokConnectionParams,
  TikTokCredentials,
  TwitterConnectionParams,
  TwitterCredentials,
  InstagramConnectionParams,
  InstagramCredentials,
  MailConnectionParams,
  MailCredentials,
  MicrosoftAccountInfo,
  MobileConnectionParams,
  OutlookConnectionParams,
  OutlookCredentials,
  Signature,
  UpdateSignatureDTO,
  ViewAccount,
  ViewAccountRepo,
  ViewSignature,
  ViewSignatureRepo,
  WhatsAppConnectionParams,
  WhatsAppCredentials,
  OAuthCredentials,
  AccountType,
  AccountSource,
  isEnvSupportedAccount,
  AccountSourceStatus,
  GoogleCalendarCredentials,
  isGoogleCalendarCredentials,
  ProxyConnectionParams,
  isLinkedInAccount,
  isMessengerAccount,
  isTikTokAccount,
  isInstagramAccount,
  isTwitterAccount,
  WebViewAppPasswordSupportedAccounts,
  ICloudConnectionParams,
  ICloudCredentials,
} from '../domain';
import { AccountRepo } from '../infra/repository/AccountRepo';
import { SignatureRepo } from '../infra/repository/SignatureRepo';
import { MicrosoftOAuthReturn } from '../infra/services/OAuthService';
import { AccountUseCase } from './AccountUseCase';
import dayjs from 'dayjs';
import { EncryptedCredentialsRepo } from '../domain/projections/EncryptedCredentialsRepo';
import { Crypto, EncryptionCryptoKey } from '../../Common/infra/services/crypto/Crypto';
import { Environment } from '../../_container/interfaces/Environment';
import { isObject } from '../../Common/utils';
import { GoogleOAuthReturn } from '../infra/services/OAuthService';
import { SearchUseCase } from '../../Search';
import { PermissionsService } from '../../Common/infra/services/PermissionsService';
import { InvalidCredentials } from '../infra/services/ScrapFormAuthService';
import { MissingOAuthScopesError } from '../../Common/infra/services/google/GoogleAuthenticationProvider';
import { MailSyncService } from '../../Mail/infra/services/MailSyncService';
import { Capacitor } from '@capacitor/core';
import { Taggable } from '../../Tag';
import { ScrapingNetworkError } from '../../Common/infra/services/scraper/ScraperService';
import { ICloudDisabledMailError } from '../infra/services/iCloud/iCloudAppPasswordService';

export class AppAccountUseCase implements AccountUseCase {
  readonly ERROR_UNEXPECTED = i18n.t('accountSettings.error.unexpected');
  readonly ERROR_UNSUPPORTED_DELETION = i18n.t('accountSettings.error.unsupportedDeletion');
  readonly ERROR_ALREADY_ADDED = i18n.t('accountSettings.error.alreadyAdded');
  readonly ERROR_CONNEXION_FAIL = i18n.t('accountSettings.error.connectionFailed');
  readonly ERROR_WRONG_GOOGLE_ACC = i18n.t('accountSettings.error.wrongGoogle');
  readonly ERROR_MISSING_OAUTH_SCOPES = i18n.t('accountSettings.error.missingOAuthScopes');
  readonly ERROR_SIGNATURE_NAME_ALREADY_EXIST = i18n.t('signatureSettings.new.errorAlreadyExist');

  TMP_ACCOUNT_ID = mockUuid('__TMP__');

  /**
   *
   */
  constructor(
    private readonly publisher: Publisher,
    private readonly account: AccountRepo,
    private readonly signature: SignatureRepo,
    private readonly viewAccount: ViewAccountRepo,
    private readonly viewSignature: ViewSignatureRepo,
    private readonly credentials: EncryptedCredentialsRepo,
    private readonly crypto: Crypto,
    private readonly mailServices: MailServicesMap,
    private readonly imServices: ImServicesMap,
    private readonly callServices: CallServicesMap,
    private readonly contactServices: ContactServicesMap,
    private readonly calendarServices: CalendarServicesMap,
    private readonly oauthServices: OAuthServicesMap,
    private readonly scrapFormAuthServices: ScrapFormAuthServicesMap,
    private readonly scrapQRCodeAuthServices: ScrapQRCodeAuthServicesMap,
    private readonly scrapAppPasswordServices: ScrapAppPasswordServicesMap,
    private readonly searchUc: SearchUseCase,
    private readonly logger: LoggerService,
    private readonly env: Environment,
    private readonly mailSync: MailSyncService,
    private readonly permissions?: PermissionsService
  ) {}

  async getAll(): Promise<Result<(ViewAccount & Taggable)[]>> {
    try {
      const accounts = await this.viewAccount.getAllWithTags();

      return {
        result: accounts,
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  async initAllAccountsSources(credentialsKey: EncryptionCryptoKey): Promise<Result<AccountSource[]>> {
    try {
      const accounts = await this.viewAccount.getAll();
      let result: AccountSource[] = [];
      await Promise.all(
        accounts.map(async (account) => {
          const sources = await this._registerAccountSources(account, credentialsKey, 'IDLE');
          result = [...result, ...sources];
          return;
        })
      );

      // When we have all sources and the status they should have without limitations, disable to take limitations in consideration

      /**
       * Disable sources exceeding the device limit
       * On mobile, only one account of each scrapped account type can be synced
       * Arbitrary chose the first one and disable the others.
       */
      if (this.env.device_type === 'mobile') {
        const messenger_to_disable = accounts
          .filter(isMessengerAccount)
          .slice(1)
          .map((acc) => acc.id);

        const tiktok_to_disable = accounts
          .filter(isTikTokAccount)
          .slice(1)
          .map((acc) => acc.id);
        const twitter_to_disable = accounts
          .filter(isTwitterAccount)
          .slice(1)
          .map((acc) => acc.id);
        const instagram_to_disable = accounts
          .filter(isInstagramAccount)
          .slice(1)
          .map((acc) => acc.id);
        const linkedin_to_disable = accounts
          .filter(isLinkedInAccount)
          .slice(1)
          .map((acc) => acc.id);

        // LinkedIn accounts that have a proxy setup must be set as unsupported on Mobile
        const with_proxy_to_disable = accounts
          .filter(isLinkedInAccount)
          .filter((acc) => acc.connection_params.im.proxy)
          .map((acc) => acc.id);

        result.forEach((source, index) => {
          if (
            linkedin_to_disable.includes(source.account_id) ||
            messenger_to_disable.includes(source.account_id) ||
            tiktok_to_disable.includes(source.account_id) ||
            instagram_to_disable.includes(source.account_id) ||
            twitter_to_disable.includes(source.account_id)
          ) {
            result[index].status = 'DISABLED_DEVICE_LIMIT';
          }
          if (with_proxy_to_disable.includes(source.account_id)) {
            result[index].status = 'UNSUPPORTED';
          }
        });
      }

      /**
       * Disable sources exceeding the subscription level limit
       * On personal, only X mails and X messaging accounts can be synced
       * Arbitrary chose the first ones and disable the others.
       */

      /**
       * @todo Get the subscription level an set DISABLE_USER_LIMIT to sources
       */

      return {
        result,
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   * Register the account in services needed for each source activated
   * The presence of connection_params for a source type determine if the source has been activated by the user when adding the account
   * @param acc Account
   * @param credentialsKey User key needed for credentials decryption
   * @returns the sources of the account with its status
   */
  private async _registerAccountSources(
    acc: ViewAccount,
    credentialsKey: EncryptionCryptoKey,
    default_status: AccountSourceStatus = 'IDLE'
  ): Promise<AccountSource[]> {
    const sources: AccountSource[] = [];

    if (isMailSupportedAccount(acc)) {
      const source: AccountSource = { account_id: acc.id, type: 'MAILS', status: 'NOT_SETUP' };
      if (isEnvSupportedAccount(acc, this.env, 'MAILS')) {
        if (acc.connection_params.mail) {
          await this.mailServices[acc.type]?.registerAccount(acc.id, acc.connection_params.mail, credentialsKey);
          source.status = default_status;
        }
      } else {
        source.status = 'UNSUPPORTED';
      }
      sources.push(source);
    }

    if (isCalendarSupportedAccount(acc)) {
      const source: AccountSource = { account_id: acc.id, type: 'CALENDAR', status: 'NOT_SETUP' };
      if (isEnvSupportedAccount(acc, this.env, 'CALENDAR')) {
        if (acc.connection_params.calendar) {
          await this.calendarServices[acc.type]?.registerAccount(acc.id, acc.connection_params.calendar, credentialsKey);
          source.status = default_status;
        }
      } else {
        source.status = 'UNSUPPORTED';
      }
      sources.push(source);
    }

    if (isImSupportedAccount(acc)) {
      const source: AccountSource = { account_id: acc.id, type: 'IMS', status: 'NOT_SETUP' };
      if (isEnvSupportedAccount(acc, this.env, 'IMS')) {
        if (acc.connection_params.im) {
          console.log(acc.connection_params);
          await this.imServices[acc.type]?.registerAccount(acc.id, acc.connection_params.im, credentialsKey);
          await this.contactServices[acc.type]?.registerAccount(acc.id, acc.connection_params.im, credentialsKey);
          if (acc.type === 'MOBILE' && this.permissions && !(await this.permissions.hasPermissions('SMS'))) {
            source.status = 'PERMISSIONS';
          } else source.status = default_status;
        }
      } else {
        source.status = 'UNSUPPORTED';
      }
      sources.push(source);
    }

    if (isCallSupportedAccount(acc)) {
      const source: AccountSource = { account_id: acc.id, type: 'CALLS', status: 'NOT_SETUP' };
      if (isEnvSupportedAccount(acc, this.env, 'IMS')) {
        if (acc.connection_params.call) {
          await this.callServices[acc.type]?.registerAccount(acc.id, acc.connection_params.call, credentialsKey);
          if (acc.type === 'MOBILE' && this.permissions && !(await this.permissions.hasPermissions('CALL_LOG'))) {
            source.status = 'PERMISSIONS';
          } else source.status = default_status;
        }
      } else {
        source.status = 'UNSUPPORTED';
      }
      sources.push(source);
    }

    return sources;
  }

  async unregisterAllAccountSources(): Promise<void> {
    const accounts = await this.viewAccount.getAll();
    await Promise.all(accounts.map(async (account) => this._unregisterAccountSources(account)));
    return;
  }

  private async _unregisterAccountSources(account: ViewAccount): Promise<void> {
    if (isMailSupportedAccount(account)) {
      await this.mailSync.cancelFullFetch(account.id);
      await this.mailServices[account.type]?.unregisterAccount(account.id);
    }
    if (isCalendarSupportedAccount(account)) await this.calendarServices[account.type]?.unregisterAccount(account.id);
    if (isImSupportedAccount(account)) await this.imServices[account.type]?.unregisterAccount(account.id);
    if (isCallSupportedAccount(account)) await this.callServices[account.type]?.unregisterAccount(account.id);

    /**
     * @note TO REMOVE when the session can be injected, this will not be necessary as the webview clean itself when destroyed
     */
    if (account?.type === 'WHATSAPP') {
      await this.scrapQRCodeAuthServices[account.type].logoutDefinitely(account.id);
    }
  }

  /**
   *
   */
  async get(account_id: Uuid): Promise<Result<(ViewAccount & Taggable) | null>> {
    try {
      const result = await this.viewAccount.get(account_id);
      return {
        result,
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   * Test if an account can be added based on different rules
   * - Is the account type supported on the device
   * - Is the subscription level high enough for multiple account
   * - Is multiple account technically possible
   * @param account_type The type of the account added
   * @returns {
   *    success: True if the account can be added
   *    reason: Set if success if false :
   *      - UNSUPPORTED : The device does not support this type of account
   *      - MAX : The technical maximum amount of accounts added is reached
   *      - MAX_SUB : The subscription maximum amount of accounts added is reached
   *    platforms: Supported platform if reason is UNSUPPORTED
   * }
   */
  async canAccountBeAdded(account_type: AccountType): Promise<
    Result<{
      success: boolean;
      reason?: 'UNSUPPORTED' | 'MAX' | 'MAX_SUB';
      platforms?: ('ANDROID' | 'IOS' | 'DESKTOP' | 'WEB')[];
    }>
  > {
    try {
      let accounts;
      switch (account_type) {
        case 'GOOGLE':
        case 'MAIL':
        case 'OUTLOOK':
          break;
        case 'GOOGLE_CALENDAR':
          if (this.env.device_type === 'mobile') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['WEB', 'DESKTOP'] } };
          }
          break;
        case 'LINKEDIN':
          if (this.env.device_type !== 'desktop') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['DESKTOP'] } };
          }
          break;
        case 'MESSENGER':
          if (this.env.device_type !== 'desktop') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['DESKTOP'] } };
          }
          break;
        case 'TIKTOK':
          if (this.env.device_type !== 'desktop') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['DESKTOP'] } };
          }
          break;
        case 'TWITTER':
          if (this.env.device_type !== 'desktop') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['DESKTOP'] } };
          }
          break;
        case 'INSTAGRAM':
          if (this.env.device_type !== 'desktop') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['DESKTOP'] } };
          }
          break;
        case 'WHATSAPP':
          if (this.env.device_type !== 'desktop') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['DESKTOP'] } };
          }
          accounts = await this.viewAccount.getByType('WHATSAPP');
          if (accounts.length > 0) {
            return { result: { success: false, reason: 'MAX' } };
          }
          break;
        case 'MOBILE':
          if (Capacitor.getPlatform() !== 'android') {
            return { result: { success: false, reason: 'UNSUPPORTED', platforms: ['ANDROID'] } };
          }
          accounts = await this.viewAccount.getByType('MOBILE');
          if (accounts.length > 0) {
            return { result: { success: false, reason: 'MAX' } };
          }
          break;
      }
      return { result: { success: true } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async addMail(
    account_name: string,
    credentials: MailCredentials,
    connection_params: MailConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => deepEqual(acc.connection_params, connection_params));

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'MAIL',
        full_fetch_progress: [],
        full_fetch_status: 'IDLE',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      // Assign a default signature
      const signatures = await this.viewSignature.getAll();
      if (signatures.length > 0) {
        const resultSign = aggregate.selectSignature(signatures[0].id);
        await this.publisher.emit(resultSign.changes);
      }

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'MAIL') throw new Error('Retrieved account is not a Mail account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * It's similar to addMailAccount for now because it only includes GMAIL, but will be more complexe in the future
   */
  async addGoogle(
    account_name: string,
    credentials: GoogleCredentials,
    connection_params: GoogleConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => deepEqual(acc.connection_params, connection_params));

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'GOOGLE',
        full_fetch_progress: [],
        full_fetch_status: 'IDLE',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      // Assign a default signature
      const signatures = await this.viewSignature.getAll();
      if (signatures.length > 0) {
        const resultSign = aggregate.selectSignature(signatures[0].id);
        await this.publisher.emit(resultSign.changes);
      }

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'GOOGLE') throw new Error('Retrieved account is not a GOOGLE account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * It's similar to addMailAccount for now because it only includes Mails, but will be more complexe in the future
   */
  async addICloud(
    account_name: string,
    credentials: ICloudCredentials,
    connection_params: ICloudConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => deepEqual(acc.connection_params, connection_params));

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'ICLOUD',
        full_fetch_progress: [],
        full_fetch_status: 'IDLE',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      // Assign a default signature
      const signatures = await this.viewSignature.getAll();
      if (signatures.length > 0) {
        const resultSign = aggregate.selectSignature(signatures[0].id);
        await this.publisher.emit(resultSign.changes);
      }

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'ICLOUD') throw new Error('Retrieved account is not a ICLOUD account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async addGoogleCalendar(
    account_name: string,
    credentials: OAuthCredentials,
    connection_params: GoogleCalendarConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => deepEqual(acc.connection_params, connection_params));

      if (found) {
        return { error: this.ERROR_ALREADY_ADDED };
      }

      const { aggregate, ...resultCreate } = Account.create({
        connection_params: connection_params,
        name: account_name,
        type: 'GOOGLE_CALENDAR',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      const cred: GoogleCalendarCredentials = {
        type: 'GOOGLE_CALENDAR',
        devices: {
          [this.env.device_type]: credentials,
        },
      };
      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(cred)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      // Assign a default signature
      const signatures = await this.viewSignature.getAll();
      if (signatures.length > 0) {
        const resultSign = aggregate.selectSignature(signatures[0].id);
        await this.publisher.emit(resultSign.changes);
      }

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'GOOGLE_CALENDAR') {
        throw new Error('Retrieved account is not a GOOGLE_CALENDAR account.');
      }

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      /** Reindex calendars. */
      await this.searchUc.indexCalendars();

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async addOutlook(
    account_name: string,
    credentials: OAuthCredentials,
    connection_params: OutlookConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => deepEqual(acc.connection_params, connection_params));

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params: connection_params,
        name: account_name,
        type: 'OUTLOOK',
        full_fetch_progress: [],
        full_fetch_status: 'IDLE',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      const cred: OutlookCredentials = {
        type: 'OUTLOOK',
        devices: {
          [this.env.device_type]: credentials,
        },
      };

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(cred)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      // Assign a default signature
      const signatures = await this.viewSignature.getAll();
      if (signatures.length > 0) {
        const resultSign = aggregate.selectSignature(signatures[0].id);
        await this.publisher.emit(resultSign.changes);
      }

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'OUTLOOK') throw new Error('Retrieved account is not an OUTLOOK account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async addMobile(
    account_name: string,
    connection_params: MobileConnectionParams
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if an account of type MOBILE is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => acc.type === 'MOBILE');

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      // Then create and persist the account
      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'MOBILE',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      await this.publisher.emit(resultCreate.changes);
      const account = await this.viewAccount.get(aggregate.id); // as MobileAccount | null;

      if (account?.type !== 'MOBILE') throw new Error('Retrieved account is not a Mobile account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, undefined as unknown as EncryptionCryptoKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @note Add LinkedIn account
   */
  async addLinkedIn(
    account_name: string,
    credentials: LinkedInCredentials,
    connection_params: LinkedInConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts
        .filter(isLinkedInAccount)
        .find((acc) => acc.connection_params.im.username === connection_params.im.username);

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'LINKEDIN',
        created_at: toUTCDateTimeMs(dayjs()),
        // last_fetched_at : null
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'LINKEDIN') throw new Error('Retrieved account is not a LinkedIn account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @note Add Messenger account
   */
  async addMessenger(
    account_name: string,
    credentials: MessengerCredentials,
    connection_params: MessengerConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts
        .filter(isMessengerAccount)
        .find((acc) => acc.connection_params.im.username === connection_params.im.username);

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'MESSENGER',
        created_at: toUTCDateTimeMs(dayjs()),
        // last_fetched_at : null
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'MESSENGER') throw new Error('Retrieved account is not a Messenger account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @note Add Twitter account
   */
  async addTwitter(
    account_name: string,
    credentials: TwitterCredentials,
    connection_params: TwitterConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts
        .filter(isTwitterAccount)
        .find((acc) => acc.connection_params.im.username === connection_params.im.username);

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'TWITTER',
        created_at: toUTCDateTimeMs(dayjs()),
        // last_fetched_at : null
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'TWITTER') throw new Error('Retrieved account is not a Twitter account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @note Add Instagram account
   */
  async addInstagram(
    account_name: string,
    credentials: InstagramCredentials,
    connection_params: InstagramConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts
        .filter(isInstagramAccount)
        .find((acc) => acc.connection_params.im.username === connection_params.im.username);

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'INSTAGRAM',
        created_at: toUTCDateTimeMs(dayjs()),
        // last_fetched_at : null
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'INSTAGRAM') throw new Error('Retrieved account is not a Instagram account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @note Add TikTok account
   */
  async addTikTok(
    account_name: string,
    credentials: TikTokCredentials,
    connection_params: TikTokConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if the account is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts
        .filter(isTikTokAccount)
        .find((acc) => acc.connection_params.im.username === connection_params.im.username);

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'TIKTOK',
        created_at: toUTCDateTimeMs(dayjs()),
        // last_fetched_at : null
      });

      const resultCredentials = aggregate.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'TIKTOK') throw new Error('Retrieved account is not a TikTok account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }
  /**
   * @note Add WhatsApp account
   */
  async addWhatsApp(
    account_name: string,
    credentials: WhatsAppCredentials,
    connection_params: WhatsAppConnectionParams,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<{ account: ViewAccount; sources: AccountSource[] }>> {
    try {
      // First test if an account of type whatsapp is not already added
      const user_accounts = await this.viewAccount.getAll();
      const found = user_accounts.find((acc) => acc.type === 'WHATSAPP');

      if (found) return { error: this.ERROR_ALREADY_ADDED };

      const { aggregate, ...resultCreate } = Account.create({
        connection_params,
        name: account_name,
        type: 'WHATSAPP',
        created_at: toUTCDateTimeMs(dayjs()),
      });

      /**
       * @note We're not storing WhatsApp session/credentials at the moment.
       *       See notes in WhatsAppAuthProvider.
       */
      //   const resultCredentials = aggregate.addCredentials({
      //     value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      //   });

      //   await this.publisher.emit([...resultCreate.changes, ...resultCredentials.changes]);
      await this.publisher.emit(resultCreate.changes);

      const account = await this.viewAccount.get(aggregate.id);
      if (account?.type !== 'WHATSAPP') throw new Error('Retrieved account is not a WhatsApp account.');

      // Register account in services for inititalFetch process
      const sources = await this._registerAccountSources(account, credentialsKey, 'FETCHING');

      return { result: { account, sources } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async deleteAccount(account_id: Uuid): Promise<Result<void>> {
    try {
      const viewAccount = await this.viewAccount.get(account_id);

      if (!viewAccount) throw new Error('Cannot found account to be deleted');

      if (!isEnvSupportedAccount(viewAccount, this.env, 'DELETION')) return { error: this.ERROR_UNSUPPORTED_DELETION };

      await this._unregisterAccountSources(viewAccount);

      const accountAgg = await this.account.getAggregate(account_id);
      const resDelete = accountAgg.delete();

      await this.publisher.emit(resDelete.changes);

      // Reindex the whole data from scratch so we are sure we don't miss anything
      await this.searchUc.index();

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   *
   */
  async reconnectGoogleCalendar(
    account_id: Uuid,
    credentialsKey: EncryptionCryptoKey,
    auth_code?: string
  ): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (account?.type !== 'GOOGLE_CALENDAR') {
        throw new Error('Account must be a GOOGLE_CALENDAR Type account to use reconnectGoogleCalendar');
      }

      /**
       * @todo Either try a similar workaround for TS lack of existential types as used with ProjectionistConfig
       *       and createProjectorRef, or change any to unknown in the XxxxServicesMap and use some type
       *       predicates.
       */
      const { identifier, at, rt }: GoogleOAuthReturn = await this.oauthServices['GOOGLE_CALENDAR'].requestAuth(auth_code);
      const acc_email = account.connection_params.calendar;

      /**
       * Because the user can login with any google account in the google sign in popup, we have to make sure
       * it's matching the source account he is trying to reconnect
       */
      if (identifier !== acc_email) {
        return { error: `${this.ERROR_WRONG_GOOGLE_ACC} : ${acc_email}` };
      }

      // Retrieve existing credentials to be able to update and not erase
      const crypto = this.crypto;
      const encryptedCredentials = await this.credentials.get(account_id as Uuid);

      let credentials: GoogleCalendarCredentials;
      if (encryptedCredentials) {
        prettyPrint({ encryptedCredentials }, 'reconnectGoogleCalendar', 'warn');
        try {
          const parsed = JSON.parse(
            await crypto.decrypt(credentialsKey, encryptedCredentials.value)
          ) as GoogleCalendarCredentials;
          if (isGoogleCalendarCredentials(parsed)) {
            credentials = parsed;
          }
        } catch (error) {
          console.log("Couldn't decrypt old credentials with current key in AppAccountUseCase.reconnectGoogleCalendar", error);
        }
      }
      /** Assign default value if credentials not found or contained garbage values. */
      credentials ??= {
        type: 'GOOGLE_CALENDAR',
        devices: {},
      };

      credentials.devices[this.env.device_type] = {
        at,
        rt,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.addCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });
      await this.publisher.emit(result.changes);

      return {};
    } catch (e) {
      if (e instanceof MissingOAuthScopesError) {
        /**
         * @note We know what scopes are missing, if we ever need to specify which scopes are missing
         *       to the user, it's available in e.missingScopes.
         *       Right now this returns a generic message about missing scopes.
         */
        return { error: this.ERROR_MISSING_OAUTH_SCOPES };
      }
      /** @todo Handle user cancelling the OAuth process. */
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   *
   */
  async reconnectGoogle(account_id: Uuid, auth_code: string, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      // const account = await this.viewAccount.get(account_id);

      // if (account?.type !== 'GOOGLE') throw new Error('Account must be a GOOGLE Type account to use reconnectGoogle');

      // const { identifier, at, rt } = await this.oauthServices['GOOGLE'].requestAuth(auth_code);

      // const acc_email = account.connection_params.email;

      // /**
      //  * Because the user can login with any google account in the google sign in popup, we have to make sure
      //  * it's matching the source account he is trying to reconnect
      //  */
      // if (identifier !== acc_email) return { error: this.ERROR_WRONG_GOOGLE_ACC + acc_email };

      // const accountAgg = await this.account.getAggregate(account_id);

      // const result = accountAgg.addCredentials({
      //   value: await this.crypto.encrypt(
      //     credentialsKey,
      //     JSON.stringify({
      //       type: 'GOOGLE',
      //       access_token: at,
      //       refresh_token: rt,
      //     })
      //   ),
      // });

      // await this.publisher.emit(result.changes);
      // if (result.ok === true) {
      //   return {};
      // }
      // throw `Could not reconnect Google credentials ( ${accountAgg.id} ) : ${result.reason}.`;
      throw new Error('Not implemented');
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  async reconnectMail(
    account_id: Uuid,
    credentials: MailCredentials,
    credentialsKey: EncryptionCryptoKey
  ): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'MAIL' && account?.type !== 'GOOGLE' && account?.type !== 'ICLOUD')
        throw new Error('Account must be a MAIL Type account to use reconnectMail');

      // Then test if the connection succeed with the connection params and credentials given
      const connection_success = await this.testMailConnection(credentials, account.connection_params.mail);

      if (!connection_success) return { error: this.ERROR_CONNEXION_FAIL };

      //   await this.encryptedCredentials.set(account.id, credentials);

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectLinkedIn(account_id: Uuid, password: string, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'LINKEDIN') throw new Error('Account must be a LINKEDIN Type account to use reconnectLinkedIn');

      const cookie = await this.scrapFormAuthServices['LINKEDIN'].login({
        username: account.connection_params.im.username,
        password,
      });

      const credentials: LinkedInCredentials = {
        type: 'LINKEDIN',
        cookie,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      await this._registerAccountSources(account, credentialsKey);

      return {};
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectMessenger(account_id: Uuid, password: string, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'MESSENGER') throw new Error('Account must be a MESSENGER Type account to use reconnectMessenger');

      const cookies = await this.scrapFormAuthServices['MESSENGER'].login({
        username: account.connection_params.im.username,
        password,
      });

      const credentials: MessengerCredentials = {
        type: 'MESSENGER',
        cookies,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      await this._registerAccountSources(account, credentialsKey);

      return {};
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectTikTok(account_id: Uuid, password: string, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'TIKTOK') throw new Error('Account must be a TIKTOK Type account to use reconnectTikTok');

      const cookies = await this.scrapFormAuthServices['TIKTOK'].login({
        username: account.connection_params.im.username,
        password,
      });

      const credentials: TikTokCredentials = {
        type: 'TIKTOK',
        cookies,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      await this._registerAccountSources(account, credentialsKey);

      return {};
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectInstagram(account_id: Uuid, password: string, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'INSTAGRAM') throw new Error('Account must be a INSTAGRAM Type account to use reconnectInstagram');

      const cookies = await this.scrapFormAuthServices['INSTAGRAM'].login({
        username: account.connection_params.im.username,
        password,
      });

      const credentials: InstagramCredentials = {
        type: 'INSTAGRAM',
        cookies,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      await this._registerAccountSources(account, credentialsKey);

      return {};
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectTwitter(account_id: Uuid, password: string, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'TWITTER') throw new Error('Account must be a TWITTER Type account to use reconnectTwitter');

      const cookies = await this.scrapFormAuthServices['TWITTER'].login({
        username: account.connection_params.im.username,
        password,
      });

      const credentials: TwitterCredentials = {
        type: 'TWITTER',
        cookies,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      await this._registerAccountSources(account, credentialsKey);

      return {};
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectWhatsApp(account_id: Uuid, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'WHATSAPP') throw new Error('Account must be a WHATSAPP Type account to use reconnectWhatsApp');

      const { session, identifier } = await this.scrapQRCodeAuthServices['WHATSAPP'].authenticateWithQRCode(account_id);

      /**
       * @note this is disable while we dont reinject session in webview
       */
      // const accountAgg = await this.account.getAggregate(account_id);
      // const result = accountAgg.updateCredentials({
      //   value: await this.crypto.encrypt(credentialsKey, JSON.stringify(session)),
      // });

      // await this.publisher.emit(result.changes);

      await this._registerAccountSources(account, credentialsKey);

      return {};
    } catch (e) {
      // if (e instanceof IncorrectCredentialsError) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async reconnectMicrosoft(account_id: Uuid, credentialsKey: EncryptionCryptoKey): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (account?.type !== 'OUTLOOK') throw new Error('Account must be a OUTLOOK Type account to use reconnectMicrosoft');

      const data: MicrosoftOAuthReturn = await this.oauthServices['OUTLOOK'].requestAuth(
        undefined,
        account.connection_params.mail
      );

      // Retrieve existing credentials to be able to update and not erase
      const crypto = this.crypto;
      const encryptedCredentials = await this.credentials.get(account_id as Uuid);

      const credentials: OutlookCredentials = encryptedCredentials
        ? (JSON.parse(await crypto.decrypt(credentialsKey, encryptedCredentials.value)) as OutlookCredentials)
        : {
            type: 'OUTLOOK',
            devices: {},
          };

      credentials.devices[this.env.device_type] = {
        at: data.at,
        rt: data.rt,
      };

      const accountAgg = await this.account.getAggregate(account_id);
      const result = accountAgg.updateCredentials({
        value: await this.crypto.encrypt(credentialsKey, JSON.stringify(credentials)),
      });

      await this.publisher.emit(result.changes);

      return {};
    } catch (e) {
      if ((isObject(e) && e?.errorCode === 'user_cancelled') || e === 'user_cancelled') return { error: 'cancelled' };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async requestMicrosoftAuth(account?: MicrosoftAccountInfo): Promise<Result<MicrosoftOAuthReturn>> {
    try {
      const result = await this.oauthServices['OUTLOOK'].requestAuth(undefined, account);
      return {
        result,
      };
    } catch (e) {
      console.log(e);
      if ((isObject(e) && e?.errorCode === 'user_cancelled') || e === 'user_cancelled' || e === 'Login cancelled.')
        return { error: 'cancelled' };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async requestGoogleCalendarAuth(auth_code?: string): Promise<Result<GoogleOAuthReturn>> {
    try {
      const result: GoogleOAuthReturn = await this.oauthServices['GOOGLE_CALENDAR'].requestAuth(auth_code);
      return {
        result,
      };
    } catch (e) {
      if (e instanceof MissingOAuthScopesError) {
        /**
         * @note We know what scopes are missing, if we ever need to specify which scopes are missing
         *       to the user, it's available in e.missingScopes.
         *       Right now this returns a generic message about missing scopes.
         */
        return { error: this.ERROR_MISSING_OAUTH_SCOPES };
      }
      /** @todo Handle user cancelling the OAuth process. */
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async testMailConnection({ type, ...credentials }: MailCredentials, connection_params: ImapConnectionParams): Promise<boolean> {
    console.log({ connection_params, credentials });
    return this.mailServices['MAIL'].testConnection?.(connection_params, credentials) ?? false;
  }

  async getImapAppPassword(
    account_type: WebViewAppPasswordSupportedAccounts,
    onStepChange: (step: number) => void
  ): Promise<Result<{ email: string; password: string }>> {
    try {
      const result = await this.scrapAppPasswordServices[account_type].getAppPassword(onStepChange);

      return {
        result,
      };
    } catch (e) {
      if (e instanceof ICloudDisabledMailError) {
        return { error: 'INVALID' };
      }
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Login to LinkedIn
   */
  async loginLinkedIn(username: string, password: string, proxy_settings?: ProxyConnectionParams): Promise<Result<string>> {
    try {
      const cookie = await this.scrapFormAuthServices['LINKEDIN'].login(
        {
          username,
          password,
        },
        proxy_settings
      );

      return {
        result: cookie,
      };
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };

      this.logger.captureException(e);
      if (e instanceof ScrapingNetworkError) {
        return { error: i18n.t('wizard.linkedInLogin.connexionFailError') };
      }
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Login to Messenger
   */
  async loginMessenger(username: string, password: string, is_reconnecting = false): Promise<Result<string>> {
    try {
      const user_accounts = await this.viewAccount.getAll();
      // const has_messenger = user_accounts.find((acc) => acc.type === 'MESSENGER');
      // if (has_messenger && !is_reconnecting) return { error: this.ERROR_ALREADY_ADDED };

      const cookies = await this.scrapFormAuthServices['MESSENGER'].login({
        username,
        password,
      });

      return { result: cookies };
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Login to TikTok
   */
  async loginTikTok(username: string, password: string, is_reconnecting = false): Promise<Result<string>> {
    try {
      const user_accounts = await this.viewAccount.getAll();

      const cookies = await this.scrapFormAuthServices['TIKTOK'].login({
        username,
        password,
      });

      return { result: cookies };
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Login to Instagram
   */
  async loginInstagram(username: string, password: string, is_reconnecting = false): Promise<Result<string>> {
    try {
      const user_accounts = await this.viewAccount.getAll();

      const cookies = await this.scrapFormAuthServices['INSTAGRAM'].login({
        username,
        password,
      });

      return { result: cookies };
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Login to Twitter
   */
  async loginTwitter(username: string, password: string, is_reconnecting = false): Promise<Result<string>> {
    try {
      const user_accounts = await this.viewAccount.getAll();

      const cookies = await this.scrapFormAuthServices['TWITTER'].login({
        username,
        password,
      });

      return { result: cookies };
    } catch (e) {
      if (e instanceof InvalidCredentials) return { error: e.message };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Login to WhatsApp
   */
  async loginWhatsApp(): Promise<Result<{ session: string; identifier: string }>> {
    const SCRAPER_NAME = 'WHATSAPP_TMP';
    try {
      const user_accounts = await this.viewAccount.getAll();
      const has_whatsapp = user_accounts.find((acc) => isWhatsAppAccount(acc));
      if (has_whatsapp) return { error: this.ERROR_ALREADY_ADDED };

      const { session, identifier } = await this.scrapQRCodeAuthServices['WHATSAPP'].authenticateWithQRCode(SCRAPER_NAME);

      return { result: { session, identifier } };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async requestMailConnectionParams(email: string): Promise<Result<ImapConnectionParams>> {
    try {
      return {
        result: await this.mailServices['MAIL'].requestConnectionParams?.(email),
      };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Retrieve the list of all signatures
   */
  async getSignatures(): Promise<Result<ViewSignature[]>> {
    try {
      return {
        result: await this.viewSignature.getAll(),
      };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Delete a signature
   * @param signature_id ID of the signature
   */
  async deleteSignature(signature_id: Uuid): Promise<Result<void>> {
    try {
      const accounts = (await this.viewAccount.getAll()).filter((acc) => acc.current_signature === signature_id);

      const accountsAgg = await Promise.all(accounts.map(async (acc) => this.account.getAggregate(acc.id)));

      let accChanges: DomainEvent[] = [];

      accountsAgg.forEach((agg) => {
        accChanges = [...accChanges, ...agg.selectSignature(undefined).changes];
      });

      const signature = await this.signature.getAggregate(signature_id);

      await this.publisher.emit([...accChanges, ...signature.delete().changes]);
      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Assign a signature to an account
   * @param account_id ID of the account
   * @param signature_id ID of the signature
   */
  async selectSignature(account_id: Uuid, signature_id: Uuid): Promise<Result<void>> {
    try {
      const account = await this.account.getAggregate(account_id);

      await this.publisher.emit(account.selectSignature(signature_id).changes);

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Edit a signature
   * @param signature_id ID of the signature
   * @param values Values to update
   */
  async updateSignature(signature_id: Uuid, values: UpdateSignatureDTO): Promise<Result<ViewSignature>> {
    try {
      const sign = await this.signature.getAggregate(signature_id);

      const result = sign.edit(values);

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const result = await this.viewSignature.get(signature_id);
        if (!result) throw new Error(`Could not get the edited signature ( ${sign.id} )`);
        return { result };
      }

      throw new Error(`Could not edit signature ( ${sign.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async createSignature(name: string): Promise<Result<ViewSignature>> {
    try {
      const signatures = await this.viewSignature.getAll();

      if (signatures.find((signature) => signature.name === name)) return { error: this.ERROR_SIGNATURE_NAME_ALREADY_EXIST };

      const result = Signature.create({ name });

      if (result.ok === true) {
        await this.publisher.emit(result.changes);
        const sign = await this.viewSignature.get(result.aggregate.id);
        if (!sign) throw new Error(`Cannot get created signature ( ${result.aggregate.id} )`);
        return { result: sign };
      } else {
        return { error: result.reason };
      }
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }
}
