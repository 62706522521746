import {
  AccountType,
  CalendarSupportedAccountTypes,
  CallSupportedAccountTypes,
  canAccountBeAdded,
  ContactSupportedAccountTypes,
  ImSupportedAccountTypes,
  MailSupportedAccountTypes,
  RootState,
  useAppDispatch,
} from '@focus-front/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WizardContext } from './WizardContext';
import { ArrowRight, ArrowRightAlt, GetApp } from '@material-ui/icons';
import { AccountTypeIcon } from '@focus-front/ui';
import cn from 'classnames';
import { NextButton } from './NavButton';
import { Alert } from '@material-ui/lab';

const useClasses = makeStyles((theme) => ({
  accountContainer: {
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: 121,
    height: 110,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.selected': {
      outline: `${theme.palette.secondary.main} solid 2px `,
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
    },
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  wrap: {
    whiteSpace: 'nowrap',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  bottom: {
    minHeight: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}));

type Account = {
  id: AccountType;
};

export default function AccountTypeSelection() {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [alert, setAlert] = useState<{ show: boolean; message: string; show_download: boolean }>({
    show: false,
    message: '',
    show_download: false,
  });
  const { goNext, setAccountType, setSources } = useContext(WizardContext);

  const ACCOUNTS: Account[] = [
    {
      id: 'GOOGLE',
    },
    {
      id: 'GOOGLE_CALENDAR',
    },
    {
      id: 'ICLOUD',
    },
    {
      id: 'OUTLOOK',
    },
    {
      id: 'MAIL',
    },
    {
      id: 'LINKEDIN',
    },
    {
      id: 'MESSENGER',
    },
    {
      id: 'INSTAGRAM',
    },
    {
      id: 'WHATSAPP',
    },
    {
      id: 'MOBILE',
    },
  ];

  const [selected, setSelected] = useState<AccountType>();

  const onConnect = async () => {
    const type = selected;
    const { result } = await dispatch(canAccountBeAdded(type));
    if (result.success) {
      setAccountType(type);
      setSources({
        CALENDAR: CalendarSupportedAccountTypes.includes(type),
        MAILS: MailSupportedAccountTypes.includes(type),
        IMS: ImSupportedAccountTypes.includes(type),
        CALLS: CallSupportedAccountTypes.includes(type),
        CONTACTS: ContactSupportedAccountTypes.includes(type),
      });
      goNext();
    } else {
      if (result.reason === 'MAX') {
        setAlert({ show: true, message: t('wizard.accountSelection.maxReached'), show_download: false });
      }
      if (result.reason === 'UNSUPPORTED') {
        setAlert({
          show: true,
          message: t('wizard.accountSelection.unsupported', { platforms: result.platforms?.join(', ') }),
          show_download: true,
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!selected) return null;
      const { result } = await dispatch(canAccountBeAdded(selected));
      if (result.success) {
        setAlert({ show: false, message: '', show_download: false });
      }
      if (result.reason === 'MAX') {
        setAlert({ show: true, message: t('wizard.accountSelection.maxReached'), show_download: false });
      }
      if (result.reason === 'UNSUPPORTED') {
        setAlert({
          show: true,
          message: t('wizard.accountSelection.unsupported', { platforms: result.platforms?.join(', ') }),
          show_download: true,
        });
      }
    })();
  }, [selected]);
  return (
    <div>
      <Typography variant="h2">{t('wizard.accountSelection.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.accountSelection.subtitle')}</Typography>
      <Grid container spacing={1} className={c.container}>
        {ACCOUNTS.map((acc, index) => (
          <Grid item key={index}>
            <Tooltip title={t('wizard.accountSelection.accountDescription.' + acc.id.toLowerCase())} placement="top" arrow>
              <div className={cn(c.accountContainer, selected === acc.id && 'selected')} onClick={() => setSelected(acc.id)}>
                <AccountTypeIcon size={40} account_type={acc.id} />
                <Typography variant="h1" style={{ fontWeight: 400, width: '100%', fontSize: 15, textAlign: 'center', margin: 0 }}>
                  {t('accounts.' + acc.id.toLowerCase())}
                </Typography>
              </div>
            </Tooltip>
          </Grid>
        ))}
      </Grid>

      <div className={c.bottom}>
        {alert.message && (
          <Alert
            severity="info"
            className={c.alert}
            action={
              alert.show_download && (
                <Button
                  startIcon={<GetApp />}
                  size="small"
                  color="inherit"
                  variant="text"
                  classes={{
                    label: c.wrap,
                  }}
                  onClick={() => window.open('https://www.unipile.com/telechargement-beta/', '_blank')}
                >
                  {t('wizard.accountSelection.downloadBtn')}
                </Button>
              )
            }
          >
            {alert.message}
          </Alert>
        )}

        {!alert.show && <NextButton disabled={!selected} onClick={onConnect}></NextButton>}
      </div>
    </div>
  );
}
