import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  loginUser,
  resetPassword,
  resetPasswordWithoutRecoveryCode,
  sessionStateSelector,
  useAppDispatch,
} from '@focus-front/core';
import { createStyles, IconButton, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import LoadingButton from '../Common/LoadingButton';
import { useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useParams } from 'react-router-dom';

interface ResetPasswordForm {
  email: string;
  password: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: 400,
      },
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: 400,
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    field: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 10,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& ion-icon': {
        marginRight: theme.spacing(1),
        fontSize: 20,
      },
    },
  })
);
export default function ResetPasswordNoSecretForm({ onResetSuccess }: { onResetSuccess?: () => void }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const session = useSelector(sessionStateSelector);
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const { requestId, token } = useParams<{ requestId: string; token: string }>();
  const { register, errors, handleSubmit } = useForm<ResetPasswordForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const onSubmit = async (data: ResetPasswordForm) => {
    setError(null);
    setLoading(true);

    const error = await dispatch(resetPasswordWithoutRecoveryCode(data.email, data.password, requestId, token));
    if (error) {
      /** @todo Implement retry for relevant errors. */
      switch (error.type) {
        case 'BACKUPKEYREQUEST_VALIDATION_ERROR':
          setError(t('resetPasswordNoSecret.form.invalidLink'));
          break;
        case 'RESETPASSWORDREQUEST_VALIDATION_ERROR':
          setError(t('resetPasswordNoSecret.form.invalidReset'));
          break;
      }
      setLoading(false);
    } else {
      onResetSuccess();
    }
  };

  useEffect(() => {
    if (session.error) setError(session.error);
  }, [session.error]);

  const [loading, setLoading] = useState(false);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <TextField
        label={t('login.form.email.label')}
        name="email"
        inputRef={register({
          required: t<string>('login.form.email.required'),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t('login.form.email.invalid'),
          },
        })}
        fullWidth
        error={!!errors.email}
        helperText={errors.email && errors.email.message}
        InputProps={{
          className: classes.field,
        }}
      />

      <TextField
        label={t('resetPasswordNoSecret.form.password.label')}
        name="password"
        type={showPassword ? 'text' : 'password'}
        inputRef={register({ required: t<string>('resetPasswordNoSecret.form.password.required') })}
        fullWidth
        error={!!errors.password}
        helperText={errors.password && errors.password.message}
        InputProps={{
          className: classes.field,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        className={classes.button}
        type="submit"
      >
        {t('resetPasswordNoSecret.form.submit')}
      </LoadingButton>

      {error && <Alert severity="error">{error}</Alert>}
    </form>
  );
}
