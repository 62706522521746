import React, { useEffect, useState } from 'react';

import { getMailChildDraft, mailReply, ViewMailDraft, useAppDispatch } from '@focus-front/core';

import MailForm from '../Mail/MailForm';
import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';

export default function MailTransferAction({ onDone, context }: ActionModalProps) {
  const dispatch = useAppDispatch();
  const [draft, setDraft] = useState<ViewMailDraft>(null);
  const [loading, setLoading] = useState<boolean>(false);

  let mail_id = context.metadata.attached_entity.id;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const draft = await dispatch(getMailChildDraft(mail_id, 'TRANSFER'));
      setDraft(draft);
      setLoading(false);
    })();
  }, [mail_id]);

  const onSend = async (draft: ViewMailDraft) => {
    setLoading(true);
    const error = await dispatch(mailReply(context, draft));
    if (error) {
      setLoading(false);
      alert(error);
    } else onDone('transfer');
  };

  return (
    <ActionModal loading={loading} style={{ height: '50%', maxHeight: 'auto' }}>
      {draft && <MailForm defaultValues={draft} onSend={onSend} action_id="transfer" parent_id={mail_id} />}
    </ActionModal>
  );
}
