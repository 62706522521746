import { ProxyConnectionParams } from '../../../../Account';
import { MaybePromise } from '../../../utils';
import { ScrapingScript, ScriptsProvider, UserAgents } from './ScriptsProvider';

export interface ScraperWindowSettings {
  width?: number;
  height?: number;
  closable?: boolean;
  show?: boolean;
  minimizable?: boolean;
  maximizable?: boolean;
  fullscreenable?: boolean;
  skipTaskbar?: boolean;
  alwaysOnTop?: boolean;
}

export interface ScraperAuthProvider {
  provider: ScriptsProvider<any, any, any>;
  persistSession?: boolean;
  proxySettings?: ProxyConnectionParams | undefined;

  authenticate: (scraper: Scraper) => Promise<void>;
}

export interface ScraperService<T> {
  /**
   * Destroy an existing scraper
   */
  destroyScraper: (id: string) => MaybePromise<void>;

  /**
   * Create a scraper or return the existing one
   */
  createScraper: (
    id: string,
    contexts_selectors: { [key: string]: string },
    window_settings?: ScraperWindowSettings,
    authProvider?: ScraperAuthProvider
  ) => T;

  /**
   * Return a scraper
   */
  getScraper: (id: string) => T;
}

export interface Scraper {
  running: boolean;

  /**
   * Initialize the webview
   */
  init: (userAgent?: UserAgents) => MaybePromise<void>;

  /**
   * Show the webview
   */
  showWebview: () => MaybePromise<void>;

  /**
   * Hide the webview
   */
  hideWebview: () => MaybePromise<void>;

  /**
   * Execute javascript in the webview
   */
  executeScript: <T, P>(script: ScrapingScript<P, T>, params?: P) => Promise<T>;

  /**
   * Get HTML of the webview
   */
  getHtml: (selector: string) => MaybePromise<string>;

  /**
   * Get lang of the website
   */
  getLang: () => MaybePromise<string | null>;

  /**
   * Get any cookie
   */
  getCookie: (name: string) => MaybePromise<string | null>;

  /**
   * Set any stringifed cookie (Electron cookie formatted)
   */
  setCookie: (cookie_stringifed: string, url: string) => MaybePromise<void>;

  /**
   * Load URL in the webview
   */
  loadUrl: (url: string) => MaybePromise<void>;

  /**
   * Get URL in the webview
   */
  getUrl: () => MaybePromise<string>;

  /**
   * Download URL in the webview
   */
  downloadUrl: (url: string) => Promise<{ status: number; statusText: string; blob: Blob }>;

  /**
   * Wait for a selector to appear in the webview
   */
  waitForSelector: (selectors: string[], exclude_error_selectors?: string[], timeoutMs?: number) => Promise<void>;

  /**
   * Add a job in the queue
   */
  run: <R>(job: () => MaybePromise<R>, priority: number) => Promise<R>;
}

export class ScrapingScriptError extends Error {
  constructor(message: string, public script_name: string) {
    super(`${message} when running ${script_name}`);
  }
}

export class ScrapingTimeoutError extends Error {
  constructor(message: string, public script_name: string) {
    super(`${message} when running ${script_name}`);
  }
}

export class InvalidContextError extends Error {
  constructor(message: string, public script_name: string, public context_waiting: string, public context_found: string) {
    super(`${message} when running ${script_name} | wait for ${context_waiting} | ${context_found} found`);
  }
}
export class UnknownContextError extends Error {
  constructor(message: string, public script_name: string, public context_waiting: string, public page_url: string) {
    super(`${message} when running ${script_name} | wait for ${context_waiting} | on ${page_url}`);
  }
}
export class ScrapingNetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NetworkError';
  }
}
