/**
 * Repository.
 */

import { CalendarEvent } from '../../domain';
import { AggregateRepo, DomainEventStore } from '../../../Common';

// export class CalendarEventRepo implements AggregateRepo {
export class CalendarEventRepo extends AggregateRepo<CalendarEvent> {
  constructor(eventStore: DomainEventStore) {
    super(CalendarEvent, eventStore);
  }
  //   /**
  //    * Query.
  //    */
  //   async getDomainEvents(aggregateId: Uuid) {
  //     // const events = this.eventStore.getByAggregate<CalendarEventDomainEvent>(aggregateId);
  //     // if(!events.length) {
  //     //     throw new Error(`Unknown aggregate id : ${aggregateId}.`)
  //     // }
  //     // return events;
  //     return this.eventStore.getByAggregate(aggregateId);
  //   }

  //   /**
  //    * Query.
  //    *
  //    * @note What happens when given aggregateId is valid for a non-CalendarEvent
  //    *       aggregate and getAggregate returns unrelated events.
  //    *
  //    *       events.length ? checks out, a new CalendarEvent is instantiated and
  //    *       the latter ignores all the event types it doesn't handle.
  //    *
  //    *       You end up with a malformed aggregate without an id.
  //    *
  //    */
  //   async getAggregate(aggregateId: Uuid) {
  //     const events = await this.eventStore.getByAggregate(aggregateId);
  //     // return events.length ? new CalendarEvent(events) : null;
  //     if (!events.length) {
  //       throw new Error(`Unknown aggregate id : ${aggregateId}.`);
  //     }
  //     return new CalendarEvent(events);
  //   }
}
