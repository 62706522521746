import { loadContacts, searchContacts, Taggable, useAppDispatch, ViewContact } from '@focus-front/core';
import { useEffect, useState } from 'react';

export function useContactsList(open) {
  const [{ contacts, groups, groupCounts }, setContacts] = useState<{
    contacts: (ViewContact & Taggable)[];
    groups: string[];
    groupCounts: number[];
  }>({ contacts: [], groups: [], groupCounts: [] });

  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>('');
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (open)
      (async () => {
        let res: (ViewContact & Taggable)[] = [];
        if (query !== '') {
          const search = await dispatch(searchContacts(query));
          res = search.map((res) => res.item);
        } else {
          res = await dispatch(loadContacts());
        }

        // Remove contact that only have email addresses
        res = res.filter((contact) => contact.phone?.length || contact.social?.length);

        const groups: string[] = [];
        const groupCounts: number[] = [];

        const contacts = res.sort((a, b) => (a.full_name < b.full_name ? -1 : 1));

        contacts.forEach((contact) => {
          const groupIndex = groups.findIndex((g) => g === contact.full_name.trim().charAt(0));
          if (groupIndex < 0) {
            groups.push(contact.full_name.trim().charAt(0));
            groupCounts.push(1);
          } else {
            groupCounts[groupIndex]++;
          }
        });

        setContacts({ contacts, groups, groupCounts });
        setLoading(false);
      })();
  }, [open, query]);

  return { contacts, groups, groupCounts, loading, setQuery, query };
}
