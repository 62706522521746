import { createTag, NewTagDTO, randomEnum, TagColor, useAppDispatch, ViewTag } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Typography } from '@material-ui/core';
import { checkmark } from 'ionicons/icons';
import { useConfirm } from 'material-ui-confirm';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  colorSelectContainer: {
    display: 'flex',
    width: 540,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  colorSelect: {
    width: 50,
    height: 30,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    '& ion-icon': {
      color: 'white',
      fontSize: 30,
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

type Props = {
  open: boolean;
  defaultValue: Partial<ViewTag>;
  onClose: () => void;
  onCreate: (tag: ViewTag) => void;
};
export default function TagCreationDialog({ open, onClose, defaultValue, onCreate }: Props) {
  const { t } = useTranslation();
  const c = useClasses();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue({ ...value, label: e.target.value });
  };

  const [value, setValue] = useState<NewTagDTO>({
    label: '',
    color: TagColor.RED,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const { result, error } = await dispatch(createTag(value));
    if (error) return confirm({ title: null, content: error, cancellationButtonProps: { style: { display: 'none' } } });
    onCreate(result);
  };

  useEffect(() => {
    if (open) {
      setValue({ ...value, ...defaultValue });
    }
  }, [defaultValue, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{t('tag.form.title')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label={t('tag.form.label')}
            fullWidth
            name="label"
            value={value.label}
            onChange={onChange}
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
          />
          <div className={c.colorSelectContainer}>
            {Object.values(TagColor).map((color) => (
              <ColorSelect
                key={color}
                color={color}
                selected={value.color === color}
                onClick={() => setValue({ ...value, color })}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('tag.form.cancel')}</Button>
          <Button type="submit">{t('tag.form.create')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

type ColorSelectProps = {
  color: TagColor;
  onClick: () => void;
  selected: boolean;
};
function ColorSelect({ color, selected, onClick }: ColorSelectProps) {
  const c = useClasses();

  return (
    <div className={c.colorSelect} style={{ backgroundColor: color }} onClick={onClick}>
      {selected && <IonIcon icon={checkmark} />}
    </div>
  );
}
