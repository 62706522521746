import {
  datesChange,
  DEFAULT_EVENT_INTERVAL,
  deleteNotification,
  draftCalendarEvent,
  replanDroppedMissedEventNotification,
  toUTCDate,
  toUTCDateTimeMs,
  Tzid,
  updateCalendarEvent,
  useAppDispatch,
  useAppStore,
  Uuid,
  ViewNotification,
} from '@focus-front/core';
import { DatesSetArg, EventChangeArg, EventClickArg } from '@fullcalendar/common';
import { DateClickArg, EventReceiveArg } from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import { useConfirm } from 'material-ui-confirm';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const getDefaultTzid = () => {
  return dayjs.tz.guess() as Tzid;
};

/**
 * Full calendar handlers logic extracted in this hook, do not use anywhere else than for FullCalendar.
 */
export default function useCalendarHandlers() {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const { t } = useTranslation();
  const { getState } = useAppStore();
  const history = useHistory();

  const confirmArchive = useCallback(async () => {
    await confirm({
      title: t('event.doneWarning.title'),
      description: t('event.doneWarning.message'),
      confirmationText: t('event.doneWarning.okButton'),
      cancellationText: t('event.doneWarning.cancelButton'),
    });
  }, []);

  /**
   * On event click :
   * -> If missed event, go to the notification in the inbox
   * -> Else go to the work mode (which redirect to event view if not in work mode)
   */
  const handleEventClick = useCallback(
    (arg: EventClickArg) => {
      const id = arg.event.id;
      const draft = id === 'draft';
      const missed_notif = getState().notifications.entities.find((n) => n.metadata?.origin_event?.id === id);
      if (missed_notif) return history.push(`/inbox/${missed_notif.id}`);
      if (!draft) return history.push(`/work/${id}`);
    },
    [history]
  );

  /**
   * On date/time click :
   * -> Draft a new event at datetime clicked
   */
  const handleDateClick = useCallback((args: DateClickArg) => {
    dispatch(draftCalendarEvent('CALENDAR', { start: toUTCDateTimeMs(args.dateStr), all_day: args.allDay }));
  }, []);

  /**
   * On calendar's internal date change (using the API)
   * -> Update date and title for the CalendarNav component
   * -> Fetch in storage and load in store the events to display
   */
  const handleDateChange = useCallback((args: DatesSetArg) => {
    dispatch(datesChange(args.startStr, args.endStr, args.view.title, toUTCDate(dayjs(args.view.activeStart).utc(true))));
  }, []);

  /**
   * On notification drop
   * -> If missed event notification, replace the attached event
   * -> Else, draft a new event with immediate creation and notification metadata
   * -> If dropped in the past, show an archive warning
   */
  const handleReceive = useCallback(async (args: EventReceiveArg) => {
    /** notification may not exist, let's check it does at least exist before the assertion. */
    if (args.event.extendedProps.notification === undefined) {
      args.revert();
      return;
    }

    const notification = args.event.extendedProps.notification as ViewNotification;

    console.log(notification);

    try {
      // Show confirm if dropped in the past
      if (dayjs(args.event.endStr).isBefore(dayjs())) {
        await confirmArchive();
      }

      let startTime = args.event.startStr;

      // Set default start hour to 8am (on month view dropped event is always set to 0)
      if (args.view.type === 'dayGridMonth') {
        startTime = dayjs(startTime).add(8, 'hour').toString();
      }

      // --- Drop missed event notification
      if (notification.metadata?.origin_event && notification.type === 'EVENT_MISSED') {
        const error = await dispatch(
          replanDroppedMissedEventNotification(notification, {
            start: toUTCDateTimeMs(startTime),
            end: toUTCDateTimeMs(dayjs(startTime).add(DEFAULT_EVENT_INTERVAL, 'minute')),
            ...(args.event.allDay
              ? {
                  all_day: true,
                  start_tzid: null,
                  end_tzid: null,
                }
              : {
                  all_day: false,
                  start_tzid: getDefaultTzid(),
                  end_tzid: getDefaultTzid(),
                }),
          })
        );

        if (error) throw error;
        dispatch(deleteNotification(notification.id));
        // Revert the local event created (the actual event will be provided through the events source)
        args.revert();

        // --- Drop other notification
      } else {
        // Draft the event with immediate creation
        await dispatch(
          draftCalendarEvent(
            'CALENDAR',
            {
              start: toUTCDateTimeMs(startTime),
              summary: args.event.title,
            },
            true,
            args.event._def.sourceId as Uuid
          )
        );
        dispatch(deleteNotification(notification.id));
        // Revert the local event created (the actual event will be provided through the events source)
        args.revert();
      }
    } catch (e) {
      console.error(e);
      args.revert();
    }
  }, []);

  /**
   * On drag/resize of an existing event
   * -> Update the event
   * -> If drag & dropped in the past, show an archive warning
   */
  const handleEventChange = useCallback(async ({ event, revert }: EventChangeArg) => {
    try {
      // Show confirm if dropped in the past
      if (dayjs(event.endStr).isBefore(dayjs())) {
        await confirmArchive();
      }

      // Update the modified event
      if (event.id !== 'draft')
        dispatch(
          updateCalendarEvent(event.id as Uuid, {
            type: event.extendedProps.type,
            kind: event.extendedProps.kind,
            start: toUTCDateTimeMs(event.startStr),
            end: toUTCDateTimeMs(event.endStr),
            ...(event.allDay
              ? {
                  all_day: true,
                  start_tzid: null,
                  end_tzid: null,
                }
              : {
                  all_day: false,
                  start_tzid: getDefaultTzid(),
                  end_tzid: getDefaultTzid(),
                }),
          })
        );
    } catch (e) {
      console.error(e);
      revert();
    }
  }, []);

  return { handleDateChange, handleReceive, handleDateClick, handleEventClick, handleEventChange };
}
