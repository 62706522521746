import { TagRelationCreated, TagRelationDeleted, TagRelationRestored } from './events/TagRelation.events';
import {
  AggregateRoot,
  createProjectionConfig,
  createUuidFrom,
  DomainEvent,
  Uuid,
  CommandAccepted,
  CommandResult,
} from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { NewTagRelationDTO } from './projections/ViewTagRelation';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const TAG_RELATION_NAMESPACE = '841c4405-fcb6-4b6e-922e-ee4a4dc9d3c0' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  deleted: false,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  TAG_RELATION_CREATED: (event) => ({
    id: event.aggregateId,
  }),
  TAG_RELATION_RESTORED: () => ({
    deleted: false,
  }),
  TAG_RELATION_DELETED: () => ({
    deleted: true,
  }),
});

/**
 * Aggregate.
 */
export class TagRelation extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  static getUuidFrom(content: NewTagRelationDTO): Uuid {
    return createUuidFrom(content.element + content.element_id + content.tag_id, TAG_RELATION_NAMESPACE);
  }

  /**
   * Command.
   */
  static create(content: NewTagRelationDTO): CommandAccepted<DomainEvent, TagRelation> {
    /**
     * Generate an Uuid with the whole content because a relation between a tag and an element is unique.
     */
    const aggregateId = TagRelation.getUuidFrom(content);
    return TagRelation.accept(new TagRelationCreated(aggregateId, content));
  }

  /**
   * Command.
   */
  restore(content: NewTagRelationDTO): CommandResult<'ALREADY_EXISTING' | 'WRONG_RELATION'> {
    const aggregateId = TagRelation.getUuidFrom(content);
    if (aggregateId !== this.id) return this.reject('WRONG_RELATION');

    return this.projection.state.deleted
      ? this.apply(new TagRelationRestored(this.id, content, this.version)).accept()
      : this.reject('ALREADY_EXISTING');
  }

  /**
   * Command.
   */
  delete(): CommandResult<'DELETED'> {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new TagRelationDeleted(this.id, this.version)).accept();
  }
}
