import { getSignaturesList, ViewSignature, Uuid, UpdateSignatureDTO, useAppDispatch } from '@focus-front/core';
import { Button, List, ListItem, ListItemText, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureAddModal from './SignatureAddModal';
import SignatureEditor from './SignatureEditor';
import SignaturesAccountList from './SignaturesAccountList';

const useClasses = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  editor: {
    border: `solid 1px ${theme.palette.divider}`,
    height: 300,
    borderRadius: 10,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  listWrapper: {
    minWidth: 180,
    width: 180,
    marginRight: theme.spacing(1),
    flex: 'auto',
    overflowX: 'hidden',
  },
  list: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 10,
    minWidth: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      marginLeft: 10,
    },
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

type Signature = Partial<ViewSignature>;
// type Action = { type: 'create' } | { type: 'select'; payload: Signature } | { type: 'load'; payload: Signature[] };
// type State = { signatures: Signature[]; selected: Signature | null };
// const initialState: State = { signatures: [], selected: null };

// function reducer(state: State, action: Action) {
//   switch (action.type) {
//     case 'create':
//       return { ...state };
//     case 'select':
//       return { count: state.count - 1 };
//     case 'load':
//       return { ...state, signatures: action.payload };
//     default:
//       throw new Error();
//   }
// }

export default function SignaturesPage() {
  const c = useClasses();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [signatures, setSignatures] = useState<ViewSignature[] | null>(null);
  const [selected, setSelected] = useState<Uuid | null>(null);
  const [showNew, setShowNew] = useState<boolean>(false);

  const handleUpdate = (updated: ViewSignature) => {
    const index = signatures.findIndex((sign) => sign.id === updated.id);
    const list = [...signatures];
    list[index] = updated;
    setSignatures(list);
  };

  const handleDelete = (id: Uuid) => {
    const index = signatures.findIndex((sign) => sign.id === id);
    const list = [...signatures];
    list.splice(index, 1);
    setSignatures(list);
    if (list.length) setSelected(list[0].id);
  };

  useEffect(() => {
    (async () => {
      const { error, result } = await dispatch(getSignaturesList());
      if (error) alert(error);
      setSignatures(result);
      if (result.length) setSelected(result[0].id);
    })();
  }, []);

  const onCreate = async (sign: ViewSignature) => {
    setSignatures([...signatures, sign]);
    setSelected(sign.id);
  };

  if (signatures === null) return null;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={c.sectionTitle}>
          <Typography variant="h2">{t('signatureSettings.list.title')}</Typography>
          <Typography variant="subtitle2">{t('signatureSettings.list.subtitle')}</Typography>
        </div>
        <Button onClick={() => setShowNew(true)} color="secondary" startIcon={<AddCircle />}>
          {t('signatureSettings.list.add')}
        </Button>
      </div>
      {selected && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div className={c.listWrapper}>
            <div className={c.list}>
              <List>
                {signatures.map((sign) => (
                  <ListItem key={sign.id} divider button selected={sign.id === selected} onClick={() => setSelected(sign.id)}>
                    <ListItemText primary={sign.name} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
          <SignatureEditor
            defaultValues={signatures.find((sign) => sign.id === selected)}
            id={selected}
            onUpdated={handleUpdate}
            onDeleted={handleDelete}
            showDelete={signatures.length > 1}
          />
        </div>
      )}

      <div className={c.sectionTitle}>
        <Typography variant="h2">{t('signatureSettings.assign.title')}</Typography>
        <Typography variant="subtitle2">{t('signatureSettings.assign.subtitle')}</Typography>
      </div>
      <SignaturesAccountList signatures={signatures} />
      <SignatureAddModal open={showNew} onClose={() => setShowNew(false)} onCreate={onCreate} />
    </>
  );
}
