import { sessionSelector } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Button, Typography } from '@material-ui/core';
import { rocketOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useStyles } from '../useStyles';
import SubscriptionCard from './SubscriptionCard';

export default function SubscriptionsPage() {
  const c = useStyles();
  const { t } = useTranslation();
  const session = useSelector(sessionSelector);
  return (
    <div>
      <div className={c.sectionTitle}>
        <Typography variant="h2">{t('subscription.current.title')}</Typography>
        <Typography variant="subtitle2">{t('subscription.current.subtitle')}</Typography>
      </div>

      <SubscriptionCard offer={session.offer} showCta />
      <div className={c.sectionTitle}>
        <Typography variant="h2">{t('subscription.available.title')}</Typography>
        <Typography variant="subtitle2">{t('subscription.available.subtitle')}</Typography>
      </div>

      {session.offer !== 'Boost' && <SubscriptionCard offer="Boost" showCta />}
      {session.offer !== 'Personal' && <SubscriptionCard offer="Personal" showCta />}
    </div>
  );
}
