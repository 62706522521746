import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { PartialViewMail, displayAttendeeFull } from '@focus-front/core';
import { useTranslation } from 'react-i18next';

export default function MailDetails({ mail }: { mail: PartialViewMail }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'mail-details' : undefined;

  const to = useMemo(() => {
    return (mail.to_attendees || []).map(displayAttendeeFull).join(', ');
  }, [mail]);

  const cc = useMemo(() => {
    return (mail.cc_attendees || []).map(displayAttendeeFull).join(', ');
  }, [mail]);

  return (
    <div style={{ display: 'inline-block' }}>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <ArrowDropDown />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Box p={2}>
          <Typography variant="body2">
            <strong>{t('mailForm.to')} :</strong> {to}
          </Typography>
          {cc && (
            <Typography variant="body2">
              <strong>{t('mailForm.cc')} :</strong> {cc}
            </Typography>
          )}
        </Box>
      </Popover>
    </div>
  );
}
