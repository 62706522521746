import { NewTagRelationDTO } from '.';
import { AggregateRoot, createProjectionConfig, createUuidFrom, DomainEvent, Uuid, CommandAccepted } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { TagCreated } from './events/Tag.events';
import { NewTagDTO } from './projections/ViewTag';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const TAG_NAMESPACE = 'b4951146-cef2-4db2-9cea-88d3ff53ecad' as Uuid;

export enum TagColor {
  RED = '#FF9898',
  ORANGE = '#FFC498',
  YELLOW = '#D3DD84',
  GREEN = '#9CDD84',
  CYAN = '#84DDC5',
  BLUE = '#84CFDD',
  PURPLE = '#849FDD',
  INDIGO = '#9F84DD',
  PINK = '#DD84DD',
  BORDEAUX = '#DD84AD',
  RED_DARK = '#BE7373',
  ORANGE_DARK = '#BE9373',
  YELLOW_DARK = '#919158',
  GREEN_DARK = '#619158',
  CYAN_DARK = '#58918F',
  BLUE_DARK = '#4B7991',
  PURPLE_DARK = '#45638D',
  INDIGO_DARK = '#4C4781',
  PINK_DARK = '#7A4781',
  BORDEAUX_DARK = '#864161',
}

/**
 * DecisionProjection initial state.
 */
const initialState = {};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  TAG_CREATED: (event) => ({
    id: event.aggregateId,
  }),
});

/**
 * Aggregate.
 */
export class Tag extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static create(content: NewTagDTO): CommandAccepted<DomainEvent, Tag> {
    const aggregateId = createUuidFrom(content.label, TAG_NAMESPACE);
    return Tag.accept(new TagCreated(aggregateId, content));
  }
}
