import { IDBPDatabase } from 'idb';

import { Uuid } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { createViewNotification, ViewNotification, UpdateNotificationDTO, ViewNotificationRepo } from '../../domain';

/**
 * Repository.
 *
 * @todo Consider using a transaction that takes a collections of items to add,
 *       clear the store, add each item for replays.
 */
export class IdbViewNotificationRepo implements ViewNotificationRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewNotification) {
    return Idb.put('viewNotifications', projection);
    // Idb.put('viewNotifications', { ...projection, status: 'LIVE' });
    // this.idb.put('viewNotifications', projection);
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateNotificationDTO) {
    const tx = Idb.transaction('viewNotifications', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewNotification ${id} does not exist.`);
    }

    /** @note await should be unnecessary here. */
    // await Promise.all([tx.store.put(createViewNotification(id, { ...old_projection, ...values })), tx.done]);
    tx.store.put(createViewNotification(id, { ...old_projection, ...values }));
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid) {
    // this.update(id, {}, 'DELETED');
    return Idb.delete('viewNotifications', id);
  }

  async removeByAccount(account_id: Uuid) {
    let cursor = await Idb.transaction('viewNotifications', 'readwrite')
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  /**
   *
   */
  async clear() {
    return Idb.clear('viewNotifications');
  }

  /**
   * Query.
   */
  async get(id: Uuid) {
    const notification = await Idb.get('viewNotifications', id);
    return notification || null;
  }

  /**
   * Query.
   */
  async getAll() {
    const notifications = await Idb.getAll('viewNotifications');
    return notifications;
  }

  /**
   * Query.
   */
  async getLive() {
    console.log('@todo : getLive().');
    return [] as ViewNotification[];
  }

  /**
   * Query.
   */
  async getSnoozed() {
    console.log('@todo : getSnoozed().');
    return [] as ViewNotification[];
  }

  /**
   * Query.
   */
  async getByEntityId(entity_id: Uuid) {
    const notifications = await Idb.getAllFromIndex('viewNotifications', 'by-entity-id', IDBKeyRange.only(entity_id));
    return notifications;
  }
}
