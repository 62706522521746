import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { searchLayoutSelector, selectSearchResult, useAppDispatch, Uuid } from '@focus-front/core';
import SearchEventView from '../Event/SearchEventView';
import SearchMailDraftView from '../Mail/SearchMailDraftView';
import SearchMailView from '../Mail/SearchMailView';
import SearchImThreadView from '../Im/SearchImThreadView';
import SearchCallView from '../Call/SearchCallView';
import ContactView from '../Contact/ContactView';

const useClasses = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 20,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: theme.palette.background.paper,
    // borderTop: `solid 1px ${theme.palette.divider}`,
    overflow: 'hidden',
  },
}));

export default function SelectedResultView() {
  const { selected } = useSelector(searchLayoutSelector);
  const dispatch = useAppDispatch();
  const c = useClasses();

  const onBack = () => {
    dispatch(selectSearchResult(null));
  };
  if (selected)
    return (
      <div className={c.root}>
        {selected.type === 'mail' && <SearchMailView mail_id={selected.id} onBack={onBack} />}
        {selected.type === 'mail_draft' && <SearchMailDraftView mail_id={selected.id} onBack={onBack} />}
        {selected.type === 'event' && <SearchEventView event_id={selected.id} onBack={onBack} />}
        {selected.type === 'im_thread' && <SearchImThreadView thread_id={selected.id} onBack={onBack} />}
        {selected.type === 'call' && <SearchCallView call_id={selected.id} onBack={onBack} />}
        {selected.type === 'contact' && <ContactView contact_id={selected.id} onBack={onBack} />}
      </div>
    );

  return null;
}
