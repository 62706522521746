import { IDBPDatabase } from 'idb';

import { Uuid } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { createViewSignature, UpdateSignatureDTO, ViewSignature } from '../../domain';
import { ViewSignatureRepo } from '../../domain/projections/ViewSignatureRepo';

/**
 * Repository.
 */
export class IdbViewSignatureRepo implements ViewSignatureRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewSignature) {
    Idb.put('viewSignatures', projection);
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateSignatureDTO) {
    const tx = Idb.transaction('viewSignatures', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewSignature ${id} does not exist.`);
    }

    /** @note await should be unnecessary here. */
    tx.store.put(createViewSignature(id, { ...old_projection, ...values }));
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid) {
    Idb.delete('viewSignatures', id);
  }

  /**
   *
   */
  async clear() {
    Idb.clear('viewSignatures');
  }

  /**
   * Query.
   */
  async get(id: Uuid) {
    return (await Idb.get('viewSignatures', id)) ?? null;
  }

  /**
   * Query.
   */
  async getAll() {
    return Idb.getAll('viewSignatures');
  }
}
