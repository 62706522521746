import { AccountType } from '@focus-front/core';
import React, { HTMLAttributes } from 'react';
import {
  AndroidCallAccountIcon,
  GmailAccountIcon,
  GoogleCalendarAccountIcon,
  ICloudAccountIcon,
  LinkedInAccountIcon,
  MailAccountIcon,
  MessengerAccountIcon,
  TikTokAccountIcon,
  TwitterAccountIcon,
  InstagramAccountIcon,
  MicrosoftAccountIcon,
  WhatsAppAccountIcon,
} from './UnipileIcon';

interface Props {
  account_type: AccountType;
  size: number;
}

export function AccountTypeIcon({ account_type, size, className }: Props & HTMLAttributes<HTMLDivElement>) {
  function getIcon() {
    switch (account_type) {
      case 'GOOGLE': {
        return <GmailAccountIcon size={size} />;
      }
      case 'ICLOUD': {
        return <ICloudAccountIcon size={size} />;
      }
      case 'GOOGLE_CALENDAR': {
        return <GoogleCalendarAccountIcon size={size} />;
      }
      case 'LINKEDIN': {
        return <LinkedInAccountIcon size={size} />;
      }
      case 'MAIL': {
        return <MailAccountIcon size={size} />;
      }
      case 'MESSENGER': {
        return <MessengerAccountIcon size={size} />;
      }
      case 'TIKTOK': {
        return <TikTokAccountIcon size={size} />;
      }
      case 'TWITTER': {
        return <TwitterAccountIcon size={size} />;
      }
      case 'INSTAGRAM': {
        return <InstagramAccountIcon size={size} />;
      }
      case 'MOBILE': {
        return <AndroidCallAccountIcon size={size} />;
      }
      case 'OUTLOOK': {
        return <MicrosoftAccountIcon size={size} />;
      }
      case 'WHATSAPP': {
        return <WhatsAppAccountIcon size={size} />;
      }
    }
  }

  const icon = getIcon();

  if (icon)
    return (
      <div className={className} style={{ width: size, height: size }}>
        {icon}
      </div>
    );

  return null;
}
