import dayjs from 'dayjs';
import { createProjector } from '../../../Common';
import { createViewIm } from '../projections/ViewIm';
import { ViewImRepo } from '../projections/ViewImRepo';
import { createViewImThread } from '../projections/ViewImThread';
import { ViewImThreadRepo } from '../projections/ViewImThreadRepo';

export interface viewImsProjectorRepos {
  viewIm: ViewImRepo;
  viewImThread: ViewImThreadRepo;
}
/**
 * Handler/Projector.
 */
export const viewImsProjector = createProjector('viewImsProjector', {
  IM_SYNCED: async ({ aggregateId, thread_id, im }, { viewIm, viewImThread }: viewImsProjectorRepos) => {
    return Promise.all([
      viewIm.add(createViewIm(aggregateId, thread_id, im)),
      (async () => {
        /**
         * @warning This can fail because the projection of the threads can be done after im projections, so the thread does not exist yet
         */
        const thread = await viewImThread.get(thread_id);
        if (!thread) return;

        if (!thread.last_update || dayjs(im.date).isAfter(dayjs(thread.last_update)))
          viewImThread.update(thread_id, { last_update: im.date });
      })(),
    ]);
  },
  IM_SUBMITTED: async ({ aggregateId, thread_id, im }, { viewIm, viewImThread }: viewImsProjectorRepos) => {
    return Promise.all([
      viewIm.add(createViewIm(aggregateId, thread_id, im)),
      viewImThread.update(thread_id, { last_update: im.date }),
    ]);
  },
  IM_REPLACED: async ({ aggregateId }, { viewIm }: viewImsProjectorRepos) => {
    return viewIm.remove(aggregateId);
  },
  IM_SENDING: async ({ aggregateId }, { viewIm }: viewImsProjectorRepos) => {
    return viewIm.update(aggregateId, { send_status: 'SENDING' });
  },
  IM_SENT_ERROR: async ({ aggregateId }, { viewIm }: viewImsProjectorRepos) => {
    return viewIm.update(aggregateId, { send_status: 'SENDING_ERROR' });
  },
  IM_SENT_SUCCESS: async ({ aggregateId }, { viewIm }: viewImsProjectorRepos) => {
    return viewIm.update(aggregateId, { send_status: 'SENT' });
  },
  IM_THREAD_SYNCED: async ({ aggregateId, thread }, { viewImThread }: viewImsProjectorRepos) => {
    return viewImThread.add(createViewImThread(aggregateId, { ...thread, sync_status: 'SYNC' }));
  },
  IM_THREAD_SUBMITTED: async ({ aggregateId, thread }, { viewImThread }: viewImsProjectorRepos) => {
    return viewImThread.add(createViewImThread(aggregateId, { ...thread, sync_status: 'NOT_SYNC' }));
  },
  IM_THREAD_ACKNOWLEDGED: async ({ aggregateId, values }, { viewIm, viewImThread }: viewImsProjectorRepos) => {
    const thread_ims = await viewIm.getByThread(aggregateId);
    return Promise.all([
      ...thread_ims.map((im) => viewIm.update(im.id, { provider_thread_id: values.provider_id })),
      viewImThread.update(aggregateId, { ...values, sync_status: 'SYNC' }),
    ]);
  },
  IM_THREAD_ACKNOWLEDGED_ATTENDEES: async ({ aggregateId, values }, { viewImThread }: viewImsProjectorRepos) => {
    return viewImThread.update(aggregateId, values);
  },
  IM_THREAD_ALL_PAST_FETCHED: async ({ aggregateId }, { viewImThread }: viewImsProjectorRepos) => {
    return viewImThread.update(aggregateId, { all_past_ims_synced: true });
  },
});
