import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNotifications, notificationsSelectedSelector } from '@focus-front/core';

import InboxList from './InboxList';
import InboxHeader from './InboxHeader';

export default function Inbox() {
  const dispatch = useDispatch();
  const { selectMode } = useSelector(notificationsSelectedSelector);

  useEffect(() => {
    setTimeout(() => dispatch(getNotifications()), 100);
  }, [dispatch]);

  // Reload notifications at interval when the user is on the inbox to make reappear snoozed notifications
  useEffect(() => {
    const interval = selectMode ? null : setInterval(() => dispatch(getNotifications()), 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, [selectMode]);

  return (
    <>
      <InboxHeader />
      <InboxList />
    </>
  );
}
