import { DomainEventShape, Uuid } from '../../../Common';
import { NewMessageModelDTO, UpdateMessageModelDTO } from '../projections/ViewMessageModel';

/**
 * DomainEvent.
 */
export class MessageModelCreated implements DomainEventShape {
  readonly type = 'MESSAGE_MODEL_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly messageModel: NewMessageModelDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class MessageModelDeleted implements DomainEventShape {
  readonly type = 'MESSAGE_MODEL_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class MessageModelEdited implements DomainEventShape {
  readonly type = 'MESSAGE_MODEL_UPDATED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly update: UpdateMessageModelDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class MessageModelUsed implements DomainEventShape {
  readonly type = 'MESSAGE_MODEL_USED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly count: number,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type MessageModelDomainEvent = MessageModelCreated | MessageModelEdited | MessageModelDeleted | MessageModelUsed;
