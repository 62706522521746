import { DomainEventShape, Uuid } from '../../../Common';
import { NewImThreadDTO, UpdateImThreadDTO } from '../projections/ViewImThread';

/**
 * DomainEvent.
 */
export class ImThreadSynced implements DomainEventShape {
  readonly type = 'IM_THREAD_SYNCED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly thread: NewImThreadDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ImThreadSubmitted implements DomainEventShape {
  readonly type = 'IM_THREAD_SUBMITTED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly thread: NewImThreadDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ImThreadAcknowledged implements DomainEventShape {
  readonly type = 'IM_THREAD_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly values: UpdateImThreadDTO,
    readonly version: number,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class ImThreadAcknowledgedAttendees implements DomainEventShape {
  readonly type = 'IM_THREAD_ACKNOWLEDGED_ATTENDEES';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly values: Pick<UpdateImThreadDTO, 'attendees'>,
    readonly version: number,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class ImThreadAllPastFetched implements DomainEventShape {
  readonly type = 'IM_THREAD_ALL_PAST_FETCHED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * Collect and export all event definitions.
 */
export type ImThreadDomainEvent =
  | ImThreadSynced
  | ImThreadSubmitted
  | ImThreadAcknowledged
  | ImThreadAcknowledgedAttendees
  | ImThreadAllPastFetched;
