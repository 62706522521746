import { createProjector } from '../../../Common';
import { createViewCall } from '../projections/ViewCall';
import { ViewCallRepo } from '../projections/ViewCallRepo';

/**
 * Handler/Projector.
 */
export const viewCallsProjector = createProjector('viewCallsProjector', {
  MISSED_CALL_RECEIVED: async ({ aggregateId, call }, repo: ViewCallRepo) => {
    return repo.add(createViewCall(aggregateId, call));
  },
  OUTGOING_CALL_RECEIVED: async ({ aggregateId, call }, repo: ViewCallRepo) => {
    return repo.add(createViewCall(aggregateId, call));
  },
  INCOMING_CALL_RECEIVED: async ({ aggregateId, call }, repo: ViewCallRepo) => {
    return repo.add(createViewCall(aggregateId, call));
  },
  NO_ANSWER_CALL_RECEIVED: async ({ aggregateId, call }, repo: ViewCallRepo) => {
    return repo.add(createViewCall(aggregateId, call));
  },
});
