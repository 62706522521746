import React, { FC } from 'react';
import { createStyles, makeStyles, alpha } from '@material-ui/core/styles';
import { ToggleButton } from '@material-ui/lab';

interface TipTapToolbarButtonProps {
  className?: string;
  icon: JSX.Element;
  value?: string;
  disabled?: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      border: 'none',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      '&.is-active': {
        color: theme.palette.action.active,
        backgroundColor: alpha(theme.palette.action.active, 0.12),
      },
    },
  })
);

const TipTapToolbarButton: FC<TipTapToolbarButtonProps> = ({ icon, className, ...rest }) => {
  const classes = useStyles();

  return (
    <ToggleButton size="small" className={`${classes.button} ${className}`} value="1" {...rest}>
      {icon}
    </ToggleButton>
  );
};

export default TipTapToolbarButton;
