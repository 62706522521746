export interface ProxyConfig {
  host: string;
  username: string;
  password: string;
}

export interface GoogleConnectionParams {
  email: string;
}

export interface MailConnectionParams {
  imap_host: string;
  imap_port: number;
  imap_user: string;
  smtp_host: string;
  smtp_port: number;
  smtp_user: string;
}

export type ProxymapConnectionParams = MailConnectionParams | GoogleConnectionParams;

export interface AutoConfig {
  imap_host?: string;
  imap_port?: string;
  imap_socket?: string;
  smtp_host?: string;
  smtp_port?: string;
  smtp_socket?: string;
}

export class InvalidCredentialsError extends Error {
  constructor(message?: string) {
    super(message || 'Invalid credentials'); // (1)
    this.name = 'InvalidCredentialsError'; // (2)
  }
}

export class MissingCredentialsError extends Error {
  constructor() {
    super('Missing credentials'); // (1)
    this.name = 'MissingCredentialsError'; // (2)
  }
}

export class ProxyServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProxyServerError';
  }
}
