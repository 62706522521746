/**
 * @note atob(), btoa() throw InvalidCharacterError when given data is invalid.
 *
 * @note Opaque type.
 */
declare const validHttpUrl: unique symbol;
export type HttpUrl = string & { [validHttpUrl]: true };

/**
 *
 */
export function isHttpUrl(url: string): url is HttpUrl {
  let parsed: URL;

  try {
    parsed = new URL(url);
  } catch (_) {
    return false;
  }

  return parsed.protocol === 'http:' || parsed.protocol === 'https:';
}
