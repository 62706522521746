import { UserSession } from '../../../domain';
import { SessionRepo } from './SessionRepo';

/**
 * Session repository in memory
 * @note we do not store the password with this repository because it's not safe.
 */
export class MemorySessionRepo implements SessionRepo {
  private saved_session: UserSession | null = null;
  private password: string | null = null;
  async set(session: UserSession, password?: string | null): Promise<void> {
    this.saved_session = session;
  }
  async get(): Promise<UserSession | null> {
    return this.saved_session;
  }
  async getPassword(): Promise<string | null> {
    return this.password;
  }
  async delete(): Promise<void> {
    this.saved_session = null;
  }
}
