import { Uuid } from '../../../Common';
import { Idb } from '../../../Common/infra/services/idb';
import { ViewTagRelation, ViewTagRelationRepo, TaggableElement, ViewTagRepo, ViewTag } from '../../domain';

export class IdbViewTagRelationRepo implements ViewTagRelationRepo {
  constructor(private readonly viewTag: ViewTagRepo) {}

  async add(relation: ViewTagRelation): Promise<string> {
    return Idb.put('viewTagRelations', relation);
  }

  async delete(id: Uuid): Promise<void> {
    return Idb.delete('viewTagRelations', id);
  }

  async getTagsByElement(element: TaggableElement, element_id: Uuid): Promise<ViewTag[]> {
    const relations = await Idb.getAllFromIndex('viewTagRelations', 'by-element', IDBKeyRange.only([element, element_id]));

    const tags = await this.viewTag.getMany(relations.map(({ tag_id }) => tag_id));

    return tags;
  }

  async getByElement(element: TaggableElement, element_id: Uuid): Promise<ViewTagRelation[]> {
    const relations = await Idb.getAllFromIndex('viewTagRelations', 'by-element', IDBKeyRange.only([element, element_id]));

    return relations;
  }

  // async deleteByElement(element: TaggableElement, element_id: Uuid): Promise<void> {
  //   let cursor = await Idb.transaction('viewTagRelations', 'readwrite')
  //     .store.index('by-element')
  //     .openCursor(IDBKeyRange.only([element, element_id]));

  //   while (cursor) {
  //     await cursor.delete();
  //     cursor = await cursor.continue();
  //   }
  // }

  // async deleteByElementAndTag(element: TaggableElement, element_id: Uuid, tag_id: Uuid): Promise<void> {
  //   let cursor = await Idb.transaction('viewTagRelations', 'readwrite')
  //     .store.index('by-element')
  //     .openCursor(IDBKeyRange.only([element, element_id]));

  //   while (cursor) {
  //     if (cursor.value.tag_id === tag_id) {
  //       await cursor.delete();
  //       break;
  //     }
  //     cursor = await cursor.continue();
  //   }
  // }

  async clear(): Promise<void> {
    return Idb.clear('viewTagRelations');
  }
}
