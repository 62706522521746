import { mockUuid, TagColor, TaggableElement, updateElementTags, useAppDispatch, Uuid, ViewTag } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, IconButton, makeStyles, Popover, Tooltip } from '@material-ui/core';
import { Add, Check, Close, Create } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import { add, pricetags, pricetagsOutline, settingsOutline } from 'ionicons/icons';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchTextField from '../Common/SearchTextField';
import theme from '../lightTheme';
import SearchTagsCloud from '../Search/SearchTagsCloud';
import Tag from './Tag';
import TagPicker from './TagPicker';

type Props = {
  tags: ViewTag[];
  element: TaggableElement;
  element_id: Uuid;
  emptyBtn?: (handleClick) => JSX.Element;
};

const useClasses = makeStyles((theme) => ({
  navigationBtn: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
      marginLeft: 10,
    },
  },
}));

function EditableTags({ tags, element, element_id, emptyBtn }: Props) {
  const [items, setItems] = useState([]);
  const c = useClasses();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setItems(tags);
  }, [tags]);

  const onDelete = (tag: ViewTag) => {
    setItems(items.filter((item) => item !== tag));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (tags: ViewTag[]) => {
    const { error } = await dispatch(updateElementTags(element, element_id, tags));
    setAnchorEl(null);
    if (error) return alert(error);
    setItems(tags);
  };

  return (
    <Box display="flex" alignItems="center">
      {!items?.length ? (
        <>
          {emptyBtn ? (
            emptyBtn(handleClick)
          ) : (
            <Button variant="text" className={c.navigationBtn} onClick={handleClick}>
              {t('tag.add')}
              <IonIcon icon={pricetagsOutline}></IonIcon>
            </Button>
          )}
        </>
      ) : (
        <Tooltip title={t('tag.add')}>
          <IconButton size="small" onClick={handleClick}>
            <IonIcon icon={add} style={{ fontSize: 24 }}></IonIcon>
          </IconButton>
        </Tooltip>
      )}
      <TagPicker anchorEl={anchorEl} handleClose={handleClose} defaultValue={tags} />
      {items?.map((tag, index) => (
        <Tag key={tag.id} tag={tag} />
      ))}
    </Box>
  );
}

export default React.memo(EditableTags);
