import { cogOutline, flash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { closeSettingsDialog, erroredSourcesSelector, openSettingsDialog, settingsDialogSelector, useAppDispatch } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { IconButton, Tooltip } from '@material-ui/core';

import AccountDisconnectedIcon from '../Common/AccountDisconnectedIcon';
import SettingsDialog from './SettingsDialog';

export default function SettingsButton() {
  // const [open, setOpen] = useState<boolean>(false);
  const { open } = useSelector(settingsDialogSelector);
  const errored_sources = useSelector(erroredSourcesSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      {errored_sources.length > 0 && (
        <div onClick={() => dispatch(openSettingsDialog())} style={{ cursor: 'pointer' }}>
          <AccountDisconnectedIcon title={t('accountSettings.disconnected.warning')} />
        </div>
      )}
      <Tooltip title={t('header.settingsMob')}>
        <IconButton color="inherit" onClick={() => dispatch(openSettingsDialog())}>
          {/* <Badge badgeContent=" " color="primary" classes={{ badge: c.badge }}> */}
          <IonIcon icon={cogOutline} />
          {/* </Badge> */}
        </IconButton>
      </Tooltip>
      <SettingsDialog open={open} onClose={() => dispatch(closeSettingsDialog())} />
    </>
  );
}
