import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getImapAppPassword, testMailConnection, useAppDispatch } from '@focus-front/core';
import { TwoFAIcon, ImapActivationIcon, AppPasswordIcon } from '@focus-front/ui';
import { WizardContext, ICLOUD_IMAP_CONFIG_DEFAULTS } from './WizardContext';
import { Typography, Box } from '@material-ui/core';
import { BackButton, NextButton } from './NavButton';
import { Alert } from '@material-ui/lab';
import { AutoConfigStep } from './AutoConfigStep';

export function ICloudMailConfigurationAuto() {
  const { t } = useTranslation();

  const { goBack, goNext, forms, setForms, setAlias, setSteps, steps, setLoading } = useContext(WizardContext);

  const dispatch = useAppDispatch();

  const [step, setStep] = useState<number>(0);
  const [invalid, setInvalid] = useState<boolean>(false);

  const goToFallback = () => {
    const index = steps.findIndex((step) => step === 'ICLOUD_MAIL_AUTO');
    // Insert the error message pannel and manual config panels before going to next wizard step
    steps.splice(index + 1, 0, 'ICLOUD_MAIL_AUTO_ERROR', 'ICLOUD_MAIL_MANUAL_1', 'ICLOUD_MAIL_MANUAL_2');
    setSteps(steps);
    goNext();
  };

  const start = async () => {
    setInvalid(false);

    // Start the process of app password scraping, update step state on callback
    const { result, error } = await dispatch(getImapAppPassword('ICLOUD', setStep));

    // If the iCloud account does not have emails enabled
    if (error === 'INVALID') {
      setStep(0);
      return setInvalid(true);
    }

    // If anything goes wrong, go to manual config
    if (error) return goToFallback();

    // While we test the connection with the retrieved email and password, block the ui
    setLoading(true);

    const success = await dispatch(
      testMailConnection(
        { type: 'MAIL', imap_pwd: result.password, smtp_pwd: result.password },
        {
          ...ICLOUD_IMAP_CONFIG_DEFAULTS,
          imap_user: result.email,
          smtp_user: result.email,
        }
      )
    );

    setLoading(false);

    // If the email/password are not working, go to manual config
    if (!success) return goToFallback();

    setAlias(result.email);
    setForms({ ...forms, ICLOUD_MAIL: result });
    goNext();
  };

  function getStepStatus(s) {
    if (s > step) return 'IDLE';
    if (s < step) return 'DONE';
    return 'LOADING';
  }

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2"> {t('wizard.icloudMailAutoConfig.title')}</Typography>
      <Typography variant="subtitle2"> {t('wizard.icloudMailAutoConfig.subtitle')}</Typography>
      <Box mt={3} mb={5}>
        <AutoConfigStep
          status={getStepStatus(1)}
          title={t('wizard.icloudMailAutoConfig.step1')}
          subtitle={t('wizard.icloudMailAutoConfig.needAuth')}
          icon={<TwoFAIcon size={40} />}
        />
        <AutoConfigStep
          status={getStepStatus(2)}
          title={t('wizard.icloudMailAutoConfig.step2')}
          subtitle={t('wizard.icloudMailAutoConfig.needAuth')}
          icon={<AppPasswordIcon size={40} />}
        />
      </Box>
      {invalid && (
        <Box mt={1} mb={1}>
          <Alert severity="error">{t('wizard.icloudMailAutoConfig.invalidAccountError')}</Alert>
        </Box>
      )}
      <NextButton onClick={start} disabled={step !== 0}>
        {t('wizard.icloudMailAutoConfig.start')}
      </NextButton>
    </div>
  );
}

export function ICloudMailAutoError() {
  const { t } = useTranslation();

  const { goNext } = useContext(WizardContext);

  return (
    <div>
      <Typography variant="h2"> {t('wizard.icloudMailAutoConfig.title')}</Typography>
      <Box mt={3} mb={5}>
        <Alert severity="error"> {t('wizard.icloudMailAutoConfig.error')}</Alert>
      </Box>
      <NextButton onClick={goNext}> {t('wizard.icloudMailAutoConfig.startManual')}</NextButton>
    </div>
  );
}
