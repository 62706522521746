import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCallView, Uuid, ViewCall, fancyTimeFormat, useAppDispatch, displayAttendeeName, Taggable } from '@focus-front/core';
import AccountLabel from '../Common/AccountLabel';
import dayjs from 'dayjs';
import { IonIcon } from '@ionic/react';
import { recordingOutline, timeOutline } from 'ionicons/icons';
import AttendeeAvatarPopover from '../Contact/AttendeeAvatarPopover';
import EditableTags from '../Tag/EditableTags';

const useClasses = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  contactContainer2: {
    marginLeft: theme.spacing(2),
  },
  infoLine: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& > ion-icon': {
      fontSize: 24,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function CallView({ call_id }: { call_id: Uuid }) {
  const [call, setCall] = useState<ViewCall & Taggable>(null);
  const dispatch = useAppDispatch();
  const c = useClasses();
  const { t } = useTranslation();

  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      const call = await dispatch(getCallView(call_id));
      setCall(call);
    })();
  }, [call_id]);

  if (!call) return <div></div>;

  return (
    <div>
      <div className={c.header}>
        <div>
          <Typography variant="h1">
            {call.type === 'MISSED' && displayAttendeeName(call.from_attendee) + ' ' + t('notification.call.missedTitle')}
            {call.type === 'INCOMING' && displayAttendeeName(call.from_attendee) + ' ' + t('notification.call.incomingTitle')}
            {call.type === 'NO_ANSWER' && displayAttendeeName(call.from_attendee) + ' ' + t('notification.call.no_answerTitle')}
          </Typography>
          <div className={c.contactContainer}>
            <AttendeeAvatarPopover attendee={call.from_attendee} />
            <div className={c.contactContainer2}>
              <Typography variant="h4">{call.from_attendee.display_name}</Typography>
              <Typography variant="body2">{call.from_attendee.identifier}</Typography>
            </div>
          </div>
        </div>
        <div className={c.details}>
          <Typography variant="body2" className={c.detailText}>
            {dayjs(call.date).format('DD MMM YYYY [à] HH:mm')}
          </Typography>
          <AccountLabel account_id={call.account_id} className={c.detailText} />
          <div className={c.tags}>
            <EditableTags tags={call.tags} element="CALL" element_id={call.id} />
          </div>
        </div>
      </div>
      {call.duration > 0 && (
        <div className={c.infoLine}>
          <Tooltip title={t('call.duration')}>
            <IonIcon icon={timeOutline} />
          </Tooltip>
          <Typography variant="body1">{fancyTimeFormat(call.duration)}</Typography>
        </div>
      )}
      {call.type === 'MISSED' && (
        <div className={c.infoLine}>
          <IonIcon icon={recordingOutline} />
          <Typography variant="body1">
            {call.from_attendee.display_name} {!!call.voice_mail_id ? t('call.withVoicemail') : t('call.withoutVoicemail')}
          </Typography>
        </div>
      )}
    </div>
  );
}
