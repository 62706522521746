import { AggregateRoot, CommandAccepted, createProjectionConfig, createUuid, DomainEvent, UTCDateTime } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { SignatureCreated, SignatureDeleted, SignatureEdited } from './events/Signature.events';
import { NewSignatureDTO, UpdateSignatureDTO } from './projections/ViewSignature';
import { NewEncryptedCredentialsDTO } from './projections/ViewCredentials';

/**
 * DecisionProjection initial state.
 */
const initialState = {
  deleted: false,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  SIGNATURE_CREATED: (event) => ({ id: event.aggregateId }),
  SIGNATURE_DELETED: () => ({ deleted: true }),
});

/**
 * Aggregate.
 */
export class Signature extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   *
   * @todo Check invariants here !
   */
  static create(content: NewSignatureDTO) {
    return content.name ? Signature.accept(new SignatureCreated(createUuid(), content)) : this.reject('No name');
  }

  /**
   * Command.
   */
  delete() {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new SignatureDeleted(this.id, this.version)).accept();
  }

  /**
   * Command.
   */
  edit(values: UpdateSignatureDTO) {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new SignatureEdited(this.id, this.version, values)).accept();
  }
}
