import { AggregateRoot, createProjectionConfig, createUuidFrom, DomainEvent, Uuid } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { ImReplaced, ImSending, ImSentError, ImSentSuccess, ImSubmitted, ImSynced } from './events/Im.events';
import { ImSendStatus, ImSyncStatus, NewImDTO } from './projections/ViewIm';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const IM_NAMESPACE = '6037dba5-2dc5-415c-ba98-883ac6a59b85' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  send_status: 'IDLE' as ImSendStatus,
  sync_status: 'NOT_SYNC' as ImSyncStatus,
  replaced: null as boolean | null,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  IM_SYNCED: (event) => ({
    id: event.aggregateId,
    sync_status: 'SYNC',
    replaced: true,
  }),
  IM_SUBMITTED: (event) => ({
    id: event.aggregateId,
    sync_status: 'NOT_SYNC',
    replaced: false,
  }),
  IM_SENDING: () => ({
    send_status: 'SENDING',
  }),
  IM_SENT_SUCCESS: () => ({
    send_status: 'SENT',
  }),
  IM_SENT_ERROR: () => ({
    send_status: 'SENDING_ERROR',
  }),
  IM_REPLACED: () => ({
    replaced: true,
  }),
});

const imType = {
  SUBMIT: ImSubmitted,
  SYNC: ImSynced,
};

/**
 * Aggregate.
 */
export class Im extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static create(content: NewImDTO, thread_id: Uuid, origin: 'SUBMIT' | 'SYNC') {
    /**
     * @todo Figure out what is enough to say :
     *
     *       "Oh ! these two IMs are the same !"
     *
     *       And use it as a name with createUuidFrom(name, namespace).
     */
    /**
     * For a synced im, use the provider_id, for a non-synced, use date + body
     */
    const aggregateId = createUuidFrom(
      content.provider_id ? content.provider_id + content.account_id : content.date + content.body + content.account_id,
      IM_NAMESPACE
    );
    return Im.accept(
      new imType[origin](
        aggregateId,
        {
          ...content,
          sync_status: origin === 'SYNC' ? 'SYNC' : 'NOT_SYNC',
          /**
           * We consider that a synced outgoing message is sent
           */
          send_status: origin === 'SYNC' && content.direction === 'OUTGOING' ? 'SENT' : content.send_status,
        },
        thread_id
      )
    );
  }

  /**
   * Command.
   */
  successSend() {
    return this.projection.state.send_status !== 'SENDING'
      ? this.reject('NOT SENDING')
      : this.apply(new ImSentSuccess(this.id, this.version)).accept();
  }

  /**
   * Command.
   */
  errorSend() {
    return this.projection.state.send_status !== 'SENDING'
      ? this.reject('NOT SENDING')
      : this.apply(new ImSentError(this.id, this.version)).accept();
  }

  /**
   * Command.
   */
  send() {
    return this.projection.state.send_status === 'SENT'
      ? this.reject('ALREADY SENT')
      : this.apply(new ImSending(this.id, this.version)).accept();
  }

  /**
   * Command.
   *
   * Delete an aggregate of a sent IM to be replaced by the remote version
   */
  replace() {
    return this.projection.state.sync_status === 'SYNC' || this.projection.state.replaced
      ? this.reject('ALREADY REPLACED')
      : this.apply(new ImReplaced(this.id, this.version)).accept();
  }
}
