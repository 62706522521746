import React, { useEffect } from 'react';
import TipTapUnipile from '../Common/TipTapUnipile/TipTapUnipile';
import { Button, makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { deleteSignature, updateSignature, UpdateSignatureDTO, useAppDispatch, Uuid, ViewSignature } from '@focus-front/core';
import { useTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';

const useClasses = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      marginLeft: theme.spacing(1),
    },
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

interface SignatureEditorProps {
  defaultValues: UpdateSignatureDTO;
  id: Uuid;
  onUpdated: (sign: ViewSignature) => void;
  onDeleted: (id: Uuid) => void;
  showDelete: boolean;
}

const SignatureEditor: React.FC<SignatureEditorProps> = ({ defaultValues, id, onUpdated, onDeleted, showDelete }) => {
  const c = useClasses();

  const { register, handleSubmit, control, formState, reset, setValue, watch } = useForm<UpdateSignatureDTO>({
    mode: 'onChange',
    defaultValues,
  });

  const confirm = useConfirm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    reset(defaultValues);
  }, [id]);

  const onSubmit = async (values) => {
    const { error, result } = await dispatch(updateSignature(id, values));
    if (error) return alert(error);
    reset(values);
    onUpdated(result);
  };

  const onDelete = async () => {
    await confirm({
      title: t('signatureSettings.deleteConfirm.title'),
      description: t('signatureSettings.deleteConfirm.description'),
      cancellationText: t('signatureSettings.deleteConfirm.cancellationText'),
      confirmationText: t('signatureSettings.deleteConfirm.confirmationText'),
      confirmationButtonProps: {
        variant: 'outlined',
        className: c.deleteBtn,
        startIcon: <Delete />,
      },
      cancellationButtonProps: {
        variant: 'text',
      },
    });
    const error = await dispatch(deleteSignature(id));
    if (error) return alert(error);
    onDeleted(id);
  };

  const showSave = formState.isValid && formState.isDirty;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ onChange, value }) => (
            <TextField variant="outlined" label="Nom" fullWidth value={value} onChange={onChange} />
          )}
        />

        <Controller
          control={control}
          name="body"
          render={({ onChange, value }) => <TipTapUnipile onChange={onChange} initialContent={defaultValues?.body} />}
        />
      </div>

      <div className={c.actions}>
        {showDelete && (
          <Button variant="outlined" className={c.deleteBtn} startIcon={<Delete />} onClick={onDelete}>
            {t('signatureSettings.form.delete')}
          </Button>
        )}
        <Button onClick={handleSubmit(onSubmit)} disabled={!showSave} color="secondary">
          {t('signatureSettings.form.save')}
        </Button>
      </div>
    </div>
  );
};

export default SignatureEditor;
