import { IonIcon } from '@ionic/react';
import { Button, makeStyles, Switch, Typography } from '@material-ui/core';
import {
  AccountSourceType,
  AccountType,
  CalendarSupportedAccountTypes,
  CallSupportedAccountTypes,
  ImSupportedAccountTypes,
  MailSupportedAccountTypes,
  ContactSupportedAccountTypes,
} from '@focus-front/core';
import { calendarOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Call, Chat, Contacts, Mail } from '@material-ui/icons';
import { WizardContext } from './WizardContext';

const useClasses = makeStyles((theme) => ({
  sourceContainer: {
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    padding: '11px 20px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sourceIcon: {
    width: 40,
    height: 40,
    boxShadow: '1px 2px 4px rgba(0,0,0,0.16)',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.text.primary,
    color: '#fff',
    fontSize: 24,
  },
}));

const ICONS: { [key in AccountSourceType]: JSX.Element } = {
  CALENDAR: <IonIcon icon={calendarOutline} />,
  CALLS: <Call />,
  CONTACTS: <Contacts />,
  IMS: <Chat />,
  MAILS: <Mail />,
};

export function SourceIcon({ source }: { source: AccountSourceType }) {
  const c = useClasses();
  return <div className={c.sourceIcon}>{ICONS[source]}</div>;
}

type SelectorProps = {
  source: AccountSourceType;
  onChange: (selected: boolean) => void;
  selected: boolean;
  soon?: boolean;
};

function SourceSelector({ source, onChange, selected, soon = false }: SelectorProps) {
  const { t } = useTranslation();
  const c = useClasses();

  return (
    <div className={c.sourceContainer}>
      <SourceIcon source={source} />
      <Typography variant="h1" style={{ fontWeight: 400, flex: 1, marginLeft: 20 }}>
        {t(`accountSettings.sources.name.${source}`)}
      </Typography>
      {soon ? (
        <Button variant="text" disabled>
          {t('wizard.accountSelection.accountStatus.soonAvailable')}
        </Button>
      ) : (
        <Switch onChange={(e) => onChange(!selected)} checked={selected} />
      )}
    </div>
  );
}

type Props = {
  account_type: AccountType;
  onChange: (selected: { [key in AccountSourceType]: boolean }) => void;
};

export default function SourceSelection({ account_type, onChange }: Props) {
  const { t } = useTranslation();
  const { sources } = useContext(WizardContext);
  const [MAILS, setMails] = useState<boolean>(sources.MAILS);
  const [IMS, setIms] = useState<boolean>(sources.IMS);
  const [CALLS, setCalls] = useState<boolean>(sources.CALLS);
  const [CALENDAR, setCalendar] = useState<boolean>(sources.CALENDAR);
  const [CONTACTS, setContacts] = useState<boolean>(sources.CONTACTS);

  useEffect(() => {
    onChange({ MAILS, IMS, CALLS, CALENDAR, CONTACTS });
  }, [MAILS, IMS, CALLS, CALENDAR, CONTACTS]);

  return (
    <div>
      <Typography>{t('wizard.sourceSelection.title')}</Typography>
      {MailSupportedAccountTypes.includes(account_type) && <SourceSelector source="MAILS" selected={MAILS} onChange={setMails} />}
      {ImSupportedAccountTypes.includes(account_type) && <SourceSelector source="IMS" selected={IMS} onChange={setIms} />}
      {CallSupportedAccountTypes.includes(account_type) && <SourceSelector source="CALLS" selected={CALLS} onChange={setCalls} />}
      {CalendarSupportedAccountTypes.includes(account_type) && (
        <SourceSelector source="CALENDAR" selected={CALENDAR} onChange={setCalendar} />
      )}
      {ContactSupportedAccountTypes.includes(account_type) && (
        <SourceSelector source="CONTACTS" selected={CONTACTS} onChange={setContacts} />
      )}
    </div>
  );
}
