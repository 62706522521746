import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';

import {
  NewCalendarEventDTO,
  calendarsFormSelector,
  createCalendarEvent,
  eventDraftCanceled,
  updateCalendarEvent,
  useAppDispatch,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { IconButton, makeStyles } from '@material-ui/core';

import { EventForm } from '../Event/EventForm';

const useClasses = makeStyles((theme) => ({
  container: {
    borderRadius: 10,
    padding: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[3],
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },
}));

export default function CalendarFormModal() {
  const { mode, eventDraft, editedEventId, recurringEventRoot } = useSelector(calendarsFormSelector);
  const [confirm, setConfirm] = useState<boolean>(false);
  const confirmDialog = useConfirm();
  const [status, setStatus] = useState<'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS'>('IDLE');
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const { t } = useTranslation();

  if (mode !== 'CALENDAR') return null;

  const handleSubmit = async (draft: NewCalendarEventDTO) => {
    if (status === 'IDLE') {
      setStatus('LOADING');
      const err = editedEventId
        ? await dispatch(updateCalendarEvent(editedEventId, draft))
        : await dispatch(createCalendarEvent(draft));
      if (err) {
        alert(err);
      }
      setStatus('IDLE');
    }
  };

  const handleDirty = (dirty: boolean) => {
    setConfirm(dirty);
  };

  const handleClose = async () => {
    confirm &&
      (await confirmDialog({
        title: t('calendar.form.discardTitle'),
        description: t('calendar.form.discardAlert'),
        cancellationText: t('calendar.form.cancelButton'),
      }));
    dispatch(eventDraftCanceled());
  };

  return (
    <div className={classes.container}>
      <IconButton className={classes.close} onClick={handleClose}>
        <IonIcon icon={closeOutline} />
      </IconButton>
      <EventForm
        defaultValues={eventDraft}
        onSubmit={handleSubmit}
        isDirty={handleDirty}
        editedEventId={editedEventId}
        recurringEventRoot={recurringEventRoot}
      />
    </div>
  );
}
