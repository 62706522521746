import { ViewNotification } from '@focus-front/core';
import React, { HTMLAttributes } from 'react';
import {
  AndroidIncomingCallIcon,
  AndroidMessagesAccountIcon,
  AndroidMissedCallIcon,
  AndroidNoAnswserCallIcon,
  AndroidOutgoingCallIcon,
  GmailAccountIcon,
  ICloudAccountIcon,
  LinkedInAccountIcon,
  MailAccountIcon,
  MessengerAccountIcon,
  TikTokAccountIcon,
  TwitterAccountIcon,
  InstagramAccountIcon,
  MissedEventIcon,
  OutlookAccountIcon,
  WhatsAppAccountIcon,
} from './UnipileIcon';

interface Props {
  notification: ViewNotification;
  size: number;
}

export function NotificationIcon({ notification, size, className }: Props & HTMLAttributes<HTMLDivElement>) {
  function getIcon() {
    switch (notification.type) {
      case 'MAIL_NEW': {
        switch (notification.metadata?.attached_entity?.account_type) {
          case 'GOOGLE': {
            return <GmailAccountIcon size={size} />;
          }
          case 'MAIL': {
            return <MailAccountIcon size={size} />;
          }
          case 'OUTLOOK': {
            return <OutlookAccountIcon size={size} />;
          }
          case 'ICLOUD': {
            return <ICloudAccountIcon size={size} />;
          }
        }
      }
      case 'MESSAGE_NEW': {
        switch (notification.metadata?.attached_entity?.account_type) {
          case 'LINKEDIN': {
            return <LinkedInAccountIcon size={size} />;
          }
          case 'MESSENGER': {
            return <MessengerAccountIcon size={size} />;
          }
          case 'INSTAGRAM': {
            return <InstagramAccountIcon size={size} />;
          }
          case 'TIKTOK': {
            return <TikTokAccountIcon size={size} />;
          }
          case 'TWITTER': {
            return <TwitterAccountIcon size={size} />;
          }
          case 'MOBILE': {
            return <AndroidMessagesAccountIcon size={size} />;
          }
          case 'WHATSAPP': {
            return <WhatsAppAccountIcon size={size} />;
          }
        }
      }
      case 'EVENT_MISSED': {
        return <MissedEventIcon size={size} />;
      }
      case 'CALL_OUTGOING': {
        return <AndroidOutgoingCallIcon size={size} />;
      }
      case 'CALL_INCOMING': {
        return <AndroidIncomingCallIcon size={size} />;
      }
      case 'CALL_MISSED': {
        return <AndroidMissedCallIcon size={size} />;
      }
      case 'CALL_NO_ANSWER': {
        return <AndroidNoAnswserCallIcon size={size} />;
      }
    }
    return null;
  }

  const icon = getIcon();

  if (icon)
    return (
      <div className={className} style={{ width: size, height: size }}>
        {icon}
      </div>
    );

  return null;
}
