import RRule from '@keep_focus/rrule-tz';
import { capitalize } from 'libs/core/src/Common/utils/string';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

const frenchStrings = {
  every: 'chaque',
  until: "jusqu'a",
  day: 'jour',
  days: 'jours',
  week: 'semaine',
  weeks: 'semains',
  on: 'le',
  in: 'in',
  'on the': 'le',
  for: 'pour',
  and: 'et',
  or: 'ou',
  at: 'à',
  last: 'dérniere',
  '(~ approximate)': '(~ approximatif)',
  times: 'fois',
  time: 'fois',
  minutes: 'minutes',
  hours: 'heures',
  weekdays: 'jours de la semaine',
  weekday: 'jour de la semaine',
  months: 'mois',
  month: 'mois',
  years: 'ans',
  year: 'année',
  st: '',
  nd: '',
  rd: '',
  th: '',
};
export const RRuleToText = ({ strRRule }) => {
  const language = {
    dayNames: dayjs.weekdays(),
    monthNames: dayjs.months(),
    tokens: {},
  };

  const getText = (id) => {
    return frenchStrings[id];
  };

  const dateFormat = (year, month, day) => dayjs().date(day).year(year).month(month).format('LL');

  return strRRule ? capitalize(RRule.fromString(strRRule).toText(getText, language, dateFormat)) : '';
};
