import { UTCDateTimeMs, Uuid } from '../../../Common';

export type MailDraftType = 'REPLY' | 'TRANSFER' | 'NEW' | 'REPLY_ALL';

export interface MailDraftAttendee {
  display_name?: string | undefined;
  identifier: string;
}

/**
 * Projection.
 */
export type ViewMailDraft = Readonly<{
  id: Uuid /** Aggregate id. */;
  account_id?: Uuid;
  type: MailDraftType;
  from_attendee?: MailDraftAttendee;
  to_attendees?: MailDraftAttendee[];
  cc_attendees?: MailDraftAttendee[];
  bcc_attendees?: MailDraftAttendee[];
  reply_to_attendees?: MailDraftAttendee[];
  subject?: string;
  body?: string;
  update_date?: UTCDateTimeMs;
  tags?: string[];
  attachment_ids?: string[];
  parent_mail_id?: Uuid;
}>;

export type NewMailDraftDTO = Omit<ViewMailDraft, 'id'>;

export type UpdateMailDraftDTO = Partial<NewMailDraftDTO>;

/**
 *
 */
export function createViewMailDraft(aggregateId: Uuid, values: NewMailDraftDTO): ViewMailDraft {
  if (!values.type) throw new Error('Missing values to instantiate a MailDraft');
  return {
    ...values,
    id: aggregateId,
  };
}
