import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  accountIdentifier,
  getSignaturesList,
  MailSupportedAccountTypes,
  ViewSignature,
  deleteAccount,
  useAppDispatch,
  Uuid,
  stopAccountsCron,
  restartAccountsCron,
  Taggable,
  ViewAccount,
  getAccount,
  setSettingsDialogTab,
  Tab,
  //   getAccountCalendars,
} from '@focus-front/core';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Add, ArrowBack, Delete, Edit } from '@material-ui/icons';
import AlertMui from '@material-ui/lab/Alert';

import { useConfirm } from 'material-ui-confirm';
import parse from 'html-react-parser';
import ReconnectMail from './ReconnectMail';
import ReconnectLinkedIn from './ReconnectLinkedIn';
import ReconnectMessenger from './ReconnectMessenger';
import ReconnectInstagram from './ReconnectInstagram';
import ReconnectTikTok from './ReconnectTikTok';
import ReconnectTwitter from './ReconnectTwitter';
import ReconnectWhatsApp from './ReconnectWhatsApp';
import ReconnectMicrosoft from './ReconnectMicrosoft';
import ReconnectGoogleCalendar from './ReconnectGoogleCalendar';
import DebugWebView from './DebugWebView';
import EditableTags from '../Tag/EditableTags';
import AccountPageSources from './AccountPageSources';
import { AccountTypeIcon } from '@focus-front/ui';

const useClasses = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  accIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  source: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
  },
  sourceIcon: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.grey[800],
  },
  sourceMsg: {
    marginLeft: theme.spacing(1),
  },
  sourceDesc: {
    flex: 1,
  },
  check: {
    color: theme.palette.success.main,
  },
  pause: {
    color: theme.palette.warning.main,
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
  paramsRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  alert: {
    maxWidth: 350,
  },
  signatureEditor: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Alert = withStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
  },
  message: {
    flex: 1,
  },
  action: {
    padding: 0,
    margin: 0,
    width: '100%',
    justifyContent: 'flex-end',
  },
}))(AlertMui);

type Props = {
  onBack: () => void;
  account_id: Uuid;
};

export default function AccountPage({ onBack, account_id }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);
  const [reconnect, setReconnect] = useState<boolean>(false);
  const [signature, setSignature] = useState<ViewSignature>(null);
  const [account, setAccount] = useState<ViewAccount & Taggable>(null);

  const setTab = (tab: Tab) => {
    dispatch(setSettingsDialogTab(tab));
  }

  useEffect(() => {
    (async () => {
      const account = await dispatch(getAccount(account_id));
      if (account) {
        setAccount(account);
        const { result, error } = await dispatch(getSignaturesList());
        if (!error && account.current_signature) setSignature(result.find((sign) => sign.id === account.current_signature));
        // if (account.type === 'GOOGLE_CALENDAR') {
        //   dispatch(getAccountCalendars(account.id));
        // }
      }
    })();
  }, [account_id]);

  const handleDelete = async () => {
    await confirm({
      title: t('accountSettings.danger.delete'),
      description: t('accountSettings.danger.deleteMessage'),
      confirmationText: t('accountSettings.danger.confirmationText'),
      cancellationText: t('accountSettings.danger.cancellationText'),
    });
    await dispatch(stopAccountsCron());
    setLoading(true);
    const error = await dispatch(deleteAccount(account_id));
    setLoading(false);
    if (error) return alert(error);
    dispatch(restartAccountsCron());
    onBack();
  };

  if (!account) return null;

  return (
    <div>
      <div className={c.header}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={onBack}>
            <ArrowBack />
          </IconButton>

          <AccountTypeIcon account_type={account.type} size={82} className={c.accIcon} />

          <div>
            <Box display="flex" alignItems="center">
              <Typography variant="h2">{account.name || accountIdentifier(account.connection_params)}</Typography>
              <Tooltip title={t('soon')}>
                <IconButton size="small">
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="body1">{accountIdentifier(account.connection_params)}</Typography>
          </div>
        </Box>
      </div>
      <div className={c.sectionTitle}>
        <Typography variant="h2">{t('accountSettings.sources.title')}</Typography>
        <Typography variant="subtitle2">{t('accountSettings.sources.subtitle')}</Typography>
      </div>
      <AccountPageSources account_id={account.id} account_type={account.type} />

      <section>
        <div className={c.sectionTitle}>
          <Typography variant="h2">{t('accountSettings.tags.title')}</Typography>
          <Typography variant="subtitle2">{t('accountSettings.tags.subtitle')}</Typography>
        </div>
        <EditableTags
          emptyBtn={(handleClick) => (
            <Button onClick={handleClick} variant="outlined" startIcon={<Add />}>
              {t('accountSettings.tags.emptyBtn')}
            </Button>
          )}
          tags={account.tags}
          element="ACCOUNT"
          element_id={account.id}
        />
      </section>

      {/* Signatures managment for Mail supported accounts */}
      {MailSupportedAccountTypes.includes(account.type) && (
        <section>
          <div className={c.sectionTitle}>
            <Typography variant="h2">{t('accountSettings.signatures.title')}</Typography>
            <Typography variant="subtitle2">{t('accountSettings.signatures.subtitle')}</Typography>
          </div>

          {signature ? (
            <div className={c.signatureEditor}>{parse(signature.body)}</div>
          ) : (
            <Typography variant="body1" style={{ marginBottom: 8 }}>
              Pas de signature
            </Typography>
          )}
          <Button variant="outlined" onClick={() => setTab('mails/signatures')}>
            {t('accountSettings.signatures.manage')}
          </Button>
        </section>
      )}

      {account.type === 'MAIL' && (
        <section>
          <div className={c.sectionTitle}>
            <Typography variant="h2">{t('accountSettings.connectionParams.title')}</Typography>
            <Typography variant="subtitle2">{t('accountSettings.connectionParams.subtitle')}</Typography>
          </div>

          <div className={c.paramsRow}>
            <TextField
              label="Incoming server host"
              name="imap_host"
              placeholder="mail.example.com"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              disabled
              value={account.connection_params.mail.imap_host}
            />

            <TextField
              label="Incoming server port"
              name="imap_port"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="number"
              disabled
              value={account.connection_params.mail.imap_port}
            />
          </div>

          <div className={c.paramsRow}>
            <TextField
              label="Outgoing server host"
              name="smtp_host"
              placeholder="smtp.example.com"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              disabled
              value={account.connection_params.mail.smtp_host}
            />

            <TextField
              label="Outgoing server port"
              name="smtp_port"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="number"
              disabled
              value={account.connection_params.mail.smtp_port}
            />
          </div>
          <Tooltip title={t('soon')}>
            <Button variant="outlined">{t('accountSettings.connectionParams.edit')}</Button>
          </Tooltip>
        </section>
      )}
      <div className={c.sectionTitle}>
        <Typography variant="h2">{t('accountSettings.danger.title')}</Typography>
      </div>
      <Button
        className={c.deleteBtn}
        variant="outlined"
        startIcon={loading ? <CircularProgress size={20} /> : <Delete />}
        onClick={handleDelete}
        disabled={loading}
      >
        {t('accountSettings.danger.delete')}
      </Button>
      <div>
        <Typography variant="caption">{t('accountSettings.danger.deleteMessage')}</Typography>
      </div>

      {/* {account.type === 'GOOGLE' && (
              <ReconnectGoogle account_id={account_id} isOpen={reconnect} onClose={() => setReconnect(false)} />
            )} */}

      <DebugWebView id={account.type === 'WHATSAPP' ? 'WHATSAPP' : account_id} />
    </div>
  );
}
