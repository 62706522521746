/**
 * @todo Consider if being more specific with ContactEdited is useful, e.g. :
 *
 *         ContactPhoneAdded,
 *         ContactEmailAdded,
 *         ContactAddressAdded,
 *         ContactWebsiteAdded,
 *         ContactJobPositionAdded,
 *         ContactSocialAdded.
 */

import { DomainEventShape, Uuid } from '../../../Common';
import { NewContactDTO, UpdateContactDTO } from '../projections/ViewContact';

/**
 * DomainEvent.
 */
export class ContactCreated implements DomainEventShape {
  readonly type = 'CONTACT_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly contact: NewContactDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ContactDeleted implements DomainEventShape {
  readonly type = 'CONTACT_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class ContactEdited implements DomainEventShape {
  readonly type = 'CONTACT_EDITED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly updatedValues: UpdateContactDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type ContactDomainEvent = ContactCreated | ContactDeleted | ContactEdited;
