import { ClientOptions, Client, AuthenticationProvider } from '@microsoft/microsoft-graph-client';

/**
 * Microsoft Graph Client
 * Instanciate a new Client with our custom Authentication Provider to access the Graph API
 */
export class MicrosoftGraphClient {
  public client: Client;
  constructor(authProvider: AuthenticationProvider) {
    let clientOptions: ClientOptions = {
      authProvider,
    };
    this.client = Client.initWithMiddleware(clientOptions);
  }
}
