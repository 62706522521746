import React from 'react';
import {
  Avatar,
  Button,
  ButtonGroup,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  PopoverProps,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { selectSearchResult, useAppDispatch, ViewContact, writeNewMail } from '@focus-front/core';
import { LinkedIn, Mail, Phone, WhatsApp, Facebook } from '@material-ui/icons';
import AttendeeAvatar from './AttendeeAvatar';
import ContactView from './ContactView';
import { AccountTypeIcon } from '@focus-front/ui';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  infos: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  infos2: {
    marginLeft: theme.spacing(2),
  },
  itemCont: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  accIcon: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    color: theme.palette.grey[800],
    height: 50,
    width: 50,
    fontSize: 30,
    marginRight: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  contact: ViewContact;
} & PopoverProps;

export default function ContactPopover({ contact, ...popoverProps }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 2,
      }}
      {...popoverProps}
    >
      <div className={c.root}>
        <div className={c.header}>
          <div className={c.infos}>
            <AttendeeAvatar size="default" display_name={contact.full_name} avatar_url={contact.profile_picture} />
            <div className={c.infos2}>
              <Typography variant="h4">{contact.full_name}</Typography>
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch(selectSearchResult({ id: contact.id, type: 'contact' }))}
              >
                {t('contact.popover.action')}
              </Link>
            </div>
          </div>
        </div>
        {/* <LightTabs value={tab}>
        <LightTab value="contact" label="Contact" onClick={() => setTab('contact')} />
      </LightTabs> */}

        {contact && (
          <>
            {/* <Typography variant="h3" className={c.sectionTitle}>
              Informations de contact
            </Typography> */}
            <List>
              {contact.email?.map((email) => (
                <Paper variant="outlined" className={c.itemCont} key={email.label}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={c.accIcon}>
                        <AccountTypeIcon size={40} account_type="MAIL" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="h3">{email.label}</Typography>} secondary={email.address} />
                    <ListItemSecondaryAction
                    // onClick={async () => {
                    //   const id = await dispatch(
                    //     writeNewMail({
                    //       type: 'NEW',
                    //       to_attendees: [
                    //         {
                    //           display_name: contact.full_name,
                    //           identifier: email.address,
                    //         },
                    //       ],
                    //     })
                    //   );
                    //   history.push(`/work/${id}?action=write`);
                    // }}
                    >
                      <Button color="secondary">Envoyer un email</Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              ))}
              {contact.phone?.map((phone) => (
                <Paper variant="outlined" className={c.itemCont} key={phone.number}>
                  {phone.label === 'WHATSAPP' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type="WHATSAPP" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h4">{t('contact.whatsappConnected')}</Typography>}
                          secondary={phone.number}
                        />
                      </ListItem>
                    </>
                  )}
                </Paper>
              ))}
              {contact.social?.map((social) => (
                <Paper variant="outlined" className={c.itemCont} key={social.identifier}>
                  {social.identifier_type === 'LINKEDIN_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'LINKEDIN'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h4">{t('contact.linkedinConnected')}</Typography>}
                          secondary={
                            <Link href={`https://linkedin.com/in/${social.identifier}`}>{t('contact.openLinkedInProfile')}</Link>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                  {social.identifier_type === 'MESSENGER_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'MESSENGER'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h4">{t('contact.messengerConnected')}</Typography>}
                          secondary={
                            <Link href={`https://www.facebook.com/${social.identifier}`}>
                              {t('contact.openMessengerProfile')}
                            </Link>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                  {social.identifier_type === 'TWITTER_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'TWITTER'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h4">{t('contact.twitterConnected')}</Typography>}
                          secondary={
                            <Link href={`https://twitter.com/${social.identifier}`}>{t('contact.openTwitterProfile')}</Link>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                  {social.identifier_type === 'TIKTOK_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'TIKTOK'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h4">{t('contact.tiktokConnected')}</Typography>}
                          secondary={
                            <Link href={`https://www.tiktok.com/@${social.identifier}`}>{t('contact.openTikTokProfile')}</Link>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                  {social.identifier_type === 'INSTAGRAM_ID' && (
                    <>
                      <ListItem style={{ borderBottom: 'solid 1px #eee' }}>
                        <ListItemAvatar>
                          <AccountTypeIcon size={40} account_type={'INSTAGRAM'} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h4">{t('contact.instagramConnected')}</Typography>}
                          secondary={
                            <Link href={`https://www.instagram.com/${social.identifier}`}>
                              {t('contact.openInstagramProfile')}
                            </Link>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                </Paper>
              ))}
            </List>
          </>
        )}
      </div>
    </Popover>
  );
}
