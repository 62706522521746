import { loginInstagram, useAppDispatch } from '@focus-front/core';
import { Box, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { InstagramLoginForm, WizardContext } from './WizardContext';

export default function InstagramLogin() {
  const { t } = useTranslation();

  const { goBack, forms, setLoading, setForms, goNext } = useContext(WizardContext);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit } = useForm<InstagramLoginForm>({
    mode: 'onChange',
    defaultValues: forms.INSTAGRAM_LOGIN,
  });

  const onSubmit = async ({ instagram_user, instagram_password }: InstagramLoginForm) => {
    setError(null);
    setLoading(true);
    setForms({
      ...forms,
      INSTAGRAM_LOGIN: { instagram_user, instagram_password, instagram_cookies: undefined },
    });

    const { error, result } = await dispatch(loginInstagram(instagram_user, instagram_password));

    if (error) {
      // This error comes from ScrapFormAuthService
      if (error === 'Invalid Credentials') {
        setError(t('wizard.instagramLogin.invalidCredentials'));
      } else {
        setError(error);
      }
      setLoading(false);
    } else {
      if (result) {
        setForms({
          ...forms,
          INSTAGRAM_LOGIN: { instagram_user, instagram_password, instagram_cookies: result },
        });
      }
      goNext();
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.instagramLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.instagramLogin.subtitle')}</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        <Box mt={3} mb={5} maxWidth={300}>
          <TextField
            inputRef={register({ required: true })}
            name="instagram_user"
            variant="standard"
            label={t('wizard.instagramLogin.userLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            autoComplete="off"
            autoFocus
          />
          <TextField
            inputRef={register({ required: true })}
            name="instagram_password"
            variant="standard"
            label={t('wizard.instagramLogin.passwordLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 8 }}
            autoComplete="new-password"
            type="password"
          />
        </Box>
        <NextButton type="submit" />
      </form>
    </div>
  );
}
