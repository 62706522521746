import { AggregateRoot, createProjectionConfig, createUuidFrom, DomainEvent, Uuid } from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { NewImThreadDTO, ImThreadSyncStatus, UpdateImThreadDTO } from './projections/ViewImThread';
import {
  ImThreadAcknowledged,
  ImThreadAcknowledgedAttendees,
  ImThreadAllPastFetched,
  ImThreadSubmitted,
  ImThreadSynced,
} from './events/ImThread.events';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
export const IM_THREAD_NAMESPACE = '6f24d70e-2190-4dbb-bb81-74f0f3b827fe' as Uuid;
/**
 * DecisionProjection initial state.
 */
const initialState = {
  sync_status: 'NOT_SYNC' as ImThreadSyncStatus,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  IM_THREAD_SYNCED: (event) => ({
    id: event.aggregateId,
    sync_status: 'SYNC',
  }),
  IM_THREAD_SUBMITTED: (event) => ({
    id: event.aggregateId,
    sync_status: 'NOT_SYNC',
  }),
});

const imThreadType = {
  SUBMIT: ImThreadSubmitted,
  SYNC: ImThreadSynced,
};

/**
 * Aggregate.
 */
export class ImThread extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static create(content: NewImThreadDTO, origin: 'SUBMIT' | 'SYNC') {
    const aggregateId = createUuidFrom(content.provider_id + content.account_id, IM_THREAD_NAMESPACE);

    return ImThread.accept(new imThreadType[origin](aggregateId, content));
  }

  /**
   * Command.
   */
  acknowledge(values: UpdateImThreadDTO) {
    return this.projection.state.sync_status === 'SYNC'
      ? this.reject('ALREADY SYNCED')
      : this.apply(new ImThreadAcknowledged(this.id, values, this.version)).accept();
  }

  /**
   * Command.
   */
  acknowledgeAttendees(values: Pick<UpdateImThreadDTO, 'attendees'>) {
    return this.apply(new ImThreadAcknowledgedAttendees(this.id, values, this.version)).accept();
  }

  /**
   * Command.
   */
  hasAllPastFetched() {
    return this.apply(new ImThreadAllPastFetched(this.id, this.version)).accept();
  }
}
