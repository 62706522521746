import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { closeInboxSnackbar, inboxSnackbarsSelector, useAppDispatch } from '@focus-front/core';
import { IconButton, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface SnackbarMessage {
  message: string;
  key: number;
}

const useClasses = makeStyles((theme) => ({
  snackBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'none',
  },
  snackBarContent: {
    backgroundColor: theme.palette.success.main,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    color: '#fff',
  },
}));

export default function InboxSnackbar() {
  const snackPack = useSelector(inboxSnackbarsSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const c = useClasses();
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      dispatch(closeInboxSnackbar());
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      className={c.snackBar}
    >
      <div className={c.snackBarContent}>
        <Typography variant="subtitle1">{messageInfo?.message}</Typography>
      </div>
    </Snackbar>
  );
}
