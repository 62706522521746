import { InvalidCredentialsError, MissingCredentialsError } from '@focus-front/proxy';
import { AuthenticationProvider } from '@microsoft/microsoft-graph-client';
import dayjs from 'dayjs';
import { OAuthCredentials } from '../../../../Account';
import { toUTCDateTime, UTCDateTime } from '../../../domain/Date';

/**
 * Authentication provider for the Web App
 * Manage the AT and RT Lifecycle and provide the AT to a Microsoft Client.
 */
export class MicrosoftGraphAuthProvider implements AuthenticationProvider {
  expires_at: UTCDateTime;
  at: string | null;
  rt: string | null;
  constructor(
    protected clientId: string,
    private getCredentials: (account_id: string) => Promise<OAuthCredentials | null>,
    public account_id: string
  ) {
    this.expires_at = toUTCDateTime(dayjs());
    this.at = null;
    this.rt = null;
  }

  /**
   * Get credentials and save tokens in the memory
   */
  protected async authenticate(): Promise<boolean> {
    let credentials: OAuthCredentials | null = null;

    // Get account credentials with function passed to creator
    try {
      if (this.getCredentials) credentials = await this.getCredentials(this.account_id);
    } catch (e) {
      throw new InvalidCredentialsError();
    }

    // Check if retrieved credentials are good for Gmail
    if (!credentials) throw new MissingCredentialsError();
    if (!credentials.at || !credentials.rt) throw new InvalidCredentialsError();

    // Save tokens in memory in base 64 as they are transfered in headers in this format
    this.at = credentials.at;
    this.rt = credentials.rt;

    return true;
  }

  /**
   * Verify if the at is valid
   */
  protected isAtExpired(): boolean {
    return !this.expires_at || dayjs(this.expires_at).isBefore(dayjs());
  }

  /**
   * Retrieve the AT
   */
  async getAccessToken(): Promise<string> {
    // If tokens are not in memory
    if (!this.at || !this.rt) await this.authenticate();

    // If AT is expired, call then token endpoint with the RT to get a new AT and set it in memory
    if (this.isAtExpired()) {
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');

      var urlencoded = new URLSearchParams();
      urlencoded.append('client_id', this.clientId);
      urlencoded.append('refresh_token', this.rt || '');
      urlencoded.append('grant_type', 'refresh_token');
      urlencoded.append('scope', 'User.Read'); // Set a minimal scope, but scopes already granted stay granted

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: urlencoded,
      };

      const response = await fetch('https://login.microsoftonline.com/common/oauth2/v2.0/token', requestOptions);

      const data = await response.json();

      if (!data.refresh_token || !data.expires_in || !data.access_token)
        throw new InvalidCredentialsError(
          'Cannot refresh microsoft AT : ' + data.error_description || data.error || 'Undefined error'
        );

      this.expires_at = toUTCDateTime(dayjs().add(data.expires_in, 'second'));
      this.at = data.access_token;
      this.rt = data.refresh_token;
    }

    if (!this.at) throw new Error('Cannot get microsoft AT');

    // Return the in memory AT
    return this.at;
  }
}
