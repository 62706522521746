import React from 'react';

import { CalendarEventStatus, ViewCalendarEvent, HIDE_DATETIME_THRESHOLD } from '@focus-front/core';
import { makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { CalendarEventIcon } from '@focus-front/ui';
import dayjs from 'dayjs';

interface ClassProps {
  draft: boolean;
  preview: boolean;
  status: CalendarEventStatus;
  duration: number;
  backgroundColor?: string;
}
const useClasses = makeStyles((theme) => ({
  icon: {
    position: 'absolute',
    left: -5,
    top: -2,
  },
  summary: (props: ClassProps) => ({
    color: props.status === 'DONE' || props.status === 'MISSED' ? theme.palette.text.secondary : '#fff',
    fontSize: props.duration <= 7 && 10,
  }),
  wrapper: (props: ClassProps) => {
    return {
      '&:hover > *:last-child':
        props.status === 'PLANNED'
          ? {
              visibility: 'initial',
            }
          : {},
      paddingLeft: 20,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      background: props.draft && `repeating-linear-gradient(45deg, #fa9e42, #fa9e42 10px, #ff7f00 10px, #ff7f00 20px)`,
      cursor: 'pointer',
      overflow: 'hidden',
    };
  },
}));

interface Props {
  event: Partial<Pick<ViewCalendarEvent, 'metadata' | 'location' | 'start' | 'end' | 'summary' | 'id' | 'status' | 'all_day'>>;
  backgroundColor?: string;
  draft: boolean;
  preview: boolean;
}

const NewCalendarEvent = ({ event, draft, preview, backgroundColor }: Props) => {
  const { t } = useTranslation();

  const duration = dayjs(event.end).diff(dayjs(event.start), 'minute');

  const c = useClasses({
    draft,
    preview,
    status: event.status,
    duration,
    backgroundColor,
  });

  return (
    <div className={c.wrapper}>
      <CalendarEventIcon event={event} size={20} className={c.icon} />
      <div className={c.summary} style={{ fontWeight: 500 }}>
        {event.summary || t('calendar.form.defaultTitle')}
      </div>
      {duration >= HIDE_DATETIME_THRESHOLD && (
        <div className={c.summary}>
          {!event.all_day && `${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}`}
          {event.location && `, ${event.location}`}
        </div>
      )}
    </div>
  );
};

export default React.memo(NewCalendarEvent, () => true);
