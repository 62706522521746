import { Uuid } from '../../Common';
import { ViewAccountRepo } from '../../Account';
import { ViewCalendarEventRepo } from '../../Calendar/domain/projections/ViewCalendarEventRepo';
import { ViewCallRepo } from '../../Call';
import { Attendee, ViewContactRepo } from '../../Contact';
import { ViewImRepo } from '../../Im';
import { ViewImThreadRepo } from '../../Im/domain/projections/ViewImThreadRepo';
import { ViewMailDraftRepo, MailReferenceRepo } from '../../Mail';
import {
  AttendeeDocument,
  CallDocument,
  ContactDocument,
  EventDocument,
  ImDocument,
  MailDocument,
  SearchResult,
  SearchService,
} from '../infra/services/SearchService';
import { SearchUseCase } from './SearchUseCase';
import { MailSyncService } from '../../Mail/infra/services/MailSyncService';

export class AppSearchUseCase implements SearchUseCase {
  constructor(
    private search: SearchService,
    private viewContact: ViewContactRepo,
    private viewMail: MailReferenceRepo,
    private viewMailDraft: ViewMailDraftRepo,
    private viewIm: ViewImRepo,
    private viewCalendarEvent: ViewCalendarEventRepo,
    private viewCall: ViewCallRepo,
    private viewImThread: ViewImThreadRepo,
    private viewAccount: ViewAccountRepo,
    private mailSync: MailSyncService
  ) {}

  /**
   * @todo Do some profiling and check how much memory this requires on large-ish
   *       accounts.
   *
   *       If this turns out to be too much, we can try to :
   *       - split index in smaller functions, one per things to index. This may
   *         help by letting objects get out of scope.
   *       - work in chunks on each things to index.
   */
  async index(): Promise<void> {
    console.time('Search index in ');
    const accounts = await this.viewAccount.getAll();

    /** Uuids are globally unique, we shouldn't need to group by account types. */
    // const accountIdsByType = groupAsSetBy(
    //   accounts,
    //   (a) => a.type,
    //   (a) => a.id
    // );
    /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
    const accountIds = new Set(accounts.map((a) => a.id));

    /**
     * Do as much as possible in parallel, using the results that have no data
     * dependencies as soon as they are available.
     *
     * @todo Ask what to do with contacts : they may reference multiple accounts.
     *       No filtering applied for now.
     */
    const results = await Promise.all([
      this.indexMails(accountIds),
      this.viewMailDraft
        .getAll()
        .then((drafts) =>
          this.search.indexMailDrafts(drafts.filter((d) => d.account_id === undefined || accountIds.has(d.account_id)))
        ),
      this.viewContact.getAll().then((contacts) => this.search.indexContacts(contacts)),
      this.viewCalendarEvent
        .getAll()
        .then((events) => this.search.indexEvents(events.filter((e) => !('account_id' in e) || accountIds.has(e.account_id)))),
      this.viewCall.getAll().then((calls) => this.search.indexCalls(calls.filter((c) => accountIds.has(c.account_id)))),
      this.viewIm.getAll(),
      this.viewImThread.getAll(),
    ]);

    /** Handle results that have data dependencies. */
    const [ims, im_threads] = [results[5], results[6]];
    await this.search.indexIms(
      ims.map((im) => {
        const account_type = accounts.find((acc) => acc.id === im.account_id)?.type;
        return { ...im, ...(account_type && { account_type }) };
      }),
      im_threads
    );

    console.timeEnd('Search index in ');
    // const mails = await this.viewMail.getAll();
    // const drafts = await this.viewMailDraft.getAll();
    // const contacts = await this.viewContact.getAll();
    // const events = (await this.viewCalendarEvent.getAll()).filter(
    //   (e) => !('account_id' in e) || accountIdsByType['GOOGLE_CALENDAR'].has(e.account_id)
    // );
    // const calls = await this.viewCall.getAll();
    // const ims = await this.viewIm.getAll();
    // const im_threads = await this.viewImThread.getAll();

    // await this.search.indexMails(mails, drafts);
    // await this.search.indexContacts(contacts);
    // await this.search.indexEvents(events);
    // await this.search.indexCalls(calls);
    // const attendees: AttendeeDocument[] = [];

    // /**
    //  * Loop over contact mails to create a list of attendees
    //  * Fuse let us boost keys on search, but does not alow to boost documents,
    //  * So we put a weight of 2 then we can sort the search results to show contacts first
    //  * See https://github.com/krisk/Fuse/issues/444
    //  * */
    // contacts.forEach((contact) => {
    //   contact.email?.forEach((mail) => {
    //     const found = attendees.find((att) => att.identifier === mail.email);
    //     if (!found)
    //       attendees.push({
    //         display_name: contact.display_name || contact.firstname + ' ' + contact.lastname,
    //         identifier: mail.email,
    //         identifier_provider: 'MAIL',
    //         weight: 2,
    //       });
    //   });
    // });

    // // Loop over mails to get attendees not saved as contact
    // mails.forEach((mail) => {
    //   const found = attendees.find((att) => att.identifier === mail.from_attendee.identifier);
    //   if (!found) attendees.push({ ...mail.from_attendee, weight: 1 });
    // });

    // await this.search.indexAttendees(attendees);
  }

  async indexMails(active_accounts_ids: Set<Uuid>): Promise<void> {
    const all_mails = await this.mailSync.getAll();
    const all_drafts = await this.viewMailDraft.getAll();

    const mails = all_mails.filter((mail) => active_accounts_ids.has(mail.account_id));
    const drafts = all_drafts.filter((draft) => draft.account_id === undefined || active_accounts_ids.has(draft.account_id));

    await this.search.indexMails(mails, drafts);

    const attendees: AttendeeDocument[] = [];

    mails.forEach((mail) => {
      const found = attendees.find((att) => att.identifier === mail.from_attendee.identifier);
      if (!found) attendees.push({ ...mail.from_attendee, weight: 1 });
    });

    await this.search.indexAttendees(attendees);
  }

  /**
   *
   */
  async indexCalendars(): Promise<void> {
    /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
    const calendarAccountIds = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));

    const events = (await this.viewCalendarEvent.getAll()).filter(
      (e) => !('account_id' in e) || calendarAccountIds.has(e.account_id)
    );
    return this.search.indexEvents(events);
  }

  async searchMails(query: string): Promise<SearchResult<MailDocument>[]> {
    return this.search.searchMails(query);
  }

  async searchEvents(query: string): Promise<SearchResult<EventDocument>[]> {
    return this.search.searchEvents(query);
  }

  async searchCalls(query: string): Promise<SearchResult<CallDocument>[]> {
    return this.search.searchCalls(query);
  }

  async searchIms(query: string): Promise<SearchResult<ImDocument>[]> {
    return this.search.searchIms(query);
  }

  async searchContacts(query: string): Promise<SearchResult<ContactDocument>[]> {
    return this.search.searchContacts(query);
  }

  async searchAttendees(query: string): Promise<SearchResult<AttendeeDocument>[]> {
    const results = await this.search.searchAttendees(query);
    return results
      .map((res) => ({
        ...res,
        score: res.score ? res.score * (1 - res.item.weight || 1) : 1,
      }))
      .sort((a, b) => a.score - b.score);
  }
}
