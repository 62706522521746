import { tagsSelector, ViewTag } from '@focus-front/core';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import Tag from '../Tag/Tag';

type Props = {
  onTagClick: (tag: ViewTag) => void;
};

export default function SearchTagsCloud({ onTagClick }: Props) {
  const tags = useSelector(tagsSelector);

  return (
    <div>
      {tags.map((tag) => (
        <Tag tag={tag} key={tag.id} size="medium" onClick={() => onTagClick(tag)} />
      ))}
    </div>
  );
}
