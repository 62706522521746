import { ViewTagRelationRepo, ViewTagRepo, Taggable, ViewTagRelation } from '../../../Tag';
import { Uuid } from '../../../Common';
import { Idb } from '../../../Common/infra/services/idb';
import { AccountType, createViewAccount, UpdateAccountDTO, ViewAccount } from '../../domain';
import { ViewAccountRepo } from '../../domain/projections/ViewAccountRepo';
import { notEmpty } from '../../../Common/utils';

/**
 * Repository.
 */
export class IdbViewAccountRepo implements ViewAccountRepo {
  constructor(private readonly viewTagRelation: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewAccount): Promise<Uuid> {
    return Idb.put('viewAccounts', projection) as Promise<Uuid>;
  }

  /**
   *
   */
  //   async update<T extends UpdateAccountDTO>(id: Uuid, values: T) {
  async update<T extends UpdateAccountDTO>(id: Uuid, values: T): Promise<void> {
    const tx = Idb.transaction('viewAccounts', 'readwrite');
    // const old_projection = (await tx.store.get(id)) as (ViewAccount & { type: T['type'] }) | undefined;
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewAccount ${id} doesn't exist.`);
    }

    /** @note Invariants should be enforced in AggregateRoot. Commenting. */
    // if (values.type && old_projection.type !== values.type) {
    //   throw new Error(`Invalid type : update type ${values.type} doesn't match projection type ${old_projection.type}`);
    // }
    // const u = { ...old_projection, ...values };

    /** @note await should be unnecessary here. */
    // await Promise.all([tx.store.put(createViewAccount(id, { ...old_projection, ...values })), tx.done]);
    tx.store.put(createViewAccount(id, { ...old_projection, ...values }));
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid): Promise<void> {
    return Idb.delete('viewAccounts', id);
  }

  /**
   *
   */
  async clear(): Promise<void> {
    return Idb.clear('viewAccounts');
  }

  /**
   * Query.
   */
  async get(id: Uuid): Promise<(ViewAccount & Taggable) | null> {
    const account = await Idb.get('viewAccounts', id);
    if (!account) return null;
    const tags = await this.viewTagRelation.getTagsByElement('ACCOUNT', id);
    return { ...account, tags };
  }

  private async _getTags(results: ViewAccount[]): Promise<(ViewAccount & Taggable)[]> {
    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    for (let i = 0, length = results.length; i < length; ++i) {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['ACCOUNT', results[i].id]));
    }

    const allTags = await this.viewTagRepo.getAll();

    const accountsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...results[i], tags };
      })
    );

    return accountsWithTags;
  }

  /**
   * Query.
   */
  async getAll(): Promise<ViewAccount[]> {
    const accounts = await Idb.getAll('viewAccounts');
    return accounts;
  }

  /**
   * Query.
   */
  async getAllWithTags(): Promise<(ViewAccount & Taggable)[]> {
    const accounts = await Idb.getAll('viewAccounts');
    return this._getTags(accounts);
  }

  /**
   * Query.
   */
  async getByType(type: AccountType): Promise<ViewAccount[]> {
    return Idb.getAllFromIndex('viewAccounts', 'by-type', IDBKeyRange.only(type));
  }
}
