import React from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import { selectSearchTab, SearchableEntity, searchTabSelector, useAppDispatch } from '@focus-front/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LightTab from '../Common/LightTab';

const useClasses = makeStyles((theme) => ({
  selected: {
    fontWeight: 700,
    opacity: 1,
  },
  chip: {
    marginLeft: 5,
    fontWeight: 'initial',
  },
}));

const TOTAL_LIMIT = 999;

interface Props {
  value: SearchableEntity;
  selected: boolean;
}

export default function SearchHeaderTab({ value, selected }: Props) {
  const dispatch = useAppDispatch();
  const c = useClasses();
  const { t } = useTranslation();
  const { total_results, status } = useSelector(searchTabSelector(value));
  return (
    <LightTab
      label={
        <>
          {t('search.' + value)}{' '}
          {total_results > 0 && (
            <Chip className={c.chip} size="small" label={total_results < TOTAL_LIMIT ? total_results : TOTAL_LIMIT + '+'} />
          )}
        </>
      }
      className={selected && c.selected}
      value={value}
      onClick={() => dispatch(selectSearchTab(value))}
    />
  );
}
