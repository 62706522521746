import { Attendee } from '@focus-front/core';
import { Typography, Link } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AtendeeIdentifier({ attendee }: { attendee: Attendee }) {
  const { t } = useTranslation();

  if (attendee.identifier_type === 'EMAIL_ADDRESS' || attendee.identifier_type === 'PHONE_NUMBER')
    return <Typography variant="body2">{attendee.identifier}</Typography>;

  if (attendee.identifier_type === 'LINKEDIN_ID')
    return (
      <Typography variant="body2">
        <Link href={`https://linkedin.com/in/${attendee.identifier}`}>{t('contact.openLinkedInProfile')}</Link>
      </Typography>
    );

  if (attendee.identifier_type === 'MESSENGER_ID')
    return (
      <Typography variant="body2">
        <Link href={`https://www.facebook.com/${attendee.identifier}`}>{t('contact.openMessengerProfile')}</Link>
      </Typography>
    );

  if (attendee.identifier_type === 'TWITTER_ID')
    return (
      <Typography variant="body2">
        <Link href={`https://twitter.com/${attendee.identifier}`}>{t('contact.openTwitterProfile')}</Link>
      </Typography>
    );

  if (attendee.identifier_type === 'TIKTOK_ID')
    return (
      <Typography variant="body2">
        <Link href={`https://www.tiktok.com/@${attendee.identifier}`}>{t('contact.openTikTokProfile')}</Link>
      </Typography>
    );

  if (attendee.identifier_type === 'INSTAGRAM_ID')
    return (
      <Typography variant="body2">
        <Link href={`https://www.instagram.com/${attendee.identifier}`}>{t('contact.openInstagramProfile')}</Link>
      </Typography>
    );

  return <Typography variant="body2">Syncing...</Typography>;
}
