import { Box, createStyles, makeStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Image } from '@material-ui/icons';
import { Editor } from '@tiptap/react';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      border: 'none',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      transition: 'color .2s ease-out',
    },
  })
);

interface TipTapToolbarImageProps {
  editor: Editor;
  isEditorMail: boolean;
}

const TipTapToolbarImage: FC<TipTapToolbarImageProps> = ({ editor, isEditorMail }) => {
  const c = useStyles();
  const [image, setImage] = useState('');
  const imageRef = useRef(null);
  const editorid = isEditorMail ? 'mail' : 'signature'; // Workaround for double ref

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result.toString());
      };

      reader.readAsDataURL(files[0]);
    }
  };

  useEffect(() => {
    if (image && imageRef.current) {
      imageRef.current.value = image;
      // imageRef.current.click();
      editor.chain().focus().setImage({ src: image }).run();
    }
    // console.log(image);
  }, [image]);

  return (
    <Box>
      <input accept="image/*" type="file" id={`image-upload-${editorid}`} style={{ display: 'none' }} onChange={handleChange} />
      <button style={{ display: 'none' }} ref={imageRef} />
      <label htmlFor={`image-upload-${editorid}`}>
        <ToggleButton size="small" className={c.button} component="span" value="1">
          <Image color="inherit" />
        </ToggleButton>
      </label>
    </Box>
  );
};

export default TipTapToolbarImage;
