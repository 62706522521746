import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/web/WebContainer';
import { Invitation } from '../domain';
import { Result } from '../../Common';

export const initSubscriptionCheckCron =
  () =>
  async (d: AppDispatch, g: AppGetState, { subscription }: Container): Promise<void> => {
    const int = setInterval(async () => {
      const { error, result } = await subscription.checkSubscriptionChange();

      if (error) {
        clearInterval(int);
      }

      if (result) {
        window.location.reload();
      }
    }, 1000 * 10);
  };

/**
 * Bridge Thunk
 */
export const getReferralInvitationsList =
  () =>
  async (d: AppDispatch, g: AppGetState, { subscription }: Container): Promise<Result<Invitation[]>> => {
    return subscription.getReferralInvitationsList();
  };

/**
 * Bridge Thunk
 */
export const sendReferralInvitationsByEmail =
  (emails: string[]) =>
  async (d: AppDispatch, g: AppGetState, { subscription }: Container): Promise<Result<void>> => {
    return subscription.sendReferralInvitationsByEmail(emails);
  };
