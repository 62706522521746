/**
 *
 */
 export interface Comparator<T> {
    (a: T, b: T): number;
  }
  
  /**
   * @note Comparator function should return :
   *         < 0 if 'a' should go up
   *         > 0 if 'b' should go up
   *         = 0 if they're equal
   */
  export class Heap<TNode> {
    private _array: TNode[];
  
    /**
     * Return Heap wrapper around heapified shallow-copy of given array using
     * given comparator function.
     */
    constructor(public compare: Comparator<TNode>, array: TNode[] = []) {
      this._array = [...array];
      for (let i = array.length >> 1; i--; ) {
        this._bubbleDown(i);
      }
    }
  
    /**
     * Check if given array of nodes satisfy heap property according to given comparator function.
     */
    static check<TNode>(compare: Comparator<TNode>, array: TNode[]): boolean {
      for (let i = 0, last = array.length, half = last >> 1; i < half; ++i) {
        const left = (i << 1) + 1;
        const right = left + 1;
  
        //   console.log(
        //     JSON.stringify(
        //       {
        //         node: array[i],
        //         leftChild: array[left],
        //         rightChild: array[right],
        //         cmpLeft: compare(array[i], array[left]),
        //         cmpRight: compare(array[i], array[right]),
        //       },
        //       null,
        //       4
        //     )
        //   );
        if (compare(array[left], array[i]) < 0 || (right < last && compare(array[right], array[i]) < 0)) {
          return false;
        }
      }
  
      return true;
    }
  
    /**
     * ( Mutative ) Heapify in-place given array using given comparator function
     * and return a Heap wrapper around given array.
     *
     * @note Mutating the original array after Heap.make WILL BREAK THINGS !
     */
    static make<TNode>(comparator: Comparator<TNode>, array: TNode[]): Heap<TNode> {
      const heap = new Heap<TNode>(comparator);
      heap._setArray(array);
  
      for (let i = array.length >> 1; i--; ) {
        heap._bubbleDown(i);
      }
  
      return heap;
    }
  
    /**
     *
     */
    push(node: TNode): this {
      let i = this._array.length;
      let parent: number;
  
      while ((parent = (i - 1) >> 1) >= 0 && this.compare(node, this._array[parent]) < 0) {
        this._array[i] = this._array[parent];
        i = parent;
      }
  
      this._array[i] = node;
  
      return this;
    }
  
    /**
     *
     */
    peek(): TNode | undefined {
      return this._array[0];
    }
  
    /**
     *
     */
    getNode(i: number): TNode | undefined {
      return this._array[i];
    }
  
    /**
     *
     */
    get size(): number {
      return this._array.length;
    }
  
    /**
     *
     */
    pop(): TNode | undefined {
      const last = this._array.pop();
  
      if (this._array.length && last !== undefined) {
        const first = this._array[0];
        this._array[0] = last;
        this._bubbleDown(0);
        return first;
      }
  
      return last;
    }
  
    /**
     * Push given node then pop.
     */
    pushPop(node: TNode): TNode {
      if (this._array.length && this.compare(this._array[0], node) < 0) {
        [node, this._array[0]] = [this._array[0], node];
        this._bubbleDown(0);
      }
      return node;
    }
  
    /**
     * Pop then push given node.
     */
    replace(node: TNode): TNode | undefined {
      const first = this._array[0];
      this._array[0] = node;
      this._bubbleDown(0);
      return first;
    }
  
    /**
     * Remove and return given node if it exists in the heap.
     */
    remove(node: TNode, areEqual = (a: TNode, b: TNode) => a === b): TNode | undefined {
      if (!this._array.length) {
        return undefined;
      }
  
      const removeIndex = this._array.findIndex((n) => areEqual(node, n));
  
      switch (removeIndex) {
        case -1:
          return undefined;
        case 0:
          return this.pop();
        case this._array.length - 1:
          return this._array.pop();
        default: {
          const last = this._array.pop() as TNode;
          const removed = this._array[removeIndex];
  
          /** Bubble up. */
          let i = removeIndex;
          let parent: number;
  
          while ((parent = (i - 1) >> 1) >= 0 && this.compare(node, this._array[parent]) < 0) {
            this._array[i] = this._array[parent];
            i = parent;
          }
  
          this._array[i] = last;
  
          this._bubbleDown(removeIndex);
          return removed;
        }
      }
    }
  
    /**
     *
     */
    private _bubbleDown(i: number) {
      const last = this._array.length;
      const half = last >> 1;
      const currentNode = this._array[i];
  
      while (i < half) {
        const left = (i << 1) + 1;
        const right = left + 1;
  
        let bestChild = left;
  
        if (right < last && this.compare(this._array[left], this._array[right]) > 0) {
          bestChild = right;
        }
  
        if (this.compare(currentNode, this._array[bestChild]) <= 0) {
          break;
        }
  
        this._array[i] = this._array[bestChild];
        i = bestChild;
      }
  
      this._array[i] = currentNode;
    }
  
    /**
     *
     */
    private _setArray(array: TNode[]) {
      this._array = array;
    }
  }
  