import React from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';

import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProxyConfigForm } from './WizardContext';

type Props = {
  useForm: UseFormMethods<any>;
};

const useClasses = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
}));

export default function ProxyConfig({ useForm: { register, errors } }: Props) {
  const { t } = useTranslation();

  const c = useClasses();

  return (
    <>
      <Box p={4} mb={2} className={c.container}>
        <Box pb={2}>
          <TextField
            name="proxy_host"
            type="text"
            label={t('wizard.proxyConfig.host')}
            placeholder={t('wizard.proxyConfig.hostPlaceholder')}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            autoComplete="nope"
            inputRef={register()}
          />
          <TextField
            name="proxy_port"
            type="number"
            label={t('wizard.proxyConfig.port')}
            placeholder={t('wizard.proxyConfig.portPlaceholder')}
            fullWidth
            style={{ marginTop: 8 }}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            autoComplete="nope"
            inputRef={register()}
          />
        </Box>
        <Box>
          <TextField
            name="proxy_username"
            type="text"
            label={t('wizard.proxyConfig.username')}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            autoComplete="nope"
            inputRef={register()}
          />
          <TextField
            name="proxy_password"
            type="password"
            label={t('wizard.proxyConfig.password')}
            fullWidth
            style={{ marginTop: 8 }}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            autoComplete="nope"
            inputRef={register()}
          />
        </Box>
      </Box>
    </>
  );
}
