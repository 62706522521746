import React, { useEffect } from 'react';
import { ActionModalProps } from './ActionModalProps';
import { imDone, useAppDispatch } from '@focus-front/core';

export default function ImDone({ onDone, context }: ActionModalProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const error = await dispatch(imDone(context));
      if (!error) setTimeout(() => onDone('done'), 200);
      else alert(error);
    })();
  }, []);

  return <div></div>;
}
