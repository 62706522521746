import { SubscriptionOffer, isSubscriptionOffer, SubscriptionLevel, Invitation } from '../domain';
import { Result, LoggerService } from '../../Common';
import i18n from '../../Common/infra/services/i18nextService';
import { SubscriptionUseCase } from './SubscriptionUseCase';
import { SessionRepo } from '../infra/repository/session/SessionRepo';
import { ApiService } from '../infra/services/ApiService';

export class AppSubscriptionUseCase implements SubscriptionUseCase {
  ERROR_UNEXPECTED = i18n.t('error.unexpected');

  constructor(private readonly session: SessionRepo, private readonly api: ApiService, private readonly logger: LoggerService) {}

  /**
   * Check for a subscription offer change
   * @returns the new subscribtion offer if changed, void if no change
   */
  async checkSubscriptionChange(): Promise<Result<SubscriptionOffer>> {
    try {
      const session = await this.session.get();

      if (!session) return { error: 'No session' };

      let saved_offer: SubscriptionOffer;
      if (!session.offer || !isSubscriptionOffer(session.offer)) {
        saved_offer = 'Personal';
      } else {
        saved_offer = session.offer;
      }

      const user = await this.api.getUser(session.id);

      if (!user) throw new Error('No user found');

      if (typeof user.offer !== 'string' || !isSubscriptionOffer(user.offer))
        throw new Error("Can't retrieve subscription level");

      if (SubscriptionLevel[user.offer] !== SubscriptionLevel[saved_offer]) {
        await this.session.set({ ...session, offer: user.offer });
        return { result: user.offer };
      }

      return {};
    } catch (e) {
      this.logger.captureException(e);

      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Get the list of referral invitations
   * @returns the list
   */
  async getReferralInvitationsList(): Promise<Result<Invitation[]>> {
    try {
      const result = await this.api.getInvitations();

      return { result };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Send a batch of referral invitations by email
   */
  async sendReferralInvitationsByEmail(emails: string[]): Promise<Result<void>> {
    try {
      const session = await this.session.get();
      if (!session) throw new Error('No session found');

      await Promise.all(
        emails.map((email) => this.api.sendInvitation({ email, message: ' ', subject: ' ', user_id: session.id }))
      );

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }
}
