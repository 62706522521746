import React, { useState, useCallback } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getAttachmentFile, ImAttachment, LocalFile, useAppDispatch, Uuid } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { downloadOutline, mic, micOutline, playOutline } from 'ionicons/icons';
import audiosvgself from './audio_skeleton.svg';
import audiosvg from './audio_skeleton2.svg';

const useClasses = makeStyles((theme) => ({
  container: {
    padding: 5,
    display: 'flex',
  },
  download: ({ self }: { self: boolean }) => ({
    position: 'absolute',
    zIndex: 5,
    left: 5,
    right: 55,
    top: 0,
    bottom: 0,
    backgroundColor: self ? theme.palette.secondary.main : theme.palette.background.paper,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .button': {
      backgroundColor: 'rgba(36,36,36,0.7)',
      borderRadius: 50,
      width: 50,
      height: 50,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '& ion-icon': {
        fontSize: 30,
      },
      '& .progress': {
        position: 'absolute',
      },
    },
    '& .imgCont': { textAlign: 'center', flex: 1 },
  }),
  mic: ({ self }: { self: boolean }) => ({
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& ion-icon': {
      fontSize: 30,
      color: self ? 'white' : theme.palette.text.primary,
    },
  }),
}));

type Props = { account_id: Uuid; attachment: ImAttachment; self: boolean };

function ImAudioAttachment({ account_id, attachment, self }: Props) {
  const c = useClasses({ self });
  const [status, setStatus] = useState<'LOADING' | 'ERROR' | 'UNSUPPORTED' | 'IDLE'>('IDLE');
  const [file, setFile] = useState<LocalFile>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const download = useCallback(async () => {
    const { error, result } = await dispatch(getAttachmentFile(account_id, attachment, false));
    if (error) alert(t('attachment.' + (error === 'UnsupportedDevice' ? 'notSupported' : 'error')));
    setStatus('IDLE');
    if (result) setFile(result);
  }, [attachment]);

  return (
    <div className={c.container}>
      {!file && (
        <div className={c.download}>
          <div className="button" onClick={download}>
            {status === 'LOADING' ? <CircularProgress className="progress" /> : <IonIcon icon={playOutline} />}
          </div>
          <div className="imgCont">
            <img src={self ? audiosvgself : audiosvg} />
          </div>
        </div>
      )}
      <audio controls>{file && file.data && <source src={`data:${file.mime}; base64,${file.data}`} />}</audio>
      <div className={c.mic}>
        <IonIcon icon={mic} />
      </div>
    </div>
  );
}

export default React.memo(ImAudioAttachment);
