import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core';

import MainArea from './MainArea';
import SideArea from './SideArea';
import ContactViewModal from '../Contact/ContactViewModal';

// import Tour from '../Onboarding/Tour';
import { WizardDialog } from '../Wizard/Wizard';
import NewImModal from '../Im/NewImModal';
import { useSelector } from 'react-redux';
import { calendarModeSelector, envSelector } from '@focus-front/core';
import DownloadDesktopModal from '../Onboarding/DownloadDesktopModal';
import AutomaticHelp from '../Onboarding/AutomaticHelp/AutomaticHelp';
import SyncCalendarModal from '../Settings/SyncCalendarModal';
import SubscriptionUpgradeDialog from '../Settings/Subscription/SubscriptionUpgradeDialog';
import Reconnect from '../Settings/Reconnect';
import { CalendarContext } from '../Calendar/CalendarContext';
import FullCalendar from '@fullcalendar/react';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'grid',
  },
  left: {
    backgroundColor: theme.palette.background.default,
    gridColumn: 1,
    height: '100vh',
  },
  right: {
    backgroundColor: theme.palette.background.paper,
    gridColumn: 2,
    height: '100vh',
  },
}));

export default function Layout() {
  const classes = useClasses();
  const env = useSelector(envSelector);
  const mode = useSelector(calendarModeSelector);
  const calendarRef = useRef<FullCalendar>();

  const gridTemplateColumns = mode === 'DAY' || mode === 'LIST' ? '60% 40%' : '40% 60%';

  return (
    <CalendarContext.Provider value={calendarRef}>
      <main className={classes.root} style={{ gridTemplateColumns }}>
        <div className={classes.left}>
          <MainArea />
        </div>
        <div className={classes.right}>
          <SideArea />
        </div>
        {/* <ContactForm /> */}
        <ContactViewModal />
        <WizardDialog />
        <AutomaticHelp />
        <NewImModal />
        <SyncCalendarModal />
        <SubscriptionUpgradeDialog />
        <Reconnect />
        {env.device_type === 'web' && <DownloadDesktopModal />}
        {/* <Tour /> */}
      </main>
    </CalendarContext.Provider>
  );
}
