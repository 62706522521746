import { IonIcon } from '@ionic/react';
import { ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';
import { ChevronRightOutlined } from '@material-ui/icons';
import { chevronForwardOutline } from 'ionicons/icons';
import React from 'react';

const useClasses = makeStyles((theme) => ({
  categoryCard: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px ' + theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.divider,
    },
  },
  cardChevron: {
    position: 'absolute',
    fontSize: 24,
    color: theme.palette.grey[700],
    right: 10,
    bottom: 10,
  },
  icon: {
    fontSize: 20,
    height: 40,
    width: 40,
    borderRadius: 50,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  title: string;
  icon: string;
  onClick: () => void;
}

export default function SearchCatCard({ title, icon, onClick }: Props) {
  const c = useClasses();
  return (
    <Paper className={c.categoryCard}>
      <ListItem onClick={onClick}>
        <ListItemAvatar>
          <div className={c.icon}>
            <IonIcon icon={icon} />
          </div>
        </ListItemAvatar>
        <ListItemText primary={<Typography variant="h3">{title}</Typography>} />
        <ChevronRightOutlined />
      </ListItem>
    </Paper>
  );
}
