import { Tab, withStyles } from '@material-ui/core';

const LightTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 400,
    height: 50,
    minWidth: 120,
    padding: 6,
  },
  disabled: {
    opacity: '0.3!important',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  selected: {
    fontWeight: 700,
  },
}))(Tab);

export default LightTab;
