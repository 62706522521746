import { iCloudScriptsProvider, Scraper, ScraperService } from '../../../../Common';
import { ScrapAppPasswordService } from '../ScrapAppPasswordService';

export class ICloudDisabledMailError extends Error {
  constructor() {
    super('This iCloud account does not have emails enabled');
    this.name = 'ICloudDisabledMailError';
  }
}
export class iCloudAppPasswordService implements ScrapAppPasswordService {
  constructor(protected readonly provider: iCloudScriptsProvider, readonly scraper: ScraperService<Scraper>) {}

  async getAppPassword(stepCallback: (step_number: number) => void): Promise<{ email: string; password: string }> {
    stepCallback(1);

    const ID = '__ICLOUD_CONFIG';

    try {
      // Create a disposable scraper
      const scraper = this.scraper.createScraper(ID, this.provider.contexts(), { closable: true, width: 800, height: 700 });

      await scraper.init();

      await scraper.loadUrl(this.provider.urls().SETTINGS_URL);

      // Skip the landing page
      await scraper.executeScript(this.provider.scripts().skipLanding);

      // Wait for the login form to appear
      await scraper.waitForSelector([this.provider.contexts().LOGIN_FORM], [this.provider.contexts().LANDING_PAGE], 5 * 1000);

      // Show the webview to the user
      await scraper.showWebview();

      // Let the user login
      await scraper.waitForSelector([this.provider.contexts().SETTINGS], [this.provider.contexts().LOGIN_FORM], 5 * 60 * 1000);

      // Hide the webview when done
      await scraper.hideWebview();

      await scraper.loadUrl(this.provider.urls().INFOS_URL);

      const email = await scraper.executeScript(this.provider.scripts().getEmail);

      if (email === false) throw new ICloudDisabledMailError();

      stepCallback(2);

      await scraper.loadUrl(this.provider.urls().SETTINGS_URL);

      await scraper.executeScript(this.provider.scripts().generateAppPassword);

      await scraper.waitForSelector(
        [this.provider.contexts().LOGIN_FORM_2],
        [this.provider.contexts().SETTINGS, this.provider.contexts().APP_PASSWORD_SCREEN],
        5 * 60 * 1000
      );

      // At the end of the script, password prompt must appear, let the user login again
      await scraper.showWebview();

      // Let the user login by waiting for the app password to appear for 5 min
      await scraper.waitForSelector(
        [this.provider.contexts().APP_PASSWORD_SCREEN],
        [this.provider.contexts().SETTINGS, this.provider.contexts().LOGIN_FORM_2],
        5 * 60 * 1000
      );

      await scraper.hideWebview();

      const password = await scraper.executeScript(this.provider.scripts().getAppPassword);

      stepCallback(3);

      await this.scraper.destroyScraper(ID);

      return {
        email,
        password,
      };
    } catch (e) {
      // Destroy the scraper if any error
      await this.scraper.destroyScraper(ID);
      throw e;
    }
  }
}
