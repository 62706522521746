import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  calendarsFormSelector,
  draftCalendarEvent,
  cancelPlan,
  NewCalendarEventDTO,
  planNotification,
  ViewNotification,
  useAppDispatch,
} from '@focus-front/core';
import { EventForm } from '../Event/EventForm';
import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';
import { useTranslation } from 'react-i18next';

interface Props {
  notification: ViewNotification;
}

export default function PlanAction({ onClose, onDone, notification }: ActionModalProps & Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mode, eventDraft, recurringEventRoot } = useSelector(calendarsFormSelector);
  const [mounted, setMounted] = useState(false);
  const handleSubmit = (values: NewCalendarEventDTO) => {
    dispatch(planNotification(values, notification.id));
    onDone('plan');
  };

  useEffect(() => {
    dispatch(
      draftCalendarEvent('ACTIONBAR', {
        summary: t(`notification.plan.defaultTitle.${notification.type.toLowerCase()}`, {
          attendee: notification.attendees?.[0].display_name || notification.attendees?.[0].identifier || 'Unknown',
        }),
        description: notification.content,
        // start_datetime: @TODO Find the next free available time in the calendar
      })
    );
    setMounted(true);
    return () => {
      dispatch(cancelPlan());
    };
  }, []);

  useEffect(() => {
    if (mode === 'CALENDAR' && mounted) onClose?.();
  }, [mode]);

  if (mode !== 'ACTIONBAR') return null;

  return (
    <ActionModal>
      <EventForm defaultValues={eventDraft} onSubmit={handleSubmit} recurringEventRoot={recurringEventRoot} />
    </ActionModal>
  );
}
