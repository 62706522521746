import { IonIcon } from '@ionic/react';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  container: {
    flex: 1,
    height: '100%',
    display: ' flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  icon: {
    fontSize: 40,
  },
}));

export default function NoResults({ label, icon }: { label: string; icon: string }) {
  const c = useClasses();
  const { t } = useTranslation();
  return (
    <div className={c.container}>
      <IonIcon icon={icon} className={c.icon} />
      <Typography variant="h2">{t('search.noResultsAllList.title')}</Typography>
      <Typography variant="subtitle1">{t('search.noResultsAllList.subtitle', { label })}</Typography>
    </div>
  );
}
