import { AggregateRepo, DomainEventStore } from '../../../Common';
import { TagRelation } from '../../domain';

/**
 * Repository.
 */
export class TagRelationRepo extends AggregateRepo<TagRelation> {
  constructor(eventStore: DomainEventStore) {
    super(TagRelation, eventStore);
  }
}
