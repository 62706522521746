import { UTCDateTimeMs, Uuid } from '../../../Common';
import { Select } from '../../../Common/utils';

export interface ImAttachment {
  id: string;
  name: string;
  size: number;
  extension: string;
  mime: string;
  download_link: string;
  type: 'IMAGE' | 'DOCUMENT' | 'CONTACT' | 'AUDIO' | 'LOCATION' | 'VIDEO' | 'OTHER';
  media_data: {
    width?: number;
    height?: number;
    duration?: number;
    aspect_ratio?: number;
    preview?: string;
  };
}

export type ImAttachmentDownloaded = ImAttachment & { data?: string };

export type ImSendStatus = 'IDLE' | 'SENDING' | 'SENT' | 'SENDING_ERROR';
export type ImSyncStatus = 'NOT_SYNC' | 'SYNC';
export type ImDirection = 'OUTGOING' | 'INCOMING';

/**
 * Projection.
 */
export type ViewIm = Readonly<{
  id: Uuid /** Aggregate id. */;
  from_attendee_identifier: string;
  account_id: Uuid;
  date: UTCDateTimeMs;
  thread_id: Uuid;
  provider_thread_id: string;
  direction: ImDirection;
  send_status: ImSendStatus;
  sync_status: ImSyncStatus;
  body?: string | undefined;
  tags?: string[];
  attachments?: ImAttachment[];
  provider_id?: string;
}>;

export type NewImDTO = Omit<
  Select<ViewIm, 'from_attendee_identifier' | 'provider_thread_id' | 'date' | 'direction' | 'account_id'>,
  'id' | 'thread_id'
>;

export type UpdateImDTO = Partial<NewImDTO>;

/**
 * @note Unlike Mails or ImThreads, attendees already exists when a new Im is created beause they have been created
 * while creating the ImThread.
 */
export function createViewIm(aggregateId: Uuid, thread_id: Uuid, values: NewImDTO): ViewIm {
  /** @note Keep this pattern to check at runtime values received from the external world. */
  if (!values.from_attendee_identifier || !values.provider_thread_id || !values.date || !values.direction) {
    throw new Error('Missing values to instantiate an Im');
  }
  return {
    ...values,
    id: aggregateId,
    thread_id,
    /**
     * Add default values here if needed.
     */
    send_status: values.send_status || 'IDLE',
    sync_status: values.sync_status || 'NOT_SYNC',
  };
}
