import { useState } from 'react';

export default function usePopover(): {
  onOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose: () => void;
  anchorEl: HTMLElement;
} {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return {
    onOpen,
    onClose,
    anchorEl,
  };
}
