import React from 'react';
import { Link, makeStyles, Tooltip as Tl, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { flash, flashOutline, warning } from 'ionicons/icons';
import { useAppDispatch } from '@focus-front/core';
import cn from 'classnames';

const useClasses = makeStyles((theme) => ({
  root: {
    // backgroundColor: 'rgb(255, 244, 229)',
    width: 17,
    height: 17,
    boxSizing: 'content-box',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.warning.main,
  },
}));

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: 10,
    padding: theme.spacing(1),
    color: '#fff',
    boxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
    fontSize: 14,
  },
  arrow: {
    color: theme.palette.warning.main,
  },
}))(Tl);

type Props = {
  title?: string;
  className?: string;
};

export default function AccountDisconnectedIcon({ title, className }: Props) {
  const c = useClasses();
  const icon = (
    <div className={cn(c.root, className)}>
      <IonIcon icon={flash}></IonIcon>
    </div>
  );
  if (title)
    return (
      <Tooltip title={title} arrow>
        {icon}
      </Tooltip>
    );
  return icon;
}
