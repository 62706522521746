import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  loginUser,
  prettyPrint,
  resetPassword,
  retryResetPassword,
  sessionStateSelector,
  useAppDispatch,
} from '@focus-front/core';
import { createStyles, IconButton, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import LoadingButton from '../Common/LoadingButton';
import { useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
// import { BackupKeyRequestResponse } from '@focus-front/core';

interface ResetPasswordForm {
  email: string;
  recoveryCode: string;
  password: string;
}

interface BackupKeyRequestResponse {
  request_id: string;
  token: string;
  user_backup_key: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: 400,
      },
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: 400,
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    field: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: 10,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& ion-icon': {
        marginRight: theme.spacing(1),
        fontSize: 20,
      },
    },
  })
);
export default function ResetPasswordForm({ onResetSuccess }: { onResetSuccess: () => void }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const session = useSelector(sessionStateSelector);
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const { requestId, token } = useParams<{ requestId: string; token: string }>();
  const { register, errors, handleSubmit } = useForm<ResetPasswordForm>({
    defaultValues: {
      email: '',
      recoveryCode: '',
      password: '',
    },
  });
  const [retryWith, setRetryWith] = useState<BackupKeyRequestResponse | null>(null);

  const onSubmit = async (data: ResetPasswordForm) => {
    setError(null);
    setLoading(true);
    prettyPrint({ retryWith });

    const error = retryWith
      ? await dispatch(retryResetPassword(data.email, data.recoveryCode, data.password, retryWith))
      : await dispatch(resetPassword(data.email, data.recoveryCode, data.password, requestId, token));

    if (error) {
      switch (error.type) {
        case 'BACKUPKEYREQUEST_VALIDATION_ERROR':
          setError(t('resetPassword.form.invalidLink'));
          break;
        case 'RECOVERY_CODE_INVALID':
          setError(t('resetPassword.form.recoveryCode.invalid'));
          setRetryWith(error.retryWith);
          break;
        case 'RECOVERY_CODE_INVALID_FORMAT':
          setError(t('resetPassword.form.recoveryCode.invalidFormat'));
          if (error.retryWith) {
            setRetryWith(error.retryWith);
          }
          //   setRetryWith(error.retryWith ?? null);
          break;
        case 'RESETPASSWORDREQUEST_VALIDATION_ERROR':
          setError(t('resetPassword.form.invalidReset'));
          setRetryWith(error.retryWith);
          break;
      }
      setLoading(false);
    } else {
      onResetSuccess();
    }
  };

  //   useEffect(() => {
  //     if (session.error) setError(session.error);
  //   }, [session.error]);

  const [loading, setLoading] = useState(false);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <TextField
        label={t('login.form.email.label')}
        name="email"
        inputRef={register({
          required: t<string>('login.form.email.required'),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t('login.form.email.invalid'),
          },
        })}
        fullWidth
        error={!!errors.email}
        helperText={errors.email && errors.email.message}
        InputProps={{
          className: classes.field,
        }}
      />
      <TextField
        label={t('resetPassword.form.recoveryCode.label')}
        name="recoveryCode"
        inputRef={register({
          required: t<string>('resetPassword.form.recoveryCode.required'),
        })}
        fullWidth
        error={!!errors.recoveryCode}
        helperText={errors.recoveryCode && errors.recoveryCode.message}
        InputProps={{
          className: classes.field,
        }}
      />
      <TextField
        label={t('resetPassword.form.password.label')}
        name="password"
        type={showPassword ? 'text' : 'password'}
        inputRef={register({ required: t<string>('resetPassword.form.password.required') })}
        fullWidth
        error={!!errors.password}
        helperText={errors.password && errors.password.message}
        InputProps={{
          className: classes.field,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        className={classes.button}
        type="submit"
      >
        {t('resetPassword.form.submit')}
      </LoadingButton>

      {error && <Alert severity="error">{error}</Alert>}
    </form>
  );
}
