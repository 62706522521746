import { Environment } from '../../../_container/interfaces/Environment';
import { IdentifierType } from '../../../Contact';
import { UTCDateTimeMs, Uuid } from '../../../Common';
import { Demand, Optional, Select, Strip } from '../../../Common/utils';
import { Capacitor } from '@capacitor/core';
import { Taggable } from '../../../Tag';

export interface MicrosoftAccountInfo {
  id: string;
  username: string;
}
export interface AccountSignature {
  title: string;
  content: string;
}

export interface ImapConnectionParams {
  imap_host: string;
  imap_port: number;
  imap_user: string;
  smtp_host: string;
  smtp_port: number;
  smtp_user: string;
}

export interface SmsConnectionParams {
  phone_number: string;
  sim_serial_number: string;
}

export interface MobileCallsConnectionParams {
  phone_number: string;
  sim_serial_number: string;
}

export interface FormLoginConnectionParams {
  username: string;
  proxy?: ProxyConnectionParams;
}

export interface ProxyConnectionParams {
  host: string;
  port: number;
  username: string;
  password: string;
}

export interface WhatsAppIdentifier {
  phone_number: string;
}

/**
 * Projection.
 */
export interface ViewAccountBase {
  id: Uuid /** Aggregate id. */;
  name: string;
  created_at: UTCDateTimeMs;
  current_signature?: Uuid;
  signatures?: AccountSignature[];
  groups: Uuid[];
}

export interface MailFetchAccount {
  full_fetch_progress: { mailbox: string; offset: number; total: number; done: boolean }[];
  full_fetch_status: 'IDLE' | 'DONE' | 'FETCHING';
}

export interface MobileAccount extends ViewAccountBase {
  type: 'MOBILE';
  connection_params: {
    im: SmsConnectionParams;
    call: MobileCallsConnectionParams;
  };
  last_fetched_at?: UTCDateTimeMs;
}
export interface MailAccount extends ViewAccountBase, MailFetchAccount {
  type: 'MAIL';
  connection_params: {
    mail: ImapConnectionParams;
  };
}
export interface GoogleAccount extends ViewAccountBase, MailFetchAccount {
  type: 'GOOGLE';
  connection_params: {
    mail: ImapConnectionParams;
  };
}

export interface ICloudAccount extends ViewAccountBase, MailFetchAccount {
  type: 'ICLOUD';
  connection_params: {
    mail: ImapConnectionParams;
  };
}

export interface OutlookAccount extends ViewAccountBase, MailFetchAccount {
  type: 'OUTLOOK';
  connection_params: {
    mail: MicrosoftAccountInfo;
  };
}

export interface GoogleCalendarAccount extends ViewAccountBase {
  type: 'GOOGLE_CALENDAR';
  connection_params: {
    calendar: string;
  };
  sync_token?: string;
}
export interface LinkedInAccount extends ViewAccountBase {
  type: 'LINKEDIN';
  connection_params: {
    im: FormLoginConnectionParams;
  };
  last_fetched_at?: UTCDateTimeMs;
}
export interface MessengerAccount extends ViewAccountBase {
  type: 'MESSENGER';
  connection_params: {
    im: FormLoginConnectionParams;
  };
  last_fetched_at?: UTCDateTimeMs;
}
export interface TikTokAccount extends ViewAccountBase {
  type: 'TIKTOK';
  connection_params: {
    im: FormLoginConnectionParams;
  };
  last_fetched_at?: UTCDateTimeMs;
}
export interface TwitterAccount extends ViewAccountBase {
  type: 'TWITTER';
  connection_params: {
    im: FormLoginConnectionParams;
  };
  last_fetched_at?: UTCDateTimeMs;
}
export interface InstagramAccount extends ViewAccountBase {
  type: 'INSTAGRAM';
  connection_params: {
    im: FormLoginConnectionParams;
  };
  last_fetched_at?: UTCDateTimeMs;
}
export interface WhatsAppAccount extends ViewAccountBase {
  type: 'WHATSAPP';
  connection_params: {
    im: WhatsAppIdentifier;
  };
  last_fetched_at?: UTCDateTimeMs;
}

export type ViewAccount = Readonly<
  | MobileAccount
  | MailAccount
  | GoogleAccount
  | ICloudAccount
  | OutlookAccount
  | GoogleCalendarAccount
  | LinkedInAccount
  | MessengerAccount
  | TikTokAccount
  | InstagramAccount
  | TwitterAccount
  | WhatsAppAccount
>;

export type NewAccountDTO = Strip<Optional<ViewAccount, 'groups'>, 'id'>;

export const ImAccountIdentifierType: { [key in ImSupportedAccounts]: IdentifierType } = {
  LINKEDIN: 'LINKEDIN_ID',
  MESSENGER: 'MESSENGER_ID',
  TIKTOK: 'TIKTOK_ID',
  INSTAGRAM: 'INSTAGRAM_ID',
  TWITTER: 'TWITTER_ID',
  MOBILE: 'PHONE_NUMBER',
  WHATSAPP: 'PHONE_NUMBER',
};

/**
 * @note Tagged DTO are used where we need to be strict and check type compatibility.
 *       Non-tagged DTO can be used where we can assume type compatibility was checked upstream.
 *
 *       e.g. : An Aggregate.edit command that does not allow for its type to change,
 *              will require a TaggedUpdateAggDTO and accept only if given update
 *              type matches its internal state type.
 *
 *              The AggregateEdited event may not need to record the type and can use
 *              UpdateAggDTO. Consumers of AggregateEdited events may also expect
 *              the looser UpdateAggDTO, knowing the type compatibility invariant
 *              was asserted upstream.
 */
type UpdateDTO<T> = T extends ViewAccount ? Omit<Partial<T>, 'id'> : never;
type TaggedUpdateDTO<T> = T extends ViewAccount ? Omit<Select<T, 'type'>, 'id'> : never;

export type TaggedUpdateAccountDTO = TaggedUpdateDTO<ViewAccount>;
export type UpdateAccountDTO = UpdateDTO<ViewAccount>;

export type FetchedAccountDTO =
  | Demand<LinkedInAccount, 'type' | 'last_fetched_at'>
  | Demand<MessengerAccount, 'type' | 'last_fetched_at'>
  | Demand<TikTokAccount, 'type' | 'last_fetched_at'>
  | Demand<TwitterAccount, 'type' | 'last_fetched_at'>
  | Demand<InstagramAccount, 'type' | 'last_fetched_at'>
  | Demand<WhatsAppAccount, 'type' | 'last_fetched_at'>
  | Demand<MobileAccount, 'type' | 'last_fetched_at'>
  | Demand<GoogleCalendarAccount, 'type' | 'sync_token'>;

/**
 *
 */
export function createViewAccount<T extends NewAccountDTO>(aggregateId: Uuid, values: T): ViewAccount & { type: T['type'] } {
  return { ...values, id: aggregateId, groups: values.groups ?? [] };
}

export const MailSupportedAccountTypes: Readonly<AccountType[]> = ['MAIL', 'ICLOUD', 'GOOGLE', 'OUTLOOK'] as const;

export const ImSupportedAccountTypes: Readonly<AccountType[]> = [
  'TIKTOK',
  'INSTAGRAM',
  'TWITTER',
  'MESSENGER',
  'LINKEDIN',
  'WHATSAPP',
  'MOBILE',
] as const;

export const CallSupportedAccountTypes: Readonly<AccountType[]> = ['MOBILE'] as const;

export const CalendarSupportedAccountTypes: Readonly<AccountType[]> = [
  'GOOGLE_CALENDAR',
  // 'OUTLOOK',
] as const;

export const ContactSupportedAccountTypes: Readonly<AccountType[]> = [] as const;

export function isMobileAccount(acc: Pick<ViewAccount, 'type'>): acc is MobileAccount {
  return acc.type === 'MOBILE';
}

export function isMailAccount(acc: Pick<ViewAccount, 'type'>): acc is MailAccount {
  return acc.type === 'MAIL';
}

export function isGoogleAccount(acc: Pick<ViewAccount, 'type'>): acc is GoogleAccount {
  return acc.type === 'GOOGLE';
}

export function isGoogleCalendarAccount(acc: Pick<ViewAccount, 'type'>): acc is GoogleCalendarAccount {
  return acc.type === 'GOOGLE_CALENDAR';
}

export function isLinkedInAccount(acc: Pick<ViewAccount, 'type'>): acc is LinkedInAccount {
  return acc.type === 'LINKEDIN';
}

export function isMessengerAccount(acc: Pick<ViewAccount, 'type'>): acc is MessengerAccount {
  return acc.type === 'MESSENGER';
}

export function isTikTokAccount(acc: Pick<ViewAccount, 'type'>): acc is TikTokAccount {
  return acc.type === 'TIKTOK';
}

export function isInstagramAccount(acc: Pick<ViewAccount, 'type'>): acc is InstagramAccount {
  return acc.type === 'INSTAGRAM';
}

export function isTwitterAccount(acc: Pick<ViewAccount, 'type'>): acc is TwitterAccount {
  return acc.type === 'TWITTER';
}

export function isWhatsAppAccount(acc: Pick<ViewAccount, 'type'>): acc is WhatsAppAccount {
  return acc.type === 'WHATSAPP';
}

export function isMailSupportedAccount(acc: ViewAccount): acc is ViewAccount & { type: MailSupportedAccounts } {
  return MailSupportedAccountTypes.includes(acc.type);
}

/**
 * @note I had to duplicate isMailSupportedAccount which was not working when the account is & Taggable
 */
export function isMailSupportedAccountTaggable(
  acc: ViewAccount & Taggable
): acc is ViewAccount & Taggable & { type: MailSupportedAccounts } {
  return MailSupportedAccountTypes.includes(acc.type);
}

export function isImSupportedAccount(acc: ViewAccount): acc is ViewAccount & { type: ImSupportedAccounts } {
  return ImSupportedAccountTypes.includes(acc.type);
}

export function isCallSupportedAccount(acc: ViewAccount): acc is ViewAccount & { type: CallSupportedAccounts } {
  return CallSupportedAccountTypes.includes(acc.type);
}

// export function isContactSupportedAccount(acc: ViewAccount): acc is ViewAccount & { type: ContactSupportedAccounts } {
//   return ContactSupportedAccountTypes.includes(acc.type);
// }

export function isCalendarSupportedAccount(acc: ViewAccount): acc is ViewAccount & { type: CalendarSupportedAccounts } {
  return CalendarSupportedAccountTypes.includes(acc.type);
}

export function isImapConnectionParams(params: ImapConnectionParams): params is ImapConnectionParams {
  return !!(params.imap_host && params.imap_port && params.imap_user && params.smtp_host && params.smtp_port && params.smtp_user);
}

export function isEnvSupportedAccount(
  acc: Pick<ViewAccount, 'type'>,
  env: Pick<Environment, 'device_type'>,
  source: AccountSourceType | 'DELETION'
): boolean {
  if (isMobileAccount(acc) && env.device_type !== 'mobile') return false;
  if (isMobileAccount(acc) && env.device_type === 'mobile' && Capacitor.getPlatform() === 'ios') return false;
  if (isLinkedInAccount(acc) && env.device_type !== 'desktop') return false;
  if (isMessengerAccount(acc) && env.device_type !== 'desktop') return false;
  if (isTikTokAccount(acc) && env.device_type !== 'desktop') return false;
  if (isInstagramAccount(acc) && env.device_type !== 'desktop') return false;
  if (isTwitterAccount(acc) && env.device_type !== 'desktop') return false;

  if (isWhatsAppAccount(acc) && env.device_type !== 'desktop') return false;
  if (isGoogleCalendarAccount(acc) && env.device_type === 'mobile') return false;
  return true;
}

//------------------------------------------------------------------------------
/**
 * Aliases.
 */
export type MailConnectionParams = MailAccount['connection_params'];
export type GoogleConnectionParams = GoogleAccount['connection_params'];
export type ICloudConnectionParams = ICloudAccount['connection_params'];
export type GoogleCalendarConnectionParams = GoogleCalendarAccount['connection_params'];
export type OutlookConnectionParams = OutlookAccount['connection_params'];
export type MobileConnectionParams = MobileAccount['connection_params'];
export type LinkedInConnectionParams = LinkedInAccount['connection_params'];
export type MessengerConnectionParams = MessengerAccount['connection_params'];
export type TikTokConnectionParams = TikTokAccount['connection_params'];
export type TwitterConnectionParams = TwitterAccount['connection_params'];
export type InstagramConnectionParams = InstagramAccount['connection_params'];

export type WhatsAppConnectionParams = WhatsAppAccount['connection_params'];

export type AccountType = ViewAccount['type'];
export type OfAccountType<T extends AccountType> = T;

export type MailSupportedAccounts = OfAccountType<'GOOGLE' | 'MAIL' | 'OUTLOOK' | 'ICLOUD'>;

export type ImSupportedAccounts = OfAccountType<
  'TIKTOK' | 'INSTAGRAM' | 'TWITTER' | 'MESSENGER' | 'LINKEDIN' | 'WHATSAPP' | 'MOBILE'
>;
/**
 * @note GOOGLE mail and GOOGLE_CALENDAR kept separate as we are not using the
 *       same kind of auth for both of them.
 *
 */
export type CalendarSupportedAccounts = OfAccountType<'GOOGLE_CALENDAR'>; // | 'OUTLOOK'
export type CallSupportedAccounts = OfAccountType<'MOBILE'>;
export type OAuthSupportedAccounts = OfAccountType<'GOOGLE' | 'OUTLOOK' | 'GOOGLE_CALENDAR'>;

/**
 * Type of accounts which are connected through a basic login page form
 */
export type WebViewFormLoginSupportedAccounts = OfAccountType<'LINKEDIN' | 'MESSENGER' | 'TIKTOK' | 'INSTAGRAM' | 'TWITTER'>;

/**
 * Type of accounts which are connected by scanninng a QR Code
 */
export type WebViewQRCodeLoginSupportedAccounts = OfAccountType<'WHATSAPP'>;

/**
 * Type of accounts which are connected by scanninng a QR Code
 */
export type ScrappedAccounts = OfAccountType<
  'WHATSAPP' | 'GOOGLE' | 'ICLOUD' | 'LINKEDIN' | 'MESSENGER' | 'TIKTOK' | 'INSTAGRAM' | 'TWITTER'
>;

/**
 * Type of accounts that need an Application Password to call an API
 */
export type WebViewAppPasswordSupportedAccounts = OfAccountType<'GOOGLE' | 'ICLOUD'>;

export type AccountSourceType = 'MAILS' | 'CALENDAR' | 'CONTACTS' | 'IMS' | 'CALLS';

/**
 * Source status
 * - IDLE : Is waiting to be picked up by the cron
 * - FETCHING : Is fetching new data
 * - UNSUPPORTED : Is not supported on the user's device. For example IM source of a Mobile account on a Web platform
 * - CREDENTIALS : Credentials needed to fetch new data are invalid or missing. For example user changed his email provider password
 * - PERMISSIONS : Device permissions needed to fetch new data are not given. For example user disable SMS permissions on his Android Phone
 * - ERROR : An unexpected error occured during the data fetch, the source is paused and will not be picked up by the cron
 * - NOT_SETUP : The source has not been added when the Account was connected for the first time, it will never be picked up by the cron until setup by the user
 * - DISABLED_DEVICE_LIMIT : The source is paused automatically by the system because the device limit of concurrent accounts is reached
 * - DISABLED_USER_LIMIT : The source is paused automatically by the system because the user limit of concurrent accounts is reached
 * - PAUSE : The source is paused manually by the user and will not be picked up by the cron until unpaused by the user
 */
export type AccountSourceStatus =
  | 'UNSUPPORTED'
  | 'FETCHING'
  | 'ERROR'
  | 'IDLE'
  | 'CREDENTIALS'
  | 'PERMISSIONS'
  | 'NOT_SETUP'
  | 'PAUSE'
  | 'DISABLED_DEVICE_LIMIT'
  | 'DISABLED_USER_LIMIT';

export interface AccountSource {
  status: AccountSourceStatus;
  type: AccountSourceType;
  account_id: Uuid;
}
