import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { recoveryRequestSelector, sessionStateSelector } from '@focus-front/core';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Fade, Typography } from '@material-ui/core';

import Logo from '../Header/Logo';
import { ExpandMoreOutlined } from '@material-ui/icons';

/**
 * Redirect to /login if no user in redux store
 */
export default function ProtectedRoute(props: RouteProps) {
  const { session, loading, initProgress } = useSelector(sessionStateSelector);
  const recoveryRequested = useSelector(recoveryRequestSelector);
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  if (recoveryRequested) return <Redirect to={'/recover'} />;

  if (loading)
    return (
      <Fade in={true}>
        <div className="splashscreen" onClick={() => setShowDetails(!showDetails)}>
          <Logo />
          <CircularProgress color="inherit" style={{ marginTop: 40 }} />
          <Typography variant="subtitle1" >
            {t('login.sync')}
          </Typography>
          {showDetails &&
            initProgress.map((msg) => (
              <Typography key={msg} variant="subtitle2">
                {t(msg)}
              </Typography>
            ))}
        </div>
      </Fade>
    );

  if (!session) return <Redirect to="/login" />;

  if (!session.params.onboarding) return <Redirect to="/onboarding" />;

  return <Route {...props} />;
}
