import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AccountRepo, AccountType, ViewAccountRepo } from '../../Account';
import { ViewCallRepo } from '../../Call';
import {
  LoggerService,
  Publisher,
  Result,
  toUTCDate,
  toUTCDateTimeMs,
  UTCDate,
  UTCDateTimeMs,
  Uuid,
  toTzid,
  Tzid,
  ResultEither,
  ResultOk,
  ResultError,
} from '../../Common';
import i18n from '../../Common/infra/services/i18nextService';
import { Select, SingleOrArray, Strip, AllOrNothing, prettyPrint } from '../../Common/utils';
import { Attendee } from '../../Contact';
import { ViewImThreadRepo } from '../../Im';
import { ViewMailDraftRepo, MailReferenceRepo } from '../../Mail';
import { Notification, NotificationRepo, ViewNotification, ViewNotificationRepo } from '../../Notification';
import { DEFAULT_CALENDAR_EVENT_INTERVAL, DEFAULT_EVENT_INTERVAL } from '../constants';
import {
  Calendar,
  CalendarEvent,
  CalendarEventInstanceId,
  CalendarEventRecurringKind,
  CalendarEventSingleKind,
  GoogleCalendarEvent,
  NewCalendarEventDTO,
  PatchCalendarEventDTO,
  CalendarEventDate,
  UnipileCalendarEvent,
  ViewCalendar,
  ViewCalendarEvent,
  ExternalCalendar,
} from '../domain';
import { NewCalendarDTO, TaggedExternalPatchCalendarDTO, TaggedPatchCalendarDTO } from '../domain/types/CalendarDTO';
import { UnipileCalendarEventInstanceId } from '../domain/CalendarEventInstance';
import { CalendarEventExceptionDTO } from '../domain/types/CalendarEventExceptionDTO';
import { RecurringCalendarEventRoot } from '../domain/projections/RecurringCalendarEventRoot';
import { ViewCalendarEventRepo } from '../domain/projections/ViewCalendarEventRepo';
import { ViewCalendarRepo } from '../domain/projections/ViewCalendarRepo';
import { CalendarEventRepo } from '../infra/repository/CalendarEventRepo';
import { CalendarRepo } from '../infra/repository/CalendarRepo';
import { CalendarUseCase } from './CalendarUseCase';
import { Optional } from 'utility-types';
import { MailSyncService } from '../../Mail/infra/services/MailSyncService';
import { Taggable } from '../../Tag';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @todo Refactor in such a way that this UseCase isn't dependent on a specific UI rule.
 *       e.g. : decide on event duration where you know what you want, pass result
 *              duration to getEventDraft.
 */
export type FormMode = 'IDLE' | 'ACTIONBAR' | 'CALENDAR';

/**
 *
 */
export class AppCalendarUseCase implements CalendarUseCase {
  readonly ERROR_UNEXPECTED = i18n.t('accountSettings.error.unexpected');

  constructor(
    private readonly publisher: Publisher,
    private readonly calendar: CalendarRepo,
    private readonly calendarEvent: CalendarEventRepo,
    private readonly account: AccountRepo,
    private readonly notification: NotificationRepo,
    private readonly viewCalendar: ViewCalendarRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly viewNotification: ViewNotificationRepo,
    private readonly viewImThread: ViewImThreadRepo,
    private readonly viewMail: MailReferenceRepo,
    private readonly viewMailDraft: ViewMailDraftRepo,
    private readonly viewCall: ViewCallRepo,
    private readonly viewAccount: ViewAccountRepo,
    private readonly logger: LoggerService,
    private readonly mailSync: MailSyncService
  ) {}

  /**
   *
   */
  async createCalendar<T extends NewCalendarDTO>(values: T): Promise<ViewCalendar | null> {
    const result = Calendar.create(values);
    if (result.ok) {
      await this.publisher.emit(result.changes);
      return this.viewCalendar.get(result.aggregate.id);
    }
    return null;
  }

  /**
   *
   */
  async editCalendar(calendar_id: Uuid, values: TaggedPatchCalendarDTO): Promise<ViewCalendar | null> {
    try {
      const agg = await this.calendar.getAggregate(calendar_id);
      const result = agg.edit(values);
      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        return this.viewCalendar.get(calendar_id);
      }

      throw new Error(`Could not edit calendar ( ${agg.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async activateCalendarSync(calendar_id: Uuid): Promise<ViewCalendar | null> {
    try {
      const agg = await this.calendar.getAggregate(calendar_id);
      const result = agg.activateSync();
      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        return this.viewCalendar.get(calendar_id);
      }

      throw new Error(`Could not activate calendar sync ( ${agg.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async deactivateCalendarSync(calendar_id: Uuid): Promise<ViewCalendar | null> {
    try {
      const agg = await this.calendar.getAggregate(calendar_id);
      const result = agg.deactivateSync();
      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        return this.viewCalendar.get(calendar_id);
      }

      throw new Error(`Could not deactivate calendar sync ( ${agg.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   * Retrieve all known calendars or a newly created default one if none exists
   * for current user.
   *
   * @todo Deal with default calendar creation failure ?
   */
  async getCalendars(): Promise<ViewCalendar[]> {
    try {
      /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
      const calendarAccounts = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));
      let calendars = (await this.viewCalendar.getAll()).filter(
        (c) => !('account_id' in c) || calendarAccounts.has(c.account_id)
      );

      if (!calendars.length) {
        const default_tzid = toTzid(dayjs.tz.guess());
        const defaultCalendar = await this.createCalendar({ type: 'UNIPILE', name: 'unipile', default_tzid });
        calendars = defaultCalendar ? [defaultCalendar] : [];
      }

      return calendars;
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async getCalendar(calendar_id: Uuid): Promise<ViewCalendar | null> {
    try {
      return this.viewCalendar.get(calendar_id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async getCalendarsByExternalId(account_id: Uuid, external_ids: SingleOrArray<string>): Promise<ViewCalendar[]> {
    try {
      return this.viewCalendar.getByExternalId(account_id, external_ids);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async getCalendarsByAccountId(account_id: Uuid): Promise<ExternalCalendar[]> {
    try {
      return this.viewCalendar.getByAccountId(account_id);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  async getEvents(min_date: UTCDate, max_date: UTCDate): Promise<(ViewCalendarEvent & Taggable)[]> {
    /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
    const calendarAccounts = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));
    return (await this.viewCalendarEvent.getByDate(min_date, max_date)).filter(
      (e) => !('account_id' in e) || calendarAccounts.has(e.account_id)
    );
  }

  /**
   *
   */
  async getEventsByCalendars(
    min_date: UTCDate,
    max_date: UTCDate,
    calendarIds: Set<Uuid>
  ): Promise<(ViewCalendarEvent & Taggable)[]> {
    /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
    const calendarAccounts = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));
    return (await this.viewCalendarEvent.getByDate(min_date, max_date)).filter(
      (e) => (!('account_id' in e) || calendarAccounts.has(e.account_id)) && calendarIds.has(e.calendar_id)
    );
  }

  /**
   *
   */
  async getEvent(id: Uuid | CalendarEventInstanceId): Promise<Result<(ViewCalendarEvent & Taggable) | null>> {
    try {
      const event = await this.viewCalendarEvent.get(id);

      return { result: event };
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async getAllPastEvents(size: number, offset: number, calendarIds: Set<Uuid>): Promise<(ViewCalendarEvent & Taggable)[]> {
    /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
    const calendarAccounts = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));
    return (await this.viewCalendarEvent.getAllPast(size, offset)).filter(
      (e) => (!('account_id' in e) || calendarAccounts.has(e.account_id)) && calendarIds.has(e.calendar_id)
    );
  }

  /**
   *
   */
  async getAllFutureEvents(size: number, offset: number, calendarIds: Set<Uuid>): Promise<(ViewCalendarEvent & Taggable)[]> {
    /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
    const calendarAccounts = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));
    return (await this.viewCalendarEvent.getAllFuture(size, offset)).filter(
      (e) => (!('account_id' in e) || calendarAccounts.has(e.account_id)) && calendarIds.has(e.calendar_id)
    );
  }

  /**
   *
   */
  async getInstancesOf(recurringEventId: Uuid, min_date: UTCDateTimeMs, max_date: UTCDateTimeMs): Promise<ViewCalendarEvent[]> {
    return (await this.viewCalendarEvent.getInstancesOf(recurringEventId)).filter(
      (instance) => instance.start >= min_date && instance.start < max_date
    );
  }

  /**
   *
   */
  async getRecurringRoot(recurringEventId: Uuid): Promise<RecurringCalendarEventRoot | null> {
    return this.viewCalendarEvent.getRecurringRoot(recurringEventId);
  }

  /**
   *
   */
  async createSingleEvent(values: NewCalendarEventDTO & { kind: 'SINGLE' }): Promise<Result<ViewCalendarEvent>> {
    try {
      const result = CalendarEvent.create(values);
      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const event = await this.viewCalendarEvent.get(result.aggregate.id);

        if (!event) {
          throw new Error('Cannot retrieve event after creation ( ' + result.aggregate.id + ' )');
        }

        return { result: event };
      }

      throw new Error(`Could not create calendar event : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async createRecurringEvent(
    values: NewCalendarEventDTO & { kind: 'RECURRING' },
    completeInstancesBefore?: UTCDateTimeMs
  ): Promise<Result<Uuid>> {
    try {
      const { changes, ...result } = CalendarEvent.create({
        ...values,
        ...(completeInstancesBefore &&
          values.start < completeInstancesBefore && { statusOptions: [{ status: 'DONE', to: completeInstancesBefore }] }),
      });

      if (result.ok === true) {
        await this.publisher.emit(changes);
        return { result: result.aggregate.id };
      }

      throw new Error(`Could not create calendar event : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @todo Consider requiring more info about the event in the parameters to distinguish
   *       kind at least.
   *
   * @todo Figure out what to do with/how to decide between edit/addException.
   *       We may need to add a tag in the projection to mark exception from regular
   *       instances.
   */
  async editEvent(
    event_id: Uuid | CalendarEventInstanceId,
    values: PatchCalendarEventDTO
  ): Promise<Result<ViewCalendarEvent | RecurringCalendarEventRoot>> {
    try {
      const event = await this.viewCalendarEvent.get(event_id);
      if (event && event.kind === 'SINGLE' && event.id) {
        const agg = await this.calendarEvent.getAggregate(event.id);
        const result = agg.edit(values);
        await this.publisher.emit(result.changes);

        if (result.ok === true) {
          const event =
            values.kind === 'SINGLE'
              ? await this.viewCalendarEvent.get(event_id)
              : await this.viewCalendarEvent.getRecurringRoot(result.changes[0]?.aggregateId);
          if (!event)
            throw new Error(
              'Cannot retrieve event after edit ( ' + values.kind === 'SINGLE' ? event_id : result.changes[0]?.aggregateId + ' )'
            );

          return { result: event };
        }
        throw new Error(`Could not edit calendar event ( ${agg.id} ) : ${result.reason}.`);

        // await this.publisher.emit(result.changes);
      }
      throw new Error(`Could not edit calendar event, event ( ${event_id} ) doesn't exist.`);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async editInstanceEvent(
    event_id: CalendarEventInstanceId | Uuid,
    values: CalendarEventExceptionDTO
  ): Promise<Result<ViewCalendarEvent & Taggable>> {
    try {
      const event = await this.viewCalendarEvent.get(event_id);
      if (event && event.kind === 'INSTANCE') {
        const aggId = event.recurring_event_id;
        const agg = await this.calendarEvent.getAggregate(aggId);
        const result = agg.editException(event_id as CalendarEventInstanceId, values);
        await this.publisher.emit(result.changes);

        if (result.ok === true) {
          const event = await this.viewCalendarEvent.get(event_id);

          if (!event) throw new Error('Cannot retrieve event after edit ( ' + event_id + ' )');

          return { result: event };
        }
        throw new Error(`Could not edit calendar event ( ${agg.id} ) : ${result.reason}.`);

        // await this.publisher.emit(result.changes);
      }
      throw new Error(`Could not edit calendar event, event ( ${event_id} ) doesn't exist.`);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async editRecurringEvent(
    recurring_event_id: Uuid,
    values: PatchCalendarEventDTO
  ): Promise<Result<RecurringCalendarEventRoot | (ViewCalendarEvent & Taggable)>> {
    try {
      const agg = await this.calendarEvent.getAggregate(recurring_event_id);

      const result = agg.edit(values);
      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const event =
          values.kind === 'RECURRING'
            ? await this.viewCalendarEvent.getRecurringRoot(recurring_event_id)
            : await this.viewCalendarEvent.get(result.changes[0]?.aggregateId);
        if (!event)
          throw new Error(
            'Cannot retrieve event after edit ( ' + values.kind === 'RECURRING'
              ? recurring_event_id
              : result.changes[0]?.aggregateId + ' )'
          );

        return { result: event };
      }

      throw new Error(`Could not edit calendar event ( ${agg.id} ) : ${result.reason}.`);
      //   const result = isSingleEvent ? agg.edit(values) : agg.editException(event_id as CalendarEventInstanceId, values);
      //   await this.publisher.emit(result.changes);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   * @todo Decide if moveEvent is a use case convenience over CALENDAREVENT_EDITED
   *       or if it warrants its own CALENDAREVENT_MOVED.
   *       This version considers that moveEvent is just one particular way to edit
   *       a CalendarEvent but the domain doesn't care about the 'how'.
   */
  async moveEvent(event: ViewCalendarEvent, start: dayjs.Dayjs): Promise<ViewCalendarEvent | null> {
    const duration = dayjs(event.end).diff(event.start, 'hour', true);
    const end = start.add(duration, 'hour');
    try {
      let agg: CalendarEvent;
      let result: ReturnType<CalendarEvent['edit'] | CalendarEvent['addException']>;

      switch (event.kind) {
        case 'SINGLE':
          agg = await this.calendarEvent.getAggregate(event.id);
          result = agg.edit({
            type: event.type,
            kind: event.kind,
            /**
             * @note This is necessary to quiet TS for now, we'll see about relaxing
             *       CalendarEventDate requirement later.
             */
            ...(event.all_day
              ? {
                  all_day: true,
                  start_tzid: null,
                  end_tzid: null,
                }
              : {
                  all_day: false,
                  start_tzid: event.start_tzid,
                  end_tzid: event.end_tzid,
                }),
            start: toUTCDateTimeMs(start),
            end: toUTCDateTimeMs(end),
          });
          break;
        case 'INSTANCE':
          agg = await this.calendarEvent.getAggregate(event.recurring_event_id);
          result = agg.addException(
            event.id,
            /**
             * @todo Figure out why this yielded a type error assertion during build.
             */
            {
              type: event.type,
              start: toUTCDateTimeMs(start),
              end: toUTCDateTimeMs(end),
            } as CalendarEventExceptionDTO
          );
          break;
      }
      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        return this.viewCalendarEvent.get(event.id);
      }

      throw new Error(`Could not move calendar event ( ${agg.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }
  /**
   * @todo Consider requiring more info about the event in the parameters to distinguish
   *       kind at least.
   */
  async deleteEvent(event_id: Uuid | CalendarEventInstanceId): Promise<Result<void>> {
    try {
      const event = await this.viewCalendarEvent.get(event_id);
      if (event) {
        const aggId = event.kind === 'SINGLE' ? event.id : event.recurring_event_id;
        const agg = await this.calendarEvent.getAggregate(aggId);
        await this.publisher.emit(event.kind === 'INSTANCE' ? agg.deleteInstance(event.id).changes : agg.delete().changes);
      }
      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async deleteRecurringEvent(recurring_event_id: Uuid): Promise<Result<void>> {
    try {
      const event = await this.viewCalendarEvent.getRecurringRoot(recurring_event_id);
      if (event) {
        const agg = await this.calendarEvent.getAggregate(recurring_event_id);
        await this.publisher.emit(agg.delete().changes);
      }
      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Set event to done status
   *
   * @todo Replace retry loop with https://trello.com/c/gOvxsqcJ/1042-event-sourcing-implement-a-retry-helper-for-occ
   *
   * @todo Consider agg snapshots. It should reuse projectionConfig.
   *       Combine the idea of agg snapshots and periodic conditional agg timeline compaction.
   */
  async completeEvent(event: ViewCalendarEvent): Promise<string | void> {
    try {
      const maxRetries = 10;
      const baseDelayBetweenRetriesInMilliseconds = 50;
      const maximumBaseBackoffInMilliseconds = 2000;
      let opDurationInMilliseconds: number;
      let delay = 0;

      /** @todo Track real_start ! */
      const now = toUTCDateTimeMs(dayjs());

      for (let i = 0; i < maxRetries; ++i) {
        const t0 = performance.now();
        try {
          const aggId = event.kind === 'SINGLE' ? event.id : event.recurring_event_id;
          const agg = await this.calendarEvent.getAggregate(aggId);

          const result = event.kind === 'INSTANCE' ? agg.completeInstance(event.id, now, now) : agg.complete(now, now);
          await this.publisher.emit(result.changes);
          const t1 = performance.now();
          // console.log(`completeEvent try done in ${t1 - t0} milliseconds.`);
          return;
        } catch (error) {
          if (!(error instanceof DOMException && (error.name === 'ConstraintError' || error.name === 'AbortError'))) {
            throw error;
          }
          const t1 = performance.now();
          opDurationInMilliseconds = t1 - t0;
          // console.log(`completeEvent try failed in ${opDurationInMilliseconds} milliseconds.`);
          delay =
            Math.min(delay + baseDelayBetweenRetriesInMilliseconds + opDurationInMilliseconds, maximumBaseBackoffInMilliseconds) +
            Math.random() * baseDelayBetweenRetriesInMilliseconds;
          // prettyPrint({ event_id: event.id, i, delay }, 'completeEvent');
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }
      throw new RetriesExhaustedError(maxRetries, `AppCalendarUseCase.completeEvent on event_id : ${event.id}`);
    } catch (e) {
      this.logger.captureException(e);
      return this.ERROR_UNEXPECTED;
    }
  }

  /**
   * @note We are using Optimistic Concurrency Control on aggregate updates.
   *       That means that if you try to issue multiple commands and emit the
   *       resulting events in parallel for the same aggregate, it will fail.
   *
   *       The DB layer will rightfully reject the commit ( DOMException: AbortError
   *       for IndexedDB ).
   */
  async missPastEvents(): Promise<ViewNotification[] | { error: string }> {
    try {
      const oldest = await this.viewCalendarEvent.getOldestByStatus('PLANNED');

      const now = dayjs();

      if (oldest && dayjs(oldest.start).isBefore(now)) {
        const from = toUTCDate(oldest.start);
        const to = toUTCDate(now);

        /** @note This is a stopgap until we can 'cascade' projections deletion safely. */
        const calendarAccounts = new Set((await this.viewAccount.getByType('GOOGLE_CALENDAR')).map((a) => a.id));
        const planned_events = (await this.viewCalendarEvent.getByDate(from, to)).filter(
          (e) =>
            (!('account_id' in e) || calendarAccounts.has(e.account_id)) &&
            e.status === 'PLANNED' &&
            dayjs(e.end).isBefore(now) &&
            !document.location.pathname.includes(e.id) && // If openned in the work mode, the event id is in the URL
            !document.location.href.includes(e.id)
        );

        const result: ViewNotification[] = [];
        for (const e of planned_events) {
          const resultMissEvent = await this.missEvent(e.id);
          //   prettyPrint({ notification, e }, 'missPastEvents', 'warn');
          if (resultMissEvent.ok === true) {
            result.push(resultMissEvent.value);
          } else {
            console.log(`Cannot mark event ${e.id} as missed because its state is ' + ${resultMissEvent.error}`);
          }
        }
        return result;

        /**
         * @todo Handle errors ? For now if one fail it wont effect others events, but this one will fail every time
         */
      }
      return [];
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Set event to missed status and create a missed event type notification
   *
   *
   *
   */
  private async missEvent(
    event_id: Uuid | UnipileCalendarEventInstanceId //   : Promise< //     ResultEither< //       ViewNotification, //       | 'DELETED' //       | 'SNOOZED' //       | 'MISSED'
  ) //       | 'DONE'
  //       | 'NOT A RECURRING EVENT'
  //       | 'INSTANCE DELETED'
  //       | 'INSTANCE SNOOZED'
  //       | 'INSTANCE MISSED'
  //       | 'INSTANCE DONE'
  //       | 'NOT A SINGLE EVENT'
  //     >
  //   >
  {
    // console.warn('missEvent...', event_id);
    // try {
    const event = await this.viewCalendarEvent.get(event_id);
    if (!event) throw new Error('Event to miss not found');

    let acc_id: Uuid | undefined = undefined;
    let account_type: AccountType | undefined = undefined;

    let notification_attendees: Attendee[] = [];
    if (event.metadata?.attached_entity) {
      if (event.metadata.attached_entity.type === 'IM_THREAD') {
        const thread = await this.viewImThread.get(event.metadata.attached_entity.id);
        if (thread) {
          notification_attendees = thread.attendees.filter((att) => att.identifier !== 'YOU');
          acc_id = thread.account_id;
        }
      }
      if (event.metadata.attached_entity.type === 'MAIL') {
        const mail = await this.mailSync.getMailMeta(event.metadata.attached_entity.id);
        if (mail) {
          notification_attendees = [mail.from_attendee];
          acc_id = mail.account_id;
        }
      }
      if (event.metadata.attached_entity.type === 'CALL') {
        const call = await this.viewCall.get(event.metadata.attached_entity.id);
        if (call) {
          notification_attendees = [call.from_attendee];
          acc_id = call.account_id;
        }
      }
      if (event.metadata.attached_entity.type === 'MAIL_DRAFT') {
        const draft = await this.viewMailDraft.get(event.metadata.attached_entity.id);
        if (draft && draft.to_attendees) {
          notification_attendees = draft.to_attendees.map((att) => ({
            identifier: att.identifier,
            display_name: att.display_name,
            identifier_type: 'EMAIL_ADDRESS',
          }));
          acc_id = draft.account_id;
        }
      }
    }

    if (acc_id) {
      const account = await this.viewAccount.get(acc_id);
      if (account) account_type = account.type;
    }

    const aggId = event.kind === 'SINGLE' ? event.id : event.recurring_event_id;
    const eventAgg = await this.calendarEvent.getAggregate(aggId);

    // console.warn('About to Notification.create with', event_id + event.metadata?.origin_notification?.type + event.end);
    const { aggregate: notificationAgg, ...result } = Notification.create({
      type: 'EVENT_MISSED',
      title: event.summary,
      content: dayjs(event.start).format('dddd DD MMM - HH:mm'),
      received_date: event.end,
      attendees: notification_attendees,
      ...(account_type && { account_type }),
      metadata: {
        origin_event: {
          id: event_id,
        },
        ...(event.metadata?.attached_entity && { attached_entity: event.metadata?.attached_entity }),
        ...(event.metadata?.origin_notification && {
          origin_notification: {
            id: event.metadata.origin_notification.id,
            type: event.metadata.origin_notification.type,
          },
        }),
      },
    });

    const miss =
      event.kind === 'INSTANCE' ? eventAgg.missInstance(event.id, notificationAgg.id) : eventAgg.miss(notificationAgg.id);

    if (miss.ok === false) {
      // console.log(`Cannot mark event ${event.id} as missed because its state is ' + ${miss.reason}`);
      // return miss;
      return new ResultError(miss.reason);
    }

    //console.table([...result.changes, ...miss.changes]);
    await this.publisher.emit([...result.changes, ...miss.changes]);

    const notification = await this.viewNotification.get(notificationAgg.id);

    if (!notification) {
      throw new Error('Cannot get the created missed event notification');
    }

    // return { ok: true, result: notification } as const;
    return new ResultOk(notification);
    // } catch (e) {
    //   this.logger.captureException(e);
    //   return { ok: false, reason: e instanceof Error ? `${e.name} : ${e.message}` : this.ERROR_UNEXPECTED } as const;
    // }
  }

  /**
   * Compute a default from and to datetimes rounded to the closest quarter around now.
   * Ex : At 14:04, it will return 14:00
   * Ex : At 08:43, it will return 08:45
   * Inspired of big calendar Apps
   * @returns fromDate, toDate
   */
  getDefaultFromDatetime(): Dayjs {
    const now = dayjs();
    const minutes = now.get('minute');
    const hours = now.get('hour');
    const m = ((((minutes + 7.5) / 15) | 0) * 15) % 60;

    const h = (((minutes / 105 + 0.5) | 0) + hours) % 24;
    const rounded = now.set('hour', h).set('minute', m);

    return rounded;
  }

  /**
   * @todo Use an alias for values type.
   */
  getEventDraft(
    values: CalendarEventDraftDTO,
    default_tzid: Tzid,
    mode: FormMode,
    immediateCreation: boolean
  ): NewCalendarEventDTO {
    const isCreatedFromCalendar: boolean = mode === 'CALENDAR' && !immediateCreation;

    const start = values.start ? dayjs(values.start) : this.getDefaultFromDatetime();
    const end =
      values.end ?? isCreatedFromCalendar
        ? start.add(DEFAULT_CALENDAR_EVENT_INTERVAL, 'minute')
        : start.add(DEFAULT_EVENT_INTERVAL, 'minute');

    return {
      ...values,
      ...(values.all_day
        ? {
            all_day: true,
            start_tzid: null,
            end_tzid: null,
          }
        : {
            all_day: false,
            /** Allow values to overwrite start_tzid, end_tzid if they are provided. */
            start_tzid: values.start_tzid ?? default_tzid,
            end_tzid: values.end_tzid ?? default_tzid,
          }),
      summary: values.summary ?? '',
      start: toUTCDateTimeMs(start),
      end: values.all_day ? toUTCDateTimeMs(end.add(1, 'day')) : toUTCDateTimeMs(end),
    };
  }
}

export type CalendarEventDraftDTO = Select<UnipileCalendarEvent, 'calendar_id' | 'type'> &
  //   | Omit<
  //       Select<GoogleCalendarEvent, 'calendar_id' | 'account_id' | 'type'>,
  //       | 'external_id'
  //       | 'external_recurring_event_id'
  //       | 'etag'
  //       | 'ical_uid'
  //       | 'creator'
  //       | 'organizer'
  //       | 'html_link'
  //       | 'hangout_link'
  //       | 'locked'
  //       | 'attachments'
  //     >
  Strip<CalendarEventSingleKind | CalendarEventRecurringKind, 'id'> &
  Optional<CalendarEventDate>;

/**
 *
 */
export class RetriesExhaustedError extends Error {
  constructor(public maxRetries: number, public actor?: string, public lastResult?: unknown) {
    super(`Maximum number of retries exhausted : ${actor ?? 'it was'} tried ${maxRetries} times but failed.`);
    this.name = 'RetriesExhaustedError';
  }
}
