import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';

export const FilterCheckbox = withStyles({
  root: {
    color: grey[400],
    padding: 3,
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="secondary" size="small" disableRipple {...props} />);
