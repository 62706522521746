import React from 'react';
import { useSelector } from 'react-redux';
import { mailFromSelector, Uuid, ViewAccount } from '@focus-front/core';
import { FormControl, Select, MenuItem } from '@material-ui/core';

type Account = { id: Uuid; name: string; signature?: Uuid; identifier: string };

interface Props {
  value: string;
  // This is the regular field callback to update the string field in parent form
  onChange: (value: string) => void;

  // This is a callback to be used for side effects, returning more account informations
  onChangeAccount: (account: Account) => void;
}

export default function FromAccountField({ value, onChange, onChangeAccount }: Props) {
  const accounts = useSelector(mailFromSelector);

  function getItem(acc: Account) {
    let item = acc.name;
    if (acc.identifier !== acc.name) item += ` (${acc.identifier})`;
    return item;
  }

  return (
    <FormControl variant="standard" fullWidth>
      <Select
        value={value}
        label="From"
        onChange={(e, c) => {
          const identifier = e.target.value as string;
          const acc = accounts.find((acc) => acc.identifier === identifier);
          onChange(identifier);
          onChangeAccount(acc);
        }}
      >
        {accounts.map((acc) => (
          <MenuItem key={acc.id} value={acc.identifier}>
            {getItem(acc)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
