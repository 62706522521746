/* eslint-disable etc/throw-error */
import { useRef, useEffect } from 'react';

interface CancelablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

export function useCancellablePromise(dependencies: any[] = []): { cancellablePromise: <T>(p: Promise<T>) => typeof p } {
  const promises = useRef<CancelablePromise<any>[]>([]);

  useEffect(() => {
    promises.current = promises.current || [];
    return () => {
      promises.current.forEach((p) => p.cancel());
      promises.current = [];
    };
  }, dependencies);

  function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
    let isCanceled = false;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
      promise
        .then((val) => (isCanceled ? reject({ isCanceled }) : resolve(val)))
        .catch((error) => (isCanceled ? reject({ isCanceled }) : reject(error)));
    });

    return {
      promise: wrappedPromise,
      cancel() {
        isCanceled = true;
      },
    };
  }

  function cancellablePromise<T>(p: Promise<T>): typeof p {
    const cPromise = makeCancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  return { cancellablePromise };
}
