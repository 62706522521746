import React, { useEffect, useState } from 'react';

import { accountIdentifier, getAccount, Uuid, ViewAccount, useAppDispatch } from '@focus-front/core';
import { Tooltip, Typography, TypographyProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function AccountLabel({ account_id, ...rest }: { account_id: Uuid } & TypographyProps) {
  const [account, setAccount] = useState<ViewAccount | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async function () {
      setAccount(await dispatch(getAccount(account_id)));
    })();
  }, [account_id]);

  if (!account) return null;

  return (
    <Tooltip
      title={
        <>
          {account.type}{' '}
          {accountIdentifier(account.connection_params) !== account.name && (
            <> | {accountIdentifier(account.connection_params)}</>
          )}
        </>
      }
    >
      <Typography variant="body2" {...rest}>
        {account.name || accountIdentifier(account.connection_params)}
      </Typography>
    </Tooltip>
  );
}
