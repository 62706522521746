import {
  AggregateRoot,
  createProjectionConfig,
  createUuidFrom,
  DomainEvent,
  Uuid,
  CommandAccepted,
  CommandRejected,
} from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import { MessageModelCreated, MessageModelDeleted, MessageModelEdited, MessageModelUsed } from './events/MessageModel.events';
import { NewMessageModelDTO, UpdateMessageModelDTO } from './projections/ViewMessageModel';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const MESSAGE_MODEL_NAMESPACE = 'faf9d242-14c5-11ed-861d-0242ac120002' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  deleted: false,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  MESSAGE_MODEL_CREATED: (event) => ({
    id: event.aggregateId,
  }),
  MESSAGE_MODEL_DELETED: () => ({ deleted: true }),
});

/**
 * Aggregate.
 */
export class MessageModel extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static create(content: NewMessageModelDTO): CommandAccepted<DomainEvent, MessageModel> {
    const aggregateId = createUuidFrom(content.name, MESSAGE_MODEL_NAMESPACE);
    return MessageModel.accept(new MessageModelCreated(aggregateId, content));
  }

  /**
   * Command.
   */
  delete() {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new MessageModelDeleted(this.id, this.version)).accept();
  }

  /**
   * Command.
   */
  edit(values: UpdateMessageModelDTO) {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new MessageModelEdited(this.id, this.version, values)).accept();
  }

  /**
   * Command.
   */
  use(count: number) {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new MessageModelUsed(this.id, this.version, count)).accept();
  }
}
