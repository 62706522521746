import { automaticHelpSelector, discardAutomaticHelp, useAppDispatch } from '@focus-front/core';
import React from 'react';
import { useSelector } from 'react-redux';
import AutomaticHelpMailReply from './AutomaticHelpMailReply';

export default function AutomaticHelp() {
  const help = useSelector(automaticHelpSelector);
  const dispatch = useAppDispatch();
  return (
    <>
      <AutomaticHelpMailReply
        open={Boolean(help.ahi_mail_reply)}
        onClose={() => dispatch(discardAutomaticHelp('ahi_mail_reply'))}
      />

      {/* Add other modals here.... */}
    </>
  );
}
