import { createProjector } from '../../../Common';
import { CalendarEventRepo } from '../../infra/repository/CalendarEventRepo';
import { CalendarEvent } from '../CalendarEvent';
import { createRecurringCalendarEventRoot, RecurringCalendarEventRoot } from '../projections/RecurringCalendarEventRoot';
import { createViewCalendarEvent } from '../projections/ViewCalendarEvent';
import { ViewCalendarEventRepo } from '../projections/ViewCalendarEventRepo';
import { CalendarEventDTO } from '../types/CalendarEventDTO';
import { CalendarEventException } from '../types/CalendarEventException';

export interface ViewCalendarEventsProjectorRepos {
  viewCalendarEvent: ViewCalendarEventRepo;
  calendarEvent: CalendarEventRepo;
}

/**
 * Handler/Projector.
 *
 * @note You have to annotate the first repo argument for Typescript to infer
 *       createProjector type, only then will you get all the autocompletion.
 *
 *       Doing createProjector<ViewCalendarRepo>(...) yields the same type constraints
 *       but Typescript gets weird and doesn't autocomplete because it doesn't
 *       do partial type inference.
 *
 * @note To do anything async that you expect to be done by
 *       the time await publisher.emit(...) is done, the handler must be async
 *       or return the appropriate promise, e.g. :
 *
 *       CALENDAREVENT_CREATED: async (
 *         { aggregateId, calendarEvent },
 *         repos: ViewCalendarEventsProjectorRepos
 *       ) => repos.viewCalendarEvent.add(createViewCalendarEvent(aggregateId, calendarEvent)),
 *
 * @todo Find a way to help TS and get rid of as CalendarEventDTO
 *       assertions.
 */
export const viewCalendarEventsProjector = createProjector('viewCalendarEventsProjector', {
  CALENDAREVENT_CREATED: async ({ aggregateId, calendarEvent, statusOptions }, repos: ViewCalendarEventsProjectorRepos) => {
    switch (calendarEvent.kind) {
      case 'SINGLE':
        return repos.viewCalendarEvent.add(createViewCalendarEvent(aggregateId, calendarEvent));
      case 'RECURRING': {
        if (statusOptions) {
          const exceptions = CalendarEvent.expandStatusOptionsAsMap(aggregateId, calendarEvent, statusOptions);
          return Promise.all([
            repos.viewCalendarEvent.addRecurringRoot(createRecurringCalendarEventRoot(aggregateId, calendarEvent, exceptions)),
            repos.viewCalendarEvent.expand(aggregateId, calendarEvent, exceptions),
          ]);
        }
        return Promise.all([
          repos.viewCalendarEvent.addRecurringRoot(createRecurringCalendarEventRoot(aggregateId, calendarEvent)),
          repos.viewCalendarEvent.expand(aggregateId, calendarEvent),
        ]);
      }
    }
  },

  CALENDAREVENT_ACKNOWLEDGED: async ({ aggregateId, calendarEvent, statusOptions }, repos) => {
    switch (calendarEvent.kind) {
      case 'SINGLE':
        return repos.viewCalendarEvent.add(createViewCalendarEvent(aggregateId, calendarEvent));
      case 'RECURRING': {
        if (statusOptions) {
          const exceptions = CalendarEvent.expandStatusOptionsAsMap(aggregateId, calendarEvent, statusOptions);
          return Promise.all([
            repos.viewCalendarEvent.addRecurringRoot(createRecurringCalendarEventRoot(aggregateId, calendarEvent, exceptions)),
            repos.viewCalendarEvent.expand(aggregateId, calendarEvent, exceptions),
          ]);
        }
        return Promise.all([
          repos.viewCalendarEvent.addRecurringRoot(createRecurringCalendarEventRoot(aggregateId, calendarEvent)),
          repos.viewCalendarEvent.expand(aggregateId, calendarEvent),
        ]);
      }
    }
  },

  CALENDAREVENT_RESTORE_ACKNOWLEDGED: async ({ aggregateId, calendarEvent }, repos) => {
    switch (calendarEvent.kind) {
      case 'SINGLE':
        return repos.viewCalendarEvent.add(createViewCalendarEvent(aggregateId, calendarEvent));
      case 'RECURRING': {
        return Promise.all([
          repos.viewCalendarEvent.addRecurringRoot(createRecurringCalendarEventRoot(aggregateId, calendarEvent)),
          repos.viewCalendarEvent.expand(aggregateId, calendarEvent),
        ]);
      }
    }
  },

  CALENDAREVENT_EDITED: async ({ aggregateId, patch, mustExpand }, repos) => {
    switch (patch.kind) {
      case 'SINGLE': {
        if (mustExpand) {
          const { exceptions, recurrence, ...strippedRoot } = (await repos.viewCalendarEvent.removeRecurringRoot(
            aggregateId
          )) as RecurringCalendarEventRoot & { kind: 'RECURRING' };

          return Promise.all([
            repos.viewCalendarEvent.removeInstancesOf(aggregateId),
            repos.viewCalendarEvent.add(createViewCalendarEvent(aggregateId, { ...strippedRoot, ...patch } as CalendarEventDTO)),
          ]);
        }
        return repos.viewCalendarEvent.patch(aggregateId, patch);
      }

      case 'RECURRING': {
        /** @todo Double check how Single->Recurring is tested in the spec. */
        return mustExpand
          ? repos.viewCalendarEvent.reExpand(aggregateId, patch)
          : repos.viewCalendarEvent.patchRecurringEvent(aggregateId, patch);
      }
    }
  },

  CALENDAREVENT_PATCH_ACKNOWLEDGED: async ({ aggregateId, patch, mustExpand }, repos) => {
    switch (patch.kind) {
      case 'SINGLE':
        if (mustExpand) {
          const { exceptions, recurrence, ...strippedRoot } = (await repos.viewCalendarEvent.removeRecurringRoot(
            aggregateId
          )) as RecurringCalendarEventRoot & { kind: 'RECURRING' };

          return Promise.all([
            repos.viewCalendarEvent.removeInstancesOf(aggregateId),
            repos.viewCalendarEvent.add(createViewCalendarEvent(aggregateId, { ...strippedRoot, ...patch } as CalendarEventDTO)),
          ]);
        }
        return repos.viewCalendarEvent.patch(aggregateId, patch);
      case 'RECURRING': {
        return mustExpand
          ? /**
             * @todo Weight discarding vs ignoring exceptions. Isn't it worse to have
             *       dangling exceptions ? Should we trust that an exception cancelling
             *       event will follow ? How about using the optimistic update path
             *       here and firing the complete reExpand transaction ?
             *
             *       discard -> overwrite with addRecurring instead of using patchRootOf, expand
             *       optimistic update -> use reExpand
             *       messy mishmash -> patchRootOf, removeInstancesOf, expand
             */
            //   ? Promise.all([
            //     repos.viewCalendarEvent.addRecurringRoot(
            //       createRecurringCalendarEventRoot(aggregateId, calendarEvent)
            //     ),
            //     repos.viewCalendarEvent.expand(aggregateId, calendarEvent),
            //   ])
            repos.viewCalendarEvent.reExpand(aggregateId, patch)
          : /**
             * @todo Weight discarding vs ignoring exceptions. Same idea here.
             */
            repos.viewCalendarEvent.patchRecurringEvent(aggregateId, patch);
        //   : Promise.all([
        //       repos.viewCalendarEvent.patchRootOf(aggregateId, patch),
        //       repos.viewCalendarEvent.patchInstancesOf(aggregateId, patch),
        //     ]);
      }
    }
  },

  CALENDAREVENT_ETAG_ACKNOWLEDGED: async ({ aggregateId, patch }, repos) => {
    try {
      switch (patch.kind) {
        case 'SINGLE':
          await repos.viewCalendarEvent.patch(aggregateId, { etag: patch.etag });
          break;
        case 'RECURRING': {
          await repos.viewCalendarEvent.patchRecurringEvent(aggregateId, patch);
          break;
        }
      }
      return;
    } catch {
      /**
       * Let it silently fail, this is not an error, most of the time
       * RecurringCalendarEventRoot and/or ViewCalendarEvent
       * is not going to exist when this event is played.
       */
      return;
    }
  },

  CALENDAREVENT_EXCEPTION_ADDED: async ({ aggregateId, instanceId, exception }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.patch(instanceId, exception),
      repos.viewCalendarEvent.putException(aggregateId, instanceId, exception),
    ]);
  },

  CALENDAREVENT_EXCEPTION_ACKNOWLEDGED: async ({ aggregateId, instanceId, exception }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.patch(instanceId, exception),
      repos.viewCalendarEvent.putException(aggregateId, instanceId, exception),
    ]);
  },

  CALENDAREVENT_EXCEPTION_RESTORE_ACKNOWLEDGED: async ({ aggregateId, instanceId, exception }, repos) => {
    /**
     * @todo Replace this hack with a more specific restore method on the repo,
     *       this is A LOT OF WORK to restore a single instance and patch it.
     */
    await repos.viewCalendarEvent.putException(aggregateId, instanceId, exception);
    const root = await repos.viewCalendarEvent.getRecurringRoot(aggregateId);
    if (root && root.kind === 'RECURRING') {
      // root.exceptions.set(instanceId, exception);
      return repos.viewCalendarEvent.reExpand(aggregateId, {
        kind: root.kind,
        recurrence: root.recurrence,
        // exceptions: root.exceptions,
      });
    }

    // await repos.viewCalendarEvent.cancelException(aggregateId, instanceId); // doesn't handle deleted instances.
    // return Promise.all([
    //   repos.viewCalendarEvent.patch(instanceId, exception),
    //   repos.viewCalendarEvent.putException(aggregateId, instanceId, exception),
    // ]);
  },

  CALENDAREVENT_EXCEPTION_EDITED: async ({ aggregateId, instanceId, exceptionPatch }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.patch(instanceId, exceptionPatch),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, exceptionPatch),
    ]);
  },

  CALENDAREVENT_EXCEPTION_PATCH_ACKNOWLEDGED: async ({ aggregateId, instanceId, exceptionPatch }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.patch(instanceId, exceptionPatch),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, exceptionPatch),
    ]);
  },

  CALENDAREVENT_EXCEPTION_ETAG_ACKNOWLEDGED: async ({ aggregateId, instanceId, etag }, repos) => {
    /** @note Replace with Promise.allSettled when compiler target allows. */
    return Promise.all([
      /**
       * Let it silently fail, this is not an error, most of the time
       * RecurringCalendarEventRoot and/or ViewCalendarEvent
       * is not going to exist when this event is played.
       */
      repos.viewCalendarEvent.patch(instanceId, { etag }).catch((e) => e),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, { etag }).catch((e) => e),
    ]);
  },

  CALENDAREVENT_EXCEPTION_CANCELLED: async ({ aggregateId, instanceId }, repos) => {
    return repos.viewCalendarEvent.cancelException(aggregateId, instanceId);
  },

  CALENDAREVENT_EXCEPTION_CANCEL_ACKNOWLEDGED: async ({ aggregateId, instanceId }, repos) => {
    return repos.viewCalendarEvent.cancelException(aggregateId, instanceId);
  },

  CALENDAREVENT_DELETED: async ({ aggregateId, kind }, repos) => {
    switch (kind) {
      case 'SINGLE':
        return repos.viewCalendarEvent.remove(aggregateId);
      case 'RECURRING': {
        return Promise.all([
          repos.viewCalendarEvent.removeInstancesOf(aggregateId),
          repos.viewCalendarEvent.removeRecurringRoot(aggregateId),
        ]);
      }
    }
  },

  CALENDAREVENT_DELETE_ACKNOWLEDGED: async ({ aggregateId, kind }, repos) => {
    switch (kind) {
      case 'SINGLE':
        return repos.viewCalendarEvent.remove(aggregateId);
      case 'RECURRING': {
        return Promise.all([
          repos.viewCalendarEvent.removeInstancesOf(aggregateId),
          repos.viewCalendarEvent.removeRecurringRoot(aggregateId),
        ]);
      }
    }
  },

  CALENDAREVENT_INSTANCE_DELETED: async ({ aggregateId, instanceId }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.remove(instanceId),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, { status: 'DELETED' }),
    ]);
  },

  CALENDAREVENT_INSTANCE_DELETE_ACKNOWLEDGED: async ({ aggregateId, instanceId, calendarType, etag }, repos) => {
    let patch: CalendarEventException;

    switch (calendarType) {
      case 'GOOGLE':
        patch = { status: 'DELETED', google_status: 'cancelled', etag };
        break;
    }

    return Promise.all([
      repos.viewCalendarEvent.remove(instanceId),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, patch),
    ]);
  },

  CALENDAREVENT_SNOOZED: async ({ aggregateId }, repos) => {
    return repos.viewCalendarEvent.remove(aggregateId);
  },

  CALENDAREVENT_MISSED: async ({ aggregateId }, repos) => {
    return repos.viewCalendarEvent.patch(aggregateId, { status: 'MISSED' });
  },

  CALENDAREVENT_INSTANCE_MISSED: async ({ aggregateId, instanceId }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.patch(instanceId, { status: 'MISSED' }),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, { status: 'MISSED' }),
    ]);
  },

  CALENDAREVENT_DONE: async ({ aggregateId, realStart: real_start, realEnd: real_end }, repos) => {
    return repos.viewCalendarEvent.patch(aggregateId, { status: 'DONE', real_start, real_end });
  },

  CALENDAREVENT_INSTANCE_DONE: async ({ aggregateId, instanceId, realStart: real_start, realEnd: real_end }, repos) => {
    return Promise.all([
      repos.viewCalendarEvent.patch(instanceId, { status: 'DONE', real_start, real_end }),
      repos.viewCalendarEvent.patchException(aggregateId, instanceId, {
        status: 'DONE',
        real_start,
        real_end,
      }),
    ]);
  },

  //   CALENDAREVENT_RESCHEDULED: async (event, repos) => {
  //     const { aggregateId, start_datetime } = event;
  //     const rollback = new DecisionProjection(rollbackProjection);
  //     const events = await repos.calendarEvent.getDomainEventsAt(aggregateId, event);
  //     // const events = await repos.calendarEvent.getDomainEvents(aggregateId);
  //     // const eventsAt = await repos.calendarEvent.getDomainEventsAt(aggregateId, event);
  //     // console.log('CALENDAREVENT_RESCHEDULED', events, eventsAt);

  //     const projection = rollback.process(events).state;

  //     const duration = dayjs(projection.end).diff(projection.start, 'hour', true);
  //     const start = dayjs(start_datetime);
  //     const end = start.add(duration, 'hour');

  //     repos.viewCalendarEvent.add({
  //       ...projection,
  //       start: toUTCDateTimeMs(start),
  //       end: toUTCDateTimeMs(end),
  //     });
  //   },
});

/**
 * Dedicated handler to restore a deleted projection.
 */
// const rollbackProjection = createProjectionConfig({} as ViewCalendarEvent, {
//   CALENDAREVENT_CREATED: (event) => ({
//     ...createViewCalendarEvent(event.aggregateId, event.calendarEvent),
//     /**
//      * @note Squelching type mismatch introduced by : id: Uuid | UnipileCalendarEventInstanceId
//      *       in ViewCalendarEventBase.
//      */
//     id: event.aggregateId,
//   }),
//   //   CALENDAREVENT_EDITED: (event, state) => ({ ...state, ...event.updatedValues }),
//   CALENDAREVENT_EDITED: (event) => ({ ...event.values }),
//   // CALENDAREVENT_SNOOZED: (event) => ({ metadata: { notification_id: event.notificationId } }),
//   // CALENDAREVENT_MISSED: (event) => ({ metadata: { notification_id: event.notificationId } }),
//   // CALENDAREVENT_DONE: (event, state) => ({ real_start: event.realStart, real_end: event.realEnd }),
// });

/**
 *
 */
// function AreEquivalent<T extends unknown[]>(a: T, b: T): boolean {
//   if (a.length !== b.length) {
//     return false;
//   }

//   for (let i = 0, length = a.length; i < length; i++) {
//     if (a[i] !== b[i]) {
//       return false;
//     }
//   }
//   return true;
// }
