import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_translation from '../../../../../../locales/en/translation.json';
import fr_translation from '../../../../../../locales/fr/translation.json';
import it_translation from '../../../../../../locales/it/translation.json';
import es_translation from '../../../../../../locales/es/translation.json';
import de_translation from '../../../../../../locales/de/translation.json';

const resources = {
  en: {
    translation: en_translation,
  },
  fr: {
    translation: fr_translation,
  },
  it: {
    translation: it_translation,
  },
  es: {
    translation: es_translation,
  },
  de: {
    translation: de_translation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'it', 'es', 'de'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      /**
       * @note Use the navigator as the main source to detect language initially.
       *       We have our own way to track the language per account after that.
       *       Caching has been disabled as it was getting in the way :
       *         - For testing.
       *         - If the user changes their preferred language on their device,
       *           the cached value would still be used before login.
       */
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag'],
      caches: [],
    },
  });

/**
 * @note The LanguageDetector will check navigator.languages where available on top of
 *       navigator.language. It's not clear how it chooses the main languages from the
 *       array of possible languages it builds.
 *
 *       This caused problems on Android, where it wasn't selecting the 'main'
 *       navigator.language but some other language in the navigator.languages
 *       list. While there is probably an explanation for this hidden somewhere,
 *       I'm getting more consistent results across platforms/browser with
 *       navigator.language.
 *
 *       On Android we should use the ionic Device plugin, to get the system's
 *       preferred language rather than the webview/chrome set language.
 */
if (navigator.language) {
  i18n.changeLanguage(navigator.language);
}
export default i18n;
