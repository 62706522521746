import { IonIcon } from '@ionic/react';
import { Button } from '@material-ui/core';
import { callOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewCall, getCallView, Uuid, useAppDispatch } from '@focus-front/core';
import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';

export default function CallCallbackAction({ onDone, context }: ActionModalProps) {
  const [call, setCall] = useState<ViewCall>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  let call_id = context.metadata.attached_entity.id;

  useEffect(() => {
    (async function () {
      const call = await dispatch(getCallView(call_id));
      setCall(call);
    })();
  }, [call_id]);

  const handleCall = () => {
    window.open('tel:' + call?.from_attendee.identifier);
  };

  return (
    <ActionModal expandable={false}>
      {call && (
        <Button startIcon={<IonIcon icon={callOutline} />} onClick={handleCall}>
          {call.from_attendee.identifier}
        </Button>
      )}
    </ActionModal>
  );
}
