/** 
       * @see https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations
       * 
       * @todo Handle or select appropriately amongst duplicates.
       */
 export const TimeZoneAbbreviations: Record<string, string> = {
    ACDT: '+1030', //Australian Central Daylight Saving Time
    ACST: '+0930', //Australian Central Standard Time
    ACT: '-0500', //Acre Time
    // ACT:	"+0800",//ASEAN Common Time (proposed)
    ACWST: '+0845', //Australian Central Western Standard Time (unofficial)
    ADT: '-0300', //Atlantic Daylight Time
    AEDT: '+1100', //Australian Eastern Daylight Saving Time
    AEST: '+1000', //Australian Eastern Standard Time
    AET: '+1000', //+10/+11 //Australian Eastern Time
    AFT: '+0430', //Afghanistan Time
    AKDT: '-0800', //Alaska Daylight Time
    AKST: '-0900', //Alaska Standard Time
    ALMT: '+0600', //Alma-Ata Time[1]
    AMST: '-0300', //Amazon Summer Time (Brazil)[2]
    AMT: '-0400', //Amazon Time (Brazil)[3]
    // AMT: '+0400', //Armenia Time
    ANAT: '+1200', //Anadyr Time[4]
    AQTT: '+0500', //Aqtobe Time[5]
    ART: '-0300', //Argentina Time
    AST: '+0300', //Arabia Standard Time
    // AST: '-0400', //Atlantic Standard Time
    AWST: '+0800', //Australian Western Standard Time
    AZOST: '+0000', //Azores Summer Time
    AZOT: '-0100', //Azores Standard Time
    AZT: '+0400', //Azerbaijan Time
    BNT: '+0800', //Brunei Time
    BIOT: '+0600', //British Indian Ocean Time
    BIT: '-1200', //Baker Island Time
    BOT: '-0400', //Bolivia Time
    BRST: '-0200', //Brasília Summer Time
    BRT: '-0300', //Brasília Time
    // BST: '+0600', //Bangladesh Standard Time
    // BST: '+1100', //Bougainville Standard Time[6]
    BST: '+0100', //British Summer Time (British Standard Time from Feb 1968 to Oct 1971)
    BTT: '+0600', //Bhutan Time
    CAT: '+0200', //Central Africa Time
    CCT: '+0630', //Cocos Islands Time
    CDT: '-0500', //Central Daylight Time (North America)
    // CDT: '-0400', //Cuba Daylight Time[7]
    CEST: '+0200', //Central European Summer Time
    CET: '+0100', //Central European Time
    CHADT: '+1345', //Chatham Daylight Time
    CHAST: '+1245', //Chatham Standard Time
    CHOT: '+0800', //Choibalsan Standard Time
    CHOST: '+0900', //Choibalsan Summer Time
    CHST: '+1000', //Chamorro Standard Time
    CHUT: '+1000', //Chuuk Time
    CIST: '-0800', //Clipperton Island Standard Time
    CKT: '-1000', //Cook Island Time
    CLST: '-0300', //Chile Summer Time
    CLT: '-0400', //Chile Standard Time
    COST: '-0400', //Colombia Summer Time
    COT: '-0500', //Colombia Time
    // CST: '-0600', //Central Standard Time (North America)
    CST: '+0800', //China Standard Time
    // CST: '-0500', //Cuba Standard Time
    CT: '-0600', //-06/-05,//Central Time
    CVT: '-0100', //Cape Verde Time
    CWST: '+0845', //Central Western Standard Time (Australia) unofficial
    CXT: '+0700', //Christmas Island Time
    DAVT: '+0700', //Davis Time
    DDUT: '+1000', //Dumont d'Urville Time
    DFT: '+0100', //AIX-specific equivalent of Central European Time[NB 1]
    EASST: '-0500', //Easter Island Summer Time
    EAST: '-0600', //Easter Island Standard Time
    EAT: '+0300', //East Africa Time
    ECT: '-0400', //Eastern Caribbean Time (does not recognise DST)
    // ECT: '-0500', //Ecuador Time
    EDT: '-0400', //Eastern Daylight Time (North America)
    EEST: '+0300', //Eastern European Summer Time
    EET: '+0200', //Eastern European Time
    EGST: '+0000', //Eastern Greenland Summer Time
    EGT: '-0100', //Eastern Greenland Time
    EST: '-0500', //Eastern Standard Time (North America)
    ET: '-0500', // -05 / -04,//Eastern Time (North America)
    FET: '+0300', //Further-eastern European Time
    FJT: '+1200', //Fiji Time
    FKST: '-0300', //Falkland Islands Summer Time
    FKT: '-0400', //Falkland Islands Time
    FNT: '-0200', //Fernando de Noronha Time
    GALT: '-0600', //Galápagos Time
    GAMT: '-0900', //Gambier Islands Time
    GET: '+0400', //Georgia Standard Time
    GFT: '-0300', //French Guiana Time
    GILT: '+1200', //Gilbert Island Time
    GIT: '-0900', //Gambier Island Time
    GMT: '+0000', //Greenwich Mean Time
    GST: '-0200', //South Georgia and the South Sandwich Islands Time
    // GST: '+0400', //Gulf Standard Time
    GYT: '-0400', //Guyana Time
    HDT: '-0900', //Hawaii–Aleutian Daylight Time
    HAEC: '+0200', //Heure Avancée d'Europe Centrale French-language name for CEST
    HST: '-1000', //Hawaii–Aleutian Standard Time
    HKT: '+0800', //Hong Kong Time
    HMT: '+0500', //Heard and McDonald Islands Time
    HOVST: '+0800', //Hovd Summer Time (not used from 2017-present)
    HOVT: '+0700', //Hovd Time
    ICT: '+0700', //Indochina Time
    IDLW: '-1200', //International Day Line West time zone
    IDT: '+0300', //Israel Daylight Time
    IOT: '+0300', //Indian Ocean Time
    IRDT: '+0430', //Iran Daylight Time
    IRKT: '+0800', //Irkutsk Time
    IRST: '+0330', //Iran Standard Time
    IST: '+0530', //Indian Standard Time
    // IST: '+0100', //Irish Standard Time[8]
    // IST: '+0200', //Israel Standard Time
    JST: '+0900', //Japan Standard Time
    KALT: '+0200', //Kaliningrad Time
    KGT: '+0600', //Kyrgyzstan Time
    KOST: '+1100', //Kosrae Time
    KRAT: '+0700', //Krasnoyarsk Time
    KST: '+0900', //Korea Standard Time
    LHST: '+1030', //Lord Howe Standard Time
    // LHST: '+1100', //Lord Howe Summer Time
    LINT: '+1400', //Line Islands Time
    MAGT: '+1200', //Magadan Time
    MART: '-0930', //Marquesas Islands Time
    MAWT: '+0500', //Mawson Station Time
    MDT: '-0600', //Mountain Daylight Time (North America)
    MET: '+0100', //Middle European Time (same zone as CET)
    MEST: '+0200', //Middle European Summer Time (same zone as CEST)
    MHT: '+1200', //Marshall Islands Time
    MIST: '+1100', //Macquarie Island Station Time
    MIT: '-0930', //Marquesas Islands Time
    MMT: '+0630', //Myanmar Standard Time
    MSK: '+0300', //Moscow Time
    MST: '+0800', //Malaysia Standard Time
    // MST: '-0700', //Mountain Standard Time (North America)
    MUT: '+0400', //Mauritius Time
    MVT: '+0500', //Maldives Time
    MYT: '+0800', //Malaysia Time
    NCT: '+1100', //New Caledonia Time
    NDT: '-0230', //Newfoundland Daylight Time
    NFT: '+1100', //Norfolk Island Time
    NOVT: '+0700', //Novosibirsk Time [9]
    NPT: '+0545', //Nepal Time
    NST: '-0330', //Newfoundland Standard Time
    NT: '-0330', //Newfoundland Time
    NUT: '-1100', //Niue Time
    NZDT: '+1300', //New Zealand Daylight Time
    NZST: '+1200', //New Zealand Standard Time
    OMST: '+0600', //Omsk Time
    ORAT: '+0500', //Oral Time
    PDT: '-0700', //Pacific Daylight Time (North America)
    PET: '-0500', //Peru Time
    PETT: '+1200', //Kamchatka Time
    PGT: '+1000', //Papua New Guinea Time
    PHOT: '+1300', //Phoenix Island Time
    PHT: '+0800', //Philippine Time
    PHST: '+0800', //Philippine Standard Time
    PKT: '+0500', //Pakistan Standard Time
    PMDT: '-0200', //Saint Pierre and Miquelon Daylight Time
    PMST: '-0300', //Saint Pierre and Miquelon Standard Time
    PONT: '+1100', //Pohnpei Standard Time
    PST: '-0800', //Pacific Standard Time (North America)
    PWT: '+0900', //Palau Time[10]
    PYST: '-0300', //Paraguay Summer Time[11]
    PYT: '-0400', //Paraguay Time[12]
    RET: '+0400', //Réunion Time
    ROTT: '-0300', //Rothera Research Station Time
    SAKT: '+1100', //Sakhalin Island Time
    SAMT: '+0400', //Samara Time
    SAST: '+0200', //South African Standard Time
    SBT: '+1100', //Solomon Islands Time
    SCT: '+0400', //Seychelles Time
    SDT: '-1000', //Samoa Daylight Time
    SGT: '+0800', //Singapore Time
    SLST: '+0530', //Sri Lanka Standard Time
    SRET: '+1100', //Srednekolymsk Time
    SRT: '-0300', //Suriname Time
    SST: '-1100', //Samoa Standard Time
    // SST: '+0800', //Singapore Standard Time
    SYOT: '+0300', //Showa Station Time
    TAHT: '-1000', //Tahiti Time
    THA: '+0700', //Thailand Standard Time
    TFT: '+0500', //French Southern and Antarctic Time[13]
    TJT: '+0500', //Tajikistan Time
    TKT: '+1300', //Tokelau Time
    TLT: '+0900', //Timor Leste Time
    TMT: '+0500', //Turkmenistan Time
    TRT: '+0300', //Turkey Time
    TOT: '+1300', //Tonga Time
    TVT: '+1200', //Tuvalu Time
    ULAST: '+0900', //Ulaanbaatar Summer Time
    ULAT: '+0800', //Ulaanbaatar Standard Time
    UTC: '+0000', //Coordinated Universal Time
    UYST: '-0200', //Uruguay Summer Time
    UYT: '-0300', //Uruguay Standard Time
    UZT: '+0500', //Uzbekistan Time
    VET: '-0400', //Venezuelan Standard Time
    VLAT: '+1000', //Vladivostok Time
    VOLT: '+0400', //Volgograd Time
    VOST: '+0600', //Vostok Station Time
    VUT: '+1100', //Vanuatu Time
    WAKT: '+1200', //Wake Island Time
    WAST: '+0200', //West Africa Summer Time
    WAT: '+0100', //West Africa Time
    WEST: '+0100', //Western European Summer Time
    WET: '+0000', //Western European Time
    WIB: '+0700', //Western Indonesian Time
    WIT: '+0900', //Eastern Indonesian Time
    WITA: '+0800', //Central Indonesia Time
    WGST: '-0200', //West Greenland Summer Time[14]
    WGT: '-0300', //West Greenland Time[15]
    WST: '+0800', //Western Standard Time
    YAKT: '+0900', //Yakutsk Time
    YEKT: '+0500', //Yekaterinburg Time
  };

  export const EMAIL_HEADER_DATE_TZ_REGEX = /([0-9]{2}:[0-9]{2}:[0-9]{2}) (\w*)$/;