import dayjs from 'dayjs';
import { chevronForward, notificationsOutline, playForwardOutline } from 'ionicons/icons';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
  displayAttendeeName,
  eventByAttachedSelector,
  ImDocument,
  notificationByAttachedSelector,
  SearchResult,
  useAppDispatch,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Badge, Box, makeStyles, Typography } from '@material-ui/core';

import Highlighter from '../Search/Highlighter';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import { NotificationChip } from '../Common/NotificationChip';
import { EventChip } from '../Common/EventChip';
import { AccountTypeIcon } from '@focus-front/ui';

const useClasses = makeStyles((theme) => ({
  root: (props: { isOpen: boolean }) => ({
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: props.isOpen && theme.palette.background.default,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '& .Avatar': {
      flexShrink: 0,
    },
    borderLeft: props.isOpen && `solid 4px ${theme.palette.secondary.main}`,
  }),
  right: {
    paddingLeft: 20,
    flex: 1,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  body: {
    flex: 1,
  },
  date: {
    fontSize: '0.75rem',
    opacity: 0.5,
  },
  arrow: {
    fontSize: 24,
    color: theme.palette.grey[700],
  },
}));

interface Props {
  onClick: () => void;
  im: SearchResult<ImDocument>;
  index: number;
}

export function ImSearchResult({ onClick, im, index }: Props) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const notification = useSelector(notificationByAttachedSelector(im.item.thread_id));
  const event = useSelector(eventByAttachedSelector(im.item.thread_id));

  const c = useClasses({
    isOpen: (notification && location.pathname.includes(notification.id)) || (event && location.pathname.includes(event.id)),
  });

  return (
    <div className={c.root} onClick={onClick}>
      <Typography variant="subtitle1">
        #
        {im.item.thread.provider_name ||
          im.item.thread.attendees
            .filter((att) => att.display_name !== 'YOU')
            .map((att) => att.display_name)
            .join(', ')}
      </Typography>
      <Box display="flex">
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={!!im.item.account_type && <AccountTypeIcon size={30} account_type={im.item.account_type} />}
        >
          <AttendeeAvatar
            display_name={displayAttendeeName(im.item.from_attendee)}
            // avatar_url={attendee.profile_picture}
            size="small"
          />
        </Badge>
        <div className={c.right}>
          <div className={c.top}>
            <Box display="flex" alignItems="center">
              <Typography variant="h4" style={{ marginRight: 10 }}>
                {im.matches.find((match) => match.key === 'from_attendee.display_name') ? (
                  <Highlighter
                    text={im.item.from_attendee.display_name}
                    ranges={im.matches.find((match) => match.key === 'from_attendee.display_name').indices}
                  />
                ) : (
                  im.item.from_attendee.display_name
                )}
              </Typography>
              {!!notification && <NotificationChip id={notification.id} />}
              {!!event && <EventChip id={event.id} />}
            </Box>
            <div className={c.date}>{dayjs(im.item.date).format('L')}</div>
          </div>
          <Box display="flex" alignItems="flex-end" minHeight="24px">
            <Typography variant="subtitle2" className={c.body}>
              {im.matches.find((match) => match.key === 'body') ? (
                <Highlighter
                  text={im.item.body ?? ''}
                  ranges={im.matches.find((match) => match.key === 'body').indices}
                  trimSize={12}
                />
              ) : im.item.body?.length > 150 ? (
                im.item.body?.slice(0, 150) + '...'
              ) : (
                im.item.body
              )}
            </Typography>
            <IonIcon className={c.arrow} icon={chevronForward} />
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default memo(ImSearchResult, (prev, next) => prev.im.item.id === next.im.item.id);
