import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';

const HeaderTabs = withStyles((theme) => ({
  indicator: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 4,
  },
  root: {
    flex: 1,
  },
}))(Tabs);

export default HeaderTabs;
