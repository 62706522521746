import { isObject } from '../../../Common/utils/index';
import { GoogleWebAuthenticationProvider } from '../../../Common/infra/services/google/GoogleWebAuthenticationProvider';
import { GoogleOAuthReturn, OAuthService } from './OAuthService';
import { GoogleCalendarScopes } from '../../../Calendar/infra/services/GcalService';
import { MissingOAuthScopesError } from '../../../Common/infra/services/google/GoogleAuthenticationProvider';

export class GoogleCalendarOAuthWebService implements OAuthService<GoogleOAuthReturn> {
  constructor(private readonly config: { host: string; password: string; username: string }) {}

  /**
   * @todo Consider merging with GoogleAuthenticationProvider family ?
   */
  async requestAuth(code?: string): Promise<GoogleOAuthReturn> {
    if (!code) {
      throw new Error(
        'As defined, the OAuthServiceService interface requires code to be optional. ' +
          'Changing the interface requires changes in multiple files. ' +
          'In the meantime, we throw if code is undefined.'
      );
    }
    const authProvider = new GoogleWebAuthenticationProvider(this.config);
    const result = await authProvider.authenticateWithCode(code);

    // console.log('GoogleCalendarOAuthWebService.requestAuth result', result);

    const parsed = JSON.parse(result.access_token);
    if (!isObject(parsed)) {
      throw new Error("GoogleCalendar OAuth for Web result doesn't contain a well-formed Access token.");
    }

    /** Were all required scopes granted ? */
    if (typeof parsed.scope !== 'string') {
      console.info('typeof parsed.scope !== string');
      throw new MissingOAuthScopesError(GoogleCalendarScopes);
    }
    const grandtedScopes = parsed.scope.split(' ');
    const missingScopes = GoogleCalendarScopes.filter((scope) => !grandtedScopes.includes(scope));
    if (missingScopes.length !== 0) {
      throw new MissingOAuthScopesError(missingScopes);
    }

    return {
      at: result.access_token,
      rt: result.refresh_token,
      identifier: result.email,
    };
  }
}
