/**
 * Return a new string with given basic multilingual plane char removed from both
 * ends of given string.
 *
 * @note There is no 'char' type in typescript. If a string longer than 1 char or
 *       a character from outside the basic multilingual plan is given as the char
 *       to trim, trimChar will accept it, silently fail to remove anything and
 *       return a copy of the original string.
 *
 * @note If given char is a High-surrogate or Low-surrogate code unit, all bets
 *       are off, expect weird results !
 *
 * @see https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
 */
export function trimBMPChar(str: string, char: string): string {
  let start = 0;
  let end = str.length;

  while (start < end && str[start] === char) {
    ++start;
  }

  while (end > start && str[end - 1] === char) {
    --end;
  }
  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

/**
 * Capitalize functions made easy
 */
export function capitalize(s: string): string {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function capitalizeOnly(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}
