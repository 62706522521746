import {
  AggregateRoot,
  createProjectionConfig,
  createUuid,
  DomainEvent,
  Uuid,
  CommandResult,
  CommandAccepted,
} from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import {
  AccountCreated,
  AccountCredentialsAdded,
  AccountCredentialsUpdated,
  AccountDeleted,
  AccountEdited,
  AccountFetched,
  AccountSignatureSelected,
  AccountSynced,
  AccountProgressSet,
  // AccountFullFetchCompleted,
  AccountFullFetchStart,
} from './events/Account.events';
import {
  AccountType,
  FetchedAccountDTO,
  NewAccountDTO,
  TaggedUpdateAccountDTO,
  MailSupportedAccountTypes,
} from './projections/ViewAccount';
import { NewEncryptedCredentialsDTO } from './projections/ViewCredentials';

/**
 * DecisionProjection initial state.
 */
const initialState = {
  deleted: false,
  credentials: 'NONE' as 'NONE' | 'VALID' | 'INVALID',
  type: null as AccountType | null,
  sync_token: null as string | null,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  ACCOUNT_CREATED: (event) => ({ id: event.aggregateId, type: event.account.type }),
  ACCOUNT_DELETED: () => ({ deleted: true }),
  ACCOUNT_CREDENTIALS_ADDED: () => ({
    credentials: 'VALID',
  }),
  ACCOUNT_CREDENTIALS_UPDATED: () => ({
    credentials: 'VALID',
  }),
  ACCOUNT_SYNCED: ({ sync_token }) => ({
    sync_token,
  }),
  /** @note No special handling needed for :. */
  //   ACCOUNT_EDITED: (event, state) => ({}),
  //   ACCOUNT_SIGNATURE_SELECTED: (event, state) => {
  //   }
});

/**
 * Aggregate.
 */
export class Account extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   *
   * @todo Check invariants here !
   */
  static create(content: NewAccountDTO): CommandAccepted<DomainEvent, Account> {
    return Account.accept(new AccountCreated(createUuid(), content));
  }

  /**
   * Command.
   */
  delete(): CommandResult<'DELETED'> {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new AccountDeleted(this.id, this.version)).accept();
  }

  /**
   * Command.
   *
   * @todo Figure out if there is a way to prevent messing with the account
   *       sub-type that doesn't require its type tag in UpdateAccountDTO.
   */
  edit(values: TaggedUpdateAccountDTO): CommandResult<'TYPE MISMATCH' | 'DELETED'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new AccountEdited(this.id, this.version, values)).accept();
  }

  /**
   * Command.
   */
  markFetched(values: FetchedAccountDTO): CommandResult<'TYPE MISMATCH' | 'DELETED' | 'NOT A NEW TOKEN'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    switch (values.type) {
      case 'LINKEDIN':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'MESSENGER':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'TIKTOK':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'INSTAGRAM':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'TWITTER':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'WHATSAPP':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'MOBILE':
        return this.apply(new AccountFetched(this.id, this.version, values.last_fetched_at)).accept();
      case 'GOOGLE_CALENDAR':
        if (this.projection.state.sync_token === values.sync_token) {
          return this.reject('NOT A NEW TOKEN');
        }
        return this.apply(new AccountSynced(this.id, this.version, values.sync_token)).accept();
      //   default:
      //     return this.reject('FETCH NOT SUPPORTED');
    }
  }

  /**
   * Command.
   */
  setProgress(mailbox: string, offset: number, done: boolean): CommandResult<'DELETED' | 'TYPE MISMATCH'> {
    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    if (this.projection.state.type && MailSupportedAccountTypes.includes(this.projection.state.type)) {
      return this.apply(new AccountProgressSet(this.id, this.version, mailbox, offset, done)).accept();
    } else {
      return this.reject('TYPE MISMATCH');
    }
  }

  /**
   * Command.
   */
  startFullFetch(mailboxes: { mailbox: string; total: number }[]): CommandResult<'DELETED' | 'TYPE MISMATCH'> {
    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    if (this.projection.state.type && MailSupportedAccountTypes.includes(this.projection.state.type)) {
      return this.apply(new AccountFullFetchStart(this.id, this.version, mailboxes)).accept();
    } else {
      return this.reject('TYPE MISMATCH');
    }
  }

  // /**
  //  * Command.
  //  */
  // completeFullFetch() {
  //   if (this.projection.state.deleted) {
  //     return this.reject('DELETED');
  //   }

  //   if (this.projection.state.type && MailSupportedAccountTypes.includes(this.projection.state.type)) {
  //     return this.apply(new AccountFullFetchCompleted(this.id, this.version)).accept();
  //   } else {
  //     return this.reject('TYPE MISMATCH');
  //   }
  // }

  /**
   * Command.
   */
  selectSignature(id: Uuid | undefined): CommandResult<'DELETED'> {
    return this.projection.state.deleted
      ? this.reject('DELETED')
      : this.apply(new AccountSignatureSelected(this.id, this.version, id)).accept();
  }

  /**
   * Command.
   *
   * @todo Fuse addCredentials/updateCredentials if no business rules or
   *       projection need to make the distinction.
   */
  addCredentials(content: NewEncryptedCredentialsDTO): CommandResult {
    return this.apply(new AccountCredentialsAdded(this.id, this.version, content)).accept();
  }

  /**
   * Command.
   */
  updateCredentials(content: NewEncryptedCredentialsDTO): CommandResult {
    return this.apply(new AccountCredentialsUpdated(this.id, this.version, content)).accept();
  }
}
