import cn from 'classnames';
import { arrowBackOutline, chevronDownOutline, chevronUpOutline, notificationsOutline } from 'ionicons/icons';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  eventCompleteDone,
  getNotifications,
  nextAndPreviousNotificationSelector,
  notificationSelector,
  snoozeNotification,
  useAppDispatch,
} from '@focus-front/core';
import { ActionId, NotificationIcon } from '@focus-front/ui';
import { IonIcon } from '@ionic/react';
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';

import ActionsTabBar from '../ActionBar/ActionsTabBar';
import MailView from '../Mail/MailView';
import CallView from '../Call/CallView';
import ImThreadView from '../Im/ImThreadView';
import MailDraftView from '../Mail/MailDraftView';
import EventView from '../Event/EventView';
import SearchEventView from '../Event/SearchEventView';

import SnoozeSvg from '../../assets/snooze.svg';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigationBtn: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
      marginLeft: 10,
    },
  },
  backIcon: {
    marginLeft: '0!important',
    marginRight: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: 10,
      marginLeft: 10,
    },
  },
  content: {
    flex: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  actionBar: {
    // height: 100,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    paddingBottom: 20,
  },
  modal: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    height: '30%',
  },
  modalExpanded: {
    height: '100%',
  },
  snoozed: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity .2s ease',
    backgroundColor: theme.palette.success.main,
    width: '100%',
    height: '100%',
    borderRadius: 5,
    color: theme.palette.success.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  show: {
    opacity: 1,
  },
}));

export default function NotificationPage() {
  const { id }: { id: string } = useParams();

  const { t } = useTranslation();
  const classes = useClasses();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { next_id, previous_id } = useSelector(nextAndPreviousNotificationSelector(id));

  const notification = useSelector(notificationSelector(id));
  const [snoozed, setSnoozed] = useState(false);

  // Reload notifications on notification change to remove done notifications
  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch, id]);

  const [reload, setReload] = useState<number>(0);
  const handleReload = () => {
    console.log('reload');
    setReload(reload + 1);
  };

  const snooze = () => {
    dispatch(snoozeNotification(notification));
    setSnoozed(true);
    setTimeout(() => setSnoozed(false), 1500);
    onDone('snooze');
  };

  const onDone = (action_id: ActionId | 'snooze') => {
    /**
     * In a case where the notification is created from an event, complete the event if the notification is done.
     * (The event projection is already updated within the action useCase (for ex mailReply))
     * However, is the action is 'replan', that mean the event still have to be completed somehow, so we don't complete it here.
     * If the action is reply or transfer, user must use the done action to complete
     */
    if (notification.metadata?.origin_event && action_id !== 'replan' && action_id !== 'reply' && action_id !== 'transfer') {
      dispatch(eventCompleteDone(notification.metadata.origin_event.id));
    }

    // When an action is done, redirect to the next notification page or the inbox if no more notification to show
    if (action_id !== 'reply' && action_id !== 'transfer') history.push(next_id ? `/inbox/${next_id}` : '/inbox');
  };

  if (!notification) return <Typography>Loading</Typography>;

  const attached_entity = notification.metadata?.attached_entity || null;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.navigation}>
          <Button component={Link} to="/inbox" variant="text" className={classes.navigationBtn}>
            <IonIcon icon={arrowBackOutline} className={classes.backIcon}></IonIcon>
            {t('notification.navigation.back')}
          </Button>

          <div>
            <Tooltip title={t('notification.action.snoozeTooltip')} enterDelay={800}>
              <Button variant="text" className={classes.navigationBtn} onClick={snooze}>
                {t('notification.action.snooze')}
                <IonIcon icon={SnoozeSvg}></IonIcon>
                <div className={cn([classes.snoozed, snoozed && classes.show])}>Snoozed</div>
              </Button>
            </Tooltip>

            <Tooltip title={t('notification.navigation.previousTooltip')} enterDelay={800}>
              <Button
                component={Link}
                to={`/inbox/${previous_id}`}
                variant="text"
                className={classes.navigationBtn}
                disabled={!previous_id}
              >
                {t('notification.navigation.previous')}
                <IonIcon icon={chevronUpOutline}></IonIcon>
              </Button>
            </Tooltip>

            <Tooltip title={t('notification.navigation.nextTooltip')} enterDelay={800}>
              <Button
                component={Link}
                to={`/inbox/${next_id}`}
                variant="text"
                className={classes.navigationBtn}
                disabled={!next_id}
              >
                {t('notification.navigation.next')}
                <IonIcon icon={chevronDownOutline}></IonIcon>
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.title}>
          <NotificationIcon notification={notification} size={30}></NotificationIcon>
          <Typography variant="h2">{t('notification.type.' + notification.type.toLowerCase())}</Typography>
        </div>
      </div>

      {!!attached_entity && (
        <>
          {notification.type === 'EVENT_MISSED' && <EventView event_id={notification.metadata.origin_event.id} headerMode />}
          <div className={classes.content}>
            {attached_entity.type === 'MAIL' && <MailView mail_id={attached_entity.id} />}
            {attached_entity.type === 'MAIL_DRAFT' && <MailDraftView mail_id={attached_entity.id} reload={reload} />}
            {attached_entity.type === 'CALL' && <CallView call_id={attached_entity.id} />}
            {attached_entity.type === 'IM_THREAD' && <ImThreadView thread_id={attached_entity.id} />}
          </div>
          <ActionsTabBar
            context="NOTIFICATION"
            entity_type={attached_entity.type}
            entity_id={attached_entity.id}
            notification={notification}
            onDone={onDone}
            onMustReload={handleReload}
          />
        </>
      )}
      {!attached_entity && !!notification.metadata?.origin_event && (
        <>
          <div className={classes.content}>
            <EventView event_id={notification.metadata.origin_event.id} />
          </div>
          <ActionsTabBar
            context="NOTIFICATION"
            entity_type="EVENT"
            entity_id={notification.metadata.origin_event.id}
            notification={notification}
            onDone={onDone}
          />
        </>
      )}
    </>
  );
}
