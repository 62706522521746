import React, { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AOL_IMAP_HOST,
  detectConfig,
  GMX_IMAP_HOST,
  ICLOUD_IMAP_HOST,
  useAppDispatch,
  YAHOO_IMAP_HOST,
  ZOHO_IMAP_HOST,
} from '@focus-front/core';
import { Box, Typography } from '@material-ui/core';

import MailLoginForm from './MailLoginForm';
import { BackButton, NextButton } from './NavButton';
import SourceSelection from './SourceSelection';
import { MailAccountConfigForm, WizardContext, WizardStep } from './WizardContext';

export default function AccountConfiguration() {
  const { t } = useTranslation();
  const {
    accountType,
    goBack,
    goNext,
    setSources,
    setLoading,
    setAlias,
    forms,
    setForms,
    setSteps,
    steps,
    setAccountType,
    sources,
  } = useContext(WizardContext);

  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: forms.ACCOUNT_CONFIG,
    mode: 'onChange',
  });

  const noSourceSelected = useMemo(() => {
    return !Object.keys(sources).find((key) => sources[key]);
  }, [sources]);

  const onSubmit = async ({ email }: MailAccountConfigForm) => {
    setLoading(true);
    // setAlias(alias);
    if (accountType === 'MAIL') {
      const { error, result } = await dispatch(detectConfig(email));
      if (error) {
        alert(error);
        setLoading(false);
      } else {
        setAlias(email);
        if (result.imap_host.includes('gmail')) {
          setAccountType('GOOGLE');
          setForms({
            ...forms,
            GOOGLE_MAIL: {
              email,
              password: '',
            },
          });
          setLoading(false);
          return;
        } else if (result.imap_host.includes('outlook')) {
          setAccountType('OUTLOOK');
          setLoading(false);
          return;
        } else if (result.imap_host === ICLOUD_IMAP_HOST) {
          setAccountType('ICLOUD');
          setForms({
            ...forms,
            ICLOUD_MAIL: {
              email,
              password: '',
            },
          });
          setLoading(false);
          return;
        } else {
          setForms({
            ACCOUNT_CONFIG: { email },
            IMAP_MAIL_1: {
              ...result,
              imap_user: email,
              smtp_user: email,
              imap_password: '',
              smtp_password: '',
            },
          });
          if (result.imap_host === YAHOO_IMAP_HOST) {
            insertStep('IMAP_PASSWORD_YAHOO');
          } else if (result.imap_host === AOL_IMAP_HOST) {
            insertStep('IMAP_PASSWORD_AOL');
          } else if (result.imap_host === ZOHO_IMAP_HOST) {
            insertStep('IMAP_PASSWORD_ZOHO');
          } else if (result.imap_host === GMX_IMAP_HOST) {
            insertStep('IMAP_PASSWORD_GMX');
          } else {
            insertStep('IMAP_PASSWORD');
          }
        }
      }
    }
    goNext();
  };

  function insertStep(step: WizardStep) {
    const index = steps.findIndex((step) => step === 'IMAP_MAIL_1');
    steps.splice(index, 0, step);
    setSteps(steps);
  }

  useEffect(() => {
    if (accountType === 'MAIL')
      setSources({
        CALENDAR: false,
        CALLS: false,
        CONTACTS: false,
        IMS: false,
        MAILS: true,
      });
  }, []);

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">
        {t('wizard.accountConfig.title')} {t('accounts.' + accountType.toLowerCase())}
      </Typography>
      <Typography variant="subtitle2">{t('wizard.accountConfig.subtitle')}</Typography>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <Box pt={4} pb={4}>
          {/* <TextField
            name="alias"
            inputRef={form.register()}
            label={t('wizard.accountConfig.alias')}
            placeholder={t('wizard.accountConfig.aliasPlaceholder')}
            fullWidth
            variant="standard"
            autoFocus
            defaultValue={alias}
            InputLabelProps={{ shrink: true }}
          /> */}
        </Box>

        {accountType !== 'MAIL' && <SourceSelection account_type={accountType} onChange={setSources} />}
        {accountType === 'MAIL' && <MailLoginForm useForm={form} />}
        <NextButton type="submit" disabled={noSourceSelected || !form.formState.isValid} />
      </form>
    </div>
  );
}
