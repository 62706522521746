import {
  workEventSelector,
  mockUuid,
  nextEventSelector,
  ViewCalendarEvent,
  openViewEvent,
  useAppDispatch,
  calendarsFormSelector,
  getRecurringRoot,
  RootState,
  previousEventSelector,
  //   makePreviousEventSelector,
} from '@focus-front/core';
import { CircularProgress } from '@material-ui/core';
import { RecurringCalendarEventRoot } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import EventPage from './EventPage';

/**
 * This component load the work event and redirect to the view mode if not found
 */
export default function WorkEventContainer() {
  const { id }: { id: string } = useParams();
  const dispatch = useAppDispatch();
  const [recurringEventRoot, setRecurringEventRoot] = useState<RecurringCalendarEventRoot>(null);
  const next_id = useSelector(nextEventSelector(id));

  //   const previousEventSelector = useMemo(makePreviousEventSelector, []);
  //   const previous_id = useSelector((state : RootState) => previousEventSelector(state, id));
  const previous_id = useSelector(previousEventSelector(id));
  const work_event = useSelector(workEventSelector(mockUuid(id)));

  useEffect(() => {
    if (work_event?.kind === 'INSTANCE') {
      (async () => {
        const recurringEventRootResult = await dispatch(getRecurringRoot(work_event.recurring_event_id));
        setRecurringEventRoot(recurringEventRootResult);
      })();
    }
  }, [work_event]);

  if (!work_event) return <Redirect to={`/event/${id}`} />;

  return (
    <EventPage
      status="WORK"
      event={work_event}
      previous_id={previous_id}
      next_id={next_id}
      recurringEventRoot={recurringEventRoot}
    />
  );
}
