import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { workEventsSelector } from '../../Calendar';

import Container from '../../_container/interfaces/Container';
import { EnvironmentState } from '../../_container/interfaces/Environment';
import { AppDispatch, AppGetState } from '../../_container/web/WebContainer';
import { Uuid } from '../domain/Uuid';
import { CalendarEventInstanceId } from '../../Calendar/domain';
import { reconnectAccountSuccess, ViewAccount } from '../../Account';
import { AutomaticHelpId, updateSession } from '../../User';
import { Capacitor } from '@capacitor/core';

type SideTab = 'calendar' | 'search' | 'activity' | 'context';

export type Tab =
  | 'user'
  | 'connected_accounts'
  | 'advanced'
  | 'mails/signatures'
  | 'message_models'
  | 'message_models/new'
  | 'calls'
  | 'ims'
  | 'calendar'
  | 'subscription'
  | 'security';

type SettingsDialogOptions = {
  messageModelId?: Uuid | null;
};

interface ConfigurationState {
  logInOpen: boolean;
  signUpOpen: boolean;
  wizardOpen: boolean;
  subUpgradeOpen: boolean;
  smsPermOpen: boolean;
  smsPermission: boolean | null;
  addMobileAccountOpen: boolean;
  workOn: Uuid | CalendarEventInstanceId | null;
  workAction: string | null;
  showWorkMode: boolean;
  showEventView: Uuid | CalendarEventInstanceId | null;
  refresher: boolean;
  showDebugMode: boolean;
  sideTab: SideTab;
  showAutomaticHelp: { [key in AutomaticHelpId]?: true };
  showReconnectAccount: Pick<ViewAccount, 'type' | 'id'> | null;
  settingsDialog: { open: boolean; tab: Tab; options: SettingsDialogOptions };
}

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {
    logInOpen: false,
    signUpOpen: false,
    wizardOpen: false,
    subUpgradeOpen: false,
    smsPermOpen: false,
    storagePermission: true,
    smsPermission: null,
    addMobileAccountOpen: false,
    workOn: null,
    workAction: null,
    showWorkMode: false,
    refresher: true,
    showEventView: null,
    showDebugMode: localStorage.getItem('showDebugMode') === 'true',
    sideTab: localStorage.getItem('__UNIPILE_SIDE') || 'calendar',
    showAutomaticHelp: {},
    showReconnectAccount: null,
    settingsDialog: { open: false, tab: 'connected_accounts', options: {} },
    // darkMode: false,
  } as ConfigurationState,
  reducers: {
    openLogIn: (state) => {
      state.signUpOpen = false;
      state.logInOpen = true;
    },
    openSignUp: (state) => {
      state.logInOpen = false;
      state.signUpOpen = true;
    },
    openWizard: (state) => {
      state.wizardOpen = true;
    },
    closeWizard: (state) => {
      state.wizardOpen = false;
    },
    openSubUpgrade: (state) => {
      state.subUpgradeOpen = true;
    },
    closeSubUpgrade: (state) => {
      state.subUpgradeOpen = false;
    },
    openSmsPerm: (state) => {
      state.smsPermOpen = true;
    },
    closeLogIn: (state) => {
      state.logInOpen = false;
    },
    closeSignUp: (state) => {
      state.signUpOpen = false;
    },
    closeSmsPerm: (state) => {
      state.smsPermOpen = false;
    },
    setSmsPermissions: (state, { payload }: PayloadAction<boolean>) => {
      state.smsPermission = payload;
      if (payload === true) state.smsPermOpen = false;
    },
    showMobileAddAccount: (state, { payload }: PayloadAction<boolean>) => {
      state.addMobileAccountOpen = payload;
    },
    showWorkMode: (state, { payload }: PayloadAction<Uuid | CalendarEventInstanceId | null>) => {
      state.showWorkMode = true;
      state.workOn = payload;
    },
    showWorkAction: (state, { payload }: PayloadAction<string>) => {
      state.workAction = payload;
    },
    closeWorkMode: (state) => {
      state.workOn = null;
      state.showWorkMode = false;
    },
    setRefresher: (state, { payload }: PayloadAction<boolean>) => {
      state.refresher = payload;
    },
    setDebugMode: (state, { payload }: PayloadAction<boolean>) => {
      state.showDebugMode = payload;
    },
    showEventView: (state, { payload }: PayloadAction<Uuid | CalendarEventInstanceId | null>) => {
      state.showEventView = payload;
    },
    closeEventView: (state) => {
      state.showEventView = null;
    },
    setSideTab: (state, { payload }: PayloadAction<SideTab>) => {
      localStorage.setItem('__UNIPILE_SIDE', payload);
      state.sideTab = payload;
    },
    showAutomaticHelp: (state, { payload }: PayloadAction<AutomaticHelpId>) => {
      state.showAutomaticHelp[payload] = true;
    },
    hideAutomaticHelp: (state, { payload }: PayloadAction<AutomaticHelpId>) => {
      delete state.showAutomaticHelp[payload];
    },
    setShowReconnectAccount: (state, { payload }: PayloadAction<Pick<ViewAccount, 'type' | 'id'> | null>) => {
      state.showReconnectAccount = payload;
    },
    openSettingsDialog: (state, { payload }: PayloadAction<Tab | null>) => {
      state.settingsDialog.open = true;
      state.settingsDialog.tab = payload || 'connected_accounts';
    },
    closeSettingsDialog: (state) => {
      state.settingsDialog.open = false;
    },
    setSettingsDialogTab: (state, { payload }: PayloadAction<Tab>) => {
      state.settingsDialog.tab = payload;
    },
    openMessageModelSettings: (state, { payload }: PayloadAction<Uuid>) => {
      state.settingsDialog.open = true;
      state.settingsDialog.tab = 'message_models';
      state.settingsDialog.options.messageModelId = payload;
    },
    resetMessageModelSettings: (state) => {
      state.settingsDialog.options.messageModelId = null;
    },
    setMessageModelId: (state, { payload }: PayloadAction<Uuid | null>) => {
      state.settingsDialog.options.messageModelId = payload;
    },
  },
});

export const {
  openLogIn,
  openSignUp,
  openSmsPerm,
  closeLogIn,
  closeSignUp,
  closeSmsPerm,
  openSubUpgrade,
  closeSubUpgrade,
  setSmsPermissions,
  showMobileAddAccount,
  showWorkMode,
  closeWorkMode,
  showWorkAction,
  setRefresher,
  showEventView,
  closeEventView,
  setDebugMode,
  openWizard,
  closeWizard,
  setSideTab,
  showAutomaticHelp,
  hideAutomaticHelp,
  setShowReconnectAccount,
  openSettingsDialog,
  closeSettingsDialog,
  setSettingsDialogTab,
  openMessageModelSettings,
  resetMessageModelSettings,
  setMessageModelId,
} = configurationSlice.actions;

export const initPermissions =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { permissions }: Container): Promise<void> => {
    if (!permissions || Capacitor.getPlatform() === 'ios') return;
    const call_log = await permissions.hasPermissions('CALL_LOG');
    const sim = await permissions.hasPermissions('SIM');
    const sms = await permissions.hasPermissions('SMS');
    const contacts = await permissions.hasPermissions('CONTACTS');
    dispatch(setSmsPermissions(call_log && sim && sms && contacts));
  };

export const requestSmsPermissions =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { permissions }: Container): Promise<void> => {
    if (!permissions) return;
    try {
      await permissions.requestPermissions('SIM');
      await permissions.requestPermissions('CALL_LOG');
      await permissions.requestPermissions('SMS');
      await permissions.requestPermissions('CONTACTS');
      dispatch(setSmsPermissions(true));

      /**
       * For now, use reconnectAccountSuccess thunk to bring back sources to IDLE
       * Might need a better way to manage permissions if other type of account/source need some
       */
      const { accounts } = getState().accounts;
      accounts.forEach((acc) => {
        if (acc.type === 'MOBILE') dispatch(reconnectAccountSuccess(acc.id));
      });
    } catch {
      dispatch(setSmsPermissions(false));
    }
  };

/**
 * Hide an automatic help window and update the user params to do not show again
 * @param id window id
 * @returns
 */
export const discardAutomaticHelp =
  (id: AutomaticHelpId) =>
  async (dispatch: AppDispatch, getState: AppGetState, { user }: Container): Promise<void> => {
    dispatch(hideAutomaticHelp(id));

    const updated_session = await user.discardAutomaticHelp(id);
    dispatch(updateSession(updated_session));
  };

export const configurationSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration;
};

export const workConfigurationSelector = (state: { configuration: ConfigurationState }) => {
  const { showWorkMode, workOn } = state.configuration;
  return {
    showWorkMode,
    workOn,
  };
};

export const smsPermissionsSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.smsPermission;
};

export const wizardSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.wizardOpen;
};

export const openWorkMode =
  (event_id?: Uuid | CalendarEventInstanceId, action_id?: string) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    const work_events = workEventsSelector(getState());
    if (event_id) {
      if (work_events.findIndex((e) => e.id === event_id) >= 0) dispatch(showWorkMode(event_id));
      else dispatch(showEventView(event_id));
    } else {
      if (work_events.length) dispatch(showWorkMode(work_events[0].id));
      else dispatch(showWorkMode(null));
    }
    if (action_id) setTimeout(() => dispatch(showWorkAction(action_id)), 700);
  };

export const envSelector = (state: { env: EnvironmentState }) => {
  return state.env;
};

export const sideTabSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.sideTab;
};

export const automaticHelpSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.showAutomaticHelp;
};

export const reconnectAccountSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.showReconnectAccount;
};

export const settingsDialogSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.settingsDialog;
};

export const messageModelIdSelector = (state: { configuration: ConfigurationState }) => {
  return state.configuration.settingsDialog.options.messageModelId;
};

export default configurationSlice.reducer;
