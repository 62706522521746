/**
 * 
 */
export class InvalidCredentialsLoginError extends Error {
  constructor() {
    super('Invalid credentials');
    this.name = 'InvalidCredentialsLoginError';
  }
}

/**
 *
 */
 export class InvalidOrExpiredRefreshTokenError extends Error {
    constructor() {
      super(`Refresh token is invalid or expired.`);
      this.name = 'InvalidOrExpiredRefreshTokenError';
    }
  }

export interface AuthenticatorService {
  /**
   * Login a user and store a Session
   */
  login: (username: string, password: string) => Promise<void>;

  /**
   * Delete the Session
   */
  logout: () => Promise<void>;

  /**
   * Get the JWT of the Session (to access the API for example)
   */
  getJwt: () => Promise<string | null>;
}



  