import { Dialog, Typography, Button, Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import img from '../../../assets/help_mail_reply.png';

export default function AutomaticHelpMailReply({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <Dialog onClose={onClose} open={open}>
      <Box p={2} style={{ backgroundColor: 'white' }}>
        <Box display="flex" flexDirection="column" alignItems="center" mt={3} pb={2}>
          <img src={img} alt="tutorial" height="390" style={{ alignItems: 'flex-end', marginLeft: -16, marginRight: 'auto' }} />
          <Box pl={5} pr={5} mb={3} mt={2}>
            <Typography variant="h2" align="center" style={{ lineHeight: 1.3 }}>
              {t('onboarding.help.mail_reply.message')}
            </Typography>
          </Box>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
