import { debounce } from '@material-ui/core';
import React, { memo, useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { scrollResults, selectSearchResult, eventResultsSelector, useAppDispatch, Uuid } from '@focus-front/core';
import { EventSearchResult } from '../Event/EventSearchResult';
import NoResults from './NoResults';
import { useSelector } from 'react-redux';
import { useClasses } from './SearchTabProps';
import EventSearchResultFake from '../Event/EventSearchResultFake';

export function EventSearchTab() {
  const c = useClasses();
  const { results, scroll, status } = useSelector(eventResultsSelector);
  const dispatch = useAppDispatch();

  const handleScroll = () => {
    const firstRendered = document.querySelector('#search-results > div > div > div');
    if (firstRendered) dispatch(scrollResults({ tab: 'event', scroll: Number(firstRendered.getAttribute('data-index')) }));
  };

  if (status === 'LOADING')
    return (
      <div className={c.wrapper}>
        <EventSearchResultFake />
        <EventSearchResultFake />
        <EventSearchResultFake />
        <EventSearchResultFake />
      </div>
    );
  if (!results.length) return <NoResults />;

  return (
    <div className={c.wrapper}>
      <Virtuoso
        style={{ height: '100%' }}
        totalCount={results.length}
        initialTopMostItemIndex={scroll}
        onScroll={debounce(handleScroll, 100)}
        id="search-results"
        itemContent={(index) => (
          <EventSearchResult
            index={index}
            event={results[index]}
            onClick={() => dispatch(selectSearchResult({ id: results[index].item.id as Uuid, type: 'event' }))}
          />
        )}
      />
    </div>
  );
}

export default memo(EventSearchTab, (prev, next) => true);
