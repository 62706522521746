import dayjs from 'dayjs';

import localizedFormat from 'dayjs/plugin/localizedFormat';

import { MailServicesMap } from '../../_container/interfaces/Container';
import { ViewAccountRepo } from '../../Account/domain/projections/ViewAccountRepo';
import {
  LoggerService,
  Publisher,
  Result,
  StorageService,
  toUTCDateTimeMs,
  UTCDateTimeMs,
  Uuid,
  FileManagerService,
  LocalFile,
  createUuid,
} from '../../Common';
import { accountIdentifier } from '../../Common/utils';
import { ViewCalendar } from '../../Calendar';
import {
  MailDraftAttendee,
  MailDraftType,
  NewMailDraftDTO,
  UpdateMailDraftDTO,
  ViewMailDraft,
  ViewMail,
  PartialViewMail,
  ViewMailDraftRepo,
  MailReferenceRepo,
  createViewMailDraft,
} from '../domain';
import { MailRepo } from '../infra/repository/MailRepo';
import { MailUseCase } from './MailUseCase';
import { SearchService } from '../../Search';
import {
  isMailSupportedAccount,
  ViewAccount,
  ViewSignatureRepo,
  AccountSourceStatus,
  MailSupportedAccounts,
  isMailAccount,
} from '../../Account';
import { CalendarEvent, CalendarUseCase, DEFAULT_EVENT_INTERVAL, ViewCalendarEvent } from '../../Calendar';
import { Taggable, ViewTagRepo, ViewTagRelationRepo } from '../../Tag';
import { ViewCalendarEventRepo } from '../../Calendar/domain/projections/ViewCalendarEventRepo';
import i18n from '../../Common/infra/services/i18nextService';
import { InvalidCredentialsError, MissingCredentialsError } from '@focus-front/proxy';
import { ActionContext, isEvent, isNotification } from '../../Common/domain/Action';
import { NotificationUseCase } from '../../Notification';
import { InvalidAccountError, UnexpectedError } from '../../Common/app/Errors';
import { MailSyncService } from '../infra/services/MailSyncService';
import { GetMailCallbacks } from './MailUseCase';
import { displayAttendeeName } from '../../Contact';

dayjs.extend(localizedFormat);

export class CantSendMailError extends Error {
  constructor() {
    super(i18n.t('error.mail.cantSendMail'));
    this.name = 'CantSendMailError';
  }
}

export class AppMailUseCase implements MailUseCase {
  SYNC_ALL_STEP = 50;
  ERROR_UNEXPECTED = i18n.t('error.unexpected');
  ERROR_CREDENTIALS = i18n.t('mail.credentialsError');
  ERROR_CANTSENDMAIL = i18n.t('error.mail_cantsend');
  ERROR_NO_ACCOUNT_TO_WRITE = i18n.t('error.mailDraftNoAccount');

  constructor(
    private readonly publisher: Publisher,
    private readonly notification: NotificationUseCase,
    private readonly calendar: CalendarUseCase,
    private readonly mail: MailRepo,
    private readonly mailRef: MailReferenceRepo,
    private readonly viewMailDraft: ViewMailDraftRepo,
    private readonly viewAccount: ViewAccountRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly viewSignature: ViewSignatureRepo,
    private readonly viewTag: ViewTagRepo,
    private readonly viewTagRelation: ViewTagRelationRepo,
    private readonly mailServices: MailServicesMap,
    private readonly logger: LoggerService,
    private readonly storage: StorageService,
    private readonly search: SearchService,
    private readonly fileManager: FileManagerService,
    private readonly mailSync: MailSyncService
  ) {}

  /*
   *
   */
  async get(mail_id: Uuid, { onContent, onError, onMeta }: GetMailCallbacks): Promise<void> {
    this.mailSync.getViewMail(mail_id, {
      onContent,
      onMeta,
      onError: (e: Error) => {
        if (e instanceof MissingCredentialsError || (e as any).code === 'MissingCredentialsError')
          return onError(this.ERROR_CREDENTIALS);
        if (e instanceof InvalidCredentialsError || (e as any).code === 'InvalidCredentialsError')
          return onError(this.ERROR_CREDENTIALS);
        this.logger.captureException(e);
        onError(this.ERROR_UNEXPECTED);
      },
    });
  }

  /*
   *
   */
  async getDraft(mail_draft_id: Uuid): Promise<ViewMailDraft | null> {
    return this.viewMailDraft.get(mail_draft_id);
  }

  async getAll(size: number, offset: number): Promise<(PartialViewMail & Taggable)[]> {
    return this.mailSync.getMailsList(size, offset);
  }

  async getAllDrafts(size: number, offset: number): Promise<ViewMailDraft[]> {
    return this.viewMailDraft.getAllByDate(size, offset);
  }

  async getAttachmentsData(mail: ViewMail): Promise<LocalFile[]> {
    try {
      const account = await this.viewAccount.get(mail.account_id);
      const ref = await this.mailRef.get(mail.id);

      if (!ref) throw new Error("Can't find Mail Reference");

      const attachments = mail.attachments
        ? await Promise.all(mail.attachments.map((attachment) => this.fileManager.get(attachment.id)))
        : [];

      if (attachments.findIndex((attachment) => !attachment || !attachment?.data) > -1) {
        if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();
        const { files } = await this.mailServices[account.type].fetchMail(ref, true);

        if (files?.length) {
          await Promise.all(files.map((file) => this.fileManager.add(file)));
          return files;
        }
      } else {
        return attachments.filter((attachment): attachment is LocalFile => attachment !== null);
      }
      return [];
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async writeNewMail(calendar: ViewCalendar, values: Partial<NewMailDraftDTO>): Promise<Result<ViewCalendarEvent>> {
    try {
      const accounts = await this.viewAccount.getAll();
      const mail_accounts = accounts.filter(isMailSupportedAccount);

      if (!mail_accounts.length) return { error: i18n.t('error.mailDraftNoAccount') || this.ERROR_NO_ACCOUNT_TO_WRITE };

      const { id } = await this.createNewDraft(values);

      const start = dayjs();
      const end = start.add(5, 'minute');
      const result = CalendarEvent.create({
        kind: 'SINGLE',
        type: 'UNIPILE',
        all_day: false,
        calendar_id: calendar.id,
        summary: i18n.t('event.defaultTitle.mail_draft'),
        start: toUTCDateTimeMs(start),
        end: toUTCDateTimeMs(end),
        start_tzid: calendar.default_tzid,
        end_tzid: calendar.default_tzid,
        metadata: {
          attached_entity: {
            id,
            type: 'MAIL_DRAFT',
          },
        },
      });

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const event = await this.viewCalendarEvent.get(result.aggregate.id);
        return event ? { result: event } : { error: `Could not find calendar event ( ${result.aggregate.id} ).` };
      }
      throw new Error(`Could not add calendar event : ${result.reason}.`);
    } catch (error) {
      this.logger.captureException(error);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async getDraftAttendees(
    parent_mail_id: Uuid,
    type: MailDraftType
  ): Promise<Pick<ViewMailDraft, 'cc_attendees' | 'to_attendees'>> {
    const parent_mail = await this.mailSync.getMailMeta(parent_mail_id);

    if (!parent_mail) {
      throw new Error(`Could not find parent_mail : ${parent_mail_id}.`);
    }

    const account = await this.viewAccount.get(parent_mail.account_id);

    if (!account) {
      throw new Error(`Could not find account : ${parent_mail.account_id}.`);
    }

    const identifier = accountIdentifier(account.connection_params);

    let to_attendees: MailDraftAttendee[] = [];
    let cc_attendees: MailDraftAttendee[] = [];

    switch (type) {
      case 'REPLY':
        to_attendees = [
          { display_name: parent_mail.from_attendee.display_name, identifier: parent_mail.from_attendee.identifier },
        ];
        break;
      case 'REPLY_ALL':
        to_attendees = [
          { display_name: parent_mail.from_attendee.display_name, identifier: parent_mail.from_attendee.identifier },
        ];
        cc_attendees = [
          ...(parent_mail.cc_attendees || []).map((att) => ({ display_name: att.display_name, identifier: att.identifier })),
          ...(parent_mail.to_attendees || [])
            .filter((att) => att.identifier !== identifier)
            .map((att) => ({ display_name: att.display_name, identifier: att.identifier })),
        ];
        break;
      default:
        break;
    }

    return {
      to_attendees,
      cc_attendees,
    };
  }

  /**
   * @note Mode is used to prefill some values
   *
   * If mode === 'REPLY'
   *    to = from
   *    cc = null
   *    bcc = null
   *
   * Else If mode === 'REPLY_ALL'
   *    to = from
   *    cc = to + cc + bcc (excluding user)
   *    bcc = null
   *
   * Else
   *    to = null
   *    cc = null
   *    bcc = null
   *
   * @note Do not create another MailDraft if there is already one for this mail
   * @todo Probably need to add a check if parent_mail was not found and throw an error accordingly
   */
  async getChildDraft(
    parent_mail_id: Uuid,
    type: MailDraftType,
    update_attendees = false
  ): Promise<Result<ViewMailDraft | null>> {
    try {
      /**
       * @note Find existing MailDraft if any
       */
      let getType = type;
      if (type === 'REPLY_ALL') getType = 'REPLY';

      const mail_draft = await this.viewMailDraft.getByParentAndType(parent_mail_id, getType);
      if (mail_draft) {
        const attendees = update_attendees ? await this.getDraftAttendees(parent_mail_id, type) : {};
        return { result: { ...mail_draft, ...attendees } };
      }

      const parent_mail = await this.mailSync.getFullMail(parent_mail_id);

      if (!parent_mail) {
        throw new Error(`Could not find parent_mail : ${parent_mail_id}.`);
      }

      const accounts = await this.viewAccount.getAll();
      const mail_accounts = accounts.filter((account) => isMailSupportedAccount(account));
      const found_account = mail_accounts.find((mail_account) => mail_account.id === parent_mail.account_id);
      const account = found_account || mail_accounts[0];

      const subject = `${type === 'TRANSFER' ? 'Fwd: ' : type === 'NEW' ? '' : 'Re: '} ${parent_mail.subject}`;
      const attachment_ids = type === 'TRANSFER' ? (parent_mail.attachments || []).map((attachment) => attachment.id) : [];
      const signature = await this.getSignature(account.current_signature);
      const body = `${signature}<br /><br /><blockquote>${parent_mail.body}</blockquote>`;

      const attendees = await this.getDraftAttendees(parent_mail_id, type);

      const resultCreate = createViewMailDraft(createUuid(), {
        type,
        account_id: account?.id ?? null,
        update_date: toUTCDateTimeMs(dayjs()),
        ...(account && {
          from_attendee: {
            display_name: account.name || accountIdentifier(account.connection_params),
            identifier: accountIdentifier(account.connection_params),
          },
        }),
        attachment_ids,
        subject,
        body,
        parent_mail_id: parent_mail.id,
        ...attendees,
      });

      await this.viewMailDraft.add(resultCreate);

      return { result: await this.viewMailDraft.get(resultCreate.id) };
    } catch (error) {
      this.logger.captureException(error);
      return { error: new UnexpectedError().message };
    }
  }

  /**
   * Returns the body with signature and eventual parent mail body
   */
  async getDraftBody(body: string, parent_mail_id?: Uuid): Promise<string> {
    const accounts = await this.viewAccount.getAll();
    const account: ViewAccount | undefined = accounts.filter(isMailSupportedAccount)[0];

    if (!account) throw new Error("Can't create a new mail draft without mail account");

    const signature = await this.getSignature(account.current_signature);

    let draftBody = `${body}${signature}`;

    if (parent_mail_id) {
      const parent_mail = await this.mailSync.getFullMail(parent_mail_id);
      draftBody += `<br /><br /><blockquote>${parent_mail?.body}</blockquote>`;
    }

    return draftBody;
  }

  /**
   *
   */
  async editDraft(mail_draft_id: Uuid, values: UpdateMailDraftDTO): Promise<Result<ViewMailDraft>> {
    try {
      await this.viewMailDraft.update(mail_draft_id, { ...values, update_date: toUTCDateTimeMs(dayjs()) });

      const mailDraft = await this.viewMailDraft.get(mail_draft_id);

      return mailDraft ? { result: mailDraft } : { error: `Could not find mail draft ( ${mail_draft_id} ).` };
    } catch (error) {
      this.logger.captureException(error);
      return typeof error === 'string' ? { error: error } : {};
    }
  }

  /**
   * Archive the mail remotely
   * If notification, archive the notification
   * Delete drafts here ? @todo
   *
   * @todo Consider archiving the mail and notification/calendarEvent atomically.
   */
  async mailDone(context: ActionContext): Promise<Result<void>> {
    try {
      if (context?.metadata?.attached_entity) {
        await this.archive(context.metadata.attached_entity.id);

        if (isNotification(context)) {
          await this.notification.archiveNotification(context.id);
          if (context.metadata?.origin_event) {
            const { result: originEvent } = await this.calendar.getEvent(context.metadata.origin_event.id);
            if (originEvent) {
              await this.calendar.deleteEvent(originEvent.id);
            }
          }
        }
        if (isEvent(context)) await this.calendar.deleteEvent(context.id);
      }
      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Delete the MailDraft
   * Delete the event
   */
  async mailDraftDone(context: ActionContext): Promise<void | Error> {
    try {
      if (context?.metadata?.attached_entity) {
        await this.viewMailDraft.remove(context.metadata.attached_entity.id);
        if (isNotification(context)) {
          await this.notification.archiveNotification(context.id);
          if (context.metadata?.origin_event) await this.calendar.deleteEvent(context.metadata.origin_event.id);
        }
        if (isEvent(context)) await this.calendar.deleteEvent(context.id);
      }
    } catch (e) {
      this.logger.captureException(e);
      return new UnexpectedError();
    }
  }

  /**
   * Send the MailDraft
   * Delete the MailDraft
   */
  async mailDraftWrite(context: ActionContext, mail_draft: ViewMailDraft): Promise<Result<void>> {
    try {
      const account = mail_draft.account_id ? await this.viewAccount.get(mail_draft.account_id) : null;

      if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();

      const sent = await this.mailServices[account.type].sendMail(account.id, mail_draft);

      if (!sent) throw new CantSendMailError();

      const event_id = isEvent(context) ? context.id : context.metadata?.origin_event?.id;
      if (event_id) {
        const hack = event_id.split('_');
        const aggId = hack[0] as Uuid;
        const isSingleEvent = hack.length === 1;

        if (isSingleEvent) {
          /**
           * This is another reason why we should talk about the use cases for
           * context.metadata.origin_event.id. Maybe you only want to use it
           * with Unipile calendars ?
           */
          const type = (await this.viewCalendarEvent.get(aggId))?.type;
          switch (type) {
            case 'GOOGLE':
              await this.calendar.editEvent(aggId, {
                type,
                kind: 'SINGLE',
                summary:
                  i18n.t('event.defaultTitle.mail_draftSent', {
                    attendees: mail_draft.to_attendees?.map(displayAttendeeName).join(', '),
                  }) ?? '',
              });
              break;
            case 'UNIPILE':
              await this.calendar.editEvent(aggId, {
                type,
                kind: 'SINGLE',
                summary:
                  i18n.t('event.defaultTitle.mail_draftSent', {
                    attendees: mail_draft.to_attendees?.map(displayAttendeeName).join(', '),
                  }) ?? '',
              });
              break;
            case undefined:
              break;
          }
        } else {
          /** @todo Handle recurring event instance case */
        }
      }

      await this.viewMailDraft.remove(mail_draft.id);

      if (isNotification(context)) {
        await this.notification.archiveNotification(context.id);
        if (context.metadata?.origin_event) {
          const { result: originEvent } = await this.calendar.getEvent(context.metadata.origin_event.id);
          if (originEvent) {
            await this.calendar.completeEvent(originEvent);
          }
        }
      }
      if (isEvent(context)) await this.calendar.completeEvent(context);

      return {};
    } catch (e) {
      if (e instanceof CantSendMailError) return { error: e.message };
      if (e instanceof InvalidCredentialsError) return { error: 'credentials' };
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * Send the MailDraft
   * Delete the mailDraft
   * Mark Mail as replied
   * if Notification, archive notification,
   * if Event, complete event @todo
   *
   * @todo Consider marking mail as replied, deleting the mailDraft and calendarEvent atomically.
   */
  async mailReply(
    calendar: ViewCalendar,
    context: ActionContext,
    mail_draft: ViewMailDraft
  ): Promise<Result<ViewCalendarEvent | null>> {
    try {
      if (!mail_draft.parent_mail_id) {
        throw new Error(`Mail draft ( ${mail_draft.id} ) doesn't have a parent_mail_id.`);
      }
      const mailAggregate = await this.mail.getAggregate(mail_draft.parent_mail_id);

      const account = mail_draft.account_id ? await this.viewAccount.get(mail_draft.account_id) : null;

      if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();
      if (!mail_draft.to_attendees?.length) throw new Error(`To attendees field is empty in MailUseCase.mailReply`);

      const resultReplied = mailAggregate.markReplied();

      if (resultReplied.ok !== true) throw new Error(`Could not mark email as replied`);

      const sent = await this.mailServices[account.type].sendMail(account.id, mail_draft);
      if (!sent) throw new CantSendMailError();

      await this.viewMailDraft.remove(mail_draft.id);
      await this.publisher.emit(resultReplied.changes);

      const end = dayjs();
      const start = end.subtract(DEFAULT_EVENT_INTERVAL, 'minute');

      const result = CalendarEvent.create({
        kind: 'SINGLE',
        type: 'UNIPILE',
        all_day: false,
        calendar_id: calendar.id,
        summary: i18n.t('event.defaultTitle.mailDone', {
          attendee: mail_draft.to_attendees.map(displayAttendeeName).join(', '),
        }),
        start: toUTCDateTimeMs(start),
        end: toUTCDateTimeMs(end),
        start_tzid: calendar.default_tzid,
        end_tzid: calendar.default_tzid,
        metadata: {
          attached_entity: {
            id: mail_draft.parent_mail_id,
            type: 'MAIL',
            account_id: account.id,
            account_type: account.type,
          },
        },
      });

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        /** @todo Track real_start ! */
        const now = toUTCDateTimeMs(dayjs());
        const eventCompleted = result.aggregate.complete(now, now);
        if (eventCompleted.ok === true) {
          await this.publisher.emit(eventCompleted.changes);
          const event = await this.viewCalendarEvent.get(result.aggregate.id);

          return event ? { result: event } : { error: `Could not find calendar event ( ${result.aggregate.id} ).` };
        } else {
          throw new Error(`Could not complete calendar event ( ${result.aggregate.id} ).`);
        }
      } else {
        throw new Error(`Could not add calendar event : ${result.reason}.`);
      }
    } catch (e) {
      if (e instanceof CantSendMailError) return { error: this.ERROR_CANTSENDMAIL };
      else {
        this.logger.captureException(e);
        return { error: this.ERROR_UNEXPECTED };
      }
    }
  }

  async mailTransfer(
    calendar: ViewCalendar,
    context: ActionContext,
    mail_draft: ViewMailDraft
  ): Promise<Result<ViewCalendarEvent | null>> {
    return this.mailReply(calendar, context, mail_draft);
  }

  /**
   * Create a new calendar event attached to an archived mail
   * @param calendar ID of the calendar where the event must be created
   * @param mail_id ID of the mail to attach
   */
  async mailWork(calendar: ViewCalendar, mail_id: Uuid): Promise<Result<ViewCalendarEvent>> {
    try {
      const mail = await this.mailSync.getMailMeta(mail_id);

      if (!mail) throw new Error('Mail not found : AppMailUseCase | mailWork');

      const account = await this.viewAccount.get(mail.account_id);

      if (!account) throw new Error("Can't find mail's account");

      /**
       * @note Maybe we should check if the mail is not attached to anything before ?
       */

      const start = dayjs();
      const end = start.add(DEFAULT_EVENT_INTERVAL, 'minute');
      const result = CalendarEvent.create({
        kind: 'SINGLE',
        type: 'UNIPILE',
        all_day: false,
        calendar_id: calendar.id,
        summary: i18n.t('event.defaultTitle.mailWork', { attendee: displayAttendeeName(mail.from_attendee) }),
        start: toUTCDateTimeMs(start),
        end: toUTCDateTimeMs(end),
        start_tzid: calendar.default_tzid,
        end_tzid: calendar.default_tzid,
        metadata: {
          attached_entity: {
            id: mail_id,
            type: 'MAIL',
            account_id: account.id,
            account_type: account.type,
          },
        },
      });

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const event = await this.viewCalendarEvent.get(result.aggregate.id);
        return event ? { result: event } : { error: `Could not find calendar event ( ${result.aggregate.id} ).` };
      }
      throw new Error(`Could not add calendar event : ${result.reason}.`);
    } catch (error) {
      this.logger.captureException(error);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   * @todo Handle attachments
   */
  async fetchNew(
    account_id: Uuid,
    from_date?: UTCDateTimeMs,
    limit?: number
  ): Promise<{ mails: PartialViewMail[]; status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (!account) throw new InvalidAccountError();

      const new_mails = await this.mailSync.fetchNew(account, from_date, limit);

      return { mails: new_mails, status: 'IDLE' };
    } catch (e) {
      console.error(e);
      /**
       * We must test with e.code because for Outlook, the MicrosoftGraphClient convert custom errors of our AuthProvider to
       * a "GraphError", which store the name of our errors in a "code" variable.
       **/
      if (e instanceof MissingCredentialsError || (e as any).code === 'MissingCredentialsError')
        return { mails: [], status: 'CREDENTIALS' };
      if (e instanceof InvalidCredentialsError || (e as any).code === 'InvalidCredentialsError')
        return { mails: [], status: 'CREDENTIALS' };
      this.logger.captureException(e);
      return { mails: [], status: 'ERROR' };
    }
  }

  /**
   *
   */
  async initialFetchNew(account_id: Uuid, duration: number): Promise<{ mails: PartialViewMail[]; status: AccountSourceStatus }> {
    const MAX = 100;
    const from_date = toUTCDateTimeMs(dayjs().subtract(duration, 'hour'));
    const results = await this.fetchNew(account_id, from_date, MAX);

    return results;
  }

  /**
   *
   */
  private async archive(mail_id: Uuid): Promise<void> {
    const viewMail = await this.mailRef.get(mail_id);

    if (!viewMail) {
      // If mail is not found, it's probably already archived
      return;
    }

    const account = await this.viewAccount.get(viewMail.account_id);
    const mailAggregate = await this.mail.getAggregate(mail_id);

    if (!account || !isMailSupportedAccount(account)) throw new InvalidAccountError();

    // Disable remote folder move for IMAP accounts that cause issues
    if (account.type !== 'OUTLOOK') return;

    // await this.mailServices[account.type].archiveMail(account.id, viewMail);
    this.mailServices[account.type].archiveMail(account.id, viewMail).catch((e) => {
      /**
       * archiveMail can take a long time depending on the provider.
       * AppMailUseCase.archive doesn't want to await its result.
       *
       * Yet we need to catch possible errors to avoid crashing the app for any reason.
       *
       * @todo Handle different types of error here, e.g. alert user ? retry ? ignore some ?
       */
      this.logger.captureException(e);
      return e;
    });

    const result = mailAggregate.archive();
    await this.publisher.emit(result.changes);
  }

  private async getSignature(signature_id: Uuid | undefined): Promise<string> {
    if (!signature_id) return '';

    const signature = await this.viewSignature.get(signature_id);

    if (signature) return '<div class="UnipileSignature"><br/><br/>' + signature.body + '</div>';

    return '';
  }

  private async createNewDraft(values: Partial<NewMailDraftDTO>): Promise<ViewMailDraft> {
    /**
     * Retrieve a mail account if any to set the "from" field.
     */
    const accounts = await this.viewAccount.getAll();
    const account: ViewAccount | undefined = accounts.filter(isMailSupportedAccount)[0];

    if (!account) throw new Error("Can't create a new mail draft without mail account");

    const draft = createViewMailDraft(createUuid(), {
      ...values,
      body: (values?.body ? values.body : '') + (await this.getSignature(account?.current_signature)),
      type: 'NEW',
      account_id: account?.id,
      ...(account && {
        from_attendee: {
          display_name: account.name || accountIdentifier(account.connection_params),
          identifier: accountIdentifier(account.connection_params),
        },
      }),
      update_date: toUTCDateTimeMs(dayjs()),
    });

    await this.viewMailDraft.add(draft);

    return draft;
  }

  private async _syncMeta(account: ViewAccount & { type: MailSupportedAccounts }): Promise<void> {
    try {
      await this.mailSync.syncMeta(account);
    } catch (e) {
      if (e instanceof MissingCredentialsError || (e as any).code === 'MissingCredentialsError') return;
      if (e instanceof InvalidCredentialsError || (e as any).code === 'InvalidCredentialsError') return;
      this.logger.captureException(e);
    }
  }

  async syncOneMeta(account_id: Uuid): Promise<void> {
    try {
      const account = await this.viewAccount.get(account_id);
      if (account && isMailSupportedAccount(account)) this._syncMeta(account);
      else throw new InvalidAccountError();
    } catch (e) {
      this.logger.captureException(e);
    }
  }

  async syncAllMeta(): Promise<void> {
    try {
      const accounts = await this.viewAccount.getAll();

      const mail_sup_acc = accounts.filter(isMailSupportedAccount);

      Promise.all(mail_sup_acc.map((acc) => this._syncMeta(acc)));
    } catch (e) {
      this.logger.captureException(e);
    }
  }

  async fullFetch(account_id?: Uuid): Promise<void> {
    try {
      await this.mailSync.fullFetch(account_id);
    } catch (e) {
      if (e instanceof MissingCredentialsError || (e as any).code === 'MissingCredentialsError') return;
      if (e instanceof InvalidCredentialsError || (e as any).code === 'InvalidCredentialsError') return;
      this.logger.captureException(e);
    }
  }
}
