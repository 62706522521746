import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0E2936',
      contrastText: '#FFFFFF',
      light: '#153d51',
      dark: '#091b24',
    },
    secondary: {
      main: '#00BFA2',
      light: '#33CCB5',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#42AF71',
      contrastText: '#FFFFFF',
    },
    divider: '#D5D5D5',
    background: {
      default: '#FFFFFF',
      paper: '#f4f5f7',
    },
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiButton: {
      disableElevation: true,
      variant: 'contained',
      style: {
        textTransform: 'none',
        // fontFamily: 'Poppins',
      },
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      color: 'secondary',
    },
    MuiLink: {
      color: 'secondary',
      style: {
        textDecoration: 'underline',
      },
    },
  },
  typography: {
    fontFamily: ['Inter', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 'initial',
    },
    h2: {
      fontSize: 24,
      fontFamily: 'Poppins',
      letterSpacing: -1,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: -1,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 400,
      opacity: 0.5,
    },
  },
});

export default theme;
