/// <reference path="../../../../custom.d.ts" />

import './app.scss';

import { ConfirmProvider } from 'material-ui-confirm';
import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';

import {
  recoverSession,
  registerErrorSink,
  stopResizeObserverErrorPropagation,
  debugThrowError,
  useAppDispatch,
  createUuid,
  crash,
  recoveryReloadRequestSelector,
  recoveryRequestSelector,
  KeyCannotBeCreatedError,
  NetworkError,
} from '@focus-front/core';

import { Button, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import Layout from './Common/Layout';
import theme from './lightTheme';
import LogInPage from './User/LogInPage';
import OnboardingPage from './Onboarding/OnboardingPage';
import SignUpPage from './User/SignUpPage';
import { useSelector } from 'react-redux';
import RecoveryPage from './Recovery/RecoveryPage';

import { ErrorBoundary } from 'react-error-boundary';
import ProtectedRoute from './Common/ProtectedRoute';
import ResetPasswordPage from './Recovery/ResetPasswordPage';
import ResetPasswordNoSecretPage from './Recovery/ResetPasswordNoSecretPage';

// const useBeforeRender = (func: Callable) => {
//   const willMount = useRef(true);

//   if (willMount.current) {
//     func();
//   }
//   willMount.current = false;
// };

export default function App() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const reloadRequested = useSelector(recoveryReloadRequestSelector);
  //   const recoveryRequested = useSelector(recoveryRequestSelector);

  //   useBeforeRender(() => {
  //     console.warn('Registering error sink.');
  //     const errorSink = (error) => {
  //       console.warn('error sink : ', error);
  //       dispatch(crash(error));
  //     };
  //     stopResizeObserverErrorPropagation();
  //     const unregisterErrorSink = registerErrorSink(errorSink);
  //     return unregisterErrorSink;
  //   });

  useEffect(() => {
    // console.warn('Registering error sink.');
    const errorSink = (error) => {
      console.warn('error sink : ', error);
      dispatch(crash(error));
    };
    stopResizeObserverErrorPropagation();
    const unregisterErrorSink = registerErrorSink(errorSink);

    return unregisterErrorSink;
  }, [dispatch]);

  /**
   * On the App Launch, the first thing to do is try to recover a session
   */
  useEffect(() => {
    dispatch(recoverSession());
  }, []);

  useEffect(() => {
    if (reloadRequested) {
      console.warn('Reload requested !');
      history.push('/');
      window.location.reload();
    }
  }, [reloadRequested, history]);

  //   useEffect(() => {
  //     if (recoveryRequested) {
  //       console.warn('Recovery requested !');
  //       history.push('/recover');
  //     }
  //   }, [recoveryRequested, history]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <ErrorBoundary
        FallbackComponent={RecoveryPage}
        // onError={(error, info) => {
        //   dispatch(crash(error));
        // }}
      >
        <ConfirmProvider
          defaultOptions={{
            dialogProps: {
              maxWidth: 'xs',
              onClose: () => {},
            },
          }}
        >
          <Switch>
            <Route path="/signup" component={SignUpPage} />
            <Route path="/login" component={LogInPage} />
            <Route path="/onboarding" component={OnboardingPage} />
            <Route path="/recover" component={RecoveryPage} />
            <Route path="/resetpassword/:requestId/:token" component={ResetPasswordPage} />
            <Route path="/resetpasswordnosecret/:requestId/:token" component={ResetPasswordNoSecretPage} />
            <ProtectedRoute render={() => <Layout />} />
          </Switch>
        </ConfirmProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
