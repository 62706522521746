import { MobileConnectionParams } from '../../../Account';
import { UnsupportedDevice, Uuid } from '../../../Common';
import { ImSendStatus, NewImDTO, ViewIm, ImAttachment } from '../../domain/projections/ViewIm';
import { NewImThreadDTO, UpdateImThreadDTO, ViewImThread } from '../../domain/projections/ViewImThread';
import { ImService, ImSearchQuery } from './ImService';

export class UnsupportedImService implements ImService<any> {
  registerAccount(): void {
    return;
  }

  unregisterAccount(): void {
    return;
  }

  async getThreadsList(): Promise<NewImThreadDTO[]> {
    throw new UnsupportedDevice();
  }

  async sendMessage(): Promise<UpdateImThreadDTO & { send_status: ImSendStatus; error?: Error }> {
    throw new UnsupportedDevice();
  }

  async searchMessages(): Promise<{ im: NewImDTO; thread?: NewImThreadDTO; update_thread?: UpdateImThreadDTO }[]> {
    throw new UnsupportedDevice();
  }

  async downloadAttachment(): Promise<{ extension?: string; mime?: string; size: number; data: string }> {
    throw new UnsupportedDevice();
  }
}
