import { openSubUpgrade, sessionSelector, SubscriptionOffer, useAppDispatch } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { cardOutline, giftOutline, rocketOutline } from 'ionicons/icons';
import React from 'react';
import cn from 'classnames';
import { Trans, Translation, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAdminLink } from '../../Common/useAdminLink';

type Props = {
  offer: SubscriptionOffer;
  showCta?: boolean;
};

const useClasses = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    backgroundColor: 'transparent',
    '& ul': {
      margin: 0,
    },
  },
  top: ({ offer }: Props) => ({
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: 'white',
    margin: -1,
  }),
  bgc: ({ offer }: Props) => ({
    background:
      offer === 'Personal'
        ? theme.palette.primary.main
        : offer === 'Boost'
        ? 'linear-gradient(12deg, rgba(0,191,162,1) 0%, rgba(0,255,216,1) 100%);'
        : 'linear-gradient(12deg, rgba(66,175,113,1) 0%, rgba(0,255,110,1) 100%);',
  }),
  cta: {
    position: 'absolute',
    right: 0,
    bottom: -theme.spacing(2),
  },
}));

export default function SubscriptionCard({ offer, showCta }: Props) {
  const c = useClasses({ offer });
  const { t } = useTranslation();
  const session = useSelector(sessionSelector);
  const dispatch = useAppDispatch();
  const admin_link = useAdminLink({ destination: 'subscription' });

  const isCurrentPlan = session.offer === offer;

  return (
    <Paper variant="outlined" className={c.paper}>
      <Box p={3} className={cn(c.top, c.bgc)}>
        <Typography variant="h2">Unipile {offer}</Typography>
      </Box>
      <Box px={2} py={4}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1">
              {offer === 'Personal' && (
                <Trans i18nKey="subscription.personal.description">
                  <ul>
                    <li>2 comptes Email</li>
                    <li>1 Mobile (Appels + SMS)</li>
                    <li>1 messagerie instantanée</li>
                  </ul>
                </Trans>
              )}
              {offer === 'Boost' && (
                <Trans i18nKey="subscription.boost.description">
                  <ul>
                    <li>Comptes emails illimités</li>
                    <li>Mobiles illimités</li>
                    <li>Messageries instantanée illimitées</li>
                  </ul>
                </Trans>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ position: 'relative', paddingBottom: isCurrentPlan ? 0 : 60 }}>
            {showCta && (
              <>
                {isCurrentPlan && (
                  <>
                    {offer === 'Boost' && (
                      <>
                        <Typography variant="subtitle1" align="right">
                          {t('subscription.freeRemaining', { days: 1 })}
                        </Typography>
                        <Box textAlign="right" mt={1} mb={3}>
                          <Button
                            color="secondary"
                            classes={{ root: c.bgc }}
                            startIcon={<IonIcon icon={giftOutline} />}
                            onClick={() => dispatch(openSubUpgrade())}
                          >
                            {t('subscription.getMore')}
                          </Button>
                        </Box>
                        <Typography variant="subtitle1" align="right">
                          {t('subscription.invalidPayment', { date: '11/11/2022' })}
                        </Typography>
                        <Box textAlign="right" mt={1}>
                          <Button
                            variant="outlined"
                            onClick={() => alert('Not available yet')}
                            startIcon={<IonIcon icon={cardOutline} />}
                          >
                            {t('subscription.updatePayment')}
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                )}
                {!isCurrentPlan && offer !== 'Personal' && (
                  <Button
                    startIcon={<IonIcon icon={rocketOutline} />}
                    color="secondary"
                    size="large"
                    className={c.cta}
                    classes={{ root: c.bgc }}
                    onClick={() => dispatch(openSubUpgrade())}
                  >
                    {t('subscription.upgradeNow')}
                  </Button>
                )}
                {!isCurrentPlan && offer === 'Personal' && (
                  <>
                    <Typography variant="subtitle1" align="right">
                      {t('subscription.personal.downgradeWarn')}
                    </Typography>
                    <Button className={c.cta} onClick={admin_link.onClick}>
                      {t('subscription.downgrade', { offer })}
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
