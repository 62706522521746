import React from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { toRRuleString } from 'libs/core/src/Common/domain/RRule';
import { useTranslation } from 'react-i18next';
import RRule from '@keep_focus/rrule-tz';
import { RRuleToText } from './EventRecurrentSelectTranslations';
import dayjs from 'dayjs';
import { capitalize } from 'libs/core/src/Common/utils/string';
import i18n from 'libs/core/src/Common/infra/services/i18nextService';
import { RecurringCalendarEventRootStrip } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import {
  EventDefaultValues,
  parseRRuleStrings,
  parseSelectValue,
  RecurrencyInput,
  RecurringEventSelectOptions,
} from '@focus-front/ui';

interface EventRecurrentSelectProps {
  value: RecurrencyInput;
  onChange: (value: RecurrencyInput) => void;
  recurringEventRoot: RecurringCalendarEventRootStrip;
  defaultValues: EventDefaultValues;
}

const useClasses = makeStyles((theme) => ({
  selectForm: {},
}));

const EventRecurrentSelect: React.FC<EventRecurrentSelectProps> = ({ value, onChange, recurringEventRoot, defaultValues }) => {
  const eventData = recurringEventRoot || defaultValues;
  const { t } = useTranslation();
  const c = useClasses();

  const defaultSelectValue = defaultValues.kind === 'SINGLE' ? 'SINGLE' : parseSelectValue(recurringEventRoot);
  const customRuleObject =
    recurringEventRoot?.kind === 'RECURRING' && parseRRuleStrings(recurringEventRoot.recurrence, recurringEventRoot.start);

  const customRule = defaultSelectValue === 'CUSTOM' ? new RRule(customRuleObject.options) : null;

  // Workaround for the string not displaying the correct day
  const day = dayjs(eventData.start).day() === 0 ? 6 : dayjs(eventData.start).day() - 1;

  // These rules will be applied to the string only ---
  const weeklyRule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [day],
  });

  const monthlyRule = new RRule({
    freq: RRule.MONTHLY,
    bymonthday: [dayjs(eventData.start).date()],
  });

  const yearlyRule = new RRule({
    freq: RRule.YEARLY,
    bymonthday: [dayjs(eventData.start).date()],
  });

  // --------------------------------------------

  const handleChange = (event) => {
    const recurrency: RecurringEventSelectOptions = event.target.value;
    switch (recurrency) {
      case 'SINGLE':
        onChange({ kind: 'SINGLE', selectValue: recurrency });
        break;
      case 'DAILY':
      case 'WEEKLY':
      case 'MONTHLY':
      case 'YEARLY': {
        const rule = new RRule({
          freq: RRule[recurrency],
        });
        onChange({
          kind: recurrency === defaultSelectValue ? defaultValues.kind : 'RECURRING',
          selectValue: recurrency,
          recurrence: [toRRuleString(rule.toString())],
        });
        break;
      }
      case 'WEEKDAY': {
        const rule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
        });
        onChange({
          kind: recurrency === defaultSelectValue ? defaultValues.kind : 'RECURRING',
          selectValue: recurrency,
          recurrence: [toRRuleString(rule.toString())],
        });
        break;
      }
      case 'CUSTOM': {
        // Same logic that they had before
        onChange({
          kind: recurrency === defaultSelectValue ? defaultValues.kind : 'RECURRING',
          selectValue: recurrency,
          recurrence: [toRRuleString(customRule.toString())],
        });
        break;
      }
      default:
        onChange({ kind: 'SINGLE', selectValue: recurrency });
        break;
    }
  };

  return (
    <FormControl variant="outlined" size="small" className={c.selectForm}>
      <Select value={value.selectValue} onChange={handleChange}>
        <MenuItem value={'SINGLE'}> {t('calendar.recurrentSelect.single')}</MenuItem>
        <MenuItem value={'DAILY'}>{t('calendar.recurrentSelect.everyDay')}</MenuItem>
        <MenuItem value={'WEEKLY'}>
          {i18n.language === 'fr' ? RRuleToText({ strRRule: weeklyRule.toString() }) : capitalize(weeklyRule.toText())}
        </MenuItem>
        <MenuItem value={'MONTHLY'}>
          {i18n.language === 'fr' ? RRuleToText({ strRRule: monthlyRule.toString() }) : capitalize(monthlyRule.toText())}
        </MenuItem>
        <MenuItem value={'YEARLY'}>
          {i18n.language === 'fr'
            ? RRuleToText({ strRRule: yearlyRule.toString() }) + `/${dayjs(eventData.start).format('MM')}`
            : 'Every year'}
        </MenuItem>
        <MenuItem value={'WEEKDAY'}>{t('calendar.recurrentSelect.everyWeekdayWeb')}</MenuItem>
        {customRule && (
          <MenuItem value={'CUSTOM'}>
            {i18n.language === 'fr' ? RRuleToText({ strRRule: customRule.toString() }) : capitalize(customRule.toText())}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default EventRecurrentSelect;
