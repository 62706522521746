import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const HeaderTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 400,
    height: 50,
    minWidth: 123,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  selected: {
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  disabled: {
    opacity: '0.3!important',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  labelIcon: {
    minHeight: 0,
    paddingTop: 5,
    '& ion-icon:first-child': {
      fontSize: 24,
      marginBottom: '0!important',
      marginRight: 6,
    },
  },
}))(Tab);

export default HeaderTab;
