import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Signature } from '../../domain';

/**
 * Repository.
 */
export class SignatureRepo extends AggregateRepo<Signature> {
  constructor(eventStore: DomainEventStore) {
    super(Signature, eventStore);
  }
}
