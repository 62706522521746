import { UTCDateBasic, UTCDateTimeBasic, Uuid } from '../../Common';
import { GoogleCalendarEventId } from '../infra/services/GoogleCalendarEventId';

/**
 * @note Opaque type.
 */
declare const validUnipileCalendarEventInstanceId: unique symbol;
export type UnipileCalendarEventInstanceId = string & { [validUnipileCalendarEventInstanceId]: true };

/**
 * @note Opaque type.
 */
declare const validGoogleCalendarEventInstanceId: unique symbol;
export type GoogleCalendarEventInstanceId = string & { [validGoogleCalendarEventInstanceId]: true };

/**
 * @note Opaque type.
 */
declare const validGoogleCalendarEventAllDayInstanceId: unique symbol;
export type GoogleCalendarEventAllDayInstanceId = string & {
  [validGoogleCalendarEventAllDayInstanceId]: true;
};

/**
 * @note Opaque type.
 */
declare const validGoogleCalendarEventAllDaySplitRecurringId: unique symbol;
export type GoogleCalendarEventAllDaySplitRecurringId = string & {
  [validGoogleCalendarEventAllDaySplitRecurringId]: true;
};

/**
 *
 */
export function toUnipileCalendarEventInstanceId(id: Uuid, original_start: UTCDateTimeBasic): UnipileCalendarEventInstanceId {
  return `${id}_${original_start}` as UnipileCalendarEventInstanceId;
}

/**
 *
 */
export function toGoogleCalendarEventInstanceId(
  id: GoogleCalendarEventId,
  original_start: UTCDateTimeBasic
): GoogleCalendarEventInstanceId {
  return `${id}_${original_start}` as GoogleCalendarEventInstanceId;
}

/**
 *
 */
export function toGoogleCalendarEventAllDayInstanceId(
  id: GoogleCalendarEventId,
  original_start: UTCDateBasic
): GoogleCalendarEventAllDayInstanceId {
  return `${id}_${original_start}` as GoogleCalendarEventAllDayInstanceId;
}

/**
 * @note This is more a reminder of this existing id format than something to use.
 *
 * @note Consider this scenario on Gcal UI :
 *
 *          - Create a non-allDay recurring event repeating n times with n > 2.
 *          - Create an exception, make instance n-1 allDay.
 *            Choose "This and following events".
 *            This creates a "split" and yields a regular `${id}` id shape
 *            for the new original recurring event.
 *            The original non-allDay recurring event recurrence is adjusted to
 *            end at n - 2.
 *          - Edit the original non-allDay recurring event.
 *            As expected, only the instances belonging to the original non-allDay
 *            recurring event will reflect the changes.
 *
 *       Now, compare with :
 *
 *          - Create an allDay recurring event repeating n times with n > 2.
 *          - Create an exception, edit anything but allDay and dates on instance n-1.
 *            Choose "This and following events".
 *            This creates a "split" and yields the `${id}_R${new_start}` id shape
 *            for the new original recurring event.
 *          - Edit the original allDay recurring event.
 *            Unexpectedly, both the original and the split recurring events and
 *            their instances will reflect the changes !!!
 *            This doesn't appear to be limited to some rules on the UI, a patch
 *            on the original allDay event done via the API will yield the same
 *            results.
 *          - Edit what you expect to be the split recurring event, making it
 *            non-allDay.
 *            The split recurring event with the `${id}_R${new_start}` shape will
 *            be cancelled.
 *            The change will affect the original recurring event and its recurrence
 *            will be adjusted to reintegrate the "split".
 *
 *       Also :
 *          - Create an allDay recurring event repeating n times with n > 2.
 *          - Create an exception, edit anything but allDay and dates on instance n-1.
 *            Choose "This and following events".
 *            This creates a "split" and yields the `${id}_R${new_start}` id shape
 *            for the new original recurring event.
 *          - Edit what you expect to be the split recurring event, keeping it
 *            allDay and moving it to a different date ( reassuringly it seems
 *            to work the same for any date, earlier, later, overlapping ).
 *            The split recurring event with the `${id}_R${new_start}` shape will
 *            be cancelled.
 *            A new recurring event will be created, starting at the specified new
 *            date.
 *          - Edit the original allDay recurring event.
 *            As expected, only the instances belonging to the original allDay
 *            recurring event will reflect the changes.
 *            The two recurring event should now be effectively independent.
 *
 *       Conclusion :
 *          - On a AllDaySplit, while there exists 2 recurring events at some point,
 *            The one with the `${id}_R${new_start}` id shape should be treated
 *            with caution. As long as a recurring event has an id with shape,
 *            it seems to not be considered a completely independent event but
 *            rather a weird extension of how Gcal encodes exceptions for
 *            recurring events.
 *
 *       See https://developers.google.com/calendar/api/guides/recurringevents
 *
 * @todo Handle events with a GoogleCalendarEventAllDaySplitRecurringId appropriately,
 *       as described in toGoogleCalendarEventAllDaySplitRecurringId notes.
 *
 */
export function toGoogleCalendarEventAllDaySplitRecurringId(
  id: GoogleCalendarEventId,
  new_start: UTCDateBasic
): GoogleCalendarEventAllDaySplitRecurringId {
  return `${id}_R${new_start}` as GoogleCalendarEventAllDaySplitRecurringId;
}

/**
 * Holidays.
 *
 * 20220413_1u0ljn4ss6obofcto6s6c3ema4
 * 20220403_sti3uqtus90v22rh3eo1lvt13k
 * 
 * ${UTCDateBasic}_${string}
 * 
 * @note This is more a reminder of this existing id format than something to use.
 *
 * @note Opaque type.
 */
declare const validGoogleCalendarEventHolidayId: unique symbol;
export type GoogleCalendarEventHolidayId = string & {
  [validGoogleCalendarEventHolidayId]: true;
};

/**
 * Sports.
 *
 * 20220130T000000_2022012910
 * 20211214T011500_20211213022
 * 20220206T200000_20220206032
 * 
 * ${UTCDateTimeBasic}_${string}
 * 
 * @note This is more a reminder of this existing id format than something to use.
 *
 * @note Opaque type.
 */
declare const validGoogleCalendarEventSportId: unique symbol;
export type GoogleCalendarEventSportId = string & {
  [validGoogleCalendarEventSportId]: true;
};

/**
 * Moonphases.
 *
 * moonphase+1672363260000
 * 
 * moonphase+${integer}
 * 
 * @note This is more a reminder of this existing id format than something to use.
 *
 * @note Opaque type.
 */
declare const validGoogleCalendarEventMoonId: unique symbol;
export type GoogleCalendarEventMoonId = string & {
  [validGoogleCalendarEventMoonId]: true;
};

/**
 * Any other yet undiscovered shape.
 * 
 * @note This is used to avoid adding string to the union of possible shapes.
 *
 * @note Opaque type.
 */
declare const validGoogleCalendarEventOtherId: unique symbol;
export type GoogleCalendarEventOtherId = string & {
  [validGoogleCalendarEventOtherId]: true;
};


