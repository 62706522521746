import { downloadOutline, imagesOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { Uuid, useAppDispatch, ImAttachment, getAttachmentFile, LocalFile } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Dialog, Typography } from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const useClasses2 = makeStyles((theme) => ({
  attImgContainer: {
    width: 250,
    height: 250,
    padding: 5,
    position: 'relative',
    '& > .img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 11,
      display: 'block',
    },
    '& img.img': {
      cursor: 'zoom-in',
      backgroundColor: 'black',
    },
    '& .download': {
      opacity: 0,
      position: 'absolute',
      right: 10,
      top: 10,
      backgroundColor: 'rgba(0,0,0,0.7)',
      borderRadius: 8,
      color: '#fff',
      width: 34,
      fontSize: 22,
      cursor: 'pointer',
      textAlign: 'center',
    },
    '&:hover .download': {
      opacity: 1,
    },
  },
  attImgIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    textAlign: 'center',
    color: 'white',
    '& ion-icon': {
      fontSize: 40,
    },
  },
  thumb: {
    borderRadius: 11,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'block',
      filter: 'blur(3px)',
      transform: 'scale(1.1)',
    },
  },
}));

function ImageAttachment({ account_id, attachment }: { account_id: Uuid; attachment: ImAttachment }) {
  const [file, setFile] = useState<LocalFile>(null);
  const [status, setStatus] = useState<'LOADING' | 'ERROR' | 'UNSUPPORTED' | 'IDLE'>('LOADING');
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const c = useClasses2();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const { error, result } = await dispatch(getAttachmentFile(account_id, attachment));
      setStatus(error === 'UnsupportedDevice' ? 'UNSUPPORTED' : error ? 'ERROR' : 'IDLE');
      if (result) setFile(result);
    })();
  }, [attachment]);

  const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:${attachment.mime}; base64,${file.data}`;
    downloadLink.download = attachment.name;
    downloadLink.click();
  };

  return (
    <>
      {file && (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'} scroll="body">
          <img
            style={{ width: '100%', height: 'auto', display: 'block' }}
            src={`data:${attachment.mime}; base64,${file.data || ''}`}
          ></img>
        </Dialog>
      )}
      <div className={c.attImgContainer}>
        {(status !== 'IDLE' || !file?.data) && (
          <div className="img">
            {attachment.media_data.preview && (
              <div className={c.thumb}>
                <img src={attachment.media_data.preview} />
              </div>
            )}
            <div className={c.attImgIcon}>
              <IonIcon icon={imagesOutline} />
              {status === 'ERROR' && <Typography variant="subtitle2">{t('attachment.error')}</Typography>}
              {status === 'UNSUPPORTED' && <Typography variant="subtitle2">{t('attachment.notSupported')}</Typography>}
            </div>
            {status === 'LOADING' && (
              <Skeleton variant="rect" width={240} height={240} style={{ borderRadius: 11 }} animation="wave" />
            )}
          </div>
        )}
        {file && file.data && (
          <>
            <div className="download" onClick={handleDownload}>
              <IonIcon icon={downloadOutline} />
            </div>
            <img className="img" onClick={() => setOpen(true)} src={`data:${attachment.mime}; base64,${file.data || ''}`}></img>
          </>
        )}
      </div>
    </>
  );
}

export default React.memo(ImageAttachment);
