import {
  calendarsSelector,
  toUTCDateTimeMs,
  useAppDispatch,
  calendarTitleSelector,
  calendarDateSelector,
  calendarModeSelector,
} from '@focus-front/core';
import {
  Box,
  ButtonBase,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);
dayjs.extend(weekOfYear);
import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DayjsUtils from '@date-io/dayjs';
import CalendarViewMultiSelect from './CalendarViewMultiSelect';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { NavButton } from '../Wizard/NavButton';
import useCalendar from './useCalendar';
import cn from 'classnames';

const useClasses = makeStyles((theme) => ({
  container: {
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: theme.spacing(1),
    borderRadius: 6,
  },
  todayHeader: {
    color: theme.palette.secondary.main,
  },
  todayButton: {
    marginRight: theme.spacing(0.5),
  },
  select: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& div': {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
    '& fieldset': {
      border: 'none',
    },
  },
}));

export function NewCalendarNav() {
  const [isPickerOpen, setPickerOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const pickerId = isPickerOpen ? 'select date' : undefined;

  const { t } = useTranslation();
  const c = useClasses();

  const title = useSelector(calendarTitleSelector);
  const date = useSelector(calendarDateSelector);
  const allCalendars = useSelector(calendarsSelector);
  const mode = useSelector(calendarModeSelector);

  const { changeView, gotoDate, next, prev, today } = useCalendar();

  const handleClick = () => {
    setPickerOpen(!isPickerOpen);
  };
  const handlePick = (date: dayjs.Dayjs | null) => {
    gotoDate(toUTCDateTimeMs(date));
    setPickerOpen(false);
  };
  const handleClose = () => setPickerOpen(false);

  const handleViewChange = (event) => {
    const viewMode = event.target.value;
    changeView(viewMode);
  };

  return (
    <div className={c.container}>
      <ButtonBase className={c.title} ref={anchorRef} aria-describedby={pickerId} onClick={handleClick}>
        <div>
          <Typography variant="h1">{title}</Typography>
          {mode !== 'MONTH' && (
            <Typography variant="subtitle1" className={cn(dayjs(date).isToday() && c.todayHeader)}>
              {mode === 'DAY' ? dayjs(date).format('dddd') : `${t('calendar.select.week')} ${dayjs(date).week()}`}
            </Typography>
          )}
        </div>
        <KeyboardArrowDown />
      </ButtonBase>

      <Box display="flex" alignItems="center" paddingTop="6px">
        {allCalendars?.length > 1 && <CalendarViewMultiSelect />}
        <FormControl size="small">
          <Select defaultValue={'DAY'} variant="outlined" onChange={handleViewChange} className={c.select}>
            <MenuItem value={'DAY'}>{t('calendar.select.day')}</MenuItem>
            <MenuItem value={'WEEK'}>{t('calendar.select.week')}</MenuItem>
            <MenuItem value={'MONTH'}>{t('calendar.select.month')}</MenuItem>
            <MenuItem value={'LIST'}>{t('calendar.select.list')}</MenuItem>
          </Select>
        </FormControl>
        <NavButton onClick={today}>{t('calendar.today')}</NavButton>
        <Tooltip title={t('calendar.previousDay')}>
          <IconButton onClick={prev} size="small">
            <ChevronLeft />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('calendar.nextDay')}>
          <IconButton onClick={next} size="small">
            <ChevronRight />
          </IconButton>
        </Tooltip>
      </Box>

      <Popover
        open={isPickerOpen}
        id={pickerId}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        elevation={1}
        // transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <DatePicker disableToolbar disabled variant="static" value={date} onChange={handlePick} />
        </MuiPickersUtilsProvider>
      </Popover>
    </div>
  );
}

export default memo(NewCalendarNav, () => true);
