import cn from 'classnames';
import { chevronDownOutline, chevronUpOutline, closeSharp } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IonIcon } from '@ionic/react';
import { IconButton, LinearProgress, makeStyles, WithStyles } from '@material-ui/core';

interface Props {
  onClose?: () => void;
  loading?: boolean;
  children: React.ReactNode;
  expanded?: boolean;
  expandable?: boolean;
  style?: React.CSSProperties;
}

const useClasses = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%',
    overflowY: 'auto',
  },
  modal: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    minHeight: 0,
    maxHeight: '50%',
    position: 'relative',
  },
  modalExpanded: {
    height: '100%',
    maxHeight: 'none',
    borderTopColor: 'transparent',
  },
  windowControls: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    backgroundColor: '#fff',
    position: 'absolute',
    bottom: '100%',
    right: 10,
    borderBottom: 'none',
    '& .MuiIconButton-root': {
      fontSize: 16,
      padding: 5,
    },
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    opacity: 0.5,
    zIndex: 10,
  },
}));

export default function ActionModal({ children, onClose, loading = false, expanded = false, expandable = true, style }: Props) {
  const classes = useClasses();
  const [exp, setExpanded] = useState(expanded);
  const history = useHistory();

  const handleClose = () => {
    history.push({ search: '' });
    onClose?.();
  };
  return (
    <div className={cn([classes.modal, exp && classes.modalExpanded])} style={style}>
      {loading && (
        <>
          <LinearProgress />
          <div className={classes.loading}></div>
        </>
      )}
      <div className={classes.content}>{children}</div>
      <div className={classes.windowControls}>
        {expandable && (
          <IconButton onClick={() => setExpanded(!exp)}>
            <IonIcon icon={exp ? chevronDownOutline : chevronUpOutline} />
          </IconButton>
        )}
        <IconButton onClick={handleClose}>
          <IonIcon icon={closeSharp} />
        </IconButton>
      </div>
    </div>
  );
}
