import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

const useClasses = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
  },
  right: {
    paddingLeft: 10,
    flex: 1,
  },
}));

export default function MailSearchResultFake() {
  const c = useClasses();
  return (
    <div className={c.root}>
      <Skeleton variant="circle" style={{ width: 40, height: 40 }} />
      <div className={c.right}>
        <Skeleton variant="text" style={{ width: '40%', fontSize: 20 }} />
        <Skeleton variant="text" style={{ width: '70%' }} />
        <Skeleton variant="text" style={{ width: '100%' }} />
        <Skeleton variant="text" style={{ width: '40%' }} />
      </div>
    </div>
  );
}
