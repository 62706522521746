import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Calendar } from '../../domain';

/**
 * Repository.
 */
export class CalendarRepo extends AggregateRepo<Calendar> {
  constructor(eventStore: DomainEventStore) {
    super(Calendar, eventStore);
  }
}
// export class CalendarRepo implements AggregateRepoShape {
//   constructor(private readonly eventStore: DomainEventStore) {}
//   /**
//    * Query.
//    */
//   async getDomainEvents(aggregateId: Uuid) {
//     return this.eventStore.getByAggregate(aggregateId);
//   }

//   /**
//    * Query.
//    */
//   async getAggregate(aggregateId: Uuid) {
//     const events = await this.eventStore.getByAggregate(aggregateId);
//     if (!events.length) {
//       throw new Error(`Unknown aggregate id : ${aggregateId}.`);
//     }
//     return new Calendar(events);
//     // return events.length ? new Calendar(events) : null;
//   }
// }
