export type Result<T> = { error?: string | undefined; result?: T | undefined };
export type ActionReturn = Promise<Error>;

/**
 * @note For anything more complicated, e.g. chain, unwrap, etc ..., browse existing libs.
 */
export type ResultEither<TResult, TError extends Error | string | undefined = undefined> = TError extends Error | string
  ? ResultOk<TResult> | ResultError<TError>
  : ResultOk<TResult>;

/**
 *
 */
export class ResultOk<TResult> {
  readonly ok = true;
  constructor(readonly value: TResult) {}
}

/**
 *
 */
export class ResultError<TError extends Error | string> {
  readonly ok = false;
  constructor(readonly error: TError) {}
}



/**
 *
 */
export function encase<TResult, TArgs extends unknown[]>(fn: (...args: TArgs) => TResult) {
  return (...args: TArgs): ResultEither<TResult, Error> => {
    try {
      return new ResultOk(fn(...args));
    } catch (e) {
      let error : Error;

      switch (typeof e) {
        case 'string':
        case 'number':
        case 'boolean':
          error = new Error(`${e}`);
          break;
        case 'function':
          error = new Error(`${e.name || 'anonymous'} ${e.toString()}`);
          break;
        case 'bigint':
        case 'symbol':
          error = new Error(e.toString());
          break;
        case 'undefined':
          error = new Error('undefined');
          break;
        case 'object':
          if (e instanceof Error) {
            error = e;
          } else {
            /** 
             * @note If you want to attempt to JSON.stringify the object, make sure to use
             *       a safe wrapper/replacer that handles circular references, throwing getters, etc ... 
             */
            error = new Error('object');
          }
          break;
      }
      return new ResultError(error);
    }
  };
}
