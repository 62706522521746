import { Box, TextField } from '@material-ui/core';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MailAccountConfigForm } from './WizardContext';

type Props = {
  useForm: UseFormMethods<MailAccountConfigForm>;
};

export default function MailLoginForm({ useForm: { register, errors } }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Box pb={4}>
        <Box pb={2}>
          <TextField
            inputRef={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('login.form.email.invalid'),
              },
            })}
            name="email"
            type="email"
            label={t('wizard.accountConfig.mailAddress')}
            placeholder={t('wizard.accountConfig.mailAddressPlaceholder')}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            autoComplete="nope"
            autoFocus
          />
        </Box>
      </Box>
    </>
  );
}
