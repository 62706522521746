import React from 'react';

import { Button, ButtonProps } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { openWizard, useAppDispatch } from '@focus-front/core';

export function AddAccountButton(props: ButtonProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(openWizard());
  };

  return (
    <Button onClick={handleClick} color="secondary" startIcon={<AddCircle />} {...props}>
      {t('accountSettings.list.add')}
    </Button>
  );
}
