import { addCircle, calendarOutline, chatboxOutline, mailOutline, personOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { draftCalendarEvent, writeNewMail, useAppDispatch, showAttendeesList } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { ButtonBase, List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';

const Button = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: 30,
    height: 34,
    borderRadius: 17,
    paddingLeft: 2,
    paddingRight: 15,
    margin: '0 23px',
    '& span': {
      display: 'block',
      fontSize: 14,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: 8,
    },
  },
}))(ButtonBase);

const useClasses = makeStyles((theme) => ({
  root: {},
  icon: {
    fontSize: 24,
  },
}));

export default function NewButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const history = useHistory();
  const confirm = useConfirm();
  const [newMenuEl, setNewMenuEl] = useState<null | HTMLElement>(null);
  const openNewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNewMenuEl(event.currentTarget);
  };

  const closeNewMenu = () => {
    setNewMenuEl(null);
  };
  return (
    <>
      <Button onClick={openNewMenu} id="tour-5">
        <IonIcon icon={addCircle}></IonIcon>
        <span>{t('header.new')}</span>
      </Button>
      <Popover
        id="new-menu"
        anchorEl={newMenuEl}
        keepMounted
        open={Boolean(newMenuEl)}
        onClose={closeNewMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem
            button
            divider
            onClick={async () => {
              closeNewMenu();
              const { result, error } = await dispatch(writeNewMail());
              if (error) {
                await confirm({
                  title: t('mail.notFoundTitle'),
                  description: error,
                  cancellationButtonProps: { style: { display: 'none' } },
                });
                return;
              }
              if (!result) return;
              history.push(`/work/${result}?action=write`);
            }}
          >
            <ListItemIcon className={classes.icon}>
              <IonIcon icon={mailOutline} />
            </ListItemIcon>
            <ListItemText primary={t('header.newMail')} />
          </ListItem>
          <ListItem
            button
            divider
            onClick={() => {
              closeNewMenu();
              dispatch(showAttendeesList(true));
            }}
          >
            <ListItemIcon className={classes.icon}>
              <IonIcon icon={chatboxOutline} />
            </ListItemIcon>
            <ListItemText primary={t('header.newMessage')} />
          </ListItem>
          <ListItem
            button
            divider
            onClick={() => {
              closeNewMenu();
              console.log("NEW : dispatch(draftCalendarEvent('CALENDAR'));");
              dispatch(draftCalendarEvent('CALENDAR'));
            }}
          >
            <ListItemIcon className={classes.icon}>
              <IonIcon icon={calendarOutline} />
            </ListItemIcon>
            <ListItemText primary={t('header.newEvent')} />
          </ListItem>
          {/* <ListItem
            button
            onClick={() => {
              closeNewMenu();
              createContact();
            }}
          >
            <ListItemIcon className={classes.icon}>
              <IonIcon icon={personOutline} />
            </ListItemIcon>
            <ListItemText primary={t('header.newContact')} />
          </ListItem> */}
        </List>
      </Popover>
    </>
  );
}
