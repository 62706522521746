import { StoreNames } from 'idb';
import { Idb, DBSchema } from './index';

export async function getTableSize(
  tableName: StoreNames<DBSchema>,
  filter: (value: any) => boolean = (value) => true
): Promise<number> {
  let size = 0;
  let cursor = await Idb.transaction(tableName, 'readonly').store.openCursor();

  while (cursor) {
    const storedObject = cursor.value;
    if (filter(storedObject)) {
      const json = JSON.stringify(storedObject);
      size += json.length;
    }

    cursor = await cursor.continue();
  }

  return size;
}

/**
 * Convert bytes to humand readable size
 * @param bytes bytes
 * @returns ex 34,5 MB
 */
export function humanReadableSize(bytes: number): string {
  const thresh = 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}
