import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { reconnectWhatsAppAccount, Uuid, useAppDispatch, reconnectAccountSuccess } from '@focus-front/core';
import { Button, Dialog, DialogContent, InputAdornment, LinearProgress, TextField } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import DialogTitle from '../Common/DialogTitle';

export type ReconnectForm = unknown;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  account_id: Uuid;
}

export default function ReconnectWhatsApp({ isOpen, onClose, account_id }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSubmit = async (values: ReconnectForm) => {
    setError(null);
    setLoading(true);

    const error = await dispatch(reconnectWhatsAppAccount(account_id));
    if (error) {
      setError(error);
      setLoading(false);
    } else {
      setError(null);
      setLoading(false);
      onClose();
      dispatch(reconnectAccountSuccess(account_id));
    }
  };
  const { register, formState, handleSubmit } = useForm<ReconnectForm>({
    mode: 'onChange',
    defaultValues: {},
  });

  return (
    <Dialog open={isOpen}>
      <DialogTitle onClose={onClose}>Se reconnecter</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent style={{ minWidth: '250px' }}>
        {error && <Alert severity="error">{error}</Alert>}
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          disabled={!formState.isValid || loading}
        >
          {t('accountSettings.reconnect.button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
