import { IDBPDatabase } from 'idb';
import { IdentifierType } from '../../../Contact';
import { Uuid } from '../../../Common';
import { DBSchema, Idb } from '../../../Common/infra/services/idb';
import { createViewImThread, IdbViewImThread, UpdateImThreadDTO, ViewImThread } from '../../domain/projections/ViewImThread';
import { ViewImThreadRepo } from '../../domain/projections/ViewImThreadRepo';
import { ViewTagRelationRepo, Taggable, ViewTagRelation, ViewTagRepo } from '../../../Tag';
import { notEmpty } from '../../../Common/utils';

export class IdbViewImThreadRepo implements ViewImThreadRepo {
  constructor(private readonly viewTagRelation: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  static projectionToStored(projection: ViewImThread): IdbViewImThread {
    return {
      ...projection,
      attendees_identifiers: projection.attendees
        .filter((att) => att.identifier !== 'YOU')
        .map((att) => att.identifier_type + att.identifier),
    };
  }

  static storedToProjection({ attendees_identifiers, ...projection }: IdbViewImThread): ViewImThread {
    return projection;
  }

  async add(projection: ViewImThread) {
    return Idb.put('viewImThreads', IdbViewImThreadRepo.projectionToStored(projection));
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateImThreadDTO) {
    const tx = Idb.transaction('viewImThreads', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewImThread ${id} does not exist.`);
    }

    /** @note await should be unnecessary here. */
    tx.store.put(IdbViewImThreadRepo.projectionToStored(createViewImThread(id, { ...old_projection, ...values })));
    return tx.done;

    // const old_projection = await this.get(id);
    // if (!old_projection) {
    //   throw new Error(`Invalid id : ViewImThread ${id} does not exist.`);
    // }
    // return Idb.put('viewImThreads', createViewImThread(id, { ...old_projection, ...values }));
  }

  /**
   *
   */
  async remove(id: Uuid) {
    return Idb.delete('viewImThreads', id);
  }

  async removeByAccount(account_id: Uuid) {
    let cursor = await Idb.transaction('viewImThreads', 'readwrite')
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  /**
   *
   */
  async clear() {
    return Idb.clear('viewImThreads');
  }

  /**
   * Query.
   */
  async get(id: Uuid): Promise<(ViewImThread & Taggable) | null> {
    const thread = await Idb.get('viewImThreads', id);
    if (!thread) return null;
    const tags = await this.viewTagRelation.getTagsByElement('IM_THREAD', id);
    return { ...IdbViewImThreadRepo.storedToProjection(thread), tags };
  }

  /**
   * Query.
   */
  async getAll(): Promise<ViewImThread[]> {
    const threads = await Idb.getAll('viewImThreads');
    return threads.map(IdbViewImThreadRepo.storedToProjection);
  }

  /**
   * Query.
   */
  async getAllByDate(size: number, offset: number): Promise<(ViewImThread & Taggable)[]> {
    let cursor = await Idb.transaction('viewImThreads', 'readonly').store.index('by-date').openCursor(undefined, 'prev');
    const results: IdbViewImThread[] = [];
    let hasSkipped = false;

    while (results.length < size && cursor) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        cursor = (await cursor?.advance(offset)) ?? null;
      }
      if (!cursor) break;
      results.push(cursor.value);
      cursor = await cursor.continue();
    }

    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    for (let i = 0, length = results.length; i < length; ++i) {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['IM_THREAD', results[i].id]));
    }

    const allTags = await this.viewTagRepo.getAll();

    const threadsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...IdbViewImThreadRepo.storedToProjection(results[i]), tags };
      })
    );

    return threadsWithTags;
  }

  /**
   * Query.
   */
  // async getByAccount(account_id: Uuid) {
  //   return Idb.getAllFromIndex('viewImThreads', 'by-account', IDBKeyRange.only(account_id));
  // }

  /**
   * Query.
   */
  async getByProviderId(account_id: Uuid, provider_thread_id: string): Promise<ViewImThread | null> {
    const tx = Idb.transaction('viewImThreads', 'readonly');
    const thread = await tx.store.index('by-account-and-provider-id').get(IDBKeyRange.only([account_id, provider_thread_id]));
    if (!thread) return null;
    return IdbViewImThreadRepo.storedToProjection(thread);
  }

  /**
   * Query.
   */
  async getByAttendeeIdentifier(identifier_type: IdentifierType, identifier: string): Promise<ViewImThread[]> {
    const results = await Idb.getAllFromIndex(
      'viewImThreads',
      'by-attendee-identifier',
      IDBKeyRange.only(identifier_type + identifier)
    );
    return results.map(IdbViewImThreadRepo.storedToProjection);
  }
}
