import React, { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DEFAULT_EVENT_INTERVAL,
  deleteNotification,
  displayAttendeeName,
  doneNotification,
  eventCompleteDone,
  notificationsSelectedSelector,
  NotificationVm,
  pushInboxSnackbar,
  sessionSelector,
  setSideTab,
  snoozeNotification,
  Taggable,
  toggleSelectNotification,
  useAppDispatch,
  UTCDateTimeMs,
} from '@focus-front/core';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  alpha,
  makeStyles,
  Tooltip,
  Typography,
  Checkbox,
  Theme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  Action,
  CalendarEventDefaultIcon,
  GroupeThreadIcon,
  MailDraftIcon,
  NotificationDefaultIcon,
  NotificationIcon,
  useActionBar,
} from '@focus-front/ui';
import dayjs from 'dayjs';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import relativeTime from 'dayjs/plugin/relativeTime';
import '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';
import { useSelector } from 'react-redux';
import AttendeeAvatarPopover from '../Contact/AttendeeAvatarPopover';
import { useInterval } from 'react-use';

import SnoozeSvg from '../../assets/snooze.svg';
import Tags from '../Tag/Tags';

dayjs.extend(relativeTime);

interface Props {
  notification: NotificationVm & Taggable;
  selectMode: boolean;
}

export const useClasses = makeStyles<Theme, { notification: { selected: boolean }; selectMode: boolean }>((theme) => ({
  icon: {
    position: 'absolute',
    right: 0,
    top: 33,
  },
  container: {
    backgroundColor: ({ notification }) =>
      notification.selected ? alpha(theme.palette.secondary.light, 0.3) : alpha(theme.palette.background.paper, 1),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    paddingLeft: ({ selectMode }) => (selectMode ? theme.spacing(1) + 2 : 0),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'stretch',
    // marginBottom: theme.spacing(1),
    border: 'solid 1px transparent',
    '&:hover $actions': {
      opacity: 1,
    },
    '&:hover $tags': {
      opacity: 0,
    },
    '&:hover': {
      boxShadow: ({ selectMode }) => !selectMode && theme.shadows[2],
      borderColor: ({ selectMode }) => (selectMode ? theme.palette.secondary.main : theme.palette.divider),
    },
    '&:hover $col0': {
      opacity: 1,
    },
    position: 'relative',
    transition: 'padding .2s ease',
  },
  tags: {},
  col0: {
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
  },
  col1: {
    position: 'relative',
    marginRight: '10px',
    '& > div:first-child': {
      margin: '0 6px 6px 0',
    },
  },
  col2: {
    flex: 1,
    overflow: 'hidden',
    alignSelf: 'center',
  },
  col3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  actions: {
    opacity: 0,
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  date: {
    fontSize: 12,
    opacity: 0.5,
  },
  description: {
    color: theme.palette.grey[500],
    fontSize: 14,
    paddingTop: 2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  title: {
    color: theme.palette.text.primary,
  },
  sender: {
    lineHeight: '28px',
  },
  action: {
    backgroundColor: theme.palette.background.default,
    borderRight: 'none!important',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
    },
    '& ion-icon': {
      fontSize: 19,
    },
  },
  snooze: {
    marginLeft: theme.spacing(1),
  },
  dot: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: 1,
    borderRadius: 10,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/**
 * Display and update every minute the relative time of the notification
 */
function RelativeTime({ time }: { time: UTCDateTimeMs }) {
  const [val, setVal] = useState(dayjs(time).fromNow());
  useInterval(() => {
    setVal(dayjs(time).fromNow());
  }, 1000 * 60);

  useEffect(() => {
    setVal(dayjs(time).fromNow());
  }, [time]);
  return <>{val}</>;
}

function NotificationCard({ notification, selectMode }: Props & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();
  const history = useHistory();
  const session = useSelector(sessionSelector);
  const c = useClasses({ notification, selectMode });
  const { actions } = useActionBar(
    'NOTIFICATION',
    notification?.metadata?.attached_entity?.type,
    notification?.type,
    notification?.metadata?.origin_event?.origin_notification?.type
  );
  const dispatch = useAppDispatch();

  const onSnooze = (e) => {
    e.stopPropagation();
    dispatch(snoozeNotification(notification));
    dispatch(pushInboxSnackbar(t('notification.snoozeMessage', { title: notification.content })));
  };

  const onAction = async (action: Action) => {
    // prettyPrint({ actionId: action.id, actionLabel: action.label, notification }, 'NotificationCard onAction', 'warn');
    if (action.id === 'done') {
      const error = await dispatch(doneNotification(notification));
      if (notification.metadata?.origin_event) {
        dispatch(eventCompleteDone(notification.metadata.origin_event.id));
      }
      if (error) return alert('onAction : ' + error);
      dispatch(deleteNotification(notification.id));
      dispatch(pushInboxSnackbar(t('notification.doneMessage')));
    } else {
      history.push(`/inbox/${notification.id}?action=${action.id}`);
    }
  };

  // Initialise the draggable property
  const el = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let drag: Draggable;
    if (el?.current && !selectMode) {
      drag = new Draggable(el.current, {
        eventData: {
          title:
            notification.type === 'EVENT_MISSED'
              ? notification.content
              : notification.attendees?.length
              ? t(`notification.plan.defaultTitle.${notification.type.toLowerCase()}`) +
                ' ' +
                displayAttendeeName(notification.attendees[0])
              : '',
          sourceId: notification.id,
          notification,
          duration: {
            minutes: DEFAULT_EVENT_INTERVAL,
          },
        },
      });
      drag.dragging.emitter.on('dragstart', () => {
        dispatch(setSideTab('calendar'));
      });
    }

    return () => {
      drag?.destroy();
    };
  }, [notification, selectMode]);

  const avatar = useMemo(() => {
    if (notification.attendees?.[0]?.identifier_type === 'GROUP_THREAD') return <GroupeThreadIcon size={60} />;
    if (notification.attendees?.length) return <AttendeeAvatarPopover attendee={notification.attendees[0]} />;
    if (
      notification.type === 'EVENT_MISSED' &&
      notification.metadata?.attached_entity?.type === 'MAIL_DRAFT' &&
      !notification.attendees?.length
    )
      return <MailDraftIcon size={60} />;
    if (notification.type === 'EVENT_MISSED' && !notification.metadata?.attached_entity)
      return <CalendarEventDefaultIcon size={60} />;
    return <NotificationDefaultIcon size={60} />;
  }, [notification.attendees, notification.type, notification.metadata]);

  const sender = useMemo(() => {
    if (notification.attendees?.length && notification.type !== 'EVENT_MISSED')
      return notification.attendees[0].identifier === 'YOU'
        ? session.firstname + ' ' + session.lastname
        : displayAttendeeName(notification.attendees[0]);
    /** @todo Consider providing default values for other notification.type. */
    return notification.title || (notification.type === 'EVENT_MISSED' ? t('calendar.form.defaultTitle') : '');
  }, [notification.attendees, notification.title]);

  const title = useMemo(() => {
    if (notification.type === 'EVENT_MISSED') return t('notification.type.event_missed');
    return notification.title;
  }, [notification.type, notification.title]);

  const onClick = useCallback(() => {
    if (!notification.loading) {
      if (selectMode) dispatch(toggleSelectNotification(notification.id));
      else history.push('/inbox/' + notification.id);
    }
  }, [selectMode, notification.id]);

  return (
    <div className={c.container} ref={el} onClick={onClick}>
      {notification.loading && (
        <div className={c.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={c.col0} style={selectMode ? { opacity: 1 } : undefined}>
        <Checkbox
          checked={notification.selected}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(toggleSelectNotification(notification.id));
          }}
          color="default"
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
      <div className={c.col1} style={{ position: 'relative' }}>
        {avatar}
        <NotificationIcon notification={notification} className={c.icon} size={30} />
      </div>
      <div className={c.col2}>
        <Typography variant="h4" className={c.sender}>
          {sender}
        </Typography>
        <div className={c.description}>
          <span className={c.title}>{title}</span>
          {notification.content && <span> • {notification.content}</span>}
        </div>
      </div>
      <div className={c.col3}>
        <div className={c.date}>
          <RelativeTime time={notification.received_date} />
        </div>
        <div className={c.tags}>
          <Tags tags={notification.tags} />
        </div>

        {!notification.loading && (
          <div className={c.actions}>
            <ButtonGroup variant="contained" disableElevation>
              {actions.map((action, index) => (
                <Tooltip title={action.label} key={index}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      onAction(action);
                    }}
                    className={c.action}
                  >
                    {action.icon}
                  </Button>
                </Tooltip>
              ))}
            </ButtonGroup>
            <ButtonGroup variant="contained" disableElevation className={c.snooze}>
              <Tooltip title={t('notification.action.snooze')}>
                <Button className={c.action} onClick={onSnooze}>
                  <IonIcon icon={SnoozeSvg} />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(
  NotificationCard,
  (prev, next) =>
    prev.notification.id === next.notification.id &&
    prev.notification.content === next.notification.content &&
    prev.notification.selected === next.notification.selected &&
    prev.notification.loading === next.notification.loading &&
    prev.selectMode === next.selectMode
);
