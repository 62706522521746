import React, { useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { listOutline } from 'ionicons/icons';
import TipTapUnipile from '../Common/TipTapUnipile/TipTapUnipile';
import { useTranslation } from 'react-i18next';
import {
  getRecurringRoot,
  updateException,
  updateRecurringCalendarEvent,
  updateSingleCalendarEvent,
  useAppDispatch,
  ViewCalendarEvent,
} from '@focus-front/core';
import parse from 'html-react-parser';
import { useConfirm } from 'material-ui-confirm';

interface EventDescriptionProps {
  className: string;
  event: ViewCalendarEvent;
}

const useClasses = makeStyles((theme) => ({
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  editorContainer: {
    maxWidth: '800px',
  },
}));

const EventDescription: React.FC<EventDescriptionProps> = ({ className, event }) => {
  const classes = useClasses();
  const { t } = useTranslation();

  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string | null>(event.description);
  const [isSending, setIsSending] = useState(false);
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  //UseEffect for the value
  useEffect(() => {
    setDescriptionValue(event.description);
  }, [event]);

  const onSubmit = async () => {
    setIsSending(true);
    //Single
    if (event.kind === 'SINGLE') {
      await dispatch(
        updateSingleCalendarEvent(
          event.id,
          event.type === 'GOOGLE'
            ? {
                kind: event.kind,
                type: event.type,
                description: descriptionValue,
              }
            : {
                kind: event.kind,
                type: event.type,
                description: descriptionValue,
              }
        )
      );
    } else {
      let deleteRadioButtonValue = 'SINGLE';
      await confirm({
        title: t('calendar.form.editAlertRecurrent'),
        description: (
          <FormControl>
            <RadioGroup onChange={(e) => (deleteRadioButtonValue = e.target.value)} defaultValue="SINGLE">
              <FormControlLabel value="SINGLE" control={<Radio />} label={t('calendar.form.confirmAlertSingleOption')} />
              <FormControlLabel value="ALL" control={<Radio />} label={t('calendar.form.confirmAlertAllOption')} />
            </RadioGroup>
          </FormControl>
        ),
        confirmationText: t('calendar.form.saveButton'),
        cancellationText: t('calendar.form.cancelButton'),
      });
      if (deleteRadioButtonValue === 'ALL') {
        const recurringEventRoot = await dispatch(getRecurringRoot(event.recurring_event_id));
        await dispatch(
          updateRecurringCalendarEvent(
            event.recurring_event_id,
            event.type === 'GOOGLE'
              ? {
                  kind: 'RECURRING',
                  type: event.type,
                  recurrence: recurringEventRoot.kind === 'RECURRING' && recurringEventRoot.recurrence,
                  description: descriptionValue,
                }
              : {
                  kind: 'RECURRING',
                  type: event.type,
                  recurrence: recurringEventRoot.kind === 'RECURRING' && recurringEventRoot.recurrence,
                  description: descriptionValue,
                }
          )
        );
      } else {
        await dispatch(
          updateException(
            event.id,
            event.type === 'GOOGLE'
              ? {
                  type: event.type,
                  description: descriptionValue,
                }
              : {
                  type: event.type,
                  description: descriptionValue,
                }
          )
        );
      }
    }

    setIsSending(false);
    setOpenEditor(false);
  };

  return (
    <div className={className}>
      <IonIcon icon={listOutline} />
      {!openEditor ? (
        <Typography style={{ cursor: 'pointer' }} onClick={() => setOpenEditor(true)} variant="body2">
          {parse(descriptionValue)}
        </Typography>
      ) : (
        <div className={classes.editorContainer}>
          <TipTapUnipile
            initialContent={descriptionValue}
            onChange={(stringValue) => setDescriptionValue(stringValue)}
            lowerToolbar={
              <div className={classes.actions}>
                <Button variant="contained" color="primary" onClick={() => setOpenEditor(false)}>
                  {t('event.form.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={isSending && <CircularProgress size={24} />}
                  disabled={isSending}
                  onClick={onSubmit}
                >
                  {!isSending && t('event.form.confirm')}
                </Button>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default EventDescription;
