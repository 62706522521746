import { getAllTags, mockUuid, randomEnum, TagColor, useAppDispatch, ViewTag } from '@focus-front/core';
import { Button, InputBase, Popper } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePickerClasses } from '../Common/usePickerClasses';
import TagCreationDialog from './TagCreationDialog';

type Props = {
  anchorEl: Element;
  handleClose: (tags: ViewTag[]) => void;
  defaultValue: ViewTag[];
  creatable?: boolean;
};

type TagOption = ViewTag & { inputValue?: string };

const filter = createFilterOptions<TagOption>();

export default function TagPicker({ anchorEl, handleClose, defaultValue, creatable = true }: Props) {
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const classes = usePickerClasses();
  const { t } = useTranslation();

  const [tags, setTags] = useState<TagOption[]>([]);
  const [pendingValue, setPendingValue] = useState<TagOption[]>([]);
  const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
  const [dialogValue, setDialogValue] = useState({
    label: '',
    color: randomEnum(TagColor),
  });

  useEffect(() => {
    setPendingValue(defaultValue);
  }, [defaultValue]);

  const onDialogClose = () => {
    setShowNewDialog(false);
    setDialogValue({
      label: '',
      color: randomEnum(TagColor),
    });
  };

  const onAutocompleteClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    // if (anchorEl) {
    //   anchorEl.focus();
    // }

    handleClose(pendingValue);
  };

  const onTagCreate = (tag: ViewTag) => {
    setShowNewDialog(false);
    setTags([...tags, tag]);
    const update = [...pendingValue, tag];
    setPendingValue(update);
    handleClose(update);
  };

  useEffect(() => {
    (async () => {
      const { result, error } = await dispatch(getAllTags());
      setTags(result);
    })();
  }, [open]);

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom" className={classes.popper}>
        <Autocomplete
          open={true}
          onClose={onAutocompleteClose}
          multiple
          autoHighlight
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={(event: SyntheticEvent, newValue: TagOption[], reason) => {
            if (reason === 'remove-option' && event.type === 'keydown') return;
            const has_created = newValue.find((val) => {
              if (typeof val !== 'string') return val.id === 'CREATE';
              else return false;
            });
            if (has_created) {
              setDialogValue({
                ...dialogValue,
                label: has_created.inputValue,
              });
              setShowNewDialog(true);
            } else setPendingValue(newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value

            if (creatable)
              filtered.push({
                id: mockUuid('CREATE'),
                label: params.inputValue ? t('tag.picker.createX', { input: params.inputValue }) : t('tag.picker.create'),
                inputValue: params.inputValue,
                color: TagColor.BLUE,
                last_assign_date: null,
              });

            return filtered;
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText={t('tag.picker.noResult')}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Done className={classes.iconSelected} style={{ visibility: selected ? 'visible' : 'hidden' }} />
              <span
                className={classes.color}
                style={{ backgroundColor: option.color, visibility: option.id === 'CREATE' ? 'hidden' : 'visible' }}
              />
              <div className={classes.text} style={{ fontWeight: option.id === 'CREATE' ? 700 : 'inherit' }}>
                {option.label}
              </div>
              <Close className={classes.close} style={{ visibility: selected ? 'visible' : 'hidden' }} />
            </React.Fragment>
          )}
          options={tags}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <InputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus className={classes.inputBase} />
          )}
        />
        <div className={classes.footer}>
          <Button fullWidth color="secondary" size="small" onClick={() => handleClose(pendingValue)}>
            Apply
          </Button>
        </div>
      </Popper>
      <TagCreationDialog open={showNewDialog} onClose={onDialogClose} defaultValue={dialogValue} onCreate={onTagCreate} />
    </>
  );
}
