import { ViewTagRepo } from '../projections/ViewTagRepo';
import { createProjector, toUTCDateTimeMs } from '../../../Common';
import { createViewTagRelation } from '../projections/ViewTagRelation';
import { ViewTagRelationRepo } from '../projections/ViewTagRelationRepo';
import dayjs from 'dayjs';

/**
 *
 */
interface Repos {
  viewTagRelation: ViewTagRelationRepo;
  viewTag: ViewTagRepo;
}

/**
 * Handler/Projector.
 */
export const viewTagsRelationsProjector = createProjector('viewTagsRelationsProjector', {
  TAG_RELATION_CREATED: async ({ aggregateId, tag }, { viewTagRelation, viewTag }: Repos) => {
    await viewTag.update(tag.tag_id, { last_assign_date: toUTCDateTimeMs(dayjs()) });
    return viewTagRelation.add(createViewTagRelation(aggregateId, tag));
  },
  TAG_RELATION_RESTORED: async ({ aggregateId, tag }, { viewTagRelation, viewTag }: Repos) => {
    await viewTag.update(tag.tag_id, { last_assign_date: toUTCDateTimeMs(dayjs()) });
    return viewTagRelation.add(createViewTagRelation(aggregateId, tag));
  },
  TAG_RELATION_DELETED: async ({ aggregateId }, { viewTagRelation }: Repos) => {
    return viewTagRelation.delete(aggregateId);
  },
});
