import { Attendee, displayAttendeeName, ViewContact } from '@focus-front/core';
import React from 'react';
import AttendeeAvatar from './AttendeeAvatar';
import AttendeePopover from './AttendeePopover';
import ContactPopover from './ContactPopover';
import { useContact } from './useContact';
import usePopover from './usePopover';

type Props = {
  attendee: Attendee;
};

export default function AttendeeAvatarPopover({ attendee }: Props) {
  const { anchorEl, onClose, onOpen } = usePopover();
  /**
   * @todo contact must load on popover open, not on avatar mount
   */
  const contact = useContact(attendee);
  return (
    <>
      {contact ? (
        <ContactPopover
          open={!!anchorEl}
          onClose={onClose}
          // For Notification cards
          onClick={(e) => e.stopPropagation()}
          // onBackdropClick={(e) => e.stopPropagation()}
          contact={contact}
          id={'popover-' + attendee.identifier}
          anchorEl={anchorEl}
        />
      ) : (
        <AttendeePopover
          open={!!anchorEl}
          onClose={onClose}
          // For Notification cards
          onClick={(e) => e.stopPropagation()}
          // onBackdropClick={(e) => e.stopPropagation()}
          attendee={attendee}
          id={'popover-' + attendee.identifier}
          anchorEl={anchorEl}
        />
      )}
      <AttendeeAvatar
        onClick={(e) => {
          onOpen(e);
          e.stopPropagation();
        }}
        aria-describedby={'popover-' + attendee.identifier}
        display_name={contact?.full_name || displayAttendeeName(attendee)}
        avatar_url={contact?.profile_picture || attendee.profile_picture}
      />
    </>
  );
}
