import { InvalidCredentialsError, MissingCredentialsError, ProxyConfig, ProxyServerError } from '@focus-front/proxy';
import jwtDecode from 'jwt-decode';
import { OAuthCredentials } from '../../../../Account';
import { Uuid } from '../../..';
import { isObject } from '../../../utils';
import { Base64 } from '../../../utils/Base64';
import { GoogleAuthenticationProvider } from './GoogleAuthenticationProvider';

/**
 *
 */
interface DecodedJwt {
  exp: number;
  iat: number;
}

/**
 *
 */
interface GcalDecodedJwt extends DecodedJwt {
  google_email: string;
  google_access_token: unknown;
  google_refresh_token: string;
}

/**
 * GoogleWebAuthenticationProvider uses proximap to get and refresh Google's access_token.
 *
 * @todo Clean up / refactor. This was extracted 'as is' from GcalApiClient to allow
 *       injecting different providers depending on the platform; there's room for
 *       improvement and 'historical stuff' that can be simplified.
 */
export class GoogleWebAuthenticationProvider implements GoogleAuthenticationProvider {
  private access_token: Base64 | null = null;
  private refresh_token: Base64 | null = null;
  private jwt: string | null = null;

  constructor(
    protected config: ProxyConfig,
    private getCredentials?: (account_id: Uuid) => Promise<OAuthCredentials | null>,
    public account_id?: Uuid
  ) {}

  /**
   *
   */
  private async _authenticate(): Promise<[Base64, Base64]> {
    let credentials: OAuthCredentials | null = null;

    // Get account credentials with function passed to creator
    try {
      if (this.getCredentials && this.account_id) {
        credentials = await this.getCredentials(this.account_id);
      }
    } catch (e) {
      throw new InvalidCredentialsError();
    }

    if (!credentials) {
      throw new MissingCredentialsError();
    }

    return [btoa(credentials.at), btoa(credentials.rt)] as [Base64, Base64];
  }

  /**
   *
   */
  private async _refreshAccessToken(access_token: Base64, refresh_token: Base64): Promise<Base64> {
    if (this._isJwtExpired()) {
      this.jwt = await this._getProximapJwt();
    }

    const response = await fetch(`${this.config.host}/api/google/calendar/refresh`, {
      headers: {
        'X-AT': access_token,
        'X-RT': refresh_token,
        Authorization: `Bearer ${this.jwt}`,
      },
      //   credentials: 'include',
    });

    try {
      const data = (await response.json()) as unknown;

      if (!response.ok || !isObject(data) || typeof data.access_token !== 'string') {
        throw new ProxyServerError(JSON.stringify(data));
      }

      return data.access_token as Base64;
    } catch (e) {
      // For 500 Errors, response is in HTML, therefore response.json() fail.
      throw new ProxyServerError(response.statusText);
    }
  }

  /**
   * @note Proximap is still required to refresh google's access_token token.
   *
   *       For historical reasons, access_token is a Base64 encoded json string which itself contains
   *       google's access_token, refresh_token, etc ...
   *
   * @todo Consider holding on this.access_token in another form.
   */
  private _extractGoogleAccessToken(access_token: Base64): string | null {
    const parsed = JSON.parse(atob(access_token));
    return isObject(parsed) && typeof parsed.access_token === 'string' ? parsed.access_token : null;
  }

  /**
   *
   */
  private _isAccesTokenExpiredOrInvalid(access_token: Base64): boolean {
    try {
      const parsed = JSON.parse(atob(access_token));

      if (!isObject(parsed) || typeof parsed.expires_in !== 'number') {
        return true;
      }

      let created = typeof parsed.created === 'number' ? parsed.created : null;

      /** If created is missing, try to recover iat on id_token. */
      if (!created && typeof parsed.id_token === 'string') {
        const parts = parsed.id_token.split('.');

        if (parts.length === 2) {
          const payload = JSON.parse(atob(parts[1]));
          if (isObject(payload) && typeof payload.iat === 'number') {
            created = payload.iat;
          }
        }
      }
      /** Do we have a token at all ? Is it set to expire in the next 30 seconds ? */
      return created === null || (created + (parsed.expires_in - 30)) * 1000 < Date.now();
    } catch (e) {
      /** @note Be consistent : either throw on all malformed token cases or always return true. */
      return true;
      //   throw new InvalidCredentialsError("DirectGcalApiService._isAccesTokenExpired couldn't parse given access_token.");
    }
  }

  /**
   * Verify if proximap jwt is valid.
   */
  private _isJwtExpired(): boolean {
    return !this.jwt || jwtDecode<DecodedJwt>(this.jwt).exp < Math.floor(Date.now() / 1000);
  }

  /**
   * Authenticate with Google : exchange an auth code with a refresh token and access token used as credentials for api requests.
   * This must be done for the first login.
   * @param auth_code Code used to retrieve the credentials from the server.
   *                  This code is retrieved from the client Google Sign In prompt with offline mode and code response settings
   */
  async authenticateWithCode(auth_code: string): Promise<{ email: string; access_token: string; refresh_token: string }> {
    const response = await fetch(`${this.config.host}/api/login_check`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        username: this.config.username,
        password: this.config.password,
        google_access_code: auth_code,
        /** @todo Make scopes parametric. */
        google_scope: ['calendar'],
        redirect_uri: btoa(window.location.origin),
      }),
    });
    const data = await response.json();
    const decoded = jwtDecode<GcalDecodedJwt>(data.token);

    // console.log('authenticateWithCode', data, decoded);

    return {
      email: decoded.google_email,
      access_token: JSON.stringify(decoded.google_access_token),
      refresh_token: decoded.google_refresh_token,
    };
  }

  /**
   *
   */
  invalidateCachedTokens(): void {
    this.access_token = null;
    this.refresh_token = null;
  }
  
  /**
   *
   */
  async getAccessToken(): Promise<string | null> {
    if (!this.access_token || !this.refresh_token) {
      [this.access_token, this.refresh_token] = await this._authenticate();
    }

    if (this._isAccesTokenExpiredOrInvalid(this.access_token)) {
      this.access_token = await this._refreshAccessToken(this.access_token, this.refresh_token);
    }

    /**
     * @todo Cache and update google_access_token along with this.access_token.
     */
    return this._extractGoogleAccessToken(this.access_token);
  }

  /**
   * @note That's 1 generic proximap JWT per account to manage ? Do they need to
   *       be isolated ?
   */
  private async _getProximapJwt(): Promise<string> {
    const response = await fetch(`${this.config.host}/api/login_check`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        username: this.config.username,
        password: this.config.password,
      }),
    });
    const data = await response.json();
    return data.token;
  }
}
