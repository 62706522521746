import { Tabs, withStyles } from '@material-ui/core';

const LightTabs = withStyles((theme) => ({
  indicator: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 4,
  },
  root: {
    flexShrink: 0,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}))(Tabs);

export default LightTabs;
