import { accountSourcesSelector, AccountType, Uuid } from '@focus-front/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AccountSourceCard from './AccountSourceCard';

type Props = {
  account_id: Uuid;
  account_type: AccountType;
};
export default function AccountPageSources({ account_id, account_type }: Props) {
  const sources = useSelector(accountSourcesSelector(account_id)) || [];
  return (
    <>
      {sources.map((source, index) => (
        <AccountSourceCard source={source} account_type={account_type} key={index} />
      ))}
    </>
  );
}
