import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getMailDraftAttachmentData,
  getMailDraftView,
  MailAttachment,
  sessionSelector,
  Uuid,
  ViewMailDraft,
  useAppDispatch,
} from '@focus-front/core';
import { Attachment } from '@focus-front/ui';
import { IconButton, makeStyles, Typography } from '@material-ui/core';

import SearchActionsBar from '../ActionBar/SearchActionsBar';
import { IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import AttendeeAvatar from '../Contact/AttendeeAvatar';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      marginTop: 13,
    },
  },
  content: {
    overflowY: 'scroll',
  },
  subheader: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  contactContainer2: {
    marginLeft: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(2),
  },
  attachments: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

interface Props {
  mail_id: Uuid;
  reload?: number;
  onBack: () => void;
}

export default function SearchMailDraftView({ mail_id, reload, onBack }: Props) {
  const [mail, setMail] = useState<ViewMailDraft>(null);
  const [attachments, setAttachments] = useState<MailAttachment[]>([]);
  const session = useSelector(sessionSelector);
  const [attLoading, setAttLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const bodyRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      const mail = await dispatch(getMailDraftView(mail_id));
      setMail(mail);
      const attachments_with_data = await Promise.all(
        mail.attachment_ids.map((attachment_id) => dispatch(getMailDraftAttachmentData(attachment_id)))
      );
      setAttachments(attachments_with_data.filter((attachment) => attachment));
      setAttLoading(false);
    })();
  }, [mail_id, reload]);

  useEffect(() => {
    if (bodyRef && bodyRef.current) {
      if (!bodyRef.current.shadowRoot) bodyRef.current.attachShadow({ mode: 'open' });

      bodyRef.current.shadowRoot.innerHTML = `<div id="mail-body" style="display: table; width: 100%">${mail.body || ''}</div>`;
    }
  }, [mail]);

  if (!mail) return <div></div>;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <IconButton onClick={onBack}>
          <IonIcon icon={arrowBack} />
        </IconButton>
        <Typography variant="h1">{mail.subject || t('mailForm.noSubject')}</Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.subheader}>
          <div className={classes.contactContainer}>
            <AttendeeAvatar display_name={session.firstname} avatar_url={session.profile_picture} />
            <div className={classes.contactContainer2}>
              <Typography variant="h4">{session.firstname + ' ' + session.lastname}</Typography>
              <Typography variant="body2">
                <strong>{t('mailForm.to')} :</strong> {mail.to_attendees?.map((att) => att.display_name).join(', ')}
              </Typography>
            </div>
          </div>

          <div className={classes.details}>
            <Typography variant="body2" className={classes.detailText}>
              {dayjs(mail.update_date).format('DD MMM YYYY [à] HH:mm')}
            </Typography>
            {/* <AccountLabel account_id={mail.account_id} className={classes.detailText} /> */}
          </div>
        </div>

        {!mail.parent_mail_id && <SearchActionsBar entity_id={mail_id} entity_type="MAIL_DRAFT" />}
        <div ref={bodyRef} className={classes.body}></div>
        {!!attachments?.length && (
          <div className={classes.attachments}>
            {attachments.map((attachment) => (
              <Attachment key={attachment.id} attachment={attachment} loaded={!attLoading} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
