import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { sessionSelector } from '@focus-front/core';
import OnboardingTutorial from './OnboardingTutorial';

export default function OnboardingPage() {
  const session = useSelector(sessionSelector);

  // Redirect to login if not connected
  if (!session) return <Redirect to="/login" />;

  // Redirect to home if onboarding already done
  if (session.params.onboarding) return <Redirect to="/" />;

  return <OnboardingTutorial />;
}
