import { InvalidCredentialsError, ProxyServerError } from '@focus-front/proxy';
import {
  CalendarEventListChangesQuery,
  CalendarEventListQuery,
  CalendarEventListResult,
  CalendarListChangesQuery,
  CalendarListQuery,
  CalendarListResult,
} from './CalendarService';
import {
  isGcalCalendarEventListResponse,
  isGcalCalendarListResponse,
  parseGcalCalendarItems,
  parseGcalEventItems,
} from './GcalApiTypes';
import { GoogleAuthenticationProvider } from '../../../Common/infra/services/google/GoogleAuthenticationProvider';

/**
 * Sometimes sync tokens are invalidated by the server, for various reasons including
 * token expiration or changes in related ACLs. In such cases, the server will respond
 * to an incremental request with a response code 410. This should trigger a full wipe
 * of the client’s store and a new full sync.
 *
 * @see https://developers.google.com/calendar/api/guides/sync#full_sync_required_by_server
 */
export class GoogleSyncTokenInvalidatedError extends Error {
  constructor() {
    super(`Google syncToken has been invalidated, full sync required.`);
    this.name = 'GoogleSyncTokenInvalidatedError';
  }
}

/**
 * DirectGcalApiService use proximap to get and refresh Google's access_token
 * but talks directly to Google Calendar API for other requests.
 */
export class GcalApiClient {
  constructor(private readonly authProvider: GoogleAuthenticationProvider) {}

  /**
   *
   */
  private async _fetch(url: string, options?: RequestInit) {
    const google_access_token = await this.authProvider.getAccessToken();
    if (!google_access_token) {
      throw new InvalidCredentialsError();
    }

    return fetch('https://www.googleapis.com/calendar/v3' + url, {
      headers: {
        Authorization: `Bearer ${google_access_token}`,
        Accept: 'application/json',
      },
      ...options,
    });
  }

  /**
   * https://www.googleapis.com/calendar/v3/users/me/calendarList?pageToken=qsdqsd&syncToken=qsfqsf
   */
  private async _listCalendars(query?: CalendarListQuery | CalendarListChangesQuery): Promise<CalendarListResult> {
    const response = await this._fetch(
      `/users/me/calendarList${
        query
          ? '?' +
            new URLSearchParams({
              ...('sync_token' in query && { syncToken: query.sync_token }),
              ...(query.page_token && { pageToken: query.page_token }),
            }).toString()
          : ''
      }`,
      {
        method: 'GET',
      }
    );

    /**
     * If the syncToken expires, the server will respond with a 410 GONE response code and the
     * client should clear its storage and perform a full synchronization without any syncToken.
     *
     * @note Consider that the way page_token works, you can get a 410 GONE response to mean
     *       the sync_token has been invalidated by Gcal API when no sync_token has been supplied
     *       but the currently used page_token is used to paginate through a previous request that
     *       used a sync_token.
     */
    if (!response.ok) {
      switch (response.status) {
        case 401:
          this.authProvider.invalidateCachedTokens();
          throw new InvalidCredentialsError();
        case 410:
          if (query && 'sync_token' in query) {
            throw new GoogleSyncTokenInvalidatedError();
          }
        //eslint : intentional fallthrough
        default:
          throw new Error(`${response.status} : ${response.statusText}`);
      }
    }

    const result = (await response.json()) as unknown;

    if (isGcalCalendarListResponse(result)) {
      /** @todo Consider what to do about invalid calendars ? Discard ? Fix with default values ? Log ? */
      return {
        ...parseGcalCalendarItems(result.items),
        sync_token: result.nextSyncToken ?? null,
        page_token: result.nextPageToken ?? null,
      };
    }
    /** @todo Consider a more specific error type ? Dump/log response as well ? */
    throw new Error('Invalid GcalCalendarListResponse received.');
  }

  /**
   *
   */
  async listCalendars(query?: CalendarListQuery): Promise<CalendarListResult> {
    return this._listCalendars(query);
  }

  /**
   *
   */
  async listCalendarChanges(query: CalendarListChangesQuery): Promise<CalendarListResult> {
    return this._listCalendars(query);
  }

  /**
   * https://www.googleapis.com/calendar/v3/calendars/[CALENDARID]/events?
   */
  async listEvents(query: CalendarEventListQuery): Promise<CalendarEventListResult> {
    const response = await this._fetch(
      `/calendars/${encodeURIComponent(query.calendar_external_id)}/events?${new URLSearchParams({
        ...(query.min_date && { timeMin: query.min_date }),
        ...(query.max_date && { timeMax: query.max_date }),
        ...(query.max_results && { maxResults: '' + query.max_results }),
        ...(query.page_token && { pageToken: query.page_token }),
        ...(query.search && { q: query.search }),
        ...(query.showDeleted !== undefined && { showDeleted: query.showDeleted.toString() }),
        // singleEvents: `${query.single_events}`,
      }).toString()}`,
      {
        method: 'GET',
      }
    );

    if (!response.ok) {
      switch (response.status) {
        case 401:
          this.authProvider.invalidateCachedTokens();
          throw new InvalidCredentialsError();
        default:
          throw new Error(`${response.status} : ${response.statusText}`);
      }
    }

    const result = (await response.json()) as unknown;

    if (isGcalCalendarEventListResponse(result)) {
      return {
        ...parseGcalEventItems(result.items, result.timeZone),
        sync_token: result.nextSyncToken ?? null,
        page_token: result.nextPageToken ?? null,
      };
    }
    /** @todo Consider a more specific error type ? Dump/log response as well ? */
    throw new ProxyServerError('Invalid ProxyGcalEventListResponse received.');
  }

  /**
   * https://www.googleapis.com/calendar/v3/calendars/[CALENDARID]/events?
   */
  async listEventChanges(query: CalendarEventListChangesQuery): Promise<CalendarEventListResult> {
    const response = await this._fetch(
      `/calendars/${encodeURIComponent(query.calendar_external_id)}/events?${new URLSearchParams({
        ...(query.max_results && { maxResults: '' + query.max_results }),
        ...(query.page_token && { pageToken: query.page_token }),
        syncToken: query.sync_token,
      }).toString()}`,
      {
        method: 'GET',
      }
    );

    /**
     * If the syncToken expires, the server will respond with a 410 GONE response code and the
     * client should clear its storage and perform a full synchronization without any syncToken.
     */
    if (!response.ok) {
      switch (response.status) {
        case 401:
          this.authProvider.invalidateCachedTokens();
          throw new InvalidCredentialsError();
        case 410:
          throw new GoogleSyncTokenInvalidatedError();
        default:
          throw new Error(`${response.status} : ${response.statusText}`);
      }
    }

    const result = (await response.json()) as unknown;

    if (isGcalCalendarEventListResponse(result)) {
      return {
        ...parseGcalEventItems(result.items, result.timeZone),
        sync_token: result.nextSyncToken ?? null,
        page_token: result.nextPageToken ?? null,
      };
    }
    /** @todo Consider a more specific error type ? Dump/log response as well ? */
    throw new ProxyServerError('Invalid ProxyGcalEventListResponse received.');
  }
}
