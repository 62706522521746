import { ApiService } from './ApiService';
import { AuthenticatorService } from './AuthenticatorService';
import { CoreApiConfig } from '../../../_container/interfaces/Environment';
import {
  ApiDomainEvent,
  ApiDomainEventDraft,
  BackupKeyRequestResponse,
  CoreServerError,
  Device,
  Invitation,
  NewDevice,
  NewMailInvitation,
  NewSmsInvitation,
  PasswordResetResponse,
  SuccessResponse,
  UserDTO,
  UserUpdate,
} from './CoreApiTypes';
import { NetworkError } from '../../../Common/app/Errors';
import { prettyPrint } from '../../../Common/utils';
import { WrappedKey } from '../../../Common/infra/services/crypto/Crypto';

/**
 *
 */
export class UserAlreadyExistsError extends Error {
  constructor() {
    super('User already exists.');
    this.name = 'UserAlreadyExistsError';
  }
}

export class CoreApiService implements ApiService {
  constructor(private config: CoreApiConfig, private authenticator: AuthenticatorService) {}

  /**
   * Fetch the API
   */
  private async _fetch<T>(url: string, options?: RequestInit): Promise<T | never> {
    const init: RequestInit = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
      },
    };

    /**
     * @note fetch() will only reject on network failure or if anything prevented
     *       the request from completing.
     *
     *       It will throw an unhelpful and generic TypeError with a message that
     *       vary by browser.
     *
     *       We wrap it with a more specific NetworkError to be able to switch
     *       on the error type reliably in other places.
     */
    let response: Response;
    try {
      response = await fetch(this.config.host + url, init);
    } catch (e) {
      if (e instanceof TypeError) {
        throw new NetworkError(e.message);
      }
      throw e;
    }

    let jsonResponse;
    try {
      jsonResponse = await response.json();

      // prettyPrint({ url: this.config.host + url, init, jsonResponse }, '_fetch', 'warn');
    } catch (e) {
      // For 500 Errors, response is in HTML, therefore response.json() fail.
      throw new CoreServerError(response.statusText);
    }

    if (!response.ok) {
      if (response.status === 400 && jsonResponse.type === 'user_already_exists') {
        throw new UserAlreadyExistsError();
      }
      const e = new Error(jsonResponse.toString());
      e.name = jsonResponse.title ? jsonResponse.title : 'CoreClientError';
      throw e;
    }
    return jsonResponse as T;
  }

  /**
   * Fetch the API with authorization
   */
  private async _fetchAuth<T>(url: string, options?: RequestInit): Promise<T | never> {
    const jwt = await this.authenticator.getJwt();

    if (!jwt) throw new Error('You must be logged in to access a protected route');

    const request: RequestInit = {
      ...options,
      headers: {
        Authorization: `Bearer ${jwt}`,
        ...options?.headers,
      },
    };
    return this._fetch<T>(url, request);
  }

  /**
   * Get an Admin autologin link fot the logged in user
   */
  async getAdminLink(): Promise<string> {
    const response = await this._fetchAuth<{ url: string }>('/api/security/link');
    return response.url;
  }

  async registerUser(
    email: string,
    password: string,
    firstname: string,
    lastname: string,
    referral_code?: string
  ): Promise<SuccessResponse> {
    return this._fetch(`/api/user/registration`, {
      method: 'POST',
      body: JSON.stringify({ firstname, lastname, email, password, referral_code }),
    });
  }

  async getUser(id: number): Promise<UserDTO> {
    return this._fetchAuth<UserDTO>(`/api/user/${id}`);
  }

  async updateUser(update: UserUpdate, id: number): Promise<SuccessResponse> {
    return this._fetchAuth<SuccessResponse>(`/api/user/${id}`, {
      method: 'PUT',
      body: JSON.stringify(update),
    });
  }

  async getDomainEvents(user_id: number, from_id = 0, limit = 100): Promise<ApiDomainEvent[]> {
    // console.warn('>>>> getDomainEvent', '/api/domainevent/list', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     user_id,
    //     from_id,
    //     limit,
    //   }),
    // });
    return this._fetchAuth('/api/domainevent/list', {
      method: 'POST',
      body: JSON.stringify({
        user_id,
        from_id,
        limit,
      }),
    });
  }

  async deleteDomainEvents(user_id: number, from_id: number, to_id: number): Promise<SuccessResponse> {
    return this._fetchAuth('/api/domainevent', {
      method: 'DELETE',
      body: JSON.stringify({
        user_id,
        from_id,
        to_id,
      }),
    });
  }

  async resetDomainEvents(user_id: number): Promise<SuccessResponse> {
    return this._fetchAuth('/api/domainevent/reset', {
      method: 'DELETE',
      body: JSON.stringify({
        user_id,
      }),
    });
  }

  /**
   * @todo Fix the empty events case and ApiDomainEventDraft shape once
   *       /api/domainevent/create_many has stabilized.
   */
  async pushDomainEvents(events: ApiDomainEventDraft[]): Promise<SuccessResponse> {
    if (events.length) {
      return this._fetchAuth('/api/domainevent/create_many', {
        method: 'POST',
        body: JSON.stringify({
          user_id: events[0].user_id,
          values: events.map((e) => e.content),
        }),
      });
    }
    throw new Error('Unsupported call to CoreApiService.pushDomainEvents with empty events array.');
  }

  async createDevice(device: NewDevice): Promise<SuccessResponse> {
    return this._fetchAuth(`/api/device`, {
      method: 'POST',
      body: JSON.stringify(device),
    });
  }

  async getUserDevices(user_id: number): Promise<Device[]> {
    return this._fetchAuth(`/api/user/${user_id}/device`);
  }

  /**
   *
   */
  async requestBackupKeyStopGap(email: string, requestId: string, token: string): Promise<BackupKeyRequestResponse> {
    return this._fetch(`/api/user/requestbackup`, {
      method: 'POST',
      body: JSON.stringify({ email, request_id: requestId, token }),
    });
  }

  /**
   *
   */
  async resetPassword(
    email: string,
    requestId: string,
    token: string,
    newPassword: string,
    rewrappedUserKey: WrappedKey,
    rewrappedRecoveryKey: WrappedKey
  ): Promise<PasswordResetResponse> {
    return this._fetch(`/api/user/resetpwd`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        request_id: requestId,
        new_password: newPassword,
        token,
        user_key: rewrappedUserKey,
        recovery_key: rewrappedRecoveryKey,
      }),
    });
  }

  /**
   * Retrieve the referal invitations sent by the user
   */
  async getInvitations(): Promise<Invitation[]> {
    const invitations = await this._fetchAuth<Invitation[]>(`/api/invitation`);

    return invitations;
  }

  /**
   * Send a referal invitation
   */
  async sendInvitation(invitation: NewMailInvitation | NewSmsInvitation): Promise<void> {
    return this._fetchAuth(`/api/invitation`, {
      method: 'POST',
      body: JSON.stringify(invitation),
    });
  }

  /**
   *
   */
  async resetPasswordWithoutRecoveryCode(
    email: string,
    requestId: string,
    token: string,
    newPassword: string,
    wrappedUserKey: WrappedKey,
    wrappedRecoveryKey: WrappedKey,
    wrappedUserBackupKey: WrappedKey
  ): Promise<PasswordResetResponse> {
    return this._fetch(`/api/user/resetpwdnocode`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        request_id: requestId,
        new_password: newPassword,
        token,
        user_key: wrappedUserKey,
        recovery_key: wrappedRecoveryKey,
        backup_key: wrappedUserBackupKey,
      }),
    });
  }
}

export class NoPermissionsError extends Error {
  constructor() {
    super('NoPermissions');
    this.name = 'NoPermissionsError';
  }
}
