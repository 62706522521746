import dayjs from 'dayjs';

import { CallServicesMap } from '../../_container/interfaces/Container';
import { ViewAccountRepo } from '../../Account/domain/projections/ViewAccountRepo';
import {
  ActionContext,
  InvalidAccountError,
  isEvent,
  isNotification,
  LoggerService,
  NoPermissionsError,
  Publisher,
  Result,
  toUTCDateTimeMs,
  UnsupportedDevice,
  UTCDateTimeMs,
  Uuid,
} from '../../Common';
import { Call, ViewCall, ViewCallRepo } from '../domain';
import { CallRepo } from '../infra/repository/CallRepo';
import { CallUseCase } from './CallUseCase';
import { NotificationUseCase } from '../../Notification';
import { CalendarEvent, CalendarUseCase, DEFAULT_EVENT_INTERVAL, ViewCalendarEvent } from '../../Calendar';
import { Environment } from '../../_container/interfaces/Environment';
import { isCallSupportedAccount, isEnvSupportedAccount, AccountSourceStatus } from '../../Account';
import i18n from '../../Common/infra/services/i18nextService';
import { ViewCalendarEventRepo } from '../../Calendar/domain/projections/ViewCalendarEventRepo';
import { ViewCalendar } from '../../Calendar';
import { Taggable, TagRelation } from '../../Tag';
import { displayAttendeeName } from '../../Contact';

export class AppCallUseCase implements CallUseCase {
  ERROR_UNEXPECTED = i18n.t('error.unexpected');

  constructor(
    private readonly publisher: Publisher,
    private readonly notification: NotificationUseCase,
    private readonly calendar: CalendarUseCase,
    private readonly call: CallRepo,
    private readonly viewCall: ViewCallRepo,
    private readonly viewAccount: ViewAccountRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly callServices: CallServicesMap,
    private readonly logger: LoggerService,
    private readonly env: Environment
  ) {}

  async get(call_id: Uuid): Promise<(ViewCall & Taggable) | null> {
    return this.viewCall.get(call_id);
    /**
     * @todo Fetch remotely if not found locally
     */
  }

  async getAll(size: number, offset: number): Promise<(ViewCall & Taggable)[]> {
    return this.viewCall.getAllByDate(size, offset);
  }

  async callDone(calendar: ViewCalendar, context: ActionContext): Promise<Result<ViewCalendarEvent | null>> {
    try {
      if (isNotification(context)) {
        await this.notification.archiveNotification(context.id);

        const end = dayjs();
        const start = end.subtract(DEFAULT_EVENT_INTERVAL, 'minute');

        if (!context.metadata?.attached_entity?.id) {
          throw new Error(`Could not terminate without the event`);
        }

        const call = await this.viewCall.get(context.metadata.attached_entity.id);

        if (!call) {
          throw new Error(`Call doesn't exist`);
        }

        const account = await this.viewAccount.get(call.account_id);

        if (!account) {
          throw new Error(`Can't find call's account`);
        }

        const result = CalendarEvent.create({
          /** @todo Make sure the calendar_id you pass in belongs to a UNIPILE calendar. This is a placeholder. */
          kind: 'SINGLE',
          type: 'UNIPILE',
          all_day: false,
          calendar_id: calendar.id,
          summary: i18n.t('event.defaultTitle.callDone_' + call.type, {
            attendee: displayAttendeeName(call.from_attendee),
          }),
          start: toUTCDateTimeMs(start),
          end: toUTCDateTimeMs(end),
          start_tzid: calendar.default_tzid,
          end_tzid: calendar.default_tzid,
          metadata: {
            attached_entity: {
              id: context.metadata.attached_entity.id,
              type: 'CALL',
              account_id: account.id,
              account_type: account.type,
            },
          },
        });

        await this.publisher.emit(result.changes);

        if (result.ok === true) {
          /** @todo Track real_start ! */
          const now = toUTCDateTimeMs(dayjs());
          const eventCompleted = result.aggregate.complete(now, now);
          if (eventCompleted.ok === true) {
            await this.publisher.emit(eventCompleted.changes);
            const event = await this.viewCalendarEvent.get(result.aggregate.id);
            return event ? { result: event } : { error: `Could not find calendar event ( ${result.aggregate.id} ).` };
          } else {
            throw new Error(`Could not complete calendar event ( ${result.aggregate.id} ).`);
          }
        } else {
          throw new Error(`Could not add calendar event : ${result.reason}.`);
        }
      }

      if (isEvent(context)) {
        await this.calendar.completeEvent(context);
        return { result: null };
      }

      throw new Error(`Context is not Event or Notification`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  async fetchNew(account_id: Uuid, from_date?: UTCDateTimeMs): Promise<{ calls: ViewCall[]; status: AccountSourceStatus }> {
    try {
      const account = await this.viewAccount.get(account_id);

      if (!account || !isCallSupportedAccount(account)) throw new InvalidAccountError();
      if (!isEnvSupportedAccount(account, this.env, 'CALLS')) throw new UnsupportedDevice();

      const last_call = await this.viewCall.getLatestByAccountAndType(account.id, 'MISSED');

      const last_used_date = from_date
        ? from_date
        : last_call && dayjs(last_call.date).isAfter(dayjs(account.created_at))
        ? last_call.date
        : account.created_at;

      const calls = await this.callServices[account.type].searchCalls(account.id, {
        after_date: last_used_date,
      });

      const viewCalls = (
        await Promise.all(
          calls.map(async (call) => {
            const callAgg = Call.create(call);

            let changes = callAgg.changes;

            // Assign account's tags to the call
            account.tags.forEach((tag) => {
              const relAgg = TagRelation.create({ element: 'CALL', element_id: callAgg.aggregate.id, tag_id: tag.id });
              changes = [...changes, ...relAgg.changes];
            });

            await this.publisher.emit(changes);

            return this.viewCall.get(callAgg.aggregate.id);
          })
        )
      ).filter((call): call is ViewCall & Taggable => call !== null);

      return { calls: viewCalls, status: 'IDLE' };
    } catch (e) {
      // if (e instanceof IncorrectCredentialsError) return { calls: [], status: 'CREDENTIALS' };
      if (e instanceof UnsupportedDevice) return { calls: [], status: 'UNSUPPORTED' };
      if (e instanceof NoPermissionsError) return { calls: [], status: 'PERMISSIONS' };
      this.logger.captureException(e);
      return { calls: [], status: 'ERROR' };
    }
  }

  /**
   *
   */
  async initialFetchNew(account_id: Uuid, duration: number): Promise<{ calls: ViewCall[]; status: AccountSourceStatus }> {
    const from_date = toUTCDateTimeMs(dayjs().subtract(duration, 'hour'));
    return this.fetchNew(account_id, from_date);
  }
}
