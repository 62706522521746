import dayjs from 'dayjs';
import { notEmpty } from '../../../Common/utils';
import { Uuid, LocalFile, FileManagerService, toUTCDateTimeMs } from '../../../Common';
import { MailContent, MailCache } from '../../domain';
import { IdbMailCacheRepo } from '../repository/IdbMailCacheRepo';
import { MailCacheService } from './MailCacheService';

export class DefaultMailCacheService implements MailCacheService {
  max = 100;

  constructor(private readonly mailCacheRepo: IdbMailCacheRepo, private readonly fileManager: FileManagerService) {}

  async set(id: Uuid, content: MailContent, files: LocalFile[] | undefined): Promise<void> {
    await this.mailCacheRepo.add({ id, date: toUTCDateTimeMs(dayjs()), ...content });
    if (files) await Promise.all(files?.map((file) => this.fileManager.add(file)));
  }

  async get(id: Uuid): Promise<(MailCache & { files: LocalFile[] }) | null> {
    const cache = await this.mailCacheRepo.get(id);
    if (!cache) return null;
    const files = await Promise.all(cache.attachments.map((att) => this.fileManager.get(att.id)));
    return { ...cache, files: files.filter(notEmpty) };
  }

  async clear(): Promise<void> {
    /**
     * @todo
     */
  }
}
