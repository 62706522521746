import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import { Button, Fade, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';

import bg from '../../assets/login_bg.svg';
import logo from '../../assets/logo.svg';
import { useSelector } from 'react-redux';
import { prettyPrint, sessionSelector } from '@focus-front/core';
import ResetPasswordForm from './ResetPasswordForm';
import LoadingButton from '../Common/LoadingButton';
import { Alert } from '@material-ui/lab';

const useClasses = makeStyles((theme: Theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  left: {
    flex: 1,
    height: '100%',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '15vh',
    paddingBottom: '25vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    transition: 'all .5s ease',
  },
  right: {
    width: '68%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'all .5s ease',
  },
  rightDone: {
    width: 0,
  },
  leftDone: {
    opacity: 0,
  },
  title: {
    marginBottom: 20,
    fontSize: 28,
  },
  formContainer: {
    maxWidth: 400,
  },
  body: {
    fontSize: '1.125rem',
    marginBottom: 16,
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(244,245,247,1) 100%)',
    position: 'absolute',
    top: '80%',
    left: -30,
    right: -30,
    bottom: 0,
  },
  shadowHide: {
    background: 'rgba(244,245,247,1)',
    position: 'absolute',
    top: '100%',
    left: -30,
    right: -30,
    bottom: -80,
  },
  imgContainer: {
    position: 'relative',
    maxWidth: '80%',
  },
  hiddenImg: {
    opacity: 0,
    transform: 'translateY(50px)',
  },
  transition: {
    transition: 'all .3s ease',
  },
  hidden: {
    opacity: 0,
  },
  bg: {
    transform: 'translateX(-80%) translateY(100%)',
    transition: 'all .5s ease',
    height: '180%',
    zIndex: -1,
  },
  bgMounted: {
    transform: 'translateX(0) translateY(0)',
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  noSecret: {
    paddingTop: '2em',
  },
}));

export default function ResetPasswordPage() {
  const c = useClasses();
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const session = useSelector(sessionSelector);
  const { requestId, token } = useParams<{ requestId: string; token: string }>();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    });
  }, []);

  /**
   * @todo Consider if we want to redirect the user to the app if a session is present.
   *       It may help avoid useless password resets. On the other hand it's frustrating
   *       to have to log out if you want actually want to change your password even
   *       though you still have a session open.
   */
  //   useEffect(() => {
  //     if (session)
  //       setTimeout(() => {
  //         history.push('/');
  //       }, 500);
  //   }, [session]);

  return (
    <div className={c.container}>
      <Fade in={mounted}>
        <div className={c.left}>
          <div>
            <img src={logo} alt="Logo" width="173px" />
            <Typography variant="body2" className={c.body}>
              {t('slogan')}
            </Typography>
          </div>

          <div className={c.formContainer}>
            <Typography variant="h2" className={c.title}>
              {t('resetPassword.title')}
            </Typography>
            <Typography variant="subtitle2">{t('resetPassword.subtitle')}</Typography>

            <ResetPasswordForm
              onResetSuccess={() => {
                /**
                 * There are two timeouts to handle both the display time of the snackbar
                 * and the Fade animation.
                 * 
                 * The snackbar opens immediatly onClick and is displayed until we navigate away
                 * from the page, i.e. outer + inner timeouts.
                 * 
                 * The Fade animation starts on setMounted(false) and lasts for the duration of the inner
                 * timeout.
                 */
                setIsSnackbarOpen(true);
                setTimeout(() => {
                  setMounted(false);
                  setTimeout(() => {
                    history.push('/');
                  }, 500);
                }, 2000);
              }}
            />

            <Typography variant="subtitle2" className={c.noSecret}>
              {t('resetPassword.infoNoSecret')}
            </Typography>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setMounted(false);
                setTimeout(() => {
                  history.push(`/resetpasswordnosecret/${requestId}/${token}`);
                }, 500);
              }}
            >
              {t('resetPassword.redirectNoSecret')}
            </a>
            {/* <br />
            <a
              href="#"
              onClick={(e) => {
                setIsSnackbarOpen(true);
                e.preventDefault();
                setTimeout(() => {
                  setMounted(false);
                  setTimeout(() => {
                    history.push(`/resetpasswordnosecret/${requestId}/${token}`);
                  }, 500);
                }, 1500);
              }}
            >
              test snackbar timing
            </a> */}
          </div>
        </div>
      </Fade>
      <div className={c.right}>
        <img className={cn(mounted && c.bgMounted, c.bg)} src={bg} alt="Unipile bands" />
      </div>
      <Snackbar
        open={isSnackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        // onClose={handleClose}
      >
        <Alert variant="filled" elevation={6} severity="success">
          {t('resetPassword.success')}
        </Alert>
      </Snackbar>
    </div>
  );
}
