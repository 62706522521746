import { DomainEventShape, UTCDateTime, Uuid } from '../../../Common';
import { ViewSignature, NewSignatureDTO, UpdateSignatureDTO } from '../projections/ViewSignature';
import { NewEncryptedCredentialsDTO } from '../projections/ViewCredentials';

/**
 * DomainEvent.
 */
export class SignatureCreated implements DomainEventShape {
  readonly type = 'SIGNATURE_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly signature: NewSignatureDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class SignatureDeleted implements DomainEventShape {
  readonly type = 'SIGNATURE_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class SignatureEdited implements DomainEventShape {
  readonly type = 'SIGNATURE_EDITED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly updatedValues: UpdateSignatureDTO,
    readonly createdAt = Date.now()
  ) {}
}

export type SignatureDomainEvent = SignatureCreated | SignatureDeleted | SignatureEdited;
