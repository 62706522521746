import React, { useEffect } from 'react';
import { useActionBar, EntityContext, AttachedEntityType, Action } from '@focus-front/ui';
import {
  draftCalendarEvent,
  eventByAttachedSelector,
  notificationByAttachedSelector,
  useAppDispatch,
  Uuid,
  workOnEntity,
} from '@focus-front/core';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  root: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-root': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

interface Props {
  entity_id: Uuid;
  entity_type: AttachedEntityType;
}

export default function SearchActionsBar({ entity_id, entity_type }: Props) {
  const dispatch = useAppDispatch();
  const c = useClasses();
  const history = useHistory();

  const notification = useSelector(notificationByAttachedSelector(entity_id));
  const event = useSelector(eventByAttachedSelector(entity_id));
  const { actions } = useActionBar(
    notification ? 'NOTIFICATION' : event ? 'EVENT' : 'ARCHIVED',
    entity_type,
    notification?.type,
    event ? event.metadata?.origin_notification?.type : notification?.metadata?.origin_event?.origin_notification?.type
  );

  const onClick = async (action: Action) => {
    if (action.id === 'process') {
      const event_id = await dispatch(workOnEntity(entity_type, entity_id));
      history.push(`/work/${event_id}`);
    }
    if (notification) history.push(`/inbox/${notification.id}?action=${action.id}`);
    if (event) history.push(`/work/${event.id}?action=${action.id}`);
  };

  return (
    <div className={c.root}>
      {actions.map((action) => (
        <Button startIcon={action.icon} variant="text" onClick={() => onClick(action)}>
          {action.label}
        </Button>
      ))}
    </div>
  );
}
