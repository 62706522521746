import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { CalendarEventInstanceId } from '../../Calendar';
import { Uuid } from '../../Common';
import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/web/WebContainer';
import {
  AttendeeDocument,
  CallDocument,
  ContactDocument,
  EventDocument,
  ImDocument,
  MailDocument,
  SearchResult,
} from '../infra/services/SearchService';

export type SearchableEntity = 'mail' | 'im' | 'contact' | 'event' | 'call';
export type SelectableEntity = SearchableEntity | 'mail_draft' | 'im_thread';
type SearchTab<T> = {
  results: SearchResult<T>[];
  scroll: number;
  status: 'IDLE' | 'LOADING';
};

interface SearchState {
  layout: {
    query: string;
    tab: SearchableEntity;
    selected: {
      id: Uuid;
      type: SelectableEntity;
    } | null;
  };
  mail: SearchTab<MailDocument>;
  im: SearchTab<ImDocument>;
  contact: SearchTab<ContactDocument>;
  event: SearchTab<EventDocument>;
  call: SearchTab<CallDocument>;
  index_status: 'INDEXING' | 'DONE';
}

const tabInitialState: SearchTab<any> = {
  results: [],
  status: 'LOADING',
  scroll: 0,
};

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    layout: {
      query: '',
      tab: 'mail',
      selected: null,
    },
    mail: tabInitialState,
    event: tabInitialState,
    call: tabInitialState,
    im: tabInitialState,
    contact: tabInitialState,
    index_status: 'INDEXING',
  } as SearchState,
  reducers: {
    indexingDone: (state) => {
      state.index_status = 'DONE';
    },
    searchMailsSuccess: (state, { payload }: PayloadAction<SearchResult<MailDocument>[]>) => {
      state.mail = {
        results: payload,
        status: !payload.length && state.layout.query.length <= 2 ? 'LOADING' : 'IDLE',
        scroll: 0,
      };
    },
    searchEventsSuccess: (state, { payload }: PayloadAction<SearchResult<EventDocument>[]>) => {
      let toScrollIndex = 0;
      const nowTs = dayjs().unix();
      [...payload].reduce((previous, current, index) => {
        const ts = dayjs(current.item.start).unix();
        if (ts < nowTs) {
          toScrollIndex = index;
          return ts;
        } else {
          return previous;
        }
      }, 0);
      state.event = {
        results: payload,
        status: !payload.length && state.layout.query.length <= 2 ? 'LOADING' : 'IDLE',
        scroll: Math.max(toScrollIndex - 4, 0),
      };
    },
    searchImsSuccess: (state, { payload }: PayloadAction<SearchResult<ImDocument>[]>) => {
      state.im = {
        results: payload,
        status: !payload.length && state.layout.query.length <= 2 ? 'LOADING' : 'IDLE',
        scroll: 0,
      };
    },
    searchCallsSuccess: (state, { payload }: PayloadAction<SearchResult<CallDocument>[]>) => {
      state.call = {
        results: payload,
        status: !payload.length && state.layout.query.length <= 2 ? 'LOADING' : 'IDLE',
        scroll: 0,
      };
    },
    searchContactsSuccess: (state, { payload }: PayloadAction<SearchResult<ContactDocument>[]>) => {
      state.contact = {
        results: payload,
        status: !payload.length && state.layout.query.length <= 2 ? 'LOADING' : 'IDLE',
        scroll: 0,
      };
    },
    searchQuery: (state, { payload }: PayloadAction<{ tab: SearchableEntity; query: string }>) => {
      state.layout.query = payload.query;
    },
    scrollResults: (state, { payload }: PayloadAction<{ tab: SearchableEntity; scroll: number }>) => {
      if (payload.scroll === 0) return;
      state[payload.tab].scroll = payload.scroll;
    },
    selectSearchTab: (state, { payload }: PayloadAction<SearchableEntity>) => {
      state.layout.tab = payload;
    },
    selectSearchResult: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Uuid;
        type: SelectableEntity;
      }>
    ) => {
      state.layout.selected = payload;
    },
  },
});

export const {
  indexingDone,
  searchMailsSuccess,
  searchEventsSuccess,
  searchCallsSuccess,
  searchImsSuccess,
  searchContactsSuccess,
  searchQuery,
  scrollResults,
  selectSearchTab,
  selectSearchResult,
} = searchSlice.actions;

export const initSearch =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { search }: Container): Promise<void> => {
    await search.index();
    dispatch(indexingDone());
  };

export const runSearch =
  (query: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { search }: Container): Promise<void> => {
    // Do not run search if the tunk is dispatched with the same query (this can happen on SearchTab mount)
    // if (query === getState().search.layout.query) return;

    // Save the query in the store
    dispatch(searchQuery({ tab: 'mail', query }));
    if (query.length <= 2) {
      dispatch(searchMailsSuccess([]));
      dispatch(searchEventsSuccess([]));
      dispatch(searchImsSuccess([]));
      dispatch(searchCallsSuccess([]));
      dispatch(searchContactsSuccess([]));
    } else {
      const mails = await search.searchMails(query);
      dispatch(searchMailsSuccess(mails));
      const events = await search.searchEvents(query);
      dispatch(searchEventsSuccess(events));
      const ims = await search.searchIms(query);
      dispatch(searchImsSuccess(ims));
      const calls = await search.searchCalls(query);
      dispatch(searchCallsSuccess(calls));
      const contacts = await search.searchContacts(query);
      dispatch(searchContactsSuccess(contacts));
    }
  };

export const searchContacts =
  (query: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { search }: Container): Promise<SearchResult<ContactDocument>[]> => {
    return search.searchContacts(query);
  };

export const searchAttendees =
  (query: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { search }: Container): Promise<SearchResult<AttendeeDocument>[]> => {
    return search.searchAttendees(query);
  };

export const mailResultsSelector = (state: { search: SearchState }) => {
  return state.search.mail;
};

export const eventResultsSelector = (state: { search: SearchState }) => {
  return state.search.event;
};

export const callResultsSelector = (state: { search: SearchState }) => {
  return state.search.call;
};

export const imResultsSelector = (state: { search: SearchState }) => {
  return state.search.im;
};

export const contactResultsSelector = (state: { search: SearchState }) => {
  return state.search.contact;
};

export const searchLayoutSelector = (state: { search: SearchState }) => {
  return state.search.layout;
};

export const indexStatusSelector = (state: { search: SearchState }) => {
  return state.search.index_status;
};

export const searchTabSelector = (id: SearchableEntity) => {
  return (state: { search: SearchState }) => ({
    total_results: state.search[id]?.results.length || 0,
    status: state.search[id]?.status || 'IDLE',
  });
};

export default searchSlice.reducer;
