import {
  AggregateRoot,
  CommandResult,
  createProjectionConfig,
  createUuid,
  createUuidFrom,
  DomainEvent,
  Uuid,
} from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import {
  CalendarAcknowledged,
  CalendarCreated,
  CalendarDeleteAcknowledged,
  CalendarDeleted,
  CalendarEdited,
  CalendarEtagAcknowledged,
  CalendarPatchAcknowledged,
  CalendarRestoreAcknowledged,
  CalendarSyncActivated,
  CalendarSyncDeactivated,
  CalendarSynced,
} from './events/Calendar.events';
import { CalendarType, ExternalCalendarEtag, GoogleCalendar } from './projections/ViewCalendar';
import {
  ExternalCalendarDTO,
  NewCalendarDTO,
  SyncedCalendarDTO,
  TaggedExternalPatchCalendarDTO,
  TaggedPatchCalendarDTO,
} from './types/CalendarDTO';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const CALENDAR_NAMESPACE = 'cfb63f0e-1194-4f1a-874c-597c3d49bc13' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  type: null as CalendarType | null,
  deleted: false,
  sync: false,
  sync_token: null as string | null,
  etag: null as ExternalCalendarEtag | null,
};

/**
 * DecisionProjection config.
 *
 * @note Specify generic parameters to narrow down handled types / autocompletion :
 *
 *         createProjectionConfig<typeof initialState, CalendarDomainEvent>
 *
 *       Otherwise ts will infer from passed argument for initialState and default to
 *       DomainEvent for the second generic parameter.
 *
 * @todo Consider tracking event list collection etag. It may help when diffing
 *       on sync_token invalidation.
 */
const projectionConfig = createProjectionConfig(initialState, {
  CALENDAR_CREATED: ({ aggregateId: id, calendar }) => ({
    id,
    type: calendar.type,
  }),
  CALENDAR_ACKNOWLEDGED: ({ aggregateId: id, calendar }) => ({
    id,
    type: calendar.type,
    sync: calendar.sync_activated ?? false,
    etag: calendar.etag,
  }),
  CALENDAR_RESTORE_ACKNOWLEDGED: ({ calendar }) => ({
    deleted: false,
    type: calendar.type,
    sync: calendar.sync_activated ?? false,
    etag: calendar.etag,
  }),
  CALENDAR_DELETED: () => ({ deleted: true }),
  CALENDAR_DELETE_ACKNOWLEDGED: ({ etag }) => ({ deleted: true, etag }),
  CALENDAR_ETAG_ACKNOWLEDGED: ({ etag }) => ({ etag }),
  CALENDAR_PATCH_ACKNOWLEDGED: ({ patch: { etag } }) => ({
    etag,
  }),
  CALENDAR_SYNC_ACTIVATED: () => ({ sync: true }),
  CALENDAR_SYNC_DEACTIVATED: () => ({ sync: false }),
  CALENDAR_SYNCED: ({ sync_token }) => ({
    sync_token,
  }),
});

// type Check<T, S> = [T] extends [S] ? ([S] extends [T] ? true : false) : false;
// type Ccreate = Check<
//   | CommandRejected<DomainEvent, 'SYNC ALREADY ACTIVATED'>
//   | CommandRejected<DomainEvent, 'DELETED'>
//   | CommandAccepted<DomainEvent, undefined>,
//   CommandResult<'DELETED' | 'SYNC ALREADY ACTIVATED'>
// >;

/**
 * Aggregate.
 */
export class Calendar extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   *
   */
  static getAggregateIdFromGoogleId(external_id: GoogleCalendar['external_id'], account_id: Uuid): Uuid {
    return createUuidFrom(external_id + account_id, CALENDAR_NAMESPACE);
  }

  /**
   * Command.
   *
   * @todo Consider that if create is allowed for external Calendars, it must
   *       be done in such a way that we can tell 'oh ! we know that calendar'
   *       when we sync with the external system, e.g. :
   *
   *       - stamping with external_id ( can't be done for google calendars but
   *         will be used with google calendar events )
   *
   *       - using a deterministic Uuid ( unfortunately the way we can uniquely
   *         identify calendars also involves external_id )
   */
  static create(content: NewCalendarDTO): CommandResult<'UNSUPPORTED TYPE', DomainEvent, Calendar> {
    if (content.type !== 'UNIPILE') {
      return Calendar.reject('UNSUPPORTED TYPE');
    }

    return Calendar.accept(new CalendarCreated(createUuid(), content));
  }

  /**
   * Command.
   */
  static acknowledge(content: ExternalCalendarDTO): CommandResult<'NOT IMPLEMENTED', DomainEvent, Calendar> {
    switch (content.type) {
      case 'GOOGLE': {
        const aggregateId = Calendar.getAggregateIdFromGoogleId(content.external_id, content.account_id);
        return Calendar.accept(new CalendarAcknowledged(aggregateId, content));
      }
      case 'OUTLOOK': {
        return Calendar.reject('NOT IMPLEMENTED');
      }
    }
  }

  /**
   * Command.
   */
  delete(): CommandResult<'DELETED'> {
    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    return this.apply(new CalendarDeleted(this.id, this.version)).accept();
  }

  /**
   * Command.
   *
   * @todo Restrict to property not related to Calendar sharing.
   *       Later : implement shareWithTeam(), shareWithGroup(), shareWithUser()
   *       commands.
   */
  edit(values: TaggedPatchCalendarDTO): CommandResult<'TYPE MISMATCH' | 'DELETED'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    return this.apply(new CalendarEdited(this.id, this.version, values)).accept();
  }

  /**
   * Command.
   *
   * @note Consider that you most likely need to acknowledge a new etag even if
   *       you reject a patch ?
   *       That or accept without question ?
   *       Otherwise you won't be able to push further updates with conditional
   *       requests and an obsolete etag !
   *       This also means you probably need some way to 'forcePush' and
   *       disregard the whatever etag the external calendar holds ?
   */
  acknowledgePatch(values: TaggedExternalPatchCalendarDTO): CommandResult<'TYPE MISMATCH' | 'DELETED' | 'NOT A NEW ETAG'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.etag === values.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    if (this.projection.state.deleted) {
      // return this.apply(new CalendarEtagAcknowledged(this.id, this.version, values.etag)).reject('DELETED');
      /**
       * @note Not acking the etag here allows to issue a restore command on 'DELETED' rejection.
       *
       * @todo Consider acking the etag in a CalendarEtagAcknowledged and not rejecting on same etag
       *       in restore command ? Wouldn't that introduce repeated acks problems ? Probably not if
       *       it also rejects if the effect of a restore is already applied, i.e. 'NOT DELETED'.
       */
      return this.reject('DELETED');
    }

    return this.apply(new CalendarPatchAcknowledged(this.id, this.version, values)).accept();
  }

  /**
   * Command.
   *
   * @todo Consider that you most likely need to acknowledge a new etag even if
   *       you reject a delete ?
   */
  acknowledgeDelete(etag: ExternalCalendarEtag): CommandResult<'NOT A NEW ETAG' | 'DELETED'> {
    if (this.projection.state.etag === etag) {
      return this.reject('NOT A NEW ETAG');
    }

    if (this.projection.state.deleted) {
      return this.apply(new CalendarEtagAcknowledged(this.id, this.version, etag)).reject('DELETED');
    }

    return this.apply(new CalendarDeleteAcknowledged(this.id, this.version, etag)).accept();
  }

  /**
   * Command.
   *
   * @note External calendars can be restored. But it's not as simple as
   *       as setting their deleted status to false since other changes to the calendar may have
   *       accrued and retrieved when syncing. For now we require the 'full' calendar. Later
   *       we may either require a patch after diffing before the command, or require the 'full'
   *       calendar and diff inside the command to save a patch in CalendarRestoreAcknowledged.
   */
  acknowledgeRestore(values: ExternalCalendarDTO): CommandResult<'TYPE MISMATCH' | 'NOT A NEW ETAG' | 'NOT DELETED'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.etag === values.etag) {
      return this.reject('NOT A NEW ETAG');
    }

    if (!this.projection.state.deleted) {
      return this.reject('NOT DELETED');
    }

    return this.apply(new CalendarRestoreAcknowledged(this.id, this.version, values)).accept();
  }

  /**
   * Command.
   */
  markSynced(values: SyncedCalendarDTO): CommandResult<'TYPE MISMATCH' | 'DELETED' | 'SYNC NOT ACTIVATED' | 'NOT A NEW TOKEN'> {
    if (this.projection.state.type !== values.type) {
      return this.reject('TYPE MISMATCH');
    }

    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    if (!this.projection.state.sync) {
      return this.reject('SYNC NOT ACTIVATED');
    }

    if (this.projection.state.sync_token === values.sync_token) {
      return this.reject('NOT A NEW TOKEN');
    }

    switch (values.type) {
      case 'GOOGLE':
        return this.apply(new CalendarSynced(this.id, this.version, values.sync_token)).accept();
      case 'OUTLOOK':
        return this.apply(new CalendarSynced(this.id, this.version, values.sync_token)).accept();
      //   default:
      //     return this.reject('FETCH NOT SUPPORTED');
    }
  }

  /**
   * Command.
   */
  activateSync(): CommandResult<'UNSUPPORTED TYPE' | 'DELETED' | 'SYNC ALREADY ACTIVATED'> {
    if (this.projection.state.type === 'UNIPILE') {
      return this.reject('UNSUPPORTED TYPE');
    }

    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    if (this.projection.state.sync) {
      return this.reject('SYNC ALREADY ACTIVATED');
    }

    return this.apply(new CalendarSyncActivated(this.id, this.version)).accept();
  }

  /**
   * Command.
   */
  deactivateSync(): CommandResult<'UNSUPPORTED TYPE' | 'DELETED' | 'SYNC ALREADY DEACTIVATED'> {
    if (this.projection.state.type === 'UNIPILE') {
      return this.reject('UNSUPPORTED TYPE');
    }

    if (this.projection.state.deleted) {
      return this.reject('DELETED');
    }

    if (!this.projection.state.sync) {
      return this.reject('SYNC ALREADY DEACTIVATED');
    }

    return this.apply(new CalendarSyncDeactivated(this.id, this.version)).accept();
  }
}
