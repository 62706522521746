import React, { useEffect, useState } from 'react';

import { getMailChildDraft, mailReply, ViewMailDraft, useAppDispatch } from '@focus-front/core';

import MailForm from '../Mail/MailForm';
import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';
import { ActionAlert } from './ActionAlert';
import { useTranslation } from 'react-i18next';

export default function MailReply({ onDone, context }: ActionModalProps) {
  const dispatch = useAppDispatch();
  const [draft, setDraft] = useState<ViewMailDraft>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const { t } = useTranslation();

  let mail_id = context.metadata.attached_entity.id;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const draft = await dispatch(getMailChildDraft(mail_id, 'REPLY'));
      setDraft(draft);
      setLoading(false);
    })();
  }, [mail_id]);

  const onReplyToAll = async () => {
    const draft = await dispatch(getMailChildDraft(mail_id, 'REPLY_ALL', true));
    setDraft(null);
    setDraft(draft);
  };

  const onSend = async (draft: ViewMailDraft) => {
    setLoading(true);
    const error = await dispatch(mailReply(context, draft));
    if (error) {
      setLoading(false);
      setShowError(true);
      setTimeout(() => setShowError(false), 4000);
    } else onDone('reply');
  };

  return (
    <ActionModal loading={loading} style={{ minHeight: '50%', maxHeight: 'auto', position: 'relative' }}>
      {draft && <MailForm defaultValues={draft} onSend={onSend} action_id="reply" onReplyToAllClick={onReplyToAll} parent_id={mail_id} />}
      <ActionAlert show={!!showError}>{t('error.mail_cantsend')}</ActionAlert>
    </ActionModal>
  );
}
