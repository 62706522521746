import dayjs from 'dayjs';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationVm } from './NotificationVm';
import { AppGetState, AppDispatch } from '../../_container/web/WebContainer';
import Container from '../../_container/interfaces/Container';
import { ViewNotification as Notification, ViewNotification, NotificationType } from '../domain';

import {
  ViewCalendarEvent as CalendarEvent,
  NewCalendarEventDTO,
  PatchCalendarEventDTO,
  CalendarEventDate,
} from '../../Calendar';
import { AccountType } from '../../Account';
import { Uuid } from '../../Common';
import { completeEventContext, eventCreateDone, completeEvent, eventUpdateDone } from '../../Calendar/state/calendar.slice';
import { ViewIm, imDone } from '../../Im';
import { callDone } from '../../Call';
import { Taggable } from '../../Tag';
import { mailDone, mailDraftDone } from '../../Mail';
import { RRuleString } from '../../Common/domain/RRule';
import { filteredNotificationsSelector } from './notifications.selectors';

export interface NotificationsState {
  entities: (NotificationVm & Taggable)[];
  status: 'FETCHING' | 'IDLE' | 'ERROR';
  filters: {
    tags: Uuid[];
    accounts: { id: Uuid; type: AccountType }[];
    types: NotificationType[];
  };
  sort: 'ASC' | 'DSC';
  snackbars: {
    message: string;
    key: number;
  }[];
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    // See https://material-ui.com/components/snackbars/#consecutive-snackbars
    snackbars: [],
    filters: {
      tags: [],
      accounts: [],
      types: [],
    },
    sort: 'ASC',
    entities: [],
    status: 'FETCHING',
  } as NotificationsState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<Notification & Taggable>) => {
      const found = state.entities.findIndex((n) => n.id === payload.id);
      if (found >= 0) state.entities[found] = { ...payload, loading: false, selected: false };
      else state.entities.push({ ...payload, loading: false, selected: false });
      state.entities.sort((a, b) => dayjs(b.received_date).diff(dayjs(a.received_date)));
    },
    getNotifications: (state, { payload }: PayloadAction<(Notification & Taggable)[]>) => {
      state.entities = payload.map((n, i) => ({ ...n, loading: false, selected: false }));
      state.entities.sort((a, b) => dayjs(b.received_date).diff(dayjs(a.received_date)));
      state.status = 'IDLE';
    },
    updateNotification: (state, { payload }: PayloadAction<Notification>) => {
      const index = state.entities.findIndex((notification) => notification.id === payload.id);
      state.entities[index] = { ...state.entities[index], ...payload };
    },
    deleteNotification: (state, { payload }: PayloadAction<string>) => {
      const index = state.entities.findIndex((notification) => notification.id === payload);
      if (index !== -1) {
        state.entities.splice(index, 1);
      }
    },
    deleteNotifications: (state, { payload }: PayloadAction<string[]>) => {
      state.entities = state.entities.filter((notification) => !payload.includes(notification.id));
    },
    deleteNotificationsByAccount: (state, { payload }: PayloadAction<Uuid>) => {
      state.entities = state.entities.filter((notification) => notification.account_id !== payload);
    },
    notificationLoading: (state, { payload }: PayloadAction<string>) => {
      const index = state.entities.findIndex((notification) => notification.id === payload);
      //   prettyPrint({ entities: state.entities, index }, 'notificationLoading', 'warn');
      if (index !== -1) {
        state.entities[index].loading = true;
      }
    },
    notificationCancelLoading: (state, { payload }: PayloadAction<string>) => {
      const index = state.entities.findIndex((notification) => notification.id === payload);
      if (index !== -1) {
        state.entities[index].loading = false;
      }
    },
    closeInboxSnackbar: (state) => {
      state.snackbars = state.snackbars.slice(1);
    },
    pushInboxSnackbar: (state, { payload }: PayloadAction<string>) => {
      state.snackbars.push({ message: payload, key: new Date().getTime() });
    },
    setTagFilter: (state, { payload }: PayloadAction<Uuid[]>) => {
      state.filters.tags = payload;

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    toggleTagFilter: (state, { payload }: PayloadAction<Uuid>) => {
      const i = state.filters.tags.indexOf(payload);
      i === -1 ? state.filters.tags.push(payload) : state.filters.tags.splice(i, 1);

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    setTypeFilter: (state, { payload }: PayloadAction<NotificationType[]>) => {
      state.filters.types = payload;

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    toggleTypeFilter: (state, { payload }: PayloadAction<NotificationType>) => {
      const i = state.filters.types.indexOf(payload);
      i === -1 ? state.filters.types.push(payload) : state.filters.types.splice(i, 1);

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    setAccountFilter: (state, { payload }: PayloadAction<{ id: Uuid; type: AccountType }[]>) => {
      state.filters.accounts = payload;

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    toggleAccountFilter: (state, { payload }: PayloadAction<{ id: Uuid; type: AccountType }>) => {
      const i = state.filters.accounts.findIndex((acc) => acc.id === payload.id);
      i === -1 ? state.filters.accounts.push(payload) : state.filters.accounts.splice(i, 1);

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    clearNotificationsFilters: (state) => {
      state.filters = {
        tags: [],
        types: [],
        accounts: [],
      };

      // Remove selection on filter change
      state.entities = state.entities.map((e) => ({ ...e, selected: false }));
    },
    toggleSort: (state) => {
      state.sort = state.sort === 'ASC' ? 'DSC' : 'ASC';
    },
    toggleSelectNotification: (state, { payload }: PayloadAction<string>) => {
      const index = state.entities.findIndex((notification) => notification.id === payload);
      if (index !== -1) {
        state.entities[index].selected = !state.entities[index].selected;
      }
    },
    toggleSelectAll: (state) => {
      // Selection apply on filtered notifications only
      const filtered = filteredNotificationsSelector({ notifications: state });
      const selected = filtered.findIndex((n) => n.selected) >= 0;
      for (const i in state.entities) {
        const found = filtered.find((n) => n.id === state.entities[i].id);
        if (found) state.entities[i].selected = !selected;
      }
    },
  },
});

export const {
  addNotification,
  updateNotification,
  deleteNotification,
  deleteNotifications,
  notificationLoading,
  notificationCancelLoading,
  closeInboxSnackbar,
  pushInboxSnackbar,
  deleteNotificationsByAccount,
  setTagFilter,
  setTypeFilter,
  setAccountFilter,
  toggleAccountFilter,
  toggleTagFilter,
  toggleTypeFilter,
  clearNotificationsFilters,
  toggleSort,
  toggleSelectNotification,
  toggleSelectAll,
} = notificationsSlice.actions;

export const getNotifications =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<void> => {
    const notifications = await notification.getNotifications();
    dispatch(notificationsSlice.actions.getNotifications(notifications));
  };

export const snoozeNotification =
  ({ id }: Notification) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<void> => {
    dispatch(notificationLoading(id));
    const updated = await notification.snoozeNotification(id);
    if (updated) {
      dispatch(updateNotification(updated));
    }
    dispatch(notificationCancelLoading(id));
  };

export const archiveNotification =
  ({ id }: Notification) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<void> => {
    try {
      dispatch(notificationLoading(id));
      await notification.archiveNotification(id);
      dispatch(deleteNotification(id));
    } catch (err) {
      dispatch(notificationCancelLoading(id));
    }
  };

export const snoozeAndNotifyCalendarEvent =
  (calendarEvent: CalendarEvent) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<void> => {
    try {
      // console.log('snoozeAndNotifyCalendarEvent', calendarEvent);
      // dispatch(eventDeleteRequested());
      // const newNotification = await notification.snoozeAndNotifyCalendarEvent(calendarEvent);
      // dispatch(addNotification(newNotification));
      // dispatch(eventDeleteDone(calendarEvent.id));
    } catch (err) {
      // dispatch(eventDeleteFailed(err.message));
    }
  };

export const doneNotification =
  (notification: ViewNotification) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail, call, im }: Container): Promise<void | Error | string> => {
    const entity_type = notification.metadata?.attached_entity?.type;
    // console.log('doneNotification ...');

    // console.log('doneNotification pause done !');
    dispatch(notificationLoading(notification.id));
    await new Promise((resolve) => setTimeout(resolve, 1000));
    switch (entity_type) {
      case 'MAIL':
        await dispatch(mailDone(notification));
        break;
      case 'MAIL_DRAFT':
        await dispatch(mailDraftDone(notification));
        break;
      case 'CALL':
        await dispatch(callDone(notification));
        break;
      case 'IM':
      case 'IM_THREAD':
        await dispatch(imDone(notification));
        break;
      default:
        break;
    }

    if (notification.metadata?.origin_event) {
      return dispatch(completeEventContext(notification));
    }
  };

export const doneSelected =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void | Error | string> => {
    const selected = getState().notifications.entities.filter((n) => n.selected);
    const done: string[] = [];

    await Promise.all(
      selected.map(async (n) => {
        const error = await dispatch(doneNotification(n));
        if (!error) done.push(n.id);
      })
    );

    dispatch(deleteNotifications(done));
  };

export const snoozeSelected =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<void | Error | string> => {
    const selected = getState().notifications.entities.filter((n) => n.selected);

    /**
     * @note We don't use the snoozeNotification thunk in the loop because we want to update all
     * notifications at once, not one by one, because it looks weird in the UI.
     */
    await Promise.all(
      selected.map(async (n) => {
        dispatch(notificationLoading(n.id));
        await notification.snoozeNotification(n.id);
      })
    );

    // Instead of updating manually the snooze notification, just get all of them
    dispatch(getNotifications());
  };

/**
 *
 */
export const planNotification =
  (draft: NewCalendarEventDTO, originNotificationId?: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<void> => {
    const id = originNotificationId || getState().calendar.form.originNotificationId;
    if (!id) return alert('Unexpected error : No originNotification found');
    const { error, result } = await notification.planNotification(id, draft);
    if (error || !result) return alert('error'); // TODO Handle error
    dispatch(eventCreateDone(result));
    if (dayjs(result.end).isBefore(dayjs())) dispatch(completeEvent(result));
  };

export const replanDroppedMissedEventNotification =
  (droppedNotification: ViewNotification, newDate: CalendarEventDate) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification, calendar }: Container): Promise<string | void> => {
    // prettyPrint({ droppedNotification, newDate }, 'replanDroppedMissedEventNotification', 'warn');

    if (!droppedNotification.metadata?.origin_event || droppedNotification.type !== 'EVENT_MISSED') {
      throw new Error('replanDroppedMissedEventNotification : Not a EVENT_MISSED notification !');
    }

    const missedEvent = (await calendar.getEvent(droppedNotification.metadata.origin_event.id)).result;

    if (!missedEvent) {
      throw new Error(
        `replanDroppedMissedEventNotification : origin_event ${droppedNotification.metadata.origin_event.id} not found !`
      );
    }

    const { result, error } = await notification.replanMissedEvent(droppedNotification.id, {
      type: missedEvent.type,
      ...newDate,
      ...(missedEvent.kind === 'INSTANCE'
        ? {
            kind: 'RECURRING',
            /**
             * @todo Split AppNotificationUseCase.replanMissedEvent in two, or make it easier
             *       to supply an actual CalendarEventExceptionDTO.
             *
             *       We have to supply a fake recurrence because AppNotificationUseCase.replanMissedEvent
             *       only accept a PatchCalendarEventDTO. It gets away with it because a PatchCalendarEventDTO
             *       is structurally compatible with a CalendarEventExceptionDTO, if you ignore the
             *       excess properties, that is.
             *
             *       This is not needed for patching an exception and only works because
             *       we know that it's going to be stripped down the line, but it's a
             *       dangerous thing to rely on !
             *
             */
            recurrence: [] as RRuleString[],
          }
        : { kind: 'SINGLE' }),
    });

    if (!result || error) return error;
    dispatch(eventUpdateDone(result));
    if (dayjs(result.end).isBefore(dayjs())) dispatch(completeEvent(result));
  };

export const replanMissedEvent =
  (notification_id: Uuid, update: PatchCalendarEventDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification }: Container): Promise<string | void> => {
    // prettyPrint({ notification_id, update }, 'replanMissedEvent', 'warn');
    const { result, error } = await notification.replanMissedEvent(notification_id, update);
    if (!result || error) return error;
    dispatch(eventUpdateDone(result));
    if (dayjs(result.end).isBefore(dayjs())) dispatch(completeEvent(result));
  };

export const notifyNewIm =
  (im: ViewIm) =>
  async (dispatch: AppDispatch, getState: AppGetState, { notification, tag }: Container): Promise<void> => {
    /**
     * Im must not be notified if the thread is attached to an event in the workmode
     */
    const in_work_mode = getState().calendar.workEvents.find((event) => event.metadata?.attached_entity?.id === im.thread_id);
    if (in_work_mode) return;
    const new_notification = await notification.notifyNewIm(im);
    if (new_notification) {
      const { result, error } = await tag.getNotificationTags(new_notification);
      dispatch(addNotification({ ...new_notification, tags: result || [] }));
    }
  };

export default notificationsSlice.reducer;
