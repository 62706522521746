import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

/** 
 * @note Opaque type. 
 */
declare const validUuid: unique symbol;
export type Uuid = string & { [validUuid]: true };

/**
 * Generate a random version 4 UUID.
 */
export function createUuid(): Uuid {
  return uuidv4() as Uuid;
}

/**
 * Generate a deterministic version 5 UUID from given name string and given 
 * namespace Uuid.
 */
export function createUuidFrom(name : string, namespace : Uuid) : Uuid {
    return uuidv5(name, namespace) as Uuid;
}

/**
 * Tag given string as valid Uuid for testing purposes only !
 */
export function mockUuid(testUuid: string): Uuid {
  return testUuid as Uuid;
}
