import dayjs from 'dayjs';
import {
  chevronDownOutline,
  chevronUpOutline,
  listOutline,
  locationOutline,
  notificationsOutline,
  peopleOutline,
  settingsOutline,
  trashOutline,
} from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  deleteCalendarEvent,
  editCalendarEvent,
  refreshEvent,
  Uuid,
  ViewCalendarEvent,
  useAppDispatch,
  CalendarEventInstanceId,
  UnipileEventAttendee,
  Taggable,
  ActionId,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import ActionsTabBar from '../ActionBar/ActionsTabBar';
import CallView from '../Call/CallView';
import ImThreadView from '../Im/ImThreadView';
import MailDraftView from '../Mail/MailDraftView';
import MailView from '../Mail/MailView';
import EventStatusBar from './EventStatusBar';
import { useConfirm } from 'material-ui-confirm';
import { RecurringCalendarEventRootStrip } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import EventRecurrentString from './EventRecurrentString';
import EventDescription from './EventDescription';
import EventLocation from './EventLocation';
import EventAttendees from './EventAttendees';

import SnoozeSvg from '../../assets/snooze.svg';
import EditableTags from '../Tag/EditableTags';

type Props = {
  event: ViewCalendarEvent & Taggable;
  status: 'WORK' | 'VIEW';
  recurringEventRoot: RecurringCalendarEventRootStrip;
  previous_id?: Uuid | CalendarEventInstanceId;
  next_id?: Uuid | CalendarEventInstanceId;
};

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
  },
  header: ({ event }: Props) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: event?.metadata?.origin_notification?.content ? `solid 1px ${theme.palette.divider}` : '',
  }),
  navigation: ({ status }: Props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: status === 'VIEW' ? -theme.spacing(1) : 0,
  }),
  navigationBtn: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
      marginLeft: 10,
    },
  },
  backIcon: {
    marginLeft: '0!important',
    marginRight: 10,
  },
  title: {
    flex: 1,
    paddingTop: theme.spacing(1),
  },
  content: {
    flex: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  actionBar: {
    // height: 100,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    paddingBottom: 20,
  },
  modal: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    height: '30%',
  },
  modalExpanded: {
    height: '100%',
  },
  snoozed: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity .2s ease',
    backgroundColor: theme.palette.success.main,
    width: '100%',
    height: '100%',
    borderRadius: 5,
    color: theme.palette.success.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  show: {
    opacity: 1,
  },
  subheader: {
    color: theme.palette.grey[600],
  },
  details: ({ status }: Props) => ({
    display: 'flex',
    marginTop: theme.spacing(2),
    paddingLeft: status === 'VIEW' ? theme.spacing(1) : 0,
    '& ion-icon': {
      fontSize: 24,
      marginRight: 10,
      color: theme.palette.grey[700],
    },
    '& .MuiTypography-root': {
      flex: 1,
    },
  }),
  viewHeader: ({ event }: Props) => ({
    background:
      event.status === 'DONE' || event.status === 'MISSED'
        ? `repeating-linear-gradient(45deg, ${theme.palette.grey[200]}, ${theme.palette.grey[200]} 10px, ${theme.palette.grey[300]} 10px, ${theme.palette.grey[300]} 20px)`
        : theme.palette.secondary.main,
    color:
      event.status === 'DONE' || event.status === 'MISSED' ? theme.palette.text.secondary : theme.palette.secondary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  }),
  icon: {
    width: 24,
    height: 24,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginRight: theme.spacing(1),
    color: theme.palette.success.contrastText,
  },
  doneIcon: {
    backgroundColor: theme.palette.success.main,
  },
  missedIcon: {
    backgroundColor: theme.palette.warning.main,
  },
  attendeesList: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  attendeesItem: {
    marginLeft: theme.spacing(2),
  },
}));

/**
 * Page displaying an event in a WORK or VIEW (standalone) mode
 */
export default function EventPage({ event, previous_id, next_id, status, recurringEventRoot }: Props) {
  const { t } = useTranslation();
  const classes = useClasses({ status, event } as Props);
  const confirm = useConfirm();
  const attached_entity = event?.metadata?.attached_entity;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const edit = () => {
    dispatch(editCalendarEvent('CALENDAR', event.id));
  };

  const del = async () => {
    await confirm({
      title: t('event.deleteWarning.title'),
      description: t('event.deleteWarning.message'),
      confirmationText: t('event.deleteWarning.okButton'),
      cancellationText: t('event.deleteWarning.cancelButton'),
    });
    const error = await dispatch(deleteCalendarEvent(event.id));
    if (!error) history.push('/inbox');
  };

  const [reload, setReload] = useState<number>(0);
  const handleReload = () => {
    setReload(reload + 1);
  };

  return (
    <>
      {status === 'VIEW' && <EventStatusBar event={event} />}
      <div className={classes.header}>
        <div className={classes.navigation}>
          {status === 'VIEW' && (
            <IconButton onClick={() => history.push('/')}>
              <Close />
            </IconButton>
          )}
          <div className={classes.title}>
            <Typography variant="h2">{event.summary || t('calendar.form.defaultTitle')}</Typography>
            <Typography variant="subtitle1" className={classes.subheader}>
              {event.all_day
                ? dayjs(event.start).format('dddd D MMMM')
                : `${dayjs(event.start).format('dddd D MMMM, HH:mm')} - ${dayjs(event.end).format('HH:mm')}`}
            </Typography>
            {event.kind === 'INSTANCE' && recurringEventRoot && <EventRecurrentString recurringEventRoot={recurringEventRoot} />}
          </div>

          <Box display="flex">
            <EditableTags
              tags={event.tags}
              element="EVENT"
              element_id={event.kind === 'SINGLE' ? event.id : event.recurring_event_id}
            />
            {event.status === 'PLANNED' && (
              <Tooltip title={t('event.action.editTooltip')} enterDelay={800}>
                <Button variant="text" className={classes.navigationBtn} onClick={edit}>
                  {t('event.action.edit')}
                  <IonIcon icon={settingsOutline} />
                </Button>
              </Tooltip>
            )}
            {event.status === 'DONE' && (
              <Tooltip title={t('event.action.deleteTooltip')} enterDelay={800}>
                <Button variant="text" className={classes.navigationBtn} onClick={del}>
                  {t('event.action.delete')}
                  <IonIcon icon={trashOutline} />
                </Button>
              </Tooltip>
            )}
            {status === 'WORK' && (
              <Tooltip title={t('event.navigation.previousTooltip')} enterDelay={800}>
                <Button
                  component={Link}
                  to={`/work/${previous_id}`}
                  variant="text"
                  className={classes.navigationBtn}
                  disabled={!previous_id}
                >
                  {t('event.navigation.previous')}
                  <IonIcon icon={chevronUpOutline}></IonIcon>
                </Button>
              </Tooltip>
            )}

            {status === 'WORK' && (
              <Tooltip title={t('event.navigation.nextTooltip')} enterDelay={800}>
                <Button
                  component={Link}
                  to={`/work/${next_id}`}
                  variant="text"
                  className={classes.navigationBtn}
                  disabled={!next_id}
                >
                  {t('event.navigation.next')}
                  <IonIcon icon={chevronDownOutline}></IonIcon>
                </Button>
              </Tooltip>
            )}
          </Box>
        </div>
        {event.attendees.length > 0 && <EventAttendees className={classes.details} attendees={event.attendees} />}
        {event.description && <EventDescription className={classes.details} event={event} />}
        {event.location && <EventLocation className={classes.details} location={event.location} />}
      </div>
      <>
        <div className={classes.content}>
          {attached_entity?.type === 'MAIL' && <MailView mail_id={attached_entity.id} />}
          {attached_entity?.type === 'MAIL_DRAFT' && <MailDraftView mail_id={attached_entity.id} reload={reload} />}
          {attached_entity?.type === 'CALL' && <CallView call_id={attached_entity.id} />}
          {attached_entity?.type === 'IM_THREAD' && <ImThreadView thread_id={attached_entity.id} />}
        </div>
        {(event.status === 'PLANNED' || event.status === 'MISSED') && (
          <ActionsTabBar
            context="EVENT"
            entity_type={attached_entity?.type}
            entity_id={attached_entity?.id}
            notification={event?.metadata?.origin_notification || null}
            event={event}
            onDone={async (action_id: ActionId) => {
              await dispatch(refreshEvent(event.id));
              if (action_id !== 'reply' && action_id !== 'transfer') history.push(next_id ? '/work/' + next_id : '/work');
            }}
            onMustReload={handleReload}
          />
        )}
      </>
    </>
  );
}
