import { EventInput } from '@fullcalendar/common';
import { createSelector } from '@reduxjs/toolkit';
import { CalendarEventInstanceId, ExternalCalendar } from '../domain';
import { Uuid } from '../../Common';
import { CalendarState } from './calendar.slice';

/**
 * Root selectors
 */

export const hiddenCalendarIdsSelector = (state: { calendar: CalendarState }): CalendarState['view']['hiddenCalendarIds'] => {
  return state.calendar.view.hiddenCalendarIds;
};

export const calendarViewDraftSelector = (state: { calendar: CalendarState }): CalendarState['view']['draft'] => {
  return state.calendar.view.draft;
};

export const calendarViewEventsSelector = (state: { calendar: CalendarState }): CalendarState['view']['events'] => {
  return state.calendar.view.events;
};

export const calendarModeSelector = (state: { calendar: CalendarState }): CalendarState['view']['mode'] => {
  return state.calendar.view.mode;
};

export const calendarTitleSelector = (state: { calendar: CalendarState }): CalendarState['view']['title'] => {
  return state.calendar.view.title;
};

export const calendarDateSelector = (state: { calendar: CalendarState }): CalendarState['view']['date'] => {
  return state.calendar.view.date;
};

export const workEventsSelector = (state: { calendar: CalendarState }): CalendarState['workEvents'] => {
  return state.calendar.workEvents;
};

export const calendarsSelector = (state: { calendar: CalendarState }): CalendarState['calendars'] => {
  return state.calendar.calendars;
};

export const calendarSnackbarSelector = (state: { calendar: CalendarState }): CalendarState['successSnackbar'] => {
  return state.calendar.successSnackbar;
};

export const calendarsFormSelector = (state: { calendar: CalendarState }): CalendarState['form'] => {
  return state.calendar.form;
};

export const calendarsEditedEventSelector = (state: { calendar: CalendarState }): CalendarState['form']['editedEventId'] => {
  return state.calendar.form.editedEventId;
};

export const syncModalAccountIdSelector = (state: { calendar: CalendarState }): CalendarState['syncModalAccountId'] => {
  return state.calendar.syncModalAccountId;
};

/**
 * Memoized selectors
 */

export const availableCalendarsSelector = createSelector(calendarsSelector, (calendars) => {
  return calendars.filter((calendar) => (calendar.type === 'GOOGLE' && calendar.sync_activated) || calendar.type === 'UNIPILE');
});

export const availableCalendarIdsSelector = createSelector(calendarsSelector, (calendars) => {
  return new Set(
    calendars.reduce<Uuid[]>((ids, c) => {
      if ((c.type === 'GOOGLE' && c.sync_activated) || c.type === 'UNIPILE') {
        ids.push(c.id);
      }
      return ids;
    }, [])
  );
});

export const visibleCalendarIdsSelector = createSelector(
  availableCalendarsSelector,
  hiddenCalendarIdsSelector,
  (calendars, hiddenCalendarIds) => {
    const hiddenSet = new Set(hiddenCalendarIds);
    return new Set(
      calendars.reduce<Uuid[]>((ids, c) => {
        if (!hiddenSet.has(c.id)) {
          ids.push(c.id);
        }
        return ids;
      }, [])
    );
  }
);

export const accountCalendarsSelector = (accountId: Uuid) => {
  return createSelector(calendarsSelector, (calendars) => {
    return calendars.filter((c) => c.type !== 'UNIPILE' && c.account_id === accountId) as ExternalCalendar[];
  });
};

export const syncedAccountCalendarsSelector = (accountId: Uuid) => {
  return createSelector(calendarsSelector, (calendars) => {
    return calendars.filter((c) => c.type !== 'UNIPILE' && c.account_id === accountId && c.sync_activated) as ExternalCalendar[];
  });
};

/**
 * Selector.
 *
 * @todo Rethink default calendar definition :
 *       This is a relic from the era when the app only had a UNIPILE type calendar.
 *       To avoid getting a random-ish non-UNIPILE calendar selected as the default
 *       one, we pick the first UNIPILE calendar.
 *       But this will break again if we allow the user to create more than one
 *       UNIPILE calendar.
 *       What we probably need to do is track the default calendar id somewhere.
 *       It may be a user's choice and end up in the settings, or set in stone and
 *       tagged on a property of UNIPILE type calendars, or something else TBD.
 */
export const calendarsDefaultCalendarSelector = createSelector(
  calendarsSelector,
  (calendars) => calendars.find((c) => c.type === 'UNIPILE') ?? null
);

export const nextEventSelector = (event_id: string) => {
  return createSelector(workEventsSelector, (events) => events[events.findIndex((e) => e.id === event_id) + 1]?.id);
};

export const previousEventSelector = (event_id: string) => {
  return createSelector(workEventsSelector, (events) => events[events.findIndex((e) => e.id === event_id) - 1]?.id);
};

export const workEventSelector = (event_id: Uuid | CalendarEventInstanceId) => {
  return createSelector(workEventsSelector, (events) => events.find((event) => event.id === event_id) || null);
};

export const eventByAttachedSelector = (entity_id: Uuid) => {
  return createSelector(workEventsSelector, (events) => {
    if (!entity_id) return undefined;
    return events.find((event) => event.metadata?.attached_entity?.id === entity_id);
  });
};

export const eventsSourceSelector = createSelector(
  calendarViewEventsSelector,
  calendarsSelector,
  hiddenCalendarIdsSelector,
  calendarViewDraftSelector,
  calendarsEditedEventSelector,
  (events, calendars, hidden, draft, editedEvent) => {
    const result = events.map((event) => {
      const calendar = calendars.find((c) => c.id === event.calendar_id);
      const display_event: EventInput = {
        id: event.id,
        title: event.summary,
        allDay: event.all_day,
        editable: event.status === 'PLANNED',
        start: event.start,
        end: event.end,
        display: hidden.includes(event.calendar_id) || event.id === editedEvent ? 'none' : 'auto',

        extendedProps: {
          type: event.type,
          kind: event.kind,
          metadata: event.metadata,
          status: event.status,
          location: event.location,
        },
      };

      if (calendar) {
        display_event.backgroundColor = calendar.background_color;
      }

      if (event.status !== 'PLANNED') {
        display_event.backgroundColor = '#e0e0e0';
      }

      return display_event;
    });

    if (draft) {
      result.push({
        id: 'draft',
        title: draft.summary,
        allDay: draft.all_day,
        editable: true,
        start: draft.start,
        end: draft.end,
        backgroundColor: 'orange',
      });
    }

    return result;
  }
);
