import { IDBPDatabase } from 'idb';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { LocalFile, LocalFileStore } from '../../domain/LocalFile';

/**
 * IndexedDB File store
 */
export class IdbFileStore implements LocalFileStore {
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  async add(file: LocalFile) {
    return Idb.put('localFiles', file);
  }

  /**
   *
   */
  async remove(id: string) {
    return Idb.delete('localFiles', id);
  }

  /**
   *
   */
  async clear() {
    return Idb.clear('localFiles');
  }

  /**
   *
   */
  async get(id: string) {
    return (await Idb.get('localFiles', id)) ?? null;
  }

  async getAll() {
    return Idb.getAll('localFiles');
  }
}
