import { StorageService } from './StorageService';

export class LocalStorageService implements StorageService {
  async get(key: string) {
    return localStorage.getItem(key);
  }
  async set(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  async del(key: string) {
    localStorage.removeItem(key);
  }
}
