import { UTCDateTimeMs, UTCDate, Uuid } from '../../../Common';

/**
 * Projection.
 * Mail Reference contains the minimal data to retrieve a mail remotely if it's not cached
 * It also contains a date, which is the reception date of the email, used to know from where to fetch when fetching new mails
 */
export type MailReference = Readonly<{
  id: Uuid /** Aggregate id. */;
  account_id: Uuid;
  date: UTCDateTimeMs;
  provider_id: string;
}>;

export type NewMailReferenceDTO = Omit<MailReference, 'id'>;

/**
 *
 */
export function createMailReference(aggregateId: Uuid, values: NewMailReferenceDTO): MailReference {
  if (!values.account_id || !values.date || !values.provider_id) {
    throw new Error('Missing values to instantiate a Mail');
  }
  return {
    ...values,
    id: aggregateId,
  };
}
