import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Container from '../../_container/interfaces/Container';
import { AppGetState } from '../../_container/mobile/MobileContainer';
import { AppDispatch } from '../../_container/store';
import { Uuid, Result } from '../../Common';
import {
  GoogleConnectionParams,
  ImapConnectionParams,
  LinkedInConnectionParams,
  MessengerConnectionParams,
  TikTokConnectionParams,
  TwitterConnectionParams,
  InstagramConnectionParams,
  MailConnectionParams,
  MicrosoftAccountInfo,
  MobileConnectionParams,
  GoogleCalendarConnectionParams,
  OutlookConnectionParams,
  ViewAccount,
  WhatsAppConnectionParams,
  AccountSource,
  isMailSupportedAccount,
  AccountType,
  ProxyConnectionParams,
  WebViewAppPasswordSupportedAccounts,
  ICloudCredentials,
  ICloudConnectionParams,
} from '../domain';
import {
  GoogleCredentials,
  LinkedInCredentials,
  MessengerCredentials,
  TikTokCredentials,
  InstagramCredentials,
  TwitterCredentials,
  MailCredentials,
  OAuthCredentials,
  WhatsAppCredentials,
} from '../domain/projections/ViewCredentials';
import { deleteNotificationsByAccount } from '../../Notification';
import { accountIdentifier } from '../../Common/utils';
import { Taggable } from '../../Tag';
import { MicrosoftOAuthReturn, GoogleOAuthReturn } from '../infra/services/OAuthService';
import { sourcesSlice, removeAccountSources, initMailMetaSync } from './sources.slice';
import { getCalendars, deleteEventsByAccount } from '../../Calendar/state/calendar.slice';

export interface AccountsState {
  accounts: (ViewAccount & { mail_ff_start?: boolean } & Taggable)[];
}

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: [],
  } as AccountsState,
  reducers: {
    setAccounts: (state, { payload }: PayloadAction<(ViewAccount & Taggable)[]>) => {
      state.accounts = payload.map((acc) => {
        const found = state.accounts.find((a) => a.id === acc.id);
        return {
          ...acc,
          mail_ff_start: !!(found && found.mail_ff_start),
        };
      });
    },
    removeAccount: (state, { payload }: PayloadAction<Uuid>) => {
      state.accounts = state.accounts.filter((account) => account.id !== payload);
    },
    selectSignatureSuccess: (state, { payload }: PayloadAction<{ account_id: Uuid; signature_id: Uuid }>) => {
      const index = state.accounts.findIndex((acc) => acc.id === payload.account_id);
      state.accounts[index].current_signature = payload.signature_id;
    },
    deleteSignatureSuccess: (state, { payload }: PayloadAction<Uuid>) => {
      state.accounts.forEach((acc, i) => {
        if (acc.current_signature === payload) state.accounts[i].current_signature = undefined;
      });
    },
    startMailFullFetch: (state, { payload }: PayloadAction<Uuid>) => {
      const index = state.accounts.findIndex((acc) => acc.id === payload);
      state.accounts[index].mail_ff_start = true;
    },
  },
});

export const { selectSignatureSuccess, deleteSignatureSuccess, setAccounts, removeAccount, startMailFullFetch } =
  accountsSlice.actions;

export const loadAccounts =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<void> => {
    const { result, error } = await account.getAll();
    if (error || !result) throw new Error('Cannot load accounts');
    if (result) {
      dispatch(setAccounts(result));
    }
  };

/**
 * Bridge thunk
 */
export const getAccount =
  (account_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<(ViewAccount & Taggable) | null> => {
    const { result, error } = await account.get(account_id);
    // TODO Handle error
    if (result) {
      return result;
    }
    return null;
  };

/**
 * Bridge thunk
 */
export const detectConfig =
  (email: string) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<{ error?: string; result?: ImapConnectionParams }> => {
    return account.requestMailConnectionParams(email);
  };

/**
 * Bridge thunk
 */
export const requestAuthMicrosoft =
  (acc?: MicrosoftAccountInfo) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<{ error?: string; result?: MicrosoftOAuthReturn }> => {
    return account.requestMicrosoftAuth(acc);
  };

/**
 * Bridge thunk
 */
export const requestAuthGoogleCalendar =
  (auth_code?: string) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<{ error?: string; result?: GoogleOAuthReturn }> => {
    return account.requestGoogleCalendarAuth(auth_code);
  };

/**
 * Bridge thunk
 */
export const testMailConnection =
  (credentials: MailCredentials, connection_params: ImapConnectionParams) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<boolean> => {
    return account.testMailConnection(credentials, connection_params);
  };

/**
 * Bridge thunk
 */
export const canAccountBeAdded =
  (account_type: AccountType) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<
    Result<{
      success: boolean;
      reason?: 'UNSUPPORTED' | 'MAX' | 'MAX_SUB';
      platforms?: ('ANDROID' | 'IOS' | 'DESKTOP' | 'WEB')[];
    }>
  > => {
    return account.canAccountBeAdded(account_type);
  };

/**
 * Bridge thunk
 */
export const loginLinkedIn =
  (username: string, password: string, proxy_settings?: ProxyConnectionParams) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<Result<string>> => {
    return account.loginLinkedIn(username, password, proxy_settings);
  };

/**
 * Bridge thunk
 */
export const getImapAppPassword =
  (account_type: WebViewAppPasswordSupportedAccounts, onStepChange: (step: number) => void) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<Result<{ email: string; password: string }>> => {
    return account.getImapAppPassword(account_type, onStepChange);
  };

/**
 * Bridge thunk
 */
export const loginMessenger =
  (username: string, password: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<Result<string>> => {
    return account.loginMessenger(username, password);
  };

/**
 * Bridge thunk
 */
export const loginTikTok =
  (username: string, password: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<Result<string>> => {
    return account.loginTikTok(username, password);
  };

/**
 * Bridge thunk
 */
export const loginTwitter =
  (username: string, password: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<Result<string>> => {
    return account.loginTwitter(username, password);
  };

/**
 * Bridge thunk
 */
export const loginInstagram =
  (username: string, password: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<Result<string>> => {
    return account.loginInstagram(username, password);
  };

/**
 * Bridge thunk
 */
export const loginWhatsApp =
  () =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<{ error?: string; result?: { session: string; identifier: string } }> => {
    return account.loginWhatsApp();
  };

const addAccountSuccess =
  (account: ViewAccount, sources: AccountSource[]) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(loadAccounts());
    dispatch(sourcesSlice.actions.addSources(sources));
  };

export const addMailAccount =
  (account_name: string, credentials: MailCredentials, connection_params: MailConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addMail(account_name, credentials, connection_params, credentialsKey);
    if (error) return { error };
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

export const addGoogleAccount =
  (account_name: string, credentials: GoogleCredentials, connection_params: GoogleConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addGoogle(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

export const addICloudAccount =
  (account_name: string, credentials: ICloudCredentials, connection_params: ICloudConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addICloud(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

export const addGoogleCalendarAccount =
  (account_name: string, credentials: OAuthCredentials, connection_params: GoogleCalendarConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addGoogleCalendar(account_name, credentials, connection_params, credentialsKey);
    if (result) {
      dispatch(addAccountSuccess(result.account, result.sources));
    }
    // console.log('addGoogleCalendarAccount', 'result', result, 'error', error);
    return { error, account_id: result?.account.id };
  };

export const addOutlookAccount =
  (account_name: string, credentials: OAuthCredentials, connection_params: OutlookConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addOutlook(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

export const addMobileAccount =
  (account_name: string, connection_params: MobileConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const { error, result } = await account.addMobile(account_name, connection_params);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

/**
 * @note Add a LinkedIn Account
 */
export const addLinkedInAccount =
  (account_name: string, credentials: LinkedInCredentials, connection_params: LinkedInConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addLinkedIn(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

/**
 * @note Add a Messenger Account
 */
export const addMessengerAccount =
  (account_name: string, credentials: MessengerCredentials, connection_params: MessengerConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addMessenger(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

/**
 * @note Add a Instagram Account
 */
export const addInstagramAccount =
  (account_name: string, credentials: InstagramCredentials, connection_params: InstagramConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addInstagram(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

/**
 * @note Add a TikTok Account
 */
export const addTikTokAccount =
  (account_name: string, credentials: TikTokCredentials, connection_params: TikTokConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addTikTok(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

/**
 * @note Add a Twitter Account
 */
export const addTwitterAccount =
  (account_name: string, credentials: TwitterCredentials, connection_params: TwitterConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addTwitter(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };
/**
 * @note Add a WhatsApp Account
 */
export const addWhatsAppAccount =
  (account_name: string, credentials: WhatsAppCredentials, connection_params: WhatsAppConnectionParams) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { account, user }: Container
  ): Promise<{ error?: string; account_id?: Uuid }> => {
    const credentialsKey = await user.getKey('credentials');
    const { error, result } = await account.addWhatsApp(account_name, credentials, connection_params, credentialsKey);
    if (result) dispatch(addAccountSuccess(result.account, result.sources));
    return { error, account_id: result?.account.id };
  };

export const reconnectMailAccount =
  (credentials: MailCredentials, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectMail(acc_id, credentials, credentialsKey);
    if (!error) dispatch(initMailMetaSync(acc_id));
    return error;
  };

export const reconnectGoogleAccount =
  (auth_code: string, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectGoogle(acc_id, auth_code, credentialsKey);
    if (!error) dispatch(initMailMetaSync(acc_id));
    return error;
  };

export const reconnectLinkedInAccount =
  (password: string, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectLinkedIn(acc_id, password, credentialsKey);
    return error;
  };

export const reconnectMessengerAccount =
  (password: string, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectMessenger(acc_id, password, credentialsKey);
    return error;
  };

export const reconnectInstagramAccount =
  (password: string, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectInstagram(acc_id, password, credentialsKey);
    return error;
  };

export const reconnectTikTokAccount =
  (password: string, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectTikTok(acc_id, password, credentialsKey);
    return error;
  };

export const reconnectTwitterAccount =
  (password: string, acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectTwitter(acc_id, password, credentialsKey);
    return error;
  };

export const reconnectWhatsAppAccount =
  (acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectWhatsApp(acc_id, credentialsKey);
    return error;
  };

export const reconnectMicrosoftAccount =
  (acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectMicrosoft(acc_id, credentialsKey);
    if (!error) dispatch(initMailMetaSync(acc_id));
    return error;
  };

export const reconnectGoogleCalendarAccount =
  (acc_id: Uuid, auth_code?: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account, user }: Container): Promise<string | undefined> => {
    const credentialsKey = await user.getKey('credentials');
    const { error } = await account.reconnectGoogleCalendar(acc_id, credentialsKey, auth_code);
    return error;
  };

export const deleteAccount =
  (acc_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<string | void> => {
    console.info('deleteAccount');
    const { error } = await account.deleteAccount(acc_id);
    console.info('deleteAccount error', error);
    if (!error) {
      const deletedAccount = getState().accounts.accounts.find((account) => account.id === acc_id);
      if (deletedAccount?.type === 'GOOGLE_CALENDAR') {
        dispatch(getCalendars());
        // dispatch(navigateCalendarToDate(getState().calendar.view.date));
      }
      dispatch(removeAccountSources(acc_id));
      dispatch(removeAccount(acc_id));
      dispatch(deleteNotificationsByAccount(acc_id));
      dispatch(deleteEventsByAccount(acc_id));
    }
    return error;
  };

export const reconnectAccountSuccess =
  (account_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { account }: Container): Promise<string | void> => {
    /**
     * Bring back all sources to IDLE when an account is reconnected
     * When diferrent auth flows per source for 1 account will be setup, specify here what to bring back to IDLE
     **/
    dispatch(sourcesSlice.actions.setSourceStatus({ account_id, status: 'IDLE', type: 'CALENDAR' }));
    dispatch(sourcesSlice.actions.setSourceStatus({ account_id, status: 'IDLE', type: 'CALLS' }));
    dispatch(sourcesSlice.actions.setSourceStatus({ account_id, status: 'IDLE', type: 'IMS' }));
    dispatch(sourcesSlice.actions.setSourceStatus({ account_id, status: 'IDLE', type: 'MAILS' }));
  };

export const mailFromSelector = (state: { accounts: AccountsState }) => {
  const mail_accounts = state.accounts.accounts.filter((account) => isMailSupportedAccount(account));
  return mail_accounts.map((acc) => ({
    name: acc.name,
    id: acc.id,
    signature: acc.current_signature,
    identifier: accountIdentifier(acc.connection_params),
  }));
};

export const mobileAccountSelector = (state: { accounts: AccountsState }) => {
  return state.accounts.accounts.filter((acc) => acc.type === 'MOBILE').length;
};

export const signaturesSelector = (state: { accounts: AccountsState }) => {
  return state.accounts.accounts.filter((acc) => isMailSupportedAccount(acc));
};

export const accountSelector = (account_id: Uuid) => (state: { accounts: AccountsState }) => {
  return state.accounts.accounts.find((account) => account.id === account_id);
};

export const accountsListSelector = (state: { accounts: AccountsState }) => {
  return state.accounts.accounts;
};

export default accountsSlice.reducer;
