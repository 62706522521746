import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Typography } from '@material-ui/core';

import AccountList from './AccountList';
import AccountPage from './AccountPage';
import { AddAccountButton } from './AddAccountButton';
import { ViewAccount } from '@focus-front/core';

const useClasses = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}));

export default function AccountListPage() {
  const c = useClasses();
  const { t } = useTranslation();

  const [selected, setSelected] = useState<ViewAccount | null>(null);

  if (selected) return <AccountPage onBack={() => setSelected(null)} account_id={selected.id} />;
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={c.sectionTitle}>
          <Typography variant="h2">{t('accountSettings.list.title')}</Typography>
          <Typography variant="subtitle2">{t('accountSettings.list.subtitle')}</Typography>
        </div>
        <AddAccountButton />
      </div>
      <AccountList onClick={(account) => setSelected(account)} />
    </>
  );
}
