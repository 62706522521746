import {
  openViewEvent,
  Uuid,
  ViewCalendarEvent,
  useAppDispatch,
  CalendarEventInstanceId,
  getRecurringRoot,
  Taggable,
  setSideTab,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { calendarOutline, listOutline, locationOutline, navigate } from 'ionicons/icons';
import { RecurringCalendarEventRoot } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCalendar from '../Calendar/useCalendar';
import ErrorMessage from '../Common/ErrorMessage';
import EditableTags from '../Tag/EditableTags';
import EventAttendees from './EventAttendees';
import EventDescription from './EventDescription';
import EventLocation from './EventLocation';
import EventRecurrentString from './EventRecurrentString';

type Props = {
  event_id: Uuid | CalendarEventInstanceId;
  headerMode?: boolean;
};

const useClasses = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  title: {
    paddingTop: theme.spacing(1),
  },
  details: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& ion-icon': {
      fontSize: 24,
      marginRight: 10,
      color: theme.palette.grey[700],
    },
    '& .MuiTypography-root': {
      flex: 1,
    },
  },
  content: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    height: 100,
    position: 'relative',
  },
  datetime: {
    color: theme.palette.grey[600],
  },
  navigationBtn: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -1,
    position: 'relative',
    color: theme.palette.grey[700],
    '& ion-icon': {
      fontSize: 24,
      marginLeft: 10,
    },
  },
}));

export default function EventView({ event_id, headerMode = false }: Props) {
  const [event, setEvent] = useState<ViewCalendarEvent & Taggable>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const [recurringEventRoot, setRecurringEventRoot] = useState<RecurringCalendarEventRoot>(null);
  const { gotoEvent } = useCalendar();

  const { t } = useTranslation();

  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      const event = await dispatch(openViewEvent(event_id));
      if (!event) return setNotFound(true);
      setEvent(event);
    })();
  }, [event_id]);

  useEffect(() => {
    if (event?.kind === 'INSTANCE')
      (async () => {
        const recurringEventRootResult = await dispatch(getRecurringRoot(event.recurring_event_id));
        setRecurringEventRoot(recurringEventRootResult);
      })();
  }, [event]);

  const showEvent = useCallback(() => {
    dispatch(setSideTab('calendar'));
    gotoEvent(event);
  }, [gotoEvent, event]);

  if (notFound) return <ErrorMessage title={t('event.notFoundTitle')} subtitle={t('event.notFoundSubtitle')} />;
  if (!event) return <div></div>;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography variant="h1">{event.summary || t('calendar.form.defaultTitle')}</Typography>
          <Typography variant="subtitle1" className={classes.datetime}>
            {event.all_day
              ? dayjs(event.start).format('dddd D MMMM')
              : `${dayjs(event.start).format('dddd D MMMM, HH:mm')} - ${dayjs(event.end).format('HH:mm')}`}
          </Typography>
          {event?.kind === 'INSTANCE' && recurringEventRoot && <EventRecurrentString recurringEventRoot={recurringEventRoot} />}
        </div>
        <Box display="flex">
          <EditableTags
            tags={event.tags}
            element="EVENT"
            element_id={event.kind === 'SINGLE' ? event.id : event.recurring_event_id}
          />
          <Button className={classes.navigationBtn} variant="text" onClick={showEvent}>
            {t('event.action.navigateTo')} <IonIcon icon={calendarOutline} />
          </Button>
        </Box>
      </div>
      {!headerMode && (
        <div className={classes.content}>
          {event.attendees.length > 0 && <EventAttendees className={classes.details} attendees={event.attendees} />}
          {event.description && <EventDescription className={classes.details} event={event} />}
          {event.location && <EventLocation className={classes.details} location={event.location} />}
        </div>
      )}
    </>
  );
}
