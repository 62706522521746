import { UnsupportedDevice } from '../../../Common';
import { ScrapQRCodeAuthService } from './ScrapQRCodeAuthService';

export class UnsupportedScrapQRCodeAuthService implements ScrapQRCodeAuthService {
  async authenticateWithQRCode(account_id: string): Promise<{ session: string; identifier: string }> {
    throw new UnsupportedDevice();
  }

  async logoutDefinitely(account_id: string): Promise<void> {
    // Nothing to do
  }
}
