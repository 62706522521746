import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { createProjectionConfig, Tzid, UTCDateTimeMs } from '../../Common';
import { RRuleString } from '../../Common/domain/RRule';
import { CalendarEvent } from './CalendarEvent';
import { CalendarEventType, ExternalCalendarEventEtag, GoogleCalendarReminders } from './projections/ViewCalendarEvent';

dayjs.extend(utc);

export type CalendarEventAggStatus = 'PLANNED' | 'DELETED' | 'SNOOZED' | 'MISSED' | 'DONE';

/**
 * DecisionProjection initial state.
 */
export const initialState = {
  type: 'UNIPILE' as CalendarEventType,
  current: 'PLANNED' as CalendarEventAggStatus,
  kind: 'SINGLE' as 'SINGLE' | 'RECURRING',
  recurrence: [] as RRuleString[],
  /**
   * Relax Record<CalendarEventInstanceId, CalendarEventStatus> to
   * Record<string, CalendarEventStatus>string so that TS doesn't get confused
   * with opaque types.
   *
   * @todo Decide between keeping track of start/end, etag for exception or requiring
   *       them when editing exceptions, e.g. :
   *
   *         Record<string, {
   *                          status : CalendarEventStatus;
   *                          start : UTCDateTimeMs;
   *                          end : UTCDateTimeMs;
   *                          etag: ExternalCalendarEventEtag | null;
   *                        }
   *               >
   *
   */
  exceptions: {} as Partial<Record<string, { status: CalendarEventAggStatus; etag?: ExternalCalendarEventEtag | null }>>,
  //   exceptions: {} as Partial<Record<string, { status: CalendarEventAggStatus; etag: ExternalCalendarEventEtag | null }>>,
  /**
   * 'Safe' values, lexicographically after/before any expected possible dates.
   *  Used to avoid non-null assertion and intended to fail Calendar.hasValidDates().
   */
  start: 'Z' as UTCDateTimeMs,
  end: '0' as UTCDateTimeMs,
  all_day: false,
  start_tzid: null as Tzid | null,
  reminders: null as GoogleCalendarReminders | null,
  etag: null as ExternalCalendarEventEtag | null,
};

/**
 * DecisionProjection config.
 *
 * @note Use type parameters to restrict autocompletion to a subset of DomainEvent.
 *       e.g. : createProjectionConfig<typeof initialState, CalendarEventDomainEvent>(...).
 */
export const projectionConfig = createProjectionConfig(initialState, {
  CALENDAREVENT_CREATED: ({ aggregateId: id, calendarEvent, statusOptions }) => {
    return {
      id,
      type: calendarEvent.type,
      kind: calendarEvent.kind,
      start: calendarEvent.start,
      end: calendarEvent.end,
      start_tzid: calendarEvent.start_tzid,
      ...(calendarEvent.all_day && { all_day: calendarEvent.all_day }),
      recurrence: calendarEvent.kind === 'RECURRING' ? calendarEvent.recurrence : [],
      ...(calendarEvent.type === 'GOOGLE' && { reminders: calendarEvent.reminders }),
      exceptions:
        statusOptions && calendarEvent.kind === 'RECURRING'
          ? CalendarEvent.expandStatusOptions(id, calendarEvent, statusOptions)
          : {},
    };
  },
  CALENDAREVENT_ACKNOWLEDGED: ({ aggregateId: id, calendarEvent, statusOptions }) => ({
    id,
    type: calendarEvent.type,
    kind: calendarEvent.kind,
    start: calendarEvent.start,
    end: calendarEvent.end,
    start_tzid: calendarEvent.start_tzid,
    ...(calendarEvent.all_day && { all_day: calendarEvent.all_day }),
    recurrence: calendarEvent.kind === 'RECURRING' ? calendarEvent.recurrence : [],
    ...(calendarEvent.type === 'GOOGLE' && { reminders: calendarEvent.reminders }),
    etag: calendarEvent.etag,
    exceptions:
      statusOptions && calendarEvent.kind === 'RECURRING'
        ? CalendarEvent.expandStatusOptions(id, calendarEvent, statusOptions)
        : {},
    // exceptions: CalendarEvent.expandStatusOptions(id, calendarEvent),
  }),
  CALENDAREVENT_RESTORE_ACKNOWLEDGED: ({ calendarEvent }) => ({
    current: 'PLANNED',
    type: calendarEvent.type,
    kind: calendarEvent.kind,
    start: calendarEvent.start,
    end: calendarEvent.end,
    start_tzid: calendarEvent.start_tzid,
    ...(calendarEvent.all_day && { all_day: calendarEvent.all_day }),
    recurrence: calendarEvent.kind === 'RECURRING' ? calendarEvent.recurrence : [],
    ...(calendarEvent.type === 'GOOGLE' && { reminders: calendarEvent.reminders }),
    etag: calendarEvent.etag,
  }),
  CALENDAREVENT_DELETED: () => ({
    current: 'DELETED',
  }),
  CALENDAREVENT_DELETE_ACKNOWLEDGED: ({ etag }) => ({
    current: 'DELETED',
    etag,
  }),
  CALENDAREVENT_ETAG_ACKNOWLEDGED: ({ patch: { etag } }) => ({
    etag,
  }),
  CALENDAREVENT_INSTANCE_DELETED: ({ instanceId }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag: state.exceptions[instanceId]?.etag ?? null, status: 'DELETED' },
    },
  }),
  /** @note For Google Calendar, original recurring event etag doesn't change on exception. */
  CALENDAREVENT_INSTANCE_DELETE_ACKNOWLEDGED: ({ instanceId, etag }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag, status: 'DELETED' },
    },
  }),
  CALENDAREVENT_EDITED: ({ patch }) => ({
    /** @todo Simplify this if kind is always required ! */
    ...(patch.kind && { kind: patch.kind }),
    ...(patch.start && { start: patch.start }),
    ...(patch.end && { end: patch.end }),
    ...(patch.start_tzid && { start_tzid: patch.start_tzid }),
    ...(patch.all_day && { all_day: patch.all_day }),
    recurrence: patch.kind === 'RECURRING' ? patch.recurrence : [],
    ...(patch.type === 'GOOGLE' && patch.reminders && { reminders: patch.reminders }),
    ...(patch.status && { current: patch.status }),
  }),
  CALENDAREVENT_PATCH_ACKNOWLEDGED: ({ patch }) => ({
    /** @todo Simplify this if kind is always required ! */
    ...(patch.kind && { kind: patch.kind }),
    ...(patch.start && { start: patch.start }),
    ...(patch.end && { end: patch.end }),
    ...(patch.start_tzid && { start_tzid: patch.start_tzid }),
    ...(patch.all_day && { all_day: patch.all_day }),
    recurrence: patch.kind === 'RECURRING' ? patch.recurrence : [],
    ...(patch.type === 'GOOGLE' && patch.reminders && { reminders: patch.reminders }),
    etag: patch.etag,
    // ...(patch.status && { current: patch.status }), // Probably not meaningful.
  }),
  CALENDAREVENT_EXCEPTION_ADDED: ({ instanceId }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag: state.exceptions[instanceId]?.etag ?? null, status: 'PLANNED' },
    },
  }),

  CALENDAREVENT_EXCEPTION_ACKNOWLEDGED: ({ instanceId, exception: { etag } }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag, status: 'PLANNED' },
    },
  }),
  CALENDAREVENT_EXCEPTION_RESTORE_ACKNOWLEDGED: ({ instanceId, exception: { etag } }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag, status: 'PLANNED' },
    },
  }),
  /**
   * @todo Double check that CALENDAREVENT_EXCEPTION_EDITED warrants no update
   *       to the decision projection.
   */
  //   CALENDAREVENT_EXCEPTION_EDITED : (event, state) => ({
  //     exceptions: {
  //       ...state.exceptions,
  //       [event.instanceId]: '????',
  //     },
  //   }),
  CALENDAREVENT_EXCEPTION_PATCH_ACKNOWLEDGED: ({ instanceId, exceptionPatch: { etag } }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag, status: state.exceptions[instanceId]?.status ?? 'PLANNED' },
    },
  }),
  CALENDAREVENT_EXCEPTION_ETAG_ACKNOWLEDGED: ({ instanceId, etag }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag, status: state.exceptions[instanceId]?.status ?? 'PLANNED' },
    },
  }),
  /**
   * @todo Match Google calendar rules for cancelling exception from internal
   *      events and for Unipile calendar.
   *
   * @todo Consider what to do with the etag. It's discarded here.
   */
  CALENDAREVENT_EXCEPTION_CANCELLED: (event, state) => {
    const { [event.instanceId]: cancelled, ...rest } = state.exceptions;
    return { exceptions: rest };
  },
  /**
   * @todo Match Google calendar rules for cancelling exception from internal
   *      events and for Unipile calendar.
   *
   * @todo Consider what to do with the etag. It's discarded here.
   */
  CALENDAREVENT_EXCEPTION_CANCEL_ACKNOWLEDGED: (event, state) => {
    const { [event.instanceId]: cancelled, ...rest } = state.exceptions;
    return { exceptions: rest };
  },
  CALENDAREVENT_SNOOZED: () => ({
    current: 'SNOOZED',
  }),
  CALENDAREVENT_MISSED: () => ({
    current: 'MISSED',
  }),
  CALENDAREVENT_INSTANCE_MISSED: ({ instanceId }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag: state.exceptions[instanceId]?.etag ?? null, status: 'MISSED' },
    },
  }),
  CALENDAREVENT_DONE: () => ({
    current: 'DONE',
  }),
  CALENDAREVENT_INSTANCE_DONE: ({ instanceId }, state) => ({
    exceptions: {
      ...state.exceptions,
      [instanceId]: { etag: state.exceptions[instanceId]?.etag ?? null, status: 'DONE' },
    },
  }),
  CALENDAREVENT_RESCHEDULED: () => ({
    current: 'PLANNED',
  }),
});
