import { reconnectAccountSuccess, reconnectMicrosoftAccount, useAppDispatch, Uuid } from '@focus-front/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  account_id: Uuid;
}

export default function ReconnectMicrosoft({ isOpen, onClose, account_id }: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isOpen)
      (async () => {
        // Go to inbox because the Redirect URI accepted for Microsoft is '/inbox' (cannot use /*)
        history.push('/inbox');
        setTimeout(async () => {
          const error = await dispatch(reconnectMicrosoftAccount(account_id));

          if (error) {
            if (error !== 'cancelled') alert(error);
          } else dispatch(reconnectAccountSuccess(account_id));
          onClose();
        }, 100);
      })();
  }, [isOpen]);

  return null;
}
