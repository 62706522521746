import { createProjector } from '../../../Common';
import { createViewTag } from '../projections/ViewTag';
import { ViewTagRepo } from '../projections/ViewTagRepo';

export interface viewTagsProjectorRepos {
  viewTag: ViewTagRepo;
}

/**
 * Handler/Projector.
 */
export const viewTagsProjector = createProjector('viewTagsProjector', {
  TAG_CREATED: async ({ aggregateId, tag }, viewTag: ViewTagRepo) => {
    return viewTag.add(createViewTag(aggregateId, tag));
  },
  TAG_UPDATED: async ({ aggregateId, update }, viewTag: ViewTagRepo) => {
    return viewTag.update(aggregateId, update);
  },
});
