import { IDBPDatabase } from 'idb';
import { notEmpty } from '../../../Common/utils';

import { Uuid } from '../../../Common';
import { Taggable, ViewTagRelationRepo, ViewTagRepo, ViewTagRelation } from '../../../Tag';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { createViewContact, ViewContact, ViewContactRepo, UpdateContactDTO, IdbViewContact, IdentifierType } from '../../domain';

/**
 * Repository.
 */
export class IdbViewContactRepo implements ViewContactRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   */
  constructor(private readonly viewTagRelation: ViewTagRelationRepo, private readonly viewTagRepo: ViewTagRepo) {}

  static projectionToStored(projection: ViewContact): IdbViewContact {
    return {
      ...projection,
      identifiers: [
        ...projection.email.map((email) => 'EMAIL_ADDRESS' + email.address),
        ...projection.phone.map((phone) => 'PHONE_NUMBER' + phone.number),
        ...projection.social.map((social) => social.identifier_type + social.identifier),
      ],
      managing_accounts: [
        ...projection.email.map((email) => email.managing_account).filter(notEmpty),
        ...projection.phone.map((phone) => phone.managing_account).filter(notEmpty),
        ...projection.social.map((social) => social.managing_account).filter(notEmpty),
      ],
    };
  }

  static storedToProjection({ identifiers, managing_accounts, ...contact }: IdbViewContact): ViewContact {
    return contact;
  }

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewContact) {
    return Idb.put('viewContacts', IdbViewContactRepo.projectionToStored(projection));
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateContactDTO) {
    const tx = Idb.transaction('viewContacts', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : ViewContact ${id} does not exist.`);
    }

    /** @note await should be unnecessary here. */
    tx.store.put(IdbViewContactRepo.projectionToStored(createViewContact(id, { ...old_projection, ...values })));

    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid) {
    return Idb.delete('viewContacts', id);
  }

  /**
   * Query
   */
  async clear() {
    return Idb.clear('viewContacts');
  }

  /**
   * Query.
   */
  async get(id: Uuid): Promise<(ViewContact & Taggable) | null> {
    const found = await Idb.get('viewContacts', id);
    if (!found) return null;
    const tags = await this.viewTagRelation.getTagsByElement('CONTACT', id);
    return { ...IdbViewContactRepo.storedToProjection(found), tags };
  }

  /**
   * Query
   */
  async getByFullName(full_name: string) {
    return Idb.getAllFromIndex('viewContacts', 'by-full_name', IDBKeyRange.only(full_name));
  }

  /**
   * Query
   */
  async getByAccount(account_id: Uuid) {
    return Idb.getAllFromIndex('viewContacts', 'by-account', IDBKeyRange.only(account_id));
  }

  async removeByAccount(account_id: Uuid) {
    let cursor = await Idb.transaction('viewContacts', 'readwrite')
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      const contact = IdbViewContactRepo.storedToProjection(cursor.value);
      const has_multiple_identifiers = contact.phone.length + contact.email.length + contact.social.length > 1;
      /**
       * If a contact has an other identifier than the one comming from the account to remove, do not delete the whole contact,
       * but just un-assign the identifier from it
       */
      if (has_multiple_identifiers) {
        await cursor.update(
          IdbViewContactRepo.projectionToStored({
            ...contact,
            email: contact.email.filter((email) => email.managing_account !== account_id),
            phone: contact.phone.filter((phone) => phone.managing_account !== account_id),
            social: contact.social.filter((social) => social.managing_account !== account_id),
          })
        );
      } else {
        await cursor.delete();
      }
      cursor = await cursor.continue();
    }
  }

  /**
   * Query
   */
  async getByIdentifier(identifier_type: IdentifierType, identifier: string) {
    const results = await Idb.getAllFromIndex('viewContacts', 'by-identifier', IDBKeyRange.only(identifier_type + identifier));
    return results.map(IdbViewContactRepo.storedToProjection);
  }

  private async _getTags(results: ViewContact[]): Promise<(ViewContact & Taggable)[]> {
    const tagReq: Promise<ViewTagRelation[]>[] = [];
    const tagTx = Idb.transaction('viewTagRelations', 'readonly', { durability: 'relaxed' }).store.index('by-element');

    for (let i = 0, length = results.length; i < length; ++i) {
      tagReq[i] = tagTx.getAll(IDBKeyRange.only(['CONTACT', results[i].id]));
    }

    const allTags = await this.viewTagRepo.getAll();

    const contactsWithTags = await Promise.all(
      tagReq.map(async (req, i) => {
        const relations = await req;
        const tags = relations.map((rel) => allTags.find((tag) => tag.id === rel.tag_id)).filter(notEmpty);

        return { ...results[i], tags };
      })
    );

    return contactsWithTags;
  }

  /**
   * Query.
   */
  async getAll(): Promise<(ViewContact & Taggable)[]> {
    const results = await Idb.getAll('viewContacts');
    return this._getTags(results.map(IdbViewContactRepo.storedToProjection));
  }
}
