import { warningOutline } from 'ionicons/icons';
import React from 'react';

import { IonIcon } from '@ionic/react';
import { Typography } from '@material-ui/core';

type Props = {
  title: string;
  subtitle: string;
  variant?: 'ERROR' | 'WARNING';
};

export default function ErrorMessage({ title, subtitle, variant = 'ERROR' }: Props) {
  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {variant === 'WARNING' && <IonIcon style={{ fontSize: 50 }} color="warning" icon={warningOutline} />}
      {variant === 'ERROR' && <IonIcon style={{ fontSize: 50 }} color="error" icon={warningOutline} />}
      <Typography variant="h2" align="center">
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        {subtitle}
      </Typography>
      {/* {link && (
        <Button
          color="secondary"
          variant="text"
          style={{ marginTop: 10, textTransform: 'none' }}
          onClick={() => window.open(link_href, '_blank')}
        >
          <IonIcon icon={arrowForwardCircleOutline} style={{ fontSize: 20, marginRight: 5 }}></IonIcon>
          {link}
        </Button>
      )} */}
    </div>
  );
}
