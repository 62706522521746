import { AccountType, NotificationType, Uuid, accountSourceSeletor, AccountSourceType, AccountSource } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import {
  archiveOutline,
  arrowRedoOutline,
  arrowUndoOutline,
  callOutline,
  chatboxOutline,
  createOutline,
  openOutline,
  timeOutline,
  trashOutline,
} from 'ionicons/icons';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

/**
 * AttachedEntityType
 * This is the list of entity types supported by Unipile
 * This is used to determine what actions to return (callback for a call, reply for a mail, ...)
 */
export type AttachedEntityType = 'MAIL' | 'MAIL_DRAFT' | 'IM' | 'CALL' | 'EVENT' | 'IM_THREAD';

/**
 * ActionId
 * This is the action id used in the translation key to find the label and in the url to open it from an external link
 */
export type ActionId = 'reply' | 'transfer' | 'chat' | 'callback' | 'done' | 'plan' | 'replan' | 'write' | 'process' | 'delete';

/**
 * EntityContext
 * This is what the entity is attached to, or if the entity is not attached (archived).
 * This is used to determine what link or what the action must do, because for a same entity, the action does différent things based
 * on this context
 */
export type EntityContext = 'EVENT' | 'NOTIFICATION' | 'ARCHIVED';

export type Action = {
  id: ActionId;
  label: string;
  icon: ReactElement;
  disabled: boolean;
};

export const EntityToSourceMapping: { [key in AttachedEntityType]: AccountSourceType } = {
  CALL: 'CALLS',
  EVENT: 'CALENDAR',
  IM: 'IMS',
  IM_THREAD: 'IMS',
  MAIL: 'MAILS',
  MAIL_DRAFT: 'MAILS',
};

/**
 * This hook return an array of Actions based on the context, the attached entity, and the origin notification type if any
 */
export function useActionBar(
  entity_context: EntityContext,
  entity_type?: AttachedEntityType,
  notification_type?: NotificationType | null,
  origin_notification_type?: NotificationType,
  account_type?: AccountType,
  account_id?: Uuid
): { actions: Action[]; source: AccountSource } {
  const { t } = useTranslation();
  // const [actions, setActions] = useState([]);

  /**
   * @todo Check if this selector handles external calendar events.
   */
  const source = useSelector(accountSourceSeletor(account_id, EntityToSourceMapping[entity_type]));

  function getActionIds(): ActionId[] {
    if (entity_context === 'ARCHIVED') return ['process'];
    let ids: ActionId[] = [];
    switch (entity_type) {
      case 'MAIL':
        // if (notification_type === 'MAIL_NEW' || origin_notification_type === 'MAIL_NEW') ids = ['reply', 'transfer'];
        ids = ['reply', 'transfer'];
        break;
      case 'MAIL_DRAFT':
        ids = ['write'];
        break;
      case 'IM_THREAD':
        ids = ['chat'];
        break;
      case 'CALL':
        ids = ['callback'];
        break;
      case 'EVENT':
        ids = [];
        break;
      default:
        ids = [];
    }

    ids.push(entity_context === 'EVENT' || notification_type === 'EVENT_MISSED' ? 'replan' : 'plan');
    // if (entity_type === 'MAIL' || entity_type === 'MAIL_DRAFT') ids.push('delete');
    // if (entity_type !== 'MAIL_DRAFT') ids.push('done');
    ids.push('done');
    return ids;
  }

  function getActionLabel(action: ActionId): string {
    if (action === 'plan') return t('action.plan');
    if (action === 'replan') return t('action.replan');
    if (action === 'process') return t('action.process');
    if (entity_type) return t(`action.${entity_type.toLowerCase()}_${action}`);
    return t(`action.${action}`);
  }

  function getActionIcon(action: ActionId): ReactElement {
    const ICONS: { [key in ActionId]: any } = {
      reply: <IonIcon icon={arrowUndoOutline} />,
      callback: <IonIcon icon={callOutline} />,
      chat: <IonIcon icon={chatboxOutline} />,
      transfer: <IonIcon icon={arrowRedoOutline} />,
      plan: <IonIcon icon={timeOutline} />,
      replan: <IonIcon icon={timeOutline} />,
      done: <IonIcon icon={archiveOutline} />,
      write: <IonIcon icon={createOutline} />,
      process: <IonIcon icon={openOutline} />,
      delete: <IonIcon icon={trashOutline} />,
    };
    return ICONS[action];
  }

  function getActionDisabled(action: ActionId, source: AccountSource): boolean {
    if (!source) return false;
    if (!(source.status === 'FETCHING' || source.status === 'IDLE' || source.status === 'PAUSE')) {
      if (action === 'callback' || action === 'chat' || action === 'reply' || action === 'transfer') return true;
      if (action === 'done' && entity_type === 'MAIL') return true;
    }
    return false;
  }

  // useEffect(() => {
  //   setActions(
  //     getActionIds().map((action) => ({
  //       id: action,
  //       label: getActionLabel(action),
  //       icon: getActionIcon(action),
  //     }))
  //   );
  // }, [entity_type]);

  const actions = useMemo(
    () =>
      getActionIds().map((action) => ({
        id: action,
        label: getActionLabel(action),
        icon: getActionIcon(action),
        disabled: getActionDisabled(action, source),
      })),
    [entity_context, entity_type, notification_type, source]
  );

  return { actions, source };
}
