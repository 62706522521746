import { getContactByAttendee, useAppDispatch, Attendee, ViewContact } from '@focus-front/core';
import { useEffect, useState } from 'react';

export function useContact(attendee: Attendee) {
  const dispatch = useAppDispatch();
  const [contact, setContact] = useState<ViewContact | null>(null);
  useEffect(() => {
    (async () => {
      const contact = await dispatch(getContactByAttendee(attendee));
      setContact(contact);
    })();
  }, [attendee.identifier]);
  return contact;
}
