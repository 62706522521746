import cn from 'classnames';
import { checkmarkCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IonIcon } from '@ionic/react';
import { Button, Fade, makeStyles, Typography } from '@material-ui/core';

import logo from '../../assets/logo.svg';
import signupScreen from '../../assets/test_svg_wizard.png';
import SignUpCarousel from './SignUpCarousel';
import SignUpForm from './SignUpForm';

const useClasses = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    overflowY: 'scroll',
  },
  left: {
    flex: 1,
    height: '100%',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '10vh',
    paddingBottom: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    transition: 'all .5s ease',
  },
  right: {
    width: '68%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'all .5s ease',
  },
  rightDone: {
    width: 0,
  },
  leftDone: {
    opacity: 0,
  },
  title: {
    marginBottom: 50,
    fontSize: 28,
  },
  body: {
    fontSize: '1.125rem',
    marginBottom: 16,
  },
  img: {
    boxShadow: '2px 2px 30px rgba(0,0,0,0.1)',
    borderRadius: 10,
    display: 'block',
    width: '100%',
    marginBottom: 50,
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
    position: 'absolute',
    top: '80%',
    left: -30,
    right: -30,
    bottom: 50,
  },
  shadowHide: {
    background: 'rgba(255,255,255,1)',
    position: 'absolute',
    top: 'calc(100% - 50px)',
    left: -30,
    right: -30,
    bottom: 0,
  },
  imgContainer: {
    position: 'relative',
    maxWidth: '60%',
  },
  hiddenImg: {
    opacity: 0,
    transform: 'translateY(50px)',
  },
  transition: {
    transition: 'all .3s ease',
  },
  hidden: {
    opacity: 0,
  },
  bg: {
    transform: 'translateX(-80%) translateY(100%)',
    transition: 'all .5s ease',
  },
  bgMounted: {
    transform: 'translateX(0) translateY(0)',
  },
  formWrapper: {
    width: '100%',
    position: 'relative',
  },
  subtitle: {
    position: 'absolute',
    left: 0,
    top: 45,
    width: 600,
    display: 'flex',
    fontSize: 16,
    '& span': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
      '& ion-icon': {
        marginRight: 5,
        color: theme.palette.secondary.main,
        fontSize: 25,
      },
    },
  },
  imgTitle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -30,
    textAlign: 'center',
  },
}));

export default function SignUpPage() {
  const c = useClasses();
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={c.container}>
      <Fade in={mounted}>
        <div className={c.left}>
          <div>
            <img src={logo} alt="Logo" width="173px" />
            <Typography variant="body1" className={c.body}>
              {t('slogan')}
            </Typography>
          </div>

          <div className={c.formWrapper}>
            <Typography variant="h2" className={c.title}>
              {t('signup.title')}
            </Typography>
            <Typography variant="h6" color="textPrimary" className={c.subtitle}>
              <Trans i18nKey="signup.subtitle">
                <span>
                  <IonIcon icon={checkmarkCircleOutline} /> Version Gratuite
                </span>
                <span>
                  <IonIcon icon={checkmarkCircleOutline} /> Sans CB
                </span>
                <span>
                  <IonIcon icon={checkmarkCircleOutline} /> Sans Engagement
                </span>
              </Trans>
            </Typography>
            <SignUpForm />
            <Typography variant="subtitle2" align="center">
              <Trans i18nKey="signup.warning">
                En cliquant sur Commencer, vous créez un compte et vous acceptez les{' '}
                <a href="https://www.unipile.com/fr/cgus/" target="_blank">
                  Conditions générales d'utilisation
                </a>{' '}
                ainsi que la{' '}
                <a href="https://www.unipile.com/fr/confidentialite/" target="_blank">
                  Politique de confidentialité
                </a>{' '}
                d'Unipile.
              </Trans>
            </Typography>
          </div>

          <Button component={Link} to="/login" variant="text" color="secondary">
            {t('signup.login')}
          </Button>
        </div>
      </Fade>
      <div className={cn(c.right)}>
        <Fade in={mounted}>
          <div className={c.imgContainer}>
            <img src={signupScreen} className={c.img} alt="" />
            <div className={c.gradient}></div>
            <div className={c.shadowHide}></div>

            <Typography variant="h2" className={c.imgTitle}>
              <SignUpCarousel />
            </Typography>
          </div>
        </Fade>
      </div>
    </div>
  );
}
