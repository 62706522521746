import { ProxyConnectionParams } from '../../domain';

export interface WebViewFormLoginCredentials {
  username: string;
  password: string;
}

export class InvalidCredentials extends Error {
  constructor() {
    super('Invalid Credentials');
  }
}
export interface ScrapFormAuthService {
  /**
   * Login to the app with username/password
   * Optional proxy settings to load the website through
   */
  login: (credentials: WebViewFormLoginCredentials, proxy_settings?: ProxyConnectionParams) => Promise<string>;
}
