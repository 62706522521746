import { arrowBack } from 'ionicons/icons';
import React from 'react';

import { Uuid } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { IconButton, Link, makeStyles, Typography } from '@material-ui/core';

import SearchActionsBar from '../ActionBar/SearchActionsBar';
import AccountLabel from '../Common/AccountLabel';
import ImThreadBubblesContainer from './ImThreadBubblesContainer';

import AttendeeTitle from '../Contact/AttendeeTitle';
import { useImThread } from './useImThread';
import Tags from '../Tag/Tags';

const useClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  thread_id: Uuid;
  focus?: boolean;
  onBack: () => void;
}

export default function SearchImThreadView({ thread_id, focus = false, onBack }: Props) {
  const c = useClasses();

  const { thread, ims, status, loadMore } = useImThread(thread_id);

  if (!thread) return <div></div>;

  const attendees = thread.attendees.filter((att) => att.display_name !== 'YOU');
  if (!attendees.length) return <div></div>;

  return (
    <div className={c.container}>
      <div className={c.header}>
        <IconButton onClick={onBack}>
          <IonIcon icon={arrowBack} />
        </IconButton>
        <AttendeeTitle attendees={attendees} titleOverride={thread.provider_name} />
        <div className={c.details}>
          <AccountLabel account_id={thread.account_id} className={c.detailText} />
          <div className={c.tags}>
            <Tags tags={thread.tags} />
          </div>
        </div>
      </div>
      <SearchActionsBar entity_id={thread.id} entity_type="IM_THREAD" />
      <ImThreadBubblesContainer
        ims={ims}
        thread={thread}
        startReached={() => {
          status === 'IDLE_AUTO' && loadMore();
        }}
        showMoreClick={loadMore}
        retryClick={loadMore}
        showMoreButton={status === 'IDLE_MANUAL'}
        showMoreError={status === 'ERROR'}
        showMoreLoading={status === 'LOADING'}
        showMoreUnsupported={status === 'UNSUPPORTED'}
      />
    </div>
  );
}
