import { GmailSearchQuery, GmailNewMail, GmailMailList, GmailFolder, GmailMail, GmailMailQuery } from '../types/gmail';
import { InvalidCredentialsError, MissingCredentialsError, ProxyConfig, ProxyServerError } from '../types/common';
import jwtDecode from 'jwt-decode';

interface DecodedJwt {
  exp: number;
  iat: number;
  google_email: string;
  google_access_token: string;
  google_refresh_token: string;
}

interface GoogleCredentials {
  type: 'GOOGLE';
  access_token: string;
  refresh_token: string;
}

export class Gmail {
  private access_token: string | null = null;
  private refresh_token: string | null = null;
  private jwt: string | null = null;

  constructor(
    protected config: ProxyConfig,
    private getCredentials?: (account_id: string) => Promise<GoogleCredentials | null>,
    public account_id?: string
  ) {}

  /**
   * Fetch utility handling errors and refreshing jwt if needed
   * @param url Url to call
   * @param options Options (method, headers)
   */
  protected async fetch(url: string, options?: RequestInit): Promise<any | never> {
    if (!this.access_token || !this.refresh_token) {
      [this.access_token, this.refresh_token] = await this._authenticate();
    }

    if (this.isJwtExpired()) await this.getJwt();

    const response = await fetch(this.config.host + url, {
      headers: {
        'Content-Type': 'application/json',
        'X-AT': this.access_token,
        'X-RT': this.refresh_token,
        Authorization: `Bearer ${this.jwt}`,
      },
      credentials: 'include',
      ...options,
    });
    let jsonResponse;
    try {
      jsonResponse = await response.json();
    } catch (e) {
      // For 500 Errors, response is in HTML, therefore response.json() fail.
      throw new ProxyServerError(response.statusText);
    }
    if (!response.ok) {
      throw new ProxyServerError(jsonResponse.title ? jsonResponse.title : jsonResponse.toString());
    }

    // Update the access_token with the one returned (refreshed by the server if expired)
    this.access_token = response.headers.get('X-AT') ?? this.access_token;

    return jsonResponse;
  }

  /**
   * Get credentials and save tokens in the memory
   */
  private async _authenticate(): Promise<[string, string]> {
    let credentials: GoogleCredentials | null = null;

    // Get account credentials with function passed to creator
    try {
      if (this.getCredentials && this.account_id) {
        credentials = await this.getCredentials(this.account_id);
      }
    } catch (e) {
      throw new InvalidCredentialsError();
    }

    // Check if retrieved credentials are good for Gmail
    if (!credentials) throw new MissingCredentialsError();
    if (!credentials.access_token || !credentials.refresh_token) throw new InvalidCredentialsError();

    return [btoa(credentials.access_token), btoa(credentials.refresh_token)];
  }

  /**
   * Verify if the jwt is valid
   */
  private isJwtExpired(): boolean {
    return !this.jwt || jwtDecode<DecodedJwt>(this.jwt).exp < Math.floor(Date.now() / 1000);
  }

  /**
   * Authenticate with Google : exc hange an auth code with a refresh token and access token used as credentials for api requests.
   * This must be done for the first login.
   * @param auth_code Code used to retrieve the credentials from the server.
   *                  This code is retrieved from the client Google Sign In prompt with offline mode and code response settings
   */
  async authenticateWithCode(auth_code: string): Promise<{ email: string; access_token: string; refresh_token: string }> {
    const response = await fetch(`${this.config.host}/api/login_check`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        username: this.config.username,
        password: this.config.password,
        google_access_code: auth_code,
        google_scope: ['mail', 'calendar'],
        redirect_uri: btoa(window.location.origin),
      }),
    });
    const data = await response.json();
    const decoded = jwtDecode<DecodedJwt>(data.token);

    return {
      email: decoded.google_email,
      access_token: JSON.stringify(decoded.google_access_token),
      refresh_token: decoded.google_refresh_token,
    };
  }

  async getJwt(): Promise<void> {
    const response = await fetch(`${this.config.host}/api/login_check`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        username: this.config.username,
        password: this.config.password,
      }),
    });
    const data = await response.json();
    this.jwt = data.token;
  }

  /**
   * Search mails in the user's mailbox
   * @param query Filters to find mails in the user's mailbox
   */
  async searchMails(query: GmailSearchQuery): Promise<GmailMailList> {
    return this.fetch(`/api/google/mail/message/list`, {
      method: 'POST',
      body: JSON.stringify(query),
    });
  }

  /**
   * Send an email from the user's mailbox smtp
   * @param new_mail Mail to send
   */
  async sendMail(new_mail: GmailNewMail): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/message/send`, {
      method: 'POST',
      body: JSON.stringify(new_mail),
    });
  }

  /**
   * Get the list of folders details in the user's mailbox
   */
  async getFolders(): Promise<Array<GmailFolder>> {
    return this.fetch(`/api/google/mail/labels`);
  }

  /**
   * Create a new folder
   * @param label_name Name of the folder
   */
  async addFolder(label_name: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/label`, {
      method: 'POST',
      body: JSON.stringify({ label_name }),
    });
  }

  /**
   * Delete a folder
   * @param label_name Name of the folder
   */
  async deleteFolder(label_name: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/label`, {
      method: 'DELETE',
      body: JSON.stringify({ label_id: label_name }),
    });
  }

  /**
   * Remove an email from a folder
   * @param id Mail id
   * @param folder_label Folder label to remove the mail from
   */
  async removeMailFromFolder(id: string, folder_label: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/message/label`, {
      method: 'PUT',
      body: JSON.stringify({ id, remove_label_ids: [folder_label] }),
    });
  }

  /**
   * Add an email from to folder
   * @param id Mail id
   * @param folder_label Folder label to add the mail to
   */
  async addMailToFolder(id: string, folder_label: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/message/label`, {
      method: 'PUT',
      body: JSON.stringify({ id, add_label_ids: [folder_label] }),
    });
  }

  /**
   * Moves an email from inbox to a folder
   * @param id Mail id
   * @param folder_label Folder where the mail will be moved
   */
  async moveMailToFolder(id: string, folder_label: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/message/label`, {
      method: 'PUT',
      body: JSON.stringify({ id, add_label_ids: [folder_label], remove_label_ids: ['INBOX'] }),
    });
  }

  /**
   * Get an email
   * @param id Mail id
   */
  async getMail(query: GmailMailQuery): Promise<GmailMail> {
    return this.fetch(`/api/google/mail/message`, {
      method: 'POST',
      body: JSON.stringify(query),
    });
  }

  /**
   * Put an email to trash folder
   * @param id Mail id
   */
  async trashMail(id: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/message`, {
      method: 'DELETE',
      body: JSON.stringify({ id }),
    });
  }

  /**
   * Put an email out of trash folder
   * @param id Mail id
   */
  async untrashMail(id: string): Promise<{ status: string }> {
    return this.fetch(`/api/google/mail/message`, {
      method: 'PUT',
      body: JSON.stringify({ id }),
    });
  }
}
