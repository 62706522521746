import i18n from '../infra/services/i18nextService';

// Return this error to the state layer for errors the user can't do anything about.
export class UnexpectedError extends Error {
  constructor() {
    super(i18n.t('error.unexpectedError'));
    this.name = 'UnexpectedError';
  }
}

export class InvalidAccountError extends Error {
  constructor() {
    super('Invalid Account type');
    this.name = 'InvalidAccountError';
  }
}

export class UnsupportedDevice extends Error {
  constructor() {
    super('Unsupported device');
    this.name = 'UnsupportedDevice';
  }
}

export class NoPermissionsError extends Error {
  constructor() {
    super('NoPermissions');
    this.name = 'NoPermissionsError';
  }
}

export class MissingRelation extends Error {
  constructor(projectionA: string, projectionB: string) {
    super(`There is a missing relation between ${projectionA} and ${projectionB}`);
    this.name = 'MissingRelation';
  }
}

export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NetworkError';
  }
}

export class FileNotFoundError extends Error {
  constructor() {
    super('File not found');
    this.name = 'FileNotFound';
  }
}

export class ElectronError extends Error {
  constructor(message: string, name: string, public extra: { [key: string]: any }) {
    super(message);
    this.name = name;
  }
}
