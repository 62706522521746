import React from 'react';
import {
  Backdrop,
  CircularProgress,
  ListItem as Li,
  ListItemIcon,
  ListItemText,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { logoutUser, sessionStateSelector, useAppDispatch } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';

const ListItem = withStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root': {
      fontSize: 24,
      color: 'inherit',
    },
  },
}))(Li);

const useClasses = makeStyles((theme) => ({}));

export default function LogoutButton() {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const { loadingLogout } = useSelector(sessionStateSelector);

  const confirmLogout = async () => {
    await confirm({
      title: t('settings.menu.logoutBoxTitle'),
      description: t('settings.menu.logoutBoxMessage'),
      confirmationText: t('settings.menu.logout'),
      cancellationText: t('settings.menu.cancelButton'),
    });
    dispatch(logoutUser());
  };

  return (
    <>
      <Backdrop open={loadingLogout} style={{ zIndex: 10000 }}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <ListItem button onClick={() => confirmLogout()}>
        <ListItemIcon>
          <IonIcon icon={logOutOutline} />
        </ListItemIcon>
        <ListItemText primary={t('settings.menu.logout')} />
      </ListItem>
    </>
  );
}
