import { createUser, InvalidUserDTOError, UserSession } from '../../../domain';
import { SessionRepo } from './SessionRepo';
import { isObject } from '../../../../Common/utils';

/**
 * @note This is part of a Security vs Convenience trade-off to avoid asking the
 *       user for its password every app launch on a mobile device during development.
 *       It's most likely a dangerous thing to do and SHOULD NOT GO INTO PRODUCTION
 *       as is without further researches.
 *
 */

export class UnsafeSessionRepo implements SessionRepo {
  private static SESSION_KEY = '__UNIPILE_SESSION';
  private static PASSWORD_KEY = '__UNIPILE_PASSWORD';

  constructor() {
    console.warn('UnsafeSessionRepo : UNSAFE DEV MODE ! some account info are stored in insecure way for DX convenience.');
  }

  async set(session: UserSession, password?: string): Promise<void> {
    sessionStorage.setItem(UnsafeSessionRepo.SESSION_KEY, JSON.stringify(session));
    if (password) sessionStorage.setItem(UnsafeSessionRepo.PASSWORD_KEY, password);
  }

  async get(): Promise<UserSession | null> {
    const result = sessionStorage.getItem(UnsafeSessionRepo.SESSION_KEY);
    if (!result) {
      return null;
    }
    const parsed = JSON.parse(result);

    if (!isObject(parsed)) throw new Error("Can't parse usersession stored");
    if (typeof parsed.refresh_token !== 'string') throw new Error('Invalid refresh token in session');

    try {
        const user = createUser(parsed);
        return { ...user, refresh_token: parsed.refresh_token };
    } catch (e) {
        if(e instanceof InvalidUserDTOError){
            this.delete();
            return null;
        }
        throw e;
    }
  }

  async getPassword(): Promise<string | null> {
    const result = sessionStorage.getItem(UnsafeSessionRepo.PASSWORD_KEY);
    return result || null;
  }

  async delete(): Promise<void> {
    sessionStorage.removeItem(UnsafeSessionRepo.SESSION_KEY);
    sessionStorage.removeItem(UnsafeSessionRepo.PASSWORD_KEY);
  }
}
