import {
  closeSubUpgrade,
  configurationSelector,
  isEmail,
  searchAttendees,
  sessionSelector,
  useAppDispatch,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog as D,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  makeStyles,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  CardGiftcardOutlined,
  CheckCircleOutlined,
  CheckCircleRounded,
  Close,
  Facebook,
  FileCopy,
  FileCopyOutlined,
  LinkedIn,
  Twitter,
} from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { constructSharp, giftOutline } from 'ionicons/icons';
import { getReferralInvitationsList, sendReferralInvitationsByEmail } from 'libs/core/src/User/state/subscription.slice';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { useAdminLink } from '../../Common/useAdminLink';
import Confetti from 'react-confetti';

const Dialog = withStyles((theme) => ({
  paper: {
    width: 700,
    minHeight: 300,
    display: 'flex',
  },
}))(D);

const useClasses = makeStyles((theme) => ({
  stepBlock: {
    position: 'relative',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    width: '32%',
    textAlign: 'center',
    padding: 10,
    paddingRight: 0,
    zIndex: 1,
    marginBottom: 20,
    marginTop: 20,
    '& > .bgt, & > .bgb': {
      backgroundColor: theme.palette.divider,
      height: '50%',
      position: 'absolute',
      width: '100%',
      zIndex: -1,
    },
    '& .bgt': {
      top: 0,
      transform: 'skewX(40deg)',
    },
    '& .bgb': {
      bottom: 0,
      transform: 'skewX(-40deg)',
    },
  },
  stepInfo: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    bottom: '-15px',
    padding: '2px 5px',
    borderRadius: '5px',
    fontSize: 13,
    right: '10px',
    left: '20px',
  },
  stepper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    margin: '0 auto',
  },
  title: {
    fontSize: 32,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  friendInfo: {
    backgroundColor: '#D4AF37',
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: '5px',
    fontSize: 13,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    width: '30%',
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
}));

export default function SubscriptionUpgradeDialog() {
  const dispatch = useAppDispatch();
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const { subUpgradeOpen } = useSelector(configurationSelector);
  const session = useSelector(sessionSelector);
  const { t } = useTranslation();
  const c = useClasses();
  const admin_link = useAdminLink({ destination: 'subscription' });

  const referral_link = 'https://app.unipile.com/signup?aff=' + session.referral_code;

  const onLinkCopy = () => {
    navigator.clipboard.writeText(referral_link);
    setShowCopied(true);
  };

  return (
    <>
      <Dialog open={subUpgradeOpen} maxWidth={false}>
        <Box p={2} style={{ backgroundColor: 'white' }}>
          <IconButton style={{ position: 'absolute', left: 10, top: 10 }} onClick={() => dispatch(closeSubUpgrade())}>
            <Close />
          </IconButton>
          <div style={{ position: 'absolute', right: 20, top: 20 }}>
            <Link color="textPrimary" onClick={admin_link.onClick}>
              {t('subscription.invite.skip')}
            </Link>
          </div>
          <Typography variant="h2" align="center" className={c.title}>
            {t('subscription.invite.title')}
          </Typography>
          <Typography variant="body1" align="center">
            {t('subscription.invite.desc')}
          </Typography>
          <Box className={c.stepper}>
            <div className={c.stepBlock}>
              <div className="bgt"></div>
              <div className="bgb"></div>
              {t('subscription.invite.step1')}
              <div className={c.stepInfo}>{t('subscription.invite.step1offer')}</div>
            </div>
            <div className={c.stepBlock}>
              <div className="bgt"></div>
              <div className="bgb"></div>
              {t('subscription.invite.step2')}
              <div className={c.stepInfo}>{t('subscription.invite.step2offer')}</div>
            </div>
            <div className={c.stepBlock}>
              <div className="bgt"></div>
              <div className="bgb"></div>
              {t('subscription.invite.step3')}
              <div className={c.stepInfo}>{t('subscription.invite.step3offer')}</div>
            </div>
          </Box>
          <SendInvitationsForm />
          <Typography variant="h2">{t('subscription.invite.share.title')}</Typography>
          <Typography variant="subtitle2">{t('subscription.invite.share.subtitle')}</Typography>
          <Box>
            <Tooltip title="Facebook" placement="top">
              <FacebookShareButton url={referral_link}>
                <IconButton style={{ color: '#3B5998' }}>
                  <Facebook />
                </IconButton>
              </FacebookShareButton>
            </Tooltip>
            <Tooltip title="LinkedIn" placement="top">
              <LinkedinShareButton url={referral_link}>
                <IconButton style={{ color: '#0E76A8' }}>
                  <LinkedIn />
                </IconButton>
              </LinkedinShareButton>
            </Tooltip>
            <Tooltip title="Twitter" placement="top">
              <TwitterShareButton url={referral_link}>
                <IconButton style={{ color: '#00ACEE' }}>
                  <Twitter />
                </IconButton>
              </TwitterShareButton>
            </Tooltip>
            <Tooltip title={t('subscription.invite.share.copy')} placement="top">
              <IconButton onClick={onLinkCopy}>
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Dialog>
      <Snackbar open={showCopied} autoHideDuration={3000} onClose={() => setShowCopied(false)}>
        <Alert variant="filled" elevation={6} severity="success">
          Link copied
        </Alert>
      </Snackbar>
    </>
  );
}

type Option = { email: string };

function SendInvitationsForm() {
  const MAX_INVITATIONS = 100;
  const { t } = useTranslation();
  const c = useClasses();
  const dispatch = useAppDispatch();
  const [values, setValues] = useState([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [input, setInput] = useState('');
  const [invitationsNb, setInvitationsNb] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [sendingStatus, setSendingStatus] = useState<'LOADING' | 'IDLE' | 'ERROR' | 'SUCCESS'>('IDLE');

  useEffect(() => {
    let active = true;

    if (input === '') {
      setOptions([]);
      return undefined;
    }

    (async () => {
      const attendees = await dispatch(searchAttendees(input));

      if (active) {
        setOptions([
          ...attendees.slice(0, 5).map((att) => ({
            email: att.item.identifier,
          })),
        ]);
      }
    })();

    return () => {
      active = false;
    };
  }, [input]);

  const handleChanges = (event, values: (string | { email: string })[]) => {
    setValues(values.map((value) => (typeof value === 'string' ? { email: value } : value)).filter((val) => isEmail(val.email)));
  };

  const handleBlur = (event) => {
    const changes: (string | { email: string })[] = [...values];
    if (input !== '') changes.push(input);
    handleChanges(event, changes);
  };

  useEffect(() => {
    (async () => {
      const { result, error } = await dispatch(getReferralInvitationsList());
      if (!error && result) setInvitationsNb(result.length);
    })();
  }, []);

  const REMAINING_INVITES = useMemo(() => (invitationsNb !== null ? MAX_INVITATIONS - invitationsNb : 0), [invitationsNb]);

  const handleSubmit = async () => {
    setError(null);
    if (values.length === 0) {
      setError(t('subscription.invite.send.errorMinLength'));
    } else if (values.length > REMAINING_INVITES) {
      setError(t('subscription.invite.send.errorMaxLength', { x: values.length, y: REMAINING_INVITES }));
    } else {
      setSendingStatus('LOADING');
      const { error } = await dispatch(sendReferralInvitationsByEmail(values.map((v) => v.email)));
      if (error) {
        setError(error);
        setSendingStatus('ERROR');
      } else setSendingStatus('SUCCESS');
    }
  };

  return (
    <>
      <Dialog open={sendingStatus !== 'IDLE'}>
        <Box p={6} flex="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {sendingStatus === 'LOADING' && (
            <>
              <CircularProgress />
              <Typography variant="subtitle1" style={{ marginTop: 8 }}>
                {t('subscription.invite.send.sendingSubtitle')}
              </Typography>
              <Typography variant="h2" style={{ marginTop: 16, marginBottom: 20 }}>
                {t('subscription.invite.send.sendingTitle')}...
              </Typography>
            </>
          )}
          {sendingStatus === 'SUCCESS' && (
            <>
              <Confetti width={600} height={300} numberOfPieces={100} />
              <CheckCircleOutlined style={{ fontSize: 50 }} color="secondary" />
              <Typography variant="subtitle1" style={{ marginTop: 8 }}>
                {t('subscription.invite.send.successSubtitle')}
              </Typography>
              <Typography variant="h2" style={{ marginTop: 16, marginBottom: 20 }}>
                {t('subscription.invite.send.successTitle')}
              </Typography>
              <Button onClick={() => window.location.reload()}> {t('subscription.invite.send.end')}</Button>
            </>
          )}
          {sendingStatus === 'ERROR' && (
            <>
              <Typography variant="subtitle1" color="error" style={{ marginBottom: 20 }}>
                {error}
              </Typography>
              <Button
                onClick={() => {
                  setError(null);
                  setSendingStatus('IDLE');
                }}
              >
                Ok
              </Button>
            </>
          )}
        </Box>
      </Dialog>
      <div className={c.friendInfo}>+ {t('subscription.invite.friendOffer')}</div>
      <Typography variant="h2">{t('subscription.invite.send.title')}</Typography>
      <Typography variant="subtitle2">{t('subscription.invite.send.subtitle')}</Typography>
      <Box mt={3} mb={5}>
        <Autocomplete
          multiple
          value={values}
          freeSolo
          disableCloseOnSelect
          limitTags={4}
          id="referral-emails"
          options={options}
          onInputChange={(e, v) => {
            setInput(v);
          }}
          onChange={handleChanges}
          onBlur={handleBlur}
          getOptionLabel={(option) => option.email}
          defaultValue={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              variant="outlined"
              placeholder={t('subscription.invite.send.placeholder')}
              fullWidth
              error={!!error}
              helperText={error ? error : t('subscription.invite.send.remaining', { number: REMAINING_INVITES })}
            />
          )}
        />

        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button startIcon={<IonIcon icon={giftOutline} />} color="secondary" onClick={handleSubmit}>
            {t('subscription.invite.send.submit')}
          </Button>
        </Box>
      </Box>
    </>
  );
}
