import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchResult, getAllCalls, selectSearchResult, CallDocument, useAppDispatch } from '@focus-front/core';
import AllListContainer, { AllListProps } from './AllListContainer';
import { Virtuoso } from 'react-virtuoso';
import CallSearchResult from '../../Call/CallSearchResult';
import CallSearchResultFake from '../../Call/CallSearchResultFake';
import NoResults from './NoResults';
import { IonIcon } from '@ionic/react';
import { callOutline } from 'ionicons/icons';

export default function AllCallsList({ onBack }: Pick<AllListProps, 'onBack'>) {
  const [calls, setCalls] = useState<SearchResult<CallDocument>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      await loadMore();
      setLoading(false);
    })();
  }, []);

  const loadMore = async () => {
    console.log('load more');
    const res = await dispatch(getAllCalls(50, calls.length));
    setCalls([
      ...calls,
      ...res.map((call) => ({
        item: call,
        matches: [],
        refIndex: 0,
      })),
    ]);
  };

  return (
    <AllListContainer title={t('search.call_plural')} onBack={onBack}>
      {loading && (
        <>
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
          <CallSearchResultFake />
        </>
      )}
      {!loading && calls.length > 0 && (
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={calls.length}
          endReached={loadMore}
          itemContent={(index) => (
            <CallSearchResult
              index={index}
              key={index}
              call={calls[index]}
              onClick={() => dispatch(selectSearchResult({ id: calls[index].item.id, type: 'call' }))}
            />
          )}
        />
      )}
      {!loading && !calls.length && <NoResults icon={callOutline} label={t('search.call_plural')} />}
    </AllListContainer>
  );
}
