import { Uuid } from '../../../Common/';
import { Idb } from '../../../Common/infra/services/idb/index';
import {
  createEncryptedCredentials,
  EncryptedCredentials,
  EncryptedCredentialsRepo,
  NewEncryptedCredentialsDTO,
} from '../../domain';

/**
 * Repository.
 */
export class IdbCredentialsRepo implements EncryptedCredentialsRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   */
  //   constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: EncryptedCredentials) {
    return Idb.put('credentials', projection);
  }

  /**
   *
   */
  async update(id: Uuid, values: NewEncryptedCredentialsDTO) {
    const tx = Idb.transaction('credentials', 'readwrite');
    const old_projection = await tx.store.get(id);

    if (!old_projection) {
      throw new Error(`Invalid id : EncryptedCredentials for Account ${id} does not exist.`);
    }

    /** @note await should be unnecessary here. */
    // await Promise.all([tx.store.put(createEncryptedCredentials(id, { ...old_projection, ...values })), tx.done]);
    tx.store.put(createEncryptedCredentials(id, { ...old_projection, ...values }));
    return tx.done;
  }

  /**
   *
   */
  async remove(id: Uuid) {
    return Idb.delete('credentials', id);
  }

  /**
   *
   */
  async clear() {
    return Idb.clear('credentials');
  }

  /**
   * Query.
   */
  async get(id: Uuid) {
    return (await Idb.get('credentials', id)) ?? null;
  }

  /**
   * Query.
   */
  async getAll() {
    return Idb.getAll('credentials');
  }
}
