import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/web/WebContainer';
import { Image, mockUuid, Result, Uuid } from '../../Common';

import { Attendee, NewContactDTO, ViewContact as Contact, ViewContact } from '../domain';
import { ViewIm, ViewImThread } from '../../Im';
import { ViewAccount } from '../../Account';
import { Taggable } from '../../Tag';

interface ContactsState {
  entities: Contact[];
  form: {
    status: 'CREATE' | 'EDIT';
    defaultValues: NewContactDTO;
    editContactId?: Uuid;
  } | null;
  viewContactId: Uuid | null;
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    entities: [] as Contact[],
    form: null,
    viewContactId: null,
    contactList: {
      isOpened: false,
      filters: [],
    },
  } as ContactsState,
  reducers: {
    loadContactsSuccess: (state, { payload }: PayloadAction<Contact[]>) => {
      state.entities = payload;
    },
    createContact: (state, { payload }: PayloadAction<NewContactDTO>) => {
      state.form = { status: 'CREATE', defaultValues: payload };
    },
    editContact: (state, { payload }: PayloadAction<{ contact: NewContactDTO; id: Uuid }>) => {
      state.form = { status: 'EDIT', defaultValues: payload.contact, editContactId: payload.id };
    },
    cancelContactForm: (state) => {
      state.form = null;
    },
    createContactSuccess: (state, { payload }: PayloadAction<Contact>) => {
      state.entities.push(payload);
      state.form = null;
    },
    editContactSuccess: (state, { payload }: PayloadAction<Contact>) => {
      const index = state.entities.findIndex((contact) => contact.id === payload.id);
      state.entities[index] = { ...state.entities[index], ...payload };
      state.form = null;
    },
    deleteContactSuccess: (state, { payload }: PayloadAction<Uuid>) => {
      const index = state.entities.findIndex((contact) => contact.id === payload);
      state.entities.splice(index, 1);
      if (state.viewContactId && state.viewContactId === payload) state.viewContactId = null;
    },
    viewContact: (state, { payload }: PayloadAction<Uuid>) => {
      state.viewContactId = payload;
    },
    closeViewContact: (state) => {
      state.viewContactId = null;
    },
  },
});

export const {
  loadContactsSuccess,
  createContact,
  editContact,
  deleteContactSuccess,
  createContactSuccess,
  editContactSuccess,
  cancelContactForm,
  viewContact,
  closeViewContact,
} = contactsSlice.actions;

export const loadContacts =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<(ViewContact & Taggable)[]> => {
    // contact.fetchContacts(mockUuid('1d6feaee-04f7-42ec-bd02-b8afe764216e'));
    const contacts = await contact.getContactsList();
    // dispatch(loadContactsSuccess(contacts));
    return contacts;
  };

export const createContactFetch =
  (form_data: NewContactDTO, image?: Image) =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<void> => {
    // try {
    //   const result = await contact.createContact(form_data, image);
    //   dispatch(createContactSuccess(result));
    // } catch (e) {
    //   return e;
    // }
  };

export const deleteContactFetch =
  (contact_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<void> => {
    // try {
    //   await contact.deleteContact(contact_id);
    //   dispatch(deleteContactSuccess(contact_id));
    // } catch (e) {
    //   return e;
    // }
  };

export const getContactView =
  (contact_id: Uuid) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { contact }: Container
  ): Promise<
    Result<{
      contact: ViewContact & Taggable;
      threads: (ViewImThread & { last_im: ViewIm | null; account: ViewAccount })[];
    } | null>
  > => {
    return contact.getContact(contact_id);
  };

export const getContactByAttendee =
  (attendee: Attendee) =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<ViewContact | null> => {
    return contact.getContactByAttendee(attendee);
  };

export const editContactFetch =
  (contact_id: Uuid, form_data: NewContactDTO, image?: Image) =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<void> => {
    // try {
    //   const result = await contact.editContact(contact_id, form_data, image);
    //   if (result) dispatch(editContactSuccess(result));
    //   if (result === null) dispatch(deleteContactSuccess(contact_id));
    // } catch (e) {
    //   return e;
    // }
  };

export const assignAttendeeToContact =
  (contact_id: Uuid, attendee: Attendee, contact_assign: Contact) =>
  async (dispatch: AppDispatch, getState: AppGetState, { contact }: Container): Promise<void> => {
    // const result = await contact.assignAttendeeToContact(attendee, contact_assign);
    // dispatch(editContact({ contact: result, id: contact_id }));
  };

export const contactFormSelector = (state: { contacts: ContactsState }) => {
  return state.contacts.form;
};

export const contactViewSelector = (state: { contacts: ContactsState }) => {
  return state.contacts.viewContactId;
};

export const contactsSelector = (state: { contacts: ContactsState }) => {
  return state.contacts.entities;
};

/**
 * Build a selector to find a contact based on a contact_id
 * @param contact_id
 */
export const contactByIdSelectorFactory = (contact_id: string) => {
  return createSelector(contactsSelector, (contacts) => {
    return contacts.find((contact) => contact.id === contact_id);
  });
};

export default contactsSlice.reducer;
