import {
  calendarsSelector,
  toggleCalendarVisibility,
  useAppDispatch,
  ViewCalendar,
  visibleCalendarIdsSelector,
  CalendarVm,
  availableCalendarsSelector,
  fetchHiddenCalendars,
} from '@focus-front/core';
import { AccountTypeIcon, CalendarEventDefaultIcon } from '@focus-front/ui';
import { IonIcon } from '@ionic/react';
import { Button, Checkbox, List, ListItem, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { caretDownSharp } from 'ionicons/icons';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavButton } from '../Wizard/NavButton';

interface CalendarViewMultiSelectProps {}

const useClasses = makeStyles((theme) => ({
  accountName: {
    fontSize: '16px',
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  calendarListItem: {
    opacity: '1 !important',
  },
  calendarMenuItem: {
    padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    '& span:first-letter': {
      textTransform: 'uppercase',
    },
  },
  multiSelect: {
    fontSize: '14px',
    marginRight: theme.spacing(1),
  },
}));

const CalendarViewMultiSelect: FC<CalendarViewMultiSelectProps> = () => {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * Load hidden calendars from localstorage
   */
  useEffect(() => {
    dispatch(fetchHiddenCalendars());
  }, []);

  const availableCalendars = useSelector(availableCalendarsSelector);
  const visibleCalendarSet = useSelector(visibleCalendarIdsSelector);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarsChange = (calendar: ViewCalendar) => {
    dispatch(toggleCalendarVisibility(calendar));
  };

  /**
   * @todo Replace all the processing done here by a selector that returns something like
   *       a sorted array of tuples that are ready to be mapped in this component :
   *
   *         [accountName, accountCalendars[]][]
   *
   *      ( with the UNIPILE tuple first and primary calendars first in each seq ).
   */
  // Can be improved as we will need both the account and the calendar type for the divisor list
  const accountsMail = Array.from(new Set(availableCalendars?.map((calendar: CalendarVm) => calendar.account_name)));

  // Sorts the Unipile group as the first.
  // It should always contain unipile, but just in case.
  if (accountsMail.includes('Unipile')) {
    const indexUnipile = accountsMail.indexOf('Unipile');
    [accountsMail[0], accountsMail[indexUnipile]] = [accountsMail[indexUnipile], accountsMail[0]];
  }

  return (
    <>
      <NavButton onClick={handleClick} endIcon={<IonIcon icon={caretDownSharp} style={{ fontSize: 12 }} />}>
        {t('calendar.selectAgenda')}
      </NavButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} keepMounted onClose={() => setAnchorEl(null)}>
        <List>
          {accountsMail?.map((accountMail) => (
            <React.Fragment key={accountMail}>
              <ListItem disabled className={c.calendarListItem}>
                {accountMail === 'Unipile' ? (
                  <CalendarEventDefaultIcon size={30} />
                ) : (
                  <AccountTypeIcon account_type="GOOGLE_CALENDAR" size={30} />
                )}
                <div className={c.accountName}>{accountMail}</div>
              </ListItem>
              {availableCalendars
                .filter((calendar) => calendar.account_name === accountMail)
                .map((calendar) => (
                  <MenuItem
                    key={calendar.id}
                    value={calendar.id}
                    onClick={() => handleCalendarsChange(calendar)}
                    className={c.calendarMenuItem}
                  >
                    <Checkbox checked={visibleCalendarSet.has(calendar.id)} style={{ color: calendar.background_color }} />
                    <span>
                      {calendar.type === 'GOOGLE' && calendar?.is_primary && calendar.name.includes('@')
                        ? t('calendar.mainAgenda')
                        : calendar.name}
                    </span>
                  </MenuItem>
                ))}
            </React.Fragment>
          ))}
        </List>
      </Menu>
    </>
  );
};

export default CalendarViewMultiSelect;
