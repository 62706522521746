import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Mail } from '../../domain/Mail';

/**
 * Repository.
 */
export class MailRepo extends AggregateRepo<Mail> {
  constructor(eventStore: DomainEventStore) {
    super(Mail, eventStore);
  }
}
