import './Calendar.scss';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { ClassNamesGenerator, CustomContentGenerator, EventContentArg } from '@fullcalendar/common';

import React, { useCallback, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import {
  eventsSourceSelector,
  toUTCDateTimeMs,
  useAppStore,
  Uuid,
  // visibleCalendarEventsSelector,
} from '@focus-front/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
import CalendarUpdateSnackbar from './CalendarUpdateSnackbar';
import NewCalendarEvent from './NewCalendarEvent';
import useCalendar from './useCalendar';
import { NewCalendarNav } from './NewCalendarNav';
import useCalendarHandlers from './useCalendarHandlers';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

const useClasses = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    border: 'none',
    borderRadius: 6,
    paddingLeft: 5,
    cursor: 'pointer',
  },
  selected: {
    outline: 'solid 2px black',
    zIndex: 10,
  },
  view: {
    maxWidth: '100%',
  },
  label: {
    color: theme.palette.grey[400],
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  now: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
    border: 'none!important',
    boxShadow: theme.shadows[2],
  },
}));

function NewCalendar() {
  const c = useClasses();
  const events = useSelector(eventsSourceSelector);
  const { ref, gotoDate, scrollToTime } = useCalendar();
  const { handleDateChange, handleReceive, handleDateClick, handleEventClick, handleEventChange } = useCalendarHandlers();
  const { palette } = useTheme();
  const location = useLocation();
  const { getState } = useAppStore();
  const { i18n } = useTranslation();

  const draft = useMemo(() => events.find((e) => e.id === 'draft'), [events]);

  /**
   * Effect that go to a time when an event is drafted
   */
  useEffect(() => {
    if (draft) {
      setTimeout(() => {
        gotoDate(draft.start.toString());
        scrollToTime({
          hour: dayjs(draft.start.toString()).get('hour') - 1,
        });
      }, 100);
      /**
       * @note Set timeout is used to let the calendar resize after the form appear, so the scrollToTime don't bug
       */
    }
  }, [!!draft, gotoDate, scrollToTime]);

  const generateClassNames: ClassNamesGenerator<EventContentArg> = useCallback(
    (args: EventContentArg) => {
      const id = args.event.id;
      const classes = [c.container];

      const missed = getState().notifications.entities.find((n) => n.metadata?.origin_event?.id === id);
      if (location.pathname.includes(id) || (missed && location.pathname.includes(missed.id))) classes.push(c.selected);
      return classes;
    },
    [location]
  );

  return (
    <div className={c.wrapper} id="tour-3">
      <NewCalendarNav />
      {/* Find documentation here : https://fullcalendar.io/docs#toc */}
      <FullCalendar
        headerToolbar={null}
        ref={ref}
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin, listPlugin]}
        locale={i18n.language}
        initialView="timeGridDay"
        height="100%"
        nowIndicator
        firstDay={1}
        events={events}
        editable={true}
        droppable={true}
        dayMaxEvents={true}
        fixedWeekCount={false}
        slotDuration={{ minute: 10 }}
        slotLabelInterval={{ minute: 60 }}
        eventClassNames={generateClassNames}
        eventContent={renderEvent}
        slotLabelClassNames={c.label}
        viewClassNames={c.view}
        nowIndicatorClassNames={c.now}
        eventBackgroundColor={palette.secondary.main}
        scrollTime={dayjs().subtract(2, 'h').format('HH:mm:ss')}
        datesSet={handleDateChange}
        eventChange={handleEventChange}
        dateClick={handleDateClick}
        eventReceive={handleReceive}
        eventClick={handleEventClick}
        allDayContent={() => 'Toute la journée'}
      />
      <CalendarUpdateSnackbar />
    </div>
  );
}

const renderEvent: CustomContentGenerator<EventContentArg> = (source) => {
  // Use the default FullCalendar event display for month/list view and all-day events
  if (source.view.type === 'dayGridMonth') return undefined;
  if (source.view.type === 'listWeek') return undefined;
  if (source.event.allDay) return undefined;

  const event = source.event;

  return (
    <NewCalendarEvent
      key={event?.id || 'draft'}
      event={{
        id: event.id as Uuid,
        location: event.extendedProps.location,
        summary: event.title,
        metadata: event.extendedProps.metadata,
        start: toUTCDateTimeMs(dayjs(event.start)),
        end: event.end ? toUTCDateTimeMs(dayjs(event.end)) : null,
        status: event.extendedProps.status,
        all_day: event.allDay,
      }}
      draft={source.event.id === 'draft'}
      preview={!event || source.isDragging}
    />
  );
};

export default React.memo(NewCalendar);
