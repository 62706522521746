import { Publisher, LoggerService, Uuid, Result } from '../../Common';
import { MessageModelUseCase } from './MessageModelUseCase';
import { ViewMessageModel, ViewMessageModelRepo, MessageModel, UpdateMessageModelDTO } from '../domain';
import { MessageModelRepo } from '../infra/repository/MessageModelRepo';
import i18n from '../../Common/infra/services/i18nextService';
import { MailUseCase } from '../../Mail';
import { ViewImThread } from '../../Im';
import { Taggable, ViewTag } from '../../Tag';
import { MESSAGE_MODEL_VARIABLES } from '../constants';

type MessageModelVariables = { [K in typeof MESSAGE_MODEL_VARIABLES[number]]?: string };

export type EmailDeps = {
  type: 'email';
  draft_id: Uuid;
  parent_id?: Uuid;
};

export type IMDeps = {
  type: 'im';
  thread: ViewImThread;
};

export type PreviewMessageModel = {
  id: Uuid;
  content: string;
  tags: ViewTag[];
  destination: 'EMAIL' | 'IM';
};

export class AppMessageModelUseCase implements MessageModelUseCase {
  readonly ERROR_MESSAGE_MODEL_NAME_ALREADY_EXIST = i18n.t('messageModelsSettings.new.errorAlreadyExist');
  readonly ERROR_UNEXPECTED = i18n.t('accountSettings.error.unexpected');

  constructor(
    private readonly publisher: Publisher,
    private readonly mail: MailUseCase,
    private readonly messageModelRepo: MessageModelRepo,
    private readonly viewMessageModelRepo: ViewMessageModelRepo,
    private readonly logger: LoggerService
  ) {}

  async getAllMessageModels(): Promise<Result<(ViewMessageModel & Taggable)[]>> {
    try {
      const messageModels = await this.viewMessageModelRepo.getAllWithTags();
      return { result: messageModels };
    } catch (e) {
      this.logger.captureException(e);
      return { error: 'Unexpected' };
    }
  }

  async createMessageModel(name: string, body?: string): Promise<Result<ViewMessageModel>> {
    try {
      const messageModels = await this.viewMessageModelRepo.getAll();

      if (messageModels.find((model) => model.name === name)) return { error: this.ERROR_MESSAGE_MODEL_NAME_ALREADY_EXIST };

      const { aggregate, changes } = MessageModel.create({ name, body });

      await this.publisher.emit(changes);

      const result = await this.viewMessageModelRepo.get(aggregate.id);

      if (!result) throw new Error("Can't find the messageModel after creation");

      return { result };
    } catch (e) {
      if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError'))
        return { error: 'A messageModel with this label already exists' };
      this.logger.captureException(e);
      console.log(e);
      return { error: "Can't create the messageModel" };
    }
  }
  /**
   *
   */
  async deleteMessageModel(message_model_id: Uuid): Promise<Result<void>> {
    try {
      const messageModel = await this.messageModelRepo.getAggregate(message_model_id);
      const result = messageModel.delete();

      await this.publisher.emit(result.changes);

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async updateMessageModel(message_model_id: Uuid, values: UpdateMessageModelDTO): Promise<Result<ViewMessageModel>> {
    try {
      const messageModel = await this.messageModelRepo.getAggregate(message_model_id);
      const result = messageModel.edit(values);

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        const result = await this.viewMessageModelRepo.get(message_model_id);
        if (!result) throw new Error(`Could not get the edited message model ( ${messageModel.id} )`);
        return { result };
      }

      throw new Error(`Could not edit messageModel ( ${messageModel.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async useMessageModel(
    message_model_id: Uuid,
    count: number,
    deps: EmailDeps | IMDeps
  ): Promise<Result<string>> {
    try {
      const messageModel = await this.messageModelRepo.getAggregate(message_model_id);
      const result = messageModel.use(count);

      await this.publisher.emit(result.changes);

      if (result.ok === true) {
        let message: string;
        switch (deps.type) {
          case 'email':
            message = await this.applyMessageModelToEmailBody(message_model_id, deps)
            break;

          case 'im':
            message = await this.applyMessageModelToIM(message_model_id, deps);
            break;
        }

        return { result: message };
      }

      throw new Error(`Could not use messageModel ( ${messageModel.id} ) : ${result.reason}.`);
    } catch (e) {
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async applyMessageModelToEmailBody(message_model_id: Uuid, { draft_id, parent_id }: EmailDeps): Promise<string> {
    const draft = await this.mail.getDraft(draft_id);

    if (!draft) {
      throw new Error(`Could not find draft : ${draft_id}.`);
    }

    const message_model = await this.viewMessageModelRepo.get(message_model_id);

    if (!message_model) {
      throw new Error(`Could not find message_model : ${message_model_id}.`);
    }

    const { firstName, lastName } = this.getFirstAndLastName(draft?.to_attendees?.[0]?.display_name ?? '');

    const variables: MessageModelVariables = {
      firstname: firstName,
      lastname: lastName,
      email: draft?.to_attendees?.[0]?.identifier ?? '',
    };

    const message = await this.getProcessedMessageModel(message_model, variables);
    const result = await this.mail.getDraftBody(message, parent_id);

    return result;
  }

  /**
   *
   */
  async applyMessageModelToIM(message_model_id: Uuid, { thread }: IMDeps): Promise<string> {
    const message_model = await this.viewMessageModelRepo.get(message_model_id);

    if (!message_model) {
      throw new Error(`Could not find message_model : ${message_model_id}.`);
    }

    const { firstName, lastName } = this.getFirstAndLastName(thread.attendees[0].display_name ?? '');

    const variables: MessageModelVariables = {
      firstname: firstName,
      lastname: lastName,
      phone: thread.attendees[0].identifier,
    };

    const message = await this.getProcessedMessageModel(message_model, variables);

    return message;
  }

  /**
   *
   */
  async previewMessageModel(message_model_id: Uuid, deps: EmailDeps | IMDeps): Promise<PreviewMessageModel> {
    switch (deps.type) {
      case 'email':
        return this.previewMessageModelForEmail(message_model_id, deps.draft_id);

      case 'im':
        return this.previewMessageModelForIM(message_model_id, deps.thread);
    }
  }

  /**
   *
   */
  async previewMessageModelForEmail(message_model_id: Uuid, draft_id: Uuid): Promise<PreviewMessageModel> {
    const draft = await this.mail.getDraft(draft_id);

    if (!draft) {
      throw new Error(`Could not find draft : ${draft_id}.`);
    }

    const message_model = await this.viewMessageModelRepo.get(message_model_id);

    if (!message_model) {
      throw new Error(`Could not find message_model : ${message_model_id}.`);
    }

    const { firstName, lastName } = this.getFirstAndLastName(draft?.to_attendees?.[0]?.display_name ?? '');

    const variables: MessageModelVariables = {
      firstname: firstName,
      lastname: lastName,
      email: draft?.to_attendees?.[0]?.identifier ?? '',
    };

    const message = await this.getProcessedMessageModel(message_model, variables);

    return {
      id: message_model_id,
      content: message,
      tags: message_model.tags,
      destination: 'EMAIL',
    };
  }

  /**
   *
   */
  async previewMessageModelForIM(message_model_id: Uuid, thread: ViewImThread): Promise<PreviewMessageModel> {
    const message_model = await this.viewMessageModelRepo.get(message_model_id);

    if (!message_model) {
      throw new Error(`Could not find message_model : ${message_model_id}.`);
    }

    const { firstName, lastName } = this.getFirstAndLastName(thread.attendees[0].display_name ?? '');

    const variables: MessageModelVariables = {
      firstname: firstName,
      lastname: lastName,
      phone: thread.attendees[0].identifier,
    };

    const message = await this.getProcessedMessageModel(message_model, variables);

    return {
      id: message_model_id,
      content: message,
      tags: message_model.tags,
      destination: 'IM',
    };
  }

  /**
   * Returns the message model with variables applied
   */
  private async getProcessedMessageModel(
    message_model: ViewMessageModel,
    variables: { [key: string]: string | null }
  ): Promise<string> {
    let message = message_model.body;

    // If no date is provided in variables, use the current date
    if (!variables.date) {
      const date = new Date().toLocaleDateString(i18n.t('locale'), {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      });
      message = message?.replace(new RegExp('{{[^}]*date[^}]*}}', 'gi'), date);
    }

    for (const userVariable in variables) {
      message = message?.replace(new RegExp('{{[^}]*' + userVariable + '[^}]*}}', 'gi'), variables[userVariable] || '');
    }

    return message ?? '';
  }

  private getFirstAndLastName(name: string): { firstName: string; lastName: string } {
    const names = name.split(' ');
    const firstName = names[0];
    const lastName = names.slice(1).join(' ');

    return { firstName, lastName };
  }
}