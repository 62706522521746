import { debounce, makeStyles } from '@material-ui/core';
import React, { useRef } from 'react';
import { scrollResults, selectSearchResult, contactResultsSelector, useAppDispatch } from '@focus-front/core';
import ContactSearchResult from '../Contact/ContactSearchResult';
import NoResults from './NoResults';
import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import { useClasses } from './SearchTabProps';

export default function ContactSearchTab() {
  const c = useClasses();
  const { results, scroll, status } = useSelector(contactResultsSelector);
  const dispatch = useAppDispatch();

  const handleScroll = () => {
    const firstRendered = document.querySelector('#search-results > div > div > div');
    if (firstRendered) dispatch(scrollResults({ tab: 'contact', scroll: Number(firstRendered.getAttribute('data-index')) }));
  };

  // if (status === 'LOADING')
  //   return (
  //     <div className={c.wrapper}>
  //       <CallSearchResultFake />
  //       <CallSearchResultFake />
  //       <CallSearchResultFake />
  //       <CallSearchResultFake />
  //     </div>
  //   );
  if (!results.length) return <NoResults />;

  return (
    <div className={c.wrapper}>
      <Virtuoso
        style={{ height: '100%' }}
        totalCount={results.length}
        initialTopMostItemIndex={scroll}
        onScroll={debounce(handleScroll, 100)}
        id="search-results"
        itemContent={(index) => (
          <ContactSearchResult
            key={index}
            index={index}
            contact={results[index]}
            onClick={() => dispatch(selectSearchResult({ type: 'contact', id: results[index].item.id }))}
          />
        )}
      />
    </div>
  );
}
