import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { useClasses } from './NotificationCard';

export default function NotificationCardFake() {
  const c = useClasses({ notification: { selected: false }, selectMode: false });
  return (
    <div className={c.container} style={{ marginBottom: 8, paddingLeft: 34 }}>
      <div className={c.col1} style={{ position: 'relative' }}>
        <Skeleton variant="circle" width={66} height={66} />
      </div>
      <div className={c.col2}>
        <div className={c.sender}>
          <Skeleton variant="text" width={400} />
        </div>
        <div className={c.description}>
          <Skeleton variant="text" width={100} />
        </div>
      </div>
      <div className={c.col3}>
        <div className={c.date}>
          <Skeleton variant="text" width={100} />
        </div>
      </div>
    </div>
  );
}
