import {
  AccountSourceType,
  AccountType,
  ImapConnectionParams,
  OAuthCredentials,
  MicrosoftAccountInfo,
  GoogleOAuthReturn,
  TagColor,
  Uuid,
  ViewTag,
} from '@focus-front/core';
import React from 'react';

/**
 * The Wizard Context helps to build a step by step account wizard.
 */

/**
 * ------------------------------------------------
 * Forms definitions
 * -> Define types for all forms here
 */
export interface ProxyConfigForm {
  proxy_host?: string;
  proxy_port?: number;
  proxy_username?: string;
  proxy_password?: string;
}
export interface MailAccountConfigForm {
  email: string;
}

export interface ImapPasswordForm {
  password: string;
}

export interface LinkedInLoginForm extends ProxyConfigForm {
  linkedin_user: string;
  linkedin_password: string;
  linkedin_cookie: string | undefined;
  use_proxy: boolean;
}

export interface MessengerLoginForm {
  messenger_user: string;
  messenger_password: string;
  messenger_cookies: string | undefined;
}

export interface TikTokLoginForm {
  tiktok_user: string;
  tiktok_password: string;
  tiktok_cookies: string | undefined;
}

export interface TwitterLoginForm {
  twitter_user: string;
  twitter_password: string;
  twitter_cookies: string | undefined;
}

export interface InstagramLoginForm {
  instagram_user: string;
  instagram_password: string;
  instagram_cookies: string | undefined;
}

export interface GoogleMailConfigForm {
  email: string;
  password: string;
}

export const GMAIL_IMAP_CONFIG_DEFAULTS = {
  imap_host: 'imap.gmail.com',
  imap_port: 993,
  smtp_host: 'smtp.gmail.com',
  smtp_port: 465,
};
export interface ICloudMailConfigForm {
  email: string;
  password: string;
}

export const ICLOUD_IMAP_CONFIG_DEFAULTS = {
  imap_host: 'imap.mail.me.com',
  imap_port: 993,
  smtp_host: 'smtp.mail.me.com',
  smtp_port: 587,
};

export interface WhatsAppLoginForm {
  whatsapp_phone_number: string;
  session: string;
}

export interface TagsConfigForm {
  tags: ViewTag[];
}

export type MicrosoftLoginForm = OAuthCredentials & { account: MicrosoftAccountInfo };

export type ImapMailConfigForm = ImapConnectionParams & { imap_password: string; smtp_password: string };

export type GoogleCalendarConfigForm = GoogleOAuthReturn;
/**
 * This is the state type of all forms
 * All forms states are saved
 * - So the user don't lose his data when he navigates between steps
 * - Then we can submit all the data at once for the account addition at the end of the wizard
 * Only one form can be shown per step (for UX reasons), so the key correspond to the step where the form is shown
 */
export interface WizardForms {
  ACCOUNT_CONFIG?: MailAccountConfigForm;
  IMAP_PASSWORD?: ImapPasswordForm;
  IMAP_MAIL_1?: ImapMailConfigForm;
  GOOGLE_MAIL?: GoogleMailConfigForm;
  ICLOUD_MAIL?: ICloudMailConfigForm;
  GOOGLE_CALENDAR_LOGIN?: GoogleCalendarConfigForm;
  MICROSOFT_LOGIN?: MicrosoftLoginForm;
  LINKEDIN_LOGIN?: LinkedInLoginForm;
  MESSENGER_LOGIN?: MessengerLoginForm;
  TIKTOK_LOGIN?: TikTokLoginForm;
  TWITTER_LOGIN?: TwitterLoginForm;
  INSTAGRAM_LOGIN?: InstagramLoginForm;
  WHATSAPP_LOGIN?: WhatsAppLoginForm;
  TAGS_CONFIG?: TagsConfigForm;
  MAIL_HISTORY_CONFIG?: number;
  IM_HISTORY_CONFIG?: number;
  CALL_HISTORY_CONFIG?: number;
}

export type WizardStep =
  | 'ACCOUNT_TYPE_SELECTION'
  | 'ACCOUNT_CONFIG'
  | 'GOOGLE_CALENDAR_LOGIN'
  | 'GOOGLE_CALENDAR_SYNC'
  | 'LINKEDIN_LOGIN'
  | 'MESSENGER_LOGIN'
  | 'TIKTOK_LOGIN'
  | 'INSTAGRAM_LOGIN'
  | 'TWITTER_LOGIN'
  | 'WHATSAPP_LOGIN'
  | 'GOOGLE_MAIL_MANUAL_1'
  | 'GOOGLE_MAIL_MANUAL_2'
  | 'GOOGLE_MAIL_AUTO'
  | 'GOOGLE_MAIL_AUTO_ERROR'
  | 'ICLOUD_MAIL_MANUAL_1'
  | 'ICLOUD_MAIL_MANUAL_2'
  | 'ICLOUD_MAIL_AUTO'
  | 'ICLOUD_MAIL_AUTO_ERROR'
  | 'MICROSOFT_LOGIN'
  | 'IMAP_PASSWORD'
  | 'IMAP_PASSWORD_YAHOO'
  | 'IMAP_PASSWORD_ZOHO'
  | 'IMAP_PASSWORD_AOL'
  | 'IMAP_PASSWORD_GMX'
  | 'IMAP_MAIL_1'
  | 'MAIL_HISTORY'
  | 'CALENDAR_CONFIG'
  | 'CALL_CONFIG'
  | 'IM_CONFIG'
  | 'TAGS_CONFIG'
  | 'SUCCESS_SCREEN'
  | 'IM_TUTORIAL_1'
  | 'IM_TUTORIAL_2'
  | 'IM_TUTORIAL_3';

/**
 * ------------------------------------------------
 */

type Sources = { [key in AccountSourceType]: boolean };

interface ContextType {
  /**
   * Use to go to the next step
   */
  goNext: () => void;

  /**
   * Use to fo to the previous step
   */
  goBack: () => void;

  /**
   * Use to go to a specific step
   */
  goToStep: (step: WizardStep) => void;

  /**
   * Use after a form submit to save the form data
   */
  setForms: (forms: WizardForms) => void;

  /**
   * Use to show/hide a loader over the wizard (if async stuff is needed between steps)
   */
  setLoading: (loading: boolean) => void;

  /**
   * Used by the step 0 and 1 for every account type
   */
  setAlias: (alias: string) => void;
  setAccountType: (type: AccountType) => void;
  setSources: (sources: Sources) => void;
  setSteps: (steps: WizardStep[]) => void;

  setAccountId: ((accountId: Uuid) => void) | null;
  accountId: Uuid | null;

  accountType: AccountType;
  sources: Sources;
  alias: string;
  steps: WizardStep[];
  forms: WizardForms;
}

export const WizardContext = React.createContext<ContextType>({
  accountType: null,
  sources: {
    CALENDAR: false,
    CALLS: false,
    CONTACTS: false,
    IMS: false,
    MAILS: false,
  },
  forms: null,
  alias: '',
  setForms: null,
  setAccountType: null,
  setSources: null,
  goNext: null,
  goBack: null,
  goToStep: null,
  setLoading: null,
  setAlias: null,
  setSteps: null,
  steps: [],
  setAccountId: null,
  accountId: null,
});
