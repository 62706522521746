import { Gmail } from '@focus-front/proxy';

import { GoogleOAuthReturn, OAuthService } from './OAuthService';

/**
 * @todo Consider merging with GoogleAuthenticationProvider family ?
 */
export class GoogleOAuthService implements OAuthService<GoogleOAuthReturn> {
  constructor(private readonly config: { host: string; password: string; username: string }) {}

  async requestAuth(code?: string): Promise<GoogleOAuthReturn> {
    if (!code) {
      throw new Error(
        'As defined, the OAuthServiceService interface requires code to be optional. ' +
          'Changing the interface requires changes in multiple files. ' +
          'In the meantime, we throw if code is undefined.'
      );
    }
    const gmail = new Gmail(this.config);
    const result = await gmail.authenticateWithCode(code);

    return {
      at: result.access_token,
      rt: result.refresh_token,
      identifier: result.email,
    };
  }
}
