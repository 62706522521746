import { AggregateRepo, DomainEventStore } from '../../../Common';
import { ImThread } from '../../domain';

/**
 * Repository.
 */
export class ImThreadRepo extends AggregateRepo<ImThread> {
  constructor(eventStore: DomainEventStore) {
    super(ImThread, eventStore);
  }
}
