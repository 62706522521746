import { EncryptedCredentialsRepo, Signature, AccountUseCase } from '../../Account';
import {
  LocalStorageService,
  DomainEvent,
  DomainEventStore,
  Image,
  KeychainService,
  KeychainServiceBuilder,
  LoggerService,
  Publisher,
  Result,
  CronJobService,
  InvalidPasswordError,
  KeyTypes,
  KeyCannotBeUnwrappedError,
  scrubDomainEvent,
  DebugDigestMap,
} from '../../Common';
import { dumpToFile } from '../../Common/utils/debug';
import { ApiDomainEventDraft, BackupRepoCtor } from '../../Common/domain/BackupRepo';
import {
  BrokenProjectorError,
  Projectionist,
  ProjectionistBuilder,
  ProjectionistConfig,
} from '../../Common/domain/Projectionist';

import { ApiDomainEvent, BackupServiceCtor } from '../../Common/infra/services/BackupService';
import {
  Crypto,
  EncryptionAndWrappingUsages,
  EncryptionCryptoKey,
  ExportedRawKey,
  WrappedKey,
  WrappingCryptoKey,
  WrappingUsages,
} from '../../Common/infra/services/crypto/Crypto';
import { DbService } from '../../Common/infra/services/DbService';
import { KeyStore, KeyTopic } from '../../Common/infra/stores/KeyStore';
import { UserSession, UserParams, AutomaticHelpId } from '../domain';

import { UserRepo } from '../infra/repository/UserRepo';
import { UserUseCase } from './UserUseCase';
import i18n from '../../Common/infra/services/i18nextService';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { BackupCursorRepoCtor } from '../../Common/domain/BackupCursorRepo';
import { Base64 } from '../../Common/utils/Base64';
import { RemoteKeyRepoCtor } from '../../Common/infra/repositories/RemoteKeyRepo';
import { CalendarUseCase } from '../../Calendar';
import {
  AuthenticatorService,
  InvalidCredentialsLoginError,
  InvalidOrExpiredRefreshTokenError,
} from '../infra/services/AuthenticatorService';
import { SessionRepo } from '../infra/repository/session/SessionRepo';
import { ApiService } from '../infra/services/ApiService';
import { Idb } from '../../Common/infra/services/idb';
import { ErrorRecommendations } from '../../Common/app/ErrorRecommendations';
import { UnexpectedError } from '../../Common/app/Errors';
import { LedgerStore } from '../../Common/domain/LedgerStore';
import utc from 'dayjs/plugin/utc';
import { prettyPrint, SingleOrArray, sleep } from '../../Common/utils';
import { SubscriptionOffer } from '../domain/Subscription';
import { BackupKeyRequestResponse } from '../infra/services/CoreApiTypes';
import { KeyCanaryStore, KeyCanaryTriggeredError } from '../../Common/infra/stores/KeyCanaryStore';
import { ScriptsProvidersMap } from '../../_container/interfaces/Container';
import { UserAlreadyExistsError } from '../infra/services/CoreApiService';

dayjs.extend(utc);
const ONBOARDING_STATUS_PREFIX = '__UNIPILE_ONBOARDING_';

export class AppUserUseCase implements UserUseCase {
  ERROR_UNEXPECTED = i18n.t('error.signup_unexpected');
  ERROR_UNEXPECTED_LOGIN = i18n.t('error.login_unexpected');
  ERROR_UNEXPECTED_INIT = i18n.t('error.init_unexpected');
  ERROR_INVALID_CREDENTIALS_LOGIN = i18n.t('error.login_invalid_credentials');
  ERROR_USER_ALREADY_EXIST = i18n.t('error.signup_already_exist');
  ERROR_SESSION_END = i18n.t('error.session_end');

  private keychain: KeychainService | null = null;
  private projectionist: Projectionist<ProjectionistConfig<unknown, DomainEvent>, DomainEvent> | null = null;

  constructor(
    private readonly session: SessionRepo,
    private readonly user: UserRepo,
    private readonly authenticator: AuthenticatorService,
    private readonly api: ApiService,
    private readonly logger: LoggerService,
    private readonly localDB: DbService,
    private readonly encryptedCredentials: EncryptedCredentialsRepo,
    private readonly projectionistConfig: ProjectionistConfig<unknown, DomainEvent>,
    private readonly ProjectionistType: ProjectionistBuilder,
    private readonly ledgerStore: LedgerStore,
    private readonly publisher: Publisher,
    private readonly eventStore: DomainEventStore,
    private readonly BackupType: BackupServiceCtor,
    private readonly BackupRepoType: BackupRepoCtor,
    // private readonly backupCursorRepo: BackupCursorRepo,
    private readonly BackupCursorRepoType: BackupCursorRepoCtor,
    private readonly crypto: Crypto,
    private readonly KeychainType: KeychainServiceBuilder,
    private readonly RemoteKeyRepoType: RemoteKeyRepoCtor,
    private readonly keyStore: KeyStore,
    private readonly keyCanaryStore: KeyCanaryStore,
    private readonly calendarUc: CalendarUseCase,
    private readonly accountUc: AccountUseCase,
    /** @note Part of refresh V0. */
    private readonly cron: CronJobService,
    private readonly storage: LocalStorageService,
    private readonly scriptsProviders: Partial<ScriptsProvidersMap>
  ) {}

  /**
   *
   */
  debugDumpPublisher(): void {
    console.log(this.publisher);
  }

  /**
   *
   */
  async debugDumpEventStore(): Promise<void> {
    const session = await this.session.get();
    if (!session) {
      throw new UnauthorizedError();
    }

    const branchId = await new this.BackupCursorRepoType().getBranchId();

    const dump = await this.eventStore.getDebugDigest();
    console.log('debugDumpEventStore', dump);
    dumpToFile(dump, `EventStoreDigest_${session.id}_${branchId}_${dayjs().utc().format('YYYYMMDDTHHmmss[Z]')}.json`);
    return;
    // return await fetch('/');
  }
  /**
   *
   */
  async debugGetEventStoreDigest(): Promise<{
    name: string;
    dump: DebugDigestMap<DomainEvent>;
  }> {
    const session = await this.session.get();
    if (!session) {
      throw new UnauthorizedError();
    }
    const branchId = await new this.BackupCursorRepoType().getBranchId();

    const dump = await this.eventStore.getDebugDigest();
    return {
      name: `EventStoreDigest_${session.id}_${branchId}_${dayjs().utc().format('YYYYMMDDTHHmmss[Z]')}.json`,
      dump,
    };
  }

  /**
   * @todo Replace with intermediate recovery key.
   */

  /**
   * @todo Consider what could be done to further alleviate the service 'readyness'
   *       issue for the Projectionist. It's been pushed to the edge, in a UseCase,
   *       but there surely must be another way to organize things.
   *       Here, this.projectionist ref is mostly used to make sure we're not instancing
   *       a bunch a Projectionists sharing a LedgerStore. There surely is a better
   *       way as well.
   */
  private async _initBranch(
    user_id: number,
    userKey: EncryptionCryptoKey,
    onProgress?: (messages: SingleOrArray<string>) => void
  ): Promise<void> {
    onProgress?.('login.steps.resetPublisher');
    this.publisher.setStore(this.eventStore);
    this.publisher.clearSubscribers();
    // await sleep(1000);

    const t0 = performance.now();

    if (!this.projectionist) {
      onProgress?.('login.steps.initProjectionist');
      this.projectionist = await this.ProjectionistType.create(this.projectionistConfig, this.ledgerStore, this.eventStore);
      // await sleep(1000);
    }

    onProgress?.('login.steps.catchUpProjectors');
    const report = await this.projectionist.play(this.publisher);
    // await sleep(1000);

    const t1 = performance.now();
    console.log(`_initBranch : projectionist.play() done in : ${t1 - t0} milliseconds.`);

    if (report.length) {
      onProgress?.('login.steps.reportProjectorErrors');
      console.error('Projectionist error report : ', report);
      this.logger.captureException(
        new BrokenProjectorError(
          report.map(({ error, projectorName, event }) => {
            return {
              error,
              projectorName,
              event: scrubDomainEvent(event),
            };
          })
        )
      );
      // await sleep(1000);
    }

    onProgress?.('login.steps.initBackupRepo');
    const backupRepo = new this.BackupRepoType(user_id, new this.BackupCursorRepoType(), this.api);
    // await sleep(1000);

    onProgress?.('login.steps.initBackupService');
    const crypto = this.crypto;

    const encryptMiddleware = async (apiEventDraft: ApiDomainEventDraft): Promise<ApiDomainEventDraft> => {
      /** @todo Consider not mutating and returning a copy ? */
      //   console.log('encryptMiddleware', apiEventDraft.content);
      apiEventDraft.content = await crypto.encrypt(userKey, apiEventDraft.content);
      return apiEventDraft;
    };

    const decryptMiddleware = async (apiEvent: ApiDomainEvent): Promise<ApiDomainEvent> => {
      /** @todo Consider not mutating and returning a copy ? */
      apiEvent.content = await crypto.decrypt(userKey, apiEvent.content as Base64);
      return apiEvent;
    };

    const backup = new this.BackupType(
      user_id,
      backupRepo,
      this.eventStore,
      [encryptMiddleware],
      [decryptMiddleware],
      this.projectionist,
      5 * 1000
    );
    // await sleep(1000);

    onProgress?.('login.steps.pullEvents');
    backup.register(this.publisher);
    // await backup.pull();

    /** @note Part of refresh V0. */
    await this.cron.runCron(
      'pull domain events',
      async () => {
        await backup.pull((mergedResultLength, report) => {
          //   console.log('mergedResultLength', mergedResultLength);
          if (mergedResultLength > 0) {
            if (report.length) {
              console.error('Backupservie merge error report : ', report);
              this.logger.captureException(
                new BrokenProjectorError(
                  report.map(({ error, projectorName, event }) => {
                    return {
                      error,
                      projectorName,
                      event: scrubDomainEvent(event),
                    };
                  })
                )
              );
            }
            /**
             * @todo Consider a separate UI publisher/event bus for refresh events.
             *       Slices can register reactors when they're up and it's innocuous to fire
             *       refresh event event if no one is listening.
             */
            window.location.reload();
          }
        });
      },
      20 * 1000
    );
    // await sleep(1000);
  }

  /**
   * @todo Consider what could be done to further alleviate the service 'readyness'
   *       issue. It's been pushed to the edge, in a UseCase, but there surely
   *       must be another way to organize things.
   */
  async getKey<T extends KeyTopic>(topic: T): Promise<KeyTypes[T]> {
    // console.warn('AppUserUserCase.getKey', topic);
    if (!this.keychain) {
      throw new UninitializedKeychainError('UserUseCase.getKey');
    }
    return this.keychain.get(topic);
  }

  /**
   *
   */
  async getRecoveryCode(): Promise<ExportedRawKey> {
    if (!this.keychain) {
      throw new UninitializedKeychainError('UserUseCase.getRecoveryCode');
    }
    return this.crypto.exportRawUnwrapped(await this.keychain.get('userRecovery'));
  }

  /**
   *
   */
  async dumpRecoveryCode(): Promise<void> {
    const recoveryCode = await this.getRecoveryCode();
    // dumpToFile('dumpToFile ʫ Ͷ Φ 😀', 'test_charset.txt');
    dumpToFile(recoveryCode, 'unipile_recovery_code.txt');
    return;
  }

  /**
   *
   */
  async importRecoveryCode(recoveryCode: ExportedRawKey): Promise<WrappingCryptoKey> {
    return this.crypto.importRawUnwrapped<WrappingUsages>(recoveryCode, true, ['wrapKey', 'unwrapKey']);
  }

  /**
   * Recover the session persisted
   *
   * @note We do not need to authenticate with the API again, the Authenticator has access to
   * the session repository to get the refresh_token and retrieve a JWT.
   * If the refresh_token is expired, it might be a good idea to end the session anyway, for security reasons.
   *
   * @todo Replace with the proton refresh trick.
   */
  async recoverSession(): Promise<Result<UserSession | null>> {
    try {
      const session = await this.session.get();
      const password = await this.session.getPassword();

      //   console.log('UserUseCase.recoverSession session', session);
      /**
       * Since the password is necessary to initialize the app after that, throw the error immediatly
       * and remove the session.
       */
      if (session && !password) {
        await this.session.delete();
        /**
         * @note Consider asking for the user password
         */
        throw new SessionEndedError();
      }

      return {
        result: session,
      };
    } catch (e) {
      if (e instanceof SessionEndedError) return { error: this.ERROR_SESSION_END };
      if (e instanceof Error && e.name in ErrorRecommendations) {
        /** To be able to do or recommend something about the error, we need the error. */
        throw e;
      }
      /** Not something we have a plan for, log it and throw something we can handle. */
      this.logger.captureException(e);
      throw new UnexpectedError();
      //   return { error: this.ERROR_UNEXPECTED_INIT };
    }
  }

  /**
   *
   */
  async deleteBranch() {
    // const digest = await this.eventStore.getDebugDigest();

    // const size = new TextEncoder().encode(JSON.stringify(digest)).length;
    // const kiloBytes = size / 1024;
    // const megaBytes = kiloBytes / 1024;
    // console.log(`${kiloBytes} KiB / ${megaBytes} MiB`);

    // console.log('@todo Send digest to sentry !', digest);

    return this.localDB.clearDomain();
    /**
     * @note WARNING : A new branch has just been started.
     */
    // window.addEventListener('beforeunload', (event) => {
    //   console.warn('App has been reset ! Hit Reload to continue or Cancel to read DebugDigest.');
    //   event.preventDefault();
    //   event.returnValue = '';
    // });
    // window.location.reload();
  }
  /**
   *
   */
  async deleteDb(user_id: number) {
    return this.localDB.delete(user_id);
  }

  /**
   * @todo Replace with more precise pathological aggregate timeline deletion ?
   */
  async clearAccountDataStopGap(user_id: number) {
    const backupRepo = new this.BackupRepoType(user_id, new this.BackupCursorRepoType(), this.api);
    return backupRepo.deleteAllRemoteEventsStopGap();
  }

  /**
   * Authenticate the user with the core API
   */
  async login(username: string, password: string): Promise<Result<UserSession>> {
    try {
      await this.authenticator.login(username, password);

      const session = await this.session.get();
      //   console.log('UserUseCase.login session', session);

      if (!session) throw new Error('Session has not been set after login.');

      /** Retrieve onboarding completion status for that device if any. */
      const deviceCompletion = await this.storage.get(ONBOARDING_STATUS_PREFIX + session.id);
      if (deviceCompletion) {
        session.params.onboarding = deviceCompletion;
      }

      return {
        result: session,
      };
    } catch (e) {
      console.log(e);
      if (e instanceof InvalidCredentialsLoginError) return { error: this.ERROR_INVALID_CREDENTIALS_LOGIN };
      /** Not something we have a plan for, log it and throw something we can handle. */
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED_LOGIN };
      //   /** Not something we have a plan for, log it and notify UI with generic message. */
      //   this.logger.captureException(e);
      //   return { error: this.ERROR_UNEXPECTED_LOGIN };
    }
  }

  /**
   * Inititialise the app for the authenticated user with given password (from login form for example)
   * or with the one found in the sessionRepo when recovering.
   * @param login_password
   */
  async initializeAppForSession(
    login_password?: string,
    onProgress?: (messages: SingleOrArray<string>) => void
  ): Promise<Result<void>> {
    try {
      // prettyPrint({ login_password }, 'initializeAppForSession', 'warn');
      onProgress?.('login.steps.getSession');
      let session = await this.session.get();
      // await sleep(1000);

      this.logger.setSession(session);

      if (!session) throw new UnauthorizedError();

      onProgress?.('login.steps.getPassword');
      const password = login_password || (await this.session.getPassword());
      // await sleep(1000);
      if (!password) throw new Error('Password not found in session to initialize KeyChain, Unexpected');

      onProgress?.('login.steps.getUser');
      const user = await this.user.get(session.id);
      // await sleep(1000);

      let language = user.language;

      if (session.language !== language) {
        onProgress?.('login.steps.setSessionLanguage');
        session = { ...session, language };
        this.session.set(session);
        // await sleep(1000);
      }

      const first_login = !user.params.last_login;

      /**
       * @todo Ask JM to modify route /api/user/registration so that it accepts a language
       *       parameter, then delete this workaround and set user's language
       *       as you register.
       *
       *       API Core seems to only accept 2-letter codes. To avoid risking a 500
       *       error, if whatever language found is not currently supported, we default
       *       to english.
       */
      if (first_login) {
        onProgress?.('login.steps.setUserLanguage');
        const code = i18n.language.split('-')[0].toLowerCase();
        language = code.length === 2 ? code : 'en';
        await this.user.update(session.id, { language });
        // await sleep(1000);
      }

      onProgress?.(['login.steps.openDB', 'login.steps.setAppLanguage', 'login.steps.setUserLanguage']);
      await Promise.all([
        this.localDB.open(session.id),
        i18n.changeLanguage(language),
        this.updateUserParam('last_login', dayjs().toISOString()),
      ]);
      // await sleep(1000);

      try {
        onProgress?.('login.steps.initKeychain');
        this.keychain = await this.KeychainType.create(
          password,
          this.keyStore,
          new this.RemoteKeyRepoType(session.id, this.api),
          this.keyCanaryStore,
          this.crypto
        );
        // await sleep(1000);
      } catch (error) {
        console.warn('initializeAppForSession', error);
        if (error instanceof KeyCanaryTriggeredError) {
          onProgress?.('login.steps.deleteBranch');
          await this.deleteBranch();
          // await sleep(1000);

          onProgress?.('login.steps.resetKeychain');
          this.keychain = await this.KeychainType.create(
            password,
            this.keyStore,
            new this.RemoteKeyRepoType(session.id, this.api),
            this.keyCanaryStore,
            this.crypto
          );
          // await sleep(1000);
        }
      }

      if (this.keychain) {
        /**
         * This is a stopgap to force checking and renewing non-user keys, i.e. 'credentials'
         * before they're used.
         *
         * If they can't be unwrapped, they get renewed.
         *
         * @todo Decide what to do and where it make sense to handle
         *       KeyCannotBeUnwrappedError and replace this.
         */
        try {
          onProgress?.('login.steps.checkingCredentialsKey');
          await this.keychain.get('credentials');
          // await sleep(1000);
        } catch (error) {
          if (error instanceof KeyCannotBeUnwrappedError) {
            onProgress?.('login.steps.shreddingCredentialsKey');
            await this.keychain.shred('credentials');
            // await sleep(1000);
          }
        }

        dayjs.locale(i18n.language);

        await this._initBranch(session.id, await this.keychain.get('user'), onProgress);
      }

      /**
       * If it's the first login of the user, create a default signature
       */
      if (first_login) {
        onProgress?.('login.steps.createDefaultSignature');
        // Create the default signature
        const result = Signature.create({
          name: 'Unipile',
          body: i18n.t('defaultSignature') ?? '',
        });

        if (result.ok === true) {
          await this.publisher.emit(result.changes);
        } else {
          throw new Error('Cannot create default signature');
        }
        // await sleep(1000);
      }

      /**
       * If the user have planned events in the future when he log out, then login after the end datetime of those events,
       * they must be set to missed before the UI load, so the events are already shown
       * as missed and missed_event notifications are already in the inbox.
       *
       * While the user is logged in, a cron notify missed events every minute. (see initNotifyMissedEvents())
       */
      onProgress?.('login.steps.checkMissedCalendarEvents');
      await this.calendarUc.missPastEvents();
      // await sleep(1000);

      /**
       * Initialize scraping scripts
       * Check availability of new scripts remotely and download them.
       */
      onProgress?.('login.steps.checkScriptVersions');
      await Promise.all(Object.values(this.scriptsProviders).map((val) => val.init()));
      // await sleep(1000);

      return {};
    } catch (e) {
      if (e instanceof InvalidPasswordError || e instanceof InvalidOrExpiredRefreshTokenError) {
        return { error: this.ERROR_SESSION_END };
      }
      if (e instanceof Error && e.name in ErrorRecommendations) {
        /** To be able to do or recommend something about the error, we need the error. */
        throw e;
      }

      /** Not something we have a plan for, log it and notify UI with generic message. */
      await this.authenticator.logout();
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED_INIT };
    }
  }

  /**
   *
   */
  async logout(): Promise<Result<void>> {
    try {
      const session = await this.session.get();
      if (session && Idb) {
        this.cron.clearCrons();
        await this.accountUc.unregisterAllAccountSources();
        await this.localDB.close(session.id);
        this.logger.setSession(null);
      }
      await this.authenticator.logout();

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   *
   */
  clearCrons() {
    console.log('clearing crons');
    this.cron.clearCrons();
  }

  /**
   * @todo Replace requestBackupKeyStopGap with another form of challenge/response ?
   *       See notes in api/core route.
   *
   * @todo Update core API resetpassword route to accept new wrapped user key.
   *       CoreApiService.createDevice requires auth and we're not logged in here !
   *
   * @todo Set the new password and replace user key atomically ?
   *
   * @todo Extract import/unwrap/rewrap of keys to some static method on KeychainService.
   */
  async resetPassword(email: string, recoveryCode: string, newPassword: string, requestId: string, token: string) {
    let importedRecoveryKey: WrappingCryptoKey;
    try {
      importedRecoveryKey = await this.importRecoveryCode(recoveryCode as ExportedRawKey);
    } catch (error) {
      return {
        type: 'RECOVERY_CODE_INVALID_FORMAT',
      } as const;
    }

    let backupKeyResponse: BackupKeyRequestResponse;
    try {
      // prettyPrint({ email, recoveryCode, newPassword, requestId, token }, 'resetPassword', 'warn');

      backupKeyResponse = await this.api.requestBackupKeyStopGap(email, requestId, token);
      //   prettyPrint({ backupKeyResponse }, 'resetPassword', 'warn');
    } catch (error) {
      console.log(error);
      return {
        type: 'BACKUPKEYREQUEST_VALIDATION_ERROR',
      } as const;
    }

    if (!backupKeyResponse.user_backup_key) {
      throw new Error('User backup key returned by API is null or empty.');
    }

    /**
     * @todo Parse/validate backupKeyResponse.user_backup_key to assert that it is a WrappedKey ?
     */
    let unwrappedBackupUserKey: EncryptionCryptoKey & WrappingCryptoKey;
    try {
      unwrappedBackupUserKey = await this.crypto.unwrapWithKey<EncryptionAndWrappingUsages>(
        importedRecoveryKey,
        backupKeyResponse.user_backup_key as WrappedKey,
        ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
      );
    } catch (error) {
      return {
        type: 'RECOVERY_CODE_INVALID',
        retryWith: backupKeyResponse,
      } as const;
    }

    try {
      const rewrappedUserKey = await this.crypto.wrap(newPassword, unwrappedBackupUserKey);
      const rewrappedRecoveryKey = await this.crypto.wrap(newPassword, importedRecoveryKey);
      const password_hash = await this.crypto.getStopGapPasswordHash(newPassword, email);

      // prettyPrint({ rewrappedUserKey, rewrappedRecoveryKey, password_hash }, 'resetPassword', 'warn');
      const passwordResetResponse = await this.api.resetPassword(
        email,
        backupKeyResponse.request_id,
        backupKeyResponse.token,
        password_hash,
        rewrappedUserKey,
        rewrappedRecoveryKey
      );
    } catch (error) {
      /** @todo Distinguish between possible errors. */
      return {
        type: 'RESETPASSWORDREQUEST_VALIDATION_ERROR',
        retryWith: backupKeyResponse,
      } as const;
    }
    // prettyPrint({ passwordResetResponse }, 'resetPassword', 'warn');
  }

  /**
   * @todo Replace requestBackupKeyStopGap with another form of challenge/response ?
   *       See notes in api/core route.
   *
   * @todo Update core API resetpassword route to accept new wrapped user key.
   *       CoreApiService.createDevice requires auth and we're not logged in here !
   *
   * @todo Set the new password and replace user key atomically ?
   *
   * @todo Extract import/unwrap/rewrap of keys to some static method on KeychainService.
   */
  async retryResetPassword(email: string, recoveryCode: string, newPassword: string, retryWith: BackupKeyRequestResponse) {
    let importedRecoveryKey: WrappingCryptoKey;
    try {
      importedRecoveryKey = await this.importRecoveryCode(recoveryCode as ExportedRawKey);
    } catch (error) {
      return {
        type: 'RECOVERY_CODE_INVALID_FORMAT',
        retryWith,
      } as const;
    }

    if (!retryWith.user_backup_key) {
      throw new Error('retryResetPassword : retryWith.user_backup_key is null or empty.');
    }

    /**
     * @todo Parse/validate backupKeyResponse.user_backup_key to assert that it is a WrappedKey ?
     */
    let unwrappedBackupUserKey: EncryptionCryptoKey & WrappingCryptoKey;
    try {
      unwrappedBackupUserKey = await this.crypto.unwrapWithKey<EncryptionAndWrappingUsages>(
        importedRecoveryKey,
        retryWith.user_backup_key as WrappedKey,
        ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
      );
    } catch (error) {
      return {
        type: 'RECOVERY_CODE_INVALID',
        retryWith,
      } as const;
    }

    try {
      const rewrappedUserKey = await this.crypto.wrap(newPassword, unwrappedBackupUserKey);
      const rewrappedRecoveryKey = await this.crypto.wrap(newPassword, importedRecoveryKey);
      const password_hash = await this.crypto.getStopGapPasswordHash(newPassword, email);

      prettyPrint({ rewrappedUserKey, rewrappedRecoveryKey, password_hash }, 'resetPassword', 'warn');
      const passwordResetResponse = await this.api.resetPassword(
        email,
        retryWith.request_id,
        retryWith.token,
        password_hash,
        rewrappedUserKey,
        rewrappedRecoveryKey
      );
    } catch (error) {
      /** @todo Distinguish between possible errors. */
      return {
        type: 'RESETPASSWORDREQUEST_VALIDATION_ERROR',
        retryWith,
      } as const;
    }
    // prettyPrint({ passwordResetResponse }, 'resetPassword', 'warn');
  }

  /**
   *
   */
  async resetPasswordWithoutRecoveryCode(email: string, newPassword: string, requestId: string, token: string) {
    /**
     * Even though we don't have a recovery code, we go through the requestBackupKeyStopGap step to
     * get a token for the second step.
     */
    let backupKeyResponse: BackupKeyRequestResponse;
    try {
      backupKeyResponse = await this.api.requestBackupKeyStopGap(email, requestId, token);
    } catch (error) {
      console.log(error);
      return {
        type: 'BACKUPKEYREQUEST_VALIDATION_ERROR',
      } as const;
    }

    if (!backupKeyResponse.user_backup_key) {
      throw new Error('User backup key returned by API is null or empty.');
    }

    /**
     * Generate a new << user, userBackup, userRecovery >> triple.
     */
    try {
      // Generate a user key.
      const userKey = await this.crypto.generateEncryptionAndWrappingKey();
      const wrappedUserKey = await this.crypto.wrap(newPassword, userKey);

      // Generate an extractable recovery key.
      const recoveryKey = await this.crypto.generateWrappingKey();
      const wrappedRecoveryKey = await this.crypto.wrap(newPassword, recoveryKey);

      // Wrap user key with recovery key as backup.
      const wrappedBackupUserKey = await this.crypto.wrapWithKey(recoveryKey, userKey);

      const password_hash = await this.crypto.getStopGapPasswordHash(newPassword, email);

      // prettyPrint({ rewrappedUserKey, rewrappedRecoveryKey, password_hash }, 'resetPassword', 'warn');
      const passwordResetResponse = await this.api.resetPasswordWithoutRecoveryCode(
        email,
        backupKeyResponse.request_id,
        backupKeyResponse.token,
        password_hash,
        wrappedUserKey,
        wrappedRecoveryKey,
        wrappedBackupUserKey
      );
    } catch (error) {
      /** @todo Distinguish between possible errors. */
      return {
        type: 'RESETPASSWORDREQUEST_VALIDATION_ERROR',
        retryWith: backupKeyResponse,
      } as const;
    }
    // prettyPrint({ passwordResetResponse }, 'resetPassword', 'warn');
  }
  /**
   *
   */
  async register(
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    referral_code?: string
  ): Promise<Result<void>> {
    try {
      const password_hash = await this.crypto.getStopGapPasswordHash(password, email);
      await this.user.create({
        email,
        password: password_hash,
        firstname,
        lastname,
        ...(referral_code && { referral_code }),
      });

      return {};
    } catch (e) {
      /** 
       * @note  In the absence of any kind of mitigation, e.g. rate limiting,
       *        sending a password reset email if existing address, this is a
       *        User Enumeration vulnerability. 
       *        Not displaying a specific error message here in the frontend
       *        would not be enough to fix this, the problem is present in the
       *        backend.
       * */
      if (e instanceof UserAlreadyExistsError) {
        return { error: this.ERROR_USER_ALREADY_EXIST };
      }
      this.logger.captureException(e);
      return { error: this.ERROR_UNEXPECTED };
    }
  }

  /**
   *
   */
  async updateProfile(firstname: string, lastname: string, profile_picture: Image | null = null): Promise<UserSession> {
    try {
      const session = await this.session.get();

      if (!session) throw new UnauthorizedError();

      await this.user.update(session.id, { firstname, lastname }, profile_picture);

      const updated_user = await this.user.get(session.id);
      const updated_session: UserSession = {
        ...session,
        firstname: updated_user.firstname,
        lastname: updated_user.lastname,
        ...(updated_user.profile_picture && { profile_picture: updated_user.profile_picture }),
      };

      this.session.set(updated_session);

      return updated_session;
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   *
   */
  private async updateUserParam(key: keyof UserParams, value: string): Promise<UserSession> {
    try {
      const session = await this.session.get();

      if (!session) throw new UnauthorizedError();

      const api_user = await this.user.get(session.id);

      const updated_session: UserSession = {
        ...session,
        ...(api_user.language && { language: api_user.language }),
        params: {
          ...session.params,
          ...api_user.params,
          [key]: value,
        },
      };

      await this.user.update(session.id, updated_session);
      await this.session.set(updated_session);

      return updated_session;
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  /**
   * @note The local system to track onboarding completion has been grafted on
   *       top of the existing system to test the waters. Once the design is
   *       settled and an acceptable granularity for the tracking has been agreed
   *       upon, we should refactor this.
   */
  async completeOnboarding(): Promise<UserSession> {
    const session = await this.updateUserParam('onboarding', 'done');
    this.storage.set(ONBOARDING_STATUS_PREFIX + session.id, 'done');
    return session;
  }

  async discardAutomaticHelp(help: AutomaticHelpId): Promise<UserSession> {
    const session = await this.updateUserParam(help, 'done');
    return session;
  }

  /**
   *
   */
  async completeTour(): Promise<UserSession> {
    return this.updateUserParam('tour', 'done');
  }

  async getAdminLink(): Promise<Result<string>> {
    try {
      const link = await this.api.getAdminLink();

      if (!link) throw new Error('Webadmin link retrieved is empty.');

      return {
        result: link,
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   *
   */
  async initAnonymous(): Promise<void> {
    // await Promise.all([
    //   //   this.credentials.initialize(),
    //   this.localDB.initialize(),
    // ]);
    // throw new Error('Anonymous mode not ( yet? ) supported !');
    console.log('Anonymous mode not ( yet? ) supported !');
  }
}

export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message || 'You must be logged in to do this.');
    this.name = 'UnauthorizedError';
  }
}

export class SessionEndedError extends Error {
  constructor(message?: string) {
    super(message || 'Session ended.');
    this.name = 'SessionEndedError';
  }
}

/**
 *
 */
export class UninitializedKeychainError extends Error {
  constructor(readonly actor?: string) {
    super(`KeychainService has not been initialized yet${actor ? ' to use in ' + actor : ''} !`);
    this.name = 'UninitializedKeychainError';
  }
}
