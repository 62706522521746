import React, { FC, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { makeStyles, TextField } from '@material-ui/core';
import { EventDefaultValues, parseTimeInDates } from '@focus-front/ui';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import { toUTCDateTimeMs, useAppDispatch } from '@focus-front/core';

interface EventFormPickersProps {
  defaultValues: EventDefaultValues;
  formMethods: {
    getValues: Function;
    setValue: Function;
    register: any;
  };
}

const useClasses = makeStyles((theme) => ({
  pickersContainer: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  field: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& ion-icon': {
      marginRight: theme.spacing(1),
      fontSize: 20,
    },
  },
}));

const EventFormPickers: FC<EventFormPickersProps> = ({ defaultValues, formMethods }) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const dispatch = useAppDispatch();
  const { getValues, setValue, register } = formMethods;
  const isDaily = getValues().isDaily !== undefined || null ? getValues().isDaily : defaultValues.all_day;

  /**
   * This useEffect register fromDate/toDate because they are controlled by a useState, not a ref of a TextField
   */
  useEffect(() => {
    register('fromDate');
    register('toDate');
  }, [register]);

  const defaultFrom = dayjs(defaultValues.start);
  const defaultTo = defaultValues.all_day ? dayjs(defaultValues.end).subtract(1, 'day') : dayjs(defaultValues.end);
  const [fromDate, handleFromDate] = useState(defaultFrom);
  const [toDate, handleToDate] = useState(defaultTo);
  const [duration, setDuration] = useState(defaultTo.diff(defaultFrom));

  const handleChangeFromTime = () => {
    const { parsedFromDate } = parseTimeInDates(getValues(), null);

    // prettyPrint({ defaultValues, duration, fromTime, toTime, fromDate, toDate }, 'fromTime onChange');
    const newTo = parsedFromDate.add(duration, 'millisecond');

    handleToDate(newTo);
    setValue('toTime', newTo.format('HH:mm'));
  };

  const handleChangeToTime = () => {
    const { parsedFromDate, parsedToDate } = parseTimeInDates({ ...getValues(), fromDate, toDate }, null);

    const diff = parsedToDate.diff(parsedFromDate, 'millisecond');
    if (diff <= 0) {
      const newFrom = parsedToDate.subtract(duration, 'millisecond');
      handleFromDate(newFrom);
      setValue('fromTime', newFrom.format('HH:mm'));
    } else {
      setDuration(diff);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div className={classes.pickersContainer}>
        <DatePicker
          value={fromDate}
          cancelLabel={t('calendar.form.cancelButton')}
          todayLabel={t('calendar.form.datePickerTodayLabel')}
          format={'DD MMM YYYY'}
          showTodayButton
          onChange={(date) => {
            const { fromTime } = getValues();
            const newFrom = date.hour(+fromTime.slice(0, 2)).minute(+fromTime.slice(3));
            const newTo = newFrom.add(duration, 'millisecond');

            handleFromDate(date);
            handleToDate(newTo);

            // prettyPrint({ duration, date, newFrom, newTo }, 'fromDate onChange');

            setValue('fromDate', date);
            setValue('toDate', newTo);

            // Navigate in the calendar view
            // dispatch(navigateCalendarToDate(toUTCDateTimeMs(date)));
          }}
          InputProps={{
            className: classes.field,
            inputProps: { style: { textTransform: 'capitalize', width: '110px', textAlign: 'center' } },
          }}
          animateYearScrolling
        />
        <TextField
          name="fromTime"
          type="time"
          style={{ display: isDaily ? 'none' : 'inherit' }}
          inputRef={register}
          onBlur={() => handleChangeFromTime()}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleChangeFromTime();
            }
          }}
          InputProps={{
            className: classes.field,
            inputProps: { style: { textTransform: 'capitalize' } },
          }}
          inputProps={{
            step: 300,
          }}
        />
        <div style={{ alignSelf: 'center' }}>-</div>
        <TextField
          id="toTime"
          name="toTime"
          type="time"
          style={{ display: isDaily ? 'none' : 'inherit' }}
          inputRef={register}
          onBlur={() => handleChangeToTime()}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleChangeToTime();
            }
          }}
          InputProps={{
            className: classes.field,
            inputProps: { style: { textTransform: 'capitalize' } },
          }}
          inputProps={{
            step: 300,
          }}
        />
        <DatePicker
          value={toDate}
          cancelLabel={t('calendar.form.cancelButton')}
          todayLabel={t('calendar.form.datePickerTodayLabel')}
          format={'DD MMM YYYY'}
          showTodayButton
          onChange={(date) => {
            const isDaily = getValues().isDaily;
            const { parsedFromDate, parsedToDate } = parseTimeInDates({ ...getValues(), toDate: date }, null);

            // Add logic for allDay
            const diff = parsedToDate.diff(parsedFromDate, 'millisecond');

            if (diff <= 0) {
              const newFrom = parsedToDate.subtract(duration, 'millisecond');
              if (isDaily) {
                handleFromDate(date);
              } else {
                handleFromDate(newFrom);
              }
              setValue('fromDate', parsedFromDate);
              setValue('fromTime', newFrom.format('HH:mm'));
            } else {
              setDuration(diff);
            }

            handleToDate(parsedToDate);
            setValue('toDate', parsedToDate);

            // prettyPrint({ duration, date, parsedFromDate, parsedToDate }, 'toDate onChange');
          }}
          InputProps={{
            className: classes.field,
            inputProps: { style: { textTransform: 'capitalize', width: '110px', textAlign: 'center' } },
          }}
          animateYearScrolling
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default EventFormPickers;
