import { archiveOutline, checkmarkDoneOutline } from 'ionicons/icons';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  doneSelected,
  notificationsSelectedSelector,
  pushInboxSnackbar,
  snoozeSelected,
  toggleSelectAll,
  useAppDispatch,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Button, ButtonGroup, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';

import RefreshButton from './RefreshButton';
import InboxFilters from './InboxFilters';
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons';
import SnoozeSvg from '../../assets/snooze.svg';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  action: {
    backgroundColor: theme.palette.background.paper,

    borderRight: 'none!important',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
    },
    '& ion-icon': {
      fontSize: 19,
    },
  },
  actions: {
    marginLeft: theme.spacing(2),
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

function InboxHeader() {
  const classes = useStyles();
  const { selectMode, number, all } = useSelector(notificationsSelectedSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const checkboxIcon = useMemo(() => {
    if (number === 0) return <CheckBoxOutlineBlank />;
    if (all) return <CheckBox />;
    return <IndeterminateCheckBox />;
  }, [number]);

  const onDone = useCallback(async () => {
    await dispatch(doneSelected());
    dispatch(pushInboxSnackbar(t('notification.doneMessage')));
  }, []);

  const onSnooze = useCallback(async () => {
    dispatch(snoozeSelected());
    dispatch(pushInboxSnackbar(t('notification.snoozeMessageBatch')));
  }, []);

  return (
    <div className={classes.header}>
      <Box display="flex" alignItems="center">
        <IconButton style={{ marginRight: 8 }} onClick={() => dispatch(toggleSelectAll())}>
          {checkboxIcon}
        </IconButton>
        {selectMode ? (
          <>
            <Typography variant="subtitle2">
              {number} {t('inbox.selected')}
            </Typography>
            <ButtonGroup variant="contained" disableElevation className={classes.actions}>
              <Tooltip title={t('action.done')}>
                <Button className={classes.action} onClick={onDone}>
                  <IonIcon slot="icon-only" icon={archiveOutline} />
                </Button>
              </Tooltip>
              <Tooltip title={t('notification.action.snooze')}>
                <Button className={classes.action} onClick={onSnooze}>
                  <IonIcon icon={SnoozeSvg} />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </>
        ) : (
          <RefreshButton />
        )}
      </Box>
      <InboxFilters />
    </div>
  );
}

export default React.memo(InboxHeader);
