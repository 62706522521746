import { Publisher, LoggerService, Uuid, Result } from '../../Common';
import { TagUseCase } from './TagUseCase';
import {
  ViewTag,
  NewTagDTO,
  ViewTagRelationRepo,
  ViewTagRepo,
  Tag,
  TaggableElement,
  ViewTagRelation,
  TagRelation,
} from '../domain';
import { ViewNotification } from '../../Notification';
import { TagRelationRepo } from '../infra/repository/TagRelationRepo';
import { IdbMailMetaRepo } from '../../Mail/infra/repository/IdbMailMetaRepo';
import { SearchService } from '../../Search';
import { ViewCallRepo } from '../../Call';
import { ViewCalendarEventRepo } from '../../Calendar';

export class AppTagUseCase implements TagUseCase {
  constructor(
    private readonly publisher: Publisher,
    private readonly viewTag: ViewTagRepo,
    private readonly viewTagRelation: ViewTagRelationRepo,
    private readonly tagRelation: TagRelationRepo,
    private readonly mailMeta: IdbMailMetaRepo,
    private readonly viewCall: ViewCallRepo,
    private readonly viewCalendarEvent: ViewCalendarEventRepo,
    private readonly search: SearchService,
    private readonly logger: LoggerService
  ) {}

  async getNotificationTags(notification: ViewNotification): Promise<Result<ViewTag[]>> {
    try {
      const element_id = notification.metadata?.attached_entity?.id;
      const element_type = notification.metadata?.attached_entity?.type;

      if (!element_id || !element_type || element_type === 'IM' || element_type === 'MAIL_DRAFT') return { result: [] };

      const result = await this.viewTagRelation.getTagsByElement(element_type, element_id);

      return { result };
    } catch (e) {
      this.logger.captureException(e);
      return { error: 'Unexpected' };
    }
  }

  async getAllTags(): Promise<Result<ViewTag[]>> {
    try {
      const tags = await this.viewTag.getAll();
      return { result: tags };
    } catch (e) {
      this.logger.captureException(e);
      return { error: 'Unexpected' };
    }
  }

  async createTag(tag: NewTagDTO): Promise<Result<ViewTag>> {
    try {
      const { aggregate, changes } = Tag.create(tag);

      await this.publisher.emit(changes);

      const result = await this.viewTag.get(aggregate.id);

      if (!result) throw new Error("Can't find the tag after creation");

      return { result };
    } catch (e) {
      if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError'))
        return { error: 'A tag with this label already exists' };
      this.logger.captureException(e);
      return { error: "Can't create the tag" };
    }
  }

  async updateElementTags(element: TaggableElement, element_id: Uuid, tags: ViewTag[]): Promise<Result<void>> {
    try {
      // Get the actual tag relations of the element
      const relations = await this.viewTagRelation.getByElement(element, element_id);

      // Find the relations to delete based on given tags
      const to_remove: ViewTagRelation[] = relations.filter((relation) => !tags.find((tag) => relation.tag_id === tag.id));

      // Find the tags to create a relation with based on given tags
      const to_add: ViewTag[] = tags.filter((tag) => !relations.find((relation) => relation.tag_id === tag.id));

      // Remove those relations
      const deleteChanges = await Promise.all(
        to_remove.map(async ({ id }) => {
          const agg = await this.tagRelation.getAggregate(id);
          return agg.delete().changes;
        })
      );
      await this.publisher.emit(deleteChanges.reduce((acc, val) => acc.concat(val), []));

      // Create those relations
      await Promise.all(
        to_add.map(async (tag) => {
          const new_dto = { element, element_id, tag_id: tag.id };
          try {
            const { changes } = TagRelation.create(new_dto);
            await this.publisher.emit(changes);
          } catch (e) {
            // If the relation was existing in the past (meaning this tag was once added then removed from the element)
            // Use the restore command
            if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
              const aggId = TagRelation.getUuidFrom(new_dto);
              const agg = await this.tagRelation.getAggregate(aggId);
              const result = agg.restore(new_dto);
              await this.publisher.emit(result.changes);
            }
          }
        })
      );

      // Reindex element
      /**
       * @note This could have been done in searchIndexReactor but its done here for multiple reasons :
       * - We reindex only once for an element, not as many time as there is added / removed relations
       * - When relation is deleted, we don't have enough informations in the deletion event about the element to be re-indexed
       * - The same code must be duplicated for the 3 commands
       */

      switch (element) {
        case 'MAIL': {
          const mail = await this.mailMeta.get(element_id);
          if (mail) await this.search.putMail(mail);
          break;
        }
        case 'ACCOUNT': {
          // Not indexed
          break;
        }
        case 'CALL': {
          const call = await this.viewCall.get(element_id);
          if (call) await this.search.putCall(call);
          break;
        }
        case 'EVENT': {
          const event = await this.viewCalendarEvent.get(element_id);
          if (event) await this.search.putEvent(event);
          break;
        }
        case 'IM_THREAD': {
          // Not indexed
          break;
        }
      }

      return {};
    } catch (e) {
      this.logger.captureException(e);
      return { error: "Can't update element tags: Unexpected error" };
    }
  }
}
