import { makeStyles } from '@material-ui/core';

export interface SearchTabProps {
  query: string;
}

export const useClasses = makeStyles((theme) => ({
  wrapper: {
    // overflowY: 'scroll',
    flex: 1,
    '&::-webkit-scrollbar': {
      width: 6,
      position: 'absolute',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: 3,
    },
  },
}));
