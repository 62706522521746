import dayjs from 'dayjs';

import {
  AggregateRoot,
  createProjectionConfig,
  createUuid,
  createUuidFrom,
  DomainEvent,
  toUTCDateTimeMs,
  UTCDateTimeMs,
  Uuid,
} from '../../Common';
import { SingleOrArray } from '../../Common/utils';
import {
  NotificationDeleted,
  NotificationImUpdated,
  NotificationPlanned,
  NotificationReceived,
  // NotificationReminded,
  NotificationSnoozed,
} from './events/Notification.events';
import { NewNotificationDTO, UpdateNotificationDTO } from './projections/ViewNotification';

/**
 * Namespace UUID for this particular aggregate.
 * Used for deterministic UUID generation.
 */
const NOTIFICATION_NAMESPACE = '0e2751a4-6966-4aae-8f90-634092dd8a48' as Uuid;

/**
 * DecisionProjection initial state.
 */
const initialState = {
  current: 'LIVE' as 'LIVE' | 'DELETED' | 'PLANNED',
  snoozedAt: null as UTCDateTimeMs | null,
};

/**
 * DecisionProjection config.
 */
const projectionConfig = createProjectionConfig(initialState, {
  NOTIFICATION_RECEIVED: (event) => ({ id: event.aggregateId }),
  NOTIFICATION_DELETED: () => ({
    current: 'DELETED',
  }),
  NOTIFICATION_PLANNED: () => ({
    current: 'PLANNED',
  }),
  NOTIFICATION_SNOOZED: (event) => ({
    /**
     * @note Greg : I don't really know what snoozed is used for, it's probably useless
     *              to track this in the decision projection, see viewNotificationProjector.ts
     *              for a place where it does make sense.
     *              This is just to showcase basic event handlers if the time when a
     *              notification was last snoozed was important for other decisions.
     */
    snoozedAt: toUTCDateTimeMs(dayjs(event.createdAt)),
  }),
  // NOTIFICATION_REMINDED: () => ({
  //   snoozedAt: null,
  //   current: 'LIVE',
  // }),
});

// e.g.
// type ReasonNotLive = Exclude<typeof initialState['current'], 'LIVE'>;

/**
 * Aggregate.
 */
export class Notification extends AggregateRoot<typeof initialState> {
  /**
   *
   */
  constructor(events: SingleOrArray<DomainEvent>) {
    super(projectionConfig, events);
  }

  /**
   * Command.
   */
  static create(content: NewNotificationDTO) {
    const aggregateId = content.metadata?.origin_event
      ? /**
         * @note If notification has an origin event, for exemple to notify a missed event, it should be created only once
         */
        createUuidFrom(content.metadata?.origin_event.id + content.type + content.received_date, NOTIFICATION_NAMESPACE)
      : content.metadata?.attached_entity && content.metadata.attached_entity.type !== 'IM_THREAD'
      ? /**
         * @note If a notification has an attached entity, it can have only one notification per type (for exemple a mail can be notified to be received only once)
         * But for an IM_THREAD, once the notification is discarded, the user can still be notified for the same thread in the future. This would be a new notification.
         */
        createUuidFrom(content.metadata.attached_entity.id + content.type, NOTIFICATION_NAMESPACE)
      : createUuid();

    return Notification.accept(new NotificationReceived(aggregateId, content));
  }

  /**
   * Command.
   */
  delete() {
    return this.projection.state.current === 'LIVE'
      ? this.apply(new NotificationDeleted(this.id, this.version)).accept()
      : this.reject(this.projection.state.current);
  }

  /**
   * Command.
   */
  plan() {
    return this.projection.state.current === 'LIVE'
      ? this.apply(new NotificationPlanned(this.id, this.version)).accept()
      : this.reject(this.projection.state.current);
  }

  /**
   * Command.
   */
  snooze() {
    return this.projection.state.current === 'LIVE'
      ? this.apply(new NotificationSnoozed(this.id, this.version)).accept()
      : this.reject(this.projection.state.current);
  }

  /**
   * Command.
   */
  // remind() {
  //   return this.apply(new NotificationReminded(this.id, this.version)).accept();
  // }

  /**
   * Command.
   *
   * @todo Be more specific than ImUpdated ! Why are they updated after they are
   *       received/acknowledged ? It sounds like it means that you can change/edit
   *       an existing message.
   */
  update(values: UpdateNotificationDTO) {
    return this.projection.state.current === 'LIVE'
      ? this.apply(new NotificationImUpdated(this.id, this.version, values)).accept()
      : this.reject(this.projection.state.current);
  }

  //   /**
  //    * Command.
  //    */
  //   async remind(): Promise<void> {
  //     return this.emit(publisher, new NotificationReminded(this.id, this.version));
  //   }
}
