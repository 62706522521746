import { IDBPDatabase } from 'idb';
import { Uuid, UTCDateTimeMs } from '../../../Common';
import { Idb, DBSchema } from '../../../Common/infra/services/idb';
import { createViewIm, ImSyncStatus, UpdateImDTO, ViewIm } from '../../domain/projections/ViewIm';
import { ViewImRepo } from '../../domain/projections/ViewImRepo';

/**
 * Repository.
 */
export class IdbViewImRepo implements ViewImRepo {
  /**
   * @todo See what can be done about Idb import and initialization.
   *       Maybe start by injecting Idb in the constructor ?
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   * @note Put is used for : 'should clobber existing projection on add given projection with existing id'.
   */
  async add(projection: ViewIm) {
    return Idb.put('viewIms', projection);
  }

  /**
   *
   */
  async update(id: Uuid, values: UpdateImDTO) {
    const tx = Idb.transaction('viewIms', 'readwrite');
    const old_projection = await tx.store.get(id);
    if (!old_projection) {
      throw new Error(`Invalid id : ViewIm ${id} does not exist.`);
    }
    /** @note await should be unnecessary here. */
    /** @todo Consider what happens with createXXXX and default values here. */
    tx.store.put(createViewIm(id, old_projection.thread_id, { ...old_projection, ...values }));
    return tx.done;

    // const old_projection = await this.get(id);
    // if (!old_projection) {
    //   throw new Error(`Invalid id : ViewIm ${id} does not exist.`);
    // }
    // return await Idb.put('viewIms', createViewIm(id, old_projection.thread_id, { ...old_projection, ...values }));
  }

  /**
   *
   */
  async remove(id: Uuid) {
    return Idb.delete('viewIms', id);
  }

  async removeByAccount(account_id: Uuid) {
    let cursor = await Idb.transaction('viewIms', 'readwrite')
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  /**
   *
   */
  async clear() {
    return Idb.clear('viewIms');
  }

  /**
   * Query.
   */
  async get(id: Uuid) {
    return (await Idb.get('viewIms', id)) ?? null;
  }

  /**
   * Query.
   */
  async getAll() {
    return Idb.getAll('viewIms');
  }

  /**
   * Query.
   */
  async getByAccount(account_id: Uuid) {
    return Idb.getAllFromIndex('viewIms', 'by-account', IDBKeyRange.only(account_id));
  }

  /**
   * Query.
   */
  async getLatestByAccount(account_id: Uuid) {
    const cursor = await Idb.transaction('viewIms', 'readonly')
      .store.index('by-account-and-date')
      .openCursor(IDBKeyRange.bound([account_id, '0'], [account_id, 'Z']), 'prev');
    //                                           ^^^                ^^^
    //                                            |                  |
    // safe values that you know are lexicographically before/after any possible dates.

    return cursor?.value ?? null;
  }

  /**
   * Query.
   */
  async getLatestByAccountAndSyncStatus(account_id: Uuid, sync_status: ImSyncStatus) {
    const cursor = await Idb.transaction('viewIms', 'readonly')
      .store.index('by-account-and-status-and-date')
      .openCursor(IDBKeyRange.bound([account_id, sync_status, '0'], [account_id, sync_status, 'Z']), 'prev');

    return cursor?.value ?? null;
  }

  /**
   * Query.
   */
  async getLatestByThreadAndSyncStatus(thread_id: Uuid, sync_status: ImSyncStatus) {
    const cursor = await Idb.transaction('viewIms', 'readonly')
      .store.index('by-thread-and-status-and-date')
      .openCursor(IDBKeyRange.bound([thread_id, sync_status, '0'], [thread_id, sync_status, 'Z']), 'prev');

    return cursor?.value ?? null;
  }

  /**
   * Query.
   */
  async getByThread(thread_id: Uuid) {
    return Idb.getAllFromIndex('viewIms', 'by-thread', IDBKeyRange.only(thread_id));
  }

  /**
   * Query.
   */
  async getAllByThreadAndDate(thread_id: Uuid, size: number, offset: number) {
    let cursor = await Idb.transaction('viewIms', 'readonly')
      .store.index('by-thread-and-date')
      .openCursor(IDBKeyRange.bound([thread_id, '0'], [thread_id, 'Z']), 'prev');
    const results: ViewIm[] = [];
    let hasSkipped = false;

    while (results.length < size && cursor) {
      if (!hasSkipped && offset > 0) {
        hasSkipped = true;
        cursor = await cursor.advance(offset);
      }
      if (!cursor) return results;
      results.push(cursor.value);
      cursor = await cursor.continue();
    }
    return results;
  }

  /**
   * Query.
   */
  async getLatestByThread(thread_id: Uuid) {
    const cursor = await Idb.transaction('viewIms', 'readonly')
      .store.index('by-thread-and-date')
      .openCursor(IDBKeyRange.bound([thread_id, '0'], [thread_id, 'Z']), 'prev');
    //                                          ^^^               ^^^
    //                                           |                 |
    // safe values that you know are lexicographically before/after any possible dates.

    return cursor?.value ?? null;
  }

  /**
   * Query.
   */
  async getOldestByThread(thread_id: Uuid) {
    const cursor = await Idb.transaction('viewIms', 'readonly')
      .store.index('by-thread-and-date')
      .openCursor(IDBKeyRange.bound([thread_id, '0'], [thread_id, 'Z']), 'next');
    //                                          ^^^               ^^^
    //                                           |                 |
    // safe values that you know are lexicographically before/after any possible dates.

    return cursor?.value ?? null;
  }

  /**
   * Query.
   */
  async getByAccountAndProviderIdAndSyncStatus(account_id: Uuid, provider_id: string, sync_status: ImSyncStatus) {
    const tx = Idb.transaction('viewIms', 'readonly');
    return (
      (await tx.store
        .index('by-account-and-provider-id-and-status')
        .get(IDBKeyRange.only([account_id, provider_id, sync_status]))) ?? null
    );
  }

  async getFromDateByThread(thread_id: Uuid, from_date: UTCDateTimeMs): Promise<ViewIm[]> {
    return Idb.getAllFromIndex('viewIms', 'by-thread-and-date', IDBKeyRange.bound([thread_id, from_date], [thread_id, 'Z']));
  }
}
