import React, { useContext } from 'react';
import { Box, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { useForm } from 'react-hook-form';
import { ImapPasswordForm, WizardContext } from './WizardContext';
import { Alert } from '@material-ui/lab';

const useClasses = makeStyles((theme) => ({}));

type Props = {
  info?: string;
  help?: JSX.Element;
  appPassword?: boolean;
};

export function ImapPasswordScreen({ help, info, appPassword = false }: Props) {
  const c = useClasses();

  const { forms, setForms, goNext, goBack } = useContext(WizardContext);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: forms.IMAP_PASSWORD,
  });
  const { t } = useTranslation();

  const onSubmit = ({ password }: ImapPasswordForm) => {
    setForms({
      ...forms,
      IMAP_PASSWORD: {
        password,
      },
      IMAP_MAIL_1: {
        ...forms.IMAP_MAIL_1,
        imap_password: password,
        smtp_password: password,
      },
    });
    goNext();
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Typography variant="h2">{t('wizard.imapPassword.title')}</Typography>
        <Typography variant="subtitle2">
          {t(`wizard.imapPassword.${appPassword ? 'subtitleAppPassword' : 'subtitle'}`)}
        </Typography>
        {info && (
          <Box mt={2}>
            <Alert severity="info">{info}</Alert>
          </Box>
        )}
        {help && (
          <Box pt={2} pb={2}>
            <Typography variant="body1">{help}</Typography>
          </Box>
        )}
        <Box pb={4} mt={2}>
          <TextField
            inputRef={register({ required: true })}
            name="password"
            label={t('wizard.accountConfig.appPassword')}
            type="password"
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
            autoFocus
            error={!!errors.password}
          />
        </Box>
        <NextButton type="submit" />
      </form>
    </div>
  );
}

export function ImapPasswordYahoo() {
  const { t } = useTranslation();
  return (
    <ImapPasswordScreen
      appPassword
      info={t('wizard.imapPassword.help.yahoo.summary')}
      help={
        <Trans i18nKey="wizard.imapPassword.help.yahoo.content">
          <ul>
            <li>
              Cliquez sur le lien :{' '}
              <Link
                href="https://login.yahoo.com/myaccount/security/app-password/?src=ym&activity=ybar-acctinfo&pspid=159612001&done=https%3A%2F%2Fmail.yahoo.com%2F%3F.intl%3Dfr%26.lang%3Dfr-FR&scrumb=DZAkPoQ2bql"
                target="_blank"
              >
                Connexion Yahoo
              </Link>
            </li>
            <li>Connectez-vous à votre compte si vous n’êtes pas connecté</li>
            <li>Saisissez unipile dans le champ ”Nom de l’application” et cliquez sur Générer un mot de passe.</li>
            <li>
              Cliquez sur "Copier”, coller ou saisissez les caractères dans le champ Mot de passe ci-dessous. Vous pouvez cliquer
              sur Terminé de la page Yahoo.
            </li>
          </ul>
        </Trans>
      }
    />
  );
}

export function ImapPasswordZoho() {
  const { t } = useTranslation();
  return (
    <ImapPasswordScreen
      info={t('wizard.imapPassword.help.zoho.summary')}
      help={
        <Trans i18nKey="wizard.imapPassword.help.zoho.content">
          <ul>
            <li>
              <Link
                href="https://accounts.zoho.eu/signin?servicename=VirtualOffice&signupurl=https://www.zoho.eu/mail/zohomail-pricing.html&serviceurl=https://mail.zoho.eu"
                target="_blank"
              >
                Connectez-vous sur Zoho Mail
              </Link>
            </li>
            <li>
              Dans l’onglet Paramètres, choisissez Email puis Comptes de Messagerie. Sur le panneau Général, section IMAP, vous
              allez cocher Accès IMAP.
            </li>
            <li>Cliquez sur Enregistrer en bas de la page</li>
          </ul>
        </Trans>
      }
    />
  );
}

export function ImapPasswordAol() {
  const { t } = useTranslation();
  return (
    <ImapPasswordScreen
      appPassword
      info={t('wizard.imapPassword.help.aol.summary')}
      help={
        <Trans i18nKey="wizard.imapPassword.help.aol.content">
          <ul>
            <li>
              Cliquez sur le lien :{' '}
              <Link href="https://login.aol.com/myaccount/security/app-password/?scrumb=ELmj66en.d3" target="_blank">
                Connexion AOL
              </Link>
            </li>
            <li>Connectez-vous à votre compte si vous n’êtes pas connecté.</li>
            <li>Saisissez unipile dans le champ ”Nom de l’application” et cliquez sur Générer un mot de passe.</li>
            <li>
              Cliquez sur "Copier”, coller ou saisissez les caractères dans le champ Mot de passe ci-dessous. Vous pouvez cliquer
              sur Terminé de la page AOL.
            </li>
          </ul>
        </Trans>
      }
    />
  );
}
export function ImapPasswordGmx() {
  const { t } = useTranslation();
  return (
    <ImapPasswordScreen
      info={t('wizard.imapPassword.help.gmx.summary')}
      help={
        <Trans i18nKey="wizard.imapPassword.help.gmx.content">
          <ul>
            <li>
              <Link href="https://www.gmx.fr/#.1730840-header-navlogin2-1" target="_blank">
                Connectez-vous sur votre compte email GMX
              </Link>
            </li>
            <li>Une fois sur l’onglet E-mail (logo e-mail en haut de la page), cliquez sur Paramètres (en bas à gauche)</li>
            <li>
              Ensuite, dans le menu gauche choisissez Fonctions POP3/IMAP et cocher Autoriser l’accès à ce compte via POP3 et
              IMAP.
            </li>
            <li>
              Enfin, cliquez sur Sauvegarder et saisissez les informations de contrôle de sécurité qui s’affiche sur votre écran.
            </li>
          </ul>
        </Trans>
      }
    />
  );
}
