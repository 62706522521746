import { WrappedKey } from '../services/crypto/Crypto';

export const keyTopics = ['user', 'userBackup', 'userRecovery', 'credentials'] as const;
export type KeyTopic = typeof keyTopics[number];

/**
 *
 */
export interface KeyStore {
  get: (topic: KeyTopic) => Promise<WrappedKey | null>;
  getAll: () => Promise<Partial<Record<KeyTopic, WrappedKey>>>;
  put: (topic: KeyTopic, wrappedKey: WrappedKey) => Promise<WrappedKey>;
  invalidate: (topic: KeyTopic) => Promise<void>;
}
