import { closeSuccessSnackbar, calendarSnackbarSelector, useAppDispatch } from '@focus-front/core';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface SnackbarMessage {
  message: string;
  key: number;
}

export default function CalendarUpdateSnackbar() {
  const snackPack = useSelector(calendarSnackbarSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      dispatch(closeSuccessSnackbar());
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      message={messageInfo ? t('event.snackbar.' + messageInfo.message) : undefined}
      action={
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
}
