import { Taggable } from '../../Tag';
import { calendarsDefaultCalendarSelector, eventCreateDone } from '../../Calendar';
import { Uuid } from '../../Common';
import { ActionContext } from '../../Common/domain/Action';
import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/store';
import { ViewCall } from '../domain';

export const getCallView =
  (call_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { call }: Container): Promise<(ViewCall & Taggable) | null> => {
    return call.get(call_id);
  };

export const getAllCalls =
  (size: number, offset: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { call }: Container): Promise<(ViewCall & Taggable)[]> => {
    return call.getAll(size, offset);
  };

export const callDone =
  (context: ActionContext) =>
  async (dispatch: AppDispatch, getState: AppGetState, { call }: Container): Promise<void | string> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());
    if (defaultCalendar) {
      const { result, error } = await call.callDone(defaultCalendar, context);
      if (error) {
        return error;
      }
      if (result) {
        dispatch(eventCreateDone(result));
      }
      return;
    }
    throw new Error('todo ! Unhandled path in callDone thunk : calendar_id is null.');
  };
