import { IDBPDatabase } from 'idb';
import { Uuid } from '../../domain/Uuid';
import { DBSchema, Idb } from '../services/idb';
import { KeyCanary } from './KeyCanaryStore';

/**
 * @todo Consider alternative method for checking sync with branchId or at least
 *       inject a BackupCursorRepo ?
 */
export class IdbKeyCanaryStore {
  // private branch: Uuid | undefined;

  /**
   *
   */
  constructor(private readonly idb: IDBPDatabase<DBSchema> = Idb) {}

  /**
   *
   */
  async get(): Promise<KeyCanary | null> {
    const result = await Idb.getAll('keyCanary');

    if (!result.length) {
      //   throw new MissingCanaryError();
      return null;
    }

    return result[0];
  }

  /**
   *
   */
  async put(canary: KeyCanary): Promise<void> {
    // this.branch = canary.branch;
    const tx = Idb.transaction('keyCanary', 'readwrite');
    tx.store.put(canary);
    return tx.done;
  }

  /**
   *
   */
  async getBranchId(): Promise<Uuid> {
    // return this.branch ?? (this.branch = (await Idb.getAll('backup'))?.[0]?.branch);
    return (await Idb.getAll('backup'))?.[0]?.branch;
  }
}
