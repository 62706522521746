import { archiveOutline, checkmarkDone } from 'ionicons/icons';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IonIcon } from '@ionic/react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';
import {
  imChatSubmit,
  imDone,
  erroredSourceSelector,
  threadViewSelector,
  useAppDispatch,
  unsupportedSourceSelector,
  accountSourcesSelector,
  accountSelector,
  ViewMessageModel,
  PreviewMessageModel,
  useMessageModel,
} from '@focus-front/core';
import { useSelector } from 'react-redux';
import AccountDisconnectedIcon from '../Common/AccountDisconnectedIcon';
import { useDisabledAction, useMessageModelActions } from '@focus-front/ui';
import MessageModelButton from '../MessageModel/MessageModelButton';
import { htmlToText } from 'html-to-text';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 2,
    alignItems: 'flex-end',
  },
  input: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 15,

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  btn: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
    width: 115,
    '& ion-icon': {
      fontSize: 20,
    },
  },
  messageModelIcon: {
    marginLeft: theme.spacing(1),
  },
  discIcon: {
    padding: 10,
    marginBottom: 2,
  },
}));

export default function ImChatAction({ onDone, context }: ActionModalProps) {
  const c = useClasses();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { thread } = useSelector(threadViewSelector);
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>('');

  const focus = () => {
    input.current.focus();
  };

  const setContent = (content: string) => {
    setValue(content);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      setValue(value + '\n');
    } else if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(true);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (isFromInputEnter: boolean) => {
    focus();
    if (value) {
      dispatch(imChatSubmit(value, thread));
      setValue('');
    } else if (!value && isFromInputEnter) {
      setValue('');
    } else {
      const error = await dispatch(imDone(context));
      if (!error) onDone('chat');
      else alert(error);
    }
  };

  const { applyMessageModel, previewMessageModel } = useMessageModelActions(setContent, thread);

  const disabledMessage = useDisabledAction(thread?.account_id, 'IM_THREAD', 'chat');

  return (
    <ActionModal expandable={false}>
      <div className={c.root}>
        <TextField
          id="value"
          name="value"
          multiline
          rows={1}
          rowsMax={10}
          fullWidth
          autoFocus
          InputProps={{
            className: c.input,
          }}
          autoComplete="off"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('chatForm.placeholder')}
          value={value}
          inputRef={input}
        />

        <div className={c.messageModelIcon}>
          <MessageModelButton messageBody={value} applyMessageModel={applyMessageModel} previewMessageModel={previewMessageModel} />
        </div>

        <Button
          color="secondary"
          startIcon={value.length > 0 ? <Send /> : <IonIcon icon={archiveOutline} />}
          className={c.btn}
          onClick={() => handleSubmit(false)}
          disabled={value.length > 0 && !!disabledMessage}
        >
          {t(value.length > 0 ? 'chatForm.send' : 'chatForm.done')}
        </Button>
        {disabledMessage && <AccountDisconnectedIcon title={disabledMessage} className={c.discIcon} />}
      </div>
    </ActionModal>
  );
}
