import { IonIcon } from '@ionic/react';
import { CircularProgress, ListItem, ListItemAvatar, ListItemIcon, ListItemText, makeStyles, Paper } from '@material-ui/core';
import { checkmarkCircle } from 'ionicons/icons';
import React from 'react';

type StepProps = {
  status: 'IDLE' | 'LOADING' | 'DONE';
  title: string;
  subtitle?: string;
  icon: JSX.Element;
};

const useClasses = makeStyles((theme) => ({
  step: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  stepIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    width: 25,
    marginRight: 5,
    fontSize: 20,
  },
}));

export function AutoConfigStep({ status, title, subtitle, icon }: StepProps) {
  const c = useClasses();
  return (
    <Paper variant="outlined" className={c.step}>
      <ListItem>
        <ListItemAvatar>{icon}</ListItemAvatar>
        <ListItemText primary={title} secondary={subtitle || ' '}></ListItemText>
        <ListItemIcon style={{ justifyContent: 'flex-end' }}>
          <>
            {status === 'LOADING' && <CircularProgress size={30} />}
            {status === 'DONE' && <IonIcon style={{ fontSize: 30 }} icon={checkmarkCircle} color="success" />}
          </>
        </ListItemIcon>
      </ListItem>
    </Paper>
  );
}
