import { WebEnvironment } from '../_container/interfaces/Environment';
import { environment as env } from './environment';

export const environment: WebEnvironment = {
  ...env,
  production: true,
  environment: 'staging',
  device_type: 'web',
  sentry: {
    dsn: 'https://e720d6d175b34517824a3a9d4749e5d8@o467030.ingest.sentry.io/5487502',
  },
};
