import dayjs from 'dayjs';
import { stripHtml } from 'string-strip-html';
import { Imap, ImapMail, ImapMailQuery, ImapMailUser, ImapSearchQuery } from '@focus-front/proxy';

import {
  isMailCredentials,
  ImapConnectionParams,
  isGoogleCredentials,
  isICloudCredentials,
  isImapConnectionParams,
} from '../../../Account';
import { EncryptedCredentialsRepo } from '../../../Account/domain/projections/EncryptedCredentialsRepo';
import { FileManagerService, LocalStorageService, toUTCDateTimeMs, Uuid, LocalFile, UTCDateTimeMs } from '../../../Common';
import { MaybePromise, prettyPrint } from '../../../Common/utils';
import { Attendee } from '../../../Contact';
import { MailReference } from '../../domain/projections/MailReference';
import { MailBox, MailSearchQuery, MailService, onFullSyncCallbacks, onFullSyncProgress } from './MailService';
import dompurify from 'dompurify';
import { Crypto, EncryptionCryptoKey } from '../../../Common/infra/services/crypto/Crypto';
import { notEmpty, chunkArray } from '../../../Common/utils';
import { ViewMailDraft, ViewMail, MailAttachment, NewMailDTO, PartialViewMail } from '../../domain';
import { EMAIL_HEADER_DATE_TZ_REGEX, TimeZoneAbbreviations } from './emailHeaderDates';

/**
 *
 */
function timezoneReplacer(match: string, time: string, tzPart: string, offset: number, string: number): string {
  // console.log({ match, time, tzPart, offset, string });
  return `${time} ${TimeZoneAbbreviations[tzPart] ?? '+0000'}`;
}

/**
 *
 */
export class ProxyImapService implements MailService<ImapConnectionParams> {
  /** @todo Consider using a Map<Uuid, Imap>. */
  private connections: Imap[] = [];
  static DEFAULT_MAILBOX = 'DEFAULT';

  constructor(
    private readonly config: { host: string; password: string; username: string },
    private readonly credentials: EncryptedCredentialsRepo,
    private readonly crypto: Crypto,
    private readonly storage: LocalStorageService,
    private readonly fileManager: FileManagerService
  ) {}

  static extractAttendee(user: ImapMailUser): Attendee {
    return {
      display_name: user.name,
      identifier: user.email,
      identifier_type: 'EMAIL_ADDRESS',
    };
  }

  static extractAttachmentsFiles(mail: ImapMail): LocalFile[] {
    if (!mail.has_attachments) return [];
    if (!mail.attachments) throw new Error("Can't extract files from ImapMail, did you use include_attachments = true ?");
    return mail.attachments.map((att) => {
      return {
        data: att.data || '',
        extension: att.extension,
        id: att.id,
        mime: att.mime,
        name: att.name,
        size: att.size,
      };
    });
  }

  static extractAttachmentsMetadata(mail: ImapMail): MailAttachment[] {
    if (!mail.has_attachments) return [];
    if (!mail.attachments) return [];
    return mail.attachments.map((att) => {
      return {
        extension: att.extension,
        id: att.id,
        mime: att.mime,
        name: att.name,
        size: att.size,
      };
    });
  }

  static convertImapMail(mail: ImapMail, account_id: Uuid, mailbox_id: string): NewMailDTO {
    const parseAttempt = dayjs.utc(mail.date);

    const date = parseAttempt.isValid()
      ? (parseAttempt.toISOString() as UTCDateTimeMs)
      : toUTCDateTimeMs(mail.date.replace(EMAIL_HEADER_DATE_TZ_REGEX, timezoneReplacer));

    return {
      account_id,
      from_attendee: ProxyImapService.extractAttendee(mail.from[0]),
      to_attendees: mail.to.map(ProxyImapService.extractAttendee),
      cc_attendees: mail.cc.map(ProxyImapService.extractAttendee),
      bcc_attendees: mail.bcc.map(ProxyImapService.extractAttendee),
      subject: mail.subject,
      body: dompurify.sanitize(mail.text_html || '') || mail.text_plain || '',
      body_plain: mail.text_plain || stripHtml(mail.text_html || '').result,
      date,
      attachments: ProxyImapService.extractAttachmentsMetadata(mail),
      has_attachments: mail.has_attachments,
      reply_to_attendees: mail.reply_to.map(ProxyImapService.extractAttendee),
      provider_id: ProxyImapService.encodeProviderId(account_id, mail, mailbox_id),
    };
  }

  private getConnection(account_id: Uuid): Imap {
    const connection = this.connections.find((connection) => connection.account_id === account_id);
    if (!connection) throw new Error('Imap connection missing');
    return connection;
  }

  static customMessageId(account_id: Uuid, imap_mail: ImapMail): string {
    return `unipile_${imap_mail.from[0].email}${imap_mail.to[0].email}${account_id}${toUTCDateTimeMs(imap_mail.date)}`;
  }

  // Encode the provider_id for a ViewMail to contain a message_id (unique) and the uid (the index in a folder)
  static encodeProviderId(account_id: Uuid, imap_mail: ImapMail, mailbox_id: string): string {
    return JSON.stringify({
      message_id: imap_mail.message_id || this.customMessageId(account_id, imap_mail),
      uid: imap_mail.uid,
      mailbox_id,
    });
  }

  // Decode the provider_id of a ViewMail back to message_id and uid
  static decodeProviderId(provider_id: string): { message_id: string; uid: string; mailbox_id: string } {
    const parsed = JSON.parse(provider_id) as any;
    if (!parsed.message_id || !parsed.uid) throw new Error('Invalid ViewMail.provider_id');
    return {
      message_id: parsed.message_id,
      uid: parsed.uid,
      mailbox_id: parsed.mailbox_id,
    };
  }

  /**
   * @note This is an utility function that retrieves an uid for a specific mail
   *
   * @todo Find UIDs in every Mailbox using getMailboxes
   */
  private async getUid(account_id: Uuid, { date, provider_id }: MailReference): Promise<string | null> {
    const { message_id, mailbox_id } = ProxyImapService.decodeProviderId(provider_id);

    const query: ImapSearchQuery = {
      before: date && dayjs(date).add(1, 'day').format('YYYY-MM-DD'),
      since: date && dayjs(date).format('YYYY-MM-DD'),
      include_body: false,
      include_attachments: false,
      send_attachments: false,
    };

    if (mailbox_id !== ProxyImapService.DEFAULT_MAILBOX) query.pattern = mailbox_id;

    const mails = await this.getConnection(account_id).searchMails(query);

    const found = mails.find(
      (mail) => mail.message_id === message_id || ProxyImapService.customMessageId(account_id, mail) === message_id
    );

    return found?.uid.toString() ?? null;
  }

  registerAccount(account_id: Uuid, connection_params: ImapConnectionParams, credentialsKey: EncryptionCryptoKey): boolean {
    try {
      this.getConnection(account_id);
    } catch (e) {
      const crypto = this.crypto;
      if (!isImapConnectionParams(connection_params)) return false;
      this.connections.push(
        new Imap(
          this.config,
          async (account_id?: string) => {
            /** @note account_id as string because Uuid is difficult to import where Imap is defined. */
            const encryptedCredentials = await this.credentials.get(account_id as Uuid);

            if (!encryptedCredentials) {
              return null;
            }

            const parsed = JSON.parse(await crypto.decrypt(credentialsKey, encryptedCredentials.value));

            return isMailCredentials(parsed)
              ? parsed
              : isGoogleCredentials(parsed) || isICloudCredentials(parsed)
              ? parsed.mail
              : null;
          },
          account_id,
          connection_params
        )
      );
    }
    return true;
  }

  unregisterAccount(account_id: Uuid): void {
    const index = this.connections.findIndex((connection) => connection.account_id === account_id);
    if (index >= 0) this.connections.splice(index, 1);
  }

  /**
   * @note before is exclusive while since is inclusive
   */
  async searchMails(account_id: Uuid, query: MailSearchQuery): Promise<NewMailDTO[]> {
    const { before_date, after_date, mailbox_name, ...params } = query;

    const search_query: ImapSearchQuery = { ...params, include_body: false, include_attachments: false, send_attachments: false };

    if (before_date) search_query.before = dayjs(before_date).format('YYYY-MM-DD');
    if (after_date) search_query.since = dayjs(after_date).format('YYYY-MM-DD');
    if (mailbox_name) search_query.pattern = mailbox_name;

    // First quick search mails uids to see if there is new ones
    const mails_list = await this.getConnection(account_id).searchMails(search_query);
    const uids = mails_list
      .filter((mail) => dayjs(mail.date).isAfter(dayjs(after_date)))
      .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
      .map((mail) => mail.uid);

    if (!uids.length) return [];

    const perChunk = 10; // items per chunk

    const chunks = chunkArray(uids, perChunk);

    // Then do an extra request to get mail content for new ones (chunked 10 by 10)
    let full_mails: NewMailDTO[] = [];

    for (const chunk of chunks) {
      const mail_query: ImapMailQuery = {
        email_uids: chunk.join(','),
        include_body: true,
        include_attachments: true,
        send_attachments: false,
      };

      if (mailbox_name) mail_query.pattern = mailbox_name;

      const result = await this.getConnection(account_id).getMails(mail_query);
      full_mails = full_mails.concat(
        result.map((mail) => ProxyImapService.convertImapMail(mail, account_id, mailbox_name || ProxyImapService.DEFAULT_MAILBOX))
      );
    }

    return full_mails;
  }

  private async _fetchMailsMeta(mails: MailReference[]): Promise<ViewMail[]> {
    const mail_query: ImapMailQuery = {
      email_uids: mails
        .map((mail) => {
          const { uid } = ProxyImapService.decodeProviderId(mail.provider_id);
          return uid;
        })
        .join(','),
      include_body: true,
      include_attachments: false,
      send_attachments: false,
    };

    const { mailbox_id } = ProxyImapService.decodeProviderId(mails[0].provider_id);

    if (mailbox_id !== ProxyImapService.DEFAULT_MAILBOX) mail_query.pattern = mailbox_id;

    const result = await this.getConnection(mails[0].account_id).getMails(mail_query);

    const ret: ViewMail[] = [];

    // Associate ref ids to fetched mails
    for (const ref of mails) {
      const { message_id, mailbox_id } = ProxyImapService.decodeProviderId(ref.provider_id);
      // Find the mail fetched for the reference
      let imap_mail = result.find((imap_mail) => {
        return message_id === imap_mail.message_id;
      });

      if (!imap_mail) {
        // If no mail is corresponding
        // 1. Mail has changed uid, in that case try to fetch the new uid
        const uid = await this.getUid(ref.account_id, ref);

        // 2. The mail was removed remotely but the ref is still exsting, in that case, return nothing
        if (!uid) continue;
        const res = await this._getMailByUid(ref.account_id, uid, mailbox_id, true, false, false);
        if (res.length) imap_mail = res[0];
        else continue;
      }

      const m = ProxyImapService.convertImapMail(imap_mail, ref.account_id, mailbox_id);
      ret.push({ id: ref.id, ...m });
    }

    return ret;
  }

  /**
   * Fetch mails meta of a list of Mail references
   */
  async fetchMailsMeta(mails: MailReference[], onFetch: (results: ViewMail[]) => MaybePromise<void>): Promise<void> {
    // Split by mailbox
    const mailbox_groups: { [key: string]: MailReference[] } = {};
    mails.forEach((mail) => {
      const { mailbox_id } = ProxyImapService.decodeProviderId(mail.provider_id);
      if (!mailbox_groups[mailbox_id]) {
        mailbox_groups[mailbox_id] = [mail];
      } else {
        mailbox_groups[mailbox_id].push(mail);
      }
    });

    // Fetch each mailbox
    await Promise.all(
      Object.keys(mailbox_groups).map(async (mailbox_id) => {
        // Fetch per chunk for performance reasons
        const chunks = chunkArray(mailbox_groups[mailbox_id], 10);
        for (const chunk of chunks) {
          const results = await this._fetchMailsMeta(chunk);
          onFetch(results);
        }
      })
    );
  }

  async getMailsList(account_id: Uuid, mailbox_name: string, offset?: number, limit?: number): Promise<NewMailDTO[]> {
    const full_mails = await this.getConnection(account_id).getMailsList(
      mailbox_name,
      offset && limit ? `${offset}:${offset + limit - 1}` : '1:10',
      true
    );

    return full_mails.map((mail) => ProxyImapService.convertImapMail(mail, account_id, mailbox_name));
  }

  /**
   * @todo Set text content by removing html tags from body property
   */
  async sendMail(account_id: Uuid, mail_draft: ViewMailDraft): Promise<boolean> {
    const attachments = mail_draft.attachment_ids
      ? await Promise.all(mail_draft.attachment_ids.map((attachment_id) => this.fileManager.get(attachment_id, true)))
      : [];

    if (!mail_draft.from_attendee || !mail_draft.to_attendees) throw new Error('Incomplete ViewMailDraft to send');

    const res = await this.getConnection(account_id).sendMail({
      from: mail_draft.from_attendee.identifier,
      to: mail_draft.to_attendees?.map((attendee) => attendee.identifier),
      cc: mail_draft.cc_attendees?.map((attendee) => attendee.identifier) || [],
      bcc: mail_draft.bcc_attendees?.map((attendee) => attendee.identifier) || [],
      reply_to: mail_draft.reply_to_attendees?.map((attendee) => attendee.identifier) || [],
      subject: mail_draft.subject || '',
      text: '',
      html: mail_draft.body || ' ', // Must be blank here otherwise the API throws an error
      attachments: attachments.filter(notEmpty).map((attachment) => ({ filename: attachment.name, data: attachment.data || '' })),
    });

    if (res.status === 'ok') {
      return true;
    }

    return false;
  }

  /**
   *
   */
  async archiveMail(account_id: Uuid, mail: MailReference): Promise<void> {
    const archiveFolderName = 'ARCHIVE UNIPILE';
    const { mailbox, delimiter } = await this.getConnection(account_id).getMailbox();

    const inboxName = mailbox.split('}')[1];

    let folders = await this.getConnection(account_id).getFolders();
    /**
     * Folders have this shape :
     *
     *   {mailbox}Path
     *
     * It's difficult to get an exact match on the Path part as the behaviour
     * seems to vary depending on the provider.
     *
     * If we find a satisfying match, we use it
     */
    let directory = folders.find((folder) => folder.includes(archiveFolderName));

    // prettyPrint({ folders, directory, inboxName, account_id, mailbox, delimiter }, 'archiveMail', 'warn');

    if (!directory) {
      /**
       * Unfortunately, when createFolder fails we can't distinguish between failure types
       * from the error returned by proximap.
       *
       * Neither the library used by proximap, i.e. php-imap, nor the underlying php function,
       * imap_createmailbox, gives any infos about the failure type.
       *
       * Because the folder can be created concurrently by another process/action within the app,
       * we need to ignore some failures.
       *
       * Here, if the folder doesn't exist when we first check, we try to create it.
       * Whether createFolder is a success or not, we check again.
       * If it's there, we use its actual name, otherwise we give up.
       */
      try {
        await this.getConnection(account_id).createFolder({
          name: archiveFolderName,
          pattern: inboxName,
        });
      } catch (e) {
        // console.info('caught and ignored >>>> NEW FOLDER', e);
        /** Ignore. */
      }

      folders = await this.getConnection(account_id).getFolders();
      directory = folders.find((folder) => folder.includes(archiveFolderName));
      if (!directory) {
        throw new Error('ImapService.archiveMail : Could not create/find archive folder.\n' + JSON.stringify(folders, null, 2));
      }
    }

    const uid = await this.getUid(account_id, mail);

    const isGmail = mailbox.includes('imap.gmail.com');

    if (uid) {
      /**
       * @note Many things can happen between the previous step where we retrieve/create the
       *       archive folder and the following step where we try to move some mail to that
       *       folder, e.g. : the folder may have been renamed, removed, etc ...
       *
       *       The bottom line is, this can will sometimes fail !
       */
      await this.getConnection(account_id).moveMails({
        mail_ids: uid,
        pattern: inboxName,
        directory: directory.split('}')[1],
      });
    }
  }

  private async _getMailByUid(
    account_id: Uuid,
    uid: string,
    mailbox_id: string,
    include_body: boolean,
    include_attachments: boolean,
    send_attachments: boolean
  ) {
    const query: ImapMailQuery = {
      email_uids: uid,
      include_attachments,
      send_attachments,
      include_body,
    };

    if (mailbox_id !== ProxyImapService.DEFAULT_MAILBOX) query.pattern = mailbox_id;

    return this.getConnection(account_id).getMails(query);
  }

  private async _fetchMail(
    mail: MailReference,
    include_body: boolean,
    include_attachments: boolean,
    send_attachments: boolean
  ): Promise<ImapMail | null> {
    const { message_id, uid, mailbox_id } = ProxyImapService.decodeProviderId(mail.provider_id);

    let mail_data = await this._getMailByUid(
      mail.account_id,
      uid,
      mailbox_id,
      include_body,
      include_attachments,
      send_attachments
    );

    /**
     * Because the uid might change, we must check if we found the right mail
     * if not, do an extra request to get the new uid
     */
    if (!mail_data.length || mail_data[0].message_id !== message_id) {
      const uid = await this.getUid(mail.account_id, mail);

      if (!uid) return null;

      mail_data = await this._getMailByUid(mail.account_id, uid, mailbox_id, include_body, include_attachments, send_attachments);
    }

    if (!mail_data.length) return null;

    return mail_data[0];
  }

  async fetchMail(
    mail: MailReference,
    send_attachments = false
  ): Promise<{ mail: ViewMail | null; files: LocalFile[] | undefined }> {
    const imap_mail = await this._fetchMail(mail, true, true, send_attachments);

    if (!imap_mail) return { mail: null, files: undefined };

    const { mailbox_id } = ProxyImapService.decodeProviderId(mail.provider_id);

    // If we asked for attachments data, extract them as Local Files, or set files to undefined
    const files: LocalFile[] | undefined = send_attachments ? ProxyImapService.extractAttachmentsFiles(imap_mail) : undefined;

    return {
      files,
      mail: {
        id: mail.id,
        ...ProxyImapService.convertImapMail(imap_mail, mail.account_id, mailbox_id),
      },
    };
  }

  async requestConnectionParams(email: string): Promise<Partial<ImapConnectionParams>> {
    const proxy = new Imap(this.config);
    const config = await proxy.getAutoConfig(email);
    return {
      smtp_user: '',
      imap_user: '',
      imap_host: config.imap_host || '',
      imap_port: config.imap_port ? parseInt(config.imap_port) : 0,
      smtp_host: config.smtp_host || '',
      smtp_port: config.smtp_port ? parseInt(config.smtp_port) : 0,
    };
  }

  async getMailboxes(account_id: Uuid): Promise<MailBox[]> {
    const folders = await this.getConnection(account_id).getFolders();
    const labels = folders.map((folder) => folder.split('}')[1]);
    const mailboxes = await Promise.all(labels.map(async (label) => this.getConnection(account_id).getMailbox(label)));

    return mailboxes.map((mailbox) => ({
      id: mailbox.mailbox.split('}')[1],
      label: mailbox.mailbox.split('}')[1],
      nb_mails: mailbox.nmsgs,
    }));
  }

  async testConnection(connection_params: ImapConnectionParams, credentials: any): Promise<boolean> {
    const proxy = new Imap(this.config, async () => credentials, undefined, connection_params);

    try {
      // Test the connection by doing a simple request like getting folders of the account
      await proxy.getFolders();
      return true;
    } catch (error) {
      return false;
    }
  }
}
