import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, List } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React, { useState } from 'react';

export default function DebugWebView({ id }: { id: string }) {
  const [show, setShow] = useState<boolean>(false);

  const handleShowWebView = async () => {
    setShow(true);
    try {
      await window.electron.scrapingEvents('scraping-show', { id });
    } catch (e) {
      alert(e);
    }
  };
  const handleHideWebView = async () => {
    setShow(false);
    try {
      await window.electron.scrapingEvents('scraping-hide', { id });
    } catch (e) {
      alert(e);
    }
  };

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <Visibility />
        </ListItemIcon>
        <ListItemText primary="Debug WebView" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            checked={show}
            onChange={(event) => {
              if (event.target.checked) handleShowWebView();
              else handleHideWebView();
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export function DebugWebViewOnLaunch() {
  const [checked, setChecked] = useState(!!localStorage.getItem('showDebugMode'));
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <Visibility />
        </ListItemIcon>
        <ListItemText primary="Show debug webview au launch" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            checked={checked}
            onChange={(event) => {
              if (event.target.checked) {
                localStorage.setItem('showDebugMode', 'true');
                setChecked(true);
              } else {
                localStorage.removeItem('showDebugMode');
                setChecked(false);
              }
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
