import { clearNotificationsFilters, notificationsSelector, totalNotificationsSelector, useAppDispatch } from '@focus-front/core';
import { Box, Button, Typography } from '@material-ui/core';
import { Cancel, CancelPresentationRounded, Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ZenMessage from '../Common/ZenMessage';

function InboxFiltersInfos() {
  const notifications = useSelector(notificationsSelector);
  const totalNotifications = useSelector(totalNotificationsSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (notifications.length === totalNotifications) return null;

  const hidden = totalNotifications - notifications.length;

  return (
    <Box mb={1} ml={2} mr={2}>
      <Alert
        icon={false}
        action={
          <Button
            color="inherit"
            size="small"
            variant="text"
            startIcon={<Cancel />}
            onClick={() => dispatch(clearNotificationsFilters())}
          >
            {t('inbox.clearFilters')}
          </Button>
        }
        severity="info"
      >
        <strong>{t('inbox.hiddenMsg', { count: hidden })}</strong>
      </Alert>
      {notifications.length === 0 && (
        <div style={{ height: 400 }}>
          <ZenMessage title={t('inbox.notFound.title')} subtitle={t('inbox.hiddenMsg', { count: hidden })} />
        </div>
      )}
    </Box>
  );
}

export default React.memo(InboxFiltersInfos);
