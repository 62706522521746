import React, { useMemo } from 'react';
import { Avatar, Box, Button, Divider, List, ListItem, makeStyles, Paper, Switch, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  AccountSource,
  AccountSourceType,
  AccountSourceStatus,
  useAppDispatch,
  setSourceStatus,
  syncModalOpened,
  accountCalendarsSelector,
  syncedAccountCalendarsSelector,
  syncModalAccountIdSelector,
  AccountType,
} from '@focus-front/core';
import {
  ArrowForwardIosRounded,
  ArrowRight,
  ArrowRightAltRounded,
  CalendarToday,
  Call,
  Chat,
  CheckCircle,
  Contacts,
  Mail,
  PauseCircleFilled,
  RefreshOutlined,
} from '@material-ui/icons';
import { Alert, Color } from '@material-ui/lab';
import { FullFetchProgress } from './FullFetchProgress';
import { useSelector } from 'react-redux';
import SourceAlert from './SourceAlert';

const useClasses = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 14,
    marginRight: 8,
    marginTop: 2,
  },
  source: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
  },
  sourceIcon: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.grey[800],
  },
  sourceMsg: {
    marginLeft: theme.spacing(1),
  },
  sourceDesc: {
    flex: 1,
  },
  syncCalendarButton: {
    marginTop: theme.spacing(2),
  },
  syncContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  syncList: {
    paddingBottom: 0,
  },
  syncListTitle: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  source: AccountSource;
  account_type: AccountType;
};

const SOURCE_ICON: { [key in AccountSourceType]: JSX.Element } = {
  CALLS: <Call />,
  CALENDAR: <CalendarToday />,
  IMS: <Chat />,
  MAILS: <Mail />,
  CONTACTS: <Contacts />,
};

const STATUS_ICON: { [key in AccountSourceStatus]: JSX.Element } = {
  IDLE: <CheckCircle style={{ color: '#42AF71' }} />,
  FETCHING: <CheckCircle style={{ color: '#42AF71' }} />,
  NOT_SETUP: <PauseCircleFilled style={{ color: '#838990' }} />,
  DISABLED_DEVICE_LIMIT: <PauseCircleFilled style={{ color: '#264C5F' }} />,
  DISABLED_USER_LIMIT: <PauseCircleFilled style={{ color: '#838990' }} />,
  CREDENTIALS: <PauseCircleFilled style={{ color: '#FF7F00' }} />,
  PERMISSIONS: <PauseCircleFilled style={{ color: '#FF7F00' }} />,
  ERROR: <PauseCircleFilled style={{ color: '#E82D2D' }} />,
  PAUSE: <PauseCircleFilled style={{ color: '#FFD700' }} />,
  UNSUPPORTED: <PauseCircleFilled style={{ color: '#264C5F' }} />,
};

export default function AccountSourceCard({ source, account_type }: Props) {
  const c = useClasses();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const syncedAccountCalendars = useSelector(syncedAccountCalendarsSelector(source.account_id));

  const alertEl = useMemo<JSX.Element | null>(() => {
    return <SourceAlert source={source} account_type={account_type} className={c.alert} />;
  }, [source]);

  return (
    <Paper variant="outlined" className={c.source}>
      <Box display="flex" alignItems="center">
        <Avatar className={c.sourceIcon}>{SOURCE_ICON[source.type]}</Avatar>
        <div className={c.sourceDesc}>
          <Typography variant="h5">{t(`accountSettings.sources.name.${source.type}`)}</Typography>
          <Box display="flex" alignItems="center">
            {STATUS_ICON[source.status]}
            <Typography variant="subtitle2" className={c.sourceMsg}>
              {t(`accountSettings.sources.status.${source.status}`)} •{' '}
              {t(`accountSettings.sources.statusMessage.${source.type}_${source.status}`)}
            </Typography>
          </Box>
        </div>
        {(source.status === 'PAUSE' || source.status === 'IDLE' || source.status === 'FETCHING') && (
          <Tooltip title={source.status === 'PAUSE' ? 'Activer la synchronisation' : 'Mettre en pause la synchronisation'}>
            <Switch
              checked={source.status !== 'PAUSE'}
              onChange={() =>
                dispatch(
                  setSourceStatus({
                    ...source,
                    status: source.status === 'PAUSE' ? 'IDLE' : 'PAUSE',
                  })
                )
              }
              color="primary"
            />
          </Tooltip>
        )}
      </Box>
      {source.type === 'CALENDAR' && (
        <div className={c.syncContainer}>
          {/* {!!syncedAccountCalendars?.length && (
            <> */}
          <List className={c.syncList}>
            {syncedAccountCalendars.map((accountCalendar) => (
              <ListItem key={accountCalendar.id}>
                <span className={c.color} style={{ backgroundColor: accountCalendar.background_color }} />
                {accountCalendar.name.includes('@') && accountCalendar.type === 'GOOGLE' && accountCalendar?.is_primary
                  ? t('calendar.mainAgenda')
                  : accountCalendar.name}
              </ListItem>
            ))}
          </List>
          <Button
            onClick={() => dispatch(syncModalOpened(source.account_id))}
            color="primary"
            variant="outlined"
            className={c.syncCalendarButton}
            // startIcon={<AddCircle />}
          >
            {t('accountSettings.sources.syncButton')}
          </Button>
          {/* </>
          )} */}
        </div>
      )}
      {alertEl}
      {source.type === 'MAILS' && <FullFetchProgress account_id={source.account_id} />}
    </Paper>
  );
}
