import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import {
  getMailDraftAttachmentData,
  getSignaturesList,
  saveMailDraft,
  saveMailDraftAttachmentData,
  UpdateMailDraftDTO,
  updateSignatureInHtml,
  Uuid,
  ViewMailDraft,
  useAppDispatch,
  ViewMessageModel,
  PreviewMessageModel,
  useMessageModel,
} from '@focus-front/core';
import { Attachment, useDisabledAction, useMessageModelActions } from '@focus-front/ui';
import { Box, Button, CircularProgress, Divider, IconButton, Link as L, makeStyles, TextField, Tooltip } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AttendeesField from './AttendeesField';
import FromAccountField from './FromAccountField';
import { useConfirm } from 'material-ui-confirm';
import AccountDisconnectedIcon from '../Common/AccountDisconnectedIcon';
import { useSelector } from 'react-redux';
import TipTapUnipile from '../Common/TipTapUnipile/TipTapUnipile';
import { withStyles } from '@material-ui/styles';
import MessageModelButton from '../MessageModel/MessageModelButton';

const useClasses = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& > *:last-child': {
      flex: '1',
    },

    marginTop: theme.spacing(1),
  },
  label: {
    textTransform: 'capitalize',
    width: 50,
  },
  ccBtns: {
    position: 'absolute',
    right: 0,
    '& > *': {
      marginLeft: 6,
      cursor: 'pointer',
    },
  },
  btnContainer: {
    display: 'flex',
  },
  draftMsg: {
    // position: 'absolute',
    // right: theme.spacing(2),
    // bottom: 65,
  },
  attachmentsContainer: {
    display: 'flex',
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  aboveToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export type MailFormAttendee = {
  display_name: string;
  email: string;
  email_label?: string;
  avatar_url?: string;
};

interface FormInputs {
  account_id?: Uuid;
  from?: string;
  subject?: string;
  to?: MailFormAttendee[];
  cc?: MailFormAttendee[];
  bcc?: MailFormAttendee[];
  body?: string;
  attachment_ids?: string[];
}

interface Props {
  defaultValues: ViewMailDraft;
  onSend: (values: ViewMailDraft) => void;
  onToUpdate?: (to: MailFormAttendee[]) => void;
  action_id: 'reply' | 'write' | 'transfer';
  onReplyToAllClick?: () => void;
  parent_id?: Uuid;
}

const Link = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
  },
}))(L);

export default function MailForm({ defaultValues, onSend, onToUpdate, action_id, onReplyToAllClick, parent_id }: Props) {
  const classes = useClasses();
  const dispatch = useAppDispatch();
  const mounted = useRef(false);
  const { t } = useTranslation();

  const { register, handleSubmit, control, formState, watch, setValue, getValues } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      account_id: defaultValues.account_id,
      from: defaultValues.from_attendee?.identifier || '',
      subject: defaultValues.subject || '',
      to: defaultValues.to_attendees?.map((to) => ({ email: to.identifier, display_name: to.display_name })) || [],
      cc: defaultValues.cc_attendees?.map((to) => ({ email: to.identifier, display_name: to.display_name })) || [],
      bcc: defaultValues.bcc_attendees?.map((to) => ({ email: to.identifier, display_name: to.display_name })) || [],
      body: defaultValues.body || '',
      attachment_ids: defaultValues.attachment_ids || [],
    },
  });
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [saved, setSaved] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [invalidAtt, setInvalidAtt] = useState<boolean>(false);
  const confirm = useConfirm();

  const setContent = (content: string) => {
    setValue('body', content);
  }

  function inputsToDraft(inputs: FormInputs): UpdateMailDraftDTO {
    return {
      account_id: inputs.account_id,
      body: inputs.body,
      subject: inputs.subject,
      attachment_ids: inputs.attachment_ids,
      from_attendee: {
        display_name: inputs.from,
        identifier: inputs.from,
      },
      to_attendees: inputs.to.map((to) => ({
        display_name: to.display_name,
        identifier: to.email,
      })),
      cc_attendees: inputs.cc?.map((cc) => ({
        display_name: cc.display_name,
        identifier: cc.email,
      })),
      bcc_attendees: inputs.bcc?.map((bcc) => ({
        display_name: bcc.display_name,
        identifier: bcc.email,
      })),
    };
  }

  const onSubmit = async (values: FormInputs) => {
    if (invalidAtt)
      await confirm({
        title: t('attachment.notFoundWarning.title'),
        description: t('attachment.notFoundWarning.description'),
        confirmationText: t('attachment.notFoundWarning.btnOk'),
        cancellationText: t('attachment.notFoundWarning.btnCancel'),
        confirmationButtonProps: {
          color: 'secondary',
        },
      });

    setIsSending(true);
    setTimeout(() => {
      onSend({ ...defaultValues, ...inputsToDraft(values) });
      setIsSending(false);
    }, 1000);
  };

  const autoSave = () => {
    if (!mounted.current) return;
    dispatch(saveMailDraft(defaultValues.id, inputsToDraft(getValues())));
    setSaved(true);
  };

  const subject = watch('subject');
  const body = watch('body');
  const from = watch('from');
  const to = watch('to');
  const cc = watch('cc');
  const bcc = watch('bcc');
  const attachment_ids = watch('attachment_ids');
  const account_id = watch('account_id');

  const disabledMessage = useDisabledAction(account_id, 'MAIL', action_id);

  const { applyMessageModel, previewMessageModel } = useMessageModelActions(setContent, defaultValues, parent_id);

  const handleChangeAcc = async (acc: { id: Uuid; signature?: Uuid }) => {
    // Update the signature on "from" change
    if (acc.signature) {
      const { result, error } = await dispatch(getSignaturesList());
      if (error) return alert(error);

      setValue(
        'body',
        updateSignatureInHtml(
          getValues().body,
          result.find((sign) => sign.id === acc.signature)
        )
      );
    }

    // Update the account_id field on "from" change
    setValue('account_id', acc.id);
  };

  /**
   * For input-type fields, auto save with 1 sec debounce
   */
  useEffect(() => {
    setSaved(false);
  }, [body, subject, from, to, cc, bcc, attachment_ids, account_id]);
  useDebounce(
    () => {
      if (!saved) autoSave();
    },
    1000,

    [body, subject, from, to, cc, bcc, attachment_ids, account_id]
  );

  useEffect(() => {
    if (cc.length > 0) setShowCC(true);
  }, [cc]);

  /**
   * Trigger onToUpdate when to field change
   */
  useEffect(() => {
    if (mounted.current) onToUpdate && onToUpdate(to);
  }, [to.length]);

  const handleAttachments = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    const attachments = Array.from(files).map(async (file) => {
      const attachment = await dispatch(saveMailDraftAttachmentData(file));
      return attachment;
    });
    const res = await Promise.all(attachments);
    setValue('attachment_ids', [...getValues().attachment_ids, ...res.map((att) => att.id)]);
  };

  const handleDeleteAtt = (id: string) => {
    setValue(
      'attachment_ids',
      getValues().attachment_ids.filter((att) => att !== id)
    );
  };

  /** Lazy load attachments data */
  useEffect(() => {
    (async () => {
      // Get attachmetnts data from local storage from attachment_ids
      const attachments_with_data = await Promise.all(
        attachment_ids.map((attachment_id) => dispatch(getMailDraftAttachmentData(attachment_id)))
      );
      /**
       * Assign data to the list of attachments ids. If data was not found (cleared local storage or file uploaded from another device)
       * Set "not found" data and set InvalidAtt to true so we can show a warning on send.
       * Set size to 1 to make it status as "loaded"
       **/
      setAttachments(
        attachment_ids.map((id) => {
          const found = attachments_with_data.find((att) => att?.id === id);
          if (found) return found;
          setInvalidAtt(true);
          return { id, extension: '', mime: '', name: t('attachment.notFound'), size: 1 };
        })
      );
    })();
  }, [attachment_ids, dispatch]);

  /**
   * Register manually the attachment_ids field on the form because there is no visible
   * input in the dom to automatically register it
   */
  useEffect(() => {
    register('attachment_ids');
    register('account_id');
    mounted.current = true;
  }, []);

  return (
    <form className={classes.container} autoComplete="off">
      <div className={classes.inputRow}>
        <div className={classes.label}>{t('mailForm.to')}</div>
        <Controller
          control={control}
          name="to"
          rules={{
            validate: (val) => {
              return val.length > 0;
            },
          }}
          render={({ onChange, value }) => <AttendeesField value={value} onChange={onChange} />}
        />
        <div className={classes.ccBtns}>
          {action_id === 'reply' && <Link onClick={onReplyToAllClick}>{t('mailForm.reply_to_all')}</Link>}
          {!showCC && <Link onClick={() => setShowCC(true)}>{t('mailForm.cc')}</Link>}
          {!showBCC && <Link onClick={() => setShowBCC(true)}>{t('mailForm.bcc')}</Link>}
        </div>
      </div>

      <div className={classes.inputRow} style={{ display: showCC ? 'flex' : 'none' }}>
        <div className={classes.label}>{t('mailForm.cc')}</div>
        <Controller
          control={control}
          name="cc"
          render={({ onChange, value }) => <AttendeesField value={value} onChange={onChange} />}
        />
      </div>

      <div className={classes.inputRow} style={{ display: showBCC ? 'flex' : 'none' }}>
        <div className={classes.label}>{t('mailForm.bcc')}</div>
        <Controller
          control={control}
          name="bcc"
          render={({ onChange, value }) => <AttendeesField value={value} onChange={onChange} />}
        />
      </div>

      <div className={classes.inputRow}>
        <div className={classes.label}>{t('mailForm.subject')}</div>
        <TextField name="subject" variant="standard" fullWidth inputRef={register()} />
      </div>
      <div className={classes.inputRow}>
        <div className={classes.label}>{t('mailForm.from')}</div>
        <Controller
          control={control}
          name="from"
          rules={{
            required: true,
          }}
          render={(props) => <FromAccountField {...props} onChangeAccount={handleChangeAcc} />}
        />
      </div>

      <Controller
        control={control}
        name="body"
        render={({ onChange, value }) => (
          <TipTapUnipile
            initialContent={value}
            onChange={onChange}
            isEditorMail
            isSaved={saved}
            minifiedToolbar
            upperToolbar={
              !!attachments.length && (
                <div className={classes.attachmentsContainer}>
                  {attachments.map((attachment) => (
                    <Attachment
                      key={attachment.id}
                      attachment={attachment}
                      loaded={!!attachment.size}
                      mode="upload"
                      onDelete={handleDeleteAtt}
                    />
                  ))}
                </div>
              )
            }
            lowerToolbar={
              <div className={classes.actions}>
                <MessageModelButton messageBody={body} applyMessageModel={applyMessageModel} previewMessageModel={previewMessageModel} />
                <IconButton
                  style={{ marginRight: '5px', padding: '7px' }}
                  onClick={() => document.getElementById('upload_attachments_editor').click()}
                >
                  <AttachmentIcon />
                </IconButton>

                <input
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                  style={{ display: 'none' }}
                  id="upload_attachments_editor"
                  multiple
                  type="file"
                  onChange={handleAttachments}
                />
                {!!disabledMessage && (
                  <Box p={2}>
                    <AccountDisconnectedIcon title={disabledMessage} />
                  </Box>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={isSending && <CircularProgress size={24} />}
                  disabled={!formState.isValid || !!disabledMessage || isSending}
                  onClick={handleSubmit(onSubmit)}
                >
                  {!isSending && t('mailForm.send')}
                </Button>
              </div>
            }
          />
        )}
      />
    </form>
  );
}
