export type SubscriptionOffer = 'Personal' | 'Boost' | 'Team' | 'Enterprise';

/**
 * @note Use hundreds as level value to allow new level in-between if needed
 */
export const SubscriptionLevel: { [key in SubscriptionOffer]: number } = {
  Personal: 100,
  Boost: 200,
  Team: 300,
  Enterprise: 400,
};

export function isSubscriptionOffer(string: string): string is SubscriptionOffer {
  return string === 'Personal' || string === 'Boost' || string === 'Team' || string === 'Enterprise';
}

export interface Invitation {
  id: number;
  email?: string;
  phone_number?: string;
  status?: string;
  creation_date?: string;
  last_update_date?: string;
}
