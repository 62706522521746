import { MicrosoftOAuthReturn, OAuthService } from './OAuthService';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MicrosoftAccountInfo } from '../../domain';
import scopes from '../../constants/microsoft_scopes';

export class MicrosoftOAuthService implements OAuthService<MicrosoftOAuthReturn> {
  private instance: PublicClientApplication;
  constructor(clientId: string) {
    this.instance = new PublicClientApplication({ auth: { clientId }, cache: { cacheLocation: 'sessionStorage' } });
  }

  async requestAuth(code?: string, account?: MicrosoftAccountInfo): Promise<MicrosoftOAuthReturn> {
    // Show account selection Popup

    const resp = await this.instance.acquireTokenPopup({
      prompt: 'login',
      loginHint: account?.username,
      scopes,
    });

    // The AT is in the response
    const at = resp.accessToken;

    // But the RT is stored in the sessionStorage (Token refresh is supposed to be managed internaly
    // by the MSAL Library so it's a trick to use it like we want)
    let rt = '';
    const rt_session_storage_key =
      resp.uniqueId +
      '.' +
      resp.tenantId +
      '-login.windows.net-refreshtoken-' +
      (resp.idTokenClaims as { aud: string }).aud +
      '--';
    const data = sessionStorage.getItem(rt_session_storage_key);
    if (data) {
      const rt_storage = JSON.parse(data) as { secret: string };
      if (rt_storage && rt_storage.secret) {
        rt = rt_storage.secret;
      }
    }

    if (rt === '') throw new Error('Cannot retrieve the Refresh Token');
    if (!at) throw new Error('Microsoft OAuth return does not contain Access Token.');
    if (!resp.account) throw new Error('Microsoft OAuth return does not contain the account.');

    sessionStorage.removeItem(rt_session_storage_key);

    return {
      at,
      rt,
      account: {
        id: resp.account.localAccountId,
        username: resp.account.username,
      },
    };
  }
}
