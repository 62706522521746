import {
  GoogleCalendarAttendee,
  NewCalendarEventDTO,
  toUTCDateTimeMs,
  Tzid,
  UnipileEventAttendee,
  UTCDateTimeMs,
  Uuid,
} from '@focus-front/core';
import { rrulestr } from '@keep_focus/rrule-tz/dist/esm/src/rrulestr';
import dayjs, { Dayjs } from 'dayjs';
import { RecurringCalendarEventRootStrip } from 'libs/core/src/Calendar/domain/projections/RecurringCalendarEventRoot';
import { rewriteTzidAs, RRuleString, TZID_REGEX } from 'libs/core/src/Common/domain/RRule';

export type RecurringEventSelectOptions = 'SINGLE' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY' | 'WEEKDAY' | 'CUSTOM';
export interface RecurrencyInput {
  kind: 'SINGLE' | 'RECURRING';
  recurrence?: RRuleString[];
  selectValue: RecurringEventSelectOptions;
}

export type EventFormInputs = UnipileFormInputs | GoogleCalendarFormInputs;
export interface FormInputs {
  isDaily: boolean;
  title?: string;
  description?: string;
  fromDate: Dayjs;
  fromTime: string;
  toDate: Dayjs;
  toTime: string;
  recurrency: RecurrencyInput;
  location?: string;
}

export interface UnipileFormInputs extends FormInputs {
  type: 'UNIPILE';
  attendees: UnipileEventAttendee[];
}

export interface GoogleCalendarFormInputs extends FormInputs {
  type: 'GOOGLE';
  attendees: GoogleCalendarAttendee[];
}

export type EventDefaultValues = NewCalendarEventDTO & {
  recurrency?: RecurrencyInput;
  recurring_event_id?: Uuid;
  fromTime?: Dayjs;
  toTime?: Dayjs;
};

export function parseRRuleStrings(rules: RRuleString[], start: UTCDateTimeMs, tzid?: Tzid | null) {
  tzid ??= 'Etc/UTC' as Tzid;
  /**  Rewrite all rules with given TZID. */
  rules = rules.map((rule) => rule.replace(TZID_REGEX, rewriteTzidAs(tzid as Tzid)) as RRuleString);
  const rruleString = `DTSTART;TZID=${tzid}:${dayjs(start).tz(tzid).format('YYYYMMDDTHHmmss')}\n` + rules.join('\n');

  return rrulestr(rruleString);
}

/**
 * @todo Extend this function with all the controls for the Google recurring events
 * following RRule
 */
export const getRecurringFrequency = (recurringEventRoot: RecurringCalendarEventRootStrip): RecurringEventSelectOptions => {
  if (recurringEventRoot?.kind === 'RECURRING' && recurringEventRoot?.recurrence[0]) {
    const ruleObject = parseRRuleStrings(recurringEventRoot.recurrence, recurringEventRoot.start);
    const { count, interval, freq, byweekday } = ruleObject.options;

    if (!count && (!interval || interval === 1)) {
      const isWeekday = byweekday?.length === 5 && [0, 1, 2, 3, 4].every((elem) => byweekday.includes(elem));
      if (isWeekday) {
        return 'WEEKDAY';
      } else {
        switch (freq) {
          case 3:
            return 'DAILY';
          case 2:
            return 'WEEKLY';
          case 1:
            return 'MONTHLY';
          case 0:
            return 'YEARLY';
        }
      }
    } else {
      return 'CUSTOM';
    }
  }
};

export const parseSelectValue = (recurringEventRoot: RecurringCalendarEventRootStrip): RecurringEventSelectOptions => {
  return getRecurringFrequency(recurringEventRoot);
};

/**
 * @todo Define fromTime, toTime format more stricly.
 *
 * @todo Check and store the user tz in calendar.slice ? With a Tzid type.
 */
export function parseTimeInDates(
  inputs: EventFormInputs,
  recurringEventRoot: RecurringCalendarEventRootStrip | null,
  tz = dayjs.tz.guess()
) {
  const { fromTime, toTime, fromDate, toDate } = inputs;

  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
   * "The value of the time input is always in 24-hour format that includes leading zeros: hh:mm."
   */
  // We may have to use moment-tz for the rare DST problem cases.
  const parsedFrom = dayjs.tz(fromTime, 'HH:mm', tz);
  const parsedTo = dayjs.tz(toTime, 'HH:mm', tz);
  const fromTimeHour = parsedFrom.hour();
  const fromTimeMinute = parsedFrom.minute();
  const toTimeHour = parsedTo.hour();
  const toTimeMinute = parsedTo.minute();

  return {
    parsedFromDate: dayjs(fromDate).hour(fromTimeHour).minute(fromTimeMinute),
    parsedToDate: dayjs(toDate).hour(toTimeHour).minute(toTimeMinute),
    recurrentParsedFromDate: recurringEventRoot?.start
      ? dayjs(recurringEventRoot.start).hour(fromTimeHour).minute(fromTimeMinute)
      : null,
    recurrentParsedToDate: recurringEventRoot?.end ? dayjs(recurringEventRoot.end).hour(toTimeHour).minute(toTimeMinute) : null,
  };
}

// Workaround since formState.dirtyFields.toDate & fromDate don't work
// In this case it doesn't display the confirm box if we changed the date, and not only the hour.
export function areDatesDirty(defaultValues: EventDefaultValues, values: EventFormInputs) {
  const { parsedFromDate, parsedToDate } = parseTimeInDates(values, null);

  const dirtyFromDate: boolean = defaultValues.start !== toUTCDateTimeMs(parsedFromDate);
  const dirtyToDate: boolean = defaultValues.end !== toUTCDateTimeMs(parsedToDate);

  return (dirtyFromDate || dirtyToDate) && !!values.fromTime && !!values.toTime;
}

export function parseAttendees(attendees: UnipileEventAttendee[] | GoogleCalendarAttendee[]) {
  return attendees.map((attendee) => ({
    ...attendee,
    response_status: attendee?.response_status || 'needsAction',
  }));
}
