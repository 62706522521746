import { DomainEventShape, Uuid } from '../../../Common';
import {
  ExternalCalendarEtag,
} from '../projections/ViewCalendar';
import {
    ExternalCalendarDTO,
    ExternalPatchCalendarDTO,
    NewCalendarDTO,
    PatchCalendarDTO
} from "../types/CalendarDTO";

/**
 * DomainEvent.
 */
export class CalendarCreated implements DomainEventShape {
  readonly type = 'CALENDAR_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly calendar: NewCalendarDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class CalendarAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAR_ACKNOWLEDGED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly calendar: ExternalCalendarDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class CalendarRestoreAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAR_RESTORE_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly calendar: ExternalCalendarDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 *
 * @todo See if there is a syncToken equivalent for Calendar List. -> yes !
 *       This is for the Event List, do not confuse them.
 *       Figure out how/where to store the Calendar List syncToken. -> on the parent Account.
 */
export class CalendarSynced implements DomainEventShape {
  readonly type = 'CALENDAR_SYNCED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly sync_token: string | null,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarSyncActivated implements DomainEventShape {
  readonly type = 'CALENDAR_SYNC_ACTIVATED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class CalendarSyncDeactivated implements DomainEventShape {
  readonly type = 'CALENDAR_SYNC_DEACTIVATED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class CalendarDeleted implements DomainEventShape {
  readonly type = 'CALENDAR_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class CalendarEdited implements DomainEventShape {
  readonly type = 'CALENDAR_EDITED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly updatedValues: PatchCalendarDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarPatchAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAR_PATCH_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly patch: ExternalPatchCalendarDTO,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarEtagAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAR_ETAG_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly etag: ExternalCalendarEtag,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class CalendarDeleteAcknowledged implements DomainEventShape {
  readonly type = 'CALENDAR_DELETE_ACKNOWLEDGED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly version: number,
    readonly etag: ExternalCalendarEtag,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * Collect and export all event definitions.
 */
export type CalendarDomainEvent =
  | CalendarCreated
  | CalendarAcknowledged
  | CalendarRestoreAcknowledged
  | CalendarDeleted
  | CalendarEdited
  | CalendarPatchAcknowledged
  | CalendarEtagAcknowledged
  | CalendarDeleteAcknowledged
  | CalendarSynced
  | CalendarSyncActivated
  | CalendarSyncDeactivated;

/**
 * @todo Consider using/renaming user_id as owner_id ? There are a lot of questions
 *        to ask around shared calendar management.
 */
// | CalendarSharedWithGroup
// | CalendarSharedWithTeam
// | CalendarSharedWithUser
// | CalendarUnSharedWithGroup
// | CalendarUnSharedWithTeam
// | CalendarUnSharedWithUser
// | CalendarMadePrivate
// | SharedCalendarUnsubscribed
