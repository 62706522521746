import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';

const CARROUSEL_SIZE = 4;

const useClasses = makeStyles((theme) => ({
  title: {
    transition: 'all .5s ease',
  },
  titleHide: {
    opacity: 0,
  },
}));

export default function SignUpCarousel() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const c = useClasses();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(
      () => {
        if (!show) setIndex(index === CARROUSEL_SIZE - 1 ? 0 : index + 1);

        setShow(!show);
      },
      show ? 2000 : 500
    );
  }, [show]);
  return <div className={cn(c.title, !show && c.titleHide)}>{t('signup.carrousel' + (index + 1))}</div>;
}
