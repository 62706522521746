import * as React from 'react';
import { IonIcon } from '@ionic/react';
import { Typography } from '@material-ui/core';
import { locationOutline } from 'ionicons/icons';

interface EventLocationProps {
  className: string;
  location: string;
}

const EventLocation: React.FC<EventLocationProps> = ({ className, location }) => {
  return (
    <div className={className}>
      <IonIcon icon={locationOutline} />
      <Typography variant="body2">
        <a href={`https://www.google.fr/maps/place/${location}`} target="_blank" rel="noreferrer">
          {location}
        </a>
      </Typography>
    </div>
  );
};

export default EventLocation;
