import { useDispatch, useStore } from 'react-redux';

import { configureStore, createSlice, combineReducers, AnyAction, Reducer } from '@reduxjs/toolkit';
import Container from './interfaces/Container';

import accounts from '../Account/state/accounts.slice';
import session from '../User/state/session.slice';
import notifications from '../Notification/state/notifications.slice';
import configuration from '../Common/state/configuration.slice';
import recovery from '../Common/state/recovery.slice';
import calendar from '../Calendar/state/calendar.slice';
import contacts from '../Contact/state/contacts.slice';
import search from '../Search/state/search.slice';
import work from '../Common/state/work.slice';
import ims from '../Im/state/ims.slice';
import sources from '../Account/state/sources.slice';
import tags from '../Tag/state/tags.slice';
import messageModels from '../MessageModel/state/messageModels.slice';
import { EnvironmentState } from './interfaces/Environment';
import { store } from './web';

/**
 *
 */
export const createStore = (container: Container) => {
  const appReducer = combineReducers({
    accounts,
    session,
    notifications,
    configuration,
    calendar,
    contacts,
    search,
    work,
    ims,
    recovery,
    sources,
    tags,
    messageModels,
    env: createSlice({
      name: 'env',
      initialState: {
        device_type: container.environment.device_type,
        production: container.environment.production,
      } as EnvironmentState,
      reducers: {},
    }).reducer,
  });

  const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    switch (action.type) {
      case 'SESSION_RESET':
        /**
         * @note We may be able to selectively extract some slices to exclude from the reset, eg :
         *
         *         const { env } = state;
         *         state = { env } as typeof state;
         *
         * @note Each slice is free to listen and respond to SESSION_RESET if necessary, eg :
         *
         *         extraReducers: (builder) => {
         *             builder.addCase(SESSION_RESET, (state, action) => {
         *             return { ...this.initialState };
         *             });
         *         },
         *
         */
        state = undefined;
        break;
      case 'APP_RECOVERY_REQUESTED':
        state = undefined;
        break;
      default:
        break;
    }

    return appReducer(state, action);
  };
  // const enhancers = [];
  // if (container?.logger instanceof SentryService) enhancers.push(SentryService.createReduxEnhancer());
  return configureStore({
    reducer: rootReducer,
    // enhancers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: container,
        },
      }),
  });
};

export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export type AppGetState = ReturnType<typeof createStore>['getState'];
export type AppDispatch = ReturnType<typeof createStore>['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppStore = () => useStore<RootState>();
