import dayjs from 'dayjs';

import { createProjectionConfig, createProjector, DecisionProjection, toUTCDateTimeMs } from '../../../Common';
import { NotificationRepo } from '../../infra/repository/NotificationRepo';
import { createViewNotification, ViewNotification } from '../projections/ViewNotification';
import { ViewNotificationRepo } from '../projections/ViewNotificationRepo';

export interface viewNotificationsProjectorRepos {
  viewNotification: ViewNotificationRepo;
  notification: NotificationRepo;
}

/**
 * Dedicated handler to restore a deleted projection.
 */
// const rollbackProjection = createProjectionConfig({} as ViewNotification, {
//   NOTIFICATION_RECEIVED: (event) => ({ ...createViewNotification(event.aggregateId, event.notification) }),
//   NOTIFICATION_SNOOZED: (event) => ({
//     snooze_date: toUTCDateTime(dayjs(event.createdAt)),
//   }),
// });

/**
 * Handler/Projector.
 */
export const viewNotificationsProjector = createProjector('viewNotificationsProjector', {
  NOTIFICATION_RECEIVED: async ({ aggregateId, notification }, repos: viewNotificationsProjectorRepos) => {
    return repos.viewNotification.add(createViewNotification(aggregateId, notification));
  },
  NOTIFICATION_DELETED: async ({ aggregateId }, repos) => {
    return repos.viewNotification.remove(aggregateId);
  },
  NOTIFICATION_PLANNED: async ({ aggregateId }, repos) => {
    return repos.viewNotification.remove(aggregateId);
  },
  NOTIFICATION_SNOOZED: async ({ aggregateId, createdAt }, repos) => {
    return repos.viewNotification.update(aggregateId, {
      snooze_date: toUTCDateTimeMs(dayjs(createdAt)),
    });
  },
  NOTIFICATION_IM_UPDATED: async ({ aggregateId, values }, repos) => {
    return repos.viewNotification.update(aggregateId, {
      ...values,
    });
  },
  //   NOTIFICATION_REMINDED: ({ aggregateId, createdAt }, repos) => {
  //     repos.viewNotification.update(aggregateId, { received_date: toUTCDateTime(dayjs(createdAt)) });
  //     console.log('@todo : handle non-deleted case.')
  //   },

  // NOTIFICATION_REMINDED: async (event, repos) => {
  //   const { aggregateId, createdAt } = event;

  //   const rollback = new DecisionProjection(rollbackProjection);
  //   const events = await repos.notification.getDomainEventsAt(aggregateId, event);
  //   // const events = await repos.notification.getDomainEvents(aggregateId);
  //   // const eventsAt = await repos.notification.getDomainEventsAt(aggregateId, event);
  //   // console.log('NOTIFICATION_REMINDED', events, eventsAt);

  //   rollback.process(events);
  //   return repos.viewNotification.add({
  //     ...rollback.state,
  //     received_date: toUTCDateTimeMs(dayjs(createdAt)),
  //   });
  // },
});
