import { Taggable } from '../../Tag';
import {
  // isContactSupportedAccount,
  isEnvSupportedAccount,
  isImSupportedAccount,
  ViewAccount,
  ViewAccountRepo,
} from '../../Account';
import {
  FileManagerService,
  Image,
  InvalidAccountError,
  LoggerService,
  NoPermissionsError,
  Publisher,
  Result,
  UnsupportedDevice,
  Uuid,
} from '../../Common';
import i18n from '../../Common/infra/services/i18nextService';
import { ViewIm, ViewImRepo, ViewImThread, ViewImThreadRepo } from '../../Im';
import { SearchService } from '../../Search';
import { ContactServicesMap } from '../../_container/interfaces/Container';
import { Environment } from '../../_container/interfaces/Environment';
import { Attendee, Contact, IdentifierType, ViewContact } from '../domain';
import { ViewContactRepo } from '../domain/projections/ViewContactRepo';
import { ContactRepo } from '../infra/repository/ContactRepo';
import { ContactUseCase } from './ContactUseCase';

export class AppContactUseCase implements ContactUseCase {
  ERROR_UNEXPECTED = i18n.t('error.unexpected');

  constructor(
    private readonly publisher: Publisher,
    private readonly contact: ContactRepo,
    private readonly viewContact: ViewContactRepo,
    private readonly logger: LoggerService,
    private readonly search: SearchService,
    private readonly contactServices: ContactServicesMap,
    private readonly viewAccount: ViewAccountRepo,
    private readonly viewImThread: ViewImThreadRepo,
    private readonly viewIm: ViewImRepo,
    private readonly env: Environment
  ) {}

  async getContactsList(): Promise<(ViewContact & Taggable)[]> {
    try {
      return this.viewContact.getAll();
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  async getContact(contact_id: Uuid): Promise<
    Result<{
      contact: ViewContact & Taggable;
      threads: (ViewImThread & {
        last_im: ViewIm | null;
        account: ViewAccount;
      })[];
    } | null>
  > {
    try {
      const contact = await this.viewContact.get(contact_id);
      if (!contact)
        return {
          result: null,
        };

      let identifiers: { identifier: string; identifier_type: IdentifierType }[] = [];
      if (contact.email)
        identifiers = identifiers.concat(
          contact.email.map((email) => ({ identifier: email.address, identifier_type: 'EMAIL_ADDRESS' }))
        );
      if (contact.phone)
        identifiers = identifiers.concat(
          contact.phone.map((phone) => ({ identifier: phone.number, identifier_type: 'PHONE_NUMBER' }))
        );
      if (contact.social)
        identifiers = identifiers.concat(
          contact.social.map((social) => ({ identifier: social.identifier, identifier_type: social.identifier_type }))
        );

      const returnThreads: (ViewImThread & {
        last_im: ViewIm | null;
        account: ViewAccount;
      })[] = [];

      await Promise.all(
        identifiers.map(async ({ identifier, identifier_type }) => {
          const threads = await this.viewImThread.getByAttendeeIdentifier(identifier_type, identifier);
          await Promise.all(
            threads.map(async (thread) => {
              const last_im = await this.viewIm.getLatestByThread(thread.id);
              const account = await this.viewAccount.get(thread.account_id);
              if (!account) return;
              returnThreads.push({
                ...thread,
                account,
                last_im,
              });
            })
          );
        })
      );

      return {
        result: {
          contact,
          threads: returnThreads,
        },
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  async getContactByAttendee(attendee: Attendee): Promise<ViewContact | null> {
    try {
      const contacts = await this.viewContact.getByIdentifier(attendee.identifier_type, attendee.identifier);
      if (contacts.length) return contacts[0];
      return null;
    } catch (e) {
      this.logger.captureException(e);
      throw e;
    }
  }

  // async deleteContact(contact_id: Uuid): Promise<void> {
  //   try {
  //     const agg = await this.contact.getAggregate(contact_id);
  //     await this.publisher.emit(agg.delete().changes);
  //   } catch (e) {
  //     this.logger.captureException(e);
  //     throw e;
  //   }
  // }

  // /**
  //  * Create a contact with form values and add it to the search index
  //  * @param values
  //  * @param image
  //  */
  // async createContact(values: NewContactDTO, image?: Image): Promise<ViewContact> {
  //   try {
  //     const result = Contact.create({ ...values, profile_picture: image, provider: 'UNIPILE' });
  //     await this.publisher.emit(result.changes);

  //     if (result.ok === true) {
  //       const created = await this.viewContact.get(result.aggregate.id);

  //       /** @note This is one way to handle 'null' without returning Promise<ViewContact | null>. */
  //       if (!created) throw new Error(`Could not get created viewContact for aggregate id : ${result.aggregate.id}.`);

  //       return created;
  //     }

  //     throw new Error(`Could not create contact : ${result.reason}.`);

  //     // this.search.addContact(created);
  //   } catch (e) {
  //     this.logger.captureException(e);
  //     throw e;
  //   }
  // }

  // /**
  //  * Edit an exising contact with form values and update
  //  * @param contact_id
  //  * @param values
  //  * @param image
  //  */
  // async editContact(contact_id: Uuid, values: NewContactDTO, image?: Image): Promise<ViewContact | null> {
  //   try {
  //     const agg = await this.contact.getAggregate(contact_id);
  //     const result = agg.edit({ ...values, profile_picture: image });
  //     await this.publisher.emit(result.changes);

  //     if (result.ok === true) {
  //       return this.viewContact.get(contact_id);
  //       // this.search.updateContact(updated.id, updated);
  //     }

  //     throw new Error(`Could not edit contact ( ${agg.id} ) : ${result.reason}.`);
  //   } catch (e) {
  //     this.logger.captureException(e);
  //     throw e;
  //   }
  // }

  async createContactFromAttendee(attendee: Attendee, managing_account: Uuid): Promise<Result<ViewContact>> {
    try {
      const { changes, aggregate } = Contact.create({
        full_name: attendee.display_name || 'Unknown',
        social: [{ identifier: attendee.identifier, identifier_type: attendee.identifier_type, managing_account }],
        profile_picture: attendee.profile_picture,
      });
      await this.publisher.emit(changes);

      const contact = await this.viewContact.get(aggregate.id);

      if (!contact) throw new Error('Cannot get the created contact');

      return {
        result: contact,
      };
    } catch (e) {
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  /**
   *
   */
  async fetchContacts(account_id: Uuid): Promise<Result<void>> {
    try {
      const account = await this.viewAccount.get(account_id);

      console.log(account);
      if (!account || !isImSupportedAccount(account)) throw new InvalidAccountError();
      if (!isEnvSupportedAccount(account, this.env, 'CONTACTS')) throw new UnsupportedDevice();

      const contacts = await this.contactServices[account.type].searchContacts(account.id, {});

      await Promise.all(
        contacts.map(async (contact) => {
          const result = Contact.create(contact);
          try {
            await this.publisher.emit(result.changes);
          } catch (e) {
            if (e instanceof DOMException && (e.name === 'ConstraintError' || e.name === 'AbortError')) {
              console.log(`Contact already exists : `, e, contact);
              return null;
            }
            throw e;
          }
        })
      );
      return {};
    } catch (e) {
      if (e instanceof UnsupportedDevice || e instanceof NoPermissionsError) return { error: e.name };
      // if (e instanceof CanceledError) return {};
      this.logger.captureException(e);
      return {
        error: this.ERROR_UNEXPECTED,
      };
    }
  }

  // /**
  //  * @note Keeping this function for now as we might use it later to include first sync specific logic (such as different query params)
  //  */
  // async initialFetch(account_id: Uuid): Promise<Result<void>> {
  //   return this.fetchContacts(account_id);
  // }
}
