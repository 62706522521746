import { MaybePromise, Select, SingleOrArray } from '../utils';
import { DomainEvent, DomainEventId, DomainEventShape } from './DomainEvent';
import { Player } from './Projectionist';
import { Publisher } from './Publisher';
import { Uuid } from './Uuid';

/**
 * @note All domain event handlers MUST be IDEMPOTENT.
 */
export interface ProjectorHandler<TRepos, TDomainEvent extends DomainEventShape> {
  //   (event: TDomainEvent, repos: TRepos): MaybePromise<void>;
  (event: TDomainEvent, repos: TRepos): MaybePromise<unknown>;
}

/**
 * Collection of ProjectorHandlers keyed by DomainEventType.
 */
export type ProjectorConfig<TRepos, TDomainEvent extends DomainEventShape = DomainEvent> = {
  [TDomainEventType in TDomainEvent['type']]?: ProjectorHandler<TRepos, TDomainEvent & { type: TDomainEventType }>;
};

/**
 *
 */
export interface ProjectorError<TDomainEvent extends DomainEventShape = DomainEvent> {
  error: unknown;
  projectorName: string;
  event: TDomainEvent;
}

/**
 * @note Domain events are validated at the aggregate level, this should only
 *       consume them.
 *
 * @todo Consider whose responsibility it is to clear/prepare the repo.
 *
 * @todo Consider returning Fx/Action objects from handlers and deferring execution to another process ?
 *       Isn't that exactly what projectors are already doing with DomainEvents ??
 */
export function createProjector<TRepos, TDomainEvent extends DomainEventShape = DomainEvent>(
  name: string,
  config: ProjectorConfig<TRepos, TDomainEvent>
): Projector<TRepos, TDomainEvent> {
  let _lastEventId: DomainEventId | null = null;
  return {
    type: 'projector',
    name,

    /**
     * @note No version checking is done here. It expects well-formed, well-sorted
     *       'streams' of events.
     *
     * @todo Consider getting rid of the closure by allowing Publisher.on to
     *       take and hold extras refs, to repos or whatever?
     *
     * @todo Study what is needed to handle Commutative SET ops : state[aggId].version <= event.version ?
     *       But then how to enforce that handler can only use SET op ?
     *       Is this even desirable ? Using the previous projection state is very handy for Patch-ing.
     *       Still, only a very limited kind of Patch op should be, the idempotent kind, e.g. :
     *       setting a property is ok, appending to an array is not.
     *
     *       Each projector would have to track version seen per aggId, in some Map<Uuid, number> or
     *       Record<Uuid, number>.
     */
    register(publisher: Select<Publisher<TDomainEvent>, 'on'>, repos) {
      (Object.keys(config) as Array<keyof typeof config>).forEach((eventType) => {
        publisher.on(eventType, async (event) => {
          await config[eventType]?.(event, repos);
          _lastEventId = {
            aggregateId: event.aggregateId,
            version: event.version,
          };
        });
      });
    },

    /**
     *
     */
    registerPlayer(player: Select<Player<TDomainEvent>, 'on'>, repos, name: string) {
      (Object.keys(config) as Array<keyof typeof config>).forEach((eventType) => {
        player.on(eventType, [
          async (event) => {
            await config[eventType]?.(event, repos);
            _lastEventId = {
              aggregateId: event.aggregateId,
              version: event.version,
            };
          },
          name,
        ]);
      });
    },

    /**
     * @note No version checking is done here. It expects well-formed, well-sorted
     *       'streams' of events.
     *
     * @todo Study what is needed to handle Commutative SET ops : state[aggId].version <= event.version ?
     *       But then how to enforce that handler can only use SET op ?
     *       Is this even desirable ? Using the previous projection state is very handy for Patch-ing.
     *       Still, only a very limited kind of Patch op should be, the idempotent kind, e.g. :
     *       setting a property is ok, appending to an array is not.
     *
     *       Each projector would have to track version seen per aggId, in some Map<Uuid, number> or
     *       Record<Uuid, number>.
     */
    async play(events: SingleOrArray<TDomainEvent>, repos) {
      if (!Array.isArray(events)) {
        /** Wrap single event in an array. */
        events = [events];
      }

      const errors: ProjectorError<TDomainEvent>[] = [];
      const blocklist = new Set<Uuid>();

      /**
       * @note Handlers can be async function calling async function, etc ...
       *       Sequential iteration is needed to guarantee event processing order.
       */
      for (let i = 0, length = events.length; i < length; ++i) {
        if (!blocklist.has(events[i].aggregateId)) {
          try {
            await config[events[i].type as TDomainEvent['type']]?.(events[i], repos);
            _lastEventId = {
              aggregateId: events[i].aggregateId,
              version: events[i].version,
            };
          } catch (error) {
            blocklist.add(events[i].aggregateId);
            errors.push({
              error,
              projectorName: name,
              event: events[i],
            });
          }
        }
      }
      return errors;
    },

    /**
     *
     */
    getConfig() {
      return config;
    },
    /**
     *
     */
    getLastEventId() {
      return _lastEventId;
    },
  };
}
/**
 * Projectors are domain event handlers meant to be called on original event
 * emission AND replays.
 *
 * @note They MUST NOT perform side-effects.
 *
 * @note All domain event handlers MUST be IDEMPOTENT.
 *
 * @todo Add schema version to handle projector upgrades.
 */
export interface Projector<
  TRepos = Record<string, Record<string, unknown>>,
  TDomainEvent extends DomainEventShape = DomainEvent
> {
  type: 'projector';
  name: string;
  //   schema: number;
  register: (publisher: Select<Publisher<TDomainEvent>, 'on'>, repos: TRepos) => void;
  registerPlayer: (player: Select<Player<TDomainEvent>, 'on'>, repos: TRepos, name: string) => void;
  play: (events: SingleOrArray<TDomainEvent>, repos: TRepos) => MaybePromise<ProjectorError<TDomainEvent>[]>;
  getConfig: () => ProjectorConfig<TRepos, TDomainEvent>;
  getLastEventId: () => DomainEventId | null;
}
