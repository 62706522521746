import { AccountDomainEvent } from '../../Account';
import { SignatureDomainEvent } from '../../Account/domain/events/Signature.events';
import { CalendarDomainEvent, CalendarEventDomainEvent } from '../../Calendar';
import { CallDomainEvent } from '../../Call';
import { ContactDomainEvent } from '../../Contact';
import { ImDomainEvent, ImThreadDomainEvent } from '../../Im';
import { MailDomainEvent } from '../../Mail';
import { MessageModelDomainEvent } from '../../MessageModel';
import { NotificationDomainEvent } from '../../Notification';
import { TagDomainEvent, TagRelationDomainEvent } from '../../Tag';
import { Uuid } from './Uuid';

/**
 * Collect and export all event definitions.
 */
export type DomainEvent =
  | TagDomainEvent
  | TagRelationDomainEvent
  | AccountDomainEvent
  | SignatureDomainEvent
  | CalendarDomainEvent
  | CalendarEventDomainEvent
  | ContactDomainEvent
  | NotificationDomainEvent
  | ImDomainEvent
  | ImThreadDomainEvent
  | MailDomainEvent
  | CallDomainEvent
  | MessageModelDomainEvent
  | DomainEventShape<'GENERIC_TEST'>;

export type DomainEventType = DomainEvent['type'];

export interface CreationalDomainEvent extends DomainEventShape {
  version: 0;
}

// const t: CreationalDomainEvent = {
//   version: 0,
//   aggregateId: 'Uuid' as Uuid,
//   schema: 1,
//   createdAt: 0,
//   type : 'test'
// };

/**
 * @note Do not rely on the createdAt timestamp to order events filtered by type for replays !
 *       It lacks the granularity needed and offers little guarantee about the order
 *       of events especially when issued by different devices.
 *
 * @note A global event sequence looks difficult since event can be issued from different devices
 *       that are not necessarily in sync.
 *
 * @note The order of events for a given aggregateId is what should matter the most,
 *       and is tracked by version.
 *
 * @note version is 0-based. A event with version = 0 is a 'creational' event, meaning it
 *       expects to be read by a new empty aggregate and to stamp it with its id.
 *
 * @note schema is 1-based. Use it IndexedDB schema usage.
 *
 * @todo Research vector clock if version isn't enough.
 */
export type DomainEventShape<TEvent = string> = Readonly<{
  type: TEvent;
  /**
   * @todo Look for a minimum effort way to tag events with a node/branch id.
   *       Maybe when they are persisted in EventStore ?
   */
  //   node?: Uuid;
  aggregateId: Uuid;
  version: number;
  schema: number;
  createdAt: number;
}>;

/**
 * @note [aggregateId, version] must be a unique composite key for DomainEvent.
 */
export interface DomainEventId {
  aggregateId: Uuid;
  version: number;
}

/**
 * @todo Consider pros/cons of alternate way to write events ?
 *       eg : extending base vs implementing interface.
 */
export abstract class DomainEventBase<TEvent = string> implements DomainEventShape<TEvent> {
  abstract readonly type: TEvent;
  abstract readonly schema: number;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 *
 */
export function scrubDomainEvent(event: DomainEventShape): DomainEventShape {
  return {
    type: event.type,
    aggregateId: event.aggregateId,
    version: event.version,
    schema: event.schema,
    createdAt: event.createdAt,
  };
}
// export type DomainEventCreatorFn =  (
//   aggregateId: string,
//   version: number,
//   ...args: unknown[]
// ) => DomainEventShape;

/**
 * @todo Consider pros/cons of alternate way to write events ?
 *         eg : extending base vs implementing interface.
 *
 * export class DomainEventBase implements DomainEventShape {
 *   readonly type;
 *   readonly schema = 1;
 *   constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
 * }
 *
 * export class CalendarEventCreated implements DomainEventShape {
 *   readonly type = 'CALENDAREVENT_CREATED';
 *   readonly schema = 1;
 *   constructor(
 *     readonly aggregateId: Uuid,
 *     readonly version: number,
 *     // readonly calendarEvent: NewCalendarEvent,
 *     readonly createdAt = Date.now()
 *   ) {}
 * }
 *
 * export class CalendarEventDeleted extends DomainEventBase {
 *   readonly type = 'CALENDAREVENT_DELETED';
 *   readonly schema = 1;
 *   constructor(aggregateId: Uuid, version: number) {
 *     super(aggregateId, version);
 *   }
 * }
 *
 * @note Alternate way that requires an extra type definition and needs more research to
 *       type its signature properly.
 *
 * export const CalendarEventEdited : DomainEventCreatorFn = (aggregateId: string, version = 0, more : number) =>
 *   ({
 *     type: 'CALENDAREVENT_EDITED',
 *     schema: 1,
 *     createdAt: Date.now(),
 *     aggregateId,
 *     version,
 *   } as const);
 *
 * export type CalendarEventEdited = ReturnType<typeof CalendarEventEdited>;
 *
 * @note Using constructor functions for domain events definition and doing
 *       ReturnType<typeof ctorFunc> to collect the discriminated union type
 *       doesn't yield the litteral narrowing needed for the type tag to work
 *       unless the return type is coaxed with an 'as const' assertion.
 *
 *       Constructor functions work if the complete discriminated union is written manually
 *       on top of the domain event constructor functions.
 *
 *       In typescript Classes are both type definition and constructor function.
 *       Thanks to structural typing, it fits nicely here and keep the amount of
 *       ceremony pretty low.
 *
 *       Even if using this.constructor.name to derive the type tag from the class name
 *       was a good idea, it would backfire when the code is minified/mangled. It is
 *       imperative that type is both serializible and human-readable.
 *
 *       The type property MUST BE readonly for typescript literal narrowing to work.
 *
 *       Typescript doesn't strictly enforce the readonly modifier when implementing
 *       an interface.
 *
 *       Extending an abstract class adds a lot of noise and doesn't help enforcing
 *       readonly in implementers :
 *
 *         abstract class DomainEventBase {
 *           abstract readonly type: string;
 *           constructor(public aggregateId: Uuid) {}
 *         }
 *
 *         class MyEvent extends DomainEventBase {
 *             type = 'MY_EVENT';
 *             constructor(aggregateId: Uuid, public extra : string) {
 *                 super(aggregateId);
 *             }
 *
 *             bad() {
 *                 this.type = 'overwritten without error'
 *             }
 *         }
 *
 *       Relying on structural typing, it's probably ok to omit 'implements DomainEventShape'
 *       on domain event class definition.
 *
 * @todo Consider how dangerous it is for parameters used to build domain events not to
 *       be deep cloned or be deep frozen.
 * @todo Consider stamping with user_id when sending to remote repo.
 * @todo Consider a payload 'root' inside DomainEventShape to
 */

// export type WithId = { id: Uuid };
// export type WithVersion = { version: number };
// export type WithSchema = { schema: number };
// export type WithTimestamp = { createdAt: UTCDate };

// export type DomainEventShape<TEvent = string> = WithId & {
//   type: TEvent;
// };
