import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';

export interface DivNodeOptions {
  HTMLAttributes: Record<string, any>;
}

export const DivNodeBlock = Node.create<DivNodeOptions>({
  name: 'div',
  content: 'block*',
  group: 'block',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: 'div' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
