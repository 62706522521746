import { DomainEventShape, Uuid } from '../../../Common';
import { NewTagRelationDTO } from '../projections/ViewTagRelation';

/**
 * DomainEvent.
 */
export class TagRelationCreated implements DomainEventShape {
  readonly type = 'TAG_RELATION_CREATED';
  readonly schema = 1;
  readonly version = 0;
  constructor(readonly aggregateId: Uuid, readonly tag: NewTagRelationDTO, readonly createdAt = Date.now()) {}
}

/**
 * DomainEvent.
 */
export class TagRelationRestored implements DomainEventShape {
  readonly type = 'TAG_RELATION_RESTORED';
  readonly schema = 1;
  constructor(
    readonly aggregateId: Uuid,
    readonly tag: NewTagRelationDTO,
    readonly version: number,
    readonly createdAt = Date.now()
  ) {}
}

/**
 * DomainEvent.
 */
export class TagRelationDeleted implements DomainEventShape {
  readonly type = 'TAG_RELATION_DELETED';
  readonly schema = 1;
  constructor(readonly aggregateId: Uuid, readonly version: number, readonly createdAt = Date.now()) {}
}

/**
 * Collect and export all event definitions.
 */
export type TagRelationDomainEvent = TagRelationCreated | TagRelationDeleted | TagRelationRestored;
