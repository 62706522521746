import { modeSelected, ViewCalendarEvent, ViewMode } from '@focus-front/core';
import { DurationInput } from '@fullcalendar/common';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarContext } from './CalendarContext';

export default function useCalendar() {
  const context = useContext(CalendarContext);
  const dispatch = useDispatch();
  const api = useMemo(() => context.current?.getApi(), [context.current]);

  const next = useCallback(() => {
    if (api) api.next();
  }, [api]);

  const prev = useCallback(() => {
    if (api) api.prev();
  }, [api]);

  const today = useCallback(() => {
    if (api) api.today();
  }, [api]);

  const scrollToTime = useCallback(
    (timeInput: DurationInput) => {
      if (api) api.scrollToTime(timeInput);
    },
    [api]
  );

  const changeView = useCallback(
    (mode: ViewMode) => {
      if (api) {
        switch (mode) {
          case 'DAY':
            api.changeView('timeGridDay');
            break;
          case 'WEEK':
            api.changeView('timeGridWeek');
            break;
          case 'MONTH':
            api.changeView('dayGridMonth');
            break;
          case 'LIST':
            api.changeView('listWeek');
            break;
        }
        dispatch(modeSelected(mode));
      }
    },
    [api]
  );

  const gotoDate = useCallback(
    (date: string) => {
      if (api) {
        const current_date = api.getDate();
        if (!dayjs(current_date).isSame(dayjs(date), 'date')) api.gotoDate(dayjs(date).format('YYYY-MM-DD'));
      }
    },
    [api]
  );

  const gotoEvent = useCallback(
    (event: ViewCalendarEvent) => {
      if (api) {
        gotoDate(event.start);
        setTimeout(() => api.scrollToTime(dayjs(event.start).format('HH:mm')), 200);
      }
    },
    [gotoDate, api]
  );

  return {
    ref: context,
    next,
    prev,
    today,
    changeView,
    gotoDate,
    gotoEvent,
    scrollToTime,
  };
}
