import dayjs from 'dayjs';
import { arrowBack } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { displayAttendeeName, displayAttendeeFull, Uuid } from '@focus-front/core';
import { Attachment, useMailView } from '@focus-front/ui';
import { IonIcon } from '@ionic/react';
import { IconButton, LinearProgress, makeStyles, Typography } from '@material-ui/core';

import SearchActionsBar from '../ActionBar/SearchActionsBar';
import AccountLabel from '../Common/AccountLabel';
import AttendeeAvatarPopover from '../Contact/AttendeeAvatarPopover';
import Tags from '../Tag/Tags';
import ErrorMessage from '../Common/ErrorMessage';
import MailDetails from './MailDetails';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      marginTop: 13,
      marginBottom: theme.spacing(1),
    },
  },
  content: {
    overflowY: 'scroll',
    flex: 1,
  },
  subheader: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  contactContainer2: {
    marginLeft: theme.spacing(2),
  },
  body: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  attachments: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  mail_id: Uuid;
  onBack: () => void;
}

export default function SearchMailView({ mail_id, onBack }: Props) {
  const classes = useClasses();
  const { t } = useTranslation();
  const { bodyRef, mail, attachments, status, errorMessage } = useMailView(mail_id);

  const to = useMemo(() => {
    if (!mail) return '';
    const attendees = [...(mail.to_attendees || []), ...(mail.cc_attendees || [])];
    return attendees.map(displayAttendeeName).join(', ');
  }, [mail]);

  return (
    <div className={classes.root}>
      {mail && (
        <div className={classes.header}>
          <IconButton onClick={onBack}>
            <IonIcon icon={arrowBack} />
          </IconButton>
          <Typography variant="h1">{mail.subject}</Typography>
        </div>
      )}
      <div className={classes.content}>
        {mail && (
          <div className={classes.subheader}>
            <div className={classes.contactContainer}>
              <AttendeeAvatarPopover attendee={mail.from_attendee} />
              <div className={classes.contactContainer2}>
                <Typography variant="h4">{displayAttendeeFull(mail.from_attendee)}</Typography>
                <Typography variant="body2">
                  <strong>{t('mailForm.to')} :</strong> {to}
                  <MailDetails mail={mail} />
                </Typography>
              </div>
            </div>
            <div className={classes.details}>
              <Typography variant="body2" className={classes.detailText}>
                {dayjs(mail.date).format('DD MMM YYYY [à] HH:mm')}
              </Typography>
              <AccountLabel account_id={mail.account_id} className={classes.detailText} />
              <div className={classes.tags}>
                <Tags tags={mail.tags} />
              </div>
            </div>
          </div>
        )}

        <SearchActionsBar entity_id={mail_id} entity_type="MAIL" />
        {status === 'ERROR' && <ErrorMessage title={t('error.unexpected')} subtitle={errorMessage} />}
        {status === 'LOADING' && mail && <LinearProgress />}
        <div ref={bodyRef} className={classes.body}></div>
        {!!attachments?.length && (
          <div className={classes.attachments}>
            {attachments.map((attachment, index) => (
              <Attachment key={index} attachment={attachment} loaded={true} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
