import { IonIcon } from '@ionic/react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { arrowBack } from 'ionicons/icons';
import React, { PropsWithChildren } from 'react';

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing(1),
    paddingTop: 0,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface AllListProps {
  onBack: () => void;
  title: string;
}

export default function AllListContainer({ onBack, title, children }: PropsWithChildren<AllListProps>) {
  const c = useClasses();
  return (
    <div className={c.root}>
      <div className={c.header}>
        <IconButton onClick={onBack}>
          <IonIcon icon={arrowBack} />
        </IconButton>
        <Typography variant="h3">{title}</Typography>
      </div>
      {children}
    </div>
  );
}
