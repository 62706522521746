import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Im } from '../../domain';

/**
 * Repository.
 */
export class ImRepo extends AggregateRepo<Im> {
  constructor(eventStore: DomainEventStore) {
    super(Im, eventStore);
  }
}
