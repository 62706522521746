import { testMailConnection, useAppDispatch } from '@focus-front/core';
import { Box, FormControl, Link, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { UnfoldMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { GMAIL_IMAP_CONFIG_DEFAULTS, GoogleMailConfigForm, WizardContext } from './WizardContext';

export function GoogleMailConfigurationManual1() {
  const { t } = useTranslation();

  const { goBack, goNext } = useContext(WizardContext);

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.googleMailConfig.title1')}</Typography>
      <Typography variant="subtitle2">{t('wizard.googleMailConfig.subtitle1')}</Typography>
      <Box mt={3} mb={5}>
        <Typography variant="body1">
          <Trans i18nKey="wizard.googleMailConfig.tuto">
            1. Activer la validation en 2 étapes :{' '}
            <Link href="https://myaccount.google.com/security" target="_blank">
              Cliquez ici
            </Link>
            <ul>
              <li>
                Dans la section <strong>Connexion à Google</strong>, choisissez <strong>Validation en deux étapes</strong>
              </li>
              <li>Suivez les instructions de Google qui s’affichent à votre écran</li>
            </ul>
            2. Activer la fonction IMAP :{' '}
            <Link href="https://mail.google.com/mail/u/0/#settings/fwdandpop" target="_blank">
              Cliquez ici
            </Link>
            <ul>
              <li>
                Dans la section <strong>Accès IMAP</strong>, Cochez <strong>Activer IMAP</strong>
              </li>
              <li>Cliquez sur Enregistrer les modifications</li>
            </ul>
            3. Créer un mot de passe d’application :{' '}
            <Link href="https://myaccount.google.com/apppasswords" target="_blank">
              Cliquez ici
            </Link>
            <ul>
              <li>
                Sélectionnez <strong>Autre</strong>, saisissez Unipile puis cliquez sur Générer
              </li>
              <li>Copiez le mot de passe affiché</li>
            </ul>
          </Trans>
        </Typography>
      </Box>
      <NextButton onClick={goNext} />
    </div>
  );
}

export function GoogleMailConfigurationManual2() {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  const { goBack, forms, setLoading, setForms, setAlias, goNext } = useContext(WizardContext);
  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm<GoogleMailConfigForm>({
    mode: 'onChange',
    defaultValues: forms.GOOGLE_MAIL,
  });

  const onSubmit = async (values: GoogleMailConfigForm) => {
    setForms({ ...forms, GOOGLE_MAIL: values });
    setAlias(values.email);
    setLoading(true);
    const success = await dispatch(
      testMailConnection(
        { type: 'MAIL', imap_pwd: values.password, smtp_pwd: values.password },
        {
          ...GMAIL_IMAP_CONFIG_DEFAULTS,
          imap_user: values.email,
          smtp_user: values.email,
        }
      )
    );
    if (!success) {
      setError(t('wizard.imapMailConfig.connexionFailError'));
      setLoading(false);
    } else {
      goNext();
      setError('');
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.googleMailConfig.title2')}</Typography>
      <Typography variant="subtitle2">{t('wizard.googleMailConfig.subtitle2')}</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        <Box mt={3} mb={5} maxWidth={300}>
          <TextField
            inputRef={register({ required: true })}
            name="email"
            variant="standard"
            label={t('wizard.googleMailConfig.mailLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="mail"
            autoComplete="off"
            autoFocus
          />
          <TextField
            inputRef={register({ required: true })}
            name="password"
            variant="standard"
            label={t('wizard.googleMailConfig.passwordLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 8 }}
            autoComplete="new-password"
            type="password"
          />
        </Box>
        <NextButton type="submit" />
      </form>
    </div>
  );
}
