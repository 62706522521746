import React, { useEffect, useRef, useState } from 'react';

import {
  resetLocalBranch,
  useAppDispatch,
  envSelector,
  configurationSelector,
  setDebugMode,
  logoutUser,
  dumpEventStoreDigest,
  dumpRecoveryCode,
  DebugRecoveryPageRequestedError,
} from '@focus-front/core';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { BackupOutlined, Delete, FontDownload, GetApp, SettingsBackupRestore, Visibility } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useStyles } from './useStyles';
import { useConfirm } from 'material-ui-confirm';
import { getTableSize, humanReadableSize } from '@focus-front/core';
import { useInterval } from 'react-use';
import { Skeleton } from '@material-ui/lab';
import { DebugWebViewOnLaunch } from './DebugWebView';

type Storage = {
  mail: string;
  mail_cache: string;
  app_data: string;
  attachments: string;
  ims: string;
  calendar: string;
};

const useClasses = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
  },
}));

export default function AdvancedSettingsPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const style = useStyles();
  const history = useHistory();
  const classes = useClasses();
  const [storage, setStorage] = useState<Storage>();
  const confirm = useConfirm();
  const mounted = useRef(true);

  async function getStorageUsage(): Promise<void> {
    const meta = await getTableSize('mailMeta');
    const cache = await getTableSize('mailCache');
    const app_data = await getTableSize('domainEvents');
    const events = await getTableSize('viewCalendarEvents');
    const calendar = await getTableSize('viewCalendars');
    const ims = await getTableSize('viewIms');
    const threads = await getTableSize('viewImThreads');

    if (mounted.current)
      setStorage({
        mail: humanReadableSize(meta),
        mail_cache: humanReadableSize(cache),
        app_data: humanReadableSize(app_data),
        attachments: humanReadableSize(0),
        calendar: humanReadableSize(events + calendar),
        ims: humanReadableSize(ims + threads),
      });
  }
  useEffect(() => {
    getStorageUsage();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      <div className={style.sectionTitle}>
        <Typography variant="h2">{t('advancedSettings.storage.title')}</Typography>
        <Typography variant="subtitle2">{t('advancedSettings.storage.subtitle')}</Typography>
      </div>

      <List>
        <Paper elevation={0} className={classes.item}>
          <ListItem>
            <ListItemText primary={t('advancedSettings.storage.appData')}></ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body2">{storage?.app_data || <Skeleton width={80} />}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
        <Paper elevation={0} className={classes.item}>
          <ListItem>
            <ListItemText primary={t('advancedSettings.storage.mails')}></ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body2">{storage?.mail || <Skeleton width={80} />}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
        <Paper elevation={0} className={classes.item}>
          <ListItem>
            <ListItemText primary={t('advancedSettings.storage.calendars')}></ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body2">{storage?.calendar || <Skeleton width={80} />}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
        <Paper elevation={0} className={classes.item}>
          <ListItem>
            <ListItemText primary={t('advancedSettings.storage.ims')}></ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body2">{storage?.ims || <Skeleton width={80} />}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
        <Paper elevation={0} className={classes.item}>
          <ListItem>
            <ListItemText primary={t('advancedSettings.storage.mailCache')}></ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body2">{storage?.mail_cache || <Skeleton width={80} />}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
      </List>
      <Button variant="outlined" startIcon={<Delete />} disableElevation>
        {t('advancedSettings.storage.clearCache')}
      </Button>

      <div className={style.sectionTitle}>
        <Typography variant="h2">{t('advancedSettings.dump.title')}</Typography>
        <Typography variant="subtitle2">{t('advancedSettings.dump.subtitle')}</Typography>
      </div>
      <Button
        onClick={async () => {
          await dispatch(dumpEventStoreDigest());
        }}
        variant="outlined"
        startIcon={<GetApp />}
        disableElevation
      >
        {t('recovery.dumpDigest.description')}
      </Button>

      <div className={style.sectionTitle}>
        <Typography variant="h2">{t('advancedSettings.recovery.title')}</Typography>
        <Typography variant="subtitle2">{t('advancedSettings.recovery.subtitle')}</Typography>
      </div>
      <Button
        onClick={async () => {
          confirm()
            .then(async () => {
              await dispatch(resetLocalBranch());
              await dispatch(logoutUser());
              history.push('/');
              window.location.reload();
            })
            .catch(() => {});
        }}
        variant="outlined"
        startIcon={<SettingsBackupRestore />}
        className={style.dangerBtn}
        disableElevation
      >
        {t('recovery.localReset.description')}
      </Button>

      <div></div>
      <div className={style.sectionTitle}>
        <Typography variant="h2">Developers</Typography>
      </div>
      <Button
        style={{ marginTop: 60 }}
        onClick={() => {
          throw new DebugRecoveryPageRequestedError();
        }}
        variant="outlined"
        disableElevation
      >
        Debug recovery page
      </Button>
      <DebugWebViewOnLaunch />
    </>
  );
}
