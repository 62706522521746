import '../../Common/infra/services/i18nextService';

import { useDispatch } from 'react-redux';

import { environment } from '../../_environments/environment.web';
import {
  AccountRepo,
  AppAccountUseCase,
  GoogleOAuthService,
  IdbViewAccountRepo,
  IdbViewSignatureRepo,
  SignatureRepo,
  viewAccountsProjector,
} from '../../Account';
import { encryptedCredentialsProjector } from '../../Account/domain/handlers/encryptedCredentialsProjector';
import { IdbCredentialsRepo } from '../../Account/infra/repository/IdbCredentialsRepo';
import {
  AppCalendarUseCase,
  CalendarEventRepo,
  CalendarRepo,
  IdbViewCalendarEventRepo,
  IdbViewCalendarRepo,
  viewCalendarEventsProjector,
  viewCalendarsProjector,
} from '../../Calendar';
import { AppCallUseCase, CallRepo, IdbViewCallRepo, viewCallsProjector } from '../../Call';
import {
  BasicLoggerService,
  CronJobService,
  IdbDomainEventStore,
  KeychainService,
  LocalStorageService,
  Publisher,
  SentryService,
} from '../../Common';
import { createProjectorRef, createReactorRef, Projectionist, ProjectionistConfig } from '../../Common/domain/Projectionist';
import { ApiBackupRepo } from '../../Common/infra/repositories/ApiBackupRepo';
import { ApiKeyRepo } from '../../Common/infra/repositories/ApiKeyRepo';
import { IdbBackupCursorRepo } from '../../Common/infra/repositories/IdbBackupCursorRepo';
import { BackupService } from '../../Common/infra/services/BackupService';
import { WebCrypto } from '../../Common/infra/services/crypto/WebCrypto';
import { IDBService } from '../../Common/infra/services/idb/IDBService';
import { IdbFileStore } from '../../Common/infra/stores/IdbFileStore';
import { IdbKeyStore } from '../../Common/infra/stores/IdbKeyStore';
import { IdbLedgerStore } from '../../Common/infra/stores/IdbLedgerStore';
import { AppContactUseCase, ContactRepo, IdbViewContactRepo, viewContactsProjector } from '../../Contact';
import { AppImUseCase, IdbViewImRepo, ImRepo, viewImsProjector } from '../../Im';
import { IdbViewImThreadRepo } from '../../Im/infra/repository/IdbViewImThreadRepo';
import {
  AppMailUseCase,
  IdbViewMailDraftRepo,
  IdbMailReferenceRepo,
  ProxyImapService,
  MailRepo,
  viewMailsProjector,
} from '../../Mail';
import {
  AppNotificationUseCase,
  IdbViewNotificationRepo,
  NotificationRepo,
  viewNotificationsProjector,
} from '../../Notification';
import { AppSearchUseCase } from '../../Search';
import { ApiUserRepo, AppUserUseCase } from '../../User';
import Container, {
  CalendarServicesMap,
  CallServicesMap,
  ContactServicesMap,
  ImServicesMap,
  MailServicesMap,
  OAuthServicesMap,
  ScrapAppPasswordServicesMap,
  ScrapFormAuthServicesMap,
  ScrapQRCodeAuthServicesMap,
} from '../interfaces/Container';
import { WebEnvironment } from '../interfaces/Environment';
import { createStore } from '../store';
import FuseService from '../../Search/infra/services/FuseService';
import { searchIndexReactor } from '../../Search/domain/handlers/searchIndexReactor';
import { viewSignaturesProjector } from '../../Account/domain/handlers/viewSignaturesProjector';
import { ImThreadRepo } from '../../Im/infra/repository/ImThreadRepo';

import { CoreAuthenticatorService } from '../../User/infra/services/CoreAuthenticatorService';
import { CoreApiService } from '../../User/infra/services/CoreApiService';
import { UnsupportedImService } from '../../Im/infra/services/UnsupportedImService';
import { MemorySessionRepo } from '../../User/infra/repository/session/MemorySessionRepo';
import { UnsafeSessionRepo } from '../../User/infra/repository/session/UnsafeSessionRepo';
import { MicrosoftOAuthService } from '../../Account/infra/services/MicrosoftOAuthService';
import { OutlookService } from '../../Mail/infra/services/OutlookService';
import { BasicFileService } from '../../Common/infra/services/BasicFileService';
import { GcalWebService } from '../../Calendar/infra/services/GcalWebService';
import { GoogleCalendarOAuthWebService } from '../../Account/infra/services/GoogleCalendarOAuthWebService';
import { CalendarSyncService } from '../../Calendar/infra/services/CalendarSyncService';
import { UnsupportedContactService } from '../../Contact/infra/services/UnsupportedContactService';
import { UnsupportedCallService } from '../../Call/infra/services/UnsupportedCallService';
import { UnsupportedScrapFormAuthService } from '../../Account/infra/services/UnsupportedScrapFormAuthService';
import { UnsupportedScrapQRCodeAuthService } from '../../Account/infra/services/UnsupportedScrapQRCodeAuthService';
import { IdbMailMetaRepo } from '../../Mail/infra/repository/IdbMailMetaRepo';
import { IdbMailCacheRepo } from '../../Mail/infra/repository/IdbMailCacheRepo';
import { MailSyncService } from '../../Mail/infra/services/MailSyncService';
import { DefaultMailCacheService } from '../../Mail/infra/services/DefaultMailCacheService';
import {
  IdbViewTagRepo,
  IdbViewTagRelationRepo,
  TagRepo,
  TagRelationRepo,
  viewTagsProjector,
  viewTagsRelationsProjector,
  AppTagUseCase,
} from '../../Tag';
import {
  MessageModelRepo,
  IdbViewMessageModelRepo,
  viewMessageModelsProjector,
  AppMessageModelUseCase,
} from '../../MessageModel';
import { IdbKeyCanaryStore } from '../../Common/infra/stores/IdbKeyCanaryStore';
import { AppSubscriptionUseCase } from '../../User/app/AppSubscriptionUseCase';
import { UnsuppportedScrapAppPasswordService } from '../../Account/infra/services/UnsupportedScrapAppPasswordService';

/**
 * Composition root.
 */
function createContainer(environment: WebEnvironment): Container {
  const session = environment.production ? new MemorySessionRepo() : new UnsafeSessionRepo();
  const logger = environment.production ? new SentryService(environment) : new BasicLoggerService();

  /**
   * Domain infra.
   */
  const eventStore = new IdbDomainEventStore();
  const publisher = new Publisher(eventStore);
  const ledgerStore = new IdbLedgerStore();

  const calendarEvent = new CalendarEventRepo(eventStore);
  const calendar = new CalendarRepo(eventStore);
  const notification = new NotificationRepo(eventStore);
  const mail = new MailRepo(eventStore);
  const im = new ImRepo(eventStore);
  const imThread = new ImThreadRepo(eventStore);
  const call = new CallRepo(eventStore);
  const contact = new ContactRepo(eventStore);
  const account = new AccountRepo(eventStore);
  const signature = new SignatureRepo(eventStore);
  const tag = new TagRepo(eventStore);
  const messageModel = new MessageModelRepo(eventStore);
  const tagRelation = new TagRelationRepo(eventStore);

  const viewTag = new IdbViewTagRepo();
  const viewTagRelation = new IdbViewTagRelationRepo(viewTag);
  const viewMessageModel = new IdbViewMessageModelRepo(viewTagRelation, viewTag);
  const viewCalendar = new IdbViewCalendarRepo();
  const viewCalendarEvent = new IdbViewCalendarEventRepo(viewTagRelation, viewTag);
  const viewNotification = new IdbViewNotificationRepo();
  const mailRef = new IdbMailReferenceRepo();
  const mailMeta = new IdbMailMetaRepo(viewTagRelation, viewTag);
  const mailCache = new IdbMailCacheRepo();
  const viewImThread = new IdbViewImThreadRepo(viewTagRelation, viewTag);
  const viewCall = new IdbViewCallRepo(viewTagRelation, viewTag);
  const viewIm = new IdbViewImRepo();
  const viewMailDraft = new IdbViewMailDraftRepo();
  const viewContact = new IdbViewContactRepo(viewTagRelation, viewTag);
  const viewAccount = new IdbViewAccountRepo(viewTagRelation, viewTag);
  const viewSignature = new IdbViewSignatureRepo();
  const encryptedCredentials = new IdbCredentialsRepo();

  /**
   * Core API
   */
  const authenticator = new CoreAuthenticatorService(environment.api, session, WebCrypto);
  const api = new CoreApiService(environment.api, authenticator);
  const user = new ApiUserRepo(api);

  /**
   * Common services
   */
  const keyStore = new IdbKeyStore();
  const keyCanaryStore = new IdbKeyCanaryStore();
  const cron = new CronJobService();
  const search = new FuseService();
  const localDB = new IDBService();
  const storage = new LocalStorageService();
  const fileManager = new BasicFileService(new IdbFileStore());

  const mailServices: MailServicesMap = {
    GOOGLE: new ProxyImapService(environment.proxy, encryptedCredentials, WebCrypto, storage, fileManager),
    MAIL: new ProxyImapService(environment.proxy, encryptedCredentials, WebCrypto, storage, fileManager),
    ICLOUD: new ProxyImapService(environment.proxy, encryptedCredentials, WebCrypto, storage, fileManager),
    OUTLOOK: new OutlookService(encryptedCredentials, WebCrypto, environment.microsoft.clientId, fileManager),
  };

  const imServices: ImServicesMap = {
    MOBILE: new UnsupportedImService(),
    LINKEDIN: new UnsupportedImService(),
    MESSENGER: new UnsupportedImService(),
    TIKTOK: new UnsupportedImService(),
    TWITTER: new UnsupportedImService(),
    INSTAGRAM: new UnsupportedImService(),
    WHATSAPP: new UnsupportedImService(),
  };

  const contactServices: ContactServicesMap = {
    LINKEDIN: new UnsupportedContactService(),
    MESSENGER: new UnsupportedContactService(),
    TIKTOK: new UnsupportedContactService(),
    TWITTER: new UnsupportedContactService(),
    INSTAGRAM: new UnsupportedContactService(),
    WHATSAPP: new UnsupportedContactService(),
    MOBILE: new UnsupportedContactService(),
  };

  const callServices: CallServicesMap = {
    MOBILE: new UnsupportedCallService(),
  };

  const calendarServices: CalendarServicesMap = {
    GOOGLE_CALENDAR: new GcalWebService(environment.proxy, encryptedCredentials, WebCrypto),
  };

  const oauthServices: OAuthServicesMap = {
    GOOGLE_CALENDAR: new GoogleCalendarOAuthWebService(environment.proxy),
    GOOGLE: new GoogleOAuthService(environment.proxy),
    OUTLOOK: new MicrosoftOAuthService(environment.microsoft.clientId),
  };

  const mailCacheService = new DefaultMailCacheService(mailCache, fileManager);
  const mailSync = new MailSyncService(
    mailServices,
    mailMeta,
    mailCacheService,
    mailRef,
    viewAccount,
    viewTagRelation,
    account,
    publisher,
    search
  );

  const webViewFormLoginServices: ScrapFormAuthServicesMap = {
    LINKEDIN: new UnsupportedScrapFormAuthService(),
    MESSENGER: new UnsupportedScrapFormAuthService(),
    TIKTOK: new UnsupportedScrapFormAuthService(),
    TWITTER: new UnsupportedScrapFormAuthService(),
    INSTAGRAM: new UnsupportedScrapFormAuthService(),
  };

  const webViewQRCodeLoginServices: ScrapQRCodeAuthServicesMap = {
    WHATSAPP: new UnsupportedScrapQRCodeAuthService(),
  };

  const webViewAppPasswordServices: ScrapAppPasswordServicesMap = {
    GOOGLE: new UnsuppportedScrapAppPasswordService(),
    ICLOUD: new UnsuppportedScrapAppPasswordService(),
  };

  /**
   * Projectionist
   */
  const projectionistConfig: ProjectionistConfig = {
    projectors: [
      createProjectorRef(viewCalendarsProjector, viewCalendar, (repo) => repo.clear()),
      createProjectorRef(viewCalendarEventsProjector, { viewCalendarEvent, calendarEvent }, (repo) =>
        repo.viewCalendarEvent.clear()
      ),
      createProjectorRef(viewNotificationsProjector, { viewNotification, notification }, (repo) => repo.viewNotification.clear()),
      createProjectorRef(viewMailsProjector, mailRef, (repo) => repo.clear()),
      createProjectorRef(viewImsProjector, { viewIm, viewImThread }, ({ viewIm, viewImThread }) => {
        viewIm.clear();
        viewImThread.clear();
      }),
      createProjectorRef(viewCallsProjector, viewCall, (repo) => repo.clear()),
      createProjectorRef(viewContactsProjector, viewContact, (repo) => repo.clear()),
      createProjectorRef(
        viewAccountsProjector,
        { viewAccount, viewMail: mailRef, viewCall, viewIm, viewImThread, viewNotification, viewCalendarEvent, viewContact },
        ({ viewAccount }) => {
          viewAccount.clear();
        }
      ),
      createProjectorRef(viewSignaturesProjector, viewSignature, (repo) => repo.clear()),
      createProjectorRef(viewTagsProjector, viewTag, (repo) => repo.clear()),
      createProjectorRef(viewTagsRelationsProjector, { viewTagRelation, viewTag }, ({ viewTagRelation }) =>
        viewTagRelation.clear()
      ),
      createProjectorRef(viewMessageModelsProjector, viewMessageModel, (repo) => repo.clear()),
      createProjectorRef(encryptedCredentialsProjector, encryptedCredentials, (repo) => repo.clear()),
    ],
    reactors: [
      createReactorRef(searchIndexReactor, {
        search,
        viewContact,
        viewMailDraft,
        viewCalendarEvent,
        viewImThread,
        viewCall,
        viewMail: mailRef,
      }),
    ],
  };
  // const projectionist = new Projectionist(projectionistConfig, ledgerStore, eventStore);

  const contactUc = new AppContactUseCase(
    publisher,
    contact,
    viewContact,
    logger,
    search,
    contactServices,
    viewAccount,
    viewImThread,
    viewIm,
    environment
  );

  /**
   * UseCases
   */
  const notificationUc = new AppNotificationUseCase(
    publisher,
    notification,
    calendarEvent,
    viewNotification,
    viewCalendarEvent,
    viewAccount,
    viewContact,
    viewImThread,
    viewTagRelation,
    logger
  );
  const calendarUc = new AppCalendarUseCase(
    publisher,
    calendar,
    calendarEvent,
    account,
    notification,
    viewCalendar,
    viewCalendarEvent,
    viewNotification,
    viewImThread,
    mailRef,
    viewMailDraft,
    viewCall,
    viewAccount,
    logger,
    mailSync
  );
  const calendarSync = new CalendarSyncService(
    publisher,
    eventStore,
    calendar,
    calendarEvent,
    account,
    viewCalendar,
    viewCalendarEvent,
    calendarServices,
    viewAccount,
    logger
  );
  const mailUc = new AppMailUseCase(
    publisher,
    notificationUc,
    calendarUc,
    mail,
    mailRef,
    viewMailDraft,
    viewAccount,
    viewCalendarEvent,
    viewSignature,
    viewTag,
    viewTagRelation,
    mailServices,
    logger,
    storage,
    search,
    fileManager,
    mailSync
  );
  const imUc = new AppImUseCase(
    publisher,
    notificationUc,
    contactUc,
    calendarUc,
    im,
    imThread,
    account,
    viewIm,
    viewImThread,
    viewAccount,
    viewCalendarEvent,
    viewContact,
    imServices,
    logger,
    search,
    fileManager,
    environment
  );
  const callUc = new AppCallUseCase(
    publisher,
    notificationUc,
    calendarUc,
    call,
    viewCall,
    viewAccount,
    viewCalendarEvent,
    callServices,
    logger,
    environment
  );
  const searchUc = new AppSearchUseCase(
    search,
    viewContact,
    mailRef,
    viewMailDraft,
    viewIm,
    viewCalendarEvent,
    viewCall,
    viewImThread,
    viewAccount,
    mailSync
  );
  const accountUc = new AppAccountUseCase(
    publisher,
    account,
    signature,
    viewAccount,
    viewSignature,
    encryptedCredentials,
    WebCrypto,
    mailServices,
    imServices,
    callServices,
    contactServices,
    calendarServices,
    oauthServices,
    webViewFormLoginServices,
    webViewQRCodeLoginServices,
    webViewAppPasswordServices,
    searchUc,
    logger,
    environment,
    mailSync
  );
  const tagUc = new AppTagUseCase(
    publisher,
    viewTag,
    viewTagRelation,
    tagRelation,
    mailMeta,
    viewCall,
    viewCalendarEvent,
    search,
    logger
  );

  const messageModelUc = new AppMessageModelUseCase(publisher, mailUc, messageModel, viewMessageModel, logger);

  const subscriptionUc = new AppSubscriptionUseCase(session, api, logger);

  /**
   * Container.
   */
  return {
    logger,
    cron,
    fileManager,
    notification: notificationUc,
    calendar: calendarUc,
    calendarSync,
    mail: mailUc,
    im: imUc,
    call: callUc,
    contact: contactUc,
    search: searchUc,
    tag: tagUc,
    messageModel: messageModelUc,
    subscription: subscriptionUc,
    user: new AppUserUseCase(
      session,
      user,
      authenticator,
      api,
      logger,
      localDB,
      encryptedCredentials,
      projectionistConfig,
      Projectionist,
      ledgerStore,
      publisher,
      eventStore,
      BackupService,
      ApiBackupRepo,
      IdbBackupCursorRepo,
      WebCrypto,
      KeychainService,
      ApiKeyRepo,
      keyStore,
      keyCanaryStore,
      calendarUc,
      accountUc,
      cron,
      storage,
      {}
    ),
    account: accountUc,
    environment,
    mailSync,
  };
}

export const store = createStore(createContainer(environment));

export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;

/**
 * @note Typescript doesn't let you write : typeof someFunc<TypeParam>.
 *       inferenceWrapper allows us to use typeof on a 'concrete' value and
 *       satisfy the explicit module boundary types rule with minimal effort.
 *
 *       See https://stackoverflow.com/questions/40249906/using-a-generic-type-argument-with-typeof-t
 *       and https://stackoverflow.com/questions/50321419/typescript-returntype-of-generic-function
 */
const inferenceWrapper = () => useDispatch<AppDispatch>();
export const useAppDispatch = (): ReturnType<typeof inferenceWrapper> => useDispatch<AppDispatch>();
