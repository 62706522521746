import { loginLinkedIn, useAppDispatch } from '@focus-front/core';
import { Box, Checkbox, FormControlLabel, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { LinkedInLoginForm, WizardContext } from './WizardContext';
import ProxyConfig from './ProxyConfig';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@material-ui/icons';

const useClasses = makeStyles((theme) => ({
  checkboxLabel: {
    fontSize: 12,
  },
}));
export default function LinkedInLogin() {
  const { t } = useTranslation();
  const c = useClasses();

  const { goBack, forms, setLoading, setForms, goNext } = useContext(WizardContext);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);

  const form = useForm<LinkedInLoginForm>({
    mode: 'onChange',
    defaultValues: forms.LINKEDIN_LOGIN,
  });

  const showProxyConfig = form.watch('use_proxy');

  const onSubmit = async ({
    linkedin_user,
    linkedin_password,
    linkedin_cookie,
    use_proxy,
    ...proxy_settings
  }: LinkedInLoginForm) => {
    setError(null);
    setLoading(true);
    setForms({
      ...forms,
      LINKEDIN_LOGIN: { linkedin_user, linkedin_password, linkedin_cookie: undefined, use_proxy, ...proxy_settings },
    });

    const { error, result } = await dispatch(
      loginLinkedIn(
        linkedin_user,
        linkedin_password,
        use_proxy
          ? {
              host: proxy_settings.proxy_host,
              port: proxy_settings.proxy_port,
              password: proxy_settings.proxy_password,
              username: proxy_settings.proxy_username,
            }
          : undefined
      )
    );

    if (error) {
      // This error comes from ScrapFormAuthService
      if (error === 'Invalid Credentials') {
        setError(t('wizard.linkedInLogin.invalidCredentials'));
      } else {
        setError(error);
      }
      setLoading(false);
    } else {
      if (result) {
        setForms({
          ...forms,
          LINKEDIN_LOGIN: { linkedin_user, linkedin_password, linkedin_cookie: result, use_proxy, ...proxy_settings },
        });
      }
      goNext();
      setLoading(false);
    }
  };

  return (
    <div>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.linkedInLogin.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.linkedInLogin.subtitle')}</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="chrome-off">
        <Box mt={3} mb={5} maxWidth={300}>
          <TextField
            inputRef={form.register({ required: true })}
            name="linkedin_user"
            variant="standard"
            label={t('wizard.linkedInLogin.userLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            autoComplete="off"
            autoFocus
          />
          <TextField
            inputRef={form.register({ required: true })}
            name="linkedin_password"
            variant="standard"
            label={t('wizard.linkedInLogin.passwordLabel')}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 8 }}
            autoComplete="new-password"
            type="password"
          />
        </Box>
        <FormControlLabel
          control={
            <Controller
              control={form.control}
              name="use_proxy"
              render={({ value, onChange }) => (
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  value={value}
                  onChange={(e, check) => onChange(check)}
                  name="use_proxy"
                />
              )}
            />
          }
          label={t('wizard.proxyConfig.useProxy')}
          classes={{
            label: c.checkboxLabel,
          }}
        />
        {showProxyConfig && <ProxyConfig useForm={form} />}
        <NextButton type="submit" />
      </form>
    </div>
  );
}
