import { mockUuid, randomEnum, TagColor, ViewTag } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { pricetags } from 'ionicons/icons';
import React from 'react';
import Tag from './Tag';

const ALL = 100;

type Props = {
  tags: ViewTag[];
  listMode?: boolean;
  maxVisible?: number;
};

const useClasses = makeStyles((theme) => ({
  tags: {
    marginTop: 5,
    minHeight: 26,
  },
  tagsList: {
    display: 'flex',
    alignItems: 'center',
  },
  moreIndicator: {
    marginLeft: 6,
    fontWeight: 'bold',
  },
  tagsIcon: {
    alignSelf: 'center',
    opacity: 0.3,
    verticalAlign: 'middle',
    marginRight: 5,
    fontSize: 16,
  },
}));

function Tags({ tags, listMode = false, maxVisible = ALL }: Props) {
  const c = useClasses();
  const items = (
    <div className={c.tagsList}>
      {tags.map((tag) => (
        <Tag key={tag.id} tag={tag} />
      )).slice(0, maxVisible)}
      {tags.length > maxVisible && (
        <span className={c.moreIndicator}>{`+${tags.length - maxVisible}`}</span>
      )}
    </div>
  );
  if (!listMode) return items;
  return (
    <div className={c.tags}>
      <IonIcon icon={pricetags} className={c.tagsIcon} />
      {items}
    </div>
  );
}

export default React.memo(Tags);
