import {
  stopImCron,
  getThread,
  loadIms,
  useAppDispatch,
  Uuid,
  ViewIm,
  ViewImThread,
  initImCron,
  fetchNewInThread,
  notifyNewIm,
  toUTCDateTime,
  threadViewSelector,
  unloadThreadView,
  Taggable,
  useCancellablePromise,
} from '@focus-front/core';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

type ImThreadPastStatus = 'END' | 'ERROR' | 'LOADING' | 'IDLE_AUTO' | 'IDLE_MANUAL' | 'UNSUPPORTED';

const LOAD_MORE_QUANTITY = 20;

export function useImThread(thread_id: Uuid, live = false) {
  const dispatch = useAppDispatch();
  const [thread, setThread] = useState<(ViewImThread & Taggable) | null>(null);
  const [ims, setIms] = useState<ViewIm[]>([]);
  const [status, setStatus] = useState<ImThreadPastStatus>('LOADING');

  const interval = useRef(null);

  useEffect(() => {
    loadThread();
    loadMore();
    if (live) {
      dispatch(stopImCron());
      interval.current = setInterval(async () => {
        const { result, error } = await dispatch(fetchNewInThread(thread_id));
        if (error) clearInterval(interval.current);
        if (result && result.length) {
          setIms((ims) => [...ims, ...result]);
        }
      }, 1000 * 5);
    }
    return () => {
      if (live) {
        clearInterval(interval.current);
        dispatch(initImCron());
      }
    };
  }, []);

  // Load the thread
  const loadThread = useCallback(async () => {
    const { result, error } = await dispatch(getThread(thread_id));
    /**
     * @todo Handle error properly
     */
    if (error) return alert(error);
    setThread(result);
  }, [thread_id]);

  // Load more past messages
  const loadMore = async () => {
    setStatus('LOADING');
    const { result, error } = await dispatch(
      loadIms(thread_id, LOAD_MORE_QUANTITY, ims.filter((im) => im.sync_status === 'SYNC').length)
    );
    if (error) return setStatus(error === 'UnsupportedDevice' ? 'UNSUPPORTED' : 'ERROR');
    if (result.must_reload_thread) await loadThread();
    setIms([...result.ims.reverse(), ...ims]);

    if (result.reached_end_local && !result.reached_end_remote) setStatus('IDLE_MANUAL');
    else if (result.reached_end_local && result.reached_end_remote) setStatus('END');
    else setStatus('IDLE_AUTO');
  };

  return { thread, status, ims, loadMore };
}

export function useImThreadRedux(thread_id: Uuid) {
  const dispatch = useAppDispatch();
  const { ims, thread } = useSelector(threadViewSelector);
  const [status, setStatus] = useState<ImThreadPastStatus>('LOADING');
  const { cancellablePromise } = useCancellablePromise([thread_id]);

  const interval = useRef(null);

  useEffect(() => {
    (async () => {
      dispatch(unloadThreadView());
      await cancellablePromise(loadThread());
      await cancellablePromise(loadMore());
      dispatch(stopImCron());
    })();

    interval.current = setInterval(async () => {
      const { error } = await dispatch(fetchNewInThread(thread_id, true));
      if (error) clearInterval(interval.current);
    }, 1000 * 5);

    return () => {
      clearInterval(interval.current);
      dispatch(initImCron());
      dispatch(unloadThreadView());
    };
  }, [thread_id]);

  // Load the thread
  const loadThread = useCallback(async () => {
    await dispatch(getThread(thread_id, true));
  }, [thread_id]);

  // Load more past messages
  const loadMore = async () => {
    setStatus('LOADING');
    const { result, error } = await dispatch(loadIms(thread_id, LOAD_MORE_QUANTITY, null, true));
    if (error) return setStatus(error === 'UnsupportedDevice' ? 'UNSUPPORTED' : 'ERROR');
    if (result.must_reload_thread) await loadThread();

    if (result.reached_end_local && !result.reached_end_remote) setStatus('IDLE_MANUAL');
    else if (result.reached_end_local && result.reached_end_remote) setStatus('END');
    else setStatus('IDLE_AUTO');
  };

  return { thread, status, ims, loadMore };
}
