import { Uuid } from '../../../Common';
import i18n from '../../../Common/infra/services/i18nextService';
import { Select } from '../../../Common/utils';

/**
 * @note GROUP_THREAD is used to store group data as an attendee for Notifications
 */
export type IdentifierType =
  | 'PHONE_NUMBER'
  | 'EMAIL_ADDRESS'
  | 'MESSENGER_ID'
  | 'MESSENGER_THREAD_ID'
  | 'TIKTOK_ID'
  | 'TIKTOK_THREAD_ID'
  | 'TWITTER_ID'
  | 'TWITTER_THREAD_ID'
  | 'INSTAGRAM_ID'
  | 'INSTAGRAM_THREAD_ID'
  | 'LINKEDIN_ID'
  | 'LINKEDIN_THREAD_ID'
  | 'GROUP_THREAD';

export type ContactPhone = {
  label: string;
  number: string;
  managing_account?: Uuid;
};
export type ContactEmail = {
  label: string;
  address: string;
  managing_account?: Uuid;
};
export type ContactSocial = {
  identifier_type: IdentifierType;
  identifier: string;
  /**
   * UUid of accounts that generated this contact, useful to handle contacts when an account is deleted
   */
  managing_account: Uuid;
};

/**
 * Projection of a Contact
 */
export type ViewContact = Readonly<{
  id: Uuid /** Aggregate id. */;
  full_name: string;
  profile_picture?: string;
  phone: ContactPhone[];
  email: ContactEmail[];
  social: ContactSocial[];
}>;

/**
 * Stored version of the projection for IDB
 */
export type IdbViewContact = ViewContact & { identifiers: string[]; managing_accounts: Uuid[] };

export type NewContactDTO = Omit<Select<ViewContact, 'full_name'>, 'id'>;

export type UpdateContactDTO = Partial<NewContactDTO>;

export function createViewContact(aggregateId: Uuid, values: NewContactDTO): ViewContact {
  return {
    email: [],
    phone: [],
    social: [],
    ...values,
    id: aggregateId,
  };
}

export type Attendee = {
  display_name?: string | undefined;
  profile_picture?: string | undefined;
  identifier: string;
  identifier_type: IdentifierType;
};

export function displayAttendeeName(attendee: Omit<Attendee, 'profile_picture' | 'identifier_type'>): string {
  if (!attendee) return 'No attendee';
  const name = attendee.display_name || attendee.identifier || 'Unknown';
  if (name === 'YOU') return i18n.t('attendee_YOU');
  return name;
}

export function displayAttendeeFull(attendee: Omit<Attendee, 'profile_picture' | 'identifier_type'>): string {
  if (attendee.display_name) return `${attendee.display_name}<${attendee.identifier}>`;
  return attendee.identifier;
}
