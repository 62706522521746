import dayjs from 'dayjs';
import { chevronForward, notificationsOutline, playForwardOutline } from 'ionicons/icons';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
  CallDocument,
  displayAttendeeName,
  eventByAttachedSelector,
  notificationByAttachedSelector,
  SearchResult,
} from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import { Box, Chip, makeStyles, Tooltip, Typography } from '@material-ui/core';

import Highlighter from '../Search/Highlighter';
import { PhoneMissedRounded, PhoneForwardedRounded, SettingsPhoneRounded, PhoneCallbackRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import AccountLabel from '../Common/AccountLabel';
import AttendeeAvatar from '../Contact/AttendeeAvatar';
import { NotificationChip } from '../Common/NotificationChip';
import { EventChip } from '../Common/EventChip';
import Tags from '../Tag/Tags';

const useClasses = makeStyles((theme) => ({
  root: (props: { isOpen: boolean }) => ({
    padding: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: props.isOpen && theme.palette.background.default,
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '& .Avatar': {
      flexShrink: 0,
    },
    borderLeft: props.isOpen && `solid 4px ${theme.palette.secondary.main}`,
  }),
  right: {
    paddingLeft: 10,
    flex: 1,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  body: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  date: {
    fontSize: '0.75rem',
    opacity: 0.5,
  },
  arrow: {
    fontSize: 24,
    color: theme.palette.grey[700],
  },
  missed: {
    color: theme.palette.error.main,
    display: 'inline-flex',
  },
  incoming: {
    color: theme.palette.success.main,
    display: 'inline-flex',
  },
  outgoing: {
    color: theme.palette.grey[500],
    display: 'inline-flex',
  },
}));

interface Props {
  onClick: () => void;
  call: SearchResult<CallDocument>;
  index: number;
}

export function CallSearchResult({ onClick, call }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const notification = useSelector(notificationByAttachedSelector(call.item.id));
  const event = useSelector(eventByAttachedSelector(call.item.id));

  const c = useClasses({
    isOpen: (notification && location.pathname.includes(notification.id)) || (event && location.pathname.includes(event.id)),
  });

  return (
    <div className={c.root} onClick={onClick}>
      <AttendeeAvatar
        display_name={displayAttendeeName(call.item.from_attendee)}
        avatar_url={call.item.from_attendee.profile_picture}
        size="small"
      />
      <div className={c.right}>
        <div className={c.top}>
          <Box display="flex" alignItems="center">
            <Typography variant="h4" style={{ marginRight: 10 }}>
              {call.matches.find((match) => match.key === 'from_attendee.display_name') ? (
                <Highlighter
                  text={displayAttendeeName(call.item.from_attendee)}
                  ranges={call.matches.find((match) => match.key === 'from_attendee.display_name').indices}
                />
              ) : (
                displayAttendeeName(call.item.from_attendee)
              )}
            </Typography>
            {!!notification && <NotificationChip id={notification.id} />}
            {!!event && <EventChip id={event.id} />}
          </Box>
          <div className={c.date}>{dayjs(call.item.date).format('L')}</div>
        </div>
        <Box display="flex" alignItems="center" minHeight="24px">
          {call.item.type === 'MISSED' && (
            <div className={c.missed}>
              <PhoneMissedRounded />
            </div>
          )}
          {call.item.type === 'OUTGOING' && (
            <div className={c.outgoing}>
              <PhoneForwardedRounded />
            </div>
          )}
          {call.item.type === 'NO_ANSWER' && (
            <div className={c.missed}>
              <SettingsPhoneRounded />
            </div>
          )}
          {call.item.type === 'INCOMING' && (
            <div className={c.incoming}>
              <PhoneCallbackRounded />
            </div>
          )}
          <Box display="flex" alignItems="center" className={c.body}>
            <Typography variant="subtitle1" style={{ marginRight: 5 }}>
              {t('notification.type.call_' + call.item.type.toLowerCase())} {t('onDevice')}
            </Typography>
            <AccountLabel account_id={call.item.account_id} />
          </Box>

          <IonIcon className={c.arrow} icon={chevronForward} />
        </Box>
        {call.item.tags.length > 0 && <Tags tags={call.item.tags} listMode />}
      </div>
    </div>
  );
}

export default memo(CallSearchResult, (prev, next) => prev.call.item.id === next.call.item.id);
