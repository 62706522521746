import { Uuid } from '../../domain/Uuid';
import { Interface } from '../../utils';
import { Base64 } from '../../utils/Base64';
import { IdbKeyCanaryStore } from './IdbKeyCanaryStore';

/**
 * 
 */
export interface KeyCanary {
    branch: Uuid;
    canary: Base64;
  }

/**
 *
 */
export interface KeyCanaryStore extends Interface<IdbKeyCanaryStore> {}

/**
 *
 */
export class MissingCanaryError extends Error {
  constructor() {
    super(`Canary not found.`);
    this.name = 'MissingCanaryError';
  }
}

/**
 *
 */
export class KeyCanaryTriggeredError extends Error {
  constructor(readonly branch: Uuid) {
    super(`KeyCanary triggered for branch : ${branch}.`);
    this.name = 'KeyCanaryTriggeredError';
  }
}


