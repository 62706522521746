import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  NewCalendarEventDTO,
  calendarsFormSelector,
  cancelPlan,
  editCalendarEvent,
  isEvent,
  isNotification,
  replanMissedEvent,
  updateCalendarEvent,
  Uuid,
  useAppDispatch,
  CalendarEventInstanceId,
  prettyPrint,
} from '@focus-front/core';

import { EventForm } from '../Event/EventForm';
import ActionModal from './ActionModal';
import { ActionModalProps } from './ActionModalProps';

interface Props {
  event_id: Uuid | CalendarEventInstanceId;
}

export default function RePlanAction({ onClose, onDone, event_id, context }: ActionModalProps & Props) {
  const dispatch = useAppDispatch();
  const { mode, eventDraft, recurringEventRoot, editedEventId } = useSelector(calendarsFormSelector);
  const [mounted, setMounted] = useState(false);
  const handleSubmit = async (values: NewCalendarEventDTO) => {
    if (isEvent(context)) await dispatch(updateCalendarEvent(event_id, values));
    if (isNotification(context)) await dispatch(replanMissedEvent(context.id, values));
    onDone('replan');
  };

  useEffect(() => {
    dispatch(editCalendarEvent('ACTIONBAR', event_id, true));
    setMounted(true);
    return () => {
      dispatch(cancelPlan());
    };
  }, [event_id]);

  useEffect(() => {
    if (mode === 'CALENDAR' && mounted) onClose?.();
  }, [mode]);

  if (mode !== 'ACTIONBAR') return null;

  return (
    <ActionModal>
      <EventForm
        defaultValues={eventDraft}
        onSubmit={handleSubmit}
        recurringEventRoot={recurringEventRoot}
        editedEventId={editedEventId}
        replan
      />
    </ActionModal>
  );
}
