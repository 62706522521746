import { IonIcon } from '@ionic/react';
import { IconButton, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { close, searchOutline } from 'ionicons/icons';
import React from 'react';

const useClasses = makeStyles((theme) => ({
  field: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .searchIcon': {
      marginRight: theme.spacing(1),
      fontSize: 24,
    },
  },
}));

export default function SearchTextField({
  showClear,
  clickClear,
  ...props
}: TextFieldProps & { showClear: boolean; clickClear: () => void }) {
  const c = useClasses();
  return (
    <TextField
      variant="outlined"
      fullWidth
      InputProps={{
        className: c.field,
        startAdornment: <IonIcon className="searchIcon" icon={searchOutline} />,
        endAdornment: (
          <IconButton onClick={clickClear} style={{ opacity: showClear ? 1 : 0 }}>
            <IonIcon icon={close} />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
}
