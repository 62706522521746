import { AppDispatch, AppGetState } from '../../_container/store';
import Container from '../../_container/interfaces/Container';
import { Uuid, Result, openSettingsDialog, toUTCDateTimeMs } from '../../Common';
import { ViewMessageModel, UpdateMessageModelDTO } from '../domain';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewImThread } from '../../Im';
import { Taggable } from '../../Tag';
import { EmailDeps, IMDeps, PreviewMessageModel } from '../app/AppMessageModelUseCase';
import dayjs from 'dayjs';
import i18n from '../../Common/infra/services/i18nextService';

export interface MessageModelsState {
  entities: (ViewMessageModel & Taggable)[];
  modelFrom: string | null;
  sort: MessageModelSortOptions;
}

export type MessageModelSortOptions = {
  sortBy: MessageModelSortByOption;
  sortOrder: 'asc' | 'desc';
};

export type MessageModelSortByOption = 'name' | 'create_date' | 'update_date' | 'last_use_date' | 'use_count' | 'tags' | 'none';

export type SortOption = {
  name: MessageModelSortByOption;
  label: string;
  direction: 'asc' | 'desc';
};

const DEFAULT_SORT_OPTIONS: MessageModelSortOptions = { sortBy: 'name', sortOrder: 'asc' };

export const sortOptions: SortOption[] = [
  {
    name: 'name',
    label: i18n.t('message_model.picker.sort_options.name_asc'),
    direction: 'asc',
  },
  {
    name: 'name',
    label: i18n.t('message_model.picker.sort_options.name_desc'),
    direction: 'desc',
  },
  {
    name: 'create_date',
    label: i18n.t('message_model.picker.sort_options.create_date_asc'),
    direction: 'asc',
  },
  {
    name: 'create_date',
    label: i18n.t('message_model.picker.sort_options.create_date_desc'),
    direction: 'desc',
  },
  {
    name: 'update_date',
    label: i18n.t('message_model.picker.sort_options.update_date'),
    direction: 'desc',
  },
  {
    name: 'last_use_date',
    label: i18n.t('message_model.picker.sort_options.last_use_date'),
    direction: 'desc',
  },
  {
    name: 'use_count',
    label: i18n.t('message_model.picker.sort_options.use_count'),
    direction: 'desc',
  },
];

export const messageModelsSlice = createSlice({
  name: 'messageModels',
  initialState: {
    entities: [],
    modelFrom: null,
    sort: DEFAULT_SORT_OPTIONS,
  } as MessageModelsState,
  reducers: {
    loadAllMessageModels: (state, { payload }: PayloadAction<(ViewMessageModel & Taggable)[]>) => {
      state.entities = payload;
    },
    addMessageModel: (state, { payload }: PayloadAction<ViewMessageModel & Taggable>) => {
      state.entities.push(payload);
    },
    setModelFromContent: (state, { payload }: PayloadAction<string>) => {
      state.modelFrom = payload;
    },
    resetModelFromContent: (state) => {
      state.modelFrom = null;
    },
    setMessageModels: (state, { payload }: PayloadAction<(ViewMessageModel & Taggable)[]>) => {
      state.entities = payload;
    },
    sortMessageModels: (state, { payload }: PayloadAction<MessageModelSortOptions | undefined>) => {
      const { sortBy, sortOrder } = payload || state.sort;

      const op = sortOrder === 'asc' ? -1 : 1;

      state.entities.sort((a, b) => {
        const { aValue, bValue } = getSortParam(sortBy, a, b);
        if (aValue < bValue) {
          return op;
        }
        if (aValue > bValue) {
          return -op;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -op : op;
      });

      state.sort.sortBy = sortBy;
      state.sort.sortOrder = sortOrder;
    },
  },
});

export const {
  loadAllMessageModels,
  addMessageModel,
  setModelFromContent,
  resetModelFromContent,
  setMessageModels,
  sortMessageModels,
} = messageModelsSlice.actions;

export const modelFromSelector = (state: { messageModels: MessageModelsState }) => {
  return state.messageModels.modelFrom;
};

export const messageModelsSelector = (state: { messageModels: MessageModelsState }) => {
  return state.messageModels.entities;
};

export const selectableMessageModelsSelector = (state: { messageModels: MessageModelsState }) => {
  // return state.messageModels.entities.filter((m) => m.body);
  return state.messageModels.entities;
};

export const messageModelsSortSelector = (state: { messageModels: MessageModelsState }) => {
  return state.messageModels.sort;
};

// Bridge thunk
export const createNewMessageModelFromContent =
  (content: string) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    dispatch(setModelFromContent(content));
    dispatch(openSettingsDialog('message_models/new'));
  };

// Bridge Thunk
export const getAllMessageModels =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { messageModel }: Container): Promise<void> => {
    const { result, error } = await messageModel.getAllMessageModels();
    if (result) {
      dispatch(loadAllMessageModels(result));
    }
  };

// Bridge Thunk
export const getSortedMessageModels =
  (sortOptions?: MessageModelSortOptions) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    await dispatch(getAllMessageModels());
    dispatch(sortMessageModels(sortOptions));
  };

// Bridge Thunk
export const createMessageModel =
  (name: string) =>
  async (dispatch: AppDispatch, getState: AppGetState, { messageModel }: Container): Promise<Result<ViewMessageModel>> => {
    const body = getState().messageModels.modelFrom || undefined;
    const { result, error } = await messageModel.createMessageModel(name, body);
    if (error) return { error };
    return { result };
  };

export const updateMessageModel =
  (message_model_id: Uuid, values: UpdateMessageModelDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { messageModel }: Container): Promise<Result<ViewMessageModel>> => {
    return messageModel.updateMessageModel(message_model_id, values);
  };

export const useMessageModel =
  (message_model_id: Uuid, count: number, deps: EmailDeps | IMDeps) =>
  async (dispatch: AppDispatch, getState: AppGetState, { messageModel }: Container): Promise<Result<string>> => {
    return messageModel.useMessageModel(message_model_id, count, deps);
  };

export const deleteMessageModel =
  (message_model_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { messageModel }: Container): Promise<string | undefined> => {
    const { error } = await messageModel.deleteMessageModel(message_model_id);
    return error;
  };

// Bridge Thunk
export const previewMessageModel =
  (message_model_id: Uuid, deps: EmailDeps | IMDeps) =>
  async (dispatch: AppDispatch, getState: AppGetState, { messageModel }: Container): Promise<PreviewMessageModel> => {
    const result = await messageModel.previewMessageModel(message_model_id, deps);
    return result;
  };

export default messageModelsSlice.reducer;

function getSortParam(sortBy: MessageModelSortByOption, a: ViewMessageModel, b: ViewMessageModel): { aValue: any; bValue: any } {
  switch (sortBy) {
    case 'name':
      return { aValue: a.name.toLowerCase(), bValue: b.name.toLowerCase() };
    case 'create_date':
      return { aValue: a.create_date, bValue: b.create_date };
    case 'update_date':
      return {
        aValue: a.update_date || toUTCDateTimeMs(dayjs('2000-01-01T00:00:00.000Z')),
        bValue: b.update_date || toUTCDateTimeMs(dayjs('2000-01-01T00:00:00.000Z')),
      };
    case 'last_use_date':
      return {
        aValue: a.last_use_date || toUTCDateTimeMs(dayjs('2000-01-01T00:00:00.000Z')),
        bValue: b.last_use_date || toUTCDateTimeMs(dayjs('2000-01-01T00:00:00.000Z')),
      };
    case 'use_count':
      return { aValue: a.use_count, bValue: b.use_count };
    default:
      return { aValue: a.name.toLowerCase(), bValue: b.name.toLowerCase() };
  }
}
