import { EmailDeps, IMDeps, PreviewMessageModel, previewMessageModel as previewModel, useAppDispatch, useMessageModel, Uuid, ViewImThread, ViewMailDraft, ViewMessageModel } from "@focus-front/core";
import { htmlToText } from 'html-to-text';

const isMailDraft = (deps: ViewMailDraft | ViewImThread): deps is ViewMailDraft => !!(deps as ViewMailDraft)?.from_attendee;

export function useMessageModelActions(setContent: (value: string) => void, defaultValues: ViewMailDraft | ViewImThread, parent_id?: Uuid) {
  const dispatch = useAppDispatch();

  const values: EmailDeps | IMDeps = isMailDraft(defaultValues) ? { type: 'email', draft_id: defaultValues.id, parent_id } : { type: 'im', thread: defaultValues };

  const applyMessageModel = async (messageModel?: ViewMessageModel) => {
    const body = await dispatch(useMessageModel(messageModel.id, messageModel.use_count, values));

    if (isMailDraft(defaultValues)) {
      setContent(body.result);
    }
    else {
      setContent(getTextFromHTML(body.result));
    }
  }

  const previewMessageModel = async (messageModel: ViewMessageModel): Promise<PreviewMessageModel> => {
    const preview = await dispatch(previewModel(messageModel.id, values));
    return isMailDraft(defaultValues) ? preview : { ...preview, content: getTextFromHTML(preview.content) };
  }

  return { applyMessageModel, previewMessageModel };
}

function getTextFromHTML(html) {
  return htmlToText(html, {
    wordwrap: false,
    selectors: [
      { selector: 'a', options: { hideLinkHrefIfSameAsText: true } },
    ],
  });
}