import React from 'react';
import { AppBar, Container, IconButton, Toolbar, Typography } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

export default function SettingsHeader({ handleClose }) {
  const { t } = useTranslation();
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar variant="dense" style={{ height: 50 }}>
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} maxWidth="lg">
          <Typography variant="h1">{t('header.settings')}</Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <IonIcon icon={closeOutline} />
          </IconButton>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
