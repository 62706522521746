import { Typography, Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { UnfoldMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton, NextButton } from './NavButton';
import { WizardContext } from './WizardContext';

type Props = {
  title: string;
  subtitle: string;
  label: string;
  infos: string;
  value?: number | null;
  onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
};
export function HistoryConfig({ title, subtitle, label, infos, value, onChange }: Props) {
  const { t } = useTranslation();
  const { goNext, goBack } = useContext(WizardContext);

  return (
    <>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
      <Box mt={3} mb={5}>
        <Typography variant="body1">{label}</Typography>
        <FormControl variant="outlined" fullWidth>
          <Select value={value || null} onChange={onChange} displayEmpty IconComponent={UnfoldMore}>
            <MenuItem value={null}>{t('wizard.historyConfig.noHistory')}</MenuItem>
            <MenuItem value={24 * 2}>{t('wizard.historyConfig.48h')}</MenuItem>
            <MenuItem value={24 * 30}>{t('wizard.historyConfig.1m')}</MenuItem>
          </Select>
        </FormControl>
        <Alert color="info" style={{ marginTop: 16 }}>
          <Typography variant="subtitle2">{infos}</Typography>
        </Alert>
      </Box>
      <NextButton onClick={goNext} />
    </>
  );
}

export function MailHistoryConfig() {
  const { t } = useTranslation();
  const { forms, setForms } = useContext(WizardContext);

  return (
    <HistoryConfig
      title={t('wizard.historyConfig.title')}
      subtitle={t('wizard.historyConfig.subtitle')}
      label={t('wizard.historyConfig.label')}
      infos={t('wizard.historyConfig.infos')}
      value={forms.MAIL_HISTORY_CONFIG}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        setForms({ ...forms, MAIL_HISTORY_CONFIG: e.target.value ? parseInt(e.target.value) : null })
      }
    />
  );
}
