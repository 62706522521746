import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Contact } from '../../domain';

/**
 * Repository.
 */
export class ContactRepo extends AggregateRepo<Contact> {
  constructor(eventStore: DomainEventStore) {
    super(Contact, eventStore);
  }
}
