import { Result, Uuid } from '../../Common';
import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/store';
import { UpdateSignatureDTO, ViewSignature } from '../domain';
import { selectSignatureSuccess, deleteSignatureSuccess } from './accounts.slice';

export const getSignaturesList = () => async (
  dispatch: AppDispatch,
  getState: AppGetState,
  { account }: Container
): Promise<Result<ViewSignature[]>> => {
  return account.getSignatures();
};

export const createSignature = (name: string) => async (
  dispatch: AppDispatch,
  getState: AppGetState,
  { account }: Container
): Promise<Result<ViewSignature>> => {
  return account.createSignature(name);
};

export const updateSignature = (signature_id: Uuid, values: UpdateSignatureDTO) => async (
  dispatch: AppDispatch,
  getState: AppGetState,
  { account }: Container
): Promise<Result<ViewSignature>> => {
  return account.updateSignature(signature_id, values);
};

export const deleteSignature = (signature_id: Uuid) => async (
  dispatch: AppDispatch,
  getState: AppGetState,
  { account }: Container
): Promise<string | undefined> => {
  const { error } = await account.deleteSignature(signature_id);
  if (!error) dispatch(deleteSignatureSuccess(signature_id));
  return error;
};

export const selectSignature = (account_id: Uuid, signature_id: Uuid) => async (
  dispatch: AppDispatch,
  getState: AppGetState,
  { account }: Container
): Promise<string | undefined> => {
  const { error } = await account.selectSignature(account_id, signature_id);
  if (!error) dispatch(selectSignatureSuccess({ account_id, signature_id }));
  return error;
};
