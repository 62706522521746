import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { displayAttendeeFull, displayAttendeeName, Uuid } from '@focus-front/core';
import { Attachment, useMailView } from '@focus-front/ui';
import { Box, IconButton, LinearProgress, makeStyles, Typography } from '@material-ui/core';

import AccountLabel from '../Common/AccountLabel';
import ErrorMessage from '../Common/ErrorMessage';
import AttendeeAvatarPopover from '../Contact/AttendeeAvatarPopover';
import Tags from '../Tag/Tags';
import EditableTags from '../Tag/EditableTags';
import { ArrowDropDown } from '@material-ui/icons';
import MailDetails from './MailDetails';

const useClasses = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  detailText: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  contactContainer2: {
    marginLeft: theme.spacing(2),
  },
  body: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  attachments: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function MailView({ mail_id }: { mail_id: Uuid }) {
  const classes = useClasses();
  const { t } = useTranslation();

  const { bodyRef, mail, attachments, status, errorMessage } = useMailView(mail_id);

  const to = useMemo(() => {
    if (!mail) return '';
    const attendees = [...(mail.to_attendees || []), ...(mail.cc_attendees || [])];
    return attendees.map(displayAttendeeName).join(', ');
  }, [mail]);

  return (
    <div>
      {mail && (
        <div className={classes.header}>
          <div>
            <Typography variant="h1">{mail.subject}</Typography>

            <div className={classes.contactContainer}>
              <AttendeeAvatarPopover attendee={mail.from_attendee} />
              <div className={classes.contactContainer2}>
                <Typography variant="h4" component="span">
                  {`${mail.from_attendee.display_name || mail.from_attendee.identifier} `}
                </Typography>
                {mail.from_attendee.display_name && mail.from_attendee.display_name !== mail.from_attendee.identifier && (
                  <Typography variant="body2" component="span">
                    {`<${mail.from_attendee.identifier}>`}
                  </Typography>
                )}
                <Typography variant="body2" component="div">
                  <strong>{t('mailForm.to')} :</strong> {to}
                  <MailDetails mail={mail} />
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.details}>
            <Typography variant="body2" className={classes.detailText}>
              {dayjs(mail.date).format('DD MMM YYYY [à] HH:mm')}
            </Typography>
            <AccountLabel account_id={mail.account_id} className={classes.detailText} style={{ flex: 1 }} />
            <div className={classes.tags}>
              <EditableTags tags={mail.tags} element="MAIL" element_id={mail.id} />
            </div>
          </div>
        </div>
      )}
      {status === 'LOADING' && <LinearProgress />}
      {status === 'NOT_FOUND' && (
        <div style={{ paddingTop: 50 }}>
          <ErrorMessage variant="WARNING" title={t('mail.notFoundTitle')} subtitle={t('mail.notFoundSubtitle')} />
        </div>
      )}
      {status === 'ERROR' && (
        <div style={{ paddingTop: 50 }}>
          <ErrorMessage title={t('error.unexpected')} subtitle={errorMessage} />
        </div>
      )}
      <div ref={bodyRef} className={classes.body}></div>
      {!!attachments.length && (
        <div className={classes.attachments}>
          {attachments.map((attachment) => (
            <Attachment key={attachment.id} attachment={attachment} loaded={true} />
          ))}
        </div>
      )}
    </div>
  );
}
