import { createProjector } from '../../../Common';
import { EncryptedCredentialsRepo } from '../projections/EncryptedCredentialsRepo';
import { createEncryptedCredentials } from '../projections/ViewCredentials';

/**
 * Handler/Projector.
 */
export const encryptedCredentialsProjector = createProjector('encryptedCredentialsProjector', {
  ACCOUNT_DELETED: ({ aggregateId }, repo: EncryptedCredentialsRepo) => {
    repo.remove(aggregateId);
  },
  ACCOUNT_CREDENTIALS_ADDED: (
    { aggregateId, encryptedCredentials },
    repo: EncryptedCredentialsRepo
  ) => {
    repo.add(createEncryptedCredentials(aggregateId, encryptedCredentials));
  },
  ACCOUNT_CREDENTIALS_UPDATED: (
    { aggregateId, encryptedCredentials },
    repo: EncryptedCredentialsRepo
  ) => {
    repo.add(createEncryptedCredentials(aggregateId, encryptedCredentials));
  },
});
