import { getAllTags, mockUuid, randomEnum, TagColor, useAppDispatch, ViewTag } from '@focus-front/core';
import { IonIcon } from '@ionic/react';
import {
  Typography,
  Box,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
  IconButton,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Add, Close, Create, Done, UnfoldMore } from '@material-ui/icons';
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { MenuItem } from 'electron';
import { add } from 'ionicons/icons';
import { title } from 'process';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '../Tag/Tag';
import TagCreationDialog from '../Tag/TagCreationDialog';
import TagPicker from '../Tag/TagPicker';
import { BackButton, NextButton } from './NavButton';
import { WizardContext } from './WizardContext';
import tagsTutorial1 from '../../assets/wizard_tags_tutorial_1.png';
import tagsTutorial2 from '../../assets/wizard_tags_tutorial_2.png';
import cn from 'classnames';

type TagOption = ViewTag & { inputValue?: string };

const filter = createFilterOptions<TagOption>();

const useClasses = makeStyles((theme) => ({
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 14,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    backgroundColor: '#f6f8fa',
  },
}));

export default function TagsConfiguration() {
  const { goBack, goNext, forms, setForms } = useContext(WizardContext);
  const [tags, setTags] = useState<TagOption[]>([]);
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
  const [dialogValue, setDialogValue] = useState({
    label: '',
    color: randomEnum(TagColor),
  });

  const onTagCreate = (tag: ViewTag) => {
    setShowNewDialog(false);
    setTags([...tags, tag]);
    const newValue = [...(forms.TAGS_CONFIG?.tags || []), tag];
    setForms({ ...forms, TAGS_CONFIG: { tags: newValue } });
  };

  const onDialogClose = () => {
    setShowNewDialog(false);
    setDialogValue({
      label: '',
      color: randomEnum(TagColor),
    });
  };

  useEffect(() => {
    (async () => {
      const { result, error } = await dispatch(getAllTags());
      setTags(result);
    })();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <BackButton onClick={goBack} />
      <Typography variant="h2">{t('wizard.tagsConfig.title')}</Typography>
      <Typography variant="subtitle2">{t('wizard.tagsConfig.subtitle')}</Typography>
      <Box mt={3} mb={8}>
        <Typography variant="body1">{t('wizard.tagsConfig.label')}</Typography>
        <Autocomplete
          options={tags}
          multiple
          autoHighlight
          classes={{
            popper: classes.popper,
          }}
          value={forms.TAGS_CONFIG?.tags || []}
          onChange={(event: SyntheticEvent, newValue: TagOption[], reason) => {
            const has_created = newValue.find((val) => {
              if (typeof val !== 'string') return val.id === 'CREATE';
              else return false;
            });
            if (has_created) {
              setDialogValue({
                ...dialogValue,
                label: has_created.inputValue,
              });
              setShowNewDialog(true);
            } else setForms({ ...forms, TAGS_CONFIG: { tags: newValue } });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            filtered.push({
              id: mockUuid('CREATE'),
              label: params.inputValue ? t('tag.picker.createX', { input: params.inputValue }) : t('tag.picker.create'),
              inputValue: params.inputValue,
              color: TagColor.BLUE,
              last_assign_date: null,
            });

            return filtered;
          }}
          renderTags={(value) => value.map((tag, index) => <Tag tag={tag} key={index} />)}
          renderInput={(params) => (
            <TextField {...params} size="medium" variant="outlined" placeholder={t('wizard.tagsConfig.placeholder')} fullWidth />
          )}
          getOptionLabel={(option) => option.label}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              {option.id === 'CREATE' ? (
                <Add className={classes.iconSelected} />
              ) : (
                <Done className={classes.iconSelected} style={{ visibility: selected ? 'visible' : 'hidden' }} />
              )}

              <span
                className={classes.color}
                style={{ backgroundColor: option.color, visibility: option.id === 'CREATE' ? 'hidden' : 'visible' }}
              />
              <div className={classes.text} style={{ fontWeight: option.id === 'CREATE' ? 700 : 'inherit' }}>
                {option.label}
              </div>
              <Close className={classes.close} style={{ visibility: selected ? 'visible' : 'hidden' }} />
            </React.Fragment>
          )}
          getOptionSelected={(option, value) => option.id === value.id}
        />
        <TagCreationDialog open={showNewDialog} onClose={onDialogClose} defaultValue={dialogValue} onCreate={onTagCreate} />
      </Box>
      <TagsConfigurationCarrousel />
      <NextButton onClick={goNext} />
    </>
  );
}

function TagsConfigurationCarrousel() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setTimeout(
      () => {
        if (!show) setIndex(index === 2 - 1 ? 0 : index + 1);

        setShow(!show);
      },
      show ? 2000 : 500
    );
  }, [show]);
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={3} mb={5} position="relative">
      <img src={tagsTutorial1} width={629} style={{ alignItems: 'flex-end', marginLeft: 'auto', marginRight: -16 }} />

      <img
        src={tagsTutorial2}
        width={629}
        style={{ right: -16, top: 0, position: 'absolute', transition: 'all .2s ease', opacity: index }}
      />
    </Box>
  );
}
