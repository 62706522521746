import { Idb } from '../../../Common/infra/services/idb';
import { Uuid } from '../../../Common';
import { MailCache } from '../../domain';

/**
 * Repository handling Mails' Cache persistance in Indexed DB
 */
export class IdbMailCacheRepo {
  /**
   * Remove all mail cache
   */
  async clear(): Promise<void> {
    return Idb.clear('mailCache');
  }

  /**
   * Remove a mail's cached data
   */
  async remove(id: Uuid): Promise<void> {
    return Idb.delete('mailCache', id);
  }

  /**
   * Remove cached data of multiple mails
   */
  async removeMany(ids: Uuid[]): Promise<void> {
    const tx = Idb.transaction('mailCache', 'readwrite', { durability: 'relaxed' });
    for (let i = 0, length = ids.length; i < length; ++i) {
      tx.store.delete(ids[i]);
    }
    return tx.done;
  }

  /**
   * Remove cached data of all mails of a specific account
   */
  async removeByAccount(account_id: Uuid): Promise<void> {
    let cursor = await Idb.transaction('mailCache', 'readwrite', { durability: 'relaxed' })
      .store.index('by-account')
      .openCursor(IDBKeyRange.only(account_id), 'next');

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
  }

  /**
   * Add cache for a mail
   */
  async add(data: MailCache): Promise<void> {
    await Idb.put('mailCache', data);
  }

  /**
   * Add cache for multiple mails
   */
  async addMany(data: MailCache[]): Promise<void> {
    const tx = Idb.transaction('mailCache', 'readwrite', { durability: 'relaxed' });
    for (let i = 0, length = data.length; i < length; ++i) {
      tx.store.add(data[i]);
    }
    return tx.done;
  }

  /**
   * Get metadata of a mail by its id
   */
  async get(id: Uuid): Promise<MailCache | null> {
    const cache = await Idb.get('mailCache', id);
    return cache || null;
  }
}
