import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { Button, Fade, makeStyles, Typography } from '@material-ui/core';

import bg from '../../assets/login_bg.svg';
import logo from '../../assets/logo.svg';
import LogInForm from './LogInForm';
import { useSelector } from 'react-redux';
import { sessionSelector } from '@focus-front/core';

const useClasses = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  left: {
    flex: 1,
    height: '100%',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '15vh',
    paddingBottom: '15vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    transition: 'all .5s ease',
  },
  right: {
    width: '68%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'all .5s ease',
  },
  rightDone: {
    width: 0,
  },
  leftDone: {
    opacity: 0,
  },
  title: {
    marginBottom: 20,
    fontSize: 28,
    maxWidth: 300,
  },
  body: {
    fontSize: '1.125rem',
    marginBottom: 16,
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(244,245,247,1) 100%)',
    position: 'absolute',
    top: '80%',
    left: -30,
    right: -30,
    bottom: 0,
  },
  shadowHide: {
    background: 'rgba(244,245,247,1)',
    position: 'absolute',
    top: '100%',
    left: -30,
    right: -30,
    bottom: -80,
  },
  imgContainer: {
    position: 'relative',
    maxWidth: '80%',
  },
  hiddenImg: {
    opacity: 0,
    transform: 'translateY(50px)',
  },
  transition: {
    transition: 'all .3s ease',
  },
  hidden: {
    opacity: 0,
  },
  bg: {
    transform: 'translateX(-80%) translateY(100%)',
    transition: 'all .5s ease',
    height: '180%',
    zIndex: -1,
  },
  bgMounted: {
    transform: 'translateX(0) translateY(0)',
  },
}));

export default function LogInPage() {
  const c = useClasses();
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const session = useSelector(sessionSelector);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    });
  }, []);

  useEffect(() => {
    if (session)
      setTimeout(() => {
        history.push('/');
      }, 500);
  }, [session]);

  return (
    <div className={c.container}>
      <Fade in={mounted}>
        <div className={c.left}>
          <div>
            <img src={logo} alt="Logo" width="173px" />
            <Typography variant="body2" className={c.body}>
              {t('slogan')}
            </Typography>
          </div>

          <div>
            <Typography variant="h2" className={c.title}>
              {t('login.title')}
            </Typography>

            <LogInForm
              onLogin={() => {
                setMounted(false);
              }}
            />
          </div>

          <Button component={Link} to="/signup" variant="text" color="secondary">
            {t('login.signUp')}
          </Button>
        </div>
      </Fade>
      <div className={c.right}>
        <img className={cn(mounted && c.bgMounted, c.bg)} src={bg} alt="Unipile bands" />
      </div>
    </div>
  );
}
