import { Select } from '../../../Common/utils/utilityTypes';
import { Uuid } from '../../../Common';
import { isObject } from '../../../Common/utils';
import { Base64 } from '../../../Common/utils/Base64';
import { DeviceType } from '../../../_container/interfaces/Environment';

export type EncryptedCredentials = Readonly<{
  id: Uuid /** Aggregate id. */;
  value: Base64;
  // status: 'VALID' | 'INVALID'; // just remove from projection and rollback when/if necessary
}>;

export type NewEncryptedCredentialsDTO = Omit<Select<EncryptedCredentials, 'value'>, 'id'>;

export interface OAuthCredentials {
  at: string;
  rt: string;
  // expiry_date? : number;
}

export interface GoogleCredentials {
  type: 'GOOGLE';
  mail?: {
    imap_pwd: string;
    smtp_pwd: string;
  };
}

export interface ICloudCredentials {
  type: 'ICLOUD';
  mail?: {
    imap_pwd: string;
    smtp_pwd: string;
  };
}

/**
 * @note It looks like OAuthCredentials per DeviceType are nested in a 'devices'
 *       property to workaround mixing interfaces, mapped types and extra properties
 *       in typescript.
 *
 *       Should you want to avoid that extra 'devices' level, consider defining :
 *
 *         type OAuthCredentialsPerDevice = {
 *             [key in DeviceType]?: OAuthCredentials;
 *         };
 *
 *       then you can extend it :
 *
 *         export interface GoogleCalendarCredentials extends OAuthCredentialsPerDevice {
 *           type: 'GOOGLE_CALENDAR';
 *         }
 *
 */
export interface GoogleCalendarCredentials {
  type: 'GOOGLE_CALENDAR';
  devices: { [key in DeviceType]?: OAuthCredentials };
}

/**
 * Credentials of an Outlook account are not reusable accross device types,
 * so we must store credentials for each of them
 */
export interface OutlookCredentials {
  type: 'OUTLOOK';
  devices: { [key in DeviceType]?: OAuthCredentials };
}

export interface MailCredentials {
  type: 'MAIL';
  imap_pwd: string;
  smtp_pwd: string;
}

export interface LinkedInCredentials {
  type: 'LINKEDIN';
  cookie: string;
}

export interface MessengerCredentials {
  type: 'MESSENGER';
  // Stringified array of cookies
  cookies: string;
}

export interface TikTokCredentials {
  type: 'TIKTOK';
  // Stringified array of cookies
  cookies: string;
}

export interface TwitterCredentials {
  type: 'TWITTER';
  // Stringified array of cookies
  cookies: string;
}

export interface InstagramCredentials {
  type: 'INSTAGRAM';
  // Stringified array of cookies
  cookies: string;
}

export interface WhatsAppCredentials {
  type: 'WHATSAPP';
  session: string;
}

export type DiscriminatedCredentials =
  | MailCredentials
  | GoogleCredentials
  | GoogleCalendarCredentials
  | OutlookCredentials
  | LinkedInCredentials
  | MessengerCredentials
  | TikTokCredentials
  | InstagramCredentials
  | TwitterCredentials
  | WhatsAppCredentials;

export type ViewCredentials = Readonly<
  {
    id: Uuid /** Aggregate id. */;
  } & DiscriminatedCredentials
>;

export type NewCredentialsDTO = Omit<ViewCredentials, 'id'> & DiscriminatedCredentials;

/**
 *
 */
export function createEncryptedCredentials(aggregateId: Uuid, values: NewEncryptedCredentialsDTO): EncryptedCredentials {
  return {
    ...values,
    id: aggregateId,
  };
}

/**
 * @todo Consider this may not be necessary at all.
 */
export function createCredentials(aggregateId: Uuid, values: NewCredentialsDTO): ViewCredentials {
  return { ...values, id: aggregateId };
  //   if (credentials.access_token && credentials.refresh_token) {
  //     return {
  //       access_token: credentials.access_token,
  //       refresh_token: credentials.refresh_token,
  //     };
  //   }
  //   if (credentials.imap_pwd && credentials.smtp_pwd) {
  //     return {
  //       imap_pwd: credentials.imap_pwd,
  //       smtp_pwd: credentials.smtp_pwd,
  //     };
  //   }
  //   throw new Error('Wrong key/pair values to instantiate Credentials');
}

/**
 * @todo Tailor to match actual constraints. I did this based only on the type
 *       written above.
 */
export function isGoogleCredentials(u: unknown): u is GoogleCredentials {
  return isObject(u) && u.type === 'GOOGLE';
}

export function isICloudCredentials(u: unknown): u is ICloudCredentials {
  return isObject(u) && u.type === 'ICLOUD';
}

export function isGoogleCalendarCredentials(u: unknown): u is GoogleCalendarCredentials {
  // return isObject(u) && u.type === 'GOOGLE_CALENDAR' && typeof u.at === 'string' && typeof u.rt === 'string';
  return isObject(u) && u.type === 'GOOGLE_CALENDAR' && isObject(u.devices);
}

export function isOutlookCredentials(u: unknown): u is OutlookCredentials {
  return isObject(u) && u.type === 'OUTLOOK';
}

export function isOauthCredentials(u: unknown): u is OAuthCredentials {
  return isObject(u) && typeof u.at === 'string' && typeof u.rt === 'string';
}

/**
 * @todo Tailor to match actual constraints. I did this based only on the type
 *       written above.
 */
export function isMailCredentials(u: unknown): u is MailCredentials {
  return isObject(u) && u.type === 'MAIL' && typeof u.imap_pwd === 'string' && typeof u.smtp_pwd === 'string';
}

export function isLinkedInCredentials(u: unknown): u is LinkedInCredentials {
  return isObject(u) && u.type === 'LINKEDIN' && typeof u.cookie === 'string';
}

export function isMessengerCredentials(u: unknown): u is MessengerCredentials {
  return isObject(u) && u.type === 'MESSENGER' && typeof u.cookies === 'string';
}

export function isTikTokCredentials(u: unknown): u is TikTokCredentials {
  return isObject(u) && u.type === 'TIKTOK' && typeof u.cookies === 'string';
}

export function isTwitterCredentials(u: unknown): u is TwitterCredentials {
  return isObject(u) && u.type === 'TWITTER' && typeof u.cookies === 'string';
}

export function isInstagramCredentials(u: unknown): u is InstagramCredentials {
  return isObject(u) && u.type === 'INSTAGRAM' && typeof u.cookies === 'string';
}

export function isWhatsAppCredentials(u: unknown): u is WhatsAppCredentials {
  return isObject(u) && u.type === 'WHATSAPP' && typeof u.session === 'string';
}
