import { getFileHash, readFilePromisified } from '../../utils'; 
import { LocalFile, LocalFileStore } from '../../domain/LocalFile';
import { FileManagerService } from './FileManagerService';

export class BasicFileService implements FileManagerService {
  constructor(private readonly fileStore: LocalFileStore) {}

  async get(id: string, withData = false) {
    return this.fileStore.get(id);
  }

  async upload(file: File) {
    /** @todo Consider using an opaque type to validate file name format. */
    const extension = file.name.split('.').pop()!.toUpperCase();
    const content = await readFilePromisified(file);
    const fileHash = await getFileHash(file);
    const localFile: LocalFile = {
      id: fileHash,
      name: file.name,
      extension,
      size: file.size,
      mime: file.type,
      data: String(content).replace(/^data:.*;base64,/, ''),
    };
    await this.add(localFile);
    return localFile;
  }

  async add(file: LocalFile) {
    this.fileStore.add(file);
  }

  async delete(id: string) {
    this.fileStore.remove(id);
  }
}
