import { AccountType, ViewCalendarEvent } from '@focus-front/core';
import React, { HTMLAttributes } from 'react';
import {
  AndroidCallAccountIcon,
  AndroidMessagesAccountIcon,
  CalendarEventDefaultIcon,
  GmailAccountIcon,
  GoogleCalendarAccountIcon,
  LinkedInAccountIcon,
  MailAccountIcon,
  MailDraftIcon,
  MessengerAccountIcon,
  TwitterAccountIcon,
  InstagramAccountIcon,
  TikTokAccountIcon,
  MicrosoftAccountIcon,
  MissedEventIcon,
  OutlookAccountIcon,
  WhatsAppAccountIcon,
} from './UnipileIcon';

interface Props {
  event: Partial<Pick<ViewCalendarEvent, 'metadata' | 'status'>>;
  size: number;
}

function CalendarEventIconComp({ event, size, className }: Props & HTMLAttributes<HTMLDivElement>) {
  function getIcon() {
    if (event.status === 'MISSED') return <MissedEventIcon size={size} />;

    if (event.metadata?.attached_entity?.type) {
      switch (event.metadata.attached_entity.type) {
        case 'CALL': {
          return <AndroidCallAccountIcon size={size} />;
        }
        case 'IM':
        case 'IM_THREAD': {
          switch (event.metadata.attached_entity.account_type) {
            case 'LINKEDIN': {
              return <LinkedInAccountIcon size={size} />;
            }
            case 'MESSENGER': {
              return <MessengerAccountIcon size={size} />;
            }
            case 'TIKTOK': {
              return <TikTokAccountIcon size={size} />;
            }
            case 'INSTAGRAM': {
              return <InstagramAccountIcon size={size} />;
            }
            case 'TWITTER': {
              return <TwitterAccountIcon size={size} />;
            }
            case 'MOBILE': {
              return <AndroidMessagesAccountIcon size={size} />;
            }
            case 'WHATSAPP': {
              return <WhatsAppAccountIcon size={size} />;
            }
          }
          break;
        }
        case 'MAIL': {
          switch (event.metadata.attached_entity.account_type) {
            case 'GOOGLE': {
              return <GmailAccountIcon size={size} />;
            }
            case 'MAIL': {
              return <MailAccountIcon size={size} />;
            }
            case 'OUTLOOK': {
              return <OutlookAccountIcon size={size} />;
            }
          }
          break;
        }
        case 'EVENT': {
          switch (event.metadata.attached_entity.account_type) {
            case 'GOOGLE_CALENDAR': {
              return <GoogleCalendarAccountIcon size={size} />;
            }
          }
          break;
        }
        case 'MAIL_DRAFT': {
          return <MailDraftIcon size={size} />;
        }
      }
    }

    return <CalendarEventDefaultIcon size={size} />;
  }

  const icon = getIcon();

  if (icon)
    return (
      <div className={className} style={{ width: size, height: size, filter: event.status === 'DONE' && 'grayscale(1)' }}>
        {icon}
      </div>
    );

  return null;
}

export const CalendarEventIcon = React.memo(CalendarEventIconComp);
