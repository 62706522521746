import { AggregateRepo, DomainEventStore } from '../../../Common';
import { Tag } from '../../domain';

/**
 * Repository.
 */
export class TagRepo extends AggregateRepo<Tag> {
  constructor(eventStore: DomainEventStore) {
    super(Tag, eventStore);
  }
}
