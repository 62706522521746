import { TagColor } from '../Tag';
import { Uuid, UTCDateTimeMs } from '../../../Common';
import { Select } from '../../../Common/utils';

export type ViewTag = Readonly<{
  // Aggregate ID
  id: Uuid;
  // Tag label
  label: string;
  // Hex tag color
  color: TagColor;

  last_assign_date: UTCDateTimeMs | null;
}>;

export type NewTagDTO = Select<Omit<ViewTag, 'id'>, 'label' | 'color'>;

export type UpdateTagDTO = Partial<Omit<ViewTag, 'id'>>;

export function createViewTag(aggregateId: Uuid, values: NewTagDTO): ViewTag {
  if (!values.label || !values.color) throw new Error('Missing values to instantiate a Tag');

  return {
    ...values,
    last_assign_date: values.last_assign_date || null,
    id: aggregateId,
  };
}
