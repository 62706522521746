import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  GoogleCalendarAttendee,
  GoogleCalendarEvent,
  GoogleCalendarAttachment,
  GoogleCalendarReminders,
  CalendarEventDate,
} from '../../domain';
import { GoogleCalendarEventDTO, DeleteGoogleCalendarEventDTO } from '../../domain/types/CalendarEventDTO';
import { isObject, Strip, Unreliable, hasPropOfType, hasOptionalPropOfType } from '../../../Common/utils';
import { RRuleString } from '../../../Common/domain/RRule';
import { HttpUrl, isHttpUrl } from '../../../Common/utils/Url';
import {
  CancelGoogleCalendarEventExceptionDTO,
  DeleteGoogleCalendarEventExceptionDTO,
  GoogleCalendarEventExceptionDTO,
} from '../../domain/types/CalendarEventExceptionDTO';
import { UTCDateTimeMs, UnixTimeMs, isTzid, Tzid } from '../../../Common';
import { DeleteGoogleCalendarDTO, GoogleCalendarDTO } from '../../domain/types/CalendarDTO';

dayjs.extend(utc);

/**
 * @todo Consider introducing a validation library !
 *
 *       Evaluate https://ajv.js.org/
 */

/**
 *
 */
export interface GcalCalendarListResponse {
  // kind: 'calendar#calendarList';
  etag: string; // ETag of the collection.
  nextSyncToken?: string;
  nextPageToken?: string;
  items: unknown[];
}

/**
 *
 */
export function isGcalCalendarListResponse(u: unknown): u is GcalCalendarListResponse {
  return (
    isObject(u) &&
    Array.isArray(u.items) &&
    // u.kind === 'calendar#calendarList' &&
    typeof u.etag === 'string' &&
    ('nextSyncToken' in u ? typeof u.nextSyncToken === 'string' : true) &&
    ('nextPageToken' in u ? typeof u.nextPageToken === 'string' : true)
  );
}

/**
 * @see https://github.com/microsoft/TypeScript/issues/21732
 *      https://github.com/microsoft/TypeScript/issues/38801
 *      For why it's not working as expected with TS.
 */
export function parseGcalCalendarListResponse(u: unknown): GcalCalendarListResponse | null {
  if (
    isObject(u) &&
    hasPropOfType(u, 'etag', 'string') &&
    hasPropOfType(u, 'items', 'array') &&
    hasOptionalPropOfType(u, 'nextSynctoken', 'string') &&
    hasOptionalPropOfType(u, 'nextPageToken', 'string')
  ) {
    return u;
  }
  return null;
}

/**
 *
 */
export interface GcalCalendarEventListResponse {
  // kind: 'calendar#events';
  //   summary: string;
  //   description: string;
  //   updated: UTCDateTimeMs;
  //   timeZone: string;
  //   accessRole: GcalCalendarAccessRole;
  //   defaultReminders: unknown[];
  etag: string; // ETag of the collection, NOT of the associated calendar.
  nextSyncToken?: string;
  nextPageToken?: string;
  timeZone?: Tzid;
  items: unknown[];
}
/**
 *
 */
export interface GcalCalendarListResult {
  calendars: Strip<GoogleCalendarDTO, 'account_id' | 'sync_token'>[];
  deleted: DeleteGoogleCalendarDTO[];
  // deleted: Strip<GoogleCalendarDTO, 'account_id'>[];
  sync_token: string | null;
  page_token: string | null;
}

/**
 *
 */
export interface GcalCalendarEventListResult {
  events: Strip<GoogleCalendarEventDTO, 'calendar_id' | 'account_id'>[];
  events_deleted: (DeleteGoogleCalendarEventDTO | CancelGoogleCalendarEventExceptionDTO)[];
  exceptions: GoogleCalendarEventExceptionDTO[];
  exceptions_deleted: DeleteGoogleCalendarEventExceptionDTO[];
  sync_token: string | null;
  page_token: string | null;
  // single_events: false;
}

/**
 *
 */
export function isGcalCalendarEventListResponse(u: unknown): u is GcalCalendarEventListResponse {
  return (
    isObject(u) &&
    Array.isArray(u.items) &&
    // u.kind === 'calendar#events' &&
    typeof u.etag === 'string' &&
    ('nextSyncToken' in u ? typeof u.nextSyncToken === 'string' : true) &&
    ('nextPageToken' in u ? typeof u.nextPageToken === 'string' : true) &&
    ('timeZone' in u ? typeof u.timeZone === 'string' && isTzid(u.timeZone) : true)
  );
}

/**
 *
 */
export type GcalCalendarAccessRole = 'owner' | 'writer' | 'reader' | 'freeBusyReader';

/**
 *
 */
export function isGcalCalendarAccessRole(u: unknown): u is GcalCalendarAccessRole {
  switch (u) {
    case 'owner':
    case 'writer':
    case 'reader':
    case 'freeBusyReader':
      return true;
    default:
      return false;
  }
}

/**
 * @todo Handle Calendar vs CalendarList.
 */
export function parseGcalCalendarItems(items: unknown[]): Pick<GcalCalendarListResult, 'calendars' | 'deleted'> {
  const calendars: GcalCalendarListResult['calendars'] = [];
  const deleted: GcalCalendarListResult['deleted'] = [];

  items.forEach((u) => {
    if (isObject(u) && typeof u.id === 'string' && typeof u.etag === 'string') {
      /** Short form : calendar deleted or unsubscribed. */
      if (u.deleted === true) {
        deleted.push({
          type: 'GOOGLE',
          external_id: u.id,
          etag: u.etag,
        });
        return;
      }

      /**
       * Extended form : new calendar or hidden toggled
       * or user-specific property changed ( e.g. color, summaryOverride ).
       */
      calendars.push({
        type: 'GOOGLE',
        external_id: u.id,
        etag: u.etag,
        /** @todo Track both summary and summaryOverride and let the UI decide. */
        name: typeof u.summaryOverride === 'string' ? u.summaryOverride : typeof u.summary === 'string' ? u.summary : '',
        ...(typeof u.description === 'string' && { description: u.description }),
        background_color: typeof u.backgroundColor === 'string' ? u.backgroundColor : '#00BFA2',
        ...(typeof u.foregroundColor === 'string' && { foreground_color: u.foregroundColor }),
        is_primary: typeof u.primary === 'boolean' ? u.primary : false,
        access_role: isGcalCalendarAccessRole(u.accessRole) ? u.accessRole : 'reader',
        // sync_token: null, /**  NO ! We must NOT overwrite sync_token. */
        default_tzid: typeof u.timeZone === 'string' && isTzid(u.timeZone) ? u.timeZone : ('Etc/UTC' as Tzid),
        // sync_activated: true,
        /** Reject possible garbage truthy value, use a strict comparison. */
        ...(u.deleted === true && { deleted: true }),
      });
    }
  });

  return {
    calendars,
    deleted,
  };
}

/**
 * "If the event doesn't use the default reminders, this lists the reminders specific
 *  to the event, or, if not set, indicates that no reminders are set for this event.
 *  The maximum number of override reminders is 5."
 */
export interface GcalEventReminders {
  useDefault: boolean;
  overrides?: {
    method: GcalEventReminderMethod;
    minutes: number;
  }[];
}

/**
 *
 */
export type GcalEventReminderMethod = 'email' | 'popup';

/**
 *
 */
export function isGcalEventReminderMethod(u: unknown): u is GcalEventReminderMethod {
  switch (u) {
    case 'email':
    case 'popup':
      return true;
    default:
      return false;
  }
}

/**
 * @note Be careful with Array.isArray, it narrows to any[] !!!
 */
export function parseGcalEventReminders(u: unknown): GoogleCalendarReminders | null {
  if (!isObject(u) || typeof u.useDefault !== 'boolean') {
    return null;
  }
  if (!('overrides' in u)) {
    return { use_default: u.useDefault };
  }
  if (!Array.isArray(u.overrides)) {
    return null;
  }

  const overrides: NonNullable<GoogleCalendarReminders['overrides']> = [];
  for (let i = 0, length = u.overrides.length; i < length; ++i) {
    if (isGcalEventReminderMethod(u.overrides[i]?.method) && typeof u.overrides[i]?.minutes === 'number') {
      overrides.push(u.overrides[i]);
    }
  }
  return overrides.length ? { use_default: u.useDefault, overrides } : null; //{use_default : true};
}

/**
 *
 */
export interface GcalEventAttachment {
  fileId: string;
  fileUrl: HttpUrl;
  title: string;
  mimeType: string;
  iconLink: HttpUrl;
}

/**
 *
 */
export function isGcalEventAttachment(u: unknown): u is GcalEventAttachment {
  return (
    isObject(u) &&
    typeof u.fileId === 'string' &&
    typeof u.fileUrl === 'string' &&
    isHttpUrl(u.fileUrl) &&
    typeof u.title === 'string' &&
    typeof u.mimeType === 'string' &&
    typeof u.iconLink === 'string' &&
    isHttpUrl(u.iconLink)
  );
}

/**
 *
 */
export function parseGcalEventAttachments(u: unknown): GoogleCalendarAttachment[] | null {
  if (!Array.isArray(u)) {
    return null;
  }

  const attachments: GoogleCalendarAttachment[] = [];
  for (let i = 0, length = u.length; i < length; ++i) {
    const a: unknown = u[i];
    if (isGcalEventAttachment(a)) {
      attachments.push({
        file_url: a.fileUrl,
        title: a.title,
        mime_type: a.mimeType,
        icon_link: a.iconLink,
        file_id: a.fileId,
      });
    }
  }
  return attachments;
}

/**
 *
 */
export type GcalEventAttendeeResponseStatus = 'needsAction' | 'declined' | 'tentative' | 'accepted';

/**
 *
 */
export function isGcalEventAttendeeResponseStatus(u: unknown): u is GcalEventAttendeeResponseStatus {
  switch (u) {
    case 'needsAction':
    case 'tentative':
    case 'declined':
    case 'accepted':
      return true;
    default:
      return false;
  }
}

/**
 *
 */
export function parseGcalEventAttendees(u: unknown): GoogleCalendarAttendee[] | null {
  if (Array.isArray(u)) {
    const parsed: GoogleCalendarAttendee[] = [];
    for (let i = 0, length = u.length; i < length; ++i) {
      const a: unknown = u[i];
      if (isObject(a) && typeof a.email === 'string' && isGcalEventAttendeeResponseStatus(a.responseStatus)) {
        parsed.push({
          email: a.email,
          response_status: a.responseStatus,
          ...(typeof a.id === 'string' && { id: a.id }),
          ...(typeof a.displayName === 'string' && { display_name: a.displayName }),
          ...(typeof a.organizer === 'boolean' && { organizer: a.organizer }),
          ...(typeof a.self === 'boolean' && { self: a.self }),
          ...(typeof a.resource === 'boolean' && { resource: a.resource }),
          ...(typeof a.optional === 'boolean' && { optional: a.optional }),
          ...(typeof a.comment === 'string' && { comment: a.comment }),
          ...(typeof a.additionalGuests === 'number' && { additional_guests: a.additionalGuests }),
        });
      }
    }
    /** Return null if parsed is empty because nothing could be parsed. */
    return parsed.length || !u.length ? parsed : null;
  }
  return null;
}

/**
 *
 */
export type GcalEventStatus = 'confirmed' | 'tentative' | 'cancelled';

/**
 *
 */
export function isGcalEventStatus(u: unknown): u is GcalEventStatus {
  switch (u) {
    case 'confirmed':
    case 'tentative':
    case 'cancelled':
      return true;
    default:
      return false;
  }
}

/**
 * @todo Validate rrules !
 */
export function parseGcalRecurrence(u: unknown): RRuleString[] | null {
  if (Array.isArray(u)) {
    const parsed: RRuleString[] = [];
    for (let i = 0, length = u.length; i < length; ++i) {
      const r: unknown = u[i];
      if (typeof u[i] === 'string') {
        parsed.push(r as RRuleString);
      }
    }
    /** Return null if parsed is empty because nothing could be parsed. */
    return parsed.length || !u.length ? parsed : null;
  }
  return null;
}

/**
 * @note Google doesn't play by the same rules they require for an user
 *       specifying its own event id.
 */
export function isGcalEventId(u: unknown): u is NonNullable<GoogleCalendarEvent['external_id']> {
  //   return typeof u === 'string' && isGoogleCalendarEventId(u.split('_')[0]);
  return typeof u === 'string';
}

/**
 * @note For non-allDay events, Google Calendar API documentation says :
 *
 *       "dateTime :
 *        The time, as a combined date-time value ( formatted according to RFC3339 ).
 *        A time zone offset is required unless a time zone is explicitly specified
 *        in timeZone."
 *
 *       In practice for an event returned by Google Calendar API, if dateTime
 *       is present on start/end, it always seems to include the timezone offset,
 *       even if timeZone is also present.
 *
 *       Maybe it means the restriction is relaxed when creating or updating event.
 *
 * @note There are no timeZone on allDay events, even for recurring events
 *       despite Google Calendar API documentation saying :
 *
 *         "For recurring events this field is required and specifies the time
 *          zone in which the recurrence is expanded."
 *
 * @note Be careful with daysjs :
 *
 *       dayjs(undefined).isValid() is true
 *
 *       Because dayjs(undefined) is equivalent to dayjs() and yields 'now'.
 *
 *       That means you cannot rely on something like :
 *
 *       dayjs(u.start?.dateTime).isValid() and expect it to not be valid if
 *       start or dateTime do not exist.
 *
 *       The optional chaining would short-circuit to undefined, and
 *       dayjs().isValid() is true.
 *
 * @see https://github.com/microsoft/TypeScript/issues/37700
 *      https://github.com/microsoft/TypeScript/issues/35799
 *
 *      For why it's painful to use unknown and optional chaining operator.
 *
 * @todo Consider if a default timezone, e.g. Etc/UTC make sense if timeZone,
 *       can't be parsed. Probably too risky for recurring events : it may yield
 *       erroneous instance ids around DST.
 */
type UnreliableDate = Unreliable<{
  date: unknown;
  dateTime: unknown;
  timeZone: unknown;
}>;

interface UnreliableDates {
  start: UnreliableDate;
  end: UnreliableDate;
}

export function parseGcalEventDates({ start, end }: UnreliableDates, defaultTzid: Tzid): CalendarEventDate | null {
  let all_day: boolean;
  let parsedStart: dayjs.Dayjs;
  let parsedEnd: dayjs.Dayjs;

  if ((all_day = !!(typeof start?.date === 'string' && typeof end?.date === 'string'))) {
    /** AllDay. */
    parsedStart = dayjs.utc(start.date);
    parsedEnd = dayjs.utc(end.date);

    if (!parsedStart.isValid() || !parsedEnd.isValid()) {
      return null;
    }

    return {
      start: parsedStart.toISOString() as UTCDateTimeMs,
      end: parsedEnd.toISOString() as UTCDateTimeMs,
      /** @note Quieting compiler not being as strict as core with all_day === true. */
      all_day: all_day as true,
      start_tzid: null,
      end_tzid: null,
    };
  } else if (
    typeof start?.dateTime === 'string' &&
    typeof end?.dateTime === 'string'
    // typeof start.timeZone === 'string' &&
    // isTzid(start.timeZone) &&
    // typeof end.timeZone === 'string' &&
    // isTzid(end.timeZone)
  ) {
    /** Non-allDay. */
    parsedStart = dayjs(start.dateTime);
    parsedEnd = dayjs(end.dateTime);

    if (!parsedStart.isValid() || !parsedEnd.isValid()) {
      return null;
    }
    return {
      start: parsedStart.toISOString() as UTCDateTimeMs,
      end: parsedEnd.toISOString() as UTCDateTimeMs,
      /** @note Quieting compiler not being as strict as core with all_day === false. */
      all_day: all_day as false,
      start_tzid:
        'timeZone' in start && typeof start.timeZone === 'string' && isTzid(start.timeZone) ? start.timeZone : defaultTzid,
      end_tzid: 'timeZone' in end && typeof end.timeZone === 'string' && isTzid(end.timeZone) ? end.timeZone : defaultTzid,
    };
  }
  return null;
}
// export function parseGcalEventDates({ start, end }: UnreliableDates): CalendarEventDate | null {
//   let all_day: boolean;
//   let parsedStart: dayjs.Dayjs;
//   let parsedEnd: dayjs.Dayjs;

//   if ((all_day = !!(typeof start?.date === 'string' && typeof end?.date === 'string'))) {
//     parsedStart = dayjs.utc(start.date);
//     parsedEnd = dayjs.utc(end.date);
//   } else if (typeof start?.dateTime === 'string' && typeof end?.dateTime === 'string') {
//     parsedStart = dayjs(start.dateTime);
//     parsedEnd = dayjs(end.dateTime);
//   } else {
//     return null;
//   }

//   if (!parsedStart.isValid()) {
//     return null;
//   }
//   if (!parsedEnd.isValid()) {
//     return null;
//   }
//   return {
//     start: parsedStart.toISOString() as UTCDateTimeMs,
//     end: parsedEnd.toISOString() as UTCDateTimeMs,
//     all_day,
//   };
// }

export function parseOriginalStart(u: UnreliableDate): UnixTimeMs | null {
  let parsed: dayjs.Dayjs;
  if (typeof u?.dateTime === 'string') {
    parsed = dayjs(u.dateTime);
  } else if (typeof u?.date === 'string') {
    parsed = dayjs.utc(u.date);
  } else {
    return null;
  }

  if (!parsed.isValid()) {
    return null;
  }
  return parsed.valueOf() as UnixTimeMs;
}

/**
 * @todo Consider that we are not getting updated properties for exceptions, but
 *       the whole event including unchanged properties :
 *          - See if this is a problem where there might be a difference between
 *            a property that is not written on an exception and default to the
 *            original event and a property that is written on the exception.
 *          - If that's a problem : where should we do the diff ?
 *
 * @see https://fettblog.eu/typescript-hasownproperty/
 *      For working around TS not narrowing Record<PropertyKey, unknown> down.
 *      Maybe too cumbersome to use here.
 */
export function parseGcalEventItems(
  items: unknown[],
  defaultTzid = 'Etc/UTC' as Tzid
): Pick<GcalCalendarEventListResult, 'events' | 'events_deleted' | 'exceptions' | 'exceptions_deleted'> {
  const events: GcalCalendarEventListResult['events'] = [];
  const events_deleted: GcalCalendarEventListResult['events_deleted'] = [];
  const exceptions: GcalCalendarEventListResult['exceptions'] = [];
  const exceptions_deleted: GcalCalendarEventListResult['exceptions_deleted'] = [];

  items.forEach((u) => {
    /** Bare minimum present ? else exit early. */
    if (!isObject(u) || !isGcalEventId(u.id) || !(typeof u.etag === 'string') || !isGcalEventStatus(u.status)) {
      return;
    }
    /** Short form : event deleted or exception cancelled or exception deleted. */
    if (u.status === 'cancelled') {
      /** Exception deleted. */
      if (typeof u.recurringEventId === 'string') {
        const original_start = parseOriginalStart(u.originalStartTime as UnreliableDate);
        /** Usable date ? Else exit early. */
        if (!original_start) {
          return;
        }
        exceptions_deleted.push({
          type: 'GOOGLE',
          external_id: u.id as GoogleCalendarEvent['external_id'],
          external_recurring_event_id: u.recurringEventId as NonNullable<GoogleCalendarEvent['external_recurring_event_id']>,
          original_start,
          etag: u.etag,
        });
        return;
      }

      /**
       * To be safe, if recurringEventId is not present but originalStartTime is,
       * we have no idea what this is and should probably abort.
       *
       * @todo Study again the different scenarios and forms and reconsider.
       */
      if (isObject(u.originalStartTime)) {
        return;
      }

      /** Event deleted or exception cancelled. */
      events_deleted.push({
        type: 'GOOGLE',
        external_id: u.id as GoogleCalendarEvent['external_id'],
        etag: u.etag,
      });
      return;
    }

    /** Extended form : event or exception. */
    const parsedDates = parseGcalEventDates(u as unknown as UnreliableDates, defaultTzid);
    /** Usable dates ? Else exit early. */
    if (!parsedDates) {
      return;
    }

    /**
     * If an exception has no attachments listed, it means there no attachments on the exception !
     * It doesn't mean that it should default to the original recurring event attachments.
     * But it doesn't say if the exception attachments are different from those on the original recurring
     * event.
     *
     * Here, if some attachments exists on the exception but we can't parse any, we let the exception
     * default to the original recurring event.
     *
     * Same idea with attendees.
     *
     * @todo Handle attachments patch/exceptions on recurring events.
     * @todo Figure out if we need to have a special case for exceptions that should default to the
     *       original recurring event value for attachments.
     */
    const attachments = 'attachments' in u ? parseGcalEventAttachments(u.attachments) : [];
    const attendees = 'attendees' in u ? parseGcalEventAttendees(u.attendees) : [];

    /** Instance/Exception. */
    if (typeof u.recurringEventId === 'string') {
      const original_start = parseOriginalStart(u.originalStartTime as UnreliableDate);
      /** Usable date ? Else exit early. */
      if (!original_start) {
        return;
      }
      exceptions.push({
        type: 'GOOGLE',
        ...parsedDates,
        ...(typeof u.summary === 'string' && { summary: u.summary }),
        ...(typeof u.description === 'string' && { description: u.description }),
        ...(typeof u.location === 'string' && { location: u.location }),
        external_id: u.id as NonNullable<GoogleCalendarEventExceptionDTO['external_id']>,
        external_recurring_event_id: u.recurringEventId as NonNullable<GoogleCalendarEvent['external_recurring_event_id']>,
        original_start,
        etag: u.etag,
        google_status: u.status,
        ...(typeof u.iCalUID === 'string' && { ical_uid: u.iCalUID }),
        ...(typeof (u.creator as any)?.email === 'string' && { creator: (u.creator as any).email }),
        ...(typeof (u.organizer as any)?.email === 'string' && { organizer: (u.organizer as any).email }),
        ...(typeof u.htmlLink === 'string' && isHttpUrl(u.htmlLink) && { html_link: u.htmlLink }),
        ...(typeof u.hangoutLink === 'string' && isHttpUrl(u.hangoutLink) && { hangout_link: u.hangoutLink }),
        reminders: parseGcalEventReminders(u.reminders) ?? { use_default: true },
        ...(attachments && { attachments }),
        ...(attendees && { attendees }),
        ...(typeof u.locked === 'boolean' && { locked: u.locked }),
      });
      return;
    }

    /** Single/Recurring Event. */
    /**
     * @note As it stands, no distinction is made between the absence of a recurrence property,
     *       which indicates a single event, and a failure to parse anything meaningful from
     *       the recurrence property.
     *       This means that a recurring event with a unusable recurrence property will
     *       be defaulted to a single event.
     */
    const recurrence = parseGcalRecurrence(u.recurrence);
    events.push({
      type: 'GOOGLE',
      ...parsedDates,
      ...(recurrence ? { kind: 'RECURRING', recurrence } : { kind: 'SINGLE' }),
      // status: 'PLANNED',
      summary: typeof u.summary === 'string' ? u.summary : '',
      ...(typeof u.description === 'string' && { description: u.description }),
      ...(typeof u.location === 'string' && { location: u.location }),
      external_id: u.id,
      etag: u.etag,
      google_status: u.status,
      ical_uid: typeof u.iCalUID === 'string' ? u.iCalUID : null,
      creator: typeof (u.creator as any)?.email === 'string' ? (u.creator as any).email : null,
      organizer: typeof (u.organizer as any)?.email === 'string' ? (u.organizer as any).email : null,
      html_link: typeof u.htmlLink === 'string' && isHttpUrl(u.htmlLink) ? u.htmlLink : null,
      ...(typeof u.hangoutLink === 'string' && isHttpUrl(u.hangoutLink) && { hangout_link: u.hangoutLink }),
      reminders: parseGcalEventReminders(u.reminders) ?? { use_default: true },
      attendees: attendees ?? [],
      attachments: attachments ?? [],
      ...(typeof u.locked === 'boolean' && { locked: u.locked }),
    });
  });

  return {
    events,
    events_deleted,
    exceptions,
    exceptions_deleted,
  };
}
