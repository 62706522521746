import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  refreshSync,
  setCalendarSync,
  syncModalOpened,
  syncModalAccountIdSelector,
  useAppDispatch,
  ViewCalendar,
} from '@focus-front/core';
import { DialogTitle } from '@focus-front/ui';
import { Button, Dialog } from '@material-ui/core';

import AccountSyncCalendarList from './AccountSyncCalendarList';

const SyncCalendarModal: FC = () => {
  const accountId = useSelector(syncModalAccountIdSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [calendarsEdited, setCalendarsEdited] = useState<boolean>(false);

  const handleCalendarSwitch = async (calendar: ViewCalendar) => {
    setCalendarsEdited(true);
    if (calendar.type === 'GOOGLE') {
      /** Toggle. */
      dispatch(setCalendarSync(calendar.id, !calendar.sync_activated));
    }
  };

  const handleClose = () => {
    dispatch(syncModalOpened(null));
    if (calendarsEdited) {
      dispatch(refreshSync());
      setCalendarsEdited(false);
    }
  };

  return (
    <Dialog open={accountId !== null} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => handleClose()}>{t('accountSettings.sources.syncButton')}</DialogTitle>
      <AccountSyncCalendarList handleCalendarSwitch={handleCalendarSwitch} accountId={accountId}/>
      <Button onClick={() => handleClose()} color="secondary">
        {t('action.done')}
      </Button>
    </Dialog>
  );
};

export default SyncCalendarModal;
