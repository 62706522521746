import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ActionContext, isEvent, Result, toUTCDate, toUTCDateTimeMs, UTCDate, Uuid } from '../../Common';
import { Taggable } from '../../Tag';
import { Sorter } from '../../Common/utils/normalizedStateShape';
import { capitalize } from '../../Common/utils/string';
import { addNotification, archiveNotification, planNotification } from '../../Notification';
import Container from '../../_container/interfaces/Container';
import { AppDispatch, AppGetState } from '../../_container/store';
import { FormMode } from '../app/AppCalendarUseCase';
import { CalendarVm } from '../state/CalendarVm';
import { CalendarEventInstanceId, NewCalendarEventDTO, PatchCalendarEventDTO, ViewCalendar, ViewCalendarEvent } from '../domain';
import { RecurringCalendarEventRoot, RecurringCalendarEventRootStrip } from '../domain/projections/RecurringCalendarEventRoot';
import { CalendarEventExceptionDTO } from '../domain/types/CalendarEventExceptionDTO';
import { sessionSelector } from '../../User';
import { availableCalendarIdsSelector, calendarsDefaultCalendarSelector, hiddenCalendarIdsSelector } from './calendar.selectors';

export const HIDDEN_CALENDARS_PREFIX = '__UNIPILE_HIDDEN_CALENDARS_';

export type ViewMode = 'DAY' | 'WEEK' | 'MONTH' | 'LIST';

/**
 * @todo Clean this up : AttachedEntityType is defined  in libs/ui/src/lib/useActionBar.tsx,
 *       importing caused a circular dependency problem.
 */
type AttachedEntityType = 'MAIL' | 'MAIL_DRAFT' | 'IM' | 'CALL' | 'EVENT' | 'IM_THREAD';

/**
 *
 */
export interface CalendarState {
  calendars: CalendarVm[];
  syncModalAccountId: Uuid | null;
  view: {
    title: string;
    date: UTCDate;
    start: string;
    end: string;
    mode: ViewMode;
    events: ViewCalendarEvent[];
    draft: Omit<NewCalendarEventDTO, 'calendar_id'> | null;
    hiddenCalendarIds: Uuid[];
  };
  /**
   * Events of the day, always loaded to run the work mode
   */
  workEvents: (ViewCalendarEvent & Taggable)[];
  form: {
    mode: FormMode;
    editedEventId: Uuid | CalendarEventInstanceId | null;
    eventDraft: NewCalendarEventDTO | null;
    recurringEventRoot: RecurringCalendarEventRootStrip | null;
    originNotificationId: Uuid | null;
  };
  // See https://material-ui.com/components/snackbars/#consecutive-snackbars
  successSnackbar: {
    message: string;
    key: number;
  }[];
}

/**
 *
 */
const initialState: CalendarState = {
  calendars: [],
  syncModalAccountId: null,
  view: {
    title: '',
    date: toUTCDate(dayjs()),
    start: '',
    end: '',
    mode: 'DAY',
    events: [],
    draft: null,
    hiddenCalendarIds: [],
  },
  workEvents: [],
  form: {
    mode: 'IDLE',
    editedEventId: null,
    eventDraft: null,
    recurringEventRoot: null,
    originNotificationId: null,
  },
  successSnackbar: [],
};

/**
 *
 */
const eventSorter: Sorter<ViewCalendarEvent> = (a, b) => {
  return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
};

/**
 *
 */
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    calendarsFetched: (state, { payload }: PayloadAction<CalendarVm[]>) => {
      state.calendars = payload;
    },
    calendarEdited: (state, { payload }: PayloadAction<ViewCalendar>) => {
      const index = state.calendars.findIndex((e) => e.id === payload.id);
      if (index !== -1) {
        state.calendars[index] = {
          ...payload,
          account_name: (payload as CalendarVm).account_name ?? state.calendars[index].account_name,
        };
      }
    },
    syncModalOpened: (state, { payload }: PayloadAction<Uuid | null>) => {
      state.syncModalAccountId = payload;
    },
    updateDateAndTitle: (state, { payload }: PayloadAction<{ title: string; date: UTCDate; start: string; end: string }>) => {
      state.view.title = payload.title;
      state.view.date = payload.date;
      state.view.start = payload.start;
      state.view.end = payload.end;
    },
    modeSelected: (state, { payload }: PayloadAction<ViewMode>) => {
      state.view.mode = payload;
    },
    loadEvents: (state, { payload }: PayloadAction<ViewCalendarEvent[]>) => {
      state.view.events = payload;
    },
    hiddenCalendarsUpdated: (state, { payload }: PayloadAction<Uuid[]>) => {
      state.view.hiddenCalendarIds = payload;
    },
    loadWorkEventsDone: (state, { payload }: PayloadAction<(ViewCalendarEvent & Taggable)[]>) => {
      state.workEvents = payload.sort(eventSorter);
    },
    eventDraftRequested: (
      state,
      {
        payload,
      }: PayloadAction<{
        draft: NewCalendarEventDTO;
        mode: FormMode;
        originNotificationId: Uuid | null;
      }>
    ) => {
      state.form = {
        mode: payload.mode,
        editedEventId: null,
        eventDraft: payload.draft,
        recurringEventRoot: null,
        originNotificationId: payload.originNotificationId,
      };
      state.view.draft = payload.draft;
    },
    eventDraftUpdate: (state, { payload }: PayloadAction<Omit<NewCalendarEventDTO, 'calendar_id'>>) => {
      state.view.draft = payload;
    },
    eventDraftCanceled: (state) => {
      state.form = {
        mode: 'IDLE',
        editedEventId: null,
        eventDraft: null,
        recurringEventRoot: null,
        originNotificationId: null,
      };
      state.view.draft = null;
    },
    eventCreateDone: (state, { payload }: PayloadAction<ViewCalendarEvent>) => {
      state.view.events.push(payload);
      state.view.events.sort(eventSorter);
      if ((payload.start.startsWith(toUTCDate(dayjs())) && payload.status === 'PLANNED') || payload.all_day) {
        state.workEvents.push({ ...payload, tags: [] });
        state.workEvents.sort(eventSorter);
      }
      state.form = {
        mode: 'IDLE',
        editedEventId: null,
        eventDraft: null,
        recurringEventRoot: null,
        originNotificationId: null,
      };
      state.view.draft = null;
      state.successSnackbar.push({ message: 'created', key: new Date().getTime() });
    },
    eventRecurringCreateDone: (state, { payload }: PayloadAction) => {
      state.form = {
        mode: 'IDLE',
        editedEventId: null,
        eventDraft: null,
        recurringEventRoot: null,
        originNotificationId: null,
      };
      state.view.draft = null;
      state.successSnackbar.push({ message: 'created', key: new Date().getTime() });
    },
    eventDeleteDone: (state, { payload }: PayloadAction<Uuid | CalendarEventInstanceId>) => {
      state.view.events = state.view.events.filter(
        // Delete functioning: SINGLE, ALL INSTANCES, ONE INSTANCE
        (e) => (e.kind === 'SINGLE' ? e.id !== payload : e.recurring_event_id !== payload && e.id !== payload)
      );
      state.workEvents = state.workEvents.filter((e) =>
        e.kind === 'SINGLE' ? e.id !== payload : e.recurring_event_id !== payload && e.id !== payload
      );
      state.form = {
        mode: 'IDLE',
        editedEventId: null,
        eventDraft: null,
        recurringEventRoot: null,
        originNotificationId: null,
      };
      state.view.draft = null;
      state.successSnackbar.push({ message: 'deleted', key: new Date().getTime() });
    },
    eventCompleteDone: (state, { payload }: PayloadAction<Uuid | CalendarEventInstanceId>) => {
      const index = state.view.events.findIndex((e) => e.id === payload);
      if (index >= 0) state.view.events[index].status = 'DONE';

      // Remove the event from work events list
      const index2 = state.workEvents.findIndex((e) => e.id === payload);
      if (index2 >= 0) state.workEvents.splice(index2, 1);
    },
    eventMissedDone: (state, { payload }: PayloadAction<Uuid | CalendarEventInstanceId>) => {
      const index = state.view.events.findIndex((e) => e.id === payload);
      if (index >= 0) state.view.events[index].status = 'MISSED';

      // Remove the event from work events list
      const index2 = state.workEvents.findIndex((e) => e.id === payload);
      if (index2 >= 0) state.workEvents.splice(index2, 1);
    },
    eventUpdateDone: (state, { payload }: PayloadAction<ViewCalendarEvent & Taggable>) => {
      const index = state.view.events.findIndex((e) => e.id === payload.id);
      const now = dayjs();

      /**
       * Update the day view
       */
      if (index >= 0) {
        // Case where the event is moved from the same day
        state.view.events[index] = payload;
      } else {
        // Case where the event is moved from another day
        state.view.events.push(payload);
      }
      state.view.events.sort(eventSorter);

      /**
       * Update the work events list
       */
      const index2 = state.workEvents.findIndex((e) => e.id === payload.id);
      if (index2 >= 0) {
        if (dayjs(payload.end).isBefore(now)) {
          // Case where the event is moved from today's future to past
          state.workEvents.splice(index2, 1);
        } else {
          // Case where the event is moved from today's future to today's future
          state.workEvents[index2] = payload;
          state.workEvents.sort(eventSorter);
        }
      } else if (dayjs(payload.end).isAfter(now) && toUTCDate(payload.start) === toUTCDate(now) && payload.status === 'PLANNED') {
        // Case where the event is moved from past to today's future
        state.workEvents.push(payload);
        state.workEvents.sort(eventSorter);
      }
      state.form = {
        mode: 'IDLE',
        editedEventId: null,
        eventDraft: null,
        recurringEventRoot: null,
        originNotificationId: null,
      };
      state.view.draft = null;
      state.successSnackbar.push({ message: 'updated', key: new Date().getTime() });
    },
    eventRecurringUpdateDone: (state, { payload }: PayloadAction<RecurringCalendarEventRootStrip>) => {
      state.form = {
        mode: 'IDLE',
        editedEventId: null,
        eventDraft: null,
        recurringEventRoot: null,
        originNotificationId: null,
      };
      state.view.draft = null;
      state.successSnackbar.push({ message: 'updated', key: new Date().getTime() });
    },
    eventEditRequested: (
      state,
      {
        payload,
      }: PayloadAction<{
        event: ViewCalendarEvent;
        mode: FormMode;
        recurringEventRoot: RecurringCalendarEventRootStrip | null;
      }>
    ) => {
      state.form = {
        mode: payload.mode,
        editedEventId: payload.event.id,
        /** @todo Handle single vs recurring event instance, silencing TS for now but this will break. */ // Talk with GREG about this?
        eventDraft: payload.event as NewCalendarEventDTO,
        recurringEventRoot: payload.recurringEventRoot,
        originNotificationId: null,
      };
      /** @todo Handle single vs recurring event instance, silencing TS for now but this will break. */
      state.view.draft = payload.event as NewCalendarEventDTO;
    },
    closeSuccessSnackbar: (state) => {
      state.successSnackbar = state.successSnackbar.slice(1);
    },
    deleteEventsByAccount: (state, { payload }: PayloadAction<Uuid>) => {
      state.view.events = state.view.events.filter((e) => e.metadata?.origin_notification?.account_id !== payload);
      state.workEvents = state.workEvents.filter((e) => e.metadata?.origin_notification?.account_id !== payload);
    },
  },
});

export const {
  calendarsFetched,
  calendarEdited,
  updateDateAndTitle,
  modeSelected,
  hiddenCalendarsUpdated,
  loadWorkEventsDone,
  eventDraftRequested,
  eventDraftUpdate,
  eventDraftCanceled,
  eventCreateDone,
  eventRecurringCreateDone,
  eventDeleteDone,
  eventUpdateDone,
  eventRecurringUpdateDone,
  eventEditRequested,
  eventCompleteDone,
  closeSuccessSnackbar,
  eventMissedDone,
  deleteEventsByAccount,
  loadEvents,
  syncModalOpened,
} = calendarSlice.actions;

export const datesChange =
  (start: string, end: string, title: string, date: UTCDate) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<void> => {
    dispatch(updateDateAndTitle({ title, date, start, end }));
    const events = await calendar.getEventsByCalendars(
      toUTCDate(start),
      toUTCDate(end),
      availableCalendarIdsSelector(getState())
    );

    dispatch(loadEvents(events));
  };

export const refreshView =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<void> => {
    const { start, end } = getState().calendar.view;

    // In some cases, the calendar did not load yet and there is not date range to refresh the events
    if (!start || !end) return;

    const events = await calendar.getEventsByCalendars(
      toUTCDate(start),
      toUTCDate(end),
      availableCalendarIdsSelector(getState())
    );

    dispatch(loadEvents(events));
  };

/**
 * Thunk.
 */
export const getAllPastEvents =
  (size: number, offset: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<(ViewCalendarEvent & Taggable)[]> => {
    return calendar.getAllPastEvents(size, offset, availableCalendarIdsSelector(getState()));
  };

/**
 * Thunk.
 */
export const getAllFutureEvents =
  (size: number, offset: number) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<(ViewCalendarEvent & Taggable)[]> => {
    return calendar.getAllFutureEvents(size, offset, availableCalendarIdsSelector(getState()));
  };

/**
 * Thunk.
 *
 * @todo Refresh work events when sync_activated is changed on some calendars.
 *
 * @todo Filter by available calendars when notifying missed events.
 *
 * @todo Fix the timezone issue : if toUTCDate(now) is on a different day than user local, timezone
 *       aware time, this doesn't work !
 */
export const loadWorkEvents =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<void> => {
    const now = dayjs();
    const nowDate = toUTCDate(now);
    // const events = await calendar.getEvents(nowDate, nowDate);
    const events = await calendar.getEventsByCalendars(nowDate, nowDate, availableCalendarIdsSelector(getState()));
    dispatch(
      loadWorkEventsDone(
        events.filter((e) => {
          //   prettyPrint({ e, nowDate }, "loadWorkEvents");
          return dayjs(e.end).isAfter(now) && toUTCDate(e.start) === nowDate && e.status === 'PLANNED';
        })
      )
    );
  };

/**
 * Thunk.
 *
 * @note Greg : Calendar handling appears to have been removed at some point. It seems that all events
 *              were floating without a reference to a calendar ( calendar_id : null ).
 *
 *              feature/googlecalendar requires that events are attached to a calendar.
 *
 *              I'm adding back the bare minimum so that we at least have a default UNIPILE calendar
 *              and its id to pass around.
 * @note Marco : I actually extended this function for the use in the calendar selector
 */
export const getCalendars =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar, account }: Container): Promise<void> => {
    const sortCalendars = (caledar: CalendarVm) => {
      if (caledar.type === 'GOOGLE') {
        return caledar.is_primary ? -1 : 1;
      } else return 1;
    };
    const calendars = await calendar.getCalendars();
    const { result: accountList } = await account.getAll();
    if (accountList) {
      const formattedCalendars: CalendarVm[] = calendars
        .map((calendar) => {
          const account_name =
            calendar.type === 'UNIPILE'
              ? 'Unipile'
              : accountList.find((account) => account.id === calendar.account_id)?.name || capitalize(calendar.type.toString());
          return {
            ...calendar,
            account_name,
          };
        })
        .sort(sortCalendars);
      dispatch(calendarsFetched(formattedCalendars));
    }
  };

/**
 * Thunk.
 */
export const setCalendarSync =
  (calendarId: Uuid, syncActivated: boolean) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<void> => {
    const updatedCalendar = syncActivated
      ? await calendar.activateCalendarSync(calendarId)
      : await calendar.deactivateCalendarSync(calendarId);

    if (updatedCalendar && updatedCalendar.type !== 'UNIPILE') {
      dispatch(setCalendarVisibility(updatedCalendar, syncActivated));
      dispatch(loadWorkEvents());
      // dispatch(updateAccountCalendar(updatedCalendar));
      dispatch(calendarEdited(updatedCalendar));
    } else {
      throw new Error('todo ! Possible unhandled path in setCalendarSync thunk : updatedCalendar is null.');
    }
  };

/**
 * Thunk.
 *
 * Draft a CalendarEvent
 * @param mode What form should open (CALENDAR for side area, ACTIONBAR for plan action modals)
 * @param defaultValues Values to pre-fill the form
 */
export const draftCalendarEvent =
  (
    mode: FormMode,
    defaultValues?: Partial<Pick<NewCalendarEventDTO, 'start' | 'end' | 'description' | 'summary' | 'metadata' | 'all_day'>>,
    immediateCreation?: boolean,
    originNotificationId?: Uuid
  ) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<void> => {
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());
    if (defaultCalendar) {
      const draft = calendar.getEventDraft(
        {
          calendar_id: defaultCalendar.id,
          /**
           * @todo Handle other type of calendar and other kind of events.
           */
          type: 'UNIPILE',
          kind: 'SINGLE',
          ...defaultValues,
        },
        defaultCalendar.default_tzid,
        mode,
        immediateCreation ?? false
      );

      if (immediateCreation) {
        await dispatch(createCalendarEvent(draft, originNotificationId));
      } else {
        await dispatch(eventDraftRequested({ draft, mode, originNotificationId: originNotificationId || null }));
      }
    }
  };

/**
 * Thunk.
 *
 * Opens the EventForm.
 */
export const editCalendarEvent =
  (mode: FormMode, event_id: Uuid | CalendarEventInstanceId, replan?: boolean) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<void> => {
    // Add recurring event case!
    const { result, error } = await calendar.getEvent(event_id);
    /**
     * This is a workaround to delete the exceptions in order to save the object
     * in the Redux State without the non-serializable state of the exceptions, which we don't need.
     * @todo Find a more correct solution.
     */
    let recurringEventRoot: any = null;
    if (!result || error) return alert(error || 'Event to edit not found');
    let { start, end } = result;

    /**
     * @todo Redo allDay handling. This is a cop out because the UI doesn't handle
     *       all_day events at all. At the time this was written, the UI will overwrite
     *       all_day to false during an edit no matter what.
     *       Until the forms are updated, we default allDay event to some fixed duration.
     */
    if (replan && result.status === 'MISSED') {
      const now = dayjs();
      start = toUTCDateTimeMs(now);
      end = result.all_day
        ? toUTCDateTimeMs(now.add(1, 'day'))
        : toUTCDateTimeMs(now.add(dayjs(result.end).diff(result.start, 'minute'), 'minute'));
    }

    // dispatch(navigateCalendarToDate(toUTCDateTimeMs(start)));
    if (result.kind === 'INSTANCE') {
      recurringEventRoot = await calendar.getRecurringRoot(result.recurring_event_id);
    }

    if (recurringEventRoot?.exceptions) {
      delete recurringEventRoot.exceptions;
    }

    dispatch(
      eventEditRequested({
        event: {
          ...result,
          start,
          end,
        },
        mode,
        recurringEventRoot,
      })
    );
  };

/**
 * Thunk.
 */
export const deleteCalendarEvent =
  (id: Uuid | CalendarEventInstanceId) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    const result = await calendar.deleteEvent(id);
    if (result.error) return result.error;
    dispatch(eventDeleteDone(id));
  };

/**
 * Thunk.
 */
export const deleteRecurringCalendarEvent =
  (id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    const result = await calendar.deleteRecurringEvent(id);
    if (result.error) return result.error;
    dispatch(eventDeleteDone(id));
  };

/**
 * Thunk.
 */
export const createCalendarEvent =
  (newEvent: NewCalendarEventDTO, originNotificationId?: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    // If the event is created from a notification, the logic is more complex and inside another thunk.
    if (originNotificationId || getState().calendar.form.originNotificationId !== null) {
      return dispatch(planNotification(newEvent, originNotificationId));
    }

    // If the event is not attached to any notification / entity
    switch (newEvent.kind) {
      case 'SINGLE': {
        const { result, error } = await calendar.createSingleEvent(newEvent);
        if (!result) return error;
        dispatch(eventCreateDone(result));
        if (dayjs(result.end).isBefore(dayjs())) dispatch(completeEvent(result));
        break;
      }
      case 'RECURRING': {
        const { result, error } = await calendar.createRecurringEvent(newEvent, toUTCDateTimeMs(dayjs()));
        if (!result) {
          return error;
        }
        dispatch(eventRecurringCreateDone());
        break;
      }
    }
  };

/**
 * Thunk.
 */
export const updateSingleCalendarEvent =
  (id: Uuid, update: PatchCalendarEventDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    const { result, error } = await calendar.editEvent(id, update);
    if (!result || error) return error;

    if (result.kind === 'SINGLE') {
      dispatch(eventUpdateDone(result as ViewCalendarEvent & Taggable));
      if (dayjs(result.end).isBefore(dayjs())) {
        dispatch(completeEvent(result));
      }
    }
    if (result.kind === 'RECURRING') {
      dispatch(eventRecurringUpdateDone(result));
    }
  };

/**
 * Thunk.
 */
export const updateRecurringCalendarEvent =
  (id: Uuid, update: PatchCalendarEventDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    const recurringEventRoot = getState().calendar.form.recurringEventRoot;
    if (!recurringEventRoot) return 'Cannot retrieve recurrent event';

    if (update.kind === 'RECURRING') {
      const isDifferentDates =
        (update?.start && dayjs(update?.start).diff(recurringEventRoot.start, 'day') !== 0) ||
        (update?.end && dayjs(update?.end).diff(recurringEventRoot.end, 'day') !== 0);

      if (isDifferentDates) {
        return 'Cannot edit a recurring event if the update day differs from recurrent day.';
      }
    }

    const { result, error } = await calendar.editRecurringEvent(recurringEventRoot.id, update);

    if (!result || error) return error;

    if (result.kind === 'RECURRING') {
      dispatch(eventRecurringUpdateDone(result));
    }
    if (result.kind === 'SINGLE') {
      dispatch(eventUpdateDone(result as ViewCalendarEvent & Taggable));
      if (dayjs(result.end).isBefore(dayjs())) {
        dispatch(completeEvent(result));
      }
    }
  };

export const updateException =
  (id: CalendarEventInstanceId, update: CalendarEventExceptionDTO) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    const { result, error } = await calendar.editInstanceEvent(id, update);
    if (!result || error) return error;
    dispatch(eventUpdateDone(result));
    if (dayjs(result.end).isBefore(dayjs())) dispatch(completeEvent(result));
  };

/**
 * Thunk.
 *
 * We can have three cases:
 * 1 - Single event update or Single event turning Recurrent.
 *    In the second case the update.kind is "SINGLE".
 * 2 - Recurrent event update or Recurrent event turning Single.
 *    This happens when we change the recurrency selector or when we want to apply the edits to all the events.
 * 3 - Instance event update.
 *
 * There might be better solutions to handle this, like passing an extra propriety in the updateCalendarEvent
 * or manage all this logic in the view and not in the slice, or calling the other functions directly.
 */
export const updateCalendarEvent =
  (id: Uuid | CalendarEventInstanceId, update: PatchCalendarEventDTO | ViewCalendarEvent) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    const recurringEventRoot = getState().calendar.form.recurringEventRoot;
    const isRecurringEvent = recurringEventRoot?.id;

    if ((update.kind === 'SINGLE' || update.kind === 'RECURRING') && !isRecurringEvent) {
      dispatch(updateSingleCalendarEvent(id as Uuid, update));
    }

    if ((update.kind === 'SINGLE' || update.kind === 'RECURRING') && isRecurringEvent) {
      dispatch(updateRecurringCalendarEvent(id as Uuid, update));
    }

    if (update.kind === 'INSTANCE') {
      dispatch(updateException(id as CalendarEventInstanceId, update));
    }
  };

/**
 * Thunk.
 */
export const openViewEvent =
  (id: Uuid | CalendarEventInstanceId) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { calendar }: Container
  ): Promise<(ViewCalendarEvent & Taggable) | null> => {
    const { result, error } = await calendar.getEvent(id);
    if (error) alert(error);
    return result || null;
  };

/**
 * Thunk.
 */
export const getRecurringRoot =
  (recurring_event_id: Uuid) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<RecurringCalendarEventRoot | null> => {
    const recurringEventRoot = await calendar.getRecurringRoot(recurring_event_id);
    return recurringEventRoot || null;
  };

/**
 * Thunk.
 */
export const workOnEntity =
  (entity_type: AttachedEntityType, entity_id: Uuid | CalendarEventInstanceId) =>
  async (
    dispatch: AppDispatch,
    getState: AppGetState,
    { mail, im }: Container
  ): Promise<Uuid | CalendarEventInstanceId | void> => {
    // const calendar_id = calendarsDefaultCalendarIdSelector(getState());
    const defaultCalendar = calendarsDefaultCalendarSelector(getState());

    let result: Result<ViewCalendarEvent> = {};
    /**
     * @todo Replace the assertions by using entity_type to tag a discriminated union and
     *       handling all the cases ?
     */
    if (defaultCalendar) {
      switch (entity_type) {
        case 'MAIL':
          result = await mail.mailWork(defaultCalendar, entity_id as Uuid);
          break;
        case 'IM_THREAD':
          result = await im.imThreadWork(defaultCalendar, entity_id as Uuid);
          break;
        default:
          break;
      }

      if (result.error) return alert(result.error);
      if (result.result) {
        dispatch(eventCreateDone(result.result));
        return result.result.id;
      }
    }

    throw new Error('todo ! Unhandled path in workOnEntity thunk : calendar_id is null.');
  };

/**
 * Thunk.
 */
export const refreshEvent =
  (event_id: Uuid | CalendarEventInstanceId) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail, call, im, calendar }: Container): Promise<string | void> => {
    const { result, error } = await calendar.getEvent(event_id);
    if (error) return error;
    if (result) {
      if (result.status === 'DONE') dispatch(eventCompleteDone(event_id));
      else if (result.status === 'MISSED') dispatch(eventMissedDone(event_id));
      else dispatch(eventUpdateDone(result));
    }
    if (!result) dispatch(eventDeleteDone(event_id));
  };

/**
 * Thunk.
 */
export const completeEventContext =
  (context: ActionContext) =>
  async (dispatch: AppDispatch, getState: AppGetState, { calendar }: Container): Promise<string | void> => {
    // prettyPrint({ context }, 'completeEventContext', 'warn');
    const isNotification = !isEvent(context);
    const eventID = isNotification ? context.metadata?.origin_event?.id : context.id;
    if (eventID) {
      const { result, error } = await calendar.getEvent(eventID);
      if (isNotification) {
        dispatch(archiveNotification(context));
      }
      if (result) {
        return dispatch(completeEvent(result));
      }
      if (error) return error;
    } else {
      return 'EVENT ID IS UNDEFINED';
    }
  };

/**
 * Thunk.
 */
export const completeEvent =
  (event: ViewCalendarEvent) =>
  async (dispatch: AppDispatch, getState: AppGetState, { mail, call, im, calendar }: Container): Promise<string | void> => {
    // prettyPrint({ event }, 'completeEvent', 'warn');
    if (event.metadata?.attached_entity) {
      const eventCalendar =
        getState().calendar.calendars.find((c) => c.id === event.calendar_id) ?? (await calendar.getCalendar(event.calendar_id));
      const entity_type = event.metadata.attached_entity.type;
      /**
       * @todo See what to do in case of a recurrent event
       */
      if (eventCalendar) {
        switch (entity_type) {
          case 'MAIL':
            await mail.mailDone(event);
            break;
          case 'MAIL_DRAFT':
            await mail.mailDraftDone(event);
            break;
          case 'CALL':
            await call.callDone(eventCalendar, event);
            break;
          case 'IM':
          case 'IM_THREAD':
            await im.imDone(eventCalendar, event);
            break;
          default:
            break;
        }
      }
    } else {
      const error = await calendar.completeEvent(event);
      if (error) {
        return error;
      }
    }
    dispatch(eventCompleteDone(event.id));
  };

/**
 * Thunk.
 */
export const initNotifyMissedEvents =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState, { cron, calendar, tag }: Container): Promise<void> => {
    await cron.runCron(
      'notifyMissedEvents',
      async () => {
        const missedEventNotifications = await calendar.missPastEvents();

        if ('error' in missedEventNotifications) {
          return;
        }
        /**
         * @note Greg : initNotifyMissedEvents was updated to avoid looking at stale cache
         *              ( getState().calendar.workEvents ) to figure out what events are actually
         *              missed.
         *
         *              The following part is doing pretty much the same thing it did before
         *              the update and seems to have a large delay when marking multiple
         *              events as missed. This should be looked into.
         */
        await Promise.all(
          missedEventNotifications.map(async (n) => {
            const eventId = n.metadata?.origin_event?.id;
            if (eventId) {
              dispatch(eventMissedDone(eventId));
            }
            const tags = await tag.getNotificationTags(n);
            dispatch(addNotification({ ...n, tags: tags.result || [] }));
            return;
          })
        );
      },
      60 * 1000
    );
  };

/**
 * Thunk.
 */
export const cancelPlan =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    if (getState().calendar.form.mode === 'CALENDAR') return;
    dispatch(eventDraftCanceled());
  };

/**
 * Thunk.
 */
export const fetchHiddenCalendars =
  () =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    const session = sessionSelector(getState());

    if (session) {
      const storedHiddenCalendars = localStorage.getItem(HIDDEN_CALENDARS_PREFIX + session.id);
      if (storedHiddenCalendars !== null) {
        const storedHiddenCalendarIds = JSON.parse(storedHiddenCalendars as string) as Uuid[];
        dispatch(hiddenCalendarsUpdated(storedHiddenCalendarIds));
      } else {
        dispatch(hiddenCalendarsUpdated([]));
      }
    } else {
      throw new Error('todo ! Possible unhandled path in fetchHiddenCalendars thunk : session is null.');
    }
  };

/**
 * Thunk.
 */
export const toggleCalendarVisibility =
  (calendar: ViewCalendar) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    const session = sessionSelector(getState());

    if (session) {
      const hiddenCalendarIds = [...hiddenCalendarIdsSelector(getState())];

      const index = hiddenCalendarIds.indexOf(calendar.id);
      if (index === -1) {
        hiddenCalendarIds.push(calendar.id);
      } else {
        hiddenCalendarIds.splice(index, 1);
      }

      dispatch(hiddenCalendarsUpdated(hiddenCalendarIds));
      localStorage.setItem(HIDDEN_CALENDARS_PREFIX + session.id, JSON.stringify(hiddenCalendarIds));
    } else {
      throw new Error('todo ! Possible unhandled path in toggleCalendarVisibility thunk : session is null.');
    }
  };

/**
 * Thunk.
 */
export const setCalendarVisibility =
  (calendar: ViewCalendar, visibility: boolean) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    const session = sessionSelector(getState());

    if (session) {
      const hiddenSet = new Set(hiddenCalendarIdsSelector(getState()));

      if (visibility) {
        hiddenSet.delete(calendar.id);
      } else {
        hiddenSet.add(calendar.id);
      }

      const hiddenCalendarIds = [...hiddenSet];
      dispatch(hiddenCalendarsUpdated(hiddenCalendarIds));
      localStorage.setItem(HIDDEN_CALENDARS_PREFIX + session.id, JSON.stringify(hiddenCalendarIds));
    } else {
      throw new Error('todo ! Possible unhandled path in setCalendarVisibility thunk : session is null.');
    }
  };

/**
 * Selector.
 */

export default calendarSlice.reducer;
